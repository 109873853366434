import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { apiCollaborators } from 'constants/api';
import PermissionContainer from './PermissionContainer';
import { ElementContainer } from './PermissionsPopover.style';

export const PermissionsPopover = ({
  handleClose,
  open,
  id,
  anchorEl,
  sharedWith,
  handleShare,
  canShare = false,
  isOwner = false,
  shareRoute,
  entityId,
  shareOptions,
  accessExpiration,
}) => {
  const permissionPopoverTitleMap = {
    [apiCollaborators.INSPECTIONS_COLLBAORATORS]: canShare
      ? "Indica gli utenti a cui è consentita la modifica dell'attività"
      : "Utenti a cui è stata consentita la modifica dell'attività",
    [apiCollaborators.STRUCTURE_COLLBAORATORS]: 'Condividi Opera',
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <ElementContainer>
        <Typography variant="h3Bold" mb={2}>
          {permissionPopoverTitleMap[shareRoute]}
        </Typography>

        <PermissionContainer
          shareRoute={shareRoute}
          sharedWith={sharedWith}
          canShare={canShare}
          handleShare={handleShare}
          entityId={entityId}
          isOwner={isOwner}
          accessExpiration={accessExpiration}
          shareOptions={shareOptions}
        />
      </ElementContainer>
    </Popover>
  );
};

PermissionsPopover.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  id: PropTypes.string,
  anchorEl: PropTypes.node,
  sharedWith: PropTypes.array,
  shareOptions: PropTypes.array,
  handleShare: PropTypes.func,
  canShare: PropTypes.bool,
  isOwner: PropTypes.bool,
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shareRoute: PropTypes.oneOf([
    apiCollaborators.INSPECTIONS_COLLBAORATORS,
    apiCollaborators.STRUCTURE_COLLBAORATORS,
  ]),
  accessExpiration: PropTypes.string,
};
