export const markdown_faq_25_1 = `Tutto ciò che viene inserito all'interno di INBEE può essere esportato in **diversi formati** in base alle esigenze e al tipo di utente/sottoscrizione.

E' possibile scegliere tra diverse tipologie di esportazione, tra cui:
- Schede di censimento - le schede ministeriali richieste compilate in base ai campi inseriti ed esportate in formato word editabile;
- Schede di ispezione - le schede ministeriali richieste compilate in base ai campi inseriti ed esportate in formato word editabile;
- Schede di valutazione difetti e il relativo allegato fotografico - schede ministeriali richieste compilate in base ai campi inseriti;
- Schede di sintesi dei 4 rischi rilevati e della CdA complessiva;
- csv con i dati richiesti da ANSFISA e direttamente compatibili con il portale AINOP (solo per utente di tipo ENTE GESTORE con sottoscrizione attiva);
- csv contenenti tutti i dati inseriti in piattaforma di più ponti (solo per utente di tipo ENTE GESTORE con sottoscrizione attiva);
- Relazione tecnica completa e sottocategorie (solo per utente di tipo ISPETTORE con sottoscrizione attiva).

**COME ESPORTARE FILE DI UN SINGOLO PONTE**
1.  Selezionare il tasto *Esporta* presente in corrispondenza della schede di sintesi del ponte
2. Scegliere il tipo di file
3. Selezionare *Esporta*
4. Selezionare *Download* per scaricare il file sul tuo computer.

Prima di esportare è possibile selezionare la spunta *Salva il file nell'area Documenti del ponte* per caricare automaticamente il file tra i documenti del ponte.`;
export const markdown_faq_25_2 = `**COME ESPORTARE DATI DI PIU' PONTI**
1. Nella sezione Ponti, definire l'elenco dei ponti per i quali si vogliono esportare i dati attraverso l'uso dei filtri
2. Selezionare il tasto *Esporta Elenco Ponti* presente nella sezione Ponti
3. Dal pannello di esportazione, selezionare la tipologia di esportazione. Se si sceglie *Personalizzata* è possibile definire i campi da esportare
4. Selezionare *Esporta*.

ATTENZIONE: Questa funzione è disponibile solo per utenti con una sottoscrizione attiva.`;
