import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, GridWrap, InfoWrapper, Table } from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';

import { tipologiaArcoOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import ArchiForm from './ArchiForm';

const ArchiSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const mensoleTamponiCampateImpalcatoTableArchiTableWatch = watch(
    `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].archiTable`
  );

  // table setup
  const dataConfigArchi = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaArco: {
        label: 'Tipologia',
        format: tipologiaArcoOptions,
      },
      luceArco: {
        label: 'L [cm]',
      },
      larghezzaSuperioreArco: {
        label: 'Ls [cm]',
      },
      larghezzaInferioreArco: {
        label: 'Li [cm]',
      },
      spessoreArco: {
        label: 's [cm]',
      },
      frecciaArco: {
        label: 'f [cm]',
      },
      superficieArco: {
        label: 'Sup. [m²]',
        formatValue: val => `${(+val)?.toFixed(2)}`,
      },
    },
  };

  // archi setup
  const [selectedArchi, setSelectedArchi] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsArchi = item => {
    setValue('l0t2a5_archiTableValue', [item]);
    setValue('l0t2a5_tipologiaArcoTableValue', item.tipologiaArco);
    setValue('l0t2a5_luceArcoTableValue', item.luceArco);
    setValue(
      'l0t2a5_larghezzaSuperioreArcoTableValue',
      item.larghezzaSuperioreArco
    );
    setValue(
      'l0t2a5_larghezzaInferioreArcoTableValue',
      item.larghezzaInferioreArco
    );
    setValue('l0t2a5_spessoreArcoTableValue', item.spessoreArco);
    setValue('l0t2a5_frecciaArcoTableValue', item.frecciaArco);
    setValue('l0t2a5_superficieArcoTableValue', item.superficieArco);
  };
  const handleSelectAllClickArchi = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].archiTable`
      );
      setSelectedArchi(selectedValue);
      setValue('l0t2a5_archiTableValue', selectedValue);
    } else {
      setSelectedArchi([]);
      setValue('l0t2a5_archiTableValue', []);
    }
  };

  return (
    <>
      <GridWrap container spacing={2} style={{ marginTop: 16 }}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.impalcatoArchi.drawerTitle}
          drawerText={INFO.impalcatoArchi.drawerText}
        >
          <Divider>ARCHI</Divider>
        </InfoWrapper>
        {mensoleTamponiCampateImpalcatoTableArchiTableWatch?.length === 0 && (
          <GridWrap item xs={12} paddingTop={'0px !important'}>
            <Typography>Nessun arco presente in questo elemento</Typography>
          </GridWrap>
        )}
      </GridWrap>
      {mensoleTamponiCampateImpalcatoTableArchiTableWatch?.length > 0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <ArchiForm
              archiFields={
                mensoleTamponiCampateImpalcatoTableArchiTableWatch || []
              }
              selected={selectedArchi}
              setSelected={setSelectedArchi}
              archiTable={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].archiTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Archi'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].archiTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Struttura]}
          />
          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={mensoleTamponiCampateImpalcatoTableArchiTableWatch || []}
                config={dataConfigArchi}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsArchi(item);
                            setSelectedArchi([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        mensoleTamponiCampateImpalcatoTableArchiTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedArchi}
                onSelectAllClick={handleSelectAllClickArchi}
                setSelected={setSelectedArchi}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
ArchiSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default ArchiSection;
