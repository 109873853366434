import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { DrawerCopy, LocalTourWrapper, RHFSelect } from 'components';
import { SelectionPanel } from 'components/SelectionPanel/SelectionPanel';
import { mensole } from 'constants/inspections';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { generateCopySteps } from 'utils';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import {
  isNullOrUndefinedOrEmptyString,
  updateArrayTableRows,
} from 'utils/utilities';
import { BtnContainer, GridWrap } from './Accordion5Impalcato.styles';
import useCopy from './hooks/useCopy';
import ArchiSection from './sections/ArchiSection';
import ControventiSection from './sections/ControventiSection';
import ElementiExtraSection from './sections/ElementiExtraSection';
import PiedrittiSection from './sections/PiedrittiSection';
import SoletteSection from './sections/SoletteSection';
import TirantiSection from './sections/TiranteSection';
import TraversiSection from './sections/TraversiSection';
import TraviSection from './sections/TraviSection';

const Accordion5Impalcato = () => {
  const { watch, control, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  // SPALLE/PILE Impalcato Table
  const {
    append: addMensolaTamponeCampataImpalcato,
    remove: removeMensolaTamponeCampataImpalcato,
  } = useFieldArray({
    control,
    name: 'mensoleTamponiCampateImpalcatoTable',
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  // i dati sono collegati all'Accordion1StructuralFeatures, dove per ogni pila e spalla
  // vengono definiti i numeri di appoggi, giunti, antenne pulvini e dispositivi sismici
  const watchMensole = watch('mensole');
  const isMensole = watchMensole === mensole.Si;

  const mensoleTamponiTableWatch = watch('mensoleTamponiTable');
  const campataTableWatch = watch('campataStrutturaTable');
  const mensoleTamponiCampateImpalcatoTableWatch = watch(
    'mensoleTamponiCampateImpalcatoTable'
  );

  const numCampate = watch('numeroCampate');
  const numMensole = watch('nMensoleStruttura');
  const numTamponi = watch('nTamponiStruttura');

  const handleMensoleTamponiCampateImpalcatoTable = table => {
    const hasChangedAnySolette = table?.some((el, idx) => {
      return (
        el.soletta !==
        mensoleTamponiCampateImpalcatoTableWatch[idx]?.soletteTable?.length
      );
    });
    const hasChangedAnyTravi = table?.some((el, idx) => {
      return (
        el.travi !==
        mensoleTamponiCampateImpalcatoTableWatch[idx]?.traviTable?.length
      );
    });
    const hasChangedAnyTraversi = table?.some((el, idx) => {
      return (
        el.traversi !==
        mensoleTamponiCampateImpalcatoTableWatch[idx]?.traversiTable?.length
      );
    });
    const hasChangedAnyControventi = table?.some((el, idx) => {
      return (
        el.controventi !==
        mensoleTamponiCampateImpalcatoTableWatch[idx]?.controventiTable?.length
      );
    });
    const hasChangedAnyArchi = table?.some((el, idx) => {
      return (
        el.archi !==
        mensoleTamponiCampateImpalcatoTableWatch[idx]?.archiTable?.length
      );
    });
    const hasChangedAnyPiedritti = table?.some((el, idx) => {
      return (
        el.piedritti !==
        mensoleTamponiCampateImpalcatoTableWatch[idx]?.piedrittiTable?.length
      );
    });
    const hasChangedAnyTiranti = table?.some((el, idx) => {
      return (
        el.tiranti !==
        mensoleTamponiCampateImpalcatoTableWatch[idx]?.tirantiTable?.length
      );
    });
    const hasChangedAnyElementiExtra = table?.some((el, idx) => {
      return (
        el.elementiExtra !==
        mensoleTamponiCampateImpalcatoTableWatch[idx]?.elementiExtraTable
          ?.length
      );
    });

    if (
      table?.length !== mensoleTamponiCampateImpalcatoTableWatch?.length ||
      hasChangedAnySolette ||
      hasChangedAnyTravi ||
      hasChangedAnyTraversi ||
      hasChangedAnyControventi ||
      hasChangedAnyArchi ||
      hasChangedAnyPiedritti ||
      hasChangedAnyTiranti ||
      hasChangedAnyElementiExtra
    ) {
      removeMensolaTamponeCampataImpalcato();
      table.map((el, idx) => {
        const itemFromImpalcatoTable =
          mensoleTamponiCampateImpalcatoTableWatch?.find(
            col => col.codice === el.campata
          );
        const soletteTable = itemFromImpalcatoTable?.soletteTable || [];
        const traviTable = itemFromImpalcatoTable?.traviTable || [];
        const traversiTable = itemFromImpalcatoTable?.traversiTable || [];
        const controventiTable = itemFromImpalcatoTable?.controventiTable || [];
        const archiTable = itemFromImpalcatoTable?.archiTable || [];
        const piedrittiTable = itemFromImpalcatoTable?.piedrittiTable || [];
        const tirantiTable = itemFromImpalcatoTable?.tirantiTable || [];
        const elementiExtraTable =
          itemFromImpalcatoTable?.elementiExtraTable || [];

        const handlePushSolette = i => {
          soletteTable.push({
            id: uuidv4(),
            codice: `SO-${el.campata}.${i}`,
            tipologiaSoletta: '',
            altezzaSpessoreBordoSoletta: '',
            lunghezzaSbalzoSoletta: '',
            schedaDifetti: populateSchedaDifetti('soletta').map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          });
        };

        const handlePushTravi = i => {
          traviTable.push({
            id: uuidv4(),
            codice: `TP-${el.campata}.${i}`,
            tipologiaTrave: '',
            tipoTrave: '',
            altezzaSviluppoLateraleTrave: '',
            lunghezzaTrave: '',
            spessoreTrave: '',
            superficieTrave: '',
            schedaDifetti: populateSchedaDifetti('trave').map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          });
        };
        const handlePushTraversi = i => {
          traversiTable.push({
            id: uuidv4(),
            codice: `TS-${el.campata}.${i}`,
            tipologiaTraverso: '',
            altezzaTraverso: '',
            lunghezzaTraverso: '',
            spessoreTraverso: '',
            superficieTraverso: '',
            schedaDifetti: populateSchedaDifetti('traverso').map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          });
        };
        const handlePushControventi = i => {
          controventiTable.push({
            id: uuidv4(),
            codice: `CV-${el.campata}.${i}`,
            tipologiaControvento: '',
            geometriaSezioneControvento: '',
            lunghezzaControvento: '',
            diametroControvento: '',
            spessoreControvento: '',
            altezzaControvento: '',
            superficieControvento: '',
            schedaDifetti: populateSchedaDifetti('controvento').map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          });
        };
        const handlePushArchi = i => {
          archiTable.push({
            id: uuidv4(),
            codice: `A-${el.campata}.${i}`,
            tipologiaArco: '',
            luceArco: '',
            larghezzaSuperioreArco: '',
            larghezzaInferioreArco: '',
            spessoreArco: '',
            frecciaArco: '',
            superficieArco: '',
            schedaDifetti: populateSchedaDifetti('arco').map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          });
        };
        const handlePushPiedritti = i => {
          piedrittiTable.push({
            id: uuidv4(),
            codice: `PD-${el.campata}.${i}`,
            tipologiaPiedritto: '',
            altezzaPiedritto: '',
            larghezzaPiedritto: '',
            profonditaPiedritto: '',
            superficiePiedritto: '',
            schedaDifetti: populateSchedaDifetti('piedritto').map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          });
        };
        const handlePushTiranti = i => {
          tirantiTable.push({
            id: uuidv4(),
            codice: `TN-${el.campata}.${i}`,
            tipologiaTirante: '',
            geometriaSezioneTirante: '',
            lunghezzaTirante: '',
            diametroTirante: '',
            spessoreTirante: '',
            profonditaTirante: '',
            schedaDifetti: populateSchedaDifetti('tirante').map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          });
        };
        const handlePushelementiExtra = i => {
          elementiExtraTable.push({
            id: uuidv4(),
            codice: `EX-${el.campata}.${i}`,
            nomeElementoExtra: '',
            tipologiaElementoExtra: '',
            altezzaElementoExtra: '',
            larghezzaElementoExtra: '',
            profonditaElementoExtra: '',
            superficieElementoExtra: '',
            schedaDifetti: populateSchedaDifetti('elementoExtra').map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          });
        };

        updateArrayTableRows(el.soletta, soletteTable, handlePushSolette);
        updateArrayTableRows(el.travi, traviTable, handlePushTravi);
        updateArrayTableRows(el.traversi, traversiTable, handlePushTraversi);
        updateArrayTableRows(
          el.controventi,
          controventiTable,
          handlePushControventi
        );
        updateArrayTableRows(el.archi, archiTable, handlePushArchi);
        updateArrayTableRows(el.piedritti, piedrittiTable, handlePushPiedritti);
        updateArrayTableRows(el.tiranti, tirantiTable, handlePushTiranti);
        updateArrayTableRows(
          el.elementiExtra,
          elementiExtraTable,
          handlePushelementiExtra
        );

        addMensolaTamponeCampataImpalcato({
          id: uuidv4(),
          codice: el.campata,
          soletteTable,
          traviTable,
          traversiTable,
          controventiTable,
          archiTable,
          piedrittiTable,
          tirantiTable,
          elementiExtraTable,
        });
      });
    }
  };
  /* 
  useEffect(() => {
    console.log(watchMensole, 'watchMensole')
    if (watchMensole && watchMensole === mensole.Si) {
      if (
        parseInt(numMensole) === 0 ||
        isNullOrUndefinedOrEmptyString(numMensole) ||
        parseInt(numTamponi) === 0 ||
        isNullOrUndefinedOrEmptyString(numTamponi)
      ) {
        removeMensolaTamponeCampataImpalcato();
      }
    } else {
      if (
        parseInt(numCampate) === 0 ||
        isNullOrUndefinedOrEmptyString(numCampate)
      ) {
        removeMensolaTamponeCampataImpalcato();
      }
    }
  }, [watchMensole, numCampate, numMensole, numTamponi]); */

  useEffect(() => {
    if (
      mensoleTamponiTableWatch &&
      campataTableWatch &&
      mensoleTamponiCampateImpalcatoTableWatch
    ) {
      // we loop through all of the entries of mensoleTamponiTable for check
      // if the user has changed any of the values of the table
      if (isMensole) {
        handleMensoleTamponiCampateImpalcatoTable(mensoleTamponiTableWatch);
      } else {
        handleMensoleTamponiCampateImpalcatoTable(campataTableWatch);
      }
    }
  }, [mensoleTamponiTableWatch, campataTableWatch, isMensole]);

  const [
    mensoleTamponiCampateImpalcatoSelezioneOptions,
    setMensoleTamponiCampateImpalcatoSelezioneOptions,
  ] = useState([]);

  const [selectedIdx, setSelectedIdx] = useState('');

  useEffect(() => {
    if (mensoleTamponiCampateImpalcatoTableWatch) {
      setMensoleTamponiCampateImpalcatoSelezioneOptions(
        mensoleTamponiCampateImpalcatoTableWatch.map((el, idx) => {
          return { label: el.codice, value: idx };
        })
      );
    }
  }, [mensoleTamponiCampateImpalcatoTableWatch]);

  // watcher for selecting index of which spalla/pila to modify
  const mensoleTamponiCampateImpalcatoSelezioneWatch = watch(
    'mensoleTamponiCampateImpalcatoSelezione'
  );
  useEffect(() => {
    if (mensoleTamponiCampateImpalcatoSelezioneWatch !== undefined) {
      setSelectedIdx(mensoleTamponiCampateImpalcatoSelezioneWatch);
    }
  }, [mensoleTamponiCampateImpalcatoSelezioneWatch]);

  const {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedCopy,
    setSelectedCopy,
    selectedPaste,
    dataMensoleTamponiCampateConfig,
    handleSelectAllMensoleTamponiCampatePaste,
    setSelectedPaste,
    checkCompatibility,
    handleCopy,
  } = useCopy();

  const tourId = 'l0-struttura-impalcato';

  return (
    <>
      <GridWrap item xs={12}>
        <SelectionPanel subtitle="Seleziona una Campata/Mensola/Tampone per procedere alla compilazione dei rispettivi soletta/travi/traversi/controventi/archi/piedritti/tiranti/elementi extra">
          <GridWrap container spacing={2}>
            <GridWrap item xs={10}>
              <RHFSelect
                name="mensoleTamponiCampateImpalcatoSelezione"
                label={'Seleziona elemento'}
                defaultValue={''}
                selectOptions={mensoleTamponiCampateImpalcatoSelezioneOptions}
              />
            </GridWrap>

            <GridWrap item xs={2}>
              {selectedIdx !== '' &&
                !inspectionDisabled &&
                !isViewingInspection && (
                  <BtnContainer>
                    <LocalTourWrapper
                      steps={generateCopySteps(tourId)}
                      style={{ margin: '0 auto', marginRight: 'unset' }}
                    />
                    <Button
                      className={`${tourId}`}
                      onClick={() => {
                        setCopyDrawerOpen(true);
                        setSelectedCopy(
                          getValues(
                            `mensoleTamponiCampateImpalcatoTable[${selectedIdx}]`
                          )
                        );
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Copia
                    </Button>
                  </BtnContainer>
                )}
            </GridWrap>
          </GridWrap>
        </SelectionPanel>
      </GridWrap>
      {selectedIdx !== '' && (
        <>
          <SoletteSection selectedIdx={selectedIdx} />
          <TraviSection selectedIdx={selectedIdx} />
          <TraversiSection selectedIdx={selectedIdx} />
          <ControventiSection selectedIdx={selectedIdx} />
          <ArchiSection selectedIdx={selectedIdx} />
          <PiedrittiSection selectedIdx={selectedIdx} />
          <TirantiSection selectedIdx={selectedIdx} />
          <ElementiExtraSection selectedIdx={selectedIdx} />
        </>
      )}
      <DrawerCopy
        title={`Impalcato / Copia ${getValues(
          `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].codice`
        )}`}
        subtitle={
          "Seleziona le destinazioni sulle quali copiare i dati dell'impalcato"
        }
        open={copyDrawerOpen}
        setOpen={setCopyDrawerOpen}
        tableConfig={dataMensoleTamponiCampateConfig}
        copyTableData={
          isMensole
            ? watch('mensoleTamponiTable')?.filter(
                el => el.campata == selectedCopy.codice
              )
            : watch('campataStrutturaTable')?.filter(
                el => el.campata == selectedCopy.codice
              ) || []
        }
        pasteTableData={
          isMensole
            ? watch('mensoleTamponiTable')?.filter(
                el => el.campata != selectedCopy.codice
              )
            : watch('campataStrutturaTable')?.filter(
                el => el.campata != selectedCopy.codice
              ) || []
        }
        selectedCopy={selectedCopy}
        selectedPaste={selectedPaste}
        handleSelectAll={handleSelectAllMensoleTamponiCampatePaste}
        setSelectedPaste={setSelectedPaste}
        checkCompatibility={checkCompatibility}
        handleCopy={handleCopy}
      />
    </>
  );
};

export { Accordion5Impalcato };
