import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const Container = styled('div')({
  height: '100vh',
  width: '100%',
});

export const Head = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '10px 20px',
});

export const ButtonBox = styled('div')({
  display: 'flex',
});

export const ButtonSpace = styled(Button)({
  marginRight: '10px',
});

export const WhiteBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  margin: '20px',
}));
