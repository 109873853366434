import styled from '@emotion/styled';

export const Buttons = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  '& .MuiIconButton-root': {
    marginLeft: '5px',
  },
});
