import { Typography } from '@mui/material';

import { MediaUpload, RHFTextField } from 'components';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';

import {
  GridWrap,
  TitleSection,
} from './Accordion6AnnotazioniPerReport.styles';

const Accordion6AnnotazioniPerReport = () => {
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();
  return (
    <>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              {'INFORMAZIONI DI ISPEZIONE'}
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="descrizioneInformazioniDiIspezioneAccordion6"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoInformazioniDiIspezioneAccordion6"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">{'RISCHIO FRANE'}</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="descrizioneRischioFraneAccordion6"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoRischioFraneAccordion6"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="annotazioniRischioFraneAccordion6"
            label={'Annotazioni rischio frane'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoAnnotazioniRischioFraneAccordion6"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="commentiConclusiviCdAFrane"
            label={'Commenti conclusivi alla CdA frane'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      </GridWrap>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">{'RISCHIO IDRAULICO'}</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="descrizioneDatiGeneraliAccordion6"
            label={'Dati generali'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoDatiGeneraliAccordion6"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="descrizioneCaratteristicheAlveoAccordion6"
            label={'Caratteristiche alveo'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoCaratteristicheAlveoAccordion6"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="descrizioneSormontoAccordion6"
            label={'Sormonto'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoSormontoAccordion6"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="descrizioneErosioneAccordion6"
            label={'Erosione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoErosioneAccordion6"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="descrizioneOpereDiProtezioneAccordion6"
            label={'Opere di protezione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoOpereDiProtezioneAccordion6"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="descrizioneAnnotazioniRischioIdraulicoAccordion6"
            label={'Annotazioni rischio idraulico'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoAnnotazioniRischioIdraulicoAccordion6"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>

        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="descrizioneCommentiConclusiviCdAIdraulicaAccordion6"
            label={'Commenti conclusivi alla CdA idraulica'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      </GridWrap>
    </>
  );
};

export { Accordion6AnnotazioniPerReport };
