import { FieldType, MediaGroup } from 'constants/inspections';
import { DataTypeFileLimitMap } from 'constants/media';
import { formatData } from './utilities';

export const getFileSize = size => {
  if (size < 1000000) {
    return `${Math.ceil(size / 1000)}kb`;
  } else {
    return `${Math.ceil(size / 1000000)}mb`;
  }
};

export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : o => o[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

export const checkFileSizeLimit = file => {
  if (file.type.match(/image-*/)) {
    return file.size >= DataTypeFileLimitMap.image;
  } else if (file.type.match(/video-*/)) {
    return file.size >= DataTypeFileLimitMap.video;
  } else if (
    file.type.match(
      'application/msword|application/pdf|application/vnd.openxmlformats-officedocument.wordprocessingml.document|application/acad|image/vnd.dwg|image/x-dwg'
    ) ||
    file.name.endsWith('.dwg')
  ) {
    return file.size >= DataTypeFileLimitMap.document;
  } else {
    return file.size >= DataTypeFileLimitMap.file;
  }
};

export const checkFileTypeMatch = (dataType, file) => {
  const condition =
    dataType === 'image/*'
      ? file.type.match(/image-*/)
      : dataType === 'video/*,image/*'
      ? file.type.match(/image-*/) || file.type.match(/video-*/)
      : dataType === '.pdf,.doc,.docx,.jpeg,.png,.dwg'
      ? file.type.match(
          'image/jpeg|image/png|application/msword|application/pdf|application/vnd.openxmlformats-officedocument.wordprocessingml.document|application/acad|image/vnd.dwg|image/x-dwg'
        ) || file.name.endsWith('.dwg')
      : dataType === '.pdf,.doc,.docx,.jpeg,.png'
      ? file.type.match(
          'image/jpeg|image/png|application/msword|application/pdf|application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        )
      : dataType === '.jpeg,.png'
      ? file.type.match('image/jpeg|image/png')
      : dataType === '.pdf,.jpeg,.png'
      ? file.type.match('image/jpeg|image/png|application/pdf')
      : dataType === '.dwg'
      ? file.name.endsWith('.dwg')
      : file.type.match(/image-*/);

  return condition;
};

const MediaCodeMapper = {
  fotoCopertina: {
    isMultiple: false,
    code: 'Cont_Generale',
  },
  fotoInquadramento: {
    isMultiple: true,
    code: 'Cont_Generale',
  },
  fotoInquadramentoFrana: {
    isMultiple: true,
    code: 'Cont_Frane',
  },
  fotoAnnotazioniRischioFrane: {
    isMultiple: true,
    code: 'Cont_Frane',
  },
  fotoInquadramentoIdraulica: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoNaturaMaterialeAlveo: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoDivagazionePlanimetricaIdraulico: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoDepositoSedimenti: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoTrasportoMateriale: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoAbassamentoAlveo: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoProfonditaFondazioni: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoAccumuliMonteDellaPila: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoProtezioneSpondale: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoProtezionePileSpalle: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoBrigliaDiProtezione: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  fotoAnnotazioneRischioIdraulico: {
    isMultiple: true,
    code: 'Cont_Idraulica',
  },
  satellitareFotoAereeStoriche: {
    isMultiple: true,
    code: 'Cont_Esposizione',
  },
  satellitareFotoAereeAttuale: {
    isMultiple: true,
    code: 'Cont_Esposizione',
  },
  ripreseFotografiche: {
    isMultiple: true,
    code: 'Cont_Esposizione',
  },
  elCriticiVulnerabilitaStrutturaleFoto: {
    isMultiple: true,
    code: 'Strut_Elementi_critici',
  },
  elCriticiVulnerabilitaSismicaFoto: {
    isMultiple: true,
    code: 'Strut_Elementi_critici',
  },
  condizioniCriticheVulnerabilitaSismicaFoto: {
    isMultiple: true,
    code: 'Strut_Condizioni_critiche',
  },
  elementiVulnerabilitaSismicaFoto: {
    isMultiple: true,
    code: 'Strut_Vulnerabilità_sismica',
  },
  'spalleTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codiceSpalla',
  },
  'pileTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codicePila',
  },
  'spallePileCollegamentiTable[#idx#].appoggiTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codice',
  },
  'spallePileCollegamentiTable[#idx#].giuntiTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codice',
  },
  'spallePileCollegamentiTable[#idx#].antenneTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codice',
  },
  'spallePileCollegamentiTable[#idx#].dispositiviSismiciTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codice',
  },
  'mensoleTamponiCampateImpalcatoTable[#idx#].soletteTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codice',
  },
  'mensoleTamponiCampateImpalcatoTable[#idx#].traviTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codice',
  },
  'mensoleTamponiCampateImpalcatoTable[#idx#].controventiTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codice',
  },
  'mensoleTamponiCampateImpalcatoTable[#idx#].archiTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codice',
  },
  'mensoleTamponiCampateImpalcatoTable[#idx#].piedrittiTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codice',
  },
  'mensoleTamponiCampateImpalcatoTable[#idx#].tirantiTable[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    codeToInject: 'codice',
  },
  'mensoleTamponiCampateImpalcatoTable[#idx#].elementiExtraTable[#idx#].media':
    {
      toReplace: '.media',
      isMultiple: true,
      codeToInject: 'codice',
    },
  'spalleTable[#idx#].schedaDifetti[#idx#].media': {
    toReplace: '.media',
    isMultiple: true,
    isDefect: true,
    parentCode: 'codiceSpalla',
    codeToInject: 'codiceDifetto',
  },
};

export const createMediaCode = (formName, currEl, idx, getValues) => {
  if (MediaCodeMapper[formName]) {
    return `${MediaCodeMapper[formName].code}${
      MediaCodeMapper[formName].isMultiple ? `_${idx + 1}` : ''
    }`;
  }
  const regMatch = MediaCodeMapper[formName.replaceAll(/\[.*?\]/g, '[#idx#]')];
  if (regMatch) {
    const getCodeToInject = getValues(formName.replace(regMatch.toReplace, ''))[
      regMatch.codeToInject
    ];
    /*   if (regMatch.isDefect) {
      const getDefectCode = getValues(formName.replace(regMatch.toReplace, ''))[
        regMatch.codeToInject
      ];
      return `${getCodeToInject}_${}_${idx + 1}`;

    } */
    return `${getCodeToInject}_${idx + 1}`;
  }

  return '';
};

export const createMediaDate = (
  type,
  mediaGroup,
  idraulicaData,
  franaData,
  data
) => {
  if (type === FieldType.images) {
    if (Array.isArray(mediaGroup)) {
      if (mediaGroup?.includes(MediaGroup.Idraulica)) {
        return idraulicaData
          ? formatData(new Date(idraulicaData))
          : data
          ? formatData(new Date(data))
          : formatData(new Date());
      } else if (mediaGroup?.includes(MediaGroup.Frane)) {
        return franaData
          ? formatData(new Date(franaData))
          : data
          ? formatData(new Date(data))
          : formatData(new Date());
      } else {
        return data ? formatData(new Date(data)) : formatData(new Date());
      }
    } else {
      if (mediaGroup === MediaGroup.Idraulica) {
        return idraulicaData
          ? formatData(new Date(idraulicaData))
          : data
          ? formatData(new Date(data))
          : formatData(new Date());
      } else if (mediaGroup === MediaGroup.Frane) {
        return franaData
          ? formatData(new Date(franaData))
          : data
          ? formatData(new Date(data))
          : formatData(new Date());
      } else {
        return data ? formatData(new Date(data)) : formatData(new Date());
      }
    }
  } else {
    return data ? formatData(new Date(data)) : formatData(new Date());
  }
};

export const createMediaUserName = (
  type,
  mediaGroup,
  ispettoreIdraulica,
  ispettoreFrane,
  ispettore,
  userName
) => {
  if (type === FieldType.images) {
    if (Array.isArray(mediaGroup)) {
      if (mediaGroup?.includes(MediaGroup.Idraulica)) {
        return ispettoreIdraulica;
      } else if (mediaGroup?.includes(MediaGroup.Frane)) {
        return ispettoreFrane;
      } else {
        return ispettore;
      }
    } else {
      if (mediaGroup === MediaGroup.Idraulica) {
        return ispettoreIdraulica;
      } else if (mediaGroup === MediaGroup.Frane) {
        return ispettoreFrane;
      } else {
        return ispettore;
      }
    }
  } else {
    return userName;
  }
};
