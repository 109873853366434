import { useFormContext } from 'react-hook-form';
import { calculateDifettosita } from './calculateDifettosita';

const calculateDifettositaSottostruttura = () => {
  const { getValues } = useFormContext();

  const calculateSottostrutturaDifettositaStrutturale = spallaPila => {
    const spallePileTable = spallaPila.codice.startsWith('S')
      ? getValues('spalleTable')
      : getValues('pileTable');

    const found = spallePileTable.find(el =>
      spallaPila.codice.startsWith('S')
        ? el.codiceSpalla === spallaPila.codice
        : el.codicePila === spallaPila.codice
    );
    if (
      !found?.CDAstrutturale ||
      spallaPila.antenneTable.some(el => !el.CDAstrutturale) ||
      spallaPila.appoggiTable.some(el => !el.CDAstrutturale) ||
      spallaPila.giuntiTable.some(el => !el.CDAstrutturale) ||
      spallaPila.pulviniTable.some(el => !el.CDAstrutturale)
      /* spallaPila.dispositiviSismiciTable.some(el => !el.CDAstrutturale) */
    ) {
      return null;
    } else {
      return calculateDifettosita(
        [
          found,
          ...spallaPila.antenneTable,
          ...spallaPila.appoggiTable,
          ...spallaPila.giuntiTable,
          ...spallaPila.pulviniTable,
          /* ...spallaPila.dispositiviSismiciTable, */
        ],
        'strutturale'
      );
    }
  };
  const calculateSottostrutturaDifettositaSismica = spallaPila => {
    const spallePileTable = spallaPila.codice.startsWith('S')
      ? getValues('spalleTable')
      : getValues('pileTable');
    const found = spallePileTable.find(el =>
      spallaPila.codice.startsWith('S')
        ? el.codiceSpalla === spallaPila.codice
        : el.codicePila === spallaPila.codice
    );
    if (
      !found?.CDAsismica ||
      spallaPila.antenneTable.some(el => !el.CDAsismica) ||
      spallaPila.appoggiTable.some(el => !el.CDAsismica) ||
      spallaPila.giuntiTable.some(el => !el.CDAsismica) ||
      spallaPila.pulviniTable.some(el => !el.CDAsismica)
      /* spallaPila.dispositiviSismiciTable.some(el => !el.CDAsismica) */
    ) {
      return null;
    } else {
      return calculateDifettosita(
        [
          found,
          ...spallaPila.antenneTable,
          ...spallaPila.appoggiTable,
          ...spallaPila.giuntiTable,
          ...spallaPila.pulviniTable,
          /* ...spallaPila.dispositiviSismiciTable, */
        ],
        'sismica'
      );
    }
  };

  return {
    calculateSottostrutturaDifettositaStrutturale,
    calculateSottostrutturaDifettositaSismica,
  };
};
export default calculateDifettositaSottostruttura;
