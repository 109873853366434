export const SubscriptionStatus = {
  ND: 1,
  ATTIVA: 2,
  SCADUTA: 3,
};

export const SubscriptionStatusMap = {
  [SubscriptionStatus.ND]: 'n.d.',
  [SubscriptionStatus.ATTIVA]: 'Attiva',
  [SubscriptionStatus.SCADUTA]: 'Scaduta',
};
