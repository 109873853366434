export const VoiceMenuMap = {
  '/': 1,
  structures: 2,
  inspections: 3,
  images: 4,
  documents: 5,
};

export const VoiceMenuAdminMap = {
  dashboard: 1,
  users: 2,
  analytics: 3,
};
