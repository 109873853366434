import styled from '@emotion/styled';

export const CardWhiteBox = styled('div')({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 8,
  padding: 16,
});

export const CardHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  marginBottom: 5,
  marginLeft: -3,
});

export const CardBody = styled('div')({
  display: 'flex',
  gap: 50,
});
