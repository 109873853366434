import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiStrutturaliL1SectionWrapper,
  InspectionInfoForm,
} from 'components';
import { CDAOptions, tipologiaSolettaOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import SoletteForm from './SoletteForm';
const SoletteSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const mensoleTamponiCampateImpalcatoTableSoletteTableWatch = watch(
    `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable`
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsSolette = item => {
    setValue('soletteTableValue', [item]);
    setValue('tipologiaSolettaTableValue', item.tipologiaSoletta);
  };

  const handleSelectAllClickSolette = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable`
      );
      setSelected(selectedValue);
      setValue('soletteTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('soletteTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t2a4_tecnicoRilevatoreSolette',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t2a4_dataIspezioneSolette',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t2a4_elementoCriticoStrutturaSolette',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].elementoCriticoStruttura`
      ) || ''
    );
    setValue(
      'l1t2a4_elementoCriticoSismicaSolette',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].elementoCriticoSismica`
      ) || ''
    );
    setValue(
      'l1t2a4_condizioneCriticaStrutturaSolette',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].condizioneCriticaStruttura`
      ) || ''
    );
    setValue(
      'l1t2a4_condizioneCriticaSismicaSolette',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].condizioneCriticaSismica`
      ) || ''
    );
    setValue(
      'l1t2a4_noteGeneraliSolette',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t2a4_tecnicoRilevatoreSolette');
    const dataIspezione = getValues('l1t2a4_dataIspezioneSolette');
    const elementoCriticoStruttura = getValues(
      'l1t2a4_elementoCriticoStrutturaSolette'
    );
    const elementoCriticoSismica = getValues(
      'l1t2a4_elementoCriticoSismicaSolette'
    );
    const condizioneCriticaStruttura = getValues(
      'l1t2a4_condizioneCriticaStrutturaSolette'
    );
    const condizioneCriticaSismica = getValues(
      'l1t2a4_condizioneCriticaSismicaSolette'
    );
    const noteGenerali = getValues('l1t2a4_noteGeneraliSolette');
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].elementoCriticoStruttura`,
      elementoCriticoStruttura
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].elementoCriticoSismica`,
      elementoCriticoSismica
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].condizioneCriticaStruttura`,
      condizioneCriticaStruttura
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].condizioneCriticaSismica`,
      condizioneCriticaSismica
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
    setValue('l1t2a5_trigger', Math.random() * 1000);
  };

  const handleResetInspection = () => {
    setValue(`l1t2a4_tecnicoRilevatoreSolette`, '');
    setValue(`l1t2a4_dataIspezioneSolette`, null);
    setValue(`l1t2a4_elementoCriticoStrutturaSolette`, '');
    setValue(`l1t2a4_elementoCriticoSismicaSolette`, '');
    setValue(`l1t2a4_condizioneCriticaStrutturaSolette`, '');
    setValue(`l1t2a4_condizioneCriticaSismicaSolette`, '');
    setValue(`l1t2a4_noteGeneraliSolette`, '');
  };

  const dataSoletteConfig = {
    columns: {
      codice: { label: 'Cod.' },
      tipologiaSoletta: { label: 'Tipologia', format: tipologiaSolettaOptions },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      indiceCondizione: { label: 'Indice di condizione' },
      CDAstrutturale: { label: 'Difettosità Str.', format: CDAOptions },
      CDAsismica: { label: 'Difettosità Sis.', format: CDAOptions },
      nrtotali: { label: 'NR' },
      g5totali: { label: 'G=5' },
      pstotali: { label: 'PS' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaSoletta: {
        label: 'Tipologia',
        format: tipologiaSolettaOptions,
      },
    },
  };

  const mensoleTamponiCampateImpalcatoSelezione = watch(
    'mensoleTamponiCampateImpalcatoSelezione'
  );

  useEffect(() => {
    if (mensoleTamponiCampateImpalcatoSelezione) {
      setSchedaDifettiIndex(undefined);
      setOpenSchedaDifetti(false);
    }
  }, [mensoleTamponiCampateImpalcatoSelezione]);

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiStrutturaliL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'solette'}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoSolette.drawerTitle}
        drawerText={INFO.impalcatoSolette.drawerText}
        singleElementTitle={'soletta'}
        tableWatch={mensoleTamponiCampateImpalcatoTableSoletteTableWatch}
        tableWatchName={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable`}
        elementForm={
          <SoletteForm
            soletteFields={
              mensoleTamponiCampateImpalcatoTableSoletteTableWatch || []
            }
            selected={selected}
            setSelected={setSelected}
            soletteTable={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].soletteTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoForm
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t2a4_tecnicoRilevatoreSolette'}
            dateName={'l1t2a4_dataIspezioneSolette'}
            elementoCriticoStrutturaName={
              'l1t2a4_elementoCriticoStrutturaSolette'
            }
            elementoCriticoSismicaName={'l1t2a4_elementoCriticoSismicaSolette'}
            condizioneCriticaStrutturaName={
              'l1t2a4_condizioneCriticaStrutturaSolette'
            }
            condizioneCriticaSismicaName={
              'l1t2a4_condizioneCriticaSismicaSolette'
            }
            noteGeneraliName={'l1t2a4_noteGeneraliSolette'}
          />
        }
        tableConfig={dataSoletteConfig}
        editRows={editRowsSolette}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickSolette}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaSoletta'}
      />
    </>
  );
};
SoletteSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default SoletteSection;
