import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { VideoContainer } from './VideoTutorialBox.styles';

const VideoTutorialBox = ({ title, youtubeId, description, bgColor }) => {
  return (
    <VideoContainer bgColor={bgColor}>
      <Typography variant="h2" fontSize={'16px'} mb={2}>
        {title}
      </Typography>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
        allowfullscreen
      ></iframe>
      <Typography variant="p">{description}</Typography>
    </VideoContainer>
  );
};

VideoTutorialBox.propTypes = {
  title: PropTypes.string,
  bgColor: PropTypes.string,
  youtubeId: PropTypes.string,
  description: PropTypes.string,
};

export { VideoTutorialBox };
