import { useCallback } from 'react';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { STORE_PREFIX } from 'constants/globals';

const useStore = create(
  persist(
    set => ({
      schedaDifettiTrigger: null,
      setSchedaDifettiTrigger: value =>
        set(() => ({ schedaDifettiTrigger: value })),
      inspectionLevelTab: null,
      setInspectionLevelTab: newValue =>
        set(() => ({ inspectionLevelTab: newValue })),
      inspectionLevelSubTab: null,
      setInspectionLevelSubTab: newValue =>
        set(() => ({ inspectionLevelSubTab: newValue })),
      inspectionLevelSubTabAccordion: null,
      setInspectionLevelSubTabAccordion: newValue =>
        set(() => ({ inspectionLevelSubTabAccordion: newValue })),
      inspectionDisabled: null,
      setInspectionDisabled: newValue =>
        set(() => ({ inspectionDisabled: newValue })),
    }),

    {
      name: `${STORE_PREFIX}inspection`,
      getStorage: () => sessionStorage,
      partialize: state =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              ![
                'schedaDifettiTrigger',
                'inspectionDisabled',
                'inspectionLevelTab',
                'inspectionLevelSubTab',
                'inspectionLevelSubTabAccordion',
              ].includes(key)
          )
        ),
    }
  )
);

const useInspectionSelector = () => useStore(useCallback(state => state, []));

export { useInspectionSelector, useStore as useInspectionStore };
