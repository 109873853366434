import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { colors } from 'constants/colors';

export const HeaderWrapper = styled('div')({
  display: 'flex',
});

export const ButtonWrapper = styled(Button)({
  margin: '5px',
});

export const WhiteContainer = styled('div')({
  width: '100%',
  borderRadius: 8,
  backgroundColor: colors.WHITE,
  padding: 24,
  marginTop: -50,
});
