import { Typography } from '@mui/material';
import Pdf from 'assets/icons/file-pdf.svg';
import ViewBtn from 'assets/icons/icon-button-view.svg';
import { HeaderPage } from 'components';
import { INFO_ASSISTANCE_PATHS } from 'constants/paths';
import { downloadFile } from 'utils/upload';
import { inBeeGuides, legislationFiles } from './constants';
import {
  Container,
  FileContainer,
  IconShowPdf,
  InfoFlex,
  WhiteContainer,
} from './GuideApprofondimenti.styles';

const GuideApprofondimenti = () => {
  return (
    <>
      <HeaderPage
        back={INFO_ASSISTANCE_PATHS.ROOT}
        title={'Guide e Approfondimenti'}
      />
      <Container>
        <WhiteContainer>
          <Typography variant="h3Bold" fontSize={'18px'}>
            Normative
          </Typography>
          {legislationFiles.map((file, idx) => (
            <FileContainer key={idx}>
              <InfoFlex>
                <img src={Pdf} width={18} />
                <Typography variant="body" fontSize={'16px'}>
                  {file.title}
                </Typography>
              </InfoFlex>
              <InfoFlex>
                <Typography variant="body">{file.size}</Typography>
                <IconShowPdf
                  src={ViewBtn}
                  onClick={() => downloadFile(file.path)}
                />
              </InfoFlex>
            </FileContainer>
          ))}
        </WhiteContainer>

        <WhiteContainer>
          <Typography variant="h3Bold" fontSize={'18px'}>
            Guide InBee
          </Typography>
          {inBeeGuides.map((file, idx) => (
            <FileContainer key={idx}>
              <InfoFlex>
                <img src={Pdf} width={18} />
                <Typography variant="body" fontSize={'16px'}>
                  {file.title}
                </Typography>
              </InfoFlex>
              <InfoFlex>
                <Typography variant="body">{file.size}</Typography>
                <IconShowPdf
                  src={ViewBtn}
                  onClick={() => downloadFile(file.path)}
                />
              </InfoFlex>
            </FileContainer>
          ))}
        </WhiteContainer>
      </Container>
    </>
  );
};

export default GuideApprofondimenti;
