import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import { tipologiaElementoExtraOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
const ElementiExtraForm = ({
  elementiExtraFields,
  selected,
  setSelected,
  elementiExtraTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t2a5_elementiExtraTableValue: [],
      l0t2a5_nomeElementoExtraTableValue: '',
      l0t2a5_tipologiaElementoExtraTableValue: '',
      l0t2a5_altezzaElementoExtraTableValue: '',
      l0t2a5_larghezzaElementoExtraTableValue: '',
      l0t2a5_profonditaElementoExtraTableValue: '',
      l0t2a5_superficieElementoExtraTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a5_elementiExtraTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetElementiExtra();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeElementiExtra = () => {
    const l0t2a5_nomeElementoExtraTableValue = getValues(
      'l0t2a5_nomeElementoExtraTableValue'
    );
    const l0t2a5_tipologiaElementoExtraTableValue = getValues(
      'l0t2a5_tipologiaElementoExtraTableValue'
    );
    const l0t2a5_altezzaElementoExtraTableValue = getValues(
      'l0t2a5_altezzaElementoExtraTableValue'
    );
    const l0t2a5_larghezzaElementoExtraTableValue = getValues(
      'l0t2a5_larghezzaElementoExtraTableValue'
    );
    const l0t2a5_profonditaElementoExtraTableValue = getValues(
      'l0t2a5_profonditaElementoExtraTableValue'
    );
    const l0t2a5_superficieElementoExtraTableValue = getValues(
      'l0t2a5_superficieElementoExtraTableValue'
    );

    const ids = selected.map(el => el.codice);

    const elementiExtra = [...elementiExtraFields];

    elementiExtra.map((x, index) => {
      if (ids.includes(x?.codice)) {
        elementiExtra[index] = {
          ...x,
          nomeElementoExtra: l0t2a5_nomeElementoExtraTableValue,
          tipologiaElementoExtra: l0t2a5_tipologiaElementoExtraTableValue,
          altezzaElementoExtra: l0t2a5_altezzaElementoExtraTableValue,
          larghezzaElementoExtra: l0t2a5_larghezzaElementoExtraTableValue,
          profonditaElementoExtra: l0t2a5_profonditaElementoExtraTableValue,
          superficieElementoExtra: l0t2a5_superficieElementoExtraTableValue,
          ...(l0t2a5_tipologiaElementoExtraTableValue !==
          x.tipologiaElementoExtra
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'elementoExtra',
                  l0t2a5_tipologiaElementoExtraTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(elementiExtraTable, elementiExtra);
    onResetElementiExtra();
  };

  const onConfirmChangeElementiExtra = () => {
    setOpenConfirmationDialog(false);
    changeElementiExtra();
  };

  const onSubmitElementiExtra = () => {
    const l0t2a5_tipologiaElementoExtraTableValue = getValues(
      'l0t2a5_tipologiaElementoExtraTableValue'
    );

    const ids = selected.map(el => el.codice);

    const elementiExtra = [...elementiExtraFields];

    const hasChangedTipologia = elementiExtra.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a5_tipologiaElementoExtraTableValue !==
            el.tipologiaElementoExtra &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaElementoExtra)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeElementiExtra();
    }
  };

  const onResetElementiExtra = () => {
    setValue('l0t2a5_elementiExtraTableValue', []);
    setValue('l0t2a5_nomeElementoExtraTableValue', '');
    setValue('l0t2a5_tipologiaElementoExtraTableValue', '');
    setValue('l0t2a5_altezzaElementoExtraTableValue', '');
    setValue('l0t2a5_larghezzaElementoExtraTableValue', '');
    setValue('l0t2a5_profonditaElementoExtraTableValue', '');
    setValue('l0t2a5_superficieElementoExtraTableValue', '');

    setSelected([]);
  };

  const altezzaElementoExtraWatch = parseFloat(
    watch('l0t2a5_altezzaElementoExtraTableValue')
  );
  const larghezzaElementoExtraWatch = parseFloat(
    watch('l0t2a5_larghezzaElementoExtraTableValue')
  );
  const profonditaElementoExtraWatch = parseFloat(
    watch('l0t2a5_profonditaElementoExtraTableValue')
  );

  // [2*(Profondità+Larghezza)*Altezza]/10000

  useEffect(() => {
    const result = (
      (2 *
        (profonditaElementoExtraWatch + larghezzaElementoExtraWatch) *
        altezzaElementoExtraWatch) /
      10000
    ).toFixed(2);
    setValue('l0t2a5_superficieElementoExtraTableValue', result);
  }, [
    altezzaElementoExtraWatch,
    larghezzaElementoExtraWatch,
    profonditaElementoExtraWatch,
  ]);

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper
          size={6}
          infoMessage={INFO.elementiExtraSelezionati.infoMessage}
        >
          <RHFTagAutocomplete
            multiple={true}
            id="tags-elementiExtra"
            name={'l0t2a5_elementiExtraTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={elementiExtraFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Elementi Extra selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFTextField
            name="l0t2a5_nomeElementoExtraTableValue"
            label={'Nome elemento*'}
            defaultValue=""
            inputProps={{ maxLength: 50 }}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_tipologiaElementoExtraTableValue"
            label="Tipologia Elemento*"
            defaultValue={''}
            onChange={e => {
              setValue(
                'l0t2a5_tipologiaElementoExtraTableValue',
                e.target.value
              );
            }}
            selectOptions={tipologiaElementoExtraOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_altezzaElementoExtraTableValue"
            label={'Altezza (H) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_larghezzaElementoExtraTableValue"
            label={'Larghezza (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_profonditaElementoExtraTableValue"
            label={'Profondità (P) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <InfoWrapper
          size={2}
          infoMessage={INFO.superficieElementoExtra.infoMessage}
        >
          <RHFNumberTextField
            name="l0t2a5_superficieElementoExtraTableValue"
            label={'Superficie [m²]'}
            defaultValue=""
            decimalScale={2}
            disabled
            InputLabelProps={{ shrink: true }}
            withValueLimit={({ value }) => value < 1000}
          />
        </InfoWrapper>
        <GridWrap item xs={1}>
          <FormSubmitButtons
            onSubmit={onSubmitElementiExtra}
            onReset={onResetElementiExtra}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeElementiExtra}
      />
    </>
  );
};
ElementiExtraForm.propTypes = {
  elementiExtraFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      nomeElementoExtra: PropTypes.string,
      tipologiaElementoExtra: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      altezzaElementoExtra: PropTypes.number,
      larghezzaElementoExtra: PropTypes.number,
      profonditaElementoExtra: PropTypes.number,
      superficieElementoExtra: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  elementiExtraTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default ElementiExtraForm;
