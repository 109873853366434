import { Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import Check from 'assets/icons/check-white.svg';
import Cancel from 'assets/icons/close-black.svg';
import { RHFMultiSelect, RHFSelect, RHFTextField } from 'components';
import {
  convogliamentoAcque,
  tipologiaConvogliamentoAcque,
} from 'constants/inspections';
import {
  convogliamentoAcqueOptions,
  tipologiaConvogliamentoAcqueOptions,
} from 'constants/selectOptions';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import { Buttons } from './WaterConveyanceForm.styles';

const WaterConveyanceForm = ({ waterFields }) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      convogliamentoAcque: '',
      tipologiaConvogliamentoAcque: '',
      tipologiaAltro: '',
    },
  });
  const { populateScheda20Difetti } = useSchedaDifetti();

  const watchConvogliamentoAcque = watch('convogliamentoAcqueTableValue');
  const watchAcquePresente =
    watchConvogliamentoAcque === convogliamentoAcque.Presente;

  const watchTipologiaConvogliamento = watch(
    'tipologiaConvogliamentoAcqueTableValue'
  );

  const watchTipologiaAltro =
    watchTipologiaConvogliamento !== ''
      ? watchTipologiaConvogliamento?.some(
          el => el === tipologiaConvogliamentoAcque.Altro
        )
      : false;

  useEffect(() => {
    !watchTipologiaAltro && setValue('convogliamentoAcqueAltro', '');
  }, [watchTipologiaConvogliamento]);

  const onSubmitAcque = () => {
    const convogliamentoAcqueTableValue = getValues(
      'convogliamentoAcqueTableValue'
    );

    if (isNullOrUndefinedOrEmptyString(convogliamentoAcqueTableValue)) {
      return setValue('convogliamentoAcqueTable', []);
    }
    const tipologiaConvogliamentoAcqueTableValue = getValues(
      'tipologiaConvogliamentoAcqueTableValue'
    );

    const tipologiaAltroTableValue = getValues('convogliamentoAcqueAltro');

    const schedaDifettiConvogliamentoAcque = populateScheda20Difetti(
      'convogliamentoAcque',
      convogliamentoAcqueTableValue
    ).map(el => {
      return {
        ...el,
        visto:
          convogliamentoAcqueTableValue === convogliamentoAcque.Assente
            ? true
            : false,
        presente:
          convogliamentoAcqueTableValue === convogliamentoAcque.Assente
            ? true
            : false,
        statoDifetto: null,
        note: '',
        media: [],
      };
    });

    if (waterFields.length > 0) {
      const acqueTable = [...waterFields];
      acqueTable.map((x, index) => {
        acqueTable[index] = {
          ...x,
          convogliamentoAcque: convogliamentoAcqueTableValue,
          tipologiaConvogliamentoAcque: tipologiaConvogliamentoAcqueTableValue,
          tipologiaAltro: tipologiaAltroTableValue,
          assente:
            convogliamentoAcqueTableValue === convogliamentoAcque.Assente
              ? true
              : undefined,
          presenza: convogliamentoAcqueTableValue,
          ...(convogliamentoAcqueTableValue !== x.paliIlluminazione
            ? {
                schedaDifetti: schedaDifettiConvogliamentoAcque,
              }
            : null),
        };
      });
      setValue('convogliamentoAcqueTable', acqueTable);
    } else {
      setValue('convogliamentoAcqueTable', [
        {
          id: uuidv4(),
          convogliamentoAcque: convogliamentoAcqueTableValue,
          tipologiaConvogliamentoAcque: tipologiaConvogliamentoAcqueTableValue,
          tipologiaAltro: tipologiaAltroTableValue,
          assente:
            convogliamentoAcqueTableValue === convogliamentoAcque.Assente
              ? true
              : undefined,
          presenza: convogliamentoAcqueTableValue,
          schedaDifetti: schedaDifettiConvogliamentoAcque,
        },
      ]);
    }
    onResetConvogliamentoAcque();
  };

  const onResetConvogliamentoAcque = () => {
    setValue('convogliamentoAcqueTableValue', '');
    setValue('tipologiaConvogliamentoAcqueTableValue', '');
    setValue('convogliamentoAcqueAltro', '');
  };

  useEffect(() => {
    if (!watchAcquePresente) {
      setValue('tipologiaConvogliamentoAcqueTableValue', '');
      setValue('convogliamentoAcqueAltro', '');
    }
  }, [watchAcquePresente]);

  return (
    <Grid container spacing={2} padding="20px">
      <Grid item xs={3}>
        <RHFSelect
          name="convogliamentoAcqueTableValue"
          label="Convogliamento acque*"
          defaultValue={''}
          onChange={e => {
            setValue('convogliamentoAcqueTableValue', e.target.value);
          }}
          selectOptions={convogliamentoAcqueOptions}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFMultiSelect
          name="tipologiaConvogliamentoAcqueTableValue"
          label="Tipologia convogliamento acque"
          defaultValue={[]}
          disabled={!watchAcquePresente}
          onChange={e => {
            setValue('tipologiaConvogliamentoAcqueTableValue', e.target.value);
          }}
          selectOptions={tipologiaConvogliamentoAcqueOptions}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFTextField
          name="convogliamentoAcqueAltro"
          label={'Specificare altro'}
          inputProps={{ maxLength: 100 }}
          defaultValue=""
          disabled={!watchTipologiaAltro}
          onChange={e => {
            setValue('convogliamentoAcqueAltro', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={2}></Grid>

      <Grid item xs={1}>
        <Buttons>
          <IconButton color="primary" onClick={onSubmitAcque}>
            <img src={Check} alt="" />
          </IconButton>
          <IconButton color="primary" onClick={onResetConvogliamentoAcque}>
            <img src={Cancel} alt="" />
          </IconButton>
        </Buttons>
      </Grid>
    </Grid>
  );
};
export { WaterConveyanceForm };

WaterConveyanceForm.propTypes = {
  waterFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      convogliamentoAcqueTableValue: PropTypes.string,
      tipologiaPaliTableValue: PropTypes.string,
      convogliamentoAcqueAltro: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};
