import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { InfoWrapper, MediaUpload, RHFSelect } from 'components';
import {
  FieldType,
  MediaGroup,
  NecessitaIspezioneSpecialeRischioFrane,
  PericolositaPAIPSAIFrane,
  RischioFrana,
  RischioPresente,
  RisultatiIspezioniSpecialiFrane,
  UploadMedia,
} from 'constants/inspections';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap, TitleSection } from './Accordion4LandslideRisk.styles';
import { INFO } from '../../../../config/info';

const Accordion4LandslideRisk = () => {
  const { setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchRischioFrana = watch('rischioFrana');
  const watchRischioFranaAssente = watchRischioFrana === RischioFrana.Assente;

  const watchRischioFranaDaVerificare =
    watchRischioFrana === RischioFrana.DaVerificare;
  const watchRischioFranaPresente = watchRischioFrana === RischioFrana.Presente;

  const watchNecessiaIspezioniSpecialiRischioFrane = watch(
    'necessitaIspezioniSpecialiRischioFrane'
  );
  const watchYesNecessitaIspezioniSpeciali =
    watchNecessiaIspezioniSpecialiRischioFrane ===
    NecessitaIspezioneSpecialeRischioFrane.Si;

  //Functions
  useEffect(() => {
    if (watchRischioFranaAssente) {
      setValue('rischioPresente', '');
      setValue('necessitaIspezioniSpecialiRischioFrane', '');
      setValue('risultatiIspezioniSpecialiFrane', '');
      setValue('pericolositaPAIPSAIFrane', '');
    }
  }, [watchRischioFrana]);

  useEffect(() => {
    if (!watchYesNecessitaIspezioniSpeciali) {
      setValue('risultatiIspezioniSpecialiFrane', '');
    }
  }, [watchYesNecessitaIspezioniSpeciali]);

  return (
    <GridWrap container spacing={2} padding="20px">
      <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
        <TitleSection>
          <Typography variant="h3BoldBlue">DATI GENERALI</Typography>
        </TitleSection>
      </GridWrap>
      <InfoWrapper
        size={3}
        infoMessage={INFO.rischioFrana.infoMessage}
        linkMessage={INFO.rischioFrana.linkMessage}
      >
        <RHFSelect
          name="rischioFrana"
          label={'Rischio frana*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'Da verificare',
              value: RischioFrana.DaVerificare,
            },
            {
              label: 'Assente',
              value: RischioFrana.Assente,
            },
            {
              label: 'Presente',
              value: RischioFrana.Presente,
            },
          ]}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoMessage={INFO.tipologiaInterferenza.infoMessage}
        legislationMessage={INFO.tipologiaInterferenza.legislationMessage}
      >
        <RHFSelect
          name="rischioPresente"
          label={'Tipologia di interferenza'}
          defaultValue={''}
          disabled={
            (!watchRischioFranaDaVerificare && !watchRischioFranaPresente) ||
            inspectionDisabled ||
            isViewingInspection
          }
          selectOptions={[
            {
              label: 'Diretta',
              value: RischioPresente.Diretto,
            },
            {
              label: 'Indiretta',
              value: RischioPresente.Indiretto,
            },
          ]}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.necessitaIspezioniSpecialiRischioFrane.drawerTitle}
        drawerText={INFO.necessitaIspezioniSpecialiRischioFrane.drawerText}
        compassMessage={
          INFO.necessitaIspezioniSpecialiRischioFrane.compassMessage
        }
      >
        <RHFSelect
          name="necessitaIspezioniSpecialiRischioFrane"
          label={'Necessità di ispezioni speciali (rischio frane)*'}
          defaultValue={''}
          disabled={
            (!watchRischioFranaDaVerificare && !watchRischioFranaPresente) ||
            inspectionDisabled ||
            isViewingInspection
          }
          selectOptions={[
            {
              label: 'Sì',
              value: NecessitaIspezioneSpecialeRischioFrane.Si,
            },
            {
              label: 'No',
              value: NecessitaIspezioneSpecialeRischioFrane.No,
            },
          ]}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        compassMessage={INFO.risultatiIspezioniSpecialiFrane.compassMessage}
        drawerTitle={INFO.risultatiIspezioniSpecialiFrane.drawerTitle}
        drawerText={INFO.risultatiIspezioniSpecialiFrane.drawerText}
      >
        <RHFSelect
          name="risultatiIspezioniSpecialiFrane"
          label={'Risultati ispezioni speciali*'}
          defaultValue={''}
          disabled={
            watchRischioFranaAssente ||
            !watchYesNecessitaIspezioniSpeciali ||
            inspectionDisabled ||
            isViewingInspection
          }
          selectOptions={[
            {
              label: 'Necessità di valutazioni di Livello 4',
              value:
                RisultatiIspezioniSpecialiFrane.NecessitaValutazioneLivello4,
            },
            {
              label:
                'Ispezione speciale non esaustiva. Necessità di valutazioni di Livello 4',
              value:
                RisultatiIspezioniSpecialiFrane.IspezioneSpecialeNonEsausitva,
            },
            {
              label:
                'Necessità di ispezioni di Livello 1 e classificazione Livello 2',
              value:
                RisultatiIspezioniSpecialiFrane.NecessitaIspezioneLivello1eClassificazioneLivello2,
            },
            {
              label: ' Ispezione speciale ancora da effettuare',
              value:
                RisultatiIspezioniSpecialiFrane.IspezioneSpecialeAncoraDaEffettuare,
            },
          ]}
        />
      </InfoWrapper>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Documentazione ispezioni speciali (rischio frane)`}
          btnTitle="Allega file"
          dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
          formName="documentazioneIspezioniSpeciali"
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.Frane]}
          tags={['indagini', 'laboratorio', 'ispettore', 'prove']}
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoMessage={INFO.pericolositaPAIPSAIFrane.infoMessage}
      >
        <RHFSelect
          name="pericolositaPAIPSAIFrane"
          label={'Pericolosità PAI/PSAI - Frane'}
          defaultValue={''}
          disabled={
            (!watchRischioFranaDaVerificare && !watchRischioFranaPresente) ||
            inspectionDisabled ||
            isViewingInspection
          }
          selectOptions={[
            {
              label: 'AA: Aree di attenzione',
              value: PericolositaPAIPSAIFrane.AA,
            },
            {
              label: 'P1: Aree a pericolosità da frana moderata',
              value: PericolositaPAIPSAIFrane.P1,
            },
            {
              label: 'P2: Aree a pericolosità da frana media',
              value: PericolositaPAIPSAIFrane.P2,
            },
            {
              label: 'P3: Aree a pericolosità da frana elevata',
              value: PericolositaPAIPSAIFrane.P3,
            },
            {
              label: 'P4: Aree a pericolosità da frana molto elevata',
              value: PericolositaPAIPSAIFrane.P4,
            },
            {
              label: 'Area non mappata',
              value: PericolositaPAIPSAIFrane.AreaNonMappata,
            },
          ]}
        />
      </InfoWrapper>

      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.stralcioMappaPericolositàFranePAI.drawerTitle}
        drawerText={INFO.stralcioMappaPericolositàFranePAI.drawerText}
        drawerImages={INFO.stralcioMappaPericolositàFranePAI.drawerImages}
      >
        <MediaUpload
          title={`Stralcio mappa pericolosità frane PAI`}
          btnTitle="Allega file"
          formName="stralcioMappaPericolositàFranePAI"
          dataType=".jpeg,.png"
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.Frane]}
        />
      </InfoWrapper>

      <GridWrap item md={3}>
        <MediaUpload
          title={`Documenti inerenti le condizioni di rischio frane`}
          btnTitle="Allega file"
          formName="documentiInerentiCondizioniRischioFrane"
          dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.Frane]}
        />
      </GridWrap>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Foto di inquadramento frana`}
          btnTitle="Allega file"
          formName="fotoInquadramentoFrana"
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          dataType="video/*,image/*"
          preview={true}
          uploadType={UploadMedia.media}
          type={FieldType.images}
          mediaGroup={[MediaGroup.Contesto, MediaGroup.Frane]}
          tags={['Misure di mitigazione']}
        />
      </GridWrap>
    </GridWrap>
  );
};

export { Accordion4LandslideRisk };
