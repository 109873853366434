import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { useFormContext } from 'react-hook-form';

import {
  InfoWrapper,
  MapWrapper,
  RHFApiAutocomplete,
  RHFNumberTextField,
  RHFSelect,
  RHFTextField,
} from 'components';

import { apiGeo } from 'constants/api';
import {
  EllissoideDiRiferimento,
  SismicitaArea,
  SismicitiaAreaValApprossimativo,
  SistemaDiRiferimento,
} from 'constants/inspections';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap, TitleSection } from './Accordion3Location.styles';
import { INFO } from '../../../../config/info';

const Accordion3Location = () => {
  // hooks
  const { getValues, setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  //State
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [centerIniziale, setCenterIniziale] = useState({ lat: 0, lng: 0 });
  const [centerFinale, setCenterFinale] = useState({ lat: 0, lng: 0 });

  //Const
  const watchCapCode = watch('capCode');
  const watchSismicitaArea = watch('sismicitaArea');
  const watchSismicitaAreaValEffettivo =
    watchSismicitaArea === SismicitaArea.ValoreEffettivo;
  const watchSismicitaAreaValApprossimativo =
    watchSismicitaArea === SismicitaArea.ValoreApprossimativo;

  const watchSistemaDiRiferimento = watch('sistemaDiRiferimento');
  const watchSistemaDiRiferimentoAltro =
    watchSistemaDiRiferimento === SistemaDiRiferimento.Altro;

  const watchEllissoideDiRiferimento = watch('ellissoideDiRiferimento');
  const watchEllissoideDiRiferimentoAltro =
    watchEllissoideDiRiferimento === EllissoideDiRiferimento.Altro;

  useEffect(() => {
    if (watchCapCode) {
      const valClassificazioneSismica =
        getValues('capCode').value.classificazioneSismica;
      setValue('classificazioneSismica', valClassificazioneSismica);
    }
  }, [watchCapCode]);

  useEffect(() => {
    !watchSismicitaAreaValEffettivo && setValue('sismicitaValoreEffettivo', '');

    const valoreEffetticoSismico = getValues('sismicitaValoreEffettivo');

    if (watchSismicitaAreaValApprossimativo) {
      setValue('sismicitaValoreEffettivo', '');
    }

    if (watchSismicitaAreaValEffettivo && !valoreEffetticoSismico) {
      setValue('sismicitaValoreApprossimativo', '');
    }

    if (valoreEffetticoSismico) {
      if (valoreEffetticoSismico >= 0.25) {
        setValue(
          'sismicitaValoreApprossimativo',
          SismicitiaAreaValApprossimativo.agMaggiore25
        );
      }
      if (valoreEffetticoSismico >= 0.15 && valoreEffetticoSismico < 0.25) {
        setValue(
          'sismicitaValoreApprossimativo',
          SismicitiaAreaValApprossimativo.ag15e25
        );
      }
      if (valoreEffetticoSismico >= 0.1 && valoreEffetticoSismico < 0.15) {
        setValue(
          'sismicitaValoreApprossimativo',
          SismicitiaAreaValApprossimativo.ag10e15
        );
      }
      if (valoreEffetticoSismico >= 0.05 && valoreEffetticoSismico < 0.1) {
        setValue(
          'sismicitaValoreApprossimativo',
          SismicitiaAreaValApprossimativo.ag05e10
        );
      }
      if (valoreEffetticoSismico < 0.05) {
        setValue(
          'sismicitaValoreApprossimativo',
          SismicitiaAreaValApprossimativo.agMinore005
        );
      }
    }
  }, [watchSismicitaArea, watch('sismicitaValoreEffettivo')]);

  useEffect(() => {
    !watchSistemaDiRiferimentoAltro && setValue('sistemaDiRiferimentoAltro');
  }, [watchSistemaDiRiferimento]);

  useEffect(() => {
    !watchEllissoideDiRiferimentoAltro &&
      setValue('ellissoideDiRiferimentoAltro', '');
  }, [watchEllissoideDiRiferimento]);

  useEffect(() => {
    if (
      watch('indirizzo') &&
      !getValues('latitudineCentro') &&
      !getValues('longitudineCentro')
    ) {
      const geoFunc = window.setTimeout(() => {
        Geocode.setApiKey(process.env.REACT_APP_API_KEY);
        Geocode.setLanguage('it');
        Geocode.setRegion('it');

        const region = getValues('regione')?.label;
        const postalCode = getValues('capCode')?.label;

        const council = getValues('comune')?.label;
        const province = getValues('provincia')?.label;
        const geoAddress = `${region} ${province} ${council} ${postalCode}   ${getValues(
          'indirizzo'
        )}`;

        Geocode.fromAddress(geoAddress).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            if (
              !getValues('latitudineCentro') &&
              !getValues('longitudineCentro')
            ) {
              setValue('latitudineCentro', lat);
              setValue('longitudineCentro', lng);
              setCenter({ lat: lat, lng: lng });
            }
          },

          error => {
            console.error(error);
          }
        );
      }, 2000);
      return () => window.clearTimeout(geoFunc);
    }
  }, [watch('indirizzo')]);

  useEffect(() => {
    if (
      !center.lat &&
      !center.lng &&
      !getValues('latitudineCentro') &&
      !getValues('longitudineCentro')
    ) {
      setValue('latitudineCentro', '');
      setValue('longitudineCentro', '');
    } else if (
      center.lat &&
      center.lng &&
      getValues('latitudineCentro') &&
      getValues('longitudineCentro')
    ) {
      setValue('latitudineCentro', center.lat);
      setValue('longitudineCentro', center.lng);
    }
  }, [center]);

  useEffect(() => {
    if (
      !centerIniziale.lat &&
      !centerIniziale.lng &&
      !getValues('latitudineIniziale') &&
      !getValues('longitudineIniziale')
    ) {
      setValue('latitudineIniziale', '');
      setValue('longitudineIniziale', '');
    } else if (
      centerIniziale.lat &&
      centerIniziale.lng &&
      getValues('latitudineIniziale') &&
      getValues('longitudineIniziale')
    ) {
      setValue('latitudineIniziale', centerIniziale.lat);
      setValue('longitudineIniziale', centerIniziale.lng);
    }
  }, [centerIniziale]);

  useEffect(() => {
    if (
      !centerFinale.lat &&
      !centerFinale.lng &&
      !getValues('latitudineFinale') &&
      !getValues('longitudineFinale')
    ) {
      setValue('latitudineFinale', '');
      setValue('longitudineFinale', '');
    } else if (
      centerFinale.lat &&
      centerFinale.lng &&
      getValues('latitudineFinale') &&
      getValues('longitudineFinale')
    ) {
      setValue('latitudineFinale', centerFinale.lat);
      setValue('longitudineFinale', centerFinale.lng);
    }
  }, [centerFinale]);

  return (
    <>
      <GridWrap container spacing={2} padding="20px" marginTop={-40}>
        <GridWrap item xs={3}>
          <RHFApiAutocomplete
            name="regione"
            label={'Regione*'}
            endpoint={`${apiGeo.ROOT}${apiGeo.REGIONS}?`}
            disabled={inspectionDisabled || isViewingInspection}
            getOptionLabel={option =>
              option?.label && option?.value ? `${option?.label}` : option
            }
          />
        </GridWrap>

        <GridWrap item xs={3}>
          <RHFApiAutocomplete
            name="provincia"
            label={'Provincia*'}
            endpoint={`${apiGeo.ROOT}${apiGeo.PROVINCES}?parentId=${
              watch('regione')?.value?.id
            }`}
            getOptionLabel={option =>
              option?.label && option?.value ? `${option?.label}` : option
            }
            disabled={
              !watch('regione') || inspectionDisabled || isViewingInspection
            }
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFApiAutocomplete
            name="comune"
            label={'Comune*'}
            endpoint={`${apiGeo.ROOT}${apiGeo.COUNCILS}?parentId=${
              watch('provincia')?.value?.id
            }`}
            getOptionLabel={option =>
              option?.label && option?.value ? `${option?.label}` : option
            }
            disabled={
              !watch('provincia') || inspectionDisabled || isViewingInspection
            }
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="localita"
            label={'Località'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={
              !watch('comune') || inspectionDisabled || isViewingInspection
            }
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFApiAutocomplete
            name="capCode"
            label={'CAP*'}
            endpoint={`${apiGeo.ROOT}${apiGeo.CAPS}?parentId=${
              watch('comune')?.value?.id
            }`}
            getOptionLabel={option =>
              option?.label && option?.value ? `${option?.label}` : option
            }
            minSearchLength={1}
            disabled={
              !watch('comune') || inspectionDisabled || isViewingInspection
            }
          />
        </GridWrap>
        <GridWrap item xs={6}>
          <RHFTextField
            name="indirizzo"
            label={'Indirizzo*'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={
              !watch('comune') || inspectionDisabled || isViewingInspection
            }
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerText={INFO.siglaStradaAppartenenza.drawerText}
          drawerTitle={INFO.siglaStradaAppartenenza.drawerTitle}
          legislationMessage={INFO.siglaStradaAppartenenza.legislationMessage}
        >
          <RHFTextField
            name="siglaStradaAppartenenza"
            label={'Strada di appartenenza (AINOP)*'}
            inputProps={{ maxLength: 6 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>
        <InfoWrapper
          size={3}
          infoMessage={INFO.sismicitaArea.infoMessage}
          legislationMessage={INFO.sismicitaArea.legislationMessage}
        >
          <RHFSelect
            name="sismicitaArea"
            label={"Sismicità dell'area(ag/g)*"}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Valore effettivo',
                value: SismicitaArea.ValoreEffettivo,
              },
              {
                label: 'Valore approssimativo',
                value: SismicitaArea.ValoreApprossimativo,
              },
            ]}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="sismicitaValoreEffettivo"
            label={'Valore effettivo*'}
            decimalScale={3}
            withValueLimit={({ value }) => value <= 999}
            disabled={
              !watchSismicitaAreaValEffettivo ||
              inspectionDisabled ||
              isViewingInspection
            }
            defaultValue={''}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="sismicitaValoreApprossimativo"
            label={'Valore approssimativo*'}
            defaultValue={''}
            disabled={
              !watchSismicitaAreaValApprossimativo ||
              inspectionDisabled ||
              isViewingInspection
            }
            selectOptions={[
              {
                label: 'ag ≥ 0,25 g',
                value: SismicitiaAreaValApprossimativo.agMaggiore25,
              },
              {
                label: '0,15 g ≤ ag < 0,25 g',
                value: SismicitiaAreaValApprossimativo.ag15e25,
              },
              {
                label: '0,10 g ≤ ag < 0,15 g',
                value: SismicitiaAreaValApprossimativo.ag10e15,
              },
              {
                label: '0,05 g ≤ ag < 0,10 g',
                value: SismicitiaAreaValApprossimativo.ag05e10,
              },
              {
                label: 'ag < 0,05 g',
                value: SismicitiaAreaValApprossimativo.agMinore005,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="classificazioneSismica"
            label={'Classificazione sismica'}
            defaultValue={''}
            withValueLimit={({ value }) => value <= 999}
            decimalScale={0}
            disabled
          />
        </GridWrap>
        <InfoWrapper
          size={6}
          infoMessage={INFO.coordinateCTR.infoMessage}
          legislationMessage={INFO.coordinateCTR.legislationMessage}
        >
          <RHFTextField
            name="coordinateCTR"
            label={'Coordinate CTR'}
            inputProps={{ maxLength: 150 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>
        <InfoWrapper size={3} infoMessage={INFO.scala.infoMessage}>
          <RHFTextField
            name="scala"
            label={'Scala'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>
        <InfoWrapper size={3} infoMessage={INFO.numeroToponimo.infoMessage}>
          <RHFTextField
            name="numeroToponimo"
            label={'Numero toponimo'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>

        <InfoWrapper
          size={3}
          infoMessage={INFO.sistemaDiRiferimento.infoMessage}
        >
          <RHFSelect
            name="sistemaDiRiferimento"
            label={'Sistema di riferimento'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'ETRF2000',
                value: SistemaDiRiferimento.ETRF2000,
              },
              {
                label: 'WGS84',
                value: SistemaDiRiferimento.WGS84,
              },
              {
                label: 'Altro',
                value: SistemaDiRiferimento.Altro,
              },
            ]}
          />
        </InfoWrapper>

        <GridWrap item xs={3}>
          <RHFTextField
            name="sistemaDiRiferimentoAltro"
            label={'Specificare altro'}
            inputProps={{ maxLength: 50 }}
            disabled={
              !watchSistemaDiRiferimentoAltro ||
              inspectionDisabled ||
              isViewingInspection
            }
            defaultValue=""
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoMessage={INFO.ellissoideDiRiferimento.infoMessage}
        >
          <RHFSelect
            name="ellissoideDiRiferimento"
            label={'Ellissoide di riferimento'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'WGS84',
                value: EllissoideDiRiferimento.WGS84,
              },
              {
                label: 'Hayford',
                value: EllissoideDiRiferimento.Hayford,
              },
              {
                label: 'Altro',
                value: EllissoideDiRiferimento.Altro,
              },
            ]}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFTextField
            name="ellissoideDiRiferimentoAltro"
            label={'Specificare altro'}
            inputProps={{ maxLength: 50 }}
            disabled={
              !watchEllissoideDiRiferimentoAltro ||
              inspectionDisabled ||
              isViewingInspection
            }
            defaultValue=""
          />
        </GridWrap>
      </GridWrap>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              COORDINATE GEOGRAFICHE CENTRO
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="quotaCentro"
            label={'Quota s.l.m.(m)*'}
            decimalScale={2}
            withValueLimit={({ value }) => value <= 9999}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="latitudineCentro"
            label={'Latitudine*'}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="longitudineCentro"
            label={'Longitudine*'}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <MapWrapper
            lat={Number(watch('latitudineCentro') || 0)}
            lng={Number(watch('longitudineCentro') || 0)}
            center={center}
            setCenter={setCenter}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              COORDINATE GEOGRAFICHE INIZIALE
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="quotaIniziale"
            label={'Quota s.l.m.(m)'}
            decimalScale={2}
            withValueLimit={({ value }) => value <= 9999}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="latitudineIniziale"
            label={'Latitudine'}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="longitudineIniziale"
            label={'Longitudine'}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        {watch('latitudineIniziale') && watch('longitudineIniziale') && (
          <GridWrap item xs={12}>
            <MapWrapper
              lat={Number(watch('latitudineIniziale'))}
              lng={Number(watch('longitudineIniziale'))}
              center={centerIniziale}
              setCenter={setCenterIniziale}
            />
          </GridWrap>
        )}
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              COORDINATE GEOGRAFICHE FINALE
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="quotaFinale"
            label={'Quota s.l.m.(m)'}
            decimalScale={2}
            withValueLimit={({ value }) => value <= 9999}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="latitudineFinale"
            label={'Latitudine'}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="longitudineFinale"
            label={'Longitudine'}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        {watch('latitudineFinale') && watch('longitudineFinale') && (
          <GridWrap item xs={12}>
            <MapWrapper
              lat={Number(watch('latitudineFinale'))}
              lng={Number(watch('longitudineFinale'))}
              center={centerFinale}
              setCenter={setCenterFinale}
            />
          </GridWrap>
        )}
      </GridWrap>
    </>
  );
};

export { Accordion3Location };
