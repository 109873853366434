import { useCallback, useState } from 'react';

export function usePagination(rowPerPageOptions = [5, 10, 25]) {
  const [pagination, setPagination] = useState({
    rowsPerPageOptions: rowPerPageOptions,
    rowsPerPage: rowPerPageOptions[0],
    page: 0,
  });

  const onPageChange = useCallback(page => {
    setPagination(prev => ({ ...prev, page }));
  }, []);
  const onRowsPerPageChange = useCallback(rowsPerPage => {
    setPagination(prev => ({ ...prev, page: 0, rowsPerPage }));
  }, []);

  return {
    ...pagination,
    onPageChange,
    onRowsPerPageChange,
  };
}
