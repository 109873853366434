import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  CardBox,
  CardContainer,
  CustomTextField,
  TypoContainer,
} from './CardL2.style';
export const CardL2 = ({
  color,
  values,
  half,
  childrenArray,
  borderpx,
  secondColumn,
  sectionColorCdA,
}) => {
  const renderChildrenArray = childrenArray => {
    return childrenArray?.map((x, index) => {
      return (
        <div key={x.key}>
          <CardContainer
            key={'children-' + index}
            color={x.color}
            borderpx={x?.borderpx || '13px'}
          >
            {x?.values.map((el, index) => {
              return (
                <div key={index}>
                  {renderCustomTextfield(el.label, el.value, index)}
                  {el?.extraText && (
                    <TypoContainer>
                      <Typography variant="h5BoldRed">
                        {el.extraText}
                      </Typography>
                    </TypoContainer>
                  )}
                </div>
              );
            })}
          </CardContainer>
          {x.childrenArray && renderChildrenArray(x.childrenArray)}
        </div>
      );
    });
  };

  const renderCustomTextfield = (label, value, index) => {
    const notValue = (!value && value !== 0) || value === 'required';
    return (
      <CustomTextField
        className={(notValue || value === 'N.D.') && 'customtxt'}
        key={`custom-txt-${index}`}
        InputLabelProps={{ style: { fontWeight: 'bold' } }}
        label={label}
        value={
          value === 'required' ? '' : value !== 0 ? (!value ? '' : value) : 0
        }
        error={notValue || value === 'N.D.'}
        disabled
        helperText={
          (notValue || value === 'N.D.') &&
          'Dati non sufficienti per il calcolo'
        }
      />
    );
  };
  return (
    <CardBox
      half={half}
      secondColumn={secondColumn}
      sectionColorCdA={sectionColorCdA}
    >
      <CardContainer color={color} borderpx={borderpx}>
        {values?.map((el, index) =>
          renderCustomTextfield(el.label, el.value, index)
        )}
      </CardContainer>
      {renderChildrenArray(childrenArray)}
    </CardBox>
  );
};
CardL2.defaultProps = {
  childrenArray: [],
  borderpx: '13px',
};

CardL2.propTypes = {
  key: PropTypes.string,
  color: PropTypes.string,
  values: PropTypes.array,
  half: PropTypes.bool,
  childrenArray: PropTypes.array,
  borderpx: PropTypes.string,
  secondColumn: PropTypes.bool,
  sectionColorCdA: PropTypes.string,
};
