import styled from '@emotion/styled';
import TreeItem from '@mui/lab/TreeItem';
import { alpha } from '@mui/material/styles';

export const TreeItemCustom = styled(TreeItem)(({ theme, nodeId }) => ({
  /*  '&.MuiTreeItem-content.Mui-selected.MuiFocused': {
    backgroundColor: 'transparent',
  }, */
  /*  '& .MuiTreeItem-label': {
    fontSize: 30,
  }, */
  /* marginLeft: 5, */
  lineHeight: 2.2,
  marginLeft: nodeId === '0' ? 12 : -22,
  paddingLeft: nodeId !== '0' && 40,
  borderLeft:
    nodeId !== '0' && `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
}));

export const PlusMinusIconContainer = styled('div')({
  marginRight: 20,
  marginTop: -6,
  padding: '0px 5px',
  display: 'flex',
  alignItems: 'center',
});

export const DotSeparator = styled('div')(({ theme, hasInspectionId }) => ({
  /* width: hasInspectionId ? '46px' : '14px', */
  width: hasInspectionId ? '44px' : '14px',
  height: '1px',
  borderTop: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  margin: '0px 4px',
  position: hasInspectionId && 'absolute',
  left: hasInspectionId && '-68px',
  /* left: hasInspectionId && '-60px', */
}));
