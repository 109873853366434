import { pdf } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import React from 'react';
import useFetch from 'use-http';
import { useGlobalSelector } from 'stores';
import { downloadBlob } from 'utils/export';
import { SintesiCdAIdraulica } from '../pdf';

const useExportCdaIdraulica = ({ id, intCode, setExportSuccess }) => {
  const { get } = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { get: exportLambdaUrl } = useFetch(
    process.env.REACT_APP_EXPORT_LAMBDA_URL
  );

  const date = new Date();
  const currentDate = date.toLocaleDateString().replaceAll('/', '-');
  const currentTime = date.toLocaleTimeString('it-IT').replaceAll(':', '-');

  const pdfIdraulicaName = `${intCode} - Sintesi CdA Idraulica - ${currentDate} ${currentTime}`;

  const exportCdAIdraulica = async formData => {
    try {
      showLoader();
      //const cdaIdraulica = await get(`exports/${id}/hydraulic-cda`);
      const cdaIdraulica = await exportLambdaUrl(`/?bridgeId=${id}&type=33`);
      if (cdaIdraulica) {
        setExportSuccess(true);
      } else {
        setExportSuccess(false);
      }

      const PDFCdAIdraulica = await pdf(
        <SintesiCdAIdraulica pdfData={cdaIdraulica} formData={formData} />
      ).toBlob();
      downloadBlob(PDFCdAIdraulica, pdfIdraulicaName);

      return PDFCdAIdraulica;
    } catch (error) {
      console.log(error);
      return enqueueSnackbar(`Impossibile eseguire l'esportazione`, {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }
  };

  return {
    exportCdAIdraulica,
    pdfIdraulicaName,
  };
};

export default useExportCdaIdraulica;
