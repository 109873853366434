export const markdown_faq_18_1 = `Le schede di valutazione dei difetti vengono generate in **automatico** dalla piattaforma una volta definiti, nel Livello 0, gli elementi presenti nell'opera in esame e definita la tipologia. Per ogni elemento, nel Livello 1, si ha quindi a disposizione una scheda.
E' possibile velocizzare la compilazione delle schede di valutazione difetti **copiando** i valori inseriti in una scheda in altre schede compatibili.
Due schede sono compatibili tra loro nel momento in cui si riferiscono ad elementi dello stesso tipo (Trave, Spalla, Pila...) e della stessa tipologia (Muratura, Calcestruzzo, Acciaio...).

**PER DUPLICARE UNA SCHEDA**
1. Aprire la scheda che si desidera copiare
2. Selezionare *Copia scheda ispezione*
3. Nel pannello appena aperto, selezionare, tra quelli compatibili, gli elementi su cui si vuole copiare i dati
4. Selezionare il tasto *Copia*.

ATTENZIONE! La duplicazione consente di copiare tutti i dati eccetto le immagini allegate.`;
