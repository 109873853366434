export const markdown_faq_26_1 = `Per ogni ponte è possibile caricare file senza associarli a una specifica attività.

Dalla scheda del ponte:
1. Accedere all'area *Gallery* o *Documenti*
2. Selezionare il tasto *Carica foto* o *Carica documento*.`;
export const markdown_faq_26_2 = `Dal pannello di caricamento:
1. Selezionare *Carica file*
2. Scegliere l'immagine tra le cartelle del proprio computer.`;
export const markdown_faq_26_3 = `Per ogni file è possibile aggiungere autore, data, descrizione e tag, che consentono una ricerca più veloce nel database:
1. Selezionare l'icona della penna
2. Inserire o modificare i dati
3. Selezionare *Salva*
4. Selezionare *Conferma* per terminare la fase di caricamento.`;
