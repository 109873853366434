import { Button, Typography } from '@mui/material';
import { formatISO, isValid, parse } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as CloseIcon } from 'assets/icons/close-white.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { EditPanelMedia } from 'components/MediaUpload/EditPanelMedia/EditPanelMedia';
import { Table } from 'components/tables';
import { apiBridges } from 'constants/api';
import { FieldType, UploadMedia } from 'constants/inspections';
import { MAX_FILE_SIZE_LIMIT } from 'constants/media';
import { useInspectionPath, useSubscription } from 'hooks';
import {
  useAuthSelector,
  useGlobalSelector,
  useInspectionSelector,
} from 'stores';
import {
  checkFileSizeLimit,
  checkFileTypeMatch,
  createMediaCode,
  createMediaDate,
  createMediaUserName,
} from 'utils/media';
import { downloadFile, getS3ObjectUrl, handleUpload } from 'utils/upload';
import {
  formatData,
  formatDataToSave,
  invertDateFormat,
} from 'utils/utilities';
import {
  BtnPanelContainer,
  DrawerContentContainer,
  InfoPanelBox,
} from './DrawerMediaTable.styles';

export const DrawerMediaTable = ({
  openDrawer,
  setOpenDrawer,
  dataType,
  formName,
  fileLimit,
  tags,
  uploadType,
  type,
  mediaGroup,
  preview,
  isInfoAssistance,
  onFinishUpload,
  inspectionType,
  inspectionDate,
  isExtrapiattaforma,
}) => {
  const { user } = useSubscription();
  const { post } = useFetch();
  const { enqueueSnackbar } = useSnackbar();

  //State

  const [editState, setEditState] = useState(false);
  const [selected, setSelected] = useState([]);
  const [additionalText, setAdditionalText] = useState('');
  const { cognitoData } = useAuthSelector();
  const params = useParams();
  const structureId = params.structureId;
  const { showLoader, hideLoader } = useGlobalSelector();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const { setValue, getValues, control, watch } = useFormContext();
  const { append: addMedia, remove: removeMedia } = useFieldArray({
    control,
    name: 'mediaTable',
  });

  const userInfo = cognitoData?.attributes;

  /* const ispettoreFrane = getValues('rilevatoreRischioFrane'); */

  useEffect(() => {
    if (getValues(formName)) {
      const dataForm = getValues(formName);
      const dataManipulate = [];
      dataForm.map((el, idx) => {
        const date = parse(el.date, 'dd/MM/yyyy', new Date());
        const isValidDate = isValid(date);
        dataManipulate.push({
          ...el,
          date: isValidDate
            ? el.date
            : formatData(el.date ? new Date(el.date) : new Date()),
          tags: el.tags === '' ? [] : el.tags,
          mediaCode: createMediaCode(formName, el, idx, getValues),
        });
      });
      setValue('mediaTable', dataManipulate);
    } else {
      setValue('mediaTable', []);
    }
  }, []);

  const dataTable = watch('mediaTable');
  //Const
  const dataConfig = {
    columns: {
      mediaCode: { label: 'Codice' },
      visualName: { label: 'Nome file' },
      author: { label: type === FieldType.documents ? 'Fonte' : 'Autore' },
      date: { label: 'Data' },
      description: { label: 'Descrizione' },
    },
  };
  const rilevatoreRischioIdraulico =
    getValues('rilevatoreRischioIdraulicoMedia') || user?.displayName;
  const rilevatoreRischioFrane =
    getValues('rilevatoreRischioFraneMedia') || user?.displayName;
  const rilevatoreRischioStrutturale =
    getValues('rilevatoreRischioStrutturaleMedia') || user?.displayName;

  const dataIspezione = getValues('dataIspezioneRischioStrutturaleMedia');

  const dataRischioFrane = getValues('dataIspezioneRischioFraneMedia');

  const dataRischioIdraulico = getValues('dataIspezioneRischioIdraulicoMedia');

  //Functions
  const onRemove = item => {
    removeMedia(dataTable.findIndex(x => x.id === item.id));
  };

  const onChangeFile = event => {
    let addText = '';
    const files = event.target.files || [];

    const currMediaTableLength = dataTable?.length || 0;

    const fileArray = Object.values(files);

    const valuesFile = [];
    for (const value of Object.values(event.target.files)) {
      valuesFile.push(value);
    }
    if (fileArray.length + dataTable?.length > fileLimit) {
      addText = `Attenzione: puoi aggiungere al massimo ${fileLimit} ${
        fileLimit === 1 ? 'elemento' : 'elementi'
      }`;
    } else {
      fileArray?.map((file, idx) => {
        if (file.size <= MAX_FILE_SIZE_LIMIT) {
          const encodedName = file.name;
          const groups = mediaGroup?.length > 0 ? mediaGroup?.join(',') : '';
          const extensionFile = `.${encodedName?.split('.').pop()}`;
          const fileNameWithoutExt = encodedName?.replace(/\.[^/.]+$/, '');
          const condition = checkFileTypeMatch(dataType, file);
          const fileHasExceededSizeLimit = checkFileSizeLimit(file);

          if (fileHasExceededSizeLimit) {
            return (addText =
              fileLimit === 1
                ? 'Il file caricato è troppo grande'
                : 'Uno dei file caricati è troppo grande');
          }

          if (condition) {
            addMedia({
              id: `${uuidv4()}-${new Date().getTime()}`,
              fileName:
                fileNameWithoutExt + '_' + new Date().valueOf() + extensionFile,
              mediaCode: createMediaCode(
                formName,
                file,
                currMediaTableLength + idx,
                getValues
              ),
              visualName: file.name,
              /* author: user?.email, //TODO: user.name */
              author: createMediaUserName(
                type,
                mediaGroup,
                rilevatoreRischioIdraulico,
                rilevatoreRischioFrane,
                rilevatoreRischioStrutturale,
                user?.displayName
              ),
              /* date: formatData(new Date()), */
              date: createMediaDate(
                type,
                mediaGroup,
                dataRischioIdraulico,
                dataRischioFrane,
                dataIspezione
              ),
              description: '',
              tags: [],
              fileDimensions: file.size,
              file: file,
              type: type,
              // userId: 3, //TODO: user.id
              isVideo:
                dataType === 'video/*,image/*' && file.type.match(/video-*/)
                  ? true
                  : false,
              group: groups,
              extensionFile: extensionFile,
            });
          } else {
            addText =
              'Uno dei file caricati non rispetta il formato richiesto.';
          }
        } else {
          addText =
            fileLimit === 1
              ? 'Il file caricato è troppo grande'
              : 'Uno dei file caricati è troppo grande';
        }
      });
    }
    setAdditionalText(addText);
  };

  const resetData = () => {
    setValue('nomeFile', '');
    setValue('autoreFile', userInfo.name);
    setValue('dataFile', formatData(new Date()));
    setValue('descrizioneFile', '');
    setValue('tagsFile', []);
  };

  /* const testData = new Date('February 22, 23 00:30');
  const formatDataISO = testData.toISOString();
  const testDate = formatDataToSave(inspectionDate);
  const formatISODate = formatISO(new Date(2023, 1, 22, 0, 30, 0)); */

  /* const mediaTest = '22/02/2023';
  const dataMedia = formatDataToSave(mediaTest);
  console.log(dataMedia, 'data'); */

  const createExtraPlatformInspection = async files => {
    /* const dateDb = formatDatayMd(inspectionDate); */
    const dateDb = formatDataToSave(inspectionDate);
    const inspectionData = {
      type: Number(inspectionType),
      date: dateDb,
      sharedWith: [],
      extraPlatformInspectionFiles: files,
    };
    try {
      await post(
        `${apiBridges.ROOT}/${structureId}/inspection`,
        inspectionData
      );
      return enqueueSnackbar(
        'Ispezione pregressa extra-piattaforma archiviata correttamente',
        {
          variant: 'success',
        }
      );
    } catch (err) {
      return enqueueSnackbar({
        variant: 'error',
      });
    }
  };
  const handleResetToNull = () => {
    fileInput.current.value = null;
  };

  const saveFiles = async () => {
    const promises = [];
    const filteredFiles = dataTable.filter(x => !x.url);
    /* console.log(filteredFiles, 'filteredFiles'); */
    filteredFiles?.map(i => {
      if (i.file) {
        promises.push(
          isInfoAssistance
            ? handleUpload(`attachments`, i.file, i.id, i.fileName)
            : handleUpload(
                `bridges/${structureId}/${uploadType}`,
                i.file,
                i.id,
                i.fileName
              )
        );
      }
    });
    showLoader();
    // eslint-disable-next-line no-unused-vars
    const mediaData = dataTable.map(({ file, tags, ...others }) => {
      return {
        ...others,
        tags:
          typeof tags === 'object' && tags?.length > 0
            ? [tags?.filter((t, idx) => tags?.indexOf(t) === idx)].join(',')
            : typeof tags === 'string'
            ? [tags].join(',')
            : '',
      };
    });

    /* console.log(mediaData, 'mediaData');
    console.log(dataTable, 'dataTable'); */

    const res = await Promise.all(promises);
    res.map(x => {
      const idx = mediaData.findIndex(c => c.id === x.fileId);
      mediaData[idx] = {
        ...mediaData[idx],
        s3Path: x.s3Path,
        date: invertDateFormat(mediaData[idx].date),
        url: getS3ObjectUrl(x.s3Path),
        ...(isExtrapiattaforma && {
          structureId: Number(structureId),
          userId: user?.id,
        }),
      };
    });
    isExtrapiattaforma && (await createExtraPlatformInspection(mediaData));

    setValue(formName, mediaData);
    setValue('mediaTable', []);
    setOpenDrawer(!openDrawer);
    resetData();
    if (onFinishUpload) {
      onFinishUpload();
    }

    hideLoader();
  };

  const editRows = item => {
    setEditState(!editState);
    setValue('nomeFile', item.visualName);
    setValue('autoreFile', item.author || userInfo.name);

    var dateParts = item.date.split('/');

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject =
      dateParts && new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    setValue('dataFile', dateObject);
    setValue('descrizioneFile', item.description);
    const tagsValue =
      typeof item.tags === 'string' ? item.tags?.split(',') : item.tags;
    setValue('tagsFile', tagsValue);
  };

  const fileInput = useRef();

  let btnText;
  if (formName) {
    if (isExtrapiattaforma) {
      btnText = `Archivia`;
    } else {
      btnText = `Conferma`;
    }
  }

  const isConfirmButtonDisabled =
    inspectionDisabled ||
    isViewingInspection ||
    (isExtrapiattaforma && !inspectionDate);

  return (
    <div>
      {additionalText && (
        <InfoPanelBox>
          <Typography variant="h3Bold" style={{ color: 'red' }}>
            {additionalText}
          </Typography>
        </InfoPanelBox>
      )}
      <DrawerContentContainer>
        <Typography variant="h3Bold">
          Media caricati ({dataTable && dataTable.length})
        </Typography>

        {dataTable && dataTable.length !== 0 ? (
          <Table
            data={dataTable}
            config={dataConfig}
            hasCheckbox={false}
            previewDocuments={uploadType === UploadMedia.documents}
            typevariant={'secondary'}
            rowActions={
              inspectionDisabled || isViewingInspection
                ? [
                    {
                      onClick: item => {
                        if (item?.s3Path) {
                          downloadFile(decodeURIComponent(item.s3Path));
                        }
                      },
                      mediaTable: true,
                      downloadIcon: true,
                      icon: <DownloadIcon />,
                    },
                  ]
                : [
                    {
                      onClick: item => {
                        if (item?.s3Path) {
                          downloadFile(decodeURIComponent(item.s3Path));
                        }
                      },
                      mediaTable: true,
                      downloadIcon: true,
                      icon: <DownloadIcon />,
                    },
                    {
                      onClick: item => {
                        setSelected([item]);
                        editRows(item);
                      },
                      icon: <EditIcon />,
                      mediaTable: true,
                      editIcon: true,
                    },
                    {
                      onClick: item => {
                        onRemove(item);
                      },
                      icon: <CloseIcon />,
                    },
                  ]
            }
            selected={selected}
            setSelected={setSelected}
            preview={preview || uploadType === UploadMedia.media}
          />
        ) : (
          <Typography style={{ marginTop: -10 }} variant="h3">
            Nessun file presente.
          </Typography>
        )}
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {!inspectionDisabled && !isViewingInspection && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => fileInput.current.click()}
              disabled={!!inspectionDisabled || !!isViewingInspection}
            >
              Carica file
            </Button>
          )}
          <input
            ref={fileInput}
            type="file"
            accept={dataType}
            multiple={fileLimit !== 1}
            style={{ display: 'none' }}
            onChange={onChangeFile}
            onClick={handleResetToNull}
          />
        </div>
      </DrawerContentContainer>

      {editState && (
        <EditPanelMedia
          formName={formName}
          mediaFields={dataTable}
          selected={selected}
          tags={tags}
          editState={editState}
          setEditState={setEditState}
          resetData={resetData}
          type={type}
        />
      )}

      <BtnPanelContainer>
        {!inspectionDisabled && !isViewingInspection && (
          <>
            <Button
              variant="text"
              color="secondary"
              size="small"
              onClick={() => {
                setOpenDrawer(!openDrawer);
                resetData();
              }}
            >
              Annulla
            </Button>
            <Button
              type={isExtrapiattaforma ? 'button' : 'submit'}
              variant="contained"
              color="secondary"
              size="small"
              disabled={isConfirmButtonDisabled}
              onClick={() => {
                saveFiles();
              }}
            >
              {btnText}
            </Button>
          </>
        )}
      </BtnPanelContainer>
    </div>
  );
};
DrawerMediaTable.propTypes = {
  uploadType: PropTypes.oneOf(['media', 'documents', 'graphicElaboration']),
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  dataType: PropTypes.string,
  formName: PropTypes.string,
  fileLimit: PropTypes.number,
  tags: PropTypes.array,
  type: PropTypes.number,
  mediaGroup: PropTypes.array,
  preview: PropTypes.bool,
  isInfoAssistance: PropTypes.bool,
  isExtrapiattaforma: PropTypes.bool,
  onFinishUpload: PropTypes.func,
  inspectionType: PropTypes.string,
  inspectionDate: PropTypes.string,
};
