import { useCallback } from 'react';
// eslint-disable-next-line import/no-named-as-default
import useFetch from 'use-http';

import { apiInspections } from 'constants/api';

export function useInspectionArchiveFetch() {
  const { loading, error, post } = useFetch(`${apiInspections.ROOT}`);

  const onInspectionArchived = useCallback(
    inspectionId => post(`/${inspectionId}${apiInspections.ARCHIVE}`),
    [post]
  );

  return {
    loading,
    error,
    onInspectionArchived,
  };
}
