// eslint-disable-next-line import/no-named-as-default
import useFetch from 'use-http';

import { apiBridges } from 'constants/api';

export function useBridgeFetch(id, options) {
  const { loading, error, data } = useFetch(
    `${apiBridges.ROOT}/${id}`,
    options,
    [id]
  );

  return { loading, error, data };
}
