import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const GridWrap = styled(Grid)(({ marginBottom, marginTop = '5px' }) => ({
  marginBottom: marginBottom,
  marginTop: marginTop,
}));

export const TitleSection = styled('div')({
  marginTop: -60,
});
