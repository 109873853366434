import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { CardContainer, FirstRow } from './CardOverview.style';

export const CardOverview = ({
  title,
  subtitle,
  buttonText,
  buttonDisabled,
  height,
  subCard,
  children,
  onClick,
}) => {
  return (
    <CardContainer height={height} subCard={subCard}>
      {(title || buttonText) && (
        <FirstRow>
          <Typography
            variant={subCard ? 'h3Bold' : 'h2'}
            sx={{ margin: '19px 0' }}
          >
            {title}
          </Typography>
          {buttonText && (
            <Button
              variant="outlined"
              color="secondary"
              sx={{ height: '36px', marginTop: '16px' }}
              onClick={onClick || (() => {})}
              disabled={buttonDisabled}
            >
              {buttonText}
            </Button>
          )}
        </FirstRow>
      )}
      <Typography variant="h3">{subtitle}</Typography>
      {children}
    </CardContainer>
  );
};
CardOverview.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  height: PropTypes.string,
  subCard: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};
