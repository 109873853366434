import { differenceInCalendarDays, format } from 'date-fns';
import Dwg from 'assets/icons/file-dwg.svg';
import Excel from 'assets/icons/file-excel.svg';
import Generic from 'assets/icons/file-generic.svg';
import Pdf from 'assets/icons/file-pdf.svg';
import Word from 'assets/icons/file-word.svg';
import { InspectionRouteActions } from 'constants/inspections';

export const removeUnsafeCharacters = string => {
  let result = '';
  if (!string) return result;
  for (let i = 0; i < string.length; i++) {
    const char = string[i];

    const isNumberic = char.match(/[0-9]/);
    const isLowerLetter = char.match(/[a-z]/);
    const isCapitalizedLetter = char.match(/[A-Z]/);
    const isHyphen = char.match(/-/);
    const isUnderscore = char.match(/_/);

    if (
      isNumberic ||
      isLowerLetter ||
      isCapitalizedLetter ||
      isHyphen ||
      isUnderscore
    ) {
      result += char;
    }
  }
  return result;
};

export const formatData = date => {
  if (date) {
    const dateFormatted = new Date(date);
    return format(dateFormatted, 'dd/MM/yyyy');
  }
};

export const formatDatayMd = date => {
  if (date) {
    const dateFormatted = new Date(date);
    return format(dateFormatted, 'yyyy/MM/dd');
  }
};

export const invertDateFormat = (date, separator = '/') => {
  const arrayDate = date?.split(separator);
  arrayDate?.reverse();
  return arrayDate?.join(separator);
};

//22/02/2023 --> 02/22/2023
export const formatDateString = (date, separator = '/') => {
  const arrayDate = date?.split(separator);
  const dateUSA = `${arrayDate[1]}${separator}${arrayDate[0]}${separator}${arrayDate[2]}`;
  const newDate = new Date(dateUSA);
  return formatDataToSave(newDate);
};

// export const

export const formatDataToSave = date => {
  if (date) {
    return new Date(date).toISOString();
  }
};

export const iconType = extensionFile => {
  let result = '';
  switch (extensionFile) {
    case '.dwg':
      result = Dwg;
      break;
    case '.docx':
    case '.doc':
      result = Word;
      break;
    case '.xls':
      result = Excel;
      break;
    case '.pdf':
      result = Pdf;
      break;
    default:
      result = Generic;
  }
  return result;
};

export const updateTableRows = (
  newTotal,
  prevTotal,
  removeElements,
  addElements
) => {
  if (!newTotal) {
    return;
  }
  if (newTotal !== prevTotal) {
    if (newTotal < prevTotal) {
      const ids = [];
      for (let i = prevTotal - 1; i >= newTotal; i--) {
        ids.push(i);
      }
      removeElements(ids);
    } else {
      for (let i = prevTotal + 1; i <= newTotal; i++) {
        addElements(i);
      }
    }
  }
};

export const updateArrayTableRows = (newTotal, currArr, handlePush) => {
  if (newTotal !== currArr?.length) {
    if (newTotal < currArr?.length) {
      for (let i = currArr?.length - 1; i >= newTotal; i--) {
        currArr.pop();
      }
    } else {
      for (let i = currArr?.length + 1; i <= newTotal; i++) {
        handlePush(i);
      }
    }
  }
};

export const radians_to_degrees = radians => {
  return radians * (180 / Math.PI);
};

export const degrees_to_radians = degrees => {
  return degrees * (Math.PI / 180);
};

export const buildQueryString = params =>
  Object.keys(params)
    .filter(param => params[param])
    .map(
      param =>
        `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`
    )
    .join('&');

export const buildQueryUrl = (url, query) => {
  return `${url}?${buildQueryString(query)}`;
};

export const demoStructures = [
  'Ponte di Casale Monferrato [DEMO] su Rio Caresana',
  'Ponte Verucchio [DEMO] su Fiume Marecchia',
];

/* export const isDemoStructure = structureName => {
  const demoStructures = [
    'Ponte di Casale Monferrato [DEMO] su Rio Caresana',
    'Ponte Verucchio [DEMO] su Fiume Marecchia',
  ];
  return demoStructures.includes(structureName);
}; */

/* ----- Handle dynimic inspection tabs ----- */

export const handleDynamicInspectionsTabChange = (
  levelTab,
  levelSubTab,
  accordion,
  navigate,
  path,
  id,
  lastInspectionId
) => {
  navigate(
    `${path}/${id}/inspection/${lastInspectionId}/${InspectionRouteActions.VIEW}`,
    {
      state: {
        inspectionLevelTab: levelTab,
        inspectionLevelSubTab: levelSubTab,
        inspectionLevelSubTabAccordion: accordion,
      },
    }
  );
};

export const convertTSEnumToSelectOptions = (arr, startIdx = 1) => {
  return arr.map((el, idx) => {
    return { label: el, value: idx + startIdx };
  });
};

export const getExpirationInfo = subscriptionDate => {
  const result = differenceInCalendarDays(
    new Date(subscriptionDate),
    new Date()
  );

  return result;
};

export const getGBfromBytes = size => {
  if (isNullOrUndefinedOrEmptyString(size) || size === '0') {
    return '0';
  }
  if (size > 0) {
    const result = size / Math.pow(10, 9);
    if (result.toFixed(2) > 0.0) {
      return result.toFixed(2);
    } else {
      return '0';
    }
  }
};

export function compareObjectsPropertiesAlphabetically(a, b) {
  if (a?.email < b?.email) {
    return -1;
  }
  if (a?.email > b?.email) {
    return 1;
  }
  return 0;
}

export const isNullOrUndefinedOrEmptyString = val => {
  return val === null || val === undefined || val === '';
};
