import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { refreshS3Path } from 'utils/upload';
import { iconType } from 'utils/utilities';
import {
  BoxImage,
  ButtonContainer,
  Container,
  EllipsisTypo,
  FileContainer,
  ImagePlaceholder,
  ImagesFileContainer,
  InfoFile,
  MiniBoxImage,
  MiniIconImage,
  NoFileContainer,
  VideoBox,
} from './MediaUpload.styles';
import { DrawerMedia } from '../DrawerMedia/DrawerMedia';

export const MediaUpload = ({
  title,
  btnTitle,
  preview,
  dataType,
  formName,
  infoPanel,
  fileLimit,
  tags,
  type,
  uploadType,
  mediaGroup,
  disabled,
  onlySelection,
  compact,
  isInfoAssistance,
}) => {
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const [open, setOpen] = useState(false);

  const { watch } = useFormContext();
  const files = watch(formName) || [];

  return (
    <>
      {compact ? (
        <>
          <MiniBoxImage>
            <MiniIconImage
              disabled={disabled}
              onClick={() => !disabled && setOpen(!open)}
            >
              <img src="/icons/edit-image-white.svg" />
            </MiniIconImage>
            <Typography variant="h3">
              {files.length === 0 ? '-' : `(${files.length})`}
            </Typography>
          </MiniBoxImage>
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={`${
              inspectionDisabled || isViewingInspection
                ? 'Scarica file'
                : 'Gestisci file'
            } / ${title}`}
            infoPanel={infoPanel}
            dataType={dataType}
            formName={formName}
            fileLimit={fileLimit}
            tags={tags}
            uploadType={uploadType}
            type={type}
            mediaGroup={mediaGroup}
            onlySelection={onlySelection}
            preview={preview}
            isViewingInspection={isViewingInspection}
          />
        </>
      ) : (
        <>
          <Container isInfoAssistance={isInfoAssistance}>
            {title && (
              <NoFileContainer>
                <Typography variant="h3Bold">{title}</Typography>
                <Typography variant="h3">
                  {files.length === 0 ? '(nessun file)' : `(${files.length})`}
                </Typography>
              </NoFileContainer>
            )}

            {preview ? (
              <ImagesFileContainer>
                {files.length < 4 ? (
                  files.map((file, idx) => {
                    if (file?.isVideo) {
                      return (
                        <BoxImage key={idx}>
                          <VideoBox>
                            <source src={file.url}></source>
                          </VideoBox>
                          <EllipsisTypo variant="h5">
                            {file.visualName}
                          </EllipsisTypo>
                        </BoxImage>
                      );
                    } else
                      return (
                        <BoxImage key={idx}>
                          <img
                            width={66}
                            height={66}
                            src={refreshS3Path(file.url)}
                          />
                          <EllipsisTypo variant="h5">
                            {file.visualName}
                          </EllipsisTypo>
                        </BoxImage>
                      );
                  })
                ) : (
                  <ImagesFileContainer>
                    {files.slice(0, 3).map((file, idx) => {
                      if (file?.isVideo) {
                        return (
                          <BoxImage key={idx}>
                            <VideoBox>
                              <source src={file.url}></source>
                            </VideoBox>
                            <EllipsisTypo variant="h5">
                              {file.visualName}
                            </EllipsisTypo>
                          </BoxImage>
                        );
                      } else
                        return (
                          <BoxImage key={idx}>
                            <img
                              width={66}
                              height={66}
                              src={refreshS3Path(file.url)}
                            />
                            <EllipsisTypo variant="h5">
                              {file.visualName}
                            </EllipsisTypo>
                          </BoxImage>
                        );
                    })}
                    <ImagePlaceholder>
                      <EllipsisTypo variant="h5">
                        +{files.length - 3}
                      </EllipsisTypo>
                    </ImagePlaceholder>
                  </ImagesFileContainer>
                )}
              </ImagesFileContainer>
            ) : files.length < 3 ? (
              files.map((file, idx) => (
                <FileContainer key={idx}>
                  <InfoFile>
                    <img
                      width={16}
                      height={16}
                      src={iconType(file.extensionFile)}
                      alt="document icon"
                    />
                    <EllipsisTypo variant="h5">
                      {file.description} ({file.visualName})
                    </EllipsisTypo>
                  </InfoFile>
                </FileContainer>
              ))
            ) : (
              <>
                {files.slice(0, 2).map((file, idx) => (
                  <FileContainer key={idx}>
                    <InfoFile>
                      <img
                        width={16}
                        height={16}
                        src={iconType(file.extensionFile)}
                        alt="document icon"
                      />
                      <EllipsisTypo variant="h5">
                        {file.description} ({file.visualName})
                      </EllipsisTypo>
                    </InfoFile>
                  </FileContainer>
                ))}
                <FileContainer>
                  <EllipsisTypo variant="h5">+{files.length - 2}</EllipsisTypo>
                </FileContainer>
              </>
            )}
            {!(
              (inspectionDisabled || isViewingInspection) &&
              files.length === 0
            ) && (
              <ButtonContainer fileUploaded={files.length && true}>
                <Button
                  disabled={disabled}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {inspectionDisabled || isViewingInspection
                    ? 'Gestisci file'
                    : btnTitle}
                </Button>
              </ButtonContainer>
            )}
          </Container>
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={`${
              inspectionDisabled || isViewingInspection
                ? 'Scarica file'
                : 'Gestisci file'
            } / ${title}`}
            infoPanel={infoPanel}
            dataType={dataType}
            formName={formName}
            fileLimit={fileLimit}
            tags={tags}
            uploadType={uploadType}
            type={type}
            mediaGroup={mediaGroup}
            onlySelection={onlySelection}
            preview={preview}
            isInfoAssistance={isInfoAssistance}
            isViewingInspection={isViewingInspection}
          />
        </>
      )}
    </>
  );
};

MediaUpload.defaultProps = {
  onlySelection: false,
};

MediaUpload.propTypes = {
  title: PropTypes.string,
  btnTitle: PropTypes.string,
  preview: PropTypes.bool,
  dataType: PropTypes.string,
  formName: PropTypes.string,
  infoPanel: PropTypes.string,
  fileLimit: PropTypes.number,
  tags: PropTypes.array,
  type: PropTypes.number,
  uploadType: PropTypes.oneOf(['media', 'documents', 'graphicElaboration']),
  mediaGroup: PropTypes.array,
  disabled: PropTypes.bool,
  onlySelection: PropTypes.bool,
  compact: PropTypes.bool,
  isInfoAssistance: PropTypes.bool,
};
