import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const CompletionPercentageIndicator = ({ val }) => {
  const progress = isNaN(val) || val === 0 ? '-' : parseInt(val);
  if (progress === '-') {
    return <Box>{`${progress}`}</Box>;
  } else if (progress > 0 && progress < 60) {
    return (
      <Box sx={{ color: theme => theme.palette.error.main }}>
        {`${progress}%`}
      </Box>
    );
  } else if (progress >= 60 && progress <= 79) {
    return (
      <Box sx={{ color: theme => theme.palette.warning.main }}>
        {`${progress}%`}
      </Box>
    );
  } else {
    return (
      <Box sx={{ color: theme => theme.palette.success.main }}>
        {`${progress}%`}
      </Box>
    );
  }
};

CompletionPercentageIndicator.propTypes = {
  val: PropTypes.number,
};

export { CompletionPercentageIndicator };
