export const mappingOutCommon = data => {
  return {
    codiceIOP: data.codiceIOP || null,
    nomeOpera: data.nomeOpera || null,
    stradaAppartenenza: data.stradaAppartenenza || null,
    progressivaKmIniziale: data.progressivaKmIniziale || null,
    progressiveKmFinale: data.progressiveKmFinale || null,
    nomeEnteScavalcato: data.nomeEnteScavalcato || null,
    caratteristicheClassificazioneEnteScavalcato:
      data.caratteristicheClassificazioneEnteScavalcato || null,
    classeUso: data.classeUso || null,
    statoOpera: data.statoOpera || null,
    luceComplessivaEstesa: data.luceComplessivaEstesa || null,
    larghezzaTotaleImpalcato: data.larghezzaTotaleImpalcato || null,
    numeroCampate: parseInt(data.numeroCampate) || null,
    luceMediaCampate: parseFloat(data.luceMediaCampate) || null,
    luceMassimaCampate: parseFloat(data.luceMassimaCampate) || null,
    rischioFrana: data.rischioFrana || null,
    rischioIdraulico: data.rischioIdraulico || null,
    rischioPresente: data.rischioPresente || null,
    presenzaStudioIdraulicoLivelliDefiniti:
      data.presenzaStudioIdraulicoLivelliDefiniti || null,
    dataApprossimativaAnnoDiProgettazione:
      data.dataApprossimativaAnnoDiProgettazione || null,
    annoDiCostruzioneApprossimativo:
      data.annoDiCostruzioneApprossimativo || null,
    criteriSismiciInOpera: data.criteriSismiciInOpera || null,
    categoriaPonte: data.categoriaPonte || null,
    disponibilitaStudiEffettiCarichiTraffico:
      data.disponibilitaStudiEffettiCarichiTraffico || null,
    valutazioneEffettiCarichiTraffico:
      data.valutazioneEffettiCarichiTraffico || null,
    valoreApprossimativoTGM: data.valoreApprossimativoTGM || null,
    valoreApprossimativoCommercialiTGM:
      data.valoreApprossimativoCommercialiTGM || null,
    valoreApprossimativoCommercialiCorsiaTGM:
      data.valoreApprossimativoCommercialiCorsiaTGM || null,
    limiteCaricoCarreggiataSingolaCorsia:
      data.limiteCaricoCarreggiataSingolaCorsia || null,
    alternativeStradali: data.alternativeStradali || null,
    frequentePassaggioPersone: data.frequentePassaggioPersone || null,
    trasportoMerciPericolose: data.trasportoMerciPericolose || null,
    categoriaTopografica: data.categoriaTopografica || null,
    categoriaSottosuolo: data.categoriaSottosuolo || null,
    sismicitaValoreApprossimativo: data.sismicitaValoreApprossimativo || null,
    tipologiaStrutturale: data.tipologiaStrutturale || null,
    tipologiaStrutturaleSpecificareAltro:
      data.tipologiaStrutturaleSpecificareAltro || null,
    tipologiaSchema: data.tipologiaSchema || null,
    tipologiaImpalcato: data.tipologiaImpalcato || null,
    nPileStruttura: data.nPileStruttura || null,
    nSpalleStruttura: data.nSpalleStruttura || null,
    mensole: data.mensole || null,
    nMensoleStruttura: data.nMensoleStruttura || null,
    nTamponiStruttura: data.nTamponiStruttura || null,
    spalleTable: data.spalleTable || null,
    pileTable: data.pileTable || null,
    paliTable: data.paliTable || null,
    spallePileTable: data.spallePileTable || null,
    campataStrutturaTable: data.campataStrutturaTable || null,
    mensoleTamponiTable: data.mensoleTamponiTable || null,
    spallePileCollegamentiTable: data.spallePileCollegamentiTable || null,
    convogliamentoAcqueTable: data.convogliamentoAcqueTable || null,
    sottoserviziTable: data.sottoserviziTable || null,
    mensoleTamponiCampateImpalcatoTable:
      data.mensoleTamponiCampateImpalcatoTable || null,
    /* criteriSismiciEffettivo: data.criteriSismiciEffettivo || null,
    criteriSismiciPresunto: data.criteriSismiciPresunto || null, */
    catagoriaPonteEffettiva: data.catagoriaPonteEffettiva || null,
    categoriaPontePresunta: data.categoriaPontePresunta || null,
    /* catagoriaSottosuoloEffettiva: data.catagoriaSottosuoloEffettiva || null,
    categoriaSottosuoloPresunta: data.categoriaSottosuoloPresunta || null, */
    interventiSignificativiDataApprossimativa:
      data.interventiSignificativiDataApprossimativa || null,
    criteriSismiciInOperaAltro: data.criteriSismiciInOperaAltro || null,
    categoriaPonteAltro: data.categoriaPonteAltro || null,
    categoriaSottosuoloAltro: data.categoriaSottosuoloAltro || null,
    affidabilitaComplessivaRischioFrane:
      data.affidabilitaComplessivaRischioFrane || null,
    schemaPonteTable: data.schemaPonteTable || null,
    sovrastrutturaImpalcatoTable: data.sovrastrutturaImpalcatoTable || null,
    misureMitigazione: data.misureMitigazione || null,
    estensioneInterferenzaFrana: data.estensioneInterferenzaFrana || null,
    estensioneInterferenzaFranaAltro:
      data.estensioneInterferenzaFranaAltro || null,
    statoAttivitaFrana: data.statoAttivitaFrana || null,
    gradoCriticitaFrana: data.gradoCriticitaFrana || null,
    velocitaMaxSpostamentoFrana: data.velocitaMaxSpostamentoFrana || null,
    velocitaMaxSpostamentoFranaFlag:
      data.velocitaMaxSpostamentoFranaFlag || null,
    magnitudoBaseVolumetrica: data.magnitudoBaseVolumetrica || null,
    magnitudoBaseVolumetricaFlag: data.magnitudoBaseVolumetricaFlag || null,
    campataTable: data.campataTable || null,
    difettiElevazioni: data.difettiElevazioni || null,
    valoreLimitazione: data.valoreLimitazione || null,
    bacinoIdrograficoPresuntoRischioIdraulico:
      data.bacinoIdrograficoPresuntoRischioIdraulico || null,
    curvaturaAlveoRischioIdraulico: data.curvaturaAlveoRischioIdraulico || null,
    divagazioneAlveoRischioIdraulico:
      data.divagazioneAlveoRischioIdraulico || null,
    francoIdraulicoFP2: isNaN(parseFloat(data.francoIdraulicoFP2))
      ? null
      : parseFloat(data.francoIdraulicoFP2),
    francoIdraulicoFP3: isNaN(parseFloat(data.francoIdraulicoFP3))
      ? null
      : parseFloat(data.francoIdraulicoFP3),
    francoIdraulicoStimato: isNaN(parseFloat(data.francoIdraulicoStimato))
      ? null
      : parseFloat(data.francoIdraulicoStimato),
    francoIdraulicoSuperiore: data.francoIdraulicoSuperiore || null,
    deposizioneSedimenti: data.deposizioneSedimenti || null,
    materialeVegetaleRischioIdraulico:
      data.materialeVegetaleRischioIdraulico || null,
    fattoreRestringimentoAlveo: isNaN(parseInt(data.fattoreRestringimentoAlveo))
      ? null
      : parseInt(data.fattoreRestringimentoAlveo),
    fattoreRestringimentoAreeGolenali: isNaN(
      parseInt(data.fattoreRestringimentoAreeGolenali)
    )
      ? null
      : parseInt(data.fattoreRestringimentoAreeGolenali),

    fattoreIEL: isNaN(parseFloat(data.fattoreIEL))
      ? null
      : parseFloat(data.fattoreIEL),
    evidenzeProfonditaDelleFondazioni:
      data.evidenzeProfonditaDelleFondazioni || null,
    abbassamentoGeneralizzatoAlveo: data.abbassamentoGeneralizzatoAlveo || null,
    accumuliDetritiAMontePila: data.accumuliDetritiAMontePila || null,
    protezioneAlPiedePileSpalle: data.protezioneAlPiedePileSpalle || null,
    brigliaProtezioneValleDelPonte: data.brigliaProtezioneValleDelPonte || null,
    elementiCriticiVulnerabilitaStrutturaleFondazionale:
      data.elementiCriticiVulnerabilitaStrutturaleFondazionale || null,
    elementiCriticiVulnerabilitaSismica:
      data.elementiCriticiVulnerabilitaSismica || null,
    condizioniCriticheVulnerabilitaStrutturale:
      data.condizioniCriticheVulnerabilitaStrutturale || null,
    condizioniCriticheVulnerabilitaSismica:
      data.condizioniCriticheVulnerabilitaSismica || null,
    elementiVulnerabilitaSismica: data.elementiVulnerabilitaSismica || null,
    tipologiaImpalcatoSpecificareAltro:
      data.tipologiaImpalcatoSpecificareAltro || null,
    riepilogoStrutturaTable: data.riepilogoStrutturaTable || null,
    verificaStatica: data.verificaStatica || null,
    verificaSismica: data.verificaSismica || null,
    campateSoggetteACollasso: data.campateSoggetteACollasso || null,
    vulnerabilitaStrutturaleBassa: data.vulnerabilitaStrutturaleBassa || null,
  };
};
