import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { colors } from 'constants/colors';

export const BoxContainer = styled('div')({
  width: '100%',
  borderRadius: 8,
  border: `1px solid ${colors.PALAGREY}`,
  backgroundColor: colors.WHITE,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  padding: 16,
});

export const IconContainer = styled('div')({
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const DateTextBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  color: colors.BLACK,
  fontSize: '0.75rem',
});

export const TypeInspectionLabel = styled(Typography)(({ inspectionType }) => ({
  fontSize: '0.75rem',
  fontFamily: 'Ubuntu',
  backgroundColor: inspectionType === 'Ordinaria' ? '#DBE6FF' : '#FFE699',
  padding: '2px 6px',
  borderRadius: 10,
}));
