import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CustomTooltip } from 'components';
import { colors } from 'constants/colors';
import { planningIcons, statusInspections } from './constants';
import { ArrowIcon, BoxContainer, IconContainer } from './PlanningBox.styles';

const PlanningBox = ({
  handleDynamicTabChange,
  status,
  topic,
  statusMessage,
  isSpecialInspection,
  specialInspectionTopics,
  tooltip,
  noIcon,
  isRischioFrane,
  lastInspectionId,
  hideArrow,
}) => {
  let iconSrcPresente = '';
  let iconSrcAssente = '';

  switch (topic) {
    case 'Necessità ispezioni speciali':
      iconSrcPresente = planningIcons.necessitaIspezioniSpeciali.present;
      iconSrcAssente = planningIcons.necessitaIspezioniSpeciali.absent;
      break;
    case 'Aspetti critici struttura':
      iconSrcPresente = planningIcons.aspettiCriticiStruttura.present;
      iconSrcAssente = planningIcons.aspettiCriticiStruttura.absent;
      break;
    case 'Interventi su elementi accessori':
      iconSrcPresente = planningIcons.interventiSovrastruttura.present;
      iconSrcAssente = planningIcons.interventiSovrastruttura.absent;
      break;
    case 'Interventi su servizi':
      iconSrcPresente = planningIcons.interventiServizi.present;
      iconSrcAssente = planningIcons.interventiServizi.absent;
      break;
    case 'Monitoraggio attivo':
      iconSrcPresente = planningIcons.monitoraggioAttivo.present;
      iconSrcAssente = planningIcons.monitoraggioAttivo.absent;
      break;
    case 'Limitazioni di carico':
      iconSrcPresente = planningIcons.limitazioniCarico.present;
      iconSrcAssente = planningIcons.limitazioniCarico.absent;
      break;
    case 'Trasporto eccezionale':
      iconSrcPresente = planningIcons.trasportoEccezionale.present;
      iconSrcAssente = planningIcons.trasportoEccezionale.absent;
      break;
    case 'Trasporto merci pericolose':
      iconSrcPresente = planningIcons.trasportoMerciPericolose.present;
      iconSrcAssente = planningIcons.trasportoMerciPericolose.absent;
      break;
    case 'Rischio frana':
      iconSrcPresente = planningIcons.rischioFrana.present;
      iconSrcAssente = planningIcons.rischioFrana.absent;
      break;
    case 'Rischio idraulico':
      iconSrcPresente = planningIcons.rischioIdraulico.present;
      iconSrcAssente = planningIcons.rischioIdraulico.absent;
      break;
  }
  return (
    <BoxContainer
      status={status}
      isSpecialInspection={isSpecialInspection}
      noIcon={noIcon}
      isRischioFrane={isRischioFrane}
    >
      {!noIcon && (
        <IconContainer
          status={status}
          isSpecialInspection={isSpecialInspection}
        >
          <img
            src={
              status === statusInspections.PresenteNecessario
                ? iconSrcPresente
                : status === statusInspections.NecessitaIspezioniSpeciali &&
                  isSpecialInspection
                ? iconSrcPresente
                : iconSrcAssente
            }
            width={15}
            height={15}
          />
        </IconContainer>
      )}

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {tooltip ? (
          <CustomTooltip
            position="top"
            btnText={
              <Typography
                variant="h3Bold"
                sx={{
                  fontSize: '0.75rem',
                  color:
                    status === statusInspections.PresenteNecessario ||
                    status === statusInspections.NecessitaIspezioniSpeciali
                      ? colors.WHITE
                      : status === statusInspections.AssenteNonNecessario
                      ? colors.DARKGREY
                      : colors.BLACK,
                }}
              >
                {topic}
              </Typography>
            }
            title={tooltip}
          />
        ) : (
          <Typography
            variant="h3Bold"
            sx={{
              fontSize: '0.75rem',
              color:
                status === statusInspections.PresenteNecessario ||
                (status === statusInspections.NecessitaIspezioniSpeciali &&
                  isSpecialInspection) ||
                (isRischioFrane && status >= 1)
                  ? colors.WHITE
                  : status === statusInspections.AssenteNonNecessario
                  ? colors.DARKGREY
                  : status === 3 && !isSpecialInspection
                  ? colors.BLACK
                  : colors.BLACK,
            }}
          >
            {topic}
          </Typography>
        )}

        <div style={{ display: 'flex', gap: 5 }}>
          {isSpecialInspection ? (
            specialInspectionTopics?.length > 0 ? (
              specialInspectionTopics?.map((t, idx) => (
                <Typography
                  key={idx}
                  variant="body"
                  sx={{
                    fontSize: '0.75rem',
                    color:
                      status === statusInspections.NecessitaIspezioniSpeciali
                        ? colors.WHITE
                        : colors.DARKGREY,
                  }}
                >
                  {specialInspectionTopics?.length === idx + 1 ? t : `${t} -`}
                </Typography>
              ))
            ) : (
              <Typography
                variant="body"
                sx={{
                  fontSize: '0.75rem',
                  color: colors.BLACK,
                }}
              >
                n.d.
              </Typography>
            )
          ) : status ? (
            <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant="body"
                sx={{
                  fontSize: '0.75rem',
                  color:
                    status === statusInspections.PresenteNecessario ||
                    (isRischioFrane && status >= 1)
                      ? colors.WHITE
                      : colors.DARKGREY,
                }}
              >
                {statusMessage}{' '}
              </Typography>
              {!hideArrow && (
                <ArrowIcon
                  src={
                    status === statusInspections.PresenteNecessario ||
                    (isRischioFrane && status >= 1)
                      ? '/icons/arrow-right-white.svg'
                      : status ===
                          statusInspections.NecessitaIspezioniSpeciali &&
                        !isSpecialInspection
                      ? '/icons/arrow-right-grey.svg'
                      : '/icons/arrow-right-grey.svg'
                  }
                  onClick={handleDynamicTabChange}
                />
              )}
            </Stack>
          ) : (
            <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant="body"
                sx={{
                  fontSize: '0.75rem',
                  color: colors.BLACK,
                }}
              >
                n.d.
              </Typography>
              {lastInspectionId && !hideArrow && (
                <ArrowIcon
                  src={'/icons/arrow-right-grey.svg'}
                  onClick={handleDynamicTabChange}
                />
              )}
            </Stack>
          )}
        </div>
      </div>
    </BoxContainer>
  );
};

export { PlanningBox };

PlanningBox.propTypes = {
  handleDynamicTabChange: PropTypes.func,
  status: PropTypes.number,
  topic: PropTypes.string,
  statusMessage: PropTypes.string,
  isSpecialInspection: PropTypes.bool,
  specialInspectionTopics: PropTypes.array,
  tooltip: PropTypes.string,
  noIcon: PropTypes.bool,
  isRischioFrane: PropTypes.bool,
  lastInspectionId: PropTypes.number,
  hideArrow: PropTypes.bool,
};
