import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { INFO_ASSISTANCE_ROOT } from 'constants/paths';
const InfoAssistanceDialogActions = ({ handleCloseDialog }) => {
  const navigate = useNavigate();

  return (
    <>
      <>
        <Button onClick={handleCloseDialog}>Annulla</Button>
        <Button
          variant="contained"
          onClick={() => {
            handleCloseDialog();
            navigate(INFO_ASSISTANCE_ROOT);
          }}
        >
          Contattaci
        </Button>
      </>
    </>
  );
};

InfoAssistanceDialogActions.propTypes = {
  handleCloseDialog: PropTypes.func,
};

export { InfoAssistanceDialogActions };
