export function useInfoData(structure) {
  const council = structure?.council?.name;
  const address =
    structure?.address || structure?.province?.code || council
      ? `${structure?.address ? structure.address + ',' : ''} ${council} (${
          structure?.province?.code
        })`
      : '-';
  const internalCode = structure?.internalCode || '-';
  const owner = structure?.owner?.email || '-';
  const infoData = {
    street: address || '-',
    city: structure?.council?.name
      ? `Comune di ${structure?.council?.name}`
      : '-',
    code: internalCode ? `ID ${internalCode}` : '-',
    owner,
  };

  return infoData;
}
