import styled from '@emotion/styled';

export const ElementContainer = styled('div')(({ padding }) => ({
  padding: padding,
}));

export const FlexContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 6,
  padding: '10px 14px',
});
