// eslint-disable-next-line import/no-named-as-default
import useFetch from 'use-http';

import { apiUsers } from 'constants/api';
import { useLoader, useOrder, usePagination } from 'hooks';

function createUsersQueryURL(options) {
  const { orderBy, direction, page, pageSize, rhfData } = options || {};
  const searchParams = new URLSearchParams(
    Object.entries({
      sortBy: orderBy,
      sortOrder: direction && direction.toUpperCase(),
      page,
      pageSize,
      ...rhfData,
    }).filter(el => el[1])
  );

  if (searchParams.toString()) {
    return `${apiUsers.ROOT}?${searchParams.toString()}`;
  }

  return `${apiUsers.ROOT}`;
}

function useUsersFetch(options) {
  const { orderBy, direction, page, pageSize, rhfData, ...rest } = options;

  const structureDocumentsQueryURL = createUsersQueryURL({
    orderBy,
    direction,
    page,
    pageSize,
    rhfData,
  });

  const { loading, error, data, get } = useFetch(
    structureDocumentsQueryURL,
    {
      ...rest,
    },
    [orderBy, direction, page, pageSize, rhfData]
  );

  return {
    loading,
    error,
    data,
    get,
  };
}

const UsersSortByMap = {
  id: 'user.id',
  email: 'user.email',
  displayName: 'displayName',
  type: 'user.type',
  tipologiaUtente: 'typology',
  updatedRegistrationStatus: 'user.status',
  businessName: 'user.businessName',
  lastSubscriptionStatus: 'lastSubscriptionStatus',
  subscriptionExpiration: 'lastSubscription_expirationDate',
  relatedStructuresNumber: 'relatedStructuresNumber',
  usedSpace: 'usedSpace',
};

export function useUsersList(rhfData) {
  const { orderBy, direction, onOrderChange } = useOrder();
  const {
    rowsPerPageOptions,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
  } = usePagination();

  const UsersFetch = useUsersFetch({
    orderBy: orderBy && UsersSortByMap[orderBy],
    direction: direction && direction.toUpperCase(),
    page: page + 1,
    pageSize: rowsPerPage,
    rhfData,
  });

  const loading = UsersFetch.loading;
  const error = UsersFetch.error;
  const data = {
    users: UsersFetch.data,
  };

  useLoader(loading);

  return {
    loading,
    error,
    data,
    orderBy,
    direction,
    handleOrderChange: onOrderChange,
    rowsPerPageOptions,
    rowsPerPage,
    page,
    handlePageChange: onPageChange,
    handleRowsPerPageChange: onRowsPerPageChange,
  };
}
