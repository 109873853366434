import { Outlet } from 'react-router-dom';
import { FormContainer, LoginContainer } from './LoginLayout.style';

const LoginLayout = () => {
  return (
    <>
      <LoginContainer>
        <FormContainer>
          <Outlet />
        </FormContainer>
      </LoginContainer>
    </>
  );
};

export { LoginLayout };
