import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaArcoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

const ArchiForm = ({
  archiFields,
  selected,
  setSelected,
  archiTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      archiTableValue: [],
      tipologiaArcoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('archiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetArchi();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeArchi = () => {
    const tipologiaArcoTableValue = getValues('tipologiaArcoTableValue');

    const ids = selected.map(el => el.codice);

    const archi = [...archiFields];

    archi.map((x, index) => {
      if (ids.includes(x?.codice)) {
        archi[index] = {
          ...x,
          tipologiaArco: tipologiaArcoTableValue,
          ...(tipologiaArcoTableValue !== x.tipologiaArco
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'arco',
                  tipologiaArcoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(archiTable, archi);
    onResetArchi();
  };

  const onConfirmChangeArchi = () => {
    setOpenConfirmationDialog(false);
    changeArchi();
  };

  const onSubmitArchi = () => {
    const tipologiaArcoTableValue = getValues('tipologiaArcoTableValue');

    const ids = selected.map(el => el.codice);

    const archi = [...archiFields];

    const hasChangedTipologia = archi.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          tipologiaArcoTableValue !== el.tipologiaArco &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaArco)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeArchi();
    }
  };

  const onResetArchi = () => {
    setValue('archiTableValue', []);
    setValue('tipologiaArcoTableValue', '');
    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={3} infoMessage={INFO.archiSelezionati.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-archi"
            name={'archiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={archiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Archi selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipologiaArcoTableValue"
            label="Tipologia Arco"
            defaultValue={''}
            onChange={e => {
              setValue('tipologiaArcoTableValue', e.target.value);
            }}
            selectOptions={tipologiaArcoOptions}
          />
        </GridWrap>
        <GridWrap item xs={6} alignSelf="center">
          <FormSubmitButtons onSubmit={onSubmitArchi} onReset={onResetArchi} />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeArchi}
      />
    </>
  );
};
ArchiForm.propTypes = {
  archiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaArco: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  archiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default ArchiForm;
