import { CDAStrutturale } from 'constants/difetti';

export const calculateDifettosita = (elementsArr, type) => {
  if (elementsArr.some(el => el[`CDA${type}`] === CDAStrutturale.Alta)) {
    return CDAStrutturale.Alta;
  } else if (
    elementsArr.some(el => el[`CDA${type}`] === CDAStrutturale.MedioAlta)
  ) {
    return CDAStrutturale.MedioAlta;
  } else if (
    elementsArr.filter(el => el[`CDA${type}`] === CDAStrutturale.Media)
      .length >=
    elementsArr.length / 2
  ) {
    return CDAStrutturale.Media;
  } else if (
    elementsArr.filter(el => el[`CDA${type}`] === CDAStrutturale.MedioBassa)
      .length >=
    elementsArr.filter(
      el =>
        el[`CDA${type}`] === CDAStrutturale.MedioBassa ||
        el[`CDA${type}`] === CDAStrutturale.Bassa
    ).length /
      2
  ) {
    return CDAStrutturale.MedioBassa;
  } else {
    return CDAStrutturale.Bassa;
  }
};
