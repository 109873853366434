import styled from '@emotion/styled';
import theme from 'theme';
export const CardKpiContainer = styled('div')({
  height: '174px',
  width: '371px',
  padding: '5px 5px',
});

export const CardKpi = styled('div')(({ color }) => ({
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  backgroundColor: theme.palette.secondary.light,
  padding: '10px 20px',
  '& h3': {
    fontSize: '1rem',
  },
  '& h1': {
    fontSize: '1.93rem',
    lineHeight: '1rem',
    fontWeight: '500',
  },
  '& span': {
    color: color?.labelColor,
    margin: 0,
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: '5px',
    },
  },
  '& p': {
    fontSize: '1rem',
    lineHeight: 0,
    margin: 0,
    marginTop: '10px',
  },
}));
