import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import {
  handleArrayData,
  handleEnum,
} from 'components/ExportsPanel/ExportSelect/functions';
import { Fenomeno } from 'constants/inspections';
import {
  AnnoCostruzioneExportOptions,
  EstensioneInterferenzaFranaOptions,
  FenomenoOptions,
  GradoCriticitaFranaOptions,
  MagnitudoBaseVolumetricaOptions,
  MisureMitigazioneOptions,
  PericolositaPAIPSAIFraneShortOptions,
  PosizioneInstabilitaVersanteOptions,
  StatoAttivitaFranaOptions,
  tipoEnteScavalcatoOptions,
  TipologiaFenomenoOptions,
  tipologiaImpalcatoOptions,
  UnitaFisiograficaOptions,
  UsoSuoloAreaPotezialmenteCoinvoltaOptions,
  VelocitaMaxSpostamentoFranaOptions,
} from 'constants/selectOptions';
import { formatData } from 'utils/utilities';
import { CdaColorMap } from './constants';
import { styles } from './SintesiCdAFrane.styles';
import CalendarIcon from '../../../../assets/icons/calendar.png';
import LogoExport from '../../../../assets/icons/logoEsportazione.png';
import UserIcon from '../../../../assets/icons/user.png';

const SintesiCdAFrane = ({ pdfData, formData }) => {
  const enteScavalcato = handleArrayData(
    pdfData?.tipologiaEnteScavalcato,
    tipoEnteScavalcatoOptions
  );

  //Dati Generali - Geomorfologia
  const pericoloPAIPSAI = handleEnum(
    PericolositaPAIPSAIFraneShortOptions,
    pdfData?.pericolositaPAIPSAIFrane
  );
  const unitaFisiografica = handleEnum(
    UnitaFisiograficaOptions,
    pdfData?.unitaFisiografica
  );
  const tipologiaFenomeno = handleEnum(
    TipologiaFenomenoOptions,
    pdfData?.tipologiaFenomeno
  );
  const fenomeno = handleEnum(FenomenoOptions, pdfData?.fenomeno);
  const posizioneInstabilita = handleEnum(
    PosizioneInstabilitaVersanteOptions,
    pdfData?.posizioneInstabilitaVersante
  );

  //Dati Generali - Struttura
  let annoCostruzione;
  if (pdfData?.dataEffettivaAnnoDiCostruzione) {
    annoCostruzione = pdfData?.dataEffettivaAnnoDiCostruzione;
  } else if (
    pdfData?.annoDiCostruzioneApprossimativo &&
    !pdfData?.dataEffettivaAnnoDiCostruzione
  ) {
    annoCostruzione = handleEnum(
      AnnoCostruzioneExportOptions,
      pdfData?.annoDiCostruzioneApprossimativo
    );
  }
  const materialePrevalente = handleEnum(
    tipologiaImpalcatoOptions,
    pdfData?.tipologiaImpalcato
  );
  let fondazioni;
  switch (pdfData?.fondazioni) {
    case 1:
      fondazioni = 'Non nota';
      break;

    case 2:
      fondazioni = 'Diretta';
      break;

    case 3:
      fondazioni = 'Indiretta';
      break;
  }

  //Contesto
  const estensioneInterferenza = handleEnum(
    EstensioneInterferenzaFranaOptions,
    pdfData?.estensioneInterferenzaFrana
  );
  const usoSuolo = handleEnum(
    UsoSuoloAreaPotezialmenteCoinvoltaOptions,
    pdfData?.usoSuoloAreaPotezialmenteCoinvolta
  );
  const misureMitigazione = handleEnum(
    MisureMitigazioneOptions,
    pdfData?.misureMitigazione
  );

  //Parametri Frana
  const renderStatoAttivitaCriticita = () => {
    if (pdfData?.fenomeno === Fenomeno.Accertato) {
      const statoAttivita = handleEnum(
        StatoAttivitaFranaOptions,
        pdfData?.statoAttivitaFrana
      );
      return (
        <>
          <Text style={styles.infoLabel}>Stato di attività</Text>
          <Text style={styles.infoData}>{statoAttivita || '-'}</Text>
        </>
      );
    } else if (pdfData?.fenomeno === Fenomeno.Potenziale) {
      const statoCriticita = handleEnum(
        GradoCriticitaFranaOptions,
        pdfData?.gradoCriticitaFrana
      );
      return (
        <>
          <Text style={styles.infoLabel}>Stato di criticità</Text>
          <Text style={styles.infoData}>{statoCriticita || '-'}</Text>
        </>
      );
    } else {
      return (
        <>
          <Text style={styles.infoLabel}>Stato di attività</Text>
          <Text style={styles.infoData}>{'-'}</Text>
        </>
      );
    }
  };
  const maxVelocitaPotenziale = handleEnum(
    VelocitaMaxSpostamentoFranaOptions,
    pdfData?.velocitaMaxSpostamentoFrana
  );
  const magnitudoBaseVolumetrica = handleEnum(
    MagnitudoBaseVolumetricaOptions,
    pdfData?.magnitudoBaseVolumetrica
  );

  const renderStatusCdA = () => {
    if (pdfData?.cdaFrana) {
      return (
        <View
          style={[
            styles.infoTagGreyTitle,
            {
              backgroundColor: CdaColorMap[pdfData.cdaFrana]?.labelColor,
            },
          ]}
        >
          <Text style={styles.tagTextWhiteTitle}>
            {CdaColorMap[pdfData.cdaFrana]?.labelText}
          </Text>
        </View>
      );
    }
    return (
      <View style={styles.infoTagGreyTitle}>
        <Text style={styles.tagTextWhiteTitle}>DA DEFINIRE</Text>
      </View>
    );
  };

  const renderRischioFrane = () => {
    if (pdfData?.rischioFrana) {
      if (pdfData.rischioFrana === 3) {
        return (
          <View style={styles.infoTagBlue}>
            <Text style={styles.tagText}>PRESENTE</Text>
          </View>
        );
      } else if (pdfData?.rischioFrana === 1) {
        return (
          <View style={styles.infoTagOutline}>
            <Text style={[styles.tagText, { color: 'black' }]}>
              DA VERIFICARE
            </Text>
          </View>
        );
      } else {
        return (
          <View style={styles.infoTagGrey}>
            <Text style={styles.tagText}>ASSENTE</Text>
          </View>
        );
      }
    } else {
      return <Text style={styles.infoData}>{'-'}</Text>;
    }
  };

  const renderInstabilitaVersante = () => {
    if (pdfData?.instabilitaVersante) {
      return (
        <View
          style={[
            styles.infoTagVersante,
            {
              backgroundColor:
                CdaColorMap[pdfData.instabilitaVersante]?.labelColor,
            },
          ]}
        >
          <Text style={styles.tagText}>{`INSTABILITÀ DI VERSANTE ${
            CdaColorMap[pdfData.instabilitaVersante]?.labelText
          }`}</Text>
        </View>
      );
    } else {
      return (
        <View style={[styles.infoTagVersante, { backgroundColor: '#C3C3C3' }]}>
          <Text
            style={styles.tagText}
          >{`INSTABILITÀ DI VERSANTE DA DEFINIRE`}</Text>
        </View>
      );
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.wrapperCdA}>
          <Text style={{ fontSize: '12pt', fontFamily: 'Helvetica-Bold' }}>
            CDA FRANE
          </Text>
          {renderStatusCdA()}
        </View>
        <View
          style={{
            marginTop: 8,
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <View style={styles.iconElementContainer}>
            <Image src={CalendarIcon} />
          </View>
          <Text style={styles.infoLabel}>
            {pdfData?.dataIspezioneRischioFrane
              ? formatData(pdfData?.dataIspezioneRischioFrane)
              : '-'}
          </Text>
          <View
            style={[
              styles.iconElementContainer,
              {
                marginLeft: 10,
              },
            ]}
          >
            <Image src={UserIcon} />
          </View>
          <Text style={[styles.infoLabel, { lineHeight: 0 }]}>
            {pdfData?.rilevatoreRischioFrane || '-'}
          </Text>
        </View>

        <View style={{ marginTop: '8pt' }}>
          <Text style={styles.title}>{pdfData?.nomeOpera}</Text>
        </View>

        <View style={styles.flexContainer}>
          <Text style={styles.infoLabel}>Strada di appartenenza</Text>
          <Text style={styles.infoData}>
            {pdfData?.stradaAppartenenza || '-'}
          </Text>
        </View>

        <View style={styles.flexContainer}>
          <Text style={styles.infoLabel}>Progressiva Km Iniziale</Text>
          <Text style={styles.infoData}>
            {pdfData?.progressivaKmIniziale || '-'}
          </Text>
        </View>

        <View style={styles.flexContainer}>
          <Text style={styles.infoLabel}>Ente scavalcato</Text>
          <Text style={styles.infoData}>{enteScavalcato || '-'}</Text>
        </View>

        <View style={[styles.flexContainer, { marginTop: '6pt' }]}>
          <Text style={styles.infoLabel}>Rischio Frane</Text>
          {renderRischioFrane()}
        </View>

        {/* PHOTO SECTION ----------------------------- */}
        <View style={styles.imagesContainer}>
          {pdfData?.satellitareFotoAereeAttuale ? (
            <Image
              style={styles.image}
              src={{
                uri: pdfData?.satellitareFotoAereeAttuale,
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                  'Access-Control-Allow-Origin': '*',
                },
                body: '',
              }}
            />
          ) : (
            <View style={styles.placeholderImage} />
          )}

          {pdfData?.fotoCopertina ? (
            <Image
              style={styles.image}
              src={{
                uri: pdfData?.fotoCopertina,
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                  'Access-Control-Allow-Origin': '*',
                },
                body: '',
              }}
            />
          ) : (
            <View style={styles.placeholderImage} />
          )}

          {pdfData?.stralcioMappaPericolositàFranePAI ? (
            <Image
              style={styles.image}
              src={{
                uri: pdfData?.stralcioMappaPericolositàFranePAI,
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                  'Access-Control-Allow-Origin': '*',
                },
                body: '',
              }}
            />
          ) : (
            <View style={styles.placeholderImage} />
          )}
        </View>

        <View style={styles.captionsContainer}>
          <View style={styles.caption}>
            <Text style={styles.infoData}>Ortofoto</Text>
          </View>
          <View style={styles.caption}>
            <Text style={styles.infoData}>Foto</Text>
          </View>
          <View style={styles.caption}>
            <Text style={styles.infoData}>Mappa PAI</Text>
          </View>
        </View>
        {/* ------------------------------------------- */}

        {/* GENERAL DATA SECTION ----------------------------- */}
        <View style={styles.container}>
          <Text style={styles.titleH2}>Dati Generali</Text>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '55%' }}>
              <Text style={styles.titleH3}>GEOMORFOLOGIA</Text>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Pericolosità PAI/PSAI</Text>
                <Text style={styles.infoData}>{pericoloPAIPSAI || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Unità fisiografica</Text>
                <Text style={styles.infoData}>{unitaFisiografica || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Tipologia di fenomeno</Text>
                <Text style={styles.infoData}>{tipologiaFenomeno || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Fenomeno</Text>
                <Text style={styles.infoData}>{fenomeno || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Posizione instabilità</Text>
                <Text style={styles.infoData}>
                  {posizioneInstabilita || '-'}
                </Text>
              </View>
            </View>

            <View style={{ width: '45%' }}>
              <Text style={styles.titleH3}>STRUTTURA</Text>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Anno costruzione</Text>
                <Text style={styles.infoData}>{annoCostruzione || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>N° campate</Text>
                <Text style={styles.infoData}>
                  {pdfData?.numeroCampate || '-'}
                </Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Lunghezza totale</Text>
                <Text style={styles.infoData}>
                  {pdfData?.luceComplessivaEstesa !== undefined
                    ? `${pdfData?.luceComplessivaEstesa} m`
                    : '-'}
                </Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Fondazioni</Text>
                <Text style={styles.infoData}>{fondazioni || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Materiale prevalente</Text>
                <Text style={styles.infoData}>
                  {materialePrevalente || '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* ----------------------------------------------------------- */}

        {/* CONTEXT & PARAMETERS SECTION ------------------------------------------- */}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ width: '49%' }}>
            <View style={[styles.container, { height: '108pt' }]}>
              <Text style={styles.titleH2}>Contesto</Text>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Estensione interferenza</Text>
                <Text style={styles.infoData}>
                  {estensioneInterferenza || '-'}
                </Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Uso del suolo</Text>
                <Text style={styles.infoData}>{usoSuolo || '-'}</Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Misure di mitigazione</Text>
                <Text style={styles.infoData}>{misureMitigazione || '-'}</Text>
              </View>
            </View>
          </View>
          <View style={{ width: '49%' }}>
            <View style={styles.container}>
              <Text style={styles.titleH2}>Parametri Frana</Text>
              {renderInstabilitaVersante()}
              <View style={styles.flexContainer}>
                {renderStatoAttivitaCriticita()}
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>
                  Massima velocità potenziale
                </Text>
                <Text style={styles.infoData}>
                  {maxVelocitaPotenziale || '-'}
                </Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>
                  Magnitudo su base volumetrica
                </Text>
                <Text style={styles.infoData}>
                  {magnitudoBaseVolumetrica || '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* ----------------------------------------------------------- */}

        {/* DATA FORM ------------------------------------------------- */}
        <View style={[styles.formData, { left: 16 }]}>
          <Text style={styles.infoData}>
            {`${
              formData?.denominazioneCommittente &&
              `${formData.denominazioneCommittente},`
            } ${formData?.indirizzo && `${formData.indirizzo},`} ${
              formData?.cap && `${formData.cap},`
            } ${formData?.citta && `${formData.citta},`} ${
              formData?.provincia && `(${formData.provincia})`
            }`}
          </Text>
        </View>
        <View style={[styles.formData, { right: 16, bottom: 16 }]}>
          <Image style={{ width: '80pt' }} src={LogoExport} />
        </View>
      </Page>
    </Document>
  );
};

export { SintesiCdAFrane };

SintesiCdAFrane.propTypes = {
  pdfData: PropTypes.object,
  formData: PropTypes.object,
};
