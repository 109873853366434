import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaTraversoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

const TraversiForm = ({
  traversiFields,
  selected,
  setSelected,
  traversiTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      traversiTableValue: [],
      tipologiaTraversoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('traversiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetTraversi();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeTraversi = () => {
    const tipologiaTraversoTableValue = getValues(
      'tipologiaTraversoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const traversi = [...traversiFields];

    traversi.map((x, index) => {
      if (ids?.includes(x?.codice)) {
        traversi[index] = {
          ...x,
          tipologiaTraverso: tipologiaTraversoTableValue,
          ...(tipologiaTraversoTableValue !== x.tipologiaTraverso
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'traverso',
                  tipologiaTraversoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(traversiTable, traversi);
    onResetTraversi();
  };

  const onConfirmChangeTraversi = () => {
    setOpenConfirmationDialog(false);
    changeTraversi();
  };

  const onSubmitTraversi = () => {
    const tipologiaTraversoTableValue = getValues(
      'tipologiaTraversoTableValue'
    );
    const ids = selected.map(el => el.codice);

    const traversi = [...traversiFields];

    const hasChangedTipologia = traversi.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          tipologiaTraversoTableValue !== el.tipologiaTraverso &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaTraverso)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeTraversi();
    }
  };

  const onResetTraversi = () => {
    setValue('traversiTableValue', []);
    setValue('tipologiaTraversoTableValue', '');
    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper
          size={3}
          infoMessage={INFO.traversiSelezionati.infoMessage}
        >
          <RHFTagAutocomplete
            multiple={true}
            id="tags-traversi"
            name={'traversiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={traversiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Traversi selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipologiaTraversoTableValue"
            label="Tipologia Traverso"
            defaultValue={''}
            onChange={e => {
              setValue('tipologiaTraversoTableValue', e.target.value);
            }}
            selectOptions={tipologiaTraversoOptions}
          />
        </GridWrap>
        <GridWrap item xs={6} alignSelf="center">
          <FormSubmitButtons
            onSubmit={onSubmitTraversi}
            onReset={onResetTraversi}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeTraversi}
      />
    </>
  );
};
TraversiForm.propTypes = {
  traversiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaTraverso: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  traversiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default TraversiForm;
