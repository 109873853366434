import { mappingOutCommon } from './mappingOutCommon';

export const mappingDataOutL1 = data => {
  const diagnosisData = {
    common: { ...mappingOutCommon(data) },
    L1: {
      rilevatoreRischioStrutturale: data.rilevatoreRischioStrutturale || null,
      dataIspezioneRischioStrutturale:
        data.dataIspezioneRischioStrutturale || null,
      accessoSottoPonte: data.accessoSottoPonte || null,
      accessoSottoPonteParziale: data.accessoSottoPonteParziale || null,
      prospettiIspezionati: data.prospettiIspezionati || null,
      rilevatoreRischioFrane: data.rilevatoreRischioFrane || null,
      dataIspezioneRischioFrane: data.dataIspezioneRischioFrane || null,
      // affidabilitaComplessivaRischioIdraulico:
      //   data.affidabilitaComplessivaRischioIdraulico || null,
      affidabilitaComplessivaValutazioneRischioIdraulico:
        data.affidabilitaComplessivaValutazioneRischioIdraulico || null,
      rilevatoreRischioIdraulico: data.rilevatoreRischioIdraulico || null,
      dataIspezioneRischioIdraulico: data.dataIspezioneRischioIdraulico || null,
      descrizioneAttivitaIspezione: data.descrizioneAttivitaIspezione || null,
      documentazioneMisureMitigazione:
        data.documentazioneMisureMitigazione || null,
      fenomeno: data.fenomeno || null,
      tipologiaFenomeno: data.tipologiaFenomeno || null,
      distribuzioneAttivita: data.distribuzioneAttivita || null,
      usoSuoloAreaPotezialmenteCoinvolta:
        data.usoSuoloAreaPotezialmenteCoinvolta || null,
      usoSuoloAreaPotezialmenteCoinvoltaAltro:
        data.usoSuoloAreaPotezialmenteCoinvoltaAltro || null,
      posizioneInstabilitaVersante: data.posizioneInstabilitaVersante || null,
      formazioniFrana: data.formazioniFrana || null,
      elementoRiferimentoFrana: data.elementoRiferimentoFrana || null,
      quotaOrloSuperiore: data.quotaOrloSuperiore || null,
      quotaPonteViadotto: data.quotaPonteViadotto || null,
      profonditaSuperficieDistacco: data.profonditaSuperficieDistacco || null,
      areaTotaleFrana: data.areaTotaleFrana || null,
      volumeMassaFrana: data.volumeMassaFrana || null,
      schemaRilievoFrana: data.schemaRilievoFrana || null,
      elaboratoGraficoFrana: data.elaboratoGraficoFrana || null,
      franeAttive: data.franeAttive || null,
      annotazioniRischioFrane: data.annotazioniRischioFrane || null,
      fotoAnnotazioniRischioFrane: data.fotoAnnotazioniRischioFrane || null,
      descrizioneInformazioniDiIspezioneAccordion6:
        data.descrizioneInformazioniDiIspezioneAccordion6 || null,
      fotoInformazioniDiIspezioneAccordion6:
        data.fotoInformazioniDiIspezioneAccordion6 || null,
      descrizioneRischioFraneAccordion6:
        data.descrizioneRischioFraneAccordion6 || null,
      fotoRischioFraneAccordion6: data.fotoRischioFraneAccordion6 || null,
      annotazioniRischioFraneAccordion6:
        data.annotazioniRischioFraneAccordion6 || null,
      fotoAnnotazioniRischioFraneAccordion6:
        data.fotoAnnotazioniRischioFraneAccordion6 || null,
      commentiConclusiviCdAFrane: data.commentiConclusiviCdAFrane || null,
      descrizioneDatiGeneraliAccordion6:
        data.descrizioneDatiGeneraliAccordion6 || null,
      fotoDatiGeneraliAccordion6: data.fotoDatiGeneraliAccordion6 || null,
      descrizioneCaratteristicheAlveoAccordion6:
        data.descrizioneCaratteristicheAlveoAccordion6 || null,
      fotoCaratteristicheAlveoAccordion6:
        data.fotoCaratteristicheAlveoAccordion6 || null,
      descrizioneSormontoAccordion6: data.descrizioneSormontoAccordion6 || null,
      fotoSormontoAccordion6: data.fotoSormontoAccordion6 || null,
      descrizioneErosioneAccordion6: data.descrizioneErosioneAccordion6 || null,
      fotoErosioneAccordion6: data.fotoErosioneAccordion6 || null,
      descrizioneOpereDiProtezioneAccordion6:
        data.descrizioneOpereDiProtezioneAccordion6 || null,
      fotoOpereDiProtezioneAccordion6:
        data.fotoOpereDiProtezioneAccordion6 || null,
      descrizioneAnnotazioniRischioIdraulicoAccordion6:
        data.descrizioneAnnotazioniRischioIdraulicoAccordion6 || null,
      fotoAnnotazioniRischioIdraulicoAccordion6:
        data.fotoAnnotazioniRischioIdraulicoAccordion6 || null,
      descrizioneCommentiConclusiviCdAIdraulicaAccordion6:
        data.descrizioneCommentiConclusiviCdAIdraulicaAccordion6 || null,
      possibilitaVerificaScalzamentoFondazioni:
        data.possibilitaVerificaScalzamentoFondazioni || null,
      strutturaAlveoRischioIdraulico:
        data.strutturaAlveoRischioIdraulico || null,
      fenomenoSormontoRischioIdraulico:
        data.fenomenoSormontoRischioIdraulico || null,
      fenomenoErosioneRischioIdraulico:
        data.fenomenoErosioneRischioIdraulico || null,
      scenariPericolositaRischioIdraulico:
        data.scenariPericolositaRischioIdraulico || null,
      areaPericolosaRischioIdraulico:
        data.areaPericolosaRischioIdraulico || null,
      presenzaRilieviRischioIdraulico:
        data.presenzaRilieviRischioIdraulico || null,
      bacinoIdrograficoRischioIdraulico:
        data.bacinoIdrograficoRischioIdraulico || null,
      bacinoIdrograficoEffettivoRischioIdraulico:
        data.bacinoIdrograficoEffettivoRischioIdraulico || null,
      tipologiaReticoloRischioIdraulico:
        data.tipologiaReticoloRischioIdraulico || null,
      ricadenteAreaAlluvioniRischioIdraulico:
        data.ricadenteAreaAlluvioniRischioIdraulico || null,
      confinamentoAlveoRischioIdraulico:
        data.confinamentoAlveoRischioIdraulico || null,
      morfologiaAlveoRischioIdraulico:
        data.morfologiaAlveoRischioIdraulico || null,
      morfologiaAlveoCanaleSingoloRischioIdraulico:
        data.morfologiaAlveoCanaleSingoloRischioIdraulico || null,
      tipologiaFondoAlveoRischioIdraulico:
        data.tipologiaFondoAlveoRischioIdraulico || null,
      materialeAlveoRischioIdraulico:
        data.materialeAlveoRischioIdraulico || null,
      accumuloDetritiRischioIdraulico:
        data.accumuloDetritiRischioIdraulico || null,
      fotoNaturaMaterialeAlveo: data.fotoNaturaMaterialeAlveo || null,
      evoluzioneAlveoRischioIdraulico:
        data.evoluzioneAlveoRischioIdraulico || null,
      fotoDivagazionePlanimetricaIdraulico:
        data.fotoDivagazionePlanimetricaIdraulico || null,
      tipologiaCorsoAcquaRischioIdraulico:
        data.tipologiaCorsoAcquaRischioIdraulico || null,
      rilievoPlanimetrico: data.rilievoPlanimetrico || null,
      rilevoSezioneTrasversale: data.rilevoSezioneTrasversale || null,
      rilievoSezioneLongitudinaleAlveo:
        data.rilievoSezioneLongitudinaleAlveo || null,
      elaboratoGraficoSezioneFluviale:
        data.elaboratoGraficoSezioneFluviale || null,
      riferimentoZeroMetriRischioIdraulico:
        data.riferimentoZeroMetriRischioIdraulico || null,
      quotaMinimaIntradossoRischioIdraulico:
        data.quotaMinimaIntradossoRischioIdraulico || null,
      quotaMinimaIntradossoFlagRilevabileRischioIdraulico:
        data.quotaMinimaIntradossoFlagRilevabileRischioIdraulico || null,
      quotaMinimaIntradossoEffettivo:
        data.quotaMinimaIntradossoEffettivo || null,
      quotaMinimaIntradossoPresunto: data.quotaMinimaIntradossoPresunto || null,
      larghezzaAlveoPileSpalleRilevabile:
        data.larghezzaAlveoPileSpalleRilevabile || null,
      larghezzaAlveoPileSpalleNonRilevabile:
        data.larghezzaAlveoPileSpalleNonRilevabile || null,
      larghezzaGoleneRilevabile: data.larghezzaGoleneRilevabile || null,
      larghezzaGoleneNonRilevabile: data.larghezzaGoleneNonRilevabile || null,
      larghezzaPilaOAggettoSpallaEffettiva:
        data.larghezzaPilaOAggettoSpallaEffettiva || null,
      larghezzaPilaOAggettoSpallaPresunta:
        data.larghezzaPilaOAggettoSpallaPresunta || null,
      profonditaFondazioneRispettoAlveoEffettiva:
        data.profonditaFondazioneRispettoAlveoEffettiva || null,
      profonditaFondazioneRispettoAlveoPresunta:
        data.profonditaFondazioneRispettoAlveoPresunta || null,
      quotaPeloLiberoP2RischioIdraulico:
        data.quotaPeloLiberoP2RischioIdraulico || null,
      quotaPeloLiberoP3RischioIdraulico:
        data.quotaPeloLiberoP3RischioIdraulico || null,
      quotaArgineMinRischioIdraulico:
        data.quotaArgineMinRischioIdraulico || null,
      quotaSpondaMaxRischioIdraulico:
        data.quotaSpondaMaxRischioIdraulico || null,
      quotaFondoAlveoRischioIdraulico:
        data.quotaFondoAlveoRischioIdraulico || null,
      quotaPeloLiberoStimaRischioIdraulico:
        data.quotaPeloLiberoStimaRischioIdraulico || null,
      quotaPeloLiberoStimaFlagRischioIdraulico:
        data.quotaPeloLiberoStimaFlagRischioIdraulico || null,
      fotoDepositoSedimenti: data.fotoDepositoSedimenti || null,
      fotoTrasportoMateriale: data.fotoTrasportoMateriale || null,
      larghezzaAlveoPileSpalle: data.larghezzaAlveoPileSpalle ?? null,
      larghezzaAlveoPileSpalleFlag: data.larghezzaAlveoPileSpalleFlag || null,
      quotaPeloLiberoStimataRilevabile:
        data.quotaPeloLiberoStimataRilevabile || null,
      quotaPeloLiberoStimataNonRilevabile:
        data.quotaPeloLiberoStimataNonRilevabile || null,
      larghezzaAlveoPonte: data.larghezzaAlveoPonte || null,
      areeGolenaliAssenti: data.areeGolenaliAssenti || null,
      larghezzaComplessivaGolene: data.larghezzaComplessivaGolene || null,
      larghezzaComplessivaGoleneFlag:
        data.larghezzaComplessivaGoleneFlag || null,
      larghezzaComplessivaGolenePonte:
        data.larghezzaComplessivaGolenePonte || null,
      larghezzaPilaOAggettoSpalla: data.larghezzaPilaOAggettoSpalla ?? null,
      larghezzaPilaOAggettoSpallaFlag:
        data.larghezzaPilaOAggettoSpallaFlag || null,
      profonditaScavoMax: data.profonditaScavoMax ?? null,
      profonditaFondazioneRispettoAlveo:
        data.profonditaFondazioneRispettoAlveo || null,
      profonditaFondazioneRispettoAlveoFlag:
        data.profonditaFondazioneRispettoAlveoFlag || null,
      evoluzioneEventualeDelFondo: data.evoluzioneEventualeDelFondo || null,
      fotoProfonditaFondazioni: data.fotoProfonditaFondazioni || null,
      abbassamentoAccentuatoAlleFondazioni:
        data.abbassamentoAccentuatoAlleFondazioni || null,
      fotoAbassamentoAlveo: data.fotoAbassamentoAlveo || null,
      fotoAccumuliMonteDellaPila: data.fotoAccumuliMonteDellaPila || null,
      opereProtezioneSpondale: data.opereProtezioneSpondale || null,
      opereProtezioneSpondaleAltro: data.opereProtezioneSpondaleAltro || null,
      statoConservazioneAdeguatezzaOpera:
        data.statoConservazioneAdeguatezzaOpera || null,
      fotoProtezioneSpondale: data.fotoProtezioneSpondale || null,
      statoConservazioneProtezionePiede:
        data.statoConservazioneProtezionePiede || null,
      fotoProtezionePileSpalle: data.fotoProtezionePileSpalle || null,
      statoConservazioneAdeguatezzaOperaBriglia:
        data.statoConservazioneAdeguatezzaOperaBriglia || null,
      fotoBrigliaDiProtezione: data.fotoBrigliaDiProtezione || null,
      opereArginaturaDiAlterazionePortate:
        data.opereArginaturaDiAlterazionePortate || null,
      statoConservazioneAdeguatezzaOpereArginatura:
        data.statoConservazioneAdeguatezzaOpereArginatura || null,
      tipologiaOpereArginatura: data.tipologiaOpereArginatura || null,
      tipologiaAreePossibileAllagamento:
        data.tipologiaAreePossibileAllagamento || null,
      misureLaminazioneMitigazione: data.misureLaminazioneMitigazione || null,
      statoConservazioneAdeguatezzaOpereLaminazione:
        data.statoConservazioneAdeguatezzaOpereLaminazione || null,
      tipologiaMisureLaminazioneMitigazione:
        data.tipologiaMisureLaminazioneMitigazione || null,
      sistemaMonitoraggioRischioIdraulico:
        data.sistemaMonitoraggioRischioIdraulico || null,
      tipologiaSistemaMonitoraggioRischioIdraulico:
        data.tipologiaSistemaMonitoraggioRischioIdraulico || null,
      statoConservazioneSistemaMonitoraggioRischioIdraulico:
        data.statoConservazioneSistemaMonitoraggioRischioIdraulico || null,
      annotazioniRischioIdraulico: data.annotazioniRischioIdraulico || null,
      fotoAnnotazioneRischioIdraulico:
        data.fotoAnnotazioneRischioIdraulico || null,
      elCriticiVulnerabilitaStrutturaleCriticiStatoDegrado:
        data.elCriticiVulnerabilitaStrutturaleCriticiStatoDegrado || null,
      elCriticiVulnerabilitaStrutturaleTipologiaElemento:
        data.elCriticiVulnerabilitaStrutturaleTipologiaElemento || null,
      elCriticiVulnerabilitaStrutturaleDescrizioneSintetica:
        data.elCriticiVulnerabilitaStrutturaleDescrizioneSintetica || null,
      elCriticiVulnerabilitaStrutturaleFoto:
        data.elCriticiVulnerabilitaStrutturaleFoto || null,
      elCriticiVulnerabilitaStrutturaleStatoDegrado:
        data.elCriticiVulnerabilitaStrutturaleStatoDegrado || null,
      elCriticiVulnerabilitaSismicaStatoDegrado:
        data.elCriticiVulnerabilitaSismicaStatoDegrado || null,
      elCriticiVulnerabilitaSismicaTipologiaElemento:
        data.elCriticiVulnerabilitaSismicaTipologiaElemento || null,
      elCriticiVulnerabilitaSismicaDescrizioneSintetica:
        data.elCriticiVulnerabilitaSismicaDescrizioneSintetica || null,
      elCriticiVulnerabilitaSismicaFoto:
        data.elCriticiVulnerabilitaSismicaFoto || null,
      condizioniCriticheVulnerabilitaStrutturaTipologiaEl:
        data.condizioniCriticheVulnerabilitaStrutturaTipologiaEl || null,
      condizioniCriticheVulnerabilitaStrutturaAltro:
        data.condizioniCriticheVulnerabilitaStrutturaAltro || null,
      condizioniCriticheVulnerabilitaStrutturaFoto:
        data.condizioniCriticheVulnerabilitaStrutturaFoto || null,
      condizioniCriticheVulnerabilitaSismicaTipologiaEl:
        data.condizioniCriticheVulnerabilitaSismicaTipologiaEl || null,
      condizioniCriticheVulnerabilitaSismicaTipologiaAltro:
        data.condizioniCriticheVulnerabilitaSismicaTipologiaAltro || null,
      condizioniCriticheVulnerabilitaSismicaFoto:
        data.condizioniCriticheVulnerabilitaSismicaFoto || null,
      elementiVulnerabilitaSismicaDescrizione:
        data.elementiVulnerabilitaSismicaDescrizione || null,
      elementiVulnerabilitaSismicaFoto:
        data.elementiVulnerabilitaSismicaFoto || null,
      descrizioneStrutturaElevazione:
        data.descrizioneStrutturaElevazione || null,
      fotoDescrizioneStrutturaElevazione:
        data.fotoDescrizioneStrutturaElevazione || null,
      descrizioneStrutturaCollegamenti:
        data.descrizioneStrutturaCollegamenti || null,
      fotoStrutturaCollegamenti: data.fotoStrutturaCollegamenti || null,
      descrizioneStrutturaImpalcato: data.descrizioneStrutturaImpalcato || null,
      fotoStrutturaImpalcato: data.fotoStrutturaImpalcato || null,
      descrizioneStrutturaAspettiCritici:
        data.descrizioneStrutturaAspettiCritici || null,
      fotoStrutturaAspettiCritici: data.fotoStrutturaAspettiCritici || null,
      descrizioneRischioStrutturaleFondazionale:
        data.descrizioneRischioStrutturaleFondazionale || null,
      fotoRischioStruttraleFondazionale:
        data.fotoRischioStruttraleFondazionale || null,
      descrizioneStrutturaRischioSismico:
        data.descrizioneStrutturaRischioSismico || null,
      fotoStrutturaRischioSismico: data.fotoStrutturaRischioSismico || null,
      commentiConclusiviCdAStrutturaleFondazionale:
        data.commentiConclusiviCdAStrutturaleFondazionale || null,
      commentiConclusiviCdASismica: data.commentiConclusiviCdASismica || null,
      descrizioneSovrastrutturaImpalcato:
        data.descrizioneSovrastrutturaImpalcato || null,
      fotoSovrastrutturaImpalcato: data.fotoSovrastrutturaImpalcato || null,
      descrizioneOsservazioniSovrastrutture:
        data.descrizioneOsservazioniSovrastrutture || null,
      descrizioneServiziPali: data.descrizioneServiziPali || null,
      fotoServiziPali: data.fotoServiziPali || null,
      descrizioneServiziAcque: data.descrizioneServiziAcque || null,
      fotoServiziAcque: data.fotoServiziAcque || null,
      descrizioneServiziSottoservizi:
        data.descrizioneServiziSottoservizi || null,
      fotoServiziSottoservizi: data.fotoServiziSottoservizi || null,
      commentiConclusiviServizi: data.commentiConclusiviServizi || null,
    },
  };

  const diagnosis = JSON.stringify(diagnosisData);

  return diagnosis;
};
