import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import { colors } from 'constants/colors';
import { MessageContainer } from './Chart.styles';

export const ColumnsChart = ({
  chartID,
  data,
  config,
  isClustered,
  hasLegend,
  error,
}) => {
  useLayoutEffect(() => {
    if (!data) return;
    const root = am5.Root.new(chartID);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: false,
        wheelY: false,
        pinchZoomX: false,
      })
    );

    /* chart.setAll({
      tooltipText: isClustered ? '{name}, {categoryX}:{valueY}' : '{valueY}',
      showTooltipOn: 'always',
      tooltip: am5.Tooltip.new(root, {}),
    }); */

    // Set legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        y: am5.percent(100),
        centerY: am5.p100,
      })
    );

    legend.labels.template.setAll({
      fontSize: 12,
      maxWidth: 1,
    });

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });

    /* const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false); */

    // Create axes
    const xRenderer = am5xy.AxisRendererX.new(root, {
      ...(!isClustered && { minGridDistance: 20 }),
      ...(isClustered && {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
      }),
    });

    xRenderer.labels.template.setAll({
      paddingTop: 10,
      paddingBottom: 40,
      fontSize: 13,
      ...(!isClustered && {
        /* rotation: -90,
        paddingTop: 0, */
        oversizedBehavior: 'wrap',
        maxWidth: 110,
        textAlign: 'center',
      }),
    });

    xRenderer.grid.template.setAll({
      location: 1,
    });

    const clusterLabel = 'label';

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: clusterLabel,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
          /* maxWidth: 30, */
        }),
      })
    );

    // Create series
    const makeSeries = (name, fieldName) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: clusterLabel,
        })
      );

      const DEFAULT_SERIES_COLOR = colors.SPINDLE;

      series.set(
        'fill',
        am5.color(config?.[fieldName]?.color || DEFAULT_SERIES_COLOR)
      );

      series.columns.template.setAll({
        /* ...(isClustered && {tooltipText: '{name}, {categoryX}:{valueY}' ,}), */
        ...(isClustered && { tooltipText: '{valueY}' }),
        width: isClustered ? am5.percent(100) : 40,
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: '{valueY}',
            fill: root.interfaceColors.get('alternativeText'),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      if (!isClustered) {
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 1,
            sprite: am5.Label.new(root, {
              centerX: am5.p50,
              centerY: am5.p100,
              text: '{valueY}',
              populateText: true,
            }),
          });
        });
      }

      hasLegend && legend.data.push(series);

      series.data.setAll(data);
      series.appear();
    };

    // Set data
    xAxis.data.setAll(data);

    Object.keys(data[0] || {}).map(
      k => k !== clusterLabel && makeSeries(config?.[k]?.label || k, k)
    );

    chart.appear(1000, 100);
    return () => {
      chart.dispose();
    };
  }, [chartID, data]);

  return (
    <div style={{ width: '100%', height: '460px' }} id={chartID}>
      {error && (
        <MessageContainer>
          <Typography variant="h3Bold">
            {`Si è verificato un errore. Impossibile visualizzare il grafico.`}
          </Typography>
        </MessageContainer>
      )}
    </div>
  );
};

const configShape = {
  label: PropTypes.string,
  color: PropTypes.string,
};

ColumnsChart.propTypes = {
  chartID: PropTypes.string,
  data: PropTypes.object,
  config: PropTypes.shape(configShape),
  isClustered: PropTypes.bool,
  hasLegend: PropTypes.bool,
  error: PropTypes.bool,
};
