import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import {
  handleArrayData,
  handleEnum,
} from 'components/ExportsPanel/ExportSelect/functions';
import {
  AnnoCostruzioneExportOptions,
  CategoriaTopograficaShortOptions,
  ClassificazioneUsoStradaleOptions,
  EffettivaPresuntaOptions,
  PresentiAssentiOptions,
  SismicitiaAreaValApprossimativoOptions,
  TGMCommercialiVeicoliGiornoValoreApprossimativoPDFOptions,
  TGMVeicoliGiornoValoreApprossimativoPDFOptions,
  tipoEnteScavalcatoOptions,
  tipologiaImpalcatoOptions,
  tipologiaSchemaOptions,
  YesNoOptions,
} from 'constants/selectOptions';
import { formatData } from 'utils/utilities';
import { styles } from './SintesiCdASismica.styles';
import CalendarIcon from '../../../../assets/icons/calendar.png';
import CheckGreenIcon from '../../../../assets/icons/check-green.png';
import CriticalAspectIcon from '../../../../assets/icons/critical-aspect.png';
import LogoExport from '../../../../assets/icons/logoEsportazione.png';
import UserIcon from '../../../../assets/icons/user.png';
import { CdaColorMap } from '../SintesiCdAFrane/constants';

const SintesiCdASismica = ({ pdfData, formData }) => {
  const enteScavalcato = handleArrayData(
    pdfData?.tipologiaEnteScavalcato,
    tipoEnteScavalcatoOptions
  );

  let ag;
  if (pdfData?.sismicitaValoreEffettivo) {
    ag = pdfData?.sismicitaValoreEffettivo;
  } else if (
    pdfData?.sismicitaValoreApprossimativo &&
    !pdfData?.sismicitaValoreEffettivo
  ) {
    ag = handleEnum(
      SismicitiaAreaValApprossimativoOptions,
      pdfData?.sismicitaValoreApprossimativo
    );
  }
  const categoriaTopografica = handleEnum(
    CategoriaTopograficaShortOptions,
    pdfData?.categoriaTopografica
  );
  const categoriaSottosuolo = handleEnum(
    EffettivaPresuntaOptions,
    pdfData?.categoriaSottosuoloAltro
  );

  //Dati Generali - Rete stradale
  const usoStradale = handleEnum(
    ClassificazioneUsoStradaleOptions,
    pdfData?.classificazioneUsoStradale
  );

  const alternativeStradali = handleEnum(
    YesNoOptions,
    pdfData?.alternativeStradali
  );
  const operaStrategica = handleEnum(
    YesNoOptions,
    pdfData?.itinerarioEmergenza
  );
  let tgm;
  if (pdfData?.valoreEffettivoTGM) {
    tgm = pdfData?.valoreEffettivoTGM;
  } else if (pdfData?.valoreApprossimativoTGM && !pdfData?.valoreEffettivoTGM) {
    tgm = handleEnum(
      TGMVeicoliGiornoValoreApprossimativoPDFOptions,
      pdfData?.valoreApprossimativoTGM
    );
  }
  let tgmCommerciali;
  if (pdfData?.valoreEffettivoCommercialiTGM) {
    tgmCommerciali = pdfData?.valoreEffettivoCommercialiTGM;
  } else if (
    pdfData?.valoreApprossimativoCommercialiTGM &&
    !pdfData?.valoreEffettivoCommercialiTGM
  ) {
    tgmCommerciali = handleEnum(
      TGMCommercialiVeicoliGiornoValoreApprossimativoPDFOptions,
      pdfData?.valoreApprossimativoCommercialiTGM
    );
  }

  //Dati Generali - Struttura
  let annoCostruzione;
  if (pdfData?.dataEffettivaAnnoDiCostruzione) {
    annoCostruzione = pdfData?.dataEffettivaAnnoDiCostruzione;
  } else if (
    pdfData?.annoDiCostruzioneApprossimativo &&
    !pdfData?.dataEffettivaAnnoDiCostruzione
  ) {
    annoCostruzione = handleEnum(
      AnnoCostruzioneExportOptions,
      pdfData?.annoDiCostruzioneApprossimativo
    );
  }
  const schemaStatico = handleEnum(
    tipologiaSchemaOptions,
    pdfData?.tipologiaSchema
  );
  let fondazioni;
  switch (pdfData?.fondazioni) {
    case 1:
      fondazioni = 'Non nota';
      break;
    case 2:
      fondazioni = 'Diretta';
      break;
    case 3:
      fondazioni = 'Indiretta';
      break;
  }
  const materialePrevalente = handleEnum(
    tipologiaImpalcatoOptions,
    pdfData?.tipologiaImpalcato
  );
  const vulnerabilitaSismiche = handleEnum(
    PresentiAssentiOptions,
    pdfData?.elementiVulnerabilitaSismica
  );
  const criteriSismiciInOpera = handleEnum(
    YesNoOptions,
    pdfData?.criteriSismiciInOpera
  );
  const dispositiviAntisismici = handleEnum(
    YesNoOptions,
    pdfData?.dispositiviAntiSismici
  );

  //Stato di Conservazione - Tabella
  const elevazioni = [
    pdfData?.table?.elevazioniCounts?.g1,
    pdfData?.table?.elevazioniCounts?.g2,
    pdfData?.table?.elevazioniCounts?.g3,
    pdfData?.table?.elevazioniCounts?.g4,
    pdfData?.table?.elevazioniCounts?.g5,
    pdfData?.table?.elevazioniCounts?.ps,
  ];
  const collegamenti = [
    pdfData?.table?.collegamentiCounts?.g1,
    pdfData?.table?.collegamentiCounts?.g2,
    pdfData?.table?.collegamentiCounts?.g3,
    pdfData?.table?.collegamentiCounts?.g4,
    pdfData?.table?.collegamentiCounts?.g5,
    pdfData?.table?.collegamentiCounts?.ps,
  ];
  const impalcato = [
    pdfData?.table?.impalcatoCounts?.g1,
    pdfData?.table?.impalcatoCounts?.g2,
    pdfData?.table?.impalcatoCounts?.g3,
    pdfData?.table?.impalcatoCounts?.g4,
    pdfData?.table?.impalcatoCounts?.g5,
    pdfData?.table?.impalcatoCounts?.ps,
  ];

  const renderStatusCdA = () => {
    if (pdfData?.cdaSismica) {
      return (
        <View
          style={[
            styles.infoTagGreyTitle,
            {
              backgroundColor: CdaColorMap[pdfData.cdaSismica].labelColor,
            },
          ]}
        >
          <Text style={styles.tagTextWhiteTitle}>
            {CdaColorMap[pdfData.cdaSismica].labelText}
          </Text>
        </View>
      );
    }
    return (
      <View style={styles.infoTagGreyTitle}>
        <Text style={styles.tagTextWhiteTitle}>DA DEFINIRE</Text>
      </View>
    );
  };

  const renderDifettosita = () => {
    if (pdfData.difettositaSismica) {
      return (
        <View
          style={[
            styles.infoTagConservazione,
            {
              backgroundColor:
                CdaColorMap[pdfData.difettositaSismica].labelColor,
            },
          ]}
        >
          <Text style={styles.tagTextInSection}>{`DIFETTOSITÀ ${
            CdaColorMap[pdfData.difettositaSismica].labelText
          }`}</Text>
        </View>
      );
    } else {
      return (
        <View
          style={[styles.infoTagConservazione, { backgroundColor: '#C3C3C3' }]}
        >
          <Text style={styles.tagTextInSection}>{`DIFETTOSITÀ N.D.`}</Text>
        </View>
      );
    }
  };

  let parseIC;
  if (pdfData?.ic) {
    parseIC = parseInt(pdfData.ic);
  } else {
    parseIC = '-';
  }

  const renderIC = () => {
    if (parseIC < 95) {
      return (
        <View style={styles.icTag}>
          <Text style={styles.tagText}>{`${pdfData.ic}%`}</Text>
        </View>
      );
    } else if (parseIC >= 95) {
      return (
        <View style={styles.icTagGrey}>
          <Text style={styles.tagText}>{`${pdfData.ic}%`}</Text>
        </View>
      );
    } else if (typeof parseIC === 'string' || isNaN(parseIC)) {
      return (
        <View style={styles.icTagGrey}>
          <Text style={styles.tagText}>{'-'}</Text>
        </View>
      );
    }
  };

  const renderCriticalElements = (data, label) => {
    if (data === 1) {
      return (
        <View style={styles.rilevantAspects}>
          <View
            style={[
              styles.iconElementContainer,
              {
                backgroundColor: 'transparent',
                width: 10,
                height: 10,
              },
            ]}
          >
            <Image src={CriticalAspectIcon} />
          </View>

          <View style={[styles.flexContainer, { marginTop: '4pt' }]}>
            <Text style={styles.infoLabel}>{label}</Text>
            <Text style={styles.infoData}>Sì</Text>
          </View>
        </View>
      );
    } else if (data === 2) {
      return (
        <View style={styles.rilevantAspects}>
          <View
            style={[
              styles.iconElementContainer,
              {
                backgroundColor: 'transparent',
                width: 10,
                height: 10,
              },
            ]}
          >
            <Image src={CheckGreenIcon} />
          </View>

          <View style={[styles.flexContainer, { marginTop: '4pt' }]}>
            <Text style={styles.infoLabel}>{label}</Text>
            <Text style={styles.infoData}>No</Text>
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.rilevantAspects}>
          <View
            style={[
              styles.iconElementContainer,
              {
                backgroundColor: 'transparent',
                width: 10,
                height: 10,
              },
            ]}
          ></View>
          <View style={[styles.flexContainer, { marginTop: '4pt' }]}>
            <Text style={styles.infoLabel}>{label}</Text>
            <Text style={styles.infoData}>-</Text>
          </View>
        </View>
      );
    }
  };

  const defectImages = pdfData?.quattroFotoDifetti;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.wrapperCdA}>
          <Text style={{ fontSize: '12pt', fontFamily: 'Helvetica-Bold' }}>
            CDA SISMICA
          </Text>
          {renderStatusCdA()}
        </View>
        <View
          style={{
            marginTop: 8,
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <View style={styles.iconElementContainer}>
            <Image src={CalendarIcon} />
          </View>
          <Text style={styles.infoLabel}>
            {formatData(pdfData?.dataIspezioneRischioStrutturale) || '-'}
          </Text>
          <View
            style={[
              styles.iconElementContainer,
              {
                marginLeft: 10,
              },
            ]}
          >
            <Image src={UserIcon} />
          </View>
          <Text style={[styles.infoLabel, { lineHeight: 0 }]}>
            {pdfData?.rilevatoreRischioStrutturale || '-'}
          </Text>
        </View>

        <View style={{ marginTop: '8pt' }}>
          <Text style={styles.title}>{pdfData?.nomeOpera}</Text>
        </View>

        <View style={styles.flexContainer}>
          <Text style={styles.infoLabel}>Strada di appartenenza</Text>
          <Text style={styles.infoData}>
            {pdfData?.stradaAppartenenza || '-'}
          </Text>
        </View>

        <View style={styles.flexContainer}>
          <Text style={styles.infoLabel}>Progressiva Km Iniziale</Text>
          <Text style={styles.infoData}>
            {pdfData?.progressivaKmIniziale || '-'}
          </Text>
        </View>

        <View style={styles.flexContainer}>
          <Text style={styles.infoLabel}>Ente scavalcato</Text>
          <Text style={styles.infoData}>{enteScavalcato || '-'}</Text>
        </View>

        {/* PHOTO SECTION ----------------------------- */}
        <View style={styles.imagesContainer}>
          {pdfData?.satellitareFotoAereeAttuale ? (
            <Image
              style={styles.image}
              src={{
                uri: pdfData?.satellitareFotoAereeAttuale,
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                  'Access-Control-Allow-Origin': '*',
                },
                body: '',
              }}
            />
          ) : (
            <View style={styles.placeholderImage} />
          )}

          {pdfData?.fotoCopertina ? (
            <Image
              style={styles.image}
              src={{
                uri: pdfData?.fotoCopertina,
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                  'Access-Control-Allow-Origin': '*',
                },
                body: '',
              }}
            />
          ) : (
            <View style={styles.placeholderImage} />
          )}
          <View style={[styles.imageData, { justifyContent: 'flex-end' }]}>
            <View style={styles.flexContainer}>
              <Text style={styles.infoLabel}>Zona sismica</Text>
              <Text style={styles.infoData}>
                {pdfData?.classificazioneSismica || '-'}
              </Text>
            </View>
            <View style={styles.flexContainer}>
              <Text style={styles.infoLabel}>
                a<Text style={[styles.infoLabel, { fontSize: 5 }]}>g</Text>
              </Text>
              <Text style={styles.infoData}>{ag || '-'}</Text>
            </View>
            <View style={styles.flexContainer}>
              <Text style={styles.infoLabel}>Categoria topografica</Text>
              <Text style={styles.infoData}>{categoriaTopografica || '-'}</Text>
            </View>
            <View style={styles.flexContainer}>
              <Text style={styles.infoLabel}>Categoria sottosuolo</Text>
              <Text style={styles.infoData}>{categoriaSottosuolo || '-'}</Text>
            </View>
          </View>
        </View>

        <View style={styles.captionsContainer}>
          <View style={styles.caption}>
            <Text style={styles.infoData}>Ortofoto</Text>
          </View>
          <View style={styles.caption}>
            <Text style={styles.infoData}>Foto</Text>
          </View>
          <View style={styles.imageData}>
            <Text style={styles.infoData}></Text>
          </View>
        </View>
        {/* ------------------------------------------- */}

        {/* GENERAL DATA SECTION ----------------------------- */}
        <View style={styles.container}>
          <Text style={styles.titleH2}>Dati Generali</Text>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '55%' }}>
              <Text style={styles.titleH3}>RETE STRADALE</Text>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Uso stadale</Text>
                <Text style={styles.infoData}>{usoStradale || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Alternative stradali</Text>
                <Text style={styles.infoData}>
                  {alternativeStradali || '-'}
                </Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Opera strategica</Text>
                <Text style={styles.infoData}>{operaStrategica || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>TGM</Text>
                <Text style={styles.infoData}>{tgm || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>TGM commerciali</Text>
                <Text style={styles.infoData}>{tgmCommerciali || '-'}</Text>
              </View>
            </View>

            <View style={{ width: '45%' }}>
              <Text style={styles.titleH3}>STRUTTURA</Text>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Anno costruzione</Text>
                <Text style={styles.infoData}>{annoCostruzione || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Schema statico</Text>
                <Text style={styles.infoData}>{schemaStatico || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Fondazioni</Text>
                <Text style={styles.infoData}>{fondazioni || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Materiale prevalente</Text>
                <Text style={styles.infoData}>
                  {materialePrevalente || '-'}
                </Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Vulnerabilità sismiche</Text>
                <Text style={styles.infoData}>
                  {vulnerabilitaSismiche || '-'}
                </Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Criteri sismici in opera</Text>
                <Text style={styles.infoData}>
                  {criteriSismiciInOpera || '-'}
                </Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Dispositivi antisismici</Text>
                <Text style={styles.infoData}>
                  {dispositiviAntisismici || '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* ----------------------------------------------------------- */}

        {/* DEFECT STATUS SECTION ------------------------------------------- */}
        <View style={[styles.container, { paddingBottom: '0pt' }]}>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View style={{ width: '50%' }}>
              <Text style={styles.titleH2}>Stato di Conservazione</Text>
              <View style={styles.flexContainer}>
                {renderDifettosita()}
                <Text style={[styles.infoLabel, { marginLeft: '10pt' }]}>
                  IC
                </Text>
                {renderIC()}
              </View>

              <View style={styles.table}>
                <View style={styles.rowLabel}>
                  <Text style={styles.rowLabelText}>Elevazioni</Text>
                  <Text style={styles.rowLabelText}>Collegamenti</Text>
                  <Text style={styles.rowLabelText}>Impalcato</Text>
                </View>
                <View>
                  <View style={styles.row}>
                    <Text style={styles.row1}>G1</Text>
                    <Text style={styles.row2}>G2</Text>
                    <Text style={styles.row2}>G3</Text>
                    <Text style={styles.row2}>G4</Text>
                    <Text style={styles.row2}>G5</Text>
                    <Text style={styles.row2}>PS</Text>
                  </View>
                  {/* ELEVAZIONI */}
                  <View style={styles.row} wrap={false}>
                    <Text style={styles.rowBody1}>
                      {elevazioni[0] >= 0 ? elevazioni[0] : '-'}
                    </Text>
                    {elevazioni?.slice(1)?.map((el, idx) => (
                      <Text key={idx} style={styles.rowBody2}>
                        {el >= 0 ? el : '-'}
                      </Text>
                    ))}
                  </View>
                  {/* COLLEGAMENTI */}
                  <View style={styles.row} wrap={false}>
                    <Text style={styles.rowBody1}>
                      {collegamenti[0] >= 0 ? collegamenti[0] : '-'}
                    </Text>
                    {collegamenti?.slice(1)?.map((el, idx) => (
                      <Text key={idx} style={styles.rowBody2}>
                        {el >= 0 ? el : '-'}
                      </Text>
                    ))}
                  </View>
                  {/* IMPALCATO */}
                  <View style={styles.row} wrap={false}>
                    <Text style={styles.rowBody1}>
                      {impalcato[0] >= 0 ? impalcato[0] : '-'}
                    </Text>
                    {impalcato?.slice(1)?.map((el, idx) => (
                      <Text key={idx} style={styles.rowBody2}>
                        {el >= 0 ? el : '-'}
                      </Text>
                    ))}
                  </View>
                </View>
              </View>

              {/* CRITICAL ELEMENTS & CRITICAL CONDITIONS */}
              {renderCriticalElements(
                pdfData?.elementiCritici,
                'Elementi critici'
              )}
              {renderCriticalElements(
                pdfData?.condizioniCritiche,
                'Condizioni critiche'
              )}
            </View>

            <View
              style={{
                width: '50%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {defectImages?.map((img, idx) =>
                img !== null ? (
                  <Image
                    key={idx}
                    src={{
                      uri: img,
                      method: 'GET',
                      headers: {
                        'Cache-Control': 'no-cache',
                        'Access-Control-Allow-Origin': '*',
                      },
                      body: '',
                    }}
                    style={[
                      styles.placeholderImage,
                      {
                        width: '48%',
                        height: '90pt',
                        marginBottom: '10pt',
                        backgroundColor: 'transparent',
                      },
                    ]}
                  />
                ) : (
                  <View
                    key={idx}
                    style={[
                      styles.placeholderImage,
                      { width: '48%', height: '90pt', marginBottom: '10pt' },
                    ]}
                  />
                )
              )}
            </View>
          </View>
        </View>

        {/* ----------------------------------------------------------- */}

        {/* DATA FORM ------------------------------------------------- */}
        <View style={[styles.formData, { left: 16 }]}>
          <Text style={styles.infoData}>
            {`${
              formData?.denominazioneCommittente &&
              `${formData.denominazioneCommittente},`
            } ${formData?.indirizzo && `${formData.indirizzo},`} ${
              formData?.cap && `${formData.cap},`
            } ${formData?.citta && `${formData.citta},`} ${
              formData?.provincia && `(${formData.provincia})`
            }`}
          </Text>
        </View>
        <View style={[styles.formData, { right: 16, bottom: 16 }]}>
          <Image style={{ width: '80pt' }} src={LogoExport} />
        </View>
      </Page>
    </Document>
  );
};

export { SintesiCdASismica };

SintesiCdASismica.propTypes = {
  pdfData: PropTypes.object,
  formData: PropTypes.object,
};
