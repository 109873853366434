import { Divider, Grid, InputAdornment } from '@mui/material';
import { add } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import {
  RHFApiAutocomplete,
  RHFDatePicker,
  RHFNumberTextField,
  RHFTextField,
} from 'components/form';
import { apiGeo } from 'constants/api';
import { UserTypology } from 'constants/users';

const InstitutionForm = () => {
  const { watch } = useFormContext();

  return (
    <>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Divider>INFORMAZIONI ENTE (ACCOUNT PRINCIPALE)</Divider>
        </Grid>
        {watch('tipologiaUtente') &&
          watch('tipologiaUtente') === UserTypology.UtentePrincipale && (
            <>
              <Grid item xs={6}>
                <RHFTextField label="Ente / Società" name="businessName" />
              </Grid>
              <Grid item xs={3}>
                <RHFTextField label="Telefono" name="phone" />
              </Grid>
              <Grid item xs={3}>
                <RHFTextField label="C.F./P.IVA" name="fiscalCode" />
              </Grid>
              <Grid item xs={3}>
                <RHFApiAutocomplete
                  name="region"
                  label={'Regione'}
                  endpoint={`${apiGeo.ROOT}${apiGeo.REGIONS}?`}
                  getOptionLabel={option =>
                    option?.label && option?.value ? `${option?.label}` : option
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <RHFApiAutocomplete
                  name="province"
                  label={'Provincia'}
                  endpoint={`${apiGeo.ROOT}${apiGeo.PROVINCES}?parentId=${
                    watch('region')?.value?.id
                  }`}
                  getOptionLabel={option =>
                    option?.label && option?.value ? `${option?.label}` : option
                  }
                  disabled={!watch('region')}
                />
              </Grid>
              <Grid item xs={3}>
                <RHFApiAutocomplete
                  name="council"
                  label={'Comune'}
                  endpoint={`${apiGeo.ROOT}${apiGeo.COUNCILS}?parentId=${
                    watch('province')?.value?.id
                  }`}
                  getOptionLabel={option =>
                    option?.label && option?.value ? `${option?.label}` : option
                  }
                  disabled={!watch('province')}
                />
              </Grid>
              <Grid item xs={3}>
                <RHFTextField label="Indirizzo" name="address" />
              </Grid>
              <Grid item xs={12}>
                <Divider>SOTTOSCRIZIONE</Divider>
              </Grid>
              <Grid item xs={3}>
                <RHFDatePicker
                  label="Data scadenza"
                  name="expirationDate"
                  minDate={add(new Date(), {
                    days: 1,
                  })}
                />
              </Grid>
              <Grid item xs={3}>
                <RHFTextField
                  decimalScale={0}
                  label="Max Nr. Opere"
                  name="maxStructureNumber"
                />
              </Grid>
              <Grid item xs={3}>
                <RHFNumberTextField
                  label="Max Spazio Disponibile (GB)"
                  name="maxAvailableSpace"
                  decimalScale={0}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">GB</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
        {watch('tipologiaUtente') &&
          watch('tipologiaUtente') === UserTypology.CollaboratoreInterno && (
            <Grid item xs={3}>
              <RHFTextField
                label="Email Utente Principale*"
                name="emailUtentePrincipale"
              />
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default InstitutionForm;
