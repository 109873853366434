import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { CardOverview, MaterialBox } from 'components';
import { STRUCTURES_PATHS } from 'constants/paths';
import {
  tipologiaConvogliamentoAcqueOptions,
  tipologiaPaliOptions,
} from 'constants/selectOptions';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import { handleDynamicInspectionsTabChange } from 'utils/utilities';
import { Row } from '../TabL0.styles';

const Servizi = ({ overviewL0 }) => {
  const params = useParams();
  const id = params.structureId;
  const navigate = useNavigate();

  const lastInspectionId = overviewL0?.requestUserLastValidInspectionId;
  const pali = overviewL0?.servizi?.paliIlluminazione;
  const convogliamentoAcque = overviewL0?.servizi?.convogliamentoAcque;
  const sottoservizi = overviewL0?.servizi?.sottoservizi;
  return (
    <CardOverview
      title={'Servizi'}
      subtitle={`Rilievo delle tipologie`}
      buttonText={'Approfondisci'}
      buttonDisabled={lastInspectionId ? false : true}
      height="auto"
      onClick={() =>
        handleDynamicInspectionsTabChange(
          inspectionTabMapping.L0,
          inspectionTabMapping.Servizi,
          null,
          navigate,
          STRUCTURES_PATHS.STRUCTURES_LIST,
          id,
          lastInspectionId
        )
      }
    >
      <Row style={{ marginTop: 12 }}>
        <MaterialBox
          /* dataRecap={pali} */
          topic={'Pali illuminazione'}
          materialsData={pali}
          enumOptions={tipologiaPaliOptions}
        />
        <MaterialBox
          /* dataRecap={convogliamentoAcque} */
          topic={'Convogliamento acque'}
          materialsData={convogliamentoAcque}
          enumOptions={tipologiaConvogliamentoAcqueOptions}
        />
        <MaterialBox
          /* dataRecap={sottoservizi} */
          topic={'Sottoservizi'}
          materialsData={sottoservizi}
          enumOptions={tipologiaConvogliamentoAcqueOptions}
        />
      </Row>
    </CardOverview>
  );
};

export { Servizi };

Servizi.propTypes = {
  overviewL0: PropTypes.obj,
};
