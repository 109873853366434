import PileSection from './sections/PileSection';
import SpalleSection from './sections/SpalleSection';

const Accordion2Elevazione = () => {
  return (
    <>
      <SpalleSection />
      <PileSection />
    </>
  );
};

export default Accordion2Elevazione;
