import styled from '@emotion/styled';

export const TablePaginationContainer = styled('div')({
  width: '100%',
  display: 'flex',
});

export const PaginationCont = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});
