import { styled } from '@mui/system';
import { colors } from 'constants/colors';

export const WhiteContainer = styled('div')({
  width: '100%',
  borderRadius: 8,
  backgroundColor: colors.WHITE,
  padding: 24,
  minHeight: 180,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
});

export const FlexHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 10,
});

export const FlexIconTitle = styled('div')({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
});

export const BtnContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 5,
  marginTop: 16,
});
