import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaPulvinoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import { GridWrap } from './AppoggiForm.styles';
const PulviniForm = ({
  pulviniFields,
  selected,
  setSelected,
  pulviniTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t2a4_pulviniTableValue: [],
      l0t2a4_tipologiaPulvinoTableValue: '',
      l0t2a4_altezzaPulvinoTableValue: '',
      l0t2a4_larghezzaPulvinoTableValue: '',
      l0t2a4_profonditaPulvinoTableValue: '',
      l0t2a4_profonditaAppoggioPulvinoTableValue: '',
      l0t2a4_superficiePulvinoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a4_pulviniTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetPulvini();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changePulvini = () => {
    const l0t2a4_tipologiaPulvinoTableValue = getValues(
      'l0t2a4_tipologiaPulvinoTableValue'
    );
    const l0t2a4_altezzaPulvinoTableValue = getValues(
      'l0t2a4_altezzaPulvinoTableValue'
    );
    const l0t2a4_larghezzaPulvinoTableValue = getValues(
      'l0t2a4_larghezzaPulvinoTableValue'
    );
    const l0t2a4_profonditaPulvinoTableValue = getValues(
      'l0t2a4_profonditaPulvinoTableValue'
    );
    const l0t2a4_profonditaAppoggioPulvinoTableValue = getValues(
      'l0t2a4_profonditaAppoggioPulvinoTableValue'
    );
    const l0t2a4_superficiePulvinoTableValue = getValues(
      'l0t2a4_superficiePulvinoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const pulvini = [...pulviniFields];

    pulvini.map((x, index) => {
      if (ids.includes(x?.codice)) {
        pulvini[index] = {
          ...x,
          tipologiaPulvino: l0t2a4_tipologiaPulvinoTableValue,
          altezzaPulvino: l0t2a4_altezzaPulvinoTableValue,
          larghezzaPulvino: l0t2a4_larghezzaPulvinoTableValue,
          profonditaPulvino: l0t2a4_profonditaPulvinoTableValue,
          profonditaAppoggioPulvino: l0t2a4_profonditaAppoggioPulvinoTableValue,
          superficiePulvino: l0t2a4_superficiePulvinoTableValue,
          ...(l0t2a4_tipologiaPulvinoTableValue !== x.tipologiaPulvino
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'pulvino',
                  l0t2a4_tipologiaPulvinoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(pulviniTable, pulvini);
    onResetPulvini();
  };

  const onConfirmChangePulvini = () => {
    setOpenConfirmationDialog(false);
    changePulvini();
  };

  const onSubmitPulvini = () => {
    const l0t2a4_tipologiaPulvinoTableValue = getValues(
      'l0t2a4_tipologiaPulvinoTableValue'
    );
    const ids = selected.map(el => el.codice);

    const pulvini = [...pulviniFields];

    const hasChangedTipologia = pulvini.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a4_tipologiaPulvinoTableValue !== el.tipologiaPulvino &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaPulvino)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changePulvini();
    }
  };

  const onResetPulvini = () => {
    setValue('l0t2a4_pulviniTableValue', []);
    setValue('l0t2a4_tipologiaPulvinoTableValue', '');
    setValue('l0t2a4_altezzaPulvinoTableValue', '');
    setValue('l0t2a4_larghezzaPulvinoTableValue', '');
    setValue('l0t2a4_profonditaPulvinoTableValue', '');
    setValue('l0t2a4_profonditaAppoggioPulvinoTableValue', '');
    setValue('l0t2a4_superficiePulvinoTableValue', '');

    setSelected([]);
  };
  // [(2*Altezza*Profondità)+(2*Altezza*Larghezza)+(Larghezza*Profondità)]/10000

  const altezzaPulvinoWatch = parseFloat(
    watch('l0t2a4_altezzaPulvinoTableValue')
  );
  const profonditaPulvinoWatch = parseFloat(
    watch('l0t2a4_profonditaPulvinoTableValue')
  );
  const larghezzaPulvinoWatch = parseFloat(
    watch('l0t2a4_larghezzaPulvinoTableValue')
  );

  useEffect(() => {
    const result = (
      (2 * altezzaPulvinoWatch * profonditaPulvinoWatch +
        2 * altezzaPulvinoWatch * larghezzaPulvinoWatch +
        larghezzaPulvinoWatch * profonditaPulvinoWatch) /
      10000
    ).toFixed(2);

    setValue('l0t2a4_superficiePulvinoTableValue', result);
  }, [altezzaPulvinoWatch, profonditaPulvinoWatch, larghezzaPulvinoWatch]);

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={6} infoMessage={INFO.pulviniSelezionati.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-pulvini"
            name={'l0t2a4_pulviniTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={pulviniFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Pulvini selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a4_tipologiaPulvinoTableValue"
            label="Tipologia Pulvino*"
            defaultValue={''}
            onChange={e => {
              setValue('l0t2a4_tipologiaPulvinoTableValue', e.target.value);
            }}
            selectOptions={tipologiaPulvinoOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a4_altezzaPulvinoTableValue"
            label={'Altezza (H) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a4_larghezzaPulvinoTableValue"
            label={'Larghezza (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a4_profonditaPulvinoTableValue"
            label={'Profondità (P) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a4_profonditaAppoggioPulvinoTableValue"
            label={'Profondità appoggio (Pa) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <InfoWrapper size={2} infoMessage={INFO.superficiePulvino.infoMessage}>
          <RHFNumberTextField
            name="l0t2a4_superficiePulvinoTableValue"
            label={'Superficie [m²]'}
            defaultValue=""
            decimalScale={2}
            InputLabelProps={{ shrink: true }}
            disabled
            withValueLimit={({ value }) => value < 1000}
          />
        </InfoWrapper>
        <GridWrap item xs={1}>
          <FormSubmitButtons
            onSubmit={onSubmitPulvini}
            onReset={onResetPulvini}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangePulvini}
      />
    </>
  );
};
PulviniForm.propTypes = {
  pulviniFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaPulvino: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      altezzaPulvino: PropTypes.number,
      larghezzaPulvino: PropTypes.number,
      profonditaPulvino: PropTypes.number,
      profonditaAppoggioPulvino: PropTypes.number,
      superficiePulvino: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  pulviniTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default PulviniForm;
