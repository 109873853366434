import { createSvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

const Docx = createSvgIcon(
  <g
    id="Raggruppa_22262"
    data-name="Raggruppa 22262"
    transform="translate(-1223 -1047)"
  >
    <path
      id="Icon_metro-file-pdf"
      data-name="Icon metro-file-pdf"
      d="M27.842,8.745a4.006,4.006,0,0,1,.826,1.308,3.973,3.973,0,0,1,.344,1.515V31.4a1.646,1.646,0,0,1-1.653,1.653H4.223A1.646,1.646,0,0,1,2.571,31.4V3.856A1.646,1.646,0,0,1,4.223,2.2H19.648a3.974,3.974,0,0,1,1.515.344,4.006,4.006,0,0,1,1.308.826ZM20.2,4.545v6.473h6.473A90.629,90.629,0,0,0,20.2,4.545Zm6.61,26.3V13.221H19.648a1.646,1.646,0,0,1-1.653-1.653V4.407H4.774V30.849H26.809Z"
      transform="translate(1220.43 1044.797)"
      fill="#3260ce"
    />
    <path
      id="Path_file-word-outline"
      data-name="Path / file-word-outline"
      d="M20.53,13.924,18.061,24.505H16.415L14.3,16.522l-2.116,7.983H10.536L7.95,13.924H9.713l1.646,8.007,2.116-8.007H15l2.116,8.007,1.646-8.007Z"
      transform="translate(1221.98 1046.896)"
      fill="#3260ce"
    />
  </g>,
  'Docx'
);
const Dwg = createSvgIcon(
  <g
    id="Raggruppa_22263"
    data-name="Raggruppa 22263"
    transform="translate(-1071 -1047)"
  >
    <path
      id="Icon_metro-file-pdf"
      data-name="Icon metro-file-pdf"
      d="M27.842,8.745a4.006,4.006,0,0,1,.826,1.308,3.973,3.973,0,0,1,.344,1.515V31.4a1.646,1.646,0,0,1-1.653,1.653H4.223A1.646,1.646,0,0,1,2.571,31.4V3.856A1.646,1.646,0,0,1,4.223,2.2H19.648a3.974,3.974,0,0,1,1.515.344,4.006,4.006,0,0,1,1.308.826ZM20.2,4.545v6.473h6.473A90.629,90.629,0,0,0,20.2,4.545Zm6.61,26.3V13.221H19.648a1.646,1.646,0,0,1-1.653-1.653V4.407H4.774V30.849H26.809Z"
      transform="translate(1068.43 1044.797)"
    />
    <text
      id="dwg"
      transform="translate(1075 1069.166)"
      fontSize="8"
      fontFamily="MyriadPro-Bold, Myriad Pro"
      fontWeight="700"
    >
      <tspan x="0" y="0">
        DWG
      </tspan>
    </text>
  </g>,
  'Dwg'
);
const Pdf = createSvgIcon(
  <path
    id="Icon_metro-file-pdf"
    data-name="Icon metro-file-pdf"
    d="M27.842,8.745a4.006,4.006,0,0,1,.826,1.308,3.973,3.973,0,0,1,.344,1.515V31.4a1.646,1.646,0,0,1-1.653,1.653H4.223A1.646,1.646,0,0,1,2.571,31.4V3.856A1.646,1.646,0,0,1,4.223,2.2H19.648a3.974,3.974,0,0,1,1.515.344,4.006,4.006,0,0,1,1.308.826ZM20.2,4.545v6.473h6.473A90.629,90.629,0,0,0,20.2,4.545Zm6.61,26.3V13.221H19.648a1.646,1.646,0,0,1-1.653-1.653V4.407H4.774V30.849H26.809ZM17.961,20.64a13.1,13.1,0,0,0,1.446.964,17.082,17.082,0,0,1,2.014-.121q2.531,0,3.047.844a.832.832,0,0,1,.034.9.05.05,0,0,1-.017.034l-.034.034v.017q-.1.654-1.222.654a7.1,7.1,0,0,1-1.98-.344,12.552,12.552,0,0,1-2.238-.912,31,31,0,0,0-6.748,1.429q-2.634,4.51-4.166,4.51a1,1,0,0,1-.482-.121L7.2,28.318q-.017-.017-.1-.086a.711.711,0,0,1-.1-.62,3.738,3.738,0,0,1,.964-1.575,8.319,8.319,0,0,1,2.272-1.661.251.251,0,0,1,.4.1.1.1,0,0,1,.034.069q.9-1.463,1.842-3.391a26.274,26.274,0,0,0,1.79-4.51,13.916,13.916,0,0,1-.525-2.746,6.693,6.693,0,0,1,.112-2.195q.189-.689.723-.689h.379a.726.726,0,0,1,.6.258,1.375,1.375,0,0,1,.155,1.171.373.373,0,0,1-.069.138.448.448,0,0,1,.017.138v.516a22.542,22.542,0,0,1-.241,3.305,8.709,8.709,0,0,0,2.513,4.1ZM8.045,27.715A7.6,7.6,0,0,0,10.4,25,9.813,9.813,0,0,0,8.9,26.442,5.722,5.722,0,0,0,8.045,27.715ZM14.9,11.878a5.119,5.119,0,0,0-.034,2.272q.017-.121.121-.757,0-.052.121-.74a.387.387,0,0,1,.069-.138.05.05,0,0,1-.017-.034.035.035,0,0,0-.009-.026.035.035,0,0,1-.009-.026.991.991,0,0,0-.224-.62.05.05,0,0,1-.017.034v.034ZM12.762,23.257a25.25,25.25,0,0,1,4.889-1.394,2.6,2.6,0,0,1-.224-.164,3.083,3.083,0,0,1-.275-.232,9.119,9.119,0,0,1-2.186-3.03,23,23,0,0,1-1.429,3.391q-.516.964-.775,1.429Zm11.121-.275a4.12,4.12,0,0,0-2.41-.413,6.511,6.511,0,0,0,2.135.482,1.67,1.67,0,0,0,.31-.017q0-.017-.034-.052Z"
    transform="translate(-2.571 -2.203)"
    fill="#f31616"
  />,
  'Pdf'
);
const Ppt = createSvgIcon(
  <g
    id="Raggruppa_22258"
    data-name="Raggruppa 22258"
    transform="translate(-1603 -1047)"
  >
    <path
      id="Icon_metro-file-pdf"
      data-name="Icon metro-file-pdf"
      d="M27.842,8.745a4.006,4.006,0,0,1,.826,1.308,3.973,3.973,0,0,1,.344,1.515V31.4a1.646,1.646,0,0,1-1.653,1.653H4.223A1.646,1.646,0,0,1,2.571,31.4V3.856A1.646,1.646,0,0,1,4.223,2.2H19.648a3.974,3.974,0,0,1,1.515.344,4.006,4.006,0,0,1,1.308.826ZM20.2,4.545v6.473h6.473A90.629,90.629,0,0,0,20.2,4.545Zm6.61,26.3V13.221H19.648a1.646,1.646,0,0,1-1.653-1.653V4.407H4.774V30.849H26.809Z"
      transform="translate(1600.43 1044.797)"
      fill="#ffad4c"
    />
    <g
      id="MDI_file-powerpoint-outline"
      data-name="MDI / file-powerpoint-outline"
      transform="translate(1598.119 1045.396)"
    >
      <path
        id="Path_file-powerpoint-outline"
        data-name="Path / file-powerpoint-outline"
        d="M19,15.28a3.509,3.509,0,0,0-2.822-.941H11.712V24.92h1.881v-3.88H16.18A3.687,3.687,0,0,0,19,20.218a2.963,2.963,0,0,0,.941-2.469A3.111,3.111,0,0,0,19,15.28m-1.528,3.762a1.761,1.761,0,0,1-1.411.588H13.593V15.868h2.351a2.031,2.031,0,0,1,1.528.588,1.606,1.606,0,0,1,.47,1.293A1.606,1.606,0,0,1,17.473,19.042Z"
        transform="translate(2.17 1.085)"
        fill="#ffad4c"
      />
    </g>
  </g>,
  'Ppt'
);
const Unknow = createSvgIcon(
  <path
    id="Icon_metro-file-pdf"
    data-name="Icon metro-file-pdf"
    d="M27.842,8.745a4.006,4.006,0,0,1,.826,1.308,3.973,3.973,0,0,1,.344,1.515V31.4a1.646,1.646,0,0,1-1.653,1.653H4.223A1.646,1.646,0,0,1,2.571,31.4V3.856A1.646,1.646,0,0,1,4.223,2.2H19.648a3.974,3.974,0,0,1,1.515.344,4.006,4.006,0,0,1,1.308.826ZM20.2,4.545v6.473h6.473A90.629,90.629,0,0,0,20.2,4.545Zm6.61,26.3V13.221H19.648a1.646,1.646,0,0,1-1.653-1.653V4.407H4.774V30.849H26.809Z"
    transform="translate(-2.571 -2.203)"
    fill="#707070"
  />,
  'Unknow'
);
const Xlsx = createSvgIcon(
  <g
    id="Raggruppa_22261"
    data-name="Raggruppa 22261"
    transform="translate(-1299 -1047)"
  >
    <path
      id="Icon_metro-file-pdf"
      data-name="Icon metro-file-pdf"
      d="M27.842,8.745a4.006,4.006,0,0,1,.826,1.308,3.973,3.973,0,0,1,.344,1.515V31.4a1.646,1.646,0,0,1-1.653,1.653H4.223A1.646,1.646,0,0,1,2.571,31.4V3.856A1.646,1.646,0,0,1,4.223,2.2H19.648a3.974,3.974,0,0,1,1.515.344,4.006,4.006,0,0,1,1.308.826ZM20.2,4.545v6.473h6.473A90.629,90.629,0,0,0,20.2,4.545Zm6.61,26.3V13.221H19.648a1.646,1.646,0,0,1-1.653-1.653V4.407H4.774V30.849H26.809Z"
      transform="translate(1296.43 1044.797)"
      fill="#5dcda0"
    />
    <path
      id="Path_file-excel-outline"
      data-name="Path / file-excel-outline"
      d="M15.924,19.477l3.41,5.291H17.217l-2.352-4-2.352,4H10.4l3.41-5.291L10.4,14.186h2.116l2.352,4,2.352-4h2.116Z"
      transform="translate(1297.604 1046.635)"
      fill="#5dcda0"
    />
  </g>,
  'Xlsx'
);

const FileExtensionIconsMap = {
  docx: Docx,
  doc: Docx,
  dwg: Dwg,
  pdf: Pdf,
  pptx: Ppt,
  ppt: Ppt,
  xlsx: Xlsx,
  xls: Xlsx,
};

const FileExtensionIcon = ({ fileType, ...rest }) => {
  const Icon = FileExtensionIconsMap[fileType] || Unknow;

  return <Icon viewBox="0 0 32 32" {...rest} />;
};

FileExtensionIcon.propTypes = {
  fileType: PropTypes.string,
};

export default FileExtensionIcon;
