import { styled, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

const StyledRow = styled(TableRow)(({ rowColor = 'grey' }) => ({
  backgroundColor: rowColor || '#fff',
}));

export const DataGridBody = ({ columns, rows: rowsProp, rowColor }) => {
  const rows = rowsProp.map((row, i) => {
    const cells = columns.map(col => {
      const renderCell =
        col.renderCell ||
        (col.valueFormatter && (row => col.valueFormatter(row[col.name]))) ||
        (row => row[col.name]);

      return <TableCell key={col.name}>{renderCell(row)}</TableCell>;
    });

    return (
      <StyledRow rowColor={row.bgColor || rowColor} key={i}>
        {cells}
      </StyledRow>
    );
  });

  return <TableBody>{rows}</TableBody>;
};

DataGridBody.propTypes = {
  rowColor: PropTypes.string,
  columns: PropTypes.array,
  rows: PropTypes.array,
};
