import { Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Check from 'assets/icons/check-white.svg';
import Cancel from 'assets/icons/close-black.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { RHFTagAutocomplete, RHFTextField, Table } from 'components';
import { FormProvider } from 'providers';
import { Buttons, FormContainer, TableContainer } from './Campate.style';

// Assets

const dummyData = [
  {
    id: 1,
    campata: 'C1',
    schema: 'isostatico',
    luceCampata: '8',
    enteScavalcato: 'Corso acqua',
    luceIdraulica: '7',
  },
  {
    id: 2,
    campata: 'C2',
    schema: 'iso',
    luceCampata: '3',
    enteScavalcato: 'Corso acqua',
    luceIdraulica: '11',
  },
  {
    id: 3,
    campata: 'C3',
    schema: 'isostatico',
    luceCampata: '5',
    enteScavalcato: 'Corso acqua',
    luceIdraulica: '120',
  },
  {
    id: 4,
    campata: 'C4',
    schema: 'isostatico',
    luceCampata: '8',
    enteScavalcato: 'Corso acqua',
    luceIdraulica: '7',
  },
  {
    id: 5,
    campata: 'C5',
    schema: 'isostatico',
    luceCampata: '8',
    enteScavalcato: 'Corso acqua',
    luceIdraulica: '7',
  },
];

export const Campate = () => {
  const [editState, setEditState] = useState(false);
  const [editData, setEditData] = useState([]);

  const [selected, setSelected] = useState([]);
  // const [tableRows, setTableRows] = useState(dummyData);
  const tableRows = dummyData;
  const methods = useForm({
    defaultValues: {
      campata: '',
      schema: '',
      luceCampata: '',
      enteScavalcato: '',
      luceIdraulica: '',
    },
  });

  const dataConfig = {
    columns: {
      campata: { label: 'campata' },
      schema: { label: 'schema' },
      luceCampata: { label: 'luce Campata' },
      enteScavalcato: { label: 'ente Scavalcato' },
      luceIdraulica: { label: 'luce Idraulica' },
    },
  };

  // useEffect(() => {
  //   if (allChecked) {
  //     setCheckedRows(tableRows);
  //   } else {
  //     setCheckedRows([]);
  //   }
  // }, [allChecked]);

  const editRows = item => {
    // setEditData(item);
    setEditState(true);
    // setCheckedRows([in);
    methods.setValue('campata', item.campata);
    methods.setValue('schema', item.schema);
    methods.setValue('luceCampata', item.luceCampata);
    methods.setValue('enteScavalcato', item.enteScavalcato);
    methods.setValue('luceIdraulica', item.luceIdraulica);
  };

  const changeAutocomplete = value => {
    // const selectedIds = value.map(x => x.id);
    // console.log('select', selectedIds);
    setSelected(value);
    // setEditData(value[0]);
    // setEditState(true);
  };

  const onSubmit = data => {
    console.log(data);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      setSelected(tableRows);
    } else {
      setSelected([]);
    }
  };

  return (
    <TableContainer>
      <Typography variant="smallTitle">CAMPATE</Typography>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <FormContainer>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {/* <Autocomplete
                options={tableRows}
                size="small"
                // selected={selected}
                changeAutocomplete={value => {
                  changeAutocomplete(value);
                }}
                // checkRow={el => {
                //   checkRow(el);
                // }}
              /> */}

              <RHFTagAutocomplete
                multiple={true}
                id="tags-outlined"
                name={'tags'}
                // key={methods.getValues('tags')}
                // control={control}
                // errors={errors}
                changeAutocomplete={value => {
                  changeAutocomplete(value);
                }}
                labelName={'option.campata'}
                getOptionLabel={option => option.campata}
                opt={tableRows?.filter(
                  el => !selected?.some(s => s?.id === el?.id)
                )}
                label="Tag"
                placeholder="Aggiungi tag"
              />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField
                fullWidth={true}
                size="small"
                name="schema"
                label="Schema statico"
                onChange={e => {
                  setEditData({ ...editData, schema: e.target.value });
                  methods.setValue('schema', e.target.value);
                }}
                focused={editState}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <RHFTextField
                fullWidth={true}
                size="small"
                name="luceCampata"
                label="Luce Campata (m)"
                onChange={e => {
                  setEditData({ ...editData, luceCampata: e.target.value });
                  methods.setValue('luceCampata', e.target.value);
                }}
                focused={editState}
              />
            </Grid>
            <Grid item xs={3}>
              <RHFTextField
                name="enteScavalcato"
                fullWidth={true}
                size="small"
                label="Ente scavalcato (tipo)"
                onChange={e => {
                  setEditData({ ...editData, enteScavalcato: e.target.value });
                  methods.setValue('enteScavalcato', e.target.value);
                }}
                focused={editState}
              />
            </Grid>
            <Grid item xs={3}>
              <RHFTextField
                name="luceIdraulica"
                fullWidth={true}
                size="small"
                label="Luce idraulica (m)"
                onChange={e => {
                  setEditData({ ...editData, luceIdraulica: e.target.value });
                  methods.setValue('luceIdraulica', e.target.value);
                }}
                focused={editState}
              />
            </Grid>
            <Grid item xs={3}>
              <Buttons>
                <IconButton color="primary" type="submit">
                  <img src={Check} alt="" />
                </IconButton>
                <IconButton color="primary" onClick={() => methods.reset()}>
                  <img src={Cancel} alt="" />
                </IconButton>
              </Buttons>
            </Grid>
          </Grid>
        </FormContainer>
      </FormProvider>

      <Table
        data={tableRows}
        config={dataConfig}
        hasCheckbox={true}
        rowActions={[
          {
            onClick: item => {
              editRows(item);
              setSelected([item]);
            },
            icon: <EditIcon />,
          },
        ]}
        selected={selected}
        onSelectAllClick={handleSelectAllClick}
        setSelected={setSelected}
      />
    </TableContainer>
  );
};
