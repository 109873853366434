import { Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { HeaderPage, VideoTutorialBox } from 'components';
import { videoTutorials } from 'constants/infoAssistance';
import { INFO_ASSISTANCE_PATHS } from 'constants/paths';
import { Container, WhiteContainer } from './VideoTutorial.styles';

const VideoTutorial = () => {
  const [searchValue, setSearchValue] = useState('');

  const filterVideoTutorial = arr => {
    if (!searchValue) {
      return arr;
    }
    return arr.filter(
      currentVideoTutorial =>
        searchValue
          .split(' ')
          .some(str =>
            currentVideoTutorial.metaDescription
              .split(' ')
              .some(mt => mt.toLowerCase() === str)
          ) || currentVideoTutorial.title.toLowerCase().includes(searchValue)
    );
  };

  return (
    <>
      <HeaderPage
        back={INFO_ASSISTANCE_PATHS.ROOT}
        title={'Video Tutorial'}
        dataBreadcrumbs={[
          { path: INFO_ASSISTANCE_PATHS.ROOT, label: 'Info & Assistenza' },
          { path: INFO_ASSISTANCE_PATHS.VIDEOS, label: 'Video Tutorial' },
        ]}
      />
      <Container>
        <WhiteContainer>
          <Typography variant="h2" fontSize={'16px'}>
            Ricerca fra i video tutorial
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <TextField
                value={searchValue}
                onChange={e => setSearchValue(e.target.value?.toLowerCase())}
                placeholder="cerca"
              />
            </Grid>
          </Grid>
          {/* <Typography variant="h3Bold">Generale</Typography> */}
          <Grid container spacing={2} mt={2}>
            {filterVideoTutorial(videoTutorials).map(el => {
              return (
                <Grid item key={el.id} xs={3}>
                  <VideoTutorialBox
                    title={el.title}
                    youtubeId={el.youtubeId}
                    description={el.description}
                  />
                </Grid>
              );
            })}
          </Grid>
        </WhiteContainer>

        {/* <WhiteContainer>
          <Typography variant="h3Bold">Attività</Typography>
          <Grid container spacing={2} mt={2}>
            {videoTutorials
              .filter(el => el.category.includes(videoCategories.attivita))
              .filter(el => el.title.toLowerCase().includes(searchValue))
              .map(el => {
                return (
                  <Grid item key={el.id} xs={4}>
                    <VideoTutorialBox
                      title={el.title}
                      youtubeId={el.youtubeId}
                      description={el.description}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </WhiteContainer> */}
      </Container>
    </>
  );
};

export default VideoTutorial;
