import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import {
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
} from 'components';
import { mensole } from 'constants/inspections';
import { mensoleOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import SchemaPonteSection from './sections/SchemaPonteSection';
const Accordion1CaratteristicheSovrastruttura = ({ level1 }) => {
  const { control, watch, getValues, setValue } = useFormContext();

  const watchMensole = watch('mensole');
  const watchMensoleSi = watchMensole === mensole.Si;

  // SPALLE/PILE Sovrastruttura Table
  const { append: addSchemaPonte, remove: removeSchemaPonte } = useFieldArray({
    control,
    name: 'schemaPonteTable',
  });

  //
  const mensoleTamponiTableWatch = watch('mensoleTamponiTable');
  const campataTableWatch = watch('campataStrutturaTable');
  const schemaPonteTableWatch = watch('schemaPonteTable');
  const watchNCampate = watch('numeroCampate');

  const handleSchemaPonteTable = (table, refresh = false) => {
    if (refresh) {
      removeSchemaPonte();
    }

    const schemaPonteTable = getValues('schemaPonteTable');
    // updateTableRows(newTotal, prevTotal,removeSchemaPonte,handleAddSchemaPonte)
    if (watchMensoleSi) {
      const prevMensoleTotal = schemaPonteTable?.filter(e =>
        e?.codice.startsWith('M')
      )?.length;
      const prevTamponiTotal = schemaPonteTable?.filter(e =>
        e?.codice.startsWith('T')
      )?.length;
      const newMensoleTotal = table?.filter(e =>
        e?.campata.startsWith('M')
      )?.length;
      const newTamponiTotal = table?.filter(e =>
        e?.campata.startsWith('T')
      )?.length;
      if (newMensoleTotal === 0) {
        const ids = [];
        schemaPonteTable?.map((el, idx) => {
          if (el?.codice.startsWith('M')) {
            ids.push(idx);
          }
        });
        removeSchemaPonte(ids);
      }
      if (newTamponiTotal === 0) {
        const ids = [];
        schemaPonteTable?.map((el, idx) => {
          if (el?.codice.startsWith('T')) {
            ids.push(idx);
          }
        });
        removeSchemaPonte(ids);
      }
      if (
        newMensoleTotal < prevMensoleTotal ||
        newTamponiTotal < prevTamponiTotal
      ) {
        const ids = [];
        schemaPonteTable?.map((el, idx) => {
          // in case the number has changed in a MensoleTamponi schema
          // we have to remove the correct element ("M1","T1",etc)
          const foundIdx = table?.findIndex(t => el?.codice === t?.campata);
          if (foundIdx === -1) {
            ids.push(idx);
          }
        });
        removeSchemaPonte(ids);
      }
      if (
        newMensoleTotal > prevMensoleTotal ||
        newTamponiTotal > prevTamponiTotal
      ) {
        table?.map((el, idx) => {
          const foundIdx = schemaPonteTable?.findIndex(
            t => el?.campata === t?.codice
          );
          if (foundIdx === -1) {
            addSchemaPonte({
              id: uuidv4(),
              codice: table[idx]?.campata,
              cordoloSX: '',
              cordoloDX: '',
              marciapiedeSX: '',
              marciapiedeDX: '',
              parapettoSX: '',
              parapettoDX: '',
              guardaviaSX: '',
              guardaviaDX: '',
              spartitraffico: '',
              pavimentazione: '',
            });
          }
        });
      }
      setValue(
        'schemaPonteTable',
        getValues('schemaPonteTable')?.sort((a, b) =>
          a?.codice?.localeCompare(b?.codice)
        )
      );
    } else {
      if (table?.length !== schemaPonteTable?.length) {
        const prevTotal = schemaPonteTable?.length;
        const newTotal = table?.length;
        if (newTotal < prevTotal) {
          const ids = [];
          for (let i = prevTotal - 1; i >= newTotal; i--) {
            ids.push(i);
          }
          removeSchemaPonte(ids);
        } else {
          for (let i = prevTotal; i < newTotal; i++) {
            addSchemaPonte({
              id: uuidv4(),
              codice: table[i].campata,
              cordoloSX: '',
              cordoloDX: '',
              marciapiedeSX: '',
              marciapiedeDX: '',
              parapettoSX: '',
              parapettoDX: '',
              guardaviaSX: '',
              guardaviaDX: '',
              spartitraffico: '',
              pavimentazione: '',
            });
          }
        }
        setValue(
          'schemaPonteTable',
          getValues('schemaPonteTable')?.sort((a, b) =>
            a?.codice?.localeCompare(b?.codice)
          )
        );
      }
    }
  };

  useEffect(() => {
    if (
      (mensoleTamponiTableWatch || campataTableWatch) &&
      schemaPonteTableWatch
    ) {
      // we loop through all of the entries of mensoleTamponiTable for check
      // if the user has changed any of the values of the table
      if (watchMensoleSi) {
        // we have to reset the SchemaPonteTable if some elements are still with the Campata code (starting with "C")
        if (schemaPonteTableWatch.some(el => el.codice.startsWith('C'))) {
          handleSchemaPonteTable(mensoleTamponiTableWatch, true);
        } else {
          handleSchemaPonteTable(mensoleTamponiTableWatch);
        }
      } else {
        // we have to reset the SchemaPonteTable if some elements are still with the Mensole/Tamponi code (starting with "M" and "T")

        if (
          schemaPonteTableWatch.some(el => el.codice.startsWith('M')) ||
          schemaPonteTableWatch.some(el => el.codice.startsWith('T'))
        ) {
          handleSchemaPonteTable(campataTableWatch, true);
        } else {
          handleSchemaPonteTable(campataTableWatch);
        }
      }
    }
  }, [campataTableWatch, mensoleTamponiTableWatch, watchMensoleSi]);

  return (
    <>
      {(process.env.REACT_APP_ENVIRONMENT == 'develop' ||
        process.env.REACT_APP_ENVIRONMENT == 'test') && (
        <GridWrap container spacing={2} my={3}>
          <GridWrap item xs={12}>
            <Button
              color="info"
              fullWidth
              variant="contained"
              onClick={() =>
                console.log(getValues('sovrastrutturaImpalcatoTable'))
              }
            >
              analizza elementi accessori in console
            </Button>
          </GridWrap>
        </GridWrap>
      )}
      <GridWrap container spacing={2} mb={2}>
        <GridWrap item md={3}>
          <RHFNumberTextField
            name="numeroCampate"
            label={'N° campate'}
            defaultValue=""
            disabled
            decimalScale={0}
            withValueLimit={({ value }) => value <= 50}
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.mensole.drawerTitle}
          drawerText={INFO.mensole.drawerText}
        >
          <RHFSelect
            name="mensole"
            label={'Mensole*'}
            defaultValue={''}
            disabled
            selectOptions={mensoleOptions}
          />
        </InfoWrapper>
      </GridWrap>
      {parseInt(watchNCampate) !== 0 &&
        !isNullOrUndefinedOrEmptyString(watchNCampate) && (
          <SchemaPonteSection level1={level1} />
        )}
    </>
  );
};
Accordion1CaratteristicheSovrastruttura.propTypes = {
  level1: PropTypes.bool,
};
export default Accordion1CaratteristicheSovrastruttura;
