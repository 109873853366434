import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaSolettaOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
const SoletteForm = ({
  soletteFields,
  selected,
  setSelected,
  soletteTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      l0t2a5_soletteTableValue: [],
      l0t2a5_tipologiaSoletteTableValue: '',
      l0t2a5_altezzaSpessoreBordoSolettaTableValue: '',
      l0t2a5_lunghezzaSbalzoSolettaTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a5_soletteTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetSolette();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeSolette = () => {
    const l0t2a5_tipologiaSoletteTableValue = getValues(
      'l0t2a5_tipologiaSoletteTableValue'
    );
    const l0t2a5_altezzaSpessoreBordoSolettaTableValue = getValues(
      'l0t2a5_altezzaSpessoreBordoSolettaTableValue'
    );
    const l0t2a5_lunghezzaSbalzoSolettaTableValue = getValues(
      'l0t2a5_lunghezzaSbalzoSolettaTableValue'
    );

    const ids = selected.map(el => el.codice);

    const solette = [...soletteFields];

    solette.map((x, index) => {
      if (ids.includes(x?.codice)) {
        solette[index] = {
          ...x,
          tipologiaSoletta: l0t2a5_tipologiaSoletteTableValue,
          altezzaSpessoreBordoSoletta:
            l0t2a5_altezzaSpessoreBordoSolettaTableValue,
          lunghezzaSbalzoSoletta: l0t2a5_lunghezzaSbalzoSolettaTableValue,
          ...(l0t2a5_tipologiaSoletteTableValue !== x.tipologiaSoletta
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'soletta',
                  l0t2a5_tipologiaSoletteTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(soletteTable, solette);
    onResetSolette();
  };

  const onConfirmChangeSolette = () => {
    setOpenConfirmationDialog(false);
    changeSolette();
  };

  const onSubmitSolette = () => {
    const l0t2a5_tipologiaSoletteTableValue = getValues(
      'l0t2a5_tipologiaSoletteTableValue'
    );

    const ids = selected.map(el => el.codice);

    const solette = [...soletteFields];

    const hasChangedTipologia = solette.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a5_tipologiaSoletteTableValue !== el.tipologiaSoletta &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaSoletta)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeSolette();
    }
  };

  const onResetSolette = () => {
    setValue('l0t2a5_soletteTableValue', []);
    setValue('l0t2a5_tipologiaSoletteTableValue', '');
    setValue('l0t2a5_altezzaSpessoreBordoSolettaTableValue', '');
    setValue('l0t2a5_lunghezzaSbalzoSolettaTableValue', '');

    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={6} infoMessage={INFO.soletteSelezionate.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-solette"
            name={'l0t2a5_soletteTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={soletteFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Solette selezionate"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_tipologiaSoletteTableValue"
            label="Tipologia Soletta*"
            defaultValue={''}
            onChange={e => {
              setValue('l0t2a5_tipologiaSoletteTableValue', e.target.value);
            }}
            selectOptions={tipologiaSolettaOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_altezzaSpessoreBordoSolettaTableValue"
            label={'Altezza/spessore bordo soletta (H) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_lunghezzaSbalzoSolettaTableValue"
            label={'Lunghezza sbalzo soletta (Ls) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={9}>
          <FormSubmitButtons
            onSubmit={onSubmitSolette}
            onReset={onResetSolette}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeSolette}
      />
    </>
  );
};

SoletteForm.propTypes = {
  soletteFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaSoletta: PropTypes.number,
      altezzaSpessoreBordoSoletta: PropTypes.number,
      lunghezzaSbalzoSoletta: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  soletteTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default SoletteForm;
