import { Amplify } from '@aws-amplify/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { awsConfig } from 'aws-exports';

import { useUsers } from 'hooks/useUsers';
import { useAuthSelector, useAuthStore } from 'stores';

const AmplifyProvider = ({ children }) => {
  const { getSession } = useAuthSelector();
  const { getUserInfo } = useUsers();

  useEffect(() => {
    Amplify.configure(awsConfig);
    sessionInit();
    getUserInfo().then(user => user && useAuthStore.setState({ user }));
  }, []);

  const sessionInit = useCallback(async () => {
    await getSession();
  }, [getSession]);

  return <>{children}</>;
};

AmplifyProvider.propTypes = {
  children: PropTypes.node,
};

export { AmplifyProvider };
