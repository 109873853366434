// 1. oggetto con id = 0 --> All
// 2. altri oggett: parent id, id univoco, nome field, l0 (booleano), l1, l2, label

export const objectDataTree = [
  {
    id: '0',
    parentId: null,
    inspectionId: null,
    label: 'Tutto',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '0',
    id: '1',
    inspectionId: null,
    label: 'Contesto',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '1',
    id: '2',
    inspectionId: null,
    label: 'Informazioni generali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '2',
    id: '3',
    inspectionId: ['2b'],
    label: 'Codice interno proprietario',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '2',
    id: '4',
    inspectionId: ['3'],
    label: 'Codice IOP',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '2',
    id: '5',
    inspectionId: ['4'],
    label: 'Tipologia collegamento',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '2',
    id: '6',
    inspectionId: ['5'],
    label: 'Nome opera',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '2',
    id: '7',
    inspectionId: ['6'],
    label: 'Strada di appartenenza (Nome)',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '2',
    id: '8',
    inspectionId: ['7'],
    label: 'Progressiva km iniziale',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '2',
    id: '9',
    inspectionId: ['8'],
    label: 'Progressiva km finale',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '2',
    id: '10',
    inspectionId: ['9'],
    label: 'Estesa (km)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '2',
    id: '11',
    inspectionId: ['10'],
    label: 'Tipologia di ente scavalcato',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '2',
    id: '12',
    inspectionId: ['11'],
    label: 'Rilevanza ente scavalcato',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '2',
    id: '13',
    inspectionId: ['12'],
    label: 'Nome ente scavalcato',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '2',
    id: '14',
    inspectionId: ['13'],
    label: `Classe d'uso`,
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '2',
    id: '15',
    inspectionId: ['14'],
    label: `Classe di conseguenza`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '2',
    id: '16',
    inspectionId: ['16'],
    label: `Stato dell'opera`,
    l0: true,
    l1: false,
    l2: true,
  },
  {
    parentId: '2',
    id: '17',
    inspectionId: ['17'],
    label: `Livello di conoscenza`,
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '1',
    id: '18',
    inspectionId: null,
    label: 'Dati amministrativi',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '18',
    id: '19',
    inspectionId: null,
    label: 'Dati generali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '19',
    id: '20',
    inspectionId: ['21'],
    label: 'Proprietario',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '19',
    id: '21',
    inspectionId: ['22'],
    label: 'Concessionario',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '19',
    id: '22',
    inspectionId: ['23'],
    label: 'Ente vigilante',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '19',
    id: '23',
    inspectionId: ['24'],
    label: 'Autorità distrettuale',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '19',
    id: '24',
    inspectionId: ['25'],
    label: 'Bacino idrografico',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '18',
    id: '25',
    inspectionId: null,
    label: 'Provvedimenti di tutela',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '25',
    id: '26',
    inspectionId: ['28'],
    label: 'Tutela ai sensi del D. Lgs. 42/2004',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '25',
    id: '27',
    inspectionId: ['29'],
    label: 'Provvedimenti di tutela',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '25',
    id: '28',
    inspectionId: ['30'],
    label: 'Autore della progettazione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '25',
    id: '29',
    inspectionId: ['31'],
    label: 'Inserimento nei Piani Paesaggistici vigenti/adottati',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '1',
    id: '30',
    inspectionId: null,
    label: 'Localizzazione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '30',
    id: '31',
    inspectionId: null,
    label: 'Dati generali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '32',
    inspectionId: ['32'],
    label: 'Regione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '33',
    inspectionId: ['33'],
    label: 'Provincia',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '31',
    id: '34',
    inspectionId: ['34'],
    label: 'Comune',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '31',
    id: '35',
    inspectionId: ['35'],
    label: 'Località',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '36',
    inspectionId: ['35b'],
    label: 'CAP',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '37',
    inspectionId: ['35c'],
    label: 'Indirizzo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '38',
    inspectionId: ['36'],
    label: 'Strada di appartenenza (Sigla)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '39',
    inspectionId: ['38', '39'],
    label: `Sismicità dell'area [ag/g]`,
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '40',
    inspectionId: ['40'],
    label: `Classificazione sismica`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '41',
    inspectionId: ['41'],
    label: `Coordinate CTR`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '42',
    inspectionId: ['42'],
    label: `Scala`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '43',
    inspectionId: ['43'],
    label: `Numero toponimo`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '44',
    inspectionId: ['44'],
    label: `Sistema di riferimento`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '31',
    id: '45',
    inspectionId: ['45'],
    label: `Ellissoide di riferimento`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '30',
    id: '46',
    inspectionId: null,
    label: 'Coordinate geografiche (centro)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '46',
    id: '47',
    inspectionId: ['46a'],
    label: 'Quota s.l.m. [m] (centro)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '46',
    id: '48',
    inspectionId: ['46b'],
    label: 'Latitudine (centro)',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '46',
    id: '49',
    inspectionId: ['46c'],
    label: 'Longitudine (centro)',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '30',
    id: '50',
    inspectionId: null,
    label: 'Coordinate geografiche (iniziale)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '50',
    id: '51',
    inspectionId: ['47a'],
    label: 'Quota s.l.m. [m] (iniziale)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '50',
    id: '52',
    inspectionId: ['47b'],
    label: 'Latitudine (iniziale)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '50',
    id: '53',
    inspectionId: ['47c'],
    label: 'Longitudine (iniziale)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '30',
    id: '54',
    inspectionId: null,
    label: 'Coordinate geografiche (finale)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '54',
    id: '55',
    inspectionId: ['48a'],
    label: 'Quota s.l.m. [m] (finale)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '54',
    id: '56',
    inspectionId: ['48b'],
    label: 'Latitudine (finale)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '54',
    id: '57',
    inspectionId: ['48c'],
    label: 'Longitudine (finale)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '1',
    id: '58',
    inspectionId: null,
    label: 'Informazioni di ispezione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '58',
    id: '59',
    inspectionId: null,
    label: 'Rischio strutturale e sismico',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '59',
    id: '60',
    inspectionId: ['49'],
    label: 'Rilevatore - Rischio strutturale e sismico',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '59',
    id: '61',
    inspectionId: ['50'],
    label: 'Data ispezione - Rischio strutturale e sismico',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '59',
    id: '62',
    inspectionId: ['51'],
    label: 'Possibilità di accedere al di sotto del ponte',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '59',
    id: '63',
    inspectionId: ['52'],
    label: 'N° campate ispezionate',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '59',
    id: '64',
    inspectionId: ['53'],
    label: 'Prospetti ispezionati',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '58',
    id: '65',
    inspectionId: null,
    label: 'Rischio frane',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '65',
    id: '66',
    inspectionId: ['55'],
    label: 'Affidabilità complessiva - Rischio frana',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '65',
    id: '67',
    inspectionId: ['56'],
    label: 'Rilevatore rischio frane',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '65',
    id: '68',
    inspectionId: ['57'],
    label: 'Data ispezione rischio frane',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '58',
    id: '69',
    inspectionId: null,
    label: 'Rischio idraulico',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '69',
    id: '70',
    inspectionId: ['59'],
    label: 'Affidabilità complessiva - Rischio idraulico',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '69',
    id: '71',
    inspectionId: ['60'],
    label: 'Rilevatore rischio idraulico',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '69',
    id: '72',
    inspectionId: ['61'],
    label: 'Data ispezione rischio idraulico',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '69',
    id: '73',
    inspectionId: ['61b'],
    label: 'Possibilità verifica scalzamento fondazioni',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '1',
    id: '74',
    inspectionId: null,
    label: 'Rischio frane',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '74',
    id: '75',
    inspectionId: null,
    label: 'Dati generali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '75',
    id: '76',
    inspectionId: ['63'],
    label: 'Rischio frana',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '75',
    id: '77',
    inspectionId: ['64'],
    label: 'Tipologia di interferenza',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '75',
    id: '78',
    inspectionId: ['65'],
    label: 'Necessità di ispezioni speciali (rischio frane)',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '75',
    id: '79',
    inspectionId: ['66'],
    label: 'Risultati ispezioni speciali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '75',
    id: '80',
    inspectionId: ['68'],
    label: 'Pericolosità PAI/PSAI - Frane',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '75',
    id: '81',
    inspectionId: ['71'],
    label: 'Misure di mitigazione',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '75',
    id: '82',
    inspectionId: ['73'],
    label: 'Estensione di interferenza',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '74',
    id: '83',
    inspectionId: null,
    label: 'Tipologia di fenomeno',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '83',
    id: '84',
    inspectionId: ['75'],
    label: 'Fenomeno',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '83',
    id: '85',
    inspectionId: ['76'],
    label: 'Tipologia di fenomeno',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '83',
    id: '86',
    inspectionId: ['77'],
    label: 'Distribuzione di attività (se definibile dai dati in possesso)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '83',
    id: '87',
    inspectionId: ['79'],
    label: 'Posizione instabilità sul versante',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '83',
    id: '88',
    inspectionId: ['78'],
    label: `Uso del suolo dell'area potenzialmente coinvolta`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '74',
    id: '89',
    inspectionId: null,
    label: 'Contesto geologico',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '89',
    id: '90',
    inspectionId: ['80'],
    label: 'Formazioni',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '89',
    id: '91',
    inspectionId: ['81'],
    label: 'Elemento livello 0 m di riferimento - Rischio frane',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '89',
    id: '92',
    inspectionId: ['82'],
    label: 'Quota orlo superiore zona distacco [m]',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '89',
    id: '93',
    inspectionId: ['83'],
    label: 'Quota ponte o viadotto [m]',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '89',
    id: '94',
    inspectionId: ['84'],
    label: 'Profondità superficie di distacco [m]',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '89',
    id: '95',
    inspectionId: ['85'],
    label: (
      <>
        Area totale [m<sup>2</sup>]
      </>
    ),
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '89',
    id: '96',
    inspectionId: ['86'],
    label: (
      <>
        Volume massa [m<sup>3</sup>]
      </>
    ),
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '74',
    id: '97',
    inspectionId: null,
    label: 'Parametri frana',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '97',
    id: '98',
    inspectionId: ['89'],
    label: 'Parametro dello stato di attività',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '97',
    id: '99',
    inspectionId: ['90'],
    label: 'Frane attive',
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '97',
    id: '100',
    inspectionId: ['91'],
    label: 'Parametro del grado di criticità',
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '97',
    id: '101',
    inspectionId: ['92'],
    label: 'Parametro della massima velocità potenziale di spostamento',
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '97',
    id: '102',
    inspectionId: ['93'],
    label: (
      <>
        Parametro della magnitudo su base volumetrica [m<sup>3</sup>]
      </>
    ),
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '1',
    id: '103',
    inspectionId: null,
    label: 'Rischio idraulico',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '103',
    id: '104',
    inspectionId: null,
    label: 'Dati generali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '104',
    id: '105',
    inspectionId: ['96'],
    label: 'Rischio idraulico',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '104',
    id: '106',
    inspectionId: ['97'],
    label: 'Necessità di ispezioni speciali (rischio idraulico)',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '104',
    id: '107',
    inspectionId: ['98'],
    label: 'Risultati ispezioni speciali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '104',
    id: '108',
    inspectionId: ['100'],
    label: 'Pericolosità PAI/PSAI/PGRA - Idraulico',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '104',
    id: '109',
    inspectionId: ['108'],
    label: `Parti della struttura che interessano l'alveo`,
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '104',
    id: '110',
    inspectionId: ['105'],
    label: `Fenomeno di sormonto o insufficienza di franco`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '104',
    id: '111',
    inspectionId: ['104'],
    label: `Fenomeni di erosione localizzata e generalizzata`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '104',
    id: '112',
    inspectionId: ['102'],
    label: `Presenza di studio idraulico con livelli idrici definiti`,
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '104',
    id: '113',
    inspectionId: ['111'],
    label: `Scenari di pericolosità noti`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '104',
    id: '114',
    inspectionId: ['106'],
    label: `Area riconosciuta pericolosa`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '104',
    id: '115',
    inspectionId: ['112'],
    label: `Presenza rilievi sezioni fluviali e struttura`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '104',
    id: '116',
    inspectionId: ['114', '115'],
    label: `Superficie bacino idrografico S`,
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '104',
    id: '117',
    inspectionId: ['109'],
    label: `Tipologia di reticolo`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '104',
    id: '118',
    inspectionId: ['110'],
    label: `Ricadente in area mappata ai sensi della direttiva alluvioni per esondazione di corsi d'acqua principali`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '103',
    id: '119',
    inspectionId: null,
    label: 'Caratteristiche alveo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '119',
    id: '120',
    inspectionId: ['118'],
    label: 'Confinamento alveo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '119',
    id: '121',
    inspectionId: ['119'],
    label: 'Morfologia alveo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '119',
    id: '122',
    inspectionId: ['120'],
    label: 'Sinuosità',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '119',
    id: '123',
    inspectionId: ['122'],
    label: 'Tipologia fondo alveo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '119',
    id: '124',
    inspectionId: ['123'],
    label: 'Natura materiale alveo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '119',
    id: '125',
    inspectionId: ['125'],
    label: 'Sensibile accumulo di detriti trasportati dalla corrente',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '119',
    id: '126',
    inspectionId: ['121'],
    label: 'Evoluzione alveo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '119',
    id: '127',
    inspectionId: ['116'],
    label: 'Alveo avente sensibile curvatura in corrispondenza del ponte',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '119',
    id: '128',
    inspectionId: ['126'],
    label: `Tendenza dell'alveo a divagazione planimetrica`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '119',
    id: '129',
    inspectionId: ['128'],
    label: `Tipologia corso d'acqua`,
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '103',
    id: '130',
    inspectionId: null,
    label: 'Sormonto o insufficienza di franco',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '130',
    id: '131',
    inspectionId: ['133'],
    label: `Elemento livello 0 m di riferimento - Rischio idraulico`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '130',
    id: '132',
    inspectionId: ['134'],
    label: `Quota minima intradosso del ponte [m]`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '130',
    id: '133',
    inspectionId: ['138'],
    label: `Quota del pelo libero (scenario P2) [m]`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '130',
    id: '134',
    inspectionId: ['141'],
    label: (
      <>
        Franco idraulico F<sub>P2</sub>[m]
      </>
    ),
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '130',
    id: '135',
    inspectionId: ['139'],
    label: `Quota del pelo libero (scenario P3) [m]`,
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '130',
    id: '136',
    inspectionId: ['142'],
    label: (
      <>
        Franco idraulico F<sub>P3</sub>[m]
      </>
    ),
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '130',
    id: '137',
    inspectionId: ['135'],
    label: `Quota margine (min)(Caso I) [m]`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '130',
    id: '138',
    inspectionId: ['136'],
    label: `Quota sponda (max)(Caso II) [m]`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '130',
    id: '139',
    inspectionId: ['137'],
    label: `Quota fondo alveo (Caso III) [m]`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '130',
    id: '140',
    inspectionId: ['140'],
    label: `Quota pelo libero stimata [m]`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '130',
    id: '141',
    inspectionId: ['143'],
    label: `Franco idraulico stimato [m]`,
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '130',
    id: '142',
    inspectionId: ['144'],
    label: `Franco > 2 m per evidenze di campo`,
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '130',
    id: '143',
    inspectionId: ['145'],
    label: `Evidente e accertata deposizione di sedimenti`,
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '130',
    id: '144',
    inspectionId: ['147'],
    label: `Trasporto di materiale vegetale di notevole dimensione`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '103',
    id: '145',
    inspectionId: null,
    label: 'Erosione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '146',
    inspectionId: ['150'],
    label: (
      <>
        W<sub>a,l</sub> [m]
      </>
    ),
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '147',
    inspectionId: ['151'],
    label: (
      <>
        W<sub>a</sub> [m]
      </>
    ),
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '148',
    inspectionId: ['152'],
    label: (
      <>
        Fattore di restingimento alveo inciso C<sub>a</sub> [%]
      </>
    ),
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '145',
    id: '149',
    inspectionId: ['149'],
    label: `Aree golenali assenti`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '150',
    inspectionId: ['153'],
    label: (
      <>
        W<sub>g,l</sub> [m]
      </>
    ),
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '151',
    inspectionId: ['154'],
    label: (
      <>
        W<sub>g</sub> [m]
      </>
    ),
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '152',
    inspectionId: ['155'],
    label: (
      <>
        Fattore di restingimento delle aree golenali C<sub>g</sub> [%]
      </>
    ),
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '153',
    inspectionId: ['157'],
    label: `Larghezza pila o aggetto spalla a [m]`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '154',
    inspectionId: ['158'],
    label: (
      <>
        Massima profondità di scavo d<sub>s</sub> [m]
      </>
    ),
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '155',
    inspectionId: ['159'],
    label: (
      <>
        Profondità fondazione rispetto all alveo d<sub>f</sub> [m]
      </>
    ),
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '156',
    inspectionId: ['160'],
    label: `Fattore IEL`,
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '145',
    id: '157',
    inspectionId: ['164'],
    label: `Evidenze sulla profondità delle fondazioni`,
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '145',
    id: '158',
    inspectionId: ['156'],
    label: `Evoluzione eventuale del fondo alveo rispetto alle pile`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '159',
    inspectionId: ['161'],
    label: `Abbassamento generalizzato dell'alveo`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '160',
    inspectionId: ['163'],
    label: `Abbassamento molto accentuato anche in relazione alle fondazioni`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '145',
    id: '161',
    inspectionId: ['166'],
    label: `Accumuli di detriti o materiale flottante a monte della pila`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '103',
    id: '162',
    inspectionId: null,
    label: 'Opere di protezione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '162',
    id: '163',
    inspectionId: ['168'],
    label: 'Opere di protezione spondale',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '162',
    id: '164',
    inspectionId: ['169'],
    label: 'Stato di conservazione e adeguatezza opera spondale',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '162',
    id: '165',
    inspectionId: ['171'],
    label: 'Protezione al piede delle pile e delle spalle del ponte',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '162',
    id: '166',
    inspectionId: ['172'],
    label: 'Stato di conservazione e adeguatezza protezione al piede',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '162',
    id: '167',
    inspectionId: ['174'],
    label: 'Briglia di protezione immediatamente a valle del ponte',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '162',
    id: '168',
    inspectionId: ['175'],
    label: 'Stato di conservazione e adeguatezza briglia',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '103',
    id: '169',
    inspectionId: null,
    label: 'Opere di mitigazione e monitoraggio',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '169',
    id: '170',
    inspectionId: ['177'],
    label: 'Opere di arginatura, di alterazione delle portate liquide e solide',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '169',
    id: '171',
    inspectionId: ['179'],
    label: `Stato di conservazione e adeguatezza opere di arginatura e alterazione`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '169',
    id: '172',
    inspectionId: ['178'],
    label: `Tipologia opere di arginatura e alterazione`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '169',
    id: '173',
    inspectionId: ['186'],
    label: `Tipologia aree di possibile allagamento`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '169',
    id: '174',
    inspectionId: ['180'],
    label: `Misure/opere di laminazione e mitigazione`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '169',
    id: '175',
    inspectionId: ['182'],
    label: `Stato di conservazione e adeguatezza opere di laminazione`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '169',
    id: '176',
    inspectionId: ['181'],
    label: `Tipologia opere di laminazione`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '169',
    id: '177',
    inspectionId: ['183'],
    label: `Sistemi di monitoraggio`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '169',
    id: '178',
    inspectionId: ['184'],
    label: `Tipologia sistemi di monitoraggio`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '169',
    id: '179',
    inspectionId: ['185'],
    label: `Stato di conservazione sistemi di monitoraggio`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '1',
    id: '180',
    inspectionId: null,
    label: 'Dati di progetto',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '180',
    id: '181',
    inspectionId: null,
    label: 'Dati generali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '181',
    id: '182',
    inspectionId: ['189'],
    label: 'Progettista',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '181',
    id: '183',
    inspectionId: ['191', '192'],
    label: 'Anno di progettazione',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '181',
    id: '184',
    inspectionId: ['194'],
    label: 'Norma di progetto',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '181',
    id: '185',
    inspectionId: ['193'],
    label: 'Classe di progettazione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '181',
    id: '186',
    inspectionId: ['196', '197'],
    label: 'Anno di costruzione',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '181',
    id: '187',
    inspectionId: ['199'],
    label: 'Anno di entrata in esercizio',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '181',
    id: '188',
    inspectionId: ['205'],
    label: 'Ente approvatore',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '181',
    id: '189',
    inspectionId: ['200'],
    label: 'Interventi significativi di manutenzione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '181',
    id: '190',
    inspectionId: ['202', '203'],
    label: 'Anno interventi significativi di manutenzione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '181',
    id: '191',
    inspectionId: ['204'],
    label: 'Criteri antisismici in opera',
    l0: true,
    l1: false,
    l2: true,
  },
  {
    parentId: '180',
    id: '192',
    inspectionId: null,
    label: 'Documentazione iniziale',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '192',
    id: '193',
    inspectionId: ['206'],
    label: 'Strumenti del finanziamento',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '192',
    id: '194',
    inspectionId: ['207'],
    label: 'Piani e strumento di programmazione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '180',
    id: '195',
    inspectionId: null,
    label: 'Progetto preliminare/di massima',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '195',
    id: '196',
    inspectionId: ['208'],
    label: 'Documenti descrittivi Progetto preliminare/di massima',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '195',
    id: '197',
    inspectionId: ['209'],
    label: 'Documenti grafici Progetto preliminare/di massima',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '195',
    id: '198',
    inspectionId: ['210'],
    label: `Documenti inerenti l'iter di approvazione Progetto preliminare/di massima`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '180',
    id: '199',
    inspectionId: null,
    label: 'Progetto definitivo/esecutivo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '199',
    id: '200',
    inspectionId: ['211'],
    label: 'Documenti descrittivi Progetto definitivo/esecutivo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '199',
    id: '201',
    inspectionId: ['212'],
    label: 'Documenti grafici Progetto definitivo/esecutivo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '199',
    id: '202',
    inspectionId: ['213'],
    label: `Documenti inerenti l'iter di approvazione Progetto definitivo/esecutivo`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '180',
    id: '203',
    inspectionId: null,
    label: 'Progetto esecutivo/cantierabile',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '203',
    id: '204',
    inspectionId: ['214'],
    label: 'Documenti descrittivi Progetto esecutivo/cantierabile',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '203',
    id: '205',
    inspectionId: ['215'],
    label: 'Documenti grafici Progetto esecutivo/cantierabile',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '203',
    id: '206',
    inspectionId: ['216'],
    label: `Documenti inerenti l'iter di approvazione Progetto esecutivo/cantierabile`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '180',
    id: '207',
    inspectionId: null,
    label: 'Direzione lavori',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '207',
    id: '208',
    inspectionId: ['217'],
    label: 'Documenti contabili Direzione lavori',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '207',
    id: '209',
    inspectionId: ['218'],
    label: `Grafici allegati alla contabilità dell'appalto- Direzione lavori`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '180',
    id: '210',
    inspectionId: null,
    label: 'Realizzazione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '210',
    id: '211',
    inspectionId: ['219'],
    label: 'Documenti contrattuali Realizzazione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '210',
    id: '212',
    inspectionId: ['220'],
    label: 'Documenti contabili Realizzazione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '180',
    id: '213',
    inspectionId: null,
    label: `Varianti in corso d'opera`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '213',
    id: '214',
    inspectionId: ['221'],
    label: `Documenti descrittivi Varianti in corso d'opera`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '213',
    id: '215',
    inspectionId: ['222'],
    label: `Documenti grafici Varianti in corso d'opera`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '213',
    id: '216',
    inspectionId: ['223'],
    label: `Documenti contrattuali e contabili Varianti in corso d'opera`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '180',
    id: '217',
    inspectionId: null,
    label: `Collaudo`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '217',
    id: '218',
    inspectionId: ['224'],
    label: `Relazione di collaudo`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '217',
    id: '219',
    inspectionId: ['225'],
    label: `Allegati alla relazione di collaudo`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '1',
    id: '220',
    inspectionId: null,
    label: 'Attività pregresse',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '220',
    id: '221',
    inspectionId: ['226a'],
    label: 'Verifica statica',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '220',
    id: '222',
    inspectionId: ['226c'],
    label: 'Verifica sismica',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '220',
    id: '223',
    inspectionId: ['226'],
    label: 'Ispezioni pregresse',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '220',
    id: '224',
    inspectionId: ['228'],
    label: 'Segnalazioni',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '220',
    id: '225',
    inspectionId: ['230'],
    label: 'Interventi eseguiti',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '220',
    id: '226',
    inspectionId: ['232'],
    label: 'Attività di monitoraggio pregresse o in corso',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '220',
    id: '227',
    inspectionId: ['234'],
    label: 'Interventi di manutenzione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '1',
    id: '228',
    inspectionId: null,
    label: 'Collegamento e rete stradale',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '229',
    inspectionId: ['236'],
    label: `Classificazione d'uso stradale`,
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '417',
    inspectionId: ['236b'],
    label: `Competenza infastruttura`,
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '230',
    inspectionId: ['237'],
    label: `Categoria ponte`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '231',
    inspectionId: ['238'],
    label: `Itinerario internazionale`,
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '232',
    inspectionId: ['239'],
    label: `Rete TEN`,
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '233',
    inspectionId: ['240'],
    label: `Opera strategica`,
    l0: true,
    l1: false,
    l2: true,
  },
  {
    parentId: '228',
    id: '234',
    inspectionId: ['241'],
    label: `Trasporti eccezionali`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '235',
    inspectionId: ['243'],
    label: `Disponibilità di studi trasportistici specifici`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '236',
    inspectionId: ['245'],
    label: `Disponibilità di studi sugli effetti dei carichi da traffico`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '238',
    inspectionId: ['248'],
    label: `N° carreggiate`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '239',
    inspectionId: ['249'],
    label: `N° corsie/carreggiata`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '240',
    inspectionId: ['250'],
    label: `Larghezza carreggiata [m]`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '241',
    inspectionId: ['251'],
    label: `Presenza di curve`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '242',
    inspectionId: ['253', '254'],
    label: `TGM [veicoli/giorno] (carreggiata)`,
    l0: true,
    l1: false,
    l2: true,
  },
  {
    parentId: '228',
    id: '243',
    inspectionId: ['256', '257'],
    label: `TGM - Commerciali [veicoli/giorno] (carreggiata)`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '244',
    inspectionId: ['259', '260'],
    label: `TGM- Commerciali [veicoli/giorno] (singola corsia)`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '245',
    inspectionId: ['261'],
    label: `Limitazione di carico`,
    l0: true,
    l1: false,
    l2: true,
  },
  {
    parentId: '228',
    id: '246',
    inspectionId: ['261b'],
    label: `Valore limitazione (t)`,
    l0: true,
    l1: false,
    l2: true,
  },
  {
    parentId: '228',
    id: '247',
    inspectionId: ['266'],
    label: `Frequente passaggio di persone`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '248',
    inspectionId: ['267'],
    label: `Trasporto merci pericolose`,
    l0: true,
    l1: false,
    l2: true,
  },
  {
    parentId: '228',
    id: '249',
    inspectionId: ['262'],
    label: `Alternative stradali`,
    l0: true,
    l1: false,
    l2: true,
  },
  {
    parentId: '228',
    id: '250',
    inspectionId: ['263'],
    label: `Durata deviazione [km]`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '251',
    inspectionId: ['264'],
    label: `Durata deviazione [min]`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '228',
    id: '252',
    inspectionId: ['265'],
    label: `Categoria del percorso alternativo individuato`,
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '1',
    id: '253',
    inspectionId: null,
    label: 'Dati geomorfologi ed esposizione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '253',
    id: '254',
    inspectionId: ['268'],
    label: 'Unità fisiografica',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '253',
    id: '255',
    inspectionId: ['269'],
    label: 'Morfologia/Categoria topografica',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '253',
    id: '256',
    inspectionId: ['270'],
    label: 'Categoria di sottosuolo',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '253',
    id: '257',
    inspectionId: ['270b'],
    label: 'Categoria di sottosuolo presunta',
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '253',
    id: '258',
    inspectionId: ['271'],
    label: 'Aerosol marini e sali antigelo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '253',
    id: '259',
    inspectionId: ['272'],
    label: 'Tali da determinare una maggiore rapidità del degrado',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '253',
    id: '260',
    inspectionId: ['273'],
    label: 'Stralcio cartografico',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '253',
    id: '261',
    inspectionId: ['274'],
    label: 'Satellitare /Foto aeree storiche',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '253',
    id: '262',
    inspectionId: ['275'],
    label: 'Satellitare /Foto aeree attuale',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '253',
    id: '263',
    inspectionId: ['276'],
    label: 'Riprese fotografiche',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '0',
    id: '264',
    inspectionId: null,
    label: 'Struttura',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '264',
    id: '265',
    inspectionId: null,
    label: 'Caratteristiche geometriche',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '265',
    id: '266',
    inspectionId: ['277'],
    label: 'Luce complessiva (Estesa) [m]',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '265',
    id: '267',
    inspectionId: ['278'],
    label: 'Luce complessiva (spalla-spalla) [m]',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '265',
    id: '268',
    inspectionId: ['279'],
    label: 'Tracciato',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '265',
    id: '269',
    inspectionId: ['280'],
    label: 'Altezza utile sotto impalcato [m]',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '265',
    id: '270',
    inspectionId: ['281'],
    label: 'Larghezza totale impalcato [m]',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '265',
    id: '271',
    inspectionId: ['282'],
    label: 'N° campate',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '265',
    id: '272',
    inspectionId: ['283'],
    label: 'Luce media campate [m]',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '265',
    id: '273',
    inspectionId: ['284'],
    label: 'Luce massima campate [m]',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '264',
    id: '274',
    inspectionId: null,
    label: 'Caratteristiche strutturali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '274',
    id: '275',
    inspectionId: null,
    label: 'Dati generali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '275',
    id: '276',
    inspectionId: ['317'],
    label: 'Tipologia strutturale',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '275',
    id: '277',
    inspectionId: ['318'],
    label: 'Necessità di ispezioni speciali (rischio struttura)',
    l0: true,
    l1: false,
    l2: false,
  },
  {
    parentId: '275',
    id: '278',
    inspectionId: ['319'],
    label: 'Risultati ispezioni speciali (rischio struttura)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '275',
    id: '279',
    inspectionId: ['321'],
    label: 'Tipologia schema',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '275',
    id: '280',
    inspectionId: ['322'],
    label: 'Tipologia impalcato',
    l0: true,
    l1: true,
    l2: true,
  },
  {
    parentId: '275',
    id: '281',
    inspectionId: ['324'],
    label: 'N° spalle',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '275',
    id: '282',
    inspectionId: ['323'],
    label: 'N° pile',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '275',
    id: '283',
    inspectionId: ['325'],
    label: 'Mensole',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '275',
    id: '284',
    inspectionId: ['326'],
    label: 'N° mensole',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '275',
    id: '285',
    inspectionId: ['327'],
    label: 'N° travi tampone',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '264',
    id: '286',
    inspectionId: null,
    label: 'Elementi strutturali',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '287',
    inspectionId: ['328b'],
    label: 'Appoggi (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '288',
    inspectionId: ['328c'],
    label: 'Giunti (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '289',
    inspectionId: ['328d'],
    label: 'Pulvini (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '290',
    inspectionId: ['328e'],
    label: 'Antenne (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '291',
    inspectionId: ['328f'],
    label: 'Altri Dispositivi antisismici (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '292',
    inspectionId: ['328i'],
    label: 'Travi (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '293',
    inspectionId: ['328l'],
    label: 'Traversi (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '294',
    inspectionId: ['328m'],
    label: 'Controventi (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '295',
    inspectionId: ['328n'],
    label: 'Solette (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '296',
    inspectionId: ['328o'],
    label: 'Archi (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '297',
    inspectionId: ['328p'],
    label: 'Piedritti (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '298',
    inspectionId: ['328q'],
    label: 'Tiranti (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '286',
    id: '299',
    inspectionId: ['328r'],
    label: 'Elementi extra (n°)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '264',
    id: '300',
    inspectionId: null,
    label: 'Schemi geometrici',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '300',
    id: '301',
    inspectionId: ['329'],
    label: 'Sezione trasversale',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '300',
    id: '302',
    inspectionId: ['330'],
    label: 'Sezione longitudinale',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '300',
    id: '303',
    inspectionId: ['331'],
    label: 'Pianta',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '264',
    id: '304',
    inspectionId: null,
    label: 'Elevazione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '304',
    id: '305',
    inspectionId: null,
    label: 'Spalle - S',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '305',
    id: '306',
    inspectionId: ['335'],
    label: 'Tipologia spalle',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '305',
    id: '308',
    inspectionId: ['342'],
    label: 'Classificazione delle vie di attacco',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '305',
    id: '309',
    inspectionId: ['343'],
    label: 'Imposta fondazioni spalle',
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '304',
    id: '311',
    inspectionId: null,
    label: 'Pile - S',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '311',
    id: '312',
    inspectionId: ['348'],
    label: 'Tipologia pila',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '311',
    id: '313',
    inspectionId: ['349'],
    label: 'Tipologia sezione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '311',
    id: '314',
    inspectionId: ['350'],
    label: 'Geometria sezione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '311',
    id: '315',
    inspectionId: ['351'],
    label: 'Pila in alveo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '311',
    id: '316',
    inspectionId: ['354'],
    label: 'Altezza massima (HM) [m]',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '311',
    id: '317',
    inspectionId: ['359'],
    label: 'N° fondazioni pila',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '311',
    id: '318',
    inspectionId: ['360'],
    label: 'Imposta fondazioni pila',
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '264',
    id: '319',
    inspectionId: null,
    label: 'Collegamenti',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '319',
    id: '320',
    inspectionId: null,
    label: 'Appoggi - AP',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '320',
    id: '321',
    inspectionId: ['365'],
    label: 'Tipologia Appoggio',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '320',
    id: '322',
    inspectionId: ['366'],
    label: 'Appoggi antisismici',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '319',
    id: '323',
    inspectionId: null,
    label: 'Giunti - G',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '323',
    id: '324',
    inspectionId: ['370'],
    label: 'Tipologia giunto',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '323',
    id: '325',
    inspectionId: ['371'],
    label: 'Larghezza (L) [m]',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '319',
    id: '326',
    inspectionId: null,
    label: 'Pulvini - PU',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '326',
    id: '327',
    inspectionId: ['376'],
    label: 'Tipologia pulvino',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '319',
    id: '328',
    inspectionId: null,
    label: 'Antenne - AN',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '328',
    id: '329',
    inspectionId: ['385'],
    label: 'Tipologia antenna',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '264',
    id: '330',
    inspectionId: null,
    label: 'Impalcato',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '330',
    id: '331',
    inspectionId: null,
    label: 'Soletta - SO',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '331',
    id: '332',
    inspectionId: ['396'],
    label: 'Tipologia soletta',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '331',
    id: '333',
    inspectionId: ['398'],
    label: 'Lunghezza sbalzo soletta (Ls) [m]',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '330',
    id: '334',
    inspectionId: null,
    label: 'Travi - TP',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '334',
    id: '335',
    inspectionId: ['402'],
    label: 'Tipologia Travi',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '330',
    id: '336',
    inspectionId: null,
    label: 'Traversi - TS',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '336',
    id: '337',
    inspectionId: ['411'],
    label: 'Tipologia Traversi',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '330',
    id: '338',
    inspectionId: null,
    label: 'Controventi - CV',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '338',
    id: '339',
    inspectionId: ['419'],
    label: 'Tipologia Controventi',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '330',
    id: '340',
    inspectionId: null,
    label: 'Archi - A',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '340',
    id: '341',
    inspectionId: ['429'],
    label: 'Tipologia archi',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '330',
    id: '342',
    inspectionId: null,
    label: 'Piedritti - PD',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '342',
    id: '343',
    inspectionId: ['439'],
    label: 'Tipologia piedritti',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '330',
    id: '344',
    inspectionId: null,
    label: 'Tiranti - TN',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '344',
    id: '345',
    inspectionId: ['447'],
    label: 'Tipologia tiranti',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '330',
    id: '346',
    inspectionId: null,
    label: 'Elementi Extra - EX',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '346',
    id: '347',
    inspectionId: ['457'],
    label: 'Tipologia elementi extra',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '264',
    id: '348',
    inspectionId: null,
    label: 'Aspetti critici',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '348',
    id: '349',
    inspectionId: null,
    label: 'Elementi di vulnerabilità sismica (vedi § 4.3.3 delle Linee Guida)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '349',
    id: '350',
    inspectionId: ['480'],
    label: 'Elementi di vulnerabilità sismica',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '348',
    id: '351',
    inspectionId: null,
    label:
      'Elementi critici - Vulnerabilità strutturale e fondazionale (vedi § 3.3 delle Linee Guida)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '351',
    id: '352',
    inspectionId: ['464'],
    label: 'Elementi critici - Vulnerabilità strutturale e fondazionale',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '351',
    id: '353',
    inspectionId: ['466'],
    label: 'Tipologia di elemento',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '348',
    id: '354',
    inspectionId: null,
    label:
      'Elementi critici - Vulnerabilità sismica (vedi § 3.3 delle Linee Guida)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '354',
    id: '355',
    inspectionId: ['469'],
    label: 'Elementi critici - Vulnerabilità sismica',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '354',
    id: '356',
    inspectionId: ['471'],
    label: 'Tipologia di elemento',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '348',
    id: '357',
    inspectionId: null,
    label:
      'Condizioni critiche - Vulnerabilità strutturale e fondazionale (vedi § 3.3 delle Linee Guida)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '357',
    id: '358',
    inspectionId: ['474'],
    label: 'Condizioni critiche - Vulnerabilità strutturale e fondazionale',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '357',
    id: '359',
    inspectionId: ['475'],
    label: 'Tipologia',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '348',
    id: '360',
    inspectionId: null,
    label:
      'Condizioni critiche - Vulnerabilità sismica (vedi § 3.3 delle Linee Guida)',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '360',
    id: '361',
    inspectionId: ['477'],
    label: 'Condizioni critiche - Vulnerabilità sismica',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '360',
    id: '362',
    inspectionId: ['478'],
    label: 'Tipologia',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '0',
    id: '363',
    inspectionId: null,
    label: 'Elementi accessori',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '363',
    id: '364',
    inspectionId: ['499b+c'],
    label: 'Cordoli',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '363',
    id: '365',
    inspectionId: ['499d+e'],
    label: 'Marciapiedi',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '363',
    id: '366',
    inspectionId: ['499f+g'],
    label: 'Parapetti',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '363',
    id: '367',
    inspectionId: ['499h+i'],
    label: 'Guardiavia',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '363',
    id: '368',
    inspectionId: ['499l'],
    label: 'Spartitraffico',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '363',
    id: '369',
    inspectionId: ['499m'],
    label: 'Pavimentazione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '363',
    id: '370',
    inspectionId: ['i1'],
    label: 'Interventi su elementi accessori',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '0',
    id: '371',
    inspectionId: null,
    label: 'Servizi',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '371',
    id: '372',
    inspectionId: ['550'],
    label: 'Pali di illuminazione',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '371',
    id: '373',
    inspectionId: ['556'],
    label: 'Convogliamento acque',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '371',
    id: '374',
    inspectionId: ['560'],
    label: 'Sottoservizi',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '371',
    id: '375',
    inspectionId: ['i2'],
    label: 'Interventi su Servizi',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '0',
    id: '376',
    inspectionId: null,
    label: 'Livello 2',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '376',
    id: '377',
    inspectionId: null,
    label: 'Rischio complessivo',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '377',
    id: '378',
    inspectionId: ['cda-1'],
    label: 'Data prossima ispezione',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '377',
    id: '379',
    inspectionId: ['cda-2'],
    label: 'CdA complessiva',
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '376',
    id: '380',
    inspectionId: null,
    label: 'Rischio strutt.-fond.',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '380',
    id: '381',
    inspectionId: ['cda-3'],
    label: 'G5',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '380',
    id: '382',
    inspectionId: ['cda-4'],
    label: 'PS',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '380',
    id: '383',
    inspectionId: ['cda-5'],
    label: 'NR',
    l0: false,
    l1: true,
    l2: false,
  },
  {
    parentId: '380',
    id: '384',
    inspectionId: ['cda-6'],
    label: 'Livello difettosità strutt.-fond.',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '380',
    id: '385',
    inspectionId: ['cda-7'],
    label: 'Vulnerabilità strutt.-fond.',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '380',
    id: '386',
    inspectionId: ['cda-8'],
    label: 'Pericolosità strutt.-fond.',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '380',
    id: '387',
    inspectionId: ['cda-9'],
    label: 'Esposizione strutt.-fond.',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '380',
    id: '388',
    inspectionId: ['cda-10'],
    label: 'CdA strutt.-fond.',
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '376',
    id: '389',
    inspectionId: null,
    label: 'Rischio sismico',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '389',
    id: '390',
    inspectionId: ['cda-11'],
    label: 'Livello difettosità sismica',
    l0: false,
    l1: true,
    l2: true,
  },
  {
    parentId: '389',
    id: '391',
    inspectionId: ['cda-12'],
    label: 'Vulnerabilità sismica',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '389',
    id: '392',
    inspectionId: ['cda-13'],
    label: 'Pericolosità sismica',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '389',
    id: '393',
    inspectionId: ['cda-14'],
    label: 'Esposizione sismica',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '389',
    id: '394',
    inspectionId: ['cda-15'],
    label: 'CdA sismica',
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '376',
    id: '395',
    inspectionId: null,
    label: 'Rischio frane',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '395',
    id: '396',
    inspectionId: ['cda-16'],
    label: 'Vulnerabilità frane',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '395',
    id: '397',
    inspectionId: ['cda-17'],
    label: 'Suscettibilità frane',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '395',
    id: '398',
    inspectionId: ['cda-18'],
    label: 'Esposizione frane',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '395',
    id: '399',
    inspectionId: ['cda-19'],
    label: 'CdA frane',
    l0: false,
    l1: false,
    l2: true,
  },
  {
    parentId: '376',
    id: '400',
    inspectionId: null,
    label: 'Rischio idraulico',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '400',
    id: '401',
    inspectionId: null,
    label: 'Sormonto',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '401',
    id: '402',
    inspectionId: ['cda-20'],
    label: 'Vulnerabilità sormonto',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '401',
    id: '403',
    inspectionId: ['cda-21'],
    label: 'Pericolosità sormonto',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '401',
    id: '404',
    inspectionId: ['cda-22'],
    label: 'Esposizione sormonto',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '401',
    id: '405',
    inspectionId: ['cda-23'],
    label: 'CdA sormonto',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '400',
    id: '406',
    inspectionId: null,
    label: 'Erosione generalizzata',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '406',
    id: '407',
    inspectionId: ['cda-24'],
    label: 'Vulnerabilità erosione generalizzata',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '406',
    id: '408',
    inspectionId: ['cda-25'],
    label: 'Pericolosità erosione generalizzata',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '406',
    id: '409',
    inspectionId: ['cda-26'],
    label: 'Esposizione erosione generalizzata',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '406',
    id: '410',
    inspectionId: ['cda-27'],
    label: 'CdA erosione generalizzata',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '400',
    id: '411',
    inspectionId: null,
    label: 'Erosione localizzata',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '411',
    id: '412',
    inspectionId: ['cda-28'],
    label: 'Vulnerabilità erosione localizzata',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '411',
    id: '413',
    inspectionId: ['cda-29'],
    label: 'Pericolosità erosione localizzata',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '411',
    id: '414',
    inspectionId: ['cda-30'],
    label: 'Esposizione erosione localizzata',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '411',
    id: '415',
    inspectionId: ['cda-31'],
    label: 'CdA erosione localizzata',
    l0: false,
    l1: false,
    l2: false,
  },
  {
    parentId: '400',
    id: '416',
    inspectionId: ['cda-32'],
    label: 'CdA idraulica',
    l0: false,
    l1: false,
    l2: true,
  },
];
