import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import {
  handleArrayData,
  handleEnum,
} from 'components/ExportsPanel/ExportSelect/functions';
import { RischioIdraulico, YesNo } from 'constants/inspections';
import {
  BacinoIdrograficoPresuntoRischioIdraulicoOptions,
  EvidenzeProfonditaDelleFondazioniOptions,
  EvoluzioneAlveoRischioIdraulicoOptions,
  MorfologiaAlveoOptions,
  StatoConservazioneAdeguatezzaOperaOptions,
  StrutturaAlveoRischioIdraulicoOptions,
  tipoEnteScavalcatoOptions,
  TipologiaCorsoAcquaRischioIdraulicoOptions,
  TipologiaReticoloOptions,
  YesNoOptions,
} from 'constants/selectOptions';
import { formatData, isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import { styles } from './SintesiCdAIdraulica.styles';
import CalendarIcon from '../../../../assets/icons/calendar.png';
import LogoExport from '../../../../assets/icons/logoEsportazione.png';
import UserIcon from '../../../../assets/icons/user.png';
import { CdaColorMap } from '../SintesiCdAFrane/constants';

const SintesiCdAIdraulica = ({ pdfData, formData }) => {
  const enteScavalcato = handleArrayData(
    pdfData?.tipologiaEnteScavalcato,
    tipoEnteScavalcatoOptions
  );

  //Dati Generali - Caratteristiche idrauliche
  let dimensioniBacino;
  if (pdfData?.superficieEffettiva) {
    dimensioniBacino = `${pdfData?.bacinoIdrograficoEffettivoRischioIdraulico} Km`;
  } else if (
    pdfData?.bacinoIdrograficoPresuntoRischioIdraulico &&
    !pdfData?.bacinoIdrograficoEffettivoRischioIdraulico
  ) {
    dimensioniBacino = handleEnum(
      BacinoIdrograficoPresuntoRischioIdraulicoOptions,
      pdfData?.bacinoIdrograficoPresuntoRischioIdraulico
    );
  }
  const tipologiaReticolo = handleEnum(
    TipologiaReticoloOptions,
    pdfData?.tipologiaReticoloRischioIdraulico
  );
  const morgologiaAlveo = handleEnum(
    MorfologiaAlveoOptions,
    pdfData?.morfologiaAlveoRischioIdraulico
  );
  const evoluzioneAlveo = handleEnum(
    EvoluzioneAlveoRischioIdraulicoOptions,
    pdfData?.evoluzioneAlveoRischioIdraulico
  );
  const tipologiaCorsoAcqua = handleEnum(
    TipologiaCorsoAcquaRischioIdraulicoOptions,
    pdfData?.tipologiaCorsoAcquaRischioIdraulico
  );

  //Dati Generali - Struttura
  let luciIdrauliche;
  if (pdfData?.luceIdraulicaMin && pdfData?.luceIdraulicaMax) {
    if (pdfData?.luceIdraulicaMin === pdfData?.luceIdraulicaMax) {
      luciIdrauliche = `${pdfData?.luceIdraulicaMin} m`;
    } else if (pdfData?.luceIdraulicaMin && pdfData?.luceIdraulicaMax) {
      luciIdrauliche = `${pdfData?.luceIdraulicaMin} - ${pdfData?.luceIdraulicaMax} m`;
    }
  }

  const evidenzaProfonditaFondazioni = handleEnum(
    EvidenzeProfonditaDelleFondazioniOptions,
    pdfData?.evidenzeProfonditaDelleFondazioni
  );

  const partiInterferisconoAlveo = handleArrayData(
    pdfData?.strutturaAlveoRischioIdraulico,
    StrutturaAlveoRischioIdraulicoOptions
  );

  //Fenomeni di Sormonto
  console.log(pdfData?.francoIdraulicoFP2, 'pdfData?.francoIdraulicoFP2');
  let francoIdraulico;
  if (pdfData?.francoIdraulicoFP2) {
    francoIdraulico = `${parseFloat(pdfData?.francoIdraulicoFP2).toFixed(2)} m`;
  } else if (pdfData?.francoIdraulicoStimato) {
    francoIdraulico = `${parseFloat(pdfData?.francoIdraulicoStimato).toFixed(
      2
    )} m`;
  } else if (pdfData?.francoIdraulicoSuperiore) {
    if (pdfData?.francoIdraulicoSuperiore === YesNo.SI) {
      francoIdraulico = '>2m';
    } else if (pdfData?.francoIdraulicoSuperiore === YesNo.NO) {
      francoIdraulico = 'Non valutabile';
    }
  }
  const presenzaStudioIdraulico = handleEnum(
    YesNoOptions,
    pdfData?.presenzaStudioIdraulicoLivelliDefiniti
  );
  const evidenteDeposizioneSedimenti = handleEnum(
    YesNoOptions,
    pdfData?.deposizioneSedimenti
  );
  const trasportoMassimoMaterialeVegetale = handleEnum(
    YesNoOptions,
    pdfData?.materialeVegetaleRischioIdraulico
  );

  //Fenomeni di Erosione
  const abbassamentoGeneraleAlveo = handleEnum(
    YesNoOptions,
    pdfData?.abbassamentoGeneralizzatoAlveo
  );
  const accumuliDetriti = handleEnum(
    YesNoOptions,
    pdfData?.accumuliDetritiAMontePila
  );
  const protezione = handleEnum(YesNoOptions, pdfData?.protezioni1);

  const statoConservazione = handleEnum(
    StatoConservazioneAdeguatezzaOperaOptions,
    pdfData?.protezioni2
  );

  const renderStatusCdA = () => {
    if (pdfData?.cdaIdraulica) {
      return (
        <View
          style={[
            styles.infoTagGreyTitle,
            {
              backgroundColor: CdaColorMap[pdfData.cdaIdraulica].labelColor,
            },
          ]}
        >
          <Text style={styles.tagTextWhiteTitle}>
            {CdaColorMap[pdfData.cdaIdraulica].labelText}
          </Text>
        </View>
      );
    }
    return (
      <View style={styles.infoTagGreyTitle}>
        <Text style={styles.tagTextWhiteTitle}>DA DEFINIRE</Text>
      </View>
    );
  };

  const renderRischioIdraulico = () => {
    if (pdfData.rischioIdraulico) {
      if (pdfData.rischioIdraulico === RischioIdraulico.Presente) {
        return (
          <View style={styles.infoTagBlue}>
            <Text style={styles.tagText}>PRESENTE</Text>
          </View>
        );
      } else if (pdfData.rischioIdraulico === RischioIdraulico.DaVerificare) {
        return (
          <View style={styles.infoTagOutline}>
            <Text style={[styles.tagText, { color: 'black' }]}>
              DA VERIFICARE
            </Text>
          </View>
        );
      } else {
        return (
          <View style={styles.infoTagGrey}>
            <Text style={styles.tagText}>ASSENTE</Text>
          </View>
        );
      }
    } else {
      return <Text style={styles.infoData}>{'-'}</Text>;
    }
  };

  const renderCdAFenomenoSormonto = () => {
    if (pdfData.cdaSormonto) {
      return (
        <View
          style={[
            styles.infoTagFenomeni,
            {
              backgroundColor: CdaColorMap[pdfData.cdaSormonto].labelColor,
            },
          ]}
        >
          <Text style={styles.tagTextInSection}>{`CDA ${
            CdaColorMap[pdfData.cdaSormonto].labelText
          }`}</Text>
        </View>
      );
    } else {
      return (
        <View style={[styles.infoTagFenomeni, { backgroundColor: '#C3C3C3' }]}>
          <Text style={styles.tagTextInSection}>{`CDA DA DEFINIRE`}</Text>
        </View>
      );
    }
  };

  const renderCdAFenomenoErosione = () => {
    if (pdfData.cdaErosione) {
      return (
        <View
          style={[
            styles.infoTagFenomeni,
            {
              backgroundColor: CdaColorMap[pdfData.cdaErosione].labelColor,
            },
          ]}
        >
          <Text style={styles.tagTextInSection}>{`CDA ${
            CdaColorMap[pdfData.cdaErosione].labelText
          }`}</Text>
        </View>
      );
    } else {
      return (
        <View style={[styles.infoTagFenomeni, { backgroundColor: '#C3C3C3' }]}>
          <Text style={styles.tagTextInSection}>{`CDA DA DEFINIRE`}</Text>
        </View>
      );
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.wrapperCdA}>
          <Text style={{ fontSize: '12pt', fontFamily: 'Helvetica-Bold' }}>
            CDA IDRAULICA
          </Text>
          {renderStatusCdA()}
        </View>
        <View
          style={{
            marginTop: 8,
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <View style={styles.iconElementContainer}>
            <Image src={CalendarIcon} />
          </View>
          <Text style={styles.infoLabel}>
            {pdfData?.dataIspezioneRischioIdraulico
              ? formatData(pdfData?.dataIspezioneRischioIdraulico)
              : '-'}
          </Text>
          <View
            style={[
              styles.iconElementContainer,
              {
                marginLeft: 10,
              },
            ]}
          >
            <Image src={UserIcon} />
          </View>
          <Text style={[styles.infoLabel, { lineHeight: 0 }]}>
            {pdfData?.rilevatoreRischioIdraulico || '-'}
          </Text>
        </View>

        <View style={{ marginTop: '8pt' }}>
          <Text style={styles.title}>{pdfData?.nomeOpera}</Text>
        </View>

        <View style={styles.flexContainer}>
          <Text style={styles.infoLabel}>Strada di appartenenza</Text>
          <Text style={styles.infoData}>
            {pdfData?.stradaAppartenenza || '-'}
          </Text>
        </View>

        <View style={styles.flexContainer}>
          <Text style={styles.infoLabel}>Progressiva Km Iniziale</Text>
          <Text style={styles.infoData}>
            {pdfData?.progressivaKmIniziale || '-'}
          </Text>
        </View>

        <View style={styles.flexContainer}>
          <Text style={styles.infoLabel}>Ente scavalcato</Text>
          <Text style={styles.infoData}>{enteScavalcato || '-'}</Text>
        </View>

        <View style={[styles.flexContainer, { marginTop: '6pt' }]}>
          <Text style={styles.infoLabel}>Rischio Idraulico</Text>
          {renderRischioIdraulico()}
        </View>

        {/* PHOTO SECTION ----------------------------- */}
        <View style={styles.imagesContainer}>
          {pdfData?.satellitareFotoAereeAttuale ? (
            <Image
              style={styles.image}
              src={{
                uri: pdfData?.satellitareFotoAereeAttuale,
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                  'Access-Control-Allow-Origin': '*',
                },
                body: '',
              }}
            />
          ) : (
            <View style={styles.placeholderImage} />
          )}

          {pdfData?.fotoCopertina ? (
            <Image
              style={styles.image}
              src={{
                uri: pdfData?.fotoCopertina,
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                  'Access-Control-Allow-Origin': '*',
                },
                body: '',
              }}
            />
          ) : (
            <View style={styles.placeholderImage} />
          )}
        </View>

        <View style={styles.captionsContainer}>
          <View style={styles.caption}>
            <Text style={styles.infoData}>Ortofoto</Text>
          </View>
          <View style={styles.caption}>
            <Text style={styles.infoData}>Foto</Text>
          </View>
        </View>
        {/* ------------------------------------------- */}

        {/* GENERAL DATA SECTION ----------------------------- */}
        <View style={styles.container}>
          <Text style={styles.titleH2}>Dati Generali</Text>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '55%' }}>
              <Text style={styles.titleH3}>CARATTERISTICHE IDRAULICHE</Text>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Dimensione bacino</Text>
                <Text style={styles.infoData}>{dimensioniBacino || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Tipologia reticolo</Text>
                <Text style={styles.infoData}>{tipologiaReticolo || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Morfologia alveo</Text>
                <Text style={styles.infoData}>{morgologiaAlveo || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Evoluzione alveo</Text>
                <Text style={styles.infoData}>{evoluzioneAlveo || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text
                  style={styles.infoLabel}
                >{`Tipologia corso d'acqua`}</Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoDataMaxWidth}>
                  {tipologiaCorsoAcqua || '-'}
                </Text>
              </View>
            </View>

            <View style={{ width: '45%' }}>
              <Text style={styles.titleH3}>STRUTTURA</Text>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Lunghezza totale</Text>
                <Text style={styles.infoData}>
                  {pdfData?.luceComplessivaEstesa
                    ? `${pdfData?.luceComplessivaEstesa} m`
                    : '-'}
                </Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Luci idrauliche</Text>
                <Text style={styles.infoData}>{luciIdrauliche || '-'}</Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Altezza utile</Text>
                <Text style={styles.infoData}>
                  {pdfData?.altezzaUtileSottoImpalcato
                    ? `${pdfData?.altezzaUtileSottoImpalcato} m`
                    : '-'}
                </Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Fondazioni</Text>
                <Text style={styles.infoData}>
                  {evidenzaProfonditaFondazioni || '-'}
                </Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Parti che interferiscono</Text>
                <Text style={styles.infoData}>
                  {partiInterferisconoAlveo || '-'}
                </Text>
              </View>

              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>
                  Larghezza pila o aggetto spalla
                </Text>
                <Text style={styles.infoData}>
                  {pdfData?.larghezzaPilaOAggettoSpalla
                    ? `${pdfData?.larghezzaPilaOAggettoSpalla} m`
                    : '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* ----------------------------------------------------------- */}

        {/* PHEONOMENA SECTION ------------------------------------------- */}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ width: '49%' }}>
            <View style={[styles.container, { height: '163pt' }]}>
              <Text style={styles.titleH2}>Fenomeni di Sormonto</Text>
              {renderCdAFenomenoSormonto()}
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Franco Idraulico</Text>
                <Text style={styles.infoData}>{francoIdraulico || '-'}</Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Presenza studio idraulico</Text>
                <Text style={styles.infoData}>
                  {presenzaStudioIdraulico || '-'}
                </Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Riferimento quote</Text>
                <Text style={styles.infoData}>
                  {pdfData?.riferimentoZeroMetriRischioIdraulico || '-'}
                </Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>
                  Evidente deposizione sedimenti
                </Text>
                <Text style={styles.infoData}>
                  {evidenteDeposizioneSedimenti || '-'}
                </Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>
                  Trasporto massivo di materiale vegetale
                </Text>
                <Text style={styles.infoData}>
                  {trasportoMassimoMaterialeVegetale || '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ width: '49%' }}>
            <View style={styles.container}>
              <Text style={styles.titleH2}>Fenomeni di Erosione</Text>
              {renderCdAFenomenoErosione()}
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>
                  Restringimento alveo inciso C
                  <Text style={[styles.infoLabel, { fontSize: 5 }]}>a</Text>
                </Text>
                <Text style={styles.infoData}>
                  {isNullOrUndefinedOrEmptyString(
                    pdfData?.fattoreRestringimentoAlveo
                  )
                    ? '-'
                    : `${pdfData?.fattoreRestringimentoAlveo}%`}
                </Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>
                  Restringimento golene C
                  <Text style={[styles.infoLabel, { fontSize: 5 }]}>g</Text>
                </Text>
                <Text style={styles.infoData}>
                  {isNullOrUndefinedOrEmptyString(
                    pdfData?.fattoreRestringimentoAreeGolenali
                  )
                    ? '-'
                    : `${pdfData?.fattoreRestringimentoAreeGolenali}%`}
                </Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>
                  Indice Erosione Localizzata
                </Text>
                <Text style={styles.infoData}>
                  {pdfData?.fattoreIEL?.toFixed(2) || '-'}
                </Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>
                  Abbassamento generale alveo
                </Text>
                <Text style={styles.infoData}>
                  {abbassamentoGeneraleAlveo || '-'}
                </Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>
                  Accumuli materiale flottante e detriti
                </Text>
                <Text style={styles.infoData}>{accumuliDetriti || '-'}</Text>
              </View>
              <View style={styles.flexContainer}>
                <Text style={styles.infoLabel}>Protezioni</Text>
                <Text style={styles.infoData}>
                  {pdfData?.protezioni1 && pdfData?.protezioni2
                    ? `${protezione}, ${statoConservazione}`
                    : '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* ----------------------------------------------------------- */}

        {/* DATA FORM ------------------------------------------------- */}
        <View style={[styles.formData, { left: 16 }]}>
          <Text style={styles.infoData}>
            {`${
              formData?.denominazioneCommittente &&
              `${formData.denominazioneCommittente},`
            } ${formData?.indirizzo && `${formData.indirizzo},`} ${
              formData?.cap && `${formData.cap},`
            } ${formData?.citta && `${formData.citta},`} ${
              formData?.provincia && `(${formData.provincia})`
            }`}
          </Text>
        </View>
        <View style={[styles.formData, { right: 16, bottom: 16 }]}>
          <Image style={{ width: '80pt' }} src={LogoExport} />
        </View>
      </Page>
    </Document>
  );
};

export { SintesiCdAIdraulica };

SintesiCdAIdraulica.propTypes = {
  pdfData: PropTypes.object,
  formData: PropTypes.object,
};
