/* eslint-disable */
import { Button, IconButton, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { Checkbox } from 'components';
import { iconType } from 'utils/utilities';
import { TableCell } from './TableCell';
import {
  CustomRow,
  IconButtonContainer,
  ZoomCloseIconContainer,
  ZoomedImage,
  ZoomImgContainer,
} from './TableRow.styles';
import { useState } from 'react';
import close from 'assets/icons/close.svg';
import { tour_09 } from 'constants/tour';
import { refreshS3Path } from 'utils/upload';

export const TableRow = ({
  row,
  selected,
  config,
  onRowClick,
  actions,
  hasCheckbox,
  handleCheckboxClick,
  isSelected,
  typevariant,
  mediaActions,
  compatibilityActions,
  schedaDifettiActions,
  preview,
  previewDocuments,
}) => {
  const [isZoom, setIsZoom] = useState(false);
  const handleZoom = () => {
    setIsZoom(prev => !prev);
  };

  const renderActions = row => {
    return (
      <IconButtonContainer>
        {actions.map(action => {
          return (
            <IconButton
              style={{ marginRight: 10 }}
              key={nanoid()}
              color="primary"
              size="small"
              disabled={
                (action.mediaTable
                  ? action.downloadIcon && !row.url
                    ? true
                    : false
                  : action.disabled) || row.disabled
              }
              onClick={() => action.onClick(row)}
            >
              {action.icon}
            </IconButton>
          );
        })}
      </IconButtonContainer>
    );
  };

  const renderMedia = row => {
    return (
      <IconButtonContainer>
        {mediaActions?.map(action => {
          return (
            <div key={nanoid()}>
              {`${row?.media?.length || '-'}  `}
              <IconButton
                style={{ marginRight: 10, padding: 0 }}
                color="primary"
                size="small"
                onClick={() => action.onClick(row)}
              >
                {action.icon}
              </IconButton>
            </div>
          );
        })}
      </IconButtonContainer>
    );
  };

  const renderCompatibility = row => {
    return (
      <>
        {compatibilityActions.map(action => {
          if (action.compatibilityHeader) {
            return <Typography>Da copiare</Typography>;
          }

          if (action.checkCompatibility(row)) {
            return <Typography>Compatibile</Typography>;
          } else {
            return <Typography color="error">Non Compatibile</Typography>;
          }
        })}
      </>
    );
  };

  const isCompatible = row => {
    return compatibilityActions.map(action => {
      return action.checkCompatibility(row);
    })[0];
  };

  const renderSchedaDifetti = row => {
    return (
      <>
        {schedaDifettiActions?.map(action => {
          return (
            <div key={nanoid()}>
              <Button
                fullWidth
                color="primary"
                size="small"
                variant="contained"
                onClick={() => action.onClick(row)}
              >
                {action.setButtonText(row)}
              </Button>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      {isZoom ? (
        <ZoomImgContainer>
          <ZoomCloseIconContainer onClick={() => handleZoom()}>
            <img src={close} width={15} alt="close icon" />
          </ZoomCloseIconContainer>
          <ZoomedImage src={refreshS3Path(row.url)} />
        </ZoomImgContainer>
      ) : (
        <CustomRow
          hover={!selected}
          key={row.id}
          selected={isSelected}
          onClick={onRowClick ? () => onRowClick(row) : undefined}
          data-value="tableRow"
          typevariant={typevariant}
        >
          <>
            {hasCheckbox && (
              <TableCell padding="checkbox" typevariant={typevariant}>
                {((compatibilityActions && isCompatible(row)) ||
                  !compatibilityActions) && (
                  <div className={`${tour_09.step_01}`}>
                    <Checkbox
                      onChange={event => handleCheckboxClick(event, row.id)}
                      checked={isSelected}
                    />
                  </div>
                )}
              </TableCell>
            )}
            {preview && row.isVideo && (
              <td>
                <video style={{ width: '32px', height: '32px' }}>
                  <source src={row.url}></source>
                </video>
              </td>
            )}
            {preview && !row.isVideo && (
              <td>
                <img
                  width={32}
                  height={32}
                  src={refreshS3Path(row.url)}
                  style={{
                    cursor: 'pointer',
                    margin: '8px 8px 0px',
                    backgroundImage: 'url(/upload-img-bg.svg)',
                    backgroundSize: 'cover',
                  }}
                  onClick={() => handleZoom()}
                />
              </td>
            )}
            {previewDocuments && (
              <img
                width={32}
                height={32}
                src={iconType(row.extensionFile)}
                alt="document icon"
              />
            )}
            {Object.keys(config.columns).map(key => {
              return (
                <TableCell
                  typevariant={typevariant}
                  key={nanoid()}
                  value={row[key]}
                  format={config.columns[key].format}
                  formatValue={config.columns[key].formatValue}
                />
              );
            })}
            {mediaActions && (
              <TableCell typevariant={typevariant} value={renderMedia(row)} />
            )}
            {schedaDifettiActions && (
              <TableCell
                typevariant={typevariant}
                value={renderSchedaDifetti(row)}
              />
            )}
            {compatibilityActions && (
              <TableCell
                typevariant={typevariant}
                value={renderCompatibility(row)}
              />
            )}
            {actions && (
              <TableCell typevariant={typevariant} value={renderActions(row)} />
            )}
          </>
        </CustomRow>
      )}
    </>
  );
};

TableRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
  actions: PropTypes.array,
  config: PropTypes.object,
  onRowClick: PropTypes.func,
  hasCheckbox: PropTypes.bool,
  handleCheckboxClick: PropTypes.func,
  isSelected: PropTypes.bool,
  typevariant: PropTypes.string,
  mediaActions: PropTypes.array,
  compatibilityActions: PropTypes.array,
  schedaDifettiActions: PropTypes.array,
  preview: PropTypes.bool,
  previewDocuments: PropTypes.bool,
};
