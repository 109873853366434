export const DeliveriesException = {
  CHECK_MAIL_NOT_EXISTENT: 1,
  CHECK_MAIL_EXISTS_ENTE: 2,
  CHECK_MAIL_EXISTS_PROFESSIONISTA: 3,
  CHECK_MAIL_EXISTS_INVITED: 4,
  CHECK_MAIL_EXISTS_IS_ADMIN: 5,
};

export const DeliveryStatus = {
  NON_CONSEGNATO: 1,
  IN_CONSEGNA: 2,
  CONSEGNATA: 3,
  ARCHIVIATA: 4,
};

export const CheckBridgeOwnerAndShares = {
  ENTE_PROPRIETARIO: 1,
  ENTE_NON_PROPRIETARIO_CONDIVISA_CON_ENTI: 2,
  ISPETTORE_PROPRIETARIO_NON_CONDIVISO_CON_ENTE: 3,
};

export const BridgeOwnerAndSharesMessageMap = {
  [CheckBridgeOwnerAndShares.ENTE_PROPRIETARIO]: 'Proprietario ente',
  [CheckBridgeOwnerAndShares.ENTE_NON_PROPRIETARIO_CONDIVISA_CON_ENTI]:
    'Proprietario non ente, ma condivisa con enti',
  [CheckBridgeOwnerAndShares.ISPETTORE_PROPRIETARIO_NON_CONDIVISO_CON_ENTE]:
    'Proprietario ispettore e non condivisa con enti',
};

export const DeliveriesResponseStatus = {
  OK: 1,
  ERROR: 2,
};
