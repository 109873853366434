import { useCallback } from 'react';
// eslint-disable-next-line import/no-named-as-default
import useFetch from 'use-http';

import { apiBridges } from 'constants/api';

export function useInspectionDeleteFetch() {
  const { loading, error, del } = useFetch(
    `${apiBridges.ROOT}${apiBridges.INSPECTIONS_ARCHIVE}`
  );

  const onInspectionDelete = useCallback(
    (inspectionId, deleteMediaCheckBox) =>
      del(`/${inspectionId}`, {
        deleteMedia: deleteMediaCheckBox ? true : false,
      }),
    [del]
  );

  return {
    loading,
    error,
    onInspectionDelete,
  };
}
