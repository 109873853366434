import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import { mensole, TipologiaGuardaviaTableValue } from 'constants/inspections';
import { tipologiaGuardaviaOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
const GuardavieForm = ({
  guardavieFields,
  selected,
  setSelected,
  guardavieTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t3a2_guardaviaTableValue: [],
      l0t3a2_tipologiaGuardaviaTableValue: '',
      l0t3a2_tipologiaAltroGuardaviaTableValue: '',
      l0t3a2_altezzaGuardaviaTableValue: '',
      l0t3a2_superficieGuardaviaTableValue: '',
    },
  });

  useEffect(() => {
    setValue('l0t3a2_guardaviaTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetGuardavie();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitGuardavie = () => {
    const l0t3a2_tipologiaGuardaviaTableValue = getValues(
      'l0t3a2_tipologiaGuardaviaTableValue'
    );
    const l0t3a2_tipologiaAltroGuardaviaTableValue = getValues(
      'l0t3a2_tipologiaAltroGuardaviaTableValue'
    );
    const l0t3a2_altezzaGuardaviaTableValue = getValues(
      'l0t3a2_altezzaGuardaviaTableValue'
    );
    const l0t3a2_superficieGuardaviaTableValue = getValues(
      'l0t3a2_superficieGuardaviaTableValue'
    );

    const ids = selected.map(el => el.codice);

    const guardavie = [...guardavieFields];
    guardavie.map((x, index) => {
      if (ids.includes(x?.codice)) {
        guardavie[index] = {
          ...x,
          tipologiaGuardavia: l0t3a2_tipologiaGuardaviaTableValue,
          tipologiaAltroGuardavia: l0t3a2_tipologiaAltroGuardaviaTableValue,
          altezzaGuardavia: l0t3a2_altezzaGuardaviaTableValue,
          superficieGuardavia: l0t3a2_superficieGuardaviaTableValue,
        };
      }
    });

    setValue(guardavieTable, guardavie);
    onResetGuardavie();
  };

  const onResetGuardavie = () => {
    setValue('l0t3a2_guardaviaTableValue', []);
    setValue('l0t3a2_tipologiaGuardaviaTableValue', '');
    setValue('l0t3a2_tipologiaAltroGuardaviaTableValue', '');
    setValue('l0t3a2_altezzaGuardaviaTableValue', '');
    setValue('l0t3a2_superficieGuardaviaTableValue', '');

    setSelected([]);
  };

  // Luce impalcato*(Altezza/100)
  const l0t3a2_sovrastrutturaImpalcatoSelezioneWatch = watch(
    'l0t3a2_sovrastrutturaImpalcatoSelezione'
  );
  const altezzaGuardaviaWatch = parseFloat(
    watch('l0t3a2_altezzaGuardaviaTableValue')
  );
  const guardavieTableWatch = watch('l0t3a2_guardaviaTableValue');
  useEffect(() => {
    const watchMensole = watch('mensole');
    const watchMensoleSi = watchMensole === mensole.Si;
    let result;
    if (altezzaGuardaviaWatch) {
      if (watchMensoleSi) {
        const mensoleTamponiTableWatch = getValues(
          `mensoleTamponiTable[${l0t3a2_sovrastrutturaImpalcatoSelezioneWatch}]`
        );
        result =
          parseFloat(mensoleTamponiTableWatch.luce) *
          (altezzaGuardaviaWatch / 100).toFixed(2);
      } else {
        const campataTableWatch = getValues(
          `campataTable[${l0t3a2_sovrastrutturaImpalcatoSelezioneWatch}]`
        );
        result =
          parseFloat(campataTableWatch.luceCampata) *
          (altezzaGuardaviaWatch / 100).toFixed(2);
      }
      setValue('l0t3a2_superficieGuardaviaTableValue', result.toFixed(2));
    } else {
      setValue('l0t3a2_superficieGuardaviaTableValue', '');
    }
  }, [
    l0t3a2_sovrastrutturaImpalcatoSelezioneWatch,
    guardavieTableWatch,
    altezzaGuardaviaWatch,
  ]);

  const tipologiaGuardaviaWatch = watch('l0t3a2_tipologiaGuardaviaTableValue');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (tipologiaGuardaviaWatch === TipologiaGuardaviaTableValue.Altro) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setValue('l0t3a2_tipologiaAltroGuardaviaTableValue', '');
    }
  }, [tipologiaGuardaviaWatch]);

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper size={6} infoMessage={INFO.guardavieSelezionati.infoMessage}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-guardavie_l0t3a2"
          name={'l0t3a2_guardaviaTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={guardavieFields
            .filter(el => !el.assente)
            ?.filter(el => !selected?.some(s => s?.id === el?.id))}
          label="Guardavie selezionati"
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="l0t3a2_tipologiaGuardaviaTableValue"
          label="Tipologia Guardavia*"
          defaultValue={''}
          onChange={e => {
            setValue('l0t3a2_tipologiaGuardaviaTableValue', e.target.value);
          }}
          selectOptions={tipologiaGuardaviaOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="l0t3a2_tipologiaAltroGuardaviaTableValue"
          label={'Tipologia Guardavia (Altro)'}
          disabled={isDisabled}
          inputProps={{ maxLength: 100 }}
          defaultValue=""
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="l0t3a2_altezzaGuardaviaTableValue"
          label={'Altezza (H) [cm]'}
          defaultValue=""
          decimalScale={2}
          withValueLimit={({ value }) => value < 1000}
        />
      </GridWrap>
      <InfoWrapper size={3} infoMessage={INFO.superficieGuardavia.infoMessage}>
        <RHFNumberTextField
          name="l0t3a2_superficieGuardaviaTableValue"
          label={'Superficie [m²]'}
          defaultValue=""
          decimalScale={2}
          disabled
          InputLabelProps={{ shrink: true }}
          withValueLimit={({ value }) => value < 1000}
        />
      </InfoWrapper>
      <GridWrap item xs={6}>
        <FormSubmitButtons
          onSubmit={onSubmitGuardavie}
          onReset={onResetGuardavie}
        />
      </GridWrap>
    </GridWrap>
  );
};
GuardavieForm.propTypes = {
  guardavieFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaGuardavia: PropTypes.number,
      tipologiaAltroGuardavia: PropTypes.string,
      altezzaGuardavia: PropTypes.number,
      superficieGuardavia: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  guardavieTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default GuardavieForm;
