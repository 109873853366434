export const markdown_faq_33_1 = `**VERSIONE DEMO - Versione gratuita**

La versione gratuita di INBEE (VERSIONE DEMO), consente di navigare all'interno della piattaforma e di visualizzare i ponti demo caricati. In aggiunta, consente di ricevere in condivisione da altri utenti ponti e attività per visulizzare o operare per gli adempimenti alle Linee Guida (all'inserimento dei dati, al calcolo automatico della Classe di attenzione complessiva, fino all'esportazione di report precompilati).
Una volta terminata ed esportata l'attività, l'accesso a quello specifico ponte terminerà dopo un definito lasso di tempo.

**PACCHETTO ALVEARE - Versione con sottoscrizione**

Se si vuole mantenere un **archivio dei propri ponti e delle proprie ispezioni** nel tempo, è disponibile il PACCHETTO ALVEARE.
Il pacchetto comprende, oltre all'**archivio** in Cloud, di mantenere perpetuo nel tempo il ciclo di vita del ponte ed il suo stato di sicurezza, di **gestire** attraverso gli avvisi le **pianificazioni** delle ispezioni future, di esportare i csv compatibili con AINOP (solo per enti gestori) e di ottenere eventuali aggiornamenti normativi.`;

export const markdown_faq_33_2 = `**COME ATTIVARE O RINNOVARE UNA SOTTOSCRIZIONE?**

Per attivare una sottoscrizione contattaci scrivendo a info@inbee.it oppure compilando il form [Contatti](https://inbee.it/contattaci/).
Ti faremo un preventivo personalizzato.

**QUANTO COSTA E DURA LA SOTTOSCRIZIONE?**

Per gli Enti Proprietari dei ponti la sottoscrizione ha un costo che dipende dal numero di ponti censiti e dalla quantità di spazio richiesta per l'archivio, ed una durata annuale o pluriennale in base alle esigenze. Per una quotazione specifica contattaci scrivendo a info@inbee.it oppure compilando il form [Contatti](https://inbee.it/contattaci/).

Per gli ispettori la sottoscrizione avrà un canone annuale fisso. Se ti interesserà procedere alla sottoscrizione scrivici a info@inbee.it oppure compila il form [Contatti](https://inbee.it/contattaci/).`;
