import { useCallback, useState } from 'react';

function invertSortDirection(direction) {
  if (direction === 'asc') return 'desc';

  return 'asc';
}

export function useOrder(defaultValue) {
  const [{ orderBy, direction }, setOrder] = useState({
    orderBy: undefined,
    direction: undefined,
    ...defaultValue,
  });

  const onOrderChange = useCallback(orderBy => {
    setOrder(prev => {
      if (orderBy === prev.orderBy && prev.direction === 'asc')
        return { orderBy, direction: invertSortDirection(prev.direction) };
      else if (orderBy === prev.orderBy) {
        return {
          orderBy: undefined,
          direction: undefined,
          ...defaultValue,
        };
      }

      return { orderBy, direction: 'asc' };
    });
  }, []);

  return {
    orderBy,
    direction,
    onOrderChange,
  };
}
