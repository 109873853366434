import styled from '@emotion/styled';

export const BackContainer = styled('div')({
  height: '24px',
  width: '24px',
  cursor: 'pointer',
  marginBottom: '9vh',
});

export const LogoContainer = styled('div')(({ theme }) => ({
  width: '209px',
  height: '53px',
  marginBottom: '9vh',
  '& img': {
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: '85%',
    },
  },
}));
