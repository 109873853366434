import * as am5 from '@amcharts/amcharts5';
import am5geodata_italy from '@amcharts/amcharts5-geodata/italyHigh';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';

import { MessageContainer } from './Chart.styles';

export const MapChart = ({ chartID, dataRegion, notAnimated, error }) => {
  useLayoutEffect(() => {
    if (!dataRegion) return;
    const root = am5.Root.new(chartID);
    /*   root.setThemes([am5themes_Animated.new(root)]); */

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create the map chart
    // https://www.amcharts.com/docs/v5/charts/map-chart/
    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        ...(notAnimated && { panX: 'none', panY: 'none', maxZoomLevel: 1 }),
      })
    );
    chart.chartContainer.wheelable = false;

    // Create polygon series for the world map
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/

    const itaSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_italy,
        exclude: ['MT', 'FR-H'],
      })
    );

    const remapDataRegion = dataRegion.map(dr => ({
      ...dr,
      id: dr.aMCId,
    }));

    itaSeries.data.setAll(remapDataRegion);
    const tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
    });

    tooltip.get('background').setAll({
      fill: am5.color(0xffffff),
      stroke: am5.color(0xdddddd),
    });

    itaSeries.mapPolygons.template.setAll({
      tooltipText: `[bold]{name}[/]\n{value}`,
      fill: am5.color('#E5E5E5'),
    });
    itaSeries.set('tooltip', tooltip);

    itaSeries.mapPolygons.template.states.create('hover', {
      fill: am5.color('#B8B8B8'),
    });

    // Animation
    !notAnimated &&
      itaSeries.mapPolygons.template.events.on('click', ev => {
        const dataItem = ev.target.dataItem;
        const id = dataItem.get('id').toLowerCase().split('-').pop();
        // const name = dataItem.dataContext.name;
        const zoomAnimation = itaSeries.zoomToDataItem(dataItem);

        Promise.all([
          zoomAnimation.waitForStop(),
          am5.net.load(
            'https://cdn.amcharts.com/lib/5/geodata/json/region/usa/congressional2022/' +
              id +
              'Low.json',
            chart
          ),
        ]);
      });

    var pointSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        polygonIdField: 'aMCId',
      })
    );

    pointSeries.data.setAll(dataRegion);

    pointSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 0.5,
        sprite: am5.Circle.new(root, {
          radius: 14,
          fill: am5.color(0xffffff),
        }),
      });
    });

    pointSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          text: '{value}',
          fontSize: 10,
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          textAlign: 'center',
          populateText: true,
        }),
      });
    });

    const circleTemplate = am5.Template.new(root);
    pointSeries.set('heatRules', [
      {
        target: circleTemplate,
        dataField: 'value',
        min: 10,
        max: 30,
        key: 'radius',
      },
    ]);

    return () => {
      chart.dispose();
    };
  }, [chartID, dataRegion]);

  return (
    <div style={{ width: '100%', height: '460px' }} id={chartID}>
      {error && (
        <MessageContainer>
          <Typography variant="h3Bold">
            {`Si è verificato un errore. Impossibile visualizzare il grafico.`}
          </Typography>
        </MessageContainer>
      )}
    </div>
  );
};

const configShape = {
  label: PropTypes.string,
  color: PropTypes.string,
};

MapChart.propTypes = {
  chartID: PropTypes.string,
  dataRegion: PropTypes.array,
  config: PropTypes.shape(configShape),
  notAnimated: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  error: PropTypes.bool,
};
