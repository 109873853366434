import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { InfoBox } from 'components';
import {
  CollaborationsErrors,
  CollaborationsErrorsColorMap,
  CollaborationsErrorsMessageMap,
} from 'constants/permissions';

const ErrorForm = ({ checkInvitabilityResponse }) => {
  const ignoreInfoMessage = [
    CollaborationsErrors.CollaboratorAlreadyHaveEntityButWhereNotSharedByYou,
  ];
  return (
    <div style={{ marginTop: 2 }}>
      <Grid item xs={12} mt={2} mb={2}>
        {!ignoreInfoMessage.includes(checkInvitabilityResponse) && (
          <InfoBox
            color={CollaborationsErrorsColorMap[checkInvitabilityResponse]}
            text={CollaborationsErrorsMessageMap[checkInvitabilityResponse]}
          />
        )}
      </Grid>
    </div>
  );
};
ErrorForm.propTypes = {
  checkInvitabilityResponse: PropTypes.number,
};
export default ErrorForm;
