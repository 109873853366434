import { Typography } from '@mui/material';
import { tour_13 } from 'constants/tour';

/* import { Check } from '@mui/icons-material';*/
export const steps = [
  {
    selector: `.${tour_13.step_01}`,
    content: () => {
      return (
        <Typography>
          Clicca qui per copiare i dati della scheda corrente su un altro
          elemento
        </Typography>
      );
    },
    /* style: {
      backgroundColor: '#bada55',
    }, */
  },
];
