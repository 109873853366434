import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { BasicDialog } from 'components';
import { InspectionRouteActions } from 'constants/inspections';
import { tour_03, tour_06 } from 'constants/tour';
import { useGlobalStore } from 'stores';

import {
  BoxPanel,
  StyledTab,
  StyledTabs,
  TabPanelContainer,
} from './TabCreateInspection.style';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <TabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <BoxPanel
          sx={{ width: '100%', backgroundColor: '#FFFFFF', padding: '25px' }}
        >
          {children}
        </BoxPanel>
      )}
    </TabPanelContainer>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};

const TabCreateInspection = ({
  tabs,
  variant,
  typevariant,
  initialTab,
  firstLevel,
  className,
}) => {
  const { state, pathname } = useLocation();
  const { formState, reset } = useFormContext() || {};

  //State
  // the first level is used to differenciate
  // the upper row of tabs (L0, L1, L2...)
  // from the bottom one (Contesto, Struttura, elementi accessori...)
  const [value, setValue] = useState(
    firstLevel ? initialTab : state?.inspectionLevelSubTab || 0
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [tabToChange, setTabToChange] = useState();

  useEffect(() => {
    if (firstLevel) {
      useGlobalStore.setState({ activeTabLevel: value });
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    if (
      Object.keys(formState?.touchedFields || {}).length > 0 &&
      !formState?.isSubmitSuccessful &&
      pathname.split('/').pop() !== InspectionRouteActions.VIEW
    ) {
      /* console.log('MODIFICHE NON SALVATE', formState?.touchedFields); */
      setTabToChange(newValue);
      return setOpenDialog(true);
    }
    setValue(newValue);
  };

  const handleCloseDialog = () => {
    setTabToChange();
    setOpenDialog(false);
  };

  const handleChangeTabConfirm = () => {
    reset();
    setValue(tabToChange);
    handleCloseDialog();
  };
  useEffect(() => {
    if (firstLevel) {
      setValue(state?.inspectionLevelTab || 0);
    } else {
      setValue(state?.inspectionLevelSubTab || 0);
    }
  }, [state]);

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Box
          sx={{ borderBottom: 0, borderColor: 'divider' }}
          className={`${tour_06.step_01} ${tour_06.step_03}`}
        >
          <StyledTabs
            typevariant={typevariant}
            variant={variant}
            onChange={handleChange}
            value={value}
            textColor="inherit"
            indicatorColor="secondary"
            className={`${tour_03.step_01} ${className}`}
          >
            {tabs.map((el, idx) => (
              <StyledTab
                typevariant={typevariant}
                key={idx}
                label={el.label}
                icon={el.icon}
                iconPosition={el.iconPosition}
                color="info"
                value={el.value}
                disabled={el.disabled}
              />
            ))}
          </StyledTabs>
        </Box>
        <div className={``}>
          {tabs.map((el, idx) => (
            <TabPanel key={idx} index={idx} value={value}>
              {/* {el.content} */}
              {React.cloneElement(el.content, { setValue })}
            </TabPanel>
          ))}
        </div>
      </Box>
      <BasicDialog
        title="Attenzione"
        open={openDialog}
        onClose={handleCloseDialog}
        actions={
          <>
            <Button onClick={handleCloseDialog}>Annulla</Button>
            <Button variant="contained" onClick={handleChangeTabConfirm}>
              Scarta modifiche
            </Button>
          </>
        }
      >
        Ci sono delle modifiche non salvate nel pannello corrente. Cambiando
        pannello andranno perse, confermi di voler scartare le modifiche fatte?
      </BasicDialog>
    </>
  );
};

export { TabCreateInspection };

TabCreateInspection.defaultProps = {
  firstLevel: false,
};

TabCreateInspection.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.node,
    })
  ),
  variant: PropTypes.string,
  typevariant: PropTypes.string,
  initialTab: PropTypes.number,
  firstLevel: PropTypes.bool,
  className: PropTypes.string,
};
