import PropTypes from 'prop-types';
import { MediaBoxContainer, MediaBoxSection } from './MediaBox.style';

export const MediaBox = ({ image, title, data }) => {
  return (
    <MediaBoxContainer>
      <h5>
        {title}

        {!data ? '' : `(${data.length})`}
      </h5>
      <MediaBoxSection>
        <img src={image} alt="" />
        <span>Visualizza</span>
      </MediaBoxSection>
    </MediaBoxContainer>
  );
};

MediaBox.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
};
