import { Typography } from '@mui/material';
import { tour_12 } from 'constants/tour';

/* import { Check } from '@mui/icons-material';*/
export const steps = [
  {
    selector: `.${tour_12.step_01}`,
    content: () => {
      return (
        <Typography>
          Definisci per ogni Campata/Mensola/Trave tampone il tipo e il numero
          di elementi associati. Li ritroverai nelle sezioni
          &quot;Impalcato&quot; degli Elementi accessori
        </Typography>
      );
    },
    /* style: {
      backgroundColor: '#bada55',
    }, */
  },
];
