import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    padding: '1cm 0.75cm 0.3cm',
  },
  status: {
    border: '1px solid grey',
    padding: '0.2cm',
    borderRadius: '16pt',
    textTransform: 'uppercase',
    width: '5cm',
    marginTop: '-20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusColor: {
    height: '8px',
    width: '8px',
    borderRadius: '100%',
    margin: '0 10px',
  },
  header: { marginBottom: '1cm' },
  title: {
    fontSize: '16pt',
    lineHeight: 1.2,
    fontFamily: 'Helvetica-Bold',
  },
  imageInfo: {
    marginTop: '10pt',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '0.25cm',
  },
  image: {
    width: '60%',
    height: '120pt',
    /* backgroundColor: '#F4F5F6', */
    borderRadius: '16pt',
  },
  placeholderImage: {
    width: '100%',
    height: '120pt',
    backgroundColor: '#F4F5F6',
    borderRadius: '16pt',
  },
  info: { width: '40%', marginLeft: '16pt' },
  paragraph: { fontSize: '8pt', lineHeight: 1.2 },
  infoLabel: {
    fontSize: '8pt',
    lineHeight: 1.8,
    fontFamily: 'Helvetica-Bold',
  },
  infoData: {
    fontSize: '8pt',
    lineHeight: 1.8,
    color: 'grey',
    fontFamily: 'Helvetica',
  },
  container: {
    borderRadius: '18pt',
    border: '1px solid grey',
    marginBottom: '0.50cm',
  },
  titleH2: {
    padding: '0.45cm 0.45cm 0cm',
    fontSize: '12pt',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '0.35cm',
  },
  flexContainer: {
    padding: '0cm 0.45cm',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flex: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoTag: {
    padding: '0.1cm',
    backgroundColor: '#DBDCDD',
    borderRadius: '16pt',
    width: '50pt',
    marginLeft: '3pt',
    marginTop: '-8pt',
  },
  tagText: {
    textAlign: 'center',
    fontSize: '6pt',
    color: 'white',
    textTransform: 'uppercase',
  },
  ndText: {
    textAlign: 'center',
    fontSize: '8pt',
    color: 'white',
    textTransform: 'lowercase',
  },
  icTag: {
    padding: '0.1cm',
    backgroundColor: '#3260CE',
    borderRadius: '16pt',
    width: '20pt',
    marginLeft: '3pt',
    marginTop: '-8pt',
  },
  icTagGrey: {
    padding: '0.1cm',
    backgroundColor: '#DBDCDD',
    borderRadius: '16pt',
    width: '20pt',
    marginLeft: '3pt',
    marginTop: '-8pt',
    textTransform: 'lowercase',
  },
  numberTag: {
    padding: '0.1cm',
    backgroundColor: '#3260CE',
    borderRadius: '16pt',
    width: '16pt',
    marginLeft: '3pt',
    marginTop: '-8pt',
  },
  numberTagGrey: {
    padding: '0.1cm',
    backgroundColor: '#DBDCDD',
    borderRadius: '16pt',
    width: '16pt',
    marginLeft: '3pt',
    marginTop: '-8pt',
  },
  infoSection: {
    padding: '0cm 0.45cm',
    marginTop: '8pt',
  },
  divider: {
    width: '100%',
    height: '1pt',
    backgroundColor: 'grey',
    marginTop: '0.3cm',
  },
  rilevantAspects: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 6,
  },
  iconElementContainer: {
    width: 14,
    height: 14,
    backgroundColor: '#DBDCDD',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 6,
  },
  infoAspects: {
    fontSize: '8pt',
    fontFamily: 'Helvetica-Bold',
  },
});
