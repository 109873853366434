export const mappingL2 = (key, cda) => {
  const objectL2 = {
    'sef-p': [
      {
        label: 'Limitazione di carico',
        value:
          cda?.structuralFoundationalCda?.danger
            ?.limiteCaricoCarreggiataSingolaCorsia,
      },
      {
        label: 'TGM - Commerciali (singola corsia)',
        value:
          cda?.structuralFoundationalCda?.danger
            ?.valoreApprossimativoCommercialiCorsiaTGM,
      },
    ],
    'sef-v-section2-box1': [
      {
        label: 'Classe di progettazione',
        value:
          cda?.structuralFoundationalCda?.vulnerability?.section2
            ?.normaProgetto,
      },
    ],
    'sef-v-section1-box1': [
      {
        label: 'Campate soggette a collasso',
        value:
          cda?.structuralFoundationalCda?.vulnerability?.section1
            ?.campateSoggetteACollasso,
      },
    ],
    'sef-v-section1-box2': [
      {
        label: 'Tipologia strutturale',
        value:
          cda?.structuralFoundationalCda?.vulnerability?.section1?.section
            ?.tipologiaStrutturale,
      },
      {
        label: 'Materiale',
        value:
          cda?.structuralFoundationalCda?.vulnerability?.section1?.section
            ?.tipologiaImpalcato,
      },
      {
        label: 'Luce max campate',
        value:
          cda?.structuralFoundationalCda?.vulnerability?.section1?.section
            ?.luceMassimaCampate,
        extraText: cda?.structuralFoundationalCda?.vulnerability?.section1
          ?.section?.isClassApproximate
          ? 'Attenzione, la classe calcolata per questi parametri è approssimativa. La normativa non definisce univocamente una CdA per la combinazione di parametri sopra indicata.'
          : '',
      },
    ],
    'sef-v-section2-box2': [
      {
        label: 'Aerosol marini e sali antigelo',
        value:
          cda?.structuralFoundationalCda?.vulnerability?.section2?.section
            ?.aerosolMarini,
      },
      {
        label: 'Rapidità evol.ne degrado',
        value:
          cda?.structuralFoundationalCda?.vulnerability?.section2?.section
            ?.degradationEvolutionRapidity,
      },
    ],
    'sef-v-section2-box3': [
      {
        label: 'Livello difettosità attuale',
        value:
          cda?.structuralFoundationalCda?.vulnerability?.section2?.section
            ?.section?.defectiveness,
      },
    ],
    'sef-e': [
      {
        label: 'Rilevanza ente scavalcato',
        value:
          cda?.structuralFoundationalCda?.exposure
            ?.affollamentoEfunzioniEnteScavalcato,
      },
    ],
    'sef-e-section-box1': [
      {
        label: 'Alternative stradali',
        value:
          cda?.structuralFoundationalCda?.exposure?.section
            ?.alternativeStradali,
      },
    ],
    'sef-e-section-box2': [
      {
        label: 'Frequente passaggio persone',
        value:
          cda?.structuralFoundationalCda?.exposure?.section?.section
            ?.frequentePassaggioPersone,
      },
    ],
    'sef-e-section-box3': [
      {
        label: 'TGM(carreggiata)',
        value:
          cda?.structuralFoundationalCda?.exposure?.section?.section?.section
            ?.valoreApprossimativoTGM,
      },
      {
        label: 'Luce media campate',
        value:
          cda?.structuralFoundationalCda?.exposure?.section?.section?.section
            ?.luceMediaCampate,
      },
    ],
    's-p-section': [
      {
        label: 'Accelerazione di picco al suolo',
        value: cda?.seismicCda?.danger?.section?.ag,
      },
      {
        label: 'Categoria topografica',
        value: cda?.seismicCda?.danger?.section?.ti,
      },
    ],
    's-p': [
      {
        label: 'Categoria sottosuolo',
        value: cda?.seismicCda?.danger?.categoriaSottosuolo,
      },
    ],
    's-v-section1-box1': [
      {
        label: 'Criteri di progettazione',
        value: cda?.seismicCda?.vulnerability?.section1?.criteriProgettazione,
      },
    ],
    's-v-section1-box2': [
      {
        label: 'Elementi vulnerabilità sismica',
        value:
          cda?.seismicCda?.vulnerability?.section1?.section
            ?.elementiVulnerabilitaSismica,
      },
    ],
    's-v-section1-box3': [
      {
        label: 'Schema strutturale',
        value:
          cda?.seismicCda?.vulnerability?.section1?.section?.section
            ?.tipologiaSchema,
      },
      {
        label: 'Materiale',
        value:
          cda?.seismicCda?.vulnerability?.section1?.section?.section
            ?.materialImpalcato,
      },
      {
        label: 'N° campate',
        value:
          cda?.seismicCda?.vulnerability?.section1?.section?.section?.nCampate,
      },
      {
        label: 'Luce max. campate',
        value:
          cda?.seismicCda?.vulnerability?.section1?.section?.section
            ?.luceMaxCampate,
        extraText: cda?.seismicCda?.vulnerability?.section1?.section
          ?.isClassApproximate
          ? 'Attenzione, la classe calcolata per questi parametri è approssimativa. La normativa non definisce univocamente una CdA per la combinazione di parametri sopra indicata.'
          : '',
      },
    ],
    's-v-section2-box1': [
      {
        label: 'Livello difettosità attuale',
        value: cda?.seismicCda?.vulnerability?.section2?.difettositaSismica,
      },
    ],
    's-e-section-box1': [
      {
        label: 'Rilevanza ente scavalcato',
        value:
          cda?.seismicCda?.exposure?.section
            ?.affollamentoEfunzioniEnteScavalcato,
      },
    ],
    's-e-section-box2': [
      {
        label: 'Alternative stradali',
        value: cda?.seismicCda?.exposure?.section?.section?.alternativeStradali,
      },
    ],
    's-e-section-box3': [
      {
        label: 'Frequente passaggio persone',
        value:
          cda?.seismicCda?.exposure?.section?.section?.section
            ?.frequentePassaggioPersone,
      },
    ],
    's-e-section-box4': [
      {
        label: 'TGM (carreggiata)',
        value:
          cda?.seismicCda?.exposure?.section?.section?.section?.section
            ?.valoreApprossimativoTGM,
      },
      {
        label: 'Luce media campate',
        value:
          cda?.seismicCda?.exposure?.section?.section?.section?.section
            ?.luceMediaCampate,
      },
    ],
    's-e': [
      {
        label: "Strategicità dell'opera",
        value: cda?.seismicCda?.exposure?.strategicity,
      },
    ],
    'rf-s-section-box1': [
      {
        label: 'Affidabilità valutazione',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.section?.affidabilitaComplessivaRischioFrane,
      },
    ],
    'rf-s-section-box2': [
      {
        label: 'Stato di attività (Pa)',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.section?.section?.pa,
      },
      {
        label: 'Velocità (Pv)',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.section?.section?.pv,
      },
      {
        label: 'Magnitudo (Pm)',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.section?.section?.pm,
      },
    ],

    'rf-s-section-box3': [
      {
        label: 'Grado di criticità (Pc)',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.section?.section?.pc,
      },
      {
        label: 'Velocità (Pv)',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.section?.section?.pv,
      },
      {
        label: 'Magnitudo (Pm)',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.section?.section?.pm,
      },
    ],
    'rf-s-section-box4': [
      {
        label: 'Parametro Pa/Pc',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.section?.section?.missingPaPc,
      },
      {
        label: 'Velocità (Pv)',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.section?.section?.pv,
      },
      {
        label: 'Magnitudo (Pm)',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.section?.section?.pm,
      },
    ],
    'rf-s': [
      {
        label: 'Misure di mitigazione',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.susceptibility
            ?.misureMitigazione,
      },
    ],
    'rf-v-section-box1': [
      {
        label: 'Imposta fondazioni',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.vulnerability
            ?.section?.impostaFondazioni,
      },
    ],
    'rf-v-section-box2': [
      {
        label: 'Schema strutturale',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.vulnerability
            ?.section?.section?.schemaStatico,
      },
      {
        label: 'Materiale',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.vulnerability
            ?.section?.section?.materialImpalcato,
      },
      {
        label: 'N° campate',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.vulnerability
            ?.section?.section?.campata,
      },
      {
        label: 'Luce max. campate',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.vulnerability
            ?.section?.section?.luceMaxCampate,
      },
    ],
    'rf-v': [
      {
        label: 'Estensione interferenza',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.vulnerability
            ?.estensioneInterferenza,
      },
    ],

    'rf-e-section-box1': [
      {
        label: 'Rilevanza ente scavalcato',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.exposure?.section
            ?.affollamentoEfunzioniEnteScavalcato,
      },
    ],
    'rf-e-section-box2': [
      {
        label: 'Alternative stradali',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.exposure?.section
            ?.section?.alternativeStradali,
      },
    ],
    'rf-e-section-box3': [
      {
        label: 'Frequente passaggio persone',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.exposure?.section
            ?.section?.section?.frequentePassaggioPersone,
      },
    ],
    'rf-e-section-box4': [
      {
        label: 'TGM (carreggiata)',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.exposure?.section
            ?.section?.section?.section?.valoreApprossimativoTGM,
      },
      {
        label: 'Luce media campate',
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.exposure?.section
            ?.section?.section?.section?.luceMediaCampate,
      },
    ],
    'rf-e': [
      {
        label: "Strategicità dell'opera",
        value:
          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.exposure
            ?.strategicity,
      },
    ],
    'ri-s-p-section1': [
      {
        label: 'Franco idraulico stimato [m]',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.danger?.section1?.francoIdraulicoStimato,
      },
    ],
    'ri-s-p-section1-fs': [
      {
        label: 'Franco idraulico stimato [m]',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.danger?.section1?.francoIdraulicoSuperiore,
      },
    ],
    'ri-s-p-section1-fp2': [
      {
        label: 'Franco idraulico Fp2 [m]',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.danger?.section1?.francoIdraulicoFp2,
      },
    ],
    'ri-s-p-section2': [
      {
        label: 'Franco idraulico FP3 [m]',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.danger?.section2?.francoIdraulicoFp3,
      },
    ],
    'ri-s-v': [
      {
        label: 'Superficie bacino idrografico',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.vulnerability?.bacinoIdrograficoPresuntoRischioIdraulico,
      },
      {
        label: 'Evidente e accentuata deposizione di sedimenti',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.vulnerability?.deposizioneSedimenti,
      },
      {
        label: 'Trasporto di materiale vegetale di notevole dimensione',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.vulnerability?.materialeVegetaleRischioIdraulico,
      },
    ],

    'ri-s-e-section-box1': [
      {
        label: 'Rilevanza ente scavalcato',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.exposure?.section?.affollamentoEfunzioniEnteScavalcato,
      },
    ],
    'ri-s-e-section-box2': [
      {
        label: 'Alternative stradali',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.exposure?.section?.section?.alternativeStradali,
      },
    ],
    'ri-s-e-section-box3': [
      {
        label: 'Frequente passaggio persone',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.exposure?.section?.section?.section?.frequentePassaggioPersone,
      },
    ],
    'ri-s-e-section-box4': [
      {
        label: 'TGM (carreggiata)',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.exposure?.section?.section?.section?.section
            ?.valoreApprossimativoTGM,
      },
      {
        label: 'Luce media campate',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.exposure?.section?.section?.section?.section?.luceMediaCampate,
      },
    ],
    'ri-s-e': [
      {
        label: "Strategicità dell'opera",
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
            ?.exposure?.strategicity,
      },
    ],
    'ri-eg-p': [
      {
        label: "Fattore di restringimento dell'alveo inciso Ca [%]",
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.danger?.ca,
      },
      {
        label: 'Fattore di restringimento delle aree golenali Cg [%]',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.danger?.cg,
      },
    ],
    'ri-eg-v': [
      {
        label: 'Evidenze sulla profondità delle fondazioni',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.vulnerability
            ?.evidenzeProfonditaDelleFondazioni,
      },
      {
        label: "Abbassamento generalizzato dell'alveo",
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.vulnerability
            ?.abbassamentoGeneralizzatoAlveo,
      },
      {
        label: 'Alveo avente sensibile curvatura in corrispondenza del ponte',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.vulnerability
            ?.curvaturaAlveoRischioIdraulico,
      },
    ],

    'ri-eg-v-abbassamentoAccentuato': [
      {
        label: 'Evidenze sulla profondità delle fondazioni',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.vulnerability
            ?.evidenzeProfonditaDelleFondazioni,
      },
      {
        label: "Abbassamento generalizzato dell'alveo",
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.vulnerability
            ?.abbassamentoGeneralizzatoAlveo,
      },
      {
        label: 'Abbassamento accentuato alle fondazioni',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.vulnerability
            ?.abbassamentoAccentuatoAlleFondazioni,
      },
      {
        label: 'Alveo avente sensibile curvatura in corrispondenza del ponte',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.vulnerability
            ?.curvaturaAlveoRischioIdraulico,
      },
    ],

    'ri-eg-e-section-box1': [
      {
        label: 'Rilevanza ente scavalcato',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.exposure?.section
            ?.affollamentoEfunzioniEnteScavalcato,
      },
    ],
    'ri-eg-e-section-box2': [
      {
        label: 'Alternative stradali',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.exposure?.section?.section
            ?.alternativeStradali,
      },
    ],
    'ri-eg-e-section-box3': [
      {
        label: 'Frequente passaggio persone',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.exposure?.section?.section?.section
            ?.frequentePassaggioPersone,
      },
    ],
    'ri-eg-e-section-box4': [
      {
        label: 'TGM (carreggiata)',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.exposure?.section?.section?.section
            ?.section?.valoreApprossimativoTGM,
      },
      {
        label: 'Luce media campate',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.exposure?.section?.section?.section
            ?.section?.luceMediaCampate,
      },
    ],
    'ri-eg-e': [
      {
        label: "Strategicità dell'opera",
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda?.exposure?.strategicity,
      },
    ],

    'ri-el-p': [
      {
        label: 'IEL',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.danger?.fattoreIEL,
      },
    ],
    'ri-el-v': [
      {
        label: 'Evidenze sulla profondità delle fondazioni',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability
            ?.evidenzeProfonditaDelleFondazioni,
      },
      {
        label: "Abbassamento generalizzato dell'alveo",
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability
            ?.abbassamentoGeneralizzatoAlveo,
      },
      {
        label: 'Accumuli di detriti o materiale flottante a monte della pila',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability?.accumuliDetritiAMontePila,
      },
      {
        label: "Tendenza dell'alveo a divagazione planimetrica",
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability
            ?.divagazioneAlveoRischioIdraulico,
      },
      {
        label: 'Protezione al piede delle pile e delle spalle del ponte',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability?.protezioneAlPiedePileSpalle,
      },
      {
        label: 'Briglia di protezione immediatamente a valle del ponte',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability
            ?.brigliaProtezioneValleDelPonte,
      },
    ],

    'ri-el-v-abbassamentoAccentuato': [
      {
        label: 'Evidenze sulla profondità delle fondazioni',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability
            ?.evidenzeProfonditaDelleFondazioni,
      },
      {
        label: "Abbassamento generalizzato dell'alveo",
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability
            ?.abbassamentoGeneralizzatoAlveo,
      },
      {
        label: 'Abbassamento accentuato alle fondazioni',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability
            ?.abbassamentoAccentuatoAlleFondazioni,
      },
      {
        label: 'Accumuli di detriti o materiale flottante a monte della pila',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability?.accumuliDetritiAMontePila,
      },
      {
        label: "Tendenza dell'alveo a divagazione planimetrica",
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability
            ?.divagazioneAlveoRischioIdraulico,
      },
      {
        label: 'Protezione al piede delle pile e delle spalle del ponte',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability?.protezioneAlPiedePileSpalle,
      },
      {
        label: 'Briglia di protezione immediatamente a valle del ponte',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.vulnerability
            ?.brigliaProtezioneValleDelPonte,
      },
    ],

    'ri-el-e-section-box1': [
      {
        label: 'Rilevanza ente scavalcato',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.exposure?.section
            ?.affollamentoEfunzioniEnteScavalcato,
      },
    ],
    'ri-el-e-section-box2': [
      {
        label: 'Alternative stradali',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.exposure?.section?.section
            ?.alternativeStradali,
      },
    ],
    'ri-el-e-section-box3': [
      {
        label: 'Frequente passaggio persone',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.exposure?.section?.section?.section
            ?.frequentePassaggioPersone,
      },
    ],
    'ri-el-e-section-box4': [
      {
        label: 'TGM (carreggiata)',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.exposure?.section?.section?.section?.section
            ?.valoreApprossimativoTGM,
      },
      {
        label: 'Luce media campate',
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.exposure?.section?.section?.section?.section
            ?.luceMediaCampate,
      },
    ],
    'ri-el-e': [
      {
        label: "Strategicità dell'opera",
        value:
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda?.exposure?.strategicity,
      },
    ],
  };

  return objectL2[key];
};
