import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, GridWrap, InfoWrapper, Table } from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import { tipologiaElementoExtraOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import ElementiExtraForm from './ElementiExtraForm';
const ElementiExtraSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const mensoleTamponiCampateImpalcatoTableElementiExtraTableWatch = watch(
    `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].elementiExtraTable`
  );
  // table setup
  const dataConfigElementiExtra = {
    columns: {
      codice: { label: 'Codice' },
      nomeElementoExtra: { label: 'Nome Elemento' },
      tipologiaElementoExtra: {
        label: 'Tipologia',
        format: tipologiaElementoExtraOptions,
      },
      altezzaElementoExtra: {
        label: 'H [cm]',
      },
      larghezzaElementoExtra: {
        label: 'L [cm]',
      },
      profonditaElementoExtra: {
        label: 'P [cm]',
      },
      superficieElementoExtra: {
        label: 'Sup. [m²]',
        formatValue: val => `${(+val)?.toFixed(2)}`,
      },
    },
  };

  // piedritti setup
  const [selectedElementiExtra, setSelectedElementiExtra] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsElementiExtra = item => {
    setValue('l0t2a5_elementiExtraTableValue', [item]);
    setValue('l0t2a5_nomeElementoExtraTableValue', item.nomeElementoExtra);
    setValue(
      'l0t2a5_tipologiaElementoExtraTableValue',
      item.tipologiaElementoExtra
    );
    setValue(
      'l0t2a5_altezzaElementoExtraTableValue',
      item.altezzaElementoExtra
    );
    setValue(
      'l0t2a5_larghezzaElementoExtraTableValue',
      item.larghezzaElementoExtra
    );
    setValue(
      'l0t2a5_profonditaElementoExtraTableValue',
      item.profonditaElementoExtra
    );
    setValue(
      'l0t2a5_superficieElementoExtraTableValue',
      item.superficieElementoExtra
    );
  };

  const handleSelectAllClickElementiExtra = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].elementiExtraTable`
      );
      setSelectedElementiExtra(selectedValue);
      setValue('l0t2a5_elementiExtraTableValue', selectedValue);
    } else {
      setSelectedElementiExtra([]);
      setValue('l0t2a5_elementiExtraTableValue', []);
    }
  };

  return (
    <>
      <GridWrap container spacing={2} style={{ marginTop: 16 }}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.impalcatoElementiExtra.drawerTitle}
          drawerText={INFO.impalcatoElementiExtra.drawerText}
        >
          <Divider>ELEMENTI EXTRA</Divider>
        </InfoWrapper>
        {mensoleTamponiCampateImpalcatoTableElementiExtraTableWatch?.length ===
          0 && (
          <GridWrap item xs={12} paddingTop={'0px !important'}>
            <Typography>
              Nessun elemento extra presente in questo elemento
            </Typography>
          </GridWrap>
        )}
      </GridWrap>
      {mensoleTamponiCampateImpalcatoTableElementiExtraTableWatch?.length >
        0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <ElementiExtraForm
              elementiExtraFields={
                mensoleTamponiCampateImpalcatoTableElementiExtraTableWatch || []
              }
              selected={selectedElementiExtra}
              setSelected={setSelectedElementiExtra}
              elementiExtraTable={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].elementiExtraTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Elementi Extra'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].elementiExtraTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Struttura]}
          />
          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={
                  mensoleTamponiCampateImpalcatoTableElementiExtraTableWatch ||
                  []
                }
                config={dataConfigElementiExtra}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsElementiExtra(item);
                            setSelectedElementiExtra([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        mensoleTamponiCampateImpalcatoTableElementiExtraTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedElementiExtra}
                onSelectAllClick={handleSelectAllClickElementiExtra}
                setSelected={setSelectedElementiExtra}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
ElementiExtraSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default ElementiExtraSection;
