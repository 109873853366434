import { GoogleMap, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import blackBridge from 'assets/icons/bridges/black-bridge.png';
import { colors } from 'constants/colors';
import { mapCustomStyle } from './mapCustomStyles';

const Map = ({ lat, lng, center, setCenter, markersDefault, hasMarkers }) => {
  const navigate = useNavigate();
  const [map, setMap] = useState(null);

  const onLoad = useCallback(map => setMap(map), []);

  const defaultCenter = { lat: 41.87194, lng: 12.56738 };

  const getToDetail = url => {
    navigate(url);
  };

  useEffect(() => {
    if (map && !hasMarkers) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend({
        lat: lat !== 0 ? lat : defaultCenter.lat,
        lng: lng !== 0 ? lng : defaultCenter.lng,
      });

      map.fitBounds(bounds);
      map.setZoom(12);
      setCenter({ lat: +lat, lng: +lng });
    }
  }, [map, lat, lng, hasMarkers]);

  useEffect(() => {
    if (map && hasMarkers && markersDefault) {
      const bounds = new window.google.maps.LatLngBounds();
      markersDefault?.map(({ position }) => bounds.extend(position));
      map.fitBounds(bounds);
      map.setCenter(bounds);
    }
  }, [map, markersDefault, hasMarkers]);

  // const handleOnLoad = map => {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   markersDefault.map(({ position }) => bounds.extend(position));
  //   map.fitBounds(bounds);
  //   map.setCenter(bounds);
  //   // setCenter({ lat: lat, lng: lng });
  //   // map.setZoom(12);
  // };

  return (
    <>
      <GoogleMap
        options={{
          styles: mapCustomStyle,
        }}
        onLoad={onLoad}
        // onCenterChanged={() => setCenter(mapRef.getCenter().toJSON())}
        onClick={e => setCenter(e.latLng.toJSON())}
        mapContainerStyle={{
          margin: '10px',
          width: '98%',
          height: '50vh',
          boxShadow: `0px 0px 0px 8px ${colors.WHITE}`,
          borderRadius: '8px',
        }}
        zoom={4}
        mapTypeId="roadmap"
        clickableIcons={false}
      >
        {!hasMarkers && (
          <Marker
            position={
              center?.lat !== 0 && center?.lng !== 0 ? center : defaultCenter
            }
            icon={blackBridge}
          />
        )}
        {markersDefault?.map(({ position, icon, url }, index) => {
          return (
            <Marker
              key={index}
              position={position}
              icon={icon}
              onClick={() => getToDetail(url)}
              url={url}
            ></Marker>
          );
        })}
      </GoogleMap>
    </>
  );
};

Map.defaultValue = {
  markersDefault: [],
  hasMarkers: false,
};

Map.propTypes = {
  markersDefault: PropTypes.array,
  lat: PropTypes.number,
  lng: PropTypes.number,
  center: PropTypes.object,
  setCenter: PropTypes.func,
  hasMarkers: PropTypes.bool,
};

export { Map };
