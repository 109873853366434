import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'use-http';
import * as yup from 'yup';
import { HeaderPage, MediaUpload, RHFSelect, RHFTextField } from 'components';
import { apiContactUsInternal } from 'constants/api';
import { INFO_ASSISTANCE_PATHS } from 'constants/paths';
import { FormProvider } from 'providers';
import { useGlobalSelector } from 'stores/global';
import {
  BtnContainer,
  FlexHeader,
  FlexIconTitle,
  WhiteContainer,
} from './InfoAssistance.styles';

const BoxContainer = ({ onClick, title, icon, subtitle }) => {
  return (
    <WhiteContainer>
      <FlexHeader>
        <FlexIconTitle>
          <img src={icon} width={22} />
          <Typography variant="h2">{title}</Typography>
        </FlexIconTitle>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={onClick}
        >
          Visualizza
        </Button>
      </FlexHeader>
      <Typography variant="body">{subtitle}</Typography>
    </WhiteContainer>
  );
};
BoxContainer.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.string,
  subtitle: PropTypes.string,
};

const InfoAssistance = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { post, response } = useFetch();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    name: '',
    surname: '',
    email: '',
    subject: '',
    message: '',
    attachments: [],
  };

  const schema = yup.object({
    name: yup.string().required('Nome obbligatorio'),
    surname: yup.string().required('Cognome obbligatorio'),
    email: yup
      .string()
      .email('Inserire una email valida')
      .required('Email obbligatoria'),
    subject: yup.string().required('Oggetto segnalazione obbligatorio'),
    message: yup.string().required('Messaggio obbligatorio'),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async data => {
    const updatedData = {
      name: data?.name,
      surname: data?.surname,
      email: data?.email,
      subject: data?.subject,
      message: data?.message,
      attachments: data?.file?.map(f => f.s3Path) || [],
    };
    try {
      showLoader();
      await post(`${apiContactUsInternal.ROOT}`, updatedData);
      if (response.ok) {
        reset(defaultValues);
        console.log(updatedData);
        return enqueueSnackbar('Messaggio inviato correttamente', {
          variant: 'success',
        });
      }
    } catch (err) {
      console.error('Error in handle data: ', err);
      return enqueueSnackbar(`Si è verificato un errore`, {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <HeaderPage title="Info &#38; Assistenza" />
      <Grid container spacing={1} marginTop={-7}>
        <Grid item xs={4}>
          <BoxContainer
            onClick={() => navigate(INFO_ASSISTANCE_PATHS.FAQ)}
            icon={'/icons/faq-icon.svg'}
            title={'FAQ'}
            subtitle={'Consulta le nostre FAQ'}
          />
        </Grid>

        <Grid item xs={4}>
          <BoxContainer
            onClick={() => navigate(INFO_ASSISTANCE_PATHS.GUIDES)}
            icon={'/icons/icon-guide.svg'}
            title={'Guide e Approfondimenti'}
            subtitle={'Consulta le nostre guide'}
          />
        </Grid>
        <Grid item xs={4}>
          <BoxContainer
            onClick={() => navigate(INFO_ASSISTANCE_PATHS.VIDEOS)}
            icon={'/icons/video.svg'}
            title={'Video Tutorial'}
            subtitle={'Consulta i nostri video tutorial'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} marginTop={2}>
        <Grid item xs={12}>
          <WhiteContainer>
            <FlexHeader>
              <FlexIconTitle>
                <img src="/icons/icon-contact.svg" width={22} />
                <Typography variant="h2">Contattaci</Typography>
              </FlexIconTitle>
            </FlexHeader>
            <Typography variant="body">
              Compila il form di contatto per ricevere assistenza
            </Typography>

            <FormProvider
              methods={methods}
              onSubmit={handleSubmit(onSubmit, console.log)}
            >
              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={6}>
                  <RHFTextField
                    name="name"
                    label="Nome"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RHFTextField
                    name="surname"
                    label="Cognome"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RHFTextField
                    name="email"
                    label="Email"
                    inputProps={{ maxLength: 320 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RHFSelect
                    name="subject"
                    label={'Oggetto segnalazione'}
                    defaultValue={''}
                    selectOptions={[
                      {
                        label: 'Problema Tecnico',
                        value: 'Problema Tecnico',
                      },
                      {
                        label: 'Problema Amministrativo',
                        value: 'Problema Amministrativo',
                      },
                      {
                        label: 'Dubbio Utilizzo Piattaforma',
                        value: 'Dubbio Utilizzo Piattaforma',
                      },
                      {
                        label: 'Generico',
                        value: 'Generico',
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField
                    multiline
                    name="message"
                    label="Messaggio"
                    inputProps={{ maxLength: 20000 }}
                  />
                </Grid>
                <Grid item md={3}>
                  <MediaUpload
                    isInfoAssistance={true}
                    title={`File`}
                    btnTitle="Allega file"
                    dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
                    formName="file"
                  />
                </Grid>
              </Grid>
              <BtnContainer
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 5,
                }}
              >
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => reset(defaultValues)}
                >
                  Pulisci form
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{ width: 90 }}
                >
                  Invia
                </Button>
              </BtnContainer>
            </FormProvider>
          </WhiteContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default InfoAssistance;
