import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaAntennaOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

const AntenneForm = ({
  antenneFields,
  selected,
  setSelected,
  antenneTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      antenneTableValue: [],
      tipologiaAntennaTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('antenneTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetAntenne();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeAntenne = () => {
    const tipologiaAntennaTableValue = getValues('tipologiaAntennaTableValue');

    const ids = selected.map(el => el.codice);

    const antenne = [...antenneFields];

    antenne.map((x, index) => {
      if (ids.includes(x?.codice)) {
        antenne[index] = {
          ...x,
          tipologiaAntenna: tipologiaAntennaTableValue,
          ...(tipologiaAntennaTableValue !== x.tipologiaAntenna
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'antenna',
                  tipologiaAntennaTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(antenneTable, antenne);
    onResetAntenne();
  };

  const onConfirmChangeAntenne = () => {
    setOpenConfirmationDialog(false);
    changeAntenne();
  };

  const onSubmitAntenne = () => {
    const tipologiaAntennaTableValue = getValues('tipologiaAntennaTableValue');
    const ids = selected.map(el => el.codice);

    const antenne = [...antenneFields];

    const hasChangedTipologia = antenne.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          tipologiaAntennaTableValue !== el.tipologiaAntenna &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaAntenna)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeAntenne();
    }
  };

  const onResetAntenne = () => {
    setValue('antenneTableValue', []);
    setValue('tipologiaAntennaTableValue', '');
    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={3} infoMessage={INFO.antenneSelezionate.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-antenne"
            name={'antenneTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={antenneFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Antenne selezionate"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipologiaAntennaTableValue"
            label="Tipologia Antenna"
            defaultValue={''}
            onChange={e => {
              setValue('tipologiaAntennaTableValue', e.target.value);
            }}
            selectOptions={tipologiaAntennaOptions}
          />
        </GridWrap>
        <GridWrap item xs={6}>
          <FormSubmitButtons
            onSubmit={onSubmitAntenne}
            onReset={onResetAntenne}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeAntenne}
      />
    </>
  );
};
AntenneForm.propTypes = {
  antenneFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaAntenna: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  antenneTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default AntenneForm;
