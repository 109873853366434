import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';

import { Table } from 'components';
import { DrawerMedia } from 'components/DrawerMedia/DrawerMedia';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';

import {
  sottoserviziOptions,
  tipologiaSottoserviziOptions,
  utenzaServiziOptions,
} from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap } from './Accordion3Sottoservizi.styles';
import { SottoserviziForm } from './SottoserviziForm/SottoserviziForm';

const Accordion3Sottoservizi = () => {
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();
  //State
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const { watch, setValue } = useFormContext();

  const watchSottoServiziTable = watch('sottoserviziTable');

  const dataConfig = {
    columns: {
      sottoservizi: {
        label: 'Sottoservizi',
        format: sottoserviziOptions,
      },
      tipologiaSottoservizi: {
        label: 'Tipologia sottoservizi',
        format: tipologiaSottoserviziOptions,
      },
      tipologiaSottoserviziAltro: { label: 'Altro' },
      utenzaServizi: {
        label: 'Utenza servizi',
        format: utenzaServiziOptions,
      },
      concessionario: { label: 'Concessionario' },
    },
  };

  const editRows = item => {
    setValue('sottoserviziTableValue', item.sottoservizi);
    setValue('tipologiaSottoserviziTableValue', item.tipologiaSottoservizi);
    setValue(
      'tipologiaSottoserviziAltroTableValue',
      item.tipologiaSottoserviziAltro
    );
    setValue('utenzaServiziTableValue', item.utenzaServizi);
    setValue('concessionarioTableValue', item.concessionario);
  };

  return (
    <>
      <GridWrap container spacing={2} padding="20px" marginTop={-40}>
        {!inspectionDisabled && !isViewingInspection && (
          <SottoserviziForm
            selected={selected}
            sottoserviziFields={watchSottoServiziTable}
          />
        )}
        <GridWrap item xs={12}>
          <Table
            data={watchSottoServiziTable || []}
            config={dataConfig}
            rowActions={
              !inspectionDisabled && !isViewingInspection
                ? [
                    {
                      onClick: item => {
                        editRows(item);
                        setSelected([item]);
                      },
                      icon: <EditIcon />,
                    },
                  ]
                : []
            }
            mediaActions={[
              {
                onClick: item => {
                  setDrawerMediaIndex(
                    watchSottoServiziTable.findIndex(el => el.id === item.id)
                  );
                  setOpen(true);
                },
                icon: <MediaIcon />,
              },
            ]}
            selected={selected}
            setSelected={setSelected}
          />
        </GridWrap>

        <DrawerMedia
          open={open}
          setOpen={setOpen}
          title={'Sottoservizi'}
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          dataType="video/*,image/*"
          uploadType={UploadMedia.media}
          type={FieldType.images}
          formName={`sottoserviziTable[${drawerMediaIndex}].media`}
          mediaGroup={[MediaGroup.Servizi]}
        />
      </GridWrap>
    </>
  );
};

export { Accordion3Sottoservizi };
