import styled from '@emotion/styled';
import { colors } from '../../../constants/colors';

export const TopBar = styled('div')({
  backgroundColor: colors.WHITE,
  height: 93,
  width: '100%',
  padding: '30px 24px 20px',
  position: 'fixed',
  top: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 30,
  alignItems: 'center',
  zIndex: 11,
});
