import { Typography } from '@mui/material';
import { HeaderPage, Notification, TablePagination } from 'components';
import { useNotifications } from 'hooks';
import { formatData } from 'utils/utilities';
import {
  ButtonWrapper,
  HeaderWrapper,
  NotificationsContainer,
  StyledPaper,
} from './Notifications.styles';

const Notifications = () => {
  const startPage = 1;
  const startPageSize = 10;
  const {
    notifications,
    totalCount,
    page,
    pageSize,
    setPage,
    setPageSize,
    handleReadNotification,
    handleReadAllNotification,
  } = useNotifications({
    startPage,
    startPageSize,
  });

  const handlePageChange = v => {
    setPage(v);
  };

  return (
    <>
      <HeaderWrapper>
        <HeaderPage title="Notifiche" back={-1} />
        <ButtonWrapper
          variant="containedBlack"
          size="small"
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => handleReadAllNotification()}
        >
          Segna tutte come lette
        </ButtonWrapper>
      </HeaderWrapper>
      <div>
        <StyledPaper>
          {notifications?.length === 0 && (
            <Typography>Non ci sono notifiche</Typography>
          )}
          {notifications?.length > 0 && (
            <NotificationsContainer>
              {notifications.map(
                ({ id, text, createdAt, read, link, type }) => (
                  <Notification
                    id={id}
                    key={id}
                    handleReadNotification={handleReadNotification}
                    text={text}
                    /* text={text?.replace(`&#x27;`, `'`)} */
                    time={formatData(createdAt)}
                    read={read}
                    link={link}
                    type={type}
                  />
                )
              )}
            </NotificationsContainer>
          )}
        </StyledPaper>
        <TablePagination
          count={Math.ceil(totalCount / pageSize)}
          page={page}
          onChange={handlePageChange}
          total={totalCount}
          pageSize={pageSize}
          onRowsPerPageChange={event => setPageSize(event.target.value)}
        />
      </div>
    </>
  );
};

export default Notifications;
