import { useFormContext } from 'react-hook-form';
import {
  CDAStrutturale,
  difettosita_G,
  estensione_k1_val_1,
  estensione_k1_val_2,
  estensione_k1_val_3,
  intensita_k2_val_1,
  intensita_k2_val_2,
  intensita_k2_val_3,
} from 'constants/difetti';
import { YesNo } from 'constants/inspections';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

const useCDA = () => {
  const { getValues, setValue } = useFormContext();

  const calculateCDA = (condizioneCritica, elementoCritico, type, name) => {
    // CDA
    // Questa logica setta la Classe d'Attenzione dell'elemento strutturale
    if (
      isNullOrUndefinedOrEmptyString(condizioneCritica) ||
      isNullOrUndefinedOrEmptyString(elementoCritico)
    ) {
      setValue(`${name}.CDA${type}`, null);
      setValue(`${name}.CDA${type}computed`, false);
      return;
    }
    if (condizioneCritica && elementoCritico) {
      const Th_MB_B = 0.5;
      const filteredG4G5 = getValues(`${name}.schedaDifetti`).filter(
        el =>
          el.difettosita == difettosita_G.G5 ||
          el.difettosita == difettosita_G.G4
      );
      const filteredG3G2G1 = getValues(`${name}.schedaDifetti`).filter(
        el =>
          el.difettosita == difettosita_G.G3 ||
          el.difettosita == difettosita_G.G2 ||
          el.difettosita == difettosita_G.G1
      );
      const N_rel_321 =
        filteredG3G2G1.filter(
          el => !isNullOrUndefinedOrEmptyString(el.intensitaDifetto)
        ).length / filteredG3G2G1.length;
      if (
        condizioneCritica == YesNo.SI ||
        (elementoCritico == YesNo.SI &&
          filteredG4G5.some(
            el => !isNullOrUndefinedOrEmptyString(el.intensitaDifetto)
          ))
      ) {
        setValue(`${name}.CDA${type}`, CDAStrutturale.Alta);
        setValue(`${name}.CDA${type}computed`, true);
      } else if (
        filteredG4G5.some(
          el => el.ps && el.intensitaDifetto == intensita_k2_val_3
        )
      ) {
        setValue(`${name}.CDA${type}`, CDAStrutturale.MedioAlta);
        setValue(`${name}.CDA${type}computed`, true);
      } else if (
        (filteredG4G5.filter(
          el => el.intensitaDifetto == intensita_k2_val_3 && !el.ps
        ).length != 0 &&
          filteredG4G5
            .filter(el => el.intensitaDifetto == intensita_k2_val_3)
            .every(el => !el.ps)) ||
        getValues(`${name}.schedaDifetti`)
          .filter(el => el.difettosita == difettosita_G.G5)
          .some(
            el =>
              (el.intensitaDifetto == intensita_k2_val_2 ||
                el.intensitaDifetto == intensita_k2_val_1) &&
              (el.estensioneDifetto == estensione_k1_val_2 ||
                el.estensioneDifetto == estensione_k1_val_3)
          ) ||
        filteredG4G5.some(
          el =>
            el.ps &&
            (el.intensitaDifetto == intensita_k2_val_2 ||
              el.intensitaDifetto == intensita_k2_val_1)
        ) ||
        (elementoCritico == YesNo.SI &&
          filteredG3G2G1.some(
            el =>
              el.estensioneDifetto == estensione_k1_val_2 ||
              el.estensioneDifetto == estensione_k1_val_3
          ))
      ) {
        setValue(`${name}.CDA${type}`, CDAStrutturale.Media);
        setValue(`${name}.CDA${type}computed`, true);
      } else if (
        getValues(`${name}.schedaDifetti`)
          .filter(el => el.difettosita == difettosita_G.G4)
          .some(
            el =>
              el.intensitaDifetto == intensita_k2_val_2 ||
              el.intensitaDifetto == intensita_k2_val_1
          )
      ) {
        setValue(`${name}.CDA${type}`, CDAStrutturale.MedioBassa);
        setValue(`${name}.CDA${type}computed`, true);
      } else if (
        N_rel_321 >= Th_MB_B ||
        getValues(`${name}.schedaDifetti`)
          .filter(el => el.difettosita == difettosita_G.G5)
          .some(
            el =>
              (el.intensitaDifetto == intensita_k2_val_2 ||
                el.intensitaDifetto == intensita_k2_val_1) &&
              el.estensioneDifetto == estensione_k1_val_1
          ) ||
        (elementoCritico == YesNo.SI &&
          filteredG3G2G1.some(
            el => el.estensioneDifetto == estensione_k1_val_1
          ))
      ) {
        setValue(`${name}.CDA${type}`, CDAStrutturale.MedioBassa);
        setValue(`${name}.CDA${type}computed`, false);
      } else if (N_rel_321 < Th_MB_B) {
        setValue(`${name}.CDA${type}`, CDAStrutturale.Bassa);
        setValue(`${name}.CDA${type}computed`, false);
      } else {
        setValue(`${name}.CDA${type}`, null);
        setValue(`${name}.CDA${type}computed`, null);
      }
      setValue(`${name}.Nrel321`, N_rel_321);
      setValue(
        `${name}.summationk2G123`,
        filteredG3G2G1.filter(
          el => !isNullOrUndefinedOrEmptyString(el.intensitaDifetto)
        ).length
      );
      setValue(`${name}.summationG123`, filteredG3G2G1.length);
    }
  };

  return { calculateCDA };
};
export default useCDA;
