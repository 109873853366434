import { Typography } from '@mui/material';
import { tour_03 } from 'constants/tour';

/* import { Check } from '@mui/icons-material';*/
export const steps = [
  {
    selector: `.${tour_03.step_01}`,
    content: () => {
      return (
        <Typography>
          Leggi i riepiloghi dell&apos;ultima attività Archiviata.
        </Typography>
      );
    },
    /* style: {
      backgroundColor: '#bada55',
    }, */
  },
  {
    selector: `.${tour_03.step_02}`,
    content: () => {
      return <Typography>Naviga tra i documenti dell&apos;opera.</Typography>;
    },
  },
  {
    selector: `.${tour_03.step_03}`,
    content: () => {
      return (
        <Typography>
          Gestisci le attività dell&apos;opera: aprine di nuove, accedi
          all&apos;archivio o esporta i dati.
        </Typography>
      );
    },
  },
  {
    selector: `.${tour_03.step_04}`,
    content: () => {
      return (
        <Typography>
          Condividi il ponte con i tuoi collaboratori interni od esterni.
        </Typography>
      );
    },
  },
];
