import { Checkbox as MuiCheckbox } from '@mui/material';

import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import {
  CheckedIcon,
  IndeterminateIcon,
  UncheckedIcon,
} from './Checkbox.style';

export const Checkbox = forwardRef(
  ({ id, checked, onChange, indeterminate, isTreeViewParent }, ref) => {
    return (
      <>
        <MuiCheckbox
          color="secondary"
          icon={<UncheckedIcon />}
          id={id}
          checkedIcon={<CheckedIcon isTreeViewParent={isTreeViewParent} />}
          onChange={onChange}
          checked={checked}
          inputRef={ref}
          indeterminate={indeterminate}
          indeterminateIcon={<IndeterminateIcon />}
        />
      </>
    );
  }
);
Checkbox.displayName = 'checkbox';
Checkbox.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  indeterminate: PropTypes.bool,
  isTreeViewParent: PropTypes.bool,
};
