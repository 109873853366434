import styled from '@emotion/styled';
import { TableHead } from '@mui/material';
import { colors } from 'constants/colors';

export const CustomHead = styled(TableHead, {
  shouldForwardProp: prop => prop !== 'isMedia',
})(({ typevariant }) => ({
  backgroundColor:
    typevariant === 'secondary' ? colors.WHITE : colors.LIGHTGREY,
  ...(typevariant === 'secondary' && { border: `none !important` }),
}));
