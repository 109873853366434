import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, GridWrap, InfoWrapper, Table } from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import { tipologiaPiedrittoOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import PiedrittiForm from './PiedrittiForm';
const PiedrittiSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const mensoleTamponiCampateImpalcatoTablePiedrittiTableWatch = watch(
    `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable`
  );

  // table setup
  const dataConfigPiedritti = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaPiedritto: {
        label: 'Tipologia',
        format: tipologiaPiedrittoOptions,
      },
      altezzaPiedritto: {
        label: 'H [cm]',
      },
      larghezzaPiedritto: {
        label: 'L [cm]',
      },
      profonditaPiedritto: {
        label: 'P [cm]',
      },
      superficiePiedritto: {
        label: 'Sup. [m²]',
        formatValue: val => `${(+val)?.toFixed(2)}`,
      },
    },
  };

  // piedritti setup
  const [selectedPiedritti, setSelectedPiedritti] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsPiedritti = item => {
    setValue('l0t2a5_piedrittiTableValue', [item]);
    setValue('l0t2a5_tipologiaPiedrittoTableValue', item.tipologiaPiedritto);
    setValue('l0t2a5_altezzaPiedrittoTableValue', item.altezzaPiedritto);
    setValue('l0t2a5_larghezzaPiedrittoTableValue', item.larghezzaPiedritto);
    setValue('l0t2a5_profonditaPiedrittoTableValue', item.profonditaPiedritto);
    setValue('l0t2a5_superficiePiedrittoTableValue', item.superficiePiedritto);
  };
  const handleSelectAllClickPiedritti = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable`
      );
      setSelectedPiedritti(selectedValue);
      setValue('l0t2a5_piedrittiTableValue', selectedValue);
    } else {
      setSelectedPiedritti([]);
      setValue('l0t2a5_piedrittiTableValue', []);
    }
  };

  return (
    <>
      <GridWrap container spacing={2} style={{ marginTop: 16 }}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.impalcatoPiedritti.drawerTitle}
          drawerText={INFO.impalcatoPiedritti.drawerText}
        >
          <Divider>PIEDRITTI</Divider>
        </InfoWrapper>
        {mensoleTamponiCampateImpalcatoTablePiedrittiTableWatch?.length ===
          0 && (
          <GridWrap item xs={12} paddingTop={'0px !important'}>
            <Typography>
              Nessun piedritto presente in questo elemento
            </Typography>
          </GridWrap>
        )}
      </GridWrap>
      {mensoleTamponiCampateImpalcatoTablePiedrittiTableWatch?.length > 0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <PiedrittiForm
              piedrittiFields={
                mensoleTamponiCampateImpalcatoTablePiedrittiTableWatch || []
              }
              selected={selectedPiedritti}
              setSelected={setSelectedPiedritti}
              piedrittiTable={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Piedritti'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Struttura]}
          />
          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={
                  mensoleTamponiCampateImpalcatoTablePiedrittiTableWatch || []
                }
                config={dataConfigPiedritti}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsPiedritti(item);
                            setSelectedPiedritti([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        mensoleTamponiCampateImpalcatoTablePiedrittiTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedPiedritti}
                onSelectAllClick={handleSelectAllClickPiedritti}
                setSelected={setSelectedPiedritti}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
PiedrittiSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default PiedrittiSection;
