import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const BtnBottomContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 8,
  backgroundColor: colors.WHITE,
  width: '100%',
  position: 'fixed',
  padding: '10px 0',
  bottom: 0,
  right: 35,
  zIndex: 3,
  heigth: 100,
});
