/* import { Accordion10CaratteristicheGeometriche } from '../Accordion10CaratteristicheGeometriche/Accordion10CaratteristicheGeometriche'; */
import { WebL0Fields } from 'constants/inspectionFields';
import { Accordion11CommentiPerReport } from '../Accordion11CommentiPerReport/Accordion11CommentiPerReport';
import { Accordion1GeneralInfo } from '../Accordion1GeneralInfo/Accordion1GeneralInfo';
import { Accordion2AdministrativeData } from '../Accordion2AdministrativeData/Accordion2AdministrativeData';
import { Accordion3Location } from '../Accordion3Location/Accordion3Location';
import { Accordion4LandslideRisk } from '../Accordion4LandslideRisk/Accordion4LandslideRisk';
import { Accordion5HydraulicRisk } from '../Accordion5HydraulicRisk/Accordion5HydraulicRisk';
import { Accordion6DataProject } from '../Accordion6DataProject/Accordion6DataProject';
import { Accordion7PreviousActivities } from '../Accordion7PreviousActivities/Accordion7PreviousActivities';
import { Accordion8ConnectionAndRoadNetwork } from '../Accordion8ConnectionAndRoadNetwork/Accordion8ConnectionAndRoadNetwork';
import { Accordion9GeomorphologicalData } from '../Accordion9GeomorphologicalData/Accordion9GeomorphologicalData';

export const AccordionDataGeneralL0 = [
  {
    id: '1',
    title: '1. Informazioni Generali',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion1GeneralInfo />,
    indicatorFields: WebL0Fields.Contesto.informazioniGenerali,
  },
  {
    id: '2',
    title: '2. Dati amministrativi',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion2AdministrativeData />,
    indicatorFields: WebL0Fields.Contesto.datiAmministrativi,
  },
  {
    id: '3',
    title: '3. Localizzazione',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion3Location />,
    indicatorFields: WebL0Fields.Contesto.localizzazione,
  },
  {
    id: '4',
    title: '4. Rischio frane',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion4LandslideRisk />,
    indicatorFields: WebL0Fields.Contesto.rischioFrana,
  },
  {
    id: '5',
    title: '5. Rischio idraulico',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion5HydraulicRisk />,
    indicatorFields: WebL0Fields.Contesto.rischioIdraulico,
  },
  {
    id: '6',
    title: '6. Dati di progetto',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion6DataProject />,
    indicatorFields: WebL0Fields.Contesto.datiProgetto,
  },
  {
    id: '7',
    title: '7. Attività pregresse',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion7PreviousActivities />,
    indicatorFields: WebL0Fields.Contesto.attivitaPregresse,
  },
  {
    id: '8',
    title: '8. Collegamento e rete stradale',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion8ConnectionAndRoadNetwork />,
    indicatorFields: WebL0Fields.Contesto.collegamentoReteStradale,
  },
  {
    id: '9',
    title: '9. Dati geomorfologici ed esposizione',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion9GeomorphologicalData />,
    indicatorFields: WebL0Fields.Contesto.datiGeomorfologici,
  },
  /*   {
    id: '10',
    title: '10. Caratteristiche geometriche',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion10CaratteristicheGeometriche />,
  }, */
  {
    id: '10',
    title: '10. Commenti per report',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion11CommentiPerReport />,
    /* indicatorFields: WebL0Fields.Contesto.commentiReport, */
  },
];
