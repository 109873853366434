import styled from '@emotion/styled';
import CheckedSvg from 'assets/icons/check.svg';
import MinusSvg from 'assets/icons/minus-icon.svg';
import { colors } from '../../constants/colors';

export const UncheckedIcon = styled('div')({
  borderRadius: 3,
  width: 18,
  height: 18,
  border: `1px solid ${colors.PALAGREY}`,
  backgroundColor: colors.WHITE,
  marginRight: 8,
});

export const CheckedIcon = styled('div')(({ isTreeViewParent }) => ({
  borderRadius: 3,
  width: 18,
  height: 18,
  border: `1px solid ${colors.PALAGREY}`,
  backgroundImage: !isTreeViewParent && `url(${CheckedSvg})`,
  backgroundColor: isTreeViewParent ? colors.BLUE : colors.WHITE,
  backgroundClip: 'content-box',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '80%',
  marginRight: 8,
  padding: isTreeViewParent && '4px',
}));

export const IndeterminateIcon = styled('div')(({ isTreeViewParent }) => ({
  borderRadius: 3,
  width: 18,
  height: 18,
  border: `1px solid ${colors.PALAGREY}`,
  backgroundImage: `url(${MinusSvg})`,
  backgroundColor: isTreeViewParent ? colors.BLUE : colors.WHITE,
  backgroundClip: 'content-box',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '80%',
  marginRight: 8,
  padding: isTreeViewParent && '4px',
}));
