import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaGiuntoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

const GiuntiForm = ({
  giuntiFields,
  selected,
  setSelected,
  giuntiTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      giuntiTableValue: [],
      tipologiaGiuntoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('giuntiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetGiunti();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeGiunti = () => {
    const tipologiaGiuntoTableValue = getValues('tipologiaGiuntoTableValue');

    const ids = selected.map(el => el.codice);

    const giunti = [...giuntiFields];

    giunti.map((x, index) => {
      if (ids.includes(x?.codice)) {
        giunti[index] = {
          ...x,
          tipologiaGiunto: tipologiaGiuntoTableValue,
          ...(tipologiaGiuntoTableValue !== x.tipologiaGiunto
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'giunto',
                  tipologiaGiuntoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(giuntiTable, giunti);
    onResetGiunti();
  };

  const onConfirmChangeGiunti = () => {
    setOpenConfirmationDialog(false);
    changeGiunti();
  };

  const onSubmitGiunti = () => {
    const tipologiaGiuntoTableValue = getValues('tipologiaGiuntoTableValue');

    const ids = selected.map(el => el.codice);

    const giunti = [...giuntiFields];

    const hasChangedTipologia = giunti.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          tipologiaGiuntoTableValue !== el.tipologiaGiunto &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaGiunto)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeGiunti();
    }
  };

  const onResetGiunti = () => {
    setValue('giuntiTableValue', []);
    setValue('tipologiaGiuntoTableValue', '');
    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={3} infoMessage={INFO.giuntiSelezionati.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-giunti"
            name={'giuntiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={giuntiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Giunti selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipologiaGiuntoTableValue"
            label="Tipologia Giunto"
            defaultValue={''}
            onChange={e => {
              setValue('tipologiaGiuntoTableValue', e.target.value);
            }}
            selectOptions={tipologiaGiuntoOptions}
          />
        </GridWrap>
        <GridWrap item xs={6}>
          <FormSubmitButtons
            onSubmit={onSubmitGiunti}
            onReset={onResetGiunti}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeGiunti}
      />
    </>
  );
};
GiuntiForm.propTypes = {
  giuntiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaGiunto: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  giuntiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default GiuntiForm;
