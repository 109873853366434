import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { InfoWrapper, MediaUpload, RHFSelect, RHFTextField } from 'components';
import {
  FieldType,
  InserimentoNeiPianiPaesaggistici,
  MediaGroup,
  RischioFrana,
  RischioIdraulico,
  TipologiaEnteScavalcato,
  TutelaDLgs,
  UploadMedia,
} from 'constants/inspections';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap, TitleSection } from './Accordion2AdministrativeData.styles';
import { INFO } from '../../../../config/info';

const Accordion2AdministrativeData = () => {
  const { setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchTypoEnteScavalcato = watch('tipologiaEnteScavalcato');

  const watchTypoEnteCorsoAcqua =
    watchTypoEnteScavalcato !== ''
      ? watchTypoEnteScavalcato &&
        watchTypoEnteScavalcato?.some(
          el => el === TipologiaEnteScavalcato.CorsoAcqua
        )
      : false;

  const watchTutelaDLgs = watch('tutelaDLgs');
  const watchTutelaDLgsYes = watchTutelaDLgs === TutelaDLgs.Si;

  const watchRischioFrana = watch('rischioFrana');
  const watchRischioFranaAssente = watchRischioFrana === RischioFrana.Assente;

  const watchRischioIdraulico = watch('rischioIdraulico');
  const watchRischioIdraulicoAssente =
    watchRischioIdraulico === RischioIdraulico.Assente;

  //Functions
  useEffect(() => {
    if (!watchTypoEnteCorsoAcqua) {
      setValue('bacinoIdrografico', '');
    }
  }, [watchTypoEnteScavalcato]);

  useEffect(() => {
    if (!watchTutelaDLgsYes) {
      setValue('provvedimentiDiTutela', '');
      setValue('autoreDellaProgettazione', '');
      setValue('inserimentoNeiPianiPaesaggistici', '');
    }
  }, [watchTutelaDLgsYes]);

  useEffect(() => {
    if (watchRischioFranaAssente && watchRischioIdraulicoAssente) {
      setValue('autoritaDistrettuale', '');
    }
  }, [watchRischioFrana, watchRischioIdraulico]);

  return (
    <>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">DATI GENERALI</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="proprietario"
            label={'Proprietario*'}
            inputProps={{ maxLength: 150 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="concessionario"
            label={'Concessionario*'}
            inputProps={{ maxLength: 150 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="enteVigilante"
            label={'Ente Vigilante'}
            inputProps={{ maxLength: 150 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoMessage={INFO.autoritaDistrettuale.infoMessage}
        >
          <RHFTextField
            name="autoritaDistrettuale"
            label={'Autorità distrettuale'}
            inputProps={{ maxLength: 150 }}
            defaultValue=""
            disabled={
              (watchRischioFranaAssente && watchRischioIdraulicoAssente) ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFTextField
            name="bacinoIdrografico"
            label={'Bacino idrografico'}
            inputProps={{ maxLength: 150 }}
            defaultValue=""
            disabled={
              !watchTypoEnteCorsoAcqua ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
        </GridWrap>

        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti amministrativi`}
            btnTitle="Allega file"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            formName="documentiAmministrativi"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Amministrativi]}
            tags={[
              'gestione',
              'fatture',
              'ordini',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
              'convenzioni',
              'ordinanza',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Corrispondenza con enti terzi`}
            btnTitle="Allega file"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            formName="corrispondenzaEntiTerzi"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Amministrativi]}
            tags={[
              'gestione',
              'fatture',
              'ordini',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
              'convenzioni',
              'ordinanza',
            ]}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              PROVVEDIMENTI DI TUTELA
            </Typography>
          </TitleSection>
        </GridWrap>
        <InfoWrapper
          size={6}
          legislationMessage={
            INFO.tutelaAiSensiDelDecretoLegge.legislationMessage
          }
        >
          <RHFSelect
            name="tutelaDLgs"
            label={'Tutela ai sensi del D. Lgs. 42/2004'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Sì',
                value: TutelaDLgs.Si,
              },
              {
                label: 'No',
                value: TutelaDLgs.No,
              },
            ]}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFTextField
            name="provvedimentiDiTutela"
            label={'Provvedimenti di tutela'}
            inputProps={{ maxLength: 150 }}
            defaultValue=""
            disabled={
              !watchTutelaDLgsYes || inspectionDisabled || isViewingInspection
            }
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="autoreDellaProgettazione"
            label={'Autore della progettazione'}
            inputProps={{ maxLength: 150 }}
            defaultValue=""
            disabled={
              !watchTutelaDLgsYes || inspectionDisabled || isViewingInspection
            }
          />
        </GridWrap>
        <GridWrap item xs={6}>
          <RHFSelect
            name="inserimentoNeiPianiPaesaggistici"
            label={'Inserimento nei Piani Paesaggistici vigenti/adottati'}
            defaultValue={''}
            disabled={
              !watchTutelaDLgsYes || inspectionDisabled || isViewingInspection
            }
            selectOptions={[
              {
                label: 'Sì',
                value: InserimentoNeiPianiPaesaggistici.Si,
              },
              {
                label: 'No',
                value: InserimentoNeiPianiPaesaggistici.No,
              },
            ]}
          />
        </GridWrap>
      </GridWrap>
    </>
  );
};

export { Accordion2AdministrativeData };
