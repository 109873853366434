import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const DrawerContentContainer = styled('div')({
  marginTop: 30,
  width: '100%',
  backgroundColor: colors.WHITE,
  borderRadius: 8,
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

export const BtnPanelContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 5,
  marginTop: 15,
});

export const InfoPanelBox = styled('div')({
  marginTop: 20,
});
