import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, GridWrap, InfoWrapper, Table } from 'components';
import {
  FieldType,
  MediaGroup,
  SchemaPonteCommonTableValue,
  UploadMedia,
} from 'constants/inspections';
import { tipologiaPavimentazioneOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import PavimentazioneForm from './PavimentazioneForm';
const PavimentazioneSection = ({ selectedIdx }) => {
  // table setup
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const sovrastrutturaImpalcatoTablePavimentazioneTableWatch = watch(
    `sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable`
  );

  const dataConfigPavimentazione = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaPavimentazione: {
        label: 'Tipologia pavimentazione',
        format: tipologiaPavimentazioneOptions,
      },
      tipologiaAltroPavimentazione: {
        label: 'Tipologia pavimentazione (altro)',
      },
      spessorePavimentazione: {
        label: 's [cm]',
      },
      superficiePavimentazione: {
        label: 'S [m²]',
      },
    },
  };
  // pavimentazione setup
  const [selectedPavimentazione, setSelectedPavimentazione] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsPavimentazione = item => {
    setValue('l0t3a2_pavimentazioneTableValue', [item]);
    setValue(
      'l0t3a2_tipologiaPavimentazioneTableValue',
      item.tipologiaPavimentazione
    );
    setValue(
      'l0t3a2_tipologiaAltroPavimentazioneTableValue',
      item.tipologiaAltroPavimentazione
    );
    setValue(
      'l0t3a2_spessorePavimentazioneTableValue',
      item.spessorePavimentazione
    );
    setValue(
      'l0t3a2_superficiePavimentazioneTableValue',
      item.superficiePavimentazione
    );
  };

  const handleSelectAllClickPavimentazione = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable`
      );
      const filteredValue = selectedValue.filter(
        el => el.presenza === SchemaPonteCommonTableValue.Uno
      );
      setSelectedPavimentazione(filteredValue);
      setValue('l0t3a2_pavimentazioneTableValue', filteredValue);
    } else {
      setSelectedPavimentazione([]);
      setValue('l0t3a2_pavimentazioneTableValue', []);
    }
  };

  return (
    <>
      <InfoWrapper
        isDivider={true}
        size={12}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoPavimento.drawerTitle}
        drawerText={INFO.impalcatoPavimento.drawerText}
      >
        <Divider sx={{ marginTop: 3 }}>PAVIMENTAZIONE</Divider>
      </InfoWrapper>
      {sovrastrutturaImpalcatoTablePavimentazioneTableWatch?.length === 0 && (
        <GridWrap item xs={12}>
          <Typography>
            Nessuna pavimentazione prevista in questo elemento
          </Typography>
        </GridWrap>
      )}
      {sovrastrutturaImpalcatoTablePavimentazioneTableWatch?.filter(
        el => el.assente
      ).length > 0 && (
        <GridWrap item xs={12}>
          <Typography>
            Nessuna pavimentazione presente in questo elemento
          </Typography>
        </GridWrap>
      )}
      {sovrastrutturaImpalcatoTablePavimentazioneTableWatch?.filter(
        el => !el.assente
      ).length > 0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <PavimentazioneForm
              pavimentazioneFields={
                sovrastrutturaImpalcatoTablePavimentazioneTableWatch || []
              }
              selected={selectedPavimentazione}
              setSelected={setSelectedPavimentazione}
              pavimentazioneTable={`sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Pavimentazione'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Sovrastruttura]}
          />
          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={
                  sovrastrutturaImpalcatoTablePavimentazioneTableWatch.filter(
                    el => !el.assente
                  ) || []
                }
                config={dataConfigPavimentazione}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsPavimentazione(item);
                            setSelectedPavimentazione([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        sovrastrutturaImpalcatoTablePavimentazioneTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedPavimentazione}
                onSelectAllClick={handleSelectAllClickPavimentazione}
                setSelected={setSelectedPavimentazione}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
PavimentazioneSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default PavimentazioneSection;
