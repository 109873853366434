import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const FilesContainer = styled('div')({
  marginTop: 30,
  width: '100%',
  backgroundColor: colors.WHITE,
  borderRadius: 8,
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});
