import { Button, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import graph1 from 'assets/icons/graphs/graph1.svg';
import graph2 from 'assets/icons/graphs/graph2.svg';
import graph3 from 'assets/icons/graphs/graph3.svg';
import graph4 from 'assets/icons/graphs/graph4.svg';
import graph5 from 'assets/icons/graphs/graph5.svg';
import {
  CardOverview,
  CustomTooltip,
  DrawerNewInspection,
  ImageCarousel,
} from 'components';
import { GaugeBands } from 'components/Graphics/Overview/GaugeBands';
import { Pie } from 'components/Graphics/Overview/Pie';
import { RadiusRadar } from 'components/Graphics/Overview/RadiusRadar';
import { GuidelinesBox } from 'components/GuidelinesBox/GuidelinesBox';
import { PlanningBox } from 'components/PlanningBox/PlanningBox';
import { PlanningBoxDate } from 'components/PlanningBox/PlanningBoxDate/PlanningBoxDate';
import { apiBridges } from 'constants/api';
import { InspectionStatusesMap, Rating } from 'constants/colors';
import { InspectionStatus } from 'constants/inspections';
import { STRUCTURES_PATHS } from 'constants/paths';
import { PermissionType } from 'constants/permissions';
import { useSubscription } from 'hooks';
import usePromises from 'hooks/usePromises';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import { formatData, handleDynamicInspectionsTabChange } from 'utils/utilities';
import {
  Box,
  ContainerAttentionClass,
  GraphicContainer,
  Image,
  LittleContainer,
  MiniBoxContainer,
  RateItem,
  Row,
  Status,
  StatusContainer,
} from './TabOverview.styles';

const TabOverview = ({
  setValue,
  graphRef,
  graphRef2,
  structure,
  userPermissionOnStructure,
  openDrawerNewInspection,
  setOpenDrawerNewInspection,
}) => {
  const { user } = useSubscription();
  const { setPromises } = usePromises();
  const { get } = useFetch();
  const params = useParams();
  const id = params.structureId;
  const inspectionId = params?.inspectionId;
  const [imagesList, setImagesList] = useState([]);
  const [overviewDetail, setOverviewDetail] = useState();
  const cda = overviewDetail?.wasDetailCalculated ? true : false;
  /*  const photo = [];
  const planning = []; */
  const icValue = overviewDetail?.ic;
  const g5Value = overviewDetail?.totalG5;
  const psValue = overviewDetail?.totalPS;
  const nrValue = overviewDetail?.totalNR;

  const isOwner =
    structure?.ownerId === user?.id || structure?.ownerId === user?.parent?.id;

  const navigate = useNavigate();

  const lastInspectionId = overviewDetail?.requestUserLastValidInspectionId;

  const renderVideo = item => {
    return (
      <div>
        <video controls style={{ width: '400px', height: '300px' }}>
          <source src={item.original}></source>
        </video>
      </div>
    );
  };
  const imagesWeb = [
    {
      original: graph3,
      originalHeight: '130px',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleImages = async () => {
      if (lastInspectionId) {
        const resultMedia = await get(`bridges/${id}/overviewImages`);

        if (resultMedia?.length > 0) {
          const images = [];
          resultMedia?.map(x => {
            images.push({
              original: x.url,
              originalHeight: '350px',
              renderItem: x.isVideo ? renderVideo : null,
            });
          });

          setImagesList(images);
        }
      }
    };

    setPromises([handleImages()]);
  }, [lastInspectionId]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleOverview = async () => {
      const resultOverview = await get(
        inspectionId
          ? `${apiBridges.ROOT}/${id}/overview?inspectionId=${inspectionId}`
          : `${apiBridges.ROOT}/${id}/overview`
      );
      if (resultOverview) {
        setOverviewDetail(resultOverview);
      }
    };

    setPromises([handleOverview()]);
  }, []);

  const renderTypo = index => {
    return (
      <>
        <Status color={InspectionStatusesMap[index].labelColor} />
        <Typography variant="body" sx={{ fontSize: '0.75rem' }}>
          {InspectionStatusesMap[index].labelCdaGraph}
        </Typography>
      </>
    );
  };

  return structure?.status !== InspectionStatus.NEW ||
    overviewDetail?.hasInspection ? (
    <>
      <Row>
        <CardOverview
          title={'Classe di attenzione'}
          subtitle={
            cda &&
            overviewDetail?.lastUpdateUser &&
            overviewDetail?.inspectionUpdatedAt
              ? `Risultati derivanti dall'ispezione del ${formatData(
                  overviewDetail?.inspectionUpdatedAt
                )} di ${overviewDetail?.lastUpdateUser?.name} ${
                  overviewDetail?.lastUpdateUser?.surname
                }`
              : `Risultati derivanti dall'ispezione`
          }
          buttonText={'Approfondisci'}
          buttonDisabled={!lastInspectionId}
          onClick={() => setValue(3)}
          // buttonDisabled={true}
        >
          <ContainerAttentionClass>
            <GraphicContainer>
              <Typography variant="h3Bold" sx={{ margin: '19px 24px' }}>
                {'CdA'}
              </Typography>
              {!cda || !overviewDetail?.cdaPie ? (
                <Image ref={graphRef}>
                  <img src={graph4} />
                </Image>
              ) : (
                <div
                  ref={graphRef}
                  style={{
                    height: '100%',
                  }}
                >
                  <Pie chartID="pie-two" cda={overviewDetail?.cdaPie} />
                </div>
              )}

              <StatusContainer>
                {renderTypo(1)}
                {renderTypo(2)}
                {renderTypo(3)}
                {renderTypo(4)}
                {renderTypo(5)}
              </StatusContainer>
            </GraphicContainer>
            <MiniBoxContainer>
              <Box>
                <CustomTooltip
                  btnText={
                    <Typography
                      variant="h3Bold"
                      sx={{
                        margin: '19px 24px',
                        color: 'black',
                      }}
                    >
                      {'IC'}
                    </Typography>
                  }
                  title={`Indice di Condizione. Valori più vicini al 100% denotano una migliore condizione dell'opera.`}
                />
                {!icValue && icValue !== 0 ? (
                  <Typography
                    variant="body"
                    sx={{
                      fontSize: '0.75rem !important',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    {'n.d'}
                  </Typography>
                ) : (
                  <RateItem
                    customColors={
                      Rating[icValue >= 95 ? 'undefined' : 'checked']
                    }
                  >
                    <Typography
                      variant="body"
                      sx={{ fontSize: '0.75rem !important' }}
                    >
                      {`${icValue}%`}
                    </Typography>
                  </RateItem>
                )}
              </Box>
              <Box>
                <CustomTooltip
                  btnText={
                    <Typography
                      variant="h3Bold"
                      sx={{
                        margin: '19px 24px',
                        color: 'black',
                      }}
                    >
                      {'G=5'}
                    </Typography>
                  }
                  title={`N° difetti presenti con peso pari a 5 (difetti più gravi)`}
                />

                {!g5Value ? (
                  <Typography
                    variant="body"
                    sx={{
                      fontSize: '0.75rem !important',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    {'n.d'}
                  </Typography>
                ) : g5Value === '0' ? (
                  <RateItem customColors={Rating['undefined']}>
                    <Typography
                      variant="body"
                      sx={{ fontSize: '0.75rem !important' }}
                    >
                      {g5Value}
                    </Typography>
                  </RateItem>
                ) : (
                  <RateItem customColors={Rating['checked']}>
                    <Typography
                      variant="body"
                      sx={{ fontSize: '0.75rem !important' }}
                    >
                      {g5Value}
                    </Typography>
                  </RateItem>
                )}
              </Box>
              <Box>
                <CustomTooltip
                  btnText={
                    <Typography
                      variant="h3Bold"
                      sx={{
                        margin: '19px 24px',
                        color: 'black',
                      }}
                    >
                      {'PS'}
                    </Typography>
                  }
                  title={`N° difetti presenti che possono compromettere la statica dell'opera`}
                />
                {!psValue ? (
                  <Typography
                    variant="body"
                    sx={{
                      fontSize: '0.75rem !important',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    {'n.d'}
                  </Typography>
                ) : psValue === '0' ? (
                  <RateItem customColors={Rating['undefined']}>
                    <Typography
                      variant="body"
                      sx={{ fontSize: '0.75rem !important' }}
                    >
                      {psValue}
                    </Typography>
                  </RateItem>
                ) : (
                  <RateItem customColors={Rating['checked']}>
                    <Typography
                      variant="body"
                      sx={{ fontSize: '0.75rem !important' }}
                    >
                      {psValue}
                    </Typography>
                  </RateItem>
                )}
              </Box>
              <Box>
                <CustomTooltip
                  btnText={
                    <Typography
                      variant="h3Bold"
                      sx={{
                        margin: '19px 24px',
                        color: 'black',
                      }}
                    >
                      {'NR'}
                    </Typography>
                  }
                  title={`N° difetti che non si è potuto rilevare mediante ispezione visiva`}
                />
                {!nrValue ? (
                  <Typography
                    variant="body"
                    sx={{
                      fontSize: '0.75rem !important',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    {'n.d'}
                  </Typography>
                ) : nrValue === '0' ? (
                  <RateItem customColors={Rating['undefined']}>
                    <Typography
                      variant="body"
                      sx={{ fontSize: '0.75rem !important' }}
                    >
                      {nrValue}
                    </Typography>
                  </RateItem>
                ) : (
                  <RateItem customColors={Rating['checked']}>
                    <Typography
                      variant="body"
                      sx={{ fontSize: '0.75rem !important' }}
                    >
                      {nrValue}
                    </Typography>
                  </RateItem>
                )}
              </Box>
            </MiniBoxContainer>
          </ContainerAttentionClass>
        </CardOverview>
        <CardOverview
          title={'Gallery'}
          // subtitle={
          //   photo.length === 0
          //     ? 'Foto e video non disponibili'
          //     : 'Foto e video associati alla struttura'
          // }
          subtitle={
            imagesList?.length > 0
              ? 'Foto e video associati alla struttura'
              : 'Foto e video non disponibili'
          }
          buttonText={'Vedi foto'}
          buttonDisabled={!lastInspectionId}
          onClick={() =>
            navigate(
              STRUCTURES_PATHS.STRUCTURE_IMAGES.replace(':structureId', id)
            )
          }
        >
          <div style={{ height: '70%', width: '100%', margin: 'auto 0' }}>
            <ImageCarousel
              items={imagesList?.length > 0 ? imagesList : imagesWeb}
              slidesPerView={1}
              canClick={imagesList?.length > 0}
            />
          </div>
        </CardOverview>
      </Row>

      <CardOverview
        title={'Pianificazione'}
        subtitle={
          "Attività necessarie e aspetti rilevanti emersi dall'ispezione"
        }
        height={'400px'}
      >
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12}>
            <PlanningBoxDate
              lastOrdinaryDate={overviewDetail?.lastInspectionDate}
              nextOrdinaryDate={overviewDetail?.nextOrdinaryInspectionDate}
              nextStraordinaryDate={
                overviewDetail?.nextExtraordinaryInspectionDate
              }
            />
          </Grid>
          <Grid item xs={3}>
            <PlanningBox
              isSpecialInspection={true}
              topic="Necessità ispezioni speciali"
              status={overviewDetail?.specialInspectionInfo?.necessity}
              specialInspectionTopics={
                overviewDetail?.specialInspectionInfo?.sections.length > 0
                  ? overviewDetail?.specialInspectionInfo?.sections
                  : overviewDetail?.specialInspectionInfo?.necessity === 2
                  ? ['Assente']
                  : undefined
              }
              tooltip={`Da effettuare per ponti in c.a.p. a cavi post-tesi e ponti in aree ad evidenza din fenomeni alluvionali, erosionali e franosi, o riconosciute ad elevato rischio idrogeologico, con evidenza di possibile interferenza con la struttura`}
            />
          </Grid>

          <Grid item xs={3}>
            <PlanningBox
              handleDynamicTabChange={() =>
                handleDynamicInspectionsTabChange(
                  inspectionTabMapping.L1,
                  inspectionTabMapping.Struttura,
                  inspectionTabMapping.L1AspettiCritici,
                  navigate,
                  STRUCTURES_PATHS.STRUCTURES_LIST,
                  id,
                  lastInspectionId
                )
              }
              lastInspectionId={lastInspectionId}
              topic="Aspetti critici struttura"
              status={overviewDetail?.aspettiCriticiStrutturali}
              statusMessage={
                overviewDetail?.aspettiCriticiStrutturali === 1
                  ? 'Presenti'
                  : 'Assenti'
              }
              tooltip={`Presenza di elementi e/o condizioni critiche e/o elementi di vulnerabilità sismica`}
            />
          </Grid>
          <Grid item xs={3}>
            <PlanningBox
              handleDynamicTabChange={() =>
                handleDynamicInspectionsTabChange(
                  inspectionTabMapping.L1,
                  inspectionTabMapping.ElementiAccessori,
                  null,
                  navigate,
                  STRUCTURES_PATHS.STRUCTURES_LIST,
                  id,
                  lastInspectionId
                )
              }
              lastInspectionId={lastInspectionId}
              topic="Interventi su elementi accessori"
              status={overviewDetail?.interventiSuSovrastruttura}
              statusMessage={
                overviewDetail?.interventiSuSovrastruttura === 1
                  ? 'Necessari'
                  : 'Non necessari'
              }
              tooltip={`Necessità di interventi su elementi accessori sulla base dell'elaborazione delle schede difettologiche`}
            />
          </Grid>
          <Grid item xs={3}>
            <PlanningBox
              id={id}
              handleDynamicTabChange={() =>
                handleDynamicInspectionsTabChange(
                  inspectionTabMapping.L1,
                  inspectionTabMapping.Servizi,
                  null,
                  navigate,
                  STRUCTURES_PATHS.STRUCTURES_LIST,
                  id,
                  lastInspectionId
                )
              }
              lastInspectionId={lastInspectionId}
              topic="Interventi su servizi"
              status={overviewDetail?.interventiSuServizi}
              statusMessage={
                overviewDetail?.interventiSuServizi === 1
                  ? 'Necessari'
                  : 'Non necessari'
              }
              tooltip={`Necessità di interventi sui servizi sulla base dell'elaborazione delle schede difettologiche`}
            />
          </Grid>

          <Grid item xs={3}>
            <PlanningBox
              handleDynamicTabChange={() =>
                handleDynamicInspectionsTabChange(
                  inspectionTabMapping.L0,
                  inspectionTabMapping.DatiGenerali,
                  inspectionTabMapping.L0AttivitaPregresse,
                  navigate,
                  STRUCTURES_PATHS.STRUCTURES_LIST,
                  id,
                  lastInspectionId
                )
              }
              lastInspectionId={lastInspectionId}
              topic="Monitoraggio attivo"
              status={overviewDetail?.attivitaMonitoraggioPregresseInCorso}
              statusMessage={
                overviewDetail?.attivitaMonitoraggioPregresseInCorso === 1
                  ? 'Presente'
                  : 'Assente'
              }
            />
          </Grid>
          <Grid item xs={3}>
            <PlanningBox
              handleDynamicTabChange={() =>
                handleDynamicInspectionsTabChange(
                  inspectionTabMapping.L0,
                  inspectionTabMapping.DatiGenerali,
                  inspectionTabMapping.L0CollegamentoReteStradale,
                  navigate,
                  STRUCTURES_PATHS.STRUCTURES_LIST,
                  id,
                  lastInspectionId
                )
              }
              lastInspectionId={lastInspectionId}
              topic="Limitazioni di carico"
              status={overviewDetail?.limiteCaricoCarreggiataSingolaCorsia}
              statusMessage={
                overviewDetail?.limiteCaricoCarreggiataSingolaCorsia === 1
                  ? 'Presente'
                  : 'Assente'
              }
            />
          </Grid>

          <Grid item xs={3}>
            <PlanningBox
              handleDynamicTabChange={() =>
                handleDynamicInspectionsTabChange(
                  inspectionTabMapping.L0,
                  inspectionTabMapping.DatiGenerali,
                  inspectionTabMapping.L0CollegamentoReteStradale,
                  navigate,
                  STRUCTURES_PATHS.STRUCTURES_LIST,
                  id,
                  lastInspectionId
                )
              }
              lastInspectionId={lastInspectionId}
              topic="Trasporto eccezionale"
              status={overviewDetail?.trasportiEccezionali}
              statusMessage={
                overviewDetail?.trasportiEccezionali === 1
                  ? 'Presente'
                  : 'Assente'
              }
            />
          </Grid>
          <Grid item xs={3}>
            <PlanningBox
              handleDynamicTabChange={() =>
                handleDynamicInspectionsTabChange(
                  inspectionTabMapping.L0,
                  inspectionTabMapping.DatiGenerali,
                  inspectionTabMapping.L0CollegamentoReteStradale,
                  navigate,
                  STRUCTURES_PATHS.STRUCTURES_LIST,
                  id,
                  lastInspectionId
                )
              }
              lastInspectionId={lastInspectionId}
              topic="Trasporto merci pericolose"
              status={overviewDetail?.trasportoMerciPericolose}
              statusMessage={
                overviewDetail?.trasportoMerciPericolose === 1
                  ? 'Previsto'
                  : 'Non previsto'
              }
            />
          </Grid>
        </Grid>
      </CardOverview>

      <Row>
        <CardOverview
          title={'Parametri primari - Linee guida'}
          subtitle={
            'Aspetti primari da poter approfondire per migliorare la Classe di Attenzione'
          }
          buttonText={'Approfondisci'}
          buttonDisabled={!lastInspectionId}
          onClick={() => setValue(3)}
        >
          <GraphicContainer width="100%">
            {cda && overviewDetail?.primaryParameters ? (
              <div
                ref={graphRef2}
                style={{
                  height: '308px',
                }}
              >
                <RadiusRadar
                  chartID="radius-one"
                  cda={overviewDetail?.primaryParameters}
                  overviewDetail={overviewDetail}
                />{' '}
              </div>
            ) : (
              <Image style={{ marginTop: '40px' }}>
                <img src={graph1} ref={graphRef2} />
              </Image>
            )}
          </GraphicContainer>
        </CardOverview>
        <CardOverview
          title={'Parametri secondari - Linee guida'}
          subtitle={
            'Variabili secondarie che influiscono sulla Classe di Attenzione'
          }
          buttonText={'Approfondisci'}
          buttonDisabled={!lastInspectionId}
          onClick={() => setValue(3)}
        >
          <Grid container spacing={2} marginTop={1}>
            <Grid
              item
              xs={cda && overviewDetail?.rischioFrana === 2 ? 12 : 6}
              spacing={0}
              padding={0}
            >
              <LittleContainer>
                <CustomTooltip
                  btnText={
                    <Typography
                      variant="h3Bold"
                      sx={{
                        margin: '2px 24px',
                        color: 'black',
                      }}
                    >
                      {'Evoluzione Degrado'}
                    </Typography>
                  }
                  position="bottom-start"
                  title={`Parametro che definisce la rapidità con cui si è sviluppato il degrado osservato`}
                />
                {cda && overviewDetail?.evoluzioneDegrado >= 0 ? (
                  <GaugeBands
                    chartID={'gauge-bands-evolution'}
                    value={overviewDetail.evoluzioneDegrado}
                  />
                ) : (
                  <Image>
                    <img src={graph2} width={'200px'} />
                  </Image>
                )}
              </LittleContainer>
            </Grid>

            {cda && overviewDetail?.rischioFrana !== 2 ? (
              <Grid item xs={6}>
                <LittleContainer>
                  <Typography variant="h3Bold" sx={{ margin: '2px 24px' }}>
                    {'Misura Mitigazione Frana'}
                  </Typography>
                  {cda && overviewDetail?.misuraMitigazioneFrana >= 0 ? (
                    <GaugeBands
                      chartID={'gauge-bands'}
                      value={overviewDetail.misuraMitigazioneFrana}
                    />
                  ) : (
                    <Image>
                      <img src={graph5} width={'200px'} />
                    </Image>
                  )}
                </LittleContainer>
              </Grid>
            ) : (
              !cda && (
                <Grid item xs={6}>
                  <LittleContainer>
                    <Typography variant="h3Bold" sx={{ margin: '2px 24px' }}>
                      {'Misura Mitigazione Frana'}
                    </Typography>
                    {!cda && (
                      <Image>
                        <img src={graph5} width={'200px'} />
                      </Image>
                    )}
                  </LittleContainer>
                </Grid>
              )
            )}

            <Grid item xs={6}>
              <GuidelinesBox
                title="Alternative stradali"
                status={overviewDetail?.alternativeStradali}
                statusMessage={
                  overviewDetail?.alternativeStradali === 1
                    ? 'Presenti'
                    : 'Non presenti'
                }
              />
            </Grid>
            <Grid item xs={6}>
              <GuidelinesBox
                title="Strategicità opera"
                status={overviewDetail?.itinerarioEmergenza}
                statusMessage={
                  overviewDetail?.itinerarioEmergenza === 1 ? 'Sì' : 'No'
                }
              />
            </Grid>
            <Grid item xs={6}>
              <GuidelinesBox
                title="Categoria sottosuolo"
                status={overviewDetail?.categoriaSottosuoloAltro}
                statusMessage={
                  overviewDetail?.categoriaSottosuoloAltro === 1
                    ? 'Effettiva'
                    : 'Presunta'
                }
              />
            </Grid>
            {cda && overviewDetail?.rischioIdraulico !== 2 ? (
              <Grid item xs={6}>
                <GuidelinesBox
                  title="Affidabilità modello idraulico"
                  status={
                    overviewDetail?.affidabilitaComplessivaValutazioneRischioIdraulico
                  }
                  statusMessage={
                    overviewDetail?.affidabilitaComplessivaValutazioneRischioIdraulico ===
                    1
                      ? 'Buona'
                      : 'Limitata'
                  }
                />
              </Grid>
            ) : (
              !cda && (
                <Grid item xs={6}>
                  <GuidelinesBox
                    title="Affidabilità modello idraulico"
                    status={
                      overviewDetail?.affidabilitaComplessivaValutazioneRischioIdraulico
                    }
                    statusMessage={'n.d.'}
                  />
                </Grid>
              )
            )}
          </Grid>
        </CardOverview>
      </Row>
    </>
  ) : (
    <>
      <Stack alignItems={'center'}>
        <Typography variant="h3">{`Non sono presenti attività all'interno di questa struttura`}</Typography>
        {userPermissionOnStructure === PermissionType.CanEdit &&
          !overviewDetail?.hasInspection && (
            <Button
              onClick={() =>
                setOpenDrawerNewInspection(!openDrawerNewInspection)
              }
              variant="contained"
              color="primary"
              size="small"
              style={{ marginTop: 8 }}
            >
              Compila L0,L1
            </Button>
          )}
      </Stack>
      <DrawerNewInspection
        open={openDrawerNewInspection}
        setOpen={setOpenDrawerNewInspection}
        isOwner={isOwner}
      />
    </>
  );
};

export { TabOverview };

TabOverview.propTypes = {
  setValue: PropTypes.func,
  graphRef: PropTypes.node,
  graphRef2: PropTypes.node,
  structure: PropTypes.object,
  userPermissionOnStructure: PropTypes.oneOf([
    PermissionType.CanView,
    PermissionType.CanEdit,
  ]),
  openDrawerNewInspection: PropTypes.bool,
  setOpenDrawerNewInspection: PropTypes.func,
};
