import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormSubmitButtons, GridWrap, RHFMultiSelect } from 'components';
import { tipologiaSottoserviziOptions } from 'constants/selectOptions';

const SottoserviziForm = ({
  sottoserviziFields,
  selected,
  setSelected,
  sottoserviziTable,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      sottoserviziTableValue: [],
      tipologiaSottoserviziTableValue: '',
    },
  });

  useEffect(() => {
    setValue('sottoserviziTableValue', selected);
  }, [selected]);

  const onSubmitSottoservizi = () => {
    const tipologiaSottoserviziTableValue = getValues(
      'tipologiaSottoserviziTableValue'
    );

    const ids = selected.map(el => el.codice);

    const sottoservizi = [...sottoserviziFields];

    sottoservizi.map((x, index) => {
      if (ids.includes(x?.codice)) {
        sottoservizi[index] = {
          ...x,
          tipologiaSottoservizi: tipologiaSottoserviziTableValue,
        };
      }
    });

    setValue(sottoserviziTable, sottoservizi);
  };

  const onResetSottoservizi = () => {
    setValue('sottoserviziTableValue', []);
    setValue('tipologiaSottoserviziTableValue', '');
    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <GridWrap item xs={3}>
        <RHFMultiSelect
          name="tipologiaSottoserviziTableValue"
          label="Tipologia Sottoservizi"
          defaultValue={[]}
          onChange={e => {
            setValue('tipologiaSottoserviziTableValue', e.target.value);
          }}
          selectOptions={tipologiaSottoserviziOptions}
        />
      </GridWrap>
      <GridWrap item xs={9}>
        <FormSubmitButtons
          onSubmit={onSubmitSottoservizi}
          onReset={onResetSottoservizi}
        />
      </GridWrap>
    </GridWrap>
  );
};
SottoserviziForm.propTypes = {
  sottoserviziFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaSottoservizi: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  sottoserviziTable: PropTypes.string,
};

export default SottoserviziForm;
