import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';

function useCopy() {
  const { getValues, setValue } = useFormContext();

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [copyDrawerOpen, setCopyDrawerOpen] = useState(false);
  const [selectedPaste, setSelectedPaste] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const dataSpallePileConfig = {
    columns: {
      codice: { label: 'Codice' },
      appoggi: { label: 'AP (n°)' },
      giunti: { label: 'G (n°)' },
      pulvini: { label: 'PU (n°)' },
      antenne: { label: 'AN (n°)' },
      dispositiviSismici: { label: 'DS (n°)' },
    },
  };

  const handleSelectAllSpallePilePaste = event => {
    if (event.target.checked) {
      const selectedValue = getValues('spallePileTable').filter(el =>
        checkCompatibility(el)
      );
      setSelectedPaste(selectedValue);
    } else {
      setSelectedPaste([]);
    }
  };

  const checkCompatibility = row => {
    return (
      row.codice != selectedCopy.codice &&
      row.appoggi == selectedCopy.appoggiTable.length &&
      row.giunti == selectedCopy.giuntiTable.length &&
      row.pulvini == selectedCopy.pulviniTable.length &&
      row.antenne == selectedCopy.antenneTable.length &&
      row.dispositiviSismici == selectedCopy.dispositiviSismiciTable.length
    );
  };

  const handleCopy = () => {
    getValues('spallePileCollegamentiTable').forEach((x, spallePileIdx) => {
      if (selectedPaste.some(p => p.codice == x.codice)) {
        const appoggiCopy = selectedCopy.appoggiTable.map((el, idx) => {
          return {
            ...el,
            ...resetObjectSchedaIspezione,
            media: [],
            id: uuidv4(),
            codice: `AP-${x.codice}.${idx + 1}`,
            schedaDifetti: populateSchedaDifetti(
              'appoggio',
              el.tipologiaAppoggio
            ).map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          };
        });
        const giuntiCopy = selectedCopy.giuntiTable.map((el, idx) => {
          return {
            ...el,
            ...resetObjectSchedaIspezione,
            media: [],
            id: uuidv4(),
            codice: `G-${x.codice}.${idx + 1}`,
            schedaDifetti: populateSchedaDifetti(
              'giunto',
              el.tipologiaGiunto
            ).map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          };
        });
        const pulviniCopy = selectedCopy.pulviniTable.map((el, idx) => {
          return {
            ...el,
            ...resetObjectSchedaIspezione,
            media: [],
            id: uuidv4(),
            codice: `PU-${x.codice}.${idx + 1}`,
            schedaDifetti: populateSchedaDifetti(
              'pulvino',
              el.tipologiaPulvino
            ).map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          };
        });

        const antenneCopy = selectedCopy.antenneTable.map((el, idx) => {
          return {
            ...el,
            ...resetObjectSchedaIspezione,
            media: [],
            id: uuidv4(),
            codice: `AN-${x.codice}.${idx + 1}`,
            schedaDifetti: populateSchedaDifetti(
              'antenna',
              el.tipologiaAntenna
            ).map(el => {
              return {
                ...el,
                visto: false,
                statoDifetto: '',
                estensioneDifetto: '',
                intensitaDifetto: '',
                ps: false,
                note: '',
                media: [],
              };
            }),
          };
        });
        const dispositiviSismiciCopy = selectedCopy.dispositiviSismiciTable.map(
          (el, idx) => {
            return {
              ...el,
              id: uuidv4(),
              media: [],
              codice: `DS-${x.codice}.${idx + 1}`,
            };
          }
        );

        const tmp = {
          ...x,
          appoggiTable: appoggiCopy,
          giuntiTable: giuntiCopy,
          pulviniTable: pulviniCopy,
          antenneTable: antenneCopy,
          dispositiviSismiciTable: dispositiviSismiciCopy,
        };
        setValue(`spallePileCollegamentiTable[${spallePileIdx}]`, tmp);
      }
    });
    setCopyDrawerOpen(false);
    return enqueueSnackbar('Elemento copiato correttamente', {
      variant: 'success',
    });
  };

  return {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedPaste,
    setSelectedPaste,
    selectedCopy,
    setSelectedCopy,
    dataSpallePileConfig,
    handleSelectAllSpallePilePaste,
    checkCompatibility,
    handleCopy,
  };
}
export default useCopy;
