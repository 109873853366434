import { lazy, Suspense, useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';

import { LoadingScreen } from 'components';
import { InspectionRouteActions } from 'constants/inspections';
import {
  ADMIN_ROOT,
  ANALYTICS_ROOT,
  AUTH_PATHS,
  COLLABORATORS_PATHS,
  DOCUMENTS_PATHS,
  HOME_BO,
  IMAGES_PATHS,
  INFO_ASSISTANCE_PATHS,
  INSPECTIONS_PATHS,
  MY_AREA,
  NOTIFICATION_ROOT,
  PROFILE_AND_SUBSCRIPTION,
  REGISTRATION_PATH,
  ROOT_PATH,
  STRUCTURES_PATHS,
  USER_PATHS,
  USER_ROOT,
} from 'constants/paths';
import { UserType } from 'constants/users';
import { AuthGuard, GuestGuard } from 'guards';
import { LoginLayout, RegistrationLayout, StandardLayout } from 'layouts';
import UsersList from 'pages/Admin/Admin/Admin';
import Analytics from 'pages/Admin/Analytics/Analytics';
import Create from 'pages/Admin/Create/Create';
import Dashboard from 'pages/Admin/Dashboard/Dashboard';
import Edit from 'pages/Admin/Edit/Edit';
import CollaboratorsList from 'pages/Collaborators/CollaboratorsList/CollaboratorsList';
import CreateCollaborator from 'pages/Collaborators/CreateCollaborator/CreateCollaborator';
import FAQ from 'pages/InfoAssistance/FAQ/FAQ';
import GuideApprofondimenti from 'pages/InfoAssistance/GuideApprofondimenti/GuideApprofondimenti';
import InfoAssistance from 'pages/InfoAssistance/InfoAssistance';
import VideoTutorial from 'pages/InfoAssistance/VideoTutorial/VideoTutorial';
import CreateInspection from 'pages/Inspections/CreateInspection/CreateInspection';
import MyArea from 'pages/MyArea/MyArea';
import Notifications from 'pages/Notifications/Notifications';
import ProfileAndSubscription from 'pages/ProfileAndSubscription/ProfileAndSubscription';
import RegistrationForm from 'pages/RegistrationForm/RegistrationForm';
import DuplicateStructure from 'pages/Structures/DuplicateStructure/DuplicateStructure';
import StructureDetail from 'pages/Structures/StructureDetail/StructureDetail';
import { useAuthSelector, useGlobalStore } from 'stores';
import { VoiceMenuAdminMap, VoiceMenuMap } from './voiceMenuMap';

// eslint-disable-next-line react/display-name
const Loadable = Component => props => {
  // const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

//per entrare nei children serve mettere Outlet dentro l'element padre
export default function Router() {
  const { user } = useAuthSelector();
  const userType = user?.type;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location?.pathname.split('/')[1];

    useGlobalStore.setState(() => ({
      sidebarVoiceMenu: VoiceMenuMap[path] || 1,
      siderAdminVoiceMenu: VoiceMenuAdminMap[path] || 1,
    }));
  }, [location]);

  useEffect(() => {
    if (
      location.pathname === ROOT_PATH ||
      location.pathname === HOME_BO ||
      location.pathname === ADMIN_ROOT
    ) {
      if (userType === 'ADMIN') {
        navigate(ADMIN_ROOT);
      } else {
        navigate(HOME_BO);
      }
    }
  }, [userType]);

  /* useEffect(() => {
    const path = location?.pathname.split('/')[1];
    useGlobalStore.setState(() => ({
      sidebarVoiceMenu: VoiceMenuMap[path] || 1,
    }));
  }, [location]); */

  return useRoutes([
    {
      path: ROOT_PATH,
      element: (
        <AuthGuard>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <HomePage />, index: true },
        // { path: '/home/components/', element: <Home /> },
      ],
    },
    {
      path: HOME_BO,
      element: (
        <AuthGuard>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <HomePage />, index: true },
        // { path: '/home/components/', element: <Home /> },
      ],
    },
    {
      path: '/home/components/',
      element: (
        <AuthGuard>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [
        // { element: <Navigate to={'/home/components/'} /> },
        { element: <Home />, index: true },
      ],
    },
    {
      path: STRUCTURES_PATHS.STRUCTURES_LIST,
      element: (
        <AuthGuard roles={[UserType.Ente, UserType.Professionista]}>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <StructuresList />, index: true },
        {
          path: STRUCTURES_PATHS.STRUCTURE_DETAIL,
          element: <StructureDetail />,
        },
        {
          path: STRUCTURES_PATHS.DUPLICATE_STRUCTURE,
          element: <DuplicateStructure />,
        },
        { path: STRUCTURES_PATHS.STRUCTURES_NEW, element: <CreateStructure /> },
        {
          path: STRUCTURES_PATHS.INSPECTION_DETAIL,
          element: <CreateInspection action={InspectionRouteActions.VIEW} />,
        },
        {
          path: STRUCTURES_PATHS.INSPECTION_NEW,
          element: <CreateInspection action={InspectionRouteActions.CREATE} />,
        },
        {
          path: STRUCTURES_PATHS.INSPECTION_EDIT,
          element: <CreateInspection action={InspectionRouteActions.EDIT} />,
        },
        {
          path: STRUCTURES_PATHS.INSPECTION_VIEW,
          element: <CreateInspection action={InspectionRouteActions.VIEW} />,
        },
        {
          path: STRUCTURES_PATHS.STRUCTURE_IMAGES,
          element: <StructureImages />,
        },
        {
          path: STRUCTURES_PATHS.INSPECTIONS_ARCHIVE,
          element: <InspectionsArchive />,
        },
        {
          path: STRUCTURES_PATHS.STRUCTURE_DOCUMENTS,
          element: <StructureDocuments />,
        },
        // TODO add structure image list
      ],
    },

    {
      path: INSPECTIONS_PATHS.INSPECTIONS_LIST,
      element: (
        <AuthGuard roles={[UserType.Ente, UserType.Professionista]}>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <InspectionsList />,
          index: true,
        },
      ],
    },
    {
      path: IMAGES_PATHS.IMAGES_LIST,
      element: (
        <AuthGuard roles={[UserType.Ente, UserType.Professionista]}>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [{ element: <ImagesList />, index: true }],
    },
    {
      path: MY_AREA,
      element: (
        <AuthGuard>
          <StandardLayout isInfoAssistance={true} />
        </AuthGuard>
      ),
      children: [{ element: <MyArea />, index: true }],
    },
    {
      path: PROFILE_AND_SUBSCRIPTION,
      element: (
        <AuthGuard>
          <StandardLayout isInfoAssistance={true} />
        </AuthGuard>
      ),
      children: [{ element: <ProfileAndSubscription />, index: true }],
    },
    {
      path: NOTIFICATION_ROOT,
      element: (
        <AuthGuard>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Notifications />, index: true }],
    },
    {
      path: DOCUMENTS_PATHS.DOCUMENTS_LIST,
      element: (
        <AuthGuard roles={[UserType.Ente, UserType.Professionista]}>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [{ element: <DocumentsList />, index: true }],
    },

    {
      path: INFO_ASSISTANCE_PATHS.ROOT,
      element: (
        <AuthGuard>
          <StandardLayout isInfoAssistance={true} />
        </AuthGuard>
      ),
      children: [
        { element: <InfoAssistance />, index: true },
        {
          path: INFO_ASSISTANCE_PATHS.GUIDES,
          element: <GuideApprofondimenti />,
        },
        {
          path: INFO_ASSISTANCE_PATHS.VIDEOS,
          element: <VideoTutorial />,
        },
        {
          path: INFO_ASSISTANCE_PATHS.FAQ,
          element: <FAQ />,
        },
      ],
    },
    {
      path: COLLABORATORS_PATHS.ROOT,
      element: (
        <AuthGuard roles={[UserType.Ente, UserType.Professionista]}>
          <StandardLayout isInfoAssistance={true} />
        </AuthGuard>
      ),
      children: [
        { element: <CollaboratorsList />, index: true },
        {
          path: COLLABORATORS_PATHS.NEW_INTERNAL_COLLBAORATOR,
          element: <CreateCollaborator type="internal" />,
        },
        {
          path: COLLABORATORS_PATHS.NEW_EXTERNAL_COLLBAORATOR,
          element: <CreateCollaborator type="external" />,
        },
        {
          path: COLLABORATORS_PATHS.EDIT_INTERNAL_COLLBAORATOR,
          element: <CreateCollaborator type="internal" />,
        },
        {
          path: COLLABORATORS_PATHS.EDIT_EXTERNAL_COLLBAORATOR,
          element: <CreateCollaborator type="external" />,
        },
      ],
    },

    {
      path: USER_ROOT,
      element: (
        <AuthGuard roles={[UserType.Admin]}>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <UsersList />, index: true },
        { path: USER_PATHS.CREATE, element: <Create /> },
        { path: USER_PATHS.EDIT, element: <Edit /> },
        /* { path: ADMIN_PATHS.CREATE, element: <Create /> },
        { path: ADMIN_PATHS.EDIT, element: <Edit /> }, */
      ],
    },
    {
      path: ADMIN_ROOT,
      element: (
        <AuthGuard roles={[UserType.Admin]}>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Dashboard />, index: true }],
    },
    {
      path: ANALYTICS_ROOT,
      element: (
        <AuthGuard roles={[UserType.Admin]}>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Analytics />, index: true }],
    },
    {
      path: AUTH_PATHS.ROOT,
      element: (
        <GuestGuard>
          <LoginLayout />
        </GuestGuard>
      ),
      children: [
        // { element: <Navigate to={AUTH_PATHS.LOGIN} replace />, index: true },
        { path: AUTH_PATHS.LOGIN, element: <Login /> },
        { path: AUTH_PATHS.RESET, element: <Reset /> },
        { path: AUTH_PATHS.NEWPASSWORD, element: <NewPassword /> },
        { path: AUTH_PATHS.PASSWORDUPDATED, element: <PasswordUpdated /> },
        { path: AUTH_PATHS.EMAILSENDED, element: <EmailSended /> },
      ],
    },
    {
      path: REGISTRATION_PATH.ROOT,
      element: <RegistrationLayout />,
      children: [
        {
          element: <RegistrationForm />,
          index: true,
        },
      ],
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <NotFoundPage />, index: true }],
    },
  ]);
}

// Dashboard
// const TestForm = Loadable(lazy(() => import('../pages/TestForm/TestForm')));
// const TestForm2 = Loadable(lazy(() => import('../pages/TestForm2/TestForm2')));
const Login = Loadable(lazy(() => import('../pages/Auth/Login/Login')));
const Reset = Loadable(lazy(() => import('../pages/Auth/Reset/Reset')));
const EmailSended = Loadable(
  lazy(() => import('../pages/Auth/EmailSended/EmailSended'))
);
const PasswordUpdated = Loadable(
  lazy(() => import('../pages/Auth/PasswordUpdated/PasswordUpdated'))
);
const NewPassword = Loadable(
  lazy(() => import('../pages/Auth/NewPassword/NewPassword'))
);

const HomePage = Loadable(lazy(() => import('../pages/HomePage/HomePage')));
const Home = Loadable(lazy(() => import('../pages/Home/Home')));
const StructuresList = Loadable(
  lazy(() => import('../pages/Structures/StructuresList/StructuresList'))
);
const StructureImages = Loadable(
  lazy(() =>
    import(
      '../pages/Structures/StructureDetail/StructureImages/StructureImages'
    )
  )
);

const StructureDocuments = Loadable(
  lazy(() => import('../pages/Structures/StructureDetail/StructureDocuments'))
);

const CreateStructure = Loadable(
  lazy(() => import('../pages/Structures/CreateStructure/CreateStructure'))
);

const InspectionsList = Loadable(
  lazy(() => import('../pages/Inspections/InspectionsList/InspectionsList'))
);
const ImagesList = Loadable(
  lazy(() => import('../pages/Images/ImagesList/ImagesList'))
);
const DocumentsList = Loadable(
  lazy(() => import('../pages/Documents/DocumentsList/DocumentsList'))
);
const NotFoundPage = Loadable(lazy(() => import('../pages/NotFound/NotFound')));
const InspectionsArchive = Loadable(
  lazy(() =>
    import('../pages/Structures/InspectionsArchive/InspectionsArchive')
  )
);
