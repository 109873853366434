export const resetObjectSchedaIspezione = {
  Nrel321: null,
  g5totali: null,
  nrtotali: null,
  pstotali: null,
  CDAsismica: null,
  summationG: null,
  noteGenerali: null,
  dataIspezione: null,
  summationG123: null,
  CDAstrutturale: null,
  summationk2G45: null,
  summationk2G123: null,
  indiceCondizione: null,
  summationk2G12345: null,
  tecnicoRilevatore: null,
  CDAsismicacomputed: null,
  CDAstrutturalecomputed: null,
  elementoCriticoSismica: null,
  condizioneCriticaSismica: null,
  elementoCriticoStruttura: null,
  completamentoSchedaDifetti: null,
  condizioneCriticaStruttura: null,
};
export const resetObjectScheda20Ispezione = {
  nrtotali: null,
  noteGenerali: null,
  dataIspezione: null,
  tecnicoRilevatore: null,
  completamentoSchedaDifetti: null,
};
