import { PropTypes } from 'prop-types';
import { cardKpiValue } from 'constants/colors';
import { CardKpi as Card, CardKpiContainer } from './CardKpi.style';

export const CardKpi = ({ value, title, description }) => {
  let status = '';

  if (value > 0) {
    status = 'positive';
  }

  if (value === 0) {
    status = 'neutro';
  }

  if (value < 0) {
    status = 'negative';
  }

  return (
    <CardKpiContainer>
      <Card color={cardKpiValue[status]}>
        <h3>{title}</h3>
        <h1>{value}</h1>
        <span>
          <img src={cardKpiValue[status].labelIcon} alt="" />
          <b>{cardKpiValue[status].labelText}</b>
        </span>
        <p>{description}</p>
      </Card>
    </CardKpiContainer>
  );
};

CardKpi.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
};
