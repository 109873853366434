import { Divider } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  InfoWrapper,
  MediaUpload,
  RHFMultiSelect,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import {
  ElCriticiVulnerabilitStrutturaleFondazionale,
  FieldType,
  MediaGroup,
  UploadMedia,
} from 'constants/inspections';
import { ElementiCriticiStatoDegradoOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { INFO } from '../../../../../config/info';
import { GridWrap } from '../Accordion5CriticalAspectsL1.styles';

const ElCriticiVulnerabilitaStrutturale = () => {
  const { watch, control, setValue } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchElementiCriticiStatoDegrado = watch(
    'elCriticiVulnerabilitaStrutturaleStatoDegrado'
  );

  const watchElementiCriticiVulnerabilitaStrutturaleFondazionale = watch(
    'elementiCriticiVulnerabilitaStrutturaleFondazionale'
  );
  const watchElementiCriticiVulnerabilitaStrutturaleFondazionalePresenti =
    watchElementiCriticiVulnerabilitaStrutturaleFondazionale ===
    ElCriticiVulnerabilitStrutturaleFondazionale.Presenti;

  const { remove: removeSelected } = useFieldArray({
    control,
    name: 'elCriticiVulnerabilitaStrutturaleStatoDegrado',
  });

  useEffect(() => {
    if (watchElementiCriticiVulnerabilitaStrutturaleFondazionalePresenti) {
      if (
        watchElementiCriticiStatoDegrado?.length &&
        watchElementiCriticiStatoDegrado?.includes(3)
      ) {
        if (watchElementiCriticiStatoDegrado?.length > 1) {
          watchElementiCriticiStatoDegrado.sort();
          removeSelected(0);
        }
      }
    }

    if (
      !watchElementiCriticiVulnerabilitaStrutturaleFondazionalePresenti &&
      watchElementiCriticiStatoDegrado?.length
    ) {
      removeSelected();
    }
  }, [
    watchElementiCriticiStatoDegrado,
    watchElementiCriticiVulnerabilitaStrutturaleFondazionale,
  ]);

  useEffect(() => {
    if (!watchElementiCriticiVulnerabilitaStrutturaleFondazionalePresenti) {
      setValue('elCriticiVulnerabilitaStrutturaleTipologiaElemento', '');
      setValue('elCriticiVulnerabilitaStrutturaleDescrizioneSintetica', '');
    }
  }, [watchElementiCriticiVulnerabilitaStrutturaleFondazionale]);

  return (
    <GridWrap container spacing={2} padding="20px" marginTop={-40}>
      <InfoWrapper
        isDivider={true}
        size={12}
        compassMessage={
          INFO.elementiCriticiVulnerabilitaStrutturale.compassMessage
        }
        infoDrawer={true}
        drawerTitle={INFO.elementiCriticiVulnerabilitaStrutturale.drawerTitle}
        drawerText={INFO.elementiCriticiVulnerabilitaStrutturale.drawerText}
      >
        <Divider>
          Elementi critici - vulnerabilità strutturale e fondazionale
        </Divider>
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="elementiCriticiVulnerabilitaStrutturaleFondazionale"
          label={'Elementi critici*'}
          defaultValue={''}
          selectOptions={[
            {
              label: 'Presenti',
              value: ElCriticiVulnerabilitStrutturaleFondazionale.Presenti,
            },
            {
              label: 'Assenti',
              value: ElCriticiVulnerabilitStrutturaleFondazionale.Assenti,
            },
          ]}
          disabled
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFMultiSelect
          name="elCriticiVulnerabilitaStrutturaleStatoDegrado"
          label={'Stato di degrado'}
          defaultValue={[]}
          selectOptions={ElementiCriticiStatoDegradoOptions}
          disabled={
            !watchElementiCriticiVulnerabilitaStrutturaleFondazionalePresenti ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-elCriticiStruttura"
          name={'elCriticiVulnerabilitaStrutturaleTipologiaElemento'}
          label="Tipologia elemento"
          disabled
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="elCriticiVulnerabilitaStrutturaleDescrizioneSintetica"
          label={'Descrizione sintetica'}
          inputProps={{ maxLength: 50 }}
          defaultValue=""
          disabled={
            !watchElementiCriticiVulnerabilitaStrutturaleFondazionalePresenti ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <MediaUpload
          disabled={
            !watchElementiCriticiVulnerabilitaStrutturaleFondazionalePresenti ||
            isViewingInspection
          }
          title={`Foto`}
          btnTitle="Allega file"
          formName="elCriticiVulnerabilitaStrutturaleFoto"
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          dataType="video/*,image/*"
          preview={true}
          uploadType={UploadMedia.media}
          type={FieldType.images}
          mediaGroup={[MediaGroup.Struttura]}
          tags={[
            'Spalle',
            'Pile',
            'Appoggi',
            'Giunti',
            'Pulvini',
            'Antenne',
            'Altri dispositivi antisismici',
            'Soletta',
            'Travi',
            'Traversi',
            'Controventi',
            'Archi',
            'Piedritti',
            'Tiranti',
            'Elementi extra',
            'selle Gerber',
            'appoggi',
            'cavi di precompressione',
            'fondazioni scalzate',
          ]}
        />
      </GridWrap>
    </GridWrap>
  );
};

export default ElCriticiVulnerabilitaStrutturale;
