import acciaioImg from 'assets/icons/materials/acciaio.png';
import acciaioTeflonImg from 'assets/icons/materials/acciaioTeflon.png';
import asfaltoImg from 'assets/icons/materials/asfalto.png';
import cementoArmatoImg from 'assets/icons/materials/cemento-armato.png';
import cementoImg from 'assets/icons/materials/cemento.png';
import cementoArmatoPrecompressoImg from 'assets/icons/materials/cementoArmatoPrecompresso.png';
import gommaImg from 'assets/icons/materials/gomma.png';
import gommaArmataImg from 'assets/icons/materials/gommaArmata.png';
import gommaTeflonImg from 'assets/icons/materials/gommaTeflon.png';
import legnoImg from 'assets/icons/materials/legno.png';
import muraturaImg from 'assets/icons/materials/muratura.png';
import nessunDifettoImg from 'assets/icons/materials/nessunDifetto.svg';
import nonPresenteImg from 'assets/icons/materials/nonPresente.png';
import nonRilevabileImg from 'assets/icons/materials/nonRilevabile.png';
import pettiniImg from 'assets/icons/materials/pettini.png';
import plasticaImg from 'assets/icons/materials/plastica.png';
import profilatiElastomericiImg from 'assets/icons/materials/profilatiElastomerici.png';
import retePavimentazioneImg from 'assets/icons/materials/retePavimentazione.png';
import sottopavimentazioneImg from 'assets/icons/materials/sottopavimentazione.png';
import strisceGommaImg from 'assets/icons/materials/strisceGomma.png';

export const handleMaterialId = (enumOptions, id) => {
  return enumOptions[id - 1]?.label;
};

export const materials = {
  cementoArmato: cementoArmatoImg,
  muratura: muraturaImg,
  gomma: gommaImg,
  gommaArmata: gommaArmataImg,
  gommaTeflon: gommaTeflonImg,
  acciaio: acciaioImg,
  acciaioTeflon: acciaioTeflonImg,
  cemento: cementoImg,
  cementoArmatoPrecompresso: cementoArmatoPrecompressoImg,
  retePavimentazione: retePavimentazioneImg,
  profilatiElastomerici: profilatiElastomericiImg,
  strisceInGomma: strisceGommaImg,
  pettini: pettiniImg,
  sottopavimentazione: sottopavimentazioneImg,
  legno: legnoImg,
  plastica: plasticaImg,
  asfalto: asfaltoImg,
  nonRilevabile: nonRilevabileImg,
  nonPresente: nonPresenteImg,
  nessunDifetto: nessunDifettoImg,
};

export const renderMaterialImg = item => {
  let materialImg;
  switch (item) {
    case 'Cemento armato':
      materialImg = materials.cementoArmato;
      break;
    case 'Muratura':
      materialImg = materials.muratura;
      break;
    case 'Gomma':
      materialImg = materials.gomma;
      break;
    case 'Gomma armata':
      materialImg = materials.gommaArmata;
      break;
    case 'Gomma e teflon':
      materialImg = materials.gommaTeflon;
      break;
    case 'Acciaio':
      materialImg = materials.acciaio;
      break;
    case 'Ghisa':
      materialImg = materials.acciaio;
      break;
    case 'Metallo':
      materialImg = materials.acciaio;
      break;
    case 'Metallico':
      materialImg = materials.acciaio;
      break;
    case 'Acciaio o metallo':
      materialImg = materials.acciaio;
      break;
    case 'Cemento':
      materialImg = materials.cemento;
      break;
    case 'Calcestruzzo':
      materialImg = materials.cemento;
      break;
    case 'Calcestruzzo armato':
      materialImg = materials.cemento;
      break;
    case 'Calcestruzzo armato precompresso':
      materialImg = materials.cemento;
      break;
    case 'Asfalto':
      materialImg = materials.asfalto;
      break;
    case 'Guard rail':
      materialImg = materials.asfalto;
      break;
    case 'New Jersey':
      materialImg = materials.asfalto;
      break;
    case 'Plastica':
      materialImg = materials.plastica;
      break;
    case 'Acciaio e teflon':
      materialImg = materials.acciaioTeflon;
      break;
    case 'Rete nella pavimentazione':
      materialImg = materials.retePavimentazione;
      break;
    case 'Profilati elastomerici':
      materialImg = materials.profilatiElastomerici;
      break;
    case 'Strisce in gomma':
      materialImg = materials.strisceInGomma;
      break;
    case 'Pettini':
      materialImg = materials.pettini;
      break;
    case 'Sottopavimentazione':
      materialImg = materials.sottopavimentazione;
      break;
    case 'Legno':
      materialImg = materials.legno;
      break;
    case 'Cem. armato prec.sso':
      materialImg = materials.cementoArmatoPrecompresso;
      break;
    case 'Non previsto':
      materialImg = materials.nonRilevabile;
      break;
    case 'Non rilevabile':
      materialImg = materials.nonRilevabile;
      break;
    case 'Non presente':
      materialImg = materials.nonPresente;
      break;
    case 'Assente':
      materialImg = materials.nonPresente;
      break;
    case 'Difetto':
      materialImg = materials.nonPresente;
      break;
    case 'Nessun difetto':
      materialImg = materials.nessunDifetto;
      break;
  }
  return <img src={materialImg} />;
};
