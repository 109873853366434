export const UserType = {
  Admin: 'ADMIN',
  Ente: 'ENTE',
  Professionista: 'PROFESSIONISTA',
};

export const UserTypeMap = {
  [UserType.Admin]: 'Admin',
  [UserType.Ente]: 'Ente',
  [UserType.Professionista]: 'Ispettore',
};

export const UserTypeOptions = [
  {
    label: 'Admin',
    value: UserType.Admin,
  },
  {
    label: 'Ente',
    value: UserType.Ente,
  },
  {
    label: 'Ispettore',
    value: UserType.Professionista,
  },
];

export const UserTypology = {
  UtentePrincipale: 1,
  CollaboratoreInterno: 2,
};

export const UserTypologyOptions = [
  {
    label: 'Utente Principale',
    value: UserTypology.UtentePrincipale,
  },
  {
    label: 'Collaboratore Interno',
    value: UserTypology.CollaboratoreInterno,
  },
];

export const UserTypologyMap = {
  [UserTypology.UtentePrincipale]: 'Utente Principale',
  [UserTypology.CollaboratoreInterno]: 'Collaboratore Interno',
};

export const UserStatus = {
  Invitato: 1,
  Registrato: 2,
  Attivo: 3,
};

export const UserStatusMap = {
  [UserStatus.Invitato]: 'Invitato',
  [UserStatus.Registrato]: 'Registrato',
  [UserStatus.Attivo]: 'Attivo',
};
export const UserStatusOptions = [
  { label: 'Invitato', value: UserStatus.Invitato },
  { label: 'Registrato', value: UserStatus.Registrato },
  { lable: 'Attivo', value: UserStatus.Attivo },
];

export const StructureCdA = {
  Alta: 1,
  MedioAlta: 2,
  Media: 3,
  MedioBassa: 4,
  Bassa: 5,
  ND: 6,
};

export const UserDeletionStatus = {
  NotInDeletion: 1,
  InDeletion: 2,
  Deleted: 3,
};

export const StructureCdAMap = {
  [StructureCdA.Alta]: 'Alta',
  [StructureCdA.MedioAlta]: 'Medio-Alta',
  [StructureCdA.Media]: 'Medio',
  [StructureCdA.MedioBassa]: 'Medio-Bassa',
  [StructureCdA.Bassa]: 'Bassa',
  [StructureCdA.ND]: 'N.D.',
};

export const CollaboratorsListActionsMap = {
  DELETE: 'delete',
};

export const CollaboratorsActionsWarningMessageMap = {
  [CollaboratorsListActionsMap.DELETE]:
    'Sei sicuro di voler eliminare questo collaboratore?',
};

export const UsersListActionsMap = {
  DELETE: 'delete',
};

export const UserActionsWarningMessageMap = {
  [CollaboratorsListActionsMap.DELETE]: `Attenzione! Una volta confermata la tua richiesta di eliminazione dell’account INBEE il tuo account sarà disattivato. Avrai 30 giorni di tempo per rieffettuare l’accesso e revocare la tua richiesta di eliminazione. Al termine dei 30 giorni il tuo account sarà definitivamente eliminato e perderai l’accesso ai tuoi ponti censiti. Vuoi davvero procedere con l’eliminazione?`,
};
