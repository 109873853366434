import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';

import { Table } from 'components';
import { DrawerMedia } from 'components/DrawerMedia/DrawerMedia';
import {
  convogliamentoAcque,
  FieldType,
  MediaGroup,
  UploadMedia,
} from 'constants/inspections';

import {
  convogliamentoAcqueOptions,
  tipologiaConvogliamentoAcqueOptions,
} from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { GridWrap } from './Accordion2WaterConveyance.styles';
import { WaterConveyanceForm } from './WaterConveyanceForm/WaterConveyanceForm';

const Accordion2WaterConveyance = () => {
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();
  //State
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const { watch, setValue } = useFormContext();

  const watchConvogliamentoAcqueTable = watch('convogliamentoAcqueTable');

  const dataConfig = {
    columns: {
      convogliamentoAcque: {
        label: 'Convogliamento acque',
        format: convogliamentoAcqueOptions,
      },
      tipologiaConvogliamentoAcque: {
        label: 'Tipologia convogliamento acque',
        format: tipologiaConvogliamentoAcqueOptions,
      },
      tipologiaAltro: { label: 'Altro' },
    },
  };

  const editRows = item => {
    setValue('convogliamentoAcqueTableValue', item.convogliamentoAcque);
    setValue(
      'tipologiaConvogliamentoAcqueTableValue',
      item.tipologiaConvogliamentoAcque
    );
    setValue('convogliamentoAcqueAltro', item.tipologiaAltro);
  };

  return (
    <>
      <GridWrap container spacing={2} padding="20px" marginTop={-40}>
        {!inspectionDisabled && !isViewingInspection && (
          <WaterConveyanceForm waterFields={watchConvogliamentoAcqueTable} />
        )}
        <GridWrap item xs={12}>
          <Table
            data={watchConvogliamentoAcqueTable || []}
            config={dataConfig}
            rowActions={
              !inspectionDisabled && !isViewingInspection
                ? [
                    {
                      onClick: item => {
                        editRows(item);
                        setSelected([item]);
                      },
                      icon: <EditIcon />,
                    },
                  ]
                : []
            }
            mediaActions={[
              {
                onClick: item => {
                  setDrawerMediaIndex(
                    watchConvogliamentoAcqueTable.findIndex(
                      el => el.id === item.id
                    )
                  );
                  setOpen(true);
                },
                icon: <MediaIcon />,
              },
            ]}
            selected={selected}
            setSelected={setSelected}
          />
        </GridWrap>

        <DrawerMedia
          open={open}
          setOpen={setOpen}
          title={'Convogliamento acque'}
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          dataType="video/*,image/*"
          uploadType={UploadMedia.media}
          type={FieldType.images}
          formName={`convogliamentoAcqueTable[${drawerMediaIndex}].media`}
          mediaGroup={[MediaGroup.Servizi]}
        />
      </GridWrap>
    </>
  );
};

export { Accordion2WaterConveyance };
