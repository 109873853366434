export const NotificationType = {
  InspectionConfirmedByInspector: 1, // ISPEZIONE CONFERMATA DA ISPETTORE
  InspectionAccessExpiration: 2, // SCADENZA DI EXPORT PER ISPEZIONI IN CHIUSURA
  InspectionInviteFromUser: 3, // AVVISO CHE UN UTENTE HA INVITATO A ISPEZIONARE
  SubscriptionExpiration: 4, // SCADENZA SOTTOSCRIZIONE
  NextInspectionExpiration: 5, // ALERT SCADENZA ISPEZIONI IN BASE ALLE NORME
  InspectionNotArchived: 6, // ISPEZIONE APERTA O CONFERMATA E NON ARCHIVIATA DOPO TOT TEMPO
  /**
   * 3A
   * Si effettua una consegna di ispezione
   * Inviata a utenti ispezione in can edit e ponte in can edit o can view. Escluso ente bridge owner
   */
  InspectionClosure: 7,
  /**
   * 3B
   * Ispezione entra in stato delivered, ente già registrato.
   * Inviata all'ente destinatario della consegna.
   */
  DeliverInspectionToRegisteredInstitution: 8,
  /**
   * 3D
   * L'ispezione entra nello stato delivered.
   * Inviata al proprietario dell'ispezione
   */
  DeliveredInspection: 9,
  BridgeInviteFromUser: 10,
};
