import PropTypes from 'prop-types';
import { FormProvider as Form } from 'react-hook-form';
import { CHILDREN_TYPE } from 'utils/types';

const FormProvider = ({ children, onSubmit, methods }) => (
  <Form {...methods}>
    <form onSubmit={onSubmit} noValidate>
      {children}
    </form>
  </Form>
);

FormProvider.propTypes = {
  children: CHILDREN_TYPE,
  onSubmit: PropTypes.func,
  methods: PropTypes.object,
};

export { FormProvider };
