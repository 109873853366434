import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import { colors } from 'constants/colors';
import { MessageContainer } from './Chart.styles';

export const HorizontalBarChart = ({
  chartID,
  data,
  config,
  hasLegend,
  hasTooltip,
  error,
}) => {
  useLayoutEffect(() => {
    if (!data) return;
    const root = am5.Root.new(chartID);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: false,
        wheelY: false,
        layout: root.verticalLayout,
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const yRenderer = am5xy.AxisRendererY.new(root, {
      inversed: true,
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
    });

    yRenderer.labels.template.setAll({
      fontSize: 14,
      oversizedBehavior: 'wrap',
      maxWidth: 130,
      textAlign: 'end',
    });

    const clusterLabel = 'label';
    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: clusterLabel,
        renderer: yRenderer,
      })
    );

    yAxis.data.setAll(data);

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
        }),
        min: 0,
      })
    );

    xAxis.hide();

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name) {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: field,
          categoryYField: clusterLabel,
          sequencedInterpolation: true,
          fill: am5.color(colors.SPINDLE),
          ...(hasTooltip && {
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: 'horizontal',
              labelText: '[bold]{categoryY}[/]\n {valueString}',
            }),
          }),
        })
      );

      // Handle colors
      const colorsConfig = Object.values(config)?.map(c => am5.color(c.color));
      config && chart.get('colors').set('colors', colorsConfig);

      config &&
        series.columns.template.adapters.add('fill', function (fill, target) {
          return chart.get('colors').getIndex(series.columns.indexOf(target));
        });

      series.columns.template.setAll({
        height: am5.percent(80),
        strokeOpacity: 0,
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 1,
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            /* centerX: am5.p100, */
            text: '{valueX}%',
            populateText: true,
          }),
        });
      });
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 1,
          sprite: am5.Label.new(root, {
            text: '[bold]{valueString}[/]',
            populateText: true,
          }),
        });
      });

      /* series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerX: am5.p100,
            centerY: am5.p50,
            text: '{name}',
            fill: am5.color(0xffffff),
            populateText: true,
          }),
        });
      }); */

      series.data.setAll(data);
      series.appear();

      return series;
    }

    createSeries('percentage');

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    hasLegend && legend.data.setAll(chart.series.values);

    // Add cursor
    var cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomY',
      })
    );
    cursor.lineY.set('forceHidden', true);
    cursor.lineX.set('forceHidden', true);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
    return () => {
      chart.dispose();
    };
  }, [chartID, data]);

  return (
    <div style={{ width: '100%', height: '460px' }} id={chartID}>
      {error && (
        <MessageContainer>
          <Typography variant="h3Bold">
            {`Si è verificato un errore. Impossibile visualizzare il grafico.`}
          </Typography>
        </MessageContainer>
      )}
    </div>
  );
};

const configShape = {
  label: PropTypes.string,
  color: PropTypes.string,
};

HorizontalBarChart.propTypes = {
  chartID: PropTypes.string,
  data: PropTypes.object,
  config: PropTypes.shape(configShape),
  hasLegend: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  error: PropTypes.bool,
};
