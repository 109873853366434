import { Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import graph3 from 'assets/icons/graphs/graph3.svg';
import { CardOverview, DrawerNewInspection, ImageCarousel } from 'components';
import { InspectionStatus } from 'constants/inspections';
import { IMAGE_CAROUSEL_MAX_IMAGES } from 'constants/media';
import { PermissionType } from 'constants/permissions';
import { useSubscription } from 'hooks';
import usePromises from 'hooks/usePromises';
import { useGlobalSelector } from 'stores';
import { Contesto } from './sections/Contesto';
import { Servizi } from './sections/Servizi';
import { Sovrastruttura } from './sections/Sovrastruttura';
import { Struttura } from './sections/Struttura';
import { Row } from './TabL0.styles';

const TabL0 = ({
  structure,
  userPermissionOnStructure,
  openDrawerNewInspection,
  setOpenDrawerNewInspection,
}) => {
  const { user } = useSubscription();
  const { setPromises } = usePromises();
  const params = useParams();
  const id = params.structureId;
  const inspectionId = params?.inspectionId;
  const { get } = useFetch();
  const { showLoader, hideLoader } = useGlobalSelector();
  const [overviewL0, setOverviewL0] = useState();
  const [imagesList, setImagesList] = useState();
  const [open, setOpen] = useState(false);

  const isOwner =
    structure?.ownerId === user?.id || structure?.ownerId === user?.parent?.id;

  const renderVideo = item => {
    return (
      <div>
        <video controls style={{ width: '400px', height: '130px' }}>
          <source src={item.original}></source>
        </video>
      </div>
    );
  };

  useEffect(() => {
    const handleImages = async () => {
      const resultMedia = await get(`bridges/${id}/overviewImages?level=L0`);

      if (resultMedia?.length > 0) {
        const images = [];
        resultMedia?.map(x => {
          images.push({
            original: x.url,
            originalHeight: '130px',
            renderItem: x.isVideo ? renderVideo : null,
          });
        });

        setImagesList(images);
      }
    };

    const handleL0Data = async () => {
      try {
        showLoader();
        const result = await get(
          inspectionId
            ? `bridges/${id}/overview/L0?inspectionId=${inspectionId}`
            : `bridges/${id}/overview/L0`
        );
        result && setOverviewL0(result);
      } catch (err) {
        console.error('Error in put L0 Data Recap: ', err);
      } finally {
        hideLoader();
      }
    };
    setPromises([handleImages(), handleL0Data()]);
  }, []);

  const imagesWeb = [
    {
      original: graph3,
      originalHeight: '130px',
    },
    {
      original: graph3,
      originalHeight: '130px',
    },
    {
      original: graph3,
      originalHeight: '130px',
    },
    {
      original: graph3,
      originalHeight: '130px',
    },
    {
      original: graph3,
      originalHeight: '130px',
    },
    {
      original: graph3,
      originalHeight: '130px',
    },
  ];

  return structure?.status !== InspectionStatus.NEW ||
    overviewL0?.hasInspection ? (
    <>
      <CardOverview height="200px" buttonDisabled={true}>
        <div style={{ height: '90%', margin: 'auto 0' }}>
          <ImageCarousel
            items={imagesList?.length > 0 ? imagesList : imagesWeb}
            slidesPerView={IMAGE_CAROUSEL_MAX_IMAGES}
            canClick={imagesList?.length > 0}
            maxWidth={
              imagesList && imagesList?.length <= IMAGE_CAROUSEL_MAX_IMAGES
                ? '250px'
                : 'unset'
            }
          />
        </div>
      </CardOverview>

      <Contesto overviewL0={overviewL0} />

      <Struttura overviewL0={overviewL0} />

      <Row>
        <Sovrastruttura overviewL0={overviewL0} />
        <Servizi overviewL0={overviewL0} />
      </Row>
    </>
  ) : (
    <>
      <Stack alignItems={'center'}>
        <Typography variant="h3">{`Non sono presenti attività all'interno di questa struttura`}</Typography>
        {userPermissionOnStructure === PermissionType.CanEdit &&
          !overviewL0?.hasInspection && (
            <Button
              onClick={() =>
                setOpenDrawerNewInspection(!openDrawerNewInspection)
              }
              variant="contained"
              color="primary"
              size="small"
              style={{ marginTop: 8 }}
            >
              Compila L0,L1
            </Button>
          )}
      </Stack>
      <DrawerNewInspection
        open={openDrawerNewInspection}
        setOpen={setOpenDrawerNewInspection}
        isOwner={isOwner}
      />
    </>
  );
};

export { TabL0 };

TabL0.propTypes = {
  structure: PropTypes.object,
  userPermissionOnStructure: PropTypes.oneOf([
    PermissionType.CanView,
    PermissionType.CanEdit,
  ]),
  openDrawerNewInspection: PropTypes.bool,
  setOpenDrawerNewInspection: PropTypes.func,
};
