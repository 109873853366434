import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { PanelContainer } from './SelectionPanel.styels';

const SelectionPanel = ({ subtitle, children }) => {
  return (
    <PanelContainer>
      <Typography variant="h3BoldBlue">Seleziona elemento</Typography>
      <Typography sx={{ marginBottom: -1 }} variant="h3">
        {subtitle}
      </Typography>
      {children}
    </PanelContainer>
  );
};

export { SelectionPanel };

SelectionPanel.propTypes = {
  subtitle: PropTypes.string,
  children: PropTypes.node,
};
