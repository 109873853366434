import { Grid } from '@mui/material';
import { MediaUpload } from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';

const Accordion2SchemiGeometrici = () => {
  return (
    <Grid container spacing={2} padding="20px">
      <Grid item md={3}>
        <MediaUpload
          title={`Sezione Trasversale`}
          btnTitle="Allega file"
          dataType=".jpeg,.png"
          fileLimit={1}
          formName="sezioneTrasversale"
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          uploadType={UploadMedia.graphicElaboration}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.ElaboratiGrafici]}
        />
      </Grid>
      <Grid item md={3}>
        <MediaUpload
          title={`Sezione Longitudinale`}
          btnTitle="Allega file"
          dataType=".jpeg,.png"
          fileLimit={1}
          formName="sezioneLongitudinale"
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          uploadType={UploadMedia.graphicElaboration}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.ElaboratiGrafici]}
          tags={['Prospetto']}
        />
      </Grid>
      <Grid item md={3}>
        <MediaUpload
          title={`Pianta`}
          btnTitle="Allega file"
          dataType=".pdf,.jpeg,.png"
          fileLimit={1}
          formName="pianta"
          uploadType={UploadMedia.graphicElaboration}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.ElaboratiGrafici]}
        />
      </Grid>
      <Grid item md={3}>
        <MediaUpload
          title={`Elaborati Grafici`}
          btnTitle="Allega file"
          dataType=".dwg"
          fileLimit={1}
          formName="elaboratiGrafici"
          uploadType={UploadMedia.graphicElaboration}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.ElaboratiGrafici]}
        />
      </Grid>
      <Grid item md={3}>
        <MediaUpload
          title={`Rilievo 3D`}
          btnTitle="Allega file"
          dataType=".dwg"
          fileLimit={1}
          formName="rilievo3D"
          uploadType={UploadMedia.graphicElaboration}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.ElaboratiGrafici]}
          tags={['Prospetto']}
        />
      </Grid>
    </Grid>
  );
};

export { Accordion2SchemiGeometrici };
