import { WebL0Fields } from 'constants/inspectionFields';
import { Accordion1LightingPoles } from '../Accordion1LightingPoles/Accordion1LightingPoles';
import { Accordion2WaterConveyance } from '../Accordion2WaterConveyance/Accordion2WaterConveyance';
import { Accordion3Sottoservizi } from '../Accordion3Sottoservizi/Accordion3Sottoservizi';
import { Accordion4ServicesCommentiPerReport } from '../Accordion4ServicesCommentiPerReport/Accordion4ServicesCommentiPerReport';

export const AccordionDataServicesL0 = [
  {
    id: '1',
    title: '1. Pali illuminazione',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion1LightingPoles />,
    indicatorFields: WebL0Fields.Servizi.paliIlluminazione,
  },
  {
    id: '2',
    title: '2. Convogliamento acque',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion2WaterConveyance />,
    indicatorFields: WebL0Fields.Servizi.convogliamentoAcque,
  },
  {
    id: '3',
    title: '3. Sottoservizi',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion3Sottoservizi />,
    indicatorFields: WebL0Fields.Servizi.sottoservizi,
  },
  {
    id: '4',
    title: '4. Commenti per report',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion4ServicesCommentiPerReport />,
    /* indicatorFields: WebL0Fields.Servizi.commentiReport, */
  },
];
