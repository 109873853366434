import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { colors } from 'constants/colors';
import { BoxContainer, IconContainer } from './Guidelines.styles';

const GuidelinesBox = ({ title, status, statusMessage }) => {
  let icon = '';

  switch (status) {
    case 1:
      title === 'Strategicità opera'
        ? (icon = '/icons/alert-check.svg')
        : (icon = '/icons/check-green.svg');

      break;
    case 2:
      title === 'Strategicità opera'
        ? (icon = '/icons/check-green.svg')
        : (icon = '/icons/alert-check.svg');
      break;
  }

  return (
    <BoxContainer>
      <Typography
        variant="h3Bold"
        sx={{
          fontSize: '0.75rem',
        }}
      >
        {title}
      </Typography>
      {status ? (
        <IconContainer>
          <img src={icon} width={35} height={35} />
          <Typography
            variant="body"
            sx={{
              fontSize: '0.75rem',
              color: colors.BLACK,
            }}
          >
            {statusMessage}
          </Typography>
        </IconContainer>
      ) : (
        <IconContainer>
          <Typography
            variant="body"
            sx={{
              fontSize: '0.75rem',
              color: colors.BLACK,
            }}
          >
            n.d.
          </Typography>
        </IconContainer>
      )}
    </BoxContainer>
  );
};

export { GuidelinesBox };

GuidelinesBox.propTypes = {
  title: PropTypes.string,
  statusMessage: PropTypes.string,
  status: PropTypes.number,
};
