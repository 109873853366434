import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, InfoWrapper, Table } from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import { tipologiaGiuntoOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import { GridWrap } from './AppoggiForm.styles';
import GiuntiForm from './GiuntiForm';
const GiuntiSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const spallePileCollegamentiTableGiuntiTableWatch = watch(
    `spallePileCollegamentiTable[${selectedIdx}].giuntiTable`
  );

  // table setup
  const dataConfigGiunti = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaGiunto: {
        label: 'Tipologia',
        format: tipologiaGiuntoOptions,
      },
      larghezzaGiunto: { label: 'L [cm]' },
      profonditaGiunto: { label: 'P [cm]' },
    },
  };

  // giunti setup
  const [selectedGiunti, setSelectedGiunti] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsGiunti = item => {
    setValue('l0t2a4_giuntiTableValue', [item]);
    setValue('l0t2a4_tipologiaGiuntoTableValue', item.tipologiaGiunto);
    setValue('l0t2a4_larghezzaGiuntoTableValue', item.larghezzaGiunto);
    setValue('l0t2a4_profonditaGiuntoTableValue', item.profonditaGiunto);
  };
  const handleSelectAllClickGiunti = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `spallePileCollegamentiTable[${selectedIdx}].giuntiTable`
      );
      setSelectedGiunti(selectedValue);
      setValue('l0t2a4_giuntiTableValue', selectedValue);
    } else {
      setSelectedGiunti([]);
      setValue('l0t2a4_giuntiTableValue', []);
    }
  };

  return (
    <>
      <GridWrap container spacing={2} style={{ marginTop: 16 }}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.collegamentiGiunti.drawerTitle}
          drawerText={INFO.collegamentiGiunti.drawerText}
        >
          <Divider>GIUNTI</Divider>
        </InfoWrapper>
        {spallePileCollegamentiTableGiuntiTableWatch?.length === 0 && (
          <GridWrap item xs={12} paddingTop={'0px !important'}>
            <Typography>Nessun giunto presente in questo elemento</Typography>
          </GridWrap>
        )}
      </GridWrap>
      {spallePileCollegamentiTableGiuntiTableWatch?.length > 0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <GiuntiForm
              giuntiFields={spallePileCollegamentiTableGiuntiTableWatch || []}
              selected={selectedGiunti}
              setSelected={setSelectedGiunti}
              giuntiTable={`spallePileCollegamentiTable[${selectedIdx}].giuntiTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Giunti'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Struttura]}
          />
          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={spallePileCollegamentiTableGiuntiTableWatch || []}
                config={dataConfigGiunti}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsGiunti(item);
                            setSelectedGiunti([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        spallePileCollegamentiTableGiuntiTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedGiunti}
                onSelectAllClick={handleSelectAllClickGiunti}
                setSelected={setSelectedGiunti}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
GiuntiSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default GiuntiSection;
