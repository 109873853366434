import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const BoxContainer = styled('div')({
  width: '100%',
  borderRadius: 8,
  border: `1px solid ${colors.PALAGREY}`,
  backgroundColor: colors.WHITE,
  padding: '5px 16px',
});

export const IconContainer = styled('div')({
  width: '100%',
  height: 35,
  marginTop: 5,
  display: 'flex',
  gap: 8,
  alignItems: 'center',
});
