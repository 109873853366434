import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, InfoWrapper, Table } from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';

import { tipologiaAntennaOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import AntenneForm from './AntenneForm';
import { GridWrap } from './AppoggiForm.styles';
const AntenneSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const spallePileCollegamentiTableAntenneTableWatch = watch(
    `spallePileCollegamentiTable[${selectedIdx}].antenneTable`
  );

  // table setup
  const dataConfigAntenne = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaAntenna: {
        label: 'Tipologia',
        format: tipologiaAntennaOptions,
      },
      altezzaAntenna: { label: 'H [cm]' },
      larghezzaAntenna: { label: 'L [cm]' },
      profonditaAntenna: { label: 'P [cm]' },
      superficieAntenna: { label: 'Sup. [m²]' },
    },
  };

  // pulvini setup
  const [selectedAntenne, setSelectedAntenne] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsAntenne = item => {
    setValue('l0t2a4_antenneTableValue', [item]);
    setValue('l0t2a4_tipologiaAntennaTableValue', item.tipologiaAntenna);
    setValue('l0t2a4_altezzaAntennaTableValue', item.altezzaAntenna);
    setValue('l0t2a4_larghezzaAntennaTableValue', item.larghezzaAntenna);
    setValue('l0t2a4_profonditaAntennaTableValue', item.profonditaAntenna);
    setValue('l0t2a4_superficieAntennaTableValue', item.superficieAntenna);
  };
  const handleSelectAllClickAntenne = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `spallePileCollegamentiTable[${selectedIdx}].antenneTable`
      );
      setSelectedAntenne(selectedValue);
      setValue('l0t2a4_antenneTableValue', selectedValue);
    } else {
      setSelectedAntenne([]);
      setValue('l0t2a4_antenneTableValue', []);
    }
  };

  return (
    <>
      <GridWrap container spacing={2} style={{ marginTop: 16 }}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.collegamentiAntenne.drawerTitle}
          drawerText={INFO.collegamentiAntenne.drawerText}
        >
          <Divider>ANTENNE</Divider>
        </InfoWrapper>
        {spallePileCollegamentiTableAntenneTableWatch?.length === 0 && (
          <GridWrap item xs={12} paddingTop={'0px !important'}>
            <Typography>Nessuna antenna presente in questo elemento</Typography>
          </GridWrap>
        )}
      </GridWrap>
      {spallePileCollegamentiTableAntenneTableWatch?.length > 0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <AntenneForm
              antenneFields={spallePileCollegamentiTableAntenneTableWatch || []}
              selected={selectedAntenne}
              setSelected={setSelectedAntenne}
              antenneTable={`spallePileCollegamentiTable[${selectedIdx}].antenneTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Antenne'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`spallePileCollegamentiTable[${selectedIdx}].antenneTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Struttura]}
          />
          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={spallePileCollegamentiTableAntenneTableWatch || []}
                config={dataConfigAntenne}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsAntenne(item);
                            setSelectedAntenne([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        spallePileCollegamentiTableAntenneTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedAntenne}
                onSelectAllClick={handleSelectAllClickAntenne}
                setSelected={setSelectedAntenne}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
AntenneSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default AntenneSection;
