import styled from '@emotion/styled';
import { Delete, Edit } from '@mui/icons-material';
import { Button, Grid, Paper } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'use-http';
import {
  BasicDialog,
  DataGrid,
  FormSubmitButtons,
  HeaderPage,
  PaginationBase,
  RHFSelect,
  RHFTextField,
  TourWrapper,
} from 'components';
import { apiCollaborators } from 'constants/api';
import { colors } from 'constants/colors';
import { COLLABORATORS_PATHS, ROOT_PATH } from 'constants/paths';
import { CollaborationTypeMap } from 'constants/permissions';
import { tour_17a } from 'constants/tour';
import {
  CollaboratorsActionsWarningMessageMap,
  CollaboratorsListActionsMap,
  UserStatusMap,
  UserStatusOptions,
  UserTypeMap,
  UserTypology,
} from 'constants/users';
import { useSubscription } from 'hooks';
import { FormProvider } from 'providers';
import { useGlobalSelector } from 'stores';
import { steps } from './CollaboratorsList.tourSteps';
import { useCollaboratorsList } from './useCollaboratorsList';
import { useCollaboratorsListWarningDialog } from './useCollaboratorsListWarningDialog';

const StyledButton = styled(Button)({
  width: 36,
  height: 36,
  minWidth: 0,
});

const StyledPaper = styled(Paper)(({ colorVariant, theme }) => ({
  borderRadius: 8,
  backgroundColor: colorVariant === 'grey' && theme.palette.background.grey,
  padding: theme.spacing(2),
}));

const NavigationMap = {
  1: COLLABORATORS_PATHS.EDIT_INTERNAL_COLLBAORATOR,
  2: COLLABORATORS_PATHS.EDIT_EXTERNAL_COLLBAORATOR,
};

const CollaboratorsList = () => {
  const [searchParams, setSearchParams] = useState({});
  const { showLoader, hideLoader } = useGlobalSelector();
  const navigate = useNavigate();
  const { del } = useFetch();

  const { subscription, user } = useSubscription();

  const handleCollaboratorDelete = async id => {
    try {
      showLoader();
      await del(`${apiCollaborators.COLLABORATION}/${id}`);
    } catch (err) {
      console.log('ERR', err);
    } finally {
      setSearchParams({});
      hideLoader();
    }
  };

  const {
    openDialog,
    handleCloseDialog,
    handleDialogAction,
    actionSelected,
    handleSelectRow,
  } = useCollaboratorsListWarningDialog({
    handleCollaboratorDelete,
  });

  const columns = [
    {
      label: 'Email',
      name: 'email',
      sortable: true,
    },
    {
      label: 'Nome cognome',
      name: 'displayName',
      sortable: true,
    },
    {
      label: 'Azienda/Ente',
      name: 'businessName',
      sortable: true,
    },
    {
      label: 'Tipo Utente',
      name: 'type',
      sortable: true,
      valueFormatter: type => UserTypeMap[type],
    },
    {
      label: 'Tipologia Utente',
      name: 'collaborationType',
      sortable: true,
      valueFormatter: collaborationType =>
        CollaborationTypeMap[collaborationType],
    },
    {
      label: 'Stato',
      name: 'updatedRegistrationStatus',
      valueFormatter: status => UserStatusMap[status],
      sortable: true,
    },

    {
      name: 'actions',
      renderCell: row => {
        return (
          <Stack direction={'row'} gap={1} justifyContent="right">
            <StyledButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                navigate(
                  NavigationMap[row.collaborationType].replace(
                    ':userId',
                    row.id
                  )
                );
              }}
            >
              <Edit />
            </StyledButton>
            <StyledButton
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                handleSelectRow(CollaboratorsListActionsMap.DELETE, row.id);
              }}
            >
              <Delete />
            </StyledButton>
          </Stack>
        );
      },
    },
  ];

  const {
    error,
    loading,
    data,
    orderBy,
    direction,
    handleOrderChange,
    rowsPerPageOptions,
    rowsPerPage,
    page,
    handlePageChange,
    handleRowsPerPageChange,
  } = useCollaboratorsList(searchParams);
  const { collaborators } = data;

  const defaultValues = {
    email: '',
    displayName: '',
    type: '',
    status: '',
  };

  const methods = useForm({ defaultValues });

  const { handleSubmit, reset } = methods;

  const onSubmit = data => {
    setSearchParams(data);
  };
  const handleReset = () => {
    reset();
  };
  if (subscription === null) {
    navigate(ROOT_PATH);
  }

  useEffect(() => {
    if (user) {
      if (user.tipologiaUtente === UserTypology.CollaboratoreInterno) {
        navigate(ROOT_PATH);
      }
    }
  }, [user]);

  return (
    <div>
      <HeaderPage
        title="Collaboratori"
        buttons={
          <div className={`${tour_17a.step_01}`}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() =>
                navigate(COLLABORATORS_PATHS.NEW_INTERNAL_COLLBAORATOR)
              }
            >
              Nuovo Collaboratore interno
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() =>
                navigate(COLLABORATORS_PATHS.NEW_EXTERNAL_COLLBAORATOR)
              }
            >
              Invita utente esterno
            </Button>
          </div>
        }
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <StyledPaper
          sx={{
            marginBottom: theme => theme.spacing(3),
          }}
          colorVariant="grey"
          elevation={0}
        >
          <Grid container spacing={2}>
            <Grid item xs={2.4}>
              <RHFTextField name="email" label="Email" />
            </Grid>

            <Grid item xs={2.4}>
              <RHFTextField name="displayName" label="Nome Cognome" />
            </Grid>
            <Grid item xs={2.4}>
              <RHFTextField name="businessName" label="Azienda/Ente" />
            </Grid>
            <Grid item xs={2.4}>
              <RHFSelect
                name="status"
                label="Stato"
                selectOptions={UserStatusOptions}
              />
            </Grid>
            <Grid item xs={2.4}>
              <FormSubmitButtons
                onSubmit={handleSubmit(onSubmit)}
                onReset={handleReset}
              />
            </Grid>
          </Grid>
        </StyledPaper>
      </FormProvider>
      {!error && !loading && (
        <DataGrid
          rowColor={colors.WHITE}
          columns={columns}
          rows={collaborators?.items}
          orderBy={orderBy}
          direction={direction}
          onSort={handleOrderChange}
        />
      )}
      {!error && !loading && (
        <PaginationBase
          div
          count={collaborators?.count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}

      <BasicDialog
        title="Attenzione"
        open={openDialog}
        onClose={handleCloseDialog}
        actions={
          <>
            <Button onClick={handleCloseDialog}>Annulla</Button>
            <Button variant="contained" onClick={handleDialogAction}>
              Conferma
            </Button>
          </>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {CollaboratorsActionsWarningMessageMap[actionSelected]}
          </Grid>
        </Grid>
      </BasicDialog>
      <TourWrapper steps={steps} />
    </div>
  );
};

CollaboratorsList.propTypes = {};

export default CollaboratorsList;
