import { Typography } from '@mui/material';

import { MediaUpload, RHFTextField } from 'components';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap, TitleSection } from './Accordion6CommentiPerReportL1.styles';

const Accordion6CommentiPerReportL1 = () => {
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();
  return (
    <>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">{'ELEVAZIONE'}</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneStrutturaElevazione"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoDescrizioneStrutturaElevazione"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={40} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">{'COLLEGAMENTI'}</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneStrutturaCollegamenti"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoStrutturaCollegamenti"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
      </GridWrap>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={40} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">{'IMPALCATO'}</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneStrutturaImpalcato"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoStrutturaImpalcato"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
      </GridWrap>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={40} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">{'ASPETTI CRITICI'}</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneStrutturaAspettiCritici"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoStrutturaAspettiCritici"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
      </GridWrap>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={40} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              {'OSSERVAZIONI RISCHIO STRUTTURALE E FONDAZIONALE'}
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneRischioStrutturaleFondazionale"
            label={'Annotazioni rischio strutturale e fondazionale'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoRischioStruttraleFondazionale"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="commentiConclusiviCdAStrutturaleFondazionale"
            label={'Commenti conclusivi alla CdA strutturale e fondazionale'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={40} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              {'OSSERVAZIONI RISCHIO SISMICO'}
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneStrutturaRischioSismico"
            label={'Annotazioni rischio sisimico'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoStrutturaRischioSismico"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <RHFTextField
            multiline
            name="commentiConclusiviCdASismica"
            label={'Commenti conclusivi alla CdA sismica'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      </GridWrap>
    </>
  );
};

export { Accordion6CommentiPerReportL1 };
