import { Typography } from '@mui/material';

import { MediaUpload, RHFTextField } from 'components';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap, TitleSection } from './Accordion4CommentiPerReportL1.styles';

const Accordion4CommentiPerReportL1 = () => {
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();
  return (
    <>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">{'PALI ILLUMINAZIONE'}</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneServiziPali"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoServiziPali"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={40} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              {'CONVOGLIAMENTO ACQUE'}
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneServiziAcque"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoServiziAcque"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={40} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">{'SOTTOSERVIZI'}</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneServiziSottoservizi"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoServiziSottoservizi"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={40} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              {'OSSERVAZIONI SERVIZI'}
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="commentiConclusiviServizi"
            label={'Commenti conclusivi ai servizi'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      </GridWrap>
    </>
  );
};

export { Accordion4CommentiPerReportL1 };
