import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';

import { Table } from 'components/tables';

import { useImagesSelector } from 'stores';
import { uniqBy } from 'utils/media';
import { downloadFile } from 'utils/upload';
import {
  BtnPanelContainer,
  DrawerContentContainer,
} from './DrawerMediaSelectedTable.styles';

export const DrawerMediaSelectedTable = ({
  openDrawer,
  setOpenDrawer,
  formName,
  preview,
  isViewingInspection,
}) => {
  //State

  const [selected, setSelected] = useState([]);

  const { setValue, getValues, watch } = useFormContext();
  const { images } = useImagesSelector();

  useEffect(() => {
    if (images) {
      setValue(
        'mediaSelectedTable',
        uniqBy([...images, ...(getValues(formName) || [])], 'id')
      );
    }
    if (getValues(formName)) {
      setSelected(getValues(formName));
    }
  }, []);

  const dataTable = watch('mediaSelectedTable');

  //Const
  const dataConfig = {
    columns: {
      mediaCode: { label: 'Codice' },
      visualName: { label: 'Nome file' },
      author: { label: 'Autore' },
      date: { label: 'Data' },
      description: { label: 'Descrizione' },
    },
  };

  const onSelectAllClick = event => {
    if (event.target.checked) {
      setSelected(images);
    } else {
      setSelected([]);
    }
  };

  return (
    <div>
      <DrawerContentContainer>
        {dataTable && dataTable.length !== 0 ? (
          <Table
            data={isViewingInspection ? selected : dataTable}
            config={dataConfig}
            hasCheckbox={!isViewingInspection}
            typevariant={'secondary'}
            selected={selected}
            setSelected={setSelected}
            preview={preview}
            onSelectAllClick={onSelectAllClick}
            rowActions={
              isViewingInspection
                ? [
                    {
                      onClick: item => {
                        if (item?.s3Path) {
                          downloadFile(item.s3Path);
                        }
                      },
                      mediaTable: true,
                      downloadIcon: true,
                      icon: <DownloadIcon />,
                    },
                  ]
                : []
            }
          />
        ) : (
          <Typography style={{ marginTop: -10 }} variant="h3">
            {/* TO DO: aspettare implementazione lato Backend*/}
            Nessun file presente.
          </Typography>
        )}
      </DrawerContentContainer>
      {!isViewingInspection && (
        <BtnPanelContainer>
          <Button
            variant="text"
            color="secondary"
            size="small"
            onClick={() => {
              setOpenDrawer(!openDrawer);
            }}
          >
            Annulla
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              setOpenDrawer(!openDrawer);
              setValue(formName, selected);
              // TODO : qui selezione file
            }}
          >
            Conferma selezione
          </Button>
        </BtnPanelContainer>
      )}
    </div>
  );
};

DrawerMediaSelectedTable.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  formName: PropTypes.string,
  preview: PropTypes.bool,
  isViewingInspection: PropTypes.bool,
};
