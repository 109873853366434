import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaAntennaOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import { GridWrap } from './AppoggiForm.styles';
const AntenneForm = ({
  antenneFields,
  selected,
  setSelected,
  antenneTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t2a4_antenneTableValue: [],
      l0t2a4_tipologiaAntennaTableValue: '',
      l0t2a4_altezzaAntennaTableValue: '',
      l0t2a4_larghezzaAntennaTableValue: '',
      l0t2a4_profonditaAntennaTableValue: '',
      l0t2a4_superficieAntennaTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a4_antenneTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetAntenne();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeAntenne = () => {
    const l0t2a4_tipologiaAntennaTableValue = getValues(
      'l0t2a4_tipologiaAntennaTableValue'
    );
    const l0t2a4_altezzaAntennaTableValue = getValues(
      'l0t2a4_altezzaAntennaTableValue'
    );
    const l0t2a4_larghezzaAntennaTableValue = getValues(
      'l0t2a4_larghezzaAntennaTableValue'
    );
    const l0t2a4_profonditaAntennaTableValue = getValues(
      'l0t2a4_profonditaAntennaTableValue'
    );

    const l0t2a4_superficieAntennaTableValue = getValues(
      'l0t2a4_superficieAntennaTableValue'
    );

    const ids = selected.map(el => el.codice);

    const antenne = [...antenneFields];

    antenne.map((x, index) => {
      if (ids.includes(x?.codice)) {
        antenne[index] = {
          ...x,
          tipologiaAntenna: l0t2a4_tipologiaAntennaTableValue,
          altezzaAntenna: l0t2a4_altezzaAntennaTableValue,
          larghezzaAntenna: l0t2a4_larghezzaAntennaTableValue,
          profonditaAntenna: l0t2a4_profonditaAntennaTableValue,
          superficieAntenna: l0t2a4_superficieAntennaTableValue,
          ...(l0t2a4_tipologiaAntennaTableValue !== x.tipologiaAntenna
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'antenna',
                  l0t2a4_tipologiaAntennaTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(antenneTable, antenne);
    onResetAntenne();
  };

  const onConfirmChangeAntenne = () => {
    setOpenConfirmationDialog(false);
    changeAntenne();
  };

  const onSubmitAntenne = () => {
    const l0t2a4_tipologiaAntennaTableValue = getValues(
      'l0t2a4_tipologiaAntennaTableValue'
    );
    const ids = selected.map(el => el.codice);

    const antenne = [...antenneFields];

    const hasChangedTipologia = antenne.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a4_tipologiaAntennaTableValue !== el.tipologiaAntenna &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaAntenna)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeAntenne();
    }
  };

  const onResetAntenne = () => {
    setValue('l0t2a4_antenneTableValue', []);
    setValue('l0t2a4_tipologiaAntennaTableValue', '');
    setValue('l0t2a4_altezzaAntennaTableValue', '');
    setValue('l0t2a4_larghezzaAntennaTableValue', '');
    setValue('l0t2a4_profonditaAntennaTableValue', '');
    setValue('l0t2a4_profonditaAppoggioPulvinoTableValue', '');
    setValue('l0t2a4_superficieAntennaTableValue', '');

    setSelected([]);
  };
  // [2*(Profondità+Larghezza)*Altezza]/10000

  const altezzaAntennaWatch = parseFloat(
    watch('l0t2a4_altezzaAntennaTableValue')
  );
  const profonditaAntennaWatch = parseFloat(
    watch('l0t2a4_profonditaAntennaTableValue')
  );
  const larghezzaAntennaWatch = parseFloat(
    watch('l0t2a4_larghezzaAntennaTableValue')
  );

  useEffect(() => {
    const result = (
      (2 *
        (profonditaAntennaWatch + larghezzaAntennaWatch) *
        altezzaAntennaWatch) /
      10000
    ).toFixed(2);

    setValue('l0t2a4_superficieAntennaTableValue', result);
  }, [altezzaAntennaWatch, profonditaAntennaWatch, larghezzaAntennaWatch]);

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={6} infoMessage={INFO.antenneSelezionate.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-antenne"
            name={'l0t2a4_antenneTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={antenneFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Antenne selezionate"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a4_tipologiaAntennaTableValue"
            label="Tipologia Antenna*"
            defaultValue={''}
            onChange={e => {
              setValue('l0t2a4_tipologiaAntennaTableValue', e.target.value);
            }}
            selectOptions={tipologiaAntennaOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a4_altezzaAntennaTableValue"
            label={'Altezza (H) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a4_larghezzaAntennaTableValue"
            label={'Larghezza (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a4_profonditaAntennaTableValue"
            label={'Profondità (P) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>

        <InfoWrapper size={3} infoMessage={INFO.superficieAntenna.infoMessage}>
          <RHFNumberTextField
            name="l0t2a4_superficieAntennaTableValue"
            label={'Superficie [m²]'}
            defaultValue=""
            decimalScale={2}
            InputLabelProps={{ shrink: true }}
            disabled
            withValueLimit={({ value }) => value < 1000}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <FormSubmitButtons
            onSubmit={onSubmitAntenne}
            onReset={onResetAntenne}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeAntenne}
      />
    </>
  );
};

AntenneForm.propTypes = {
  antenneFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaAntenna: PropTypes.number,
      altezzaAntenna: PropTypes.number,
      larghezzaAntenna: PropTypes.number,
      profonditaAntenna: PropTypes.number,
      superficieAntenna: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  antenneTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default AntenneForm;
