import { Button, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import {
  DrawerCopy,
  DrawerMedia,
  GridWrap,
  InfoWrapper,
  InspectionInfoForm,
  LocalTourWrapper,
  SchedaDifettiElementiStrutturali,
  Table,
} from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import { CDAOptions, tipologiaSpallaOptions } from 'constants/selectOptions';
import { tour_13 } from 'constants/tour';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import { populateSchedeDifettiTableActions } from 'utils/Inspections/L1/schedeDifettiTableActions';
// import InspectionInfoForm from './InspectionInfoForm';
import SpalleForm from './SpalleForm';
import { steps } from '../Accordion2Elevazione.tourSteps';
import useCopy from '../hooks/useCopySpalle';

const SpalleSection = () => {
  const { setValue, getValues, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const spalleTable = watch('spalleTable');

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsSpalle = item => {
    setValue('spalleTableValue', [item]);
    setValue('tipologiaSpallaTableValue', item.tipologiaSpalla);
    setValue('impostaFondSpallaTableValue', item.impostaFondSpalla);
  };
  const handleSelectAllClickSpalle = event => {
    if (event.target.checked) {
      const selectedValue = getValues('spalleTable');
      setSelected(selectedValue);
      setValue('spalleTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('spalleTableValue', []);
    }
  };

  const dataSpalleConfig = {
    columns: {
      codiceSpalla: { label: 'Cod.' },
      tipologiaSpalla: { label: 'Tipologia', format: tipologiaSpallaOptions },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      indiceCondizione: { label: 'Indice di condizione' },
      CDAstrutturale: { label: 'Difettosità Str.', format: CDAOptions },
      CDAsismica: { label: 'Difettosità Sis.', format: CDAOptions },
      nrtotali: { label: 'NR' },
      g5totali: { label: 'G=5' },
      pstotali: { label: 'PS' },
    },
  };

  useEffect(() => {
    setValue(
      'l1t2a2_tecnicoRilevatoreSpalle',
      getValues(`spalleTable[${schedaDifettiIndex}].tecnicoRilevatore`) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t2a2_dataIspezioneSpalle',
      getValues(`spalleTable[${schedaDifettiIndex}].dataIspezione`) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t2a2_elementoCriticoStrutturaSpalle',
      getValues(
        `spalleTable[${schedaDifettiIndex}].elementoCriticoStruttura`
      ) || ''
    );
    setValue(
      'l1t2a2_elementoCriticoSismicaSpalle',
      getValues(`spalleTable[${schedaDifettiIndex}].elementoCriticoSismica`) ||
        ''
    );
    setValue(
      'l1t2a2_condizioneCriticaStrutturaSpalle',
      getValues(
        `spalleTable[${schedaDifettiIndex}].condizioneCriticaStruttura`
      ) || ''
    );
    setValue(
      'l1t2a2_condizioneCriticaSismicaSpalle',
      getValues(
        `spalleTable[${schedaDifettiIndex}].condizioneCriticaSismica`
      ) || ''
    );
    setValue(
      'l1t2a2_noteGeneraliSpalle',
      getValues(`spalleTable[${schedaDifettiIndex}].noteGenerali`) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t2a2_tecnicoRilevatoreSpalle');
    const dataIspezione = getValues('l1t2a2_dataIspezioneSpalle');
    const elementoCriticoStruttura = getValues(
      'l1t2a2_elementoCriticoStrutturaSpalle'
    );
    const elementoCriticoSismica = getValues(
      'l1t2a2_elementoCriticoSismicaSpalle'
    );
    const condizioneCriticaStruttura = getValues(
      'l1t2a2_condizioneCriticaStrutturaSpalle'
    );
    const condizioneCriticaSismica = getValues(
      'l1t2a2_condizioneCriticaSismicaSpalle'
    );
    const noteGenerali = getValues('l1t2a2_noteGeneraliSpalle');
    setValue(
      `spalleTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(`spalleTable[${schedaDifettiIndex}].dataIspezione`, dataIspezione);
    setValue(
      `spalleTable[${schedaDifettiIndex}].elementoCriticoStruttura`,
      elementoCriticoStruttura
    );
    setValue(
      `spalleTable[${schedaDifettiIndex}].elementoCriticoSismica`,
      elementoCriticoSismica
    );
    setValue(
      `spalleTable[${schedaDifettiIndex}].condizioneCriticaStruttura`,
      condizioneCriticaStruttura
    );
    setValue(
      `spalleTable[${schedaDifettiIndex}].condizioneCriticaSismica`,
      condizioneCriticaSismica
    );
    setValue(`spalleTable[${schedaDifettiIndex}].noteGenerali`, noteGenerali);
    setValue('l1t2a5_trigger', Math.random() * 1000);
  };
  const handleResetInspection = () => {
    setValue(`l1t2a2_tecnicoRilevatoreSpalle`, '');
    setValue(`l1t2a2_dataIspezioneSpalle`, null);
    setValue(`l1t2a2_elementoCriticoStrutturaSpalle`, '');
    setValue(`l1t2a2_elementoCriticoSismicaSpalle`, '');
    setValue(`l1t2a2_condizioneCriticaStrutturaSpalle`, '');
    setValue(`l1t2a2_condizioneCriticaSismicaSpalle`, '');
    setValue(`l1t2a2_noteGeneraliSpalle`, '');
  };

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  useEffect(() => {
    /* console.log('entering here'); */
  }, []);

  const {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedCopy,
    setSelectedCopy,
    dataSpallePileConfig,
    selectedPaste,
    handleSelectAllSpallePilePaste,
    setSelectedPaste,
    checkCompatibility,
    handleCopy,
  } = useCopy();

  return (
    <GridWrap container spacing={2}>
      <InfoWrapper
        isDivider={true}
        size={12}
        infoDrawer={true}
        drawerTitle={INFO.elevazioneSpalle.drawerTitle}
        drawerText={INFO.elevazioneSpalle.drawerText}
      >
        <Divider sx={{ marginTop: -3 }}>SPALLE</Divider>
      </InfoWrapper>
      <GridWrap item xs={12}>
        {!inspectionDisabled && !isViewingInspection && (
          <SpalleForm
            spalleFields={spalleTable || []}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </GridWrap>
      <GridWrap item xs={12}>
        <Table
          data={spalleTable || []}
          config={dataSpalleConfig}
          hasCheckbox={!inspectionDisabled && !isViewingInspection}
          rowActions={
            !inspectionDisabled && !isViewingInspection
              ? [
                  {
                    onClick: item => {
                      editRowsSpalle(item);
                      setSelected([item]);
                    },
                    icon: <EditIcon />,
                  },
                ]
              : []
          }
          mediaActions={[
            {
              onClick: item => {
                setDrawerMediaIndex(
                  spalleTable.findIndex(el => el.id === item.id)
                );
                setOpen(true);
              },
              icon: <MediaIcon />,
            },
          ]}
          schedaDifettiActions={populateSchedeDifettiTableActions(
            schedaDifettiIndex,
            spalleTable,
            setOpenSchedaDifetti,
            setSchedaDifettiIndex
          )}
          selected={selected}
          onSelectAllClick={handleSelectAllClickSpalle}
          setSelected={setSelected}
        />
      </GridWrap>

      {schedaDifettiIndex !== undefined && openSchedaDifetti && (
        <>
          <InfoWrapper
            isDivider={true}
            size={12}
            infoDrawer={true}
            drawerTitle={INFO.schedaIspezioniPonteDifettiInfo.drawerTitle}
            drawerText={INFO.schedaIspezioniPonteDifettiInfo.drawerText}
            button={
              <>
                {!inspectionDisabled && !isViewingInspection && (
                  <>
                    <LocalTourWrapper
                      style={{
                        padding: 5,
                        fontSize: 12,
                        height: 25,
                        marginLeft: 5,
                      }}
                      steps={steps}
                    />
                    <Button
                      className={`${tour_13.step_01}`}
                      style={{
                        padding: 5,
                        fontSize: 13,
                        height: 25,
                        marginLeft: 5,
                      }}
                      onClick={() => {
                        setCopyDrawerOpen(true);
                        setSelectedPaste([]);
                        setSelectedCopy(
                          getValues(`spalleTable[${schedaDifettiIndex}]`)
                        );
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Copia scheda ispezione
                    </Button>
                  </>
                )}
              </>
            }
          >
            <Divider>
              {spalleTable[schedaDifettiIndex].codiceSpalla} - Scheda Ispezione
              Ponti - Spalla
            </Divider>
          </InfoWrapper>
          {spalleTable[schedaDifettiIndex].schedaDifetti && (
            <InspectionInfoForm
              onSubmit={handleSaveInspection}
              onReset={handleResetInspection}
              tecnicoRilevatoreName={'l1t2a2_tecnicoRilevatoreSpalle'}
              dateName={'l1t2a2_dataIspezioneSpalle'}
              elementoCriticoStrutturaName={
                'l1t2a2_elementoCriticoStrutturaSpalle'
              }
              elementoCriticoSismicaName={'l1t2a2_elementoCriticoSismicaSpalle'}
              condizioneCriticaStrutturaName={
                'l1t2a2_condizioneCriticaStrutturaSpalle'
              }
              condizioneCriticaSismicaName={
                'l1t2a2_condizioneCriticaSismicaSpalle'
              }
              noteGeneraliName={'l1t2a2_noteGeneraliSpalle'}
            />
          )}
          <GridWrap item xs={12}>
            <SchedaDifettiElementiStrutturali
              key={spalleTable[schedaDifettiIndex].codice}
              scheda={spalleTable[schedaDifettiIndex].schedaDifetti}
              name={`spalleTable[${schedaDifettiIndex}]`}
              containerName={`spalleTable`}
            />
          </GridWrap>
        </>
      )}
      <DrawerMedia
        open={open}
        setOpen={setOpen}
        title={`Spalle`}
        infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
        dataType="video/*,image/*"
        uploadType={UploadMedia.media}
        type={FieldType.images}
        formName={`spalleTable[${drawerMediaIndex}].media`}
        mediaGroup={[MediaGroup.Struttura]}
      />
      {spalleTable?.length > 0 && (
        <DrawerCopy
          title={`Copia ${getValues(
            `spalleTable[${schedaDifettiIndex}].codiceSpalla`
          )} - Scheda ispezioni ponti - Spalla in ${
            tipologiaSpallaOptions.find(
              el =>
                el.value ===
                getValues(`spalleTable[${schedaDifettiIndex}].tipologiaSpalla`)
            )?.label
          }`}
          subtitle={
            'Tutti gli elementi su cui le informazioni saranno copiate saranno sovrascritti. Eventuali media o schede di ispezione a loro associati saranno resettati.'
          }
          open={copyDrawerOpen}
          setOpen={setCopyDrawerOpen}
          tableConfig={dataSpallePileConfig}
          copyTableData={
            spalleTable.filter(
              el => el.codiceSpalla == selectedCopy.codiceSpalla
            ) || []
          }
          pasteTableData={
            spalleTable.filter(
              el => el.codiceSpalla != selectedCopy.codiceSpalla
            ) || []
          }
          selectedCopy={selectedCopy}
          selectedPaste={selectedPaste}
          handleSelectAll={handleSelectAllSpallePilePaste}
          setSelectedPaste={setSelectedPaste}
          checkCompatibility={checkCompatibility}
          handleCopy={handleCopy}
        />
      )}
    </GridWrap>
  );
};
export default SpalleSection;
