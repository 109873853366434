import {
  Autocomplete,
  Button,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import {
  BasicDialog,
  InfoWrapper,
  LocalTourWrapper,
  // InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  Table,
} from 'components';
import { Tracciato } from 'constants/inspections';
import {
  schemaCampataOptions,
  tipoEnteScavalcatoOptions,
} from 'constants/selectOptions.js';
import { tour_09 } from 'constants/tour.js';
import { useInspectionPath } from 'hooks/index.js';
import { INFO } from 'pages/Inspections/config/info.js';
import { useInspectionSelector } from 'stores/inspection.js';
import {
  isNullOrUndefinedOrEmptyString,
  updateTableRows,
} from 'utils/utilities.js';
import { GridWrap } from './Accordion1CaratteristicheGeometriche .js';
import { steps } from './Accordion1CaratteristicheGeometriche.tourSteps.js';
import { CampateForm } from './sections/CampateForm/CampateForm';

const Accordion1CaratteristicheGeometriche = ({ levelL1 }) => {
  const { watch, getValues, setValue, control } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const [selected, setSelected] = useState([]);
  const [
    numCampateAutocompleteRenderValue,
    setNumCampateAutocompleteRenderValue,
  ] = useState(
    !isNullOrUndefinedOrEmptyString(getValues('numeroCampate'))
      ? {
          label: parseInt(getValues('numeroCampate')),
          value: parseInt(getValues('numeroCampate')),
        }
      : undefined
  );
  const [numCampateToChange, setNumCampateToChange] = useState();
  const [open, setOpen] = useState(false);

  const handleChangeCampate = newNumOfCampate => {
    const currNumOfCampate = getValues('numeroCampate');
    if (
      !isNullOrUndefinedOrEmptyString(currNumOfCampate) &&
      newNumOfCampate < parseInt(currNumOfCampate)
    ) {
      setNumCampateToChange(newNumOfCampate);
      setOpen(true);
    } else {
      setValue('numeroCampate', `${newNumOfCampate}`, { shouldDirty: true });
      setNumCampateAutocompleteRenderValue({
        label: isNullOrUndefinedOrEmptyString(newNumOfCampate)
          ? 'Nessuna Campata'
          : parseInt(newNumOfCampate),
        value: isNullOrUndefinedOrEmptyString(newNumOfCampate)
          ? ''
          : parseInt(newNumOfCampate),
      });
    }
  };

  const confirmChangeNumCampate = () => {
    setValue('numeroCampate', `${numCampateToChange}`, { shouldDirty: true });
    setNumCampateAutocompleteRenderValue({
      label: isNullOrUndefinedOrEmptyString(numCampateToChange)
        ? 'Nessuna Campata'
        : parseInt(numCampateToChange),
      value: isNullOrUndefinedOrEmptyString(numCampateToChange)
        ? ''
        : parseInt(numCampateToChange),
    });
    setOpen(false);
  };

  const {
    // fields: campateFields,
    append: addCampate,
    remove: removeCampate,
  } = useFieldArray({
    control,
    name: 'campataTable',
  });

  const dataConfig = {
    columns: {
      campata: { label: 'Codice' },
      schema: { label: 'Schema statico (tipo)', format: schemaCampataOptions },
      luceCampata: { label: 'Luce Campata (m)' },
      enteScavalcato: {
        label: 'Ente scavalcato (tipo)',
        format: tipoEnteScavalcatoOptions,
      },
      luceIdraulica: { label: 'Luce Idraulica (m)' },
    },
  };

  const editRows = item => {
    setValue('campataTableValue', [item]);
    setValue('schemaTableValue', item.schema);
    setValue('lucecampataTableValue', item.luceCampata);
    setValue('enteScavalcatoTableValue', item.enteScavalcato);
    setValue('luceIdraulicaTableValue', item.luceIdraulica);
  };

  const watchCampataTable = watch('campataTable');
  const watchNumCampate = watch('numeroCampate');

  useEffect(() => {
    const values = getValues('campataTable');
    if (values) {
      let nLuceMediaCampate = 0;
      let nLuceMaxCampate = 0;
      values?.map(el => {
        nLuceMediaCampate += parseFloat(el.luceCampata);
        /* if (el.luceCampata > nLuceMaxCampate) {
          nLuceMaxCampate = el.luceCampata;
        } */
      });
      const luceMaxValues = values?.map(el => parseFloat(el.luceCampata));
      nLuceMaxCampate = Math.max(...luceMaxValues);

      nLuceMediaCampate = nLuceMediaCampate / values?.length;
      if (!isNaN(nLuceMediaCampate)) {
        setValue('luceMediaCampate', nLuceMediaCampate);
      }
      nLuceMaxCampate === 0 || !watchNumCampate || watchNumCampate === '0'
        ? setValue('luceMassimaCampate', '')
        : setValue('luceMassimaCampate', nLuceMaxCampate);
    }
  }, [watchCampataTable]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const selectedValue = getValues('campataTable');
      setSelected(selectedValue);
      setValue('campataTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('campataTableValue', []);
    }
  };

  const handleAddCampate = i => {
    addCampate({
      id: uuidv4(),
      campata: 'C' + i,
      schema: '',
      luceCampata: '',
      enteScavalcato: '',
      luceIdraulica: '',
    });
  };

  useEffect(() => {
    const total = watchCampataTable?.length || 0;

    if (!parseInt(watchNumCampate)) {
      removeCampate();
    } else {
      updateTableRows(
        parseInt(watchNumCampate),
        total,
        removeCampate,
        handleAddCampate
      );
    }
  }, [watchNumCampate]);

  useEffect(() => {
    if (!isNullOrUndefinedOrEmptyString(watchNumCampate)) {
      setNumCampateAutocompleteRenderValue({
        label: parseInt(watchNumCampate),
        value: parseInt(watchNumCampate),
      });
    }
  }, []);

  return (
    <GridWrap container spacing={2}>
      <InfoWrapper
        isDivider={true}
        size={12}
        infoDrawer={true}
        drawerTitle={INFO.creazioneSchemaPonteAccordion10.drawerTitle}
        drawerImages={INFO.creazioneSchemaPonteAccordion10.drawerImages}
        drawerText={INFO.creazioneSchemaPonteAccordion10.drawerText}
      >
        <Divider>Caratteristiche geometriche</Divider>
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="luceComplessivaEstesa"
          label={'Luce complessiva (Estesa) [m]*'}
          defaultValue=""
          withValueLimit={({ value }) => value < 100000000}
          disabled={levelL1 || inspectionDisabled || isViewingInspection}
        />
      </GridWrap>
      {!levelL1 && (
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="luceComplessivaSpalla"
            label={'Luce complessiva (spalla-spalla) [m]'}
            defaultValue=""
            withValueLimit={({ value }) => value < 100000000}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      )}
      {!levelL1 && (
        <>
          <GridWrap item xs={3}>
            <RHFSelect
              name="tracciato"
              label={'Tracciato'}
              defaultValue={''}
              disabled={inspectionDisabled || isViewingInspection}
              selectOptions={[
                {
                  label: 'Rettilineo',
                  value: Tracciato.Rettilineo,
                },
                {
                  label: 'In curva',
                  value: Tracciato.InCurva,
                },
              ]}
            />
          </GridWrap>

          <InfoWrapper
            size={3}
            infoMessage={INFO.altezzaUtileSottoImpalcato.infoMessage}
          >
            <RHFNumberTextField
              name="altezzaUtileSottoImpalcato"
              label={'Altezza utile sotto impalcato [m]*'}
              defaultValue=""
              disabled={inspectionDisabled || isViewingInspection}
              withValueLimit={({ value }) => value < 1000}
            />
          </InfoWrapper>
          <GridWrap item xs={3}>
            <RHFNumberTextField
              name="larghezzaTotaleImpalcato"
              label={'Larghezza totale impalcato [m]*'}
              defaultValue=""
              disabled={inspectionDisabled || isViewingInspection}
              withValueLimit={({ value }) => value < 1000}
            />
          </GridWrap>
        </>
      )}
      <GridWrap item xs={3}>
        <Autocomplete
          disablePortal
          disableClearable
          disabled={inspectionDisabled || isViewingInspection || levelL1}
          id="combo-box-demo"
          options={[
            { value: '', label: 'Nessuna Campata' },
            ...Array.from(Array(166).keys()).map(el => {
              return {
                value: el,
                label: el,
              };
            }),
          ]}
          fullWidth
          renderInput={params => (
            <TextField {...params} label="N° campate (max 165)*" />
          )}
          value={numCampateAutocompleteRenderValue}
          onChange={(e, v) => handleChangeCampate(v.value)}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="luceMediaCampate"
          label={'Luce media campate [m]*'}
          withValueLimit={({ value }) => value < 1000}
          defaultValue=""
          disabled
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="luceMassimaCampate"
          label={'Luce massima campate [m]*'}
          withValueLimit={({ value }) => value < 1000}
          defaultValue=""
          disabled
        />
      </GridWrap>
      <>
        {parseInt(watchNumCampate) !== 0 &&
          !isNullOrUndefinedOrEmptyString(watchNumCampate) && (
            <>
              <GridWrap item xs={12}>
                <Typography variant="h3BoldBlue">CAMPATE</Typography>
              </GridWrap>
              {!levelL1 && !inspectionDisabled && !isViewingInspection && (
                <CampateForm
                  selected={selected}
                  setSelected={setSelected}
                  campateFields={watch('campataTable') || []}
                />
              )}
              <GridWrap item xs={12}>
                <Table
                  data={watch('campataTable') || []}
                  config={dataConfig}
                  hasCheckbox={
                    !levelL1 && !inspectionDisabled && !isViewingInspection
                  }
                  rowActions={
                    !levelL1 && !inspectionDisabled && !isViewingInspection
                      ? [
                          {
                            onClick: item => {
                              editRows(item);
                              setSelected([item]);
                            },
                            icon: (
                              <div className={`${tour_09.step_03}`}>
                                <EditIcon />
                              </div>
                            ),
                          },
                        ]
                      : []
                  }
                  selected={selected}
                  onSelectAllClick={handleSelectAllClick}
                  setSelected={setSelected}
                />
              </GridWrap>
              {!isViewingInspection && (
                <GridWrap item xs={12}>
                  <LocalTourWrapper steps={steps} />
                </GridWrap>
              )}
            </>
          )}
      </>
      <BasicDialog
        title="Attenzione"
        open={open}
        actions={
          <>
            <Button onClick={() => setOpen(false)}>Annulla</Button>
            <Button
              variant="contained"
              onClick={() => confirmChangeNumCampate()}
            >
              Conferma
            </Button>
          </>
        }
        onClose={() => setOpen(false)}
      >
        Diminuendo il numero di campate saranno rimosse anche le corrispettive
        pile e tutti gli elementi e sotto elementi al loro interno. Tutti i dati
        popolati inerenti a questi elementi saranno definitivamente eliminati.
        Vuoi davvero procedere?
      </BasicDialog>
    </GridWrap>
  );
};

Accordion1CaratteristicheGeometriche.propTypes = {
  levelL1: PropTypes.bool,
};

export { Accordion1CaratteristicheGeometriche };
