export const markdown_faq_4_1 = `La prima operazione da fare, per poi inserire tutti i dati relativi all'opera, è quella di creare uno spazio dedicato al tuo ponte all'interno del database. Per far questo, nella sezione **PONTI**, seleziona il tasto *Nuova opera*.

ATTENZIONE: Questa funzione è disponibile solo per utenti con una sottoscrizione attiva e può essere fatta solo da piattaforma web.`;
export const markdown_faq_4_2 = `La piattaforma chiede di inserire il nome del ponte e le informazioni di localizzazione, dati minimi per visualizzare il ponte sulla mappa e poter successivamente avviare nuove attività.

Per definire le **coordinate geografiche** potrai anche cliccare direttamente sulla mappa.

Una volta inseriti i dati seleziona *Aggiungi opera*.`;
export const markdown_faq_4_3 = `Avrai così creato il "contenitore" dove immagazzinare tutte le sue informazioni e avviare le specifiche attività.
Troverai il Ponte localizzato sulla mappa e troverai la sua Card riassuntiva nella sezione Ponti.`;
