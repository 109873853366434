import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaPavimentazioneOptions } from 'constants/selectOptions';

const PavimentazioneForm = ({
  pavimentazioneFields,
  selected,
  setSelected,
  pavimentazioneTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      pavimentazioneTableValue: [],
      tipologiaPavimentazioneTableValue: '',
    },
  });

  useEffect(() => {
    setValue('pavimentazioneTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetPavimentazione();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitPavimentazione = () => {
    const tipologiaPavimentazioneTableValue = getValues(
      'tipologiaPavimentazioneTableValue'
    );

    const ids = selected.map(el => el.codice);

    const pavimentazione = [...pavimentazioneFields];

    pavimentazione.map((x, index) => {
      if (ids.includes(x?.codice)) {
        pavimentazione[index] = {
          ...x,
          tipologiaPavimentazione: tipologiaPavimentazioneTableValue,
        };
      }
    });

    setValue(pavimentazioneTable, pavimentazione);
    onResetPavimentazione();
  };

  const onResetPavimentazione = () => {
    setValue('pavimentazioneTableValue', []);
    setValue('tipologiaPavimentazioneTableValue', '');
    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <GridWrap item xs={3}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-pavimentazione"
          name={'pavimentazioneTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={pavimentazioneFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Pavimentazione selezionata"
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFSelect
          name="tipologiaPavimentazioneTableValue"
          label="Tipologia Pavimentazione*"
          defaultValue={''}
          onChange={e => {
            setValue('tipologiaPavimentazioneTableValue', e.target.value);
          }}
          selectOptions={tipologiaPavimentazioneOptions}
        />
      </GridWrap>
      <GridWrap item xs={6} alignSelf="center">
        <FormSubmitButtons
          onSubmit={onSubmitPavimentazione}
          onReset={onResetPavimentazione}
        />
      </GridWrap>
    </GridWrap>
  );
};
PavimentazioneForm.propTypes = {
  pavimentazioneFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaPavimentazione: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  pavimentazioneTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default PavimentazioneForm;
