export const markdown_faq_31_1 = `La piattaforma INBEE funge anche da **archivio digitale**. Al suo interno sono predisposte sezioni specifiche per la raccolta dei file, nelle quali sono state sviluppate funzionalità che ne **semplificano la ricerca**.

I file vengono divisi tra Immagini e Documenti, a cui corrispondono le due rispettive sezioni sia della piattaforma che all'interno della scheda del ponte.`;
export const markdown_faq_31_2 = `I file sono automaticamente suddivisi in **categorie** e per ognuna è possibile trovare **filtri** diversi.
Per le immagini avrai a disposizione: Contesto, Struttura, Elementi accessori, Servizi, Difetti, Idraulica, Frane.
Per i documenti avrai a disposizione: Amministrativi, Progetto, Attività pregresse, Idraulica, Frane, Elaborati grafici, Report.`;
export const markdown_faq_31_3 = `**COME RICERCARE I FILE**
In queste categorie è possibile effettuare ricerche mirate tramite l'utilizzo dei **filtri** e dei **tag** associati in fase di caricamento dei file.
1. Inserire i filtri della ricerca
2. Selezionare l'icona della lente.

In questo modo l'elenco sarà aggiornato proponendo i file sulla base degli input di ricerca.`;
