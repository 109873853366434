import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { colors } from '../../constants/colors';

export const InfoBtnContainer = styled('div')(() => ({
  width: 36,
  height: 12,
  backgroundColor: colors.BLUE,
  textTransform: 'uppercase',
  padding: 7,
  color: colors.WHITE,
  borderRadius: 3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const MessageInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  marginTop: 20,
  backgroundColor: theme.palette.background.default,
  borderRadius: 3,
  padding: '10px 8px',
  flexDirection: 'column',
}));

export const MessagePopUpContainer = styled('div')({
  width: '90%',
  marginLeft: 10,
});

export const MessageBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '15px',
});

export const InfoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
});

export const ImageIcon = styled('img')({
  marginRight: '5px',
});

export const ImageDifetto = styled('img')({
  width: '90px',
  height: '90px',
  marginTop: '8px',
  cursor: 'pointer',
});

export const ZoomImgContainer = styled('div')({
  position: 'absolute',
  top: 76,
  left: 0,
  zIndex: 1,
  padding: 40,
  backgroundColor: colors.LIGHTGREY,
  height: 'calc(100% - 76px)',
  width: '100%',
});

export const ZoomFlexContainer = styled('div')({
  marginTop: 30,
});

export const ZoomCloseIconContainer = styled('div')({
  position: 'absolute',
  borderRadius: '50%',
  top: 123,
  right: 360,
  cursor: 'pointer',
});

export const ZommedImageDifetto = styled('img')({
  width: '65%',
  padding: 16,
  borderRadius: 8,
  backgroundColor: colors.WHITE,
});

export const ButtonContainer = styled(Button)({
  float: 'right',
});

export const BoxContainer = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  height: 'auto',
  background: theme.palette.secondary.light,
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  margin: '10px 0',
}));

export const Difettosita = styled('div')(({ theme }) => ({
  width: '43px',
  height: '28px',
  background: theme.palette.primary.main,
  borderRadius: '14px',
  opacity: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '10px 0',
}));

export const BoxDifetti = styled('div')({
  display: 'flex',
});

export const List = styled('ul')({
  paddingInlineStart: '40px',
});
