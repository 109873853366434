import { Grid, Typography } from '@mui/material';
import { useEffect, useReducer, useState } from 'react';
import { useFetch } from 'use-http';
import { HeaderPage } from 'components';
import { ColumnsChart } from 'components/Graphics/ColumnsChart';
import { HorizontalBarChart } from 'components/Graphics/HorizontalBarChart';
import { MapChart } from 'components/Graphics/MapChart';
import { apiAnalytics } from 'constants/api';
import { useGlobalSelector } from 'stores';
import { GraphicContainer } from './Analytics.styles';

const cdaConfig = {
  lowCdA: {
    label: 'Cda Bassa',
    color: '#99c567',
  },
  lowMediumCdA: {
    label: 'CdA Medio-Bassa',
    color: '#c7deb5',
  },
  mediumCdA: {
    label: 'CdA Media',
    color: '#ffe695',
  },
  mediumHighCdA: {
    label: 'CdA Medio-Alta',
    color: '#fdcb37',
  },
  highCdA: {
    label: 'CdA Alta',
    color: '#f0878a',
  },
  noData: {
    label: 'Dati insufficienti',
    color: '#bfbfbf',
  },
};

const riskCriticalConfig = {
  criticalELements: {
    label: 'Elementi critici',
    color: '#DBE6FF',
  },
  criticalCondition: {
    label: 'Condizioni critiche',
    color: '#DBE6FF',
  },
  structureSpecialInspection: {
    label: `Ispezioni speciali struttura`,
    color: '#DBE6FF',
  },
  landslideRisk: {
    label: 'Rischio frane',
    color: '#ffe695',
  },
  landslideRiskSpecialInspection: {
    label: 'Ispezioni speciali frane',
    color: '#ffe695',
  },
  hydraulicRisk: {
    label: 'Rischio idraulico',
    color: '#c7deb5',
  },
  hydraulicRiskSpecialInspection: {
    label: 'Ispezioni speciali idraulica',
    color: '#c7deb5',
  },
  interventionsAccessoryElements: {
    label: 'Interventi elementi accessori',
    color: '#FACCD5',
  },
  interventionsServices: {
    label: 'Interventi servizi',
    color: '#FACCD5',
  },
};

const initialState = {
  cdaData: null,
  cdaError: null,
  monitorAspectsData: null,
  monitorAspectsError: null,
  risksData: null,
  risksError: null,
  regionsData: null,
  regionsError: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
        [action.type]: action.data,
      };
  }
};

const Analytics = () => {
  const { get } = useFetch();

  const { showLoader, hideLoader } = useGlobalSelector();

  //State
  const [state, dispatch] = useReducer(reducer, initialState);

  const getAnalytics = async () => {
    const promises = [
      getAnalyticsCdA(),
      getAnalyticsMonitorAspects(),
      getAnalyticsRisks(),
      getAnalyticRegions(),
    ];
    showLoader();
    await Promise.all(promises);
    hideLoader();
  };

  const getAnalyticsCdA = async () => {
    try {
      const res = await get(`${apiAnalytics.CDA}`);
      dispatch({ type: 'cdaData', data: res });
    } catch (err) {
      dispatch({ type: 'cdaError', data: true });
      console.error('Error in put diagnosis: ', err);
    }
  };

  const getAnalyticsMonitorAspects = async () => {
    try {
      const res = await get(`${apiAnalytics.MONITORING}`);
      dispatch({ type: 'monitorAspectsData', data: res });
    } catch (err) {
      dispatch({ type: 'monitorAspectsError', data: true });
      console.error('Error in put diagnosis: ', err);
    }
  };

  const getAnalyticsRisks = async () => {
    try {
      const res = await get(`${apiAnalytics.RISK}`);
      dispatch({ type: 'risksData', data: res });
    } catch (err) {
      dispatch({ type: 'risksError', data: true });
      console.error('Error in put diagnosis: ', err);
    }
  };

  const getAnalyticRegions = async () => {
    try {
      const res = await get(`${apiAnalytics.REGION}`);
      dispatch({ type: 'regionsData', data: res });
    } catch (err) {
      dispatch({ type: 'regionsError', data: true });
      console.error('Error in put diagnosis: ', err);
    }
  };

  useEffect(() => {
    getAnalytics();
  }, []);
  return (
    <>
      <HeaderPage title="Analytics" />

      <Grid container spacing={4}>
        <Grid item sm={6}>
          <GraphicContainer>
            <Typography variant={'h2'} sx={{ margin: '20px' }}>
              Ponti per regione
            </Typography>
            <MapChart
              chartID="region-chart"
              notAnimated
              dataRegion={state.regionsData}
              error={state.regionsError}
            />
          </GraphicContainer>
        </Grid>

        <Grid item sm={6}>
          <GraphicContainer>
            <Typography variant={'h2'} sx={{ margin: '20px' }}>
              Classi di attenzione
            </Typography>
            <ColumnsChart
              chartID="columns-chart-1"
              data={state.cdaData}
              error={state.cdaError}
              config={cdaConfig}
              isClustered
              hasLegend
            />
          </GraphicContainer>
        </Grid>

        <Grid item sm={6}>
          <GraphicContainer>
            <Typography variant={'h2'} sx={{ margin: '20px' }}>
              Rischi e criticità
            </Typography>
            <HorizontalBarChart
              chartID="chart"
              data={state.risksData}
              error={state.risksError}
              config={riskCriticalConfig}
              hasTooltip
            />
          </GraphicContainer>
        </Grid>

        <Grid item sm={6} sx={{ marginBottom: 5 }}>
          <GraphicContainer>
            <Typography variant={'h2'} sx={{ margin: '20px' }}>
              Aspetti da monitorare
            </Typography>
            <ColumnsChart
              chartID="monitoring-chart"
              data={state.monitorAspectsData}
              error={state.monitorAspectsError}
            />
          </GraphicContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default Analytics;
