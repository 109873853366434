import styled from '@emotion/styled';

export const Box = styled('div')(() => ({
  alignItems: 'baseline',
  display: 'flex',
  gap: '5px',
  justifyContent: 'flex-start',
}));

export const IconButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});
