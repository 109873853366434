import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFSelect, RHFTextField } from 'components';
import { UserType } from 'constants/users';

const userType = [
  {
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    label: 'Ente',
    value: 'ENTE',
  },
  {
    label: 'Ispettore',
    value: 'PROFESSIONISTA',
  },
];

const userTypology = [
  {
    label: 'Utente Principale',
    value: 1,
  },
  {
    label: 'Collaboratore interno',
    value: 2,
  },
];
/* const userTypologyInstitution = [
  {
    label: 'Utente Principale',
    value: 1,
  },
  {
    label: 'Collaboratore interno',
    value: 2,
  },
];
const userTypologyInspector = [
  {
    label: 'Collaboratore interno',
    value: 2,
  },
  {
    label: 'Account principale',
    value: 1,
  },
];
 */
const DefaultForm = ({ editForm }) => {
  const { watch, setValue } = useFormContext();

  const watchType = watch('type');

  /*   useEffect(() => {
    if (!watchType) {
      setValue('name', '');
      setValue('surname', '');
      setValue('email', '');
      setValue('tipologiaUtente', '');
    }

  }, [watchType]); */

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={!watchType || watchType === UserType.Admin ? 6 : 3}>
        <RHFSelect
          name="type"
          label={'Tipo utente*'}
          defaultValue={''}
          selectOptions={userType}
        />
      </Grid>
      {watchType && watchType !== 'ADMIN' && (
        <Grid item xs={3}>
          <RHFSelect
            name="tipologiaUtente"
            label={'Tipologia utente*'}
            defaultValue={''}
            selectOptions={
              userTypology
              /* watchType === 'ENTE'
                ? userTypologyInstitution
                : userTypologyInspector */
            }
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <RHFTextField
          style={{ flex: 1 }}
          label="Email d'accesso*"
          name="email"
          defaultValue={''}
          disabled={editForm}
        />
      </Grid>
      <Grid item xs={6}>
        <RHFTextField label="Nome*" name="name" defaultValue={''} />
      </Grid>
      <Grid item xs={6}>
        <RHFTextField label="Cognome*" name="surname" defaultValue={''} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '20px' }}>
        <RHFTextField label="Note In Bee" name="notes" defaultValue={''} />
      </Grid>
    </Grid>
  );
};

export default DefaultForm;

DefaultForm.propTypes = {
  editForm: PropTypes.bool,
};
