import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { CardOverview, MaterialBox } from 'components';
import { STRUCTURES_PATHS } from 'constants/paths';
import { DifettositaSovrastruttureEServiziOptions } from 'constants/selectOptions';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import { handleDynamicInspectionsTabChange } from 'utils/utilities';
import { Row } from '../TabL1.styles';

const Sovrastruttura = ({ overviewL1 }) => {
  const params = useParams();
  const id = params.structureId;
  const navigate = useNavigate();

  const lastInspectionId = overviewL1?.requestUserLastValidInspectionId;

  const pavimentazione =
    overviewL1?.sovrastruttura?.pavimentazioni?.difettosita;
  const cordolo = overviewL1?.sovrastruttura?.cordoli?.difettosita;
  const marciapiede = overviewL1?.sovrastruttura?.marciapiedi?.difettosita;
  const parapetto = overviewL1?.sovrastruttura?.parapetti?.difettosita;
  const guardavia = overviewL1?.sovrastruttura?.guardavie?.difettosita;
  const spartitraffico =
    overviewL1?.sovrastruttura?.spartitraffico?.difettosita;

  return (
    <CardOverview
      title={'Elementi accessori'}
      subtitle={`Dettagli di difetti e non conformità`}
      buttonText={'Approfondisci'}
      buttonDisabled={lastInspectionId ? false : true}
      height="auto"
      onClick={() =>
        handleDynamicInspectionsTabChange(
          inspectionTabMapping.L1,
          inspectionTabMapping.ElementiAccessori,
          null,
          navigate,
          STRUCTURES_PATHS.STRUCTURES_LIST,
          id,
          lastInspectionId
        )
      }
    >
      <Row
        style={{
          flexWrap: 'wrap',
          marginTop: 12,
        }}
      >
        <MaterialBox
          topic={'Pavimentazione'}
          defect={pavimentazione}
          enumOptions={DifettositaSovrastruttureEServiziOptions}
        />
        <MaterialBox
          topic={'Cordolo'}
          defect={cordolo}
          enumOptions={DifettositaSovrastruttureEServiziOptions}
        />
        <MaterialBox
          topic={'Marciapiede'}
          defect={marciapiede}
          enumOptions={DifettositaSovrastruttureEServiziOptions}
        />
        <MaterialBox
          topic={'Parapetto'}
          defect={parapetto}
          enumOptions={DifettositaSovrastruttureEServiziOptions}
        />
        <MaterialBox
          topic={'Guardavia'}
          defect={guardavia}
          enumOptions={DifettositaSovrastruttureEServiziOptions}
        />
        <MaterialBox
          topic={'Spartitraffico'}
          defect={spartitraffico}
          enumOptions={DifettositaSovrastruttureEServiziOptions}
        />
      </Row>
    </CardOverview>
  );
};

export { Sovrastruttura };

Sovrastruttura.propTypes = {
  overviewL1: PropTypes.obj,
};
