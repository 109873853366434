import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { colors } from 'constants/colors';

export const CardContainer = styled('div')(({ color, borderpx }) => ({
  backgroundColor: colors.LIGHTGREY,
  /* height: '100%', */
  marginTop: '10px',
  backgroundImage: `repeating-linear-gradient(-43deg, ${color}, ${color} 3.8px, transparent 4px, transparent 5.85px, ${color} 7px)`,
  backgroundSize: `${borderpx} 100%,100% 0px,0 0,0 0`,
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  /* marginRight: '4px', */
  marginRight: 10,
  marginLeft: 10,
  borderRadius: '8px 8px 0 0',
  flexGrow: 0,
}));

export const CustomTextField = styled(TextField)({
  width: '80%',
  height: '40px',
  margin: '15px',
});

export const CardBox = styled('div')(({ half, sectionColorCdA }) => ({
  display: 'flex',
  flexDirection: 'column',
  /* width: half ? 'calc(100% /6)' : 'calc(100% /3)', */
  flex: half ? 0.5 : 1,
  /* height: '100%', */
  /* borderLeft: sectionColorCdA && `13px solid ${sectionColorCdA}`, */
}));

export const TypoContainer = styled('div')({
  margin: '5px 15px',
});
