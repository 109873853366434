import { useFormContext } from 'react-hook-form';
import {
  AppFields,
  WebL0Fields,
  WebL1Fields,
} from 'constants/inspectionFields';
import { RischioFrana, RischioIdraulico } from 'constants/inspections';

export const useCompletionInspection = () => {
  const { getValues } = useFormContext();

  /**
   *
   * @param fieldNames array di campi
   * @returns il numero di elementi compilati
   */
  const updateIndicator = fieldNames => {
    let counter = 0;

    function deepIndicatorFieldNames(field, value) {
      const condition = field.condition ? eval(field.condition) : true;
      if (condition) {
        if (field.isTable) {
          const tmp = value;
          tmp?.forEach(el => {
            if (el?.assente) {
              return counter++;
            }
            //per ogni elemento dell'array delle tabelle
            if (field.subfields) {
              field.subfields.forEach(sub => {
                //per ogni subfield del campo attuale
                if (sub.fieldName === 'schedaDifetti') {
                  //se il subfield corrente è la schedaDifetti controllo che sia al 100%
                  const completamentoSchedaDifetti =
                    el.completamentoSchedaDifetti
                      ? Number(el.completamentoSchedaDifetti.replace('%', ''))
                      : 0;
                  if (completamentoSchedaDifetti === 100) {
                    counter++;
                  }
                } else {
                  //altrimenti continuo ad iterare il subfield passando il nuova value
                  deepIndicatorFieldNames(sub, el[sub.fieldName]);
                }
              });
            } else {
              //se il field non ha subfields controllo che il campo sia valorizzato
              checkIfIsNotEmpty(el[field.fieldName]) && counter++;
            }
          });
        } else {
          //fix per gli elementi di aspetti critici che possono avere lo 0 (nessun valore)
          if (
            AppFields.Struttura.aspettiCritici.find(
              el => el.fieldName === field.fieldName
            )
          ) {
            checkIfIsNotEmpty(value === 0 ? undefined : value) && counter++;
          } else {
            checkIfIsNotEmpty(value) && counter++;
          }
        }
      }
    }

    fieldNames.forEach(el => {
      //per ogni campo mi prendo il valore sulla form
      const value = getValues(el.fieldName);
      if (
        fieldNames === AppFields.Contesto.rischioFrana ||
        fieldNames === WebL1Fields.Contesto.rischioFrana
      ) {
        const rischio = getValues('rischioFrana');
        const show =
          rischio === RischioFrana.DaVerificare ||
          rischio === RischioFrana.Presente;
        if (!show) {
          return 0;
        } else {
          //e vado ad iterare
          deepIndicatorFieldNames(el, value);
        }
      } else if (
        fieldNames === AppFields.Contesto.rischioIdraulico ||
        fieldNames === WebL1Fields.Contesto.rischioIdraulico
      ) {
        const richio = getValues('rischioIdraulico');
        const show =
          richio === RischioIdraulico.DaVerificare ||
          richio === RischioIdraulico.Presente;
        if (!show) {
          return 0;
        } else {
          //e vado ad iterare
          deepIndicatorFieldNames(el, value);
        }
      } else {
        //e vado ad iterare
        deepIndicatorFieldNames(el, value);
      }
    });

    return counter;
  };

  /**
   *
   * @param fieldNames array di campi
   * @returns il numero di elementi da compilari
   */
  const getFieldsCount = fieldNames => {
    let counter = 0;
    function deepFieldNames(field, array) {
      const condition = field.condition ? eval(field.condition) : true;
      if (condition) {
        if (field.isTable) {
          const tmp = array;
          tmp?.forEach(el => {
            if (el?.assente) {
              return counter++;
            }
            if (field.subfields) {
              field.subfields.forEach(sub => {
                deepFieldNames(sub, el[sub.fieldName]);
              });
            } else {
              counter++;
            }
          });
        } else {
          counter++;
        }
      }
    }

    fieldNames.forEach(el => {
      const value = getValues(el.fieldName);

      //check se il rischio è assente => restituisco 0
      //TODO: check per il web
      if (
        fieldNames === AppFields.Contesto.rischioFrana ||
        fieldNames === WebL1Fields.Contesto.rischioFrana
      ) {
        const rischio = getValues('rischioFrana');
        const show =
          rischio === RischioFrana.DaVerificare ||
          rischio === RischioFrana.Presente;
        if (!show) {
          return 0;
        } else {
          deepFieldNames(el, value);
        }
      } else if (
        fieldNames === AppFields.Contesto.rischioIdraulico ||
        fieldNames === WebL1Fields.Contesto.rischioIdraulico
      ) {
        const rischio = getValues('rischioIdraulico');
        const show =
          rischio === RischioIdraulico.DaVerificare ||
          rischio === RischioIdraulico.Presente;
        if (!show) {
          return 0;
        } else {
          deepFieldNames(el, value);
        }
      } else {
        deepFieldNames(el, value);
      }
    });

    return counter;
  };

  /**
   *
   * @returns la percentuale di completamento dell'ispezione app
   */
  const getAppCompletionPercentage = () => {
    const values = Object.values(AppFields) || [];
    const result = platformCompletionPercentage(values);
    return result;
  };

  function platformCompletionPercentage(values) {
    let counter = 0;
    let allFieldsCounter = 0;
    values?.forEach(val => {
      const fields = Object.values(val) || [];

      fields?.forEach(fieldArray => {
        if (fieldArray.length > 0) {
          try {
            //per ogni array di fields di ogni accordion mi calcolo
            //il numero totale di campi compilabili e quelli effettivamente compilati
            allFieldsCounter += getFieldsCount(fieldArray);
            counter += updateIndicator(fieldArray);
          } catch (error) {
            console.log(
              '🚀 ~ file: UpdateIndicatori.ts:340 ~ fields?.forEach ~ error',
              error
            );
            return 0;
          }
        }
      });
    });
    const completionPercentage = (counter / allFieldsCounter) * 100;

    return Math.floor(completionPercentage);
  }

  /**
   *
   * @returns percentuale di completamento ispezione web
   */
  const getWebCompletionPercentage = () => {
    const completionPercentageL0 = platformCompletionPercentage(
      Object.values(WebL0Fields) || []
    );
    const completionPercentageL1 = platformCompletionPercentage(
      Object.values(WebL1Fields) || []
    );
    const average = (completionPercentageL0 + completionPercentageL1) / 2;

    return Math.floor(average);
  };

  return {
    updateIndicator,
    getFieldsCount,
    getAppCompletionPercentage,
    getWebCompletionPercentage,
  };
};

function checkIfIsNotEmpty(value) {
  if (value !== undefined && value !== null && value !== '') {
    return true;
  }
  return false;
}
