import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import * as React from 'react';
import { colors } from 'constants/colors';

const LightTooltip = styled(({ className, customColor, ...props }) => (
  <Tooltip
    customColor={customColor}
    {...props}
    classes={{ popper: className }}
  />
))(({ customColor, theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    /* backgroundColor: theme.palette.common.white, */
    backgroundColor: customColor ? customColor : colors.LIGHTGREY,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    '& .MuiButtonBase-root-MuiButton-root': {
      padding: 0,
    },
    '& .MuiButtonBase-root-MuiButton-root:hover': {
      textDecoration: 'none',
    },
  },
}));

const CustomBtn = styled(Button)({
  padding: 0,
  height: 'auto',
  color: 'transparent',
  justifyContent: 'flex-start',
});

export const CustomTooltip = ({ title, btnText, customColor, position }) => {
  return (
    <LightTooltip
      title={title}
      customColor={customColor}
      placement={position ? position : 'left'}
    >
      <CustomBtn>{btnText}</CustomBtn>
    </LightTooltip>
  );
};

LightTooltip.propTypes = {
  customColor: PropTypes.string,
};

CustomTooltip.propTypes = {
  title: PropTypes.node,
  btnText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  position: PropTypes.string,
  customColor: PropTypes.string,
};
