import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  InfoWrapper,
  MediaUpload,
  RHFMultiSelect,
  RHFNumberTextField,
  RHFSelect,
  RHFTextField,
} from 'components';
import {
  CaratteristicheClassificazioneEnteScavalcato,
  ClasseDiConseguenza,
  ClasseUso,
  FieldType,
  LivelloConoscenza,
  MediaGroup,
  StatoOpera,
  TipologiaCollegamento,
  TipologiaEnteScavalcato,
  UploadMedia,
} from 'constants/inspections';
import { UserType } from 'constants/users.js';
import { useInspectionPath } from 'hooks/index.js';
import { useSubscription } from 'hooks/useSubscription.js';
import { useInspectionSelector } from 'stores';
import { GridWrap } from './Accordion1GeneralInfo.style.js';
import { INFO } from '../../../../config/info';

const Accordion1GeneralInfo = () => {
  const { watch, getValues, setValue } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { user } = useSubscription();
  const { isViewingInspection } = useInspectionPath();

  const watchTypoEnte = watch('tipologiaEnteScavalcato');
  const watchTypoEnteAltro =
    watchTypoEnte !== ''
      ? watchTypoEnte &&
        watchTypoEnte?.some(el => el === TipologiaEnteScavalcato.Altro)
      : false;

  const watchClasseConseguenza = watch('classeDiConseguenza');
  const watchCc2OrCc1 =
    watchClasseConseguenza === ClasseDiConseguenza.CC1 ||
    watchClasseConseguenza === ClasseDiConseguenza.CC2;

  useEffect(() => {
    const kmInit = getValues('progressivaKmIniziale');
    const kmEnd = getValues('progressiveKmFinale');
    if (kmInit && kmEnd) {
      setValue('estesa', kmEnd - kmInit);
    } else {
      setValue('estesa', '');
    }
  }, [watch('progressivaKmIniziale'), watch('progressiveKmFinale')]);

  useEffect(() => {
    if (!watchTypoEnteAltro && getValues('tipologiaEnteScavalcatoAltro')) {
      setValue('tipologiaEnteScavalcatoAltro', '');
    }
  }, [watchTypoEnte]);

  useEffect(() => {
    if (!watchCc2OrCc1 && getValues('spiegazioneConseguenza')) {
      setValue('spiegazioneConseguenza', '');
    }
  }, [watchClasseConseguenza]);

  return (
    <GridWrap container spacing={2} padding="20px">
      <InfoWrapper size={3} infoMessage={INFO.codiceInterno.infoMessage}>
        <RHFTextField
          name="codiceInterno"
          label={'Codice interno -ID*'}
          inputProps={{ maxLength: 30 }}
          disabled
          defaultValue=""
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFTextField
          name="codiceInternoProprietario"
          label={'Codice interno proprietario*'}
          inputProps={{ maxLength: 50 }}
          defaultValue=""
          disabled={inspectionDisabled || isViewingInspection}
        />
      </GridWrap>
      <InfoWrapper size={3} infoMessage={INFO.codiceIOP.infoMessage}>
        <RHFTextField
          name="codiceIOP"
          label={'Codice IOP'}
          // TEC-521
          // Caricare il codice IOP (solo per Utenti Principali o Collaboratori Interni di tipo Ente)
          disabled={
            // user?.type !== UserType.Ente ||
            inspectionDisabled || isViewingInspection
          }
          inputProps={{ maxLength: 18 }}
          defaultValue=""
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="tipologiaCollegamento"
          label={'Tipologia collegamento*'}
          disabled={inspectionDisabled || isViewingInspection}
          defaultValue={''}
          selectOptions={[
            {
              label: 'Ponte',
              value: TipologiaCollegamento.Ponte,
            },
            {
              label: 'Viadotto',
              value: TipologiaCollegamento.Viadotto,
            },
          ]}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="nomeOpera"
          label={'Nome opera'}
          inputProps={{ maxLength: 150 }}
          defaultValue=""
          disabled={inspectionDisabled || isViewingInspection}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="stradaAppartenenza"
          label={'Strada di appartenenza*'}
          inputProps={{ maxLength: 50 }}
          defaultValue=""
          disabled={inspectionDisabled || isViewingInspection}
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.progressivaKmIniziale.drawerTitle}
        drawerText={INFO.progressivaKmIniziale.drawerText}
        drawerImages={INFO.progressivaKmIniziale.drawerImages}
      >
        <RHFNumberTextField
          name="progressivaKmIniziale"
          label={'Progressiva km iniziale*'}
          decimalScale={3}
          withValueLimit={({ value }) => value < 1000000000000}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.progressiveKmFinale.drawerTitle}
        drawerText={INFO.progressiveKmFinale.drawerText}
        drawerImages={INFO.progressiveKmFinale.drawerImages}
      >
        <RHFNumberTextField
          name="progressiveKmFinale"
          label={'Progressiva km finale*'}
          decimalScale={3}
          withValueLimit={({ value }) => value < 1000000000000}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
        />
      </InfoWrapper>
      <InfoWrapper size={3} infoMessage={INFO.estesa.infoMessage}>
        <RHFNumberTextField
          name="estesa"
          label={'Estesa(km)*'}
          decimalScale={3}
          withValueLimit={({ value }) => value < 1000000000000}
          disabled
          defaultValue=""
        />
      </InfoWrapper>

      <GridWrap item xs={3}>
        <RHFMultiSelect
          name="tipologiaEnteScavalcato"
          label={'Tipologia di ente scavalcato*'}
          defaultValue={[]}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: "Corso d'acqua",
              value: TipologiaEnteScavalcato.CorsoAcqua,
            },
            {
              label: "Specchio d'acqua marina",
              value: TipologiaEnteScavalcato.SpecchioAcquaMarina,
            },
            {
              label: 'Discontinuità orografica (vallata, piccoli canali, ecc.)',
              value: TipologiaEnteScavalcato.DiscontinuitaOrografica,
            },
            {
              label: 'Zona edificata',
              value: TipologiaEnteScavalcato.ZonaEdificata,
            },
            {
              label: 'Zona urbanizzata',
              value: TipologiaEnteScavalcato.ZonaUrbanizzata,
            },
            {
              label: 'Altra via di comunicazione',
              value: TipologiaEnteScavalcato.AltraViaDiComunicazione,
            },
            {
              label: 'Ferrovia',
              value: TipologiaEnteScavalcato.Ferrovia,
            },
            {
              label: 'Altro',
              value: TipologiaEnteScavalcato.Altro,
            },
          ]}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="tipologiaEnteScavalcatoAltro"
          label={'Specificare altro'}
          defaultValue=""
          inputProps={{ maxLength: 50 }}
          disabled={
            !watchTypoEnteAltro || inspectionDisabled || isViewingInspection
          }
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={
          INFO.caratteristicheClassificazioneEnteScavalcato.drawerTitle
        }
        drawerText={
          INFO.caratteristicheClassificazioneEnteScavalcato.drawerText
        }
      >
        <RHFSelect
          name="caratteristicheClassificazioneEnteScavalcato"
          defaultValue={''}
          label={'Rilevanza ente scavalcato*'}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'Prevede affollamenti e/o funzioni significative',
              value:
                CaratteristicheClassificazioneEnteScavalcato.PrevedeAffollamentiSignificativi,
            },
            {
              label: 'Prevede normali affollamenti, senza funzioni essenziali',
              value:
                CaratteristicheClassificazioneEnteScavalcato.PrevedeNormaliAffollamenti,
            },
            {
              label:
                'Prevede presenza occasionale di persone e privo di valore',
              value:
                CaratteristicheClassificazioneEnteScavalcato.PrevedePresenzaOccasionale,
            },
          ]}
        />
      </InfoWrapper>
      <GridWrap item xs={6}>
        <RHFTextField
          name="nomeEnteScavalcato"
          label={'Nome ente scavalcato*'}
          inputProps={{ maxLength: 150 }}
          disabled={inspectionDisabled || isViewingInspection}
          defaultValue=""
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.classeUso.drawerTitle}
        legislationMessage={INFO.classeUso.legislationMessage}
        drawerText={INFO.classeUso.drawerText}
      >
        <RHFSelect
          name="classeUso"
          label={"Classe d'uso*"}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'I',
              value: ClasseUso.Prima,
            },
            {
              label: 'II',
              value: ClasseUso.Seconda,
            },
            {
              label: 'III',
              value: ClasseUso.Terza,
            },
            {
              label: 'IV',
              value: ClasseUso.Quarta,
            },
          ]}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.classeDiConseguenza.drawerTitle}
        legislationMessage={INFO.classeDiConseguenza.legislationMessage}
        drawerText={INFO.classeDiConseguenza.drawerText}
      >
        <RHFSelect
          name="classeDiConseguenza"
          label={'Classe di conseguenza'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'CC3',
              value: ClasseDiConseguenza.CC3,
            },
            {
              label: 'CC2',
              value: ClasseDiConseguenza.CC2,
            },
            {
              label: 'CC1',
              value: ClasseDiConseguenza.CC1,
            },
          ]}
        />
      </InfoWrapper>
      <GridWrap item xs={6}>
        <RHFTextField
          name="spiegazioneConseguenza"
          label={'Spiegazione classe di conseguenza minore di CC3'}
          inputProps={{ maxLength: 250 }}
          defaultValue=""
          disabled={!watchCc2OrCc1 || inspectionDisabled || isViewingInspection}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFSelect
          name="statoOpera"
          label={"Stato dell'opera*"}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'A: Pienamente agibile',
              value: StatoOpera.A_Agibile,
            },
            {
              label:
                'B: Agibile ma con scadenze di lavori di manutenzione ordinaria',
              value: StatoOpera.B_ManutenzioneOrdinaria,
            },
            {
              label:
                'C: Agibile ma con scadenze di lavori di manutenzione straordinaria',
              value: StatoOpera.C_ManutenzioneStraordinaria,
            },
            {
              label:
                'D: Condizioni critiche e agibile parzialmente/lavori di manutenzione urgenti',
              value: StatoOpera.D_ManutenzioneUrgente,
            },
            {
              label: 'E: Inagibile',
              value: StatoOpera.E_Inagibile,
            },
          ]}
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoMessage={INFO.livelloConoscenza.infoMessage}
        legislationMessage={INFO.livelloConoscenza.legislationMessage}
        compassMessage={INFO.livelloConoscenza.compassMessage}
      >
        <RHFSelect
          name="livelloConoscenza"
          label={'Livello di conoscenza*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'LC0',
              value: LivelloConoscenza.LC0,
            },
            {
              label: 'LC1',
              value: LivelloConoscenza.LC1,
            },
            {
              label: 'LC2',
              value: LivelloConoscenza.LC2,
            },
            {
              label: 'LC3',
              value: LivelloConoscenza.LC3,
            },
          ]}
        />
      </InfoWrapper>

      <GridWrap item md={3}>
        <MediaUpload
          title={`Foto copertina *`}
          btnTitle="Allega file"
          dataType="image/*"
          fileLimit={1}
          preview={true}
          formName="fotoCopertina"
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          uploadType={UploadMedia.media}
          type={FieldType.images}
          mediaGroup={[MediaGroup.Contesto]}
        />
      </GridWrap>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Foto di inquadramento`}
          btnTitle="Allega file"
          dataType="video/*,image/*"
          preview={true}
          formName="fotoInquadramento"
          infoPanel="Vista dei due prospetti e dell'impalcato. Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          uploadType={UploadMedia.media}
          type={FieldType.images}
          tags={[
            'Su ferrovia',
            'Su strada',
            "Su corso d'acqua",
            'prospetto',
            'sede stradale',
            'in curva',
            'pile in alveo',
            'Arco',
            'Travate appoggiate',
            'Travate continue',
            'Ponti a telaio',
            'Travate Gerber',
            'Soletta',
            'Cassone',
            'Sezione tubolare',
            'C.a.p. a cavi post tesi',
            'Strallato',
            'Sospeso',
            'Pianura',
            'Montagna',
            'Collina',
            'Città',
          ]}
          mediaGroup={[MediaGroup.Contesto]}
        />
      </GridWrap>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Documenti di contesto`}
          btnTitle="Allega file"
          dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
          formName="documentiContesto"
          infoPanel="Es: zonazioni da Enti locali, relazioni geologiche/MASW"
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
        />
      </GridWrap>
    </GridWrap>
  );
};

export { Accordion1GeneralInfo };
