import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import {
  schemaPonteCommonOptions,
  //schemaPontePavimentazioneOptions,
  schemaPonteSpartitrafficoOptions,
} from 'constants/selectOptions';
const SchemaPonteForm = ({
  schemaPonteFields,
  selected,
  setSelected,
  schemaPonteTable,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      l0t3a1_mensoleTamponiCampateTableValue: [],
      l0t3a1_cordoloSXMensoleTamponiCampateTableValue: '',
      l0t3a1_cordoloDXMensoleTamponiCampateTableValue: '',
      l0t3a1_marciapiedeSXMensoleTamponiCampateTableValue: '',
      l0t3a1_marciapiedeDXMensoleTamponiCampateTableValue: '',
      l0t3a1_parapettoSXMensoleTamponiCampateTableValue: '',
      l0t3a1_parapettoDXMensoleTamponiCampateTableValue: '',
      l0t3a1_guardaviaSXMensoleTamponiCampateTableValue: '',
      l0t3a1_guardaviaDXMensoleTamponiCampateTableValue: '',
      l0t3a1_spartitrafficoMensoleTamponiCampateTableValue: '',
      l0t3a1_pavimentazioneMensoleTamponiCampateTableValue: '',
    },
  });

  useEffect(() => {
    setValue('l0t3a1_mensoleTamponiCampateTableValue', selected);
  }, [selected]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitSchemaPonte = () => {
    const l0t3a1_cordoloSXMensoleTamponiCampateTableValue = getValues(
      'l0t3a1_cordoloSXMensoleTamponiCampateTableValue'
    );
    const l0t3a1_cordoloDXMensoleTamponiCampateTableValue = getValues(
      'l0t3a1_cordoloDXMensoleTamponiCampateTableValue'
    );
    const l0t3a1_marciapiedeSXMensoleTamponiCampateTableValue = getValues(
      'l0t3a1_marciapiedeSXMensoleTamponiCampateTableValue'
    );
    const l0t3a1_marciapiedeDXMensoleTamponiCampateTableValue = getValues(
      'l0t3a1_marciapiedeDXMensoleTamponiCampateTableValue'
    );
    const l0t3a1_parapettoSXMensoleTamponiCampateTableValue = getValues(
      'l0t3a1_parapettoSXMensoleTamponiCampateTableValue'
    );
    const l0t3a1_parapettoDXMensoleTamponiCampateTableValue = getValues(
      'l0t3a1_parapettoDXMensoleTamponiCampateTableValue'
    );
    const l0t3a1_guardaviaSXMensoleTamponiCampateTableValue = getValues(
      'l0t3a1_guardaviaSXMensoleTamponiCampateTableValue'
    );
    const l0t3a1_guardaviaDXMensoleTamponiCampateTableValue = getValues(
      'l0t3a1_guardaviaDXMensoleTamponiCampateTableValue'
    );
    const l0t3a1_spartitrafficoMensoleTamponiCampateTableValue = getValues(
      'l0t3a1_spartitrafficoMensoleTamponiCampateTableValue'
    );
    const l0t3a1_pavimentazioneMensoleTamponiCampateTableValue = getValues(
      'l0t3a1_pavimentazioneMensoleTamponiCampateTableValue'
    );

    const ids = selected.map(el => el.codice);

    const mensoleTamponeCampate = [...schemaPonteFields];

    mensoleTamponeCampate.map((x, index) => {
      if (ids.includes(x?.codice)) {
        mensoleTamponeCampate[index] = {
          id: x.id,
          codice: x.codice,
          cordoloSX: l0t3a1_cordoloSXMensoleTamponiCampateTableValue,
          cordoloDX: l0t3a1_cordoloDXMensoleTamponiCampateTableValue,
          marciapiedeSX: l0t3a1_marciapiedeSXMensoleTamponiCampateTableValue,
          marciapiedeDX: l0t3a1_marciapiedeDXMensoleTamponiCampateTableValue,
          parapettoSX: l0t3a1_parapettoSXMensoleTamponiCampateTableValue,
          parapettoDX: l0t3a1_parapettoDXMensoleTamponiCampateTableValue,
          guardaviaSX: l0t3a1_guardaviaSXMensoleTamponiCampateTableValue,
          guardaviaDX: l0t3a1_guardaviaDXMensoleTamponiCampateTableValue,
          spartitraffico: l0t3a1_spartitrafficoMensoleTamponiCampateTableValue,
          pavimentazione: l0t3a1_pavimentazioneMensoleTamponiCampateTableValue,
        };
      }
    });

    setValue(schemaPonteTable, mensoleTamponeCampate);
  };

  const onResetSchemaPonte = () => {
    setValue('l0t3a1_mensoleTamponiCampateTableValue', []);
    setValue('l0t3a1_cordoloSXMensoleTamponiCampateTableValue', '');
    setValue('l0t3a1_cordoloDXMensoleTamponiCampateTableValue', '');
    setValue('l0t3a1_marciapiedeSXMensoleTamponiCampateTableValue', '');
    setValue('l0t3a1_marciapiedeDXMensoleTamponiCampateTableValue', '');
    setValue('l0t3a1_parapettoSXMensoleTamponiCampateTableValue', '');
    setValue('l0t3a1_parapettoDXMensoleTamponiCampateTableValue', '');
    setValue('l0t3a1_guardaviaSXMensoleTamponiCampateTableValue', '');
    setValue('l0t3a1_guardaviaDXMensoleTamponiCampateTableValue', '');
    setValue('l0t3a1_spartitrafficoMensoleTamponiCampateTableValue', '');
    setValue('l0t3a1_pavimentazioneMensoleTamponiCampateTableValue', '');

    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <GridWrap item xs={6}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-mensoleTamponiCampate-l0t3a1"
          name={'l0t3a1_mensoleTamponiCampateTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={schemaPonteFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Elementi selezionati"
        />
      </GridWrap>
      <GridWrap item xs={1.5}>
        <RHFSelect
          name="l0t3a1_cordoloSXMensoleTamponiCampateTableValue"
          label="Cordolo SX*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a1_cordoloSXMensoleTamponiCampateTableValue',
              e.target.value
            );
          }}
          selectOptions={schemaPonteCommonOptions}
        />
      </GridWrap>
      <GridWrap item xs={1.5}>
        <RHFSelect
          name="l0t3a1_cordoloDXMensoleTamponiCampateTableValue"
          label="Cordolo DX*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a1_cordoloDXMensoleTamponiCampateTableValue',
              e.target.value
            );
          }}
          selectOptions={schemaPonteCommonOptions}
        />
      </GridWrap>
      <GridWrap item xs={1.5}>
        <RHFSelect
          name="l0t3a1_marciapiedeSXMensoleTamponiCampateTableValue"
          label="Marciapiede SX*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a1_marciapiedeSXMensoleTamponiCampateTableValue',
              e.target.value
            );
          }}
          selectOptions={schemaPonteCommonOptions}
        />
      </GridWrap>
      <GridWrap item xs={1.5}>
        <RHFSelect
          name="l0t3a1_marciapiedeDXMensoleTamponiCampateTableValue"
          label="Marciapiede DX*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a1_marciapiedeDXMensoleTamponiCampateTableValue',
              e.target.value
            );
          }}
          selectOptions={schemaPonteCommonOptions}
        />
      </GridWrap>
      <GridWrap item xs={1.5}>
        <RHFSelect
          name="l0t3a1_parapettoSXMensoleTamponiCampateTableValue"
          label="Parapetto SX*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a1_parapettoSXMensoleTamponiCampateTableValue',
              e.target.value
            );
          }}
          selectOptions={schemaPonteCommonOptions}
        />
      </GridWrap>
      <GridWrap item xs={1.5}>
        <RHFSelect
          name="l0t3a1_parapettoDXMensoleTamponiCampateTableValue"
          label="Parapetto DX*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a1_parapettoDXMensoleTamponiCampateTableValue',
              e.target.value
            );
          }}
          selectOptions={schemaPonteCommonOptions}
        />
      </GridWrap>
      <GridWrap item xs={1.5}>
        <RHFSelect
          name="l0t3a1_guardaviaSXMensoleTamponiCampateTableValue"
          label="Guardavia SX*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a1_guardaviaSXMensoleTamponiCampateTableValue',
              e.target.value
            );
          }}
          selectOptions={schemaPonteCommonOptions}
        />
      </GridWrap>
      <GridWrap item xs={1.5}>
        <RHFSelect
          name="l0t3a1_guardaviaDXMensoleTamponiCampateTableValue"
          label="Guardavia DX*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a1_guardaviaDXMensoleTamponiCampateTableValue',
              e.target.value
            );
          }}
          selectOptions={schemaPonteCommonOptions}
        />
      </GridWrap>
      <GridWrap item xs={1.5}>
        <RHFSelect
          name="l0t3a1_spartitrafficoMensoleTamponiCampateTableValue"
          label="Spartitraffico*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a1_spartitrafficoMensoleTamponiCampateTableValue',
              e.target.value
            );
          }}
          selectOptions={schemaPonteSpartitrafficoOptions}
        />
      </GridWrap>
      <GridWrap item xs={1.5}>
        <RHFSelect
          name="l0t3a1_pavimentazioneMensoleTamponiCampateTableValue"
          label="Pavimentazione*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a1_pavimentazioneMensoleTamponiCampateTableValue',
              e.target.value
            );
          }}
          selectOptions={schemaPonteCommonOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <FormSubmitButtons
          onSubmit={onSubmitSchemaPonte}
          onReset={onResetSchemaPonte}
        />
      </GridWrap>
    </GridWrap>
  );
};
SchemaPonteForm.propTypes = {
  schemaPonteFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cordoloSX: PropTypes.number,
      cordoloDX: PropTypes.number,
      marciapiedeDX: PropTypes.number,
      parapettoSX: PropTypes.number,
      parapettoDX: PropTypes.number,
      guardaviaSX: PropTypes.number,
      guardaviaDX: PropTypes.number,
      spartitraffico: PropTypes.number,
      pavimentazione: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  schemaPonteTable: PropTypes.string,
};
export default SchemaPonteForm;
