import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { colors } from 'constants/colors';
import { formatData } from 'utils/utilities';
import {
  BoxContainer,
  DateTextBox,
  IconContainer,
  TypeInspectionLabel,
} from './PlanningBoxDate.styles';

const PlanningBoxDate = ({
  lastOrdinaryDate,
  nextOrdinaryDate,
  nextStraordinaryDate,
}) => {
  return (
    <BoxContainer>
      <IconContainer>
        <img src="/icons/calendar-blue.svg" width={40} height={40} />
      </IconContainer>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h3Bold"
          sx={{
            fontSize: '0.75rem',
            color: colors.BLACK,
          }}
        >
          Date
        </Typography>
        <div style={{ display: 'flex', gap: 50 }}>
          <DateTextBox>
            <Typography
              variant="body"
              sx={{
                fontSize: '0.75rem',
              }}
            >
              Ultima ispezione
            </Typography>

            <TypeInspectionLabel inspectionType={'Ordinaria'}>
              Ordinaria
            </TypeInspectionLabel>
            {lastOrdinaryDate ? (
              <Typography
                variant="body"
                sx={{
                  fontSize: '0.75rem',
                }}
              >
                {formatData(lastOrdinaryDate)}
              </Typography>
            ) : (
              <Typography
                variant="body"
                sx={{
                  fontSize: '0.75rem',
                }}
              >
                n.d.
              </Typography>
            )}
          </DateTextBox>

          <DateTextBox>
            <Typography
              variant="body"
              sx={{
                fontSize: '0.75rem',
              }}
            >
              Prossima ispezione
            </Typography>

            <TypeInspectionLabel inspectionType={'Ordinaria'}>
              Ordinaria
            </TypeInspectionLabel>
            {nextOrdinaryDate ? (
              <Typography
                variant="body"
                sx={{
                  fontSize: '0.75rem',
                }}
              >
                {formatData(nextOrdinaryDate)}
              </Typography>
            ) : (
              <Typography
                variant="body"
                sx={{
                  fontSize: '0.75rem',
                }}
              >
                n.d.
              </Typography>
            )}
          </DateTextBox>

          <DateTextBox>
            <Typography
              variant="body"
              sx={{
                fontSize: '0.75rem',
              }}
            >
              Prossima ispezione
            </Typography>

            <TypeInspectionLabel inspectionType={'Straordinaria'}>
              Straordinaria
            </TypeInspectionLabel>
            {nextStraordinaryDate ? (
              <Typography
                variant="body"
                sx={{
                  fontSize: '0.75rem',
                }}
              >
                {formatData(nextStraordinaryDate)}
              </Typography>
            ) : (
              <Typography
                variant="body"
                sx={{
                  fontSize: '0.75rem',
                }}
              >
                n.d.
              </Typography>
            )}
          </DateTextBox>
        </div>
      </div>
    </BoxContainer>
  );
};

export { PlanningBoxDate };

PlanningBoxDate.propTypes = {
  lastOrdinaryDate: PropTypes.object,
  nextOrdinaryDate: PropTypes.object,
  nextStraordinaryDate: PropTypes.object,
};
