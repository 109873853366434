import {
  BacinoIdrograficoRischioIdraulico,
  EffettivoApprossimativo,
  Fenomeno,
  FenomenoErosioneRischioIdraulico,
  NecessitaIspezioneSpecialeIdraulico,
  NecessitaIspezioneSpecialeRischioFrane,
  RischioFrana,
  RischioIdraulico,
  ScenariPericolositaRischioIdraulico,
  SismicitaArea,
  StrutturaAlveoRischioIdraulico,
  TipologiaCorsoAcquaRischioIdraulico,
  tipologiaStrutturale,
  YesNo,
} from './inspections';

export const AppFields = {
  Contesto: {
    informazioniGenerali: [
      { fieldName: 'codiceInterno' },
      { fieldName: 'tipologiaCollegamento' },
      { fieldName: 'progressivaKmIniziale' },
      { fieldName: 'progressiveKmFinale' },
      { fieldName: 'tipologiaEnteScavalcato' },
      { fieldName: 'stradaAppartenenza' },
      { fieldName: 'caratteristicheClassificazioneEnteScavalcato' },
      { fieldName: 'nomeEnteScavalcato' },
      { fieldName: 'classeUso' },
      { fieldName: 'statoOpera' },
      { fieldName: 'fotoCopertina' },
    ],
    localizzazione: [
      { fieldName: 'regione' },
      { fieldName: 'provincia' },
      { fieldName: 'comune' },
      { fieldName: 'capCode' },
      { fieldName: 'siglaStradaAppartenenza' },
      { fieldName: 'quotaCentro' },
      { fieldName: 'latitudineCentro' },
      { fieldName: 'longitudineCentro' },
    ],
    informazioniIspezione: [
      { fieldName: 'rilevatoreRischioStrutturale' },
      { fieldName: 'dataIspezioneRischioStrutturale' },
      { fieldName: 'accessoSottoPonte' },
      { fieldName: 'prospettiIspezionati' },
      { fieldName: 'rischioFrana' },
      {
        fieldName: 'affidabilitaComplessivaRischioFrane',
        condition: `!(getValues('rischioFrana') == ${RischioFrana.Assente} || !getValues('rischioFrana'))`,
      },
      {
        fieldName: 'rilevatoreRischioFrane',
        condition: `!(getValues('rischioFrana') == ${RischioFrana.Assente} || !getValues('rischioFrana'))`,
      },
      {
        fieldName: 'dataIspezioneRischioFrane',
        condition: `!(getValues('rischioFrana') == ${RischioFrana.Assente} || !getValues('rischioFrana'))`,
      },
      { fieldName: 'rischioIdraulico' },
      {
        fieldName: 'affidabilitaComplessivaValutazioneRischioIdraulico',
        condition: `!(getValues('rischioIdraulico') == ${RischioIdraulico.Assente} || !getValues('rischioIdraulico'))`,
      },
      {
        fieldName: 'rilevatoreRischioIdraulico',
        condition: `!(getValues('rischioIdraulico') == ${RischioIdraulico.Assente} || !getValues('rischioIdraulico'))`,
      },
      {
        fieldName: 'dataIspezioneRischioIdraulico',
        condition: `!(getValues('rischioIdraulico') == ${RischioIdraulico.Assente} || !getValues('rischioIdraulico'))`,
      },
      {
        fieldName: 'possibilitaVerificaScalzamentoFondazioni',
        condition: `!(getValues('rischioIdraulico') == ${RischioIdraulico.Assente} || !getValues('rischioIdraulico'))`,
      },
    ],
    rischioFrana: [
      { fieldName: 'necessitaIspezioniSpecialiRischioFrane' },
      {
        fieldName: 'risultatiIspezioniSpecialiFrane',
        condition: `getValues('necessitaIspezioniSpecialiRischioFrane') == ${NecessitaIspezioneSpecialeRischioFrane.Si}`,
      },
      { fieldName: 'misureMitigazione' },
      { fieldName: 'estensioneInterferenzaFrana' },
      { fieldName: 'fenomeno' },
      { fieldName: 'elementoRiferimentoFrana' },
      {
        fieldName: 'statoAttivitaFrana',
        condition: `getValues('fenomeno') == ${Fenomeno.Accertato}`,
      },
      {
        fieldName: 'gradoCriticitaFrana',
        condition: `getValues('fenomeno') == ${Fenomeno.Potenziale}`,
      },
      { fieldName: 'velocitaMaxSpostamentoFrana' },
      { fieldName: 'magnitudoBaseVolumetrica' },
    ],
    rischioIdraulico: [
      { fieldName: 'necessitaIspezioniSpecialiRischioIdraulico' },
      {
        fieldName: 'risultatiIspezioniSpecialiRischioIdraulico',
        condition: `getValues('necessitaIspezioniSpecialiRischioIdraulico') == ${NecessitaIspezioneSpecialeIdraulico.Si}`,
      },
      { fieldName: 'strutturaAlveoRischioIdraulico' },
      { fieldName: 'fenomenoSormontoRischioIdraulico' },
      { fieldName: 'fenomenoErosioneRischioIdraulico' },
      { fieldName: 'presenzaStudioIdraulicoLivelliDefiniti' },
      { fieldName: 'scenariPericolositaRischioIdraulico' },
      { fieldName: 'presenzaRilieviRischioIdraulico' },
      {
        fieldName: 'bacinoIdrograficoPresuntoRischioIdraulico',
        condition: `getValues('bacinoIdrograficoRischioIdraulico') === ${BacinoIdrograficoRischioIdraulico.Presunto}`,
      },
      { fieldName: 'tipologiaReticoloRischioIdraulico' },
      { fieldName: 'materialeAlveoRischioIdraulico' },
      { fieldName: 'curvaturaAlveoRischioIdraulico' },
      { fieldName: 'divagazioneAlveoRischioIdraulico' },
      { fieldName: 'tipologiaCorsoAcquaRischioIdraulico' },
      { fieldName: 'riferimentoZeroMetriRischioIdraulico' },
      { fieldName: 'quotaMinimaIntradossoRischioIdraulico' },
      {
        fieldName: 'quotaPeloLiberoP2RischioIdraulico',
        condition: `(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P2}
      )`,
      },
      {
        fieldName: 'francoIdraulicoFP2',
        condition: `(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P2}
      )`,
      },
      {
        fieldName: 'quotaPeloLiberoP3RischioIdraulico',
        condition: `(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P3}
      )`,
      },
      {
        fieldName: 'francoIdraulicoFP3',
        condition: `(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P3}
      )`,
      },
      {
        fieldName: 'quotaArgineMinRischioIdraulico',
        condition: `getValues('tipologiaCorsoAcquaRischioIdraulico') ===
        ${TipologiaCorsoAcquaRischioIdraulico.CasoI} &&
        (getValues('scenariPericolositaRischioIdraulico') || []).length !== 0 &&
        (getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P2}) === false`,
      },
      {
        fieldName: 'quotaSpondaMaxRischioIdraulico',
        condition: `getValues('tipologiaCorsoAcquaRischioIdraulico') ===
        ${TipologiaCorsoAcquaRischioIdraulico.CasoII} &&
        (getValues('scenariPericolositaRischioIdraulico') || []).length !== 0 &&
        !(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P2})`,
      },
      {
        fieldName: 'quotaFondoAlveoRischioIdraulico',
        condition: `getValues('tipologiaCorsoAcquaRischioIdraulico') ===
        ${TipologiaCorsoAcquaRischioIdraulico.CasoIII} &&
        (getValues('scenariPericolositaRischioIdraulico') || []).length !== 0 &&
        !(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P2})`,
      },

      {
        fieldName: 'quotaPeloLiberoStimaRischioIdraulico',
        condition: `!(getValues('scenariPericolositaRischioIdraulico') || []).includes(
          ${ScenariPericolositaRischioIdraulico.P2}
        ) && (getValues('scenariPericolositaRischioIdraulico') || []).length !== 0 `,
      },
      {
        fieldName: 'francoIdraulicoStimato',
        condition: "getValues('quotaPeloLiberoStimaFlagRischioIdraulico') == 1",
      },
      {
        fieldName: 'francoIdraulicoSuperiore',
        condition:
          "getValues('quotaPeloLiberoStimaFlagRischioIdraulico') === 2 || getValues('quotaMinimaIntradossoFlagRilevabileRischioIdraulico') === 2",
      },
      { fieldName: 'deposizioneSedimenti' },
      { fieldName: 'materialeVegetaleRischioIdraulico' },
      //Erosione
      {
        fieldName: 'larghezzaAlveoPileSpalle',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'larghezzaAlveoPonte',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'fattoreRestringimentoAlveo',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'areeGolenaliAssenti',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'larghezzaComplessivaGolene',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} && getValues('areeGolenaliAssenti') !== ${YesNo.SI}`,
      },
      {
        fieldName: 'larghezzaComplessivaGolenePonte',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} && getValues('areeGolenaliAssenti') !== ${YesNo.SI}`,
      },
      {
        fieldName: 'fattoreRestringimentoAreeGolenali',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'larghezzaPilaOAggettoSpalla',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'profonditaScavoMax',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'profonditaFondazioneRispettoAlveo',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'fattoreIEL',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'evidenzeProfonditaDelleFondazioni',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} && (getValues('strutturaAlveoRischioIdraulico') || []).includes(
          ${StrutturaAlveoRischioIdraulico.Nessuna}
        ) === false`,
      },
      {
        fieldName: 'abbassamentoGeneralizzatoAlveo',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'accumuliDetritiAMontePila',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} && getValues('strutturaAlveoRischioIdraulico') &&
        getValues('strutturaAlveoRischioIdraulico') !==
          ${StrutturaAlveoRischioIdraulico.Nessuna}`,
      },
      //end erosione
      { fieldName: 'opereProtezioneSpondale' },
      {
        fieldName: 'protezioneAlPiedePileSpalle',
        condition: `getValues('strutturaAlveoRischioIdraulico') && !((getValues('strutturaAlveoRischioIdraulico') || []).includes(${StrutturaAlveoRischioIdraulico.Nessuna}))`,
      },
      {
        fieldName: 'brigliaProtezioneValleDelPonte',
        condition: `getValues('strutturaAlveoRischioIdraulico') && !((getValues('strutturaAlveoRischioIdraulico') || []).includes(${StrutturaAlveoRischioIdraulico.Nessuna}))`,
      },
    ],
    datiProgetto: [{ fieldName: 'criteriSismiciInOpera' }],
    collegamentoReteStradale: [
      { fieldName: 'classificazioneUsoStradale' },
      { fieldName: 'categoriaPonte' },
      { fieldName: 'nCarreggiate' },
      { fieldName: 'nCorsieCarreggiata' },
      { fieldName: 'larghezzaCarreggiata' },
      {
        fieldName: 'valoreApprossimativoTGM',
        condition: `getValues('veicoliGiornoTGM') == ${EffettivoApprossimativo.Approssimativo}`,
      },
      /*  {
        fieldName: 'valoreEffettivoTGM',
        condition: `getValues('veicoliGiornoTGM') == ${EffettivoApprossimativo.Effettivo}`,
      },
      {
        fieldName: 'valoreEffettivoCommercialiCorsiaTGM',
        condition: `getValues('veicoliCommercialITGMSingolaCorsia') == ${EffettivoApprossimativo.Effettivo}`,
      }, */
      {
        fieldName: 'valoreApprossimativoCommercialiCorsiaTGM',
        condition: `getValues('veicoliCommercialITGMSingolaCorsia') == ${EffettivoApprossimativo.Approssimativo}`,
      },
      { fieldName: 'limiteCaricoCarreggiataSingolaCorsia' },
      { fieldName: 'frequentePassaggioPersone' },
      { fieldName: 'trasportoMerciPericolose' },
    ],
    datiGeomorfologici: [
      { fieldName: 'categoriaTopografica' },
      { fieldName: 'categoriaSottosuolo' },
      { fieldName: 'aerosolMarini' },
      {
        fieldName: 'maggioreDegrado',
        condition: `getValues('aerosolMarini') == ${YesNo.SI}`,
      },
    ],
  },
  Struttura: {
    carattteristicheGeometriche: [
      { fieldName: 'luceComplessivaEstesa' },
      { fieldName: 'altezzaUtileSottoImpalcato' },
      { fieldName: 'larghezzaTotaleImpalcato' },
      { fieldName: 'numeroCampate' },
      { fieldName: 'luceMediaCampate' },
      { fieldName: 'luceMassimaCampate' },
      {
        fieldName: 'campataTable',
        isTable: true,
        subfields: [
          { fieldName: 'luceCampata' },
          { fieldName: 'enteScavalcato' },
          { fieldName: 'luceIdraulica' },
        ],
      },
    ],
    caratteristicheStrutturali: [
      { fieldName: 'tipologiaStrutturale' },
      {
        fieldName: 'necessitaIspezioniSpecialiRischioStruttura',
        condition: `getValues('tipologiaStrutturale') == "${tipologiaStrutturale.TravateCap}"`,
      },
      {
        fieldName: 'risultatiIspezioniSpeciali',
        condition: `getValues('necessitaIspezioniSpecialiRischioStruttura') == "${YesNo.SI}"`,
      },
      { fieldName: 'tipologiaSchema' },
      { fieldName: 'tipologiaImpalcato' },
      { fieldName: 'nSpalleStruttura' },
      { fieldName: 'nPileStruttura' },
      { fieldName: 'mensole' },
      {
        fieldName: 'nMensoleStruttura',
        condition: `getValues('mensole') == ${YesNo.SI}`,
      },
      {
        fieldName: 'nTamponiStruttura',
        condition: `getValues('mensole') == ${YesNo.SI}`,
      },
      {
        fieldName: 'spallePileTable',
        isTable: true,
        subfields: [
          { fieldName: 'appoggi' },
          { fieldName: 'giunti' },
          { fieldName: 'pulvini' },
          { fieldName: 'antenne' },
          { fieldName: 'dispositiviSismici' },
        ],
      },
      {
        fieldName: 'campataStrutturaTable',
        isTable: true,
        subfields: [
          { fieldName: 'travi' },
          { fieldName: 'traversi' },
          { fieldName: 'soletta' },
          { fieldName: 'archi' },
          { fieldName: 'piedritti' },
          { fieldName: 'controventi' },
          { fieldName: 'tiranti' },
          { fieldName: 'elementiExtra' },
        ],
      },
      {
        fieldName: 'mensoleTamponiTable',
        isTable: true,
        subfields: [
          { fieldName: 'travi' },
          { fieldName: 'traversi' },
          { fieldName: 'soletta' },
          { fieldName: 'archi' },
          { fieldName: 'piedritti' },
          { fieldName: 'controventi' },
          { fieldName: 'tiranti' },
          { fieldName: 'elementiExtra' },
          { fieldName: 'luce' },
        ],
      },
    ],
    schemiGeometrici: [],
    elevazione: [
      {
        fieldName: 'spalleTable',
        isTable: true,
        subfields: [
          { fieldName: 'tipologiaSpalla' },
          { fieldName: 'impostaFondSpalla' },
          { fieldName: 'schedaDifetti' },
        ],
      },
      {
        fieldName: 'pileTable',
        isTable: true,
        subfields: [
          { fieldName: 'tipoPila' },
          { fieldName: 'impostaFondPila' },
          { fieldName: 'schedaDifetti' },
        ],
      },
    ],
    collegamenti: [
      {
        fieldName: 'spallePileCollegamentiTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'appoggiTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaAppoggio' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'giuntiTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaGiunto' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'pulviniTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaPulvino' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'antenneTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaAntenna' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'dispositiviSismiciTable',
            isTable: true,
            subfields: [{ fieldName: 'nomeDispositivoSismico' }],
          },
        ],
      },
    ],
    impalcato: [
      {
        fieldName: 'mensoleTamponiCampateImpalcatoTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'soletteTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaSoletta' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'traviTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaTrave' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'traversiTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaTraverso' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'controventiTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaControvento' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'archiTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaArco' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'piedrittiTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaPiedritto' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'tirantiTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaTirante' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'elementiExtraTable',
            isTable: true,
            subfields: [
              { fieldName: 'nomeElementoExtra' },
              { fieldName: 'tipologiaElementoExtra' },
              { fieldName: 'schedaDifetti' },
            ],
          },
        ],
      },
    ],
    aspettiCritici: [
      { fieldName: 'elementiVulnerabilitaSismica' },
      { fieldName: 'elementiCriticiVulnerabilitaStrutturaleFondazionale' },
      { fieldName: 'elementiCriticiVulnerabilitaSismica' },
      { fieldName: 'condizioniCriticheVulnerabilitaStrutturale' },
      { fieldName: 'condizioniCriticheVulnerabilitaSismica' },
    ],
  },
  ElementiAccessori: {
    caratteristiche: [
      {
        fieldName: 'schemaPonteTable',
        isTable: true,
        subfields: [
          { fieldName: 'cordoloDX' },
          { fieldName: 'cordoloSX' },
          { fieldName: 'marciapiedeDX' },
          { fieldName: 'marciapiedeSX' },
          { fieldName: 'parapettoDX' },
          { fieldName: 'parapettoSX' },
          { fieldName: 'guardaviaSX' },
          { fieldName: 'guardaviaDX' },
          { fieldName: 'spartitraffico' },
          { fieldName: 'pavimentazione' },
        ],
      },
    ],
    impalcato: [
      {
        fieldName: 'sovrastrutturaImpalcatoTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'pavimentazioneTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaPavimentazione' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'cordoliTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaCordolo' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'marciapiediTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaMarciapiede' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'parapettiTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaParapetto' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'guardavieTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaGuardavia' },
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'spartitrafficoTable',
            isTable: true,
            subfields: [
              { fieldName: 'tipologiaSpartitraffico' },
              { fieldName: 'schedaDifetti' },
            ],
          },
        ],
      },
    ],
  },
  Servizi: {
    paliIlluminazione: [
      {
        fieldName: 'paliTable',
        isTable: true,
        subfields: [
          { fieldName: 'paliIlluminazione' },
          {
            fieldName: 'schedaDifetti',
            condition: "getValues('paliTable')[0].paliIlluminazione !== 3",
          },
        ],
      },
    ],
    convogliamentoAcque: [
      {
        fieldName: 'convogliamentoAcqueTable',
        isTable: true,
        subfields: [
          { fieldName: 'convogliamentoAcque' },
          {
            fieldName: 'schedaDifetti',
            condition:
              "getValues('convogliamentoAcqueTable')[0].convogliamentoAcque !== 3",
          },
        ],
      },
    ],
    sottoservizi: [
      {
        fieldName: 'sottoserviziTable',
        isTable: true,
        subfields: [
          { fieldName: 'sottoservizi' },
          {
            fieldName: 'schedaDifetti',
            condition: "getValues('sottoserviziTable')[0].sottoservizi !== 3",
          },
        ],
      },
    ],
  },
};

export const WebL0Fields = {
  Contesto: {
    informazioniGenerali: [
      { fieldName: 'codiceInterno' },
      { fieldName: 'codiceInternoProprietario' },
      { fieldName: 'tipologiaCollegamento' },
      { fieldName: 'progressivaKmIniziale' },
      { fieldName: 'progressiveKmFinale' },
      { fieldName: 'estesa' },
      { fieldName: 'tipologiaEnteScavalcato' },
      { fieldName: 'stradaAppartenenza' },
      { fieldName: 'caratteristicheClassificazioneEnteScavalcato' },
      { fieldName: 'nomeEnteScavalcato' },
      { fieldName: 'classeUso' },
      { fieldName: 'statoOpera' },
      { fieldName: 'livelloConoscenza' },
      { fieldName: 'fotoCopertina' }, //done
    ],
    datiAmministrativi: [
      { fieldName: 'proprietario' },
      { fieldName: 'concessionario' },
    ], //done
    localizzazione: [
      { fieldName: 'regione' },
      { fieldName: 'provincia' },
      { fieldName: 'comune' },
      { fieldName: 'capCode' },
      { fieldName: 'indirizzo' },
      { fieldName: 'siglaStradaAppartenenza' },
      { fieldName: 'sismicitaArea' },
      {
        fieldName: 'sismicitaValoreEffettivo',
        condition: `getValues('sismicitaArea') && getValues('sismicitaArea') === ${SismicitaArea.ValoreEffettivo}`,
      },
      {
        fieldName: 'sismicitaValoreApprossimativo',
        condition: `getValues('sismicitaArea') && getValues('sismicitaArea') === ${SismicitaArea.ValoreApprossimativo}`,
      },
      { fieldName: 'quotaCentro' },
      { fieldName: 'latitudineCentro' },
      { fieldName: 'longitudineCentro' },
    ],
    rischioFrana: [
      { fieldName: 'rischioFrana' },
      {
        fieldName: 'necessitaIspezioniSpecialiRischioFrane',
        condition: `!(getValues('rischioFrana') == ${RischioFrana.Assente} || !getValues('rischioFrana'))`,
      },
      {
        fieldName: 'risultatiIspezioniSpecialiFrane',
        condition: `getValues('necessitaIspezioniSpecialiRischioFrane') == ${NecessitaIspezioneSpecialeRischioFrane.Si}`,
      },
    ],
    rischioIdraulico: [
      { fieldName: 'rischioIdraulico' },
      {
        fieldName: 'necessitaIspezioniSpecialiRischioIdraulico',
        condition: `!(getValues('rischioIdraulico') == ${RischioIdraulico.Assente} || !getValues('rischioIdraulico'))`,
      },
      {
        fieldName: 'presenzaStudioIdraulicoLivelliDefiniti',
        condition: `!(getValues('rischioIdraulico') == ${RischioIdraulico.Assente} || !getValues('rischioIdraulico'))`,
      },
      {
        fieldName: 'risultatiIspezioniSpecialiRischioIdraulico',
        condition: `getValues('necessitaIspezioniSpecialiRischioIdraulico') == ${NecessitaIspezioneSpecialeIdraulico.Si}`,
      },
    ],
    datiProgetto: [
      { fieldName: 'normaProgetto' },
      { fieldName: 'annoDiCostruzioneApprossimativo' },
      { fieldName: 'interventiSignificativiManutenzione' },
      {
        fieldName: 'interventiSignificativiDataApprossimativa',
        condition: `getValues('interventiSignificativiManutenzione') == ${YesNo.SI}`,
      },
      { fieldName: 'criteriSismiciInOpera' }, //done
    ],
    attivitaPregresse: [],
    collegamentoReteStradale: [
      { fieldName: 'classificazioneUsoStradale' },
      { fieldName: 'competenzaInfrastruttura' },
      { fieldName: 'categoriaPonte' },
      { fieldName: 'itinerarioEmergenza' },
      { fieldName: 'trasportiEccezionali' },
      { fieldName: 'nCarreggiate' },
      { fieldName: 'nCorsieCarreggiata' },
      { fieldName: 'larghezzaCarreggiata' },
      { fieldName: 'veicoliGiornoTGM' },
      {
        fieldName: 'valoreApprossimativoTGM',
        condition: `getValues('veicoliGiornoTGM') == ${EffettivoApprossimativo.Approssimativo}`,
      },
      {
        fieldName: 'valoreEffettivoTGM',
        condition: `getValues('veicoliGiornoTGM') == ${EffettivoApprossimativo.Effettivo}`,
      },
      /* { fieldName: 'veicoliCommercialiGiornoTGM' }, */
      {
        fieldName: 'valoreEffettivoCommercialiCorsiaTGM',
        condition: `getValues('veicoliCommercialITGMSingolaCorsia') == ${EffettivoApprossimativo.Effettivo}`,
      },
      {
        fieldName: 'valoreApprossimativoCommercialiCorsiaTGM',
        condition: `getValues('veicoliCommercialITGMSingolaCorsia') == ${EffettivoApprossimativo.Approssimativo}`,
      },
      { fieldName: 'limiteCaricoCarreggiataSingolaCorsia' },
      { fieldName: 'frequentePassaggioPersone' },
      { fieldName: 'trasportoMerciPericolose' },
      { fieldName: 'alternativeStradali' }, //done
    ],
    datiGeomorfologici: [
      { fieldName: 'categoriaTopografica' },
      { fieldName: 'categoriaSottosuolo' },
      { fieldName: 'aerosolMarini' },
      {
        fieldName: 'maggioreDegrado',
        condition: `getValues('aerosolMarini') == ${YesNo.SI}`,
      }, //done
    ],
    commentiReport: [],
  },
  Struttura: {
    carattteristicheGeometriche: [
      { fieldName: 'luceComplessivaEstesa' },
      { fieldName: 'altezzaUtileSottoImpalcato' },
      { fieldName: 'larghezzaTotaleImpalcato' },
      { fieldName: 'numeroCampate' },
      { fieldName: 'luceMediaCampate' },
      { fieldName: 'luceMassimaCampate' },
      {
        fieldName: 'campataTable',
        isTable: true,
        subfields: [
          { fieldName: 'luceCampata' },
          /*      { fieldName: 'enteScavalcato' },
          { fieldName: 'luceIdraulica' }, */
        ],
      }, //done
    ],
    caratteristicheStrutturali: [
      { fieldName: 'tipologiaStrutturale' },
      {
        fieldName: 'necessitaIspezioniSpecialiRischioStruttura',
        condition: `getValues('tipologiaStrutturale') == "${tipologiaStrutturale.TravateCap}"`,
      },
      {
        fieldName: 'risultatiIspezioniSpeciali',
        condition: `getValues('necessitaIspezioniSpecialiRischioStruttura') == "${YesNo.SI}"`,
      },
      { fieldName: 'tipologiaSchema' },
      { fieldName: 'tipologiaImpalcato' },
      { fieldName: 'numeroCampate' },
      { fieldName: 'nSpalleStruttura' },
      { fieldName: 'nPileStruttura' },
      { fieldName: 'mensole' },
      {
        fieldName: 'nMensoleStruttura',
        condition: `getValues('mensole') == ${YesNo.SI}`,
      },
      {
        fieldName: 'nTamponiStruttura',
        condition: `getValues('mensole') == ${YesNo.SI}`,
      },
      {
        fieldName: 'spallePileTable',
        isTable: true,
        subfields: [
          { fieldName: 'appoggi' },
          { fieldName: 'giunti' },
          { fieldName: 'pulvini' },
          { fieldName: 'antenne' },
          { fieldName: 'dispositiviSismici' },
        ],
      },
      {
        fieldName: 'campataStrutturaTable',
        isTable: true,
        subfields: [
          { fieldName: 'travi' },
          { fieldName: 'traversi' },
          { fieldName: 'soletta' },
          { fieldName: 'archi' },
          { fieldName: 'piedritti' },
          { fieldName: 'controventi' },
          { fieldName: 'tiranti' },
          { fieldName: 'elementiExtra' },
        ],
        condition: `!(getValues('nMensoleStruttura'))`,
      },
      {
        fieldName: 'mensoleTamponiTable',
        isTable: true,
        subfields: [
          { fieldName: 'travi' },
          { fieldName: 'traversi' },
          { fieldName: 'soletta' },
          { fieldName: 'archi' },
          { fieldName: 'piedritti' },
          { fieldName: 'controventi' },
          { fieldName: 'tiranti' },
          { fieldName: 'elementiExtra' },
          { fieldName: 'luce' },
        ],
      }, //done
    ],
    schemiGeometrici: [],
    elevazione: [
      {
        fieldName: 'spalleTable',
        isTable: true,
        subfields: [
          { fieldName: 'tipologiaSpalla' },
          { fieldName: 'impostaFondSpalla' },
        ],
      },
      {
        fieldName: 'pileTable',
        isTable: true,
        subfields: [
          { fieldName: 'tipoPila' },
          { fieldName: 'impostaFondPila' },
        ],
      }, //done
    ],
    collegamenti: [
      {
        fieldName: 'spallePileCollegamentiTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'appoggiTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaAppoggio' }],
          },
          {
            fieldName: 'giuntiTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaGiunto' }],
          },
          {
            fieldName: 'pulviniTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaPulvino' }],
          },
          {
            fieldName: 'antenneTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaAntenna' }],
          },
          {
            fieldName: 'dispositiviSismiciTable',
            isTable: true,
            subfields: [{ fieldName: 'nomeDispositivoSismico' }],
          },
        ],
      },
    ],
    impalcato: [
      {
        fieldName: 'mensoleTamponiCampateImpalcatoTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'soletteTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaSoletta' }],
          },
          {
            fieldName: 'traviTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaTrave' }],
          },
          {
            fieldName: 'traversiTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaTraverso' }],
          },
          {
            fieldName: 'controventiTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaControvento' }],
          },
          {
            fieldName: 'archiTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaArco' }],
          },
          {
            fieldName: 'piedrittiTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaPiedritto' }],
          },
          {
            fieldName: 'tirantiTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaTirante' }],
          },
          {
            fieldName: 'elementiExtraTable',
            isTable: true,
            subfields: [
              { fieldName: 'nomeElementoExtra' },
              { fieldName: 'tipologiaElementoExtra' },
            ],
          },
        ],
      }, //done
    ],
    commentiReport: [],
  },
  ElementiAccessori: {
    caratteristiche: [
      {
        fieldName: 'schemaPonteTable',
        isTable: true,
        subfields: [
          { fieldName: 'cordoloDX' },
          { fieldName: 'cordoloSX' },
          { fieldName: 'marciapiedeDX' },
          { fieldName: 'marciapiedeSX' },
          { fieldName: 'parapettoDX' },
          { fieldName: 'parapettoSX' },
          { fieldName: 'guardaviaSX' },
          { fieldName: 'guardaviaDX' },
          { fieldName: 'spartitraffico' },
          { fieldName: 'pavimentazione' },
        ],
      },
    ],
    impalcato: [
      {
        fieldName: 'sovrastrutturaImpalcatoTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'pavimentazioneTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaPavimentazione' }],
          },
          {
            fieldName: 'cordoliTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaCordolo' }],
          },
          {
            fieldName: 'marciapiediTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaMarciapiede' }],
          },
          {
            fieldName: 'parapettiTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaParapetto' }],
          },
          {
            fieldName: 'guardavieTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaGuardavia' }],
          },
          {
            fieldName: 'spartitrafficoTable',
            isTable: true,
            subfields: [{ fieldName: 'tipologiaSpartitraffico' }],
          },
        ],
      }, //done
    ],
    commentiReport: [],
  },
  Servizi: {
    paliIlluminazione: [
      {
        fieldName: 'paliTable',
        isTable: true,
        subfields: [{ fieldName: 'paliIlluminazione' }],
      },
    ],
    convogliamentoAcque: [
      {
        fieldName: 'convogliamentoAcqueTable',
        isTable: true,
        subfields: [{ fieldName: 'convogliamentoAcque' }],
      },
    ],
    sottoservizi: [
      {
        fieldName: 'sottoserviziTable',
        isTable: true,
        subfields: [{ fieldName: 'sottoservizi' }],
      },
    ],
    commentiReport: [],
  },
};

export const WebL1Fields = {
  Contesto: {
    informazioniGenerali: [{ fieldName: 'stradaAppartenenza' }],
    informazioniIspezione: [
      { fieldName: 'rilevatoreRischioStrutturale' },
      { fieldName: 'dataIspezioneRischioStrutturale' },
      { fieldName: 'accessoSottoPonte' },
      { fieldName: 'prospettiIspezionati' },
      {
        fieldName: 'affidabilitaComplessivaRischioFrane',
        condition: `!(getValues('rischioFrana') == ${RischioFrana.Assente} || !getValues('rischioFrana'))`,
      },
      {
        fieldName: 'rilevatoreRischioFrane',
        condition: `!(getValues('rischioFrana') == ${RischioFrana.Assente} || !getValues('rischioFrana'))`,
      },
      {
        fieldName: 'dataIspezioneRischioFrane',
        condition: `!(getValues('rischioFrana') == ${RischioFrana.Assente} || !getValues('rischioFrana'))`,
      },
      {
        fieldName: 'affidabilitaComplessivaValutazioneRischioIdraulico',
        condition: `!(getValues('rischioIdraulico') == ${RischioIdraulico.Assente} || !getValues('rischioIdraulico'))`,
      },
      {
        fieldName: 'rilevatoreRischioIdraulico',
        condition: `!(getValues('rischioIdraulico') == ${RischioIdraulico.Assente} || !getValues('rischioIdraulico'))`,
      },
      {
        fieldName: 'dataIspezioneRischioIdraulico',
        condition: `!(getValues('rischioIdraulico') == ${RischioIdraulico.Assente} || !getValues('rischioIdraulico'))`,
      },
      {
        fieldName: 'possibilitaVerificaScalzamentoFondazioni',
        condition: `!(getValues('rischioIdraulico') == ${RischioIdraulico.Assente} || !getValues('rischioIdraulico'))`,
      },
    ], //done
    rischioFrana: [
      { fieldName: 'misureMitigazione' },
      { fieldName: 'estensioneInterferenzaFrana' },
      { fieldName: 'fenomeno' },
      { fieldName: 'elementoRiferimentoFrana' },
      {
        fieldName: 'statoAttivitaFrana',
        condition: `getValues('fenomeno') == ${Fenomeno.Accertato}`,
      },
      {
        fieldName: 'gradoCriticitaFrana',
        condition: `getValues('fenomeno') == ${Fenomeno.Potenziale}`,
      },
      { fieldName: 'velocitaMaxSpostamentoFrana' },
      { fieldName: 'magnitudoBaseVolumetrica' },
    ], //done
    rischioIdraulico: [
      { fieldName: 'strutturaAlveoRischioIdraulico' },
      { fieldName: 'fenomenoSormontoRischioIdraulico' },
      { fieldName: 'fenomenoErosioneRischioIdraulico' },
      { fieldName: 'scenariPericolositaRischioIdraulico' },
      { fieldName: 'presenzaRilieviRischioIdraulico' },
      {
        fieldName: 'bacinoIdrograficoPresuntoRischioIdraulico',
        condition: `getValues('bacinoIdrograficoRischioIdraulico') === ${BacinoIdrograficoRischioIdraulico.Presunto}`,
      },
      { fieldName: 'tipologiaReticoloRischioIdraulico' },
      { fieldName: 'materialeAlveoRischioIdraulico' },
      { fieldName: 'curvaturaAlveoRischioIdraulico' },
      { fieldName: 'divagazioneAlveoRischioIdraulico' },
      { fieldName: 'tipologiaCorsoAcquaRischioIdraulico' },
      { fieldName: 'riferimentoZeroMetriRischioIdraulico' },
      { fieldName: 'quotaMinimaIntradossoRischioIdraulico' },
      {
        fieldName: 'quotaPeloLiberoP2RischioIdraulico',
        condition: `(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P2}
      )`,
      },
      {
        fieldName: 'francoIdraulicoFP2',
        condition: `(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P2}
      )`,
      },
      {
        fieldName: 'quotaPeloLiberoP3RischioIdraulico',
        condition: `(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P3}
      )`,
      },
      {
        fieldName: 'francoIdraulicoFP3',
        condition: `(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P3}
      )`,
      },
      {
        fieldName: 'quotaArgineMinRischioIdraulico',
        condition: `getValues('tipologiaCorsoAcquaRischioIdraulico') ===
        ${TipologiaCorsoAcquaRischioIdraulico.CasoI} &&
        (getValues('scenariPericolositaRischioIdraulico') || []).length !== 0 &&
        (getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P2}) === false`,
      },
      {
        fieldName: 'quotaSpondaMaxRischioIdraulico',
        condition: `getValues('tipologiaCorsoAcquaRischioIdraulico') ===
        ${TipologiaCorsoAcquaRischioIdraulico.CasoII} &&
        (getValues('scenariPericolositaRischioIdraulico') || []).length !== 0 &&
        !(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P2})`,
      },
      {
        fieldName: 'quotaFondoAlveoRischioIdraulico',
        condition: `getValues('tipologiaCorsoAcquaRischioIdraulico') ===
        ${TipologiaCorsoAcquaRischioIdraulico.CasoIII} &&
        (getValues('scenariPericolositaRischioIdraulico') || []).length !== 0 &&
        !(getValues('scenariPericolositaRischioIdraulico') || []).includes(
        ${ScenariPericolositaRischioIdraulico.P2})`,
      },

      {
        fieldName: 'quotaPeloLiberoStimaRischioIdraulico',
        condition: `!(getValues('scenariPericolositaRischioIdraulico') || []).includes(
          ${ScenariPericolositaRischioIdraulico.P2}
        ) && (getValues('scenariPericolositaRischioIdraulico') || []).length !== 0 `,
      },
      {
        fieldName: 'francoIdraulicoStimato',
        condition: "getValues('quotaPeloLiberoStimaFlagRischioIdraulico') == 1",
      },
      {
        fieldName: 'francoIdraulicoSuperiore',
        condition:
          "getValues('quotaPeloLiberoStimaFlagRischioIdraulico') === 2 || getValues('quotaMinimaIntradossoFlagRilevabileRischioIdraulico') === 2",
      },
      { fieldName: 'deposizioneSedimenti' },
      { fieldName: 'materialeVegetaleRischioIdraulico' },
      //Erosione
      {
        fieldName: 'larghezzaAlveoPileSpalle',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'larghezzaAlveoPonte',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'fattoreRestringimentoAlveo',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'areeGolenaliAssenti',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'larghezzaComplessivaGolene',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} && getValues('areeGolenaliAssenti') !== ${YesNo.SI}`,
      },
      {
        fieldName: 'larghezzaComplessivaGolenePonte',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} && getValues('areeGolenaliAssenti') !== ${YesNo.SI}`,
      },
      {
        fieldName: 'fattoreRestringimentoAreeGolenali',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'larghezzaPilaOAggettoSpalla',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'profonditaScavoMax',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'profonditaFondazioneRispettoAlveo',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'fattoreIEL',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'evidenzeProfonditaDelleFondazioni',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} && (getValues('strutturaAlveoRischioIdraulico') || []).includes(
          ${StrutturaAlveoRischioIdraulico.Nessuna}
        ) === false`,
      },
      {
        fieldName: 'abbassamentoGeneralizzatoAlveo',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} `,
      },
      {
        fieldName: 'accumuliDetritiAMontePila',
        condition: `getValues('fenomenoErosioneRischioIdraulico') && getValues('fenomenoErosioneRischioIdraulico') !==
        ${FenomenoErosioneRischioIdraulico.Assenti} && getValues('strutturaAlveoRischioIdraulico') &&
        getValues('strutturaAlveoRischioIdraulico') !==
          ${StrutturaAlveoRischioIdraulico.Nessuna}`,
      },
      //end erosione
      { fieldName: 'opereProtezioneSpondale' },
      {
        fieldName: 'protezioneAlPiedePileSpalle',
        condition: `getValues('strutturaAlveoRischioIdraulico') && !((getValues('strutturaAlveoRischioIdraulico') || []).includes(${StrutturaAlveoRischioIdraulico.Nessuna}))`,
      },
      {
        fieldName: 'brigliaProtezioneValleDelPonte',
        condition: `getValues('strutturaAlveoRischioIdraulico') && !((getValues('strutturaAlveoRischioIdraulico') || []).includes(${StrutturaAlveoRischioIdraulico.Nessuna}))`,
      },
    ], //done
  },
  Struttura: {
    carattteristicheGeometriche: [],
    caratteristicheStrutturali: [],
    elevazione: [
      {
        fieldName: 'spalleTable',
        isTable: true,
        subfields: [
          /* { fieldName: 'tipologiaSpalla' },
          { fieldName: 'impostaFondSpalla' }, */
          { fieldName: 'schedaDifetti' },
        ],
      },
      {
        fieldName: 'pileTable',
        isTable: true,
        subfields: [
          /* { fieldName: 'tipoPila' },
          { fieldName: 'impostaFondPila' }, */
          { fieldName: 'schedaDifetti' },
        ],
      }, //done
    ],
    collegamenti: [
      {
        fieldName: 'spallePileCollegamentiTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'appoggiTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaAppoggio' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'giuntiTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaGiunto' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'pulviniTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaPulvino' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'antenneTable',
            isTable: true,
            subfields: [
              /*  { fieldName: 'tipologiaAntenna' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          /* {
            fieldName: 'dispositiviSismiciTable',
            isTable: true,
            subfields: [{ fieldName: 'nomeDispositivoSismico' }],
          }, */
        ],
      },
    ],
    impalcato: [
      {
        fieldName: 'mensoleTamponiCampateImpalcatoTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'soletteTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaSoletta' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'traviTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaTrave' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'traversiTable',
            isTable: true,
            subfields: [
              /*  { fieldName: 'tipologiaTraverso' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'controventiTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaControvento' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'archiTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaArco' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'piedrittiTable',
            isTable: true,
            subfields: [
              /*  { fieldName: 'tipologiaPiedritto' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'tirantiTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaTirante' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'elementiExtraTable',
            isTable: true,
            subfields: [
              /*  { fieldName: 'nomeElementoExtra' },
              { fieldName: 'tipologiaElementoExtra' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
        ],
      }, //done
    ],
    aspettiCritici: [
      { fieldName: 'campateSoggetteACollasso' },
      { fieldName: 'elementiVulnerabilitaSismica' },
      { fieldName: 'elementiCriticiVulnerabilitaStrutturaleFondazionale' },
      { fieldName: 'elementiCriticiVulnerabilitaSismica' },
      { fieldName: 'condizioniCriticheVulnerabilitaStrutturale' },
      { fieldName: 'condizioniCriticheVulnerabilitaSismica' },
    ], //done
    annotazioniReport: [],
  },
  ElementiAccessori: {
    caratteristiche: [],
    impalcato: [
      {
        fieldName: 'sovrastrutturaImpalcatoTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'pavimentazioneTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaPavimentazione' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'cordoliTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaCordolo' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'marciapiediTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaMarciapiede' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'parapettiTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaParapetto' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'guardavieTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaGuardavia' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
          {
            fieldName: 'spartitrafficoTable',
            isTable: true,
            subfields: [
              /* { fieldName: 'tipologiaSpartitraffico' }, */
              { fieldName: 'schedaDifetti' },
            ],
          },
        ],
      }, //done
    ],
    annotazioniReport: [],
  },
  Servizi: {
    paliIlluminazione: [
      {
        fieldName: 'paliTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'schedaDifetti',
            condition: "getValues('paliTable')[0].paliIlluminazione !== 3",
          },
        ],
      },
    ],
    convogliamentoAcque: [
      {
        fieldName: 'convogliamentoAcqueTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'schedaDifetti',
            condition:
              "getValues('convogliamentoAcqueTable')[0].convogliamentoAcque !== 3",
          },
        ],
      },
    ],
    sottoservizi: [
      {
        fieldName: 'sottoserviziTable',
        isTable: true,
        subfields: [
          {
            fieldName: 'schedaDifetti',
            condition: "getValues('sottoserviziTable')[0].sottoservizi !== 3",
          },
        ],
      },
    ],

    annotazioniReport: [],
  },
};
