import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const Row = styled('div')(({ gap = '12px' }) => ({
  display: 'flex',
  gap: gap,
}));

export const InfoBox = styled('div')({
  marginTop: -10,
});

export const ProjectClassRow = styled('div')(({ overviewL0 }) => ({
  display: 'flex',
  gap: 12,
  margin: '8px 0',
  color: overviewL0 ? colors.BLACK : colors.DARKGREY,
  alignItems: 'center',
}));

export const InterventionBoxContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 4,
});

export const InterventionBox = styled('div')(({ overviewL0 }) => ({
  width: '33%',
  height: 20,
  backgroundColor: overviewL0 ? colors.BLUE : colors.LIGHTGREY,
  borderRadius: 3,
}));
