// form
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import NumberFormat from './NumberFormat';

// Material UI components

export default function RHFNumberTextField({
  name,
  rules,
  type,
  defaultValue,
  decimalScale,
  withValueLimit,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          type={type}
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          InputLabelProps={{
            shrink: field.value != null && field.value !== '',
          }}
          InputProps={{
            inputComponent: NumberFormat,
            inputProps: {
              decimalScale,
              withValueLimit,
              defaultValue,
              // onValueChange: e => {
              //   onValueChange?.(e?.floatValue);
              //   field.onChange(e?.floatValue);
              // },
            },
          }}
          {...other}
        />
      )}
    />
  );
}

RHFNumberTextField.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  type: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimalScale: PropTypes.number,
  withValueLimit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export { RHFNumberTextField };
