export const makePath = (...pathSlices) => {
  const path = pathSlices.reduce(
    (constructedPath, slice) => `${constructedPath}${slice}`,
    ''
  );
  return path;
};

export const interpolatePathData = (path, data) => {
  const interpolatedPath = Object.entries(data).reduce(
    (constructedPath, [key, value]) =>
      constructedPath.replace(`:${key}`, String(value)),
    path
  );
  return interpolatedPath;
};
