import { pdf } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import React from 'react';
import useFetch from 'use-http';
import { useGlobalSelector } from 'stores';
import { downloadBlob } from 'utils/export';
import { SintesiCdAStruttura } from '../pdf';

const useExportCdaStruttura = ({ id, intCode, setExportSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { get: exportLambdaUrl } = useFetch(
    process.env.REACT_APP_EXPORT_LAMBDA_URL
  );

  const date = new Date();
  const currentDate = date.toLocaleDateString().replaceAll('/', '-');
  const currentTime = date.toLocaleTimeString('it-IT').replaceAll(':', '-');

  const pdfStrutturaName = `${intCode} - Sintesi CdA Struttura - ${currentDate} ${currentTime}`;

  const exportCdAStruttura = async formData => {
    try {
      showLoader();
      //const cdaStruttura = await get(`exports/${id}/structural-cda`);
      const cdaStruttura = await exportLambdaUrl(`/?bridgeId=${id}&type=28`);
      if (cdaStruttura) {
        setExportSuccess(true);
      } else {
        setExportSuccess(false);
      }

      const PDFCdAStruttura = await pdf(
        <SintesiCdAStruttura pdfData={cdaStruttura} formData={formData} />
      ).toBlob();
      downloadBlob(PDFCdAStruttura, pdfStrutturaName);
      return PDFCdAStruttura;
    } catch (error) {
      console.log(error);
      return enqueueSnackbar(`Impossibile eseguire l'esportazione`, {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }
  };
  return {
    exportCdAStruttura,
    pdfStrutturaName,
  };
};

export default useExportCdaStruttura;
