import {
  Pagination,
  TableFooter,
  TablePagination,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';

export const DataGridPagination = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => {
  return (
    <TableFooter>
      <TableRow>
        <PaginationBase
          rowsPerPageOptions={rowsPerPageOptions}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </TableRow>
    </TableFooter>
  );
};

export const PaginationBase = ({
  div,
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => {
  return (
    <TablePagination
      component={div ? 'div' : undefined}
      disablespacer
      rowsPerPageOptions={rowsPerPageOptions}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(_, page) => onPageChange(page)}
      onRowsPerPageChange={event => onRowsPerPageChange(event.target.value)}
      labelRowsPerPage="Visualizza elementi:"
      labelDisplayedRows={({ count, from, to }) =>
        `Mostra da ${from} a ${to} di ${count}`
      }
      ActionsComponent={PaginationActions}
    />
  );
};

const PaginationActions = ({ count, onPageChange, page, rowsPerPage }) => {
  return (
    <Pagination
      count={Math.ceil(count / rowsPerPage)}
      color="primary"
      page={page + 1}
      onChange={(event, page) => {
        onPageChange(event, page - 1);
      }}
    />
  );
};

PaginationActions.propTypes = {
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
};

PaginationBase.propTypes = {
  div: PropTypes.bool,
  rowsPerPageOptions: PropTypes.array,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
};

DataGridPagination.propTypes = {
  rowsPerPageOptions: PropTypes.array,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
};
