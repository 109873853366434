import {
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTextField,
} from 'components';

import { ClasseUsoOptions, StatoOperaOptions } from 'constants/selectOptions';
import { GridWrap } from './Accordion1GeneralInfoL1.styles';
import { INFO } from '../../../../config/info';

const Accordion1GeneralInfoL1 = () => {
  return (
    <>
      <GridWrap container spacing={2} padding="20px" marginTop={-40}>
        <InfoWrapper size={3} infoMessage={INFO.codiceInterno.infoMessage}>
          <RHFTextField
            name="codiceInterno"
            label={'Codice interno -ID*'}
            inputProps={{ maxLength: 30 }}
            defaultValue=""
            disabled
          />
        </InfoWrapper>
        <InfoWrapper size={3} infoMessage={INFO.codiceIOP.infoMessage}>
          <RHFTextField
            name="codiceIOP"
            label={'Codice IOP'}
            inputProps={{ maxLength: 18 }}
            defaultValue=""
            disabled
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFTextField
            name="nomeOpera"
            label={'Nome opera'}
            inputProps={{ maxLength: 150 }}
            defaultValue=""
            disabled
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="stradaAppartenenza"
            label={'Strada di appartenenza*'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.progressivaKmIniziale.drawerTitle}
          drawerText={INFO.progressivaKmIniziale.drawerText}
          drawerImages={INFO.progressivaKmIniziale.drawerImages}
        >
          <RHFNumberTextField
            name="progressivaKmIniziale"
            label={'Progressiva km iniziale*'}
            decimalScale={3}
            withValueLimit={({ value }) => value < 1000000000000}
            defaultValue={''}
            disabled
          />
        </InfoWrapper>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.progressiveKmFinale.drawerTitle}
          drawerText={INFO.progressiveKmFinale.drawerText}
          drawerImages={INFO.progressiveKmFinale.drawerImages}
        >
          <RHFNumberTextField
            name="progressiveKmFinale"
            label={'Progressiva km finale*'}
            decimalScale={3}
            withValueLimit={({ value }) => value < 1000000000000}
            defaultValue={''}
            disabled
          />
        </InfoWrapper>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.classeUso.drawerTitle}
          legislationMessage={INFO.classeUso.legislationMessage}
          drawerText={INFO.classeUso.drawerText}
        >
          <RHFSelect
            name="classeUso"
            label={"Classe d'uso*"}
            defaultValue={''}
            selectOptions={ClasseUsoOptions}
            disabled
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="statoOpera"
            label={"Stato dell'opera*"}
            defaultValue={''}
            selectOptions={StatoOperaOptions}
            disabled
          />
        </GridWrap>
      </GridWrap>
    </>
  );
};

export { Accordion1GeneralInfoL1 };
