import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    padding: '1cm 0.75cm 0.3cm',
  },
  title: {
    fontSize: '16pt',
    lineHeight: 1.2,
    fontFamily: 'Helvetica-Bold',
    marginBottom: '10pt',
  },
  wrapperCdA: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  },
  tagTextWhiteTitle: {
    textAlign: 'center',
    fontSize: '12pt',
    color: 'white',
    fontFamily: 'Helvetica-Bold',
  },
  infoTagGreyTitle: {
    padding: '0.2cm',
    backgroundColor: '#DBDCDD',
    borderRadius: '16pt',
    marginLeft: '5pt',
    width: '120pt',
  },
  tagTextWhite: {
    textAlign: 'center',
    fontSize: '6pt',
    color: 'white',
  },
  infoTagGrey: {
    padding: '0.1cm',
    backgroundColor: '#DBDCDD',
    borderRadius: '16pt',
    width: '50pt',
    marginLeft: '3pt',
    marginTop: '-6pt',
  },
  infoTagBlue: {
    padding: '0.1cm',
    backgroundColor: '#3260CE',
    borderRadius: '16pt',
    width: '50pt',
    marginLeft: '3pt',
    marginTop: '-6pt',
  },
  infoTagOutline: {
    padding: '0.1cm',
    borderRadius: '16pt',
    backgroundColor: 'white',
    border: '1px solid black',
    width: '80pt',
    marginLeft: '3pt',
    marginTop: '-6pt',
  },
  infoLabel: {
    fontSize: '8pt',
    lineHeight: 2,
    fontFamily: 'Helvetica-Bold',
  },
  infoData: {
    fontSize: '8pt',
    lineHeight: 2,
    color: 'grey',
    fontFamily: 'Helvetica',
    marginLeft: '3pt',
  },

  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '16pt',
  },
  captionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '3pt',
  },
  caption: {
    width: '48.5%',
    textAlign: 'left',
  },
  image: {
    width: '48.5%',
    height: '120pt',
    borderRadius: '10pt',
    objectFit: 'cover',
  },
  imageData: {
    width: '20%',
  },
  placeholderImage: {
    width: '48.5%',
    height: '120pt',
    backgroundColor: '#F4F5F6',
    borderRadius: '10pt',
  },
  container: {
    borderRadius: '18pt',
    border: '1px solid #C3C3C3',
    marginTop: '0.50cm',
    marginBottom: '0.50cm',
    padding: '0.45cm',
  },
  titleH2: {
    fontSize: '12pt',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '0.35cm',
  },
  titleH3: {
    fontSize: '9.5pt',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '0.25cm',
  },

  infoTagConservazione: {
    padding: '0.15cm',
    backgroundColor: '#DBDCDD',
    borderRadius: '16pt',
    marginBottom: '6pt',
    marginTop: '-5pt',
    width: '40%',
    marginRight: '10pt',
  },

  tagTextInSection: {
    textAlign: 'center',
    fontSize: '7pt',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'Helvetica-Bold',
  },

  iconElementContainer: {
    width: 8,
    height: 8,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 6,
  },

  //
  tagText: {
    textAlign: 'center',
    fontSize: '6pt',
    color: 'white',
    textTransform: 'uppercase',
  },
  rilevantAspects: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '5pt',
  },

  icTag: {
    padding: '0.1cm',
    backgroundColor: '#3260CE',
    borderRadius: '16pt',
    width: '20pt',
    marginLeft: '3pt',
    marginTop: '-8pt',
  },
  icTagGrey: {
    padding: '0.1cm',
    backgroundColor: '#DBDCDD',
    borderRadius: '16pt',
    width: '20pt',
    marginLeft: '3pt',
    marginTop: '-8pt',
    textTransform: 'lowercase',
  },
  formData: {
    position: 'absolute',
    bottom: 8,
  },
  //
  table: {
    flexDirection: 'row',
    marginTop: '8pt',
    marginBottom: '8pt',
  },
  rowLabel: {
    marginTop: '33pt',
    alignItems: 'flex-end',
  },
  rowLabelText: {
    lineHeight: 1.8,
    fontFamily: 'Helvetica-Bold',
    fontSize: '6pt',
    marginRight: '8pt',
    marginBottom: '12pt',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  row1: {
    fontFamily: 'Helvetica-Bold',
    width: '24pt',
    height: '24pt',
    padding: '0.3cm 0.1cm',
    fontSize: '6pt',
    border: '1px solid #C3C3C3',
    textAlign: 'center',
  },
  row2: {
    fontFamily: 'Helvetica-Bold',
    width: '24pt',
    height: '24pt',
    padding: '0.3cm 0.1cm',
    fontSize: '6pt',
    border: '1px solid #C3C3C3',
    borderLeft: 'none',
    textAlign: 'center',
  },
  rowBody1: {
    width: '24pt',
    height: '24pt',
    padding: '0.3cm 0.1cm',
    fontSize: '6pt',
    border: '1px solid #C3C3C3',
    borderTop: 'none',
    textAlign: 'center',
  },
  rowBody2: {
    width: '24pt',
    height: '24pt',
    padding: '0.3cm 0.1cm',
    fontSize: '6pt',
    border: '1px solid #C3C3C3',
    borderTop: 'none',
    borderLeft: 'none',
    textAlign: 'center',
  },
});
