export const markdown_faq_30_1 = `L'area collaboratori nasce per gestire facilmente i collaboratori, interni ed esterni, e i diritti dei ponti a loro associati. 

ATTENZIONE: Queste funzioni sono disponibili solo per utenti con una sottoscrizione attiva. 

**PER ACCEDERE ALL'AREA COLLABORATORI**
Per accedere, nella sezione dell'azienda, selezionare *Collaboratori*. Avrai accesso quindi all'elenco delle schede dei collaboratori.`;
export const markdown_faq_30_2 = `**PER INSERIRE UN NUOVO COLLABORATORE**
Puoi scegliere se aggiungere un nuovo collaboratore interno o esterno.
Ricorda che: 
- i **collaboratori interni** sono coloro che fanno parte della tua realtà aziendale, ed infatti saranno sempre legati e dipendenti dall’utente principale che li ha inseriti o invitati. 
- i **collaboratori esterni** sono coloro che collaborano con incarico esterno in forma di consulenza o operativa alla gestione del patrimonio.

Scegli *Nuovo collaboratore interno* per inserire i collaboratori che fanno parte della tua realtà aziendale. 

Scegli *Invita utente esterno* per inserire utenti che non fanno parte della tua realtà aziendale.`;
export const markdown_faq_30_3 = `Nella scheda di inserimento del nuovo utente:
1. Inserire i dati relativi a nome, cognome, mail e al messaggio che si desidera inviare
2. Se non ci sono momentaneamente ponti da associare, selezionare *Salva*, altrimenti proseguire con la procedura.`;
export const markdown_faq_30_4 = `**PER SELEZIONARE I PONTI DA ASSOCIARE**
Nel caso in cui si abbiano molti ponti da gestire, può essere utile poter assegnare rapidamente più ponti a un collaboratore, interno o esterno.

**Come ricercare i ponti da associare**
E' possibile velocizzare l'assegnazione delle opere ricercando quelle da associare:
1. Inserire i filtri e i tag della ricerca. 
2. Selezionare l'icona della lente. 

L'elenco dei ponti sottostante sarà ridotto in base alla ricerca effettuata.`;
export const markdown_faq_30_5 = `**Come assegnare i ponti**
1. Selezionare nella tabella i ponti che si vogliono associare
2. Nella stringa sopra la tabella definire i diritti e le date di scadenza
3. Selezionare la V 
4. Selezionare *Salva*.

In questo modo la tabella dei ponti sarà aggiornata nei contenuti sulla base delle informazioni inserite.`;
export const markdown_faq_30_6 = `**PER MODIFICARE I DATI O LE ASSEGNAZIONI DI UN COLLABORATORE**
Per modificare i dati o le assegnazioni di un collaboratore:
1. Selezionare l'icona della penna relativa al collaboratore su cui si vuole operare.
2. Entrando nella scheda del collaboratore potrai modificare i dati del collaboratore e i diritti dei ponti a lui assegnati
3. Terminate le modifiche, selezionare *Salva* per aggiornare la scheda.`;
