import styled from '@emotion/styled';

export const ElementContainer = styled('div')(() => ({
  padding: '12px',
  width: '720px',
  /* height: '349px', */
  maxHeight: '500px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
}));

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'end',
  flex: 1,
}));

export const Container = styled('div')(() => ({
  display: 'flex',
  margin: 5,
  alignItems: 'center',
  paddingBottom: 5,
  borderBottom: '1px solid #e5e5e5',
  gap: 20,
  justifyContent: 'space-between',
}));

export const Box = styled('div')(() => ({
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const Box1 = styled('div')(() => ({
  display: 'flex',
  flex: 1,
}));
