import styled from '@emotion/styled';
import { Chip, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// prop-types

// react-intl
import { Controller, useFormContext } from 'react-hook-form';

import { useFetch } from 'use-http';

import ArrowIcon from 'assets/icons/arrow-down.svg';
import CloseIcon from 'assets/icons/close.svg';

const CustomTextField = styled(TextField)({
  '& .MuiAutocomplete-tag > svg.MuiSvgIcon-fontSizeMedium': {
    backgroundImage: `url(${CloseIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    fill: 'none',
    backgroundSize: '40%',
  },
  '& button > svg.MuiSvgIcon-fontSizeMedium': {
    backgroundImage: `url(${ArrowIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    fill: 'none',
    backgroundSize: '60%',
  },
});

// eslint-disable-next-line react/display-name
const RHFApiAutocomplete = ({
  name,
  disabled,
  endpoint,
  options,
  label,
  minSearchLength,
  defaultValue,
  isRequired = false,
  multiple = false,
  freeSolo = false,
  rules,
  ...rest
}) => {
  // state
  const [currentOptions, setCurrentOptions] = useState([]);
  // use http
  const { get, loading } = useFetch();
  const { control } = useFormContext();

  // Effects

  useEffect(() => {
    if (options.length) setCurrentOptions(options);
  }, [options]);

  useEffect(() => {
    if (minSearchLength === 0) {
      handleInputChange(null, '');
    }
  }, [endpoint]);

  // Helpers
  let timer;
  const handleInputChange = (event, value) => {
    if (value.length >= minSearchLength && endpoint) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        get(value ? `${endpoint}&query=${value}` : `${endpoint}`).then(res => {
          const data =
            res?.length > 0
              ? res?.map(o => ({
                  value: {
                    id: o.id,
                    code: o?.code,
                    cadastralCode: o?.cadastralCode,
                    classificazioneSismica: o?.seismicZone,
                  },
                  label: o.name || o.code,
                }))
              : [];

          setCurrentOptions(data);
        });
      }, 500);
    }
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue || null}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Autocomplete
            multiple={multiple}
            options={currentOptions}
            freeSolo={freeSolo}
            autoComplete
            noOptionsText={'Nessun valore'}
            disabled={disabled}
            loading={loading}
            value={value}
            filterSelectedOptions
            loadingText={<CircularProgress size={20} />}
            onChange={(_, newValue) => {
              onChange(newValue);
            }}
            onInputChange={handleInputChange}
            renderInput={params => (
              <CustomTextField
                {...params}
                label={
                  <Typography variant="h4">
                    {label}
                    {isRequired && '*'}
                  </Typography>
                }
                fullWidth
                // inputRef={ref}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
                error={!!error}
                helperText={error?.message}
                // {...field}
              />
            )}
            {...rest}
          />
        )}
      />
    </>
  );
};
RHFApiAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  options: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  endpoint: PropTypes.string,
  queryParam: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  resetValue: PropTypes.bool,
  isRequired: PropTypes.bool,
  multiple: PropTypes.bool,
  freeSolo: PropTypes.bool,
  minSearchLength: PropTypes.number,
};

RHFApiAutocomplete.defaultProps = {
  disabled: false,
  minSearchLength: 3,
  options: [],
};

const RHFTagAutocomplete = ({
  name,
  defaultValue,
  disabled,
  multiple,
  freeSolo,
  placeholder,
  labelName,
  label,
  changeAutocomplete,
  opt,
  ...rest
}) => {
  // state
  const [, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const { control } = useFormContext();

  useEffect(() => {
    setOptions(opt);
  }, [opt]);

  // const renderError = (key, error, errorObj) => {
  //   if (error[key] && error[key].type)
  //     return (
  //       <Typography color={'error'}>{errorObj[error[key].type]}</Typography>
  //     );
  //   return null;
  // };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || []}
        render={({ field: { value, onChange } }) => {
          return (
            <Autocomplete
              sx={{ height: '100%' }}
              fullWidth={true}
              multiple={multiple}
              freeSolo={freeSolo}
              options={options}
              disabled={disabled}
              value={value}
              /* onBlur={event => {
                if (event?.target?.value) {
                  setValue([...value, event?.target?.value]);
                  onChange([...value, event?.target?.value]);
                }
              }} */
              onChange={(event, newValue) => {
                if (changeAutocomplete) {
                  changeAutocomplete(newValue);
                  onChange(newValue);
                } else {
                  if (!freeSolo) {
                    setOptions(newValue ? [newValue, ...options] : options);
                  }
                  setValue(newValue);
                  onChange(newValue);
                }
              }}
              renderTags={(tags, getTagProps) => {
                return tags?.map((option, index) => (
                  <Chip
                    key={'index' + index}
                    color="primary"
                    label={eval(labelName)}
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={params => {
                /*                 const currentVal = params?.inputProps?.value;
                console.log(value, 'VALUE');
                console.log(params?.inputProps?.value, 'value');
                const findSameValue = value?.some(
                  el => el === params?.inputProps?.value
                ); */

                return (
                  <CustomTextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    /* onChange={e => setInputText(e?.target?.value)} */
                  />
                );
              }}
              {...rest}
            />
          );
        }}
      />
      {/* {error && errorObj && renderError(name, error, errorObj)} */}
    </>
  );
};

RHFTagAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  opt: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  freeSolo: PropTypes.bool,
  queryParam: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  resetValue: PropTypes.bool,
  isRequired: PropTypes.bool,
  multiple: PropTypes.bool,
  changeAutocomplete: PropTypes.func,
  labelName: PropTypes.string,
};

RHFTagAutocomplete.defaultProps = {
  disabled: false,
  multiple: false,
  freeSolo: false,
  placeholder: '',
  labelName: 'option',
  opt: [],
};

export { RHFApiAutocomplete, RHFTagAutocomplete };
