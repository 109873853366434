import { Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
//eslint-disable-next-line
import useFetch from 'use-http';
import { HeaderPage, InfoBox, RHFDatePicker, RHFTextField } from 'components';
import { apiGeo, apiUsers } from 'constants/api';
import { UserTypology } from 'constants/users';
import { useSubscription, useUsers } from 'hooks';
import { FormProvider } from 'providers';

import { useGlobalSelector } from 'stores';
import { getExpirationInfo } from 'utils/utilities';
import {
  ButtonContainer,
  ButtonWrapper,
  CustomAutocomplete,
  CustomDivider,
  CustomTextField,
  HeaderWrapper,
} from './Profile.styles';

const ProfileAndSubscription = () => {
  const methods = useForm({
    defaultValues: {
      email: '',
      name: '',
      surname: '',
      pec: '',
      address: '',
      fiscalCode: '',
      phone: '',
      businessName: '',
      region: '',
      province: '',
      council: '',
      maxAvailableSpace: '',
      maxStructureNumber: '',
      expirationDate: null,
    },
  });
  const { get, put } = useFetch();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { enqueueSnackbar } = useSnackbar();
  const { getUserInfo } = useUsers();
  const { handleSubmit, watch, reset, setValue } = methods;
  const navigate = useNavigate();
  const { user } = useSubscription();
  const [expirationDate, setExpirationDate] = useState();
  const [currentUserExpirationDate, setCurrentUserExpirationDate] = useState();

  const [userId, setUserId] = useState();

  const getDetail = async () => {
    showLoader();
    await getUserInfo()
      .then(async res => {
        const resUser = await get(`${apiUsers.ROOT}/${res.id}/profile-and-sub`);
        setUserId(res.id);
        setCurrentUserExpirationDate(resUser?.subscription?.expirationDate);
        reset({
          ...resUser,
          maxStructureNumber: resUser?.subscription?.maxStructureNumber,
          maxAvailableSpace: resUser?.subscription?.maxAvailableSpace,
          expirationDate: resUser?.subscription?.expirationDate,
        });
      })
      .finally(() => {
        hideLoader();
      });
  };
  useEffect(() => {
    getDetail();
  }, []);

  const onSubmit = async data => {
    const payload = data;
    payload.regionId = data?.region?.value?.id || undefined;
    payload.provinceId = data?.province?.value?.id || undefined;
    payload.councilId = data?.council?.value?.id || undefined;
    try {
      showLoader();
      /* await post(`${apiUsers.ROOT}/${userId}${apiUsers.EDIT}`, payload); */
      await put(`${apiUsers.ROOT}/${userId}/profile-and-sub`, payload);
      enqueueSnackbar('Utente aggiornato correttamente', {
        variant: 'success',
      });
    } catch (err) {
      console.log(err, 'ERROR IN SAVE DATA');
      enqueueSnackbar('Errore durante la richiesta', {
        variant: 'error',
      });
    } finally {
      hideLoader();
      window.location.reload();
    }
  };

  const regionWatch = watch('region');
  const provinceWatch = watch('province');

  useEffect(() => {
    if (!regionWatch) {
      setValue('province', '');
      setValue('council', '');
    }
    if (!provinceWatch) {
      setValue('council', '');
    }
  }, [regionWatch, provinceWatch]);

  useEffect(() => {
    if (currentUserExpirationDate) {
      const result = getExpirationInfo(currentUserExpirationDate);
      if (result <= 31 && result > 0) {
        setExpirationDate(result);
      } else if (result <= 0) {
        setExpirationDate(-1);
      }
    }
  }, [currentUserExpirationDate]);

  /* console.log(user, 'USER'); */

  const userTypo = user?.tipologiaUtente;
  const utentePrincipale = userTypo === UserTypology.UtentePrincipale;

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <HeaderWrapper>
          <HeaderPage title="Profilo e sottoscrizione" />
          <ButtonContainer>
            <ButtonWrapper
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => navigate(-1)}
            >
              Annulla
            </ButtonWrapper>
            <ButtonWrapper variant="containedBlack" size="small" type="submit">
              Modifica
            </ButtonWrapper>
          </ButtonContainer>
        </HeaderWrapper>
        <Grid container xs={12} direction="column">
          <Grid item xs={12}>
            <CustomTextField disabled label="Email d'accesso" name="email" />
          </Grid>
          <Grid item>
            <CustomDivider>{`INFORMAZIONI ${
              user?.type === 'PROFESSIONISTA' ? 'AZIENDA' : 'ENTE'
            }`}</CustomDivider>
          </Grid>

          <Grid container xs={12} direction="row">
            <Grid item xs={6}>
              <CustomTextField
                InputLabelProps={{ shrink: true }}
                label="Regione Sociale"
                name="businessName"
                /* rules={{ required: 'Campo obbligatorio' }} */
              />
            </Grid>
            <Grid item xs={3}>
              <CustomTextField
                InputLabelProps={{ shrink: true }}
                label="Telefono"
                name="phone"
              />
            </Grid>
            <Grid item xs={3}>
              <CustomTextField
                InputLabelProps={{ shrink: true }}
                label="C.F./P.IVA"
                name="fiscalCode"
                /* rules={{ required: 'Campo obbligatorio' }} */
              />
            </Grid>
            <Grid item xs={3}>
              <CustomAutocomplete
                name="region"
                label={'Regione'}
                endpoint={`${apiGeo.ROOT}${apiGeo.REGIONS}?`}
                getOptionLabel={option =>
                  option?.label && option?.value ? `${option?.label}` : option
                }
              />
            </Grid>

            <Grid item xs={3}>
              <CustomAutocomplete
                name="province"
                label={'Provincia'}
                endpoint={`${apiGeo.ROOT}${apiGeo.PROVINCES}?parentId=${
                  watch('region')?.value?.id
                }`}
                getOptionLabel={option =>
                  option?.label && option?.value ? `${option?.label}` : option
                }
                disabled={!watch('region')}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomAutocomplete
                name="council"
                label={'Comune'}
                endpoint={`${apiGeo.ROOT}${apiGeo.COUNCILS}?parentId=${
                  watch('province')?.value?.id
                }`}
                getOptionLabel={option =>
                  option?.label && option?.value ? `${option?.label}` : option
                }
                disabled={!watch('province')}
              />
            </Grid>

            <Grid item xs={3}>
              <CustomTextField
                InputLabelProps={{ shrink: true }}
                label="Indirizzo"
                name="address"
              />
            </Grid>
          </Grid>
          <Grid item>
            <CustomDivider>LA TUA SOTTOSCRIZIONE</CustomDivider>
          </Grid>
          <Grid container spacing={2} direction="row">
            {currentUserExpirationDate && expirationDate > 0 && (
              /* subscriptionStatus === SubscriptionStatus.ATTIVA && ( */
              <Grid item xs={12}>
                <InfoBox
                  color="primary.light"
                  text={
                    <>
                      <Typography variant="h3Bold">
                        {`ATTENZIONE!`}{' '}
                        {utentePrincipale
                          ? `La tua sottoscrizione`
                          : `La sottoscrizione relativa al tuo account principale`}{' '}
                        {` scadrà tra ${expirationDate} ${
                          expirationDate > 1 ? 'giorni' : 'giorno'
                        }. Contattaci all'indirizzo `}
                        <span>
                          <a href="mailto: info@inbee.it">info@inbee.it</a>
                        </span>
                        {` per rinnovare la sottoscrizione o contatta il tuo referente INBEE.`}{' '}
                      </Typography>
                    </>
                  }
                />
              </Grid>
            )}

            {currentUserExpirationDate && expirationDate < 0 && (
              /* subscriptionStatus === SubscriptionStatus.SCADUTA && ( */
              <Grid item xs={12}>
                <InfoBox
                  color="primary.light"
                  text={
                    <>
                      <Typography variant="h3Bold">
                        {`ATTENZIONE!`}{' '}
                        {utentePrincipale
                          ? `La tua sottoscrizione`
                          : `La sottoscrizione relativa al tuo account principale`}{' '}
                        {` è scaduta. Contattaci all'indirizzo `}
                        <span>
                          <a href="mailto: info@inbee.it">info@inbee.it</a>
                        </span>
                        {` per rinnovare la sottoscrizione o contatta il tuo referente INBEE.`}{' '}
                      </Typography>
                    </>
                  }
                />
              </Grid>
            )}

            {!currentUserExpirationDate ? (
              /* subscriptionStatus === SubscriptionStatus.ND ? ( */
              <Grid item xs={12}>
                <InfoBox
                  color="primary.light"
                  text={
                    <>
                      <Typography variant="h3Bold">
                        {`Non hai una sottscrizione
                           attiva. Contattaci all'indirizzo `}
                        <span>
                          <a href="mailto: info@inbee.it">info@inbee.it</a>
                        </span>
                        {` per attivare le funzionalità della piattaforma.`}{' '}
                      </Typography>
                    </>
                  }
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={3}>
                  <RHFDatePicker
                    label="Data scadenza"
                    name="expirationDate"
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <RHFTextField
                    defaultValue={''}
                    label="Max Nr. Opere"
                    name="maxStructureNumber"
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <RHFTextField
                    defaultValue={''}
                    label="Max Spazio Disponibile"
                    name="maxAvailableSpace"
                    disabled
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default ProfileAndSubscription;
