import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiAccessoriL1SectionWrapper,
  InspectionInfoScheda20Form,
} from 'components';
import {
  paliIlluminazioneOptions,
  tipologiaPaliOptions,
} from 'constants/selectOptions';
import PaliForm from './PaliForm';

const Accordion1PaliIlluminazione = () => {
  const { watch, setValue, getValues } = useFormContext();
  const paliTableWatch = watch('paliTable');

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsPali = item => {
    setValue('paliTableValue', [item]);
    setValue('tipologiaPaliTableValue', item.tipologiaPali);
  };

  const handleSelectAllClickPali = event => {
    if (event.target.checked) {
      const selectedValue = getValues(`paliTable`);
      setSelected(selectedValue);
      setValue('paliTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('paliTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t4a1_tecnicoRilevatorePali',
      getValues(`paliTable[0].tecnicoRilevatore`) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t4a1_dataIspezionePali',
      getValues(`paliTable[0].dataIspezione`) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t4a1_noteGeneraliPali',
      getValues(`paliTable[0].noteGenerali`) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t4a1_tecnicoRilevatorePali');
    const dataIspezione = getValues('l1t4a1_dataIspezionePali');

    const noteGenerali = getValues('l1t4a1_noteGeneraliPali');
    setValue(`paliTable[0].tecnicoRilevatore`, tecnicoRilevatore);
    setValue(`paliTable[0].dataIspezione`, dataIspezione);
    setValue(`paliTable[0].noteGenerali`, noteGenerali);
  };

  const handleResetInspection = () => {
    setValue(`l1t4a1_tecnicoRilevatorePali`, '');
    setValue(`l1t4a1_dataIspezionePali`, null);
    setValue(`l1t4a1_noteGeneraliPali`, '');
  };

  const dataPaliConfig = {
    columns: {
      presenza: { label: 'Pali', format: paliIlluminazioneOptions },
      tipologiaPali: {
        label: 'Tipologia',
        format: tipologiaPaliOptions,
      },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      nrtotali: { label: 'NR' },
    },
  };

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiAccessoriL1SectionWrapper
        elementTitle={'pali illuminazione'}
        singleElementTitle={'palo illuminazione'}
        tableWatch={paliTableWatch}
        tableWatchName={`paliTable`}
        elementForm={
          <PaliForm
            paliFields={paliTableWatch || []}
            selected={selected}
            setSelected={setSelected}
            paliTable={`paliTable`}
          />
        }
        inspectionInfoForm={
          <InspectionInfoScheda20Form
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t4a1_tecnicoRilevatorePali'}
            dateName={'l1t4a1_dataIspezionePali'}
            noteGeneraliName={'l1t4a1_noteGeneraliPali'}
          />
        }
        tableConfig={dataPaliConfig}
        editRows={editRowsPali}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickPali}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
      />
    </>
  );
};
export default Accordion1PaliIlluminazione;
