import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useFetch } from 'use-http';
import * as yup from 'yup';
import {
  BasicDialog,
  HeaderPage,
  InfoAssistanceDialogActions,
  InfoWrapper,
  MapWrapper,
  RHFApiAutocomplete,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import { apiBridges, apiGeo } from 'constants/api';
import {
  CreateBridgeDisabledMessageMap,
  CreateBridgeDisabledType,
  internalCodeGenerationMode,
} from 'constants/bridges';
import { STRUCTURES_PATHS } from 'constants/paths';
import { SubscriptionStatus } from 'constants/subscription';
import { UserType } from 'constants/users';
import { useSubscription } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { FormProvider } from 'providers';
import { useGlobalSelector } from 'stores';
import { ButtonSpace, Container, WhiteBox } from './DuplicateStructure.styles';

const DuplicateStructure = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { get, post, response } = useFetch();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { subscription, subscriptionStatus, relatedStructuresNumber, user } =
    useSubscription();

  // State
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState();
  const [structure, setStructure] = useState();

  const params = useParams();
  const id = params.structureId;

  const defaultValues = {
    name: '',
    tags: [],
    region: '',
    province: '',
    postalCode: '',
    address: '',
    street: '',
    iop: '',
    //internalCode: '',
    council: '',
    internalCodeGenerationMode: internalCodeGenerationMode.AUTO,
    latitude: '',
    longitude: '',
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const schema = yup
    .object({
      name: yup.string().required("Nome dell'opera obbligatorio"),
      region: yup.object().required('Regione obbligatoria').nullable(),
      province: yup.object().required('Provincia obbligatoria').nullable(),
      postalCode: yup.object().required('Cap obbligatorio').nullable(),
      council: yup.object().required('Comune obbligatorio').nullable(),
      latitude: yup
        .string()
        .required('Latitudine obbligatoria')
        .matches(
          /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/,
          'Inserire una latitudine valida'
        ),
      longitude: yup
        .string()
        .required('Longitudine obbligatoria')
        .matches(
          /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/,
          'Inserire una longitudine valida'
        ),
      // iop: yup.string().required('Codice IOP obbligatorio'),
      // internalCode: yup.string().required('Codice interno obbligatorio'),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  console.log(methods, 'method');

  const onSubmit = async data => {
    console.log(data, 'data');
    const updatedData = {
      name: data?.name,
      tags: data.tags?.length > 0 ? data.tags?.join(',') : '',
      regionId: data.region?.value?.id,
      provinceId: data.province?.value?.id,
      councilId: data.council?.value?.id,
      capId: data.postalCode?.value?.id,
      address: data?.address,
      street: data?.street,
      internalCodeGenerationMode: internalCodeGenerationMode.AUTO,
      internalCode: `PN${data.province?.value?.code}${data.council?.value?.cadastralCode}`,
      /* internalCodeGenerationMode: data?.internalCodeGenerationMode,
      internalCode:
        data?.internalCodeGenerationMode === internalCodeGenerationMode.AUTO
          ? `PN${data.province?.value?.code}${data.council?.value?.cadastralCode}`
          : data?.internalCode, */
      iop: data?.iop ? data?.iop : undefined,
      latitude: data?.latitude,
      longitude: data?.longitude,
      originId: structure?.id,
      sharedWith: [],
    };
    let result = '';
    try {
      showLoader();
      result = await post(`${apiBridges.ROOT}`, updatedData);
      enqueueSnackbar('Opera aggiunta', {
        variant: 'success',
      });
    } catch (err) {
      console.error('Error in creation bridge: ', err);
      enqueueSnackbar('Errore durante la duplicazione', {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }

    if (response.ok) {
      navigate(`${STRUCTURES_PATHS.STRUCTURES_LIST}/${result.id}`);
    }
  };

  const {
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
  } = methods;

  const isAutoMode =
    watch('internalCodeGenerationMode') === internalCodeGenerationMode.AUTO;

  const handleDetail = async () => {
    try {
      showLoader();
      const result = await get(`${apiBridges.ROOT}/${id}`);
      if (result) {
        setStructure(result);
        const copiesNumber = result?.copies + 1;
        const formatRegion = {
          label: result?.region.name,
          value: {
            id: result?.region.id,
          },
        };
        const formatProvince = {
          label: result?.province.name,
          value: {
            id: result?.province.id,
            code: result?.province.code,
          },
        };
        const formatCouncil = {
          label: result?.council.name,
          value: {
            id: result?.council.id,
            cadastralCode: result?.council.cadastralCode,
          },
        };
        const formatPostalCode = {
          label: result?.cap.code,
          value: {
            id: result?.cap.id,
          },
        };

        reset({
          ...result,
          name: `${result?.name}_A${copiesNumber}`,
          tags: result?.tags?.length ? result?.tags?.split(',') : [],
          postalCode: formatPostalCode,
          region: formatRegion,
          province: formatProvince,
          council: formatCouncil,
          internalCodeGenerationMode: '',
          internalCode: '',
        });
      }
    } catch (err) {
      console.error('Error in put diagnosis: ', err);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    id && handleDetail().catch(console.error);
  }, [id]);

  useEffect(() => {
    if (structure) {
      /*  const copiesNumber = structure?.copies + 1;
      reset({
        name: `${structure?.name}_A${copiesNumber}`,
        tags: structure?.tags?.length ? structure?.tags?.split(',') : [],
        region: structure?.region.name,
        province: structure?.province.name,
        postalCode: structure?.cap.code,
        council: structure?.council.name,
        address: structure?.address,
        street: structure?.street,
        latitude: structure?.latitude,
        longitude: structure?.longitude,
      }); */
    }
  }, [structure]);

  const regionWatch = watch('region');
  const provinceWatch = watch('province');
  const councilWatch = watch('council');

  useEffect(() => {
    if (!regionWatch) {
      setValue('province', '');
      setValue('council', '');
      setValue('postalCode', '');
    }
    if (!provinceWatch) {
      setValue('council', '');
      setValue('postalCode', '');
    }
    if (!councilWatch) {
      setValue('postalCode', '');
    }
  }, [regionWatch, provinceWatch, councilWatch]);

  useEffect(() => {
    if (
      getValues('internalCodeGenerationMode') ===
      internalCodeGenerationMode.AUTO
    ) {
      setValue('internalCode', 'esempio: PN MI F205 0001');
    } else {
      setValue('internalCode', '');
    }
  }, [getValues('internalCodeGenerationMode')]);

  useEffect(() => {
    if (center.lat === 0 && center.lng === 0) {
      setValue('latitude', '');
      setValue('longitude', '');
    } else {
      setValue('latitude', center.lat);
      setValue('longitude', center.lng);
    }
  }, [center]);

  useEffect(() => {
    const geoFunc = window.setTimeout(() => {
      Geocode.setApiKey(process.env.REACT_APP_API_KEY);
      Geocode.setLanguage('it');
      Geocode.setRegion('it');

      const region = getValues('region').label;
      const postalCode = getValues('postalCode').label;

      const council = getValues('council').label;
      const province = getValues('province').label;
      const geoAddress = `${region} ${province} ${council} ${postalCode}   ${getValues(
        'address'
      )}`;

      Geocode.fromAddress(geoAddress).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          if (!getValues('latitude') && !getValues('longitude')) {
            setValue('latitude', lat);
            setValue('longitude', lng);
            setCenter({ lat: lat, lng: lng });
          }
        },

        error => {
          console.error(error);
        }
      );
    }, 2000);
    return () => window.clearTimeout(geoFunc);
  }, [watch('address')]);

  return (
    <Container>
      <FormProvider
        methods={methods}
        /* onSubmit={handleSubmit(onSubmit, console.log)} */
      >
        <HeaderPage
          back={STRUCTURES_PATHS.STRUCTURE_DETAIL.replace(':structureId', id)}
          buttons={
            <>
              <ButtonSpace
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() =>
                  navigate(
                    STRUCTURES_PATHS.STRUCTURE_DETAIL.replace(
                      ':structureId',
                      id
                    )
                  )
                }
              >
                Annulla
              </ButtonSpace>
              <>
                {subscription &&
                  subscriptionStatus === SubscriptionStatus.ATTIVA &&
                  relatedStructuresNumber < subscription.maxStructureNumber && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleSubmit(onSubmit, console.log)}
                    >
                      Aggiungi opera
                    </Button>
                  )}
                {(subscriptionStatus === SubscriptionStatus.ND ||
                  (subscription &&
                    subscriptionStatus === SubscriptionStatus.SCADUTA)) && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                  >
                    Aggiungi opera
                  </Button>
                )}
                {subscription &&
                  subscriptionStatus === SubscriptionStatus.ATTIVA &&
                  relatedStructuresNumber >=
                    subscription.maxStructureNumber && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        setDialogMessage(
                          CreateBridgeDisabledType.MAX_STRUCTURES_REACHED
                        );
                        setOpenDialog(true);
                      }}
                    >
                      Aggiungi opera
                    </Button>
                  )}
              </>
            </>
          }
          title="Duplica opera"
        />

        <WhiteBox>
          <Grid container spacing={2} padding="20px">
            <Grid item xs={6} marginTop="4px">
              <RHFTextField
                name="name"
                required
                label={'Nome opera'}
                inputProps={{ maxLength: 150 }}
              />
            </Grid>

            <InfoWrapper size={6} infoMessage={INFO.codiceIOP.infoMessage}>
              <RHFTextField
                name="iop"
                label={'Codice IOP'}
                inputProps={{ maxLength: 18 }}
                // TEC-521
                // Caricare il codice IOP (solo per Utenti Principali o Collaboratori Interni di tipo Ente)
                disabled={user?.type !== UserType.Ente}
              />
            </InfoWrapper>

            <InfoWrapper size={12} infoMessage={INFO.structureTag.infoMessage}>
              <RHFTagAutocomplete
                multiple={true}
                id="tags-outlined"
                name={'tags'}
                errors={errors}
                freeSolo={true}
                label="Tag"
                placeholder="Scrivi, poi premi invio per inserire il tag"
              />
            </InfoWrapper>
            <Grid item xs={6}>
              <RHFApiAutocomplete
                name={'region'}
                label={'Regione'}
                minSearchLength={0}
                error={!!errors.region}
                endpoint={`${apiGeo.ROOT}${apiGeo.REGIONS}?`}
                getOptionLabel={option =>
                  option?.label && option?.value ? `${option?.label}` : option
                }
                isRequired
              />
            </Grid>
            <Grid item xs={6}>
              <RHFApiAutocomplete
                name={'province'}
                label={'Provincia'}
                minSearchLength={0}
                error={!!errors.province}
                endpoint={`${apiGeo.ROOT}${apiGeo.PROVINCES}?parentId=${
                  watch('region')?.value?.id
                }`}
                getOptionLabel={option =>
                  option?.label && option?.value ? `${option?.label}` : option
                }
                disabled={!watch('region')}
                isRequired
              />
            </Grid>
            <Grid item xs={6}>
              <RHFApiAutocomplete
                name={'council'}
                minSearchLength={0}
                label={'Comune'}
                error={!!errors.council}
                endpoint={`${apiGeo.ROOT}${apiGeo.COUNCILS}?parentId=${
                  watch('province')?.value?.id
                }`}
                getOptionLabel={option =>
                  option?.label && option?.value ? `${option?.label}` : option
                }
                disabled={!watch('province')}
                isRequired
              />
            </Grid>
            <Grid item xs={6}>
              <RHFApiAutocomplete
                name={'postalCode'}
                minSearchLength={0}
                label={'CAP'}
                error={!!errors.postalCode}
                endpoint={`${apiGeo.ROOT}${apiGeo.CAPS}?parentId=${
                  watch('council')?.value?.id
                }`}
                getOptionLabel={option =>
                  option?.label && option?.value ? `${option?.label}` : option
                }
                disabled={!watch('council')}
                isRequired
              />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField
                name="address"
                disabled={!watch('postalCode')}
                label={'Indirizzo'}
              />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField
                name="street"
                label={'Strada di appartenenza'}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <RHFSelect
                name="internalCodeGenerationMode"
                required
                label={'Compilazione Codice Interno - ID'}
                rules={{ required: true }}
                selectOptions={[
                  {
                    label: 'Generato automaticamente',
                    value: internalCodeGenerationMode.AUTO,
                  },
                  {
                    label: 'Manuale',
                    value: internalCodeGenerationMode.MANUAL,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField
                name="internalCode"
                required={isAutoMode ? false : true}
                label={'Codice interno -ID'}
                inputProps={{ maxLength: 30 }}
                disabled={isAutoMode || !watch('internalCodeGenerationMode')}
              />
            </Grid> */}
            <Grid item xs={6}>
              <RHFTextField name="latitude" required label={'Latitudine'} />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField name="longitude" required label={'Longitudine'} />
            </Grid>
          </Grid>
          <Typography
            variant="body"
            style={{ margin: '10px 15px', fontWeight: 'bold' }}
          >
            Clicca sulla mappa per popolare più precisamente le coordinate:
          </Typography>
          <MapWrapper
            lat={Number(watch('latitude'))}
            lng={Number(watch('longitude'))}
            center={center}
            setCenter={setCenter}
          />
        </WhiteBox>
      </FormProvider>
      <BasicDialog
        open={openDialog}
        onClose={handleCloseDialog}
        actions={
          <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />
        }
      >
        {CreateBridgeDisabledMessageMap[dialogMessage]}
      </BasicDialog>
    </Container>
  );
};

export default DuplicateStructure;
