import {
  inputBaseClasses,
  tableBodyClasses,
  tableCellClasses,
  tableHeadClasses,
  tablePaginationClasses,
  tableRowClasses,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { colors } from './constants/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: colors.ORANGE,
      light: colors.LIGHTYELLOW,
    },
    secondary: {
      main: colors.BLACK,
      light: colors.WHITE,
    },
    error: {
      main: colors.RED,
      light: colors.PALAGREY,
      mauvelous: colors.MAUVELOUS,
    },
    success: {
      main: colors.GREEN,
      light: colors.LIGHTGREEN,
    },
    warning: {
      main: colors.ORANGE,
      light: colors.LIGHTYELLOW,
    },
    info: {
      main: colors.BLUE,
      light: colors.SPINDLE,
    },
    background: {
      default: colors.LIGHTGREY,
      grey: colors.GREY,
      mariner: colors.MARINER,
    },
  },
  typography: {
    h1: {
      fontSize: '1.93rem',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
      lineHeight: '2.31rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.5rem',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
      fontWeight: 'bold',
      lineHeight: '1.68rem',
    },
    h3: {
      fontSize: '0.87rem',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
      lineHeight: '1rem',
    },
    h3Bold: {
      fontSize: '0.87rem',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
      lineHeight: '1rem',
      fontWeight: 'bold',
    },
    h3Faq: {
      fontSize: '1.1rem',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
      lineHeight: '1rem',
      fontWeight: 'bold',
    },
    h3BoldBlue: {
      fontSize: '0.87rem',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
      lineHeight: '1rem',
      fontWeight: 'bold',
      color: colors.BLUE,
    },
    p: {
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
    },

    h4: {
      fontSize: '0.8rem',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
      lineHeight: '0.87rem',
    },
    h5: {
      fontSize: '0.75rem',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
    },
    h5BoldRed: {
      fontSize: '0.75rem',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
      fontWeight: 'bold',
      color: colors.RED,
    },
    h6: {
      fontSize: '0.70rem',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
      fontWeight: 'bold',
      lineheight: '1.31rem',
    },
    body: {
      fontSize: '0.87rem',
      fontFamily: `"Work Sans", "Helvetica", "Arial", sans-serif`,
      lineHeight: '1rem',
    },
    footer: {
      fontSize: '1.25rem',
      fontFamily: `"Ubuntu", sans-serif`,
      letterSpacing: '1px',
    },

    smallTitle: {
      fontSize: '0.87rem',
      fontFamily: `"Work Sans", "Helvetica", "Arial", sans-serif`,
      lineHeight: '1rem',
      fontWeight: 'bold',
      color: colors.BLUE,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          height: '49px',
          padding: '12px 20px',
          textTransform: 'initial',
          borderRadius: '8px',
          boxShadow: 'none',
          fontSize: '16px',
          fontFamily: 'Work Sans',
          whiteSpace: 'no-wrap',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeSmall: {
          borderRadius: '6px',
          fontSize: '14px',
          padding: '5px 15px',
          height: 36,
        },
        containedSecondary: {
          '&:hover': {
            color: colors.DARKGREY,
          },
        },
        containedBlack: {
          backgroundColor: colors.BLACK,
          color: colors.WHITE,
          '&:hover': {
            color: colors.DARKGREY,
            backgroundColor: colors.BLACK,
          },
          '&.Mui-disabled': {
            color: colors.DARKGREY,
            opacity: 0.3,
          },
        },
        containedNavbar: {
          fontSize: 20,
          borderRadius: 13,
          padding: '28px 65px',
          color: colors.BLACK,
          border: `2px solid ${colors.BLACK}`,
          '&:hover': {
            border: `2px solid ${colors.ORANGE}`,
            backgroundColor: '#FFAD4C',
          },
        },
        containedForm: {
          borderRadius: 10,
          color: colors.BLACK,
          border: `2px solid ${colors.BLACK}`,
          '&:hover': {
            border: `2px solid ${colors.BLACK}`,
            backgroundColor: colors.WHITE,
          },
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: colors.ORANGE,
            color: colors.BLACK,
            border: `1px solid ${colors.ORANGE}`,
          },
        },
        outlinedSecondaryBorder: {
          border: `2px solid ${colors.BLACK}`,
          padding: '20px 60px',
          '&:hover': {
            backgroundColor: colors.BLACK,
            color: colors.WHITE,
            border: `2px solid ${colors.BLACK}`,
          },
        },
        outlinedSecondary: {
          '&:hover': {
            backgroundColor: colors.BLACK,
            color: colors.WHITE,
            border: `1px solid ${colors.BLACK}`,
          },
        },
        text: {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        },
        textBlack: {
          color: colors.BLACK,
          padding: 0,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        },
        textWhite: {
          color: colors.WHITE,
          padding: 0,
          fontSize: 20,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        },
        endIcon: {
          color: colors.ORANGE,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: 'black',
          '&:disabled': {
            backgroundColor: colors.PALAGREY,
          },
        },
        colorPrimary: {
          padding: '7px',
          borderRadius: '6px',
          height: '37px',
          width: '37px',
          backgroundColor: colors.BLACK,
          color: colors.WHITE,
          '& img': {
            height: '15px',
          },
        },
        sizeSmall: {
          height: 25,
          width: 25,
          '& img': {
            position: 'relative',
            right: '-1px',
            top: '-1px',
            height: 14.67,
            width: 14.67,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          overflow: 'hidden',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.LIGHTYELLOW,
        },
        info: {
          fontSize: '8px',
          color: colors.WHITE,
          backgroundColor: colors.BLUE,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          borderRadius: '3px',
          height: '12px',
          width: '36px',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Ubuntu',
          backgroundColor: 'white',
          height: '47px',
          fontSize: '0.9rem',
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              WebkitAppearance: 'none',
              margin: 0,
            },

          /* Firefox */
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
        sizeSmall: {
          height: '37px',
          fontSize: '14px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          '& label': { fontSize: '0.8rem', fontFamily: 'Ubuntu' },
          '& label.Mui-focused': {
            color: colors.BLACK,
            fontWeight: 'bold',
          },
          '&:is(.customtxt, .customtxt:hover) .Mui-disabled fieldset': {
            borderColor: colors.RED,
          },
          '&.customtxt label ': {
            lineHeight: '0.9em',
          },

          '& .MuiOutlinedInput-root': {
            height: '100%',
            '& fieldset': {
              borderColor: colors.PALAGREY,
            },
            '&:hover fieldset': {
              borderColor: colors.PALAGREY,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.PALAGREY,
            },
          },
          '& .MuiFormHelperText-root': {
            fontSize: '0.65rem',
            margin: 0,
          },
        },
        endIcon: {
          color: colors.ORANGE,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            height: '100%',
          },
          '& label': {
            top: 3,
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            opacity: 0,
          },
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          '&.Mui-selected': {
            backgroundColor: `${colors.BLACK} !important`,
            color: colors.WHITE,
          },
        },
      },
    },
    MuiPaginationItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          margin: '0',
          height: '35px',
          width: '45px',
          borderRadius: '0',
        },
      },
    },
    // MuiTabs: {
    //   styleOverrides: {
    //     root: {
    //       padding: '0',
    //       '.MuiTabs-indicator': {
    //         backgroundColor: colors.BLUE,
    //         height: '2.5px',
    //       },
    //     },
    //   },
    // },
    // MuiTab: {
    //   defaultProps: {
    //     disableRipple: true,
    //   },
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'transparent',
    //       borderBottom: `1px solid ${colors.PALAGREY}`,
    //       fontSize: '14px',
    //       padding: '0px 40px',
    //       textTransform: 'capitalize',
    //       fontWeight: 'bold',
    //       '&.Mui-selected': {
    //         color: colors.BLUE,
    //         fontWeight: 'bold',
    //       },
    //     },
    //   },
    // },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            width: '100%',
            '& fieldset': {
              borderColor: colors.PALAGREY,
            },
            '&:hover fieldset': {
              borderColor: colors.PALAGREY,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.PALAGREY,
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          boxShadows: 'none !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: `${colors.WHITE} !important`,
          '&:hover': {
            backgroundColor: `${colors.LIGHTYELLOW} !important`,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          position: 'relative',
          fontSize: '17px',
          '&.orange': {
            backgroundColor: colors.LIGHTYELLOW,
            border: `1px solid ${colors.ORANGE}`,
            color: colors.ORANGE,
          },
          '&.green': {
            backgroundColor: colors.LIGHTGREEN,
            border: `1px solid ${colors.GREEN}`,
            color: colors.GREEN,
          },
          '&.red': {
            backgroundColor: colors.LIGHTRED,
            border: `1px solid ${colors.RED}`,
            color: colors.RED,
          },
          '&.grey': {
            backgroundColor: colors.LIGHTGREY,
            border: `1px solid ${colors.PALAGREY}`,
            color: colors.PALAGREY,
          },
          '&.white': {
            backgroundColor: colors.WHITE,
            border: `1px solid ${colors.PALAGREY}`,
            color: colors.BLACK,
            overflow: 'visible',
            position: 'relative',
            fontWeight: 'bold',
            '& span': {
              zIndex: '2',
              position: 'absolute',
              height: '16px',
              width: '16px',
              backgroundColor: colors.BLACK,
              color: colors.WHITE,
              borderRadius: '100%',
              left: '22px',
              bottom: '-4px',
              fontSize: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
          '&.black': {
            fontSize: '12px',
            fontWeight: 'medium',
            position: 'absolute',
            bottom: '-0px',
            right: '-0px',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paperAnchorRight': {
            width: '1000px',
            backgroundColor: colors.LIGHTGREY,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.SPINDLE,
          height: '47px',
          display: 'flex',
          alignItems: 'center',
          color: colors.BLUE,
          fontSize: '1rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          borderRadius: '8px',
          '&:after': {
            border: 'none',
          },
          '&:before': {
            border: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.WHITE,
          padding: 10,
          borderRadius: 0,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: colors.DARKGREY,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontSize: '5px',
          backgroundColor: colors.PALAGREY,
          border: `1px solid ${colors.PALAGREY} `,
          '& .MuiTableRow-root': {
            '& .MuiTableCell-root': {
              padding: 6,
              '& .MuiTypography-root': {
                fontWeight: 'bold',
                fontSize: '0.9rem',
              },
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          /* position: 'relative', */
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: colors.WHITE,
          '& .MuiTableRow-root': {
            '& .MuiTableCell-root': {
              position: 'relative',
              backgroundClip: 'padding-box',
              border: `1px solid ${colors.PALAGREY}`,
              padding: 6,
            },
          },
          '& .Mui-selected': {
            backgroundColor: `${colors.LIGHTYELLOW}`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: `"Ubuntu", sans-serif`,
          borderBottom: `1px solid ${colors.PALAGREY}`,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderBottom: `none`,
        },
      },
      variants: [
        {
          props: {
            disablespacer: true,
          },
          style: {
            [`.${tablePaginationClasses.spacer}`]: {
              display: 'none',
            },
            [`.${inputBaseClasses.root}`]: {
              marginRight: 'auto',
            },
          },
        },
      ],
    },
    MuiTable: {
      variants: [
        {
          props: {
            variant: 'data-grid',
          },
          style: {
            borderCollapse: 'separate',
            borderSpacing: '0 4px',
            [`.${tableBodyClasses.root}`]: {
              backgroundColor: 'transparent',

              [`.${tableRowClasses.root}`]: {
                [`.${tableCellClasses.body}`]: {
                  border: 'none',
                  ':first-of-type': {
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                  },
                  ':last-of-type': {
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  },
                },
              },
              [`.${tableRowClasses.hover}:hover`]: {
                backgroundColor: colors.SPINDLE,
              },
            },
            [`.${tableHeadClasses.root}`]: {
              backgroundColor: 'transparent',
              border: 'none',
            },
            [`.${tableCellClasses.root}`]: {
              border: 'none',
            },
          },
        },
      ],
    },
  },
});

export default theme;
