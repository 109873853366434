import styled from '@emotion/styled';
import { Edit } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'use-http';
import { DataGrid, HeaderPage, PaginationBase } from 'components';
import { CardHomeKpi } from 'components/cards/CardHomeKpi/CardHomeKpi';
import { apiExports } from 'constants/api';
import { colors } from 'constants/colors';
import { USER_PATHS } from 'constants/paths';
import { SubscriptionStatusMap } from 'constants/subscription';
import {
  UserStatusMap,
  UserTypeMap,
  UserTypology,
  UserTypologyMap,
} from 'constants/users';
import { useGlobalSelector } from 'stores';
import { downloadFile } from 'utils/upload';
import { formatData, getGBfromBytes } from 'utils/utilities';
import { useUsersList } from '../Admin/useUsersList';

const StyledButton = styled(Button)({
  width: 36,
  height: 36,
  minWidth: 0,
});

const LambdaTypeAdmin = 11;

const Dashboard = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { get, response } = useFetch();
  const { get: exportLambdaUrl } = useFetch(
    process.env.REACT_APP_EXPORT_LAMBDA_URL
  );
  const [kpi, setKpi] = useState();

  const columns = [
    {
      name: 'id',
      label: 'ID',
      sortable: true,
    },
    {
      name: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      name: 'displayName',
      label: 'Nome cognome',
      sortable: true,
    },
    {
      name: 'type',
      label: 'Tipo utente',
      sortable: true,
      valueFormatter: type => UserTypeMap[type],
    },
    {
      name: 'tipologiaUtente',
      label: 'Tipologia utente',
      sortable: true,
      valueFormatter: type => UserTypologyMap[type],
    },
    {
      label: 'Stato',
      name: 'updatedRegistrationStatus',
      valueFormatter: status => UserStatusMap[status],
      sortable: true,
    },
    {
      name: 'businessName',
      label: 'Azienda/Ente',
      sortable: true,
    },
    {
      name: 'lastSubscriptionStatus',
      label: 'Sottoscrizione',
      sortable: true,
      renderCell: row => {
        return SubscriptionStatusMap[parseInt(row.lastSubscriptionStatus)];
      },
    },
    {
      name: 'subscriptionExpiration',
      label: 'Scad. Sottoscrizione',
      sortable: true,
      renderCell: row => {
        if (row.lastSubscription) {
          return `${formatData(row.lastSubscription.expirationDate) || ''}`;
        } else {
          return '';
        }
      },
    },
    {
      name: 'relatedStructuresNumber',
      label: 'Nr. Strutture',
      sortable: true,
      renderCell: row => {
        if (row.lastSubscription) {
          return row.lastSubscription.maxStructureNumber
            ? `${row.relatedStructuresNumber || '0'}/${
                row.lastSubscription.maxStructureNumber
              }`
            : '';
        } else {
          if (row.tipologiaUtente === UserTypology.UtentePrincipale) {
            return row.relatedStructuresNumber || '0';
          }
        }
      },
    },
    {
      name: 'usedSpace',
      label: 'Spazio utilizzato',
      sortable: true,
      renderCell: row => {
        if (row.lastSubscription) {
          return row.lastSubscription.maxAvailableSpace
            ? `${getGBfromBytes(row?.usedSpace) || '0'}/${
                row.lastSubscription.maxAvailableSpace || '0'
              }GB`
            : '';
        } else {
          if (row.tipologiaUtente === UserTypology.UtentePrincipale) {
            return row?.lastSubscription?.maxAvailableSpace
              ? `${row?.lastSubscription?.maxAvailableSpace}GB`
              : `${getGBfromBytes(row?.usedSpace)}GB`;
          }
        }
      },
    },
    {
      name: 'actions',
      renderCell: row => {
        return (
          <Stack>
            <StyledButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                /* navigate(ADMIN_PATHS.EDIT.replace(':userId', row.id)); */
                navigate(USER_PATHS.EDIT.replace(':userId', row.id));
              }}
            >
              <Edit />
            </StyledButton>
          </Stack>
        );
      },
    },
  ];
  //eslint-disable-next-line
  const [filters, setFilters] = useState({});

  const {
    error,
    loading,
    data,
    orderBy,
    direction,
    handleOrderChange,
    rowsPerPageOptions,
    rowsPerPage,
    page,
    handlePageChange,
    handleRowsPerPageChange,
  } = useUsersList(filters);

  const { users } = data;

  useEffect(() => {
    const handleKpi = async () => {
      try {
        showLoader();
        const resultKpi = await get(`kpi/home`);
        response.ok && setKpi(resultKpi);
      } catch (err) {
        console.error('Error in kpi: ', err);
      } finally {
        hideLoader();
      }
    };
    handleKpi().catch(console.error);
  }, []);

  const handleExportBridges = async () => {
    try {
      showLoader();
      const resultBridges = await exportLambdaUrl(
        `/admin?type=${LambdaTypeAdmin}`
      );
      downloadFile(resultBridges);
    } catch (err) {
      console.error('Error in kpi: ', err);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <HeaderPage
        title={'Dashboard'}
        buttons={
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleExportBridges}
          >
            Esporta Tutti i Ponti
          </Button>
        }
      />
      <Grid container spacing={2} marginTop={-7} marginBottom={5}>
        <Grid item xs={2.4}>
          <CardHomeKpi
            title="Ponti"
            icon="/icons/ponti.svg"
            number={kpi?.bridges}
            text="In piattaforma"
          />
        </Grid>
        <Grid item xs={2.4}>
          <CardHomeKpi
            title="Attività"
            icon="/icons/ispezioni.svg"
            number={kpi?.onGoingInspections}
            text="In corso"
          />
        </Grid>
        <Grid item xs={2.4}>
          <CardHomeKpi
            title="CdA"
            icon="/icons/ispezioni.svg"
            number={kpi?.bridgesWithCompleteCda}
            text="Calcolate"
          />
        </Grid>
        <Grid item xs={2.4}>
          <CardHomeKpi
            title="Enti"
            icon="/icons/enti.svg"
            number={kpi?.activeInstitutions}
            text="Attivi"
          />
        </Grid>
        <Grid item xs={2.4}>
          <CardHomeKpi
            title="Ispettori"
            icon="/icons/collaboratori.svg"
            number={kpi?.activeProfessionals}
            text="Attivi"
          />
        </Grid>
      </Grid>
      <Typography variant={'h3Bold'}>
        Ultimi Enti e Ispettori registrati
      </Typography>

      {!error && !loading && (
        <DataGrid
          rowColor={colors.WHITE}
          columns={columns}
          rows={users?.items}
          orderBy={orderBy}
          direction={direction}
          onSort={handleOrderChange}
        />
      )}
      {!error && !loading && (
        <PaginationBase
          div
          count={users?.count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </>
  );
};

export default Dashboard;
