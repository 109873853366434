import { WebL0Fields } from 'constants/inspectionFields';
import { Accordion1CaratteristicheGeometriche } from '../Accordion1CaratteristicheGeometriche/Accordion1CaratteristicheGeometriche';
import { Accordion1StructuralFeatures } from '../Accordion1StructuralFeatures/Accordion1StructuralFeatures';

import { Accordion2SchemiGeometrici } from '../Accordion2SchemiGeometrici/Accordion2SchemiGeometrici';
import { Accordion3Elevazione } from '../Accordion3Elevazione/Accordion3Elevazione';
import { Accordion4Connections } from '../Accordion4Connections/Accordion4Connections';
import { Accordion5Impalcato } from '../Accordion5Impalcato/Accordion5Impalcato';
import { Accordion6StrutturaCommentiPerReport } from '../Accordion6StrutturaCommentiPerReport/Accordion6StrutturaCommentiPerReport';

export const AccordionDataStructureL0 = [
  {
    id: '1',
    title: '1. Caratteristiche geometriche',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion1CaratteristicheGeometriche />,
    indicatorFields: WebL0Fields.Struttura.carattteristicheGeometriche,
  },
  {
    id: '2',
    title: '2. Caratteristiche strutturali',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion1StructuralFeatures />,
    indicatorFields: WebL0Fields.Struttura.caratteristicheStrutturali,
  },
  {
    id: '3',
    title: '3. Schemi geometrici',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion2SchemiGeometrici />,
    indicatorFields: WebL0Fields.Struttura.schemiGeometrici,
  },
  {
    id: '4',
    title: '4. Elevazione',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion3Elevazione />,
    indicatorFields: WebL0Fields.Struttura.elevazione,
  },
  {
    id: '5',
    title: '5. Collegamenti',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion4Connections />,
    indicatorFields: WebL0Fields.Struttura.collegamenti,
  },
  {
    id: '6',
    title: '6. Impalcato',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion5Impalcato />,
    indicatorFields: WebL0Fields.Struttura.impalcato,
  },
  {
    id: '7',
    title: '7. Commenti per report',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion6StrutturaCommentiPerReport />,
    /* indicatorFields: WebL0Fields.Struttura.commentiReport, */
  },
];
