import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaSpartitrafficoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';

const SpartitrafficoForm = ({
  spartitrafficoFields,
  selected,
  setSelected,
  spartitrafficoTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      spartitrafficoTableValue: [],
      tipologiaSpartitrafficoTableValue: '',
    },
  });

  useEffect(() => {
    setValue('spartitrafficoTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetSpartitraffico();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitSpartitraffico = () => {
    const tipologiaSpartitrafficoTableValue = getValues(
      'tipologiaSpartitrafficoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const spartitraffico = [...spartitrafficoFields];

    spartitraffico.map((x, index) => {
      if (ids.includes(x?.codice)) {
        spartitraffico[index] = {
          ...x,
          tipologiaSpartitraffico: tipologiaSpartitrafficoTableValue,
        };
      }
    });

    setValue(spartitrafficoTable, spartitraffico);
    onResetSpartitraffico();
  };

  const onResetSpartitraffico = () => {
    setValue('spartitrafficoTableValue', []);
    setValue('tipologiaSpartitrafficoTableValue', '');
    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper
        size={3}
        infoMessage={INFO.spartitrafficoSelezionati.infoMessage}
      >
        <RHFTagAutocomplete
          multiple={true}
          id="tags-spartitraffico"
          name={'spartitrafficoTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={spartitrafficoFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Spartitraffico selezionati"
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="tipologiaSpartitrafficoTableValue"
          label="Tipologia Spartitraffico*"
          defaultValue={''}
          onChange={e => {
            setValue('tipologiaSpartitrafficoTableValue', e.target.value);
          }}
          selectOptions={tipologiaSpartitrafficoOptions}
        />
      </GridWrap>
      <GridWrap item xs={6} alignSelf="center">
        <FormSubmitButtons
          onSubmit={onSubmitSpartitraffico}
          onReset={onResetSpartitraffico}
        />
      </GridWrap>
    </GridWrap>
  );
};
SpartitrafficoForm.propTypes = {
  spartitrafficoFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaSpartitraffico: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  spartitrafficoTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default SpartitrafficoForm;
