import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, InfoWrapper, Table } from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import {
  appoggiAntisismiciAppoggiOption,
  tipologiaAppoggioOptions,
} from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import AppoggiForm from './AppoggiForm';
import { GridWrap } from './AppoggiForm.styles';

const AppoggiSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const spallePileCollegamentiTableAppoggiTableWatch = watch(
    `spallePileCollegamentiTable[${selectedIdx}].appoggiTable`
  );

  // table setup
  const dataConfigAppoggi = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaAppoggio: {
        label: 'Tipologia',
        format: tipologiaAppoggioOptions,
      },
      appoggiAntisismici: {
        label: 'Appoggi antisismici',
        format: appoggiAntisismiciAppoggiOption,
      },
    },
  };

  // appoggi setup
  const [selectedAppoggi, setSelectedAppoggi] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsAppoggi = item => {
    setValue('l0t2a4_appoggiTableValue', [item]);
    setValue('l0t2a4_tipologiaAppoggioTableValue', item.tipologiaAppoggio);
    setValue('l0t2a4_appoggiAntisismiciTableValue', item.appoggiAntisismici);
  };
  const handleSelectAllClickAppoggi = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `spallePileCollegamentiTable[${selectedIdx}].appoggiTable`
      );
      setSelectedAppoggi(selectedValue);
      setValue('l0t2a4_appoggiTableValue', selectedValue);
    } else {
      setSelectedAppoggi([]);
      setValue('l0t2a4_appoggiTableValue', []);
    }
  };

  useEffect(() => {
    setSelectedAppoggi([]);
  }, [selectedIdx]);

  return (
    <>
      <GridWrap container spacing={2} style={{ marginTop: 16 }}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.collegamentiAppoggi.drawerTitle}
          drawerText={INFO.collegamentiAppoggi.drawerText}
        >
          <Divider>APPOGGI</Divider>
        </InfoWrapper>
        {spallePileCollegamentiTableAppoggiTableWatch?.length === 0 && (
          <GridWrap item xs={12} paddingTop={'0px !important'}>
            <Typography>Nessun appoggio presente in questo elemento</Typography>
          </GridWrap>
        )}
      </GridWrap>
      {spallePileCollegamentiTableAppoggiTableWatch?.length > 0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <AppoggiForm
              appoggiFields={spallePileCollegamentiTableAppoggiTableWatch || []}
              selected={selectedAppoggi}
              setSelected={setSelectedAppoggi}
              appoggiTable={`spallePileCollegamentiTable[${selectedIdx}].appoggiTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Appoggi'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Struttura]}
          />

          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={spallePileCollegamentiTableAppoggiTableWatch || []}
                config={dataConfigAppoggi}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsAppoggi(item);
                            setSelectedAppoggi([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        spallePileCollegamentiTableAppoggiTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedAppoggi}
                onSelectAllClick={handleSelectAllClickAppoggi}
                setSelected={setSelectedAppoggi}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
AppoggiSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default AppoggiSection;
