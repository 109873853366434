import {
  styled,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import { colors } from 'constants/colors';

const StyledHeader = styled(TableCell)(({ isAdmin }) => ({
  backgroundColor: isAdmin && colors.LIGHTGREY,
}));

export const DataGridHeader = ({
  columns,
  orderBy,
  direction,
  onSort,
  isAdmin,
}) => {
  const cells = columns.map(({ label, name, sortable }) => {
    if (sortable) {
      return (
        <SortableTableCell
          key={name}
          active={name === orderBy}
          direction={direction}
          onClick={() => onSort(name)}
        >
          {label}
        </SortableTableCell>
      );
    }

    return (
      <StyledHeader isAdmin={isAdmin} key={name}>
        {label}
      </StyledHeader>
    );
  });

  return (
    <TableHead>
      <TableRow>{cells}</TableRow>
    </TableHead>
  );
};

DataGridHeader.propTypes = {
  columns: PropTypes.array,
  orderBy: PropTypes.string,
  direction: PropTypes.oneOf(['asc', 'desc']),
  onSort: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

// eslint-disable-next-line react/prop-types
const SortableTableCell = ({ children, active, direction, onClick }) => {
  return (
    <TableCell variant="head" sortDirection={direction || false}>
      <TableSortLabel active={active} direction={direction} onClick={onClick}>
        {children}
      </TableSortLabel>
    </TableCell>
  );
};
