import { TableCell, TableRow, TableSortLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'components';
import { CustomHead } from './TableHeader.styles';

function RenderHeader({ value }) {
  return <Typography typevariant="h3Bold">{value}</Typography>;
}

RenderHeader.propTypes = {
  value: PropTypes.string,
};

// Header Handler
export const TableHeader = ({
  order,
  orderBy,
  onRequestSort,
  columns,
  hasActions,
  onSelectAllClick,
  numSelected,
  rowCount,
  hasCheckbox,
  typevariant,
  hasMedia,
  hasSchedaDifettiActions,
  hasCompatibility,
  preview,
  previewDocuments,
}) => {
  const createSortHandler = key => event => {
    if (columns[key].id) {
      onRequestSort(event, columns[key].id);
    }
  };
  return (
    <CustomHead
      data-value="tableHead"
      typevariant={typevariant}
      isMedia={'media'}
    >
      <TableRow data-value="tableRow" typevariant={typevariant}>
        {hasCheckbox && (
          <TableCell padding="checkbox" typevariant={typevariant}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {(preview || previewDocuments) && <TableCell padding="normal" />}
        {Object.keys(columns).map(key => {
          return (
            <TableCell
              align="left"
              typevariant={typevariant}
              key={columns[key].id || key}
              sortDirection={orderBy === columns[key].id ? order : false}
              data-value="tableCell"
            >
              {columns[key].id ? (
                <TableSortLabel
                  active={orderBy === columns[key].id}
                  direction={order}
                  onClick={createSortHandler(key)}
                >
                  <RenderHeader
                    value={columns[key].label}
                    maxWidthCol={columns[key].maxWidth}
                  />
                </TableSortLabel>
              ) : (
                <RenderHeader
                  value={columns[key].label}
                  maxWidthCol={columns[key].maxWidth}
                />
              )}
            </TableCell>
          );
        })}
        {hasMedia && (
          <TableCell align="left" typevariant={typevariant} key="media">
            <TableSortLabel>
              <RenderHeader value={'Media'} />
            </TableSortLabel>
          </TableCell>
        )}
        {hasSchedaDifettiActions && (
          <TableCell align="left" typevariant={typevariant} key="shcedaDifetti">
            <TableSortLabel>
              <RenderHeader value={'Valutazione Difetti'} />
            </TableSortLabel>
          </TableCell>
        )}
        {hasCompatibility && (
          <TableCell align="left" typevariant={typevariant} key="copmatibility">
            <TableSortLabel>
              <RenderHeader value={'Compatibilità'} />
            </TableSortLabel>
          </TableCell>
        )}
        {hasActions && <TableCell align="left" typevariant={typevariant} />}
      </TableRow>
    </CustomHead>
  );
};

TableHeader.propTypes = {
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columns: PropTypes.object,
  hasActions: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  typevariant: PropTypes.string,
  hasMedia: PropTypes.bool,
  hasSchedaDifettiActions: PropTypes.bool,
  hasCompatibility: PropTypes.bool,
  preview: PropTypes.bool,
  previewDocuments: PropTypes.bool,
};

TableHeader.defaultProps = {
  hasActions: false,
  hasCheckbox: false,
  typevariant: 'primary',
};
