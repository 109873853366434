import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

function useCopy({ tableName, type }) {
  const { getValues, setValue } = useFormContext();

  const [copyDrawerOpen, setCopyDrawerOpen] = useState(false);
  const [selectedPaste, setSelectedPaste] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSelectAllPaste = event => {
    if (event.target.checked) {
      const selectedValue = getValues(tableName).filter(el =>
        checkCompatibility(el)
      );
      setSelectedPaste(selectedValue);
    } else {
      setSelectedPaste([]);
    }
  };

  const checkCompatibility = row => {
    return row.codice != selectedCopy.codice && row[type] == selectedCopy[type];
  };

  const handleCopy = () => {
    getValues(tableName).forEach((x, idx) => {
      if (selectedPaste.some(p => p.codice == x.codice)) {
        const tmp = {
          /* ...x, */
          /* tecnicoRilevatore: selectedCopy.tecnicoRilevatore,
          dataIspezione: selectedCopy.dataIspezione,
          elementoCriticoStruttura: selectedCopy.elementoCriticoStruttura,
          elementoCriticoSismica: selectedCopy.elementoCriticoSismica,
          condizioneCriticaStruttura: selectedCopy.condizioneCriticaStruttura,
          condizioneCriticaSismica: selectedCopy.condizioneCriticaSismica,
          noteGenerali: selectedCopy.noteGenerali, */
          ...x,
          Nrel321: selectedCopy.Nrel321 || undefined,
          g5totali:
            selectedCopy.g5totali === 0
              ? 0
              : selectedCopy.g5totali > 0
              ? selectedCopy.g5totali
              : undefined,
          nrtotali:
            selectedCopy.nrtotali === 0
              ? 0
              : selectedCopy.nrtotali > 0
              ? selectedCopy.nrtotali
              : undefined,
          pstotali:
            selectedCopy.pstotali === 0
              ? 0
              : selectedCopy.pstotali > 0
              ? selectedCopy.pstotali
              : undefined,
          CDAsismica: selectedCopy.CDAsismica || undefined,
          summationG: selectedCopy.summationG || undefined,
          noteGenerali: selectedCopy.noteGenerali || undefined,
          dataIspezione: selectedCopy.dataIspezione || undefined,
          summationG123: selectedCopy.summationG123 || undefined,
          CDAstrutturale: selectedCopy.CDAstrutturale || undefined,
          summationk2G45: selectedCopy.summationk2G45 || undefined,
          summationk2G123: selectedCopy.summationk2G123 || undefined,
          indiceCondizione: selectedCopy.indiceCondizione || undefined,
          summationk2G12345: selectedCopy.summationk2G12345 || undefined,
          tecnicoRilevatore: selectedCopy.tecnicoRilevatore || undefined,
          CDAsismicacomputed: selectedCopy.CDAsismicacomputed || undefined,
          CDAstrutturalecomputed:
            selectedCopy.CDAstrutturalecomputed || undefined,
          elementoCriticoSismica:
            selectedCopy.elementoCriticoSismica || undefined,
          condizioneCriticaSismica:
            selectedCopy.condizioneCriticaSismica || undefined,
          elementoCriticoStruttura:
            selectedCopy.elementoCriticoStruttura || undefined,
          completamentoSchedaDifetti:
            selectedCopy.completamentoSchedaDifetti || undefined,
          condizioneCriticaStruttura:
            selectedCopy.condizioneCriticaStruttura || undefined,
          id: x.id,
          codice: x.codice,
          schedaDifetti: selectedCopy.schedaDifetti.map(el => ({
            ...el,
            media: [],
          })),
        };
        setValue(`${tableName}[${idx}]`, tmp);
      }
    });
    setCopyDrawerOpen(false);
    return enqueueSnackbar('Elemento copiato correttamente', {
      variant: 'success',
    });
  };

  return {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedPaste,
    setSelectedPaste,
    selectedCopy,
    setSelectedCopy,
    handleSelectAllPaste,
    checkCompatibility,
    handleCopy,
  };
}
export default useCopy;
