import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  InfoWrapper,
  MediaUpload,
  RHFCheckbox,
  RHFMultiSelect,
  RHFNumberTextField,
  RHFSelect,
  RHFTextField,
} from 'components';
import {
  AbbassamentoGeneralizzatoAlveo,
  AccumuliDetritiAMontePila,
  AreeGolenaliAssenti,
  BacinoIdrograficoPresuntoRischioIdraulico,
  BacinoIdrograficoRischioIdraulico,
  BrigliaProtezioneValleDelPonte,
  DeposizioneSedimenti,
  DivagazioneAlveoRischioIdraulico,
  EffettivoPresunto,
  // EvidenzeProfonditaDelleFondazioni,
  FenomenoErosioneRischioIdraulico,
  FieldType,
  MaterialeVegetaleRischioIdraulico,
  MediaGroup,
  MisureLaminazioneMitigazione,
  MorfologiaAlveo,
  OpereArginaturaDiAlterazionePortate,
  OpereProtezioneSpondale,
  ProtezioneAlPiedePileSpalle,
  RilevabileNonRilevabile,
  RischioIdraulico,
  ScenariPericolositaRischioIdraulico,
  sistemaMonitoraggioRischioIdraulico,
  StrutturaAlveoRischioIdraulico,
  TipologiaCorsoAcquaRischioIdraulico,
  TipologiaReticolo,
  UploadMedia,
} from 'constants/inspections';
import {
  AbbassamentoAccentuatoAlleFondazioniOptions,
  AbbassamentoGeneralizzatoAlveoOptions,
  AccumuliDetritiAMontePilaOptions,
  AccumuloDetritiRischioIdraulicoOptions,
  AreaPericolosaRischioIdraulicoOptions,
  AreeGolenaliAssentiOptions,
  BacinoIdrograficoPresuntoRischioIdraulicoOptions,
  BacinoIdrograficoRischioIdraulicoOptions,
  BrigliaProtezioneValleDelPonteOptions,
  ConfinamentoAlveoOptions,
  CurvaturaAlveoRischioIdraulicoOptions,
  DeposizioneSedimentiOptions,
  DivagazioneAlveoRischioIdraulicoOptions,
  EvidenzeProfonditaDelleFondazioniOptions,
  EvoluzioneAlveoRischioIdraulicoOptions,
  FenomenoErosioneRischioIdraulicoOptions,
  FenomenoSormontoRischioIdraulicoOptions,
  FrancoIdraulicoSuperioreOptions,
  MaterialeAlveoRischioIdraulicoOptions,
  MaterialeVegetaleRischioIdraulicoOptions,
  MisureLaminazioneMitigazioneOptions,
  MorfologiaAlveoOptions,
  OpereArginaturaDiAlterazionePortateOptions,
  OpereProtezioneSpondaleOptions,
  PresenzaRilieviRischioIdraulicoOptions,
  PresenzaStudioIdraulicoLivelliDefinitiOptions,
  ProtezioneAlPiedePileSpalleOptions,
  RicadenteAreaAlluvioniRischioIdraulicoOptions,
  RischioIdraulicoOptions,
  ScenariPericolositaRischioIdraulicoOptions,
  SinuositaOptions,
  sistemaMonitoraggioRischioIdraulicoOptions,
  StatoConservazioneAdeguatezzaOperaBrigliaOptions,
  StatoConservazioneAdeguatezzaOperaOptions,
  StatoConservazioneAdeguatezzaOpereArginaturaOptions,
  StatoConservazioneAdeguatezzaOpereLaminazioneOptions,
  StatoConservazioneProtezionePiedeOptions,
  statoConservazioneSistemaMonitoraggioRischioIdraulicoOptions,
  StrutturaAlveoRischioIdraulicoOptions,
  TipologiaCorsoAcquaRischioIdraulicoOptions,
  TipologiaFondoAlveoRischioIdraulicoOptions,
  TipologiaReticoloOptions,
} from 'constants/selectOptions';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import {
  CheckboxContainer,
  GridWrap,
  TitleSection,
} from './Accordion4HydraulicRiskL1.styles';
import { INFO } from '../../../../config/info';

const Accordion4HydraulicRiskL1 = () => {
  const { getValues, setValue, watch, control } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  // CONTROLLO DATI GENERALI
  const watchRischioIdraulico = watch('rischioIdraulico');
  const watchRischioIdraulicoPresente =
    watchRischioIdraulico === RischioIdraulico.Presente;
  const watchRischioIdraulicoDaVerificare =
    watchRischioIdraulico === RischioIdraulico.DaVerificare;
  const rischioIdraulicoPresenteDaVerificare =
    watchRischioIdraulicoPresente || watchRischioIdraulicoDaVerificare;
  const watchRischioIdraulicoAssente =
    watchRischioIdraulico === RischioIdraulico.Assente;

  const watchStrutturaAlveoRischioIdraulico = watch(
    'strutturaAlveoRischioIdraulico'
  );
  const watchStrutturaAlveoRischioIdraulicoNessuna =
    watchStrutturaAlveoRischioIdraulico !== ''
      ? watchStrutturaAlveoRischioIdraulico?.some(
          el => el === StrutturaAlveoRischioIdraulico.Nessuna
        )
      : false;

  const watchStrutturaAlveoRischioIdraulicoPile =
    watchStrutturaAlveoRischioIdraulico !== ''
      ? watchStrutturaAlveoRischioIdraulico?.some(
          el => el === StrutturaAlveoRischioIdraulico.Pile
        )
      : false;

  const { remove: removeSelectedStrutturaAlveo } = useFieldArray({
    control,
    name: 'strutturaAlveoRischioIdraulico',
  });

  useEffect(() => {
    if (rischioIdraulicoPresenteDaVerificare) {
      if (
        watchStrutturaAlveoRischioIdraulico?.length &&
        watchStrutturaAlveoRischioIdraulico.includes(4)
      ) {
        if (watchStrutturaAlveoRischioIdraulico?.length > 1) {
          watchStrutturaAlveoRischioIdraulico.sort();
          removeSelectedStrutturaAlveo(0);
        }
      }
    }

    if (
      !rischioIdraulicoPresenteDaVerificare &&
      watchStrutturaAlveoRischioIdraulico?.length
    ) {
      removeSelectedStrutturaAlveo();
    }
  }, [watchRischioIdraulico, watchStrutturaAlveoRischioIdraulico]);

  //Functions
  useEffect(() => {
    if (watchStrutturaAlveoRischioIdraulicoNessuna) {
      setValue('protezioneAlPiedePileSpalle', '');
      setValue('statoConservazioneProtezionePiede', '');
      setValue('brigliaProtezioneValleDelPonte', '');
      setValue('statoConservazioneAdeguatezzaOperaBriglia', '');
    }
  }, [watchStrutturaAlveoRischioIdraulico]);

  //CONTROLLO TIPO I SORMONTO O INSUFFICIENZA DI FRANCO
  const watchScenariPericolositaRischioIdraulico = watch(
    'scenariPericolositaRischioIdraulico'
  );
  const scenarioP2 =
    watchScenariPericolositaRischioIdraulico !== ''
      ? watchScenariPericolositaRischioIdraulico?.some(
          el => el === ScenariPericolositaRischioIdraulico.P2
        )
      : false;

  const scenarioP3 =
    watchScenariPericolositaRischioIdraulico !== ''
      ? watchScenariPericolositaRischioIdraulico?.some(
          el => el === ScenariPericolositaRischioIdraulico.P3
        )
      : false;

  const scenarioPericolositaNessuno =
    watchScenariPericolositaRischioIdraulico !== ''
      ? watchScenariPericolositaRischioIdraulico?.some(
          el => el === ScenariPericolositaRischioIdraulico.Nessuno
        )
      : false;

  const { remove: removeScenarioPericoloso } = useFieldArray({
    control,
    name: 'scenariPericolositaRischioIdraulico',
  });

  useEffect(() => {
    if (scenarioP2) {
      setValue('quotaArgineMinRischioIdraulico', '');
      setValue('quotaSpondaMaxRischioIdraulico', '');
      setValue('quotaFondoAlveoRischioIdraulico', '');
      setValue('quotaPeloLiberoStimaRischioIdraulico', '');
    }

    if ((!scenarioP3 && scenarioP2) || (!scenarioP3 && !scenarioP2)) {
      setValue('quotaPeloLiberoP3RischioIdraulico', '');
      setValue('francoIdraulicoFP3', '');
    }

    if ((!scenarioP2 && scenarioP3) || (!scenarioP2 && !scenarioP3)) {
      setValue('quotaPeloLiberoP2RischioIdraulico', '');
      setValue('francoIdraulicoFP2', '');
    }
  }, [watchScenariPericolositaRischioIdraulico]);

  useEffect(() => {
    if (rischioIdraulicoPresenteDaVerificare) {
      if (
        watchScenariPericolositaRischioIdraulico?.length &&
        watchScenariPericolositaRischioIdraulico.includes(3)
      ) {
        if (watchScenariPericolositaRischioIdraulico?.length > 1) {
          watchScenariPericolositaRischioIdraulico.sort();
          removeScenarioPericoloso(0);
        }
      }
    }

    if (
      !rischioIdraulicoPresenteDaVerificare &&
      watchScenariPericolositaRischioIdraulico?.length
    ) {
      removeScenarioPericoloso();
    }
  }, [watchScenariPericolositaRischioIdraulico, watchRischioIdraulico]);

  const watchTipologiaCorsoAcqua = watch('tipologiaCorsoAcquaRischioIdraulico');
  const tipologiaCasoI =
    watchTipologiaCorsoAcqua === TipologiaCorsoAcquaRischioIdraulico.CasoI;
  const tipologiaCasoII =
    watchTipologiaCorsoAcqua === TipologiaCorsoAcquaRischioIdraulico.CasoII;
  const tipologiaCasoIII =
    watchTipologiaCorsoAcqua === TipologiaCorsoAcquaRischioIdraulico.CasoIII;

  const watchQuotaMinimaIntradosso = watch(
    'quotaMinimaIntradossoRischioIdraulico'
  );
  const watchQuotaPeloScenarioP2 = watch('quotaPeloLiberoP2RischioIdraulico');
  const watchQuotaPeloScenarioP3 = watch('quotaPeloLiberoP3RischioIdraulico');
  const watchQuotaPeloLiberoStimata = watch(
    'quotaPeloLiberoStimaRischioIdraulico'
  );

  const watchLarghezzaAlveoPileSpalle = watch('larghezzaAlveoPileSpalle');
  const watchLarghezzaAlveoPonte = watch('larghezzaAlveoPonte');

  const watchFenomenoErosioneRischioIDraulico = watch(
    'fenomenoErosioneRischioIdraulico'
  );
  const watchFenomenoErosioneAccertati =
    watchFenomenoErosioneRischioIDraulico ===
    FenomenoErosioneRischioIdraulico.Accertati;
  const watchFenomenoErosioneIpotizzati =
    watchFenomenoErosioneRischioIDraulico ===
    FenomenoErosioneRischioIdraulico.Ipotizzati;
  const fenomenoErosioneAccertatiIpotizzati =
    watchFenomenoErosioneAccertati || watchFenomenoErosioneIpotizzati;
  const watchFenomenoErosioneAssenti =
    watchFenomenoErosioneRischioIDraulico ===
    FenomenoErosioneRischioIdraulico.Assenti;

  //Functions
  useEffect(() => {
    const getValQuotaMinima = Number(watchQuotaMinimaIntradosso);
    const getValPeloLiberoStima = Number(watchQuotaPeloLiberoStimata);

    /* (getValQuotaMinima >= 0 && getValPeloLiberoStima >= 0) */
    /**
     * getValQuotaMinima = 0
     * getValPeloLiberoStima = 0
     */
    if (
      (getValQuotaMinima || getValQuotaMinima === 0) &&
      (getValPeloLiberoStima || getValPeloLiberoStima === 0)
    ) {
      const result = getValQuotaMinima - getValPeloLiberoStima;
      if (result === 0) {
        setValue('francoIdraulicoStimato', '0');
      } else {
        setValue('francoIdraulicoStimato', result);
      }
    }

    if (!watchQuotaMinimaIntradosso || !watchQuotaPeloLiberoStimata) {
      setValue('francoIdraulicoStimato', '');
    }
  }, [watchQuotaMinimaIntradosso, watchQuotaPeloLiberoStimata]);

  useEffect(() => {
    const getValQuotaMinima = Number(watchQuotaMinimaIntradosso);
    const getValQuotaPeloP2 = Number(watchQuotaPeloScenarioP2);

    if (
      (getValQuotaMinima || getValQuotaMinima === 0) &&
      (getValQuotaPeloP2 || getValQuotaPeloP2 === 0)
    ) {
      const result = getValQuotaMinima - getValQuotaPeloP2;
      if (result === 0) {
        setValue('francoIdraulicoFP2', '0');
      } else {
        setValue('francoIdraulicoFP2', result);
      }
      /* setValue('francoIdraulicoFP2', getValQuotaMinima - getValQuotaPeloP2); */
    }
    if (!watchQuotaMinimaIntradosso || !watchQuotaPeloScenarioP2) {
      setValue('francoIdraulicoFP2', '');
    }
  }, [watchQuotaMinimaIntradosso, watchQuotaPeloScenarioP2]);

  useEffect(() => {
    const getValQuotaMinima = Number(watchQuotaMinimaIntradosso);
    const getValQuotaPeloP3 = Number(watchQuotaPeloScenarioP3);

    if (
      (getValQuotaMinima || (getValQuotaMinima && getValQuotaMinima === 0)) &&
      (getValQuotaPeloP3 || (getValQuotaPeloP3 && getValQuotaPeloP3 === 0))
    ) {
      const result = getValQuotaMinima - getValQuotaPeloP3;
      if (result === 0) {
        setValue('francoIdraulicoFP3', '0');
      } else {
        setValue('francoIdraulicoFP3', result);
      }
    }

    if (!watchQuotaMinimaIntradosso || !watchQuotaPeloScenarioP3) {
      setValue('francoIdraulicoFP3', '');
    }
  }, [watchQuotaMinimaIntradosso, watchQuotaPeloScenarioP3]);

  // CONTROLLO EROSIONE
  // const watchEvidenzeProfonditaDelleFondazioni = watch(
  //   'evidenzeProfonditaDelleFondazioni'
  // );
  // const watchevidenzeProfonditaDelleFondazioniNessunaEvidenza =
  //   watchEvidenzeProfonditaDelleFondazioni ===
  //   EvidenzeProfonditaDelleFondazioni.NessunaEvidenza;

  // SBIANCAMENTO CAMPI SEZIONE EROSIONE
  useEffect(() => {
    // if (!watchFenomenoErosioneAccertati || !watchFenomenoErosioneIpotizzati) {
    if (watchFenomenoErosioneAssenti) {
      setValue('larghezzaAlveoPileSpalle', '');
      setValue('larghezzaAlveoPileSpalleRilevabile', false);
      setValue('larghezzaAlveoPileSpalleNonRilevabile', false);
      setValue('larghezzaAlveoPonte', '');
      setValue('fattoreRestringimentoAlveo', '');
      setValue('areeGolenaliAssenti', '');
      setValue('larghezzaComplessivaGolene', '');
      setValue('larghezzaComplessivaGolenePonte', '');
      setValue('larghezzaGoleneRilevabile', false);
      setValue('larghezzaGoleneNonRilevabile', false);
      setValue('fattoreRestringimentoAreeGolenali', '');
      setValue('larghezzaPilaOAggettoSpalla', '');
      setValue('larghezzaPilaOAggettoSpallaEffettiva', false);
      setValue('larghezzaPilaOAggettoSpallaPresunta', false);
      setValue('profonditaScavoMax', '');
      setValue('profonditaFondazioneRispettoAlveo', '');
      setValue('profonditaFondazioneRispettoAlveoEffettiva', false);
      setValue('profonditaFondazioneRispettoAlveoPresunta', false);
      setValue('fattoreIEL', '');
      setValue('evidenzeProfonditaDelleFondazioni', '');
      setValue('abbassamentoGeneralizzatoAlveo', '');
      setValue('evoluzioneEventualeDelFondo', '');
      setValue('abbassamentoAccentuatoAlleFondazioni', '');
      setValue('accumuliDetritiAMontePila', '');
    }
  }, [watchFenomenoErosioneRischioIDraulico]);

  // CALCOLI CAMPI EROSIONE
  const watchLarghezzaComplessivaGolene = watch('larghezzaComplessivaGolene');
  const watchLarghezzaComplessivaGolenePonte = watch(
    'larghezzaComplessivaGolenePonte'
  );

  const watchProfonditaScavoMax = watch('profonditaScavoMax');
  const watchProfonditaFondazioneRispettoAlveo = watch(
    'profonditaFondazioneRispettoAlveo'
  );

  const watchAbbassamentoGeneralizzatoAlveo = watch(
    'abbassamentoGeneralizzatoAlveo'
  );

  const watchAreeGolenaliAssenti = watch('areeGolenaliAssenti');
  const watchAreeGolenaliAssentiSi =
    watchAreeGolenaliAssenti === AreeGolenaliAssenti.Si;

  const watchAbbassamentoGeneralizzatoAlveoSi =
    watchAbbassamentoGeneralizzatoAlveo === AbbassamentoGeneralizzatoAlveo.Si;

  const watchAccumuliDetritiAMontePila = watch('accumuliDetritiAMontePila');
  const watchAccumuliDetritiAMontePilaSi =
    watchAccumuliDetritiAMontePila === AccumuliDetritiAMontePila.Si;

  //SETVALUE FATTORE
  useEffect(() => {
    if (watchProfonditaScavoMax && watchProfonditaFondazioneRispettoAlveo) {
      if (
        parseFloat(watchProfonditaScavoMax) >= 0 &&
        parseFloat(watchProfonditaFondazioneRispettoAlveo) > 0
      ) {
        setValue(
          'fattoreIEL',
          watchProfonditaScavoMax / watchProfonditaFondazioneRispettoAlveo
        );
      } else if (
        parseFloat(watchProfonditaScavoMax) === 0 ||
        parseFloat(watchProfonditaFondazioneRispettoAlveo) === 0
      ) {
        setValue('fattoreIEL', '0');
      } else {
        setValue('fattoreIEL', '');
      }
    } else {
      setValue('fattoreIEL', '');
    }
  }, [watchProfonditaScavoMax, watchProfonditaFondazioneRispettoAlveo]);

  //SETVALUE FATTORE FATTORE RESTRINGIMENTO ALVEO
  useEffect(() => {
    if (watchLarghezzaAlveoPileSpalle && watchLarghezzaAlveoPonte) {
      setValue(
        'fattoreRestringimentoAlveo',
        parseInt(
          Math.round(
            (parseFloat(watchLarghezzaAlveoPileSpalle) /
              parseFloat(watchLarghezzaAlveoPonte)) *
              100
          )
        )
      );
    }
    if (
      watchLarghezzaAlveoPileSpalle === '0.00' ||
      watchLarghezzaAlveoPonte === '0.00'
    ) {
      setValue('fattoreRestringimentoAlveo', '0');
    }
    if (
      isNullOrUndefinedOrEmptyString(watchLarghezzaAlveoPileSpalle) ||
      isNullOrUndefinedOrEmptyString(watchLarghezzaAlveoPonte)
    ) {
      setValue('fattoreRestringimentoAlveo', '');
    }
  }, [watchLarghezzaAlveoPileSpalle, watchLarghezzaAlveoPonte]);

  //SETVALUE FATTORE RESTRINGIMENTO AREE GOLENALI
  useEffect(() => {
    if (watchAreeGolenaliAssentiSi) {
      setValue('larghezzaComplessivaGolene', '');
      setValue('larghezzaComplessivaGolenePonte', '');

      setValue('fattoreRestringimentoAreeGolenali', '0');
    } else {
      setValue('fattoreRestringimentoAreeGolenali', '');
    }
    // watchAreeGolenaliAssentiSi
    //   ? setValue('fattoreRestringimentoAreeGolenali', '0')
    //   : setValue('fattoreRestringimentoAreeGolenali', '');
    if (
      watchLarghezzaComplessivaGolene &&
      watchLarghezzaComplessivaGolenePonte
    ) {
      if (watchAreeGolenaliAssentiSi) {
        setValue('fattoreRestringimentoAreeGolenali', '0');
      } else {
        setValue(
          'fattoreRestringimentoAreeGolenali',
          (watchLarghezzaComplessivaGolene /
            watchLarghezzaComplessivaGolenePonte) *
            100
        );
      }
    }
  }, [
    watchLarghezzaComplessivaGolene,
    watchLarghezzaComplessivaGolenePonte,
    watchAreeGolenaliAssenti,
  ]);

  useEffect(() => {
    !watchStrutturaAlveoRischioIdraulicoPile &&
      setValue('evoluzioneEventualeDelFondo', '');
  }, [
    // watchEvidenzeProfonditaDelleFondazioni,
    watchStrutturaAlveoRischioIdraulico,
  ]);

  // CONTROLLO OPERE DI PROTEZIONE
  const watchOpereProtezioneSpondale = watch('opereProtezioneSpondale');
  const opereProtezioneSpondaleNessuna =
    watchOpereProtezioneSpondale !== ''
      ? watchOpereProtezioneSpondale?.some(
          el => el === OpereProtezioneSpondale.Nessuna
        )
      : false;
  const opereProtezioneSpondaleAltro =
    watchOpereProtezioneSpondale !== ''
      ? watchOpereProtezioneSpondale?.some(
          el => el === OpereProtezioneSpondale.Altro
        )
      : false;

  const { remove: removeOpereProtezione } = useFieldArray({
    control,
    name: 'opereProtezioneSpondale',
  });

  useEffect(() => {
    if (rischioIdraulicoPresenteDaVerificare) {
      if (
        watchOpereProtezioneSpondale?.length &&
        watchOpereProtezioneSpondale.includes(8)
      ) {
        if (watchOpereProtezioneSpondale?.length > 1) {
          watchOpereProtezioneSpondale.sort();
          removeOpereProtezione(0);
        }
      }
    }

    if (
      !rischioIdraulicoPresenteDaVerificare &&
      watchOpereProtezioneSpondale?.length
    ) {
      removeOpereProtezione();
    }
  }, [watchOpereProtezioneSpondale, watchRischioIdraulico]);

  const watchProtezioneAlPiedePileSpalle = watch('protezioneAlPiedePileSpalle');
  const watchProtezioneAlPiedePileSpalleSi =
    watchProtezioneAlPiedePileSpalle === ProtezioneAlPiedePileSpalle.Si;

  const watchBrigliaProtezioneValleDelPonte = watch(
    'brigliaProtezioneValleDelPonte'
  );
  const watchBrigliaProtezioneValleDelPonteSi =
    watchBrigliaProtezioneValleDelPonte === BrigliaProtezioneValleDelPonte.Si;

  //Functions
  useEffect(() => {
    !watchProtezioneAlPiedePileSpalleSi &&
      setValue('statoConservazioneProtezionePiede', '');
  }, [watchProtezioneAlPiedePileSpalle]);

  useEffect(() => {
    !watchBrigliaProtezioneValleDelPonteSi &&
      setValue('statoConservazioneAdeguatezzaOperaBriglia', '');
  }, [watchBrigliaProtezioneValleDelPonte]);

  useEffect(() => {
    !opereProtezioneSpondaleAltro &&
      setValue('opereProtezioneSpondaleAltro', '');
  }, [watchOpereProtezioneSpondale]);

  // CONTROLLO OPERE DI MITIGAZIONE E MONITORAGGIO
  const watchOpereArginaturaDiAlterazionePortate = watch(
    'opereArginaturaDiAlterazionePortate'
  );
  const watchOpereArginaturaDiAlterazionePortatePresenti =
    watchOpereArginaturaDiAlterazionePortate ===
    OpereArginaturaDiAlterazionePortate.Presenti;

  const watchMisureLaminazioneMitigazione = watch(
    'misureLaminazioneMitigazione'
  );
  const watchMisureLaminazioneMitigazionePresenti =
    watchMisureLaminazioneMitigazione === MisureLaminazioneMitigazione.Presenti;

  const watchSistemaMonitoraggioRischioIdraulico = watch(
    'sistemaMonitoraggioRischioIdraulico'
  );
  const watchSistemaMonitoraggioRischioIdraulicoPresenti =
    watchSistemaMonitoraggioRischioIdraulico ===
    sistemaMonitoraggioRischioIdraulico.Presenti;

  //Functions
  useEffect(() => {
    if (!watchSistemaMonitoraggioRischioIdraulicoPresenti) {
      setValue('tipologiaSistemaMonitoraggioRischioIdraulico', '');
      setValue('statoConservazioneSistemaMonitoraggioRischioIdraulico');
    }
  }, [watchSistemaMonitoraggioRischioIdraulico]);

  useEffect(() => {
    if (!watchMisureLaminazioneMitigazionePresenti) {
      setValue('statoConservazioneAdeguatezzaOpereLaminazione', '');
      setValue('tipologiaMisureLaminazioneMitigazione', '');
    }
  }, [watchMisureLaminazioneMitigazione]);

  useEffect(() => {
    if (!watchOpereArginaturaDiAlterazionePortatePresenti) {
      setValue('tipologiaOpereArginatura', '');
      setValue('statoConservazioneAdeguatezzaOpereArginatura', '');
    }
  }, [watchOpereArginaturaDiAlterazionePortate]);

  //
  const watchBacinoIdrograficoRischioIdraulico = watch(
    'bacinoIdrograficoRischioIdraulico'
  );
  const watchBacinoIdrograficoRischioIdraulicoEffettivo =
    watchBacinoIdrograficoRischioIdraulico ===
    BacinoIdrograficoRischioIdraulico.Effettivo;
  const watchBacinoIdrograficoRischioIdraulicoPresunto =
    watchBacinoIdrograficoRischioIdraulico ===
    BacinoIdrograficoRischioIdraulico.Presunto;

  const watchTipologiaReticolo = watch('tipologiaReticoloRischioIdraulico');
  /* const watchTipologiaReticoloPrincipale =
    watchTipologiaReticolo === TipologiaReticolo.ReticoloPrincipale; */
  const watchTipologiaReticoloSecondario =
    watchTipologiaReticolo === TipologiaReticolo.ReticoloSecondario;
  const watchTipologiaReticoloArtificiale =
    watchTipologiaReticolo === TipologiaReticolo.ReticoloArtificiale;
  const tipologiaReticoloSecondarioArtificiale =
    watchTipologiaReticoloSecondario || watchTipologiaReticoloArtificiale;

  const watchMorfologiaAlveo = watch('morfologiaAlveoRischioIdraulico');
  const watchMorfologiaCanaleSingolo =
    watchMorfologiaAlveo === MorfologiaAlveo.CanaleSingolo;

  const watchTendenzaDivagazionePlanimetrica = watch(
    'divagazioneAlveoRischioIdraulico'
  );
  const watchTendenzaDivagazionePlanimetricaSi =
    watchTendenzaDivagazionePlanimetrica ===
    DivagazioneAlveoRischioIdraulico.Si;

  const watchDeposizioneSedimenti = watch('deposizioneSedimenti');
  const watchDeposizioneSedimentiSi =
    watchDeposizioneSedimenti === DeposizioneSedimenti.Si;

  const watchTrasportoMaterialeVegetale = watch(
    'materialeVegetaleRischioIdraulico'
  );
  const watchMaterialeVegetaleSi =
    watchTrasportoMaterialeVegetale === MaterialeVegetaleRischioIdraulico.Si;

  useEffect(() => {
    !watchMorfologiaCanaleSingolo &&
      setValue('morfologiaAlveoCanaleSingoloRischioIdraulico', '');
  }, [watchMorfologiaAlveo]);

  useEffect(() => {
    if (
      !watchTipologiaReticoloSecondario &&
      !watchTipologiaReticoloArtificiale
    ) {
      setValue('ricadenteAreaAlluvioniRischioIdraulico', '');
    }
  }, [watchTipologiaReticolo]);

  useEffect(() => {
    watchBacinoIdrograficoRischioIdraulicoEffettivo &&
      setValue('bacinoIdrograficoPresuntoRischioIdraulico', '');
    watchBacinoIdrograficoRischioIdraulicoPresunto &&
      setValue('bacinoIdrograficoEffettivoRischioIdraulico', '');
  }, [watchBacinoIdrograficoRischioIdraulico]);

  const watchBacinoIdrograficoSuperficieEffettiva = watch(
    'bacinoIdrograficoEffettivoRischioIdraulico'
  );

  useEffect(() => {
    if (watchBacinoIdrograficoSuperficieEffettiva) {
      watchBacinoIdrograficoSuperficieEffettiva < 100 &&
        setValue(
          'bacinoIdrograficoPresuntoRischioIdraulico',
          BacinoIdrograficoPresuntoRischioIdraulico.S100
        );
      watchBacinoIdrograficoSuperficieEffettiva <= 500 &&
        watchBacinoIdrograficoSuperficieEffettiva >= 100 &&
        setValue(
          'bacinoIdrograficoPresuntoRischioIdraulico',
          BacinoIdrograficoPresuntoRischioIdraulico.S100500
        );
      watchBacinoIdrograficoSuperficieEffettiva > 500 &&
        setValue(
          'bacinoIdrograficoPresuntoRischioIdraulico',
          BacinoIdrograficoPresuntoRischioIdraulico.S500
        );
    }
  }, [watchBacinoIdrograficoSuperficieEffettiva]);

  //Functions
  useEffect(() => {
    if (watchRischioIdraulicoAssente) {
      setValue('fenomenoSormontoRischioIdraulico', '');
      setValue('fenomenoErosioneRischioIdraulico', '');
      setValue('presenzaStudioIdraulicoLivelliDefiniti', '');
      setValue('areaPericolosaRischioIdraulico', '');
      setValue('ricadenteAreaAlluvioniRischioIdraulico', '');
      setValue('presenzaRilieviRischioIdraulico', '');
      setValue('bacinoIdrograficoRischioIdraulico', '');
      setValue('bacinoIdrograficoEffettivoRischioIdraulico', '');
      setValue('bacinoIdrograficoPresuntoRischioIdraulico', '');
      setValue('tipologiaReticoloRischioIdraulico', '');
      setValue('confinamentoAlveoRischioIdraulico', '');
      setValue('morfologiaAlveoRischioIdraulico', '');
      setValue('morfologiaAlveoCanaleSingoloRischioIdraulico', '');
      setValue('tipologiaFondoAlveoRischioIdraulico', '');
      setValue('materialeAlveoRischioIdraulico', '');
      setValue('accumuloDetritiRischioIdraulico', '');
      setValue('evoluzioneAlveoRischioIdraulico', '');
      setValue('curvaturaAlveoRischioIdraulico', '');
      setValue('divagazioneAlveoRischioIdraulico', '');
      setValue('tipologiaCorsoAcquaRischioIdraulico', '');
      setValue('riferimentoZeroMetriRischioIdraulico', '');
      setValue('quotaMinimaIntradossoRischioIdraulico', '');
      setValue('quotaMinimaIntradossoEffettivo', false);
      setValue('quotaMinimaIntradossoPresunto', false);
      setValue('quotaPeloLiberoP2RischioIdraulico', '');
      setValue('francoIdraulicoFP2', '');
      setValue('quotaPeloLiberoP3RischioIdraulico', '');
      setValue('francoIdraulicoFP3', '');
      setValue('deposizioneSedimenti', '');
      setValue('materialeVegetaleRischioIdraulico', '');
      setValue('quotaArgineMinRischioIdraulico', '');
      setValue('quotaPeloLiberoStimaRischioIdraulico', '');
      setValue('quotaPeloLiberoStimataRilevabile', false);
      setValue('quotaPeloLiberoStimataNonRilevabile', false);
      setValue('francoIdraulicoStimato', '');
      setValue('francoIdraulicoSuperiore', '');
      setValue('quotaSpondaMaxRischioIdraulico', '');
      setValue('quotaFondoAlveoRischioIdraulico', '');
      setValue('larghezzaAlveoPileSpalle', '');
      setValue('larghezzaAlveoPileSpalleRilevabile', false);
      setValue('larghezzaAlveoPileSpalleNonRilevabile', false);
      setValue('larghezzaAlveoPonte', '');
      setValue('fattoreRestringimentoAlveo', '');
      setValue('areeGolenaliAssenti', '');
      setValue('larghezzaComplessivaGolene', '');
      setValue('larghezzaGoleneRilevabile', false);
      setValue('larghezzaGoleneNonRilevabile', false);
      setValue('larghezzaComplessivaGolenePonte', '');
      setValue('fattoreRestringimentoAreeGolenali', '');
      setValue('larghezzaPilaOAggettoSpalla', '');
      setValue('larghezzaPilaOAggettoSpallaEffettiva', false);
      setValue('larghezzaPilaOAggettoSpallaPresunta', false);
      setValue('profonditaScavoMax', '');
      setValue('profonditaFondazioneRispettoAlveo', '');
      setValue('profonditaFondazioneRispettoAlveoEffettiva', false);
      setValue('profonditaFondazioneRispettoAlveoPresunta', false);
      setValue('fattoreIEL', '');
      setValue('evidenzeProfonditaDelleFondazioni', '');
      setValue('abbassamentoGeneralizzatoAlveo', '');
      setValue('evoluzioneEventualeDelFondo', '');
      setValue('abbassamentoAccentuatoAlleFondazioni', '');
      setValue('accumuliDetritiAMontePila', '');
      setValue('opereProtezioneSpondaleAltro', '');
      setValue('statoConservazioneAdeguatezzaOpera', '');
      setValue('protezioneAlPiedePileSpalle', '');
      setValue('statoConservazioneProtezionePiede', '');
      setValue('brigliaProtezioneValleDelPonte', '');
      setValue('statoConservazioneAdeguatezzaOperaBriglia', '');
      setValue('opereArginaturaDiAlterazionePortate', '');
      setValue('tipologiaOpereArginatura', '');
      setValue('statoConservazioneAdeguatezzaOpereArginatura', '');
      setValue('tipologiaAreePossibileAllagamento', '');
      setValue('misureLaminazioneMitigazione', '');
      setValue('tipologiaMisureLaminazioneMitigazione', '');
      setValue('statoConservazioneAdeguatezzaOpereLaminazione', '');
      setValue('sistemaMonitoraggioRischioIdraulico', '');
      setValue('tipologiaSistemaMonitoraggioRischioIdraulico', '');
      setValue('statoConservazioneSistemaMonitoraggioRischioIdraulico', '');
      setValue('annotazioniRischioIdraulico', '');
    }
  }, [watchRischioIdraulico]);

  // CHECKBOX

  /**
   * START Rilevabile Non rilevabile
   */
  const watchLarghezzaAlveoPileSpalleRilevabile = watch(
    'larghezzaAlveoPileSpalleRilevabile'
  );
  const watchLarghezzaAlveoPileSpalleNonRilevabile = watch(
    'larghezzaAlveoPileSpalleNonRilevabile'
  );

  const watchQuotaPeloLiberoStimataRilevabile = watch(
    'quotaPeloLiberoStimataRilevabile'
  );
  const watchQuotaPeloLiberoStimataNonRilevabile = watch(
    'quotaPeloLiberoStimataNonRilevabile'
  );

  useEffect(() => {
    !watchQuotaPeloLiberoStimataRilevabile &&
      !watchQuotaPeloLiberoStimataNonRilevabile &&
      setValue('quotaPeloLiberoStimaFlagRischioIdraulico', null);

    watchQuotaPeloLiberoStimataRilevabile &&
      setValue(
        'quotaPeloLiberoStimaFlagRischioIdraulico',
        RilevabileNonRilevabile.Rilevabile
      );
    watchQuotaPeloLiberoStimataNonRilevabile &&
      setValue(
        'quotaPeloLiberoStimaFlagRischioIdraulico',
        RilevabileNonRilevabile.NonRilevabile
      );
  }, [
    watchQuotaPeloLiberoStimataRilevabile,
    watchQuotaPeloLiberoStimataNonRilevabile,
  ]);

  const watchLarghezzaGoleneRilevabile = watch('larghezzaGoleneRilevabile');
  const watchLarghezzaGoleneNonRilevabile = watch(
    'larghezzaGoleneNonRilevabile'
  );
  useEffect(() => {
    !watchLarghezzaGoleneRilevabile &&
      !watchLarghezzaGoleneNonRilevabile &&
      setValue('larghezzaComplessivaGoleneFlag', null);

    watchLarghezzaGoleneRilevabile &&
      setValue(
        'larghezzaComplessivaGoleneFlag',
        RilevabileNonRilevabile.Rilevabile
      );
    watchLarghezzaGoleneNonRilevabile &&
      setValue(
        'larghezzaComplessivaGoleneFlag',
        RilevabileNonRilevabile.NonRilevabile
      );
  }, [watchLarghezzaGoleneRilevabile, watchLarghezzaGoleneNonRilevabile]);

  /**
   * END Rilevabile Non rilevabile
   */

  useEffect(() => {
    watchQuotaPeloLiberoStimataRilevabile &&
      setValue('francoIdraulicoSuperiore', '');
  }, [
    watchQuotaPeloLiberoStimataRilevabile,
    watchQuotaPeloLiberoStimataNonRilevabile,
  ]);

  /**
   * START Effettiva Presunta
   */
  const watchLarghezzaPilaOAggettoSpallaEffettiva = watch(
    'larghezzaPilaOAggettoSpallaEffettiva'
  );
  const watchLarghezzaPilaOAggettoSpallaPresunta = watch(
    'larghezzaPilaOAggettoSpallaPresunta'
  );

  useEffect(() => {
    !watchLarghezzaPilaOAggettoSpallaEffettiva &&
      !watchLarghezzaPilaOAggettoSpallaPresunta &&
      setValue('larghezzaPilaOAggettoSpallaFlag', null);

    watchLarghezzaPilaOAggettoSpallaEffettiva &&
      setValue('larghezzaPilaOAggettoSpallaFlag', EffettivoPresunto.Effettivo);
    watchLarghezzaPilaOAggettoSpallaPresunta &&
      setValue('larghezzaPilaOAggettoSpallaFlag', EffettivoPresunto.Presunto);
  }, [
    watchLarghezzaPilaOAggettoSpallaEffettiva,
    watchLarghezzaPilaOAggettoSpallaPresunta,
  ]);

  const watchProfonditaFondazioneRispettoAlveoEffettiva = watch(
    'profonditaFondazioneRispettoAlveoEffettiva'
  );
  const watchProfonditaFondazioneRispettoAlveoPresunta = watch(
    'profonditaFondazioneRispettoAlveoPresunta'
  );

  useEffect(() => {
    !watchProfonditaFondazioneRispettoAlveoEffettiva &&
      !watchProfonditaFondazioneRispettoAlveoPresunta &&
      setValue('profonditaFondazioneRispettoAlveoFlag', null);

    watchProfonditaFondazioneRispettoAlveoEffettiva &&
      setValue(
        'profonditaFondazioneRispettoAlveoFlag',
        EffettivoPresunto.Effettivo
      );
    watchProfonditaFondazioneRispettoAlveoPresunta &&
      setValue(
        'profonditaFondazioneRispettoAlveoFlag',
        EffettivoPresunto.Presunto
      );
  }, [
    watchProfonditaFondazioneRispettoAlveoEffettiva,
    watchProfonditaFondazioneRispettoAlveoPresunta,
  ]);

  /**
   * END  Effettiva Presunta
   */

  const watchQuotaMinimaIntradossoEffettivo = watch(
    'quotaMinimaIntradossoEffettivo'
  );
  const watchQuotaMinimaIntradossoPresunto = watch(
    'quotaMinimaIntradossoPresunto'
  );

  useEffect(() => {
    !watchQuotaMinimaIntradossoEffettivo &&
      !watchQuotaMinimaIntradossoPresunto &&
      setValue('quotaMinimaIntradossoFlagRilevabileRischioIdraulico', null);

    watchQuotaMinimaIntradossoEffettivo === true &&
      setValue(
        'quotaMinimaIntradossoFlagRilevabileRischioIdraulico',
        EffettivoPresunto.Effettivo
      );
    watchQuotaMinimaIntradossoPresunto === true &&
      setValue(
        'quotaMinimaIntradossoFlagRilevabileRischioIdraulico',
        EffettivoPresunto.Presunto
      );

    watchQuotaMinimaIntradossoEffettivo &&
      setValue('francoIdraulicoSuperiore', '');
  }, [watchQuotaMinimaIntradossoEffettivo, watchQuotaMinimaIntradossoPresunto]);

  useEffect(() => {
    if (watchQuotaMinimaIntradosso) {
      setValue('quotaMinimaIntradossoEffettivo', true);
      setValue('quotaMinimaIntradossoPresunto', false);
    } else setValue('quotaMinimaIntradossoEffettivo', false);
  }, [watchQuotaMinimaIntradosso]);

  //

  useEffect(() => {
    if (watchQuotaPeloLiberoStimata) {
      setValue('quotaPeloLiberoStimataRilevabile', true);
      setValue('quotaPeloLiberoStimataNonRilevabile', false);
    } else setValue('quotaPeloLiberoStimataRilevabile', false);
  }, [watchQuotaPeloLiberoStimata]);

  useEffect(() => {
    if (!scenarioP3 && !scenarioPericolositaNessuno) {
      setValue('quotaPeloLiberoStimataRilevabile', false);
      setValue('quotaPeloLiberoStimataNonRilevabile', false);
    }
    if (scenarioP3 && scenarioP2) {
      setValue('quotaPeloLiberoStimataRilevabile', false);
      setValue('quotaPeloLiberoStimataNonRilevabile', false);
    }
  }, [watchScenariPericolositaRischioIdraulico]);

  //
  useEffect(() => {
    !watchLarghezzaAlveoPileSpalleRilevabile &&
      !watchLarghezzaAlveoPileSpalleNonRilevabile &&
      setValue('larghezzaAlveoPileSpalleFlag', null);

    watchLarghezzaAlveoPileSpalleRilevabile &&
      setValue(
        'larghezzaAlveoPileSpalleFlag',
        RilevabileNonRilevabile.Rilevabile
      );
    watchLarghezzaAlveoPileSpalleNonRilevabile &&
      setValue(
        'larghezzaAlveoPileSpalleFlag',
        RilevabileNonRilevabile.NonRilevabile
      );
  }, [
    watchLarghezzaAlveoPileSpalleRilevabile,
    watchLarghezzaAlveoPileSpalleNonRilevabile,
  ]);

  return (
    <>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">DATI GENERALI</Typography>
          </TitleSection>
        </GridWrap>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.rischioIdraulico.drawerTitle}
          drawerText={INFO.rischioIdraulico.drawerText}
        >
          <RHFSelect
            name="rischioIdraulico"
            label={`Rischio idraulico`}
            defaultValue={''}
            selectOptions={RischioIdraulicoOptions}
            disabled
          />
        </InfoWrapper>
      </GridWrap>

      {rischioIdraulicoPresenteDaVerificare && (
        <>
          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            <InfoWrapper
              size={3}
              infoMessage={INFO.strutturaAlveoRischioIdraulico.infoMessage}
            >
              <RHFMultiSelect
                name="strutturaAlveoRischioIdraulico"
                label={`Parti della struttura che interessano l'alveo*`}
                defaultValue={[]}
                selectOptions={StrutturaAlveoRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <GridWrap item xs={3}>
              <RHFSelect
                name="fenomenoSormontoRischioIdraulico"
                label={`Fenomeno di sormonto o insuff.za di franco*`}
                defaultValue={''}
                selectOptions={FenomenoSormontoRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.fenomenoErosioneRischioIdraulico.drawerTitle}
              drawerText={INFO.fenomenoErosioneRischioIdraulico.drawerText}
            >
              <RHFSelect
                name="fenomenoErosioneRischioIdraulico"
                label={'Fenomeni di erosione generalizzata e localizzata*'}
                defaultValue={''}
                selectOptions={FenomenoErosioneRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
          </GridWrap>
          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={
                INFO.presenzaStudioIdraulicoLivelliDefiniti.drawerTitle
              }
              drawerText={
                INFO.presenzaStudioIdraulicoLivelliDefiniti.drawerText
              }
            >
              <RHFSelect
                name="presenzaStudioIdraulicoLivelliDefiniti"
                label={'Presenza studio idraulico con livelli idrici definiti*'}
                defaultValue={''}
                selectOptions={PresenzaStudioIdraulicoLivelliDefinitiOptions}
                disabled
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.scenariPericolositaRischioIdraulico.drawerTitle}
              drawerText={INFO.scenariPericolositaRischioIdraulico.drawerText}
            >
              <RHFMultiSelect
                name="scenariPericolositaRischioIdraulico"
                label={'Scenari di pericolosità noti*'}
                defaultValue={[]}
                selectOptions={ScenariPericolositaRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <GridWrap item xs={3}>
              <RHFSelect
                name="areaPericolosaRischioIdraulico"
                label={`Area riconosciuta pericolosa`}
                defaultValue={''}
                selectOptions={AreaPericolosaRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
          </GridWrap>

          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.presenzaRilieviRischioIdraulico.drawerTitle}
              drawerText={INFO.presenzaRilieviRischioIdraulico.drawerText}
            >
              <RHFSelect
                name="presenzaRilieviRischioIdraulico"
                label={'Presenza rilievi sezioni fluviali e struttura*'}
                defaultValue={''}
                selectOptions={PresenzaRilieviRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
          </GridWrap>

          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            <GridWrap item xs={3}>
              <RHFSelect
                name="bacinoIdrograficoRischioIdraulico"
                label={'Superficie bacino idrografico S'}
                defaultValue={''}
                selectOptions={BacinoIdrograficoRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
            <GridWrap item xs={3}>
              <RHFNumberTextField
                name="bacinoIdrograficoEffettivoRischioIdraulico"
                label={'Superficie effettiva [km²]'}
                defaultValue=""
                decimalScale={2}
                withValueLimit={({ value }) => value < 100000}
                disabled={
                  !watchBacinoIdrograficoRischioIdraulicoEffettivo ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
            <GridWrap item xs={3}>
              <RHFSelect
                name="bacinoIdrograficoPresuntoRischioIdraulico"
                label={'Superficie presunta [km²]*'}
                defaultValue={''}
                selectOptions={BacinoIdrograficoPresuntoRischioIdraulicoOptions}
                disabled={
                  !watchBacinoIdrograficoRischioIdraulicoPresunto ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
          </GridWrap>

          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            <GridWrap item xs={3}>
              <RHFSelect
                name="tipologiaReticoloRischioIdraulico"
                label={'Tipologia reticolo*'}
                defaultValue={''}
                selectOptions={TipologiaReticoloOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
            <InfoWrapper
              size={3}
              linkMessage={
                INFO.ricadenteAreaAlluvioniRischioIdraulico.linkMessage
              }
            >
              <RHFSelect
                name="ricadenteAreaAlluvioniRischioIdraulico"
                label={
                  'Ricadente in area mappata ai sensi della direttiva alluvioni'
                }
                defaultValue={''}
                selectOptions={RicadenteAreaAlluvioniRischioIdraulicoOptions}
                disabled={
                  !tipologiaReticoloSecondarioArtificiale ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
          </GridWrap>

          <GridWrap container spacing={2} padding="20px">
            <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
              <TitleSection>
                <Typography variant="h3BoldBlue">
                  CARATTERISTICHE ALVEO
                </Typography>
              </TitleSection>
            </GridWrap>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.confinamentoAlveo.drawerTitle}
              drawerText={INFO.confinamentoAlveo.drawerText}
            >
              <RHFSelect
                name="confinamentoAlveoRischioIdraulico"
                label={'Confinamento alveo'}
                defaultValue={''}
                selectOptions={ConfinamentoAlveoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.morfologiaAlveo.drawerTitle}
              drawerText={INFO.morfologiaAlveo.drawerText}
            >
              <RHFSelect
                name="morfologiaAlveoRischioIdraulico"
                label={'Morfologia alveo'}
                defaultValue={''}
                selectOptions={MorfologiaAlveoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.sinuosita.drawerTitle}
              drawerText={INFO.sinuosita.drawerText}
            >
              <RHFSelect
                name="morfologiaAlveoCanaleSingoloRischioIdraulico"
                label={'Sinuosità'}
                defaultValue={''}
                selectOptions={SinuositaOptions}
                disabled={
                  !watchMorfologiaCanaleSingolo ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <GridWrap item xs={3}></GridWrap>
            <GridWrap item xs={3}>
              <RHFSelect
                name="tipologiaFondoAlveoRischioIdraulico"
                label={'Tipologia fondo alveo'}
                defaultValue={''}
                selectOptions={TipologiaFondoAlveoRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
            <InfoWrapper
              size={3}
              infoMessage={INFO.materialeAlveoRischioIdraulico.infoMessage}
            >
              <RHFSelect
                name="materialeAlveoRischioIdraulico"
                label={'Natura materiale alveo*'}
                defaultValue={''}
                selectOptions={MaterialeAlveoRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <GridWrap item xs={3}>
              <RHFSelect
                name="accumuloDetritiRischioIdraulico"
                label={
                  'Sensibile accumulo di detriti trasportati dalla corrente'
                }
                defaultValue={''}
                selectOptions={AccumuloDetritiRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
            <GridWrap item md={3}>
              <MediaUpload
                title={`Foto natura materiale alveo`}
                btnTitle="Allega file"
                formName="fotoNaturaMaterialeAlveo"
                infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                dataType="video/*,image/*"
                uploadType={UploadMedia.media}
                type={FieldType.images}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
                compact={true}
              />
            </GridWrap>
            <InfoWrapper
              size={3}
              infoMessage={INFO.evoluzioneAlveoRischioIdraulico.infoMessage}
            >
              <RHFSelect
                name="evoluzioneAlveoRischioIdraulico"
                label={'Evoluzione alveo'}
                defaultValue={''}
                selectOptions={EvoluzioneAlveoRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoMessage={INFO.curvaturaAlveoRischioIdraulico.infoMessage}
            >
              <RHFSelect
                name="curvaturaAlveoRischioIdraulico"
                label={
                  'Alveo a sensibile curvatura in corrispondenza del ponte*'
                }
                defaultValue={''}
                selectOptions={CurvaturaAlveoRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.divagazioneAlveoRischioIdraulico.drawerTitle}
              drawerText={INFO.divagazioneAlveoRischioIdraulico.drawerText}
            >
              <RHFSelect
                name="divagazioneAlveoRischioIdraulico"
                label={"Tendenza dell'alveo a divagazione planimetrica*"}
                defaultValue={''}
                selectOptions={DivagazioneAlveoRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <GridWrap item md={3}>
              <MediaUpload
                title={`Foto di divagazione planimetrica`}
                btnTitle="Allega file"
                formName="fotoDivagazionePlanimetricaIdraulico"
                infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                dataType="video/*,image/*"
                uploadType={UploadMedia.media}
                type={FieldType.images}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
                disabled={!watchTendenzaDivagazionePlanimetricaSi}
                compact={true}
              />
            </GridWrap>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.tipologiaCorsoAcquaRischioIdraulico.drawerTitle}
              drawerText={INFO.tipologiaCorsoAcquaRischioIdraulico.drawerText}
            >
              <RHFSelect
                name="tipologiaCorsoAcquaRischioIdraulico"
                label={"Tipologia corso d'acqua*"}
                defaultValue={''}
                selectOptions={TipologiaCorsoAcquaRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
          </GridWrap>
          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            <GridWrap item xs={3}>
              <MediaUpload
                title={`Rilievo planimetrico`}
                btnTitle="Allega file"
                dataType=".jpeg,.png"
                formName="rilievoPlanimetrico"
                uploadType={UploadMedia.documents}
                type={FieldType.documents}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
              />
            </GridWrap>
            <GridWrap item xs={3}>
              <MediaUpload
                title={`Rilievo sezione trasversale`}
                btnTitle="Allega file"
                dataType=".jpeg,.png"
                formName="rilevoSezioneTrasversale"
                uploadType={UploadMedia.documents}
                type={FieldType.documents}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
              />
            </GridWrap>
            <GridWrap item xs={3}>
              <MediaUpload
                title={`Rilievo sezione longitudinale alveo`}
                btnTitle="Allega file"
                dataType=".jpeg,.png"
                formName="rilievoSezioneLongitudinaleAlveo"
                uploadType={UploadMedia.documents}
                type={FieldType.documents}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
              />
            </GridWrap>
            <InfoWrapper
              size={3}
              infoMessage={INFO.elaboratoGraficoSezioneFluviale.infoMessage}
            >
              <MediaUpload
                title={`Elaborato grafico sezione fluviale e struttura`}
                btnTitle="Allega file"
                dataType=".dwg"
                formName="elaboratoGraficoSezioneFluviale"
                uploadType={UploadMedia.graphicElaboration}
                type={FieldType.documents}
                mediaGroup={[
                  MediaGroup.Contesto,
                  MediaGroup.Idraulica,
                  MediaGroup.ElaboratiGrafici,
                ]}
                tags={['Prospetto']}
              />
            </InfoWrapper>
          </GridWrap>

          <GridWrap container spacing={2} padding="20px">
            <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
              <TitleSection>
                <Typography variant="h3BoldBlue">
                  SORMONTO O INSUFFICIENZA DI FRANCO
                </Typography>
              </TitleSection>
            </GridWrap>
            <InfoWrapper
              size={3}
              infoMessage={
                INFO.riferimentoZeroMetriRischioIdraulico.infoMessage
              }
            >
              <RHFTextField
                name="riferimentoZeroMetriRischioIdraulico"
                label={'Elemento livello 0m di riferimento*'}
                inputProps={{ maxLength: 50 }}
                defaultValue=""
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={
                INFO.quotaMinimaIntradossoRischioIdraulico.drawerTitle
              }
              drawerText={INFO.quotaMinimaIntradossoRischioIdraulico.drawerText}
            >
              <RHFNumberTextField
                name="quotaMinimaIntradossoRischioIdraulico"
                label={'Quota minima intradosso del ponte [m]*'}
                defaultValue=""
                decimalScale={2}
                withValueLimit={({ value }) => value < 10000 && value > -10000}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <GridWrap item xs={3}>
              <CheckboxContainer>
                <RHFCheckbox
                  name="quotaMinimaIntradossoEffettivo"
                  label="Rilevabile"
                  disabled={
                    watchQuotaMinimaIntradossoPresunto ||
                    watchQuotaMinimaIntradosso ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                  defaultValue={false}
                />
                <RHFCheckbox
                  name="quotaMinimaIntradossoPresunto"
                  label="Non rilevabile"
                  disabled={
                    watchQuotaMinimaIntradossoEffettivo ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                  defaultValue={false}
                />
              </CheckboxContainer>
            </GridWrap>
            <GridWrap item xs={3}></GridWrap>
            {/* {scenarioP2 && !tipologiaCasoI && ( */}
            {scenarioP2 && (
              <>
                <InfoWrapper
                  size={3}
                  infoMessage={
                    INFO.quotaPeloLiberoP2RischioIdraulico.infoMessage
                  }
                  compassMessage={
                    INFO.quotaPeloLiberoP2RischioIdraulico.compassMessage
                  }
                >
                  <RHFNumberTextField
                    name="quotaPeloLiberoP2RischioIdraulico"
                    label={'Quota pelo libero (scenario P2) [m]*'}
                    defaultValue=""
                    decimalScale={2}
                    withValueLimit={({ value }) =>
                      value < 10000 && value > -10000
                    }
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </InfoWrapper>
                <GridWrap item xs={3}>
                  <RHFNumberTextField
                    name="francoIdraulicoFP2"
                    label={
                      <>
                        Franco idraulico F<sub style={{ fontSize: 12 }}>P2</sub>{' '}
                        [m]*
                      </>
                    }
                    defaultValue=""
                    decimalScale={2}
                    withValueLimit={({ value }) =>
                      value < 10000 && value > -10000
                    }
                    disabled
                  />
                </GridWrap>
                <GridWrap item xs={6}></GridWrap>
              </>
            )}
            {/* {watchTipologiaReticoloPrincipale && scenarioP3 && !tipologiaCasoI && ( */}
            {/*   {watchTipologiaReticoloPrincipale && scenarioP3 && ( */}
            {scenarioP3 && (
              <>
                <InfoWrapper
                  size={3}
                  infoMessage={
                    INFO.quotaPeloLiberoP3RischioIdraulico.infoMessage
                  }
                  compassMessage={
                    INFO.quotaPeloLiberoP3RischioIdraulico.compassMessage
                  }
                >
                  <RHFNumberTextField
                    name="quotaPeloLiberoP3RischioIdraulico"
                    label={'Quota pelo libero (scenario P3) [m]*'}
                    defaultValue=""
                    decimalScale={2}
                    withValueLimit={({ value }) =>
                      value < 10000 && value > -10000
                    }
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </InfoWrapper>
                <GridWrap item xs={3}>
                  <RHFNumberTextField
                    name="francoIdraulicoFP3"
                    label={
                      <>
                        Franco idraulico F<sub style={{ fontSize: 12 }}>P3</sub>{' '}
                        [m]*
                      </>
                    }
                    defaultValue=""
                    decimalScale={2}
                    withValueLimit={({ value }) =>
                      value < 10000 && value > -10000
                    }
                    disabled
                  />
                </GridWrap>
                <GridWrap item xs={6}></GridWrap>
              </>
            )}
            {tipologiaCasoI &&
              (scenarioP3 || scenarioPericolositaNessuno) &&
              !scenarioP2 && (
                <>
                  <InfoWrapper
                    size={3}
                    infoDrawer={true}
                    drawerTitle={
                      INFO.quotaArgineMinRischioIdraulico.drawerTitle
                    }
                    drawerText={INFO.quotaArgineMinRischioIdraulico.drawerText}
                  >
                    <RHFNumberTextField
                      name="quotaArgineMinRischioIdraulico"
                      label={'Quota argine (min) [m]*'}
                      defaultValue=""
                      decimalScale={2}
                      withValueLimit={({ value }) =>
                        value < 10000 && value > -10000
                      }
                      disabled={inspectionDisabled || isViewingInspection}
                    />
                  </InfoWrapper>
                </>
              )}

            {tipologiaCasoII &&
              (scenarioP3 || scenarioPericolositaNessuno) &&
              !scenarioP2 && (
                <>
                  <InfoWrapper
                    size={3}
                    infoDrawer={true}
                    drawerTitle={
                      INFO.quotaSpondaMaxRischioIdraulico.drawerTitle
                    }
                    drawerText={INFO.quotaSpondaMaxRischioIdraulico.drawerText}
                  >
                    <RHFNumberTextField
                      name="quotaSpondaMaxRischioIdraulico"
                      label={'Quota sponda (max) [m]*'}
                      defaultValue=""
                      decimalScale={2}
                      withValueLimit={({ value }) =>
                        value < 10000 && value > -10000
                      }
                      disabled={inspectionDisabled || isViewingInspection}
                    />
                  </InfoWrapper>
                </>
              )}

            {tipologiaCasoIII &&
              (scenarioP3 || scenarioPericolositaNessuno) &&
              !scenarioP2 && (
                <>
                  <InfoWrapper
                    size={3}
                    infoDrawer={true}
                    drawerTitle={
                      INFO.quotaFondoAlveoRischioIdraulico.drawerTitle
                    }
                    drawerText={INFO.quotaFondoAlveoRischioIdraulico.drawerText}
                  >
                    <RHFNumberTextField
                      name="quotaFondoAlveoRischioIdraulico"
                      label={'Quota fondo alveo [m]*'}
                      defaultValue=""
                      decimalScale={2}
                      withValueLimit={({ value }) =>
                        value < 10000 && value > -10000
                      }
                      disabled={inspectionDisabled || isViewingInspection}
                    />
                  </InfoWrapper>
                </>
              )}
            {/* {(tipologiaCasoI || !scenarioP2) && ( */}
            {(scenarioP3 || scenarioPericolositaNessuno) && !scenarioP2 && (
              <>
                <InfoWrapper
                  size={3}
                  infoDrawer={true}
                  drawerTitle={
                    INFO.quotaPeloLiberoStimaRischioIdraulico.drawerTitle
                  }
                  drawerText={
                    INFO.quotaPeloLiberoStimaRischioIdraulico.drawerText
                  }
                >
                  <RHFNumberTextField
                    name="quotaPeloLiberoStimaRischioIdraulico"
                    label={'Quota pelo libero stimata*'}
                    defaultValue=""
                    decimalScale={2}
                    withValueLimit={({ value }) =>
                      value < 10000 && value > -10000
                    }
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </InfoWrapper>
                <GridWrap item xs={3}>
                  <CheckboxContainer>
                    <RHFCheckbox
                      name="quotaPeloLiberoStimataRilevabile"
                      label="Rilevabile"
                      disabled={
                        watchQuotaPeloLiberoStimataNonRilevabile ||
                        watchQuotaPeloLiberoStimata ||
                        inspectionDisabled ||
                        isViewingInspection
                      }
                      defaultValue={false}
                    />
                    <RHFCheckbox
                      name="quotaPeloLiberoStimataNonRilevabile"
                      label="Non rilevabile"
                      disabled={
                        watchQuotaPeloLiberoStimataRilevabile ||
                        inspectionDisabled ||
                        isViewingInspection
                      }
                      defaultValue={false}
                    />
                  </CheckboxContainer>
                </GridWrap>
              </>
            )}
          </GridWrap>

          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            {watchQuotaPeloLiberoStimataRilevabile && !scenarioP2 && (
              <>
                <InfoWrapper
                  size={3}
                  infoDrawer={true}
                  drawerTitle={INFO.francoIdraulicoStimato.drawerTitle}
                  drawerText={INFO.francoIdraulicoStimato.drawerText}
                >
                  <RHFNumberTextField
                    name="francoIdraulicoStimato"
                    label={'Franco idraulico stimato*'}
                    defaultValue=""
                    decimalScale={2}
                    withValueLimit={({ value }) =>
                      value < 10000 && value > -10000
                    }
                    disabled
                  />
                </InfoWrapper>
              </>
            )}
            {(watchQuotaMinimaIntradossoPresunto ||
              watchQuotaPeloLiberoStimataNonRilevabile) && (
              <InfoWrapper
                size={3}
                infoDrawer={true}
                drawerTitle={INFO.francoIdraulicoSuperiore.drawerTitle}
                drawerText={INFO.francoIdraulicoSuperiore.drawerText}
              >
                <RHFSelect
                  name="francoIdraulicoSuperiore"
                  label={'Franco > 2 m per evidenze di campo*'}
                  defaultValue={''}
                  selectOptions={FrancoIdraulicoSuperioreOptions}
                  disabled={
                    (!watchQuotaPeloLiberoStimataNonRilevabile &&
                      !watchQuotaMinimaIntradossoPresunto) ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                />
              </InfoWrapper>
            )}
          </GridWrap>

          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            <InfoWrapper
              size={6}
              infoDrawer={true}
              drawerTitle={INFO.deposizioneSedimenti.drawerTitle}
              drawerText={INFO.deposizioneSedimenti.drawerText}
            >
              <RHFSelect
                name="deposizioneSedimenti"
                label={'Evidente e accentuata deposizione di sedimenti*'}
                defaultValue={''}
                selectOptions={DeposizioneSedimentiOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <GridWrap item md={3}>
              <MediaUpload
                title={`Foto deposizione sedimenti`}
                btnTitle="Allega file"
                formName="fotoDepositoSedimenti"
                infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                dataType="video/*,image/*"
                uploadType={UploadMedia.media}
                type={FieldType.images}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
                disabled={!watchDeposizioneSedimentiSi}
                compact={true}
              />
            </GridWrap>
          </GridWrap>
          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            <InfoWrapper
              size={6}
              infoDrawer={true}
              drawerTitle={INFO.materialeVegetaleRischioIdraulico.drawerTitle}
              drawerText={INFO.materialeVegetaleRischioIdraulico.drawerText}
            >
              <RHFSelect
                name="materialeVegetaleRischioIdraulico"
                label={
                  'Trasporto di materiale vegetale di notevole dimensione*'
                }
                defaultValue={''}
                selectOptions={MaterialeVegetaleRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <GridWrap item md={3}>
              <MediaUpload
                title={`Foto trasporto materiale`}
                btnTitle="Allega file"
                formName="fotoTrasportoMateriale"
                infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                dataType="video/*,image/*"
                uploadType={UploadMedia.media}
                type={FieldType.images}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
                disabled={!watchMaterialeVegetaleSi}
                compact={true}
              />
            </GridWrap>
          </GridWrap>

          {fenomenoErosioneAccertatiIpotizzati && (
            <GridWrap container spacing={2} padding="20px">
              <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
                <TitleSection>
                  <Typography variant="h3BoldBlue">EROSIONE</Typography>
                </TitleSection>
              </GridWrap>
              <InfoWrapper
                size={1}
                infoDrawer={true}
                drawerTitle={INFO.larghezzaAlveoPileSpalle.drawerTitle}
                drawerText={INFO.larghezzaAlveoPileSpalle.drawerText}
              >
                <RHFNumberTextField
                  name="larghezzaAlveoPileSpalle"
                  label={
                    <>
                      W<sub style={{ fontSize: 14 }}>a,l</sub> [m]*
                    </>
                  }
                  defaultValue=""
                  decimalScale={2}
                  withValueLimit={({ value }) => value < 10000}
                  disabled={inspectionDisabled || isViewingInspection}
                />
              </InfoWrapper>
              <GridWrap item xs={3}>
                <CheckboxContainer>
                  <RHFCheckbox
                    name="larghezzaAlveoPileSpalleRilevabile"
                    label="Effettiva"
                    disabled={
                      watchLarghezzaAlveoPileSpalleNonRilevabile ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    defaultValue={false}
                  />
                  <RHFCheckbox
                    name="larghezzaAlveoPileSpalleNonRilevabile"
                    label="Presunta"
                    disabled={
                      watchLarghezzaAlveoPileSpalleRilevabile ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    defaultValue={false}
                  />
                </CheckboxContainer>
              </GridWrap>
              <InfoWrapper
                size={1}
                infoDrawer={true}
                drawerTitle={INFO.larghezzaAlveoPonte.drawerTitle}
                drawerText={INFO.larghezzaAlveoPonte.drawerText}
              >
                <RHFNumberTextField
                  name="larghezzaAlveoPonte"
                  label={
                    <>
                      W<sub style={{ fontSize: 14 }}>a</sub> [m]*
                    </>
                  }
                  defaultValue=""
                  decimalScale={2}
                  withValueLimit={({ value }) => value < 10000}
                  disabled={inspectionDisabled || isViewingInspection}
                />
              </InfoWrapper>
              <GridWrap item xs={1}></GridWrap>
              <InfoWrapper
                size={3}
                infoMessage={INFO.fattoreRestringimentoAlveo.infoMessage}
              >
                <RHFNumberTextField
                  name="fattoreRestringimentoAlveo"
                  label={
                    <>
                      Fattore restringimento alveo inciso C
                      <sub style={{ fontSize: 13 }}>a</sub>(%)*
                    </>
                  }
                  defaultValue=""
                  decimalScale={0}
                  withValueLimit={({ value }) => value < 1000}
                  disabled
                />
              </InfoWrapper>
              <GridWrap item xs={3}>
                <RHFSelect
                  name="areeGolenaliAssenti"
                  label={'Aree golenali assenti*'}
                  defaultValue={''}
                  selectOptions={AreeGolenaliAssentiOptions}
                  disabled={inspectionDisabled || isViewingInspection}
                />
              </GridWrap>

              <InfoWrapper
                size={1}
                infoDrawer={true}
                drawerTitle={INFO.larghezzaComplessivaGolene.drawerTitle}
                drawerText={INFO.larghezzaComplessivaGolene.drawerText}
              >
                <RHFNumberTextField
                  name="larghezzaComplessivaGolene"
                  label={
                    <>
                      W<sub style={{ fontSize: 14 }}>g,l</sub> [m]*
                    </>
                  }
                  defaultValue=""
                  decimalScale={2}
                  withValueLimit={({ value }) => value < 10000}
                  disabled={
                    watchAreeGolenaliAssentiSi ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                />
              </InfoWrapper>
              <GridWrap item xs={3}>
                <CheckboxContainer>
                  <RHFCheckbox
                    name="larghezzaGoleneRilevabile"
                    label="Effettiva"
                    disabled={
                      watchLarghezzaGoleneNonRilevabile ||
                      watchAreeGolenaliAssentiSi ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    defaultValue={false}
                  />
                  <RHFCheckbox
                    name="larghezzaGoleneNonRilevabile"
                    label="Presunta"
                    disabled={
                      watchLarghezzaGoleneRilevabile ||
                      watchAreeGolenaliAssentiSi ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    defaultValue={false}
                  />
                </CheckboxContainer>
              </GridWrap>
              <InfoWrapper
                size={1}
                infoDrawer={true}
                drawerTitle={INFO.larghezzaComplessivaGolenePonte.drawerTitle}
                drawerText={INFO.larghezzaComplessivaGolenePonte.drawerText}
              >
                <RHFNumberTextField
                  name="larghezzaComplessivaGolenePonte"
                  label={
                    <>
                      W<sub style={{ fontSize: 14 }}>g</sub> [m]*
                    </>
                  }
                  defaultValue=""
                  decimalScale={2}
                  withValueLimit={({ value }) => value < 10000}
                  disabled={
                    watchAreeGolenaliAssentiSi ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                />
              </InfoWrapper>
              <GridWrap item xs={1}></GridWrap>
              <InfoWrapper
                size={3}
                infoMessage={INFO.fattoreRestringimentoAreeGolenali.infoMessage}
              >
                <RHFNumberTextField
                  name="fattoreRestringimentoAreeGolenali"
                  label={
                    <>
                      Fattore restringimento aree golenali C
                      <sub style={{ fontSize: 13 }}>g</sub>(%)*
                    </>
                  }
                  defaultValue=""
                  decimalScale={0}
                  withValueLimit={({ value }) => value < 1000}
                  disabled
                />
              </InfoWrapper>
              <GridWrap item xs={3}></GridWrap>
              <InfoWrapper
                size={3}
                infoDrawer={true}
                drawerTitle={INFO.larghezzaPilaOAggettoSpalla.drawerTitle}
                drawerText={INFO.larghezzaPilaOAggettoSpalla.drawerText}
              >
                <RHFNumberTextField
                  name="larghezzaPilaOAggettoSpalla"
                  label={'Larghezza pila o aggetto spalla a [m]*'}
                  defaultValue=""
                  decimalScale={2}
                  withValueLimit={({ value }) => value < 100}
                  disabled={inspectionDisabled || isViewingInspection}
                />
              </InfoWrapper>
              <GridWrap item xs={3}>
                <CheckboxContainer>
                  <RHFCheckbox
                    name="larghezzaPilaOAggettoSpallaEffettiva"
                    label="Effettiva"
                    disabled={
                      watchLarghezzaPilaOAggettoSpallaPresunta ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    defaultValue={false}
                  />
                  <RHFCheckbox
                    name="larghezzaPilaOAggettoSpallaPresunta"
                    label="Presunta"
                    disabled={
                      watchLarghezzaPilaOAggettoSpallaEffettiva ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    defaultValue={false}
                  />
                </CheckboxContainer>
              </GridWrap>
              <InfoWrapper
                size={3}
                infoMessage={INFO.profonditaScavoMax.infoMessage}
              >
                <RHFNumberTextField
                  name="profonditaScavoMax"
                  label={
                    <>
                      {`Massima profondità di scavo d`}
                      <sub style={{ fontSize: 14 }}>s</sub> [m]*
                    </>
                  }
                  defaultValue=""
                  decimalScale={2}
                  withValueLimit={({ value }) => value < 100}
                  disabled={inspectionDisabled || isViewingInspection}
                />
              </InfoWrapper>
              <GridWrap item xs={3}></GridWrap>
              <InfoWrapper
                size={3}
                infoDrawer={true}
                drawerTitle={INFO.profonditaFondazioneRispettoAlveo.drawerTitle}
                drawerText={INFO.profonditaFondazioneRispettoAlveo.drawerText}
                legislationMessage={
                  INFO.profonditaFondazioneRispettoAlveo.legislationMessage
                }
              >
                <RHFNumberTextField
                  name="profonditaFondazioneRispettoAlveo"
                  label={
                    <>
                      {`Profondità fondazione rispetto all'alveo d`}
                      <sub style={{ fontSize: 14 }}>f</sub> [m]*
                    </>
                  }
                  defaultValue=""
                  decimalScale={2}
                  withValueLimit={({ value }) => value < 100}
                  disabled={inspectionDisabled || isViewingInspection}
                />
              </InfoWrapper>
              <GridWrap item xs={3}>
                <CheckboxContainer>
                  <RHFCheckbox
                    name="profonditaFondazioneRispettoAlveoEffettiva"
                    label="Effettiva"
                    disabled={
                      watchProfonditaFondazioneRispettoAlveoPresunta ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    defaultValue={false}
                  />
                  <RHFCheckbox
                    name="profonditaFondazioneRispettoAlveoPresunta"
                    label="Presunta"
                    disabled={
                      watchProfonditaFondazioneRispettoAlveoEffettiva ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    defaultValue={false}
                  />
                </CheckboxContainer>
              </GridWrap>
              <InfoWrapper size={3} infoMessage={INFO.fattoreIEL.infoMessage}>
                <RHFNumberTextField
                  name="fattoreIEL"
                  label={'Fattore IEL*'}
                  defaultValue=""
                  decimalScale={2}
                  withValueLimit={({ value }) => value < 100}
                  disabled
                />
              </InfoWrapper>
              <GridWrap item xs={3}></GridWrap>

              <InfoWrapper
                size={3}
                infoDrawer={true}
                drawerTitle={INFO.evidenzeProfonditaDelleFondazioni.drawerTitle}
                drawerText={INFO.evidenzeProfonditaDelleFondazioni.drawerText}
              >
                <RHFSelect
                  name="evidenzeProfonditaDelleFondazioni"
                  label={'Evidenze profondità delle fondazioni*'}
                  defaultValue={''}
                  selectOptions={EvidenzeProfonditaDelleFondazioniOptions}
                  disabled={
                    watchStrutturaAlveoRischioIdraulicoNessuna ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                />
              </InfoWrapper>
              <InfoWrapper
                size={3}
                infoMessage={INFO.evoluzioneEventualeDelFondo.infoMessage}
              >
                <RHFTextField
                  name="evoluzioneEventualeDelFondo"
                  label={
                    'Evoluzione eventuale del fondo alveo rispetto alle pile'
                  }
                  inputProps={{ maxLength: 150 }}
                  defaultValue=""
                  disabled={
                    !watchStrutturaAlveoRischioIdraulicoPile ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                />
              </InfoWrapper>
              <GridWrap item md={6}>
                <MediaUpload
                  title={`Foto profondità fondazioni`}
                  btnTitle="Allega file"
                  formName="fotoProfonditaFondazioni"
                  infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                  dataType="video/*,image/*"
                  uploadType={UploadMedia.media}
                  type={FieldType.images}
                  mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
                  tags={['Scalzamento']}
                  compact={true}
                />
              </GridWrap>
              <InfoWrapper
                size={3}
                infoDrawer={true}
                drawerTitle={INFO.abbassamentoGeneralizzatoAlveo.drawerTitle}
                drawerText={INFO.abbassamentoGeneralizzatoAlveo.drawerText}
              >
                <RHFSelect
                  name="abbassamentoGeneralizzatoAlveo"
                  label={"Abbassamento generalizzato dell'alveo*"}
                  defaultValue={''}
                  selectOptions={AbbassamentoGeneralizzatoAlveoOptions}
                  disabled={inspectionDisabled || isViewingInspection}
                />
              </InfoWrapper>
              <InfoWrapper
                size={3}
                infoMessage={INFO.abbassamentoAccentuatoFondazioni.infoMessage}
              >
                <RHFSelect
                  name="abbassamentoAccentuatoAlleFondazioni"
                  label={
                    'Abbassamento molto accentuato anche in relazione alle fondazioni'
                  }
                  defaultValue={''}
                  selectOptions={AbbassamentoAccentuatoAlleFondazioniOptions}
                  disabled={
                    !watchAbbassamentoGeneralizzatoAlveoSi ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                />
              </InfoWrapper>
              <GridWrap item md={6}>
                <MediaUpload
                  title={`Foto abbassamento alveo`}
                  btnTitle="Allega file"
                  formName="fotoAbassamentoAlveo"
                  infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                  dataType="video/*,image/*"
                  uploadType={UploadMedia.media}
                  type={FieldType.images}
                  mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
                  disabled={!watchAbbassamentoGeneralizzatoAlveoSi}
                  tags={['Scalzamento']}
                  compact={true}
                />
              </GridWrap>
              <InfoWrapper
                size={3}
                infoDrawer={true}
                drawerTitle={INFO.accumuliDetritiAMontePila.drawerTitle}
                drawerImages={INFO.accumuliDetritiAMontePila.drawerImages}
              >
                <RHFSelect
                  name="accumuliDetritiAMontePila"
                  label={
                    'Accumuli di detriti o materiale flottante a monte della pila*'
                  }
                  defaultValue={''}
                  selectOptions={AccumuliDetritiAMontePilaOptions}
                  disabled={
                    watchStrutturaAlveoRischioIdraulicoNessuna ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                />
              </InfoWrapper>
              <GridWrap item md={9}>
                <MediaUpload
                  title={`Foto accumuli a monte della pila`}
                  btnTitle="Allega file"
                  formName="fotoAccumuliMonteDellaPila"
                  infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                  dataType="video/*,image/*"
                  uploadType={UploadMedia.media}
                  type={FieldType.images}
                  mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
                  disabled={!watchAccumuliDetritiAMontePilaSi}
                  compact={true}
                />
              </GridWrap>
            </GridWrap>
          )}

          <GridWrap container spacing={2} padding="20px">
            <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
              <TitleSection>
                <Typography variant="h3BoldBlue">
                  OPERE DI PROTEZIONE
                </Typography>
              </TitleSection>
            </GridWrap>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.opereProtezioneSpondale.drawerTitle}
              drawerText={INFO.opereProtezioneSpondale.drawerText}
            >
              <RHFMultiSelect
                name="opereProtezioneSpondale"
                label={'Opere di protezione spondale*'}
                defaultValue={[]}
                selectOptions={OpereProtezioneSpondaleOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </InfoWrapper>
            <GridWrap item xs={3}>
              <RHFTextField
                name="opereProtezioneSpondaleAltro"
                label={'Specificare altro'}
                inputProps={{ maxLength: 50 }}
                defaultValue=""
                disabled={
                  !opereProtezioneSpondaleAltro ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
            <GridWrap item xs={3}>
              <RHFSelect
                name="statoConservazioneAdeguatezzaOpera"
                label={'Stato di conservazione e adeguatezza opera'}
                defaultValue={''}
                selectOptions={StatoConservazioneAdeguatezzaOperaOptions}
                disabled={
                  opereProtezioneSpondaleNessuna ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
            <GridWrap item md={3}>
              <MediaUpload
                title={`Foto protezione spondale`}
                btnTitle="Allega file"
                formName="fotoProtezioneSpondale"
                infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                dataType="video/*,image/*"
                uploadType={UploadMedia.media}
                type={FieldType.images}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
                disabled={opereProtezioneSpondaleNessuna}
                compact={true}
              />
            </GridWrap>
          </GridWrap>
          {!watchStrutturaAlveoRischioIdraulicoNessuna && (
            <>
              <GridWrap container spacing={2} padding="20px" marginTop={-40}>
                <InfoWrapper
                  size={3}
                  infoDrawer={true}
                  drawerTitle={INFO.protezioneAlPiedePileSpalle.drawerTitle}
                  drawerText={INFO.protezioneAlPiedePileSpalle.drawerText}
                >
                  <RHFSelect
                    name="protezioneAlPiedePileSpalle"
                    label={'Protezione al piede delle P e S del ponte*'}
                    defaultValue={''}
                    selectOptions={ProtezioneAlPiedePileSpalleOptions}
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </InfoWrapper>
                <InfoWrapper
                  size={3}
                  infoDrawer={true}
                  drawerTitle={
                    INFO.statoConservazioneProtezionePiede.drawerTitle
                  }
                  drawerText={INFO.statoConservazioneProtezionePiede.drawerText}
                >
                  <RHFSelect
                    name="statoConservazioneProtezionePiede"
                    label={'Stato di conservazione e adeguatezza opera'}
                    defaultValue={''}
                    selectOptions={StatoConservazioneProtezionePiedeOptions}
                    disabled={
                      !watchProtezioneAlPiedePileSpalleSi ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                  />
                </InfoWrapper>
                <GridWrap item md={3}>
                  <MediaUpload
                    title={`Foto protezione pile e spalle`}
                    btnTitle="Allega file"
                    formName="fotoProtezionePileSpalle"
                    infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                    dataType="video/*,image/*"
                    uploadType={UploadMedia.media}
                    type={FieldType.images}
                    mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
                    disabled={!watchProtezioneAlPiedePileSpalleSi}
                    compact={true}
                  />
                </GridWrap>
              </GridWrap>

              <GridWrap container spacing={2} padding="20px" marginTop={-40}>
                <InfoWrapper
                  size={3}
                  infoDrawer={true}
                  drawerTitle={INFO.brigliaProtezioneValleDelPonte.drawerTitle}
                  drawerText={INFO.brigliaProtezioneValleDelPonte.drawerText}
                >
                  <RHFSelect
                    name="brigliaProtezioneValleDelPonte"
                    label={
                      'Briglia di protezione immediatamente a valle del ponte*'
                    }
                    defaultValue={''}
                    selectOptions={BrigliaProtezioneValleDelPonteOptions}
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </InfoWrapper>
                <GridWrap item xs={3}>
                  <RHFSelect
                    name="statoConservazioneAdeguatezzaOperaBriglia"
                    label={'Stato di conservazione e adeguatezza opera'}
                    defaultValue={''}
                    selectOptions={
                      StatoConservazioneAdeguatezzaOperaBrigliaOptions
                    }
                    disabled={
                      !watchBrigliaProtezioneValleDelPonteSi ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                  />
                </GridWrap>
                <GridWrap item md={3}>
                  <MediaUpload
                    title={`Foto briglia di protezione`}
                    btnTitle="Allega file"
                    formName="fotoBrigliaDiProtezione"
                    infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                    dataType="video/*,image/*"
                    uploadType={UploadMedia.media}
                    type={FieldType.images}
                    mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
                    disabled={!watchBrigliaProtezioneValleDelPonteSi}
                    compact={true}
                  />
                </GridWrap>
              </GridWrap>
            </>
          )}

          <GridWrap container spacing={2} padding="20px">
            <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
              <TitleSection>
                <Typography variant="h3BoldBlue">
                  OPERE DI MITIGAZIONE E MONITORAGGIO
                </Typography>
              </TitleSection>
            </GridWrap>
            <GridWrap item xs={3}>
              <RHFSelect
                name="opereArginaturaDiAlterazionePortate"
                label={'Opere arginatura, alt.ne port. liquide e solide'}
                defaultValue={''}
                selectOptions={OpereArginaturaDiAlterazionePortateOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
            {watchOpereArginaturaDiAlterazionePortatePresenti && (
              <>
                <GridWrap item xs={3}>
                  <RHFTextField
                    name="tipologiaOpereArginatura"
                    label={'Tipologia'}
                    inputProps={{ maxLength: 50 }}
                    defaultValue=""
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </GridWrap>
                <GridWrap item xs={3}>
                  <RHFSelect
                    name="statoConservazioneAdeguatezzaOpereArginatura"
                    label={'Stato conservazione e adeguatezza opera'}
                    defaultValue={''}
                    selectOptions={
                      StatoConservazioneAdeguatezzaOpereArginaturaOptions
                    }
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </GridWrap>
              </>
            )}

            <GridWrap item xs={3}>
              <RHFTextField
                name="tipologiaAreePossibileAllagamento"
                label={'Tipologia aree di possibile allagamento'}
                inputProps={{ maxLength: 50 }}
                defaultValue=""
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
          </GridWrap>

          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            <GridWrap item xs={3}>
              <RHFSelect
                name="misureLaminazioneMitigazione"
                label={'Misure/opere laminazione e mitigazione'}
                defaultValue={''}
                selectOptions={MisureLaminazioneMitigazioneOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
            {watchMisureLaminazioneMitigazionePresenti && (
              <>
                <GridWrap item xs={3}>
                  <RHFTextField
                    name="tipologiaMisureLaminazioneMitigazione"
                    label={'Tipologia'}
                    inputProps={{ maxLength: 50 }}
                    defaultValue=""
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </GridWrap>
                <GridWrap item xs={3}>
                  <RHFSelect
                    name="statoConservazioneAdeguatezzaOpereLaminazione"
                    label={'Stato conservazione e adeguatezza opera'}
                    defaultValue={''}
                    selectOptions={
                      StatoConservazioneAdeguatezzaOpereLaminazioneOptions
                    }
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </GridWrap>
              </>
            )}
          </GridWrap>

          <GridWrap container spacing={2} padding="20px" marginTop={-40}>
            <GridWrap item xs={3}>
              <RHFSelect
                name="sistemaMonitoraggioRischioIdraulico"
                label={'Sistemi monitoraggio'}
                defaultValue={''}
                selectOptions={sistemaMonitoraggioRischioIdraulicoOptions}
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
            {watchSistemaMonitoraggioRischioIdraulicoPresenti && (
              <>
                <GridWrap item xs={3}>
                  <RHFTextField
                    name="tipologiaSistemaMonitoraggioRischioIdraulico"
                    label={'Tipologia'}
                    inputProps={{ maxLength: 50 }}
                    defaultValue=""
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </GridWrap>
                <GridWrap item xs={3}>
                  <RHFSelect
                    name="statoConservazioneSistemaMonitoraggioRischioIdraulico"
                    label={'Stato conservazione e adeguatezza opera'}
                    defaultValue={''}
                    selectOptions={
                      statoConservazioneSistemaMonitoraggioRischioIdraulicoOptions
                    }
                    disabled={inspectionDisabled || isViewingInspection}
                  />
                </GridWrap>
              </>
            )}
          </GridWrap>

          <GridWrap container spacing={2} padding="20px">
            <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
              <TitleSection>
                <Typography variant="h3BoldBlue">OSSERVAZIONI</Typography>
              </TitleSection>
            </GridWrap>
            <GridWrap item xs={12}>
              <RHFTextField
                name="annotazioniRischioIdraulico"
                label={'Annotazioni rischio idraulico'}
                inputProps={{ maxLength: 2000 }}
                defaultValue=""
                disabled={inspectionDisabled || isViewingInspection}
              />
            </GridWrap>
            <GridWrap item md={3}>
              <MediaUpload
                title={`Foto di annotazione rischio idraulico`}
                btnTitle="Allega file"
                formName="fotoAnnotazioneRischioIdraulico"
                infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                dataType="video/*,image/*"
                uploadType={UploadMedia.media}
                preview={true}
                type={FieldType.images}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Idraulica]}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};

export { Accordion4HydraulicRiskL1 };
