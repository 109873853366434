import styled from '@emotion/styled';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import ArrowIcon from 'assets/icons/arrow-down.svg';
import { colors } from 'constants/colors';

const CustomTextField = styled(TextField)(props => ({
  '& svg': {
    backgroundImage: props.disabled ? null : `url(${ArrowIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    fill: 'none',
    backgroundSize: '60%',
  },
}));

const CustomFormControl = styled(FormControl)(props => ({
  '& svg': {
    backgroundImage: props.disabled ? null : `url(${ArrowIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    fill: 'none',
    backgroundSize: '60%',
  },
  '& label': { fontSize: '0.8rem', fontFamily: 'Ubuntu' },
  '& label.Mui-focused': {
    color: colors.BLACK,
    fontWeight: 'bold',
  },
}));

const RHFSelect = ({
  name,
  rules,
  label,
  selectOptions,
  defaultValue,
  showNoValue = true,
  ...other
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <CustomTextField
            select
            label={label}
            {...field}
            fullWidth
            error={!!error}
            {...other}
            helperText={error?.message}
          >
            {showNoValue && (
              <MenuItem value="">
                <em>Nessun valore</em>
              </MenuItem>
            )}
            {selectOptions?.map((option, index) => (
              <MenuItem value={option.value} key={index}>
                {option.label}
              </MenuItem>
            ))}
          </CustomTextField>
        );
      }}
    />
  );
};
RHFSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  multiselect: PropTypes.bool,
  showNoValue: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
RHFSelect.defaultValue = {
  selectOptions: [],
};
const RHFMultiSelect = ({
  name,
  rules,
  label,
  selectOptions,
  defaultValue = [],
  disabled,
  ...other
}) => {
  const { control } = useFormContext();

  const valueWatch = useWatch({
    control,
    name,
  });
  const theme = useTheme();

  const [options, setOptions] = useState(defaultValue);

  const handleChange = (event, field) => {
    const {
      target: { value },
    } = event;
    const nextValue = typeof value === 'string' ? value.split(',') : value;
    field.onChange(nextValue);
    setOptions(nextValue);
  };

  useEffect(() => {
    if (valueWatch === '' || valueWatch === null || valueWatch === undefined) {
      setOptions([]);
    } else {
      setOptions(valueWatch);
    }
  }, [valueWatch]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      multiple
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <CustomFormControl style={{ width: '100%' }} disabled={disabled}>
          <InputLabel style={{ top: '-5px' }} id="demo-simple-select-label">
            {label}
          </InputLabel>
          <Select
            label={label}
            {...field}
            multiple
            disabled={disabled}
            // sx={{ height: '100%' }}
            labelId={'demo-simple-select-label'}
            error={!!error}
            {...other}
            renderValue={selected => {
              return selected.map(el => (
                <Chip
                  key={el}
                  label={selectOptions.find(o => o.value === el).label}
                  size="small"
                  color="primary"
                />
              ));
            }}
            value={options}
            onChange={e => {
              handleChange(e, field);
            }}
          >
            {selectOptions?.map(o => {
              const isSelected = options?.some(el => el === o.value);
              return (
                <MenuItem key={o.value} value={o.value} sx={{ padding: 0 }}>
                  <Box
                    width={'100%'}
                    p={0.5}
                    bgcolor={isSelected ? theme.palette.primary.light : ''}
                  >
                    {o.label}
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        </CustomFormControl>
      )}
    />
  );
};
RHFMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  multiselect: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
RHFMultiSelect.defaultValue = {
  selectOptions: [],
};
export { RHFSelect, RHFMultiSelect };
