import { Check, Clear } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line
import useFetch from 'use-http';
import { RHFTextField } from 'components/form';
import { apiCollaborators } from 'constants/api';
import {
  CollaborationResponseStatus,
  CollaborationsErrors,
  CollaboratorInvitedOn,
  PermissionType,
} from 'constants/permissions';
import { SubscriptionStatus } from 'constants/subscription';
import { UserType } from 'constants/users';
import { useSubscription } from 'hooks';
import { FormProvider } from 'providers';
import ErrorForm from './ErrorForm';
import ExistingUserForm from './ExistingUserForm';
import NewUserForm from './NewUserForm';

const CheckInvitabilityResponseType = {
  NOTFOUND: 'NOTFOUND',
};

const errorMessagePutRequestMap = [
  CollaborationsErrors.CollaboratorAlreadyExists,
  CollaborationsErrors.AlreadyInvitedByYou,
  CollaborationsErrors.YouMustUpdateNotInvite,
  CollaborationsErrors.DoNotInviteToRegisterInternalCanShareEntitiesAmongThemselves,
];
/* registeredExternal */
const errorMessageRegisteredExternalMap = [
  CollaborationsErrors.AlreadyInvitedOnThisInspection,
  CollaborationsErrors.AlreadyInvitedOnThisStructure,
  CollaborationsErrors.YouMustCreateCollaborationWithRegisteredMainUserThatIsNotYourCollaborator,
  CollaborationsErrors.CollaboratorAlreadyHaveEntityButWhereNotSharedByYou,
];

const errorMessageCannotContinueMap = [
  CollaborationsErrors.CollaboratorOfOtherUser,
  CollaborationsErrors.alreadyinvitedOnInspectionWithCanModifyByOthers,
  CollaborationsErrors.alreadyInvitedOnStructureWithCanEditByOther,
  CollaborationsErrors.inspectionAlreadySharedFromThisInviterToThisInvited,
  CollaborationsErrors.bridgeAlreadySharedFromThisInviterToThisInvited,
  CollaborationsErrors.internalCantUpdateMainThatHasSharedWithHim,
  CollaborationsErrors.internalAlreadySharedWithThisSiblingYouMustUpdateFromTheList,
  CollaborationsErrors.selfInvitationNotAllowed,
];

const sharedKeyMap = {
  [apiCollaborators.STRUCTURE_COLLBAORATORS]: 'sharedStructures',
  [apiCollaborators.INSPECTIONS_COLLBAORATORS]: 'sharedInspections',
};
const sharedSubKeyMap = {
  [apiCollaborators.STRUCTURE_COLLBAORATORS]: 'structureId',
  [apiCollaborators.INSPECTIONS_COLLBAORATORS]: 'inspectionId',
};
const CollaborationTypeMap = {
  1: 'internal',
  2: 'external',
};

const getInvitedOnValue = val => {
  switch (val) {
    case apiCollaborators.INSPECTIONS_COLLBAORATORS:
      return CollaboratorInvitedOn.Inspection;

    case apiCollaborators.STRUCTURE_COLLBAORATORS:
      return CollaboratorInvitedOn.Structure;

    default:
      return CollaboratorInvitedOn.Nothing;
  }
};

const MainForm = ({
  handleShare,
  setDisplay,
  shareRoute,
  entityId,
  shareOptions,
  isOwner = false,
  subscription,
  subscriptionStatus,
  accessExpiration,
}) => {
  /*   const { enqueueSnackbar } = useSnackbar();*/
  const payloadSchema = {
    email: '',
    name: '',
    surname: '',
    message: '',
    [sharedKeyMap[shareRoute]]: [
      {
        accessExpiration: undefined,
        [sharedSubKeyMap[shareRoute]]: parseInt(entityId),
        permission: '',
      },
    ],
  };

  const { get, put, post, loading } = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSubscription();

  const invitedOnValue = getInvitedOnValue(shareRoute);

  const [searchEmail, setSearchEmail] = useState('');
  const [disableSearchEmail, setDisableSearchEmail] = useState(false);
  const [checkInvitabilityResponse, setCheckInvitabilityResponse] = useState();
  const [collaboratorId, setCollaboratorId] = useState();
  const [collaboratorType, setCollaboratorType] = useState();
  const [collaboratorUserType, setCollaboratorUserType] = useState();
  const [shareOptionsByCheckedUser, setShareOptionByCheckedUser] =
    useState(shareOptions);

  const showDatePicker = subscriptionStatus === SubscriptionStatus.ATTIVA; // && isOwner; -> TEC-675 to view select of collaborationType and date picker of accessExpiration

  const defaultValues = {
    userEmail: '',
    name: '',
    surname: '',
    permission: PermissionType.CanEdit,
    message: '',
    accessExpiration: null,
    collaborationType: 'external',
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const handleReset = () => {
    reset();
    setSearchEmail('');
    setDisableSearchEmail(false);
    setDisplay(true);
  };

  const onSubmit = async data => {
    // this submit is divided in two parts
    // the first part (when we do not have set a searchEmail) will do a checkInvitability request
    // to see if the given mail relates to an user or not, either way it will set that input email to
    // the current state of searchEmail and use the response of the request to set
    // the checkInvitabilityRes state, which will handle (on the second onSubmit) the correct
    // endpoint of the sharing request
    if (!searchEmail) {
      setSearchEmail(data.userEmail);
      setDisableSearchEmail(true);
      const res = await get(
        `${apiCollaborators.CHECK_INVITABILITY}?email=${data.userEmail}&entityId=${entityId}&invitedOn=${invitedOnValue}`
      );
      let checkInvitabilityRes = '';
      if (res.status === CollaborationResponseStatus.OK) {
        checkInvitabilityRes = CheckInvitabilityResponseType.NOTFOUND;
      } else {
        checkInvitabilityRes = res?.exception?.errorMessage;
        if (res.response) {
          if (res.response.collaborator) {
            setCollaboratorId(res.response.collaborator);
          }
          if (res.response.collaborationType) {
            setCollaboratorType(res.response.collaborationType);
          }
          if (res.response.collaboratorObject) {
            if (res.response.collaboratorObject.type === UserType.Ente) {
              setShareOptionByCheckedUser(
                shareOptionsByCheckedUser.filter(
                  el => el.value === PermissionType.CanEdit
                )
              );
            }
            setCollaboratorUserType(res.response.collaboratorObject.type);
          }
        }
      }
      // here we also handle, based on the response, the fact that the
      // searched email relates to an existing user, resulting in a PUT request
      // otherwise we'll make a POST request to create an invitation
      setCheckInvitabilityResponse(checkInvitabilityRes);
      return;
    }
    const payload = { ...payloadSchema };
    payload.name = data.name;
    payload.surname = data.surname;
    payload.email = data.userEmail;
    payload.message = data.message;
    payload[sharedKeyMap[shareRoute]][0].permission = data.permission;
    payload[sharedKeyMap[shareRoute]][0].accessExpiration =
      data.accessExpiration ? data.accessExpiration : undefined;
    try {
      if (
        checkInvitabilityResponse === CheckInvitabilityResponseType.NOTFOUND
      ) {
        await post(`${shareRoute}/${data.collaborationType}`, payload);
      } else if (
        errorMessagePutRequestMap.includes(checkInvitabilityResponse)
      ) {
        payload.collaboratorId = collaboratorId;
        await put(
          `${shareRoute}/${CollaborationTypeMap[collaboratorType]}`,
          payload
        );
      } else if (
        errorMessageRegisteredExternalMap.includes(checkInvitabilityResponse)
      ) {
        payload.collaboratorId = collaboratorId;
        await post(
          `${shareRoute}${apiCollaborators.REGISTERED_EXTERNAL}`,
          payload
        );
      }
    } catch (err) {
      console.log('C.LOG ~ file: MainForm.jsx:189 ~ err', err);
      enqueueSnackbar(`Si è verificato un errore durante la condivisione`, {
        variant: 'error',
      });
    } finally {
      handleReset();
      handleShare();
    }
  };

  return (
    <Grid item xs={12} container sx={{ width: '100%', flex: 1, marginTop: 2 }}>
      <div style={{ width: '100%' }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFTextField
            inputRef={input => input && input.focus()}
            rules={{
              required: 'Email obbligatoria',
              pattern: {
                // eslint-disable-next-line no-useless-escape
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/,
                message: 'Inserire una email valida',
              },
            }}
            name="userEmail"
            label="Email utente con cui condividere*"
            disabled={disableSearchEmail}
          />
          {searchEmail && (
            <>
              {checkInvitabilityResponse ===
                CheckInvitabilityResponseType.NOTFOUND && (
                <NewUserForm
                  showDatePicker={
                    showDatePicker &&
                    (user?.type === UserType.Ente ||
                      (user?.type === UserType.Professionista &&
                        collaboratorUserType !== UserType.Ente))
                  }
                  subscription={subscription}
                  shareOptions={shareOptionsByCheckedUser}
                  accessExpiration={accessExpiration}
                />
              )}
              {errorMessagePutRequestMap.includes(
                checkInvitabilityResponse
              ) && (
                <ExistingUserForm
                  showDatePicker={
                    showDatePicker &&
                    (user?.type === UserType.Ente ||
                      (user?.type === UserType.Professionista &&
                        collaboratorUserType !== UserType.Ente))
                  }
                  subscription={subscription}
                  accessExpiration={accessExpiration}
                  addNoAccess
                  shareOptions={shareOptionsByCheckedUser}
                  checkInvitabilityResponse={checkInvitabilityResponse}
                />
              )}
              {errorMessageRegisteredExternalMap.includes(
                checkInvitabilityResponse
              ) && (
                <ExistingUserForm
                  subscription={subscription}
                  accessExpiration={accessExpiration}
                  showDatePicker={
                    showDatePicker &&
                    (user?.type === UserType.Ente ||
                      (user?.type === UserType.Professionista &&
                        collaboratorUserType !== UserType.Ente))
                  }
                  shareOptions={shareOptionsByCheckedUser}
                  checkInvitabilityResponse={checkInvitabilityResponse}
                />
              )}
              {errorMessageCannotContinueMap.includes(
                checkInvitabilityResponse
              ) && (
                <ErrorForm
                  checkInvitabilityResponse={checkInvitabilityResponse}
                />
              )}
            </>
          )}

          {loading && (
            <Grid item xs={12} textAlign="center">
              <CircularProgress sx={{ textAlign: 'center' }} />
            </Grid>
          )}
          {!loading && (
            <>
              {!errorMessageCannotContinueMap.includes(
                checkInvitabilityResponse
              ) && (
                <>
                  {searchEmail && (
                    <Button
                      sx={{ float: 'right', marginTop: 2 }}
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Condividi
                    </Button>
                  )}
                  {!searchEmail && (
                    <IconButton
                      sx={{ float: 'right', marginTop: 2 }}
                      color="primary"
                      onClick={handleSubmit(onSubmit)}
                    >
                      <Check />
                    </IconButton>
                  )}
                </>
              )}
              {!searchEmail && (
                <IconButton
                  sx={{ float: 'right', marginTop: 2, marginRight: 1 }}
                  color="primary"
                  onClick={handleReset}
                >
                  <Clear />
                </IconButton>
              )}
              {searchEmail && (
                <Button
                  sx={{ float: 'right', marginTop: 2 }}
                  onClick={handleReset}
                >
                  Annulla
                </Button>
              )}
            </>
          )}
        </FormProvider>
      </div>
    </Grid>
  );
};

MainForm.propTypes = {
  handleShare: PropTypes.func,
  setDisplay: PropTypes.func,
  shareRoute: PropTypes.oneOf([
    apiCollaborators.INSPECTIONS_COLLBAORATORS,
    apiCollaborators.STRUCTURE_COLLBAORATORS,
  ]),
  entityId: PropTypes.number,
  shareOptions: PropTypes.array,
  isOwner: PropTypes.bool,
  subscription: PropTypes.shape({
    expirationDate: PropTypes.string,
    id: PropTypes.number,
    maxAvailableSpace: PropTypes.number,
    maxStructureNumber: PropTypes.number,
    userId: PropTypes.number,
  }),
  subscriptionStatus: PropTypes.oneOf([
    SubscriptionStatus.ATTIVA,
    SubscriptionStatus.ND,
    SubscriptionStatus.SCADUTA,
  ]),
  accessExpiration: PropTypes.string,
};

export default MainForm;
