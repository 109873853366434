// eslint-disable-next-line import/no-named-as-default
import useFetch from 'use-http';

import { apiCollaborators } from 'constants/api';
import { useLoader, useOrder, usePagination } from 'hooks';
import { useAuthSelector } from 'stores';

function transformCollaboratorsResponse(value) {
  const { count, items } = value;

  return {
    count,
    items: items.map(el => {
      const {
        id,
        email,
        displayName,
        businessName,
        type,
        virtualUpdatedRegistrationStatus,
        collaborationType,
      } = el;

      return {
        id,
        email,
        displayName,
        businessName,
        type,
        updatedRegistrationStatus: virtualUpdatedRegistrationStatus,
        collaborationType,
      };
    }),
  };
}

function parseCollaboratorsResponse(value) {
  return transformCollaboratorsResponse(value);
}

function createCollaboratorsQueryURL(options) {
  const { orderBy, direction, page, pageSize, rhfData } = options || {};
  const searchParams = new URLSearchParams(
    Object.entries({
      sortBy: orderBy,
      sortOrder: direction && direction.toUpperCase(),
      page,
      pageSize,
      ...rhfData,
    }).filter(el => el[1])
  );

  if (searchParams.toString()) {
    return `${apiCollaborators.ROOT}?${searchParams.toString()}`;
  }

  return `${apiCollaborators.ROOT}`;
}

function useCollaboratorsFetch(options) {
  const { orderBy, direction, page, pageSize, rhfData, ...rest } = options;

  const structureDocumentsQueryURL = createCollaboratorsQueryURL({
    orderBy,
    direction,
    page,
    pageSize,
    rhfData,
  });

  const { loading, error, data, get } = useFetch(
    structureDocumentsQueryURL,
    {
      ...rest,
    },
    [orderBy, direction, page, pageSize, rhfData]
  );

  return {
    loading,
    error,
    data,
    get,
  };
}

const CollaboratorsSortByMap = {
  email: 'user.email',
  displayName: 'user.displayName',
  businessName: 'user.businessName',
  type: 'user.type',
  collaborationType: 'user.collaborationType',
  updatedRegistrationStatus: 'user.virtualUpdatedRegistrationStatus',
};

export function useCollaboratorsList(rhfData) {
  const { handleLogout } = useAuthSelector();

  const { orderBy, direction, onOrderChange } = useOrder();
  const {
    rowsPerPageOptions,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
  } = usePagination();

  const collaboratorsFetch = useCollaboratorsFetch({
    orderBy: orderBy && CollaboratorsSortByMap[orderBy],
    direction: direction && direction.toUpperCase(),
    page: page + 1,
    pageSize: rowsPerPage,
    rhfData,

    interceptors: {
      response: async ({ response }) => {
        if (!response.ok) {
          const statusCode = response.status;

          if (statusCode === 401) handleLogout();

          let errorMessage = statusCode.toString();
          if (response.statusText) errorMessage += ` - ${response.statusText}`;
          if (response?.data?.message)
            errorMessage += ` - ${response.data.message}`;
          throw new Error(errorMessage);
        }
        response.data = parseCollaboratorsResponse(response.data);

        return response;
      },
    },
  });

  const loading = collaboratorsFetch.loading;
  const error = collaboratorsFetch.error;
  const data = {
    collaborators: collaboratorsFetch.data,
  };

  useLoader(loading);

  return {
    loading,
    error,
    data,
    orderBy,
    direction,
    handleOrderChange: onOrderChange,
    rowsPerPageOptions,
    rowsPerPage,
    page,
    handlePageChange: onPageChange,
    handleRowsPerPageChange: onRowsPerPageChange,
  };
}
