import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { Table } from 'components';
import { CDAOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import useTabellaRiepilogativa from 'hooks/inspections/useTabellaRiepilogativa';
import { useInspectionSelector } from 'stores';
import TabellaRiepilogativaForm from './TabellaRiepilogativaForm';

const TabellaRiepilogativa = () => {
  const { getValues, watch, setValue } = useFormContext();

  const { schedaDifettiTrigger, inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const [selected, setSelected] = useState([]);

  const { recalculateTable } = useTabellaRiepilogativa();

  const tableWatch = watch('riepilogoStrutturaTable');

  const logRiepilogoStruttura = () => {
    recalculateTable();
    console.table(getValues('riepilogoStrutturaTable'));
  };

  const tableConfig = {
    columns: {
      codice: { label: 'Struttura.' },
      elementiAssociati: {
        label: 'Elementi Associati',
      },
      difettositaStrutturale: {
        label: 'Difettosità Strutturale',
        format: CDAOptions,
      },
      difettositaSismica: { label: 'Difettosità Sismica', format: CDAOptions },
    },
  };

  useEffect(() => {
    recalculateTable();
  }, []);

  useEffect(() => {
    recalculateTable();
  }, [schedaDifettiTrigger]);

  const editRows = item => {
    setValue('tabellaRiepilogativaTableValue', item.codice);
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 25 }}>
      {(process.env.REACT_APP_ENVIRONMENT == 'develop' ||
        process.env.REACT_APP_ENVIRONMENT == 'test') && (
        <Grid container spacing={2} my={3}>
          <Grid item xs={12}>
            <Button
              color="info"
              fullWidth
              variant="contained"
              onClick={logRiepilogoStruttura}
            >
              analizza tabella riepilogativa in console
            </Button>
          </Grid>
        </Grid>
      )}
      {tableWatch?.length > 0 && !tableWatch.includes(undefined) && (
        <>
          <Grid item xs={12}>
            {!inspectionDisabled && !isViewingInspection && (
              <TabellaRiepilogativaForm
                selected={selected}
                setSelected={setSelected}
                fields={tableWatch
                  .filter(el => !el?.disabled)
                  .map(el => {
                    return { value: el?.codice, label: el?.codice };
                  })}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Table
              data={tableWatch || []}
              config={tableConfig}
              rowActions={
                !inspectionDisabled && !isViewingInspection
                  ? [
                      {
                        onClick: item => {
                          editRows(item);
                          setSelected([item]);
                        },
                        icon: <EditIcon />,
                      },
                    ]
                  : []
              }
              selected={selected}
              setSelected={setSelected}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default TabellaRiepilogativa;
