import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'use-http';
import * as yup from 'yup';
import { HeaderPage } from 'components';
import DefaultForm from 'components/AdminCreateUser/DefaultForm';
import InspectorForm from 'components/AdminCreateUser/InspectorForm';
import InstitutionForm from 'components/AdminCreateUser/InstitutionForm';
import { apiUsers } from 'constants/api';
import { USER_PATHS } from 'constants/paths';
import { UserType, UserTypology } from 'constants/users';
import { FormProvider } from 'providers';
import { useGlobalSelector } from 'stores';
import { WhiteContainer } from '../Edit/Edit.styles';

const Create = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { enqueueSnackbar } = useSnackbar();
  const { post, response, get } = useFetch();

  useEffect(() => {}, []);

  const defaultValues = {
    type: '',
    tipologiaUtente: '',
    name: '',
    surname: '',
    email: '',
    notes: '',
    businessName: '',
    regionId: '',
    provinceId: '',
    councilId: '',
    address: '',
    phone: '',
    fiscalCode: '',
    emailUtentePrincipale: '',
    expirationDate: null,
    maxStructureNumber: '',
    maxAvailableSpace: '',
  };

  const schema = yup.object({
    type: yup.string().required('Categoria obbligatoria'),
    name: yup.string().required('Nome obbligatorio'),
    surname: yup.string().required('Cognome obbligatorio'),
    email: yup
      .string()
      .email('Inserire una email valida')
      .required('Email obbligatoria'),

    emailUtentePrincipale: yup.string().when(['tipologiaUtente'], {
      is: tipologiaUtente =>
        tipologiaUtente === UserTypology.CollaboratoreInterno,
      then: yup
        .string()
        .email('Inserire una email valida')
        .required('Email obbligatoria'),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = methods;

  console.log(errors);

  const AdminCreateUserExceptions = {
    UserAlreadyExists: 1,
    DesignedMainIsNotAMain: 2,
    CreateInternalMainUserDoesNotExists: 3,
    InternalAndMainHaveDifferentType: 4,
    OnlyAdminCanCreateUsers: 5,
  };

  const errorsMap = {
    [AdminCreateUserExceptions.UserAlreadyExists]:
      'Errore: utente già esistente!',
    [AdminCreateUserExceptions.DesignedMainIsNotAMain]:
      'Errore: utente principale selezionato non è un utente principale!',
    [AdminCreateUserExceptions.CreateInternalMainUserDoesNotExists]:
      'Errore: utente interno principale non esiste!',
    [AdminCreateUserExceptions.InternalAndMainHaveDifferentType]:
      'Errore: utente interno e utente principale sono di tipologia differente!',
    [AdminCreateUserExceptions.OnlyAdminCanCreateUsers]:
      'Errore: solo un utente admin può creare utenti!',
  };

  const onSubmit = async data => {
    const getUserList = async () => {
      let user;
      try {
        console.log('data ', data);
        console.log('watchTipologiaUtente ', watchTipologiaUtente);
        if (watchTipologiaUtente === UserTypology.CollaboratoreInterno) {
          const res = await get(
            `${apiUsers.ROOT}?email=${data.emailUtentePrincipale}`
          );
          user = res?.items[0];
        }

        if (
          watchTipologiaUtente === UserTypology.CollaboratoreInterno &&
          !user
        ) {
          return enqueueSnackbar(
            `Si è verificato un errore: Utente principale non trovato`,
            {
              variant: 'error',
            }
          );
        } else {
          let parentId;
          let updatedData;
          if (watchTipologiaUtente === UserTypology.CollaboratoreInterno) {
            if (
              (user?.type === 'ENTE' && data.type == 'ENTE') ||
              (user?.type === 'PROFESSIONISTA' &&
                data.type === 'PROFESSIONISTA')
            ) {
              parentId = user.id;
            }
          }

          updatedData = {
            ...data,

            // if (watchType === UserType.Admin) {
            //   delete updatedData.regionId;
            // }
            businessName: data?.businessName || undefined,
            phone: data?.phone || undefined,
            fiscalCode: data?.fiscalCode || undefined,
            address: data?.address || undefined,
            regionId: data.region?.value?.id || undefined,
            provinceId: data.province?.value?.id || undefined,
            councilId: data.city?.value?.id || undefined,
            parentId,

            subscription:
              watchTipologiaUtente === UserTypology.UtentePrincipale
                ? {
                    expirationDate: data?.expirationDate || undefined,
                    maxStructureNumber:
                      parseInt(data?.maxStructureNumber) || undefined,
                    maxAvailableSpace:
                      parseInt(data?.maxAvailableSpace) || undefined,
                  }
                : undefined,
          };

          // if ADMIN reset data
          if (data.type === 'ADMIN') {
            updatedData = {
              type: data.type,
              name: data.name,
              surname: data.surname,
              email: data.email,
              notes: data.notes,
            };
          }

          try {
            showLoader();
            await post(`${apiUsers.CREATE}`, updatedData);
            if (response.ok) {
              /* reset(defaultValues); */
              enqueueSnackbar('Utente creato correttamente', {
                variant: 'success',
              });
              navigate(USER_PATHS.ROOT);
            }
          } catch (err) {
            const errorRegex = /AdminCreateUserExceptions-\d+/;
            const result = err.message.match(errorRegex);
            const enumVal = result[0].split('-')[1];

            const errorMessage = errorsMap[enumVal];

            console.error('Error in handle cards: ', err);
            if (
              err?.message?.includes(
                'An account with the given email already exists'
              )
            ) {
              return enqueueSnackbar(
                `Si è verificato un errore: utente già esistente`,
                {
                  variant: 'error',
                }
              );
            } else {
              if (errorMessage) {
                return enqueueSnackbar(errorMessage, {
                  variant: 'error',
                });
              } else
                return enqueueSnackbar(`Si è verificato un errore`, {
                  variant: 'error',
                });
            }
          } finally {
            hideLoader();
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    getUserList().catch(console.error);
  };

  const watchTipologiaUtente = watch('tipologiaUtente');
  const watchType = watch('type');

  useEffect(() => {
    if (
      !watchTipologiaUtente ||
      watchTipologiaUtente === UserTypology.CollaboratoreInterno
    ) {
      setValue('businessName', '');
      setValue('phone', '');
      setValue('vatNumber', '');
      setValue('region', '');
      setValue('province', '');
      setValue('city', '');
      setValue('address', '');
      setValue('expirationDate', null);
      setValue('maxStructureNumber', '');
      setValue('maxStructureNumber', '');
    }
    if (
      !watchTipologiaUtente ||
      watchTipologiaUtente === UserTypology.UtentePrincipale
    ) {
      setValue('emailUtentePrincipale', '');
    }
  }, [watchTipologiaUtente]);

  const regionWatch = watch('region');
  const provinceWatch = watch('province');

  useEffect(() => {
    if (!regionWatch) {
      setValue('province', '');
      setValue('city', '');
    }
    if (!provinceWatch) {
      setValue('city', '');
    }
  }, [regionWatch, provinceWatch]);

  return (
    <>
      <HeaderPage title="Nuovo utente" back={USER_PATHS.ROOT} />
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit, console.log)}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 8,
            margin: '-90px 16px 10px 0',
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => navigate(USER_PATHS.ROOT)}
          >
            Annulla
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            type="submit"
            disabled={
              !watchTipologiaUtente &&
              (watchType === UserType.Ente ||
                watchType === UserType.Professionista)
            }
          >
            Crea
          </Button>
        </div>
        <WhiteContainer style={{ marginTop: 20 }}>
          <DefaultForm />
          {watchType === UserType.Ente && <InstitutionForm />}
          {watchType === UserType.Professionista && <InspectorForm />}
        </WhiteContainer>
      </FormProvider>
    </>
  );
};

export default Create;
