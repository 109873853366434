import { Button, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { useState } from 'react';

import pinPosition from 'assets/icons/pin-position.svg';
import trash from 'assets/icons/trash.svg';
import { BasicDialog, InfoAssistanceDialogActions, Rate } from 'components';

import {
  BridgesCardAccessExpiredType,
  BridgesCardDisabledMessageMap,
} from 'constants/bridges';
import { InspectionStatuses } from 'constants/colors';

import { InspectionStatus } from 'constants/inspections';
import { tour_02 } from 'constants/tour';
import { refreshS3Path } from 'utils/upload';
import {
  BtnModalWrapper,
  CardContainer,
  CardContent,
  CardImage,
  CardImageLabel,
  CardPosisiton,
  CardText,
  DisabledCard,
  ModalContainer,
  Card as MyCard,
  Status,
  StatusContainer,
  TrashIcon,
} from './Card.style';

export const Card = ({
  status,
  rating,
  image,
  date,
  title,
  position,
  cda,
  onClick,
  onDelete,
  disabled = true,
  ownershipSubscriptionStatus,
  isOwner,
  hasEditPermission,
  hasInspection,
}) => {
  const renderImage = () => {
    return (
      <CardImage>
        <img src={refreshS3Path(image)} alt={title || ''} />
        {cda && (
          <CardImageLabel cda={cda}>
            <label>CdA</label>
          </CardImageLabel>
        )}
      </CardImage>
    );
  };

  //State
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  //Functions
  const handleModal = () => {
    setOpenModal(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  let structureName;
  const splitTitle = title.split(' ');
  const foundLongWord = splitTitle.some(word => word.length > 16);
  const indexLongWord = splitTitle.findIndex(word => word.length > 16);
  if (foundLongWord) {
    indexLongWord === 0
      ? (structureName = title?.substring(0, 16).concat('...'))
      : (structureName = title?.substring(0, 21).concat('...'));
  } else if (title?.length > 50) {
    structureName = title?.substring(0, 40).concat('...');
  } else {
    structureName = title;
  }

  const BridgesCardSubscriptionActionsMap = {
    [BridgesCardAccessExpiredType.MAINUSER_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
      <></>,
    [BridgesCardAccessExpiredType.MAINUSER_NOT_OWNER_WITH_NO_SUBSCRIPTION]: (
      <></>
    ),
    [BridgesCardAccessExpiredType.MAINUSER_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
      <></>,
    [BridgesCardAccessExpiredType.MAINUSER_OWNER_WITH_EXPIRED_SUBSCRIPTION]: (
      <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />
    ),
    [BridgesCardAccessExpiredType.MAINUSER_OWNER_WITH_NO_SUBSCRIPTION]: (
      <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />
    ),
    [BridgesCardAccessExpiredType.MAINUSER_OWNER_WITH_ACTIVE_SUBSCRIPTION]: (
      <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />
    ),
    [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
      <></>,
    [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_NO_SUBSCRIPTION]:
      <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />,
    [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
      <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />,
    [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
      <></>,
    [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_NO_SUBSCRIPTION]:
      <></>,
    [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
      <></>,
  };

  return (
    <>
      <CardContainer className={tour_02.step_03}>
        {disabled && <DisabledCard onClick={() => setOpenDialog(true)} />}

        <>
          <MyCard>
            <StatusContainer>
              <Status color={InspectionStatuses[status].labelColor} />
              <Typography variant="body" sx={{ fontSize: '0.75rem' }}>
                {InspectionStatuses[status].labelCardText}
              </Typography>
            </StatusContainer>

            <CardContent onClick={onClick}>
              {image ? renderImage() : ''}

              <CardText>
                <Typography variant="body">{date}</Typography>
                <Typography variant="h2" style={{ fontSize: 22 }}>
                  {structureName}
                </Typography>
                <CardPosisiton>
                  <Typography variant="body">
                    <img src={pinPosition} alt="" />
                    {position}
                  </Typography>
                </CardPosisiton>
                <Rate rating={rating} />
              </CardText>
            </CardContent>
            {status === InspectionStatus.NEW &&
              isOwner &&
              hasEditPermission &&
              !hasInspection && (
                <TrashIcon onClick={() => handleModal()}>
                  <img src={trash} alt="" />
                </TrashIcon>
              )}
          </MyCard>
        </>

        {openModal && (
          <ModalContainer>
            <Typography variant="h3Bold" sx={{ fontSize: 16 }}>
              Sei sicuro di eliminare {title}?
            </Typography>
            <BtnModalWrapper>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onDelete}
              >
                Sì
              </Button>
              <Button
                variant="containedBlack"
                size="small"
                sx={{ padding: 0 }}
                onClick={() => handleCancel()}
              >
                No
              </Button>
            </BtnModalWrapper>
          </ModalContainer>
        )}
      </CardContainer>
      <BasicDialog
        title=""
        open={openDialog}
        actions={BridgesCardSubscriptionActionsMap[ownershipSubscriptionStatus]}
        onClose={() => setOpenDialog(false)}
      >
        {BridgesCardDisabledMessageMap[ownershipSubscriptionStatus]}
      </BasicDialog>
    </>
  );
};
Card.propTypes = {
  status: PropTypes.number,
  rating: PropTypes.object.isRequired,
  image: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  position: PropTypes.string,
  cda: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  ownershipSubscriptionStatus: PropTypes.number,
  isOwner: PropTypes.bool,
  hasEditPermission: PropTypes.bool,
  hasInspection: PropTypes.bool,
};
Card.defaultProps = {
  status: 0,
};
