import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { colors } from 'constants/colors';

export const Container = styled('div')(({ theme, isInfoAssistance }) => ({
  backgroundColor: isInfoAssistance
    ? colors.LIGHTGREY
    : theme.palette.secondary.light,
  // height: 210,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: ' 10px 15px',
  borderRadius: 8,
}));

export const NoFileContainer = styled('div')({
  display: 'flex',
  gap: 3,
});

export const MiniBoxImage = styled('div')({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  height: 55,
});

export const MiniIconImage = styled('div')(({ disabled }) => ({
  width: 37,
  height: 37,
  cursor: !disabled && 'pointer',
}));

export const BoxImage = styled('div')({
  width: 66,
});

export const EllipsisTypo = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const FileContainer = styled('div')({
  backgroundColor: colors.LIGHTGREY,
  padding: 5,
  borderRadius: 3,
  marginTop: 10,
  display: 'flex',
  justifyContent: 'space-between',
});

export const ImagesFileContainer = styled('div')({
  padding: 7,
  marginTop: 4,
  width: '100%',
  display: 'flex',
  overflow: 'auto',
  gap: 3,
});

export const ImagePlaceholder = styled('div')({
  backgroundColor: colors.LIGHTGREY,
  width: 66,
  height: 66,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const VideoBox = styled('video')({
  width: 66,
  height: 66,
});

export const InfoFile = styled('div')({
  display: 'flex',
  gap: 5,
  width: '90%',
});

export const DownloadIcon = styled('img')({
  cursor: 'pointer',
});

export const ButtonContainer = styled('div')(({ fileUploaded }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingTop: 10,
  width: '100%',
  marginTop: fileUploaded && 10,
}));

export const BtnPanelContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 5,
  marginTop: 15,
});

export const DrawerContentContainer = styled('div')({
  marginTop: 30,
  width: '100%',
  backgroundColor: colors.WHITE,
  borderRadius: 8,
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});
