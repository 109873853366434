import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GridWrap, RHFSelect } from 'components';
import { SelectionPanel } from 'components/SelectionPanel/SelectionPanel';
import CordoliSection from './sections/CordoliSection';
import GuardavieSection from './sections/GuardavieSection';
import MarciapiediSection from './sections/MarciapiediSection';
import ParapettiSection from './sections/ParapettiSection';
import PavimentazioneSection from './sections/PavimentazioneSection';
import SpartitrafficoSection from './sections/SpartitrafficoSection';

const Accordion2Impalcato = () => {
  const { watch } = useFormContext();

  const sovrastrutturaImpalcatoTableWatch = watch(
    'sovrastrutturaImpalcatoTable'
  );

  const [
    sovrastrutturaImpalcatoSelezioneOptions,
    setSovrastrutturaImpalcatoOptions,
  ] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState();

  useEffect(() => {
    if (sovrastrutturaImpalcatoTableWatch) {
      setSovrastrutturaImpalcatoOptions(
        sovrastrutturaImpalcatoTableWatch.map((el, idx) => {
          return { label: el.codice, value: idx };
        })
      );
    }
  }, [sovrastrutturaImpalcatoTableWatch]);

  // watcher for selecting index of which spalla/pila to modify
  const sovrastrutturaImpalcatoSelezioneWatch = watch(
    'sovrastrutturaImpalcatoSelezione'
  );
  useEffect(() => {
    if (sovrastrutturaImpalcatoSelezioneWatch !== undefined) {
      setSelectedIdx(sovrastrutturaImpalcatoSelezioneWatch);
    }
  }, [sovrastrutturaImpalcatoSelezioneWatch]);

  return (
    <>
      {(process.env.REACT_APP_ENVIRONMENT == 'develop' ||
        process.env.REACT_APP_ENVIRONMENT == 'test') &&
        selectedIdx !== '' && (
          <GridWrap container spacing={2} my={3}>
            <GridWrap item xs={12}>
              <Button
                color="info"
                fullWidth
                variant="contained"
                onClick={() =>
                  console.log(sovrastrutturaImpalcatoTableWatch[selectedIdx])
                }
              >
                analizza elemento in console
              </Button>
            </GridWrap>
          </GridWrap>
        )}
      <GridWrap item xs={12}>
        <SelectionPanel subtitle="Seleziona una campata/mensola/tampone per procedere alla compilazione">
          <GridWrap container spacing={2}>
            <GridWrap item xs={10}>
              <RHFSelect
                name="sovrastrutturaImpalcatoSelezione"
                label={'Seleziona elemento'}
                defaultValue={''}
                selectOptions={sovrastrutturaImpalcatoSelezioneOptions}
              />
            </GridWrap>
          </GridWrap>
        </SelectionPanel>
      </GridWrap>
      {selectedIdx !== '' && (
        <>
          <PavimentazioneSection selectedIdx={selectedIdx} />
          <CordoliSection selectedIdx={selectedIdx} />
          <MarciapiediSection selectedIdx={selectedIdx} />
          <SpartitrafficoSection selectedIdx={selectedIdx} />
          <GuardavieSection selectedIdx={selectedIdx} />
          <ParapettiSection selectedIdx={selectedIdx} />
        </>
      )}
    </>
  );
};
export default Accordion2Impalcato;
