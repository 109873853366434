// relates to
//https://github.com/nautes-tech/tecnoindagini-issues/issues/236
export const refreshScheda20 = sovrastrutturaImpalcatoTable => {
  const tmp = sovrastrutturaImpalcatoTable.map(el => {
    return {
      ...el,
      guardavieTable: el.guardavieTable.map(g => {
        return {
          ...g,
          schedaDifetti: g.schedaDifetti.map(s => {
            if (s.id == '121.c') {
              return {
                ...s,
                hasDanneggiato: null,
              };
            } else {
              return s;
            }
          }),
        };
      }),
      parapettiTable: el.parapettiTable.map(p => {
        return {
          ...p,
          schedaDifetti: p.schedaDifetti.map(s => {
            if (s.id == '121.c') {
              return {
                ...s,
                hasDanneggiato: null,
              };
            } else {
              return s;
            }
          }),
        };
      }),
    };
  });
  return tmp;
};

export const convertfromInttoArray = int => {
  if (int) {
    if (!Array.isArray(int)) {
      return [int];
    }
    return int;
  }
  return [];
};

// relates to
//https://github.com/nautes-tech/tecnoindagini-issues/issues/718

export const patchAffidabilitaComplessivaValutazioneRischioIdraulicoValue =
  affidabilitaComplessivaValutazioneRischioIdraulico => {
    switch (affidabilitaComplessivaValutazioneRischioIdraulico) {
      case 'Buona':
        return 1;
      case 'Limitata':
        return 2;
      default:
        return affidabilitaComplessivaValutazioneRischioIdraulico;
    }
  };
