import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GridWrap, RHFSelect } from 'components';
import { SelectionPanel } from 'components/SelectionPanel/SelectionPanel';
import ArchiSection from './sections/ArchiSection';
import ControventiSection from './sections/ControventiSection';
import ElementiExtraSection from './sections/ElementiExtraSection';
import PiedrittiSection from './sections/PiedrittiSection';
import SoletteSection from './sections/SoletteSections';
import TirantiSection from './sections/TirantiSection';
import TraversiSection from './sections/TraversiSection';
import TraviSection from './sections/TraviSection';

const Accordion4Impalcato = () => {
  const { watch } = useFormContext();

  const mensoleTamponiCampateImpalcatoTableWatch = watch(
    'mensoleTamponiCampateImpalcatoTable'
  );

  const [
    mensoleTamponiCampateImpalcatoSelezioneOptions,
    setMensoleTamponiCampateImpalcato,
  ] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState();

  useEffect(() => {
    if (mensoleTamponiCampateImpalcatoTableWatch) {
      setMensoleTamponiCampateImpalcato(
        mensoleTamponiCampateImpalcatoTableWatch.map((el, idx) => {
          return { label: el.codice, value: idx };
        })
      );
    }
  }, [mensoleTamponiCampateImpalcatoTableWatch]);

  // watcher for selecting index of which spalla/pila to modify
  const mensoleTamponiCampateImpalcatoSelezioneWatch = watch(
    'mensoleTamponiCampateImpalcatoSelezione'
  );
  useEffect(() => {
    if (mensoleTamponiCampateImpalcatoSelezioneWatch !== undefined) {
      setSelectedIdx(mensoleTamponiCampateImpalcatoSelezioneWatch);
    }
  }, [mensoleTamponiCampateImpalcatoSelezioneWatch]);

  return (
    <>
      <GridWrap item xs={12}>
        <SelectionPanel subtitle="Seleziona una Campata/Mensola/Tampone per procedere alla compilazione dei rispettivi soletta/travi/traversi/controventi/archi/piedritti/tiranti/elementi extra">
          <GridWrap container spacing={2}>
            <GridWrap item xs={10}>
              <RHFSelect
                name="mensoleTamponiCampateImpalcatoSelezione"
                label={'Seleziona elemento'}
                defaultValue={''}
                selectOptions={mensoleTamponiCampateImpalcatoSelezioneOptions}
              />
            </GridWrap>
          </GridWrap>
        </SelectionPanel>
      </GridWrap>
      {selectedIdx !== '' && (
        <>
          <SoletteSection selectedIdx={selectedIdx} />
          <TraviSection selectedIdx={selectedIdx} />
          <TraversiSection selectedIdx={selectedIdx} />
          <ControventiSection selectedIdx={selectedIdx} />
          <ArchiSection selectedIdx={selectedIdx} />
          <PiedrittiSection selectedIdx={selectedIdx} />
          <TirantiSection selectedIdx={selectedIdx} />
          <ElementiExtraSection selectedIdx={selectedIdx} />
        </>
      )}
    </>
  );
};
export default Accordion4Impalcato;
