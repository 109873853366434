export const ModeState = {
  ASSIGN: 1,
  TRANSFER: 2,
};

export const ChangeOwnershipErrorMap = {
  'Internal code not found': 'Codice Interno non trovato',
  Duplicates: 'Codice Interno duplicato',
  'New owner not found': 'Utente assegnatario non esistente',
  'Can not change owner': 'Impossibile cambiare proprietario',
  'Already owned structures':
    'Struttura già in possesso del proprietario di riferimento',
  ExceedMaxStructuresNumber: `La sottoscrizione dell'utente non ha sufficienti ponti rimanenti per poter effettuare il passaggio di proprietà.\n        Verifica e modifica i dati della sua sottoscrizione e riprova nuovamente.`,
  SubscriptionNotFound: `L'utente selezionato non ha una sottoscrizione attiva.
  Abbinare una sottoscrizione all'utente e riprovare nuovamente.`,
};
