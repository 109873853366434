import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';

import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

export const Breadcrumbs = ({ data }) => {
  const navigate = useNavigate();

  return (
    <MuiBreadcrumbs>
      {data?.slice(0, data.length - 1).map((el, index) => {
        return (
          <Typography
            sx={{ cursor: 'pointer' }}
            variant="body"
            key={index}
            onClick={() => {
              navigate(el.path);
            }}
          >
            {el.label}
          </Typography>
        );
      })}
      <Typography variant="body">{data?.[data?.length - 1].label}</Typography>
    </MuiBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
};
