import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { colors, InspectionStatuses } from 'constants/colors';
import {
  MisureMitigazioneOptions,
  PericolositaPAIPSAIFraneOptions,
} from 'constants/selectOptions';
import { styles } from './OverviewPdf.styles';
import CalendarIcon from '../../../../assets/icons/calendar.png';
import CheckGreenIcon from '../../../../assets/icons/check-green.png';
import CriticalAspectIcon from '../../../../assets/icons/critical-aspect.png';
import Danger from '../../../../assets/icons/danger.png';
import FraneIcon from '../../../../assets/icons/frane.png';
import IdraulicoIcon from '../../../../assets/icons/idraulica.png';
import LogoExport from '../../../../assets/icons/logoEsportazione.png';
import Monitoring from '../../../../assets/icons/monitoring.png';
import SettingIcon from '../../../../assets/icons/pdf-settings.png';
import StrutturaIcon from '../../../../assets/icons/struttura.png';
import TruckMoving from '../../../../assets/icons/truck-moving.png';
import UserIcon from '../../../../assets/icons/user.png';
import WarningAspectIcon from '../../../../assets/icons/warning-aspect.png';

import WeigthHigh from '../../../../assets/icons/weight-hanging.png';

const OverviewPdf = ({ imageData, imageData2, pdfData, formData }) => {
  let fondazioni;
  switch (pdfData?.impostaFondazioni) {
    case 1:
      fondazioni = 'Non nota';
      break;

    case 3:
      fondazioni = 'Diretta';
      break;

    case 5:
      fondazioni = 'Indiretta';
      break;
  }

  let franePAIPSAI;

  if (pdfData?.pericolositaPAIPSAIFrane) {
    switch (
      PericolositaPAIPSAIFraneOptions[pdfData?.pericolositaPAIPSAIFrane - 1]
        ?.value
    ) {
      case 1:
        franePAIPSAI = 'AA';
        break;

      case 2:
        franePAIPSAI = 'P1';
        break;

      case 3:
        franePAIPSAI = 'P2';
        break;

      case 4:
        franePAIPSAI = 'P3';
        break;

      case 5:
        franePAIPSAI = 'P4';
        break;

      case 6:
        franePAIPSAI = 'Area non mappata';
        break;
    }
  }

  console.log(formData, 'FORMDATA');
  console.log(pdfData, 'PDFDATA');
  let rilevatoreIdraulico = pdfData?.rilevatoreRischioIdraulico;
  const splitRilevatore = rilevatoreIdraulico?.split(' ');
  if (rilevatoreIdraulico?.length > 20 && splitRilevatore?.length === 2) {
    rilevatoreIdraulico = `${splitRilevatore[0]}\n${splitRilevatore[1]}`;
  } else if (
    rilevatoreIdraulico?.length > 20 &&
    splitRilevatore?.length === 3
  ) {
    rilevatoreIdraulico = `${splitRilevatore[0]} ${splitRilevatore[1]}\n${splitRilevatore[2]}`;
  }

  let parseIC;

  if (pdfData?.ic === null) {
    parseIC = '-';
  } else {
    parseIC = pdfData?.ic && parseInt(pdfData.ic);
  }

  const francoIdraulicoStimato = parseFloat(pdfData?.francoIdraulico)?.toFixed(
    2
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>{pdfData?.name}</Text>
        </View>
        <View style={styles.status}>
          <View
            style={[
              styles.statusColor,
              {
                backgroundColor:
                  InspectionStatuses[pdfData?.statoOpera]?.labelColor ||
                  InspectionStatuses[0]?.labelColor,
              },
            ]}
          />
          <Text style={styles.paragraph}>
            {InspectionStatuses[pdfData?.statoOpera]?.labelCardText ||
              InspectionStatuses[0]?.labelCardText}
          </Text>
        </View>
        <View style={styles.imageInfo}>
          <View style={styles.image}>
            {/* <Image
              src={pdfData?.fotoCopertina}
              style={{ borderRadius: '8pt' }}
            /> */}
            {pdfData?.fotoCopertina ? (
              <Image
                src={{
                  uri: pdfData?.fotoCopertina,
                  method: 'GET',
                  headers: {
                    'Cache-Control': 'no-cache',
                    'Access-Control-Allow-Origin': '*',
                  },
                  body: '',
                }}
                style={{ borderRadius: '8pt', objectFit: 'cover' }}
              />
            ) : (
              pdfData?.fotoCopertina === '' && (
                <View style={styles.placeholderImage} />
              )
            )}
          </View>
          <View style={styles.info}>
            <Text style={styles.infoLabel}>
              Comune <Text style={styles.infoData}>{pdfData?.comune}</Text>
            </Text>
            <Text style={styles.infoLabel}>
              Progressiva Km Iniziale{' '}
              <Text style={styles.infoData}>
                {pdfData?.progressivaKmIniziale}
              </Text>
            </Text>
            <Text style={styles.infoLabel}>
              Coordinate{' '}
              <Text style={styles.infoData}>{pdfData?.coordinate}</Text>
            </Text>
            <Text style={styles.infoLabel}>
              Ente scavalcato{' '}
              {pdfData?.tipologiaEnteScavalcato.length === 1 ? (
                <Text style={styles.infoData}>
                  {pdfData?.tipologiaEnteScavalcato[0]}
                </Text>
              ) : pdfData?.tipologiaEnteScavalcato.length > 1 ? (
                pdfData?.tipologiaEnteScavalcato.map((el, idx) => (
                  <Text key={idx} style={styles.infoData}>{`${el}, `}</Text>
                ))
              ) : (
                <Text style={styles.infoData}>{`-`}</Text>
              )}
              {/* {pdfData?.tipologiaEnteScavalcato.map((el, idx) => (
                <Text key={idx} style={styles.infoData}>{`${el}, `}</Text>
              ))} */}
            </Text>
            <Text style={styles.infoLabel}>
              Proprietario{' '}
              <Text style={styles.infoData}>
                {pdfData?.proprietario || '-'}
              </Text>
            </Text>
            <Text style={styles.infoLabel}>
              Ente vigilante{' '}
              <Text style={styles.infoData}>
                {pdfData?.enteVigilante || '-'}
              </Text>
            </Text>
            <Text style={styles.infoLabel}>
              Numero campate{' '}
              <Text style={styles.infoData}>{pdfData?.numeroCampate}</Text>
            </Text>
            <Text style={styles.infoLabel}>
              Luce complessiva [m]{' '}
              <Text style={styles.infoData}>
                {pdfData?.luceComplessivaEstesa || '-'}
              </Text>
            </Text>
          </View>
        </View>
        <View style={[styles.container, { height: 300, position: 'relative' }]}>
          <Text style={styles.titleH2}>Struttura</Text>

          <View style={styles.flexContainer}>
            <View style={styles.flex}>
              <Text style={styles.infoLabel}>Elementi critici</Text>
              {pdfData.elementiCritici ? (
                pdfData.elementiCritici === 1 ? (
                  <View
                    style={[styles.infoTag, { backgroundColor: '#3260CE' }]}
                  >
                    <Text style={styles.tagText}>PRESENTI</Text>
                  </View>
                ) : (
                  <View style={styles.infoTag}>
                    <Text style={styles.tagText}>ASSENTI</Text>
                  </View>
                )
              ) : (
                <View style={[styles.infoTag, { width: 30 }]}>
                  <Text style={styles.tagText}>-</Text>
                </View>
              )}
            </View>
            <View style={styles.flexContainer}>
              <Text style={styles.infoLabel}>Condizioni critiche</Text>
              {pdfData.condizioniCritiche ? (
                pdfData.condizioniCritiche === 1 ? (
                  <View
                    style={[styles.infoTag, { backgroundColor: '#3260CE' }]}
                  >
                    <Text style={styles.tagText}>PRESENTI</Text>
                  </View>
                ) : (
                  <View style={styles.infoTag}>
                    <Text style={styles.tagText}>ASSENTI</Text>
                  </View>
                )
              ) : (
                <View style={[styles.infoTag, { width: 30 }]}>
                  <Text style={styles.tagText}>-</Text>
                </View>
              )}
            </View>
            <View style={styles.flexContainer}>
              <Text style={styles.infoLabel}>IC</Text>
              {parseIC < 95 ? (
                <View style={styles.icTag}>
                  <Text style={styles.tagText}>{`${pdfData.ic}%`}</Text>
                </View>
              ) : parseIC >= 95 ? (
                <View style={styles.icTagGrey}>
                  <Text style={styles.tagText}>{`${pdfData.ic}%`}</Text>
                </View>
              ) : (
                <View style={styles.icTagGrey}>
                  <Text style={styles.tagText}>-</Text>
                </View>
              )}
            </View>
            <View style={styles.flexContainer}>
              <Text style={styles.infoLabel}>G=5</Text>
              {pdfData.totalG5 === '0' ? (
                <View style={styles.numberTagGrey}>
                  <Text style={styles.tagText}>{pdfData.totalG5}</Text>
                </View>
              ) : pdfData.totalG5 ? (
                <View style={styles.numberTag}>
                  <Text style={styles.tagText}>{pdfData.totalG5}</Text>
                </View>
              ) : (
                <View style={styles.icTagGrey}>
                  <Text style={styles.tagText}>-</Text>
                </View>
              )}
            </View>
            <View style={styles.flexContainer}>
              <Text style={styles.infoLabel}>PS</Text>
              {pdfData.totalPS === '0' ? (
                <View style={styles.numberTagGrey}>
                  <Text style={styles.tagText}>{pdfData.totalPS}</Text>
                </View>
              ) : pdfData.totalPS ? (
                <View style={styles.numberTag}>
                  <Text style={styles.tagText}>{pdfData.totalPS}</Text>
                </View>
              ) : (
                <View style={styles.icTagGrey}>
                  <Text style={styles.tagText}>-</Text>
                </View>
              )}
            </View>
            <View style={styles.flexContainer}>
              <Text style={styles.infoLabel}>NR</Text>
              {pdfData.totalNR === '0' ? (
                <View style={styles.numberTagGrey}>
                  <Text style={styles.tagText}>{pdfData.totalNR}</Text>
                </View>
              ) : pdfData.totalNR ? (
                <View style={styles.numberTag}>
                  <Text style={styles.tagText}>{pdfData.totalNR}</Text>
                </View>
              ) : (
                <View style={styles.icTagGrey}>
                  <Text style={styles.tagText}>-</Text>
                </View>
              )}
            </View>
          </View>
          <View style={styles.infoSection}>
            <Text style={styles.infoLabel}>
              Imposta fondazioni{' '}
              <Text style={styles.infoData}>
                {pdfData.impostaFondazioni ? fondazioni : '-'}
              </Text>
            </Text>
            <Text style={styles.infoLabel}>
              Categoria sosttosuolo{' '}
              <Text style={styles.infoData}>
                {pdfData.categoriaSottosuoloString || '-'}
              </Text>
            </Text>
            <Text style={styles.infoLabel}>
              Materiale prevalente{' '}
              <Text style={styles.infoData}>
                {pdfData.tipologiaImpalcato || '-'}
              </Text>
            </Text>
            <Text style={styles.infoLabel}>
              Tipologia{' '}
              <Text style={styles.infoData}>
                {pdfData.tipologiaStrutturaleString || '-'}
              </Text>
            </Text>
            <View
              style={{
                marginTop: 8,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View
                style={[
                  styles.iconElementContainer,
                  { backgroundColor: 'transparent', width: 8, height: 8 },
                ]}
              >
                <Image src={CalendarIcon} />
              </View>
              <Text style={[styles.infoLabel, { lineHeight: 0 }]}>
                {pdfData.dataIspezioneRischioStrutturale || '-'}
              </Text>

              <View
                style={[
                  styles.iconElementContainer,
                  {
                    backgroundColor: 'transparent',
                    marginLeft: 10,
                    width: 8,
                    height: 8,
                  },
                ]}
              >
                <Image src={UserIcon} />
              </View>
              <Text style={[styles.infoLabel, { lineHeight: 0 }]}>
                {pdfData.rilevatoreRischioStrutturale || '-'}
              </Text>
            </View>
          </View>

          {pdfData?.cdaPie ? (
            <View
              style={{
                position: 'absolute',
                height: 200,
                bottom: 50,
              }}
            >
              <Image
                src={imageData}
                style={{
                  objectFit: 'contain',
                }}
              />
            </View>
          ) : (
            <View
              style={{
                position: 'absolute',
                height: 200,
                bottom: 50,
                left: 176,
              }}
            >
              <Image
                src={imageData}
                style={{
                  objectFit: 'contain',
                }}
              />
            </View>
          )}
          {/*  <View style={{ position: 'absolute', height: 200, bottom: 50 }}>
            <Image
              src={imageData}
              style={{
                objectFit: 'contain',
              }}
            />
          </View> */}

          <View
            style={[
              styles.divider,
              {
                position: 'absolute',
                top: 140,
                zIndex: -1,
              },
            ]}
          ></View>

          <Text style={[styles.titleH2, { position: 'absolute', top: 150 }]}>
            Frane
          </Text>
          <View
            style={[
              styles.infoSection,
              {
                position: 'absolute',
                top: 176,
                left: 15,
                padding: 0,
              },
            ]}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={styles.infoLabel}>Rischio frane</Text>
              {pdfData.rischioFrana ? (
                pdfData.rischioFrana === 3 ? (
                  <View
                    style={[styles.infoTag, { backgroundColor: '#3260CE' }]}
                  >
                    <Text style={styles.tagText}>PRESENTE</Text>
                  </View>
                ) : pdfData.rischioFrana === 1 ? (
                  <View
                    style={[
                      styles.infoTag,
                      {
                        backgroundColor: 'white',
                        border: '1px solid black',
                        width: '80pt',
                      },
                    ]}
                  >
                    <Text style={[styles.tagText, { color: 'black' }]}>
                      DA VERIFICARE
                    </Text>
                  </View>
                ) : (
                  <View style={styles.infoTag}>
                    <Text style={styles.tagText}>ASSENTE</Text>
                  </View>
                )
              ) : (
                <View>
                  <Text style={styles.tagText}> -</Text>
                </View>
              )}
            </View>

            {pdfData?.rischioFrana === 2 ? (
              <View
                style={{
                  height: '70px',
                  width: '120px',
                  backgroundColor: '#FFFFFF',
                }}
              />
            ) : (
              <>
                <Text style={[styles.infoLabel, { marginTop: 5, width: 130 }]}>
                  Pericolosità PAI/PSAI Frane{' '}
                  <Text style={styles.infoData}>
                    {pdfData?.pericolositaPAIPSAIFrane ? franePAIPSAI : '-'}
                  </Text>
                </Text>
                <Text style={styles.infoLabel}>
                  Fenomeno{' '}
                  <Text style={styles.infoData}>
                    {pdfData?.fenomeno === 1
                      ? 'Accertato'
                      : pdfData?.fenomeno === 2
                      ? 'Potenziale'
                      : '-'}
                  </Text>
                </Text>
                <Text
                  style={[
                    styles.infoLabel,
                    { width: 160, backgroundColor: '#FFFFFF' },
                  ]}
                >
                  Grado criticità (Pc){' '}
                  <Text style={styles.infoData}>
                    {pdfData?.gradoCriticitaPc || '-'}
                  </Text>
                </Text>
                <Text style={styles.infoLabel}>
                  Misure di mitigazione{' '}
                  <Text
                    style={[styles.infoData, { backgroundColor: '#ffffff' }]}
                  >
                    {pdfData?.misureMitigazione
                      ? MisureMitigazioneOptions[pdfData.misureMitigazione - 1]
                          ?.label
                      : '-'}
                  </Text>
                </Text>
              </>
            )}

            <View
              style={{
                marginTop: 10,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View
                style={[
                  styles.iconElementContainer,
                  { backgroundColor: 'transparent', width: 8, height: 8 },
                ]}
              >
                <Image src={CalendarIcon} />
              </View>
              <Text style={[styles.infoLabel, { lineHeight: 0 }]}>
                {pdfData.dataIspezioneRischioFrane || '-'}
              </Text>

              <View
                style={[
                  styles.iconElementContainer,
                  {
                    backgroundColor: 'transparent',
                    marginLeft: 10,
                    width: 8,
                    height: 8,
                  },
                ]}
              >
                <Image src={UserIcon} />
              </View>
              <Text style={[styles.infoLabel, { lineHeight: 0 }]}>
                {pdfData.rilevatoreRischioFrane || '-'}
              </Text>
            </View>
          </View>

          <View
            style={{
              position: 'absolute',
              bottom: 0,
              right: 276,
              zIndex: -1,
              width: 1,
              height: 100,
              backgroundColor: 'grey',
            }}
          ></View>

          <View style={{ position: 'absolute', top: 150, left: 380 }}>
            <Text style={[styles.titleH2]}>Idraulica</Text>
            <View style={[styles.infoSection, { bottom: 10 }]}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={styles.infoLabel}>Rischio idraulico</Text>
                {pdfData.rischioIdraulico ? (
                  pdfData.rischioIdraulico === 3 ? (
                    <View
                      style={[styles.infoTag, { backgroundColor: '#3260CE' }]}
                    >
                      <Text style={styles.tagText}>PRESENTE</Text>
                    </View>
                  ) : pdfData.rischioIdraulico === 1 ? (
                    <View
                      style={[
                        styles.infoTag,
                        {
                          backgroundColor: 'white',
                          border: '1px solid black',
                          width: '80pt',
                        },
                      ]}
                    >
                      <Text style={[styles.tagText, { color: 'black' }]}>
                        DA VERIFICARE
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.infoTag}>
                      <Text style={styles.tagText}>ASSENTE</Text>
                    </View>
                  )
                ) : (
                  <View>
                    <Text style={styles.tagText}> -</Text>
                  </View>
                )}
              </View>

              {pdfData?.rischioIdraulico === 2 ? (
                <View
                  style={{
                    height: '70px',
                    width: '120px',
                    backgroundColor: '#FFFFFF',
                  }}
                />
              ) : (
                <>
                  <Text style={styles.infoLabel}>
                    Presenza studio idraulico{' '}
                    <Text style={styles.infoData}>
                      {pdfData.presenzaStudioIdraulicoLivelliDefiniti || '-'}
                    </Text>
                  </Text>
                  <Text style={styles.infoLabel}>
                    Franco idraulico[m]{' '}
                    <Text style={styles.infoData}>
                      {isNaN(francoIdraulicoStimato)
                        ? '-'
                        : francoIdraulicoStimato}
                    </Text>
                  </Text>
                  <Text style={styles.infoLabel}>
                    Strutture in alveo{' '}
                    {pdfData.strutturaAlveoRischioIdraulico.map((el, idx) => (
                      <Text key={idx} style={styles.infoData}>
                        {`${el}, ` || '-'}
                      </Text>
                    ))}
                  </Text>
                  <Text style={styles.infoLabel}>
                    Accumuli materiale{' '}
                    <Text style={styles.infoData}>
                      {pdfData.materialeVegetaleRischioIdraulicoString || '-'}
                    </Text>
                  </Text>
                  <Text style={styles.infoLabel}>
                    Opere di protezione{' '}
                    <Text style={styles.infoData}>
                      {pdfData.opereDiProtezione || '-'}
                    </Text>
                  </Text>
                </>
              )}

              <View style={{ marginTop: 8, flexDirection: 'row' }}>
                <View
                  style={[
                    styles.iconElementContainer,
                    { backgroundColor: 'transparent', width: 8, height: 8 },
                  ]}
                >
                  <Image src={CalendarIcon} />
                </View>
                <Text style={styles.infoLabel}>
                  {pdfData.dataIspezioneRischioIdraulico || '-'}
                </Text>
                <View
                  style={[
                    styles.iconElementContainer,
                    {
                      backgroundColor: 'transparent',
                      marginLeft: 10,
                      width: 8,
                      height: 8,
                    },
                  ]}
                >
                  <Image src={UserIcon} />
                </View>
                <Text style={[styles.infoLabel, { lineHeight: 0 }]}>
                  {rilevatoreIdraulico || '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.container, { height: 100 }]}>
          <Text style={styles.titleH2}>Attività e aspetti rilevanti</Text>

          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={styles.infoSection}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={styles.infoLabel}>
                  Necessità di ispezioni speciali
                </Text>
                {pdfData.specialInspectionInfo?.necessity ? (
                  pdfData.specialInspectionInfo?.necessity === 3 ? (
                    <View
                      style={[
                        styles.infoTag,
                        { backgroundColor: colors.AMARANTH, width: '20pt' },
                      ]}
                    >
                      <Text style={styles.tagText}>SI</Text>
                    </View>
                  ) : (
                    <View style={[styles.infoTag, { width: '20pt' }]}>
                      <Text style={styles.tagText}>NO</Text>
                    </View>
                  )
                ) : (
                  <View>
                    <Text style={styles.tagText}> -</Text>
                  </View>
                )}
              </View>
              <View style={{ flexDirection: 'row', marginTop: 10 }}>
                {pdfData?.necessitaIspezioniSpecialiRischioStruttura === 1 ? (
                  <View style={styles.rilevantAspects}>
                    <View
                      style={[
                        styles.iconElementContainer,
                        { backgroundColor: '#3260CE' },
                      ]}
                    >
                      <Image src={StrutturaIcon} />
                    </View>

                    <Text style={styles.infoAspects}>Struttura</Text>
                  </View>
                ) : (
                  <View style={styles.rilevantAspects}>
                    <View style={styles.iconElementContainer}>
                      <Image src={StrutturaIcon} />
                    </View>
                    <Text style={[styles.infoAspects, { color: 'grey' }]}>
                      Struttura
                    </Text>
                  </View>
                )}
                {pdfData?.necessitaIspezioniSpecialiRischioFrane === 1 ? (
                  <View
                    style={[styles.rilevantAspects, { margin: '0px 8px 4px' }]}
                  >
                    <View
                      style={[
                        styles.iconElementContainer,
                        { backgroundColor: '#3260CE' },
                      ]}
                    >
                      <Image src={FraneIcon} />
                    </View>

                    <Text style={styles.infoAspects}>Frane</Text>
                  </View>
                ) : (
                  <View
                    style={[styles.rilevantAspects, { margin: '0px 8px 4px' }]}
                  >
                    <View style={styles.iconElementContainer}>
                      <Image src={FraneIcon} />
                    </View>
                    <Text style={[styles.infoAspects, { color: 'grey' }]}>
                      Frane
                    </Text>
                  </View>
                )}

                {pdfData?.necessitaIspezioniSpecialiRischioIdraulico === 1 ? (
                  <View style={styles.rilevantAspects}>
                    <View
                      style={[
                        styles.iconElementContainer,
                        { backgroundColor: '#3260CE' },
                      ]}
                    >
                      <Image src={IdraulicoIcon} />
                    </View>

                    <Text style={styles.infoAspects}>Idraulico</Text>
                  </View>
                ) : (
                  <View style={styles.rilevantAspects}>
                    <View style={styles.iconElementContainer}>
                      <Image src={IdraulicoIcon} />
                    </View>
                    <Text style={[styles.infoAspects, { color: 'grey' }]}>
                      Idraulico
                    </Text>
                  </View>
                )}
              </View>
            </View>

            <View
              style={{
                width: 1,
                height: 50,
                backgroundColor: 'grey',
                marginLeft: 20,
              }}
            ></View>

            <View style={{ marginLeft: 30 }}>
              {pdfData?.interventiSuSovrastruttura === 1 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: '#3260CE' },
                    ]}
                  >
                    <Image
                      src={SettingIcon}
                      style={{ width: 6, height: 'auto' }}
                    />
                  </View>

                  <Text style={styles.infoAspects}>
                    Interventi su elementi accessori
                  </Text>
                </View>
              ) : (
                <View style={styles.rilevantAspects}>
                  <View style={styles.iconElementContainer}>
                    <Image
                      src={SettingIcon}
                      style={{ width: 6, height: 'auto' }}
                    />
                  </View>
                  <Text style={[styles.infoAspects, { color: 'grey' }]}>
                    Interventi su elementi accessori
                  </Text>
                </View>
              )}

              {pdfData?.interventiSuServizi === 1 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: '#3260CE' },
                    ]}
                  >
                    <Image
                      src={SettingIcon}
                      style={{ width: 6, height: 'auto' }}
                    />
                  </View>

                  <Text style={styles.infoAspects}>Interventi su servizi</Text>
                </View>
              ) : (
                <View style={styles.rilevantAspects}>
                  <View style={styles.iconElementContainer}>
                    <Image
                      src={SettingIcon}
                      style={{ width: 6, height: 'auto' }}
                    />
                  </View>
                  <Text style={[styles.infoAspects, { color: 'grey' }]}>
                    Interventi su servizi
                  </Text>
                </View>
              )}

              {pdfData?.attivitaMonitoraggioPregresseInCorso === 1 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: '#3260CE' },
                    ]}
                  >
                    <Image
                      src={Monitoring}
                      style={{ width: 6, height: 'auto' }}
                    />
                  </View>
                  <Text style={styles.infoAspects}>Monitoraggio Attivo</Text>
                </View>
              ) : (
                <View style={styles.rilevantAspects}>
                  <View style={styles.iconElementContainer}>
                    <Image
                      src={Monitoring}
                      style={{ width: 6, height: 'auto' }}
                    />
                  </View>
                  <Text style={[styles.infoAspects, { color: 'grey' }]}>
                    Monitoraggio Attivo
                  </Text>
                </View>
              )}
            </View>

            <View style={{ marginLeft: 30 }}>
              {pdfData?.limiteCaricoCarreggiataSingolaCorsia === 1 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: '#3260CE' },
                    ]}
                  >
                    <Image
                      src={WeigthHigh}
                      style={{ width: 6, height: 'auto' }}
                    />
                  </View>
                  <Text style={styles.infoAspects}>Limitazione di carico</Text>
                </View>
              ) : (
                <View style={styles.rilevantAspects}>
                  <View style={styles.iconElementContainer}>
                    <Image
                      src={WeigthHigh}
                      style={{ width: 6, height: 'auto' }}
                    />
                  </View>
                  <Text style={[styles.infoAspects, { color: 'grey' }]}>
                    Limitazione di carico
                  </Text>
                </View>
              )}

              {pdfData?.trasportiEccezionali === 1 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: '#3260CE' },
                    ]}
                  >
                    <Image
                      src={TruckMoving}
                      style={{ width: 6, height: 'auto' }}
                    />
                  </View>
                  <Text style={styles.infoAspects}>Trasporti eccezionali</Text>
                </View>
              ) : (
                <View style={styles.rilevantAspects}>
                  <View style={styles.iconElementContainer}>
                    <Image
                      src={TruckMoving}
                      style={{ width: 6, height: 'auto' }}
                    />
                  </View>
                  <Text style={[styles.infoAspects, { color: 'grey' }]}>
                    Trasporti eccezionali
                  </Text>
                </View>
              )}

              {pdfData?.trasportoMerciPericolose === 1 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: '#3260CE' },
                    ]}
                  >
                    <Image src={Danger} style={{ width: 6, height: 'auto' }} />
                  </View>
                  <Text style={styles.infoAspects}>
                    Trasporti merci pericolose
                  </Text>
                </View>
              ) : (
                <View style={styles.rilevantAspects}>
                  <View style={styles.iconElementContainer}>
                    <Image src={Danger} style={{ width: 6, height: 'auto' }} />
                  </View>
                  <Text style={[styles.infoAspects, { color: 'grey' }]}>
                    Trasporti merci pericolose
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>

        <View style={styles.container}>
          <Text style={styles.titleH2}>Parametri Primari e Secondari</Text>
          <View style={{ flexDirection: 'row' }}>
            {pdfData?.primaryParametersCart ? (
              <Image src={imageData2} style={{ marginBottom: 20 }} />
            ) : (
              <Image
                src={imageData2}
                style={{ width: '50%', marginBottom: 20 }}
              />
            )}
            {/* <Image src={imageData2} style={{ marginBottom: 20 }} />  */}
            <View
              style={[
                styles.infoSection,
                { marginRight: 40, marginBottom: 20 },
              ]}
            >
              {pdfData.evoluzioneDegrado === 0 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: 'transparent', width: 10, height: 10 },
                    ]}
                  >
                    <Image src={CheckGreenIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Evoluzione degrado{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`<= 1945`}</Text>
                  </Text>
                </View>
              ) : pdfData.evoluzioneDegrado === 1 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: 'transparent', width: 10, height: 10 },
                    ]}
                  >
                    <Image src={WarningAspectIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Evoluzione degrado{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`1945-1980`}</Text>
                  </Text>
                </View>
              ) : pdfData.evoluzioneDegrado === 2 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: 'transparent', width: 10, height: 10 },
                    ]}
                  >
                    <Image src={CriticalAspectIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Evoluzione degrado{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`>=1980`}</Text>
                  </Text>
                </View>
              ) : (
                <Text
                  style={[
                    styles.infoAspects,
                    { marginLeft: 16, marginBottom: 5 },
                  ]}
                >
                  Evoluzione degrado{' '}
                  <Text
                    style={[styles.infoData, { lineHeight: 0 }]}
                  >{`-`}</Text>
                </Text>
              )}

              {pdfData.alternativeStradali === 1 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      {
                        backgroundColor: 'transparent',
                        width: 10,
                        height: 10,
                      },
                    ]}
                  >
                    <Image src={CheckGreenIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Alternative stradali{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`Presenti`}</Text>
                  </Text>
                </View>
              ) : pdfData.alternativeStradali === 2 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: 'transparent', width: 10, height: 10 },
                    ]}
                  >
                    <Image src={CriticalAspectIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Alternative stradali{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`Assenti`}</Text>
                  </Text>
                </View>
              ) : (
                <Text
                  style={[
                    styles.infoAspects,
                    { marginLeft: 16, marginBottom: 5 },
                  ]}
                >
                  Alternative stradali{' '}
                  <Text
                    style={[styles.infoData, { lineHeight: 0 }]}
                  >{`-`}</Text>
                </Text>
              )}

              {pdfData.itinerarioEmergenza === 2 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: 'transparent', width: 10, height: 10 },
                    ]}
                  >
                    <Image src={CheckGreenIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Strategicità opera{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`No`}</Text>
                  </Text>
                </View>
              ) : pdfData.itinerarioEmergenza === 1 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: 'transparent', width: 10, height: 10 },
                    ]}
                  >
                    <Image src={CriticalAspectIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Strategicità opera{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`Sì`}</Text>
                  </Text>
                </View>
              ) : (
                <Text
                  style={[
                    styles.infoAspects,
                    { marginLeft: 16, marginBottom: 5 },
                  ]}
                >
                  Strategicità opera{' '}
                  <Text
                    style={[styles.infoData, { lineHeight: 0 }]}
                  >{`-`}</Text>
                </Text>
              )}

              {pdfData.categoriaSottosuoloAltro === 1 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: 'transparent', width: 10, height: 10 },
                    ]}
                  >
                    <Image src={CheckGreenIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Categoria sottosuolo{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`Effettiva`}</Text>
                  </Text>
                </View>
              ) : pdfData.categoriaSottosuoloAltro === 2 ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: 'transparent', width: 10, height: 10 },
                    ]}
                  >
                    <Image src={CriticalAspectIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Categoria sottosuolo{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`Presunta`}</Text>
                  </Text>
                </View>
              ) : (
                <Text
                  style={[
                    styles.infoAspects,
                    { marginLeft: 16, marginBottom: 5 },
                  ]}
                >
                  Categoria sottosuolo{' '}
                  <Text
                    style={[styles.infoData, { lineHeight: 0 }]}
                  >{`-`}</Text>
                </Text>
              )}

              {pdfData?.rischioIdraulico !== 2 &&
              pdfData.affidabilitaComplessivaValutazioneRischioIdraulico ===
                'Limitata' ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: 'transparent', width: 10, height: 10 },
                    ]}
                  >
                    <Image src={CriticalAspectIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Affidabilità modello idraulico{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`Limitata`}</Text>
                  </Text>
                </View>
              ) : pdfData?.rischioIdraulico !== 2 &&
                pdfData.affidabilitaComplessivaValutazioneRischioIdraulico ===
                  'Buona' ? (
                <View style={styles.rilevantAspects}>
                  <View
                    style={[
                      styles.iconElementContainer,
                      { backgroundColor: 'transparent', width: 10, height: 10 },
                    ]}
                  >
                    <Image src={CheckGreenIcon} />
                  </View>

                  <Text style={styles.infoAspects}>
                    Affidabilità modello idraulico{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`Buona`}</Text>
                  </Text>
                </View>
              ) : (
                pdfData?.rischioIdraulico !== 2 && (
                  <Text
                    style={[
                      styles.infoAspects,
                      { marginLeft: 16, marginBottom: 5 },
                    ]}
                  >
                    Affidabilità modello idraulico{' '}
                    <Text
                      style={[styles.infoData, { lineHeight: 0 }]}
                    >{`-`}</Text>
                  </Text>
                )
              )}
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Text style={styles.infoData}>
            {/* {`${formData?.denominazioneCommittente}, ${formData?.indirizzo}, ${formData?.cap}, ${formData?.citta} (${formData?.provincia})`} */}
            {`${
              formData?.denominazioneCommittente &&
              `${formData.denominazioneCommittente},`
            } ${formData?.indirizzo && `${formData.indirizzo},`} ${
              formData?.cap && `${formData.cap},`
            } ${formData?.citta && `${formData.citta},`} ${
              formData?.provincia && `(${formData.provincia})`
            }`}
          </Text>
          <Image style={{ width: '80pt' }} src={LogoExport} />
        </View>
        {/*  <View
          style={{ display: 'flex', flexDirection: 'row', marginTop: '-10px' }}
        >
          {formData?.denominazioneCommittente && (
            <Text
              style={[styles.infoData, { lineHeight: 0 }]}
            >{`${formData?.denominazioneCommittente}, `}</Text>
          )}
          {formData?.indirizzo && (
            <Text
              style={[styles.infoData, { lineHeight: 0 }]}
            >{`${formData?.indirizzo}, `}</Text>
          )}
          {formData?.cap && (
            <Text
              style={[styles.infoData, { lineHeight: 0 }]}
            >{`${formData?.cap}, `}</Text>
          )}
          {formData?.citta && (
            <Text
              style={[styles.infoData, { lineHeight: 0 }]}
            >{`${formData?.citta}, `}</Text>
          )}
          {formData?.provincia && (
            <Text
              style={[styles.infoData, { lineHeight: 0 }]}
            >{`(${formData?.provincia})`}</Text>
          )}
        </View> */}
      </Page>
    </Document>
  );
};

export { OverviewPdf };

OverviewPdf.propTypes = {
  imageData: PropTypes.string,
  imageData2: PropTypes.string,
  pdfData: PropTypes.object,
  formData: PropTypes.object,
};
