import { Avatar, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import addIcon from 'assets/icons/add.svg';
import mapPin from 'assets/icons/map-pin.svg';
import Tag from 'assets/icons/tag.svg';
import townHall from 'assets/icons/town-hall.svg';
import userCheck from 'assets/icons/user-check.svg';
import {
  ArrowBackAndTitle,
  Breadcrumbs,
  PermissionsPopover,
  PositionHeaderPonte,
} from 'components';

// import { getBreadcrumbsData } from 'routes/breadcrumbsMap';
import { apiCollaborators } from 'constants/api';
import { randomColor } from 'constants/colors';
import { PermissionTypeSelectOptions } from 'constants/permissions';
import { tour_03, tour_06 } from 'constants/tour';

import { useSubscription } from 'hooks';
import { compareObjectsPropertiesAlphabetically } from 'utils/utilities';
import {
  AvatarPlus,
  HeaderContainer,
  HeaderLine,
  HeaderLineItem,
} from './HeaderPage.style';

const ShareRouteTitleMap = {
  [apiCollaborators.STRUCTURE_COLLBAORATORS]: 'Condiviso con: ',
  [apiCollaborators.INSPECTIONS_COLLBAORATORS]: 'Modificabile da: ',
};

export const HeaderPage = ({
  buttons,
  infoData,
  title,
  back,
  sharedWith,
  handleShare,
  shareRoute,
  entityId,
  shareOptions,
  canShare = false,
  isOwner = false,
  dataBreadcrumbs,
  accessExpiration,
}) => {
  const { user } = useSubscription();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderOtherAvatar = () => {
    const otherShared = sharedWith?.length > 4 ? sharedWith.length - 4 : 0;

    return (
      <AvatarPlus
        variant="circular"
        className={`white ${tour_03.step_04} ${tour_06.step_02}`}
        onClick={handleClick}
      >
        <img src={addIcon} alt="" />
        {otherShared > 0 && <span>{otherShared}</span>}
      </AvatarPlus>
    );
  };

  const renderAvatar = () => {
    return sharedWith
      ?.sort(compareObjectsPropertiesAlphabetically)
      ?.reduce((acc, element) => {
        if (element?.id === user?.id || element?.id === user?.parent?.id) {
          return [element, ...acc];
        }
        return [...acc, element];
      }, [])
      .filter(
        el =>
          el?.collaborationInfo?.permission !==
          PermissionTypeSelectOptions.CanViewByInspectionReference
      )
      .map((el, index) => {
        const label = `${el?.name?.charAt(0)?.toUpperCase() || ''}${
          el?.surname?.charAt(0)?.toUpperCase() || ''
        }`;

        const item = randomColor[index];
        if (index < 4)
          return (
            <Avatar
              variant="circular"
              style={{
                backgroundColor: item.bg,
                border: `1px solid ${item.border}`,
                color: item.border,
              }}
              key={index}
            >
              {label}
            </Avatar>
          );
      });
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <HeaderContainer>
      <HeaderLine>
        <Breadcrumbs data={dataBreadcrumbs} />
      </HeaderLine>
      <HeaderLine>
        <HeaderLineItem>
          <ArrowBackAndTitle onClick={back} title={title} />
        </HeaderLineItem>
        <HeaderLineItem>{buttons}</HeaderLineItem>
      </HeaderLine>
      <HeaderLine>
        <HeaderLineItem>
          {infoData && (
            <>
              <PositionHeaderPonte image={townHall} title={infoData.city} />
              <PositionHeaderPonte image={mapPin} title={infoData.street} />
              <PositionHeaderPonte image={Tag} title={infoData.code} />
              <PositionHeaderPonte image={userCheck} title={infoData.owner} />
            </>
          )}
        </HeaderLineItem>
        <HeaderLineItem>
          {/* TODO: aggiungere condivisione utenti */}
          {sharedWith && (
            <Typography variant="body" sx={{ fontWeight: 'bold' }}>
              {ShareRouteTitleMap[shareRoute]}
            </Typography>
          )}
          {sharedWith && renderAvatar()}

          <PermissionsPopover
            handleClose={handleClose}
            open={open}
            id={id}
            anchorEl={anchorEl}
            sharedWith={sharedWith}
            handleShare={handleShare}
            shareRoute={shareRoute}
            entityId={entityId}
            canShare={canShare}
            isOwner={isOwner}
            shareOptions={shareOptions}
            accessExpiration={accessExpiration}
          />
          {sharedWith && renderOtherAvatar()}
        </HeaderLineItem>
      </HeaderLine>
    </HeaderContainer>
  );
};

HeaderPage.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.node,
  dataBreadcrumbs: PropTypes.array,
  infoData: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    code: PropTypes.string,
    owner: PropTypes.string,
  }),
  back: PropTypes.string,
  avatar: PropTypes.arrayOf(
    PropTypes.shape({
      initial: PropTypes.string,
      class: PropTypes.string,
    })
  ),
  sharedWith: PropTypes.array,
  shareOptions: PropTypes.array,
  handleShare: PropTypes.func,
  shareRoute: PropTypes.string,
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  canShare: PropTypes.bool,
  isOwner: PropTypes.bool,
  accessExpiration: PropTypes.string,
};
