import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Paper } from '@mui/material';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import * as yup from 'yup';

import {
  DrawerMUI,
  InfoBox,
  // Checkbox as CustomCheckbox,
  RHFDatePicker,
  RHFSelect,
} from 'components';
import { DrawerMediaTable } from 'components/DrawerMediaTable/DrawerMediaTable';
import PermissionContainer from 'components/PermissionsPopover/PermissionContainer';
import { apiBridges, apiCollaborators } from 'constants/api';
import { inspectionType } from 'constants/bridges';
import {
  FieldType,
  InspectionRouteActions,
  InspectionStatus,
  MediaGroup,
  UploadMedia,
} from 'constants/inspections';
import { STRUCTURES_PATHS } from 'constants/paths';
import {
  PermissionType,
  PermissionTypeSelectOptions,
} from 'constants/permissions';
import { tour_04 } from 'constants/tour';
import { FormProvider } from 'providers';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import { formatDataToSave, formatDatayMd } from 'utils/utilities';
import { BtnContainer, FormContainer } from './PanelForm.styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const InspectionShareOptions = PermissionTypeSelectOptions.filter(
  el => el.value !== PermissionType.CanView
);

const CreateInspectionErrorMessagesMap = {
  // TODO set value to other const
  1: `Errore! Prima di creare una nuova ispezione è necessario completare l'ispezione in corso`,
  2: `Errore! La data inserita deve essere uguale o superiore rispetto a quella dell'ultima ispezione`,
};

const DrawerNewInspection = ({ setOpen, open, isOwner }) => {
  const params = useParams();

  const id = params.structureId;
  const { get, post, response, loading } = useFetch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [inspectionCreated, setInspectionCreated] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [newInspectionId, setNewInspectionId] = useState();
  const [showArchiveWarning, setShowArchiveWarning] = useState(false);
  /*   const [openLocalTour, setOpenLocalTour] = useState(false);
   */
  const defaultValues = {
    type: '',
    date: '',
  };

  const schema = yup
    .object({
      type: yup.string().required('Tipologia di ispezione obbligatoria'),
      date: yup.string().nullable().required('Data obbligatoria'),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleCollaborators = async () => {
    const res = await get(
      `${apiCollaborators.ROOT}/inspection/${newInspectionId}`
    );
    setCollaborators(
      res
        .map(el => {
          return {
            ...el,
            collaborationInfo: {
              ...el?.sharedInspectionsWithMe[0],
              type: el?.collaboratorOf[0]?.type,
            },
          };
        })
        .filter(
          el => el.collaborationInfo?.permission === PermissionType.CanEdit
        )
    );
  };

  const onSubmit = async data => {
    if (!inspectionCreated) {
      const dateDb = formatDataToSave(data?.date);
      /* const dateDb = formatDatayMd(data?.date); */
      const dataUpdate = {
        type: Number(data.type),
        date: dateDb,
        involvedLevels: 'L0,L1,L2',
        sharedWith: [],
      };

      try {
        const result = await post(
          `${apiBridges.ROOT}/${id}/inspection`,
          dataUpdate
        );
        if (response.ok) {
          setInspectionCreated(true);
          setNewInspectionId(result.id);

          /*  navigate(
            `${STRUCTURES_PATHS.STRUCTURES_LIST}/${id}/inspection/${result.id}`
          ); */
        }
      } catch (err) {
        return enqueueSnackbar(
          CreateInspectionErrorMessagesMap[
            err.message.charAt(err.message.length - 1)
          ] || `Si è verificato un errore`,
          {
            variant: 'error',
          }
        );
      }
    } else {
      navigate(
        `${STRUCTURES_PATHS.STRUCTURES_LIST}/${id}/inspection/${newInspectionId}/${InspectionRouteActions.EDIT}`,
        {
          state: {
            inspectionLevelTab: inspectionTabMapping.L0,
            inspectionLevelSubTab: null,
            inspectionLevelSubTabAccordion: null,
          },
        }
      );
    }
  };

  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    newInspectionId && handleCollaborators();
  }, [newInspectionId]);

  useEffect(() => {
    const getLastInspectionStatus = async () => {
      try {
        const res = await get(
          `${apiBridges.ROOT}/${id}${apiBridges.LAST_INSPECTION_STATUS}`
        );
        setShowArchiveWarning(parseInt(res) === InspectionStatus.Confirmed);
      } catch (err) {
        return enqueueSnackbar(`Si è verificato un errore`, {
          variant: 'error',
        });
      }
    };
    id && getLastInspectionStatus();
  }, [id]);

  const watchSelectType = watch('type');
  const watchInspectionDate = watch('date');

  useEffect(() => {
    if (!open) {
      setValue('type', '');
      setInspectionCreated(false);
    }
  }, [open]);

  return (
    <>
      <DrawerMUI
        open={open}
        setOpen={setOpen}
        title={inspectionCreated ? 'Condividi attività' : 'Nuova attività'}
        subtitle={
          inspectionCreated
            ? "Indica gli utenti a cui è consentita la modifica dell'attività"
            : 'Scegli il tipo di attività che vuoi gestire in piattaforma'
        }
        // onClose={() => setFiles([])}
      >
        <FormContainer>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit, console.log)}
          >
            <Grid container spacing={1}>
              {!inspectionCreated && (
                <>
                  <Grid item xs={6}>
                    <RHFSelect
                      disabled={inspectionCreated}
                      name="type"
                      required
                      label={'Tipologia di attività'}
                      selectOptions={[
                        {
                          label: 'Schede L0,L1',
                          value: inspectionType.DIGITAL_INSPECTION,
                        },
                        {
                          label: 'Ispezione pregressa extra-piattaforma',
                          value:
                            inspectionType.PREVIOUS_INSPECTION_EXTRAPLATFORM,
                        },
                        // {
                        //   label: 'Ispezione pregressa extrapiattaforma',
                        //   value: inspectionType.PREVIOUS_INSPECTION_EXTRAPLATFORM,
                        // },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <RHFDatePicker
                      disabled={inspectionCreated}
                      label="Data*"
                      name="date"
                      errors={errors}
                    />
                  </Grid>
                </>
              )}
              {inspectionCreated && (
                <Grid item xs={12} className={`${tour_04.step_01}`}>
                  <StyledPaper>
                    <PermissionContainer
                      shareRoute={apiCollaborators.INSPECTIONS_COLLBAORATORS}
                      sharedWith={collaborators}
                      entityId={newInspectionId}
                      shareOptions={InspectionShareOptions}
                      handleShare={handleCollaborators}
                      isOwner={isOwner}
                      canShare
                    />
                  </StyledPaper>
                </Grid>
              )}
              {showArchiveWarning &&
                !inspectionCreated &&
                watchSelectType !==
                  inspectionType.PREVIOUS_INSPECTION_EXTRAPLATFORM && (
                  <Grid item xs={12}>
                    <InfoBox
                      color="primary.light"
                      text={
                        "Procedendo nella creazione di una nuova attività, l'ultima verrà automaticamente archiviata"
                      }
                    />
                  </Grid>
                )}
              {!inspectionCreated &&
                watchSelectType ===
                  inspectionType.PREVIOUS_INSPECTION_EXTRAPLATFORM && (
                  <Grid item xs={12}>
                    <DrawerMediaTable
                      setOpenDrawer={setOpen}
                      openDrawer={open}
                      dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
                      formName={'extrapiattaformaMedia'}
                      uploadType={UploadMedia.documents}
                      type={FieldType.documents}
                      inspectionType={watchSelectType}
                      inspectionDate={watchInspectionDate}
                      mediaGroup={[MediaGroup.IspezioneExtraPiattaforma]}
                      isExtrapiattaforma
                      tags={[
                        'Gestione',
                        'Fatture',
                        'Ordini',
                        'Computi',
                        'Corrispondenza',
                        'Contratti',
                        'Nomine',
                        'Incarichi',
                        'Contabilità',
                        'Fornitori',
                        'Preventivi',
                        'Convenzioni',
                        'Ordinanza',
                        'Indagini',
                        'Laboratorio',
                        'Ispettore',
                        'Prove',
                        'Prospetto',
                        'Amministrativo',
                        'Architettonico',
                        'Strutturale',
                        'Impianti',
                        'Progettista',
                        'Collaudatore',
                        'Certificato',
                        'Documento descrittivo con date',
                        'Documento grafico con date',
                        'Documento descrittivo',
                        'Documento grafico',
                        'Documento contrattuale e contabile',
                        'Volta in muratura',
                        'Riparazione/Sostituzione di elementi strutturali',
                        'Ampliamento di carreggiata e delle strutture',
                        'Elementi strutturali aggiuntivi/consolidamento',
                        'Interventi di carattere geotecnico in fondazione e/o in corrispondenza delle spalle',
                        `Interventi di mitigazione/protezione dall’erosione di spalle e pile`,
                      ]}
                    />
                  </Grid>
                )}
              <BtnContainer>
                {!inspectionCreated &&
                  watchSelectType !==
                    inspectionType.PREVIOUS_INSPECTION_EXTRAPLATFORM && (
                    <>
                      <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        Annulla
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="small"
                        disabled={loading}
                      >
                        Crea attività
                      </Button>
                    </>
                  )}
                {inspectionCreated && (
                  <>
                    {/* <Button
                      onClick={() => setOpenLocalTour(true)}
                      variant="secondary"
                      size="small"
                      style={{
                        border: '1px solid',
                        marginRight: 'auto',
                        marginLeft: '8px',
                      }}
                    >
                      <img style={{ width: '14px' }} src={lightbulb} />
                    </Button> */}
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setInspectionCreated(false);
                        setCollaborators([]);
                        setNewInspectionId();
                        setOpen(false);
                        navigate(
                          STRUCTURES_PATHS.INSPECTIONS_ARCHIVE.replace(
                            ':structureId',
                            id
                          )
                        );
                      }}
                    >
                      Archivio attività
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      size="small"
                    >
                      Apri attività
                    </Button>
                  </>
                )}
              </BtnContainer>
            </Grid>
          </FormProvider>
        </FormContainer>
      </DrawerMUI>
    </>
  );
};
DrawerNewInspection.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

export { DrawerNewInspection };
