import { Table } from '@mui/material';
import PropTypes from 'prop-types';

import { DataGridBody } from './DataGridBody';
import { DataGridHeader } from './DataGridHeader';
import { DataGridPagination } from './DataGridPagination';

const DataGrid = ({
  rows,
  columns,
  orderBy,
  direction,
  onSort,
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  pagination,
  rowColor,
  isAdmin,
}) => {
  return (
    <Table variant="data-grid">
      <DataGridHeader
        columns={columns}
        orderBy={orderBy}
        direction={direction}
        onSort={onSort}
        isAdmin={isAdmin}
      />
      <DataGridBody rowColor={rowColor} columns={columns} rows={rows} />
      {pagination && (
        <DataGridPagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </Table>
  );
};

DataGrid.propTypes = {
  pagination: PropTypes.bool,
  ...DataGridBody.propTypes,
  ...DataGridHeader.propTypes,
  ...DataGridPagination.propTypes,
  isAdmin: PropTypes.bool,
};

export default DataGrid;
