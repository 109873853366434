import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { InfoWrapper, MediaUpload, RHFCheckbox, RHFSelect } from 'components';
import {
  AerosolMarini,
  CategoriaSottosuolo,
  CategoriaTopografica,
  EffettivoPresunto,
  FieldType,
  MaggioreDegrado,
  MediaGroup,
  UnitaFisiografica,
  UploadMedia,
} from 'constants/inspections';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import {
  CheckboxContainer,
  GridWrap,
} from './Accordion9GeomorphologicalData.styles';
import { INFO } from '../../../../config/info';

const Accordion9GeomorphologicalData = () => {
  const { setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchAerosolMarini = watch('aerosolMarini');
  const watchAerosolMariniSi = watchAerosolMarini === AerosolMarini.Si;

  // Start Effettivo Presunto
  const watchAerosolMarinoEffettivo = watch('aerosolMarinoEffettivo');
  const watchAerosolMarinoPresunto = watch('aerosolMarinoPresunto');

  useEffect(() => {
    if (!watchAerosolMarinoEffettivo && !watchAerosolMarinoPresunto) {
      setValue('maggioreDegradoAltro', null);
    }
    if (watchAerosolMarinoEffettivo) {
      setValue('maggioreDegradoAltro', EffettivoPresunto.Effettivo);
    } else if (watchAerosolMarinoPresunto) {
      setValue('maggioreDegradoAltro', EffettivoPresunto.Presunto);
    }
  }, [watchAerosolMarinoEffettivo, watchAerosolMarinoPresunto]);

  const watchCategoriaSottosuoloEffettiva = watch(
    'catagoriaSottosuoloEffettiva'
  );
  const watchCategoriaSottosuoloPresunta = watch('categoriaSottosuoloPresunta');

  useEffect(() => {
    if (
      !watchCategoriaSottosuoloEffettiva &&
      !watchCategoriaSottosuoloPresunta
    ) {
      setValue('categoriaSottosuoloAltro', null);
    }
    if (watchCategoriaSottosuoloEffettiva) {
      setValue('categoriaSottosuoloAltro', EffettivoPresunto.Effettivo);
    } else if (watchCategoriaSottosuoloPresunta) {
      setValue('categoriaSottosuoloAltro', EffettivoPresunto.Presunto);
    }
  }, [watchCategoriaSottosuoloEffettiva, watchCategoriaSottosuoloPresunta]);
  // End Effettivo Presunto

  //Functions
  useEffect(() => {
    if (!watchAerosolMariniSi) {
      setValue('maggioreDegrado', '');
      setValue('aerosolMarinoEffettivo', false);
      setValue('aerosolMarinoPresunto', false);
    }
  }, [watchAerosolMarini]);

  return (
    <GridWrap container spacing={2} padding="20px" marginTop={-40}>
      <InfoWrapper
        size={3}
        infoMessage={INFO.unitaFisiografica.infoMessage}
        quoteMessage={INFO.unitaFisiografica.quoteMessage}
      >
        <RHFSelect
          name="unitaFisiografica"
          label={'Unità fisiografica'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'Montuosa',
              value: UnitaFisiografica.Montuosa,
            },
            {
              label: 'Collinare',
              value: UnitaFisiografica.Collinare,
            },
            {
              label: 'Pianura intermontana',
              value: UnitaFisiografica.PianuraIntermontana,
            },
            {
              label: 'Pianura bassa',
              value: UnitaFisiografica.PianuraBassa,
            },
          ]}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        legislationMessage={INFO.categoriaTopografica.legislationMessage}
      >
        <RHFSelect
          name="categoriaTopografica"
          label={'Morfologia/Categoria topografica*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'Pianura (T1)',
              value: CategoriaTopografica.Pianura,
            },
            {
              label: 'Pianura alla base dei versanti (T1)',
              value: CategoriaTopografica.PianuraVersanti,
            },
            {
              label: 'Pendio dolce (0 - 10°) (T1)',
              value: CategoriaTopografica.PendioDolce,
            },
            {
              label: 'Pendio moderato (10° - 25°) (T2)',
              value: CategoriaTopografica.PendioModerato,
            },
            {
              label: 'Pendio ripido (>25°) (T3)',
              value: CategoriaTopografica.PendioRipido,
            },
            {
              label: 'Cresta (T4)',
              value: CategoriaTopografica.Cresta,
            },
          ]}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.categoriaSottosuolo.drawerTitle}
        drawerText={INFO.categoriaSottosuolo.drawerText}
        legislationMessage={INFO.categoriaSottosuolo.legislationMessage}
      >
        <RHFSelect
          name="categoriaSottosuolo"
          label={'Categoria di sottosuolo*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'A',
              value: CategoriaSottosuolo.A,
            },
            {
              label: 'B',
              value: CategoriaSottosuolo.B,
            },
            {
              label: 'C',
              value: CategoriaSottosuolo.C,
            },
            {
              label: 'D',
              value: CategoriaSottosuolo.D,
            },
            {
              label: 'E',
              value: CategoriaSottosuolo.E,
            },
          ]}
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <CheckboxContainer>
          <RHFCheckbox
            name="catagoriaSottosuoloEffettiva"
            label="Effettiva"
            disabled={
              watchCategoriaSottosuoloPresunta ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
          <RHFCheckbox
            name="categoriaSottosuoloPresunta"
            label="Presunta"
            disabled={
              watchCategoriaSottosuoloEffettiva ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
        </CheckboxContainer>
      </GridWrap>
      <InfoWrapper
        size={3}
        infoMessage={INFO.aerosolMarini.infoMessage}
        legislationMessage={INFO.aerosolMarini.legislationMessage}
      >
        <RHFSelect
          name="aerosolMarini"
          label={'Aerosol marini e sali antigelo*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'Sì',
              value: AerosolMarini.Si,
            },
            {
              label: 'No',
              value: AerosolMarini.No,
            },
          ]}
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="maggioreDegrado"
          label={'Tali da determinare una maggiore rapidità del degrado*'}
          defaultValue={''}
          disabled={
            !watchAerosolMariniSi || inspectionDisabled || isViewingInspection
          }
          selectOptions={[
            {
              label: 'Sì',
              value: MaggioreDegrado.Si,
            },
            {
              label: 'No',
              value: MaggioreDegrado.No,
            },
          ]}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <CheckboxContainer>
          <RHFCheckbox
            name="aerosolMarinoEffettivo"
            label="Effettivo"
            disabled={
              !watchAerosolMariniSi ||
              watchAerosolMarinoPresunto ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
          <RHFCheckbox
            name="aerosolMarinoPresunto"
            label="Presunto"
            disabled={
              !watchAerosolMariniSi ||
              watchAerosolMarinoEffettivo ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
        </CheckboxContainer>
      </GridWrap>
      <GridWrap item xs={3}></GridWrap>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Stralcio cartografico`}
          btnTitle="Allega file"
          formName="stralcioCartografico"
          dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
        />
      </GridWrap>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Satellitare /Foto aeree storiche`}
          btnTitle="Allega file"
          formName="satellitareFotoAereeStoriche"
          dataType=".jpeg,.png"
          preview={true}
          uploadType={UploadMedia.media}
          type={FieldType.images}
          tags={[
            'instabilità planimetrica',
            'accumuli piante e detriti',
            'alveo intrecciato',
            'alveo sinuoso',
            'alveo meandriforme',
            'alveo rettilineo',
          ]}
          mediaGroup={[MediaGroup.Contesto]}
        />
      </GridWrap>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Satellitare /Foto aeree attuale`}
          btnTitle="Allega file"
          formName="satellitareFotoAereeAttuale"
          dataType=".jpeg,.png"
          preview={true}
          uploadType={UploadMedia.media}
          type={FieldType.images}
          tags={[
            'instabilità planimetrica',
            'accumuli piante e detriti',
            'alveo intrecciato',
            'alveo sinuoso',
            'alveo meandriforme',
            'alveo rettilineo',
          ]}
          mediaGroup={[MediaGroup.Contesto]}
        />
      </GridWrap>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Riprese fotografiche`}
          btnTitle="Allega file"
          formName="ripreseFotografiche"
          dataType=".jpeg,.png"
          preview={true}
          uploadType={UploadMedia.media}
          type={FieldType.images}
          mediaGroup={[MediaGroup.Contesto]}
        />
      </GridWrap>
    </GridWrap>
  );
};

export { Accordion9GeomorphologicalData };
