import { Button, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useFetch } from 'use-http';
import { DrawerMUI, RHFTextField } from 'components';
import { apiBridges, apiResponseStatus } from 'constants/api';
import { colors } from 'constants/colors';
import { FormProvider } from 'providers';
import { useGlobalSelector } from 'stores';
import { ChangeOwnershipErrorMap, ModeState } from './constants';

const WhiteContainer = styled('div')({
  width: '100%',
  borderRadius: 8,
  backgroundColor: colors.WHITE,
  padding: 24,
  marginBottom: 20,
});

const ModeTitleMap = {
  [ModeState.ASSIGN]: 'Assegna Opere',
  [ModeState.TRANSFER]: 'Modifica proprietario Opere',
};
const ModeSubtitleMap = {
  [ModeState.ASSIGN]:
    "L'utente specificato sarà impostato come proprietario ed otterrà il diritto di modifica per le opere indicate.",
  [ModeState.TRANSFER]:
    "L'utente specificato sarà impostato come proprietario ed otterrà il diritto di modifica per le opere indicate.",
};
const ModeCodesLableMap = {
  [ModeState.ASSIGN]:
    'Codice Interno ID opera da assegnare (se multipli riportarli andando a capo)*',
  [ModeState.TRANSFER]:
    'Codice Interno ID opere da modificare (se multipli riportarli andando a capo)*',
};

const DrawerChangeStructureOwnership = ({
  open,
  setOpen,
  mode,
  userMail,
  structureCode,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { put } = useFetch();

  const defaultValues = {
    codes: '',
    mail: '',
  };

  const methods = useForm(defaultValues);

  const { handleSubmit, setValue } = methods;

  const onSubmit = async data => {
    data.codes = data.codes.split('\n');
    try {
      showLoader();
      const res = await put(`${apiBridges.CHANGE_OWNER}`, {
        internalCodes: data.codes,
        newOwnerEmail: data.mail,
      });
      setValue('codes', '');
      setOpen(false);
      if (res?.status === apiResponseStatus.ERROR) {
        return enqueueSnackbar(
          ChangeOwnershipErrorMap[res?.exception?.errorMessage] ||
            `Si è verificato un errore`,
          {
            variant: 'error',
          }
        );
      } else {
        return enqueueSnackbar(`Operazione conclusa con successo`, {
          variant: 'success',
        });
      }
    } catch (err) {
      return enqueueSnackbar(`Si è verificato un errore`, {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (mode === ModeState.ASSIGN) {
      setValue('mail', userMail);
      setValue('codes', '');
    }
    if (mode === ModeState.TRANSFER) {
      setValue('mail', '');
      setValue('codes', structureCode);
    }
  }, [mode, structureCode]);

  return (
    <DrawerMUI open={open} setOpen={setOpen} title={ModeTitleMap[mode]}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ py: 2 }} spacing={2}>
          <Grid item xs={12}>
            <Typography>{ModeSubtitleMap[mode]}</Typography>
          </Grid>
        </Grid>
        <WhiteContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RHFTextField
                label={ModeCodesLableMap[mode]}
                multiline
                rows={4}
                name="codes"
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                label="Email nuovo proprietario (utente principale)*"
                name="mail"
                rules={{
                  required: 'Email obbligatoria',
                  pattern: {
                    // eslint-disable-next-line no-useless-escape
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/,
                    message: 'Inserire una email valida',
                  },
                }}
                disabled={mode === ModeState.ASSIGN}
              />
            </Grid>
            <Grid item xs={12} textAlign="end">
              <Button color="secondary" onClick={() => setOpen(false)}>
                Annulla
              </Button>
              <Button type="submit" variant="containedBlack">
                Trasferisci Opere
              </Button>
            </Grid>
          </Grid>
        </WhiteContainer>
      </FormProvider>
    </DrawerMUI>
  );
};

DrawerChangeStructureOwnership.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  mode: PropTypes.number,
  userMail: PropTypes.string,
  structureCode: PropTypes.string,
};

export default DrawerChangeStructureOwnership;
