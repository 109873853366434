import { Typography } from '@mui/material';

/**
 * Used for create dynamic className of the tours of the "Copia" button in L0/Struttura
 * @param {string} identificatorClassName
 * @returns {[] steps}
 */
export const generateCopySteps = identificatorClassName => {
  return [
    {
      selector: `.${identificatorClassName}`,
      content: () => {
        return (
          <Typography>
            Seleziona l&apos;elemento di cui vuoi duplicare i dati e clicca sul
            tasto `&quot;Copia`&quot;
          </Typography>
        );
      },
    },
  ];
};
/**
 * Used for create dynamic className of the tours of the "Copia" button in L1/Struttura
 * @param {string} identificatorClassName
 * @returns {[] steps}
 */
export const generateCopySchedaDifettiSteps = identificatorClassName => {
  return [
    {
      selector: `.${identificatorClassName}`,
      content: () => {
        return (
          <Typography>
            Clicca qui per copiare i dati della scheda corrente su un altro
            elemento
          </Typography>
        );
      },
    },
  ];
};
