import { useCallback } from 'react';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { STORE_PREFIX } from 'constants/globals';

const useStore = create(
  persist(
    set => ({
      loading: false,
      isProfessional: false,
      sidebarClose: false,
      sidebarVoiceMenu: 1,
      siderAdminVoiceMenu: 1,
      requestedLocation: null,
      activeTabLevel: 1,
      showLoader: () => set(() => ({ loading: true })),
      hideLoader: () => set(() => ({ loading: false })),
    }),
    {
      name: `${STORE_PREFIX}global`,
      getStorage: () => sessionStorage,
      partialize: state =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !['activeTabLevel'].includes(key)
          )
        ),
    }
  )
);

const useGlobalSelector = () => useStore(useCallback(state => state, []));

export { useGlobalSelector, useStore as useGlobalStore };
