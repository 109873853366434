export const markdown_faq_28_1 = `In alcuni casi potrebbe essere necessario duplicare una struttura, come ad esempio quando un’unica sede stradale è sorretta da due o più manufatti indipendenti da un punto di vista strutturale.

ATTENZIONE: Questa funzione è disponibile solo per utenti con una sottoscrizione attiva.

**PER DUPLICARE UN PONTE**
1. Nella scheda del ponte, selezionare *Duplica ponte*
2. Nel pannello di creazione del nuovo ponte verranno visualizzati alcuni campi già precompilati, definire il codice interno
3. Selezionare *Aggiungi opera*.

Verrà generato un nuovo ponte. Avviando un'attività per questo ponte troverei i campi del Livello 0 già compilati, sulla base dei dati presenti nel ponte sorgente al momento della creazione dell'attività.`;
