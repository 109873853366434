import PropTypes from 'prop-types';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from 'components';
import { INFO } from 'pages/Inspections/config/info';
const InspectionInfoForm = ({
  tecnicoRilevatoreName,
  dateName,
  elementoCriticoStrutturaName,
  elementoCriticoSismicaName,
  condizioneCriticaStrutturaName,
  condizioneCriticaSismicaName,
  noteGeneraliName,
  onSubmit,
  onReset,
}) => {
  const options = [
    { value: 1, label: 'Si' },
    { value: 2, label: 'No' },
  ];

  return (
    <>
      <GridWrap item xs={3}>
        <RHFTextField
          name={tecnicoRilevatoreName}
          label={'Tecnico rilevatore'}
          inputProps={{ maxLength: 100 }}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFDatePicker name={dateName} label={'Data ispezione'} />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        compassMessage={INFO.elementoStrutturaleCriticoInfo.compassMessage}
        drawerTitle={INFO.elementoStrutturaleCriticoInfo.drawerTitle}
        drawerText={INFO.elementoStrutturaleCriticoInfo.drawerText}
      >
        <RHFSelect
          name={elementoCriticoStrutturaName}
          label="Elemento Critico - Struttura"
          defaultValue={''}
          selectOptions={options}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        compassMessage={INFO.elementoSismicoCriticoInfo.compassMessage}
        drawerTitle={INFO.elementoSismicoCriticoInfo.drawerTitle}
        drawerText={INFO.elementoSismicoCriticoInfo.drawerText}
      >
        <RHFSelect
          name={elementoCriticoSismicaName}
          label="Elemento Critico - Sismica"
          defaultValue={''}
          selectOptions={options}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.condizioneStrutturaleCriticaInfo.drawerTitle}
        drawerText={INFO.condizioneStrutturaleCriticaInfo.drawerText}
        legislationMessage={
          INFO.condizioneStrutturaleCriticaInfo.legislationMessage
        }
      >
        <RHFSelect
          name={condizioneCriticaStrutturaName}
          label="Condizione Critica - Struttura"
          defaultValue={''}
          selectOptions={options}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        legislationMessage={
          INFO.condizioneSismicaCriticaInfo.legislationMessage
        }
        infoDrawer={true}
        drawerTitle={INFO.condizioneSismicaCriticaInfo.drawerTitle}
        drawerText={INFO.condizioneSismicaCriticaInfo.drawerText}
      >
        <RHFSelect
          name={condizioneCriticaSismicaName}
          label="Condizione Critica - Sismica"
          defaultValue={''}
          selectOptions={options}
        />
      </InfoWrapper>
      <GridWrap item xs={6}>
        <RHFTextField
          name={noteGeneraliName}
          label={'Note Generali'}
          inputProps={{ maxLength: 1000 }}
        />
      </GridWrap>
      <GridWrap item xs={12}>
        <FormSubmitButtons onSubmit={onSubmit} onReset={onReset} />
      </GridWrap>
    </>
  );
};
InspectionInfoForm.propTypes = {
  tecnicoRilevatoreName: PropTypes.string,
  dateName: PropTypes.string,
  elementoCriticoStrutturaName: PropTypes.string,
  elementoCriticoSismicaName: PropTypes.string,
  condizioneCriticaStrutturaName: PropTypes.string,
  condizioneCriticaSismicaName: PropTypes.string,
  noteGeneraliName: PropTypes.string,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
};
export default InspectionInfoForm;
