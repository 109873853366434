import { styled } from '@mui/system';

export const FormContainer = styled('div')(({ theme }) => ({
  marginLeft: -16,
  marginTop: 8,
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
  },
}));

export const CheckBoxContainer = styled('div')(({ theme }) => ({
  marginLeft: 30,
  textAlign: 'left',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    marginBottom: 25,
  },
}));

export const PrivacyPolicyBox = styled('div')({
  position: 'absolute',
  top: -3,
  left: 16,
});

export const BtnContainer = styled('div')(({ isProfessional }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 30,
  width: !isProfessional ? 320 : 250,
  marginLeft: 20,
}));
