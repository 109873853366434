import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import classeA from 'assets/icons/classeA.svg';
import classeABlu from 'assets/icons/classeABlu.svg';
import classeB from 'assets/icons/classeB.svg';
import classeBBlu from 'assets/icons/classeBBlu.svg';
import classeC from 'assets/icons/classeC.svg';
import classeCBlu from 'assets/icons/classeCBlu.svg';
import iconClassA from 'assets/icons/iconClassA.svg';
import iconClassB from 'assets/icons/iconClassB.svg';
import iconClassC from 'assets/icons/iconClassC.svg';
import { CardOverview } from 'components';
import { PlanningBox } from 'components/PlanningBox/PlanningBox';
import { colors } from 'constants/colors';
import { STRUCTURES_PATHS } from 'constants/paths';
import { ClassificazioneUsoStradaleOptions } from 'constants/selectOptions';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import { handleDynamicInspectionsTabChange } from 'utils/utilities';
import {
  InfoBox,
  InterventionBox,
  InterventionBoxContainer,
  ProjectClassRow,
  Row,
} from '../TabL0.styles';

const Contesto = ({ overviewL0 }) => {
  const params = useParams();
  const id = params.structureId;
  const navigate = useNavigate();

  const lastInspectionId = overviewL0?.requestUserLastValidInspectionId;

  const handleEnum = (enumOptions, val) => {
    return enumOptions[val - 1]?.label;
  };

  // Rete Stradale
  const classificazioneStradale = handleEnum(
    ClassificazioneUsoStradaleOptions,
    overviewL0?.reteStradale?.classificazioneUsoStradale
  );

  let categoriaPonte;

  switch (overviewL0?.reteStradale?.categoriaPonte) {
    case 1:
      categoriaPonte = 'Categoria I';
      break;
    case 2:
      categoriaPonte = 'Categoria II';
      break;
  }
  const nCarreggiate = overviewL0?.reteStradale?.nCarreggiate;
  const nCorsieCarreggiata = overviewL0?.reteStradale?.nCorsieCarreggiata;
  const trasportoEccezionale = overviewL0?.reteStradale?.trasportoEccezionale;
  const limitazioneCarico = overviewL0?.reteStradale?.limitazioneCarico;

  // Contesto ambientale
  const rischioFrana = overviewL0?.contestoAmbientale?.rischioFrana;
  const rischioIdraulico = overviewL0?.contestoAmbientale?.rischioIdraulio;

  // Progetto
  const classeProgettazione = overviewL0?.progetto?.classeDiProgettazione;
  let classeProgettazioneA;
  let classeProgettazioneB;
  let classeProgettazioneC;

  switch (classeProgettazione) {
    case 1:
      classeProgettazioneA = 1;
      break;

    case 2:
      classeProgettazioneB = 1;
      break;

    case 3:
      classeProgettazioneC = 1;
      break;
  }

  const interventiSignificativiManutenzione =
    overviewL0?.progetto?.interventiSignificativiDataApprossimativa;
  let before1952;
  let between1952and1990;
  let after1990;

  switch (interventiSignificativiManutenzione) {
    case 1:
      before1952 = 1;
      break;

    case 2:
      between1952and1990 = 1;
      break;

    case 3:
      after1990 = 1;
      break;
  }

  // Attività pregresse
  const verificaStatica = overviewL0?.attivitaPregresse?.verificaStatica;
  const verificaSismica = overviewL0?.attivitaPregresse?.verificaSismica;
  const ispezioniPregresse = overviewL0?.attivitaPregresse?.ispezioniPregresse;
  const segnalazioni = overviewL0?.attivitaPregresse?.segnalazioni;
  const interventiStrutturali =
    overviewL0?.attivitaPregresse?.interventiStrutturali;
  const attivitaMonitoraggio =
    overviewL0?.attivitaPregresse?.attivitaMonitoraggio;
  const interventiManutenzione =
    overviewL0?.attivitaPregresse?.interventiManutenzione;

  const renderReteStradaleInfo = () => {
    return (
      <Row gap={'4px'}>
        <Typography variant="h3">
          {classificazioneStradale ? `${classificazioneStradale} - ` : ''}
        </Typography>
        <Typography variant="h3">
          {categoriaPonte ? `${categoriaPonte} - ` : ''}
        </Typography>
        <Typography variant="h3">
          {nCarreggiate > 1
            ? `${nCarreggiate} Carreggiate - `
            : nCarreggiate === 1
            ? `${nCarreggiate} Carreggiata - `
            : ''}
        </Typography>
        <Typography variant="h3">
          {nCorsieCarreggiata > 1
            ? `${nCorsieCarreggiata} Corsie`
            : nCorsieCarreggiata === 1
            ? `${nCorsieCarreggiata} Corsia`
            : ''}
        </Typography>
      </Row>
    );
  };

  return (
    <CardOverview
      title={'Contesto'}
      subtitle={`Inquadramento generale dell'opera ed attività pregresse`}
      buttonText={'Approfondisci'}
      /* onClick={() => setValue(3)} */
      buttonDisabled={lastInspectionId ? false : true}
      onClick={() =>
        handleDynamicInspectionsTabChange(
          inspectionTabMapping.L0,
          inspectionTabMapping.DatiGenerali,
          null,
          navigate,
          STRUCTURES_PATHS.STRUCTURES_LIST,
          id,
          lastInspectionId
        )
      }
      height="auto"
    >
      <Row>
        <CardOverview
          title={'Rete stradale'}
          buttonText={'Approfondisci'}
          /* onClick={() => setValue(3)} */
          buttonDisabled={overviewL0?.lastInspectionId ? false : true}
          height="auto"
          subCard={true}
          onClick={() =>
            handleDynamicInspectionsTabChange(
              inspectionTabMapping.L0,
              inspectionTabMapping.DatiGenerali,
              inspectionTabMapping.L0CollegamentoReteStradale,
              navigate,
              STRUCTURES_PATHS.STRUCTURES_LIST,
              id,
              lastInspectionId
            )
          }
        >
          <InfoBox style={{ marginTop: 5 }}>
            {nCarreggiate || nCorsieCarreggiata ? (
              renderReteStradaleInfo()
            ) : (
              <Typography variant="h3">{'n.d.'}</Typography>
            )}
          </InfoBox>
          <Row style={{ marginTop: 16 }}>
            <PlanningBox
              topic="Trasporto eccezionale"
              status={trasportoEccezionale}
              statusMessage={
                trasportoEccezionale === 1 ? 'Presente' : 'Assente'
              }
              hideArrow={true}
            />
            <PlanningBox
              topic="Limitazioni di carico"
              status={limitazioneCarico}
              statusMessage={limitazioneCarico === 1 ? 'Presente' : 'Assente'}
              hideArrow={true}
            />
          </Row>
        </CardOverview>

        <CardOverview
          title={'Contesto ambientale'}
          buttonText={'Approfondisci'}
          buttonDisabled={overviewL0 ? false : true}
          height="auto"
          subCard={true}
          onClick={() =>
            handleDynamicInspectionsTabChange(
              inspectionTabMapping.L0,
              inspectionTabMapping.DatiGenerali,
              null,
              navigate,
              STRUCTURES_PATHS.STRUCTURES_LIST,
              id,
              lastInspectionId
            )
          }
        >
          <InfoBox style={{ marginTop: 5 }}>
            {overviewL0?.contestoAmbientale?.categoriaSottosuolo ? (
              <Typography variant="h3">{''}</Typography>
            ) : (
              <Typography variant="h3">{'n.d.'}</Typography>
            )}
          </InfoBox>
          <Row style={{ marginTop: 16 }}>
            <PlanningBox
              topic="Rischio frana"
              status={rischioFrana}
              statusMessage={
                rischioFrana === 1
                  ? 'Presente'
                  : rischioFrana === 2
                  ? 'Assente'
                  : rischioFrana === 3 && 'Da verificare'
              }
              hideArrow={true}
            />
            <PlanningBox
              topic="Rischio idraulico"
              status={rischioIdraulico}
              statusMessage={
                rischioIdraulico === 1
                  ? 'Presente'
                  : rischioIdraulico === 2
                  ? 'Assente'
                  : rischioIdraulico === 3 && 'Da verificare'
              }
              hideArrow={true}
            />
          </Row>
        </CardOverview>
      </Row>

      <Row>
        <CardOverview
          title={'Progetto'}
          buttonText={'Approfondisci'}
          buttonDisabled={overviewL0 ? false : true}
          height="auto"
          subCard={true}
          onClick={() =>
            handleDynamicInspectionsTabChange(
              inspectionTabMapping.L0,
              inspectionTabMapping.DatiGenerali,
              inspectionTabMapping.L0DatiProgetto,
              navigate,
              STRUCTURES_PATHS.STRUCTURES_LIST,
              id,
              lastInspectionId
            )
          }
        >
          <InfoBox>
            <Typography variant="h3">{'Classe di progettazione'}</Typography>
            <Row style={{ marginTop: 20 }}>
              <Stack>
                <ProjectClassRow>
                  <img src={classeProgettazioneA ? classeABlu : classeA} />
                </ProjectClassRow>
                <ProjectClassRow>
                  <img src={classeProgettazioneB ? classeBBlu : classeB} />
                </ProjectClassRow>
                <ProjectClassRow>
                  <img src={classeProgettazioneC ? classeCBlu : classeC} />
                </ProjectClassRow>
              </Stack>
              <Stack style={{ marginTop: -5 }}>
                <ProjectClassRow overviewL0={classeProgettazioneA}>
                  <Typography variant="h3">{'Classe A'}</Typography>
                  {classeProgettazioneA && <img src={iconClassA} />}
                </ProjectClassRow>
                <ProjectClassRow overviewL0={classeProgettazioneB}>
                  <Typography variant="h3">{'Classe B'}</Typography>
                  {classeProgettazioneB && <img src={iconClassB} />}
                </ProjectClassRow>
                <ProjectClassRow overviewL0={classeProgettazioneC}>
                  <Typography variant="h3">{'Classe C'}</Typography>
                  {classeProgettazioneC && <img src={iconClassC} />}
                </ProjectClassRow>
              </Stack>
            </Row>

            <Typography
              variant="h3"
              style={{ margin: '30px 0px', color: colors.DARKGREY }}
            >
              {'Interventi significativi manutenzione'}
            </Typography>
            <InterventionBoxContainer>
              <InterventionBox overviewL0={before1952} />
              <InterventionBox overviewL0={between1952and1990} />
              <InterventionBox overviewL0={after1990} />
            </InterventionBoxContainer>
            <Stack
              flexDirection={'row'}
              justifyContent={'space-evenly'}
              sx={{ marginTop: '4px' }}
            >
              <Typography variant="h3">{'1952'}</Typography>
              <Typography variant="h3">{'1990'}</Typography>
            </Stack>
          </InfoBox>
        </CardOverview>

        <CardOverview
          title={'Attività pregresse'}
          buttonText={'Approfondisci'}
          buttonDisabled={overviewL0 ? false : true}
          height="auto"
          subCard={true}
          onClick={() =>
            handleDynamicInspectionsTabChange(
              inspectionTabMapping.L0,
              inspectionTabMapping.DatiGenerali,
              inspectionTabMapping.L0AttivitaPregresse,
              navigate,
              STRUCTURES_PATHS.STRUCTURES_LIST,
              id,
              lastInspectionId
            )
          }
        >
          <Row style={{ marginTop: 16 }}>
            <PlanningBox
              topic="Verifica Statica"
              status={verificaStatica}
              statusMessage={verificaStatica === 1 ? 'Presente' : 'Assente'}
              noIcon={true}
              hideArrow={true}
            />
            <PlanningBox
              topic="Verifica Sismica"
              status={verificaSismica}
              statusMessage={verificaSismica === 1 ? 'Presente' : 'Assente'}
              noIcon={true}
              hideArrow={true}
            />
            <PlanningBox
              topic="Ispezioni Pregresse"
              status={ispezioniPregresse}
              statusMessage={
                ispezioniPregresse === 1
                  ? 'Presenti'
                  : ispezioniPregresse === 3
                  ? 'Non note'
                  : 'Assenti'
              }
              noIcon={true}
              hideArrow={true}
            />
          </Row>
          <Row style={{ marginTop: 16 }}>
            <PlanningBox
              topic="Segnalazioni"
              status={segnalazioni}
              statusMessage={
                segnalazioni === 1
                  ? 'Presenti'
                  : segnalazioni === 3
                  ? 'Non note'
                  : 'Assenti'
              }
              noIcon={true}
              hideArrow={true}
            />
            <PlanningBox
              topic="Interventi Strutturali"
              status={interventiStrutturali}
              statusMessage={
                interventiStrutturali === 1
                  ? 'Presenti'
                  : interventiStrutturali === 3
                  ? 'Non noti'
                  : 'Assenti'
              }
              noIcon={true}
              hideArrow={true}
            />
            <PlanningBox
              topic="Attività di monitoraggio"
              status={attivitaMonitoraggio}
              statusMessage={
                attivitaMonitoraggio === 1
                  ? 'Presenti'
                  : attivitaMonitoraggio === 3
                  ? 'Non note'
                  : 'Assenti'
              }
              noIcon={true}
              hideArrow={true}
            />
          </Row>
          <Row style={{ marginTop: 16, width: '32%' }}>
            <PlanningBox
              topic="Interventi di manutenzione"
              status={interventiManutenzione}
              statusMessage={
                interventiManutenzione === 1
                  ? 'Presenti'
                  : interventiManutenzione === 3
                  ? 'Non note'
                  : 'Assenti'
              }
              noIcon={true}
              hideArrow={true}
            />
          </Row>
        </CardOverview>
      </Row>
    </CardOverview>
  );
};

export { Contesto };

Contesto.propTypes = {
  overviewL0: PropTypes.obj,
};
