import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { DrawerCopy, LocalTourWrapper, RHFSelect } from 'components';
import { SelectionPanel } from 'components/SelectionPanel/SelectionPanel';
import { tour_11 } from 'constants/tour';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { updateArrayTableRows } from 'utils/utilities';
import { BtnContainer, GridWrap } from './Accordion4Connections.styles';
import { steps } from './Accordion4Connections.tourSteps';
import useCopy from './hooks/useCopy';
import AntenneSection from './sections/AntenneSection';
import AppoggiSection from './sections/AppoggiSection';
import DispositiviSismiciSection from './sections/DispositiviSismiciSection';
import GiuntiSection from './sections/GiuntiSection';
import PulviniSection from './sections/PulviniSection';

const Accordion4Connections = () => {
  const { watch, control, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  // SPALLE/PILE Collegamenti Table
  const {
    append: addSpallaPilaCollegamento,
    remove: removeSpallaPilaCollegamento,
  } = useFieldArray({
    control,
    name: 'spallePileCollegamentiTable',
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  // i dati sono collegati all'Accordion1StructuralFeatures, dove per ogni pila e spalla
  // vengono definiti i numeri di appoggi, giunti, antenne pulvini e dispositivi sismici

  const spallePileTableWatch = watch('spallePileTable');
  const spallePileCollegamentiTableWatch = watch('spallePileCollegamentiTable');

  useEffect(() => {
    if (spallePileTableWatch && spallePileCollegamentiTableWatch) {
      // we loop through all of the entries of spallePileTable for check
      // if the user has changed any of the values of the table

      const hasChangedAnyAppoggi = spallePileTableWatch?.some((el, idx) => {
        return (
          el.appoggi !==
          spallePileCollegamentiTableWatch[idx]?.appoggiTable?.length
        );
      });
      const hasChangedAnyGiunti = spallePileTableWatch?.some((el, idx) => {
        return (
          el.giunti !==
          spallePileCollegamentiTableWatch[idx]?.giuntiTable?.length
        );
      });
      const hasChangedAnyPulvini = spallePileTableWatch?.some((el, idx) => {
        return (
          el.pulvini !==
          spallePileCollegamentiTableWatch[idx]?.pulviniTable?.length
        );
      });
      const hasChangedAnyAntenne = spallePileTableWatch?.some((el, idx) => {
        return (
          el.antenne !==
          spallePileCollegamentiTableWatch[idx]?.antenneTable?.length
        );
      });
      const hasChangedAnyDispositiviSismici = spallePileTableWatch?.some(
        (el, idx) => {
          return (
            el.dispositiviSismici !==
            spallePileCollegamentiTableWatch[idx]?.dispositiviSismiciTable
              ?.length
          );
        }
      );

      if (
        spallePileTableWatch?.length !==
          spallePileCollegamentiTableWatch?.length ||
        hasChangedAnyAppoggi ||
        hasChangedAnyGiunti ||
        hasChangedAnyPulvini ||
        hasChangedAnyAntenne ||
        hasChangedAnyDispositiviSismici
      ) {
        removeSpallaPilaCollegamento();
        spallePileTableWatch.map((el, idx) => {
          const itemFromCollegamentiTable =
            spallePileCollegamentiTableWatch?.find(
              col => col.codice === el.codice
            );
          const appoggiTable = itemFromCollegamentiTable?.appoggiTable || [];
          const giuntiTable = itemFromCollegamentiTable?.giuntiTable || [];
          const pulviniTable = itemFromCollegamentiTable?.pulviniTable || [];
          const antenneTable = itemFromCollegamentiTable?.antenneTable || [];
          const dispositiviSismiciTable =
            itemFromCollegamentiTable?.dispositiviSismiciTable || [];

          const handlePushAppoggi = i => {
            appoggiTable.push({
              id: uuidv4(),
              codice: `AP-${el.codice}.${i}`,
              tipologiaAppoggio: '',
              appoggiAntisismici: '',
              schedaDifetti: populateSchedaDifetti('appoggio').map(el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }),
            });
          };

          const handlePushGiunti = i => {
            giuntiTable.push({
              id: uuidv4(),
              codice: `G-${el.codice}.${i}`,
              tipologiaGiunto: '',
              larghezzaGiunto: '',
              profonditaGiunto: '',
              schedaDifetti: populateSchedaDifetti('giunto').map(el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }),
            });
          };

          const handlePushPulvini = i => {
            pulviniTable.push({
              id: uuidv4(),
              codice: `PU-${el.codice}.${i}`,
              tipologiaPulvino: '',
              altezzaPulvino: '',
              larghezzaPulvino: '',
              profonditaPulvino: '',
              profonditaAppoggioPulvino: '',
              superficiePulvino: '',
              schedaDifetti: populateSchedaDifetti('pulvino').map(el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }),
            });
          };

          const handlePushDispositiviSismici = i => {
            dispositiviSismiciTable.push({
              id: uuidv4(),
              codice: `DS-${el.codice}.${i}`,
              nomeDispositivoSismico: '',
            });
          };

          const handlePushAntenne = i => {
            antenneTable.push({
              id: uuidv4(),
              codice: `AN-${el.codice}.${i}`,
              tipologiaAntenna: '',
              altezzaAntenna: '',
              larghezzaAntenna: '',
              profonditaAntenna: '',
              superficieAntenna: '',
              schedaDifetti: populateSchedaDifetti('antenna').map(el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }),
            });
          };

          updateArrayTableRows(el.appoggi, appoggiTable, handlePushAppoggi);
          updateArrayTableRows(el.giunti, giuntiTable, handlePushGiunti);
          updateArrayTableRows(el.pulvini, pulviniTable, handlePushPulvini);
          updateArrayTableRows(
            el.dispositiviSismici,
            dispositiviSismiciTable,
            handlePushDispositiviSismici
          );
          updateArrayTableRows(el.antenne, antenneTable, handlePushAntenne);

          addSpallaPilaCollegamento({
            id: uuidv4(),
            codice: el.codice,
            appoggiTable,
            giuntiTable,
            pulviniTable,
            antenneTable,
            dispositiviSismiciTable,
          });
        });
      }
    }
  }, [spallePileTableWatch]);

  const [
    spallePileCollegamentoSelezioneOptions,
    setSpallePileCollegamentoSelezioneOptions,
  ] = useState([]);

  const [selectedIdx, setSelectedIdx] = useState('');

  useEffect(() => {
    if (spallePileCollegamentiTableWatch) {
      setSpallePileCollegamentoSelezioneOptions(
        spallePileCollegamentiTableWatch.map((el, idx) => {
          return { label: el.codice, value: idx };
        })
      );
    }
  }, [spallePileCollegamentiTableWatch]);

  // watcher for selecting index of which spalla/pila to modify
  const spallePileCollegamentoSelezioneWatch = watch(
    'spallePileCollegamentoSelezione'
  );
  useEffect(() => {
    if (spallePileCollegamentoSelezioneWatch !== undefined) {
      setSelectedIdx(spallePileCollegamentoSelezioneWatch);
    }
  }, [spallePileCollegamentoSelezioneWatch]);

  const {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedCopy,
    setSelectedCopy,
    dataSpallePileConfig,
    selectedPaste,
    handleSelectAllSpallePilePaste,
    setSelectedPaste,
    checkCompatibility,
    handleCopy,
  } = useCopy();

  return (
    <>
      <GridWrap item xs={12}>
        <SelectionPanel subtitle="Seleziona una spalla/pila per procedere alla compilazione dei rispettivi apparecchi di appoggio/giunti/pulvini/antenne/altri dispositivi antisismici">
          <GridWrap container spacing={2}>
            <GridWrap item xs={10}>
              <RHFSelect
                name="spallePileCollegamentoSelezione"
                label={'Seleziona elemento'}
                defaultValue={''}
                selectOptions={spallePileCollegamentoSelezioneOptions}
              />
            </GridWrap>

            <GridWrap item xs={2} alignItems="center">
              {selectedIdx !== '' &&
                !inspectionDisabled &&
                !isViewingInspection && (
                  <BtnContainer>
                    <LocalTourWrapper
                      steps={steps}
                      style={{ margin: '0 auto', marginRight: 'unset' }}
                    />
                    <Button
                      className={`${tour_11.step_01}`}
                      onClick={() => {
                        setCopyDrawerOpen(true);
                        setSelectedPaste([]);
                        setSelectedCopy(
                          getValues(
                            `spallePileCollegamentiTable[${selectedIdx}]`
                          )
                        );
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Copia
                    </Button>
                  </BtnContainer>
                )}
            </GridWrap>
          </GridWrap>
        </SelectionPanel>
      </GridWrap>
      {selectedIdx !== '' && (
        <>
          <AppoggiSection selectedIdx={selectedIdx} />
          <GiuntiSection selectedIdx={selectedIdx} />
          <PulviniSection selectedIdx={selectedIdx} />
          <AntenneSection selectedIdx={selectedIdx} />
          <DispositiviSismiciSection selectedIdx={selectedIdx} />
        </>
      )}
      <DrawerCopy
        title={`Collegamenti / Copia ${getValues(
          `spallePileCollegamentiTable[${selectedIdx}].codice`
        )}`}
        subtitle={
          'Tutti gli elementi su cui le informazioni saranno copiate saranno sovrascritti. Eventuali media o schede di ispezione a loro associati saranno resettati.'
        }
        open={copyDrawerOpen}
        setOpen={setCopyDrawerOpen}
        tableConfig={dataSpallePileConfig}
        copyTableData={
          watch('spallePileTable')?.filter(
            el => el.codice == selectedCopy.codice
          ) || []
        }
        pasteTableData={
          watch('spallePileTable')?.filter(
            el => el.codice != selectedCopy.codice
          ) || []
        }
        selectedCopy={selectedCopy}
        selectedPaste={selectedPaste}
        handleSelectAll={handleSelectAllSpallePilePaste}
        setSelectedPaste={setSelectedPaste}
        checkCompatibility={checkCompatibility}
        handleCopy={handleCopy}
      />
    </>
  );
};

export { Accordion4Connections };
