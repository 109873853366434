import { Storage } from '@aws-amplify/storage';

const unsafeLetters = {
  // this relates to the AWS documentation
  // https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
  '+': '%2B',
  ' ': '+',
};

export const refreshS3Path = url => {
  if (!url) {
    return undefined;
  }
  const tmp = url.split('/');

  const correctFilename = decodeURIComponent(tmp.pop());
  const sanitizedFilename = Object.entries(unsafeLetters).reduce(
    (acc, [currKey, currValue]) => {
      const regex = currKey;
      return acc.replaceAll(regex, currValue);
    },
    correctFilename
  );
  return `${tmp.join('/')}/${sanitizedFilename}`;
};

const handleUpload = async (path, file, fileId, fileName) => {
  const bucket = process.env.REACT_APP_WEB_S3_BUCKET_NAME;
  try {
    const res = await Storage.put(`${path}/${fileName}`, file, {
      bucket,
      // level: 'private',
    });

    return { name: fileName, s3Path: res.key, fileId: fileId };
  } catch (err) {
    console.error(err);
    return { name: '', s3Path: '' };
  }
};

const getS3ObjectUrl = key => {
  const innerKey = `public/${key}`;
  return `https://${process.env.REACT_APP_WEB_S3_BUCKET_NAME}.s3-${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${innerKey}`;
};

const downloadFile = async key => {
  const signedURL = await Storage.get(key);
  window.open(signedURL);
};

const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
};

const downloadS3File = async (key, fileName) => {
  const defaultFileName = key.split('/').pop();
  const fileData = await Storage.get(key, { download: true });
  const temp = fileData.Body;
  downloadBlob(temp, fileName || defaultFileName);
};

const downloadExportFile = async key => {
  const formatKey = key.replace('public/', '');
  const signedURL = await Storage.get(formatKey);
  window.open(signedURL);
};

const deleteFromS3 = async key => Storage.remove(key);

export {
  handleUpload,
  deleteFromS3,
  getS3ObjectUrl,
  downloadFile,
  downloadBlob,
  downloadS3File,
  downloadExportFile,
};
