import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'use-http';
import { FormSubmitButtons } from 'components';
import { apiDeliveries } from 'constants/api';
import {
  DeliveriesException,
  DeliveriesResponseStatus,
} from 'constants/deliveries';
import { STRUCTURES_PATHS } from 'constants/paths';
import { useInspectionPath } from 'hooks';
import { useGlobalSelector } from 'stores';

export const UserAvatar = ({ label, name, surname, email }) => {
  return (
    <>
      <Box display={'flex'} alignItems="center">
        <Avatar
          variant="circular"
          style={{
            border: `1px solid`,
          }}
        >
          {label}
        </Avatar>
        <Stack>
          <Typography variant="h3Bold" sx={{ marginLeft: 2 }}>
            {`${name} ${surname}`}
          </Typography>
          <Tooltip title={email}>
            <Typography variant="h3" sx={{ marginLeft: 2 }}>
              {email}
            </Typography>
          </Tooltip>
        </Stack>
      </Box>
    </>
  );
};
UserAvatar.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
};

const SendArchiveInspectionForm = ({
  structureId,
  inspectionId,
  setOpen,
  onSubmitCallback,
  isDirtyForm,
  setIsDirtyForm,
}) => {
  const { isEditingInspection } = useInspectionPath();
  const navigate = useNavigate();
  const { get, post } = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const { showLoader, hideLoader } = useGlobalSelector();

  const [checkedUserType, setCheckedUserType] = useState();
  const [mail, setMail] = useState();
  const [mailError, setMailError] = useState();

  // new user form
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [message, setMessage] = useState('');
  const [businessName, setBusinessName] = useState('');

  // existing user ente
  const [enteEmail, setEnteEmail] = useState();
  const [foundEnte, setFoundEnte] = useState();

  const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

  // types of response
  // NOT_EXISTENT
  // EXISTS_ENTE
  // EXISTS_PROFESSIONISTA
  const checkUser = async () => {
    if (!mail?.match(regex) || !mail) {
      return setMailError('Inserire una mail valida');
    }
    setMailError();
    const res = await get(
      `${apiDeliveries.CHECK_INSPECTION_DELIVERY_DESTINATION}?email=${mail}`
    );
    if (res?.status === DeliveriesResponseStatus.OK) {
      if (setIsDirtyForm) setIsDirtyForm(true);
      setCheckedUserType(res.successMessage.message);
      if (
        res.successMessage.message ===
        DeliveriesException.CHECK_MAIL_EXISTS_ENTE
      ) {
        setFoundEnte(res?.response);
        setEnteEmail(res?.response?.email);
      }
      if (
        res.successMessage.message ===
        DeliveriesException.CHECK_MAIL_EXISTS_PROFESSIONISTA
      ) {
        setMailError("L'indirizzo inserito corrisponde ad un professionista");
      }
      if (
        res.successMessage.message ===
        DeliveriesException.CHECK_MAIL_EXISTS_INVITED
      ) {
        setFoundEnte(res?.response);
        setEnteEmail(res?.response?.email);
      }
      if (
        res.successMessage.message ===
        DeliveriesException.CHECK_MAIL_EXISTS_IS_ADMIN
      ) {
        setMailError("L'indirizzo inserito corrisponde ad un admin");
      }
    }
  };

  const handleSendArchive = async () => {
    try {
      showLoader();
      if (checkedUserType === DeliveriesException.CHECK_MAIL_NOT_EXISTENT) {
        const res = await post(apiDeliveries.ROOT, {
          inspectionId: parseInt(inspectionId),
          email: mail,
          message,
          name,
          surname,
          businessName,
        });
      }
      if (
        checkedUserType === DeliveriesException.CHECK_MAIL_EXISTS_ENTE ||
        checkedUserType === DeliveriesException.CHECK_MAIL_EXISTS_INVITED
      ) {
        const res = await post(apiDeliveries.ROOT, {
          inspectionId: parseInt(inspectionId),
          email: enteEmail,
          message,
        });
      }
      if (onSubmitCallback) {
        onSubmitCallback();
      }
      setOpen(false);
      enqueueSnackbar(`Ispezione consegnata con successo`, {
        variant: 'success',
      });
      if (isEditingInspection) {
        navigate(
          STRUCTURES_PATHS.INSPECTIONS_ARCHIVE.replace(
            ':structureId',
            structureId
          )
        );
      }
    } catch (err) {
      return enqueueSnackbar(
        `Si è verificato un errore nella consegna dell'ispezione`,
        {
          variant: 'error',
        }
      );
    } finally {
      hideLoader();
    }
  };

  const onReset = () => {
    setMail('');
    setMailError();
    setCheckedUserType();
    if (setIsDirtyForm) setIsDirtyForm(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          error={mailError}
          helperText={mailError}
          value={mail}
          label="e-mail"
          disabled={
            checkedUserType &&
            checkedUserType !==
              DeliveriesException.CHECK_MAIL_EXISTS_PROFESSIONISTA
          }
          onChange={e => setMail(e.target.value)}
        />
      </Grid>
      {checkedUserType !== DeliveriesException.CHECK_MAIL_EXISTS_ENTE &&
        checkedUserType !== DeliveriesException.CHECK_MAIL_NOT_EXISTENT && (
          <Grid item xs={12}>
            <FormSubmitButtons onReset={onReset} onSubmit={checkUser} />
          </Grid>
        )}

      {(checkedUserType === DeliveriesException.CHECK_MAIL_EXISTS_ENTE ||
        checkedUserType === DeliveriesException.CHECK_MAIL_EXISTS_INVITED) && (
        <>
          <Grid item xs={12}>
            <UserAvatar
              label={`${foundEnte?.name?.charAt(0)}${foundEnte?.surname?.charAt(
                0
              )}`}
              name={foundEnte?.name}
              surname={foundEnte?.surname}
              email={foundEnte?.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={message}
              multiline
              rows={4}
              label="Messaggio"
              onChange={e => setMessage(e.target.value)}
            />
          </Grid>
        </>
      )}
      {checkedUserType === DeliveriesException.CHECK_MAIL_NOT_EXISTENT && (
        <>
          <Grid item xs={12}>
            <TextField
              value={businessName}
              label="Ente/Società"
              onChange={e => setBusinessName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={name}
              label="Nome"
              onChange={e => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={surname}
              label="Cognome"
              onChange={e => setSurname(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={message}
              multiline
              rows={4}
              label="Messaggio"
              onChange={e => setMessage(e.target.value)}
            />
          </Grid>
        </>
      )}
      {checkedUserType && (
        <Grid item xs={12} textAlign="end">
          <Button color="secondary" size="small" onClick={onReset}>
            Annulla
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={
              !checkedUserType ||
              checkedUserType ===
                DeliveriesException.CHECK_MAIL_EXISTS_PROFESSIONISTA ||
              checkedUserType ===
                DeliveriesException.CHECK_MAIL_EXISTS_IS_ADMIN ||
              (checkedUserType ===
                DeliveriesException.CHECK_MAIL_NOT_EXISTENT &&
                (!name || !surname))
            }
            onClick={handleSendArchive}
          >
            Consegna e archivia
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

SendArchiveInspectionForm.propTypes = {
  structureId: PropTypes.number,
  inspectionId: PropTypes.number,
  setOpen: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  isDirtyForm: PropTypes.bool,
  setIsDirtyForm: PropTypes.func,
};

export default SendArchiveInspectionForm;
