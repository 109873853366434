import { useCallback } from 'react';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { STORE_PREFIX } from 'constants/globals';

const useStore = create(
  persist(
    set => ({
      tourIsOpen: false,
      setTourIsOpen: value => set(() => ({ tourIsOpen: value })),
    }),

    {
      version: 1, // a migration will be triggered if the version in the storage mismatches this one
      name: `${STORE_PREFIX}-tour`,
      getStorage: () => sessionStorage,
      partialize: state =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !['tourIsOpen'].includes(key))
        ),
    }
  )
);

const useTourSelector = () => useStore(useCallback(state => state, []));

export { useTourSelector, useStore as useTourStore };
