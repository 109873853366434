import { WebL0Fields } from 'constants/inspectionFields';
import Accordion1CaratteristicheSovrastruttura from '../Accordion1CaratteristicheSovrastruttura/Accordion1CaratteristicheSovrastruttura';
import Accordion2Impalcato from '../Accordion2Impalcato/Accordion2Impalcato';
import { Accordion3SuperStructuresCommentiPerReport } from '../Accordion3SuperStructuresCommentiPerReport/Accordion3SuperStructuresCommentiPerReport';

export const AccordionDataSuperstructureL0 = [
  {
    id: '1',
    title: '1. Caratteristiche elementi accessori',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion1CaratteristicheSovrastruttura />,
    indicatorFields: WebL0Fields.ElementiAccessori.caratteristiche,
  },
  {
    id: '2',
    title: '2. Impalcato',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion2Impalcato />,
    indicatorFields: WebL0Fields.ElementiAccessori.impalcato,
  },
  {
    id: '3',
    title: '3. Commenti per report',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion3SuperStructuresCommentiPerReport />,
    /* indicatorFields: WebL0Fields.ElementiAccessori.commentiReport, */
  },
];
