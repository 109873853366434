import styled from '@emotion/styled';
import BackImage from 'assets/login-backimage.jpg';

export const LoginContainer = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100%',
  backgroundImage: `url(${BackImage})`,
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0',
  position: 'relative',
  [theme.breakpoints.up('md')]: {},
}));

export const FormContainer = styled('div')(({ theme }) => ({
  width: '85%',
  height: '95vh',
  padding: '20px 30px',
  overflow: 'auto',
  borderRadius: 8,
  backgroundColor: theme.palette.secondary.light,
  [theme.breakpoints.up('374px')]: {
    height: '50vh',
  },
  [theme.breakpoints.up('md')]: {
    width: '50%',
    padding: '5% 100px 12% 100px',
    position: 'absolute',
    bottom: 0,
    borderRadius: '8px 8px 0 0',
  },
}));
