import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaPulvinoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

const PulviniForm = ({
  pulviniFields,
  selected,
  setSelected,
  pulviniTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      pulviniTableValue: [],
      tipologiaPulvinoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('pulviniTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetPulvini();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changePulvini = () => {
    const tipologiaPulvinoTableValue = getValues('tipologiaPulvinoTableValue');

    const ids = selected.map(el => el.codice);

    const pulvini = [...pulviniFields];

    pulvini.map((x, index) => {
      if (ids.includes(x?.codice)) {
        pulvini[index] = {
          ...x,
          tipologiaPulvino: tipologiaPulvinoTableValue,
          ...(tipologiaPulvinoTableValue !== x.tipologiaPulvino
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'pulvino',
                  tipologiaPulvinoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(pulviniTable, pulvini);
    onResetPulvini();
  };

  const onConfirmChangePulvini = () => {
    setOpenConfirmationDialog(false);
    changePulvini();
  };

  const onSubmitPulvini = () => {
    const tipologiaPulvinoTableValue = getValues('tipologiaPulvinoTableValue');

    const ids = selected.map(el => el.codice);

    const pulvini = [...pulviniFields];

    const hasChangedTipologia = pulvini.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          tipologiaPulvinoTableValue !== el.tipologiaPulvino &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaPulvino)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changePulvini();
    }
  };

  const onResetPulvini = () => {
    setValue('pulviniTableValue', []);
    setValue('tipologiaPulvinoTableValue', '');
    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={3} infoMessage={INFO.pulviniSelezionati.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-pulvini"
            name={'pulviniTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={pulviniFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Pulvini selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipologiaPulvinoTableValue"
            label="Tipologia Pulvino"
            defaultValue={''}
            onChange={e => {
              setValue('tipologiaPulvinoTableValue', e.target.value);
            }}
            selectOptions={tipologiaPulvinoOptions}
          />
        </GridWrap>
        <GridWrap item xs={6}>
          <FormSubmitButtons
            onSubmit={onSubmitPulvini}
            onReset={onResetPulvini}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangePulvini}
      />
    </>
  );
};
PulviniForm.propTypes = {
  pulviniFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaPulvino: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  pulviniTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default PulviniForm;
