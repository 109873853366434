import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormSubmitButtons, RHFTextField } from 'components';
import { tour_17 } from 'constants/tour';
import { FormProvider } from 'providers';
import { buildQueryString } from 'utils/utilities';

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.background.grey,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),

  gap: theme.spacing(2),
}));

const BridgesFilter = ({ setSearchParams }) => {
  const defaultValues = {
    internalCode: '',
    name: '',
    structureName: '',
    tags: '',
    structureTags: '',
  };

  const methods = useForm({ defaultValues });

  const { handleSubmit, reset, getValues } = methods;

  const onSubmit = async data => {
    /* data.structureName = getValues('name');
    data.structureTags = getValues('tags');
    const _query = buildQueryString(data); */
    setSearchParams(data);
  };

  const handleReset = () => {
    reset();
    setSearchParams(defaultValues);
  };

  return (
    <FormProvider methods={methods}>
      <StyledPaper elevation={0} className={`${tour_17.step_01}`}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <RHFTextField name="internalCode" label="Codice Interno - ID" />
          </Grid>
          <Grid item xs={3}>
            <RHFTextField name="name" label="Nome opera" />
          </Grid>
          <Grid item xs={3}>
            <RHFTextField name="tags" label="Tag" />
          </Grid>
          <Grid item xs={3} alignSelf="center">
            <FormSubmitButtons
              customSubmitIcon={<Search />}
              onReset={handleReset}
              onSubmit={handleSubmit(onSubmit)}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </FormProvider>
  );
};

BridgesFilter.propTypes = {
  setSearchParams: PropTypes.func,
};

export default BridgesFilter;
