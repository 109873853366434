export const markdown_faq_9_1 = `La condivisione è una delle funzionalità principali che consente a più professionalità di condividere informazioni o lavorare sulla stessa attività. Può avvenire a due livelli ed ognuno ha le sue funzionalità e peculiarità.

**CONDIVISIONE DEL PONTE**
La condivisione del ponte consente di fornire i diritti di acceso ad altri utenti su tutto il contenuto e le attività del ponte.
Può essere fatta scegliendo due diritti per l'utente:
- *sola visualizzazione*, per poter consultare le informazioni presenti e condividere con altri utenti in sola visualizzazione;
- *visualizzazione e modifica*, per creare nuove attività, modificare i tag del ponte, ricondividere con altri utenti anche in modalità di modifica.

**Per condividere il ponte:**
1. Accedere alla scheda del ponte
2. Cliccare sul pulsante di condivisione

Nel pannello:

3. Digitare la mail dell'utente destinatario
4. Selezionare il tipo di permesso.

*Per chi ha una sottoscrizione attiva*: è possibile definire la tipologia di collaboratore, se esterno o interno, e la data di scadenza dell'accesso.`;
export const markdown_faq_9_2 = `**CONDIVISIONE DELL'ATTIVITA'**
La condivisione dell'attività consente di fornire il diritto di modifica della singola attività ad altri utenti. Si deve condividere l'attività SOLO con utenti con cui si collabora alla stesura dell'attività di censimento o ispezione. In questo modo sarà quindi possibile, ad esempio, la collaborazione tra più professionisti ai fini della valutazione di tutti e quattro i rischi per il calcolo della Classe di Attenzione complessiva.

**Per condividere l'attività:**
1. Accedere all'attività in visualizzazione o in modifica
2. Cliccare sul pulsante di condivisione

Nel pannello:

3. Digitare la mail dell'utente destinatario.

*Per chi ha una sottoscrizione attiva*: è possibile definire la tipologia di collaboratore, se esterno o interno, e la data di scadenza dell'accesso.`;
