import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import { mensole, TipologiaParapettoTableValue } from 'constants/inspections';
import { tipologiaParapettoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
const ParapettiForm = ({
  parapettiFields,
  selected,
  setSelected,
  parapettiTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t3a2_parapettiTableValue: [],
      l0t3a2_tipologiaParapettoTableValue: '',
      l0t3a2_tipologiaAltroParapettoTableValue: '',
      l0t3a2_altezzaParapettoTableValue: '',
      l0t3a2_superficieParapettoTableValue: '',
    },
  });

  useEffect(() => {
    setValue('l0t3a2_parapettiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetParapetti();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitParapetti = () => {
    const l0t3a2_tipologiaParapettoTableValue = getValues(
      'l0t3a2_tipologiaParapettoTableValue'
    );
    const l0t3a2_tipologiaAltroParapettoTableValue = getValues(
      'l0t3a2_tipologiaAltroParapettoTableValue'
    );
    const l0t3a2_altezzaParapettoTableValue = getValues(
      'l0t3a2_altezzaParapettoTableValue'
    );

    const l0t3a2_superficieParapettoTableValue = getValues(
      'l0t3a2_superficieParapettoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const parapetti = [...parapettiFields];
    parapetti.map((x, index) => {
      if (ids.includes(x?.codice)) {
        parapetti[index] = {
          ...x,
          tipologiaParapetto: l0t3a2_tipologiaParapettoTableValue,
          tipologiaAltroParapetto: l0t3a2_tipologiaAltroParapettoTableValue,
          altezzaParapetto: l0t3a2_altezzaParapettoTableValue,
          superficieParapetto: l0t3a2_superficieParapettoTableValue,
        };
      }
    });

    setValue(parapettiTable, parapetti);
    onResetParapetti();
  };

  const onResetParapetti = () => {
    setValue('l0t3a2_parapettiTableValue', []);
    setValue('l0t3a2_tipologiaParapettoTableValue', '');
    setValue('l0t3a2_tipologiaAltroParapettoTableValue', '');
    setValue('l0t3a2_altezzaParapettoTableValue', '');
    setValue('l0t3a2_superficieParapettoTableValue', '');

    setSelected([]);
  };

  // Luce impalcato*(Altezza/100)
  const l0t3a2_sovrastrutturaImpalcatoSelezioneWatch = watch(
    'l0t3a2_sovrastrutturaImpalcatoSelezione'
  );
  const altezzaParapettoWatch = parseFloat(
    watch('l0t3a2_altezzaParapettoTableValue')
  );
  const parapettoTableWatch = watch('l0t3a2_parapettiTableValue');
  useEffect(() => {
    const watchMensole = watch('mensole');
    const watchMensoleSi = watchMensole === mensole.Si;
    let result;

    if (watchMensoleSi) {
      const mensoleTamponiTableWatch = getValues(
        `mensoleTamponiTable[${l0t3a2_sovrastrutturaImpalcatoSelezioneWatch}]`
      );
      result =
        parseFloat(mensoleTamponiTableWatch.luce) *
        (altezzaParapettoWatch / 100);
    } else {
      const campataTableWatch = getValues(
        `campataTable[${l0t3a2_sovrastrutturaImpalcatoSelezioneWatch}]`
      );
      result =
        parseFloat(campataTableWatch.luceCampata) *
        (altezzaParapettoWatch / 100);
    }
    setValue('l0t3a2_superficieParapettoTableValue', result);
  }, [
    l0t3a2_sovrastrutturaImpalcatoSelezioneWatch,
    parapettoTableWatch,
    altezzaParapettoWatch,
  ]);

  const tipologiaParapettoeWatch = watch('l0t3a2_tipologiaParapettoTableValue');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (tipologiaParapettoeWatch === TipologiaParapettoTableValue.Altro) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setValue('l0t3a2_tipologiaAltroParapettoTableValue', '');
    }
  }, [tipologiaParapettoeWatch]);

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper size={6} infoMessage={INFO.parapettiSelezionati.infoMessage}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-parapetti_l0t3a2"
          name={'l0t3a2_parapettiTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={parapettiFields
            .filter(el => !el.assente)
            ?.filter(el => !selected?.some(s => s?.id === el?.id))}
          label="Parapetti selezionati"
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="l0t3a2_tipologiaParapettoTableValue"
          label="Tipologia Parapetto*"
          defaultValue={''}
          onChange={e => {
            setValue('l0t3a2_tipologiaParapettoTableValue', e.target.value);
          }}
          selectOptions={tipologiaParapettoOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="l0t3a2_tipologiaAltroParapettoTableValue"
          label={'Tipologia Parapetto (Altro)'}
          disabled={isDisabled}
          inputProps={{ maxLength: 100 }}
          defaultValue=""
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="l0t3a2_altezzaParapettoTableValue"
          label={'Altezza (H) [cm]'}
          defaultValue=""
          decimalScale={2}
          withValueLimit={({ value }) => value < 1000}
        />
      </GridWrap>
      <InfoWrapper size={3} infoMessage={INFO.superficieParapetto.infoMessage}>
        <RHFNumberTextField
          name="l0t3a2_superficieParapettoTableValue"
          label={'Superficie [m²]'}
          defaultValue=""
          decimalScale={2}
          disabled
          InputLabelProps={{ shrink: true }}
          withValueLimit={({ value }) => value < 1000}
        />
      </InfoWrapper>
      <GridWrap item xs={6}>
        <FormSubmitButtons
          onSubmit={onSubmitParapetti}
          onReset={onResetParapetti}
        />
      </GridWrap>
    </GridWrap>
  );
};
ParapettiForm.propTypes = {
  parapettiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaParapetto: PropTypes.number,
      tipologiaAltroParapetto: PropTypes.string,
      altezzaParapetto: PropTypes.number,
      superficieParapetto: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  parapettiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default ParapettiForm;
