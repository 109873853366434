import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import {
  impostaFondPilaOptions,
  tipoPilaSelectOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

const PileForm = ({ pileFields, selected, setSelected }) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      pileTableValue: [],
      tipologiaPilaTableValue: '',
      impostaFondPilaTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('pileTableValue', selected);
  }, [selected]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changePile = () => {
    const tipologiaPilaTableValue = getValues('tipologiaPilaTableValue');
    const impostaFondPilaTableValue = getValues('impostaFondPilaTableValue');
    const ids = selected.map(el => el.codicePila);

    const pile = [...pileFields];

    pile.map((x, index) => {
      if (ids.includes(x?.codicePila)) {
        pile[index] = {
          ...x,
          tipoPila: tipologiaPilaTableValue,
          impostaFondPila: impostaFondPilaTableValue,
          ...(tipologiaPilaTableValue !== x.tipoPila ||
          impostaFondPilaTableValue !== x.impostaFondPila
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'pila',
                  tipologiaPilaTableValue,
                  impostaFondPilaTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue('pileTable', pile);
    onResetPile();
  };

  const onConfirmChangePile = () => {
    setOpenConfirmationDialog(false);
    changePile();
  };

  const onSubmitPile = () => {
    const tipologiaPilaTableValue = getValues('tipologiaPilaTableValue');
    const impostaFondPilaTableValue = getValues('impostaFondPilaTableValue');

    const ids = selected.map(el => el.codicePila);

    const pile = [...pileFields];

    const hasChangedTipologia = pile.some(el => {
      if (ids.includes(el?.codicePila)) {
        return (
          (tipologiaPilaTableValue !== el.tipoPila ||
            impostaFondPilaTableValue !== el.impostaFondPila) &&
          !isNullOrUndefinedOrEmptyString(el.tipoPila) &&
          !isNullOrUndefinedOrEmptyString(el.impostaFondPila)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changePile();
    }
  };

  const onResetPile = () => {
    setValue('pileTableValue', []);
    setValue('tipologiaPilaTableValue', '');
    setValue('impostaFondPilaTableValue', '');
    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={3} infoMessage={INFO.pileSelezionate.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-pile"
            name={'pileTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codicePila'}
            getOptionLabel={option => option.codicePila}
            opt={pileFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Pile selezionate"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipologiaPilaTableValue"
            label="Tipologia Pila"
            defaultValue={''}
            onChange={e => {
              setValue('tipologiaPilaTableValue', e.target.value);
            }}
            selectOptions={tipoPilaSelectOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="impostaFondPilaTableValue"
            label="Imposta fondazioni pila*"
            defaultValue={''}
            onChange={e => {
              setValue('impostaFondPilaTableValue', e.target.value);
            }}
            selectOptions={impostaFondPilaOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <FormSubmitButtons onSubmit={onSubmitPile} onReset={onResetPile} />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangePile}
      />
    </>
  );
};
PileForm.propTypes = {
  pileFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaSpalla: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default PileForm;
