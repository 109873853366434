import PropTypes from 'prop-types';
import { DrawerMUI } from 'components';
import { DrawerMediaSelectedTable } from 'components/DrawerMediaSelectedTable/DrawerMediaSelectedTable';
import { DrawerMediaTable } from 'components/DrawerMediaTable/DrawerMediaTable';

export const DrawerMedia = ({
  open,
  setOpen,
  title,
  infoPanel,
  dataType,
  formName,
  fileLimit,
  tags,
  uploadType,
  type,
  mediaGroup,
  onlySelection,
  preview,
  isInfoAssistance,
  onFinishUpload,
  isViewingInspection,
}) => {
  return (
    <DrawerMUI
      open={open}
      setOpen={setOpen}
      title={` ${title}`}
      subtitle={infoPanel}
    >
      {!onlySelection && (
        <DrawerMediaTable
          setOpenDrawer={setOpen}
          openDrawer={open}
          dataType={dataType}
          formName={formName}
          fileLimit={fileLimit}
          tags={tags}
          uploadType={uploadType}
          type={type}
          mediaGroup={mediaGroup}
          preview={preview}
          isInfoAssistance={isInfoAssistance}
          onFinishUpload={onFinishUpload}
        />
      )}
      {onlySelection && (
        <DrawerMediaSelectedTable
          setOpenDrawer={setOpen}
          openDrawer={open}
          formName={formName}
          preview={true}
          isViewingInspection={isViewingInspection}
        />
      )}
    </DrawerMUI>
  );
};

DrawerMedia.propTypes = {
  uploadType: PropTypes.oneOf(['media', 'documents', 'graphicElaboration']),
  dataType: PropTypes.string,
  formName: PropTypes.string,
  fileLimit: PropTypes.number,
  tags: PropTypes.array,
  type: PropTypes.number,
  mediaGroup: PropTypes.array,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  infoPanel: PropTypes.string,
  onlySelection: PropTypes.bool,
  preview: PropTypes.bool,
  isInfoAssistance: PropTypes.bool,
  onFinishUpload: PropTypes.func,
  isViewingInspection: PropTypes.bool,
};
