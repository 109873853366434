import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { InfoWrapper, MediaUpload, RHFSelect } from 'components';
import {
  FieldType,
  MediaGroup,
  NecessitaIspezioneSpecialeIdraulico,
  PericolositaPAIPSAIFrane,
  PericolositaPAIPSAIRischioIdraulico,
  PresenzaStudioIdraulicoLivelliDefiniti,
  RischioIdraulico,
  RisultatiIspezioniSpecialiRischioIdraulico,
  UploadMedia,
} from 'constants/inspections';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap, TitleSection } from './Accordion5HydraulicRisk.styles';
import { INFO } from '../../../../config/info';

const Accordion5HydraulicRisk = () => {
  const { setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchRischioIdraulico = watch('rischioIdraulico');
  const watchRischioIdraulicoAssente =
    watchRischioIdraulico === RischioIdraulico.Assente;

  const watchRischioIdraulicoDaVerificare =
    watchRischioIdraulico === RischioIdraulico.DaVerificare;
  const watchRischioIdraulicoPresente =
    watchRischioIdraulico === RischioIdraulico.Presente;

  const watchNecessiaIspezioniSpecialiRischioIdraulico = watch(
    'necessitaIspezioniSpecialiRischioIdraulico'
  );
  const watchYesNecessitaIspezioniSpeciali =
    watchNecessiaIspezioniSpecialiRischioIdraulico ===
    NecessitaIspezioneSpecialeIdraulico.Si;

  //Functions
  useEffect(() => {
    if (watchRischioIdraulicoAssente) {
      setValue('necessitaIspezioniSpecialiRischioIdraulico', '');
      setValue('risultatiIspezioniSpecialiRischioIdraulico', '');
      setValue('pericolositaPAIPSAIRischioIdraulico', '');
      setValue('presenzaStudioIdraulicoLivelliDefiniti', '');
    }
  }, [watchRischioIdraulico]);

  useEffect(() => {
    if (!watchYesNecessitaIspezioniSpeciali) {
      setValue('risultatiIspezioniSpecialiRischioIdraulico', '');
    }
  }, [watchYesNecessitaIspezioniSpeciali]);

  return (
    <GridWrap container spacing={2} padding="20px">
      <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
        <TitleSection>
          <Typography variant="h3BoldBlue">DATI GENERALI</Typography>
        </TitleSection>
      </GridWrap>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.rischioIdraulico.drawerTitle}
        drawerText={INFO.rischioIdraulico.drawerText}
        legislationMessage={INFO.rischioIdraulico.legislationMessage}
      >
        <RHFSelect
          name="rischioIdraulico"
          label={'Rischio idraulico*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'Da verificare',
              value: RischioIdraulico.DaVerificare,
            },
            {
              label: 'Assente',
              value: RischioIdraulico.Assente,
            },
            {
              label: 'Presente',
              value: RischioIdraulico.Presente,
            },
          ]}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={
          INFO.necessitaIspezioniSpecialiRischioIdraulico.drawerTitle
        }
        drawerText={INFO.necessitaIspezioniSpecialiRischioIdraulico.drawerText}
        compassMessage={
          INFO.necessitaIspezioniSpecialiRischioIdraulico.compassMessage
        }
      >
        <RHFSelect
          name="necessitaIspezioniSpecialiRischioIdraulico"
          label={'Necessità di ispezioni speciali (rischio idraulico)*'}
          defaultValue={''}
          disabled={
            (!watchRischioIdraulicoDaVerificare &&
              !watchRischioIdraulicoPresente) ||
            inspectionDisabled ||
            isViewingInspection
          }
          selectOptions={[
            {
              label: 'Sì',
              value: NecessitaIspezioneSpecialeIdraulico.Si,
            },
            {
              label: 'No',
              value: NecessitaIspezioneSpecialeIdraulico.No,
            },
          ]}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        compassMessage={
          INFO.risultatiIspezioniSpecialiRischioIdraulico.compassMessage
        }
        drawerTitle={
          INFO.risultatiIspezioniSpecialiRischioIdraulico.drawerTitle
        }
        drawerText={INFO.risultatiIspezioniSpecialiRischioIdraulico.drawerText}
      >
        <RHFSelect
          name="risultatiIspezioniSpecialiRischioIdraulico"
          label={'Risultati ispezioni speciali*'}
          defaultValue={''}
          disabled={
            watchRischioIdraulicoAssente ||
            !watchYesNecessitaIspezioniSpeciali ||
            inspectionDisabled ||
            isViewingInspection
          }
          selectOptions={[
            {
              label: 'Necessità di valutazioni di Livello 4',
              value:
                RisultatiIspezioniSpecialiRischioIdraulico.NecessitaValutazioneLivello4,
            },
            {
              label:
                'Ispezione speciale non esaustiva. Necessità di valutazioni di Livello 4',
              value:
                RisultatiIspezioniSpecialiRischioIdraulico.IspezioneSpecialeNonEsausitva,
            },
            {
              label:
                'Necessità di ispezioni di Livello 1 e classificazione Livello 2',
              value:
                RisultatiIspezioniSpecialiRischioIdraulico.NecessitaIspezioneLivello1eClassificazioneLivello2,
            },
            {
              label: ' Ispezione speciale ancora da effettuare',
              value:
                RisultatiIspezioniSpecialiRischioIdraulico.IspezioneSpecialeAncoraDaEffettuare,
            },
          ]}
        />
      </InfoWrapper>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Documentazione ispezioni speciali (rischio idr.)`}
          btnTitle="Allega file"
          dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
          formName="documentazioneIspezioniSpecialiIdraulica"
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.Idraulica]}
          tags={['indagini', 'laboratorio', 'ispettore', 'prove']}
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoMessage={INFO.pericolositaPAIPSAIRischioIdraulico.infoMessage}
        linkMessage={INFO.pericolositaPAIPSAIRischioIdraulico.linkMessage}
      >
        <RHFSelect
          name="pericolositaPAIPSAIRischioIdraulico"
          label={'Pericolosità PAI/PSAI - Idraulico'}
          defaultValue={''}
          disabled={
            (!watchRischioIdraulicoDaVerificare &&
              !watchRischioIdraulicoPresente) ||
            inspectionDisabled ||
            isViewingInspection
          }
          selectOptions={[
            {
              label:
                'P1: eventi rari di estrema intensità: 200 < TR < 500 anni',
              value: PericolositaPAIPSAIRischioIdraulico.P1,
            },
            {
              label: 'P2: eventi poco frequenti: 100 < TR ≤ 200 anni',
              value: PericolositaPAIPSAIRischioIdraulico.P2,
            },
            {
              label: 'P3: eventi frequenti: 20 < TR < 50 anni',
              value: PericolositaPAIPSAIRischioIdraulico.P3,
            },
            {
              label: 'Area non mappata',
              value: PericolositaPAIPSAIRischioIdraulico.AreaNonMappata,
            },
          ]}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.stralcioMappaPericolositàIdraulicaPAI.drawerTitle}
        drawerText={INFO.stralcioMappaPericolositàIdraulicaPAI.drawerText}
        drawerImages={INFO.stralcioMappaPericolositàIdraulicaPAI.drawerImages}
      >
        <MediaUpload
          title={`Stralcio mappa pericolosità idraulica PAI`}
          btnTitle="Allega file"
          formName="stralcioMappaPericolositàIdraulicaPAI"
          dataType=".jpeg,.png"
          infoPanel="Inserire stralcio immagine dell'area interessata. Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.Idraulica]}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.presenzaStudioIdraulicoLivelliDefiniti.drawerTitle}
        drawerText={INFO.presenzaStudioIdraulicoLivelliDefiniti.drawerText}
      >
        <RHFSelect
          name="presenzaStudioIdraulicoLivelliDefiniti"
          label={'Presenza studio idraulico con livelli idrici definiti*'}
          defaultValue={''}
          disabled={
            (!watchRischioIdraulicoDaVerificare &&
              !watchRischioIdraulicoPresente) ||
            inspectionDisabled ||
            isViewingInspection
          }
          selectOptions={[
            {
              label: 'Sì',
              value: PresenzaStudioIdraulicoLivelliDefiniti.Si,
            },
            {
              label: 'No',
              value: PresenzaStudioIdraulicoLivelliDefiniti.No,
            },
          ]}
        />
      </InfoWrapper>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Documenti inerenti le condizioni di rischio idr.`}
          btnTitle="Allega file"
          formName="documentiInerentiCondizioniRischioIdraulico"
          dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.Idraulica]}
        />
      </GridWrap>
      <GridWrap item md={3}>
        <MediaUpload
          title={`Cartografia tematica delle Autorità di Distretto`}
          btnTitle="Allega file"
          formName="cartografiaTematicaAutoritaDistretto"
          dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
          fileLimit={1}
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
          mediaGroup={[MediaGroup.Idraulica]}
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.fotoInquadramentoIdraulica.drawerTitle}
        drawerText={INFO.fotoInquadramentoIdraulica.drawerText}
      >
        <MediaUpload
          title={`Foto inquadramento idraulica`}
          btnTitle="Allega file"
          formName="fotoInquadramentoIdraulica"
          preview={true}
          dataType="video/*,image/*"
          uploadType={UploadMedia.media}
          type={FieldType.images}
          mediaGroup={[MediaGroup.Idraulica]}
          tags={[
            'Pile in alveo',
            'Spalle in alveo',
            'Alveo',
            'Prospetto',
            'Monte',
            'Valle',
            'Sponda',
            'Destra',
            'Sinistra',
            'Aree Golenali',
            'Scalzamento',
            'Foto aerea',
          ]}
        />
      </InfoWrapper>
    </GridWrap>
  );
};

export { Accordion5HydraulicRisk };
