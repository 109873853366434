import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { InfoBox, InfoWrapper } from 'components';
import { RHFDatePicker, RHFSelect, RHFTextField } from 'components/form';
import {
  CollaborationsErrors,
  CollaborationsErrorsColorMap,
  CollaborationsErrorsMessageMap,
  PermissionType,
} from 'constants/permissions';
import { INFO } from 'pages/Inspections/config/info';

const ExistingUserForm = ({
  checkInvitabilityResponse,
  shareOptions,
  addNoAccess = false,
  showDatePicker,
  subscription,
  accessExpiration,
}) => {
  const ignoreInfoMessage = [
    CollaborationsErrors.CollaboratorAlreadyHaveEntityButWhereNotSharedByYou,
    CollaborationsErrors.DoNotInviteToRegisterInternalCanShareEntitiesAmongThemselves,
  ];
  return (
    <div style={{ marginTop: 2 }}>
      <Grid item xs={12} mt={2} mb={2}>
        {!ignoreInfoMessage.includes(checkInvitabilityResponse) && (
          <InfoBox
            color={CollaborationsErrorsColorMap[checkInvitabilityResponse]}
            text={CollaborationsErrorsMessageMap[checkInvitabilityResponse]}
          />
        )}
      </Grid>
      <Grid item xs={12} mt={2} mb={4}>
        {addNoAccess && (
          <RHFSelect
            name="permission"
            label="Diritto di accesso*"
            selectOptions={shareOptions}
            showNoValue={false}
          />
        )}
        {!addNoAccess && (
          <RHFSelect
            name="permission"
            label="Diritto di accesso*"
            selectOptions={shareOptions.filter(
              el => el.value !== PermissionType.NoPermission
            )}
            showNoValue={false}
          />
        )}
      </Grid>
      {showDatePicker && (
        <InfoWrapper
          mt={2}
          size={12}
          infoMessage={INFO.accessExpirationSharing.infoMessage}
        >
          <RHFDatePicker
            name="accessExpiration"
            label={'Scadenza Accesso'}
            minDate={new Date()}
            maxDate={
              accessExpiration
                ? new Date(accessExpiration)
                : new Date(subscription?.expirationDate)
            }
          />
        </InfoWrapper>
      )}
      <Grid item xs={12} mt={2}>
        <RHFTextField
          name={'message'}
          label={'Messaggio'}
          rows={4}
          multiline
          /* rules={{ required: 'Messaggio obbligatorio' }} */
        />
      </Grid>
    </div>
  );
};

ExistingUserForm.propTypes = {
  checkInvitabilityResponse: PropTypes.number,
  addNoAccess: PropTypes.bool,
  shareOptions: PropTypes.array,

  subscription: PropTypes.shape({
    expirationDate: PropTypes.string,
    id: PropTypes.number,
    maxAvailableSpace: PropTypes.number,
    maxStructureNumber: PropTypes.number,
    userId: PropTypes.number,
  }),
  showDatePicker: PropTypes.bool,
  accessExpiration: PropTypes.string,
};

export default ExistingUserForm;
