import { useCallback } from 'react';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { STORE_PREFIX } from 'constants/globals';

const useStore = create(
  persist(
    () => ({
      images: [],
    }),
    { name: `${STORE_PREFIX}images`, getStorage: () => sessionStorage }
  )
);

const useImagesSelector = () => useStore(useCallback(state => state, []));

export { useImagesSelector, useStore as useImagesStore };
