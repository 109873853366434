export const statusInspections = {
  PresenteNecessario: 1,
  AssenteNonNecessario: 2,
  NecessitaIspezioniSpeciali: 3,
};

export const planningIcons = {
  necessitaIspezioniSpeciali: {
    present: '/icons/red-search.svg',
    absent: '/icons/grey-search.svg',
  },
  aspettiCriticiStruttura: {
    present: '/icons/blue-critical-aspect.svg',
    absent: '/icons/grey-critical-aspect.svg',
  },
  interventiSovrastruttura: {
    absent: '/icons/settings.svg',
    present: '/icons/blue-settings.svg',
  },
  interventiServizi: {
    absent: '/icons/settings.svg',
    present: '/icons/blue-settings.svg',
  },
  monitoraggioAttivo: {
    absent: '/icons/monitoring.svg',
    present: '/icons/blue-monitoring.svg',
  },
  limitazioniCarico: {
    absent: '/icons/weight-hanging.svg',
    present: '/icons/blue-weight-hanging.svg',
  },
  trasportoEccezionale: {
    absent: '/icons/truck-moving.svg',
    present: '/icons/blue-truck-moving.svg',
  },
  trasportoMerciPericolose: {
    absent: '/icons/danger.svg',
    present: '/icons/blue-danger.svg',
  },
  rischioFrana: {
    absent: '/icons/frana_grey.png',
    present: '/icons/frana_blue.png',
  },
  rischioIdraulico: {
    absent: '/icons/idraulico_grey.png',
    present: '/icons/idraulico_blue.png',
  },
};
