import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  CollaboratorsListActionsMap,
  UsersListActionsMap,
} from 'constants/users';

export const useMyAreaWarningDialog = ({ handleDeleteMyAccount }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [actionSelected, setActionSelected] = useState();

  const handleSelectRow = action => {
    setOpenDialog(true);
    setActionSelected(action);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setActionSelected();
  };

  const handleDialogAction = () => {
    switch (actionSelected) {
      case CollaboratorsListActionsMap.DELETE:
        handleDeleteMyAccount();
        break;

      case UsersListActionsMap.DELETE:
        handleDeleteMyAccount();
        break;
    }
    handleCloseDialog();
  };

  return {
    openDialog,
    handleCloseDialog,
    handleDialogAction,
    actionSelected,
    handleSelectRow,
  };
};

useMyAreaWarningDialog.propTypes = {
  handleDeleteMyAccount: PropTypes.func,
};
