import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = props => {
  const {
    inputRef,
    onChange,
    fixedDecimalScale,
    decimalScale,
    withValueLimit,
    defaultValue,
    ...other
  } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value || '',
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale}
      type="text"
      isAllowed={withValueLimit}
      isNumericString
      defaultValue={defaultValue}
      {...other}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.objectOf(PropTypes.func),
  onChange: PropTypes.func,
  fixedDecimalScale: PropTypes.bool,
  decimalScale: PropTypes.number,
  withValueLimit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

NumberFormatCustom.defaultProps = {
  fixedDecimalScale: true,
  decimalScale: 2,
  withValueLimit: true,
};

export default NumberFormatCustom;
