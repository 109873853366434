import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const Container = styled('div')({
  backgroundColor: colors.WHITE,
  width: '100%',
  marginTop: 10,
  borderRadius: 8,
  padding: 15,
});

export const TextfieldContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: 10,
  marginBottom: 15,
});

export const BtnPanelContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 5,
  marginTop: 15,
});
