import { Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { DrawerMUI, Table } from 'components';

import { apiBridges } from 'constants/api';
import { UploadMedia } from 'constants/inspections';
import { downloadFile } from 'utils/upload';
import { formatData } from 'utils/utilities';
import { FilesContainer } from './DrawerExtraplatform.styles';

const DrawerExtraplatform = ({ setOpen, open, inspectionId }) => {
  const { get } = useFetch();

  const [extraplatformData, setExtraplatformData] = useState();

  const getMediaDocuments = async () => {
    try {
      const res = await get(`${apiBridges.INSPECTIONS}/${inspectionId}`);
      res &&
        setExtraplatformData({
          date: res.date,
          media: [res?.media],
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    open && inspectionId && getMediaDocuments();
  }, [inspectionId]);

  const media = extraplatformData?.media?.flat();
  const dataTable = media?.map(d => ({
    visualName: d.visualName,
    author: d.author,
    date: d.date,
    description: d.description,
    type: d.type,
    s3Path: d.s3Path,
    extensionFile: `.${d.fileName.split('.').at(-1)}`,
  }));

  const [value, setValue] = useState(null);

  const dataConfig = {
    columns: {
      visualName: { label: 'Nome file' },
      author: { label: 'Autore' },
      date: { label: 'Data' },
      description: { label: 'Descrizione' },
    },
  };

  return (
    <>
      <DrawerMUI
        open={open}
        setOpen={setOpen}
        title={'Ispezione Extra Piattaforma'}
      >
        <div style={{ marginTop: 60 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="Data ispezione"
                size="small"
                disabled
                InputLabelProps={{ shrink: true }}
                value={formatData(extraplatformData?.date)}
                onChange={newValue => {
                  setValue(newValue);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FilesContainer>
                <Typography variant="h3Bold">
                  Media caricati ({dataTable && dataTable.length})
                </Typography>

                {dataTable && dataTable.length !== 0 ? (
                  <>
                    <Table
                      data={dataTable}
                      config={dataConfig}
                      hasCheckbox={false}
                      previewDocuments={UploadMedia.documents}
                      /* previewDocuments={true} */
                      typevariant={'secondary'}
                      rowActions={[
                        {
                          onClick: item => {
                            if (item?.s3Path) {
                              downloadFile(item.s3Path);
                            }
                          },
                          /* mediaTable: true, */
                          /* downloadIcon: true, */
                          /* icon: <DownloadIcon />, */
                          icon: <DownloadIcon />,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Typography style={{ marginTop: -10 }} variant="h3">
                    Nessun file presente.
                  </Typography>
                )}
              </FilesContainer>
            </Grid>
          </Grid>
        </div>
      </DrawerMUI>
    </>
  );
};
DrawerExtraplatform.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  inspectionId: PropTypes.number,
};

export { DrawerExtraplatform };
