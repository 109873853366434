import { Check, Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { Buttons } from './FormSubmitButtons.styles';
const FormSubmitButtons = ({
  onSubmit,
  onReset,
  customSubmitIcon,
  customCancelIcon,
  disabled,
}) => {
  return (
    <Buttons>
      <IconButton disabled={disabled} color="primary" onClick={onSubmit}>
        {customSubmitIcon && <>{customSubmitIcon}</>}
        {!customSubmitIcon && <Check />}
      </IconButton>
      <IconButton disabled={disabled} color="primary" onClick={onReset}>
        {customCancelIcon && <>{customCancelIcon}</>}
        {!customCancelIcon && <Clear />}
      </IconButton>
    </Buttons>
  );
};
FormSubmitButtons.propTypes = {
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  customSubmitIcon: PropTypes.node,
  customCancelIcon: PropTypes.node,
  disabled: PropTypes.bool,
};
export { FormSubmitButtons };
