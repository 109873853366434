import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';

export const CustomFormControlLabel = styled(FormControlLabel)(
  ({ isTreeView }) => ({
    '& .MuiFormControlLabel-label': {
      /* fontSize: '0.70rem', */
      fontSize: isTreeView ? '0.80rem' : '0.70rem',
      fontFamily: `"Ubuntu", sans-serif`,
      fontWeight: !isTreeView && 'bold',
      lineheight: '1.31rem',
    },
  })
);
