import { WebL1Fields } from 'constants/inspectionFields';
import Accordion1CaratteristicheSovrastruttura from 'pages/Inspections/CreateInspection/L0/SuperStructures/Accordion1CaratteristicheSovrastruttura/Accordion1CaratteristicheSovrastruttura';
import Accordion2Impalcato from '../Accordion2Impalcato/Accordion2Impalcato';
import { Accordion3CommentiPerReportL1 } from '../Accordion3CommentiPerReport/Accordion3CommentiPerReportL1';

export const AccordionDataSuperstructureL1 = [
  {
    id: '1',
    title: '1. Caratteristiche elementi accessori',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion1CaratteristicheSovrastruttura level1={true} />,
    indicatorFields: WebL1Fields.ElementiAccessori.caratteristiche,
  },
  {
    id: '2',
    title: '2. Impalcato',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion2Impalcato />,
    indicatorFields: WebL1Fields.ElementiAccessori.impalcato,
  },
  {
    id: '3',
    title: '3. Annotazioni ispezione per report',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion3CommentiPerReportL1 />,
    /* indicatorFields: WebL1Fields.ElementiAccessori.annotazioniReport, */
  },
];
