import { pdf } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import React from 'react';
import useFetch from 'use-http';
import { useGlobalSelector } from 'stores';
import { downloadBlob } from 'utils/export';
import { SintesiCdASismica } from '../pdf';

const useExportCdaSismica = ({ id, intCode, setExportSuccess }) => {
  const { get } = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { get: exportLambdaUrl } = useFetch(
    process.env.REACT_APP_EXPORT_LAMBDA_URL
  );

  const date = new Date();
  const currentDate = date.toLocaleDateString().replaceAll('/', '-');
  const currentTime = date.toLocaleTimeString('it-IT').replaceAll(':', '-');

  const pdfSismicaName = `${intCode} - Sintesi CdA Sismica - ${currentDate} ${currentTime}`;

  const exportCdASismica = async formData => {
    try {
      showLoader();

      //const cdaSismica = await get(`exports/${id}/seismic-cda`);
      const cdaSismica = await exportLambdaUrl(`/?bridgeId=${id}&type=29`);
      if (cdaSismica) {
        setExportSuccess(true);
      } else {
        setExportSuccess(false);
      }

      const PDFCdASismica = await pdf(
        <SintesiCdASismica pdfData={cdaSismica} formData={formData} />
      ).toBlob();
      downloadBlob(PDFCdASismica, pdfSismicaName);
      return PDFCdASismica;
    } catch (error) {
      console.log(error);
      return enqueueSnackbar(`Impossibile eseguire l'esportazione`, {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }
  };

  return {
    exportCdASismica,
    pdfSismicaName,
  };
};

export default useExportCdaSismica;
