import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { CardOverview, CriticalBox } from 'components';
import { STRUCTURES_PATHS } from 'constants/paths';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import { handleDynamicInspectionsTabChange } from 'utils/utilities';
import { Row } from '../TabL1.styles';

const Struttura = ({ overviewL1 }) => {
  const params = useParams();
  const id = params.structureId;
  const navigate = useNavigate();

  const lastInspectionId = overviewL1?.requestUserLastValidInspectionId;

  // Elevazione
  const spalle = overviewL1?.struttura?.elevazione_spalla;
  const pile = overviewL1?.struttura?.elevazione_pila;

  // Collegamenti
  const appoggi = overviewL1?.struttura?.collegamenti_appoggi;
  const giunti = overviewL1?.struttura?.collegamenti_giunti;
  const pulvini = overviewL1?.struttura?.collegamenti_pulvini;
  const antenne = overviewL1?.struttura?.collegamenti_antenne;

  // Impalcato
  const solette = overviewL1?.struttura?.impalcato_solette;
  const travi = overviewL1?.struttura?.impalcato_travi;
  const traversi = overviewL1?.struttura?.impalcato_traversi;
  const controventi = overviewL1?.struttura?.impalcato_controventi;
  const archi = overviewL1?.struttura?.impalcato_archi;
  const piedritti = overviewL1?.struttura?.impalcato_piedritti;
  const tiranti = overviewL1?.struttura?.impalcato_tiranti;
  const elementiExtra = overviewL1?.struttura?.impalcato_elementiExtra;

  return (
    <CardOverview
      title={'Struttura'}
      subtitle={'Analisi dei difetti e definizione degli elementi critici'}
      buttonText={'Approfondisci'}
      buttonDisabled={lastInspectionId ? false : true}
      height="auto"
      onClick={() =>
        handleDynamicInspectionsTabChange(
          inspectionTabMapping.L1,
          inspectionTabMapping.Struttura,
          null,
          navigate,
          STRUCTURES_PATHS.STRUCTURES_LIST,
          id,
          lastInspectionId
        )
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <CardOverview
            title={'Elevazione'}
            buttonText={'Approfondisci'}
            buttonDisabled={lastInspectionId ? false : true}
            height="auto"
            subCard={true}
            onClick={() =>
              handleDynamicInspectionsTabChange(
                inspectionTabMapping.L1,
                inspectionTabMapping.Struttura,
                inspectionTabMapping.L1Elevazione,
                navigate,
                STRUCTURES_PATHS.STRUCTURES_LIST,
                id,
                lastInspectionId
              )
            }
          >
            <Row>
              <CriticalBox
                topic={spalle?.haElementi ? 'Spalle' : 'Spalla'}
                element={spalle}
              />
              <CriticalBox
                topic={pile?.haElementi ? 'Pile' : 'Pila'}
                element={pile}
              />
            </Row>
          </CardOverview>
        </Grid>
        <Grid item xs={12}>
          <CardOverview
            title={'Collegamenti'}
            buttonText={'Approfondisci'}
            buttonDisabled={lastInspectionId ? false : true}
            height="auto"
            subCard={true}
            onClick={() =>
              handleDynamicInspectionsTabChange(
                inspectionTabMapping.L1,
                inspectionTabMapping.Struttura,
                inspectionTabMapping.L1Collegamenti,
                navigate,
                STRUCTURES_PATHS.STRUCTURES_LIST,
                id,
                lastInspectionId
              )
            }
          >
            <Row style={{ flexWrap: 'wrap', marginTop: 8 }}>
              <CriticalBox
                topic={appoggi?.haElementi ? 'Appoggi' : 'Appoggio'}
                element={appoggi}
              />
              <CriticalBox
                topic={giunti?.haElementi ? 'Giunti' : 'Giunto'}
                element={giunti}
              />
              <CriticalBox
                topic={pulvini?.haElementi ? 'Pulvini' : 'Pulvino'}
                element={pulvini}
              />
              <CriticalBox
                topic={antenne?.haElementi ? 'Antenne' : 'Antenna'}
                element={antenne}
              />
            </Row>
          </CardOverview>
        </Grid>
        <Grid item xs={12}>
          <CardOverview
            title={'Impalcato'}
            buttonText={'Approfondisci'}
            buttonDisabled={lastInspectionId ? false : true}
            height="auto"
            subCard={true}
            onClick={() =>
              handleDynamicInspectionsTabChange(
                inspectionTabMapping.L1,
                inspectionTabMapping.Struttura,
                inspectionTabMapping.L1Impalcato,
                navigate,
                STRUCTURES_PATHS.STRUCTURES_LIST,
                id,
                lastInspectionId
              )
            }
          >
            <Row style={{ flexWrap: 'wrap', marginTop: 8 }}>
              <CriticalBox
                topic={solette?.haElementi ? 'Solette' : 'Soletta'}
                element={solette}
              />
              <CriticalBox
                topic={travi?.haElementi ? 'Travi' : 'Trave'}
                element={travi}
              />
              <CriticalBox
                topic={traversi?.haElementi ? 'Traversi' : 'Traverso'}
                element={traversi}
              />
              <CriticalBox
                topic={controventi?.haElementi ? 'Controventi' : 'Controvento'}
                element={controventi}
              />
              <CriticalBox
                topic={archi?.haElementi ? 'Archi' : 'Arco'}
                element={archi}
              />
              <CriticalBox
                topic={piedritti?.haElementi ? 'Piedritti' : 'Piedritto'}
                element={piedritti}
              />
              <CriticalBox
                topic={tiranti?.haElementi ? 'Tiranti' : 'Tirante'}
                element={tiranti}
              />
              <CriticalBox
                topic={
                  elementiExtra?.haElementi
                    ? 'Elementi extra'
                    : 'Elemento extra'
                }
                element={elementiExtra}
              />
            </Row>
          </CardOverview>
        </Grid>
      </Grid>
    </CardOverview>
  );
};

export { Struttura };

Struttura.propTypes = {
  overviewL1: PropTypes.obj,
};
