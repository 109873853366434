import {
  ADMIN_ROOT,
  ANALYTICS_ROOT,
  DOCUMENTS_PATHS,
  HOME_BO,
  IMAGES_PATHS,
  INSPECTIONS_PATHS,
  STRUCTURES_PATHS,
  USER_ROOT,
} from 'constants/paths';

export const sidebarMenu = [
  {
    id: 1,
    label: 'Home',
    img: '/icons/home-icn.svg',
    imgSelected: '/icons/home-selected-icn.svg',
    path: HOME_BO,
  },
  {
    id: 2,
    label: 'Ponti',
    img: '/icons/bridge-icn.svg',
    imgSelected: '/icons/bridge-selected-icn.svg',
    path: STRUCTURES_PATHS.STRUCTURES_LIST,
  },
  {
    id: 3,
    label: 'Attività',
    img: '/icons/inspections-icn.svg',
    imgSelected: '/icons/inspections-selected-icn.svg',
    path: INSPECTIONS_PATHS.INSPECTIONS_LIST,
  },
  {
    id: 4,
    label: 'Immagini',
    img: '/icons/images-icn.png',
    imgSelected: '/icons/images-selected-icn.png',
    path: IMAGES_PATHS.IMAGES_LIST,
  },
  {
    id: 5,
    label: 'Documenti',
    img: '/icons/documents-icn.png',
    imgSelected: '/icons/documents-selected-icn.png',
    path: DOCUMENTS_PATHS.DOCUMENTS_LIST,
  },
];

export const sidebarMenuAdmin = [
  {
    id: 1,
    label: 'Dashboard',
    img: '/icons/home-icn.svg',
    imgSelected: '/icons/home-selected-icn.svg',
    path: ADMIN_ROOT,
  },
  {
    id: 2,
    label: 'Utenti',
    img: '/icons/icon-user.svg',
    imgSelected: '/icons/icon-user-selected.svg',
    path: USER_ROOT,
  },
  {
    id: 3,
    label: 'Analytics',
    img: '/icons/icon-analytics.svg',
    imgSelected: '/icons/icon-analytics-selected.svg',
    path: ANALYTICS_ROOT,
  },
];
