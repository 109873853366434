import {
  COLLABORATORS_PATHS,
  DOCUMENTS_PATHS,
  HOME_BO,
  IMAGES_PATHS,
  INSPECTIONS_PATHS,
  STRUCTURES_PATHS,
} from './paths';

/**
 * this array should contain all of the paths where
 * the "macro" button on the sidebar should be rendered
 */
export const tour_paths = [
  HOME_BO,
  STRUCTURES_PATHS.STRUCTURES_LIST,
  STRUCTURES_PATHS.STRUCTURE_DETAIL,
  STRUCTURES_PATHS.INSPECTIONS_ARCHIVE,
  INSPECTIONS_PATHS.INSPECTIONS_LIST,
  DOCUMENTS_PATHS.DOCUMENTS_LIST,
  IMAGES_PATHS.IMAGES_LIST,
  COLLABORATORS_PATHS.EDIT_EXTERNAL_COLLBAORATOR,
  COLLABORATORS_PATHS.EDIT_INTERNAL_COLLBAORATOR,
  STRUCTURES_PATHS.INSPECTION_EDIT,
  STRUCTURES_PATHS.INSPECTION_VIEW,
  COLLABORATORS_PATHS.ROOT,
];
/**
 * Home Page - Macro Tour
 */

export const tour_01 = {
  step_01: 'tour-01-section-home-step-01',
  step_02: 'tour-01-section-home-step-02',
  step_02b: 'tour-01-section-home-step-02-b',
  step_02c: 'tour-01-section-home-step-02-c',
  step_03: 'tour-01-section-home-step-03',
  step_04: 'tour-01-section-home-step-04',
};
/**
 * Lista Ponti - Macro Tour
 */
export const tour_02 = {
  step_01: 'tour-02-section-bridges-step-01',
  step_02: 'tour-02-section-bridges-step-02',
  step_02b: 'tour-02-section-bridges-step-02b',
  step_03: 'tour-02-section-bridges-step-03',
  step_04: 'tour-02-section-bridges-step-04',
};
/**
 * Dettaglio Ponte - Macro Tour
 */
export const tour_03 = {
  step_01: 'tour-03-section-bridge-detail-step-01',
  step_02: 'tour-03-section-bridge-detail-step-02',
  step_03: 'tour-03-section-bridge-detail-step-03',
  step_04: 'tour-03-section-bridge-detail-step-04',
};
/**
 * Pannello Nuova Ispezione - Micro Tour
 */
export const tour_04 = {
  step_01: 'tour-04-section-drawer-new-inspection-step-01',
};
/**
 * Archivio Ispezioni - Macro Tour
 */
export const tour_05 = {
  step_01: 'tour-05-section-inspections-archive-step-01',
};
/**
 * Modifica Ispezione - Macro Tour
 */
export const tour_06 = {
  step_01: 'tour-06-section-edit-inspection-step-01',
  step_02: 'tour-06-section-edit-inspection-step-02',
  step_03: 'tour-06-section-edit-inspection-step-03',
  step_04: 'tour-06-section-edit-inspection-step-04',
  step_05: 'tour-06-section-edit-inspection-step-05',
};
export const tour_06a = {
  step_01: 'tour-06a-section-view-inspection-step-01',
};
/**
 * Pannelo upload media getsione tag - Micro Tour
 */
export const tour_07 = {
  step_01: 'tour-07-section-upload-media-step-01',
};
/**
 * Sezione L0/Contesto/Informazioni Generali - Micro Tour
 */
export const tour_08 = {
  step_01: 'tour-08-section-l0-contesto-informazionigenerali-step-01',
};
/**
 * Sezione L0/Struttura/ Caratteristiche Geometriche - Micro Tour
 */
export const tour_09 = {
  step_01: 'tour-09-section-l0-struttura-caratteristiche-geometriche-step-01',
  step_02: 'tour-09-section-l0-struttura-caratteristiche-geometriche-step-02',
  step_03: 'tour-09-section-l0-struttura-caratteristiche-geometriche-step-03',
};
/**
 * Sezione L0/Struttura/ Caratteristiche Strutturali - Micro Tour
 */
export const tour_10 = {
  step_01: 'tour-10-section-l0-struttura-caratteristiche-strutturali-step-01',
  step_02: 'tour-10-section-l0-struttura-caratteristiche-strutturali-step-02',
};
/**
 * Sezione L0>Struttura>Collegamenti>Copia- Micro Tour
 */
export const tour_11 = {
  step_01: 'tour-11-section-l0-struttura-collegamenti-step-01',
};
/**
 * Sezione L0/Elementi Accessori/ Caratteristiche Elementi Accessori - Micro Tour
 */
export const tour_12 = {
  step_01:
    'tour-12-section-l0-elementi-accessori-caratteristiche-elementi-accessori-step-01',
};
/**
 * Sezione L1>Struttura>Elevazione>Copia- Micro Tour
 */
export const tour_13 = {
  step_01: 'tour-13-section-l1-struttura-elevazione-step-01',
};
/**
 * Sezione Ispezioni - Macro Tour
 */
export const tour_14 = {
  step_01: 'tour-14-section-inspections-list-step-01',
  step_02: 'tour-14-section-inspections-list-step-02',
};
/**
 * Sezione Documenti - Macro Tour
 */
export const tour_15 = {
  step_01: 'tour-15-section-documents-list-step-01',
  step_02: 'tour-15-section-documents-list-step-02',
};
/**
 * Sezione Immagini - Macro Tour
 */
export const tour_16 = {
  step_01: 'tour-16-section-images-list-step-01',
  step_02: 'tour-16-section-images-list-step-02',
};
/**
 * Sezione Modifica Collaboratore Interno/Esterno - Macro Tour
 */
export const tour_17 = {
  step_01: 'tour-17-section-edit-collaborator-step-01',
  step_02: 'tour-17-section-edit-collaborator-step-02',
};
/**
 * Sezione Lista Collaboratorio - Macro Tour
 */
export const tour_17a = {
  step_01: 'tour-17a-section-edit-collaborator-step-01',
};
