import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { colors } from 'constants/colors';

import { useCompletionInspection } from 'hooks';
import { AccordionContainer } from './AccordionCustom.style';

function AccordionCustom({ accordions, checkExpanded }) {
  const { state } = useLocation();

  const [initialAccordion, setInitialAccordion] = useState(
    state?.inspectionLevelSubTabAccordion
  );

  const { getFieldsCount, updateIndicator } = useCompletionInspection();

  useEffect(() => {
    setInitialAccordion(state?.inspectionLevelSubTabAccordion || undefined);
  }, [state]);

  return (
    <div>
      {accordions.map((data, idx) => {
        let done;
        let total;
        if (data.indicatorFields) {
          done = updateIndicator(data.indicatorFields);
          total = getFieldsCount(data.indicatorFields);
          /* console.log(
            data.title,
            `completamento ${updateIndicator(
              data.indicatorFields
            )}/${getFieldsCount(data.indicatorFields)}`
          ); */
        }
        return (
          <AccordionContainer
            key={idx}
            defaultExpanded={checkExpanded ? initialAccordion === idx : false}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    width: '33%',
                    flexShrink: 0,
                    fontWeight: 'bold',
                    fontSize: 15,
                  }}
                >
                  {data.title}
                </Typography>
                {done !== undefined && total !== undefined && total !== 0 && (
                  <div style={{ display: 'flex', gap: 4 }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 11 }}>
                      Dati Presenti:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 11,
                        color:
                          done == total ? `${colors.GREEN}` : `${colors.BLACK}`,
                      }}
                    >
                      {done}/{total}
                    </Typography>
                  </div>
                )}
              </div>
            </AccordionSummary>

            <AccordionDetails>
              <div
                style={{
                  width: '100%',
                  height: '2px',
                  marginTop: '-14px',
                  marginBottom: '40px',
                  backgroundColor: `${colors.GREY}`,
                }}
              ></div>
              {data.content}
            </AccordionDetails>
          </AccordionContainer>
        );
      })}
    </div>
  );
}

export { AccordionCustom };

AccordionCustom.propTypes = {
  accordions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.node,
    })
  ),
  checkExpanded: PropTypes.bool,
};
