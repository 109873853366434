import { useCallback } from 'react';
// eslint-disable-next-line import/no-named-as-default
import useFetch from 'use-http';

import { apiBridges } from 'constants/api';

export function useInspectionUnlockFetch() {
  const { loading, error, put } = useFetch(
    `${apiBridges.ROOT}${apiBridges.INSPECTIONS_ARCHIVE}`
  );

  const onInspectionUnlock = useCallback(
    inspectionId => put(`/${inspectionId}/unlock`),
    [put]
  );

  return {
    loading,
    error,
    onInspectionUnlock,
  };
}
