import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const Container = styled('div')({
  marginTop: -30,
});

export const WhiteContainer = styled('div')({
  width: '100%',
  borderRadius: 8,
  backgroundColor: colors.WHITE,
  padding: 24,
  marginBottom: 20,
});

export const VideoContainer = styled('div')({
  width: '100%',
  backgroundColor: colors.LIGHTGREY,
  padding: 20,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  justifyContent: 'space-between',
  marginTop: 10,
  '& iframe': {
    width: '100%',
  },
});

export const InfoFlex = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

export const IconShowPdf = styled('img')({
  cursor: 'pointer',
});
