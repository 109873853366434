import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { InfoWrapper, MediaUpload, RHFSelect } from 'components';
import {
  AttivitaMonitoraggioPregresseInCorso,
  FieldType,
  InspezioniPregresse,
  InterventiEseguiti,
  InterventiManutenzione,
  MediaGroup,
  Segnalazioni,
  UploadMedia,
  VerificaSismica,
  VerificaStatica,
} from 'constants/inspections';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap, TitleSection } from './Accordion7PreviousActivities.styles';
import { INFO } from '../../../../config/info';

const Accordion7PreviousActivities = () => {
  const { watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchIspezioniPregresse = watch('ispezioniPregresse');
  const watchIspezioniPregressePresenti =
    watchIspezioniPregresse === InspezioniPregresse.Presenti;

  const watchSegnalazioni = watch('segnalazioni');
  const watchSegnalazioniPresenti = watchSegnalazioni === Segnalazioni.Presenti;

  const watchInterventiEseguiti = watch('interventiEseguiti');
  const watchInterventiEseguitiPresenti =
    watchInterventiEseguiti === InterventiEseguiti.Presenti;

  const watchAttivitaMonitoraggio = watch(
    'attivitaMonitoraggioPregresseInCorso'
  );
  const watchAttivitaMonitoraggioPresenti =
    watchAttivitaMonitoraggio === AttivitaMonitoraggioPregresseInCorso.Presenti;

  const watchInterventiManutenzione = watch('interventiManutenzione');
  const watchInterventiManutenzionePresenti =
    watchInterventiManutenzione === InterventiManutenzione.Presenti;

  const watchVerificaStatica = watch('verificaStatica');
  const watchVerificaStaticaPresente =
    watchVerificaStatica === VerificaStatica.Presente;

  const watchVerificaSismica = watch('verificaSismica');
  const watchVerificaSismicaPresente =
    watchVerificaSismica === VerificaSismica.Presente;

  return (
    <>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={6}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              VERIFICA DI SICUREZZA - STATICA
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap marginBottom={-25} marginTop={16} item xs={6}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              VERIFICA DI SICUREZZA - SISMICA
            </Typography>
          </TitleSection>
        </GridWrap>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          legislationMessage={INFO.verificaStatica.legislationMessage}
          drawerTitle={INFO.verificaStatica.drawerTitle}
          drawerText={INFO.verificaStatica.drawerText}
        >
          <RHFSelect
            name="verificaStatica"
            label={'Verifica statica'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Presente',
                value: VerificaStatica.Presente,
              },
              {
                label: 'Assente',
                value: VerificaStatica.Assente,
              },
            ]}
          />
        </InfoWrapper>

        <GridWrap item md={3}>
          <MediaUpload
            disabled={!watchVerificaStaticaPresente}
            title={`Documenti della verifica`}
            btnTitle="Allega file"
            dataType=".pdf,.doc,.docx,.jpeg,.png"
            formName="documentiVerificaStatica"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.AttivitaPregresse]}
          />
        </GridWrap>

        <InfoWrapper
          size={3}
          infoDrawer={true}
          legislationMessage={INFO.verificaSismica.legislationMessage}
          drawerTitle={INFO.verificaSismica.drawerTitle}
          drawerText={INFO.verificaSismica.drawerText}
        >
          <RHFSelect
            name="verificaSismica"
            label={'Verifica sismica'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Presente',
                value: VerificaSismica.Presente,
              },
              {
                label: 'Assente',
                value: VerificaSismica.Assente,
              },
            ]}
          />
        </InfoWrapper>
        <GridWrap item md={3}>
          <MediaUpload
            disabled={!watchVerificaSismicaPresente}
            title={`Documenti della verifica`}
            btnTitle="Allega file"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            formName="documentiVerificaSismica"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.AttivitaPregresse]}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={30} item xs={6}>
          <TitleSection>
            <Typography variant="h3BoldBlue">ISPEZIONI</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap marginBottom={-25} marginTop={16} item xs={6}>
          <TitleSection>
            <Typography variant="h3BoldBlue">SEGNALAZIONI</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="ispezioniPregresse"
            label={'Ispezioni pregresse'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Presenti',
                value: InspezioniPregresse.Presenti,
              },
              {
                label: 'Assenti',
                value: InspezioniPregresse.Assenti,
              },
              {
                label: 'Non note',
                value: InspezioniPregresse.NonNote,
              },
            ]}
          />
        </GridWrap>

        <GridWrap item md={3}>
          <MediaUpload
            disabled={!watchIspezioniPregressePresenti}
            title={`Documenti di ispezione`}
            btnTitle="Allega file"
            dataType=".pdf,.doc,.docx,.jpeg,.png"
            formName="documentiIspezione"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.AttivitaPregresse]}
          />
        </GridWrap>

        <GridWrap item xs={3}>
          <RHFSelect
            name="segnalazioni"
            label={'Segnalazioni'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Presenti',
                value: Segnalazioni.Presenti,
              },
              {
                label: 'Assenti',
                value: Segnalazioni.Assenti,
              },
              {
                label: 'Non note',
                value: Segnalazioni.NonNote,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            disabled={!watchSegnalazioniPresenti}
            title={`Documenti di segnalazione`}
            btnTitle="Allega file"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            formName="documentiSegnalazione"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.AttivitaPregresse]}
            tags={[
              'Documento descrittivo con date',
              'Documento grafico con date',
            ]}
          />
        </GridWrap>
      </GridWrap>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={30} item xs={6}>
          <TitleSection>
            <Typography variant="h3BoldBlue">INTERVENTI STRUTTURALI</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap marginBottom={-25} marginTop={30} item xs={6}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              ATTIVITÀ DI MONITORAGGIO
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="interventiEseguiti"
            label={'Interventi eseguiti'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Presenti',
                value: InterventiEseguiti.Presenti,
              },
              {
                label: 'Assenti',
                value: InterventiEseguiti.Assenti,
              },
              {
                label: 'Non noti',
                value: InterventiEseguiti.NonNote,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <MediaUpload
            disabled={!watchInterventiEseguitiPresenti}
            title={`Documenti intervento eseguito`}
            btnTitle="Allega file"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            formName="documentiInterventoEseguito"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.AttivitaPregresse]}
            tags={[
              'Volta in muratura',
              'Riparazione/Sostituzione di elementi strutturali',
              'Ampliamento di carreggiata e delle strutture',
              'Elementi strutturali aggiuntivi/consolidamento',
              'Interventi di carattere geotecnico in fondazione e/o in corrispondenza delle spalle',
              `Interventi di mitigazione/protezione dall'erosione di spalle e pile`,
            ]}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="attivitaMonitoraggioPregresseInCorso"
            label={'Attività di monitoraggio pregresse o in corso'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Presenti',
                value: AttivitaMonitoraggioPregresseInCorso.Presenti,
              },
              {
                label: 'Assenti',
                value: AttivitaMonitoraggioPregresseInCorso.Assenti,
              },
              {
                label: 'Non note',
                value: AttivitaMonitoraggioPregresseInCorso.NonNote,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <MediaUpload
            disabled={!watchAttivitaMonitoraggioPresenti}
            title={`Documenti attività di monitoraggio`}
            btnTitle="Allega file"
            dataType=".pdf,.doc,.docx,.jpeg,.png"
            formName="documentiAttivitaMonitoraggio"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.AttivitaPregresse]}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={30} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              INTERVENTI DI MANUTENZIONE
            </Typography>
          </TitleSection>
        </GridWrap>

        <GridWrap item xs={3}>
          <RHFSelect
            name="interventiManutenzione"
            label={'Interventi di manutenzione'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Presenti',
                value: InterventiManutenzione.Presenti,
              },
              {
                label: 'Assenti',
                value: InterventiManutenzione.Assenti,
              },
              {
                label: 'Non note',
                value: InterventiManutenzione.NonNote,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <MediaUpload
            disabled={!watchInterventiManutenzionePresenti}
            title={`Documenti intervento di manutenzione`}
            btnTitle="Allega file"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            formName="documentiInterventoManutenzione"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.AttivitaPregresse]}
            tags={[
              'Documento descrittivo',
              'Documento grafico',
              'Documento contrattuale e contabile',
            ]}
          />
        </GridWrap>
      </GridWrap>
    </>
  );
};

export { Accordion7PreviousActivities };
