import { useFetch } from 'use-http';

import { apiUsers } from 'constants/api';
import { useAuthStore, useGlobalSelector } from 'stores';

const useUsers = () => {
  const { get, put } = useFetch();
  const { showLoader, hideLoader } = useGlobalSelector();
  const getUserInfo = async () => {
    let data;
    try {
      showLoader();
      data = await get(apiUsers.USERS_ME);
      useAuthStore.setState({ user: data });
      return data;
    } catch (err) {
      console.log('Error in fetch user: ', err);
      throw err;
    } finally {
      hideLoader();
    }
  };

  const getUserDeletionInfo = async () => {
    let data;
    try {
      showLoader();
      data = await get(apiUsers.DELETIONINFO);
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      hideLoader();
    }
  };

  const userCancelDelete = async () => {
    try {
      showLoader();
      await put(apiUsers.CANCELDELETION);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      hideLoader();
    }
  };

  return {
    getUserInfo,
    getUserDeletionInfo,
    userCancelDelete,
  };
};

export { useUsers };
