import { colors } from 'constants/colors';

export const CdaColorMap = {
  1: {
    labelColor: colors.WILDWILLOW,
    labelText: 'BASSA',
  },
  2: {
    labelColor: colors.SPROUT,
    labelText: 'MEDIO-BASSA',
  },
  3: {
    labelColor: colors.CREAMBRULEE,
    labelText: 'MEDIA',
  },
  4: {
    labelColor: colors.SUNGLOW,
    labelText: 'MEDIO-ALTA',
  },
  5: {
    labelColor: colors.MAUVELOUS,
    labelText: 'ALTA',
  },
  6: {
    labelColor: colors.SILVER,
    labelText: 'DA DEFINIRE',
  },
};

const franaRischio = {
  DaVerificare: 1,
  Assente: 2,
  Presente: 3,
};

export const pdfFrane = {
  statusCdA: 2,
  stradaAppartenenza: 'AP 2382',
  progressivaIniziale: 23000,
  enteScavalcato: [1, 5],
  rischioFrana: franaRischio.Presente,
  foto: 'https://tecnoindaginistack-demo-media.s3-eu-west-1.amazonaws.com/public/bridges/11/media/Foto copertina_1660118586720.JPG',
  ortoFoto: null,
  mappa: null,
  pericolisitaPAIPSAI: 1,
  unitaFisiografica: 3,
  fenomeno: 1,
  tipologiaFenomeno: 4,
  distribuzioneAttivita: 4,
  tipologiaImpalcato: 3,
  estensioneInterferenza: 2,
  usoDelSuolo: 11,
  misureMitigazione: 2,
  parametroStatoAttivita: 2,
  parametroVelocitaPotenziale: 2,
  magnitudoBaseVolumetrica: 4,
  nCampate: 2,
  instabilitaVersante: 5,
  dataIspezioneRischioFrane: '19/01/2023',
  rilevatoreFrana: 'Mario Rossi',
  lunghezzaTotale: 185,
  // IDRAULICA
  rischioIdraulico: 1,
  superficieEffettiva: null,
  superficiePresunta: 2,
  tipologiaReticolo: 1,
  morfologiaAlveo: 2,
  evoluzioneAlveo: 2,
  tipologiaCorsoAcqua: 1,
  luceIdraulicaMin: 180,
  luceIdraulicaMax: 234,
  altezzaUtile: 30,
  evidenzaProfonditaFondazioni: 2,
  partiInterferisconoAlveo: [1, 2],
  presenzaStudioIdraulico: 1,
  fenomenoSormonto: 2,
  fenomenoErosione: 5,

  // SISMICA & STRUTTURALE E FONDAZIONALE
  difettosita: 1,
  tgm: 2040,
  tgmCommerciali: 700,
  categoriaTopografica: 'T1',
  categoriaSottosuolo: 'A',
  fotoDifetti: [
    'https://tecnoindaginistack-demo-media.s3-eu-west-1.amazonaws.com/public/bridges/11/media/Foto copertina_1660118586720.JPG',
  ],
  /* fotoDifetti: [], */
  criticalElements: 1,
  criticalConditions: 1,
  elevazioniG1: 3,
  collegamentiG5: 1,
  ic: '93',
  usoStradale: 3,
  numeroCarreggiate: 2,
  numeroCorsieCarreggiata: 4,
  alternativaStradale: 2,
  limiteCarico: 4,
  tipologiaStrutturale: 1,
  annoCostruzione: 2,
  convogliamentoAcque: 3,
};
