import { pdf } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import React from 'react';
import useFetch from 'use-http';
import { useGlobalSelector } from 'stores';
import { downloadBlob } from 'utils/export';
import { SintesiCdAFrane } from '../pdf';

const useExportCdaFrane = ({ id, intCode, setExportSuccess }) => {
  const { get } = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { get: exportLambdaUrl } = useFetch(
    process.env.REACT_APP_EXPORT_LAMBDA_URL
  );

  const date = new Date();
  const currentDate = date.toLocaleDateString().replaceAll('/', '-');
  const currentTime = date.toLocaleTimeString('it-IT').replaceAll(':', '-');

  const pdfFraneName = `${intCode} - Sintesi CdA Frane - ${currentDate} ${currentTime}`;

  const exportCdAFrane = async formData => {
    try {
      showLoader();
      //const cdaFrane = await get(`exports/${id}/landslide-cda`);
      const cdaFrane = await exportLambdaUrl(`/?bridgeId=${id}&type=31`);
      if (cdaFrane) {
        setExportSuccess(true);
      } else {
        setExportSuccess(false);
      }

      const PDFCdAFrane = await pdf(
        <SintesiCdAFrane pdfData={cdaFrane} formData={formData} />
      ).toBlob();
      downloadBlob(PDFCdAFrane, pdfFraneName);

      return PDFCdAFrane;
    } catch (error) {
      console.log(error);
      return enqueueSnackbar(`Impossibile eseguire l'esportazione`, {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }
  };
  return { exportCdAFrane, pdfFraneName };
};

export default useExportCdaFrane;
