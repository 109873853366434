import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

import PropTypes from 'prop-types';

//chart type
import React, { useLayoutEffect } from 'react';

export const GaugeBands = ({ chartID, value }) => {
  const valueMapping = {
    0: 0.5,
    1: 1.5,
    2: 2.5,
  };
  useLayoutEffect(() => {
    /**
     * ---------------------------------------
     * This demo was created using amCharts 5.
     *
     * For more information visit:
     * https://www.amcharts.com/
     *
     * Documentation is available at:
     * https://www.amcharts.com/docs/v5/
     * ---------------------------------------
     */

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    const root = am5.Root.new(chartID);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    var chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360,
      })
    );

    // Create axis and its renderer
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
    var axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -20,
    });
    // axisRenderer.labels.template.set('visible', false);
    axisRenderer.grid.template.setAll({
      stroke: root.interfaceColors.get('background'),
      visible: true,
      strokeOpacity: 0.8,
    });
    axisRenderer.labels.template.setAll({
      fill: am5.color('#ffff'),
      fontSize: '0',
    });

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 3,
        // strictMinMax: true,
        renderer: axisRenderer,
      })
    );

    // Add clock hand
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
    var axisDataItem = xAxis.makeDataItem({});

    var clockHand = am5radar.ClockHand.new(root, {
      pinRadius: am5.percent(4),
      radius: am5.percent(90),
      bottomWidth: 6,
    });

    var bullet = axisDataItem.set(
      'bullet',
      am5xy.AxisBullet.new(root, {
        sprite: clockHand,
      })
    );

    xAxis.createAxisRange(axisDataItem);

    var label = chart.radarContainer.children.push(
      am5.Label.new(root, {
        fill: am5.color(0xffffff),
        centerX: am5.percent(90),
        textAlign: 'center',
        centerY: am5.percent(90),
        fontSize: '0',
      })
    );

    axisDataItem.set('value', valueMapping[value]);
    bullet.get('sprite').on('rotation', function () {
      var value = axisDataItem.get('value');
      var fill = am5.color(0x000000);
      xAxis.axisRanges.each(function (axisRange) {
        if (
          value >= axisRange.get('value') &&
          value <= axisRange.get('endValue')
        ) {
          fill = axisRange.get('axisFill').get('fill');
        }
      });

      label.set('text', Math.round(value).toString());

      clockHand.pin.animate({
        key: 'fill',
        to: fill,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic),
      });
      clockHand.hand.animate({
        key: 'fill',
        to: fill,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic),
      });
    });

    // setInterval(function () {
    //   axisDataItem.animate({
    //     key: "value",
    //     to: Math.round(Math.random() * 140 - 40),
    //     duration: 500,
    //     easing: am5.ease.out(am5.ease.cubic)
    //   });
    // }, 2000)

    chart.bulletsContainer.set('mask', undefined);

    // Create axis ranges bands
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Bands
    var bandsData = [
      {
        title: chartID === 'gauge-bands-evolution' ? '< 1945' : 'Stabilizzato',
        color: '#0f9747',
        lowScore: 0,
        highScore: 1,
      },
      {
        title: chartID === 'gauge-bands-evolution' ? '> 1980' : 'Assente',
        color: '#ee1f25',
        lowScore: 2,
        highScore: 3,
      },
      {
        title: chartID === 'gauge-bands-evolution' ? '1945-1980' : 'Monitorato',
        color: '#FFAD4C',
        lowScore: 1,
        highScore: 2,
      },
    ];

    am5.array.each(bandsData, function (data) {
      var axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}));

      axisRange.setAll({
        value: data.lowScore,
        endValue: data.highScore,
      });

      axisRange.get('axisFill').setAll({
        // visible: true,
        fill: am5.color(data.color),
        fillOpacity: 0.8,
      });

      axisRange.get('label').setAll({
        text: data.title,
        inside: true,
        radius: 5,
        fontSize: '0.7em',
        fill: root.interfaceColors.get('background'),
      });
    });

    // Make stuff animate on load
    chart.appear(100, 100);
    return () => {
      root.dispose();
    };
  }, [chartID]);

  return <div style={{ width: '100%', height: '100%' }} id={chartID}></div>;
};
GaugeBands.propTypes = {
  chartID: PropTypes.string,
  value: PropTypes.number,
};
