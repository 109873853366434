import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import {
  LocalTourWrapper,
  RHFDatePicker,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import { FieldType } from 'constants/inspections';
import { tour_07 } from 'constants/tour';
import { formatData } from 'utils/utilities';
import {
  BtnPanelContainer,
  Container,
  TextfieldContainer,
} from './EditPanelMedia.styles';
import { steps } from './EditPanelMedia.tourSteps';

export const EditPanelMedia = ({
  tags,
  mediaFields,
  selected,
  editState,
  setEditState,
  resetData,
  type,
}) => {
  const { getValues, setValue } = useFormContext();

  const mediaData = [...mediaFields];
  const idx = mediaData.findIndex(x => x.id === selected[0].id);
  const hasUrl = !!mediaData[idx]?.url || false;

  const onSubmitEditedData = () => {
    const extensionFile = `.${mediaData[idx].fileName?.split('.').pop()}`;
    const fileNameWithoutExt = getValues('nomeFile')?.replace(/\.[^/.]+$/, '');

    const fileName = `${fileNameWithoutExt}${extensionFile}`;
    mediaData[idx] = {
      ...mediaData[idx],
      visualName: mediaData[idx].url ? mediaData[idx].visualName : fileName,
      fileName: !mediaData[idx].url
        ? fileNameWithoutExt + '_' + new Date().valueOf() + extensionFile
        : mediaData[idx].fileName,
      author: getValues('autoreFile'),
      description: getValues('descrizioneFile'),
      tags: getValues('tagsFile'),
      date: formatData(getValues('dataFile') || new Date()),
    };

    setValue('mediaTable', mediaData);
    setEditState(!editState);
    resetData();
  };

  return (
    <Container>
      <TextfieldContainer>
        <RHFTextField name="nomeFile" label="Nome file" disabled={hasUrl} />
        <RHFTextField
          name="autoreFile"
          label={type === FieldType.documents ? 'Fonte' : 'Autore'}
        />
      </TextfieldContainer>
      <TextfieldContainer>
        <RHFDatePicker name="dataFile" label="Data" />
        <RHFTextField
          name="descrizioneFile"
          label="Descrizione"
          inputProps={{ maxLength: 100 }}
        />
      </TextfieldContainer>
      {tags?.length > 0 && (
        <TextfieldContainer className={`${tour_07.step_01}`}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-file"
            name={'tagsFile'}
            changeAutocomplete={() => {}}
            labelName={'option'}
            getOptionLabel={option => option}
            opt={tags}
            label="Tags"
          />
        </TextfieldContainer>
      )}

      <BtnPanelContainer>
        {tags?.length > 0 && <LocalTourWrapper steps={steps} />}
        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={() => {
            setEditState(!editState);
            resetData();
          }}
        >
          Rimuovi
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="small"
          onClick={onSubmitEditedData}
        >
          Salva
        </Button>
      </BtnPanelContainer>
    </Container>
  );
};

EditPanelMedia.propTypes = {
  mediaFields: PropTypes.array,
  tags: PropTypes.array,
  selected: PropTypes.array,
  editState: PropTypes.bool,
  setEditState: PropTypes.func,
  resetData: PropTypes.func,
  type: PropTypes.number,
};
