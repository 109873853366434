import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { DifettiInfoDrawer, DrawerMedia } from 'components';
import { RHFTextField } from 'components/form';
import { colors } from 'constants/colors';
import { difettosita_G, StatoDifetto } from 'constants/difetti';
import { infoDifetti } from 'constants/infoDifetti';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import {
  Box,
  IconButtonContainer,
} from './SchedaDifettiElementiStrutturaliRow.styles';

const SchedaDifettiElementiStrutturaliRow = ({ difetto, name, idx }) => {
  const { watch, register, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const scheda6ignore = [1, 2, 3, 5, 6, 8, 9, 10, 13, 14, 15, 22];
  const [open, setOpen] = useState(false);

  const vistoWatch = watch(`${name}.schedaDifetti[${idx}].visto`);
  const psWatch = watch(`${name}.schedaDifetti[${idx}].ps`);
  const estensioneDifettoWatch = watch(
    `${name}.schedaDifetti[${idx}].estensioneDifetto`
  );
  const intensitaDifettoWatch = watch(
    `${name}.schedaDifetti[${idx}].intensitaDifetto`
  );

  const scheda6ignoreWatch = watch(`${name}.schedaDifetti`).some(
    el => el.id == 95 && el.visto && el.statoDifetto
  );
  const isDisabledScheda6 =
    scheda6ignore.some(el => el == difetto.id) && scheda6ignoreWatch;

  useEffect(() => {
    if (difetto.id == 95) {
      setValue(`${name}.schedaDifetti[${idx}].scheda6ignore`, true);
    }
  }, []);

  useEffect(() => {
    if (vistoWatch === false) {
      setValue(`${name}.schedaDifetti[${idx}].statoDifetto`, null);
      setValue(`${name}.schedaDifetti[${idx}].estensioneDifetto`, null);
      setValue(`${name}.schedaDifetti[${idx}].intensitaDifetto`, null);
      setValue(`${name}.schedaDifetti[${idx}].ps`, null);
      setValue(`${name}.schedaDifetti[${idx}].note`, null);
    }
    if (
      vistoWatch === true &&
      (difetto.difettosita === difettosita_G.G4 ||
        difetto.difettosita === difettosita_G.G5) &&
      !psWatch
    ) {
      setValue(`${name}.schedaDifetti[${idx}].ps`, false);
    }
  }, [vistoWatch]);

  const statoDifettoWatch = watch(`${name}.schedaDifetti[${idx}].statoDifetto`);
  useEffect(() => {
    if (statoDifettoWatch) {
      setValue(`${name}.schedaDifetti[${idx}].estensioneDifetto`, null);
      setValue(`${name}.schedaDifetti[${idx}].intensitaDifetto`, null);
      setValue(`${name}.schedaDifetti[${idx}].ps`, null);
    }
  }, [statoDifettoWatch]);

  useEffect(() => {
    setValue(`${name}.schedaDifettiTrigger`, nanoid());
  }, [
    vistoWatch,
    intensitaDifettoWatch,
    estensioneDifettoWatch,
    statoDifettoWatch,
    psWatch,
  ]);

  /* const completamentoSchedaWatch = watch(`${name}.completamentoSchedaDifetti`); */
  /* useEffect(() => {
    setValue(containerName, getValues(containerName));
  }, [completamentoSchedaWatch]); */

  // useEffect(() => {
  //   calculateCDA(
  //     condizioneCriticaStrutturaWatch,
  //     elementoCriticoStrutturaWatch,
  //     'strutturale',
  //     name
  //   );
  // }, [condizioneCriticaStrutturaWatch, elementoCriticoStrutturaWatch]);

  // useEffect(() => {
  //   calculateCDA(
  //     condizioneCriticaSismicoWatch,
  //     elementoCriticoSismicoWatch,
  //     'sismica',
  //     name
  //   );
  // }, [elementoCriticoSismicoWatch, condizioneCriticaSismicoWatch]);

  if (isDisabledScheda6) return <></>;

  const renderMedia = () => {
    const difettiLength = getValues(
      `${name}.schedaDifetti[${idx}].media`
    )?.length;
    return (
      <IconButtonContainer>
        <div key={nanoid()}>
          {`${difettiLength || '-'} `}
          <IconButton
            style={{ marginRight: 10, padding: 0 }}
            color="primary"
            size="small"
            onClick={() => setOpen(true)}
            disabled={!vistoWatch}
          >
            <MediaIcon style={{ opacity: !vistoWatch ? '0.3' : 1 }} />
          </IconButton>
        </div>
      </IconButtonContainer>
    );
  };

  return (
    <>
      {difetto.rowTitle && (
        <>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              backgroundColor: '#F4F5F6',
            }}
          >
            <TableCell colSpan={16}>{difetto.rowTitle}</TableCell>
          </TableRow>
        </>
      )}
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          <Box>
            <DifettiInfoDrawer
              drawerTitle={`${difetto.descrizioneDifetto} -  ${difetto.codiceDifetto}`}
              difettosita={difetto.difettosita}
              infoDifetto={infoDifetti[difetto.id]}
            />
            {difetto.codiceDifetto}
          </Box>
        </TableCell>
        <TableCell>{difetto.descrizioneDifetto}</TableCell>
        <TableCell align="center">
          <input
            {...register(`${name}.schedaDifetti[${idx}].visto`)}
            type="checkbox"
            name={`${name}.schedaDifetti[${idx}].visto`}
            disabled={inspectionDisabled || isViewingInspection}
            // disabled={
            //   scheda6ignore.some(el => el == difetto.id) && scheda6ignoreWatch
            // }
          />
        </TableCell>
        <TableCell
          align="center"
          style={{ borderLeft: `2px solid ${colors.PALAGREY} ` }}
        >
          <input
            {...register(`${name}.schedaDifetti[${idx}].statoDifetto`)}
            disabled={!vistoWatch || inspectionDisabled || isViewingInspection}
            type="radio"
            name={`${name}.schedaDifetti[${idx}].statoDifetto`}
            value={StatoDifetto.NA}
            className="form-check-input"
            id={StatoDifetto.NA}
          />
        </TableCell>
        <TableCell align="center">
          <input
            {...register(`${name}.schedaDifetti[${idx}].statoDifetto`)}
            disabled={!vistoWatch || inspectionDisabled || isViewingInspection}
            type="radio"
            name={`${name}.schedaDifetti[${idx}].statoDifetto`}
            value={StatoDifetto.NR}
            className="form-check-input"
            id={StatoDifetto.NR}
          />
        </TableCell>
        <TableCell align="center">
          <input
            {...register(`${name}.schedaDifetti[${idx}].statoDifetto`)}
            disabled={!vistoWatch || inspectionDisabled || isViewingInspection}
            type="radio"
            name={`${name}.schedaDifetti[${idx}].statoDifetto`}
            value={StatoDifetto.NP}
            className="form-check-input"
            id={StatoDifetto.NP}
          />
        </TableCell>
        <TableCell
          align="center"
          style={{ borderLeft: `2px solid ${colors.PALAGREY} ` }}
        >
          <Typography>{difetto.difettosita}</Typography>
        </TableCell>
        <TableCell
          align="center"
          style={{ borderLeft: `2px solid ${colors.PALAGREY} ` }}
        >
          {difetto.estensione_k1.estensione_k1_val_1 && (
            <>
              <input
                {...register(`${name}.schedaDifetti[${idx}].estensioneDifetto`)}
                disabled={
                  statoDifettoWatch ||
                  !vistoWatch ||
                  inspectionDisabled ||
                  isViewingInspection
                }
                type="radio"
                name={`${name}.schedaDifetti[${idx}].estensioneDifetto`}
                value={difetto.estensione_k1.estensione_k1_val_1}
                className="form-check-input"
                id={difetto.estensione_k1.estensione_k1_val_1}
              />
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {difetto.estensione_k1.estensione_k1_val_2 && (
            <>
              <input
                {...register(`${name}.schedaDifetti[${idx}].estensioneDifetto`)}
                disabled={
                  statoDifettoWatch ||
                  !vistoWatch ||
                  inspectionDisabled ||
                  isViewingInspection
                }
                type="radio"
                name={`${name}.schedaDifetti[${idx}].estensioneDifetto`}
                value={difetto.estensione_k1.estensione_k1_val_2}
                className="form-check-input"
                id={difetto.estensione_k1.estensione_k1_val_2}
              />
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {difetto.estensione_k1.estensione_k1_val_3 && (
            <>
              <input
                {...register(`${name}.schedaDifetti[${idx}].estensioneDifetto`)}
                disabled={
                  statoDifettoWatch ||
                  !vistoWatch ||
                  inspectionDisabled ||
                  isViewingInspection
                }
                type="radio"
                name={`${name}.schedaDifetti[${idx}].estensioneDifetto`}
                value={difetto.estensione_k1.estensione_k1_val_3}
                className="form-check-input"
                id={difetto.estensione_k1.estensione_k1_val_3}
              />
            </>
          )}
        </TableCell>

        <TableCell
          align="center"
          style={{ borderLeft: `2px solid ${colors.PALAGREY} ` }}
        >
          {difetto.intensita_k2.intensita_k2_val_1 && (
            <>
              <input
                {...register(`${name}.schedaDifetti[${idx}].intensitaDifetto`)}
                disabled={
                  statoDifettoWatch ||
                  !vistoWatch ||
                  inspectionDisabled ||
                  isViewingInspection
                }
                type="radio"
                name={`${name}.schedaDifetti[${idx}].intensitaDifetto`}
                value={difetto.intensita_k2.intensita_k2_val_1}
                className="form-check-input"
                id={difetto.intensita_k2.intensita_k2_val_1}
              />
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {difetto.intensita_k2.intensita_k2_val_2 && (
            <>
              <input
                {...register(`${name}.schedaDifetti[${idx}].intensitaDifetto`)}
                disabled={
                  statoDifettoWatch ||
                  !vistoWatch ||
                  inspectionDisabled ||
                  isViewingInspection
                }
                type="radio"
                name={`${name}.schedaDifetti[${idx}].intensitaDifetto`}
                value={difetto.intensita_k2.intensita_k2_val_2}
                className="form-check-input"
                id={difetto.intensita_k2.intensita_k2_val_2}
              />
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {difetto.intensita_k2.intensita_k2_val_3 && (
            <>
              <input
                {...register(`${name}.schedaDifetti[${idx}].intensitaDifetto`)}
                disabled={
                  statoDifettoWatch ||
                  !vistoWatch ||
                  inspectionDisabled ||
                  isViewingInspection
                }
                type="radio"
                name={`${name}.schedaDifetti[${idx}].intensitaDifetto`}
                value={difetto.intensita_k2.intensita_k2_val_3}
                className="form-check-input"
                id={difetto.intensita_k2.intensita_k2_val_3}
              />
            </>
          )}
        </TableCell>
        <TableCell
          align="center"
          style={{ borderLeft: `2px solid ${colors.PALAGREY} ` }}
        >
          {(difetto.difettosita === difettosita_G.G4 ||
            difetto.difettosita === difettosita_G.G5) && (
            <input
              {...register(`${name}.schedaDifetti[${idx}].ps`)}
              disabled={
                statoDifettoWatch ||
                !vistoWatch ||
                inspectionDisabled ||
                isViewingInspection
              }
              type="checkbox"
              name={`${name}.schedaDifetti[${idx}].ps`}
            />
          )}
        </TableCell>
        <TableCell>{renderMedia()}</TableCell>
        <TableCell>
          <RHFTextField
            style={{ height: '40px' }}
            name={`${name}.schedaDifetti[${idx}].note`}
            label={''}
            disabled={!vistoWatch || inspectionDisabled || isViewingInspection}
            inputProps={{ maxLength: 200 }}
            defaultValue=""
          />
        </TableCell>
        <DrawerMedia
          open={open}
          setOpen={setOpen}
          title={`Media/${difetto.descrizioneDifetto} - ${difetto.codiceDifetto}`}
          infoPanel=""
          dataType="video/*,image/*"
          uploadType={UploadMedia.media}
          type={FieldType.images}
          formName={`${name}.schedaDifetti[${idx}].media`}
          mediaGroup={[MediaGroup.Struttura, MediaGroup.Difetti]}
        />
      </TableRow>
    </>
  );
};
SchedaDifettiElementiStrutturaliRow.propTypes = {
  difetto: PropTypes.shape({
    id: PropTypes.string,
    codiceDifetto: PropTypes.string,
    difettosita: PropTypes.number,
    descrizioneDifetto: PropTypes.string,
    estensione_k1: PropTypes.number,
    intensita_k2: PropTypes.number,
    visto: PropTypes.bool,
    statoDifetto: PropTypes.number,
    estensioneDifetto: PropTypes.number,
    intensitaDifetto: PropTypes.number,
    ps: PropTypes.bool,
    media: PropTypes.array,
    note: PropTypes.string,
    rowTitle: PropTypes.string,
    infoDifetto: PropTypes.object,
  }),
  name: PropTypes.string,
  containerName: PropTypes.string,
  idx: PropTypes.number,
};
export default SchedaDifettiElementiStrutturaliRow;
