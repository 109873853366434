import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaPiedrittoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
const PiedrittiForm = ({
  piedrittiFields,
  selected,
  setSelected,
  piedrittiTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t2a5_piedrittiTableValue: [],
      l0t2a5_tipologiaPiedrittoTableValue: '',
      l0t2a5_altezzaPiedrittoTableValue: '',
      l0t2a5_larghezzaPiedrittoTableValue: '',
      l0t2a5_profonditaPiedrittoTableValue: '',
      l0t2a5_superficiePiedrittoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a5_piedrittiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetPiedritti();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changePiedritti = () => {
    const l0t2a5_tipologiaPiedrittoTableValue = getValues(
      'l0t2a5_tipologiaPiedrittoTableValue'
    );
    const l0t2a5_altezzaPiedrittoTableValue = getValues(
      'l0t2a5_altezzaPiedrittoTableValue'
    );
    const l0t2a5_larghezzaPiedrittoTableValue = getValues(
      'l0t2a5_larghezzaPiedrittoTableValue'
    );
    const l0t2a5_profonditaPiedrittoTableValue = getValues(
      'l0t2a5_profonditaPiedrittoTableValue'
    );
    const l0t2a5_superficiePiedrittoTableValue = getValues(
      'l0t2a5_superficiePiedrittoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const piedritti = [...piedrittiFields];

    piedritti.map((x, index) => {
      if (ids.includes(x?.codice)) {
        piedritti[index] = {
          ...x,
          tipologiaPiedritto: l0t2a5_tipologiaPiedrittoTableValue,
          altezzaPiedritto: l0t2a5_altezzaPiedrittoTableValue,
          larghezzaPiedritto: l0t2a5_larghezzaPiedrittoTableValue,
          profonditaPiedritto: l0t2a5_profonditaPiedrittoTableValue,
          superficiePiedritto: l0t2a5_superficiePiedrittoTableValue,
          ...(l0t2a5_tipologiaPiedrittoTableValue !== x.tipologiaPiedritto
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'piedritto',
                  l0t2a5_tipologiaPiedrittoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(piedrittiTable, piedritti);
    onResetPiedritti();
  };

  const onConfirmChangePiedritti = () => {
    setOpenConfirmationDialog(false);
    changePiedritti();
  };

  const onSubmitPiedritti = () => {
    const l0t2a5_tipologiaPiedrittoTableValue = getValues(
      'l0t2a5_tipologiaPiedrittoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const piedritti = [...piedrittiFields];

    const hasChangedTipologia = piedritti.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a5_tipologiaPiedrittoTableValue !== el.tipologiaPiedritto &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaPiedritto)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changePiedritti();
    }
  };

  const onResetPiedritti = () => {
    setValue('l0t2a5_piedrittiTableValue', []);
    setValue('l0t2a5_tipologiaPiedrittoTableValue', '');
    setValue('l0t2a5_altezzaPiedrittoTableValue', '');
    setValue('l0t2a5_larghezzaPiedrittoTableValue', '');
    setValue('l0t2a5_profonditaPiedrittoTableValue', '');
    setValue('l0t2a5_superficiePiedrittoTableValue', '');

    setSelected([]);
  };

  const altezzaPiedrittoWatch = parseFloat(
    watch('l0t2a5_altezzaPiedrittoTableValue')
  );
  const larghezzaPiedrittoWatch = parseFloat(
    watch('l0t2a5_larghezzaPiedrittoTableValue')
  );
  const profonditaPiedritto = parseFloat(
    watch('l0t2a5_profonditaPiedrittoTableValue')
  );

  // [2*(Profondità+Larghezza)*Altezza]/10000
  useEffect(() => {
    const result = (
      (2 *
        (profonditaPiedritto + larghezzaPiedrittoWatch) *
        altezzaPiedrittoWatch) /
      10000
    ).toFixed(2);
    setValue('l0t2a5_superficiePiedrittoTableValue', result);
  }, [profonditaPiedritto, larghezzaPiedrittoWatch, altezzaPiedrittoWatch]);

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper
          size={6}
          infoMessage={INFO.piedrittiSelezionati.infoMessage}
        >
          <RHFTagAutocomplete
            multiple={true}
            id="tags-piedritti"
            name={'l0t2a5_piedrittiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={piedrittiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Piedritti selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_tipologiaPiedrittoTableValue"
            label="Tipologia Piedritto*"
            defaultValue={''}
            onChange={e => {
              setValue('l0t2a5_tipologiaPiedrittoTableValue', e.target.value);
            }}
            selectOptions={tipologiaPiedrittoOptions}
          />
        </GridWrap>
        <InfoWrapper size={3} infoMessage={INFO.altezzaPiedritto.infoMessage}>
          <RHFNumberTextField
            name="l0t2a5_altezzaPiedrittoTableValue"
            label={'Altezza (H) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_larghezzaPiedrittoTableValue"
            label={'Larghezza (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_profonditaPiedrittoTableValue"
            label={'Profondità (P) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoMessage={INFO.superficiePiedritto.infoMessage}
        >
          <RHFNumberTextField
            name="l0t2a5_superficiePiedrittoTableValue"
            label={'Superficie [m²]'}
            defaultValue=""
            decimalScale={2}
            disabled
            InputLabelProps={{ shrink: true }}
            withValueLimit={({ value }) => value < 1000}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <FormSubmitButtons
            onSubmit={onSubmitPiedritti}
            onReset={onResetPiedritti}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangePiedritti}
      />
    </>
  );
};

PiedrittiForm.propTypes = {
  piedrittiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaPiedritto: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      altezzaPiedritto: PropTypes.number,
      larghezzaPiedritto: PropTypes.number,
      profonditaPiedritto: PropTypes.number,
      superficiePiedritto: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  piedrittiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default PiedrittiForm;
