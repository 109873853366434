import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, GridWrap, InfoWrapper, Table } from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import { tipologiaSpartitrafficoOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import SpartitrafficoForm from './SpartitrafficoForm';
const SpartitrafficoSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const sovrastrutturaImpalcatoTableSpartitrafficoTableWatch = watch(
    `sovrastrutturaImpalcatoTable[${selectedIdx}].spartitrafficoTable`
  );

  // table setup
  const dataConfigSpartitraffico = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaSpartitraffico: {
        label: 'Tipologia Spartitraffico',
        format: tipologiaSpartitrafficoOptions,
      },
      tipologiaAltroSpartitraffico: {
        label: 'Tipologia Spartitraffico (Altro)',
      },

      larghezzaSpartitraffico: {
        label: 'L [cm]',
      },
      altezzaSpartitraffico: {
        label: 'H [cm]',
      },
      superficieSpartitraffico: {
        label: 'S [m²]',
      },
    },
  };

  // spartitraffico setup
  const [selectedSpartitraffico, setSelectedSpartitraffico] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsSpartitraffico = item => {
    setValue('l0t3a2_spartitrafficoTableValue', [item]);
    setValue(
      'l0t3a2_tipologiaSpartitrafficoTableValue',
      item.tipologiaSpartitraffico
    );
    setValue(
      'l0t3a2_tipologiaAltroSpartitrafficoTableValue',
      item.tipologiaAltroSpartitraffico
    );
    setValue(
      'l0t3a2_larghezzaSpartitrafficoTableValue',
      item.larghezzaSpartitraffico
    );
    setValue(
      'l0t3a2_altezzaSpartitrafficoTableValue',
      item.altezzaSpartitraffico
    );
    setValue(
      'l0t3a2_superficieSpartitrafficoTableValue',
      item.superficieSpartitraffico
    );
  };

  const handleSelectAllClickSpartitraffico = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].spartitrafficoTable`
      );
      setSelectedSpartitraffico(selectedValue);
      setValue('l0t3a2_spartitrafficoTableValue', selectedValue);
    } else {
      setSelectedSpartitraffico([]);
      setValue('l0t3a2_spartitrafficoTableValue', []);
    }
  };

  return (
    <>
      <InfoWrapper
        isDivider={true}
        size={12}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoSpartitraffico.drawerTitle}
        drawerText={INFO.impalcatoSpartitraffico.drawerText}
      >
        <Divider sx={{ marginTop: 3 }}>SPARTITRAFFICO</Divider>
      </InfoWrapper>
      {sovrastrutturaImpalcatoTableSpartitrafficoTableWatch?.length === 0 && (
        <GridWrap item xs={12}>
          <Typography>
            Nessun spartitraffico previsto in questo elemento
          </Typography>
        </GridWrap>
      )}
      {sovrastrutturaImpalcatoTableSpartitrafficoTableWatch?.filter(
        el => el.assente
      ).length > 0 && (
        <GridWrap item xs={12}>
          <Typography>
            Nessun spartitraffico presente in questo elemento
          </Typography>
        </GridWrap>
      )}
      {/* {sovrastrutturaImpalcatoTableSpartitrafficoTableWatch?.filter(
        el => !el.assente
      ).length === 0 && (
        <GridWrap item xs={12}>
          <Typography>
            Nessun spartitraffico presente in questo elemento
          </Typography>
        </GridWrap>
      )} */}
      {sovrastrutturaImpalcatoTableSpartitrafficoTableWatch?.filter(
        el => !el.assente
      ).length > 0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <SpartitrafficoForm
              spartitrafficoFields={
                sovrastrutturaImpalcatoTableSpartitrafficoTableWatch || []
              }
              selected={selectedSpartitraffico}
              setSelected={setSelectedSpartitraffico}
              spartitrafficoTable={`sovrastrutturaImpalcatoTable[${selectedIdx}].spartitrafficoTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Spartitraffico'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`sovrastrutturaImpalcatoTable[${selectedIdx}].spartitrafficoTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Sovrastruttura]}
          />
          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={
                  sovrastrutturaImpalcatoTableSpartitrafficoTableWatch.filter(
                    el => !el.assente
                  ) || []
                }
                config={dataConfigSpartitraffico}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsSpartitraffico(item);
                            setSelectedSpartitraffico([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        sovrastrutturaImpalcatoTableSpartitrafficoTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedSpartitraffico}
                onSelectAllClick={handleSelectAllClickSpartitraffico}
                setSelected={setSelectedSpartitraffico}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
SpartitrafficoSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default SpartitrafficoSection;
