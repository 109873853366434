const pathImages =
  'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/defects_info_images/';

export const infoDifetti = {
  1: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}1_k2=1.png`,
    description:
      'Il difetto si presenta con aree di colorazione diversa dal materiale integro. In particolare, si tratta di tracce di calcio rilasciate sulla superficie dall’umidità penetrata attraverso il calcestruzzo. Si parla di macchie di umidità passiva qualora il fenomeno si intende estinto e del quale restano macchie di colore biancastro.',
    phenomena: [
      'Fenomeni di dilavamento e di ammaloramento del calcestruzzo (C.a./c.a.p._3)',
    ],
  },

  2: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}2_k2=1.png`,
    description:
      'Il difetto si presenta con aree di colorazione diversa dal materiale integro. In particolare, si tratta di tracce di calcio rilasciate sulla superficie dall’umidità penetrata attraverso il calcestruzzo. A differenza delle macchie di umidità passiva, l’umidità attiva è legata a fenomeni di infiltrazione di acqua tutt’ora in corso e si presenta con macchie di colore scuro dovuto al contatto continuo con l’acqua e l’umidità',
    phenomena: [
      'Fenomeni di dilavamento e di ammaloramento del calcestruzzo (C.a./c.a.p._3)',
    ],
  },

  3: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}03_k2=1.jpg`,
    description:
      'Il dilavamento e/o l’ammaloramento del calcestruzzo sono dovuti alla percolazione di acque superficiali e si manifesta prevalentemente sulle superfici verticali o inclinate degli elementi. Per dilavamento si intende l’erosione dello strato superficiale di materiale dovuto al passaggio frequente di acqua, mentre con il termine ammaloramento ci si riferisce ai fenomeni di rigonfiamento superficiale del calcestruzzo, scagliamento, perdita di coesione, ecc.',
    phenomena: [
      'macchie di umidità (C.a./c.a.p._1, C.a./c.a.p._2)',
      `tracce di scolo (Dif.Gen_1)`,
    ],
    suggestions:
      'Il progredire di questo difetto può portare ad una riduzione della sezione resistente del calcestruzzo pericolosa se si sviluppa su elementi strutturali primari.',
  },
  4: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'Solo superficiale',
    k2_0_5: "Vista dell'inerte",
    k2_1: 'Inerte distaccabile',
    image_k2_0_2: `${pathImages}04_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}04_k2=0.5.jpg`,
    image_k2_1: `${pathImages}04_k2=1.jpg`,
    description:
      'I vespai o nidi di ghiaia sono difetti molto evidenti che compromettono la continuità superficiale del calcestruzzo. Possono interessare zone estese o essere localizzati in zone limitate della superficie degli elementi strutturali. Essi comportano la presenza di zone non omogenee sulle superfici degli elementi a causa dell’esposizione degli inerti più grossi di calcestruzzo, che, nei casi più gravi, sono asportabili manualmente.',
    phenomena: [
      'ossidazione e/o corrosione delle armature (C.a./c.a.p._6)',
      'ossidazione e/o corrosione delle staffe (C.a./c.a.p._16)',
      'tracce di scolo (Dif.Gen_1).',
    ],
  },
  5: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}05_k2=1.png`,
    description:
      'Il difetto si riferisce alla mancanza di porzioni di strato di ricoprimento in calcestruzzo delle armature longitudinali e trasversali degli elementi, con conseguente esposizione di queste ultime agli agenti ossidanti e corrosivi. Particolarmente soggette a distacchi di copriferro sono le travi più esterne dei ponti a travata, maggiormente esposte agli effetti degli agenti esterni. Tale fenomeno si concentra spesso in corrispondenza delle zone degli spigoli degli elementi, caratterizzate da un elevato rapporto superficie/volume esposto e quindi più soggette a distacchi.',
    phenomena: [
      'Fenomeni di dilavamento e di ammaloramento del calcestruzzo (C.a./c.a.p._3)',
      'vespai (C.a./c.a.p._4)',
      'fenomeni di ossidazione e/o corrosione delle armature esposte (C.a./c.a.p._6, C.a./c.a.p._16)',
    ],
  },
  6: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'Ossidata',
    k2_0_5: 'Intaccata la sezione della barra',
    k2_1: 'Corrosa con diminuz. di sezione',
    image_k2_0_2: `${pathImages}06_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}06_k2=0.5.jpg`,
    image_k2_1: `${pathImages}06_k2=1.jpg`,
    description:
      'A causa della mancanza o della carenza di uno strato di ricoprimento in calcestruzzo adeguato, le armature in acciaio, a contatto con agenti aggressivi esterni, appaiono ossidate o corrose. Nei casi più gravi l’evoluzione del fenomeno può provocare la riduzione della sezione resistente. Qualora il calcestruzzo sia carbonatato e il copriferro intatto, tale fenomeno non è visibile ma si sviluppa al di sotto dello strato di ricoprimento in calcestruzzo. Per carbonatazione si intende la formazione di carbonato di calcio con la conseguente riduzione del PH del calcestruzzo e la depassivazione delle armature metalliche, che ne favorisce l’ossidazione. Quest’ultima provoca l’aumento di volume dell’armatura e la conseguente fessurazione, prima, ed espulsione poi, del copriferro.',
    phenomena: [
      'distacchi di copriferro (C.a./c.a.p._5)',
      'vespai (C.a./c.a.p._4)',
    ],
  },
  7: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}7_k2=1.jpg`,
    description:
      'Il fenomeno si riferisce alla presenza di fessure di modesta entità diffuse in maniera irregolare sulla superficie degli elementi e ben visibili nelle zone particolarmente soggette agli effetti dell’umidità.',
    phenomena: [
      'macchie di umidità passiva (C.a./c.a.p._1)',
      'macchie di umidità attiva (C.a./c.a.p._2)',
      'vespai (C.a./c.a.p._4)',
    ],
  },
  8: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: '1÷2 mm',
    k2_1: '>2 mm',
    image_k2_0_2: `${pathImages}8_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}8_k2=0.5.jpg`,
    image_k2_1: `${pathImages}8_k2=1.jpg`,
    description:
      'Si tratta di stati fessurativi con andamento prevalentemente orizzontale che compaiono sulle superfici verticali di elementi strutturali quali spalle, pile, pulvini, baggioli e fondazioni. Spesso si trovano in corrispondenza di riprese di getto e negli elementi costruiti per conci.',
    phenomena: [
      'macchie di umidità passiva (C.a./c.a.p._1)',
      'macchie di umidità attiva (C.a./c.a.p._2). ',
      'fenomeni di dilavamento/ammaloramento del calcestruzzo (C.a./c.a.p._3)',
      'vespai (C.a./c.a.p._4)',
    ],
    suggestions:
      'È bene considerare solo le discontinuità in corrispondenza delle riprese di getto che implicano una fessurazione chiara e visibile.',
  },

  9: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: '1÷2 mm',
    k2_1: '>2 mm',
    image_k2_0_2: `${pathImages}9_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}9_k2=0.5.jpg`,
    image_k2_1: `${pathImages}9_k2=1.jpg`,
    description:
      'Si tratta di stati fessurativi con andamento prevalentemente verticale che compaiono sulle superfici verticali di elementi strutturali quali spalle, pile, pulvini, baggioli e fondazioni. Spesso si trovano in corrispondenza di riprese di getto o negli elementi costruiti per conci.',
    phenomena: [
      'macchie di umidità passiva (C.a./c.a.p._1)',
      'macchie di umidità attiva (C.a./c.a.p._2). ',
      'Fenomeni di dilavamento/ammaloramento del calcestruzzo (C.a./c.a.p._3)',
    ],
    suggestions:
      'Nel caso in cui le fessure verticali interessano impalcati, archi o travi si parla di lesioni longitudinali o trasversali.',
  },
  10: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: '1÷2 mm',
    k2_1: '>2 mm',
    image_k2_0_2: `${pathImages}10_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}10_k2=0.5.jpg`,
    image_k2_1: `${pathImages}10_k2=1.jpg`,
    description:
      'Si tratta di stati fessurativi con andamento prevalentemente diagonale situati su pareti verticali di fondazioni, pile, pulvini, spalle e travi e su superfici orizzontali di solette e controsolette.',
    phenomena: [
      'macchie di umidità passiva (C.a./c.a.p._1)',
      'macchie di umidità attiva (C.a./c.a.p._2). ',
      'Fenomeni di dilavamento/ammaloramento del calcestruzzo (C.a./c.a.p._3)',
    ],
  },
  11: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: '1÷2 mm',
    k2_1: '>2 mm o passante',
    image_k2_0_2: `${pathImages}11_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}11_k2=0.5.jpg`,
    image_k2_1: `${pathImages}11_k2=1.jpg`,
    description:
      'Il difetto si riferisce alla presenza di fessure in corrispondenza della sezione di incastro dei pilastri o piedritti ad altri elementi. Le fessure possono essere capillari o presentarsi con ampiezze significative, tali da provocare un vero e proprio distacco degli elementi.',
    phenomena: [
      'fenomeni di ossidazione e/o corrosione (C.a./c.a.p._6, C.a./c.a.p._16)',
      'vespai (C.a./c.a.p._4)',
    ],
  },

  12: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}12_k2=1.jpg`,
    description:
      'Per riprese successive si intendono le riparazioni localizzate effettuate sul calcestruzzo, quali riempimenti di vuoti, intasamento di vespai e ricostruzioni di copriferro, oltre che i rivestimenti di intonaco che spesso ricoprono le superfici degli elementi. Il deterioramento delle riprese successive include vari tipi di degrado, quali le lesioni, fenomeni legati all’umidità e il distacco degli elementi nuovi dal materiale base.',
    suggestions: 'Da non confondere con le riprese di getto',
  },

  13: {
    k2_0_2: 'Accennato',
    k2_0_5: 'Distacco del materiale',
    k2_1: 'Vista delle armature',
    k1_1: 'Sempre = 1',
    image_k2_0_2: `${pathImages}13_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}13_k2=0.5.jpg`,
    image_k2_1: `${pathImages}13_k2=1.jpg`,
    description:
      'Con lesioni da schiacciamento si intendono le fessure che si manifestano in genere in corrispondenza delle sedi di appoggio  degli impalcati, quali i baggioli, e degli apparecchi di appoggio, per eccesso di compressione. Esse si presentano con un’inclinazione di circa 45° e generalmente sono accoppiate, tanto da generare il distacco di cunei di materiale.',
    phenomena: [
      'macchie di umidità passiva (C.a./c.a.p._1)',
      'macchie di umidità attiva (C.a./c.a.p._2)',
      'Fenomeni di dilavamento/ammaloramento del calcestruzzo(C.a./c.a.p._3).',
    ],
    suggestions:
      'Da non confondere con le lesioni caratteristiche in zona appoggio',
  },
  14: {
    k1_0_2: 'In poche zone',
    k1_0_5: '~50% lunghezza',
    k1_1: '~intera lunghezza',
    k2_0_2: 'Capillare',
    k2_0_5: '1÷2 mm',
    k2_1: '>2 mm',
    image_k2_0_2: `${pathImages}14_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}14_k2=0.5.jpg`,
    image_k2_1: `${pathImages}14_k2=1.jpg`,
    description:
      'Il fenomeno si riferisce all’apertura di fessure in corrispondenza delle armature trasversali. Le lesioni si presentano distribuite in modo regolare, riproducendo la disposizione delle staffe degli elementi strutturali.',
    phenomena: [
      'distacco di porzioni di copriferro (C.a./c.a.p._5)',
      'ossidazione e/o corrosione delle staffe (C.a./c.a.p._16)',
    ],
    suggestions:
      'È bene riconoscere quando le lesioni sono associabili alla presenza di staffe o se la loro presenza dipenda da regimi di sollecitazione sfavorevoli',
  },
  15: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'Ossidata',
    k2_0_5: 'Intaccata la sezione della staffa',
    k2_1: 'Corrosa con diminuz. di sezione',
    image_k2_0_2: `${pathImages}15_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}15_k2=0.5.jpg`,
    image_k2_1: `${pathImages}15_k2=1.jpg`,
    description:
      'Il difetto si riferisce all’esposizione delle armature trasversali e la conseguente ossidazione dell’acciaio a contatto con l’aria e l’acqua presente nell’ambiente esterno. La presenza di staffe scoperte si associa a distacchi o carenze di copriferro.',
    phenomena: [
      'distacchi di copriferro (C.a./c.a.p._5)',
      'vespai (C.a./c.a.p._4)',
      'lesioni in corrispondenza delle staffe(C.a./c.a.p._15)',
    ],
    suggestions:
      'Da non confondere con l’armatura ossidata e/o corrosa (C.a./c.a.p._6) , fenomeno che si riferisce specificatamente alle armature longitudinali degli elementi.',
  },

  16: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}16_k2=1.jpg`,
    description:
      'Il difetto si individua laddove le barre longitudinali degli elementi strutturali risultano visibilmente deformate. Ovviamente tale difetto è rilevabile solo in corrispondenza di distacchi di copriferro che permettono la vista delle armature.',
    phenomena: [
      'fenomeni di distacco di copriferro (C.a./c.a.p._5) ',
      'armature ossidate e/o corrose (C.a./c.a.p._6)',
      'dilavamento/ammaloramento (C.a./c.a.p._3)',
    ],
  },
  17: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: '1÷2 mm',
    k2_1: '>2 mm',
    image_k2_0_2: `${pathImages}17_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}17_k2=0.5.jpg`,
    image_k2_1: `${pathImages}17_k2=1.jpg`,
    description:
      'Si tratta di lesioni che si sviluppano lungo gli assi longitudinali di elementi quali solette, archi e travi, i quali coincidono con l’asse longitudinale del ponte.',
    phenomena: [
      'macchie di umidità passiva (C.a./c.a.p._1) ',
      'macchie di umidità attiva (C.a./c.a.p._2)',
      'Fenomeni di dilavamento/ammaloramento del calcestruzzo(C.a./c.a.p._3)',
    ],
  },

  18: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: '1÷2 mm',
    k2_1: '>2 mm',
    image_k2_0_2: `${pathImages}18_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}18_k2=0.5.jpg`,
    image_k2_1: `${pathImages}18_k2=1.jpg`,
    description:
      'Si tratta di lesioni che si sviluppano lungo gli assi trasversali di elementi quali travi, traversi, solette, archi. Nel caso di solette e archi, il loro asse trasversale coincide con l’asse trasversale del ponte.',
    phenomena: [
      'macchie di umidità passiva (C.a./c.a.p._1)',
      'macchie di umidità passiva (C.a./c.a.p._1)',
      'Fenomeni di dilavamento/ammaloramento del calcestruzzo(C.a./c.a.p._3)',
    ],
  },
  19: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Modesto',
    k2_0_5: 'Qualche mm',
    k2_1: 'Qualche cm',
    image_k2_0_2: `${pathImages}19_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}19_k2=0.5.jpg`,
    image_k2_1: `${pathImages}19_k2=1.jpg`,
    description:
      'Il difetto interessa le strutture ad arco. Esso si riferisce alla presenza di lesioni tra il timpano e la struttura portante dell’arco. Per timpano si intende la parte ai lati del ponte sovrastante l’elemento strutturale portante dell’arco.',
    phenomena: [
      'macchie di umidità passiva (C.a./c.a.p._1)',
      'macchie di umidità attiva (C.a./c.a.p._2)',
      'Fenomeni di dilavamento/ammaloramento del calcestruzzo(C.a./c.a.p._3)',
    ],
    suggestions:
      'E’ bene indagare se sono presenti difetti a livello di fondazione (Ril/Fond_5) che potrebbero provocare movimenti indesiderati poiché potrebbero essere una causa di tale fenomeno',
  },
  20: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}20_k2=1.jpg`,
    description:
      'Si tratta di un fenomeno di degrado che interessa il calcestruzzo in corrispondenza delle zone di estremità di travi, solette, cassoni. In particolare, si fa riferimento al dilavamento quando ci si riferisce al distacco dello strato più superficiale di calcestruzzo a causa del continuo passaggio di acqua, mentre si parla di ammaloramento quando ci si riferisce a scagliamento, porosità, perdita di coesione e rigonfiamento, sempre degli strati più esterni del calcestruzzo.',
    phenomena: [
      'macchie di umidità passiva (C.a./c.a.p._1)',
      'macchie di umidità attiva (C.a./c.a.p._2)',
      'tracce di scolo (Dif.Gen_1).  ',
    ],
  },
  21: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: '>1 mm',
    k2_1: 'Passante',
    image_k2_0_2: `${pathImages}21_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}21_k2=0.5.jpg`,
    image_k2_1: `${pathImages}21_k2=1.jpg`,
    description:
      'Si tratta di lesioni che si rilevano in corrispondenza delle sezioni di incastro tra travi principali e traversi. Esse si sviluppano, generalmente, lungo tutta l’altezza del traverso e possono essere di modesta entità o raggiungere ampiezze considerevoli, fino a provocare un vero e proprio distacco del traverso',
    phenomena: [
      'Ossidazione e/o corrosione delle armature (C.a./c.a.p._6, C.a./c.a.p._16)',
      'vespai (C.a./c.a.p._4)',
    ],
  },

  22: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}22_k2=1.jpg`,
    description:
      'Si riferisce alla completa rottura delle armature trasversali degli elementi strutturali. Ciò spesso accade nelle travi di bordo dei ponti a travata, maggiormente esposte agli agenti esterni e quindi a fenomeni quali dilavamento e/o ammaloramento del calcestruzzo.',
    phenomena: [
      'ossidazione delle staffe (C.a./c.a.p._16)',
      'distacchi di copriferro (C.a./c.a.p._5)',
    ],
    suggestions:
      'La presenza di staffe rotte può comportare la riduzione della capacità resistente degli elementi nei confronti delle sollecitazioni taglianti.',
  },

  23: {
    k2_0_2: 'Accennati',
    k2_0_5: 'Intensi',
    k2_1: 'Rilevanti',
    k1_1: 'Sempre = 1',
    image_k2_0_2: `${pathImages}23_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}23_k2=0.5.jpg`,
    image_k2_1: `${pathImages}23_k2=1.jpg`,
    description:
      'Il difetto si riferisce all’indebolimento della sella dovuto al deterioramento del calcestruzzo e dell’acciaio. I materiali possono essere soggetti a fenomeni di degrado tipici del calcestruzzo armato, quali vespai, perdita di copriferro, esposizione di armature, corrosione di armature, rottura di barre, ecc. La situazione può essere aggravata dal congestionamento delle armature e dalla miniaturizzazione delle sezioni che rendono difficile la realizzazione di getti a regola d’arte.',
  },

  24: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}24_k2=1.jpg`,
    description:
      'Il difetto si riscontra nei ponti a travata e si riferisce alla presenza di fessure in corrispondenza della sezione di incastro della soletta alle travi. Tali fessure hanno andamento longitudinale e, di solito, sono di modesta entità.',
    phenomena: [
      'macchie di umidità passiva (C.a./c.a.p._1)',
      'macchie di umidità attiva (C.a./c.a.p._2)',
    ],
    suggestions:
      'Se la soletta è costituita da lastre prefabbricate, la presenza di discontinuità è fisiologica e NON ha senso parlare di lesioni.',
  },

  25: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% della totalità',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}25_k2=1.jpg`,
    description:
      'Si tratta di lesioni corte e ravvicinate, che si presentano sulle superfici degli elementi precompressi in corrispondenza dell’ancoraggio dei cavi',
  },

  26: {
    k1_0_2: 'Su una trave',
    k1_0_5: 'Sulla metà della trave',
    k1_1: 'Su tutte le travi',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}26_k2=1.jpg`,
    description:
      'Il difetto è individuabile qualora siano chiaramente visibili gli ancoraggi dei cavi, perché non ricoperti da un getto protettivo in calcestruzzo o se tale getto protettivo sia ammalorato o deteriorato. Esso è specifico per gli elementi in c.a.p. a cavi scorrevoli.',
    phenomena: [
      'Lesioni capillari agli ancoraggi (c.a.p._1)',
      'Distacco tamponi testate (c.a.p._3)',
      'umidità dall’interno (c.a.p._10)',
    ],
    suggestions:
      'Spesso le testate non sono accessibili per il controllo, ma se si rileva umidità dall’interno è implicita la presenza di testate di ancoraggio non sigillate',
  },

  27: {
    k1_0_2: 'Appena presente',
    k1_0_5: 'Sulla metà della trave',
    k1_1: 'Su tutte le travi',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}27_k2=1.jpg`,
    description:
      'Il difetto si riferisce a lesioni o a veri e propri distacchi in corrispondenza del tampone in calcestruzzo, posto a protezione delle testate di ancoraggio dei cavi scorrevoli in una trave in c.a.p.. Nel caso in cui si tratta di un distacco vero e proprio, saranno visibili gli ancoraggi dei cavi.',
    suggestions:
      'Se il tampone si realizza anche alle testate di travi a fili aderenti, il suo eventuale distacco non deve essere segnalato, in quanto l’elemento ha una funzione del tutto secondaria.',
  },

  28: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'In media <1',
    k2_0_5: 'In media da 1 a 3',
    k2_1: 'In media >3',
    image_k2_0_2: `${pathImages}28_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}28_k2=0.5.jpg`,
    image_k2_1: `${pathImages}28_k2=1.jpg`,
    description:
      'Il difetto si riferisce alla presenza di fessure, generalmente ad andamento obliquo, che riproducono la posizione dei cavi da precompressione. È specifico per gli elementi in c.a.p. a cavi scorrevoli ed è maggiormente evidente se la superficie è interessata da macchie di umidità.',
    suggestions:
      'Se si suppone che le lesioni siano state generate conseguentemente alla corrosione dei cavi di precompressione, conviene accertarsi che non sia presente riduzione di armatura di precompressione (c.a.p_9).',
  },

  29: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'In media <1',
    k2_0_5: 'In media da 1 a 3',
    k2_1: 'In media >3',
    image_k2_0_2: `${pathImages}29_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}29_k2=0.5.jpg`,
    image_k2_1: `${pathImages}29_k2=1.jpg`,
    description:
      'Il difetto si riferisce alla presenza di fessure, nella direzione longitudinale delle travi in c.a.p. a cavi scorrevoli, che seguono l’andamento di questi ultimi. Generalmente sono più frequenti in corrispondenza della mezzeria delle travi e sono spesso accompagnate da macchie di umidità, che rendono più semplice il loro rilevamento. Il difetto comprende anche le lesioni all’intradosso di solettoni, cassoni e archi in c.a.p.',
    phenomena: ['guaine in vista (c.a.p_6)', 'guaine degradate (c.a.p_7).'],
  },

  30: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}30_k2=1.jpg`,
    description:
      'Si tratta di un difetto specifico ed esclusivo degli elementi in c.a.p. a cavi scorrevoli e si riferisce allo scoprimento delle guaine metalliche laddove si è verificato distacco dello strato di ricoprimento in calcestruzzo. L’esposizione delle guaine favorisce la loro ossidazione.',
    phenomena: ['vespai (c.a./c.a.p._4)', 'armatura ossidata (c.a./c.a.p._6).'],
    suggestions:
      'E’ necessario assicurarsi che non si sia verificata riduzione di armatura di precompressione (c.a.p_9)',
  },

  31: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'Ossidata',
    k2_0_5: 'Intaccata la sezione del filo',
    k2_1: 'Corrosa con diminuz. di sezione',
    image_k2_0_2: `${pathImages}31_k2=0.2.jpg`, // TODO: mancante
    image_k2_0_5: `${pathImages}31_k2=0.5.jpg`, // TODO: mancante
    image_k2_1: `${pathImages}31_k2=1.jpg`,
    description:
      'Ci si riferisce a tale fenomeno nel caso in cui le guaine dei cavi da precompressione di elementi in c.a.p. a cavi scorrevoli siano corrose o rotte e in assenza di malta di iniezione. Ciò comporta la vista dei fili o dei trefoli da precompressione che, esposti all’agenti aggressivi esterni, sono soggetti a ossidazione.',
    phenomena: [
      'lesioni lungo la suola del bulbo (c.a.p_5 )',
      'riduzione dell’armatura di precompressione (c.a.p_9). 7).',
    ],
    suggestions:
      'Il verificarsi di tale fenomeno è maggiormente probabile nel caso di guaine non intasate. Se le guaine sono solo degradate ma l’iniezione è integra ci si deve riferire al fenomeno di guaine in vista (c.a.p_6).',
  },
  32: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'Ossidata',
    k2_0_5: 'Intaccata la sezione del filo',
    k2_1: 'Corrosa con diminuz. di sezione',
    image_k2_0_2: `${pathImages}32_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}32_k2=0.5.jpg`,
    image_k2_1: `${pathImages}32_k2=1.jpg`,
    description:
      'Il difetto si riferisce all’esposizione dell’armatura di precompressione di elementi in c.a.p. a cavi aderenti e alla loro conseguente ossidazione.',
    phenomena: [
      'calcestruzzo dilavato/ammalorato (C.a./c.a.p._3)',
      'riduzione dell’armatura di precompressione (c.a.p_9).',
    ],
    suggestions:
      'Per le caratteristiche stesse della tecnologia di precompressione a cavi aderenti, tale difetto si manifesta raramente, salvo situazioni particolari.',
  },
  33: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'Visibile',
    k2_0_5: 'Fino al 20% del diametro',
    k2_1: '> del 20% del diametro',
    image_k2_0_2: `${pathImages}33_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}33_k2=0.5.jpg`,
    image_k2_1: `${pathImages}33_k2=1.jpg`,
    description:
      'Il difetto è causato dall’evoluzione del fenomeno di ossidazione dei cavi negli elementi in c.a.p., sia che essi siano a cavi scorrevoli che a cavi aderenti, fino ad un’evidente riduzione di sezione e, nei casi più gravi, alla rottura dei cavi da precompressione.',
    phenomena: [
      'umidità dall’interno (c.a.p_10)',
      'guaine degradate e fili ossidati (c.a.p_7)',
      'fili aderenti in vista/ossidati (c.a.p_8)',
    ],
  },

  34: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',

    image_k2_1: `${pathImages}34_k2=1.png`,
    description:
      'Si tratta di un difetto specifico di elementi in c.a.p. a cavi scorrevoli, in quanto si riferisce alla fuoriuscita di umidità dopo lo scorrimento lungo le guaine di precompressione. Un sintomo evidente della presenza del fenomeno è la presenza di stalattiti in assenza di umidità.',
    phenomena: [
      'lesioni lungo la suola del bulbo (c.a.p_5)',
      'lesioni sull’anima lungo i cavi (c.a.p_4)',
    ],
    suggestions: 'Accertarsi che l’acqua provenga effettivamente dalle guaine',
  },
  35: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'Armatura scoperta',
    k2_0_5: 'Armatura ossidata',
    k2_1: 'Armatura corrosa',
    image_k2_0_2: `${pathImages}35_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}35_k2=0.5.jpg`,
    image_k2_1: `${pathImages}35_k2=1.jpg`,
    description:
      'Il fenomeno si riferisce allo scoprimento delle armature, sia longitudinali che trasversali, in corrispondenza delle testate degli elementi in c.a.p., e la loro conseguente ossidazione.',
    phenomena: ['dilavamento/ammaloramento del calcestruzzo (C.a./c.a.p._3)'],
  },
  36: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',

    image_k2_1: `${pathImages}36_k2=1.jpg`,
    description:
      'La fuoriuscita delle barre è conseguente alla rottura delle barre di precompressione o il cedimento dei loro ancoraggi negli elementi in c.a.p. a cavi scorrevoli. Ciò implica l’annullamento dello sforzo di precompressione.',
    phenomena: ['testate di ancoraggio non sigillate (c.a.p_2)'],
    suggestions:
      'Se si rileva questo difetto, NON indicare anche la riduzione dell’armatura di precompressione (c.a.p_9).',
  },

  37: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: 'Qualche mm',
    k2_1: 'Qualche cm',
    image_k2_0_2: `${pathImages}37_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}37_k2=0.5.jpg`,
    image_k2_1: `${pathImages}37_k2=1.jpg`,
    description:
      'Questo tipo di difetto è riferito alle fessure con andamento prevalentemente orizzontale che possono manifestarsi sugli elementi verticali come spalle e pile e, nel caso dei ponti ad arco, su rinfianchi e timpani. Esse si sviluppano principalmente lungo i ricorsi di malta o, meno frequentemente,  attraversano direttamente i mattoni o i blocchi di pietra.',
    phenomena: [
      'macchie di umidità (Mur_9)',
      'dilavamento della muratura (Mur_10)',
    ],
  },

  38: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: 'Qualche mm',
    k2_1: 'Qualche cm',
    image_k2_0_2: `${pathImages}38_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}38_k2=0.5.jpg`,
    image_k2_1: `${pathImages}38_k2=1.jpg`,
    description:
      'Ci si riferisce a fessure ad andamento prevalentemente verticale su pile, spalle e timpani. Esse possono seguire un andamento a zig-zag lungo i giunti di malta delle murature a blocchi, oppure avere un andamento continuo, tagliando i blocchi stessi,  siano essi in pietra o in laterizio. A volte possono presentarsi come un insieme di piccole fessure ravvicinate; in questo caso deve esserne analizzata nel dettaglio la geometria.',
    phenomena: [
      'macchie di umidità (Mur_9)',
      'dilavamento della muratura (Mur_10)',
    ],
  },

  39: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: 'Qualche mm',
    k2_1: 'Qualche cm',
    image_k2_0_2: `${pathImages}39_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}39_k2=0.5.jpg`,
    image_k2_1: `${pathImages}39_k2=1.jpg`,
    description:
      'Si tratta di fessure ad andamento inclinato rispetto alla verticale, che possono svilupparsi seguendo a zig-zag i giunti di malta orizzontali e verticali per i ponti in muratura di mattoni o a conci di pietra squadrata, o attraversando in modo continuo gli elementi in pietra/laterizio. A volte possono presentarsi come un insieme di piccole fessure ravvicinate; in questo caso deve esserne analizzata nel dettaglio la geometria per capire se siano state originate da un moto di traslazione o un moto di rotazione tra blocchi rigidi.',
    phenomena: [
      'macchie di umidità (Mur_9)',
      'dilavamento della muratura (Mur_10)',
    ],
  },
  40: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}40_k2=1.jpg`,
    description:
      'Il difetto si evidenzia per la mancanza o la rottura di mattoni o blocchi in pietra appartenenti agli elementi strutturali del manufatto',
    phenomena: ['polverizzazione (Mur_14)'],
  },
  41: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: 'Qualche mm',
    k2_1: 'Qualche cm',
    image_k2_0_2: `${pathImages}41_k2=0.2.JPG`,
    image_k2_0_5: `${pathImages}41_k2=0.5.jpg`,
    image_k2_1: `${pathImages}41_k2=1.jpg`,
    description: `Per fessure trasversali si intendono le lesioni che possono presentarsi all’intradosso della volta, con andamento prevalente lungo la direttrice dell’arco, trasversalmente all’asse stradale. Generalmente esse interessano i giunti di malta.`,
    phenomena: [
      `macchie di umidità (Mur_9)`,
      `dilavamento della muratura (Mur_10)`,
    ],
  },
  42: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: 'Qualche mm',
    k2_1: 'Qualche cm',
    image_k2_0_2: `${pathImages}42_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}42_k2=0.5.jpg`,
    image_k2_1: `${pathImages}42_k2=1.jpg`,
    description: `Questo tipo di difetto è riferito a fessure ad andamento circonferenziale che si presentano all’intradosso dell’arco, che quindi seguono l’andamento longitudinale dell’asse stradale. Le fessure possono interessare sia i giunti di malta sia gli elementi lapidei o di laterizio, che costituiscono la tessitura muraria.`,
    phenomena: [
      `macchie di umidità (Mur_9)`,
      `dilavamento della muratura (Mur_10)`,
    ],
  },
  43: {
    k1_0_2: 'Rispetto alla lunghezza significativa',
    k1_0_5: 'Rispetto alla lunghezza significativa',
    k1_1: 'Rispetto alla lunghezza significativa',
    k2_0_2: 'Capillare',
    k2_0_5: 'Qualche mm',
    k2_1: 'Qualche cm',
    image_k2_0_2: `${pathImages}43_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}43_k2=0.5.jpg`,
    image_k2_1: `${pathImages}43_k2=1.jpg`,
    description: `Per timpano si intende la parte verticale ai lati della struttura sovrastante l’elemento strutturale portante dell’arco, che racchiude il materiale di riempimento. Quest’ultimo, a volte, offre un notevole contributo alla capacità portante del ponte. Il difetto si riferisce al distacco di tale elemento dall’arco sottostante.`,
    phenomena: [
      `macchie di umidità (Mur_9)`,
      `dilavamento della muratura (Mur_10)`,
    ],
  },
  44: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}44_k2=1.jpg`,
    description: `Il difetto si presenta nelle zone in cui sono stati eseguiti interventi per nascondere fenomeni di degrado e lesioni senza tuttavia eliminarne le cause scatenanti, per cui con il passare del tempo il difetto si ripresenta con le stesse caratteristiche.`,
  },
  45: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}45_k2=1.jpg`,
    description: `Il difetto si presenta con macchie di colore pressoché uniforme ma in generale più scure rispetto alla muratura circostante integra. Tali macchie hanno forma molto variabile, che può dipendere sia dall’origine dell’umidità che le genera sia dal tipo di tessuto murario. Per esempio, sulle murature miste generalmente si presentano in modo disomogeneo a seconda dei vari materiali con cui esse sono realizzate (pietra, laterizio, ecc.).`,
    phenomena: [`dilavamento della muratura (Mur_10)`],
  },
  46: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}46_k2=1.jpg`,
    description: `Il dilavamento della muratura si manifesta con macchie di colore più scuro rispetto alla parte integra, generalmente alternate ad altre di colore biancastro, dovute all’accumulo di depositi di calcare, il cui andamento ricalca il percorso dell’acqua sulla superficie. Esse possono trovarsi localizzate in punti specifici delle superfici murarie o manifestarsi su una superficie più ampia, sotto forma di striature disomogenee o stalattiti calcaree.`,
    phenomena: [`macchie di umidità (Mur_9)`],
  },
  47: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}47_k2=1.jpg`,
    description: `Il difetto è un’alterazione dello strato superficiale del materiale lapideo che spesso si presenta di colore diverso (crosta nera) e con durezza e fragilità maggiore rispetto allo strato sottostante o al materiale non ammalorato. Lo strato superficiale ammalorato tende a staccarsi spontaneamente dal materiale sottostante. Spesso si forma in zone riparate dalla pioggia e dal vento.`,
    phenomena: [`patine biologiche (Mur_13)`],
  },
  48: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}48_k2=1.jpg`,
    description: `Il difetto è dovuto alla formazione di una patina biancastra e cristallina sulla superficie della struttura che può presentarsi in forma pulverulenta o filamentosa. Quando si tratta di efflorescenze di tipo salino, la cristallizzazione avviene all’interno del materiale provocando il distacco delle parti più superficiali; in tal caso si parla di criptoefflorescenza o sub-efflorescenza.`,
  },
  49: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}49_k2=1.jpg`,
    description: `La patina biologica è costituita da microrganismi a cui possono aderire polvere, terriccio, ecc., i quali formano uno strato sottile, morbido ed omogeneo, aderente alla superficie, di colore variabile, ma per lo più verde. Rientra in questo tipo di difetto anche la presenza di vegetazione invasiva. La vegetazione invasiva se particolarmente rigogliosa provoca la disgregazione della malta e la conseguente riduzione dell’ammorsamento tra i blocchi o le pietre (effetto leva delle radici).`,
    phenomena: [
      `macchie di umidità/risalita (Mur_9)`,
      `distacco di porzioni di materiale (Mur_4)`,
      `fessure di vario tipo (Mur_2, Mur_3, Mur_5, Mur_6).`,
    ],
  },
  50: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'Profondità <1 cm',
    k2_0_5: 'Profondità <5 cm',
    k2_1: 'Profondità >5 cm',
    image_k2_0_2: `${pathImages}50_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}50_k2=0.5.jpg`,
    image_k2_1: `${pathImages}50_k2=1.jpg`,
    description: `La polverizzazione si manifesta con la caduta spontanea del materiale, costituente i giunti di malta o gli elementi lapidei, in forma di polvere o granuli.`,
  },
  51: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}51_k2=1.JPG`,
    description: `Il difetto si manifesta con il distacco di uno o più strati superficiali approssimativamente paralleli fra loro (sfoglie). Tali parti distaccate assumono spesso forme specifiche in funzione delle caratteristiche strutturali e di tessitura. Le sfoglie sono usualmente di spessore che varia tra qualche millimetro e qualche centimetro.`,
  },
  52: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}52_k2=1.jpg`,
    description: `I difetti, localizzati lungo lo sviluppo dei cordoni di saldatura, risultano particolarmente pericolosi poiché riducono i valori di resistenza e tenacità del giunto saldato e con il tempo possono causare la frattura della saldatura stessa. Essi si manifestano sotto forma di:
    - cricche, ossia microlesioni o fessurazioni sui cordoni di saldatura;
    - inclusioni di natura solida e gassosa o soffiature superficiali, riconoscibili dalla presenza di piccoli crateri sulla superficie del cordone;
    - irregolarità sulle maglie del cordone con evidente variazione del suo profilo per presenza di avvallamenti, irregolarità dei bordi, ecc.`,
    suggestions: `Particolare attenzione deve essere posta in presenza di nodi perché si potrebbe ricadere nel caso di lesione ai nodi (Acc_9).`,
  },
  53: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}53_k2=1.jpg`,
    description: `Il difetto si presenta con estese e profonde fessurazioni, generalmente passanti, nel cordone di saldatura o nelle sue immediate vicinanze. È u fenomeno particolarmente pericoloso perché interessa l’intera sezione resistente della saldatura e/o degli elementi collegati e comporta lesioni che tendono a propagarsi rapidamente per fatica.`,
    phenomena: [`lesione ai nodi (Acc_9)`],
  },
  54: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}54_k2=1.jpg`,
    description: `Il difetto si riferisce al distacco della vernice protettiva dagli elementi di acciaio, con la conseguente esposizione del metallo. Può presentarsi come:
    - squamatura a pelle di coccodrillo;
    - spellamento/scollamento di strati;
    - corrosione sotto la vernice;
    - difetto di stesura della vernice in corrispondenza di giunti bullonati e/o zone angolari.`,
    phenomena: [`Corrosione (Acc_10)`, `Ossidazione (Acc_11)`],
  },
  55: {
    k1_0_2: 'Un chiodo',
    k1_0_5: 'Più chiodi su un elemento',
    k1_1: 'Più chiodi su più elementi',
    k2_0_2: 'Difettosità-testa o gambo',
    k2_0_5: 'Caduta di una delle due teste',
    k2_1: 'Chiodo caduto',
    image_k2_0_2: `${pathImages}55_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}55_k2=0.5.jpg`,
    image_k2_1: `${pathImages}55_k2=1.jpg`,
    description: `Rientrano in tale categoria, i difetti di chiodatura rilevabili a vista, quali l’errata conformazione della testa ribattuta del chiodo (teoricamente a calotta semisferica coassiale con il gambo) e l’assenza di una delle tue teste o dell’intero chiodo dal foro.`,
    phenomena: [
      `deformazioni delle anime/piattabande (Acc_7)`,
      `lesioni ai nodi (Acc_9)`,
    ],
  },
  56: {
    k1_0_2: 'Meno del 10%',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutta la superficie',
    k2_0_2: 'Difettosità-testa o dado',
    k2_0_5: 'Rottura testa o caduta dado',
    k2_1: 'Bullone mancante',
    image_k2_0_2: `${pathImages}56_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}56_k2=0.5.jpg`,
    image_k2_1: `${pathImages}56_k2=1.jpg`,
    description: `Il difetto corrisponde alla presenza di una coppia di serraggio inferiore a quella prescritta. Esso si può individuare:
    - a vista, se manca il dado del bullone e se vi è spazio tra la rondella e la piastra;
    - a mano, se si può svitare il bullone a mano;
    - controllando la coppia di serraggio dei bulloni con chiave dinamometrica.`,
    phenomena: [`Bulloni tranciati (Acc_6)`],
    suggestions: `Nel caso in cui il difetto interessi gli apparecchi di appoggio, la sua presenza può essere denunciata da un funzionamento anomalo dell’apparecchio stesso.`,
  },
  57: {
    k1_0_2: 'Un bullone',
    k1_0_5: 'Più bulloni su un elemento',
    k1_1: 'Più bulloni su più elementi',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}57_k2=1.jpg`,
    description: `Il difetto si riferisce alla rottura del gambo dei bulloni a seguito di deformazione plastica. Nei casi più gravi si possono riscontare anche rifollamento dei fori e/o lacerazioni degli elementi collegati.`,
    phenomena: [
      `bulloni allentati (Acc_5)`,
      `deformazione anime/piattabande (Acc_7)`,
      `Corrosione (Acc_10)`,
    ],
  },
  58: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% lunghezza',
    k1_1: 'Tutta la lunghezza',
    k2_0_2: 'Accennata',
    k2_0_5: '10 mm',
    k2_1: 'Oltre 10 mm',
    image_k2_0_2: `${pathImages}58_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}58_k2=0.5.jpg`,
    image_k2_1: `${pathImages}58_k2=1.bmp`,
    description: `Il difetto, localizzato nelle piattabande o nelle anime di sezioni aperte (a T, doppio T, a L, a C, ecc.), si manifesta con la perdita di forma dei profili. Nel caso in cui la deformazione interessi l’anima si parla di imbozzamento.`,
    phenomena: [
      `sfogliamento della vernice (Acc_3)`,
      `corrosione (Acc_10)`,
      `difetti caratteristici dei giunti bullonati o saldati (Acc_5, Acc_6, Acc_1)`,
    ],
    suggestions: `Il fenomeno si riferisce alle sole sezioni aperte, altrimenti si parla di deformazioni su pareti di travi scatolari (Acc_8)`,
  },
  59: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% lunghezza',
    k1_1: 'Tutta la lunghezza',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}59_k2=1.jpg`,
    description: `Il difetto si manifesta con la perdita di forma delle pareti laterali o di fondo delle sezioni scatolari. Nel caso in cui si tratta di imbozzamento, le deformazioni si presentano come una successione di onde lungo lo sviluppo delle pareti.`,
    phenomena: [
      `sfogliamento della vernice (Acc_3)`,
      `corrosione (Acc_10)`,
      `bulloni allentati (Acc_5)`,
      `Bulloni tranciati (Acc_6)`,
    ],
    suggestions: `Nel caso di deformazioni su travi a sezione aperta ci si riferisce al difetto deformazione anime/piattabande (Acc_7)`,
  },
  60: {
    k1_0_2: 'Un solo nodo',
    k1_0_5: 'Fino a tre nodi',
    k1_1: 'Oltre tre nodi',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}60_k2=1.jpg`,
    description: `Per nodo si intende il punto di convergenza di elementi strutturali in acciaio, quali i tiranti e i puntoni di una travatura reticolare. Le lesioni a cui si riferisce il difetto possono riguardare sia gli elementi di collegamento e le piastre di nodo (saldature, bullonature, chiodature), sia gli elementi strutturali collegati.`,
    phenomena: [`difetti saldature (Acc_1)`, `rottura saldature (Acc_2)`],
    suggestions: `Nel caso di zone prossime agli apparecchi di appoggio può essere concomitante con difetti specifici degli apparecchi stessi.`,
  },
  61: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% lunghezza',
    k1_1: 'Tutta la lunghezza',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}61_k2=1.jpg`,
    description: `Gli acciai comuni reagiscono con l'ambiente formando una superficie contenente ossido di ferro. Questa superficie è estremamente porosa e consente al processo elettrochimico di ossidazione di evolversi, penetrando nell'acciaio e corrodendone la superficie, riducendo progressivamente la sezione fino alla perforazione del metallo stesso. I tipi di corrosione più pericolosi sono solitamente quelli localizzati, in quanto possono creare cricche, fori, fessurazioni.`,
    phenomena: [`sfogliamento della vernice (Acc_3)`],
  },
  62: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% lunghezza',
    k1_1: 'Tutta la lunghezza',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}62_k2=1.jpg`,
    description: `È un fenomeno elettrochimico in cui il ferro (Fe) contenuto nell’acciaio reagisce con l’ossigeno dell’atmosfera formando sulla superfice ossidi più o meno aderenti. A seconda dello stadio di evoluzione del fenomeno, esso si presenta come:
    - ossidazione superficiale omogenea;
    - rigonfiamento della superficie esterna;
    - corrosione puntiforme (vaiolatura).`,
    phenomena: [`sfogliamento della vernice (Acc_3)`, `Corrosione (Acc_10)`],
  },
  63: {
    k1_0_2: 'Appena presente',
    k1_0_5: `~50% estensione`,
    k1_1: `~tutta l'estensione`,
    k2_0_2: 'Superficiale',
    k2_0_5: 'Oltre lo strato superficiale',
    k2_1: 'Profonda',
    image_k2_0_2: `${pathImages}63_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}63_k2=0.5.jpg`,
    image_k2_1: `${pathImages}63_k2=1.jpg`,
    description: `Le macchie di umidità sono causate dall’assorbimento di acqua a seguito di percolazione delle acque meteoriche sulla superficie degli elementi o per assorbimento capillare di umidità. L’umidità può variare la composizione del legno e causare, di conseguenza, variazioni di dimensioni e di resistenza del materiale.`,
    phenomena: [
      `ristagni d’acqua (Legno_7)`,
      `funghi (Legno_2)`,
      `Insetti (Legno_3)`,
    ],
  },
  64: {
    k1_0_2: 'Appena presente',
    k1_0_5: `~50% estensione`,
    k1_1: `~tutta l'estensione`,
    k2_0_2: 'Superficiale',
    k2_0_5: 'Oltre lo strato superficiale',
    k2_1: 'Profonda',
    image_k2_0_2: `${pathImages}64_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}64_k2=0.5.jpg`,
    image_k2_1: `${pathImages}64_k2=1.jpg`,
    description: `Il difetto si manifesta qualora siano presenti corpi fruttiferi sugli elementi strutturali. Questi ultimi, non sempre visibili, depositano spore e sem microscopici che possono penetrare negli elementi in legno e causare danneggiamenti strutturali, quali fratture e riduzioni di resistenza. Qualora i funghi non siano visibili, la loro presenza è denunciata da fratture che formano solchi perpendicolari tra loro (carie), in corrispondenza delle quali la resistenza del legno si riduce notevolmente.`,
    phenomena: [`macchie di umidità  (Legno_1)`, `ristagni d’acqua (Legno_7)`],
  },
  65: {
    k1_0_2: 'Appena presente',
    k1_0_5: `~50% estensione`,
    k1_1: `~tutta l'estensione`,
    k2_0_2: 'Superficiale',
    k2_0_5: 'Oltre lo strato superficiale',
    k2_1: 'Profonda',
    image_k2_0_2: `${pathImages}65_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}65_k2=0.5.jpg`,
    image_k2_1: `${pathImages}65_k2=1.jpg`,
    description: `Tale difetto si riferisce alla presenza di fori e tunnel all’interno degli elementi, creati dagli insetti per cercare cibo e riparo. Alcuni degli insetti che comunemente danneggiano gli elementi in legno dei ponti sono le termiti, gli scarabei, le formiche operaie e i tricotteri. L’entità del difetto dipende dalla tipologia di legno e di insetto, dalle condizioni ambientali, ecc.`,
    phenomena: [`attacco di funghi (Legno_2)`],
  },
  66: {
    k1_0_2: '<1/3 della lunghezza',
    k1_0_5: `<1/2 della lunghezza`,
    k1_1: `>1/2 della lunghezza`,
    k2_0_2: '<1/3 della spessore',
    k2_0_5: '<1/2 della spessore',
    k2_1: '>1/2 della spessore',
    image_k2_0_2: `${pathImages}66_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}66_k2=0.5.jpg`,
    image_k2_1: `${pathImages}66_k2=1.jpg`,
    description: `Le fessure elicoidali sono fenomeni fisiologici che possono verificarsi durante il processo di stagionatura del legno massiccio di scarsa qualità. Nel caso in cui non si estendano per più della metà dello spessore dell’elemento resistente si possono ritenere non preoccupanti; in caso contrario, potrebbe essere necessaria una verifica statica.`,
    phenomena: [`ristagni d’acqua (Legno_7)`],
  },
  67: {
    k1_0_2: 'Sempre = 0.2',
    k2_0_2: '<1/3 della spessore',
    k2_0_5: '<1/2 della spessore',
    k2_1: '>1/2 della spessore',
    image_k2_0_2: `${pathImages}67_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}67_k2=0.5.jpg`,
    image_k2_1: `${pathImages}67_k2=1.jpg`,
    description: `Le fessurazioni longitudinali sono un fenomeno fisiologico che può manifestarsi nel legno massiccio durante il processo di stagionatura. Esse seguono la fibratura e pertanto ne denunciano l’inclinazione rispetto all’asse della trave. Nel caso in cui non si estendano per più della metà dello spessore dell’elemento resistente possono ritenersi non preoccupanti; in caso contrario, potrebbe essere necessaria una verifica statica.`,
    phenomena: [`ristagni d’acqua (Legno_7)`],
  },
  68: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}68_k2=1.jpg`,
    description: `Le fessure trasversali si manifestano sotto forma di discontinuità dei tessuti lungo una porzione di sezione trasversale. Esse portano alla riduzione delle sezioni resistenti degli elementi.`,
  },
  69: {
    k1_0_2: 'Estensione limitata',
    k1_0_5: `~50% estensione`,
    k1_1: `~tutta l'estensione`,
    k2_0_2: 'Superficiale',
    k2_0_5: 'Oltre lo strato superficiale',
    k2_1: 'Profonda',
    image_k2_0_2: `${pathImages}69_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}69_k2=0.5.jpg`,
    image_k2_1: `${pathImages}69_k2=1.jpg`,
    description: `Si tratta di accumulo di umidità per assorbimento negli elementi in legno`,
    phenomena: [
      `Attacchi di funghi (Legno_2)`,
      `Attacchi di insetti (Legno_3)`,
    ],
  },
  70: {
    k1_0_2: 'Localizzata',
    k1_0_5: `Estesa`,
    k1_1: `Molto estesa`,
    k2_0_2: 'Superficiale',
    k2_0_5: '<1/3 della spessore',
    k2_1: '>1/3 della spessore',
    image_k2_0_2: `${pathImages}70_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}70_k2=0.5.jpg`,
    image_k2_1: `${pathImages}70_k2=1.jpg`,
    description: `Con tale fenomeno ci si riferisce al danneggiamento indotto dall’urto di automezzi, natanti o materiali trasportati dai corsi d’acqua con gli elementi strutturali. Tali danneggiamenti sono normalmente localizzati in zone di modesta estensione, ma, nei casi più gravi, si può avere la compromissione di elementi portanti o di collegamenti.`,
    phenomena: [
      `Attacchi di funghi (Legno_2)`,
      `Attacchi di insetti (Legno_3)`,
      `Ristagni d’acqua (Legno_7)`,
    ],
  },
  71: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Sempre = 0.2',
    image_k2_0_2: `${pathImages}71_k2=0.2.jpg`,
    description: `Si tratta di possibili perdite di connessioni dovute alla presenza di bulloni allentati e rondelle lasche`,
    phenomena: [`Ristagni d’acqua (Legno_7)`],
  },
  72: {
    k1_0_2: 'Appena presente',
    k1_0_5: `~50% estensione`,
    k1_1: `~tutta l'estensione`,
    k2_0_2: '<1 cm',
    k2_0_5: '1÷3 cm',
    k2_1: '>3 cm',
    image_k2_0_2: `${pathImages}72_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}72_k2=0.5.jpg`,
    image_k2_1: `${pathImages}72_k2=1.jpg`,
    description: `La delaminazione si verifica negli elementi in legno lamellare quando gli strati si separano lungo la linea di incollaggio delle lamelle. Con tale difetto si indicano anche le fessurazioni che avvengono all’interno dello spessore delle lamelle stesse.`,
    phenomena: [`Ristagni d’acqua (Legno_7)`],
  },
  73: {
    k1_0_2: '< 20% delle connessioni dello stesso tipo',
    k1_0_5: `20-60% delle connessioni dello stesso tipo`,
    k1_1: `>60% delle connessioni dello stesso tipo`,
    k2_0_2: 'Parziale',
    k2_0_5: 'Media',
    k2_1: 'Completa',
    image_k2_0_2: `${pathImages}73_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}73_k2=0.5.jpg`,
    image_k2_1: `${pathImages}73_k2=1.jpg`,
    description: `Il difetto si riferisce ai danneggiamenti del legno in corrispondenza dei punti di unione, sotto forma di spaccature o schiacciamenti locali.`,
    phenomena: [
      `Attacchi di funghi (Legno_2)`,
      `Attacchi di insetti (Legno_3)`,
      `Ristagni d’acqua (Legno_7)`,
    ],
  },
  74: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: '~tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}74_k2=1.jpg`,
    description: `Il difetto si presenta con aree di colorazione diversa dal materiale integro, provocate dal passaggio ripetuto di acqua meteorica sulla superficie degli elementi. Nel caso in cui l’acqua è ancora presente, sono chiaramente visibili macchie di colore scuro e bagnate, altrimenti il passaggio pregresso dell’acqua è denunciato dalla presenza di macchie di colore biancastro legate agli effetti dell’azione chimica dei sali in essa disciolti. Tali macchie ricalcano il percorso intrapreso dall’acqua percolata sulla superficie degli elementi. Tali fenomeni si riscontrano prevalentemente sulle pareti verticali degli elementi strutturali, ma si possono rilevare anche su superfici orizzontali, quali l’intradosso degli sbalzi della soletta dove ristagna l’acqua proveniente dal coronamento.`,
    phenomena: [
      `fenomeni di dilavamento/ammaloramento del calcestruzzo (c.a./c.a.p._3)`,
      `fenomeni di dilavamento/ammaloramento  della muratura (Mur_10)`,
    ],
  },
  75: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: '~tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}75_k2=1.jpg`,
    description: `Per ristagni d’acqua si intendono quantità non trascurabili di acqua accumulate in zone di compluvio, ad esempio in eventuali avvallamenti sull’estradosso dei pulvini o all’interno di una trave ad U o a V.`,
    phenomena: [`Corrosione (C.a./c.a.p._6,Acc_10)`],
    suggestions: [
      `Nel caso di impalcati a cassone, l’eventuale presenza di acqua stagnante deve indicarsi come ristagni d’acqua nei cassoni, fenomeno considerato separatamente vista la diversa gravità che esso può assumere`,
    ],
  },
  76: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Visibile',
    k2_0_5: 'Distacco di materiale limitato',
    k2_1: 'Distacco di materiale profondo',
    image_k2_0_2: `${pathImages}76_k2=0.2.png`,
    image_k2_0_5: `${pathImages}76_k2=0.5.jpg`,
    image_k2_1: `${pathImages}76_k2=1.jpg`,
    description: `Con tale fenomeno ci si riferisce al danneggiamento indotto dall’urto di automezzi, natanti o materiali trasportati dai corsi d’acqua con gli elementi strutturali. Tali danneggiamenti sono generalmente rappresentati da rottura degli spigoli, distacchi di copriferro, tranciamento o deformazione delle armature.`,
    phenomena: [
      `distacchi di copriferro (C.a./c.a.p._5)`,
      `Ossidazione (Acc_11)`,
    ],
  },
  77: {
    k1_0_2: 'Appena presente',
    k1_0_5: `~50% superficie`,
    k1_1: `~tutta la superficie`,
    k2_0_2: 'Accennato',
    k2_0_5: 'Profondo',
    k2_1: 'Distacchi completi',
    image_k2_0_2: `${pathImages}77_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}77_k2=0.5.jpg`,
    image_k2_1: `${pathImages}77_k2=1.jpg`,
    description: `Il difetto si riferisce ai danneggiamenti presenti nelle zone di appoggio a causa del funzionamento dell’appoggio stesso. Sono lesioni che possono presentarsi sia sulla parte che appoggia, sia sull’elemento di supporto (pulvino, baggiolo, mensola). Spesso nel caso di spalle in muratura, è presente un cordolo in calcestruzzo armato in sommità su cui trovano appoggio travi in calcestruzzo armato; il difetto in oggetto comprende anche le lesioni eventualmente presenti su tale elemento.`,
  },
  78: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% superficie',
    k1_1: '~tutta la superficie',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}78_k2=1.jpg`,
    description: `Per ristagni d’acqua nei cassoni si intendono quantità non trascurabili di acqua accumulate in zone di compluvio all’interno di un impalcato a cassone.`,
  },
  79: {
    k1_1: 'Sempre = 1',
    k2_0_2: '2÷4 %',
    k2_0_5: '4÷6 %',
    k2_1: '>6 %',
    image_k2_0_2: `${pathImages}79_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}79_k2=0.5.jpg`,
    image_k2_1: `${pathImages}79_k2=1.jpg`,
    description: `Il difetto si riferisce alla mancanza di verticalità degli elementi in elevazione, quali pile e spalle`,
    phenomena: [`Movimenti di fondazione (Ril/Fond_5)`],
    suggestions: [
      `Spesso tale difetto è denunciato dalla presenza di deformazioni o movimenti anomali degli elementi sulla sede stradale, quali sicurvia, parapetti o giunti. Se rilevato è bene individuarne la causa.`,
    ],
  },
  80: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'hs/dp<1',
    k2_0_5: '1<hs/dp<3',
    k2_1: 'hs/dp>3',
    image_k2_0_2: `${pathImages}80_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}80_k2=0.5.jpg`,
    image_k2_1: `${pathImages}80_k2=1.jpg`,
    description: `Tale difetto deve essere indicato qualora siano visibili parti delle fondazioni a causa dell’abbassamento della quota del terreno che le circonda. Per quanto concerne le fondazioni dirette, se si verifica il fenomeno, sia l’estensione sia l’intensità sono pari ad 1. Per quanto riguarda le fondazioni su pali la valutazione del coefficiente di estensione è sempre pari ad 1, mentre l’intensità dello scalzamento può essere correlata all’altezza dello scalzamento (hs) rispetto al nuovo piano di campagna e al diametro dei pali (dp).`,
    phenomena: [`Movimenti di fondazione (Ril/Fond_5)`],
    suggestions: `Per i ponti con pile in alveo, nel caso in cui non sia possibile verificare l’eventuale scalzamento della fondazione, è importante segnalare in fase di ispezione che il difetto non è rilevabile spuntando l’apposita casella (NR). Normalmente, le fondazioni non sono ispezionabili quando il livello idrico del corso d’acqua è elevato per recenti piogge o quando sono perennemente sommerse. Nel primo caso, sarà possibile prevedere una nuova ispezione quando il corso d’acqua sarà tornato a livelli idrici di magra.`,
  },
  81: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}81_k2=1.jpg`,
    description: `Tale difetto deve essere indicato qualora siano visibili parti delle fondazioni a causa dell’abbassamento della quota del terreno che le circonda. Per quanto concerne le fondazioni dirette, se si verifica il fenomeno, sia l’estensione sia l’intensità sono pari ad 1.`,
    phenomena: ['Movimenti di fondazione (Ril/Fond_5) '],
    suggestions: `Per i ponti con pile in alveo, nel caso in cui non sia possibile verificare l’eventuale scalzamento della fondazione, è importante segnalare in fase di ispezione che il difetto non è rilevabile spuntando l’apposita casella (NR). Normalmente, le fondazioni non sono ispezionabili quando il livello idrico del corso d’acqua è elevato per recenti piogge o quando sono perennemente sommerse. Nel primo caso, sarà possibile prevedere una nuova ispezione quando il corso d’acqua sarà tornato a livelli idrici di magra.`,
  },
  82: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}82_k2=1.jpg`,
    description: `Il difetto si riferisce all’azione erosiva esercitata dalle acque meteoriche sul materiale costituente il rilevato di approccio nella sua parte superficiale.`,
    suggestions: [
      `Generalmente è associato a carenze nel sistema di convogliamento delle acque meteoriche.`,
    ],
  },
  83: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'd<5 cm',
    k2_0_5: '5 cm<d<15 cm',
    k2_1: 'd>15 cm',
    image_k2_0_2: `${pathImages}83_k2=0.2.png`, // TODO: mancante
    image_k2_0_5: `${pathImages}83_k2=0.5.jpg`,
    image_k2_1: `${pathImages}83_k2=1.png`, // TODO: mancante
    description: `Il dissesto del rilevato rappresenta un’evoluzione dei cedimenti nel tempo eventualmente ancora in atto, con presenze di avvallamenti e cedimenti differenziali tra il rilevato e la struttura. Per quanto riguarda la valutazione del coefficiente di estensione, esso è sempre pari ad 1, mentre l’intensità può essere correlata distanza (d) intesa come altezza del gradino che si viene a formare`,
  },
  84: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}84_k2=1.jpg`,
    description: `Il dissesto del rilevato è conseguenza di movimenti gravitativi incipienti o in atto.`,
  },
  85: {
    k1_0_2: 'Appena presente',
    k1_0_5: 'In qualche zona',
    k1_1: 'Ovunque',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}85_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}85_k2=0.5.jpg`,
    image_k2_1: `${pathImages}85_k2=1.jpg`,
    description: `Il difetto comprende:
    - Rotazione longitudinale: rotazione della fondazione su un piano verticale passante per l’asse stradale;
    - Rotazione trasversale: rotazione della fondazione su un piano verticale ortogonale all’asse stradale;
    - Traslazione: spostamento della fondazione su un piano orizzontale;
    - Abbassamento: spostamento della fondazione su un piano verticale.`,
    suggestions: `Tutti i movimenti in fondazione sono difficilmente rilevabili, per cui occorre dedurne la presenza da fenomeni di degrado ad essi correlati, quali presenza di gradini o aperture in corrispondenza dei giunti stradali, presenza di particolari lesioni se la struttura è iperstatica, eventuali fuori piombo degli elementi sostenuti. `,
  },
  86: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}86_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}86_k2=0.5.jpg`,
    image_k2_1: `${pathImages}86_k2=1.jpg`,
    description: `Il difetto si riferisce alla perdita di forma e di planarità della piastra di base in acciaio degli apparecchi di appoggio.`,
  },
  87: {
    k1_0_2: 'Appena presente',
    k1_0_5: 'Su ~50% superficie',
    k1_1: '~su tutta la superficie',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}87_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}87_k2=0.5.jpg`,
    image_k2_1: `${pathImages}87_k2=1.jpg`,
    description: `Il difetto riguarda le componenti in acciaio degli apparecchi di appoggio e consiste nel degrado del loro strato superficiale, causato dalla reazione chimica che si innesca con l’ossigeno presente in atmosfera. A seconda dell’avanzamento del fenomeno, può presentarsi in varie forme, a partire dalla sola perforazione dello strato di verniciatura superficiale fino alla riduzione della sezione degli elementi.`,
  },
  88: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}88_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}88_k2=0.5.jpg`,
    image_k2_1: `${pathImages}88_k2=1.jpeg`,
    description: `Il difetto si riferisce all’errato funzionamento o ad un funzionamento diverso da quello previsto degli apparecchi di appoggio.`,
    phenomena: [
      `presenza di detriti (App_5)`,
      `ovalizzazione dei rulli (App_12)`,
      `fuori sede rulli (App_13)`,
      `deterioramento teflon (App_14)`,
      `fuori piombo permanente dei pendoli (App_11) `,
    ],
  },
  89: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}89_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}89_k2=0.5.jpg`,
    image_k2_1: `${pathImages}89_k2=1.jpg`,
    description: `Il difetto si riferisce all’errato funzionamento o ad un funzionamento diverso da quello previsto degli apparecchi di appoggio, dovuto a regolazioni originarie non corrette. Non è facilmente individuabile, ma la sua presenza si può dedurre da altri fenomeni, quali una deformazione eccessiva dell’apparecchio di appoggio.`,
    phenomena: [
      `schiacciamento/fuoriuscita delle lastre di piombo (App_9)`,
      `fuori sede rulli (App_13)`,
      `fuori piombo permanente dei pendoli (App_11) `,
    ],
  },
  90: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}90_k2=1.jpg`,
    description: `Il difetto si riferisce alla presenza di depositi di materiale (terra, fango, tavole in legno, bitume, ecc.) in corrispondenza degli apparecchi di appoggio, che compromettono il loro normale funzionamento.`,
    phenomena: [
      `Bloccaggio (App_3)`,
      `deterioramento del teflon  (App_14)`,
      `fuori sede rulli (App_13) `,
    ],
  },
  91: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}91_k2=0.2.jpg`, // TODO: mancante
    image_k2_0_5: `${pathImages}91_k2=0.5.jpg`,
    image_k2_1: `${pathImages}91_k2=1.jpg`,
    description: `Il difetto si riferisce al deterioramento degli appoggi contenenti piastre di piombo, sia che esse costituiscano direttamente l’apparecchio, sia che esse siano una parte di esso. Il difetto può manifestarsi come deformazione o rifluimento della lastra o come variazione della sua posizione a causa, ad esempio, di trascinamento.`,
    phenomena: [`preregolazione sbagliata (App_4)`],
  },
  92: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}92_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}92_k2=0.5.jpg`,
    image_k2_1: `${pathImages}92_k2=1.jpg`,
    description: `Il difetto si riferisce al deterioramento degli elementi in neoprene degli apparecchi di appoggio, in termini di lesioni, irregolarità superficiali, ecc., ai quali consegue una perdita di elasticità del materiale.`,
    phenomena: [
      `deformazione orizzontale eccessiva (App_8)`,
      `schiacciamento/fuoriuscita di materiale (App_9)`,
    ],
  },
  93: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}93_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}93_k2=0.5.jpg`,
    image_k2_1: `${pathImages}93_k2=1.jpg`,
    description: `Il fenomeno si riferisce a scorrimenti eccessivi degli apparecchi di appoggio sul piano orizzontale, lungo l’asse trasversale o longitudinale del piano stradale. Essi provocano perdita di forma delle sezioni nel caso di lastre non vulcanizzate o distacco e scorrimenti tra gli strati di neoprene e i lamierini di acciaio, nel caso di lastre vulcanizzate.`,
    phenomena: [`invecchiamento del neoprene (App_7)`],
  },
  94: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}94_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}94_k2=0.5.jpg`,
    image_k2_1: `${pathImages}94_k2=1.jpg`,
    description: `Lo schiacciamento e la fuoriuscita di neoprene si riferisce al deterioramento degli apparecchi di appoggio costituiti da tale materiale, per effetto dei carichi verticali. Esso si può manifestare sotto forma di diminuzione di spessore, rifluimenti, rotture, ecc..`,
    phenomena: [`invecchiamento del neoprene (App_7) `],
  },
  95: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}95_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}95_k2=0.5.jpg`,
    image_k2_1: `${pathImages}95_k2=1.jpg`,
    description: `Tale tipologia di difetto include diversi fenomeni di degrado che potrebbero interessare apparecchi di appoggio a pendolo in c.a., quali:
    - dilavamento/ammaloramento del calcestruzzo;
    - lesioni;
    - distacchi di calcestruzzo;
    - armature scoperte;
    - ossidazione/corrosione delle armature;
    - Sgretolamento del calcestruzzo all’interno dei tubi metallici, per rulli realizzati in acciaio e calcestruzzo.`,
    phenomena: [`tutti i difetti tipici del materiale calcestruzzo armato `], // TODO: link ?
  },
  96: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}96_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}96_k2=0.5.jpg`,
    image_k2_1: `${pathImages}96_k2=1.jpg`,
    description: `Il fenomeno si riferisce alla deviazione dell’asse di simmetria del pendolo, che costituisce l’apparecchio di appoggio, dalla verticale passante per il punto di contatto del pendolo stesso con la superficie di appoggio. I pendoli presentano normalmente una leggera inclinazione; il difetto va segnalato qualora essa risulti eccessiva o se essa permane tal quale sia in periodi estivi che invernali.`,
    phenomena: [
      `preregolazione sbagliata (App_4)`,
      `presenza di detriti (App_5)`,
      `Bloccaggio (App_3)`,
      `movimenti di fondazione (Ril/Fond_5)`,
    ],
  },
  97: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}97_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}97_k2=0.5.jpg`,
    image_k2_1: `${pathImages}97_k2=1.jpg`,
    description: `Il fenomeno si riferisce alla perdita di forma dei rulli metallici a sezione circolare, che costituiscono gli apparecchi di appoggio.`,
    phenomena: [`Bloccaggio (App_3)`, `fuori sede dei rulli (App_13)`],
  },
  98: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}98_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}98_k2=0.5.jpg`,
    image_k2_1: `${pathImages}98_k2=1.jpg`,
    description: `Il difetto consiste nello spostamento dei rulli metallici, che costituiscono gli apparecchi di appoggio, dalla loro sede originaria, corrispondente, all’incirca, al centro delle piastre metalliche superiore ed inferiore. I rulli presentano normalmente un leggero spostamento; va segnalato come difetto qualora esso risulti eccessivo e l’elemento si trovi quasi al bordo di una delle due piastre.`,
    phenomena: [
      `preregolazione sbagliata (App_4)`,
      `Bloccaggio (App_3)`,
      `presenza di detriti (App_5)`,
      `ovalizzazione dei rulli (App_12)`,
    ],
  },
  99: {
    k1_1: 'Sempre = 1',
    k2_0_2: 'Accennato',
    k2_0_5: 'Intenso',
    k2_1: 'Rilevante',
    image_k2_0_2: `${pathImages}99_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}99_k2=0.5.jpg`, // TODO: mancante
    image_k2_1: `${pathImages}99_k2=1.jpg`, // TODO: mancante
    description: `Per deterioramento del teflon si intendono tutti quei fenomeni di degrado tipici del materiale, che riducono la funzionalità dell’apparecchio di appoggio. Tra essi si possono citare: la fuoriuscita, il distacco, lo schiacciamento e l’invecchiamento del materiale, con conseguente perdita delle sue caratteristiche lubrificanti`,
  },
  100: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% lunghezza',
    k1_1: 'Tutta la lunghezza',
    k2_0_2: '<1 cm',
    k2_0_5: '1÷3 cm',
    k2_1: '>3 cm',
    image_k2_0_2: `${pathImages}100_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}100_k2=0.5.JPG`,
    image_k2_1: `${pathImages}100_k2=1.jpg`,
    description: `Il difetto si riferisce alla differenza di quota tra l’estradosso della pavimentazione e il giunto. Spesso si presenta nel caso in cui l’elemento di continuità del giunto risulta particolarmente danneggiato.`,
    phenomena: [
      `movimenti di fondazione (Ril/Fond_5)`,
      `fuori piombo (Dif.Gen_6)`,
      `difetti degli apparecchi di appoggio`,
      `dislivello tra elementi contigui (Giunt_2)`,
    ], // TODO: link ?
  },
  101: {
    k1_0_2: 'Appena presente',
    k1_0_5: '~50% lunghezza',
    k1_1: 'Tutta la lunghezza',
    k2_0_2: '<1 cm',
    k2_0_5: '1÷3 cm',
    k2_1: '>3 cm',
    image_k2_0_2: `${pathImages}101_k2=0.2.jpg`,
    image_k2_0_5: `${pathImages}101_k2=0.5.jpg`,
    image_k2_1: `${pathImages}101_k2=1.jpg`,
    description: `Il difetto si riferisce alla differenza di quota tra le parti collegate dal giunto, a cui consegue una differenza di quota tra gli elementi di continuità del giunto stesso.`,
    phenomena: [
      `movimenti di fondazione (Ril/Fond_5)`,
      `fuori piombo (Dif.Gen_6)`,
      `difetti degli apparecchi di appoggio`,
      `dislivello giunto pavimentazione (Giunt_1)`,
    ], // TODO: link ?
  },
  102: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}102_k2=1.jpg`,
    description: `Il difetto si deve segnalare qualora sono visibili riparazioni successive degli elementi del giunto, realizzate con materiali eterogenei rispetto a quelli previsti. Spesso con tali riparazioni i difetti sono solo apparentemente eliminati`,
    phenomena: [
      `Dislivello giunto pavimentazione (Giunt_1)`,
      `Dislivello tra elementi contigui (Giunt_2)`,
      `ammaloramento dei profilati metallici (Giunt_7)`,
      `rottura elementi di continuità. (Giunt_8)`,
    ],
  },
  103: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}103_k2=1.png`,
    description: `Per massetti si intendono gli elementi di supporto, generalmente in resina o in malta di cemento, degli elementi di continuità del giunto. Essi costituiscono il collegamento tra la soletta pavimentata e gli elementi di continuità. Si dovrà segnalare il difetto se sono presenti lesioni trasversali rispetto all’asse del giunto su uno dei due massetti o su entrambi.`,
    phenomena: [
      `Dislivello giunto pavimentazione (Giunt_1)`,
      `Dislivello tra elementi contigui (Giunt_2)`,
      `riparazioni provvisorie (Giunt_3)`,
      `rottura/deformazione elementi di continuità. (Giunt_8)`,
    ],
  },
  104: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}104_k2=1.jpg`,
    description: `Il difetto è specifico dei giunti denominati «a tampone» sottopavimentati, in cui l’elemento di continuità è rappresentato da una colata di asfalto o di materiali di caratteristiche simili. Esso si manifesta attraverso fessure tra il tampone e la pavimentazione, di ampiezza superiore a qualche mm.`,
    phenomena: [
      `deformazione del tampone (Giunt_6)`,
      `movimenti delle fondazioni (Ril/Fond_5)`,
    ],
  },
  105: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}105_k2=1.jpg`,
    description: `Il difetto è specifico dei giunti denominati «a tampone» sottopavimentati, in cui l’elemento di continuità è rappresentato da una colata di asfalto o di materiali di caratteristiche simili. Esso si manifesta attraverso irregolarità sulla superficie del tampone, come avvallamenti, gradini o lesioni.`,
    phenomena: [`distacco del tampone (Giunt_5)`],
    suggestions: `Talvolta è la manifestazione di movimenti delle fondazioni (Ril/Fond_5)`,
  },
  106: {
    k1_1: 'Sempre = 1',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}106_k2=1.JPG`,
    description: `Il fenomeno comprende una serie di difetti che possono interessare i profilati metallici che costituiscono il giunto, tra cui:
    - deformazioni;
    - ossidazioni/corrosioni;
    - svergolamenti;
    - rotture;
    - lesioni;`,
    phenomena: [
      `Dislivello giunto pavimentazione (Giunt_1)`,
      `Dislivello tra elementi contigui (Giunt_2)`,
      `Massetti lesionati (Giunt_4)`,
    ],
  },
  107: {
    k1_0_2: 'In poche zone',
    k1_0_5: '~50% superficie',
    k1_1: 'Tutto il perimetro',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}107_k2=1.png`,
    description: `Il difetto si riferisce alla presenza di irregolarità superficiali o, nei casi più gravi, di lesioni, rotture, distacchi o porzioni mancanti sugli elementi di continuità dei giunti, quali profilati in neoprene, mattonelle in neoprene armato, pettini metallici, ecc.`,
    phenomena: [
      `Dislivello giunto pavimentazione (Giunt_1)`,
      `Dislivello tra elementi contigui (Giunt_2)`,
      `Massetti lesionati (Giunt_4)`,
    ],
    suggestions: [
      `Può essere indicativa di movimenti delle fondazioni (Ril/Fond_5)`,
    ],
  },
  108: {
    k1_0_2: 'Difetto limitato',
    k1_0_5: '~50% lunghezza',
    k1_1: 'Da sostituire',
    k2_1: 'Sempre = 1',
    image_k2_1: `${pathImages}108_k2=1.jpg`,
    description: `La scossalina è un elemento di tenuta che impedisce il passaggio di acqua, costituito da una membrana di vario materiale e posto a cavallo del giunto. Va segnalato nel caso in cui essa sia assente o danneggiata.`,
    phenomena: [
      `macchie di umidità (c.a/c.a.p._1, c.a/c.a.p._2)`,
      `Dilavamento (c.a/c.a.p._3)`,
      `distacchi di copriferro (c.a/c.a.p._5)`,
      `ossidazione delle barre di armatura (c.a/c.a.p._6, c.a/c.a.p._10)`,
    ],
  },
  '109.a': {
    image: `${pathImages}109.jpg`,
    description: `Nell’ispezione della pavimentazione stradale, si dovranno segnalare, se presenti, i seguenti difetti:
    - Dislivello tra rilevato e impalcato: esso può essere localizzato su entrambe le spalle o su una sola di esse e va segnalato se la differenza di quota tra il rilevato e l’impalcato supera i 2 cm;
    - Presenza di dossi: si riferisce alla presenza di avvallamenti o ondulazioni sulla superficie stradale superiori ai 15 mm;
    - Fessure/anomalie della pavimentazione: sono spesso presenti in corrispondenza degli elementi di continuità dei giunti e sono riconducibili ai normali fenomeni di ritiro del materiale costituente la pavimentazione.`,
    suggestions: `Talvolta sono sintomi di movimenti anomali di fondazione (Ril/Fond_5) o difetti in corrispondenza degli elementi di appoggio`,
  },
  '109.b': {
    image: `${pathImages}109.jpg`,
    description: `Nell’ispezione della pavimentazione stradale, si dovranno segnalare, se presenti, i seguenti difetti:
    - Dislivello tra rilevato e impalcato: esso può essere localizzato su entrambe le spalle o su una sola di esse e va segnalato se la differenza di quota tra il rilevato e l’impalcato supera i 2 cm;
    - Presenza di dossi: si riferisce alla presenza di avvallamenti o ondulazioni sulla superficie stradale superiori ai 15 mm;
    - Fessure/anomalie della pavimentazione: sono spesso presenti in corrispondenza degli elementi di continuità dei giunti e sono riconducibili ai normali fenomeni di ritiro del materiale costituente la pavimentazione.`,
    suggestions: `Talvolta sono sintomi di movimenti anomali di fondazione (Ril/Fond_5) o difetti in corrispondenza degli elementi di appoggio`,
  },
  '109.c': {
    image: `${pathImages}109.jpg`,
    description: `Nell’ispezione della pavimentazione stradale, si dovranno segnalare, se presenti, i seguenti difetti:
    - Dislivello tra rilevato e impalcato: esso può essere localizzato su entrambe le spalle o su una sola di esse e va segnalato se la differenza di quota tra il rilevato e l’impalcato supera i 2 cm;
    - Presenza di dossi: si riferisce alla presenza di avvallamenti o ondulazioni sulla superficie stradale superiori ai 15 mm;
    - Fessure/anomalie della pavimentazione: sono spesso presenti in corrispondenza degli elementi di continuità dei giunti e sono riconducibili ai normali fenomeni di ritiro del materiale costituente la pavimentazione.`,
    suggestions: `Talvolta sono sintomi di movimenti anomali di fondazione (Ril/Fond_5) o difetti in corrispondenza degli elementi di appoggio`,
  },
  110: {
    image: `${pathImages}110.jpg`,
    description: `I cordoli sono elementi, generalmente in calcestruzzo armato,posti lungo i bordi del ponte, a cui vengono ancorati i parapettio i guard-rail e su cui vengono posizionati i sistemi di scarico
    delle acque.Si dovranno segnalare gli eventuali fenomeni di degrado cheinteressano tali elementi, quali distacchi di copriferro odistacchi degli elementi lapidei.`,
    phenomena: [
      `calcestruzzo dilavato/ammalorato (C.a./c.a.p._3)`,
      `distacchi di copriferro (C.a./c.a.p._5) negli elementi contigui ad essi`,
    ],
  },
  111: {
    image: `${pathImages}111.jpg`,
    description: `Il difetto si riferisce alla mancanza di un adeguato sistema di convogliamento delle acque meteoriche che eviti il contatto dell’acqua con i materiali degli elementi strutturali.`,
    phenomena: [
      `macchie di umidità (c.a/c.a.p._1, c.a/c.a.p._2)`,
      `Dilavamento (c.a/c.a.p._3)`,
      `distacchi di copriferro (c.a/c.a.p._5)`,
      `ossidazione delle barre di armatura (c.a/c.a.p._6, c.a/c.a.p._10)`,
    ],
  },
  112: {
    image: `${pathImages}112.jpg`,
    description: `Qualora sia presente il sistema di convogliamento delle acque, è necessario che esso sia in grado di funzionare adeguatamente. Va quindi segnalato il caso in cui i pozzetti sono ostruiti e compromettono il funzionamento del sistema.`,
    phenomena: [
      `macchie di umidità (c.a/c.a.p._1, c.a/c.a.p._2)`,
      `Dilavamento (c.a/c.a.p._3)`,
    ],
  },
  113: {
    image: `${pathImages}113.jpg`,
    description: `Per scarichi corti si intendono elementi del sistema di convogliamento delle acque con lunghezza non adeguata, che spesso indirizzano l’acqua meteorica direttamente sulle superfici degl elementi strutturali.`,
    phenomena: [
      `tracce di scolo (Dif.Gen_1)`,
      `Dilavamento (c.a/c.a.p._3)`,
      `distacchi di copriferro (c.a/c.a.p._5)`,
      `ossidazione delle barre di armatura (c.a/c.a.p._6, c.a/c.a.p._10)`,
    ],
  },
  114: {
    image: `${pathImages}114.jpg`,
    description: `Qualora sia presente il sistema di convogliamento delle acque, è necessario che esso sia in grado di funzionare adeguatamente. Va quindi segnalato il caso in cui le parti terminali degli scarichi sono ostruite, compromettendo il funzionamento del sistema di smaltimento acque.`,
    phenomena: [
      `macchie di umidità (c.a/c.a.p._1, c.a/c.a.p._2)`,
      `Dilavamento (c.a/c.a.p._3)`,
    ],
  },
  115: {
    image: `${pathImages}115.jpg`,
    description: `Qualora sia presente il sistema di convogliamento delle acque, è necessario che esso sia in grado di funzionare adeguatamente. Va quindi segnalato il caso in cui gli elementi di scarico siano danneggiati o rotti, compromettendo il funzionamento del sistema.`,
    phenomena: [
      `Dilavamento (c.a/c.a.p._3)`,
      `distacchi di copriferro (c.a/c.a.p._5)`,
      `ossidazione delle barre di armatura (c.a/c.a.p._6, c.a/c.a.p._10)`,
    ],
  },
  116: {
    image: `${pathImages}116.jpg`,
    description: `Il difetto comprende i possibili fenomeni di degrado relativi alla pavimentazione dei marciapiedi, eventualmente presenti ai bordi del ponte, che possono compromettere la sicurezza dei pedoni.`,
  },
  '117.a': {
    image: `${pathImages}117.jpg`,
    description: `Il difetto si riferisce al danneggiamento dei pali eventualmente presenti sull’impalcato del ponte o del loro sistema di ancoraggio. Nell’ultimo caso si tratta principalmente di sistemi di ancoraggio metallici arrugginiti o calcestruzzi fessurati.`,
  },
  '117.b': {
    image: `${pathImages}117.jpg`,
    description: `Il difetto si riferisce al danneggiamento dei pali eventualmente presenti sull’impalcato del ponte o del loro sistema di ancoraggio. Nell’ultimo caso si tratta principalmente di sistemi di ancoraggio metallici arrugginiti o calcestruzzi fessurati.`,
  },
  118: {
    image: `${pathImages}118.jpg`,
    description: `Il difetto si riferisce alla perdita della vernice protettiva e all’ossidazione dei pali di illuminazione metallici, dovute a carenze nella manutenzione.`,
  },
  119: {
    image: `${pathImages}119.jpg`,
    description: `Il difetto si riferisce a sistemi di ancoraggio dei sottoservizi alle strutture del ponte non adeguati o danneggiati. Generalmente si riscontrano ancoraggi metallici corrosi o elementi a cui si ancorano i sottoservizi particolarmente degradati.`,
  },
  120: {
    image: `${pathImages}120.jpg`,
    description: `Il difetto si riferisce all’eventualità in cui i sistemi di ritenuta stradale risultino totalmente o parzialmente assenti, pregiudicando quindi la sicurezza del traffico veicolare e pedonale del ponte.`,
  },
  '121.a': {
    image: `${pathImages}121.png`,
    description: `Il difetto si riferisce alle situazioni in cui sono presenti danneggiamenti sugli elementi delle barriere di ritenuta stradale e/o parti di esse sono gravemente ossidate. Tale difetto indica inoltre i casi in cui le barriere, seppur in buone condizioni di conservazione, non sono ancorate in maniera opportuna e/o presentano elementi di ancoraggio danneggiati e/o gravemente ossidati, compromettendo in tal modo la sicurezza stradale`,
    suggestions: `Qualora la gravità del difetto sia tale da compromettere la funzionalità delle barriere di ritenuta stradale e costituisca un potenziale pericolo al traffico veicolare, indicare il difetto come guardiavia assente`,
  },
  '121.b': {
    image: `${pathImages}121.png`,
    description: `Il difetto si riferisce alle situazioni in cui sono presenti danneggiamenti sugli elementi delle barriere di ritenuta stradale e/o parti di esse sono gravemente ossidate. Tale difetto indica inoltre i casi in cui le barriere, seppur in buone condizioni di conservazione, non sono ancorate in maniera opportuna e/o presentano elementi di ancoraggio danneggiati e/o gravemente ossidati, compromettendo in tal modo la sicurezza stradale`,
    suggestions: `Qualora la gravità del difetto sia tale da compromettere la funzionalità delle barriere di ritenuta stradale e costituisca un potenziale pericolo al traffico veicolare, indicare il difetto come guardiavia assente`,
  },
  '121.c': {
    image: `${pathImages}121.png`,
    description: `Il difetto si riferisce alle situazioni in cui sono presenti danneggiamenti sugli elementi delle barriere di ritenuta stradale e/o parti di esse sono gravemente ossidate. Tale difetto indica inoltre i casi in cui le barriere, seppur in buone condizioni di conservazione, non sono ancorate in maniera opportuna e/o presentano elementi di ancoraggio danneggiati e/o gravemente ossidati, compromettendo in tal modo la sicurezza stradale`,
    suggestions: `Qualora la gravità del difetto sia tale da compromettere la funzionalità delle barriere di ritenuta stradale e costituisca un potenziale pericolo al traffico veicolare, indicare il difetto come guardiavia assente`,
  },
  '121.d': {
    image: `${pathImages}121.png`,
    description: `Il difetto si riferisce alle situazioni in cui sono presenti danneggiamenti sugli elementi delle barriere di ritenuta stradale e/o parti di esse sono gravemente ossidate. Tale difetto indica inoltre i casi in cui le barriere, seppur in buone condizioni di conservazione, non sono ancorate in maniera opportuna e/o presentano elementi di ancoraggio danneggiati e/o gravemente ossidati, compromettendo in tal modo la sicurezza stradale`,
    suggestions: `Qualora la gravità del difetto sia tale da compromettere la funzionalità delle barriere di ritenuta stradale e costituisca un potenziale pericolo al traffico veicolare, indicare il difetto come guardiavia assente`,
  },
  122: {
    image: `${pathImages}122.jpg`, // TODO: mancante
    description: `Il difetto si riferisce alle situazioni in cui sono presenti danneggiamenti sugli elementi di spartitraffico e/o parti di esse sono gravemente ossidate. Tale difetto indica inoltre i casi in cui le barriere, seppur in buone condizioni di conservazione, non sono ancorate in maniera opportuna e/o presentano elementi di ancoraggio danneggiati e/o gravemente ossidati, compromettendo in tal modo la sicurezza stradale`,
    suggestions: `Qualora la gravità del difetto sia tale da compromettere la funzionalità dello spartitraffico e costituisca un potenziale pericolo al traffico veicolare, indicare il difetto come spartitraffico assente`,
  },
};
