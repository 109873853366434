import styled from '@emotion/styled';
import { Accordion } from '@mui/material';
import { colors } from '../../constants/colors';

export const AccordionContainer = styled(Accordion)`
  background-color: ${colors.LIGHTGREY};
  margin-bottom: 10px;
  box-shadow: none;
  border-radius: 8px;
  &:before {
    background-color: transparent;
  }
`;
