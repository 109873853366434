import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { colors } from '../../constants/colors';

export const Container = styled('div')({
  position: 'relative',
});

export const InfoBtnContainer = styled('div')(({ isDivider }) => ({
  position: 'absolute',
  top: isDivider ? '18px ' : '-17px',
  right: 0,
  left: isDivider && 20,
  width: 36,
  height: 12,
  backgroundColor: colors.BLUE,
  textTransform: 'uppercase',
  padding: 7,
  color: colors.WHITE,
  borderRadius: 3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  zIndex: 2,
}));
export const RightButtonContainer = styled('div')({
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
});
export const MessageInfoContainer = styled('div')({
  display: 'flex',
  gap: 10,
  marginTop: 10,
  backgroundColor: colors.GREY,
  borderRadius: 3,
  padding: '10px 16px',
  flexDirection: 'column',
});

export const MessagePopUpContainer = styled('div')({
  width: '90%',
  marginLeft: 10,
});

export const ImgStyle = styled('img')({
  maxWidth: '100%',
  height: 'auto',
});

export const InfoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
});

export const ButtonContainer = styled(Button)({
  float: 'right',
});
