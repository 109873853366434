import styled from '@emotion/styled';
import { Avatar } from '@mui/material';
import { colors } from 'constants/colors';

export const Container = styled('div')({
  width: '100%',
});

export const WhiteBoxInfo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  margin: '20px 0',
  // height: '18.0625em',
  display: 'flex',
  padding: '20px',
  flexDirection: 'column',
  borderRadius: '13px',
}));

export const Upper = styled('div')({
  display: 'flex',
});

export const Down = styled('div')({
  display: 'flex',
  gap: 9,
  marginTop: '20px',
});

export const AvatarBridge = styled(Avatar)({
  backgroundColor: colors.LIGHTGREY,
});

export const AvatarSpan = styled('div')({
  zIndex: 2,
  height: '34px',
  width: '34px',
  backgroundColor: colors.BLACK,
  color: colors.WHITE,
  borderRadius: '100%',
  fontSize: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '-30px',
});

export const BridgesIcon = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
});

export const ContainerInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: '60px',
});

export const Status = styled('div')(({ color }) => ({
  height: '11px',
  width: '11px',
  borderRadius: '100%',
  backgroundColor: color,
  margin: '0 10px',
}));

export const Typo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '22px',
});

export const ContainerText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: '10px',
});

export const ContainerMedia = styled('div')({
  display: 'flex',
  gap: 5,
});

export const Row = styled('div')({
  display: 'flex',
  gap: 10,
});

export const Box = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const MediaInfoBox = styled('div')({
  display: 'flex',
  flex: 1,
});

export const ImageCircle = styled('img')({
  objectFit: 'cover',
  objectPosition: 'center',
  width: '100%',
  height: '100%',
});
