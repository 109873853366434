import { Button } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import { v4 as uuidv4 } from 'uuid';
import {
  DrawerMUI,
  DrawerNewInspection,
  HeaderPage,
  TourWrapper,
} from 'components';
import { ExportsPanel } from 'components/ExportsPanel/ExportsPanel';
/* import { overviewData } from 'components/ExportsPanel/pdf/OverviewPdf/constants'; */
import { OverviewPdf } from 'components/ExportsPanel/pdf/OverviewPdf/OverviewPdf';
import { avatar } from 'components/TabCreateInspection/costants';
import { apiBridges, apiCollaborators } from 'constants/api';
import { STRUCTURES_PATHS } from 'constants/paths';
import {
  PermissionType,
  PermissionTypeSelectOptions,
} from 'constants/permissions';
import { SubscriptionStatus } from 'constants/subscription';
import { tour_03 } from 'constants/tour';
import { useSubscription } from 'hooks';

import {
  useAuthSelector,
  useGlobalSelector,
  useInspectionSelector,
} from 'stores';
import { accessHasExpired } from 'utils';
import { downloadBlob } from 'utils/export';
import { handleUpload } from 'utils/upload';
import { removeUnsafeCharacters } from 'utils/utilities';
import { steps } from './StructureDetail.tourSteps';
import Summary from './Summary/Summary';

const CanShareMap = {
  // this object maps the possibile actions on the sharing popover
  [PermissionType.CanView]: false,
  [PermissionType.CanEdit]: true,
  [PermissionType.CanViewByInspectionReference]: false,
  [PermissionType.NoPermission]: false,
};

const StructureShareOptions = PermissionTypeSelectOptions;
/* .filter(
  el => el.value !== PermissionType.CanView
); */

const StructureDetail = () => {
  const { get, response, error } = useFetch();
  const navigate = useNavigate();
  const params = useParams();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { setInspectionDisabled } = useInspectionSelector();
  const { user } = useAuthSelector();
  const { enqueueSnackbar } = useSnackbar();
  const id = params.structureId;
  const { subscriptionStatus } = useSubscription();
  const { get: exportLambdaUrl } = useFetch(
    process.env.REACT_APP_EXPORT_LAMBDA_URL
  );

  //State
  const [canShare, setCanShare] = useState(false);
  const [userPermissionOnStructure, setUserPermissionOnStructure] = useState();
  const [structure, setStructure] = useState({});
  const [collaborators, setCollaborators] = useState([]);
  const [open, setOpen] = useState(false);
  const [openExportsPanel, setOpenExportsPanel] = useState(false);
  const [isStructureOwner, setIsStructureOwner] = useState(false);
  const [accessExpirationDate, setAccessExpirationDate] = useState(null);

  //   const [files, setFiles] = useState([]);

  const arbitraryIntCode = removeUnsafeCharacters(
    structure?.arbitraryInternalCode
  );
  const date = new Date();
  const currentDate = date.toLocaleDateString().replaceAll('/', '-');
  const currentTime = date.toLocaleTimeString('it-IT').replaceAll(':', '-');
  const pdfName = `${arbitraryIntCode} - Sintesi CdA Complessiva - ${currentDate} ${currentTime}`;

  const graphRef = useRef(null);
  const graphRef2 = useRef(null);

  const dataURItoBlob = dataURI => {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  };

  const uploadTempCdAPie = async () => {
    try {
      showLoader();
      const canvas = await html2canvas(graphRef?.current, {
        backgroundColor: null,
        scrollX: 0,
        scrollY: 0,
        scale: 2,
        height: 280,
        windowWidth: window.innerWidth,
        ignoreElements: element => element.classList.contains('swiper'),
      });
      const image = canvas.toDataURL('image/png', 1.0);
      const blobData = dataURItoBlob(image);

      const idFile = `${uuidv4()}-${new Date().getTime()}`;
      if (blobData) {
        const res = await handleUpload(
          `bridges/${id}/temp-relazione-tecnica-cda-complessiva`,
          blobData,
          idFile,
          `${id}-${new Date().getTime()}-CdAGraph.png`
        );
        return res?.s3Path;
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const exportOverview = async formData => {
    try {
      showLoader();
      //const overviewData = await get(`exports/${id}/levelTwo`);
      const overviewData = await exportLambdaUrl(`/?bridgeId=${id}&type=27`);
      const canvas = await html2canvas(graphRef?.current, {
        backgroundColor: null,
        scrollX: 0,
        scrollY: 0,
        /* dpi: 5000, */
        scale: 2,
        height: 280,
        windowWidth: window.innerWidth,
        ignoreElements: element => element.classList.contains('swiper'),
      });
      const canvas2 = await html2canvas(graphRef2?.current, {
        backgroundColor: null,
        scrollX: 0,
        scrollY: 0,
        windowWidth: 2300,
        height: 200,
        ignoreElements: element => element.classList.contains('swiper'),
      });
      const image = canvas.toDataURL('image/png', 1.0);
      const image2 = canvas2.toDataURL('image/png', 1.0);
      const PDFOverview = await pdf(
        <OverviewPdf
          imageData={image}
          imageData2={image2}
          pdfData={overviewData}
          structure={structure}
          formData={formData}
        />
      ).toBlob();
      downloadBlob(PDFOverview, pdfName);
      return PDFOverview;
    } catch (error) {
      console.log(error);
      return enqueueSnackbar(`Impossibile eseguire l'esportazione`, {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }
  };

  const shareStructure = () => {
    handleCollaborators();
  };

  const handleCollaborators = async () => {
    const res = await get(`${apiCollaborators.ROOT}/structures/${id}`);
    setCollaborators(
      res
        .map(el => {
          return {
            ...el,
            collaborationInfo: {
              ...el?.sharedStructuresBy[0],
              type: el?.collaboratorOf[0]?.type,
            },
          };
        })
        .filter(
          el =>
            el?.collaborationInfo?.permission !==
            PermissionType.CanViewByInspectionReference
        )
    );
  };

  const handleMyPermissions = async () => {
    const res = await get(
      `${apiBridges.ROOT}/${id}${apiBridges.MY_PERMISSIONS}`
    );
    if (!res) {
      setCanShare(false);
    } else {
      setCanShare(CanShareMap[res?.permission]);
      setUserPermissionOnStructure(res?.permission);
      if (res.accessExpiration) {
        setAccessExpirationDate(res.accessExpiration);
        if (accessHasExpired(res.accessExpiration)) {
          navigate(STRUCTURES_PATHS.STRUCTURES_LIST);
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      handleCollaborators();
      handleMyPermissions();
    }
  }, [id]);

  useEffect(() => {
    if (!open) {
      handleCollaborators();
      handleMyPermissions();
    }
  }, [open]);

  useEffect(() => {
    error && navigate(STRUCTURES_PATHS.STRUCTURES_LIST);
  }, [error]);

  //useEffect()
  //   const [files, setFiles] = useState([]);
  const handleDetail = async () => {
    let result = '';
    let involvedLevelsColors = '';
    try {
      showLoader();
      result = await get(`${apiBridges.ROOT}/${id}`);
      involvedLevelsColors = await get(
        `${apiBridges.ROOT}/${id}/involvedLevelsColors`
      );
      if (result && involvedLevelsColors) {
        result['involvedLevelsColors'] = involvedLevelsColors;
      }
    } catch (err) {
      console.error('Error in put diagnosis: ', err);
    } finally {
      hideLoader();
      response.ok && setStructure(result);
    }
  };
  useEffect(() => {
    id && handleDetail().catch(console.error);
  }, [id]);
  useEffect(() => {
    if (!open) {
      handleDetail().catch(console.error);
    }
  }, [open]);

  useEffect(() => {
    if (user && structure) {
      if (
        user.id === structure?.owner?.id ||
        user.parent?.id === structure?.owner?.id
      ) {
        setIsStructureOwner(true);
      }
    }
  }, [user, structure]);

  useEffect(() => {
    setInspectionDisabled(null);
  }, []);

  //Const data Structure
  const titlePage = structure?.name || '-';
  const council = structure?.council?.name;
  const address =
    structure?.address || structure?.province?.code || council
      ? `${structure?.address ? structure.address + ',' : ''} ${council} (${
          structure?.province?.code
        })`
      : '-';
  const internalCode = structure?.internalCode || '-';
  const owner = structure?.owner?.email || '-';
  const infoData = {
    street: address || '-',
    city: structure?.council?.name
      ? `Comune di ${structure?.council?.name}`
      : '-',
    code: internalCode ? `ID ${internalCode}` : '-',
    owner,
  };
  const lastInspectionId = structure?.lastInspectionId;

  return (
    <>
      {structure && (
        <HeaderPage
          back={STRUCTURES_PATHS.STRUCTURES_LIST}
          shareOptions={StructureShareOptions}
          sharedWith={collaborators}
          canShare={canShare}
          isOwner={isStructureOwner}
          handleShare={shareStructure}
          shareRoute={apiCollaborators.STRUCTURE_COLLBAORATORS}
          entityId={id}
          accessExpiration={accessExpirationDate}
          buttons={
            <div className={tour_03.step_03}>
              <Button
                disabled={
                  !lastInspectionId ||
                  structure.exportInspectionCompletionPercentage === 0
                }
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => setOpenExportsPanel(!openExportsPanel)}
              >
                Esporta
              </Button>

              {userPermissionOnStructure === PermissionType.CanEdit &&
                subscriptionStatus === SubscriptionStatus.ATTIVA && (
                  <Button
                    onClick={() =>
                      navigate(
                        STRUCTURES_PATHS.DUPLICATE_STRUCTURE.replace(
                          ':structureId',
                          id
                        )
                      )
                    }
                    variant="outlined"
                    color="secondary"
                    size="small"
                  >
                    Duplica opera
                  </Button>
                )}

              {lastInspectionId && (
                <Button
                  disabled={!lastInspectionId}
                  variant="containedBlack"
                  color="secondary"
                  size="small"
                  onClick={() =>
                    navigate(
                      STRUCTURES_PATHS.INSPECTIONS_ARCHIVE.replace(
                        ':structureId',
                        structure.id
                      )
                    )
                  }
                >
                  Archivio attività
                </Button>
              )}
              {userPermissionOnStructure === PermissionType.CanEdit && (
                <Button
                  onClick={() => setOpen(!open)}
                  variant="contained"
                  color="primary"
                  size="small"
                  /* disabled={id === '11' || id === '22'} */
                >
                  {!lastInspectionId ? `Compila L0,L1` : `Nuova attività`}
                </Button>
              )}
            </div>
          }
          title={titlePage}
          avatar={avatar}
          infoData={infoData}
        />
      )}

      <DrawerNewInspection
        open={open}
        setOpen={setOpen}
        isOwner={isStructureOwner}
      />
      <DrawerMUI
        onClose={() => !structure.firstExportDate && window.location.reload()}
        open={openExportsPanel}
        setOpen={setOpenExportsPanel}
        title={'Esporta'}
        subtitle={`Seleziona la tipologia di esportazione desiderata.`}
        // subtitle={`Seleziona la tipologia di esportazione desiderata, una volta esportati troverai i file all'interno dell'area Documenti della piattaforma.`}
      >
        <ExportsPanel
          exportOverview={exportOverview}
          uploadTempCdAPie={uploadTempCdAPie}
          setOpen={setOpenExportsPanel}
          open={openExportsPanel}
          structureName={structure?.name}
          structureInfo={structure}
          pdfName={pdfName}
        />
      </DrawerMUI>
      {structure?.id && (
        <Summary
          structure={structure}
          userPermissionOnStructure={userPermissionOnStructure}
          graphRef={graphRef}
          graphRef2={graphRef2}
          openDrawerNewInspection={open}
          setOpenDrawerNewInspection={setOpen}
        />
      )}
      <TourWrapper steps={steps} />
    </>
  );
};

export default StructureDetail;
