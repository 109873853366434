/* import { Accordion10CaratteristicheGeometriche } from 'pages/Inspections/CreateInspection/L0/GeneralData/Accordion10CaratteristicheGeometriche/Accordion10CaratteristicheGeometriche'; */
import { WebL1Fields } from 'constants/inspectionFields';
import { Accordion1GeneralInfoL1 } from '../Accordion1GeneralInfo/Accordion1GeneralInfoL1';
import { Accordion2InspectionInformationL1 } from '../Accordion2InspectionInformation/Accordion2InspectionInformation';
import { Accordion3LandslideRiskL1 } from '../Accordion3LandslideRisk/Accordion3LandslideRiskL1';
import { Accordion4HydraulicRiskL1 } from '../Accordion4HydraulicRisk/Accordion4HydraulicRiskL1';
import { Accordion6AnnotazioniPerReport } from '../Accordion6AnnotazioniPerReport/Accordion6AnnotazioniPerReport';

export const AccordionDataGeneralL1 = [
  {
    id: '1',
    title: '1. Informazioni Generali',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion1GeneralInfoL1 />,
    indicatorFields: WebL1Fields.Contesto.informazioniGenerali,
  },
  {
    id: '2',
    title: '2. Informazioni di ispezione',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion2InspectionInformationL1 />,
    indicatorFields: WebL1Fields.Contesto.informazioniIspezione,
  },
  {
    id: '3',
    title: '3. Rischio frane',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion3LandslideRiskL1 />,
    indicatorFields: WebL1Fields.Contesto.rischioFrana,
  },
  {
    id: '4',
    title: '4. Rischio idraulico',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion4HydraulicRiskL1 />,
    indicatorFields: WebL1Fields.Contesto.rischioIdraulico,
  },
  /*   {
    id: '5',
    title: '5. Caratteristiche geometriche',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion10CaratteristicheGeometriche levelL1={true} />,
  }, */
  {
    id: '5',
    title: '5. Annotazioni ispezioni per report',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion6AnnotazioniPerReport />,
  },
];
