import { Button } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Notification } from 'components';
import { colors } from 'constants/colors';
import { NOTIFICATION_ROOT } from 'constants/paths';
import { tour_01 } from 'constants/tour';
import { formatData } from 'utils/utilities';
import {
  ButtonContainer,
  DotAlert,
  IconContainer,
} from './NotificationPopover.style';

export const NotificationPopover = ({
  notifications,
  handleReadAllNotification,
  handleReadNotification,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={tour_01.step_02c}>
      <IconContainer>
        {notifications.some(({ read }) => !read) && <DotAlert />}
        <img onClick={handleClick} src="/icons/notification-icn.svg" />
      </IconContainer>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            padding: '20px 30px 40px 30px',
            border: ` 1px solid ${colors.SILVER}`,
            borderRadius: '10px',
            maxWidth: '400px',
          },
          className: 'ignore-padding',
        }}
      >
        <Typography variant="h2" sx={{ fontSize: '16px' }}>
          Notifiche
        </Typography>
        {notifications?.length === 0 && (
          <Typography>Nessuna notifica presente</Typography>
        )}
        {notifications?.length > 0 && (
          <>
            {notifications.map(({ id, text, createdAt, read, link, type }) => (
              <Notification
                key={id}
                id={id}
                text={text}
                time={formatData(createdAt)}
                read={read}
                link={link}
                hideIcon
                handleReadNotification={handleReadNotification}
                type={type}
              />
            ))}
            <ButtonContainer>
              <Button
                variant="text"
                color="secondary"
                size="small"
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => handleReadAllNotification()}
              >
                Segna tutte come lette
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  navigate(NOTIFICATION_ROOT);
                  handleClose();
                }}
              >
                Vedi tutte
              </Button>
            </ButtonContainer>
          </>
        )}
      </Popover>
    </div>
  );
};

NotificationPopover.propTypes = {
  notifications: PropTypes.array,
  handleReadAllNotification: PropTypes.func,
  handleReadNotification: PropTypes.func,
};
