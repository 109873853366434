import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, InfoWrapper, Table } from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';

import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import { GridWrap } from './AppoggiForm.styles';
import DispositiviSismiciForm from './DispositiviSismiciForm';
const DispositiviSismiciSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const spallePileCollegamentiTableDispositiviSismiciTableWatch = watch(
    `spallePileCollegamentiTable[${selectedIdx}].dispositiviSismiciTable`
  );

  // table setup
  const dataConfigDispositiviSismici = {
    columns: {
      codice: { label: 'Codice' },
      nomeDispositivoSismico: { label: 'Nome elemento' },
    },
  };

  // pulvini setup
  const [selectedDispositiviSismici, setSelectedDispositiviSismici] = useState(
    []
  );
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsDispositiviSismici = item => {
    setValue('l0t2a4_dispositiviSismiciTableValue', [item]);
    setValue(
      'l0t2a4_nomeDispositivoSismicoTableValue',
      item.nomeDispositivoSismico
    );
  };
  const handleSelectAllClickDispositiviSismici = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `spallePileCollegamentiTable[${selectedIdx}].dispositiviSismiciTable`
      );
      setSelectedDispositiviSismici(selectedValue);
      setValue('l0t2a4_dispositiviSismiciTableValue', selectedValue);
    } else {
      setSelectedDispositiviSismici([]);
      setValue('l0t2a4_dispositiviSismiciTableValue', []);
    }
  };

  return (
    <>
      <GridWrap container spacing={2} style={{ marginTop: 16 }}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.collegamentiAltriDispositiviSismici.drawerTitle}
          drawerText={INFO.collegamentiAltriDispositiviSismici.drawerText}
        >
          <Divider>ALTRI DISPOSITIVI SISMICI</Divider>
        </InfoWrapper>
        {spallePileCollegamentiTableDispositiviSismiciTableWatch?.length ===
          0 && (
          <GridWrap item xs={12} paddingTop={'0px !important'}>
            <Typography>
              Nessun dispositivo sismico presente in questo elemento
            </Typography>
          </GridWrap>
        )}
      </GridWrap>
      {spallePileCollegamentiTableDispositiviSismiciTableWatch?.length > 0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <DispositiviSismiciForm
              dispositiviSismiciFields={
                spallePileCollegamentiTableDispositiviSismiciTableWatch || []
              }
              selected={selectedDispositiviSismici}
              setSelected={setSelectedDispositiviSismici}
              dispositiviSismiciTable={`spallePileCollegamentiTable[${selectedIdx}].dispositiviSismiciTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Dispositivi Sismici'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`spallePileCollegamentiTable[${selectedIdx}].dispositiviSismiciTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Struttura]}
          />
          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={
                  spallePileCollegamentiTableDispositiviSismiciTableWatch || []
                }
                config={dataConfigDispositiviSismici}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsDispositiviSismici(item);
                            setSelectedDispositiviSismici([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        spallePileCollegamentiTableDispositiviSismiciTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedDispositiviSismici}
                onSelectAllClick={handleSelectAllClickDispositiviSismici}
                setSelected={setSelectedDispositiviSismici}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
DispositiviSismiciSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default DispositiviSismiciSection;
