import {
  inputBaseClasses,
  TablePagination as MuiTablePagination,
  styled,
  tablePaginationClasses,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Pagination } from 'components';
import {
  PaginationCont,
  TablePaginationContainer,
} from './TablePagination.style';

export const TablePaginationBase = styled(MuiTablePagination)({
  [`.${tablePaginationClasses.spacer}`]: {
    display: 'none',
  },
  [`.${tablePaginationClasses.displayedRows}`]: {
    display: 'none',
  },
  [`.${tablePaginationClasses.actions}`]: {
    display: 'none',
  },
  [`.${tablePaginationClasses.selectLabel}`]: {
    fontSize: 14,
    fontFamily: `"Work Sans", "Helvetica", "Arial", sans-serif`,
  },
  [`.${inputBaseClasses.root}`]: {
    height: 'auto',
  },
});

export const TablePagination = ({
  count,
  page,
  onChange,
  label,
  pageSize,
  total,
  onRowsPerPageChange,
}) => {
  return (
    <TablePaginationContainer>
      <TablePaginationBase
        labelRowsPerPage="Visualizza Elementi"
        count={count}
        page={pageSize}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 15]}
        onPageChange={() => {}}
        onRowsPerPageChange={event => {
          onRowsPerPageChange(event);
        }}
      />
      <PaginationCont>
        <Pagination
          label={label}
          page={page}
          count={count}
          pageSize={pageSize}
          total={total}
          onChange={data => {
            onChange(data);
          }}
        />
      </PaginationCont>
    </TablePaginationContainer>
  );
};

TablePagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
};
