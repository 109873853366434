import { useLocation } from 'react-router-dom';
import { InspectionRouteActions } from 'constants/inspections';

const useInspectionPath = () => {
  const { pathname } = useLocation();

  const isViewingInspection =
    pathname.split('/').pop() === InspectionRouteActions.VIEW;

  const isEditingInspection =
    pathname.split('/').pop() === InspectionRouteActions.EDIT;

  return { isViewingInspection, isEditingInspection };
};

export { useInspectionPath };
