import { useFormContext } from 'react-hook-form';
import { calculateDifettosita } from './calculateDifettosita';

const calculateDifettositaSvrastruttura = () => {
  const { getValues } = useFormContext();

  const calculateSovrastrutturaDifettositaStrutturale = camMensTamp => {
    if (Array.isArray(camMensTamp)) {
      if (camMensTamp[0] === 'nessun elemento associato') {
        return null;
      }
      const campate = getValues('mensoleTamponiCampateImpalcatoTable');
      const tmpArchiTable = [];
      const tmpControventiTable = [];
      const tmpElementiExtraTable = [];
      const tmpPiedrittiTable = [];
      const tmpSoletteTable = [];
      const tmpTirantiTable = [];
      const tmpTraversiTable = [];
      const tmpTraviTable = [];
      campate.forEach(el => {
        if (camMensTamp.includes(el.codice)) {
          tmpArchiTable.push(...el.archiTable);
          tmpControventiTable.push(...el.controventiTable);
          tmpElementiExtraTable.push(...el.elementiExtraTable);
          tmpPiedrittiTable.push(...el.piedrittiTable);
          tmpSoletteTable.push(...el.soletteTable);
          tmpTirantiTable.push(...el.tirantiTable);
          tmpTraversiTable.push(...el.traversiTable);
          tmpTraviTable.push(...el.traviTable);
        }
      });

      const allSovrastrutturaElements = [
        ...tmpArchiTable,
        ...tmpControventiTable,
        ...tmpElementiExtraTable,
        ...tmpPiedrittiTable,
        ...tmpSoletteTable,
        ...tmpTirantiTable,
        ...tmpTraversiTable,
        ...tmpTraviTable,
      ];

      if (
        allSovrastrutturaElements.some(el => !el.CDAstrutturale) ||
        allSovrastrutturaElements.length == 0
      ) {
        return null;
      }
      return calculateDifettosita(allSovrastrutturaElements, 'strutturale');
    } else {
      const campate = getValues('mensoleTamponiCampateImpalcatoTable');
      const tmpArchiTable = [];
      const tmpControventiTable = [];
      const tmpElementiExtraTable = [];
      const tmpPiedrittiTable = [];
      const tmpSoletteTable = [];
      const tmpTirantiTable = [];
      const tmpTraversiTable = [];
      const tmpTraviTable = [];

      campate.forEach(el => {
        if (camMensTamp.includes(el.codice)) {
          tmpArchiTable.push(...el.archiTable);
          tmpControventiTable.push(...el.controventiTable);
          tmpElementiExtraTable.push(...el.elementiExtraTable);
          tmpPiedrittiTable.push(...el.piedrittiTable);
          tmpSoletteTable.push(...el.soletteTable);
          tmpTirantiTable.push(...el.tirantiTable);
          tmpTraversiTable.push(...el.traversiTable);
          tmpTraviTable.push(...el.traviTable);
        }
      });

      const allSovrastrutturaElements = [
        ...tmpArchiTable,
        ...tmpControventiTable,
        ...tmpElementiExtraTable,
        ...tmpPiedrittiTable,
        ...tmpSoletteTable,
        ...tmpTirantiTable,
        ...tmpTraversiTable,
        ...tmpTraviTable,
      ];

      if (
        allSovrastrutturaElements.some(el => !el.CDAstrutturale) ||
        allSovrastrutturaElements.length == 0
      ) {
        return null;
      }
      return calculateDifettosita(allSovrastrutturaElements, 'strutturale');
    }
  };
  const calculateSovrastrutturaDifettositaSismica = camMensTamp => {
    if (Array.isArray(camMensTamp)) {
      if (camMensTamp[0] === 'nessun elemento associato') {
        return null;
      }
      const campate = getValues('mensoleTamponiCampateImpalcatoTable');
      const tmpArchiTable = [];
      const tmpControventiTable = [];
      const tmpElementiExtraTable = [];
      const tmpPiedrittiTable = [];
      const tmpSoletteTable = [];
      const tmpTirantiTable = [];
      const tmpTraversiTable = [];
      const tmpTraviTable = [];
      campate.forEach(el => {
        if (camMensTamp.includes(el.codice)) {
          tmpArchiTable.push(...el.archiTable);
          tmpControventiTable.push(...el.controventiTable);
          tmpElementiExtraTable.push(...el.elementiExtraTable);
          tmpPiedrittiTable.push(...el.piedrittiTable);
          tmpSoletteTable.push(...el.soletteTable);
          tmpTirantiTable.push(...el.tirantiTable);
          tmpTraversiTable.push(...el.traversiTable);
          tmpTraviTable.push(...el.traviTable);
        }
      });

      const allSovrastrutturaElements = [
        ...tmpArchiTable,
        ...tmpControventiTable,
        ...tmpElementiExtraTable,
        ...tmpPiedrittiTable,
        ...tmpSoletteTable,
        ...tmpTirantiTable,
        ...tmpTraversiTable,
        ...tmpTraviTable,
      ];

      if (
        allSovrastrutturaElements.some(el => !el.CDAsismica) ||
        allSovrastrutturaElements.length == 0
      ) {
        return null;
      }
      return calculateDifettosita(allSovrastrutturaElements, 'sismica');
    } else {
      const campate = getValues('mensoleTamponiCampateImpalcatoTable');
      const tmpArchiTable = [];
      const tmpControventiTable = [];
      const tmpElementiExtraTable = [];
      const tmpPiedrittiTable = [];
      const tmpSoletteTable = [];
      const tmpTirantiTable = [];
      const tmpTraversiTable = [];
      const tmpTraviTable = [];

      campate.forEach(el => {
        if (camMensTamp.includes(el.codice)) {
          tmpArchiTable.push(...el.archiTable);
          tmpControventiTable.push(...el.controventiTable);
          tmpElementiExtraTable.push(...el.elementiExtraTable);
          tmpPiedrittiTable.push(...el.piedrittiTable);
          tmpSoletteTable.push(...el.soletteTable);
          tmpTirantiTable.push(...el.tirantiTable);
          tmpTraversiTable.push(...el.traversiTable);
          tmpTraviTable.push(...el.traviTable);
        }
      });

      const allSovrastrutturaElements = [
        ...tmpArchiTable,
        ...tmpControventiTable,
        ...tmpElementiExtraTable,
        ...tmpPiedrittiTable,
        ...tmpSoletteTable,
        ...tmpTirantiTable,
        ...tmpTraversiTable,
        ...tmpTraviTable,
      ];

      if (
        allSovrastrutturaElements.some(el => !el.CDAsismica) ||
        allSovrastrutturaElements.length == 0
      ) {
        return null;
      }
      return calculateDifettosita(allSovrastrutturaElements, 'sismica');
    }
  };

  return {
    calculateSovrastrutturaDifettositaStrutturale,
    calculateSovrastrutturaDifettositaSismica,
  };
};
export default calculateDifettositaSvrastruttura;
