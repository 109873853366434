import PropTypes from 'prop-types';
import React from 'react';
import { CustomCell } from './TabelCell.styles';

export function TableCell({
  value,
  type,
  children,
  typevariant,
  format,
  formatValue,
}) {
  const renderCell = () => {
    switch (type) {
      case 'IMAGE': {
        return <div />;
      }
    }
  };

  const renderFormat = (format, value) => {
    if (
      value &&
      typeof value !== 'number' &&
      typeof value !== 'string' &&
      value !== '' &&
      value?.length > 0
    ) {
      const result = value
        ?.map(el => {
          return format?.find(obj => obj.value === el)?.label;
        })
        .join(', ');
      return result;
    } else {
      return format?.find(el => el.value === value)?.label;
    }
  };
  return (
    <CustomCell
      typevariant={typevariant}
      // align={typeof value !== 'object' ? 'left' : 'right'}
      data-value="tableCell"
      style={{
        fontSize: '13px',
        whiteSpace: 'normal',
        wordBreak: 'break-all',
      }}
    >
      {format
        ? renderFormat(format, value)
        : // format?.find(el => el.value === value)?.label
        formatValue
        ? formatValue(value)
        : type
        ? renderCell()
        : children
        ? children
        : value}
    </CustomCell>
  );
}

TableCell.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
  children: PropTypes.node,
  typevariant: PropTypes.string,
  format: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  formatValue: PropTypes.func,
};

export default React.memo(TableCell);
