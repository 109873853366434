import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const BoxContainer = styled('div')(
  ({ status, defect, topic, structureSection }) => ({
    width: '220px',
    minHeight: '120px',
    height: !status && !defect && '90px',
    borderRadius: 8,
    border: `1px solid ${colors.PALAGREY}`,
    backgroundColor:
      (status?.presenza === 3 && topic == !'Spartitraffico') ||
      status?.presenza === 5 ||
      defect === 4
        ? colors.GREY
        : colors.WHITE,
    /* backgroundColor:
    status === 1 ? colors.WHITE : status === 2 ? colors.GREY : colors.WHITE, */
    padding: 16,
    display:
      structureSection &&
      status?.presenzaElementiStruttura === 'assenti' &&
      'none',
  })
);

export const Row = styled('div')({
  marginTop: 12,
  display: 'flex',
  gap: 12,
});
