import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import {
  marciapiedePresenteOptions,
  tipologiaMarciapiedeOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';

const MarciapiediForm = ({
  marciapiediFields,
  selected,
  setSelected,
  marciapiediTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      marciapiediTableValue: [],
      tipologiaMarciapiedeTableValue: '',
      marciapiedePresente: '',
    },
  });

  useEffect(() => {
    setValue('marciapiediTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetMarciapiedi();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitMarciapiedi = () => {
    const tipologiaMarciapiedeTableValue = getValues(
      'tipologiaMarciapiedeTableValue'
    );
    const marciapiedePresenteTableValue = getValues(
      'marciapiedePresenteTableValue'
    );

    const ids = selected.map(el => el.codice);

    const marciapiedi = [...marciapiediFields];

    marciapiedi.map((x, index) => {
      if (ids.includes(x?.codice)) {
        marciapiedi[index] = {
          ...x,
          tipologiaMarciapiede: tipologiaMarciapiedeTableValue,
          marciapiedePresente: marciapiedePresenteTableValue,
        };
      }
    });

    setValue(marciapiediTable, marciapiedi);
    onResetMarciapiedi();
  };

  const onResetMarciapiedi = () => {
    setValue('marciapiediTableValue', []);
    setValue('tipologiaMarciapiedeTableValue', '');
    setValue('marciapiedePresenteTableValue', '');
    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper
        size={3}
        infoMessage={INFO.marciapiediSelezionati.infoMessage}
      >
        <RHFTagAutocomplete
          multiple={true}
          id="tags-marciapiedi"
          name={'marciapiediTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={marciapiediFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Marciapiedi selezionati"
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="tipologiaMarciapiedeTableValue"
          label="Tipologia Marciapiede*"
          defaultValue={''}
          onChange={e => {
            setValue('tipologiaMarciapiedeTableValue', e.target.value);
          }}
          selectOptions={tipologiaMarciapiedeOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFSelect
          name="marciapiedePresenteTableValue"
          label="Marciapiede presente"
          defaultValue={''}
          onChange={e => {
            setValue('marciapiedePresenteTableValue', e.target.value);
          }}
          selectOptions={marciapiedePresenteOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <FormSubmitButtons
          onSubmit={onSubmitMarciapiedi}
          onReset={onResetMarciapiedi}
        />
      </GridWrap>
    </GridWrap>
  );
};
MarciapiediForm.propTypes = {
  marciapiediFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaMarciapiede: PropTypes.string,
      marciapiedePresente: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  marciapiediTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default MarciapiediForm;
