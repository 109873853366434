import { Grow } from '@mui/material';
import { SnackbarProvider as NotiStackProvider } from 'notistack';

import PropTypes from 'prop-types';
import React from 'react';

const SnackbarProvider = ({ children }) => {
  const notistackRef = React.createRef();

  return (
    <>
      <NotiStackProvider
        ref={notistackRef}
        maxSnack={4}
        autoHideDuration={3000}
        TransitionComponent={Grow}
        variant="default"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {children}
      </NotiStackProvider>
    </>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node,
};
export { SnackbarProvider };
