import { Table as MuiTable, TableBody } from '@mui/material';

import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React /* , { useState } */ from 'react';
import { LoadingScreen } from 'components';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';

export const Table = ({
  loading,
  rowActions,
  config,
  data,
  selected,
  setSelected,
  hasCheckbox,
  onSelectAllClick,
  mediaActions,
  compatibilityActions,
  schedaDifettiActions,
  typevariant,
  preview,
  previewDocuments,
}) => {
  const isSelected = id => {
    const result = selected?.find(x => x.id === id);
    if (result) {
      return true;
    } else return false;
  };

  const handleCheckboxClick = row => {
    let newSelected = [...selected];
    const result = newSelected?.find(x => x.id === row.id);
    if (result) {
      newSelected = newSelected.filter(object => {
        return object.id !== row.id;
      });
    } else {
      newSelected.push(row);
    }

    setSelected(newSelected);
  };

  return (
    <div>
      {loading ? (
        <LoadingScreen />
      ) : (
        <MuiTable>
          <TableHeader
            columns={config.columns}
            hasActions={!!rowActions}
            numSelected={selected?.length}
            hasCheckbox={hasCheckbox}
            onSelectAllClick={onSelectAllClick}
            rowCount={data?.length}
            typevariant={typevariant}
            hasMedia={!!mediaActions}
            hasSchedaDifettiActions={!!schedaDifettiActions}
            hasCompatibility={!!compatibilityActions}
            preview={preview}
            previewDocuments={previewDocuments}
          />
          <TableBody>
            {data?.map((row, index) => {
              return (
                <TableRow
                  key={nanoid()}
                  row={row}
                  index={index}
                  config={config}
                  actions={rowActions}
                  mediaActions={mediaActions}
                  compatibilityActions={compatibilityActions}
                  schedaDifettiActions={schedaDifettiActions}
                  rowCount={data?.length}
                  isSelected={isSelected(row.id)}
                  hasCheckbox={hasCheckbox}
                  handleCheckboxClick={() => handleCheckboxClick(row)}
                  typevariant={typevariant}
                  preview={preview}
                  previewDocuments={previewDocuments}
                />
              );
            })}
          </TableBody>
        </MuiTable>
      )}
    </div>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  rowActions: PropTypes.array,
  mediaActions: PropTypes.array,
  compatibilityActions: PropTypes.array,
  schedaDifettiActions: PropTypes.array,
  config: PropTypes.object,
  data: PropTypes.array,
  hasCheckbox: PropTypes.bool,
  selected: PropTypes.array,
  onSelectAllClick: PropTypes.func,
  setSelected: PropTypes.func,
  typevariant: PropTypes.string,
  preview: PropTypes.bool,
  previewDocuments: PropTypes.bool,
};

Table.defaultProps = {
  loading: false,
  preview: false,
  previewDocuments: false,
};
