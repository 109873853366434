import styled from '@emotion/styled';
import { TabPanel } from '@mui/lab';
import { Accordion, Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { colors, L2CdAMap } from 'constants/colors';

export const AccordionContainer = styled(Accordion)`
  background-color: ${colors.WHITE} !important;
  margin-bottom: 10px;
  box-shadow: none;
  border-radius: 8px;
  border: 2px solid pink;
  padding: 0;
`;

export const CdATag = styled('div')(({ cda, cdaSectionData }) => ({
  backgroundColor: cda ? L2CdAMap[cdaSectionData]?.labelColor : colors.GREY,
  padding: 6,
  width: 140,
  marginRight: 10,
  textAlign: 'center',
  borderRadius: 12,
}));

export const TabPanelContainer = styled('div')({
  backgroundColor: '#FFFFFF',
});

export const BoxPanel = styled(Box)({
  width: '100%',
  backgroundColor: '#FFFFFF',
  padding: 25,
});

export const StyledTabs = styled(Tabs)(({ theme, typevariant }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: `${theme.palette.info.main}`,
    height: '2.5px',
  },
  borderBottom:
    typevariant === 'secondary'
      ? `3px solid ${theme.palette.info.main}`
      : `1px solid ${theme.palette.error.light}`,
}));
export const FirstTab = styled(Tab)(({ theme, color, borderColor }) => ({
  backgroundColor: color,
  borderBottom: `1px solid ${theme.palette.error.light}`,
  fontSize: '14px',
  padding: '0px 10px',
  borderRadius: '8px 8px 0 0',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  height: '61px',
  backgroundImage: `repeating-linear-gradient(-43deg, ${borderColor}, ${borderColor} 3.8px, transparent 4px, transparent 5.85px, ${borderColor} 7px)`,
  backgroundSize: '13px 100%,100% 0px,0 0,0 0',
  color: 'black',
  backgroundRepeat: 'no-repeat',
  '&.Mui-selected': {
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: color,
  },
}));

/* export const SecondTab = styled(Tab)(({ theme, color, borderColor }) => ({
  backgroundColor: color,
  borderBottom: `1px solid ${theme.palette.error.light}`,
  fontSize: '14px',
  padding: '0px 10px',
  borderRadius: '8px 8px 0 0',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  height: '61px',
  backgroundImage: `repeating-linear-gradient(-43deg, ${borderColor}, ${borderColor} 3.8px, transparent 4px, transparent 5.85px, ${borderColor} 7px)`,
  backgroundSize: '13px 100%,100% 0px,0 0,0 0',
  color: 'black !important',
  backgroundRepeat: 'no-repeat',
  margin: '6px 5px 0px 0px',
  '&.Mui-selected': {
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: 'white',
  },
})); */

export const SecondTab = styled(Tab)(({ borderColor }) => ({
  fontSize: '14px',
  alignItems: 'flex-start',
  fontWeight: 'bold',
  height: '61px',
  textTransform: 'capitalize',
  /* backgroundImage: `repeating-linear-gradient(-43deg, ${borderColor}, ${borderColor} 3.8px, transparent 4px, transparent 5.85px, ${borderColor} 7px)`, */
  backgroundImage: `repeating-linear-gradient( ${borderColor}, ${borderColor} 3.8px)`,
  backgroundSize: '12px 100%,100% 0px,0 0,0 0',
  color: 'black !important',
  backgroundRepeat: 'no-repeat',
  margin: '6px 5px 0px 0px',
  '&.Mui-selected': {
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: 'white',
  },
}));

export const SecondTabIdraulica = styled(Tab)({
  transform: 'translateY(1px)',
  fontSize: '14px',
  alignItems: 'center',
  fontWeight: 'bold',
  height: '61px',
  borderBottom: `1px solid ${colors.BLACK}`,
  color: 'black !important',
  /* margin: '6px 5px 0px 0px', */
  marginTop: '6px',
  marginBottom: '8px',
  '&.Mui-selected': {
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: 'white',
    borderBottom: `none`,
    borderLeft: `1px solid ${colors.BLACK}`,
    borderTop: `1px solid ${colors.BLACK}`,
    borderRight: `1px solid ${colors.BLACK}`,
  },
});

export const TabPanelStyle = styled(TabPanel)({
  padding: 0,
});

export const ContainerCard = styled('div')({
  display: 'flex',
  height: '100%',
  marginBottom: '35px',
  /* marginLeft: '16px', */
  marginTop: -5,
});

export const WrapperCardL2 = styled('div')(({ section }) => ({
  borderLeft: `13px solid ${L2CdAMap[section]?.labelColor}`,
  height: '100%',
  display: 'flex',
  flex: 1,
}));

export const ContainerMessage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% /3)',
  height: '100%',
  margin: '5px',
});
