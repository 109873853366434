import { nanoid } from 'nanoid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  impostaFondSpallaOptions,
  tipologiaSpallaOptions,
} from 'constants/selectOptions';
import { useInspectionSelector } from 'stores';

function useCopy() {
  const { getValues, setValue } = useFormContext();

  const { setSchedaDifettiTrigger } = useInspectionSelector();

  const [copyDrawerOpen, setCopyDrawerOpen] = useState(false);
  const [selectedPaste, setSelectedPaste] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const dataSpallePileConfig = {
    columns: {
      codiceSpalla: { label: 'Codice' },
      tipologiaSpalla: { label: 'Tipologia', format: tipologiaSpallaOptions },
      impostaFondSpalla: {
        label: 'Imposta Fond. Spalla',
        format: impostaFondSpallaOptions,
      },
    },
  };

  const handleSelectAllSpallePilePaste = event => {
    if (event.target.checked) {
      const selectedValue = getValues('spalleTable').filter(el =>
        checkCompatibility(el)
      );
      setSelectedPaste(selectedValue);
    } else {
      setSelectedPaste([]);
    }
  };

  const checkCompatibility = row => {
    return (
      row.codiceSpalla != selectedCopy.codiceSpalla &&
      row.tipologiaSpalla == selectedCopy.tipologiaSpalla &&
      row.impostaFondSpalla == selectedCopy.impostaFondSpalla
    );
  };

  const handleCopy = () => {
    getValues('spalleTable').forEach((x, spalleIdx) => {
      if (selectedPaste.some(p => p.codiceSpalla == x.codiceSpalla)) {
        const tmp = {
          /* ...x,
          tecnicoRilevatore: selectedCopy.tecnicoRilevatore,
          dataIspezione: selectedCopy.dataIspezione,
          elementoCriticoStruttura: selectedCopy.elementoCriticoStruttura,
          elementoCriticoSismica: selectedCopy.elementoCriticoSismica,
          condizioneCriticaStruttura: selectedCopy.condizioneCriticaStruttura,
          condizioneCriticaSismica: selectedCopy.condizioneCriticaSismica,
          noteGenerali: selectedCopy.noteGenerali, */
          ...x,
          Nrel321: selectedCopy.Nrel321 ?? undefined,
          g5totali: selectedCopy.g5totali ?? undefined,
          nrtotali: selectedCopy.nrtotali ?? undefined,
          pstotali: selectedCopy.pstotali ?? undefined,
          CDAsismica: selectedCopy.CDAsismica ?? undefined,
          summationG: selectedCopy.summationG ?? undefined,
          noteGenerali: selectedCopy.noteGenerali ?? undefined,
          dataIspezione: selectedCopy.dataIspezione ?? undefined,
          summationG123: selectedCopy.summationG123 ?? undefined,
          CDAstrutturale: selectedCopy.CDAstrutturale ?? undefined,
          summationk2G45: selectedCopy.summationk2G45 ?? undefined,
          summationk2G123: selectedCopy.summationk2G123 ?? undefined,
          indiceCondizione: selectedCopy.indiceCondizione ?? undefined,
          summationk2G12345: selectedCopy.summationk2G12345 ?? undefined,
          tecnicoRilevatore: selectedCopy.tecnicoRilevatore ?? undefined,
          CDAsismicacomputed: selectedCopy.CDAsismicacomputed ?? undefined,
          CDAstrutturalecomputed:
            selectedCopy.CDAstrutturalecomputed ?? undefined,
          elementoCriticoSismica:
            selectedCopy.elementoCriticoSismica ?? undefined,
          condizioneCriticaSismica:
            selectedCopy.condizioneCriticaSismica ?? undefined,
          elementoCriticoStruttura:
            selectedCopy.elementoCriticoStruttura ?? undefined,
          completamentoSchedaDifetti:
            selectedCopy.completamentoSchedaDifetti ?? undefined,
          condizioneCriticaStruttura:
            selectedCopy.condizioneCriticaStruttura ?? undefined,
          id: x.id,
          codiceSpalla: x.codiceSpalla,
          schedaDifetti: selectedCopy.schedaDifetti.map(el => ({
            ...el,
            media: [],
          })),
        };
        setValue(`spalleTable[${spalleIdx}]`, tmp);
      }
    });
    setCopyDrawerOpen(false);
    setSchedaDifettiTrigger(nanoid());
    return enqueueSnackbar('Elemento copiato correttamente', {
      variant: 'success',
    });
  };

  return {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedPaste,
    setSelectedPaste,
    selectedCopy,
    setSelectedCopy,
    dataSpallePileConfig,
    handleSelectAllSpallePilePaste,
    checkCompatibility,
    handleCopy,
  };
}
export default useCopy;
