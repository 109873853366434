import { Clear, Search } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  RHFDatePicker,
  RHFMultiSelect,
  RHFNumberTextField,
  RHFSelect,
  RHFTextField,
} from 'components';
import { tour_02 } from 'constants/tour';
import { FormProvider } from 'providers';
import { FilterContainer } from './Filter.style';

export const Filter = ({
  inputs,
  otherInputs,
  itemInLine,
  handleSearch,
  resetCards,
  marginTop,
}) => {
  const defaultValues = {
    name: '',
    council: '',
    province: '',
    state: '',
    prof: '',
    street: '',
    bypassedEntityType: '',
    inspectionStatus: '',
    bypassedEntityName: '',
    bridgeStatus: '',
    landslideRisk: '',
    hydraulicRisk: '',
    landslideRiskCda: '',
    hydraulicRiskCda: '',
    approximateConstructionYear: '',
    extendedOverallLight: '',
    bridgeSpansNumber: '',
    spansMaximumLight: '',
    structuralTypology: '',
    prevailingMaterial: '',
    structuralAndFoundationalCda: '',
    inspectionMinDate: null,
    inspectionMaxDate: null,
    nextInspectionMinDate: null,
    nextInspectionMaxDate: null,
    seismicCda: '',
    landslidesCda: '',
    overallCda: '',
    tags: '',
  };

  const methods = useForm({
    defaultValues,
  });

  const chooseInput = input => {
    if (input.filterType === 'TextField') {
      return (
        <RHFTextField size="small" label={input.label} name={input.name} />
      );
    }
    if (input.filterType === 'Number') {
      return (
        <RHFNumberTextField
          size="small"
          decimalScale={0}
          withValueLimit={({ value }) => value < 200}
          label={input.label}
          name={input.name}
        />
      );
    }

    if (input.filterType === 'Select') {
      return (
        <RHFSelect
          size="small"
          name={input.name}
          selectOptions={input.options}
          label={input.label}
        />
      );
    }

    if (input.filterType === 'Multiselect') {
      return (
        <RHFMultiSelect
          size="small"
          name={input.name}
          selectOptions={input.options}
          label={input.label}
        />
      );
    }
    if (input.filterType === 'DatePicker') {
      return (
        <RHFDatePicker
          defaultValue=""
          size="small"
          name={input.name}
          label={input.label}
        />
      );
    }
  };

  const [allInputs, setAllInputs] = useState(false);

  const renderFirstLine = () => {
    return inputs.slice(0, itemInLine).map((el, index) => {
      return (
        <Grid
          item
          xs={2.4}
          /* md={allInputs ? 3 : numberInline[itemInLine].onlyFirstLine} */
          key={index}
        >
          {chooseInput(el)}
        </Grid>
      );
    });
  };

  const renderOtherInputs = () => {
    return otherInputs.map((el, index) => {
      return (
        <Grid item xs={12} md={2.4} key={index}>
          {chooseInput(el)}
        </Grid>
      );
    });
  };

  const renderButton = () => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{ marginRight: '5px' }}
          onClick={
            allInputs
              ? () => {
                  setAllInputs(false);
                }
              : () => {
                  setAllInputs(true);
                }
          }
        >
          {allInputs && 'Chiudi Filtri Avanzati'}
          {!allInputs && 'Filtri Avanzati'}
        </Button>
        <IconButton color="primary" type="submit">
          <Search />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => {
            methods.reset(defaultValues);
            resetCards();
          }}
        >
          <Clear />
        </IconButton>
      </>
    );
  };

  return (
    <FilterContainer marginTop={marginTop} className={tour_02.step_01}>
      <FormProvider
        methods={methods}
        onSubmit={methods.handleSubmit(handleSearch)}
      >
        <Grid container spacing={1}>
          {/* FirstLine */}
          {renderFirstLine()}
          {/* OtherInputs */}
          {allInputs && renderOtherInputs()}
          {/* Button */}
          <Grid item xs={12} md={allInputs ? 12 : 9.6}>
            {renderButton()}
          </Grid>
        </Grid>
      </FormProvider>
    </FilterContainer>
  );
};

Filter.propTypes = {
  inputs: PropTypes.array,
  otherInputs: PropTypes.array,
  itemInLine: PropTypes.number,
  handleSearch: PropTypes.func,
  resetCards: PropTypes.func,
  marginTop: PropTypes.string,
};
