import {
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useFetch } from 'use-http';
import { DrawerMUI, InfoBox } from 'components';
import { apiDeliveries, apiInspections } from 'constants/api';
import { colors } from 'constants/colors';
import {
  BridgeOwnerAndSharesMessageMap,
  CheckBridgeOwnerAndShares,
  DeliveriesResponseStatus,
  DeliveryStatus,
} from 'constants/deliveries';
import { STRUCTURES_PATHS } from 'constants/paths';
import { UserType, UserTypeMap } from 'constants/users';
import { useInspectionPath } from 'hooks';
import { useGlobalSelector } from 'stores';
import SendArchiveInspectionForm from './SendArchiveInspectionForm';

const WhiteContainer = styled('div')({
  width: '100%',
  borderRadius: 8,
  backgroundColor: colors.WHITE,
  padding: 24,
});

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: 10,
  paddingTop: 10,
});
const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const UserAvatar = ({ label, name, surname, email }) => {
  return (
    <>
      <Avatar
        variant="circular"
        style={{
          border: `1px solid`,
        }}
      >
        {label}
      </Avatar>
      <Stack>
        <Typography variant="h3Bold" sx={{ marginLeft: 2 }}>
          {`${name} ${surname}`}
        </Typography>
        <Tooltip title={email}>
          <Typography variant="h3" sx={{ marginLeft: 2 }}>
            {email}
          </Typography>
        </Tooltip>
      </Stack>
    </>
  );
};

UserAvatar.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
};

const SendArchiveStatusMessageMap = {
  [CheckBridgeOwnerAndShares.ISPETTORE_PROPRIETARIO_NON_CONDIVISO_CON_ENTE]: (
    <Typography my={2}>
      Archiviando, l’attività non sarà più modificabile. Consegna il tuo lavoro
      all’Ente gestore proprietario dell’opera, così che possa visualizzare i
      risultati direttamente in piattaforma.
    </Typography>
  ),
  [CheckBridgeOwnerAndShares.ENTE_PROPRIETARIO]: (
    <Typography my={2}>
      Archiviando, l’attività non sarà più modificabile. Consegna il tuo lavoro
      all’Ente gestore proprietario dell’opera, così che possa visualizzare i
      risultati direttamente in piattaforma.
    </Typography>
  ),
  [CheckBridgeOwnerAndShares.ENTE_NON_PROPRIETARIO_CONDIVISA_CON_ENTI]: (
    <Typography my={2}>
      Archiviando, l’attività non sarà più modificabile. Consegna il tuo lavoro
      all’Ente gestore proprietario dell’opera, così che possa visualizzare i
      risultati direttamente in piattaforma.
    </Typography>
  ),
};

const DrawerSendArchiveInspection = ({
  open,
  setOpen,
  inspectionId,
  structureId,
  onSubmitCallback,
}) => {
  const { isEditingInspection } = useInspectionPath();
  const navigate = useNavigate();
  const { get, post, del } = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const { showLoader, hideLoader } = useGlobalSelector();

  // check first the type of user, if is PROFESSIONISTA or ENTE

  const [currentDeliveryStatus, setCurrentDeliveryStatus] = useState();
  const [currentOwnerState, setCurrentOwnerState] = useState();
  const [bridgeOwner, setBridgeOwner] = useState();
  const [bridgeName, setBridgeName] = useState('');
  const [sharedWithEnti, setSharedWithEnti] = useState([]);
  const [enteToSend, setEnteToSend] = useState();
  const [message, setMessage] = useState('');
  const [recipient, setRecipient] = useState();
  const [isDirtyForm, setIsDirtyForm] = useState(false);

  const reset = () => {
    setCurrentDeliveryStatus();
    setCurrentOwnerState();
    setBridgeOwner();
    setSharedWithEnti([]);
    setEnteToSend();
    setRecipient();
    setIsDirtyForm(false);
  };

  useEffect(() => {
    const getStructureDetail = async () => {
      reset();
      try {
        showLoader();
        const statusResponse = await get(
          `${apiDeliveries.GET_STATUS}?inspectionId=${inspectionId}`
        );
        const ownerResponse = await get(
          `${apiDeliveries.CHECK_BRIDGE_OWNER_AND_SHARE}?structureId=${structureId}`
        );
        setCurrentDeliveryStatus(statusResponse?.status);
        setBridgeName(statusResponse?.bridgeName);
        if (statusResponse?.status === DeliveryStatus.IN_CONSEGNA) {
          setRecipient(statusResponse?.recipient);
        }
        if (ownerResponse?.status === DeliveriesResponseStatus.OK) {
          if (
            ownerResponse?.successMessage?.message ===
            CheckBridgeOwnerAndShares.ENTE_PROPRIETARIO
          ) {
            setBridgeOwner(ownerResponse?.response[0]);
            setEnteToSend(ownerResponse?.response[0]?.email);
          }
          if (
            ownerResponse?.successMessage?.message ===
            CheckBridgeOwnerAndShares.ENTE_NON_PROPRIETARIO_CONDIVISA_CON_ENTI
          ) {
            setSharedWithEnti(ownerResponse?.response);
          }
          setCurrentOwnerState(ownerResponse?.successMessage?.message);
        }
      } catch (err) {
        return enqueueSnackbar(
          `Si è verificato un errore nella lettura dell'ispezione`,
          {
            variant: 'error',
          }
        );
      } finally {
        hideLoader();
      }
    };
    inspectionId && structureId && getStructureDetail();
  }, [structureId, inspectionId]);

  const handleArchive = async () => {
    try {
      showLoader();
      await post(
        `${apiInspections.ROOT}/${inspectionId}${apiInspections.ARCHIVE}`
      );
      setOpen(false);
      if (onSubmitCallback) {
        onSubmitCallback();
      }
      enqueueSnackbar(`Ispezione archiviata con successo`, {
        variant: 'success',
      });
      if (isEditingInspection) {
        navigate(
          STRUCTURES_PATHS.INSPECTIONS_ARCHIVE.replace(
            ':structureId',
            structureId
          )
        );
      }
    } catch (err) {
      return enqueueSnackbar(
        `Si è verificato un errore nell'archiviazione dell'ispezione`,
        {
          variant: 'error',
        }
      );
    } finally {
      hideLoader();
    }
  };

  const handleSendAndArchive = async () => {
    try {
      showLoader();
      const res = await post(apiDeliveries.ROOT, {
        inspectionId: parseInt(inspectionId),
        email: enteToSend,
      });
      setOpen(false);
      if (onSubmitCallback) {
        onSubmitCallback();
      }
      return enqueueSnackbar(`Ispezione consegnata con successo`, {
        variant: 'success',
      });
    } catch (err) {
      return enqueueSnackbar(
        `Si è verificato un errore nella lettura dell'ispezione`,
        {
          variant: 'error',
        }
      );
    } finally {
      hideLoader();
    }
  };

  const handleChange = event => {
    setEnteToSend(event.target.value);
  };

  const handleCancelInvitation = async () => {
    try {
      showLoader();
      await del(`${apiDeliveries.CANCEL}?inspectionId=${inspectionId}`);
      setOpen(false);
    } catch (err) {
      return enqueueSnackbar(
        `Si è verificato un errore nell'annullamento dell'invio`,
        {
          variant: 'error',
        }
      );
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (!open) {
      //reset();
    }
  }, [open]);

  return (
    <DrawerMUI
      open={open}
      setOpen={setOpen}
      title={`Consegna e archivia ${bridgeName}`}
    >
      {currentDeliveryStatus && (
        <>
          {SendArchiveStatusMessageMap[currentOwnerState]}
          {process.env.REACT_APP_ENVIRONMENT === 'develop' && (
            <>{BridgeOwnerAndSharesMessageMap[currentOwnerState]}</>
          )}
          {currentDeliveryStatus === DeliveryStatus.IN_CONSEGNA && (
            <>
              <WhiteContainer>
                <Container>
                  <UserAvatar
                    label={`${recipient?.name?.charAt(
                      0
                    )}${recipient?.surname?.charAt(0)}`}
                    name={recipient?.name}
                    surname={recipient?.surname}
                    email={recipient?.email}
                  />
                </Container>
              </WhiteContainer>
              <Grid mt={2} container justifyContent={'end'}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleCancelInvitation}
                  >
                    Annulla Invio
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          {currentDeliveryStatus === DeliveryStatus.NON_CONSEGNATO && (
            <>
              {currentOwnerState ===
                CheckBridgeOwnerAndShares.ISPETTORE_PROPRIETARIO_NON_CONDIVISO_CON_ENTE && (
                <>
                  <WhiteContainer>
                    <SendArchiveInspectionForm
                      inspectionId={inspectionId}
                      structureId={structureId}
                      setOpen={setOpen}
                    />
                  </WhiteContainer>
                  {currentDeliveryStatus !== DeliveryStatus.ARCHIVIATA && (
                    <Grid mt={2} container justifyContent={'end'}>
                      <Grid item xs={12}>
                        <InfoBox
                          color="#fff"
                          text={
                            'Per archiviare senza consegnare l’attività fai click su Archivia. Potrai procedere alla consegna successivamente dall’archivio attività.'
                          }
                        />
                      </Grid>

                      <Grid item mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={handleArchive}
                        >
                          Archivia
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {currentOwnerState ===
                CheckBridgeOwnerAndShares.ENTE_PROPRIETARIO && (
                <>
                  <WhiteContainer>
                    <Typography variant="h3Bold">Consegna opera</Typography>
                    <Container>
                      <UserAvatar
                        label={`${bridgeOwner?.name?.charAt(
                          0
                        )}${bridgeOwner?.name?.charAt(0)}`}
                        name={bridgeOwner?.name}
                        surname={bridgeOwner?.name}
                        email={bridgeOwner?.email}
                      />
                    </Container>
                    <Grid item xs={12} mt={2}>
                      <TextField
                        value={message}
                        multiline
                        rows={4}
                        label="Messaggio"
                        onChange={e => setMessage(e.target.value)}
                      />
                    </Grid>
                  </WhiteContainer>
                  <Grid item xs={12} mt={2} textAlign={'end'}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleSendAndArchive}
                    >
                      Consegna ed Archivia
                    </Button>
                  </Grid>
                </>
              )}
              {currentOwnerState ===
                CheckBridgeOwnerAndShares.ENTE_NON_PROPRIETARIO_CONDIVISA_CON_ENTI && (
                <>
                  <WhiteContainer>
                    <Wrapper>
                      <SendArchiveInspectionForm
                        isDirtyForm={isDirtyForm}
                        setIsDirtyForm={setIsDirtyForm}
                        inspectionId={inspectionId}
                        structureId={structureId}
                        setOpen={setOpen}
                      />
                      {!isDirtyForm && (
                        <>
                          <Typography>
                            o seleziona un Ente con cui hai già condiviso
                            l&apos;opera
                          </Typography>
                          <FormControl>
                            <RadioGroup
                              value={enteToSend}
                              onChange={handleChange}
                            >
                              {sharedWithEnti
                                ?.filter(
                                  el => el.type === UserTypeMap[UserType.Ente]
                                )
                                .map(el => {
                                  const label = `${el.name?.charAt(
                                    0
                                  )}${el.surname?.charAt(0)}`;
                                  return (
                                    <Container key={el.id}>
                                      <FormControlLabel
                                        value={el?.email}
                                        control={<Radio />}
                                      />
                                      <UserAvatar
                                        label={label}
                                        name={el?.name}
                                        surname={el?.surname}
                                        email={el?.email}
                                      />
                                    </Container>
                                  );
                                })}
                            </RadioGroup>
                          </FormControl>
                        </>
                      )}
                    </Wrapper>
                  </WhiteContainer>
                  {!isDirtyForm && (
                    <Grid container justifyContent={'end'} mt={2}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          disabled={!enteToSend}
                          onClick={handleSendAndArchive}
                        >
                          Consegna e Archivia
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {currentDeliveryStatus === DeliveryStatus.ARCHIVIATA && (
        <WhiteContainer>
          <SendArchiveInspectionForm
            inspectionId={inspectionId}
            structureId={structureId}
            setOpen={setOpen}
            onSubmitCallback={onSubmitCallback}
          />
        </WhiteContainer>
      )}
    </DrawerMUI>
  );
};

DrawerSendArchiveInspection.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  inspectionId: PropTypes.number,
  structureId: PropTypes.number,
  onSubmitCallback: PropTypes.func,
};

export { DrawerSendArchiveInspection };
