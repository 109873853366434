import { Typography } from '@mui/material';
import { tour_17a } from 'constants/tour';

/* import { Check } from '@mui/icons-material';*/
/* Gestisci l'ispezione:
(icona) - Per bloccare l'uso ad altri utenti
(icona) - Per modificarla
(icona) - Per archiviarla
(icona) - Per visualizzarla
(icona) - Per eliminarla */
export const steps = [
  {
    selector: `.${tour_17a.step_01}`,
    content: () => {
      return (
        <Typography>
          Aggiungi come collaboratori interni coloro i quali fanno parte della
          tua realtà aziendale (saranno sempre legati e dipendenti
          dall&apos;account principale), o altrimenti aggiungili come
          collaboratori esterni.
        </Typography>
      );
    },
  },
];
