import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { GeometriaSezioneTiranteTableValue } from 'constants/inspections';
import {
  geometriaSezioneTiranteOptions,
  tipologiaTiranteOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
const TirantiForm = ({
  tirantiFields,
  selected,
  setSelected,
  tirantiTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t2a5_tirantiTableValue: [],
      l0t2a5_tipologiaTiranteTableValue: '',
      l0t2a5_geometriaSezioneTiranteTableValue: '',
      l0t2a5_lunghezzaTiranteTableValue: '',
      l0t2a5_diametroTiranteTableValue: '',
      l0t2a5_spessoreTiranteTableValue: '',
      l0t2a5_profonditaTiranteTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a5_tirantiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetTiranti();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeTiranti = () => {
    const l0t2a5_tipologiaTiranteTableValue = getValues(
      'l0t2a5_tipologiaTiranteTableValue'
    );

    const l0t2a5_geometriaSezioneTiranteTableValue = getValues(
      'l0t2a5_geometriaSezioneTiranteTableValue'
    );

    const l0t2a5_lunghezzaTiranteTableValue = getValues(
      'l0t2a5_lunghezzaTiranteTableValue'
    );
    const l0t2a5_diametroTiranteTableValue = getValues(
      'l0t2a5_diametroTiranteTableValue'
    );

    const l0t2a5_spessoreTiranteTableValue = getValues(
      'l0t2a5_spessoreTiranteTableValue'
    );
    const l0t2a5_profonditaTiranteTableValue = getValues(
      'l0t2a5_profonditaTiranteTableValue'
    );

    const ids = selected.map(el => el.codice);

    const tiranti = [...tirantiFields];

    tiranti.map((x, index) => {
      if (ids.includes(x?.codice)) {
        tiranti[index] = {
          ...x,
          tipologiaTirante: l0t2a5_tipologiaTiranteTableValue,
          geometriaSezioneTirante: l0t2a5_geometriaSezioneTiranteTableValue,
          lunghezzaTirante: l0t2a5_lunghezzaTiranteTableValue,
          diametroTirante: l0t2a5_diametroTiranteTableValue,
          spessoreTirante: l0t2a5_spessoreTiranteTableValue,
          profonditaTirante: l0t2a5_profonditaTiranteTableValue,
          ...(l0t2a5_tipologiaTiranteTableValue !== x.tipologiaTirante
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'tirante',
                  l0t2a5_tipologiaTiranteTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(tirantiTable, tiranti);
    onResetTiranti();
  };

  const onConfirmChangeTiranti = () => {
    setOpenConfirmationDialog(false);
    changeTiranti();
  };

  const onSubmitTiranti = () => {
    const l0t2a5_tipologiaTiranteTableValue = getValues(
      'l0t2a5_tipologiaTiranteTableValue'
    );

    const ids = selected.map(el => el.codice);

    const tiranti = [...tirantiFields];

    const hasChangedTipologia = tiranti.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a5_tipologiaTiranteTableValue !== el.tipologiaTirante &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaTirante)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeTiranti();
    }
  };

  const onResetTiranti = () => {
    setValue('l0t2a5_tirantiTableValue', []);
    setValue('l0t2a5_tipologiaTiranteTableValue', '');
    setValue('l0t2a5_geometriaSezioneTiranteTableValue', '');
    setValue('l0t2a5_lunghezzaTiranteTableValue', '');
    setValue('l0t2a5_diametroTiranteTableValue', '');
    setValue('l0t2a5_spessoreTiranteTableValue', '');
    setValue('l0t2a5_profonditaTiranteTableValue', '');

    setSelected([]);
  };

  const isCircolare =
    watch('l0t2a5_geometriaSezioneTiranteTableValue') ===
    GeometriaSezioneTiranteTableValue.Circolare;

  useEffect(() => {
    setValue('l0t2a5_diametroTiranteTableValue', '');
    setValue('l0t2a5_spessoreTiranteTableValue', '');
    setValue('l0t2a5_profonditaTiranteTableValue', '');
  }, [isCircolare]);

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={6} infoMessage={INFO.tirantiSelezionati.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-tiranti"
            name={'l0t2a5_tirantiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={tirantiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Tiranti selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_tipologiaTiranteTableValue"
            label="Tipologia Tirante*"
            defaultValue={''}
            onChange={e => {
              setValue('l0t2a5_tipologiaTiranteTableValue', e.target.value);
            }}
            selectOptions={tipologiaTiranteOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_geometriaSezioneTiranteTableValue"
            label="Geometria sezione Tirante"
            defaultValue={''}
            onChange={e => {
              setValue(
                'l0t2a5_geometriaSezioneTiranteTableValue',
                e.target.value
              );
            }}
            selectOptions={geometriaSezioneTiranteOptions}
          />
        </GridWrap>
        <InfoWrapper size={3} infoMessage={INFO.lunghezzaTirante.infoMessage}>
          <RHFNumberTextField
            name="l0t2a5_lunghezzaTiranteTableValue"
            label={'Lunghezza (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_diametroTiranteTableValue"
            label={'Diametro (D) [cm]'}
            defaultValue=""
            disabled={!isCircolare}
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_spessoreTiranteTableValue"
            label={'Spessore (s) [cm]'}
            defaultValue=""
            disabled={isCircolare}
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={2}>
          <RHFNumberTextField
            name="l0t2a5_profonditaTiranteTableValue"
            label={'Profondità (P) [cm]'}
            defaultValue=""
            disabled={isCircolare}
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>

        <GridWrap item xs={1}>
          <FormSubmitButtons
            onSubmit={onSubmitTiranti}
            onReset={onResetTiranti}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeTiranti}
      />
    </>
  );
};
TirantiForm.propTypes = {
  tirantiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaTirante: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      geometriaSezioneTirante: PropTypes.number,
      lunghezzaTirante: PropTypes.number,
      diametroTirante: PropTypes.number,
      spessoreTirante: PropTypes.number,
      profonditaTirante: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  tirantiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default TirantiForm;
