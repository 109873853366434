import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { DrawerMUI, GridWrap, Table } from 'components';
const DrawerCopy = ({
  title,
  subtitle,
  open,
  setOpen,
  tableConfig,
  copyTableData,
  pasteTableData,
  selectedCopy,
  selectedPaste,
  handleSelectAll,
  setSelectedPaste,
  checkCompatibility,
  handleCopy,
}) => {
  return (
    <DrawerMUI title={title} subtitle={subtitle} open={open} setOpen={setOpen}>
      <GridWrap container>
        <GridWrap item xs={12}>
          <Table
            data={copyTableData}
            config={tableConfig}
            selected={[selectedCopy]}
            hasCheckbox={false}
            compatibilityActions={[
              {
                compatibilityHeader: true,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <Table
            data={pasteTableData}
            config={tableConfig}
            hasCheckbox={true}
            selected={selectedPaste}
            onSelectAllClick={handleSelectAll}
            setSelected={setSelectedPaste}
            compatibilityActions={[
              {
                checkCompatibility: row => checkCompatibility(row),
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={12} textAlign="right">
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginRight: 5 }}
            onClick={() => setOpen(false)}
          >
            Annulla
          </Button>
          <Button
            variant={'containedBlack'}
            onClick={handleCopy}
            disabled={selectedPaste.length == 0}
          >
            Copia
          </Button>
        </GridWrap>
      </GridWrap>
    </DrawerMUI>
  );
};
DrawerCopy.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  tableConfig: PropTypes.object,
  copyTableData: PropTypes.array,
  pasteTableData: PropTypes.array,
  selectedCopy: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  selectedPaste: PropTypes.array,
  handleSelectAll: PropTypes.func,
  setSelectedPaste: PropTypes.func,
  checkCompatibility: PropTypes.func,
  handleCopy: PropTypes.func,
};
export { DrawerCopy };
