import { Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import Check from 'assets/icons/check-white.svg';
import Cancel from 'assets/icons/close-black.svg';
import {
  RHFMultiSelect,
  RHFNumberTextField,
  RHFSelect,
  RHFTextField,
} from 'components';
import { paliIlluminazione, tipologiaPali } from 'constants/inspections';

import {
  paliIlluminazioneOptions,
  tipologiaPaliOptions,
} from 'constants/selectOptions';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import { Buttons } from './PaliServiziForm.styles';

const PaliServiziForm = ({ paliFields }) => {
  const { getValues, setValue, watch } = useFormContext();

  const watchPaliIlluminati = watch('paliIlluminazioneTableValue');
  const watchPaliPresenti = watchPaliIlluminati === paliIlluminazione.Presenti;

  const methods = useFormContext({
    defaultValues: {
      paliIlluminazione: '',
      tipologiaPali: '',
      tipologiaPaliAltro: '',
      nPaliTableValue: '',
      altezzaPaliTableValue: '',
      tipologiaAltro: '',
    },
  });

  const { populateScheda20Difetti } = useSchedaDifetti();

  const watchTipologiaPaliTableMultiValue = watch(
    'tipologiaPaliTableMultiValue'
  );

  const watchTipologiaAltro =
    watchTipologiaPaliTableMultiValue !== ''
      ? watchTipologiaPaliTableMultiValue?.some(
          el => el === tipologiaPali.Altro
        )
      : false;

  const onSubmitPali = () => {
    const paliIlluminazioneTableValue = getValues(
      'paliIlluminazioneTableValue'
    );

    if (isNullOrUndefinedOrEmptyString(paliIlluminazioneTableValue)) {
      return setValue('paliTable', []);
    }

    const tipologiaPaliTableMultiValue = getValues(
      'tipologiaPaliTableMultiValue'
    );
    const tipologiaAltroPaliTableValue = getValues(
      'tipologiaAltroPaliTableValue'
    );
    const nPaliTableValue = getValues('nPaliTableValue');
    const altezzaPaliTableValue = getValues('altezzaPaliTableValue');
    const schedaDifettiPaloIlluminazione = populateScheda20Difetti(
      'paloIlluminazione',
      paliIlluminazioneTableValue
    ).map(el => {
      return {
        ...el,
        visto:
          paliIlluminazioneTableValue === paliIlluminazione.Assenti
            ? true
            : false,
        presente:
          paliIlluminazioneTableValue === paliIlluminazione.Assenti
            ? true
            : false,
        statoDifetto: null,
        note: '',
        media: [],
      };
    });

    if (paliFields.length > 0) {
      const pali = [...paliFields];
      pali.map((x, index) => {
        pali[index] = {
          ...x,
          id: uuidv4(),
          paliIlluminazione: paliIlluminazioneTableValue,
          tipologiaPali: tipologiaPaliTableMultiValue,
          tipologiaPaliAltro: tipologiaAltroPaliTableValue,
          nPaliTableValue: nPaliTableValue,
          altezzaPaliTableValue: altezzaPaliTableValue,
          assente:
            paliIlluminazioneTableValue === paliIlluminazione.Assenti
              ? true
              : undefined,
          presenza: paliIlluminazioneTableValue,
          ...(paliIlluminazioneTableValue !== x.paliIlluminazione
            ? {
                schedaDifetti: schedaDifettiPaloIlluminazione,
              }
            : null),
        };
      });
      setValue('paliTable', pali);
    } else {
      setValue('paliTable', [
        {
          id: uuidv4(),
          paliIlluminazione: paliIlluminazioneTableValue,
          tipologiaPali: tipologiaPaliTableMultiValue,
          tipologiaPaliAltro: tipologiaAltroPaliTableValue,
          nPaliTableValue: nPaliTableValue,
          altezzaPaliTableValue: altezzaPaliTableValue,
          assente:
            paliIlluminazioneTableValue === paliIlluminazione.Assenti
              ? true
              : undefined,
          presenza: paliIlluminazioneTableValue,
          schedaDifetti: schedaDifettiPaloIlluminazione,
        },
      ]);
    }
    onResetPali();
  };

  const onResetPali = () => {
    setValue('paliIlluminazioneTableValue', '');
    setValue('tipologiaPaliTableMultiValue', '');
    setValue('tipologiaAltroPaliTableValue', '');
    setValue('nPaliTableValue', '');
    setValue('altezzaPaliTableValue', '');
  };

  useEffect(() => {
    if (!watchPaliPresenti) {
      setValue('tipologiaPaliTableMultiValue', '');
      setValue('tipologiaAltroPaliTableValue', '');
      setValue('nPaliTableValue', '');
      setValue('altezzaPaliTableValue', '');
    }
  }, [watchPaliPresenti]);

  return (
    <Grid container spacing={2} padding="20px">
      <Grid item xs={3}>
        <RHFSelect
          name="paliIlluminazioneTableValue"
          label="Pali illuminazione*"
          defaultValue={''}
          onChange={e => {
            methods.setValue('paliIlluminazioneTableValue', e.target.value);
          }}
          selectOptions={paliIlluminazioneOptions}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFMultiSelect
          name="tipologiaPaliTableMultiValue"
          label="Tipologia pali"
          defaultValue={[]}
          disabled={!watchPaliPresenti}
          onChange={e => {
            methods.setValue('tipologiaPaliTableMultiValue', e.target.value);
          }}
          selectOptions={tipologiaPaliOptions}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFTextField
          name="tipologiaAltroPaliTableValue"
          label={'Specificare altro'}
          inputProps={{ maxLength: 100 }}
          defaultValue=""
          disabled={!watchTipologiaAltro}
          onChange={e => {
            setValue('tipologiaAltroPaliTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="nPaliTableValue"
          label={'Numero'}
          defaultValue=""
          decimalScale={0}
          disabled={!watchPaliPresenti}
          withValueLimit={({ value }) => value <= 999}
          onChange={e => {
            methods.setValue('nPaliTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="altezzaPaliTableValue"
          label={'Altezza [cm]'}
          defaultValue=""
          decimalScale={2}
          disabled={!watchPaliPresenti}
          withValueLimit={({ value }) => value <= 100000000}
          onChange={e => {
            methods.setValue('altezzaPaliTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={9} alignSelf="center">
        <Buttons>
          <IconButton color="primary" onClick={onSubmitPali}>
            <img src={Check} alt="" />
          </IconButton>
          <IconButton color="primary" onClick={onResetPali}>
            <img src={Cancel} alt="" />
          </IconButton>
        </Buttons>
      </Grid>
    </Grid>
  );
};
export { PaliServiziForm };

PaliServiziForm.propTypes = {
  paliFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      paliIlluminazioneTableValue: PropTypes.string,
      tipologiaPali: PropTypes.string,
      tipologiaPaliAltro: PropTypes.string,
      nPaliTableValue: PropTypes.number,
      altezzaPaliTableValue: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  onChange: PropTypes.func,
};
