import { FormControl, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import { CustomFormControlLabel } from './Checkbox.styles';
import { Checkbox } from '../../';

const RHFCheckbox = ({
  name,
  disabled,
  indeterminate,
  rules,
  defaultValue,
  isTreeView,
  isTreeViewParent,
  ...other
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <FormControl error={errors[name]} disabled={disabled}>
      <CustomFormControlLabel
        isTreeView={isTreeView}
        control={
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => {
              return (
                <Checkbox
                  {...field}
                  checked={!!field.value}
                  disabled={disabled && disabled}
                  indeterminate={indeterminate}
                  isTreeViewParent={isTreeViewParent}
                />
              );
            }}
          />
        }
        {...other}
      />
      {errors[name] && <FormHelperText>{errors[name].message}</FormHelperText>}
    </FormControl>
  );
};

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  isTreeView: PropTypes.bool,
  isTreeViewParent: PropTypes.bool,
};
export { RHFCheckbox };
