import { Button, Divider, Grid } from '@mui/material';
import { format, isAfter, isEqual, parse } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line
import useFetch from 'use-http';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import {
  FormSubmitButtons,
  HeaderPage,
  InfoBox,
  LoadingScreenWithPercentage,
  RHFDatePicker,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
  Table,
  TourWrapper,
} from 'components';
import { apiBridges, apiCollaborators } from 'constants/api';
import { COLLABORATORS_PATHS } from 'constants/paths';
import {
  CollaborationResponseStatus,
  CollaborationsErrorsMessageMap,
  PermissionType,
  PermissionTypeSelectOptions,
} from 'constants/permissions';
import { tour_17 } from 'constants/tour';
import { UserStatus, UserStatusOptions, UserType } from 'constants/users';
import { FormProvider } from 'providers';
import Router from 'routes';
import { useAuthSelector, useGlobalSelector } from 'stores';
import BridgesFilter from './BridgesFilter';
import { steps } from '../CreateCollbaorator.tourSteps';

const HeaderPageTitleMap = {
  internal: 'Collaboratore interno',
  external: 'Collaboratore esterno',
};

const dataConfigBridges = {
  columns: {
    internalCode: { label: 'Codice interno - ID' },
    name: { label: 'Nome Opera' },
    tags: { label: 'Tag' },
    accessExpiration: { label: 'Scad. Accesso' },
    permission: { label: 'Permessi', format: PermissionTypeSelectOptions },
  },
};

const CHUNK_SIZE = 20;

const formatDate = date => {
  if (!date) return;
  const splitDate = date.split('-');
  const formatDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  return new Date(formatDate);
};

const mapBridges = data => {
  // console.log('res data ', data);
  return data.map(el => {
    return {
      internalCode: el?.internalCode,
      name: el?.name,
      editable: el?.editable,
      id: el?.id,
      accessExpiration: el?.collaboratorAccesExpirationDate?.replaceAll(
        '/',
        '-'
      ),
      inviterAccessExpirationDate: el.inviterAccessExpirationDate,
      permission: el?.collaboratorPermissions,
      tags: el?.tags,
      inviterPermissions:
        el?.inviterPermissions || el?.myPermission?.permission,
    };
  });
};

const CreateCollaborator = ({ type }) => {
  const payloadSchema = {
    collaboratorId: '',
    name: '',
    surname: '',
    message: '',
    sharedStructures: [],
  };

  const DetailEndpointMap = {
    internal: apiCollaborators.ROOT,
    external: apiCollaborators.NETWORK_EXTERNAL,
  };

  const params = useParams();
  const { get, put, post, response, loading } = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const { user: loggedUser } = useAuthSelector();
  const { showLoader, hideLoader } = useGlobalSelector();

  const navigate = useNavigate();

  const userId = params.userId;

  const defaultValues = {
    status: '',
    email: '',
    name: '',
    message: '',
    surname: '',
  };
  const methods = useForm({ defaultValues });
  const { handleSubmit, setValue, getValues, watch } = methods;

  const [currentEditingUser, setCurrentEditingUser] = useState({});
  const [originalBridges, setOriginalBridges] = useState([]);
  const [bridges, setBridges] = useState([]);
  const [selectedBridges, setSelectedBridges] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState(
    PermissionTypeSelectOptions
  );
  const [permissionChangeMessage, setPermissionChangeMessage] = useState();
  const [canSubmit, setCanSubmit] = useState(true);
  const [searchParams, setSearchParams] = useState('');

  const [operationPercentage, setOperationPercentage] = useState(0);
  const [loadingOperation, setLoadingOperation] = useState(false);

  const handleCollaboratorInfo = async () => {
    const handleUserDetail = async () => {
      const res = await get(`${DetailEndpointMap[type]}/${userId}`);
      if (res) {
        setCurrentEditingUser(res);
        setValue('status', res.status);
        setValue('email', res.email);
        setValue('name', res.name);
        setValue('surname', res.surname);
      }
    };

    const handleUserBridges = async () => {
      const res = await get(
        `${apiCollaborators.ROOT}/${userId}${
          apiCollaborators.STRUCTURE_SHARE_INFORMATION
        }?${
          searchParams ? `${searchParams}&&showDemo=false` : 'showDemo=false'
        }`
      );
      if (res) {
        const remapped = mapBridges(res);

        setBridges(remapped);
        setOriginalBridges(remapped);
        // console.log('remapped ', remapped);
        setValue('rhfBridgesList', remapped);
      }
    };
    const promises = [handleUserDetail(), handleUserBridges()];
    await Promise.all(promises);
  };

  useEffect(() => {
    userId && handleCollaboratorInfo();
  }, [userId]);

  useEffect(() => {
    const handleBridges = async () => {
      // remove demo
      const res = await get(`${apiBridges.ROOT}?showDemo=false`);
      if (res) {
        const remapped = mapBridges(res?.items);
        setBridges(remapped);
        setOriginalBridges(remapped);
        setValue('rhfBridgesList', remapped);
      }
    };
    if (!userId) {
      handleBridges();
    }
  }, []);

  const onSubmit = async data => {
    // console.log('originalBridges ', originalBridges);
    // console.log('rhfBridgesList ', getValues('rhfBridgesList'));
    // console.log('selectedBridges ', selectedBridges);

    const dataSharedStructures = getValues('rhfBridgesList')
      .filter(item => item.permission)
      .filter(item => {
        const res = originalBridges.find(bridge => bridge.id === item.id);
        // console.log('permission res, item ', res.permission, item.permission);
        // console.log(
        //   'accessExpiration res, item ',
        //   res.inviterAccessExpirationDate,
        //   item.accessExpiration
        // );
        // per i permessi sui ponti
        // 3 può visualizzare
        // 4 può modificare
        // res.permission = 4 e voglio impostare item.permission = 3 questo ponte torna
        // res.permission = 3 e voglio impostare item.permission = 4 questo ponte non torna

        // per la data di scadenza di accesso:
        // se la data di scadenza è uguale alla data di scadenza di item o più avanti allora torna
        // se la data di scadenza è minore della data di scadenza dell'item non torna

        let isNotAccessExpired = true;

        if (res.inviterAccessExpirationDate && item.accessExpiration) {
          const formatAccessExpiration = item.accessExpiration.includes('Z')
            ? item.accessExpiration
            : formatDate(item.accessExpiration).toISOString();

          isNotAccessExpired =
            isAfter(
              new Date(res.inviterAccessExpirationDate),
              new Date(formatAccessExpiration)
            ) ||
            isEqual(
              new Date(res.inviterAccessExpirationDate),
              new Date(formatAccessExpiration)
            );
        }

        // se il ponte dell'utente assegnante (inviter) ha la data di scadenza (res.expirationDate) e non metto la data di scadenza per il collaboratore (item.expirationDate) devo assegnare al collaboratore la scadenza del ponte dell'utente assegnante
        if (res.inviterAccessExpirationDate && !item.expirationDate) {
          item.expirationDate = res.inviterAccessExpirationDate;
        }
        /*  console.log('isNotAccessExpired ', isNotAccessExpired);
        console.log(
          'res.inviterPermissions >= item.permission ',
          res.inviterPermissions >= item.permission
        );
        console.log(
          'res.permission !== item.permission ',
          res.permission !== item.permission
        );

        console.log(
          res.accessExpiration !== item.accessExpiration,
          'res.expirationDate !== item.expirationDate'
        ); */

        /* console.log(isNotAccessExpired, 'IS NOT ACCCESS EXPIRED');
        console.log(res.permission !== item.permission, 'PERMISSION');
        console.log(
          res.accessExpiration !== item.accessExpiration,
          'EXPIRATION'
        );
        console.log(
          res.inviterPermissions >= item.permission,
          'INVITER PERMISSION'
        );
        console.log('----------------'); */

        return (
          (res.permission !== item.permission ||
            res.accessExpiration !== item.accessExpiration) &&
          //res.accessExpiration !== item.expirationDate
          // permission changed so I consider this item for saving
          res.inviterPermissions >= item.permission && // permission must be >= of inviter permission
          // res.accessExpiration !== item.accessExpiration && // access expiration changed so I consider this item for saving
          isNotAccessExpired
        );
      })
      .map(el => {
        return {
          structureId: el.id,
          permission: el.permission,
          accessExpiration: el.accessExpiration,
        };
      });

    //console.log(getValues('rhfBridgesList'), 'rhfBridgesList');

    //console.log('dataSharedStructures ', dataSharedStructures);

    if (dataSharedStructures.length === 0) {
      if (type === 'external') {
        return enqueueSnackbar(
          "Attenzione, per mandare un invito devi condividere almeno un ponte con l'utente.",
          {
            variant: 'error',
          }
        );
      }

      if (type === 'internal') {
        let res = '';
        const payload = payloadSchema;
        payload.name = data.name;
        payload.surname = data.surname;
        payload.email = data.email;
        payload.message = data.message;
        payload.sharedStructures = [];
        showLoader();
        try {
          if (userId) {
            payload.collaboratorId = parseInt(userId);
            const url = `${apiCollaborators.ROOT}/structures/${type}`;
            res = await put(url, payload);
          } else {
            const url = `${apiCollaborators.ROOT}/${type}`;
            res = await post(url, payload);
            res.response.id &&
              navigate(`/collaborators/${type}/edit/${res.response.id}`);
          }
          enqueueSnackbar('Operazione conclusa con successo', {
            variant: 'success',
          });
        } catch (error) {
          enqueueSnackbar(
            CollaborationsErrorsMessageMap[res.exception?.errorMessage] ||
              'Errore durante la richiesta',
            {
              variant: 'error',
            }
          );
        }
        hideLoader();
      }
    } else {
      setLoadingOperation(true);
      let collaboratorId;
      for (let i = 0; i < dataSharedStructures.length; i += CHUNK_SIZE) {
        const chunk = dataSharedStructures.slice(i, i + CHUNK_SIZE);
        // do whatever
        let res = '';
        const payload = payloadSchema;
        payload.name = data.name;
        payload.surname = data.surname;
        payload.email = data.email;
        payload.message = data.message;
        //payload.sharedStructures = chunk;
        payload.sharedStructures = chunk.map(d => ({
          ...d,
          accessExpiration: formatDate(d.accessExpiration),
        }));
        try {
          if (userId || collaboratorId) {
            payload.collaboratorId = parseInt(userId || collaboratorId);
            const url = `${apiCollaborators.ROOT}/structures/${type}`;
            res = await put(url, payload);
          } else {
            const url = `${apiCollaborators.ROOT}/${type}`;
            res = await post(url, payload);
            collaboratorId =
              type === 'internal' ? res.response.id : res.response.response.id;
            console.log(collaboratorId, 'RES3 : collaboratorId');
          }
        } catch (error) {
          enqueueSnackbar(
            CollaborationsErrorsMessageMap[res.exception?.errorMessage] ||
              'Errore durante la richiesta',
            {
              variant: 'error',
            }
          );
          setLoadingOperation(false);
        }
        setOperationPercentage(
          ((i + 1 + CHUNK_SIZE) / dataSharedStructures.length) * 100
        );
      }
      setOperationPercentage(0);
      setLoadingOperation(false);
      enqueueSnackbar('Operazione conclusa con successo', {
        variant: 'success',
      });
      !userId &&
        collaboratorId &&
        navigate(`/collaborators/${type}/edit/${collaboratorId}`);
      showLoader();
      // update original bridges
      userId && (await handleCollaboratorInfo());
      hideLoader();
    }
  };

  const editRowsBridges = item => {
    setValue('rhfSelectedBridges', [item]);
  };

  const handleSelectAllClickBridges = event => {
    if (event.target.checked) {
      const bridgleList = getValues('rhfBridgesList');
      const filtered = bridgleList?.filter(el => {
        return (
          el?.internalCode
            ?.toLowerCase()
            ?.includes(searchParams?.internalCode?.toLowerCase() || '') &&
          el?.name
            ?.toLowerCase()
            ?.includes(searchParams?.name?.toLowerCase() || '') &&
          el?.tags
            ?.toLowerCase()
            ?.includes(searchParams?.tags?.toLowerCase() || '')
        );
      });
      setSelectedBridges(filtered);
      setValue('rhfSelectedBridges', filtered);
    } else {
      setSelectedBridges([]);
      setValue('rhfSelectedBridges', []);
    }
  };
  const onSubmitBridges = () => {
    const selectedPermission = getValues('rhfPermission');
    const selectedAccessExpiration = getValues('rhfAccessExpiration');
    const formatAccessExpiration =
      selectedAccessExpiration &&
      format(new Date(selectedAccessExpiration), 'dd-MM-yyyy');

    const ids = selectedBridges.map(el => el.internalCode);

    const brdgs = getValues('rhfBridgesList');

    brdgs.map((x, index) => {
      if (ids.includes(x?.internalCode)) {
        // check if the current bridge (x) has already a
        // accessExpiration, if that is true and the selectedAccessExpiration
        // is equal to null, then the action decided is to set it to null
        // otherwise, the key accessExpiration should not exists in the object
        if (
          (x.accessExpiration && formatAccessExpiration) ||
          (x.accessExpiration && !formatAccessExpiration) ||
          (!x.accessExpiration && formatAccessExpiration)
        ) {
          brdgs[index] = {
            ...x,
            permission: selectedPermission,
            accessExpiration: formatAccessExpiration,
          };
        } else {
          brdgs[index] = {
            ...x,
            permission: selectedPermission,
          };
        }
      }
    });

    // console.log('brdgs ', brdgs);
    setValue('rhfBridgesList', brdgs);
    onResetBridges();
  };

  const onResetBridges = () => {
    setValue('rhfSelectedBridges', []);
    setValue('rhfPermission', '');
    setValue('rhfAccessExpiration', null);
    setSelectedBridges([]);
  };

  const changeAutocomplete = value => {
    setSelectedBridges(value);
  };

  const resendInvitation = async () => {
    const email = getValues('email');
    const message = getValues('message');
    await post(COLLABORATORS_PATHS.RESEND_INVITATION_MAIL, {
      email,
      message,
    });
    if (response.ok) {
      enqueueSnackbar('Invito reinviato', {
        variant: 'success',
      });
    }
  };

  const handleNewSelectedBridgesPermission = newSelectedBridge => {
    let filteredPermissions = PermissionTypeSelectOptions;
    setPermissionChangeMessage();
    if (newSelectedBridge.length === 0) {
      return setPermissionOptions(PermissionTypeSelectOptions);
    }
    if (
      newSelectedBridge.some(
        el => el.inviterPermissions === PermissionType.CanView
      )
    ) {
      filteredPermissions = PermissionTypeSelectOptions.filter(
        el => el.value !== PermissionType.CanEdit
      );
      const res = newSelectedBridge.find(
        el => el.inviterPermissions === PermissionType.CanView
      );
      setPermissionChangeMessage(
        `Attenzione, sul ponte "${res?.name}" hai solo il permesso "Può visualizzare" e non poi assegnare "Può modificare" al tuo collaboratore.`
      );
    }
    if (newSelectedBridge.some(el => el.editable === false)) {
      const res = newSelectedBridge.find(el => el.editable === false);
      setPermissionChangeMessage(
        `Attenzione, non puoi modificare i permessi del ponte "${res?.name}" in quanto di proprietà del tuo collaboratore esterno.`
      );
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
    if (
      newSelectedBridge.some(
        el => el.inviterPermissions === PermissionType.NoPermission
      )
    ) {
      filteredPermissions = PermissionTypeSelectOptions.filter(
        el =>
          el.value !== PermissionType.CanEdit ||
          el.value !== PermissionType.CanView
      );
    }
    if (
      currentEditingUser &&
      currentEditingUser?.type === UserType.Ente &&
      loggedUser?.type === UserType.Professionista
    ) {
      setPermissionOptions(
        filteredPermissions.filter(el => el.value !== PermissionType.CanView)
      );
    } else {
      setPermissionOptions(filteredPermissions);
    }
  };

  useEffect(() => {
    setValue('rhfSelectedBridges', selectedBridges);
    handleNewSelectedBridgesPermission(selectedBridges);
  }, [selectedBridges]);

  useEffect(() => {
    if (
      currentEditingUser &&
      currentEditingUser?.type === UserType.Ente &&
      loggedUser?.type === UserType.Professionista
    ) {
      setPermissionOptions(
        permissionOptions.filter(el => el.value !== PermissionType.CanView)
      );
    }
  }, [currentEditingUser]);

  return (
    <div>
      {loadingOperation && (
        <LoadingScreenWithPercentage percentage={operationPercentage} />
      )}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <HeaderPage
          title={
            userId
              ? `Modifica ${HeaderPageTitleMap[type]}`
              : `Nuovo ${HeaderPageTitleMap[type]}`
          }
          back={COLLABORATORS_PATHS.ROOT}
          buttons={
            <>
              <Button variant="containedBlack" type="submit" disabled={loading}>
                Salva
              </Button>
              {currentEditingUser?.status === UserStatus.Invitato && (
                <Button
                  variant="contained"
                  onClick={() => resendInvitation()}
                  disabled={loading}
                >
                  Rimanda invito
                </Button>
              )}
            </>
          }
        />
        <Grid container spacing={2}>
          {userId && (
            <Grid item xs={6}>
              <RHFSelect
                label="Stato"
                name="status"
                disabled
                selectOptions={UserStatusOptions.filter(
                  el => el.value !== UserStatus.Attivo
                )}
              />
            </Grid>
          )}
          <Grid item xs={userId ? 6 : 12}>
            <RHFTextField
              disabled={!!userId}
              label="Email d'accesso*"
              name="email"
              rules={{
                required: 'Email obbligatoria',
                pattern: {
                  // eslint-disable-next-line no-useless-escape
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/,
                  message: 'Inserire una email valida',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField
              label="Nome*"
              rules={{ required: 'Nome obbligatorio' }}
              name="name"
            />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField
              label="Cognome*"
              rules={{ required: 'Cognome obbligatorio' }}
              name="surname"
            />
          </Grid>
          {(currentEditingUser?.status === UserStatus.Invitato || !userId) && (
            <Grid item xs={12}>
              <RHFTextField
                label="Messaggio"
                /* rules={{ required: 'Messaggio obbligatorio' }} */
                name="message"
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Divider>Opere condivise</Divider>
          </Grid>
          <Grid item xs={12}>
            <BridgesFilter setSearchParams={setSearchParams} />
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            className={`${tour_17.step_02}`}
          >
            <Grid item xs={6}>
              <RHFTagAutocomplete
                multiple={true}
                id="tags-collaborators"
                name={'rhfSelectedBridges'}
                changeAutocomplete={value => {
                  changeAutocomplete(value);
                }}
                labelName={'option.internalCode'}
                getOptionLabel={option => option.internalCode}
                opt={bridges}
                label="Opere selezionate"
              />
            </Grid>
            <Grid item xs={3}>
              <RHFSelect
                name="rhfPermission"
                defaultValue={''}
                label="Diritti di accesso"
                selectOptions={permissionOptions}
                showNoValue={false}
              />
            </Grid>
            <Grid item xs={3}>
              <RHFDatePicker
                name="rhfAccessExpiration"
                label="Scadenza Accesso"
                defaultValue={null}
                minDate={new Date()}
                maxDate={
                  getValues('rhfSelectedBridges')?.[0]
                    ?.inviterAccessExpirationDate
                    ? new Date(
                        getValues(
                          'rhfSelectedBridges'
                        )?.[0]?.inviterAccessExpirationDate
                      )
                    : loggedUser?.lastSubscription?.expirationDate
                    ? new Date(loggedUser?.lastSubscription?.expirationDate)
                    : null
                }
                /* maxDate={
                  loggedUser?.lastSubscription?.expirationDate
                    ? new Date(loggedUser?.lastSubscription?.expirationDate)
                    : null
                } */
              />
            </Grid>
            <Grid item xs={12}>
              <FormSubmitButtons
                onSubmit={onSubmitBridges}
                onReset={onResetBridges}
                disabled={!canSubmit}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {permissionChangeMessage && (
              <InfoBox color="primary.light" text={permissionChangeMessage} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Table
              data={
                watch('rhfBridgesList')?.filter(el => {
                  return (
                    el?.internalCode
                      ?.toLowerCase()
                      ?.includes(
                        searchParams?.internalCode?.toLowerCase() || ''
                      ) &&
                    el?.name
                      ?.toLowerCase()
                      ?.includes(searchParams?.name?.toLowerCase() || '') &&
                    el?.tags
                      ?.toLowerCase()
                      ?.includes(searchParams?.tags?.toLowerCase() || '')
                  );
                }) || []
              }
              /* data={bridges || []} */
              config={dataConfigBridges}
              hasCheckbox={true}
              rowActions={[
                {
                  onClick: item => {
                    setValue('rhfPermission', item.permission);
                    setValue(
                      'rhfAccessExpiration',
                      item.accessExpiration
                        ? parse(item.accessExpiration, 'dd-MM-yyyy', new Date())
                        : null
                    );
                    editRowsBridges(item);
                    setSelectedBridges([item]);
                  },
                  icon: <EditIcon />,
                },
              ]}
              selected={selectedBridges}
              onSelectAllClick={handleSelectAllClickBridges}
              setSelected={setSelectedBridges}
            />
          </Grid>
        </Grid>
      </FormProvider>
      <TourWrapper steps={steps} />
    </div>
  );
};

CreateCollaborator.propTypes = {
  type: PropTypes.oneOf(['internal', 'external']),
};

export default CreateCollaborator;
