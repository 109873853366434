import styled from '@emotion/styled';

export const BodyContainer = styled('div')({
  marginTop: '20px',
});

export const BtnContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 15,
});

export const ExportSuccess = styled('div')({
  width: '100%',
  borderRadius: 8,
  backgroundColor: '#C6E0B4B8',
  padding: 18,
  marginTop: 20,
});

export const ExportMessage = styled('div')(({ status }) => ({
  width: '100%',
  borderRadius: 8,
  backgroundColor: status === 'success' ? '#C6E0B4B8' : '#FACCD5',
  padding: 18,
  marginTop: 20,
}));
