import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const Row = styled('div')({
  display: 'flex',
  flex: 1,
  gap: '10px',
});

export const GraphicContainer = styled('div')(({ theme, width }) => ({
  width: width || '80%',
  height: '365px',
  backgroundColor: theme.palette.secondary.light,
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '13px',
}));

export const Status = styled('div')(({ color }) => ({
  height: '11px',
  width: '11px',
  borderRadius: '100%',
  backgroundColor: color,
  margin: '0 10px',
}));

export const StatusContainer = styled('div')({
  height: '40px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& p': {
    fontSize: '12px',
    whiteSpace: 'no-wrap',
    width: '100%',
  },
});

export const MiniBoxContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 0px 10px 10px',
  gap: 10,
});

export const Box = styled('div')({
  width: '125px',
  height: '64px',
  display: 'flex',
  background: colors.WHITE,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 14px 0px 0px',
  borderRadius: '13px',
});

export const ContainerAttentionClass = styled('div')({
  display: 'flex',
});

export const BluCircle = styled('div')({
  display: 'flex',
});

export const RateItem = styled('div')(({ customColors, theme }) => ({
  height: '35px',
  minWidth: '35px',
  borderRadius: '100%',
  backgroundColor: customColors?.bgColor || theme.palette.secondary.light,
  color: customColors?.color || theme.palette.error.light,
  border: `1px solid ${customColors?.border || theme.palette.error.light}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  marginRight: '2px',
}));

export const Image = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '80%',
});

export const LittleContainer = styled('div')(({ theme }) => ({
  height: '160px',
  backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
}));

export const GalleryBox = styled('div')({
  marginTop: '20px',
});
