import styled from '@emotion/styled';

export const IconContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& > img': {
    cursor: 'pointer',
  },
});

export const DotAlert = styled('div')(({ theme }) => ({
  borderRadius: '100%',
  backgroundColor: theme.palette.primary.main,
  height: '7px',
  width: '7px',
  alignSelf: 'end',
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  marginTop: '30px',
  justifyContent: 'end',
});
