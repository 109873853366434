import { Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';

import { DrawerMedia, InfoWrapper, Table } from 'components';
import { colors } from 'constants/colors';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import {
  geometriaSezionePilaOptions,
  impostaFondPilaOptions,
  impostaFondSpallaOptions,
  pilaInAlveoOptions,
  tipoFondPilaOptions,
  tipoFondSpallaOptions,
  tipologiaSpallaOptions,
  tipoPilaSelectOptions,
  tipoSezionePilaOptions,
  vieAttaccoSpallaOptions,
} from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import {
  isNullOrUndefinedOrEmptyString,
  updateTableRows,
} from 'utils/utilities';
import { GridWrap } from './Accordion3Elevazione.styles';
import PileForm from './sections/PileForm';
import SpalleForm from './sections/SpalleForm';

const Accordion3Elevazione = () => {
  const { watch, getValues, setValue, control } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [selectedSpalle, setSelectedSpalle] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [isSpalleDrawer, setIsSpalleDrawer] = useState(false);

  const numSpalle = watch('nSpalleStruttura');
  const watchSpalleTable = watch('spalleTable');

  const watchPileTable = watch('pileTable');
  const watchNPile = watch('nPileStruttura');

  // watch the values of Number of Pile set in the Accordion1 of Structures, and populate  the relative tables
  const numPile = watch('nPileStruttura');

  const {
    // fields: campateFields,
    append: addSpalle,
    remove: removeSpalle,
  } = useFieldArray({
    control,
    name: 'spalleTable',
  });

  const [selectedPile, setSelectedPile] = useState([]);
  const {
    // fields: campateFields,
    append: addPile,
    remove: removePile,
  } = useFieldArray({
    control,
    name: 'pileTable',
  });

  const dataConfigSpalle = {
    columns: {
      codiceSpalla: { label: 'Cod.' },
      tipologiaSpalla: { label: 'Tipologia', format: tipologiaSpallaOptions },
      larghezzaSpalla: { label: '(L) [cm]' },
      altezzaSpalla: { label: '(H) [cm]' },
      profonditaSuperioreSpalla: { label: 'Ps [cm]' },
      profonditaInferioreSpalla: { label: 'Pi [cm]' },
      profonditaAppoggioSpalla: { label: 'Pa [cm]' },
      superficieSpalla: { label: 'Sup. [m²]' },
      vieAttaccoSpalla: {
        label: 'Vie di attacco',
        format: vieAttaccoSpallaOptions,
      },
      impostaFondSpalla: {
        label: 'Imposta fond.',
        format: impostaFondSpallaOptions,
      },
      tipoFondSpalla: {
        label: 'Tipo fond.',
        format: tipoFondSpallaOptions,
      },
    },
  };

  const dataConfigPile = {
    columns: {
      codicePila: { label: 'Cod.' },
      tipoPila: { label: 'Tipologia', format: tipoPilaSelectOptions },
      sezionePila: { label: 'Sezione', format: tipoSezionePilaOptions },
      sezionePilaAltro: { label: 'Sezione (altro)' },
      geometriaSezionePila: {
        label: 'Geom Sez.',
        format: geometriaSezionePilaOptions,
      },
      pilaInAlveo: { label: 'Pila in alveo', format: pilaInAlveoOptions },
      pilastriPerPila: { label: 'Pil/P' },
      altezzaMediaPila: { label: 'Hm [cm]' },
      altezzaMassimaPila: { label: 'HM [cm]' },
      diametroPila: { label: 'D [cm]' },
      larghezzaPilastroPila: { label: 'L[cm]' },
      profonditaPilastroPila: { label: 'P [cm]' },
      superficiePila: { label: 'Sup. [m²]' },
      numFondPila: { label: 'N° Fond.' },
      impostaFondPila: {
        label: 'Imp. Fond.',
        format: impostaFondPilaOptions,
      },
      tipoFondPila: { label: 'Tip. Fond.', format: tipoFondPilaOptions },
    },
  };

  // watch the values of Number of Spalle set in the Accordion1 of Structures, and populate  the relative tables

  const handleAddSpalle = i => {
    addSpalle({
      id: uuidv4(),
      completamentoSchedaDifetti: '',
      indiceCondizione: '',
      codiceSpalla: 'S' + i,
      tipologiaSpalla: '',
      larghezzaSpalla: '',
      altezzaSpalla: '',
      profonditaSuperioreSpalla: '',
      profonditaInferioreSpalla: '',
      profonditaAppoggioSpalla: '',
      superficieSpalla: '',
      vieAttaccoSpalla: '',
      impostaFondSpalla: '',
      tipoFondSpalla: '',
      schedaDifetti: populateSchedaDifetti('spalla').map(el => {
        return {
          ...el,
          visto: false,
          statoDifetto: '',
          estensioneDifetto: '',
          intensitaDifetto: '',
          ps: false,
          note: '',
          media: [],
        };
      }),
      media: [],
    });
  };

  const handleAddPile = i => {
    addPile({
      id: uuidv4(),
      codicePila: 'P' + i,
      tipoPila: '',
      sezionePila: '',
      sezionePilaAltro: '',
      geometriaSezionePila: '',
      pilaInAlveo: '',
      pilastriPerPila: '',
      altezzaMediaPila: '',
      altezzaMassimaPila: '',
      diametroPila: '',
      larghezzaPilastroPila: '',
      profonditaPilastroPila: '',
      superficiePila: '',
      numFondPila: '',
      impostaFondPila: '',
      tipoFondPila: '',
      schedaDifetti: populateSchedaDifetti('pila').map(el => {
        return {
          ...el,
          visto: false,
          statoDifetto: '',
          estensioneDifetto: '',
          intensitaDifetto: '',
          ps: false,
          note: '',
          media: [],
        };
      }),
      media: '',
    });
  };

  const editRowsSpalle = item => {
    setValue('spalleTableValue', [item]);
    setValue('tipologiaSpallaTableValue', item.tipologiaSpalla);
    setValue('larghezzaSpallaTableValue', item.larghezzaSpalla);
    setValue('altezzaSpallaTableValue', item.altezzaSpalla);
    setValue('profonditaSupTableValue', item.profonditaSuperioreSpalla);
    setValue('profonditaInfTableValue', item.profonditaInferioreSpalla);
    setValue('profonditaAppTableValue', item.profonditaAppoggioSpalla);
    setValue('superficieSpallaTableValue', item.superficieSpalla);
    setValue('vieAttaccoSpallaTableValue', item.vieAttaccoSpalla);
    setValue('impostaFondSpallaTableValue', item.impostaFondSpalla);
    setValue('tipoFondSpallaTableValue', item.tipoFondSpalla);
  };
  const handleSelectAllClickSpalle = event => {
    if (event.target.checked) {
      const selectedValue = getValues('spalleTable');
      setSelectedSpalle(selectedValue);
      setValue('spalleTableValue', selectedValue);
    } else {
      setSelectedSpalle([]);
      setValue('spalleTableValue', []);
    }
  };

  const editRowsPile = item => {
    setValue('pileTableValue', [item]);
    setValue('tipoPilaTableValue', item.tipoPila);
    setValue('tipoSezionePilaTableValue', item.sezionePila);
    setValue('tipoSezioneAltroPilaTableValue', item.sezionePilaAltro);
    setValue('geometriaSezioneTableValue', item.geometriaSezionePila);
    setValue('pilaInAlveoTableValue', item.pilaInAlveo);
    setValue('pilastriPerPilaTableValue', item.pilastriPerPila);
    setValue('altezzaMediaPilaTableValue', item.altezzaMediaPila);
    setValue('altezzaMassimaPilaTableValue', item.altezzaMassimaPila);
    setValue('diametroPilaTableValue', item.diametroPila);
    setValue('larghezzaPilastroPilaTableValue', item.larghezzaPilastroPila);
    setValue('profonditaPilastroPilaTableValue', item.profonditaPilastroPila);
    setValue('superficiePilaTableValue', item.superficiePila);
    setValue('numFondPilaTableValue', item.numFondPila);
    setValue('impostaFondPilaTableValue', item.impostaFondPila);
    setValue('tipoFondPilaTableValue', item.tipoFondPila);
  };
  const handleSelectAllClickPile = event => {
    if (event.target.checked) {
      const selectedValue = getValues('pileTable');
      setSelectedPile(selectedValue);
      setValue('pileTableValue', selectedValue);
    } else {
      setSelectedPile([]);
      setValue('pileTableValue', []);
    }
  };
  useEffect(() => {
    if (!isNullOrUndefinedOrEmptyString(numSpalle)) {
      const total = watchSpalleTable?.length || 0;

      if (!parseInt(numSpalle)) {
        removeSpalle();
      } else {
        updateTableRows(
          parseInt(numSpalle),
          total,
          removeSpalle,
          handleAddSpalle
        );
      }
      /*  numPile && updateTableRows(numPile, total, removePile, handleAddPile); */
    }
  }, [numSpalle]);

  useEffect(() => {
    if (!isNullOrUndefinedOrEmptyString(numPile)) {
      const total = watchPileTable?.length || 0;

      if (!parseInt(numPile)) {
        removePile();
      } else {
        updateTableRows(parseInt(numPile), total, removePile, handleAddPile);
      }
      /*  numPile && updateTableRows(numPile, total, removePile, handleAddPile); */
    }
  }, [numPile]);

  return (
    <>
      <Grid container spacing={2}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.elevazioneSpalle.drawerTitle}
          drawerText={INFO.elevazioneSpalle.drawerText}
        >
          <Divider>SPALLE</Divider>
        </InfoWrapper>
      </Grid>
      {(parseInt(numSpalle) === 0 ||
        isNullOrUndefinedOrEmptyString(numSpalle)) && (
        <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
          <Typography variant="h3Bold" sx={{ color: colors.RED }}>
            Attenzione! Per proseguire è necessario inserire il numero di spalle
          </Typography>
        </div>
      )}
      <>
        {parseInt(numSpalle) !== 0 &&
          !isNullOrUndefinedOrEmptyString(numSpalle) && (
            <>
              {!inspectionDisabled && !isViewingInspection && (
                <SpalleForm
                  selected={selectedSpalle}
                  setSelected={setSelectedSpalle}
                  spalleFields={watchSpalleTable || []}
                />
              )}
              <GridWrap container spacing={2} mt={2}>
                <GridWrap item xs={12}>
                  <Table
                    data={watchSpalleTable || []}
                    config={dataConfigSpalle}
                    hasCheckbox={!inspectionDisabled && !isViewingInspection}
                    rowActions={
                      !inspectionDisabled && !isViewingInspection
                        ? [
                            {
                              onClick: item => {
                                editRowsSpalle(item);
                                setSelectedSpalle([item]);
                              },
                              icon: <EditIcon />,
                            },
                          ]
                        : []
                    }
                    selected={selectedSpalle}
                    onSelectAllClick={handleSelectAllClickSpalle}
                    setSelected={setSelectedSpalle}
                    mediaActions={[
                      {
                        onClick: item => {
                          setDrawerMediaIndex(
                            watchSpalleTable.findIndex(el => el.id === item.id)
                          );
                          setIsSpalleDrawer(true);
                          setOpen(true);
                        },
                        icon: <MediaIcon />,
                      },
                    ]}
                  />
                </GridWrap>
              </GridWrap>
            </>
          )}
      </>

      {(!numPile || numPile > 0) && (
        <Grid container spacing={2} mt={2} marginTop={3}>
          <InfoWrapper
            isDivider={true}
            size={12}
            infoDrawer={true}
            drawerTitle={INFO.elevazionePile.drawerTitle}
            drawerText={INFO.elevazionePile.drawerText}
          >
            <Divider>PILE</Divider>
          </InfoWrapper>
        </Grid>
      )}

      {/* {(parseInt(watchNPile) === 0 ||
        isNullOrUndefinedOrEmptyString(watchNPile)) && ( */}
      {!numPile && (
        <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
          <Typography variant="h3Bold" sx={{ color: colors.RED }}>
            Attenzione! Per proseguire è necessario inserire il numero di pile
          </Typography>
        </div>
      )}
      {parseInt(watchNPile) !== 0 &&
        !isNullOrUndefinedOrEmptyString(watchNPile) && (
          <>
            {!inspectionDisabled && !isViewingInspection && (
              <PileForm
                selected={selectedPile}
                setSelected={setSelectedPile}
                pileFields={watchPileTable || []}
              />
            )}

            <GridWrap container spacing={2} mt={2}>
              <GridWrap item xs={12}>
                <Table
                  data={watchPileTable || []}
                  config={dataConfigPile}
                  hasCheckbox={!inspectionDisabled && !isViewingInspection}
                  rowActions={
                    !inspectionDisabled && !isViewingInspection
                      ? [
                          {
                            onClick: item => {
                              editRowsPile(item);
                              setSelectedPile([item]);
                            },
                            icon: <EditIcon />,
                          },
                        ]
                      : []
                  }
                  mediaActions={[
                    {
                      onClick: item => {
                        setDrawerMediaIndex(
                          watchPileTable.findIndex(el => el.id === item.id)
                        );
                        setIsSpalleDrawer(false);
                        setOpen(true);
                      },
                      icon: <MediaIcon />,
                    },
                  ]}
                  selected={selectedPile}
                  onSelectAllClick={handleSelectAllClickPile}
                  setSelected={setSelectedPile}
                />
              </GridWrap>
            </GridWrap>
          </>
        )}
      <DrawerMedia
        open={open}
        setOpen={setOpen}
        title={isSpalleDrawer ? `Spalle` : 'Pile'}
        infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
        dataType="video/*,image/*"
        uploadType={UploadMedia.media}
        type={FieldType.images}
        formName={`${
          isSpalleDrawer ? 'spalleTable' : 'pileTable'
        }[${drawerMediaIndex}].media`}
        mediaGroup={[MediaGroup.Struttura]}
      />
    </>
  );
};

export { Accordion3Elevazione };
