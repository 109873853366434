import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const BoxStyle = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
});

function CircularProgressWithLabel({ value }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        color="info"
        sx={{ position: 'absolute', opacity: 0.75 }}
        size="4rem"
      />
      <CircularProgress variant="determinate" value={value} size="4rem" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h3" component="div" color="primary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number,
};

const LoadingScreenWithPercentage = ({ percentage }) => {
  return (
    <Backdrop
      open={true}
      sx={{
        zIndex: 1500,
      }}
    >
      <BoxStyle>
        <CircularProgressWithLabel value={percentage} />
      </BoxStyle>
    </Backdrop>
  );
};
LoadingScreenWithPercentage.propTypes = {
  percentage: PropTypes.number,
};

export { LoadingScreenWithPercentage };
