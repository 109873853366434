import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { InfoWrapper } from 'components';
import { RHFDatePicker, RHFSelect, RHFTextField } from 'components/form';
import { PermissionType } from 'constants/permissions';
import { INFO } from 'pages/Inspections/config/info';

const collaborationTypeOptions = [
  {
    label: 'Collaboratore Interno',
    value: 'internal',
  },
  {
    label: 'Collaboratore Esterno',
    value: 'external',
  },
];

const NewUserForm = ({
  shareOptions,
  showDatePicker,
  subscription,
  accessExpiration,
}) => {
  return (
    <div style={{ marginTop: 2 }}>
      <Grid item xs={12} mt={2} mb={2}>
        <RHFSelect
          name="permission"
          label="Diritto di accesso*"
          selectOptions={shareOptions.filter(
            el => el.value !== PermissionType.NoPermission
          )}
          showNoValue={false}
        />
      </Grid>
      {/**
       * relates to https://github.com/nautes-tech/tecnoindagini-issues/issues/496
       * we have to hide the choice of collaborationType in case the logged user
       * does not have an active subscription, given the default value to this field of "external"
       */}
      {showDatePicker && (
        <InfoWrapper size={12} infoMessage={INFO.collaborationType.infoMessage}>
          <RHFSelect
            name="collaborationType"
            label={'Tipologia Collaboratore'}
            selectOptions={collaborationTypeOptions}
            showNoValue={false}
          />
        </InfoWrapper>
      )}

      <Grid item xs={12} mb={4} container spacing={2}>
        <Grid item xs={6} mt={2}>
          <RHFTextField
            name={'name'}
            label={'Nome'}
            rules={{ required: 'Nome obbligatorio' }}
          />
        </Grid>
        <Grid item xs={6} mt={2}>
          <RHFTextField
            name={'surname'}
            label={'Cognome'}
            rules={{ required: 'Cognome obbligatorio' }}
          />
        </Grid>
      </Grid>
      {showDatePicker && (
        <InfoWrapper
          size={12}
          infoMessage={INFO.accessExpirationSharing.infoMessage}
        >
          <RHFDatePicker
            name="accessExpiration"
            label={'Scadenza Accesso'}
            minDate={new Date()}
            maxDate={
              accessExpiration
                ? new Date(accessExpiration)
                : new Date(subscription?.expirationDate)
            }
          />
        </InfoWrapper>
      )}
      <Grid item xs={12} mt={2}>
        <RHFTextField
          name={'message'}
          label={'Messaggio'}
          rows={4}
          multiline
          /* rules={{ required: 'Messaggio obbligatorio' }} */
        />
      </Grid>
    </div>
  );
};

NewUserForm.propTypes = {
  shareOptions: PropTypes.array,
  subscription: PropTypes.shape({
    expirationDate: PropTypes.string,
    id: PropTypes.number,
    maxAvailableSpace: PropTypes.number,
    maxStructureNumber: PropTypes.number,
    userId: PropTypes.number,
  }),
  showDatePicker: PropTypes.bool,
  accessExpiration: PropTypes.string,
};

export default NewUserForm;
