import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NOTIFICATION_ROOT } from 'constants/paths';
import { tour_01 } from 'constants/tour';
import { UserTypology } from 'constants/users';
import { useNotifications } from 'hooks';
import { useAuthSelector } from 'stores';
import { TopBar } from './Header.style';
import { colors } from '../../../constants/colors';
import { NotificationPopover } from '../components/NotificationPopover/NotificationPopover';
import { StandardPopover } from '../components/StandardPopover/StandardPopover';

const Header = ({ user, userType }) => {
  const { handleLogout } = useAuthSelector();
  const startPage = 1;
  const startPageSize = 3;
  const {
    notifications,
    handleReadAllNotification,
    handleReadNotification,
    handleNotifications,
  } = useNotifications({
    startPage,
    startPageSize,
  });
  const { pathname } = useLocation();

  const label = `${user?.name?.charAt(0)?.toUpperCase() || ''}${
    user?.surname?.charAt(0)?.toUpperCase() || ''
  }`;
  useEffect(() => {
    handleNotifications();
  }, [pathname]);

  return (
    <TopBar>
      {userType !== 'ADMIN' && (
        <>
          {pathname !== NOTIFICATION_ROOT && (
            <NotificationPopover
              handleReadAllNotification={handleReadAllNotification}
              handleReadNotification={handleReadNotification}
              notifications={notifications}
            />
          )}

          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              /* width: 178, */
              height: 44,
              borderRadius: 22,
              border: `2px solid ${colors.GREY}`,
            }}
            className={tour_01.step_02b}
          >
            {/* <Typography sx={{ p: 1, textAlign: 'right', fontWeight: 'bold' }}>
    {'Collaboratori'}
  </Typography> */}
            {/* TODO: RIPRISTINARE */}
            <StandardPopover
              label={
                user?.tipologiaUtente === UserTypology.CollaboratoreInterno
                  ? user?.parent?.businessName || user?.displayName
                  : user?.businessName || user?.displayName
              }
              name={user?.businessName}
              email={user?.email}
              personalField="Collaboratori"
              userType={user?.type}
              userTypology={user?.tipologiaUtente}
            />
          </div>
        </>
      )}

      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 44,
          height: 44,
          borderRadius: 22,
          border: `2px solid ${colors.GREY}`,
        }}
        className={tour_01.step_02}
      >
        <StandardPopover
          label={label}
          name={`${user?.name || ''} ${user?.surname || ''}`}
          email={user?.email}
          // text="Area Personale"
          personalField="Logout"
          onClickButton={() => {
            handleLogout();
          }}
        />
      </div>
    </TopBar>
  );
};

export { Header };

Header.propTypes = {
  user: PropTypes.object,
  userType: PropTypes.string,
};
