export const internalCodeGenerationMode = {
  AUTO: 'Auto',
  MANUAL: 'Manual',
};

export const inspectionType = {
  DIGITAL_INSPECTION: 1,
  PREVIOUS_INSPECTION_EXTRAPLATFORM: 2,
};

export const prevailingMaterialOptions = [
  { value: 1, label: 'Muratura' },
  { value: 2, label: 'CA' },
  { value: 3, label: 'CAP' },
  { value: 4, label: 'Acciaio' },
  { value: 5, label: 'Metallo (ponti storici)' },
  { value: 6, label: 'Misto (C.A./acciaio)' },
  { value: 7, label: 'Legno' },
  { value: 8, label: 'Altro' },
];
export const valoreCDAOptions = [
  { value: 1, label: 'Alta' },
  { value: 2, label: 'MedioAlta' },
  { value: 3, label: 'Media' },
  { value: 4, label: 'MedioBassa' },
  { value: 5, label: 'Bassa' },
  { value: 6, label: 'ND' },
];

export const CreateBridgeDisabledType = {
  MAX_STRUCTURES_REACHED: 1,
  EXPIRED_SUBSCRIPTION: 2,
};

export const CreateBridgeDisabledMessageMap = {
  [CreateBridgeDisabledType.MAX_STRUCTURES_REACHED]:
    'Hai raggiunto il limite di strutture massimo della tua sottoscrizione. Contattaci per rinnovare la sottoscrizione.',
  [CreateBridgeDisabledType.EXPIRED_SUBSCRIPTION]:
    'La tua sottoscrizione è scaduta. Contattaci per rinnovare la sottoscrizione.',
};

export const BridgesCardAccessExpiredType = {
  MAINUSER_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION: 1,
  MAINUSER_NOT_OWNER_WITH_NO_SUBSCRIPTION: 2,
  MAINUSER_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION: 3,
  MAINUSER_OWNER_WITH_NO_SUBSCRIPTION: 4,
  MAINUSER_OWNER_WITH_EXPIRED_SUBSCRIPTION: 5,
  MAINUSER_OWNER_WITH_ACTIVE_SUBSCRIPTION: 12,
  INTERNALCOLLABORATOR_OWNER_WITH_ACTIVE_SUBSCRIPTION: 6,
  INTERNALCOLLABORATOR_OWNER_WITH_NO_SUBSCRIPTION: 7,
  INTERNALCOLLABORATOR_OWNER_WITH_EXPIRED_SUBSCRIPTION: 8,
  INTERNALCOLLABORATOR_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION: 9,
  INTERNALCOLLABORATOR_NOT_OWNER_WITH_NO_SUBSCRIPTION: 10,
  INTERNALCOLLABORATOR_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION: 11,
};

export const BridgesCardDisabledMessageMap = {
  [BridgesCardAccessExpiredType.MAINUSER_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
    "L'accesso al ponte per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [BridgesCardAccessExpiredType.MAINUSER_NOT_OWNER_WITH_NO_SUBSCRIPTION]:
    "L'accesso al ponte per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [BridgesCardAccessExpiredType.MAINUSER_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
    "L'accesso al ponte per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [BridgesCardAccessExpiredType.MAINUSER_OWNER_WITH_NO_SUBSCRIPTION]:
    'Per accedere al ponte devi avere una sottoscrizione attiva. Contattaci per sottoscrivere.',
  [BridgesCardAccessExpiredType.MAINUSER_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
    'Per accedere al ponte in archivio devi avere una sottoscrizione attiva. Contattaci per rinnovare la sottoscrizione. ',
  [BridgesCardAccessExpiredType.MAINUSER_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
    "L'accesso al ponte per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
    "L'accesso al ponte per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_NO_SUBSCRIPTION]:
    'Per accedere al ponte devi avere una sottoscrizione attiva. Contattaci per sottoscrivere. ',
  [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
    'Per accedere al ponte devi avere una sottoscrizione attiva. Contattaci per rinnovare la sottoscrizione.  ',
  [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
    "L'accesso al ponte per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_NO_SUBSCRIPTION]:
    "L'accesso al ponte per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [BridgesCardAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
    "L'accesso al ponte per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
};
