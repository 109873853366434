import { ClearRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import * as React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  /*  width: '80%', */
  p: 4,
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  textAlign: 'center',
  '& img': {
    width: 'auto',
    maxHeight: '80vh',
  },
};

const Lightbox = ({ open, onClose, imageUrl }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus
      >
        <Box sx={style}>
          <IconButton
            onClick={onClose}
            sx={{
              bgcolor: 'background.paper',
              position: 'absolute',
              top: -50,
              right: 0,
            }}
          >
            <ClearRounded />
          </IconButton>
          <img src={imageUrl} />
          {/*  will it ever be necessary to add some sort of caption, use the example below */}
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
        </Box>
      </Modal>
    </div>
  );
};

Lightbox.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  imageUrl: PropTypes.string,
};

export { Lightbox };
