import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import {
  impostaFondSpallaOptions,
  tipoFondSpallaOptions,
  tipologiaSpallaOptions,
  vieAttaccoSpallaOptions,
} from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import { GridWrap } from './SpalleForm.styles';

const SpalleForm = ({ spalleFields, selected, setSelected }) => {
  const { getValues, setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const { populateSchedaDifetti } = useSchedaDifetti();

  const methods = useFormContext({
    defaultValues: {
      spalleTableValue: [],
      tipologiaSpallaTableValue: '',
      larghezzaSpallaTableValue: '',
      altezzaSpallaTableValue: '',
      profonditaSupTableValue: '',
      profonditaInfTableValue: '',
      profonditaAppTableValue: '',
      superficieSpallaTableValue: '',
      vieAttaccoSpallaTableValue: '',
      impostaFondSpallaTableValue: '',
      tipoFondSpallaTableValue: '',
    },
  });

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('spalleTableValue', selected);
  }, [selected]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeSpalle = () => {
    const tipologiaSpallaTableValue = getValues('tipologiaSpallaTableValue');
    const larghezzaSpallaTableValue = getValues('larghezzaSpallaTableValue');
    const altezzaSpallaTableValue = getValues('altezzaSpallaTableValue');
    const profonditaSupTableValue = getValues('profonditaSupTableValue');
    const profonditaInfTableValue = getValues('profonditaInfTableValue');
    const profonditaAppTableValue = getValues('profonditaAppTableValue');
    const vieAttaccoSpallaTableValue = getValues('vieAttaccoSpallaTableValue');
    const impostaFondSpallaTableValue = getValues(
      'impostaFondSpallaTableValue'
    );
    const tipoFondSpallaTableValue = getValues('tipoFondSpallaTableValue');
    const superficieSpallaTableValue = getValues('superficieSpallaTableValue');

    const ids = selected.map(el => el.codiceSpalla);

    const spalle = [...spalleFields];

    spalle.map((x, index) => {
      if (ids.includes(x?.codiceSpalla)) {
        spalle[index] = {
          // id: x.id,
          // completamentoSchedaDifetti: x.completamentoSchedaDifetti,
          // g5totali: x.g5totali,
          // nrtotali: x.nrtotali,
          // pstotali: x.pstotali,
          // codiceSpalla: x.codiceSpalla,
          // mediaSpalla: x.mediaSpalla,
          ...x,
          tipologiaSpalla: tipologiaSpallaTableValue,
          larghezzaSpalla: larghezzaSpallaTableValue,
          altezzaSpalla: altezzaSpallaTableValue,
          profonditaSuperioreSpalla: profonditaSupTableValue,
          profonditaInferioreSpalla: profonditaInfTableValue,
          profonditaAppoggioSpalla: profonditaAppTableValue,
          superficieSpalla: superficieSpallaTableValue,
          vieAttaccoSpalla: vieAttaccoSpallaTableValue,
          impostaFondSpalla: impostaFondSpallaTableValue,
          tipoFondSpalla: tipoFondSpallaTableValue,
          ...(tipologiaSpallaTableValue !== x.tipologiaSpalla ||
          impostaFondSpallaTableValue !== x.impostaFondSpalla
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'spalla',
                  tipologiaSpallaTableValue,
                  impostaFondSpallaTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue('spalleTable', spalle);
    onResetSpalle();
  };

  const onConfirmChangeSpalle = () => {
    setOpenConfirmationDialog(false);
    changeSpalle();
  };

  const onSubmitSpalle = () => {
    const tipologiaSpallaTableValue = getValues('tipologiaSpallaTableValue');
    const impostaFondSpallaTableValue = getValues(
      'impostaFondSpallaTableValue'
    );

    const ids = selected.map(el => el.codiceSpalla);

    const spalle = [...spalleFields];

    const hasChangedTipologiaOrImpostaFond = spalle.some(el => {
      if (ids.includes(el?.codiceSpalla)) {
        return (
          (tipologiaSpallaTableValue !== el.tipologiaSpalla ||
            impostaFondSpallaTableValue !== el.impostaFondSpalla) &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaSpalla) &&
          !isNullOrUndefinedOrEmptyString(el.impostaFondSpalla)
        );
      }
    });

    if (hasChangedTipologiaOrImpostaFond) {
      setOpenConfirmationDialog(true);
    } else {
      changeSpalle();
    }
  };

  const onResetSpalle = () => {
    setValue('spalleTableValue', []);
    setValue('tipologiaSpallaTableValue', '');
    setValue('larghezzaSpallaTableValue', '');
    setValue('altezzaSpallaTableValue', '');
    setValue('profonditaSupTableValue', '');
    setValue('profonditaInfTableValue', '');
    setValue('profonditaAppTableValue', '');
    setValue('superficieSpallaTableValue', '');
    setValue('vieAttaccoSpallaTableValue', '');
    setValue('impostaFondSpallaTableValue', '');
    setValue('tipoFondSpallaTableValue', '');
    setSelected([]);
  };

  // {(larghezzaSpallaTableValue*altezzaSpallaTableValue)+[(profonditaSupTableValue+profonditaInfTableValue)*altezzaSpallaTableValue]}/10000
  const altezzaSpallaWatch = parseFloat(watch('altezzaSpallaTableValue'));
  const profonditaSupSpallaWatch = parseFloat(watch('profonditaSupTableValue'));
  const profonditaInfSpallaWatch = parseFloat(watch('profonditaInfTableValue'));
  const larghezzaSpallaWatch = parseFloat(watch('larghezzaSpallaTableValue'));
  useEffect(() => {
    if (
      larghezzaSpallaWatch &&
      altezzaSpallaWatch &&
      profonditaSupSpallaWatch &&
      profonditaInfSpallaWatch &&
      altezzaSpallaWatch
    ) {
      const result =
        (larghezzaSpallaWatch * altezzaSpallaWatch +
          (profonditaSupSpallaWatch + profonditaInfSpallaWatch) *
            altezzaSpallaWatch) /
        10000;
      setValue('superficieSpallaTableValue', result);
    } else {
      setValue('superficieSpallaTableValue', '');
    }
  }, [
    altezzaSpallaWatch,
    larghezzaSpallaWatch,
    profonditaSupSpallaWatch,
    profonditaInfSpallaWatch,
  ]);

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={3} infoMessage={INFO.spalleSelezionate.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-spalle"
            name={'spalleTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codiceSpalla'}
            getOptionLabel={option => option.codiceSpalla}
            opt={spalleFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Spalle selezionate"
            disabled={inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipologiaSpallaTableValue"
            label="Tipologia Spalla*"
            defaultValue={''}
            onChange={e => {
              methods.setValue('tipologiaSpallaTableValue', e.target.value);
            }}
            selectOptions={tipologiaSpallaOptions}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="larghezzaSpallaTableValue"
            label={'Larghezza (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="altezzaSpallaTableValue"
            label={'Altezza (H) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="profonditaSupTableValue"
            label={'Profondità superiore (Ps) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="profonditaInfTableValue"
            label={'Profondità inferiore (Pi) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="profonditaAppTableValue"
            label={'Profondità appoggio (Pa) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <InfoWrapper size={3} infoMessage={INFO.superficieSpalle.infoMessage}>
          <RHFNumberTextField
            name="superficieSpallaTableValue"
            label={'Superficie [m²]'}
            // InputLabelProps={{ shrink: true }}
            defaultValue={0}
            disabled
            decimalScale={2}
            withValueLimit={({ value }) => value < 500}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="vieAttaccoSpallaTableValue"
            label="Classificazione delle vie di attacco"
            defaultValue={''}
            onChange={e => {
              methods.setValue('vieAttaccoSpallaTableValue', e.target.value);
            }}
            selectOptions={vieAttaccoSpallaOptions}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="impostaFondSpallaTableValue"
            label="Imposta fondazioni spalla*"
            defaultValue={''}
            onChange={e => {
              methods.setValue('impostaFondSpallaTableValue', e.target.value);
            }}
            selectOptions={impostaFondSpallaOptions}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipoFondSpallaTableValue"
            label="Tipologia fondazioni spalla"
            defaultValue={''}
            onChange={e => {
              methods.setValue('tipoFondSpallaTableValue', e.target.value);
            }}
            selectOptions={tipoFondSpallaOptions}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3} alignSelf="center">
          <FormSubmitButtons
            disabled={inspectionDisabled || isViewingInspection}
            onSubmit={onSubmitSpalle}
            onReset={onResetSpalle}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeSpalle}
      />
    </>
  );
};
SpalleForm.propTypes = {
  spalleFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaSpalla: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default SpalleForm;
