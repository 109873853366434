import iconL0 from 'assets/icons/levels/iconL0.png';
import iconL1 from 'assets/icons/levels/iconL1.png';
import iconL2 from 'assets/icons/levels/iconL2.png';
import iconL3 from 'assets/icons/levels/iconL3.png';
import iconL4 from 'assets/icons/levels/iconL4.png';
import iconL5 from 'assets/icons/levels/iconL5.png';
import { AccordionCustom } from 'components';
import { tour_06, tour_06a } from 'constants/tour';
import { AccordionDataGeneralL0 } from 'pages/Inspections/CreateInspection/L0/GeneralData/config/generalData';
import { AccordionDataServicesL0 } from 'pages/Inspections/CreateInspection/L0/Services/config/services';
import { AccordionDataStructureL0 } from 'pages/Inspections/CreateInspection/L0/Structures/config/structure';
import { AccordionDataSuperstructureL0 } from 'pages/Inspections/CreateInspection/L0/SuperStructures/config/superstructure';
import { AccordionDataGeneralL1 } from 'pages/Inspections/CreateInspection/L1/GeneralData/config/generalData';
import { AccordionDataServicesL1 } from 'pages/Inspections/CreateInspection/L1/Services/config/services';
import { AccordionDataStructureL1 } from 'pages/Inspections/CreateInspection/L1/Structures/config/structures';
import { AccordionDataSuperstructureL1 } from 'pages/Inspections/CreateInspection/L1/SuperStructures/config/superstructure';
import L2Cda from 'pages/Inspections/CreateInspection/L2/L2Cda';
import { TabL0 } from 'pages/Structures/StructureDetail/Summary/TabL0/TabL0';
import { TabL1 } from 'pages/Structures/StructureDetail/Summary/TabL1/TabL1';
import { TabOverview } from 'pages/Structures/StructureDetail/Summary/TabOverview/TabOverview';
import { TabCreateInspection } from './TabCreateInspection';

export const avatar = [
  {
    initial: 'AB',
    class: 'orange',
  },
  {
    initial: 'BC',
    class: 'green',
  },
  {
    initial: 'CD',
    class: 'red',
  },
  {
    initial: 'MB',
    class: 'white',
  },
  {
    initial: 'MB',
    class: 'white',
  },
  {
    initial: 'MB',
    class: 'white',
  },
  {
    initial: 'MB',
    class: 'white',
  },
  {
    initial: 'MB',
    class: 'white',
  },
];

export const contextAccordionTabsL0 = [
  {
    label: 'Contesto dati generali',
    content: (
      <AccordionCustom checkExpanded accordions={AccordionDataGeneralL0} />
    ),
    value: 0,
  },
  {
    label: 'Struttura',
    content: (
      <AccordionCustom checkExpanded accordions={AccordionDataStructureL0} />
    ),
    value: 1,
  },
  {
    label: 'Elementi accessori',
    content: (
      <AccordionCustom
        accordions={AccordionDataSuperstructureL0}
        checkExpanded
      />
    ),
    value: 2,
  },
  {
    label: 'Servizi',
    content: (
      <AccordionCustom checkExpanded accordions={AccordionDataServicesL0} />
    ),
    value: 3,
  },
  {
    label: 'Riepilogo',
    content: <TabL0 />,
    value: 4,
  },
];

export const contextAccordionTabsL1 = [
  {
    label: 'Contesto dati generali',
    content: (
      <AccordionCustom checkExpanded accordions={AccordionDataGeneralL1} />
    ),
    value: 0,
  },
  {
    label: 'Struttura',
    content: (
      <AccordionCustom checkExpanded accordions={AccordionDataStructureL1} />
    ),
    value: 1,
  },
  {
    label: 'Elementi accessori',
    content: (
      <AccordionCustom
        accordions={AccordionDataSuperstructureL1}
        checkExpanded
      />
    ),
    value: 2,
  },
  {
    label: 'Servizi',
    content: (
      <AccordionCustom checkExpanded accordions={AccordionDataServicesL1} />
    ),
    value: 3,
  },
  {
    label: 'Riepilogo',
    content: <TabL1 />,
    value: 4,
  },
];

export const contextAccordionTabsOthers = [
  {
    label: 'Contesto dati generali',
    content: <p></p>,
    value: 0,
  },
  {
    label: 'Struttura',
    content: <p></p>,
    value: 1,
  },
  {
    label: 'Sovrastruttura',
    content: <p></p>,
    value: 2,
  },
  {
    label: 'Servizi',
    content: <p></p>,
    value: 3,
  },
  {
    label: 'Riepilogo',
    content: <p></p>,
    value: 4,
  },
];

export const newInspectionTabs = [
  {
    level: '',
    label: 'Overview',
    content: <TabOverview />,
    disabled: false,
    value: 0,
  },
  {
    level: 'L0',
    label: 'Censimento e anagrafica',
    icon: <img src={iconL0} alt="L0" />,
    iconPosition: 'start',
    content: (
      <TabCreateInspection
        className={`${tour_06.step_04} ${tour_06a.step_01}`}
        tabs={contextAccordionTabsL0}
      />
    ),
    disabled: false,
    value: 1,
  },
  {
    level: 'L1',
    label: 'Ispezioni',
    content: (
      <TabCreateInspection
        className={`${tour_06.step_04} ${tour_06a.step_01}`}
        tabs={contextAccordionTabsL1}
      />
    ),
    icon: <img src={iconL1} alt="L1" />,
    iconPosition: 'start',
    disabled: false,
    value: 2,
  },
  {
    level: 'L2',
    label: 'Classi di attenzione',
    icon: <img src={iconL2} alt="L2" />,
    iconPosition: 'start',
    content: <L2Cda />,
    disabled: false,
    value: 3,
  },
  {
    level: 'L3',
    label: 'Valutazione preliminare',
    icon: <img src={iconL3} alt="L3" />,
    iconPosition: 'start',
    content: <TabCreateInspection tabs={contextAccordionTabsOthers} />,
    disabled: true,
    value: 4,
  },
  {
    level: 'L4',
    label: 'Verifica accurata',
    icon: <img src={iconL4} alt="L4" />,
    iconPosition: 'start',
    content: <TabCreateInspection tabs={contextAccordionTabsOthers} />,
    disabled: true,
    value: 5,
  },
  {
    level: 'L5',
    label: 'Sorveglianza e monitoraggio',
    icon: <img src={iconL5} alt="L5" />,
    iconPosition: 'start',
    content: <TabCreateInspection tabs={contextAccordionTabsOthers} />,
    disabled: true,
    value: 6,
  },
];

export const getOverviewTab = ({
  graphRef,
  graphRef2,
  structure,
  userPermissionOnStructure,
  openDrawerNewInspection,
  setOpenDrawerNewInspection,
}) => [
  {
    level: '',
    label: 'Overview',
    content: (
      <TabOverview
        graphRef={graphRef}
        graphRef2={graphRef2}
        structure={structure}
        userPermissionOnStructure={userPermissionOnStructure}
        openDrawerNewInspection={openDrawerNewInspection}
        setOpenDrawerNewInspection={setOpenDrawerNewInspection}
      />
    ),
    disabled: false,
    value: 0,
  },
  {
    level: 'L0',
    label: 'Riepilogo censimento e anagrafica',
    icon: <img src={iconL0} alt="L0" />,
    iconPosition: 'start',
    content: (
      <TabL0
        structure={structure}
        userPermissionOnStructure={userPermissionOnStructure}
        openDrawerNewInspection={openDrawerNewInspection}
        setOpenDrawerNewInspection={setOpenDrawerNewInspection}
      />
    ),
    // content: <TabCreateInspection tabs={contextAccordionTabsL0} />,
    disabled: false,
    value: 1,
  },
  {
    level: 'L1',
    label: 'Riepilogo ispezioni',
    icon: <img src={iconL1} alt="L1" />,
    iconPosition: 'start',
    // content: <TabCreateInspection tabs={contextAccordionTabsL1} />,
    disabled: false,
    value: 2,
    content: (
      <TabL1
        structure={structure}
        userPermissionOnStructure={userPermissionOnStructure}
        openDrawerNewInspection={openDrawerNewInspection}
        setOpenDrawerNewInspection={setOpenDrawerNewInspection}
      />
    ),
  },
  {
    level: 'L2',
    label: 'Riepilogo classi di attenzione',
    icon: <img src={iconL2} alt="L2" />,
    iconPosition: 'start',
    content: (
      <L2Cda
        structure={structure}
        userPermissionOnStructure={userPermissionOnStructure}
        openDrawerNewInspection={openDrawerNewInspection}
        setOpenDrawerNewInspection={setOpenDrawerNewInspection}
      />
    ),
    disabled: false,
    value: 3,
  },
  {
    level: 'L3',
    label: 'Riepilogo valutazione preliminare',
    icon: <img src={iconL3} alt="L3" />,
    iconPosition: 'start',
    // content: <TabCreateInspection tabs={contextAccordionTabsOthers} />,
    disabled: true,
    value: 4,
    content: <p></p>,
  },
  {
    level: 'L4',
    label: 'Riepilogo verifica accurata',
    icon: <img src={iconL4} alt="L4" />,
    iconPosition: 'start',
    // content: <TabCreateInspection tabs={contextAccordionTabsOthers} />,
    disabled: true,
    value: 5,
    content: <p></p>,
  },
  {
    level: 'L5',
    label: 'Riepilogo sorveglianza e monitoraggio',
    icon: <img src={iconL5} alt="L5" />,
    iconPosition: 'start',
    // content: <TabCreateInspection tabs={contextAccordionTabsOthers} />,
    disabled: true,
    value: 6,
    content: <p></p>,
  },
];
