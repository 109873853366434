import { nanoid } from 'nanoid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useInspectionSelector } from 'stores';
// eslint-disable-next-line
function useCopy({ tableName, type }) {
  const { getValues, setValue } = useFormContext();

  const { setSchedaDifettiTrigger } = useInspectionSelector();

  const [copyDrawerOpen, setCopyDrawerOpen] = useState(false);
  const [selectedPaste, setSelectedPaste] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSelectAllPaste = event => {
    if (event.target.checked) {
      const selectedValue = getValues(tableName).filter(el =>
        checkCompatibility(el)
      );
      setSelectedPaste(selectedValue);
    } else {
      setSelectedPaste([]);
    }
  };

  const checkCompatibility = row => {
    return (
      row.codice != selectedCopy.codice &&
      //row[type] == selectedCopy[type] &&
      row.presenza == selectedCopy.presenza
    );
  };

  const handleCopy = () => {
    getValues(tableName).forEach((x, idx) => {
      if (selectedPaste.some(p => p.codice == x.codice)) {
        const tmp = {
          /* ...x, */
          /* tecnicoRilevatore: selectedCopy.tecnicoRilevatore,
          dataIspezione: selectedCopy.dataIspezione,
          elementoCriticoStruttura: selectedCopy.elementoCriticoStruttura,
          elementoCriticoSismica: selectedCopy.elementoCriticoSismica,
          condizioneCriticaStruttura: selectedCopy.condizioneCriticaStruttura,
          condizioneCriticaSismica: selectedCopy.condizioneCriticaSismica,
          noteGenerali: selectedCopy.noteGenerali, */
          ...x,
          nrtotali: selectedCopy.nrtotali || null,
          noteGenerali: selectedCopy.noteGenerali || null,
          dataIspezione: selectedCopy.dataIspezione || null,
          tecnicoRilevatore: selectedCopy.tecnicoRilevatore || null,
          completamentoSchedaDifetti:
            selectedCopy.completamentoSchedaDifetti || null,
          id: x.id,
          codice: x.codice,
          schedaDifetti: selectedCopy.schedaDifetti.map(el => ({
            ...el,
            media: [],
          })),
        };
        setValue(`${tableName}[${idx}]`, tmp);
      }
    });
    setCopyDrawerOpen(false);
    setSchedaDifettiTrigger(nanoid());
    return enqueueSnackbar('Elemento copiato correttamente', {
      variant: 'success',
    });
  };

  return {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedPaste,
    setSelectedPaste,
    selectedCopy,
    setSelectedCopy,
    handleSelectAllPaste,
    checkCompatibility,
    handleCopy,
  };
}
export default useCopy;
