import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormSubmitButtons, GridWrap, RHFMultiSelect } from 'components';
import { tipologiaPaliOptions } from 'constants/selectOptions';

const PaliForm = ({ paliFields, selected, setSelected, paliTable }) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      paliTableValue: [],
      tipologiaPaliTableValue: '',
    },
  });

  useEffect(() => {
    setValue('paliTableValue', selected);
  }, [selected]);

  const onSubmitPali = () => {
    const tipologiaPaliTableValue = getValues('tipologiaPaliTableValue');

    const ids = selected.map(el => el.codice);

    const pali = [...paliFields];

    pali.map((x, index) => {
      if (ids.includes(x?.codice)) {
        pali[index] = {
          ...x,
          tipologiaPali: tipologiaPaliTableValue,
        };
      }
    });

    setValue(paliTable, pali);
  };

  const onResetPali = () => {
    setValue('paliTableValue', []);
    setValue('tipologiaPaliTableValue', '');
    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <GridWrap item xs={3}>
        <RHFMultiSelect
          name="tipologiaPaliTableValue"
          label="Tipologia pali"
          defaultValue={[]}
          onChange={e => {
            setValue('tipologiaPaliTableValue', e.target.value);
          }}
          selectOptions={tipologiaPaliOptions}
        />
      </GridWrap>
      <GridWrap item xs={9} alignSelf="center">
        <FormSubmitButtons onSubmit={onSubmitPali} onReset={onResetPali} />
      </GridWrap>
    </GridWrap>
  );
};
PaliForm.propTypes = {
  paliFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaPali: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  paliTable: PropTypes.string,
};

export default PaliForm;
