import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { PositionContainer } from './PositionHeaderPonte.style';

export const PositionHeaderPonte = ({ image, title }) => {
  return (
    <PositionContainer>
      <img src={image} alt="" />
      <Typography variant="body">{title}</Typography>
    </PositionContainer>
  );
};

PositionHeaderPonte.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
};
