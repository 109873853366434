import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  InfoWrapper,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import { INFO } from 'pages/Inspections/config/info';
import { GridWrap } from './AppoggiForm.styles';
const DispositiviSismiciForm = ({
  dispositiviSismiciFields,
  selected,
  setSelected,
  dispositiviSismiciTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      l0t2a4_dispositiviSismiciTableValue: [],
      l0t2a4_nomeDispositivoSismicoTableValue: '',
    },
  });

  useEffect(() => {
    setValue('l0t2a4_dispositiviSismiciTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetDispositiviSismici();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitDispositiviSismici = () => {
    const l0t2a4_nomeDispositivoSismicoTableValue = getValues(
      'l0t2a4_nomeDispositivoSismicoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const dispositiviSismici = [...dispositiviSismiciFields];

    dispositiviSismici.map((x, index) => {
      if (ids.includes(x?.codice)) {
        dispositiviSismici[index] = {
          id: x.id,
          codice: x.codice,
          nomeDispositivoSismico: l0t2a4_nomeDispositivoSismicoTableValue,
        };
      }
    });

    setValue(dispositiviSismiciTable, dispositiviSismici);
    onResetDispositiviSismici();
  };

  const onResetDispositiviSismici = () => {
    setValue('l0t2a4_dispositiviSismiciTableValue', []);
    setValue('l0t2a4_nomeDispositivoSismicoTableValue', '');

    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper
        size={6}
        infoMessage={INFO.dispositiviSismiciSelezionati.infoMessage}
      >
        <RHFTagAutocomplete
          multiple={true}
          id="tags-dispositiviSismici"
          name={'l0t2a4_dispositiviSismiciTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={dispositiviSismiciFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Altri dispositivi selezionati"
        />
      </InfoWrapper>

      <GridWrap item xs={3}>
        <RHFTextField
          name="l0t2a4_nomeDispositivoSismicoTableValue"
          label={'Nome elemento*'}
          defaultValue=""
          inputProps={{ maxLength: 50 }}
        />
      </GridWrap>

      <GridWrap item xs={3}>
        <FormSubmitButtons
          onSubmit={onSubmitDispositiviSismici}
          onReset={onResetDispositiviSismici}
        />
      </GridWrap>
    </GridWrap>
  );
};

DispositiviSismiciForm.propTypes = {
  dispositiviSismiciFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      nomeDispositivoSismico: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  dispositiviSismiciTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default DispositiviSismiciForm;
