export const markdown_faq_36_1 = `
**AINOP** è l'Archivio Informatico Nazionale delle Opere Pubbliche, ovvero il **portale ministeriale** che raccoglie le informazioni sulle opere. L'Ente gestore deve **trasmettere** qui alcuni dati dei suoi Ponti e Viadotti. 
In caso di **sottoscrizione**, **Inbee** può **velocizzare** molto il processo di inserimento dati su AINOP generando i file richiesti per il caricamento dei dati. Il trasferimento dei dati avviene in **3 step**.`;

export const markdown_faq_36_2_1 = `**1. TRASFERIMENTO DATI ANAGRAFICA BASE**
`;

export const markdown_faq_36_2_2 = `
È possibile generare il **csv** comprensivo di tutti i dati richiesti e nel **formato compatibile con AINOP** . Il file viene generato a partire dal pannello di esportazione selezionando la tipologia csv Anagrafica Base nella sezione Schede AINOP. Il file può essere generato per una **singola operea**, o per **più opere** selezionando *Esporta Elenco ponti* dalla sezione Ponti. Una volta generato il file da INBEE, l'Ente, accedendo alla sua area personale nel portale AINOP, potrà caricare il file al posto di dover inserire manualmente tutti i dati.`;

export const markdown_faq_36_3_1 = `**2. CODICE IOP**
`;

export const markdown_faq_36_3_2 = `
AINOP restituirà, per ogni ponte, un **codice IOP**, Codice Identificativo dell'Opera Pubblica. Questo codice dovrà essere inserito nell'attività del ponte in corrispondenza del relativo campo posto in _L0>Contesto dati generali>Informazioni generali_.
`;

export const markdown_faq_36_4_1 = `**3. TRASFERIMENTO DATI ANAGRAFICA SPECIFICA PONTI E VIADOTTI**
`;

export const markdown_faq_36_4_2 = `
Similmente a quanto visto per i dati di anagrafica base, è possibile generare il **csv** comprensivo di tutti i dati richiesti e nel **formato compatibile con AINOP**. Il file viene generato a partire dal pannello di esportazione selezionando la tipologia csv Ponti e viadotti stradali e nella sezione Schede AINOP. Il file può essere generato per una **singola opera**, o per **più opere** selezionando *Esporta Elenco ponti* dalla sezione Ponti. Una volta generato il file da INBEE, l'Ente, accedendo alla sua area personale nel portale AINOP, potrà caricare il file al posto di dover inserire manualmente tutti i dati.
`;

export const markdown_faq_36_5 = `A questo punto, avrai trasmesso in modo semplice e veloce tutti i dati richiesti ad AINOP!
`;
