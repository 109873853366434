import TreeView from '@mui/lab/TreeView';
import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFCheckbox } from 'components';
import {
  DotSeparator,
  PlusMinusIconContainer,
  TreeItemCustom,
} from './TreeViewCustom.styles';

const TreeViewCustom = ({ data, handleChildrenChecked }) => {
  const { watch, setValue, getValues } = useFormContext();

  const watchTrigger = watch('tree-trigger');

  function MinusSquare(props) {
    return (
      <PlusMinusIconContainer>
        <SvgIcon
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
        <DotSeparator />
      </PlusMinusIconContainer>
    );
  }

  function PlusSquare(props) {
    return (
      <PlusMinusIconContainer>
        <SvgIcon
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
        <DotSeparator />
      </PlusMinusIconContainer>
    );
  }

  const checkIndeterminate = node => {
    // make this recursive
    const parentData = data.filter(el => el?.parentId === node.parentId);

    if (parentData.length === 0) return;

    const isIndeterminate = parentData.some(el => {
      return (
        getValues(`check-${el.id}`) ||
        (!el.inspectionId && getValues(`indeterminate-${el.id}`))
      );
    });

    const isAllChecked = parentData.every(el => {
      return getValues(`check-${el.id}`);
    });
    if (isIndeterminate && !isAllChecked) {
      setValue(`indeterminate-${node.parentId}`, true);
      setValue(`check-${node.parentId}`, false);
    } else if (isAllChecked) {
      setValue(`indeterminate-${node.parentId}`, false);
      setValue(`check-${node.parentId}`, true);
    } else {
      setValue(`indeterminate-${node.parentId}`, false);
      setValue(`check-${node.parentId}`, false);
    }
    if (node.parentId) {
      checkIndeterminate(data.find(el => el.id === node.parentId));
    }
  };

  useEffect(() => {
    data.forEach(node => checkIndeterminate(node));
  }, [watchTrigger]);

  const renderTree = (parentId = '0') => {
    return data
      .filter(d => d.id === parentId)
      .map((node, idx) => {
        const nodeChildren = data.filter(d => d.parentId === node.id);

        return (
          <TreeItemCustom
            key={idx}
            nodeId={node.id}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {node.inspectionId !== null && (
                  <DotSeparator hasInspectionId={node.inspectionId !== null} />
                )}
                <RHFCheckbox
                  sx={{
                    marginLeft: node.inspectionId !== null ? '-20px' : '-8px',
                    marginBottom: '8px',
                  }}
                  color={'default'}
                  id={`check-${node.id}`}
                  name={`check-${node.id}`}
                  /* defaultValue={node.id === '0' ? true : false} */
                  defaultValue={false}
                  label={node.label}
                  isTreeViewParent={node.inspectionId === null}
                  isTreeView
                  indeterminate={
                    node.inspectionId === null &&
                    watch(`indeterminate-${node.id}`)
                  }
                  /* onInput={e => checkIndeterminate(node.parentId)} */
                  onClick={e => {
                    e.stopPropagation();
                    handleChildrenChecked(node.id, e.target.checked);
                  }}
                />
              </div>
            }
          >
            {nodeChildren.length
              ? nodeChildren.map(node => renderTree(node.id))
              : null}
          </TreeItemCustom>
        );
      });
  };

  return (
    <>
      <TreeView
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultExpanded={['0']}
      >
        {renderTree()}
      </TreeView>
    </>
  );
};

export { TreeViewCustom };

TreeViewCustom.propTypes = {
  /* data: PropTypes.object, */
  data: PropTypes.array,
  handleChildrenChecked: PropTypes.func,
  /* indeterminate: PropTypes.bool, */
};
