import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import { colors } from 'constants/colors';
// import { CustomFormControlLabel } from './Checkbox.styles';

const RHFRadioGroup = ({ name, rules, defaultValue, ...other }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl
            error={!!error}
            {...other}
            {...field}
            helperText={error?.message}
          >
            <FormLabel id="demo-radio-buttons-group-label">
              Categoria*
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="type"
            >
              <div
                style={{
                  display: 'flex',
                  color: colors.DARKGREY,
                }}
              >
                <FormControlLabel
                  value="ENTE"
                  control={<Radio />}
                  label="Ente / Gestore"
                />
                <FormControlLabel
                  value="PROFESSIONISTA"
                  control={<Radio />}
                  label="Ispettore"
                />
              </div>
            </RadioGroup>
          </FormControl>
        );
      }}
    />
  );
};

RHFRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};
export { RHFRadioGroup };

{
  /* <FormControl error={errors[name]} disabled={disabled}>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => {
              return (
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Gender
                  </FormLabel>
                  <RadioGroup
                    row
                    {...field}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    {children}
                  </RadioGroup>
                </FormControl>
              );
            }}
          />
        }
        {...other}
      />
      {errors[name] && <FormHelperText>{errors[name].message}</FormHelperText>}
    </FormControl> */
}
