import styled from '@emotion/styled';
import { Avatar } from '@mui/material';

export const HeaderContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const HeaderLine = styled('div')({
  width: '100%',
  display: 'flex',
});

export const AvatarPlus = styled(Avatar)({
  cursor: 'pointer',
});

export const HeaderLineItem = styled('div')({
  width: '50%',
  height: 50,
  display: 'flex',
  alignItems: 'center',
  padding: '30px 0px',
  '&:first-of-type': {
    justifyContent: 'flex-start',
  },
  '&:last-of-type': {
    justifyContent: 'flex-end',
    '& span': {
      marginRight: '10px',
    },
  },
  '& .MuiButton-root': {
    marginRight: '5px',
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  '& .MuiAvatar-root': {
    marginRight: 5,
  },
});
