import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { CardOverview, MaterialBox } from 'components';
import { STRUCTURES_PATHS } from 'constants/paths';
import {
  appoggiAntisismiciAppoggiOption,
  tipologiaAntennaOptions,
  tipologiaAppoggioOptions,
  tipologiaArcoOptions,
  tipologiaControventoOptions,
  tipologiaElementoExtraOptions,
  tipologiaGiuntoOptions,
  tipologiaPiedrittoOptions,
  tipologiaPulvinoOptions,
  tipologiaSolettaOptions,
  tipologiaSpallaOptions,
  tipologiaTiranteOptions,
  tipologiaTraveOptions,
  tipologiaTraversoOptions,
  tipoPilaSelectOptions,
} from 'constants/selectOptions';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import { handleDynamicInspectionsTabChange } from 'utils/utilities';
import { Row } from '../TabL0.styles';

const Struttura = ({ overviewL0 }) => {
  const params = useParams();
  const id = params.structureId;
  const navigate = useNavigate();

  const lastInspectionId = overviewL0?.requestUserLastValidInspectionId;
  // Elevazione
  const spalle = overviewL0?.elevazione?.spalle;
  const pile = overviewL0?.elevazione?.pile;

  // Collegamenti
  const appoggio = overviewL0?.collegamenti?.appoggi;
  const giunto = overviewL0?.collegamenti?.giunti;
  const pulvino = overviewL0?.collegamenti?.pulvini;
  const antenna = overviewL0?.collegamenti?.antenne;
  const antisismico = overviewL0?.collegamenti?.antisismici;

  // Impalcato
  const soletta = overviewL0?.impalcato?.soletta;
  const trave = overviewL0?.impalcato?.travi;
  const traversi = overviewL0?.impalcato?.traversi;
  const controvento = overviewL0?.impalcato?.controventi;
  const arco = overviewL0?.impalcato?.archi;
  const piedritto = overviewL0?.impalcato?.piedritti;
  const tirante = overviewL0?.impalcato?.tiranti;
  const elementoExtra = overviewL0?.impalcato?.elementiExtra;

  return (
    <CardOverview
      title={'Struttura'}
      subtitle={`Caratteristiche tipologiche di materiali presenti`}
      buttonText={'Approfondisci'}
      buttonDisabled={lastInspectionId ? false : true}
      height="auto"
      onClick={() =>
        handleDynamicInspectionsTabChange(
          inspectionTabMapping.L0,
          inspectionTabMapping.Struttura,
          null,
          navigate,
          STRUCTURES_PATHS.STRUCTURES_LIST,
          id,
          lastInspectionId
        )
      }
    >
      <CardOverview
        title={'Elevazione'}
        buttonText={'Approfondisci'}
        buttonDisabled={lastInspectionId ? false : true}
        height="auto"
        subCard={true}
        onClick={() =>
          handleDynamicInspectionsTabChange(
            inspectionTabMapping.L0,
            inspectionTabMapping.Struttura,
            inspectionTabMapping.L0Elevazione,
            navigate,
            STRUCTURES_PATHS.STRUCTURES_LIST,
            id,
            lastInspectionId
          )
        }
      >
        <Row>
          <MaterialBox
            /* dataRecap={overviewL0} */
            topic={'Spalla'}
            materialsData={spalle}
            structureSection={true}
            enumOptions={tipologiaSpallaOptions}
          />
          <MaterialBox
            /* dataRecap={overviewL0} */
            topic={'Pila'}
            materialsData={pile}
            structureSection={true}
            enumOptions={tipoPilaSelectOptions}
          />
        </Row>
      </CardOverview>

      <CardOverview
        title={'Collegamenti'}
        buttonText={'Approfondisci'}
        buttonDisabled={lastInspectionId ? false : true}
        height="auto"
        subCard={true}
        onClick={() =>
          handleDynamicInspectionsTabChange(
            inspectionTabMapping.L0,
            inspectionTabMapping.Struttura,
            inspectionTabMapping.L0Collegamenti,
            navigate,
            STRUCTURES_PATHS.STRUCTURES_LIST,
            id,
            lastInspectionId
          )
        }
      >
        <Row style={{ marginTop: '8px' }}>
          <MaterialBox
            /* dataRecap={overviewL0} */
            topic={'Appoggio'}
            materialsData={appoggio}
            structureSection={true}
            enumOptions={tipologiaAppoggioOptions}
          />
          <MaterialBox
            /* dataRecap={overviewL0} */
            topic={'Giunto'}
            materialsData={giunto}
            structureSection={true}
            enumOptions={tipologiaGiuntoOptions}
          />
          <MaterialBox
            /* dataRecap={overviewL0} */
            topic={'Pulvino'}
            materialsData={pulvino}
            structureSection={true}
            enumOptions={tipologiaPulvinoOptions}
          />
          <MaterialBox
            /* dataRecap={overviewL0} */
            topic={'Antenna'}
            materialsData={antenna}
            structureSection={true}
            enumOptions={tipologiaAntennaOptions}
          />
          <MaterialBox
            /* dataRecap={overviewL0} */
            topic={'Antisismico'}
            materialsData={antisismico}
            structureSection={true}
            enumOptions={appoggiAntisismiciAppoggiOption}
          />
        </Row>
      </CardOverview>

      <CardOverview
        title={'Impalcato'}
        buttonText={'Approfondisci'}
        buttonDisabled={lastInspectionId ? false : true}
        height="auto"
        subCard={true}
        onClick={() =>
          handleDynamicInspectionsTabChange(
            inspectionTabMapping.L0,
            inspectionTabMapping.Struttura,
            inspectionTabMapping.L0Impalcato,
            navigate,
            STRUCTURES_PATHS.STRUCTURES_LIST,
            id,
            lastInspectionId
          )
        }
      >
        <Row style={{ marginTop: '8px', flexWrap: 'wrap' }}>
          <MaterialBox
            dataRecap={overviewL0}
            topic={'Soletta'}
            materialsData={soletta}
            structureSection={true}
            enumOptions={tipologiaSolettaOptions}
          />
          <MaterialBox
            dataRecap={overviewL0}
            topic={'Trave'}
            materialsData={trave}
            structureSection={true}
            enumOptions={tipologiaTraveOptions}
          />
          <MaterialBox
            dataRecap={overviewL0}
            topic={'Traverso'}
            materialsData={traversi}
            structureSection={true}
            enumOptions={tipologiaTraversoOptions}
          />
          <MaterialBox
            dataRecap={overviewL0}
            topic={'Controvento'}
            materialsData={controvento}
            structureSection={true}
            enumOptions={tipologiaControventoOptions}
          />
          <MaterialBox
            dataRecap={overviewL0}
            topic={'Arco'}
            materialsData={arco}
            structureSection={true}
            enumOptions={tipologiaArcoOptions}
          />
          <MaterialBox
            dataRecap={overviewL0}
            topic={'Piedritto'}
            materialsData={piedritto}
            structureSection={true}
            enumOptions={tipologiaPiedrittoOptions}
          />
          <MaterialBox
            dataRecap={overviewL0}
            topic={'Tirante'}
            materialsData={tirante}
            structureSection={true}
            enumOptions={tipologiaTiranteOptions}
          />
          <MaterialBox
            dataRecap={overviewL0}
            topic={'Elemento extra'}
            materialsData={elementoExtra}
            structureSection={true}
            enumOptions={tipologiaElementoExtraOptions}
          />
        </Row>
      </CardOverview>
    </CardOverview>
  );
};

export { Struttura };

Struttura.propTypes = {
  overviewL0: PropTypes.obj,
};
