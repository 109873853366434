import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaGuardaviaOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';

const GuardavieForm = ({
  guardavieFields,
  selected,
  setSelected,
  guardavieTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      guardavieTableValue: [],
      tipologiaGuardaviaTableValue: '',
    },
  });

  useEffect(() => {
    setValue('guardavieTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetGuardavie();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitGuardavie = () => {
    const tipologiaGuardaviaTableValue = getValues(
      'tipologiaGuardaviaTableValue'
    );

    const ids = selected.map(el => el.codice);

    const guardavie = [...guardavieFields];

    guardavie.map((x, index) => {
      if (ids.includes(x?.codice)) {
        guardavie[index] = {
          ...x,
          tipologiaGuardavia: tipologiaGuardaviaTableValue,
        };
      }
    });

    setValue(guardavieTable, guardavie);
    onResetGuardavie();
  };

  const onResetGuardavie = () => {
    setValue('guardavieTableValue', []);
    setValue('tipologiaGuardaviaTableValue', '');
    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper size={3} infoMessage={INFO.guardavieSelezionati.infoMessage}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-guardavie"
          name={'guardavieTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={guardavieFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Guardavie selezionati"
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="tipologiaGuardaviaTableValue"
          label="Tipologia Guardavia*"
          defaultValue={''}
          onChange={e => {
            setValue('tipologiaGuardaviaTableValue', e.target.value);
          }}
          selectOptions={tipologiaGuardaviaOptions}
        />
      </GridWrap>
      <GridWrap item xs={6} alignSelf="center">
        <FormSubmitButtons
          onSubmit={onSubmitGuardavie}
          onReset={onResetGuardavie}
        />
      </GridWrap>
    </GridWrap>
  );
};
GuardavieForm.propTypes = {
  guardavieFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaGuardavia: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  guardavieTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default GuardavieForm;
