import { Clear, Search } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { RHFSelect, RHFTextField } from 'components';
import { tour_02 } from 'constants/tour';
import { FormProvider } from 'providers';
import { FilterContainer } from './AdminFIlter.styles';

export const AdminFilter = ({
  inputs,
  itemInLine,
  handleSearch,
  resetFilter,
  marginTop,
}) => {
  const defaultValues = {
    type: '',
    userTypology: '',
    displayName: '',
    email: '',
    status: '',
  };

  const methods = useForm({
    defaultValues,
  });

  const chooseInput = input => {
    if (input.filterType === 'TextField') {
      return (
        <RHFTextField size="small" label={input.label} name={input.name} />
      );
    }
    if (input.filterType === 'Select') {
      return (
        <RHFSelect
          size="small"
          name={input.name}
          selectOptions={input.options}
          label={input.label}
        />
      );
    }
  };

  const renderFirstLine = () => {
    return inputs.slice(0, itemInLine).map((el, index) => {
      return (
        <Grid
          item
          xs={2.2}
          /* md={allInputs ? 3 : numberInline[itemInLine].onlyFirstLine} */
          key={index}
        >
          {chooseInput(el)}
        </Grid>
      );
    });
  };

  const renderButton = () => {
    return (
      <>
        <IconButton color="primary" type="submit">
          <Search />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => {
            methods.reset(defaultValues);
            resetFilter();
          }}
        >
          <Clear />
        </IconButton>
      </>
    );
  };

  return (
    <FilterContainer marginTop={marginTop} className={tour_02.step_01}>
      <FormProvider
        methods={methods}
        onSubmit={methods.handleSubmit(handleSearch)}
      >
        <Grid container spacing={1}>
          {/* FirstLine */}
          {renderFirstLine()}
          {/* Button */}
          <Grid item xs={1}>
            {renderButton()}
          </Grid>
        </Grid>
      </FormProvider>
    </FilterContainer>
  );
};

AdminFilter.propTypes = {
  inputs: PropTypes.array,
  itemInLine: PropTypes.number,
  handleSearch: PropTypes.func,
  resetFilter: PropTypes.func,
  marginTop: PropTypes.string,
};
