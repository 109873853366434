import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, GridWrap, InfoWrapper, Table } from 'components';
import {
  FieldType,
  MediaGroup,
  SchemaPonteCommonTableValue,
  UploadMedia,
} from 'constants/inspections';
import { tipologiaCordoloOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import CordoliForm from './CordoliForm';
const CordoliSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const sovrastrutturaImpalcatoTableCordoliTableWatch = watch(
    `sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable`
  );
  // id: uuidv4(),
  // codice: `CO-${el.codice}.SX`,
  // tipologiaCordolo: '',
  // altezzaCordolo: '',
  // profonditaCordolo: '',
  // lunghezzaCordolo: '',

  // table setup
  const dataConfigCordoli = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaCordolo: {
        label: 'Tipologia cordolo',
        format: tipologiaCordoloOptions,
      },
      altezzaCordolo: {
        label: 'Altezza (H) [cm]',
      },
      profonditaCordolo: {
        label: 'Larghezza (La)[cm]',
      },
      lunghezzaCordolo: {
        label: 'Lunghezza [m]',
      },
    },
  };

  // cordoli setup
  const [selectedCordoli, setSelectedCordoli] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsCordoli = item => {
    setValue('l0t3a2_cordoliTableValue', [item]);
    setValue('l0t3a2_tipologiaCordoloTableValue', item.tipologiaCordolo);
    setValue('l0t3a2_altezzaCordoloTableValue', item.altezzaCordolo);
    setValue('l0t3a2_profonditaCordoloTableValue', item.profonditaCordolo);
    setValue('l0t3a2_lunghezzaCordoloTableValue', item.lunghezzaCordolo);
  };

  const handleSelectAllClickCordoli = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable`
      );
      const filteredValue = selectedValue.filter(
        el => el.presenza === SchemaPonteCommonTableValue.Uno
      );
      setSelectedCordoli(filteredValue);
      setValue('l0t3a2_cordoliTableValue', filteredValue);
    } else {
      setSelectedCordoli([]);
      setValue('l0t3a2_cordoliTableValue', []);
    }
  };

  return (
    <>
      <InfoWrapper
        isDivider={true}
        size={12}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoCordoli.drawerTitle}
        drawerText={INFO.impalcatoCordoli.drawerText}
      >
        <Divider sx={{ marginTop: 3 }}>CORDOLI</Divider>
      </InfoWrapper>
      {sovrastrutturaImpalcatoTableCordoliTableWatch?.length === 0 && (
        <GridWrap item xs={12}>
          <Typography>Nessun cordolo previsto in questo elemento</Typography>
        </GridWrap>
      )}
      {/*  {sovrastrutturaImpalcatoTableCordoliTableWatch?.filter(el => el.assente)
        .length > 0 && (
        <GridWrap item xs={12}>
          <Typography>Nessun cordolo presente in questo elemento</Typography>
        </GridWrap>
      )} */}

      {sovrastrutturaImpalcatoTableCordoliTableWatch?.every(el => el.assente) &&
        sovrastrutturaImpalcatoTableCordoliTableWatch?.length > 0 && (
          <GridWrap item xs={12}>
            <Typography>Nessun cordolo presente in questo elemento</Typography>
          </GridWrap>
        )}

      {sovrastrutturaImpalcatoTableCordoliTableWatch?.filter(el => !el.assente)
        .length > 0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <CordoliForm
              cordoliFields={
                sovrastrutturaImpalcatoTableCordoliTableWatch || []
              }
              selected={selectedCordoli}
              setSelected={setSelectedCordoli}
              cordoliTable={`sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Cordoli'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Sovrastruttura]}
          />
          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={
                  sovrastrutturaImpalcatoTableCordoliTableWatch.filter(
                    el => !el.assente
                  ) || []
                }
                config={dataConfigCordoli}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsCordoli(item);
                            setSelectedCordoli([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        sovrastrutturaImpalcatoTableCordoliTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedCordoli}
                onSelectAllClick={handleSelectAllClickCordoli}
                setSelected={setSelectedCordoli}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
CordoliSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default CordoliSection;
