import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import {
  FormSubmitButtons,
  RHFMultiSelect,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import {
  schemaCampataOptions,
  tipoEnteScavalcatoOptions,
} from 'constants/selectOptions';
import { tour_09 } from 'constants/tour';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';

const CampateForm = ({ campateFields, selected, setSelected }) => {
  const { getValues, setValue } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const methods = useFormContext({
    defaultValues: {
      campataTableValue: [],
      schemaTableValue: '',
      lucecampataTableValue: '',
      enteScavalcatoTableValue: '',
      luceIdraulicaTableValue: '',
    },
  });

  useEffect(() => {
    setValue('campataTableValue', selected);
  }, [selected]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitCampate = () => {
    const schemaTableValue = getValues('schemaTableValue');
    const lucecampataTableValue = getValues('lucecampataTableValue');
    const enteScavalcatoTableValue = getValues('enteScavalcatoTableValue');
    const luceIdraulicaTableValue = getValues('luceIdraulicaTableValue');

    const ids = selected.map(el => el.campata);

    const campate = [...campateFields];

    campate.map((x, index) => {
      if (ids.includes(x?.campata)) {
        campate[index] = {
          id: x.id,
          campata: x.campata,
          schema: schemaTableValue,
          luceCampata: lucecampataTableValue,
          enteScavalcato: enteScavalcatoTableValue,
          luceIdraulica: luceIdraulicaTableValue,
        };
      }
    });

    setValue('campataTable', campate);
  };

  const onResetCampate = () => {
    setValue('campataTableValue', []);
    setValue('schemaTableValue', '');
    setValue('lucecampataTableValue', '');
    setValue('enteScavalcatoTableValue', '');
    setValue('luceIdraulicaTableValue', '');
    setSelected([]);
  };

  return (
    <Grid container spacing={2} padding="20px">
      <Grid item xs={6}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-campata"
          name={'campataTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          disabled={inspectionDisabled || isViewingInspection}
          labelName={'option.campata'}
          getOptionLabel={option => option.campata}
          opt={campateFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Campate selezionate"
        />
      </Grid>
      <Grid item xs={6}>
        <RHFSelect
          name="schemaTableValue"
          label="Schema statico"
          defaultValue={''}
          onChange={e => {
            methods.setValue('schemaTableValue', e.target.value);
          }}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={schemaCampataOptions}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="lucecampataTableValue"
          label="Luce campata (m)*"
          defaultValue=""
          withValueLimit={({ value }) => value < 1000}
          disabled={inspectionDisabled || isViewingInspection}
          onChange={e => {
            methods.setValue('lucecampataTableValue', e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <RHFMultiSelect
          name="enteScavalcatoTableValue"
          label="Ente scavalcato (tipo)"
          disabled={inspectionDisabled || isViewingInspection}
          onChange={e => {
            methods.setValue('enteScavalcatoTableValue', e.target.value);
          }}
          selectOptions={tipoEnteScavalcatoOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <RHFNumberTextField
          name="luceIdraulicaTableValue"
          label="Luce idraulica (m)"
          defaultValue=""
          disabled={inspectionDisabled || isViewingInspection}
          withValueLimit={({ value }) => value < 1000}
          onChange={e => {
            methods.setValue('luceIdraulicaTableValue', e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={3} alignSelf="center" className={`${tour_09.step_02}`}>
        <FormSubmitButtons
          disabled={inspectionDisabled || isViewingInspection}
          onSubmit={onSubmitCampate}
          onReset={onResetCampate}
        />
      </Grid>
    </Grid>
  );
};
export { CampateForm };

CampateForm.propTypes = {
  campateFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      campataTableValue: PropTypes.string,
      schemaTableValue: PropTypes.number,
      lucecampataTableValue: PropTypes.number,
      enteScavalcatoTableValue: PropTypes.number,
      luceIdraulicaTableValue: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};
