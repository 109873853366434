import { Search } from '@mui/icons-material';
import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import ChangeOwnerSvg from 'assets/icons/change-owner.svg';
import {
  DataGrid,
  FormSubmitButtons,
  HeaderPage,
  RHFTextField,
} from 'components';
import DefaultForm from 'components/AdminCreateUser/DefaultForm';
import InspectorForm from 'components/AdminCreateUser/InspectorForm';
import InstitutionForm from 'components/AdminCreateUser/InstitutionForm';
import { apiUsers } from 'constants/api';
import { colors } from 'constants/colors';
import { USER_PATHS } from 'constants/paths';
import { StructureCdAMap, UserType, UserTypology } from 'constants/users';
import { StyledPaper } from 'pages/Notifications/Notifications.styles';
import { FormProvider } from 'providers';
import { useGlobalSelector } from 'stores';
import { ModeState } from './constants';
import DrawerChangeStructureOwnership from './DrawerChangeStructureOwnership';
import { ButtonWrapper, HeaderWrapper, WhiteContainer } from './Edit.styles';

const Edit = () => {
  const params = useParams();
  const userId = params.userId;

  const methods = useForm({
    defaultValues: {
      type: '',
      tipologiaUtente: '',
      name: '',
      surname: '',
      email: '',
      notes: '',
      businessName: '',
      region: '',
      province: '',
      council: '',
      address: '',
      phone: '',
      fiscalCode: '',
      emailUtentePrincipale: '',
      expirationDate: null,
      maxStructureNumber: '',
      maxAvailableSpace: '',
    },
  });
  const { handleSubmit, watch, setValue, reset } = methods;
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { enqueueSnackbar } = useSnackbar();

  const [selectDelete, setSelectDelete] = useState(false);
  const [opere, setOpere] = useState();
  const [operaFiltered, setOperaFiltered] = useState();
  const [structureMatch, setStructureMatch] = useState();
  const [openChangeOwnershipDialog, setOpenChangeOwnershipDialog] =
    useState(false);
  const [changeOwnershipDialogMode, setChangeOwnershipDialogMode] = useState();
  const [structureToTransfer, setStructureToTransfer] = useState();

  const { get, post, del, response } = useFetch();

  useEffect(() => {
    const handleDetail = async () => {
      try {
        showLoader();
        const result = await get(`users/${userId}`);
        reset({
          ...result,
          emailUtentePrincipale: result?.parentEmail,
          expirationDate: result?.subscription?.expirationDate || null,
          maxStructureNumber: result?.subscription?.maxStructureNumber,
          maxAvailableSpace: result?.subscription?.maxAvailableSpace,
        });
        result && setOpere(result?.ownedStructures);
      } catch (err) {
        console.error('Error in get users: ', err);
      } finally {
        hideLoader();
      }
    };
    userId && handleDetail().catch(console.error);
  }, [userId, openChangeOwnershipDialog]);

  const onSubmit = async data => {
    const getUserList = async () => {
      let user;
      try {
        if (watchTipologiaUtente === UserTypology.CollaboratoreInterno) {
          const res = await get(
            `${apiUsers.ROOT}?email=${data.emailUtentePrincipale}`
          );
          user = res?.items[0];
        }
        if (
          watchTipologiaUtente === UserTypology.CollaboratoreInterno &&
          !user
        ) {
          return enqueueSnackbar(
            `Si è verificato un errore: Utente principale non trovato`,
            {
              variant: 'error',
            }
          );
        } else {
          let parentId;
          if (watchTipologiaUtente === UserTypology.CollaboratoreInterno) {
            if (
              (user?.type === 'ENTE' && data.type == 'ENTE') ||
              (user?.type === 'PROFESSIONISTA' &&
                data.type === 'PROFESSIONISTA')
            ) {
              parentId = user.id;
            }
          }
          let updatedData;
          if (watchTypeUser === 'ADMIN') {
            updatedData = {
              ...data,
              regionId: data.region?.value?.id || undefined,
              provinceId: data.province?.value?.id || undefined,
              councilId: data.council?.value?.id || undefined,
              parentId,
            };
          } else {
            updatedData = {
              ...data,
              regionId: data.region?.value?.id || undefined,
              provinceId: data.province?.value?.id || undefined,
              councilId: data.council?.value?.id || undefined,
              parentId,
              subscription:
                watchTipologiaUtente === 1
                  ? {
                      expirationDate: data?.expirationDate || undefined,
                      maxStructureNumber:
                        parseInt(data?.maxStructureNumber) || undefined,
                      maxAvailableSpace:
                        parseInt(data?.maxAvailableSpace) || undefined,
                    }
                  : undefined,
            };
          }

          try {
            showLoader();
            await post(
              `${apiUsers.ROOT}/${userId}${apiUsers.EDIT}`,
              updatedData
            );
            if (response.ok) {
              enqueueSnackbar('Utente modificato correttamente', {
                variant: 'success',
              });
              navigate(USER_PATHS.ROOT);
            }
          } catch (err) {
            console.error('Error in handle cards: ', err);
            if (
              err?.message?.includes(
                'An account with the given email already exists'
              )
            ) {
              return enqueueSnackbar(
                `Si è verificato un errore: utente già esistente`,
                {
                  variant: 'error',
                }
              );
            } else
              return enqueueSnackbar(`Si è verificato un errore`, {
                variant: 'error',
              });
          } finally {
            hideLoader();
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUserList().catch(console.error);
  };

  const handleDelete = async () => {
    try {
      showLoader();
      await del(`${apiUsers.ROOT}/${userId}`);
      if (response.ok) {
        return enqueueSnackbar('Utente eliminato correttamente', {
          variant: 'success',
        });
      }
    } catch (err) {
      console.error('Error in handle user: ', err);
      if (err) {
        return enqueueSnackbar(`Si è verificato un errore`, {
          variant: 'error',
        });
      }
    } finally {
      hideLoader();
      navigate(USER_PATHS.ROOT);
    }
  };

  const watchTypeUser = watch('type');
  const watchTipologiaUtente = watch('tipologiaUtente');
  const regionWatch = watch('region');
  const provinceWatch = watch('province');

  useEffect(() => {
    if (!regionWatch) {
      setValue('province', '');
      setValue('council', '');
    }
    if (!provinceWatch) {
      setValue('council', '');
    }
  }, [regionWatch, provinceWatch]);

  // Sezione Opere - Filtro di ricerca e DataGrid
  const columns = [
    {
      name: 'internalCode',
      label: 'Codice Interno - ID',
    },
    {
      name: 'iop',
      label: 'Codice IOP',
    },
    {
      name: 'name',
      label: 'Nome Opera',
    },
    {
      name: 'cda',
      label: 'CdA',
      valueFormatter: cda => StructureCdAMap[cda],
    },
    {
      name: 'tags',
      label: 'Tag',
      renderCell: row => {
        return (
          <Stack flexDirection={'row'}>
            {row?.tags?.length > 0 &&
              row?.tags[0] !== '' &&
              row?.tags?.map((tag, idx) => (
                <Chip
                  color="primary"
                  sx={{ marginRight: 1, marginTop: 1 }}
                  key={idx}
                  label={tag}
                />
              ))}
          </Stack>
        );
      },
    },
    {
      name: 'actions',
      label: '',
      renderCell: row => {
        return (
          <Stack flexDirection={'row'}>
            <IconButton
              onClick={() => {
                setStructureToTransfer(row?.internalCode);
                setChangeOwnershipDialogMode(ModeState.TRANSFER);
                setOpenChangeOwnershipDialog(true);
              }}
            >
              <img src={ChangeOwnerSvg} />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    opere && setStructureMatch(opere);
  }, [opere]);

  const onSubmitFilter = data => {
    const dataFilter = {
      codiceInterno: data?.codiceInterno,
      codiceIOP: data?.codiceIOP,
      nomeOpera: data?.nomeOpera,
      tag: data?.tag,
    };
    data && setOperaFiltered(dataFilter);
  };

  const onReset = () => {
    opere && setStructureMatch(opere);
    setOperaFiltered({
      codiceInterno: undefined,
      codiceIOP: undefined,
      nomeOpera: undefined,
      tag: undefined,
    });
    setValue('codiceInterno', '');
    setValue('codiceIOP', '');
    setValue('nomeOpera', '');
    setValue('tag', '');
  };

  /*   useEffect(() => {
    changeOwnershipDialogMode && setOpenChangeOwnershipDialog(true);
  }, [changeOwnershipDialogMode]); */

  useEffect(() => {
    !operaFiltered && setStructureMatch(opere);
    const tags = opere?.map(op => op?.tags).flat();
    const isMatchTags = tags?.includes(operaFiltered?.tag);

    const codInt = opere?.map(op => op?.internalCode);
    const codIop = opere?.map(op => op?.iop);

    // ---- CODICE IOP ---------
    const checkIOP =
      operaFiltered?.codiceIOP &&
      codIop
        ?.map(el => el?.match(operaFiltered?.codiceIOP)?.flat())
        .flat()
        ?.includes(operaFiltered?.codiceIOP);

    const matchIop = opere?.filter(el =>
      el?.iop
        ?.match(operaFiltered?.codiceIOP)
        ?.flat()
        ?.includes(operaFiltered?.codiceIOP)
    );
    // ---------------------------

    // ------ NOME OPERA ----------
    const checkNome =
      operaFiltered?.nomeOpera &&
      opere?.some(opera =>
        opera?.name
          ?.toUpperCase()
          ?.includes(operaFiltered?.nomeOpera?.toUpperCase())
      );

    const matchOperaName =
      operaFiltered?.nomeOpera &&
      opere?.filter(opera =>
        opera?.name
          ?.toUpperCase()
          ?.includes(operaFiltered?.nomeOpera?.toUpperCase())
      );
    // -------------------------------

    // ------- CODICE INTERNO --------
    /*  const isMatchInternalCode =
      operaFiltered?.codiceInterno &&
      codInt?.includes(operaFiltered?.codiceInterno);
    const matchIntCode = opere?.filter(
      op => op?.internalCode === operaFiltered?.codiceInterno
    ); */
    const isMatchInternalCode =
      operaFiltered?.codiceInterno &&
      codInt
        ?.map(el => el?.match(operaFiltered?.codiceInterno)?.flat())
        .flat()
        ?.includes(operaFiltered?.codiceInterno);

    const matchIntCode = opere?.filter(el =>
      el?.internalCode
        ?.match(operaFiltered?.codiceInterno)
        ?.flat()
        ?.includes(operaFiltered?.codiceInterno)
    );
    // -------------------------------

    const matchtags = opere?.filter(op =>
      op?.tags?.includes(operaFiltered?.tag)
    );

    if (isMatchInternalCode && checkIOP && checkNome) {
      const match2 = matchIntCode.filter(el =>
        el?.iop
          ?.match(operaFiltered?.codiceIOP)
          ?.flat()
          ?.includes(operaFiltered?.codiceIOP)
      );
      const match3 = match2.filter(el =>
        el?.name
          ?.toUpperCase()
          ?.includes(operaFiltered?.nomeOpera?.toUpperCase())
      );
      setStructureMatch(match3);
    } else if (isMatchInternalCode && checkIOP) {
      const match2 = matchIntCode.filter(el =>
        el?.iop
          ?.match(operaFiltered?.codiceIOP)
          ?.flat()
          ?.includes(operaFiltered?.codiceIOP)
      );
      setStructureMatch(match2);
    } else if (checkNome && checkIOP) {
      const matchIopName = matchIop.filter(el =>
        el?.name
          ?.toUpperCase()
          .includes(operaFiltered?.nomeOpera?.toUpperCase())
      );
      setStructureMatch(matchIopName);
    } else if (checkNome && isMatchInternalCode) {
      const matchInternalName = matchIntCode.filter(el =>
        el?.name
          ?.toUpperCase()
          .includes(operaFiltered?.nomeOpera?.toUpperCase())
      );
      setStructureMatch(matchInternalName);
    } else if (isMatchInternalCode) {
      setStructureMatch(matchIntCode);
    } else if (checkIOP) {
      setStructureMatch(matchIop);
    } else if (checkNome) {
      setStructureMatch(matchOperaName);
    } else if (isMatchTags) {
      setStructureMatch(matchtags);
    }
  }, [operaFiltered]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <HeaderWrapper>
          <HeaderPage title="Modifica utente" back={USER_PATHS.ROOT} />
          <ButtonWrapper
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => navigate(USER_PATHS.ROOT)}
            disabled={selectDelete}
          >
            Annulla
          </ButtonWrapper>
          <ButtonWrapper
            variant="containedBlack"
            size="small"
            type="submit"
            disabled={selectDelete}
          >
            Salva
          </ButtonWrapper>
          <ButtonWrapper
            variant="outlined"
            color="secondary"
            size="small"
            disabled={selectDelete}
            onClick={() => {
              setSelectDelete(true);
              /* handleDelete(); */
              /* navigate(-1); */
            }}
          >
            Elimina
          </ButtonWrapper>
        </HeaderWrapper>
        {selectDelete && (
          <Stack
            flexDirection={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            marginTop={-8}
            marginBottom={8}
          >
            <Typography variant="h3Bold">{`Sei sicuro di voler eliminare questo utente?`}</Typography>
            <ButtonWrapper
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectDelete(true);
                handleDelete();
                /* navigate(-1); */
              }}
            >
              Sì
            </ButtonWrapper>
            <ButtonWrapper
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                setSelectDelete(false);
                /* navigate(-1); */
              }}
            >
              No
            </ButtonWrapper>
          </Stack>
        )}

        <WhiteContainer>
          <DefaultForm editForm={true} />
          {watchTypeUser === UserType.Ente && <InstitutionForm />}
          {watchTypeUser === UserType.Professionista && <InspectorForm />}
          {watchTipologiaUtente &&
            watchTipologiaUtente === UserTypology.UtentePrincipale && (
              <>
                <Grid container xs={12} spacing={2} style={{ marginTop: 2 }}>
                  <Grid item xs={12}>
                    <Divider>OPERE</Divider>
                  </Grid>
                  <Grid item xs={12} textAlign="end">
                    <Button
                      variant="containedBlack"
                      onClick={() => {
                        setChangeOwnershipDialogMode(ModeState.ASSIGN);
                        setOpenChangeOwnershipDialog(true);
                      }}
                    >
                      Assegna Opere
                    </Button>
                  </Grid>
                  {opere?.length ? (
                    <>
                      <Grid item xs={12}>
                        <StyledPaper
                          sx={{
                            marginBottom: theme => theme.spacing(3),
                          }}
                          colorVariant="grey"
                          elevation={0}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={2.4}>
                              <RHFTextField
                                name="codiceInterno"
                                label="Codice Interno - ID"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={2.4}>
                              <RHFTextField
                                name="codiceIOP"
                                label="Codice IOP"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={2.4}>
                              <RHFTextField
                                name="nomeOpera"
                                label="Nome Opera"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={2.4}>
                              <RHFTextField
                                name="tag"
                                label="Tag"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={2.4}>
                              <FormSubmitButtons
                                onSubmit={handleSubmit(onSubmitFilter)}
                                onReset={onReset}
                                customSubmitIcon={<Search />}
                              />
                            </Grid>
                          </Grid>
                        </StyledPaper>
                      </Grid>

                      <Grid item xs={12}>
                        {opere && structureMatch && (
                          <DataGrid
                            /* isAdmin={true} */
                            rowColor={colors.LIGHTGREY}
                            columns={columns}
                            rows={structureMatch}
                            /* rows={opere} */
                          />
                        )}
                      </Grid>
                    </>
                  ) : (
                    <Stack
                      alignItems={'center'}
                      width={'100%'}
                      marginTop={'10px'}
                    >
                      <Typography variant="h3Bold">{`L'utente non ha nessuna opera di sua proprietà`}</Typography>
                    </Stack>
                  )}
                </Grid>
              </>
            )}
        </WhiteContainer>
      </FormProvider>
      <DrawerChangeStructureOwnership
        userMail={watch('email')}
        mode={changeOwnershipDialogMode}
        open={openChangeOwnershipDialog}
        setOpen={setOpenChangeOwnershipDialog}
        structureCode={structureToTransfer}
      />
    </>
  );
};

export default Edit;
