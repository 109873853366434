import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiAccessoriL1SectionWrapper,
  InspectionInfoScheda20Form,
} from 'components';
import {
  sottoserviziOptions,
  tipologiaSottoserviziOptions,
} from 'constants/selectOptions';
import SottoserviziForm from './SottoserviziForm';

const Accordion3Sottoservizi = () => {
  const { watch, setValue, getValues } = useFormContext();
  const sottoserviziTableWatch = watch('sottoserviziTable');

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsSottoservizi = item => {
    setValue('sottoserviziTableValue', [item]);
    setValue('tipologiaSottoserviziTableValue', item.tipologiaSottoservizi);
  };

  const handleSelectAllClickSottoservizi = event => {
    if (event.target.checked) {
      const selectedValue = getValues(`sottoserviziTable`);
      setSelected(selectedValue);
      setValue('sottoserviziTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('sottoserviziTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t4a3_tecnicoRilevatoreSottoservizi',
      getValues(`sottoserviziTable[0].tecnicoRilevatore`) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t4a3_dataIspezioneSottoservizi',
      getValues(`sottoserviziTable[0].dataIspezione`) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t4a3_noteGeneraliSottoservizi',
      getValues(`sottoserviziTable[0].noteGenerali`) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t4a3_tecnicoRilevatoreSottoservizi');
    const dataIspezione = getValues('l1t4a3_dataIspezioneSottoservizi');

    const noteGenerali = getValues('l1t4a3_noteGeneraliSottoservizi');
    setValue(`sottoserviziTable[0].tecnicoRilevatore`, tecnicoRilevatore);
    setValue(`sottoserviziTable[0].dataIspezione`, dataIspezione);
    setValue(`sottoserviziTable[0].noteGenerali`, noteGenerali);
  };

  const handleResetInspection = () => {
    setValue(`l1t4a3_tecnicoRilevatoreSottoservizi`, '');
    setValue(`l1t4a3_dataIspezioneSottoservizi`, null);
    setValue(`l1t4a3_noteGeneraliSottoservizi`, '');
  };

  const dataSottoserviziConfig = {
    columns: {
      presenza: { label: 'Sottoservizi', format: sottoserviziOptions },
      tipologiaSottoservizi: {
        label: 'Tipologia',
        format: tipologiaSottoserviziOptions,
      },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      nrtotali: { label: 'NR' },
    },
  };

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiAccessoriL1SectionWrapper
        elementTitle={'sottoservizi'}
        singleElementTitle={'sottoservizio'}
        tableWatch={sottoserviziTableWatch}
        tableWatchName={`sottoserviziTable`}
        elementForm={
          <SottoserviziForm
            sottoserviziFields={sottoserviziTableWatch || []}
            selected={selected}
            setSelected={setSelected}
            sottoserviziTable={`sottoserviziTable`}
          />
        }
        inspectionInfoForm={
          <InspectionInfoScheda20Form
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t4a3_tecnicoRilevatoreSottoservizi'}
            dateName={'l1t4a3_dataIspezioneSottoservizi'}
            noteGeneraliName={'l1t4a3_noteGeneraliSottoservizi'}
          />
        }
        tableConfig={dataSottoserviziConfig}
        editRows={editRowsSottoservizi}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickSottoservizi}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
      />
    </>
  );
};
export default Accordion3Sottoservizi;
