import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import {
  markdown_faq_12_1,
  markdown_faq_12_2,
  markdown_faq_12_3,
  markdown_faq_12_4,
  markdown_faq_12_5,
  markdown_faq_12_6,
  markdown_faq_12_7,
} from './faqs/faq_12';
import { markdown_faq_13_1 } from './faqs/faq_13';
import { markdown_faq_16_1 } from './faqs/faq_16';
import { markdown_faq_17_1, markdown_faq_17_2 } from './faqs/faq_17';
import { markdown_faq_18_1 } from './faqs/faq_18';
import { markdown_faq_21_1, markdown_faq_21_2 } from './faqs/faq_21';
import {
  markdown_faq_22_1,
  markdown_faq_22_2,
  markdown_faq_22_3,
} from './faqs/faq_22';
import {
  markdown_faq_23_1,
  markdown_faq_23_2,
  markdown_faq_23_3,
  markdown_faq_23_4,
} from './faqs/faq_23';
import { markdown_faq_25_1, markdown_faq_25_2 } from './faqs/faq_25';
import {
  markdown_faq_26_1,
  markdown_faq_26_2,
  markdown_faq_26_3,
} from './faqs/faq_26';
import { markdown_faq_27_1, markdown_faq_27_2 } from './faqs/faq_27';
import { markdown_faq_28_1 } from './faqs/faq_28';
import { markdown_faq_29_1, markdown_faq_29_2 } from './faqs/faq_29';
import {
  markdown_faq_3_1,
  markdown_faq_3_2,
  markdown_faq_3_3,
} from './faqs/faq_3';
import {
  markdown_faq_30_1,
  markdown_faq_30_2,
  markdown_faq_30_3,
  markdown_faq_30_4,
  markdown_faq_30_5,
  markdown_faq_30_6,
} from './faqs/faq_30';
import {
  markdown_faq_31_1,
  markdown_faq_31_2,
  markdown_faq_31_3,
} from './faqs/faq_31';
import { markdown_faq_32_1 } from './faqs/faq_32';
import { markdown_faq_33_1, markdown_faq_33_2 } from './faqs/faq_33';
import { markdown_faq_34_1 } from './faqs/faq_34';
import { markdown_faq_35_1 } from './faqs/faq_35';
import {
  markdown_faq_36_1,
  markdown_faq_36_2_1,
  markdown_faq_36_2_2,
  markdown_faq_36_3_1,
  markdown_faq_36_3_2,
  markdown_faq_36_4_1,
  markdown_faq_36_4_2,
  markdown_faq_36_5,
} from './faqs/faq_36';
import {
  markdown_faq_4_1,
  markdown_faq_4_2,
  markdown_faq_4_3,
} from './faqs/faq_4';
import { markdown_faq_5_1, markdown_faq_5_2 } from './faqs/faq_5';
import { markdown_faq_6_1, markdown_faq_6_2 } from './faqs/faq_6';
import { markdown_faq_7_1, markdown_faq_7_2 } from './faqs/faq_7';
import { markdown_faq_9_1, markdown_faq_9_2 } from './faqs/faq_9';

const pathImages =
  'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/faq_images/';

export const videoCategories = {
  generale: 'Generale',
  attivita: 'Attività',
};

export const faqContentTypes = {
  HTML: 'html',
  IMAGES: 'images',
};

/**
 * the metaDescription is used for the search bar, is never rendered on screen
 */
/* eslint react/no-children-prop: 0 */
//eslint-disable-next-line
const MarkdownRenderer = ({ markdown }) => {
  return (
    <Typography variant="p">
      <ReactMarkdown linkTarget="_blank" children={markdown} />
    </Typography>
  );
};

export const faqSections = [
  {
    title: 'Linee guida Ponti',
    faq: [
      {
        question:
          'Come calcolare la Classe di Attenzione complessiva e specifiche',
        metaDescription:
          'cda livello 2 rischio livelli calcolo risultato valore classe attenzione complessiva specifiche calcolare',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_16_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}16/16.1.png` },
              { original: `${pathImages}16/16.2.png` },
            ],
          },
        ],
        videoIds: [35],
        guides: [],
      },
      {
        question: "Cos'è il codice IOP e come inserirlo",
        metaDescription:
          'ainop identificativo opera pubblica archivio informatico opere pubbliche ente gestore portale csv iop codice codici inserirlo inserire generare generato generazione inserimento',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_32_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}32/_32.1.png` },
              { original: `${pathImages}32/_32.2.png` },
            ],
          },
        ],
        videoIds: [39],
        guides: [],
      },
    ],
  },
  {
    title: "Introduzione all'utilizzo di INBEE",
    faq: [
      {
        question: 'Canali di supporto attivi',
        metaDescription:
          'informazioni supporto assistenza aiuto form contatti contatto mail video guida guide telefono referenza referenze help canali tutorial normativa normative',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_3_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}3/3.1.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_3_2} />,
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_3_3} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}3/3.3.png` }],
          },
        ],
        videoIds: [],
        guides: [],
      },
      {
        question:
          'Qual è il sistema di riferimento usato per definire gli elementi del ponte',
        metaDescription:
          'codifica numerazione numerati numerato codici codice identificativo id numero asse sistema riferimento definire elementi ponte definizione catalogazione catalogare identificare identificazione',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_22_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}22/_22.1.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_22_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}22/_22.2.png` },
              { original: `${pathImages}22/_22.3.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_22_3} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}22/_22.4.png` },
              { original: `${pathImages}22/_22.5.png` },
            ],
          },
        ],
        videoIds: [30],
        guides: [],
      },
      {
        question: "Come scaricare l'app",
        metaDescription:
          'applicazione applicativo telefono smartphone cellulare campo ispezione indagine scaricare app store',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_34_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}34/34.1.jpg` }],
          },
        ],
        videoIds: [],
        guides: [],
      },
      {
        question: 'Utenti senza sottoscrizione: Timer ponte',
        metaDescription:
          'timer sottoscrizione gratis esportazione esportazioni tempo visualizzazione limite limiti tempi tempistiche utilizzo vincoli vincolo grigio bloccata bloccato blocco',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_35_1} />,
          },
        ],
        videoIds: [36],
        guides: [],
      },
    ],
  },

  {
    title: 'Suggerimenti Operativi (Help Guide)',
    faq: [
      {
        question: 'Inserire un ponte non ancora mappato',
        metaDescription:
          'nuovo creare censire mappare mappa segnaposto pin generare iniziare opera opere ponti ponte anagrafica generare nuovi aggiungere mappato inserire',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_4_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}4/4.1.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_4_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}4/4.2.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_4_3} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}4/4.3.png` }],
          },
        ],
        videoIds: [5],
        guides: [],
      },
      {
        question: 'Cosa sono i tag del ponte',
        metaDescription:
          'ponti ponte organizzare suddividere suddivisione ripartire partizione dividere assegnare assegnazione cercare filtrare filtri ricerca lavoro collaboratori collaboratore inserire associare tag',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_5_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}5/5.1.png` },
              { original: `${pathImages}5/5.2.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_5_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}5/5.3.png` },
              { original: `${pathImages}5/5.4.png` },
            ],
          },
        ],
        videoIds: [23],
        guides: [],
      },
      {
        question: 'Come eliminare un ponte',
        metaDescription:
          'eliminazione eliminare cancellare cancellazione pulizia rimozione rimuovere opera opere struttura strutture ponte ponti',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_6_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}6/6.1.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_6_2} />,
          },
        ],
        videoIds: [6],
        guides: [],
      },
      {
        question: 'Ricercare uno o più ponti',
        metaDescription:
          'ricerca ricercare trovare individuare selezionare selezione ponte ponti filtri mappa opera opere strutture struttura',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_7_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}7/7.1.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_7_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}7/7.2.png` }],
          },
        ],
        videoIds: [7],
        guides: [],
      },
      {
        question: "Come condividere un ponte o un'attività?",
        metaDescription:
          'condivisione condividere collaborazione condivido collaboro collaboratori collaboratore collaborare ispettore ispezione censimento differenza accesso diritti visualizzazione visualizzare modifica modificare utente utenti aggiungere scadenze scadenza ponti ponte attività',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_9_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}9/9.1.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_9_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}9/9.2.png` }],
          },
        ],
        videoIds: [9],
        guides: [],
      },
      {
        question: "Attività: cos'è, come crearne una, come ricercarla",
        metaDescription:
          'compilazione schede censimento ispezioni ispezione dati livello 0 1 eliminare inserire archivio precedente storico condividere condivisione collaboratori collaboratore collaborazione condivido collaboro filtri trovare cercare inserire creare aggiungere attività crearne creare ricercarla ricercare',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_12_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}12/12.1.png` },
              { original: `${pathImages}12/12.2.png` },
            ],
          },

          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_12_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}12/12.3.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_12_3} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}12/12.4.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_12_4} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}12/12.5.png` },
              { original: `${pathImages}12/12.6.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_12_5} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}12/12.7.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_12_6} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}12/12.8.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_12_7} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}12/12.9.png` }],
          },
        ],
        videoIds: [11, 31],
        guides: [],
      },
      {
        question: "Come bloccare un'attività",
        metaDescription:
          'collaboratore collaboratori collaboro condivido condivisa condivisione lucchetto blocco accesso limitare impedire modifica sovrascrivere bloccare attività',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_13_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}13/13.1.png` }],
          },
        ],
        videoIds: [13],
        guides: [],
      },
      {
        question: 'Come compilare e modificare i dati di una tabella',
        metaDescription:
          'compilazione inserimento inserire velocizzare tabella modificare compilare dati tabella duplicare duplicazione duplicazioni copiare copia replicare replica',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_17_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}17/17.1.png` },
              { original: `${pathImages}17/17.2.png` },
              { original: `${pathImages}17/17.3.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_17_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}17/17.4.png` },
              { original: `${pathImages}17/17.5.png` },
              { original: `${pathImages}17/17.6.png` },
            ],
          },
        ],
        videoIds: [16],
        guides: [],
      },
      {
        question:
          'Schede di valutazione dei difetti: dove trovarle e come duplicarle',
        metaDescription:
          'difettosità difetto gravità estensione intensità rilevabile applicabile presente inserimento inserire compilare compilazione velocizzare valutazione difetti duplicarle duplicare duplicazione compilarle',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_18_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}18/_18.1.png` },
              { original: `${pathImages}18/_18.2.png` },
              { original: `${pathImages}18/_18.3.png` },
              { original: `${pathImages}18/_18.4.png` },
            ],
          },
        ],
        videoIds: [21],
        guides: [],
      },
      {
        question: "Come caricare e gestire i file relativi ad un'attività",
        metaDescription:
          'documenti immagini foto elaborati caricamento progetto progetti documentazione caricare gestire file attività',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_21_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}21/_21.1.png` },
              { original: `${pathImages}21/_21.2.png` },
              { original: `${pathImages}21/_21.3.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_21_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}21/_21.4.png` },
              { original: `${pathImages}21/_21.5.png` },
            ],
          },
        ],
        videoIds: [15],
        guides: [],
      },
      {
        question: 'Come definire e descrivere gli elementi del ponte',
        metaDescription:
          'elemento geometria difetti difetto materiale fondazioni descrizione definizione descrivere elementi ponte',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_23_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}23/_23.1.png` },
              { original: `${pathImages}23/_23.2.png` },
            ],
          },

          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_23_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}23/_23.3.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_23_3} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}23/_23.5.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_23_4} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}23/_23.6.png` },
              { original: `${pathImages}23/_23.7.png` },
            ],
          },
        ],
        videoIds: [18],
        guides: [],
      },
      {
        question: 'Come esportare i dati',
        metaDescription:
          'esportazione esportazioni esportare esportati esportato schede scheda censimento censimenti ispezione ispezioni difetti difetto difettosità fotografie immagini ainop csv anagrafica dati',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_25_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}25/_25.1.png` },
              { original: `${pathImages}25/_25.2.png` },
              { original: `${pathImages}25/_25.3.png` },
              { original: `${pathImages}25/_25.4.png` },
              { original: `${pathImages}25/_25.5.png` },
            ],
          },

          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_25_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}25/_25.6.png` },
              { original: `${pathImages}25/_25.7.png` },
              { original: `${pathImages}25/_25.8.png` },
              { original: `${pathImages}25/_25.9.png` },
            ],
          },
        ],
        videoIds: [22],
        guides: [],
      },
      {
        question: "Come caricare file esterni all'attività",
        metaDescription:
          'documenti immagini foto elaborati caricamento progetto progetti documentazione caricare gestire file attività caricare caricato caricati esterni esterno attività',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_26_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}26/_26.1.png` },
              { original: `${pathImages}26/_26.2.png` },
            ],
          },

          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_26_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}26/_26.3.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_26_3} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}26/_26.4.png` }],
          },
        ],
        videoIds: [15],
        guides: [],
      },

      {
        question: "Come consegnare il lavoro svolto all'Ente committente",
        metaDescription:
          'consegna gestore schede consegnare consegnato consegnati lavoro lavori ente committente committenti recapitati recapitare recapitato',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_27_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}27/27.1.png` },
              { original: `${pathImages}27/27.2.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_27_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}27/27.3.png` },
              { original: `${pathImages}27/27.4.png` },
            ],
          },
        ],
        videoIds: [38],
        guides: [],
      },
      {
        question: 'Come duplicare un ponte',
        metaDescription:
          'copiare opera struttura doppia gemella gemello gemelli uguale uguali replicare dati indipendenti separati due divisi distaccati staccati autonomi svincolati duplica copia duplicare duplicazione duplicazioni ponte ponti opera strutture',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_28_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}28/_28.1.png` },
              { original: `${pathImages}28/_28.2.png` },
              { original: `${pathImages}28/_28.3.png` },
            ],
          },
        ],
        videoIds: [37],
        guides: [],
      },
      {
        question: "Come ricercare un'immagine o un documento",
        metaDescription:
          'documenti immagini foto elaborati elaborato caricati caricato progetto progetti documentazione filtri filtro ricerca ricerche ricercare immagine documento',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_31_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}31/_31.1.png` },
              { original: `${pathImages}31/_31.2.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_31_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}31/_31.3.png` },
              { original: `${pathImages}31/_31.4.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_31_3} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}31/_31.6.png` }],
          },
        ],
        videoIds: [15, 32],
        guides: [],
      },
      {
        question: 'Trasferimento dati ad AINOP',
        metaDescription:
          'ainop archivio minstero pubblico portale ministeriale informatico database iop codice trasferimento trasferire ente gestore proprietario inserimento inserire inviare invio mandare comunicare comunicazione inserimento inserire csv excel file trasmissione dati anagrafica specifica base caricamento caricare velocizzare velocemente veloce',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_36_1} />,
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_36_2_1} />,
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_36_2_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}36/36.1.png` },
              { original: `${pathImages}36/36.2.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_36_3_1} />,
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_36_3_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}36/36.3.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_36_4_1} />,
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_36_4_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}36/36.4.png` },
              { original: `${pathImages}36/36.5.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_36_5} />,
          },
        ],
        videoIds: [40],
        guides: [],
      },
    ],
  },
  {
    title: 'Permessi e gruppi di lavoro',
    faq: [
      {
        question: 'Area collaboratori',
        metaDescription:
          'collaboratore area collaboratori interno esterno professionista ispettore assegnare ponte assegnazione ponti gruppo lavoro inserire eliminare gestire gestione modifica modificare utente utenti aggiungere diritti scadenza scadenze accesso associare associazione',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_30_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}30/30.1.png` },
              { original: `${pathImages}30/30.2.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_30_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}30/30.3.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_30_3} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}30/30.5.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_30_4} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}30/30.6.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_30_5} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}30/30.8.png` }],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_30_6} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}30/30.12.png` }],
          },
        ],
        //videoIds: [1, 2],
        videoIds: [26],
        guides: [
          /* {
            title: 'Panoramica',
            size: '371KB',
            path: 'downloadables/guides/Guida INBEE - Panoramica.pdf',
          },
          {
            title: 'Riferimenti Normativi',
            size: '371KB',
            path: 'downloadables/guides/Guida INBEE - Riferimenti normativi.pdf',
          }, */
        ],
      },
    ],
  },
  {
    title: 'Account',
    faq: [
      {
        question: 'Come cambiare password e modificare i dati del profilo',
        metaDescription:
          'utente account credenziali cambiare password modificare dati profilo utenti',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_29_1} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [
              { original: `${pathImages}29/_29.1.png` },
              { original: `${pathImages}29/_29.2.png` },
            ],
          },
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_29_2} />,
          },
          {
            type: faqContentTypes.IMAGES,
            images: [{ original: `${pathImages}29/_29.3.png` }],
          },
        ],
        videoIds: [],
        guides: [],
      },
      {
        question: 'Sottoscrizione',
        metaDescription:
          'abbonamento pacchetto pagamento scaduta contatti contatto mail funzionalità gratuito costo costa sottoscrizione sottoscrizioni',
        content: [
          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_33_1} />,
          },

          {
            type: faqContentTypes.HTML,
            innerHtml: <MarkdownRenderer markdown={markdown_faq_33_2} />,
          },
        ],
        videoIds: [],
        guides: [],
      },
    ],
  },
];

export const videoTutorials = [
  {
    id: 1,
    title: 'Benvenuto in INBEE',
    youtubeId: 'O4w4uN5t6Gg',
    category: [videoCategories.generale],
    metaDescription:
      'strumento digitale valutazione linee guida  piattaforma web app ottimizzare ispezioni campo',
    description:
      "INBEE, lo strumento digitale per la valutazione dei ponti secondo le Linee Guida Ponti e Viadotti, che offre una piattaforma web e un'app per ottimizzare le ispezioni in campo.",
  },
  {
    id: 4,
    title: 'Sezione Home Enti e Sezione Home Ispettori',
    youtubeId: '-Igu4atIr2E',
    category: [videoCategories.generale],
    metaDescription: 'panoramica indicatori sezione home ispettori enti',
    description:
      'Panoramica sugli Indicatori della sezione Home pensati appositamente per Ispettori e Enti.',
  },
  {
    id: 5,
    title: 'Come inserire un nuovo ponte',
    youtubeId: 'oVySsp6h59I',
    category: [videoCategories.generale],
    metaDescription:
      'tracciamento tracciare nuovo database primo passo  inserire dati suggerimento operativo tag inserire creare censire mappare mappa segnaposto pin generare iniziare opera opere ponti ponte anagrafica nuovi aggiungere mappato',
    description:
      'Tracciamento di un nuovo ponte nel database: il primo passo per inserire i dati, sia da piattaforma che da app. Suggerimento operativo: sfrutta i tag del ponte!',
  },
  {
    id: 6,
    title: 'Come eliminare un ponte',
    youtubeId: 'K-25vreoFcw',
    category: [videoCategories.generale],
    metaDescription:
      'eliminare eliminazione cancellare cancellazione pulizia rimozione rimuovere opera opere struttura strutture ponte ponti nuovo',
    description: 'Eliminazione di un ponte nuovo.',
  },
  {
    id: 7,
    title: 'Come ricercare un ponte',
    youtubeId: 'MYs3os92zE0',
    category: [videoCategories.generale],
    metaDescription:
      'ricercare velocizza ricerca caratteristiche simili sfruttando filtri tag  sezione ricercare trovare individuare selezionare selezione ponte ponti filtri mappa opera opere strutture struttura',
    description:
      'Velocizza la ricerca di un ponte, o di ponti con caratteristiche simili, sfruttando i filtri e i tag della sezione Ponti.',
  },
  {
    id: 8,
    title: 'Riepilogo del ponte',
    youtubeId: 'SaAYTUTir2M',
    category: [videoCategories.generale],
    metaDescription:
      'schede riepilogo ponte strumento utile ispettore per valutare avanzamento lavoro ente gestore programmare interventi intraprendere',
    description:
      "Schede di riepilogo del ponte: uno strumento utile all'Ispettore per valutare l'avanzamento del proprio lavoro e all'Ente gestore per programmare gli interventi da intraprendere.",
  },
  {
    id: 11,
    title: 'Come creare una nuova attività',
    youtubeId: 'MkFBu_sZ0SE',
    category: [videoCategories.generale],
    metaDescription:
      'attività spazio database singola ispezione inserire dati file creare nuova compilazione schede censimento dati livello 0 1 ',
    description:
      'Attività: lo spazio nel database dedicato alla singola ispezione dove inserire dati e file.',
  },
  {
    id: 9,
    title: "Come condividere un ponte o un'attività",
    youtubeId: 'oQHbNx_Ang8',
    category: [videoCategories.generale],
    metaDescription:
      'condivisione condividere collaborazione condivido collaboro collaboratori collaboratore collaborare ispettore ispezione censimento altri utenti informazioni tutto ponte singola attività aspetto fondamentale gestire rapporto ente ispettore cooperazione più professionisti condividere',
    description:
      'La condivisione con altri utenti delle informazioni, di tutto il ponte o di una singola attività: un aspetto fondamentale per gestire il rapporto Ente-Ispettore e la cooperazione tra più Professionisti.',
  },
  {
    id: 13,
    title: "Come bloccare un'attività",
    youtubeId: 'yOiwkHzYDtE',
    category: [videoCategories.generale],
    metaDescription:
      'funzione evitare sovrapposizioni inserimento dati collaboratore collaboratori collaboro condivido condivisa condivisione lucchetto blocco accesso limitare impedire modifica sovrascrivere bloccare attività',
    description:
      "La funzione Lucchetto, utile per evitare sovrapposizioni nell'inserimento dati.",
  },
  {
    id: 14,
    title: 'Inserimento dati',
    youtubeId: 'HzL88Rw7-IM',
    category: [videoCategories.generale],
    metaDescription:
      'inserire inserimento dati ispezioni compilare schede livello 0 1 suddivisione dati livelli categorie caricamento file pannelli informativi',
    description:
      'Suggerimenti per inserire i dati delle ispezioni e compilare le schede di Livello 0 e 1. Suddivisione dei dati in livelli e categorie, caricamento file e pannelli informativi.',
  },
  {
    id: 15,
    title: 'Archivio digitale: Come caricare e ricercare immagini e documenti',
    youtubeId: 'MrHlA0l09fU',
    category: [videoCategories.generale],
    metaDescription:
      'creazione archivio digitale inserimento immagini documenti caricarli ricerca file caricare ricercare immagine documento',
    description:
      "Creazione dell'archivio digitale con l'inserimento di immagini e documenti: dove e come caricarli. Successiva ricerca dei file nell'archivio.",
  },
  {
    id: 16,
    title: 'Come popolare le tabelle',
    youtubeId: 'g7ArmDs4t1g',
    category: [videoCategories.generale],
    metaDescription:
      'guida compilazione tabelle schermate inserimento dati livello 0 1 suggerimenti operativi inserimento multiplo popolare tabella compilare inserire velocizzare modificare duplicare duplicazione duplicazioni copiare copia replicare replica',
    description:
      'Guida alla compilazione delle tabelle nelle schermate di inserimento dati di Livello 0 e Livello 1. Suggerimenti operativi per un inserimento multiplo.',
  },
  {
    id: 18,
    title: 'Come definire gli elementi del ponte',
    youtubeId: 'coq_4RqZT38',
    category: [videoCategories.generale],
    metaDescription:
      'guida definizione definire elementi elementi geometria materiale materiali fondazioni strutturali accessori costituiscono opera campate campata geometria schede valutazione difetti suggerimenti operativi inserimento inserire multiplo duplicazione ponte ponti descrizione definizione descrivere ',
    description:
      "Guida alla definizione degli elementi, sia strutturali che accessori, che costituiscono l'opera. Definizione delle campate, della geometria degli elementi e delle schede di valutazione dei difetti. Suggerimenti operativi per un inserimento multiplo e per la duplicazione delle campate. ",
  },
  {
    id: 30,
    title: 'Sistema di riferimento',
    youtubeId: 'icH1-sjNX3I',
    category: [videoCategories.generale],
    metaDescription:
      'sistema riferimento codifica automatica elementi elemento strutturali accessori opera ponte numerazione numerati numerato codici codice identificativo id numero asse definire definizione catalogazione catalogare identificare identificazione',
    description:
      "Descrizione del sistema di riferimento e della codifica automatica degli elementi, strutturali e accessori, dell'opera.",
  },
  {
    id: 21,
    title: 'Come compilare le schede dei difetti',
    youtubeId: 'UqNXUct6Vb8',
    category: [videoCategories.generale],
    metaDescription:
      'compilazione compilare schede valutazione difetti definizione difettologiche caricamento immagini suggerimenti operativi duplicazione difettosità difetto gravità estensione intensità rilevabile applicabile presente inserimento inserire velocizzare valutazione difetti duplicarle duplicare',
    description:
      'La compilazione delle schede dei difetti: definizione dei difetti, schede difettologiche e caricamento immagini. Suggerimenti operativi per la duplicazione delle schede.',
  },
  {
    id: 35,
    title: 'Come visualizzare i risultati del Livello 2',
    youtubeId: 'b_uoFwyroBo',
    category: [videoCategories.generale],
    metaDescription:
      'visualizzare visualizzazione risultato risultai automatico automatici classi attenzione parziali complessiva livello 2 cda rischio livelli calcolo risultato valore classe specifiche calcolare',
    description:
      'Come visualizzare il risultato automatico delle Classi di Attenzioni parziali e complessiva: Livello 2.',
  },
  {
    id: 22,
    title: 'Le esportazioni',
    youtubeId: '3lbmbvYJTK8',
    category: [videoCategories.generale],
    metaDescription:
      'esportare esportazione esportazioni dati file precompilati precompilato automatico automatici panoramica modelli allegati linee guida sottoscrizione tipologie tipologia relazioni relazione complete csv compatibili compatibile ainop sottoscrizione pagamento pagare canone licenza ente ispettore ispettori enti esportati esportato schede scheda censimento censimenti ispezione ispezioni difetti difetto difettosità fotografie immagini anagrafica abbonamento',
    description:
      'Come esportare i dati in file precompilati. Panoramica di tutte le possibili esportazioni: avrai a disposizione i file base richiesti negli allegati delle Linee Guida. Con una sottoscrizione potrai esportare anche altre tipologie, quali relazioni complete e file csv, tra cui anche i csv, cumulativi di tutte le opere, compatibili con AINOP.',
  },
  {
    id: 38,
    title: "Come consegnare il lavoro svolto all'Ente committente",
    youtubeId: '2fHAvPwF7eU',
    category: [videoCategories.generale],
    metaDescription:
      'consegna digitale risultati ente gestore committente lavoro dati informazioni consegnare schede consegnare consegnato consegnati lavori committenti recapitati recapitare recapitato corso',
    description:
      "Come effettuare con Inbee la consegna digitale dei risultati all'Ente gestore committente.",
  },
  {
    id: 39,
    title: 'Codice IOP',
    youtubeId: 'MnrPZ00x6w0',
    category: [videoCategories.generale],
    metaDescription:
      'codice iop serve inserirlo inserimento ainop ente enti identificativo opera pubblica archivio informatico opere pubbliche gestore portale codici inserire generare generato generazione',
    description: "Cos'è il codice IOP, a cosa serve e come inserirlo.",
  },
  {
    id: 37,
    title: 'Duplica opera',
    youtubeId: 'CS84tWST2aA',
    category: [videoCategories.generale],
    metaDescription:
      'funzione duplica opera unica sede stradale sorretta più manufatti indipendenti sottoscrizione pagamento pagare canone licenza struttura doppia gemella gemello gemelli uguale uguali replicare dati separati due divisi distaccati staccati autonomi svincolati copia duplicare duplicazione duplicazioni ponte ponti opera strutture abbonamento',
    description:
      "Come utilizzare la funzione del 'Duplica opera' quando un'unica sede stradale è sorretta da due o più manufatti indipendenti.",
  },
  {
    id: 23,
    title: 'I tag, cosa sono e come gestirli',
    youtubeId: 's--0KJoS7W0',
    category: [videoCategories.generale],
    metaDescription:
      'marcare marcatura opere ponti ponte tag  vantaggi vantaggio ricerca assegnazione opera gestione suddivisione organizzare suddividere ripartire partizione dividere assegnare cercare filtrare filtri lavoro collaboratori collaboratore inserire associare',
    description:
      'Come marcare le opere con i tag: modalità e vantaggi nella ricerca e assegnazione.',
  },
  {
    id: 26,
    title: 'Area Collaboratori',
    youtubeId: 'OmoQlgm_tus',
    category: [videoCategories.generale],
    metaDescription:
      'creazione gestione area collaboratori collaboratore interni interno esterni esterno assegnazione opere opera ponti ponte modifica dati sottoscrizione pagamento pagare canone licenza professionista ispettore assegnare modifica modificare utente utenti aggiungere diritti scadenza scadenze accesso associare associazione abbonamento',
    description:
      "Creazione e gestione dell'area collaboratori: differenza tra collaboratori interni ed esterni, assegnazione delle opere, modifica dei dati.",
  },
  {
    id: 29,
    title: 'Le notifiche',
    youtubeId: 'DSy3yUFnXYk',
    category: [videoCategories.generale],
    metaDescription:
      'sistema alert notifiche notifica mail avviso avvisi campanella sottoscrizione pagamento pagare canone licenza',
    description:
      'Descrizione del sistema di alert in piattaforma e notifiche mail.',
  },
  {
    id: 31,
    title: 'Sezione Attività',
    youtubeId: 'XXQIvWQxaQg',
    category: [videoCategories.generale],
    metaDescription:
      'elenco attività ricerca ricercare filtri sezione ricercare cercare area aree',
    description: 'Elenco attività e ricerca tramite filtri.',
  },
  {
    id: 32,
    title: 'Sezioni Immagini e Documenti',
    youtubeId: 'Dq6_67jkv0Y',
    category: [videoCategories.generale],
    metaDescription:
      'elenco immagini documenti immagine documento suddivisione categorie ricerca filtri ricercare cercare sezione area sezioni aree',
    description:
      'Elenco immagini e documenti: suddivisione in categorie e ricerca tramite filtri.',
  },
  {
    id: 36,
    title: 'Timer ponte',
    youtubeId: 'UBPD3VJ5pU0',
    category: [videoCategories.generale],
    metaDescription:
      'ispettore free ape gratuito termine lavoro timer scadenza scaduto scaduta grigio ponte inserimento esportazione esportazioni esportare esporta dati  limita limitare limitazione visualizzazione  mantenere mantenimento archivio digitale attivando attivare attivazione sottoscrizione pagamento pagare canone licenza abbonamento',
    description:
      "Inbee consente di collaborare anche con utenti privi di una sottoscrizione. Questi ultimi potranno inserire dati, sia da piattaforma che tramite l'APP mobile, calcolare in automatico la Classe di Attenzione Complessiva ed esportare i report precompilati ma per i soli ponti condivisi da altri utenti. Inoltre l'accesso all'attività o al ponte condiviso ha una durata limitata per l'utente senza sottoscrizione.",
  },
  {
    id: 40,
    title: 'Trasferimento dati ad AINOP',
    youtubeId: 'YBYYPoraOQk',
    category: [videoCategories.generale],
    metaDescription:
      'ainop archivio minstero pubblico portale ministeriale informatico database iop codice trasferimento trasferire ente gestore proprietario inserimento inserire inviare invio mandare comunicare comunicazione inserimento inserire csv excel file trasmissione dati anagrafica specifica base caricamento caricare velocizzare velocemente veloce',
    description:
      "AINOP è l'Archivio Informatico Nazionale delle Opere Pubbliche, ovvero il portale ministeriale che raccoglie le informazioni sulle opere. L'Ente gestore deve trasmettere qui alcuni dati dei suoi Ponti e Viadotti. Inbee può velocizzare molto il processo di inserimento dati su AINOP generando i file richiesti per il caricamento dei dati. ",
  },
];
