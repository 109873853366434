import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  RHFNumberTextField,
  RHFTagAutocomplete,
} from 'components';
import { useInspectionPath } from 'hooks';
import useTabellaRiepilogativa from 'hooks/inspections/useTabellaRiepilogativa';
import { useInspectionSelector } from 'stores';

const CampateStrutturaForm = ({
  campateFields,
  selected,
  setSelected,
  tableName,
}) => {
  const { getValues, setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const methods = useFormContext({
    defaultValues: {
      codiceCampataTableValue: [],
      luceTableValue: '',
      traviTableValue: '',
      traversiTableValue: '',
      controventiTableValue: '',
      solettaTableValue: '',
      archiTableValue: '',
      piedrittiTableValue: '',
      tirantiTableValue: '',
      elementiExtraTableValue: '',
    },
  });

  /**
   * this hook is used to refresh the riepilogoStrutturaTable
   * since that the number of elements/or subelement that can be changed here in L0'
   * will unvalidate any other previous calculation on the CDA
   */

  const { recalculateTable } = useTabellaRiepilogativa();

  useEffect(() => {
    setValue('codiceCampataTableValue', selected);
  }, [selected]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitCampate = () => {
    const luceTableValue = getValues('luceTableValue');
    const traviTableValue = getValues('traviTableValue');
    const traversiTableValue = getValues('traversiTableValue');
    const controventiTableValue = getValues('controventiTableValue');
    const solettaTableValue = getValues('solettaTableValue');
    const archiTableValue = getValues('archiTableValue');
    const piedrittiTableValue = getValues('piedrittiTableValue');
    const tirantiTableValue = getValues('tirantiTableValue');
    const elementiExtraTableValue = getValues('elementiExtraTableValue');

    const ids = selected.map(el => el.campata);

    const campate = [...campateFields];

    campate.map((x, index) => {
      if (ids.includes(x?.campata)) {
        campate[index] = {
          id: x.id,
          campata: x.campata,
          luce: luceTableValue,
          travi: traviTableValue,
          traversi: traversiTableValue,
          controventi: controventiTableValue,
          soletta: solettaTableValue,
          archi: archiTableValue,
          piedritti: piedrittiTableValue,
          tiranti: tirantiTableValue,
          elementiExtra: elementiExtraTableValue,
        };
      }
    });

    setValue(tableName, campate);
    onResetCampate();
  };

  const onResetCampate = () => {
    setValue('codiceCampataTableValue', []);
    setValue('luceTableValue', '');
    setValue('traviTableValue', '');
    setValue('traversiTableValue', '');
    setValue('controventiTableValue', '');
    setValue('solettaTableValue', '');
    setValue('archiTableValue', '');
    setValue('piedrittiTableValue', '');
    setValue('tirantiTableValue', '');
    setValue('elementiExtraTableValue', '');
    setSelected([]);
  };

  const watchMensoleTamponiCampateImpalcatoTable = watch(
    'mensoleTamponiCampateImpalcatoTable'
  );
  useEffect(() => {
    if (watchMensoleTamponiCampateImpalcatoTable?.length > 0) {
      recalculateTable();
    }
  }, [watchMensoleTamponiCampateImpalcatoTable]);

  return (
    <Grid container spacing={2} padding="20px">
      <Grid item xs={6}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-campata"
          name={'codiceCampataTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          disabled={inspectionDisabled || isViewingInspection}
          labelName={'option.campata'}
          getOptionLabel={option => option.campata}
          opt={campateFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Elementi selezionati"
        />
      </Grid>

      {tableName === 'mensoleTamponiTable' && (
        <Grid item xs={3}>
          <RHFNumberTextField
            name="luceTableValue"
            label={'Luce M/T (m)*'}
            defaultValue=""
            decimalScale={2}
            disabled={inspectionDisabled || isViewingInspection}
            withValueLimit={({ value }) => value <= 999}
            onChange={e => {
              methods.setValue('luceTableValue', e.target.value);
            }}
          />
        </Grid>
      )}

      <Grid item xs={3}>
        <RHFNumberTextField
          name="traviTableValue"
          label={'Travi (n°)*'}
          defaultValue=""
          decimalScale={0}
          disabled={inspectionDisabled || isViewingInspection}
          withValueLimit={({ value }) => value <= 999}
          onChange={e => {
            methods.setValue('traviTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="traversiTableValue"
          label={'Traversi (n°)*'}
          defaultValue=""
          decimalScale={0}
          disabled={inspectionDisabled || isViewingInspection}
          withValueLimit={({ value }) => value <= 999}
          onChange={e => {
            methods.setValue('traversiTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="controventiTableValue"
          label={'Controventi (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          disabled={inspectionDisabled || isViewingInspection}
          onChange={e => {
            methods.setValue('controventiTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="solettaTableValue"
          label={'Soletta (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          disabled={inspectionDisabled || isViewingInspection}
          onChange={e => {
            methods.setValue('solettaTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="archiTableValue"
          label={'Archi (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          disabled={inspectionDisabled || isViewingInspection}
          onChange={e => {
            methods.setValue('archiTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="piedrittiTableValue"
          label={'Piedritti (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          disabled={inspectionDisabled || isViewingInspection}
          onChange={e => {
            methods.setValue('piedrittiTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="tirantiTableValue"
          label={'Tiranti (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          disabled={inspectionDisabled || isViewingInspection}
          onChange={e => {
            methods.setValue('tirantiTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="elementiExtraTableValue"
          label={'Elementi extra (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          disabled={inspectionDisabled || isViewingInspection}
          onChange={e => {
            methods.setValue('elementiExtraTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={6} alignSelf="center">
        <FormSubmitButtons
          disabled={inspectionDisabled || isViewingInspection}
          onSubmit={onSubmitCampate}
          onReset={onResetCampate}
        />
      </Grid>
    </Grid>
  );
};
export { CampateStrutturaForm };

CampateStrutturaForm.propTypes = {
  tableName: PropTypes.string,
  campateFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      campataStrutturaTableValue: PropTypes.string,
      luceTableValue: PropTypes.string,
      traviTableValue: PropTypes.number,
      traversiTableValue: PropTypes.number,
      solettaTableValue: PropTypes.number,
      archiTableValue: PropTypes.number,
      piedrittiTableValue: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};
