import { Button } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import professionistaIcon from 'assets/icons/professionista.png';
import enteIcon from 'assets/icons/town-hall.svg';
import { BasicDialog, InfoAssistanceDialogActions } from 'components';
import {
  COLLABORATORS_PATHS,
  MY_AREA,
  PROFILE_AND_SUBSCRIPTION,
} from 'constants/paths';
import { SubscriptionStatus } from 'constants/subscription';
import { UserType, UserTypology } from 'constants/users';
import { useSubscription } from 'hooks';
import { ElementContainer, FlexContainer } from './StandardPopover.style';
import { colors } from '../../../../constants/colors';

export const StandardPopover = ({
  label,
  name,
  email,
  text,
  personalField,
  onClickButton,
  userType,
  userTypology,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { subscriptionStatus } = useSubscription();

  const navigate = useNavigate();

  const regexDomainEmail1 = /@tecnoindagini.it$/g;
  const regexDomainEmail2 = /@inbee.it$/g;

  const isTecnoEmail =
    email?.match(regexDomainEmail1) || email?.match(regexDomainEmail2);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let icon;
  switch (userType) {
    case 'ENTE':
      icon = enteIcon;
      break;
    case 'PROFESSIONISTA':
      icon = professionistaIcon;
      break;
    default:
      icon = professionistaIcon;
      break;
  }

  return (
    <div>
      <FlexContainer>
        {personalField === 'Collaboratori' && (
          <img src={icon} width={16} height={16} />
        )}

        <Typography
          onClick={handleClick}
          sx={{ fontWeight: 'bold' }}
          /* sx={{ p: 1, textAlign: 'right', fontWeight: 'bold' }} */
        >
          {label}
        </Typography>
      </FlexContainer>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ElementContainer
          padding="12px"
          /* style={{ width: label === 'Collaboratori' && 280 }} */
        >
          <Typography
            style={{ padding: '0 12px' }}
            sx={{ p: 1, textAlign: 'right', fontWeight: 'bold' }}
          >
            {name}
          </Typography>
          <Typography
            style={{ padding: '0 12px' }}
            sx={{ p: 1, textAlign: 'right' }}
          >
            {email}
          </Typography>
        </ElementContainer>
        <div
          style={{
            width: '100%',
            height: '2px',
            backgroundColor: colors.GREY,
          }}
        ></div>
        <ElementContainer>
          <Typography sx={{ p: 1 }}>{text}</Typography>
        </ElementContainer>
        {personalField === 'Logout' && (
          <ElementContainer padding="12px 24px 12px">
            <Typography
              textAlign={'right'}
              onClick={() => {
                navigate(MY_AREA);
                handleClose();
              }}
              sx={{ p: 1, '&:hover': { cursor: 'pointer' } }}
            >
              Area Personale
            </Typography>
            <Button
              onClick={onClickButton}
              variant="outlinedSecondary"
              sx={{
                width: '100%',
                border: '1px solid',
                padding: '24px',
                marginTop: '10px',
              }}
            >
              <Typography sx={{ p: 1 }}>{personalField}</Typography>
            </Button>
          </ElementContainer>
        )}
        {personalField === 'Collaboratori' && (
          <ElementContainer padding="12px 24px 12px">
            <Typography
              textAlign={'right'}
              onClick={() => {
                navigate(PROFILE_AND_SUBSCRIPTION);
                handleClose();
              }}
              sx={{ p: 1, '&:hover': { cursor: 'pointer' } }}
            >
              Profilo e sottoscrizione
            </Typography>
            {userTypology === UserTypology.UtentePrincipale && (
              <>
                {(subscriptionStatus === SubscriptionStatus.SCADUTA ||
                  subscriptionStatus === SubscriptionStatus.ND) && (
                  <Typography
                    textAlign={'right'}
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                    sx={{
                      p: 1,
                      opacity: 0.5,
                      '&:hover': { cursor: 'pointer' },
                    }}
                  >
                    Collaboratori
                  </Typography>
                )}
                {subscriptionStatus === SubscriptionStatus.ATTIVA && (
                  <Typography
                    textAlign={'right'}
                    onClick={() => {
                      navigate(COLLABORATORS_PATHS.ROOT);
                      handleClose();
                    }}
                    sx={{ p: 1, '&:hover': { cursor: 'pointer' } }}
                  >
                    Collaboratori
                  </Typography>
                )}
              </>
            )}
          </ElementContainer>
        )}
      </Popover>
      <BasicDialog
        open={openDialog}
        onClose={handleCloseDialog}
        actions={
          <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />
        }
      >
        <Typography>
          L&apos;area Collaboratori è disponibile solo per gli utenti con
          sottoscrizione. Per sottoscrivere compila il form contattaci.
        </Typography>
      </BasicDialog>
    </div>
  );
};

StandardPopover.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  text: PropTypes.string,
  personalField: PropTypes.string,
  onClickButton: PropTypes.func,
  userType: PropTypes.string,
  userTypology: PropTypes.number,
};
