import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import * as PATHS from 'constants/paths';
import { useAuthSelector, useGlobalSelector } from 'stores';

// import { PATH_DASHBOARD } from '../routes/paths';

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuthSelector();

  const { requestedLocation } = useGlobalSelector();

  if (isAuthenticated) {
    return <Navigate to={requestedLocation || PATHS.ROOT_PATH} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export { GuestGuard };
