import PropTypes from 'prop-types';
import Arrow from 'assets/icons/arrow.svg';
import Logo from 'assets/icons/logoInBee.svg';

import { BackContainer, LogoContainer } from './LogoBack.style';

export const LogoBack = ({ path }) => {
  return (
    <>
      <BackContainer>
        <a href={path}>
          <img src={Arrow} alt="" />
        </a>
      </BackContainer>
      <LogoContainer>
        <img src={Logo} alt="" />
      </LogoContainer>
    </>
  );
};

LogoBack.propTypes = {
  path: PropTypes.string.isRequired,
};
