import styled from '@emotion/styled';
import { Button, Divider } from '@mui/material';
import { RHFApiAutocomplete, RHFTextField } from 'components';

export const HeaderWrapper = styled('div')({
  display: 'flex',
});

export const ButtonContainer = styled('div')({
  display: 'flex',
});

export const ButtonWrapper = styled(Button)({
  margin: '5px',
});

export const CustomDivider = styled(Divider)({
  marginTop: '20px',
  marginBottom: '20px',
});

export const CustomTextField = styled(RHFTextField)({
  margin: '5px',
  width: 'calc(100% - 10px)',
});

export const CustomAutocomplete = styled(RHFApiAutocomplete)({
  margin: '5px',
  width: 'calc(100% - 10px)',
});
