export const exampleEmptyCda = {
  class: 6,
  structuralFoundationalCda: {
    class: 6,
    danger: {
      class: 6,
    },
    vulnerability: {
      class: 6,
      section1: {
        section: {
          isClassApproximate: false,
        },
      },
      section2: {
        section: {
          section: {
            defectiveness: 'N.D.',
          },
        },
      },
    },
    exposure: {
      class: 6,
      section: {
        section: {
          section: {},
        },
      },
    },
  },
  seismicCda: {
    class: 6,
    danger: {
      class: 6,
      section: {},
    },
    vulnerability: {
      class: 6,
      section1: {
        section: {
          section: {},
        },
      },
      section2: {
        difettositaSismica: 'N.D.',
      },
    },
    exposure: {
      class: 6,
      section: {
        value: 6,
        section: {
          section: {
            section: {},
          },
        },
      },
    },
  },
  landslideAndHydraulicRiskCda: {
    class: 6,
    landslideRiskCda: {
      class: 6,
      susceptibility: {
        class: 6,
        section: {
          section: {
            missingPaPc: 'required',
          },
        },
      },
      vulnerability: {
        class: 6,
        section: {
          section: {},
        },
      },
      exposure: {
        class: 6,
        section: {
          value: 6,
          section: {
            section: {
              section: {},
            },
          },
        },
      },
    },
    hydraulicRiskCda: {
      class: 6,
      overlapCda: {
        class: 6,
        danger: {
          class: 6,
          section1: {},
          section2: {},
        },
        vulnerability: {
          class: 6,
        },
        exposure: {
          class: 6,
          section: {
            value: 6,
            section: {
              section: {
                section: {},
              },
            },
          },
        },
      },
      erosionCda: {
        class: 6,
        generalizedErosionCda: {
          class: 6,
          danger: {
            class: 6,
            cg: '0',
          },
          vulnerability: {
            class: 6,
          },
          exposure: {
            class: 6,
            section: {
              value: 6,
              section: {
                section: {
                  section: {},
                },
              },
            },
          },
        },
        localizedErosionCda: {
          class: 6,
          danger: {
            class: 6,
          },
          vulnerability: {
            class: 6,
          },
          exposure: {
            class: 6,
            section: {
              value: 6,
              section: {
                section: {
                  section: {},
                },
              },
            },
          },
        },
      },
    },
  },
};
