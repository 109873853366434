import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  InspectionMainTab,
  InspectionRouteActions,
  InspectionStatus,
} from 'constants/inspections';
import { STRUCTURES_PATHS } from 'constants/paths';
import { useCompletionInspection } from 'hooks';
import { useGlobalSelector, useInspectionSelector } from 'stores';

const CreateInspectionButtons = ({
  setWebCompletionPercentage,
  setAppCompletionPercentage,
  action,
  structure,
  inspectionId,
}) => {
  const { activeTabLevel } = useGlobalSelector();
  const { getWebCompletionPercentage, getAppCompletionPercentage } =
    useCompletionInspection();
  const navigate = useNavigate();
  const { inspectionDisabled } = useInspectionSelector();

  const EditCreateButtons = (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={() => navigate(-1)}
      >
        Annulla
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        type="submit"
        disabled={inspectionDisabled}
        onClick={() => {
          setWebCompletionPercentage(getWebCompletionPercentage());
          setAppCompletionPercentage(getAppCompletionPercentage());
        }}
      >
        Salva
      </Button>
      {/* <Button
        variant="contained"
        type="submit"
        color="primary"
        size="small"
        disabled={inspectionDisabled}
        onClick={() => setStatus(InspectionStatus.Confirmed)}
      >
        Conferma e salva
      </Button> */}
    </>
  );

  const ViewButtons = (
    <Button
      variant="contained"
      type="submit"
      color="primary"
      size="small"
      disabled={inspectionDisabled}
      onClick={() =>
        navigate(
          STRUCTURES_PATHS.INSPECTION_EDIT.replace(
            ':structureId',
            structure?.id
          ).replace(':inspectionId', inspectionId)
        )
      }
    >
      Modifica
    </Button>
  );

  const InspectionActiveTabActionsButtonsMap = {
    [InspectionMainTab.OVERVIEW]: <></>,
    [InspectionMainTab.L0_CENSIMENTO_ANAGRAFICA]: EditCreateButtons,
    [InspectionMainTab.L1_ISPEZIONI_PRELIMINARI]: EditCreateButtons,
    [InspectionMainTab.L2_CLASSI_DI_ATTENZIONE]: <></>,
  };
  const InspectionRouteActionsButtonsMap = {
    [InspectionRouteActions.EDIT]:
      InspectionActiveTabActionsButtonsMap[activeTabLevel],
    [InspectionRouteActions.CREATE]:
      InspectionActiveTabActionsButtonsMap[activeTabLevel],
    [InspectionRouteActions.VIEW]: ViewButtons,
  };

  return InspectionRouteActionsButtonsMap[action];
};

export default CreateInspectionButtons;
