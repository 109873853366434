import styled from '@emotion/styled';

export const RateContainer = styled('div')({
  width: '100%',
  height: '35px',
  display: 'flex',
});

export const RateItem = styled('div')(({ customColors, theme }) => ({
  height: '35px',
  minWidth: '35px',
  borderRadius: '100%',
  backgroundColor: customColors?.bgColor || theme.palette.secondary.light,
  color: customColors?.color || theme.palette.error.light,
  border: `1px solid ${customColors?.border || theme.palette.error.light}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  marginRight: '2px',
}));
