import styled from '@emotion/styled';
import { TableRow } from '@mui/material';
import { colors } from 'constants/colors';

export const CustomRow = styled(TableRow)(({ typevariant }) => ({
  backgroundColor:
    typevariant === 'secondary' ? colors.LIGHTGREY : colors.WHITE,
  border:
    typevariant === 'secondary'
      ? `5px solid ${colors.WHITE} !important`
      : `inherit`,
}));

export const IconButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

export const ZoomImgContainer = styled('div')({
  position: 'absolute',
  top: 95,
  left: 0,
  zIndex: 2,
  padding: 40,
  backgroundColor: colors.LIGHTGREY,
  height: 'calc(100% - 120px)',
  width: '100%',
});

export const ZoomCloseIconContainer = styled('div')({
  position: 'absolute',
  borderRadius: '50%',
  top: 33,
  right: 38,
  cursor: 'pointer',
});

export const ZoomedImage = styled('img')({
  width: '100%',
  padding: 16,
  borderRadius: 8,
  backgroundColor: colors.WHITE,
});
