import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import ArrowIcon from 'assets/icons/arrow-down.svg';
import { colors } from 'constants/colors';

export const CustomFormControl = styled(FormControl)({
  '& svg': {
    backgroundImage: `url(${ArrowIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    fill: 'none',
    backgroundSize: '60%',
  },
  '& label': { fontSize: '0.8rem', fontFamily: 'Ubuntu' },
  '& label.Mui-focused': {
    color: colors.BLACK,
    fontWeight: 'bold',
  },
});

export const ListSubheaderCustom = styled(ListSubheader)({
  color: colors.BLACK,
  fontSize: 16,
  fontFamily: 'Ubuntu',
  backgroundColor: colors.LIGHTGREY,
});

export const MenuItemCustom = styled(MenuItem)({
  fontFamily: 'Ubuntu',
});
