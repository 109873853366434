import { Clear } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { CHANGE_TYPOLOGY_OR_IMPOSTFOND_WARNING_MSG } from 'constants/difetti';

const ChangeElementTypologyDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <Clear />
        </IconButton>
      ) : null}
      <DialogTitle>
        <Typography variant="h1">Attenzione</Typography>
      </DialogTitle>
      <DialogContent>{CHANGE_TYPOLOGY_OR_IMPOSTFOND_WARNING_MSG}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annulla</Button>
        <Button variant="contained" onClick={onConfirm}>
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangeElementTypologyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export { ChangeElementTypologyDialog };
