import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import { colors } from 'constants/colors';
import { SubscriptionStatus } from 'constants/subscription';
import { useSubscription } from 'hooks';
import { useAuthSelector } from 'stores';

import { selectOptions } from './constants';
import { CustomFormControl, MenuItemCustom } from './ExportSelect.styles';

const ExportSelect = ({ handleSelected, isSelected, getFileValue }) => {
  const { user } = useAuthSelector();
  const { subscriptionStatus } = useSubscription();
  const userType = user?.type;

  // Special case for ID 66 (email: ispezioni@tecnoindagini.it)
  const isSpecialCase =
    (user?.id === 66 && user?.email === 'ispezioni@tecnoindagini.it') ||
    (user?.id === 812 && user?.email === 'support@inbee.it');
  const isCollSpecialCase = user?.parent?.id === 66 || user?.parent?.id === 812;
  // Special case for domain
  const regexDomainEmail1 = /@tecnoindagini.it$/g;
  const regexDomainEmail2 = /@inbee.it$/g;
  const isTecnoEmail =
    user.email?.match(regexDomainEmail1) ||
    user.eamil?.match(regexDomainEmail2);

  const isInSpecialGroup = isSpecialCase || isCollSpecialCase || isTecnoEmail;

  return (
    <div>
      <CustomFormControl style={{ width: '100%' }}>
        <InputLabel>Tipologia esportazione</InputLabel>
        <Select>
          <MenuItemCustom
            value=""
            onClick={() => isSelected && handleSelected(false)}
          >
            <em>Nessun valore</em>
          </MenuItemCustom>

          {selectOptions?.map((option, idx) => {
            // check if exists the property "visible" anche check
            // if the logged user is part of this group
            if (
              (option.visible && option.visible?.includes(userType)) ||
              !option.visible ||
              isInSpecialGroup
            ) {
              const checkDisabledForSubscription =
                option.disabledIfNoSubscription
                  ? subscriptionStatus !== SubscriptionStatus.ATTIVA
                  : false;
              if (option.value === null || option.value === 0) {
                if (option.value === null) {
                  // titolo categoria
                  return (
                    <MenuItemCustom
                      key={idx}
                      disabled
                      value={option.value}
                      style={{
                        fontSize: 13,
                        fontWeight: 'bold',
                        color: colors.BLACK,
                        opacity: 1,
                      }}
                    >
                      {option.label}
                    </MenuItemCustom>
                  );
                } else {
                  // titolo sotto categoria
                  return (
                    <MenuItemCustom
                      key={idx}
                      disabled
                      value={option.value}
                      style={{
                        fontSize: 17,
                        fontWeight: 'bold',
                        color: colors.BLACK,
                        textTransform: 'uppercase',
                        opacity: 1,
                      }}
                    >
                      {option.label}
                    </MenuItemCustom>
                  );
                }
              } else {
                // opzione effettiva
                return (
                  <MenuItemCustom
                    key={idx}
                    value={option.value}
                    disabled={
                      option.disabled ||
                      (checkDisabledForSubscription && !isInSpecialGroup)
                    }
                    onClick={() => {
                      handleSelected(true);
                      getFileValue(option.value);
                    }}
                  >
                    {option.label}
                  </MenuItemCustom>
                );
              }
            }
          })}
        </Select>
      </CustomFormControl>
    </div>
  );
};

export { ExportSelect };

ExportSelect.propTypes = {
  handleSelected: PropTypes.func,
  isSelected: PropTypes.bool,
  getFileValue: PropTypes.func,
};
