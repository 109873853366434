import { Typography } from '@mui/material';

import { MediaUpload, RHFTextField } from 'components';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap, TitleSection } from './Accordion3CommentiPerReportL1.styles';

const Accordion3CommentiPerReportL1 = () => {
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();
  return (
    <>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">{'IMPALCATO'}</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneSovrastrutturaImpalcato"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Immagini`}
            btnTitle="Seleziona"
            preview={true}
            formName="fotoSovrastrutturaImpalcato"
            infoPanel="Seleziona le foto tra quelle già caricate per l'ispezione, è necessario salvare l'ispezione per visualizzare le ultime immagini caricate"
            onlySelection={true}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={40} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              {'OSSERVAZIONI ELEMENTI ACCESSORI'}
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={12} marginTop={-10}>
          <RHFTextField
            multiline
            name="descrizioneOsservazioniSovrastrutture"
            label={'Descrizione'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      </GridWrap>
    </>
  );
};

export { Accordion3CommentiPerReportL1 };
