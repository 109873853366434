import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import theme from 'theme';
import { AvatarContainer } from './AvatarImage.style';

export const AvatarImage = ({ image }) => {
  return (
    <AvatarContainer>
      <img src={image} alt="" />
      <Avatar sx={{ bgcolor: theme.palette.secondary.main }} className="black">
        LC1
      </Avatar>
    </AvatarContainer>
  );
};

AvatarImage.propTypes = {
  image: PropTypes.string,
};
