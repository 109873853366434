import { styled } from '@mui/system';
import { colors } from '../../constants/colors';

export const PageContainer = styled('div')({
  backgroundColor: colors.WHITE,
});

export const LogoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '12px 80px 0px',
});

export const Logo = styled('img')(({ theme }) => ({
  height: 60,
  width: 160,
  marginRight: 50,
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    marginBottom: 20,
    width: 130,
  },
}));

export const ArrowIcon = styled('img')({
  cursor: 'pointer',
  marginBottom: 10,
});

export const ContentContainer = styled('div')(({ isTablet, theme }) => ({
  display: 'flex',
  flexDirection: isTablet ? 'column' : 'row',
  justifyContent: isTablet ? 'center' : 'space-between',
  alignItems: 'center',
  padding: isTablet ? '40px' : '40px 80px',
  [theme.breakpoints.up('lg')]: {
    height: '90vh',
    /* paddingBottom: 45, */
    marginTop: 20,
  },
}));

export const ImageContainer = styled('div')(({ isTablet, theme }) => ({
  width: isTablet ? '50%' : '48%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: isTablet ? 30 : 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const FormContainer = styled('div')(({ isTablet }) => ({
  width: isTablet ? '100%' : '48%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: isTablet ? 'center' : 'flex-start',
  textAlign: isTablet && 'center',
}));

export const RegistrationImage = styled('img')({
  width: '100%',
  objectFit: 'contain',
});
