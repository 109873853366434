import styled from '@emotion/styled';
import { colors } from '../../../constants/colors';

export const SideBarContainer = styled('div')(({ close }) => ({
  backgroundColor: colors.WHITE,
  width: close ? 100 : 321,
  height: '100%',
  position: 'fixed',
  top: 0,
  zIndex: 12,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'auto',
}));

export const LogoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderBottom: `3px solid ${colors.GREY}`,
});

export const Logo = styled('img')({
  width: '180px',
  height: '45px',
  objectFit: 'contain',
});

export const ElementMenuContainer = styled('div')(
  ({ selected, close, isInfoAssistance }) => ({
    width: '100%',
    padding: '20px 35px',
    borderBottom: `3px solid ${colors.GREY}`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: close ? 'center' : 'flex-start',
    gap: 12,
    backgroundColor: isInfoAssistance
      ? `${colors.WHITE}`
      : selected
      ? `${colors.LIGHTGREY}`
      : `${colors.WHITE}`,
  })
);

export const HamburgerContainer = styled('div')(({ close }) => ({
  marginTop: 10,
  padding: close ? '10px 37px 30px' : '10px 30px 30px',
}));

export const HamburgerIcon = styled('div')(({ close }) => ({
  width: 24,
  height: 14,
  position: 'relative',
  margin: 'auto 5px',
  WebkitTransform: 'rotate(0deg)',
  transform: 'rotate(0deg)',
  WebkitTransition: '.5s ease-in-out',
  MozTransition: '.5s ease-in-out',
  transition: '.5s ease-in-out',
  cursor: 'pointer',
  '& span': {
    backgroundColor: 'black',
    display: 'block',
    position: 'absolute',
    height: 2,
    width: '100%',
    borderRadius: 9,
    opacity: 1,
    left: 0,
    WebkitTransform: 'rotate(0deg)',
    transform: 'rotate(0deg)',
    WebkitTransition: '.25s ease-in-out',
    MozTransition: '.25s ease-in-out',
    transition: '.25s ease-in-out',
  },
  '& span:nth-of-type(1)': {
    top: 0,
    left: 0,
    WebkitTransformOrigin: 'left center',
    MozTransformOrigin: 'left center',
    transformOrigin: 'left center',
    WebkitTransform: close ? 'rotate(90deg)' : 'rotate(0deg)',
    transform: close ? 'rotate(90deg)' : 'rotate(0deg)',
  },
  '& span:nth-of-type(2)': {
    top: close ? 0 : '50%',
    left: close ? 8 : 0,
    WebkitTransformOrigin: 'left center',
    MozTransformOrigin: 'left center',
    transformOrigin: 'left center',
    WebkitTransform: close ? 'rotate(90deg)' : 'rotate(0deg)',
    transform: close ? 'rotate(90deg)' : 'rotate(0deg)',
  },
  '& span:nth-of-type(3)': {
    top: close ? 0 : '100%',
    left: close ? 16 : 0,
    WebkitTransformOrigin: 'left center',
    MozTransformOrigin: 'left center',
    transformOrigin: 'left center',
    WebkitTransform: close ? 'rotate(90deg)' : 'rotate(0deg)',
    transform: close ? 'rotate(90deg)' : 'rotate(0deg)',
  },
}));

export const BtnContainer = styled('div')(({ close }) => ({
  padding: close ? '45px 20px' : '45px 35px',
  width: '100%',
  marginTop: 10,
  '& > *': {
    marginTop: 10,
  },
}));
