import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ArrowBack from 'assets/icons/arrow.svg';
import { ImgContainer } from './ArrowBackAndTitle.styles';

const ArrowBackAndTitle = ({ tooltipText, onClick, title }) => {
  const navigate = useNavigate();

  return (
    <Typography variant="h1">
      {onClick && (
        <Tooltip title={tooltipText || 'Back'}>
          <ImgContainer
            src={ArrowBack}
            alt=""
            onClick={() => {
              navigate(onClick);
            }}
          />
        </Tooltip>
      )}
      {title}
    </Typography>
  );
};

ArrowBackAndTitle.propTypes = {
  tooltipText: PropTypes.string,
  onClick: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export { ArrowBackAndTitle };
