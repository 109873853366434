import { Auth } from '@aws-amplify/auth';
import { useCallback } from 'react';
import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { useGlobalStore } from './global';

export const AUTH_STORE_STORAGE_PREFIX = 'ti-';
export const SESSION_TOKEN_STORAGE_KEY = `${AUTH_STORE_STORAGE_PREFIX}sessionToken`;

const DEFAULT_VALUES = {
  isAuthenticated: false,
  cognitoData: null, //dati dall'utente cognito per gestire i ruoli
  user: null, // info utente API BE
  challengedUser: null, //cambio password iniziale
  sessionToken: null, // primo token
  // role: {
  //   name: null,
  // isAdmin: false,
  // isBackofficeOperator: false,
  // isHotel: false,
  // isManager: false,
  // isStakeholder: false,
  // },
};

const resetDefaults = () => useStore.setState({ ...DEFAULT_VALUES });

// TODO: Better describe cognito types
const buildCognitoData = cognitoUser => {
  const { attributes } = cognitoUser;
  const builtCognitoData = {
    attributes,
    group:
      cognitoUser.signInUserSession?.idToken.payload['cognito:groups']?.[0],
    hasCompleteData:
      !!attributes.preferred_username && !!attributes.phone_number,
  };

  return builtCognitoData;
};

const handleLogin = async (username, password, rememberUser = false) => {
  try {
    useGlobalStore.setState({ loading: true });
    const cognitoUser = await Auth.signIn(username.trim(), password);

    if (cognitoUser.challengeName) {
      useStore.setState({ challengedUser: cognitoUser });
    } else {
      const cognitoData = buildCognitoData(cognitoUser);

      let sessionToken = null;
      if (!rememberUser) {
        sessionToken = cognitoUser.signInUserSession?.idToken.jwtToken;
        localStorage.setItem(SESSION_TOKEN_STORAGE_KEY, sessionToken);
      }

      /* useStore.setState({ cognitoData, sessionToken }); */
      useStore.setState({ sessionToken });
      return cognitoData;
    }
    // eslint-disable-next-line no-useless-catch
  } catch (error) {
    throw error;
  } finally {
    useGlobalStore.setState({ loading: false });
  }
};

const handleLogout = async () => {
  try {
    await Auth.signOut();
  } catch (err) {
    console.log(err);
  }
  localStorage.removeItem(SESSION_TOKEN_STORAGE_KEY);
  resetDefaults();
};

const getSession = async ({ bypassCache } = { bypassCache: false }) => {
  try {
    await Auth.currentSession();
    const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache });
    const cognitoData = buildCognitoData(cognitoUser);
    const sessionToken = localStorage.getItem(SESSION_TOKEN_STORAGE_KEY);
    useStore.setState({ cognitoData, sessionToken });
  } catch (error) {
    handleLogout();
  }
};

const getRefreshedToken = async () => {
  try {
    const cognitoUserSession = await Auth.currentSession();
    const refreshedToken = cognitoUserSession.getIdToken().getJwtToken();
    return refreshedToken;
  } catch (error) {
    return null;
  }
};

const setNewPassword = async (user, password) => {
  try {
    useGlobalStore.setState({ loading: true });
    await Auth.completeNewPassword(user, password);
  } catch (error) {
    console.log(error);
  } finally {
    useGlobalStore.setState({ loading: false });
  }
};

const setAdditionalData = async (username, phone) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(currentUser, {
      preferred_username: username,
      phone_number: phone,
    });
  } catch (error) {
    throw error;
  }
};

const handleForgotPassword = async username => {
  try {
    useGlobalStore.setState({ loading: true });
    await Auth.forgotPassword(username);
    // eslint-disable-next-line no-useless-catch
  } catch (error) {
    throw error;
  } finally {
    useGlobalStore.setState({ loading: false });
  }
};

const handleForgotPasswordSubmit = async (username, code, password) => {
  try {
    useGlobalStore.setState({ loading: true });
    await Auth.forgotPasswordSubmit(username, code, password);
  } catch (error) {
    console.error(error);
  } finally {
    useGlobalStore.setState({ loading: false });
  }
};

const handleChangePassword = async (oldPassword, newPassword) => {
  try {
    useGlobalStore.setState({ loading: true });
    const user = await Auth.currentAuthenticatedUser();
    const res = await Auth.changePassword(user, oldPassword, newPassword);
    if (res) {
      return res;
    }
  } catch (error) {
    return false;
  } finally {
    useGlobalStore.setState({ loading: false });
  }
};

const handleRoles = cognitoData => {
  const { group } = cognitoData;
  const role = {
    name: group,
    // isAdmin: group === UserType[UserType.ADMIN],
    // isBackofficeOperator: group === UserType[UserType.BACKOFFICE_OPERATOR],
    // isHotel: group === UserType[UserType.HOTEL],
    // isStakeholder: group === UserType[UserType.STAKEHOLDER],
    // isManager: group === UserType[UserType.MANAGER],
  };
  useStore.setState({ role });
};

const useStore = create(
  subscribeWithSelector(() => ({
    ...DEFAULT_VALUES,
    handleLogin,
    handleLogout,
    getSession,
    getRefreshedToken,
    setNewPassword,
    setAdditionalData,
    handleForgotPassword,
    handleForgotPasswordSubmit,
    handleChangePassword,
    reset: resetDefaults,
  }))
);

useStore.subscribe(
  state => state.cognitoData,
  cognitoData => {
    useStore.setState({ isAuthenticated: !!cognitoData });
    cognitoData && handleRoles(cognitoData);
  }
);

const useAuthSelector = () => useStore(useCallback(state => state, []));

export { useAuthSelector, useStore as useAuthStore };
