import styled from '@emotion/styled';

export const ElementContainer = styled('div')(() => ({
  padding: '12px',

  display: 'flex',
  flexDirection: 'column',
}));

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'end',
  flex: 1,
}));

export const Container = styled('div')(() => ({
  display: 'flex',
  marginTop: 5,
}));

export const Box = styled('div')(() => ({
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  justifyContent: 'center',
}));
