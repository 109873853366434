export const markdown_faq_3_1 = `Inbee offre diversi canali di supporto.

**GUIDA INTERATTIVA**
Attivabile in qualsiasi momento, ti aiuterà ad orientarti e ti darà suggerimenti per una corretta compilazione.`;
export const markdown_faq_3_2 = `**INFO & ASSISTENZA**
Sezione della piattaforma che include diversi tipi di supporto:
- nella sezione **GUIDE** potete trovare le guide PDF per l'utilizzo della piattaforma e dell'APP e gli approfondimenti normativi;
- nella sezione **VIDEO TUTORIAL** troverete tutti i tutorial video con spiegazioni sul funzionamento della piattaforma e dell'APP.`;
export const markdown_faq_3_3 = `Se non trovi quello che cerchi, o se vuoi sottoporci una questione specifica, non esitare a contattarci direttamente dal form.
`;
