import {
  Archive,
  Login as Download,
  Edit,
  Visibility as Eye,
  LockOutlined as Lock,
  LockOpenOutlined as LockOpen,
  DeleteOutline as Trash,
} from '@mui/icons-material';
import { Button, Stack, styled, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import SendArchive from 'assets/icons/send-archive.svg';
import { BasicDialog, InfoAssistanceDialogActions } from 'components';
import {
  InspectionRowAccessExpiredMessageMap,
  InspectionRowAccessExpiredType,
  InspectionStatus,
} from 'constants/inspections';
import { tour_05, tour_14 } from 'constants/tour';
import { UserType } from 'constants/users';

const StyledButton = styled(Button)({
  width: 36,
  minWidth: 36,
  height: 36,
  padding: 8,
  img: {
    transition: '.3s all',
  },
  '&:hover img': {
    opacity: 0.5,
  },
});

const DisabledCard = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  /* backgroundColor: '#f4f5f699', */
  zIndex: 9,
});
const InspectionListActions = ({
  variant,
  lockedBy: lockedByProp,
  onLock,
  onUnlock,
  onEdit,
  onView,
  onDelete,
  onArchive,
  userType,
  onOpenSendArchive,
  downloadable,
  onDownload,
  disabled,
  ownershipExpiredStatus,
  isOwner,
  isOwnerActivity,
  inspectionStatus,
  notEnoughPermissions,
  canDelete,
  inspectionType,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const InspectionRowAccessExpiredDialogActionsMap = {
    [InspectionRowAccessExpiredType.MAINUSER_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
      <></>,
    [InspectionRowAccessExpiredType.MAINUSER_NOT_OWNER_WITH_NO_SUBSCRIPTION]: (
      <></>
    ),
    [InspectionRowAccessExpiredType.MAINUSER_OWNER_WITH_ACTIVE_SUBSCRIPTION]: (
      <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />
    ),
    [InspectionRowAccessExpiredType.MAINUSER_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
      <></>,
    [InspectionRowAccessExpiredType.MAINUSER_OWNER_WITH_EXPIRED_SUBSCRIPTION]: (
      <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />
    ),
    [InspectionRowAccessExpiredType.MAINUSER_OWNER_WITH_NO_SUBSCRIPTION]: (
      <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />
    ),
    [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
      <></>,
    [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_NO_SUBSCRIPTION]:
      <></>,
    [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
      <></>,
    [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
      <></>,
    [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_NO_SUBSCRIPTION]:
      <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />,
    [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
      <InfoAssistanceDialogActions handleCloseDialog={handleCloseDialog} />,
  };

  const lockedBy = lockedByProp
    ? lockedByProp === 'me'
      ? {
          title: (
            <>
              Bloccata da me <br />
              <i>(nessun altro può modificare)</i>
            </>
          ),
          color: 'info',
        }
      : {
          title: (
            <>
              Bloccata da {lockedByProp} <br />
              <i>(non puoi modificare)</i>
            </>
          ),
          color: 'primary',
        }
    : {
        title: '',
        color: 'secondary',
      };

  return (
    <>
      {disabled && <DisabledCard onClick={() => setOpenDialog(true)} />}
      <Stack
        justifyContent={'right'}
        direction="row"
        spacing={1}
        className={`${tour_05.step_01} ${tour_14.step_02}`}
      >
        {variant !== 'read' && !notEnoughPermissions && (
          <>
            {lockedByProp ? (
              <Tooltip title={lockedBy.title}>
                <StyledButton
                  variant="contained"
                  color={lockedBy.color}
                  onClick={() => lockedByProp === 'me' && onUnlock()}
                  disabled={disabled}
                  // disabled if not enough permissions
                >
                  <Lock />
                </StyledButton>
              </Tooltip>
            ) : (
              <Tooltip title={"Blocca l'attività"}>
                <StyledButton
                  variant="contained"
                  color={lockedBy.color}
                  onClick={() => onLock()}
                  disabled={disabled}
                  // disabled if not enough permissions
                >
                  <LockOpen />
                </StyledButton>
              </Tooltip>
            )}
          </>
        )}
        {variant !== 'read' && !notEnoughPermissions && (
          <Tooltip title="Modifica l'attività">
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={() => onEdit()}
              disabled={
                (lockedByProp ? lockedByProp !== 'me' : false) || disabled
              }
              // disabled if not enough permissions
            >
              <Edit />
            </StyledButton>
          </Tooltip>
        )}
        {inspectionStatus !== InspectionStatus.Delivered &&
          !notEnoughPermissions && (
            <>
              {userType === UserType.Ente &&
                inspectionStatus !== InspectionStatus.Archived && (
                  <Tooltip title="Archivia l'attività">
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      disabled={
                        (lockedByProp ? lockedByProp !== 'me' : false) ||
                        disabled ||
                        !isOwner
                      }
                      onClick={() => onArchive()}
                    >
                      <Archive />
                    </StyledButton>
                  </Tooltip>
                )}
              {userType === UserType.Professionista && isOwnerActivity && (
                <Tooltip title="Consegna e Archivia">
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    disabled={
                      (lockedByProp ? lockedByProp !== 'me' : false) ||
                      disabled ||
                      !isOwnerActivity
                    }
                    onClick={() => onOpenSendArchive()}
                  >
                    <img
                      style={{ '&:hover': { opacity: 0.5 } }}
                      src={SendArchive}
                      alt=""
                    />
                  </StyledButton>
                </Tooltip>
              )}
            </>
          )}
        {downloadable ? (
          <Tooltip title="Scarica l'attività">
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={() => onDownload()}
            >
              <Download />
            </StyledButton>
          </Tooltip>
        ) : (
          <Tooltip title="Visualizza l'attività">
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={() => onView()}
              disabled={
                disabled ||
                (notEnoughPermissions &&
                  inspectionStatus === InspectionStatus.OnGoing)
              }
              // disabled if not enough permissions and if InspectionStatus.OnGoing
            >
              <Eye />
            </StyledButton>
          </Tooltip>
        )}
        {variant === 'write-delete' && canDelete && (
          <Tooltip title="Elimina l'attività">
            <StyledButton
              variant="outlined"
              color="secondary"
              disabled={
                (lockedByProp ? lockedByProp !== 'me' : false) ||
                disabled ||
                inspectionStatus !== InspectionStatus.OnGoing
              }
              onClick={() => onDelete()}
            >
              <Trash />
            </StyledButton>
          </Tooltip>
        )}
        {inspectionType === 'Ispezione extra-piattaforma' && (
          <Tooltip title="Elimina l'attività">
            <StyledButton
              variant="outlined"
              color="secondary"
              disabled={!isOwner}
              onClick={() => console.log('cancella attività')}
              /* onClick={() => onDelete()} */
            >
              <Trash />
            </StyledButton>
          </Tooltip>
        )}
      </Stack>
      <BasicDialog
        title=""
        open={openDialog}
        actions={
          InspectionRowAccessExpiredDialogActionsMap[ownershipExpiredStatus]
        }
        onClose={() => setOpenDialog(false)}
      >
        {InspectionRowAccessExpiredMessageMap[ownershipExpiredStatus]}
      </BasicDialog>
    </>
  );
};

InspectionListActions.propTypes = {
  variant: PropTypes.oneOf(['read', 'write', 'write-delete']),
  lockedBy: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(['me'])]),
  onLock: PropTypes.func,
  onUnlock: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onDelete: PropTypes.func,
  onOpenSendArchive: PropTypes.func,
  onArchive: PropTypes.func,
  userType: PropTypes.string,
  downloadable: PropTypes.bool,
  onDownload: PropTypes.func,
  disabled: PropTypes.bool,
  ownershipExpiredStatus: PropTypes.number,
  isOwner: PropTypes.bool,
  isOwnerActivity: PropTypes.bool,
  inspectionStatus: PropTypes.number,
  notEnoughPermissions: PropTypes.bool,
  canDelete: PropTypes.bool,
  inspectionType: PropTypes.string,
};

export { InspectionListActions };
