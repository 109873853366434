import { Check } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { tour_09 } from 'constants/tour';

/* import { Check } from '@mui/icons-material';*/
export const steps = [
  {
    selector: `.${tour_09.step_01}`,
    content: () => {
      return (
        <Typography>
          Seleziona più campate per compilare contemporaneamente i dati
        </Typography>
      );
    },
    /* style: {
      backgroundColor: '#bada55',
    }, */
  },
  {
    selector: `.${tour_09.step_02}`,
    content: () => {
      return (
        <Typography>
          Compila i campi con i dati delle campate e seleziona la <Check /> per
          popolare la tabella
        </Typography>
      );
    },
  },
  {
    selector: `.${tour_09.step_03}`,
    content: () => {
      return (
        <Typography>Clicca qui per modificare un singolo elemento</Typography>
      );
    },
  },
];
