import { Typography, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ArrowBack from 'assets/icons/arrow.svg';
import logoInBee from 'assets/icons/logoInBee.svg';
import theme from 'theme';
/* import { HeaderRegistration } from './Header/HeaderRegistration'; */
import {
  ArrowIcon,
  ContentContainer,
  FormContainer,
  ImageContainer,
  Logo,
  LogoContainer,
  PageContainer,
  RegistrationImage,
} from './RegistrationLayout.styles';

const RegistrationLayout = () => {
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <PageContainer isTablet={isTablet}>
        {/* <HeaderRegistration /> */}
        <LogoContainer>
          <Logo
            src={logoInBee}
            onClick={() =>
              window.location.assign(`${process.env.REACT_APP_PUBLIC_SITE}`)
            }
          />
        </LogoContainer>

        <ContentContainer isTablet={isTablet}>
          <ImageContainer isTablet={isTablet}>
            <RegistrationImage
              src="/images/cover-home.png"
              alt="registration image"
            />
          </ImageContainer>
          <FormContainer isTablet={isTablet}>
            <ArrowIcon
              src={ArrowBack}
              onClick={() =>
                window.location.assign(`${process.env.REACT_APP_PUBLIC_SITE}`)
              }
            />
            <Typography
              variant="h1"
              style={{
                fontSize: isTablet ? 30 : 40,
                lineHeight: 1.25,
                width: isTablet ? '100%' : 650,
              }}
            >
              Registrati ad INBEE
            </Typography>
            <Typography
              variant="h3"
              style={{
                marginTop: 10,
                fontSize: 20,
                lineHeight: 1.25,
                color: '#707070',
                width: '100%',
              }}
            >
              Compila il modulo per iscriverti. Potrai navigare gratuitamente
              nella versione DEMO e visualizzare i ponti precompilati. Per usare
              tutte le funzionalità Inbee potrai sottoscrivere un abbonamento in
              qualsiasi momento.
            </Typography>

            <div>
              <Outlet />
            </div>
          </FormContainer>
        </ContentContainer>
        {/*  <Footer /> */}
      </PageContainer>
    </>
  );
};

export { RegistrationLayout };
