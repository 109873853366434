import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
// import PropTypes from 'prop-types';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import {
  BasicDialog,
  DrawerSendArchiveInspection,
  HeaderPage,
  TabCreateInspection,
  TourWrapper,
} from 'components';
import {
  avatar,
  newInspectionTabs,
} from 'components/TabCreateInspection/costants';

import { apiCollaborators, apiInspections } from 'constants/api';
import {
  InspectionRouteActions,
  InspectionStatus,
} from 'constants/inspections';
import { STRUCTURES_PATHS } from 'constants/paths';
import {
  PermissionType,
  PermissionTypeSelectOptions,
} from 'constants/permissions';
import { tour_06 } from 'constants/tour';
import { UserType } from 'constants/users';
import { useInspectionPath } from 'hooks';
import { FormProvider } from 'providers';
import { useAuthSelector, useGlobalSelector } from 'stores';
import { BtnBottomContainer } from './CreateInspection.style';
import { steps, viewInspectionSteps } from './CreateInspection.tourSteps';
import CreateInspectionButtons from './CreateInspectionButtons';
import useInspection from './useInspection';

const InspectionShareOptions = PermissionTypeSelectOptions.filter(
  el => el.value !== PermissionType.CanView
);
const CreateInspection = ({ action }) => {
  const { showLoader, hideLoader } = useGlobalSelector();
  const { user } = useAuthSelector();
  const { post } = useFetch();
  const { enqueueSnackbar } = useSnackbar();

  const { inspectionId, structureId } = useParams();
  const navigate = useNavigate();
  const { isViewingInspection } = useInspectionPath();

  //State
  const [showBtn, setShowBtn] = useState(false);
  const { state, pathname } = useLocation();
  const [initialTab, setInitialTab] = useState(state?.inspectionLevelTab || 1);
  const [openSendArchive, setOpenSendArchive] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);

  const [debuggingSearch, setDebuggingSearch] = useState(
    'mensoleTamponiCampateImpalcatoTable'
  );

  // Inspection detail Hook with all logic
  const {
    titlePage,
    structure,
    infoData,
    methods,
    handleSubmit,
    onSubmit,
    collaborators,
    shareInspection,
    canShare,
    isStructureOwner,
    isInspectionOwner,
    formState,
    inspectionStatus,
    setWebCompletionPercentage,
    setAppCompletionPercentage,
    accessExpirationDate,
  } = useInspection({
    inspectionId,
    structureId,
    openSendArchive,
  });

  //Function
  const btnOnScroll = () => {
    document.body.scrollTop > 100 || document.documentElement.scrollTop > 100
      ? setShowBtn(true)
      : setShowBtn(false);
  };
  window.onscroll = () => btnOnScroll();

  useEffect(() => {
    setInitialTab(state?.inspectionLevelTab || 1);
  }, [state]);

  const handleArchiveInspection = async () => {
    try {
      showLoader();
      await post(
        `${apiInspections.ROOT}/${inspectionId}${apiInspections.ARCHIVE}`
      );
      navigate(
        STRUCTURES_PATHS.INSPECTIONS_ARCHIVE.replace(
          ':structureId',
          structure?.id
        )
      );
    } catch (err) {
      return enqueueSnackbar(
        `Si è verificato un errore nell'archiviazione dell'ispezione`,
        {
          variant: 'error',
        }
      );
    } finally {
      hideLoader();
    }
  };

  const DebuggingOptions = [
    'spalleTable',
    'pileTable',
    'spallePileCollegamentiTable',
    'mensoleTamponiCampateImpalcatoTable',
    'sovrastrutturaImpalcatoTable',
    'paliTable',
    'sottoserviziTable',
    'convogliamentoAcqueTable',
    'vulnerabilitaStrutturaleBassa',
  ];

  const handleSendArchive = () => {
    if (
      Object.keys(formState?.touchedFields || {}).length > 0 &&
      !formState?.isSubmitSuccessful &&
      pathname.split('/').pop() !== InspectionRouteActions.VIEW
    ) {
      /* console.log('MODIFICHE NON SALVATE', formState?.touchedFields); */
      return setOpenWarningDialog(true);
    }
    setOpenSendArchive(true);
  };

  return (
    <>
      {(process.env.REACT_APP_ENVIRONMENT == 'develop' ||
        process.env.REACT_APP_ENVIRONMENT == 'test') && (
        <Grid container spacing={2} my={3}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">nome rhf</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="nome RHF"
                onChange={event => setDebuggingSearch(event.target.value)}
              >
                {DebuggingOptions.map(el => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="secondary"
              fullWidth
              variant="contained"
              onClick={() => {
                console.log(methods.getValues());
              }}
            >
              vedi tutte le opzioni
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="info"
              fullWidth
              variant="contained"
              onClick={() => {
                console.log(methods.getValues(debuggingSearch));
              }}
            >
              analizza elemento RHF
            </Button>
          </Grid>
        </Grid>
      )}
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit, console.log)}
      >
        <HeaderPage
          sharedWith={collaborators}
          shareOptions={InspectionShareOptions}
          back={STRUCTURES_PATHS.INSPECTIONS_ARCHIVE.replace(
            ':structureId',
            structure?.id
          )}
          isOwner={isStructureOwner}
          handleShare={shareInspection}
          canShare={canShare}
          shareRoute={apiCollaborators.INSPECTIONS_COLLBAORATORS}
          entityId={inspectionId}
          accessExpiration={accessExpirationDate}
          buttons={
            <>
              <div className={`${tour_06.step_05}`}>
                {structure && (
                  <CreateInspectionButtons
                    setWebCompletionPercentage={setWebCompletionPercentage}
                    setAppCompletionPercentage={setAppCompletionPercentage}
                    action={action}
                    structure={structure}
                    inspectionId={inspectionId}
                  />
                )}
                {user?.type === UserType.Professionista &&
                  inspectionStatus !== InspectionStatus.Archived &&
                  inspectionStatus !== InspectionStatus.Delivered &&
                  isInspectionOwner && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleSendArchive}
                    >
                      Consegna e Archivia
                    </Button>
                  )}
                {user?.type === UserType.Ente &&
                  inspectionStatus !== InspectionStatus.Archived &&
                  inspectionStatus !== InspectionStatus.Delivered && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setOpenArchiveDialog(true)}
                    >
                      Termina e Archivia
                    </Button>
                  )}
              </div>
            </>
          }
          title={titlePage}
          avatar={avatar}
          infoData={infoData}
        />
        <div style={{ marginTop: 30 }}>
          <TabCreateInspection
            typevariant="secondary"
            variant="fullWidth"
            tabs={newInspectionTabs}
            initialTab={initialTab}
            firstLevel={true}
          />
        </div>
        {showBtn && structure && (
          <BtnBottomContainer>
            <CreateInspectionButtons
              setWebCompletionPercentage={setWebCompletionPercentage}
              setAppCompletionPercentage={setAppCompletionPercentage}
              action={action}
              structure={structure}
              inspectionId={inspectionId}
            />
          </BtnBottomContainer>
        )}
      </FormProvider>
      {!isViewingInspection && <TourWrapper steps={steps} />}
      {isViewingInspection && <TourWrapper steps={viewInspectionSteps} />}
      <DrawerSendArchiveInspection
        open={openSendArchive}
        setOpen={setOpenSendArchive}
        inspectionId={inspectionId}
        structureId={structure?.id}
        onSubmitCallback={() => {
          navigate(
            STRUCTURES_PATHS.INSPECTIONS_ARCHIVE.replace(
              ':structureId',
              structure?.id
            )
          );
        }}
      />
      <BasicDialog
        title="Attenzione"
        open={openWarningDialog}
        onClose={() => setOpenWarningDialog(false)}
        actions={
          <>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setOpenWarningDialog(false)}
            >
              Annulla
            </Button>
          </>
        }
      >
        Ci sono delle modifiche non salvate nel pannello corrente. Assicurarsi
        di aver salvato l&rsquo;ispezione prima di consegnarla
      </BasicDialog>
      <BasicDialog
        title="Attenzione"
        open={openArchiveDialog}
        actions={
          <>
            <Button
              color="secondary"
              onClick={() => setOpenArchiveDialog(false)}
            >
              Annulla
            </Button>
            <Button variant="contained" onClick={handleArchiveInspection}>
              Termina e archivia
            </Button>
          </>
        }
        onClose={() => setOpenArchiveDialog(false)}
      >
        <Typography>
          Assicurati di aver concluso l&apos;attività.
          <br />
          Archiviando non sarà più possibile modificarla
        </Typography>
      </BasicDialog>
    </>
  );
};

CreateInspection.propTypes = {
  action: PropTypes.oneOf([
    InspectionRouteActions.CREATE,
    InspectionRouteActions.EDIT,
    InspectionRouteActions.VIEW,
  ]),
};

export default CreateInspection;
