import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GridWrap, RHFSelect } from 'components';
import { SelectionPanel } from 'components/SelectionPanel/SelectionPanel';
import AntenneSection from './sections/AntenneSection';
import AppoggiSection from './sections/AppoggiSection';
import GiuntiSection from './sections/GiuntiSection';
import PulviniSection from './sections/PulviniSection';

const Accordion3Collegamenti = () => {
  const { watch } = useFormContext();
  const spallePileCollegamentiTableWatch = watch('spallePileCollegamentiTable');

  const [
    spallePileCollegamentoSelezioneOptions,
    setSpallePileCollegamentoSelezioneOptions,
  ] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState();

  useEffect(() => {
    if (spallePileCollegamentiTableWatch) {
      setSpallePileCollegamentoSelezioneOptions(
        spallePileCollegamentiTableWatch.map((el, idx) => {
          return { label: el.codice, value: idx };
        })
      );
    }
  }, [spallePileCollegamentiTableWatch]);

  // watcher for selecting index of which spalla/pila to modify
  const spallePileCollegamentoSelezioneWatch = watch(
    'spallePileCollegamentoSelezione'
  );
  useEffect(() => {
    if (spallePileCollegamentoSelezioneWatch !== undefined) {
      setSelectedIdx(spallePileCollegamentoSelezioneWatch);
    }
  }, [spallePileCollegamentoSelezioneWatch]);

  return (
    <>
      <GridWrap item xs={12}>
        <SelectionPanel subtitle="Seleziona una spalla/pila per procedere alla compilazione dei rispettivi apparecchi di appoggio/giunti/pulvini/antenne/altri dispositivi antisismici">
          <GridWrap container spacing={2}>
            <GridWrap item xs={10}>
              <RHFSelect
                name="spallePileCollegamentoSelezione"
                label={'Seleziona elemento'}
                defaultValue={''}
                selectOptions={spallePileCollegamentoSelezioneOptions}
              />
            </GridWrap>
          </GridWrap>
        </SelectionPanel>
      </GridWrap>
      {selectedIdx !== '' && (
        <>
          <AppoggiSection selectedIdx={selectedIdx} />
          <GiuntiSection selectedIdx={selectedIdx} />
          <PulviniSection selectedIdx={selectedIdx} />
          <AntenneSection selectedIdx={selectedIdx} />
        </>
      )}
    </>
  );
};
export default Accordion3Collegamenti;
