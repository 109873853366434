import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { CardOverview, MaterialBox } from 'components';
import { STRUCTURES_PATHS } from 'constants/paths';
import {
  tipologiaCordoloOptions,
  tipologiaGuardaviaOptions,
  tipologiaMarciapiedeOptions,
  tipologiaParapettoOptions,
  tipologiaPavimentazioneOptions,
  tipologiaSpartitrafficoOptions,
} from 'constants/selectOptions';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import { handleDynamicInspectionsTabChange } from 'utils/utilities';
import { Row } from '../TabL0.styles';

const Sovrastruttura = ({ overviewL0 }) => {
  const params = useParams();
  const id = params.structureId;
  const navigate = useNavigate();

  const lastInspectionId = overviewL0?.requestUserLastValidInspectionId;
  const pavimentazione = overviewL0?.sovrastruttura?.pavimentazioni;
  const cordolo = overviewL0?.sovrastruttura?.cordoli;
  const marciapede = overviewL0?.sovrastruttura?.marciapiedi;
  const parapetto = overviewL0?.sovrastruttura?.parapetti;
  const guardavia = overviewL0?.sovrastruttura?.guardavie;
  const spartitraffico = overviewL0?.sovrastruttura?.spartitraffico;

  return (
    <CardOverview
      title={'Elementi accessori'}
      subtitle={`Dettaglio dello stato di consistenza`}
      buttonText={'Approfondisci'}
      buttonDisabled={lastInspectionId ? false : true}
      height="auto"
      onClick={() =>
        handleDynamicInspectionsTabChange(
          inspectionTabMapping.L0,
          inspectionTabMapping.ElementiAccessori,
          null,
          navigate,
          STRUCTURES_PATHS.STRUCTURES_LIST,
          id,
          lastInspectionId
        )
      }
    >
      <Row
        style={{
          flexWrap: 'wrap',
          marginTop: 12,
        }}
      >
        <MaterialBox
          /* dataRecap={overviewL0} */
          topic={'Pavimentazione'}
          materialsData={pavimentazione}
          enumOptions={tipologiaPavimentazioneOptions}
        />
        <MaterialBox
          /*  dataRecap={overviewL0} */
          topic={'Cordolo'}
          materialsData={cordolo}
          enumOptions={tipologiaCordoloOptions}
        />
        <MaterialBox
          /*        dataRecap={overviewL0} */
          topic={'Marciapiede'}
          materialsData={marciapede}
          enumOptions={tipologiaMarciapiedeOptions}
        />
        <MaterialBox
          /* dataRecap={overviewL0} */
          topic={'Parapetto'}
          materialsData={parapetto}
          enumOptions={tipologiaParapettoOptions}
        />
        <MaterialBox
          /* dataRecap={overviewL0} */
          topic={'Guardavia'}
          materialsData={guardavia}
          enumOptions={tipologiaGuardaviaOptions}
        />
        <MaterialBox
          /* dataRecap={overviewL0} */
          topic={'Spartitraffico'}
          materialsData={spartitraffico}
          enumOptions={tipologiaSpartitrafficoOptions}
        />
      </Row>
    </CardOverview>
  );
};

export { Sovrastruttura };

Sovrastruttura.propTypes = {
  overviewL0: PropTypes.obj,
};
