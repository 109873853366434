import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { DifettiInfoDrawer, DrawerMedia } from 'components';
import { RHFTextField } from 'components/form';
import { GravitaValue, StatoDifetto } from 'constants/difetti';
import { infoDifetti } from 'constants/infoDifetti';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import {
  Box,
  IconButtonContainer,
} from '../ElementiStrutturali/SchedaDifettiElementiStrutturaliRow.styles';

const SchedaDifettiElementiAccessoriRow = ({ difetto, name, idx }) => {
  const { watch, register, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const [open, setOpen] = useState(false);

  const cordoliIgnore = [1, 2, 74, 3, 4, 5, 6, 10, 18, 19, 76];
  const cordoliIgnoreWatch = watch(`${name}.schedaDifetti`).some(
    el => el.id == 110 && !(el.visto && el.presente)
  );

  const isDisabledCordoli =
    cordoliIgnore.some(el => el == difetto.id) && cordoliIgnoreWatch;

  const isWithDanneggiatoOssidato =
    difetto.id === '121.a' ||
    difetto.id === '121.b' ||
    difetto.id === '121.c' ||
    difetto.id === '121.d';
  const isAssente = difetto.id === 'assente' || difetto.id === 111;

  const vistoWatch = watch(`${name}.schedaDifetti[${idx}].visto`);

  useEffect(() => {
    if (vistoWatch === false) {
      setValue(`${name}.schedaDifetti[${idx}].statoDifetto`, null);
      setValue(`${name}.schedaDifetti[${idx}].presente`, null);
      setValue(`${name}.schedaDifetti[${idx}].note`, null);
      if (difetto.hasGravita) {
        setValue(`${name}.schedaDifetti[${idx}].gravita`, null);
      }
      if (difetto.hasDanneggiato) {
        setValue(`${name}.schedaDifetti[${idx}].danneggiato`, null);
        setValue(`${name}.schedaDifetti[${idx}].ossidato`, null);
      }
    }
  }, [vistoWatch]);

  const statoDifettoWatch = watch(`${name}.schedaDifetti[${idx}].statoDifetto`);

  useEffect(() => {
    if (statoDifettoWatch) {
      setValue(`${name}.schedaDifetti[${idx}].presente`, null);
      if (difetto.hasGravita) {
        setValue(`${name}.schedaDifetti[${idx}].gravita`, null);
      }
      if (difetto.hasDanneggiato) {
        setValue(`${name}.schedaDifetti[${idx}].danneggiato`, null);
        setValue(`${name}.schedaDifetti[${idx}].ossidato`, null);
      }
    }
  }, [statoDifettoWatch]);

  const presenteWatch = watch(`${name}.schedaDifetti[${idx}].presente`);
  const gravitaWatch = watch(`${name}.schedaDifetti[${idx}].gravita`);
  const danneggiatoWatch = watch(`${name}.schedaDifetti[${idx}].danneggiato`);
  const ossidatoWatch = watch(`${name}.schedaDifetti[${idx}].ossidato`);

  useEffect(() => {
    if (presenteWatch) {
      setValue(`${name}.schedaDifetti[${idx}].statoDifetto`, null);
    }
  }, [presenteWatch]);

  useEffect(() => {
    setValue(`${name}.schedaDifettiTrigger`, nanoid());
  }, [
    vistoWatch,
    statoDifettoWatch,
    presenteWatch,
    gravitaWatch,
    danneggiatoWatch,
    ossidatoWatch,
  ]);

  if (isDisabledCordoli) return <></>;

  const renderMedia = () => {
    const difettiLength = getValues(
      `${name}.schedaDifetti[${idx}].media`
    )?.length;
    return (
      <IconButtonContainer>
        <div key={nanoid()}>
          {`${difettiLength || '-'} `}
          <IconButton
            style={{ marginRight: 10, padding: 0 }}
            color="primary"
            size="small"
            onClick={() => setOpen(true)}
            disabled={!vistoWatch}
          >
            <MediaIcon style={{ opacity: !vistoWatch ? '0.3' : 1 }} />
          </IconButton>
        </div>
      </IconButtonContainer>
    );
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          <Box>
            {difetto.codiceDifetto && (
              <DifettiInfoDrawer
                drawerTitle={`${difetto.descrizioneDifetto} -  ${difetto.codiceDifetto}`}
                difettosita={difetto.difettosita}
                infoDifetto={infoDifetti[difetto.id]}
              />
            )}
            {difetto.codiceDifetto}
          </Box>
        </TableCell>
        <TableCell>{difetto.descrizioneDifetto}</TableCell>
        <TableCell align="center">
          <input
            {...register(`${name}.schedaDifetti[${idx}].visto`)}
            type="checkbox"
            name={`${name}.schedaDifetti[${idx}].visto`}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </TableCell>
        <TableCell align="center">
          <input
            {...register(`${name}.schedaDifetti[${idx}].statoDifetto`)}
            type="radio"
            name={`${name}.schedaDifetti[${idx}].statoDifetto`}
            value={StatoDifetto.NR}
            className="form-check-input"
            id={StatoDifetto.NR}
            disabled={
              !vistoWatch ||
              presenteWatch ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
        </TableCell>
        {!isAssente && (
          <TableCell align="center">
            <input
              {...register(`${name}.schedaDifetti[${idx}].statoDifetto`)}
              type="radio"
              name={`${name}.schedaDifetti[${idx}].statoDifetto`}
              value={StatoDifetto.NP}
              className="form-check-input"
              id={StatoDifetto.NP}
              disabled={
                !vistoWatch ||
                presenteWatch ||
                inspectionDisabled ||
                isViewingInspection
              }
            />
          </TableCell>
        )}
        <TableCell align="center">
          <Typography>{difetto.difettosita}</Typography>
        </TableCell>

        <TableCell align="center">
          <input
            {...register(`${name}.schedaDifetti[${idx}].presente`)}
            type="checkbox"
            name={`${name}.schedaDifetti[${idx}].presente`}
            disabled={
              !vistoWatch ||
              statoDifettoWatch ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
        </TableCell>

        {isWithDanneggiatoOssidato && (
          <>
            {!difetto.hasGravita && !difetto.hasDanneggiato && (
              <>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            )}
            {difetto.hasGravita && (
              <>
                <TableCell align="center">
                  <input
                    {...register(`${name}.schedaDifetti[${idx}].gravita`)}
                    disabled={
                      !vistoWatch ||
                      statoDifettoWatch ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    type="radio"
                    name={`${name}.schedaDifetti[${idx}].gravita`}
                    value={GravitaValue.Lievemente}
                    className="form-check-input"
                    id={GravitaValue.Lievemente}
                    checked={
                      gravitaWatch?.toString() ===
                      GravitaValue.Lievemente.toString()
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  <input
                    {...register(`${name}.schedaDifetti[${idx}].gravita`)}
                    disabled={
                      !vistoWatch ||
                      statoDifettoWatch ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    type="radio"
                    name={`${name}.schedaDifetti[${idx}].gravita`}
                    value={GravitaValue.Gravemente}
                    className="form-check-input"
                    id={GravitaValue.Gravemente}
                    checked={
                      gravitaWatch?.toString() ===
                      GravitaValue.Gravemente.toString()
                    }
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            )}
            {difetto.hasDanneggiato && (
              <>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="center">
                  <input
                    {...register(`${name}.schedaDifetti[${idx}].danneggiato`)}
                    disabled={
                      !vistoWatch ||
                      statoDifettoWatch ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    type="checkbox"
                    name={`${name}.schedaDifetti[${idx}].danneggiato`}
                    className="form-check-input"
                  />
                </TableCell>
                <TableCell align="center">
                  <input
                    {...register(`${name}.schedaDifetti[${idx}].ossidato`)}
                    disabled={
                      !vistoWatch ||
                      statoDifettoWatch ||
                      inspectionDisabled ||
                      isViewingInspection
                    }
                    type="checkbox"
                    name={`${name}.schedaDifetti[${idx}].ossidato`}
                    className="form-check-input"
                  />
                </TableCell>
              </>
            )}
          </>
        )}
        <TableCell>{renderMedia()}</TableCell>
        <TableCell>
          <RHFTextField
            style={{ height: '40px' }}
            name={`${name}.schedaDifetti[${idx}].note`}
            label={''}
            disabled={!vistoWatch || inspectionDisabled || isViewingInspection}
            inputProps={{ maxLength: 200 }}
            defaultValue=""
          />
        </TableCell>
        <DrawerMedia
          open={open}
          setOpen={setOpen}
          title={`Media/${difetto.descrizioneDifetto} - ${difetto.codiceDifetto}`}
          infoPanel=""
          dataType="video/*,image/*"
          uploadType={UploadMedia.media}
          type={FieldType.images}
          formName={`${name}.schedaDifetti[${idx}].media`}
          mediaGroup={[MediaGroup.Sovrastruttura, MediaGroup.Difetti]}
        />
      </TableRow>
    </>
  );
};
SchedaDifettiElementiAccessoriRow.propTypes = {
  difetto: PropTypes.shape({
    id: PropTypes.string,
    codiceDifetto: PropTypes.string,
    difettosita: PropTypes.number,
    descrizioneDifetto: PropTypes.string,
    estensione_k1: PropTypes.number,
    intensita_k2: PropTypes.number,
    visto: PropTypes.bool,
    statoDifetto: PropTypes.number,
    estensioneDifetto: PropTypes.number,
    intensitaDifetto: PropTypes.number,
    ps: PropTypes.bool,
    media: PropTypes.array,
    note: PropTypes.string,
    rowTitle: PropTypes.string,
    infoDifetto: PropTypes.object,
    hasGravita: PropTypes.bool,
    hasDanneggiato: PropTypes.bool,
  }),
  name: PropTypes.string,
  containerName: PropTypes.string,
  idx: PropTypes.number,
};
export default SchedaDifettiElementiAccessoriRow;
