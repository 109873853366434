import styled from '@emotion/styled';
import { Clear } from '@mui/icons-material';
import { TabContext } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ArrowIcon from 'assets/icons/arrow-down.svg';
import { colors, InspectionStatusesMap, L2CdAMap } from 'constants/colors';

import { CardL2 } from './CardL2/CardL2';
import { mappingL2 } from './CardL2/config/mappingL2';
import {
  CdATag,
  ContainerCard,
  ContainerMessage,
  SecondTab,
  SecondTabIdraulica,
  TabPanelStyle,
  WrapperCardL2,
} from './TabsL2.style';

const TabsL2 = ({ cda }) => {
  //State
  const [valueLevel1, setValueLevel1] = useState(0);
  const [valueLevel2, setValueLevel2] = useState(0);

  const mappingColorBorder = {
    /* 1: colors.CHRISTI, */
    1: colors.WILDWILLOW,
    /* 2: colors.FEIJOA, */
    2: colors.SPROUT,
    /* 3: colors.CASABLANCA, */
    3: colors.CREAMBRULEE,
    /* 4: colors.WEBORANGE, */
    4: colors.SUNGLOW,
    /* 5: colors.AMARANTH, */
    5: colors.MAUVELOUS,
    6: colors.SILVER,
    7: colors.SILVER,
    8: colors.SILVER,
    /*  6: colors.DOVEGRAY,
    7: colors.DOVEGRAY,
    8: colors.DOVEGRAY, */
  };

  /* const CustomPrincipalAccordion = styled(Accordion)(({ cda }) => ({
    backgroundColor: `${colors.WHITE} !important`,
    marginBottom: 10,
    boxShadow: 'none',
    borderRadius: `8px !important`,
    padding: 0,
    border: cda
      ? `3px solid ${L2CdAMap[cda?.class]?.labelColor}`
      : `3px solid  ${colors.GREY}`,
  })); */

  const AccordionSummaryTitle = styled(AccordionSummary)(({ cda }) => ({
    backgroundColor: cda ? L2CdAMap[cda?.class]?.labelColor : colors.GREY,
    '& .MuiAccordionSummary-content': {
      justifyContent: 'center',
    },
  }));

  /* const CustomNestedAccordion = styled(Accordion)(
    ({ cda, cdaSectionData }) => ({
      backgroundColor: `${colors.WHITE}`,
      marginBottom: 10,
      boxShadow: 'none',
      borderRadius: 6,
      padding: 0,
      border: cda
        ? `3px solid ${L2CdAMap[cdaSectionData]?.labelColor}`
        : `3px solid  ${colors.GREY}`,
    })
  ); */

  const NestedAccordionSummary = styled(AccordionSummary)({
    '& .MuiAccordionSummary-content': {
      justifyItems: 'center',
      justifyContent: 'space-between',
    },
    '& svg': {
      backgroundImage: `url(${ArrowIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      fill: 'none',
      backgroundSize: '60%',
    },
  });

  /*   const getPathTab = () => {
    switch (valueLevel1) {
      case 0:
        return cda?.structuralFoundationalCda;
      case 1:
        return cda?.seismicCda;
      case 2:
        return cda?.landslideAndHydraulicRiskCda?.landslideRiskCda;
      case 3:
        if (valueLevel2 === 0) {
          return cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
            ?.overlapCda;
        } else if (valueLevel2 === 1) {
          return cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.generalizedErosionCda;
        } else
          return cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.localizedErosionCda;

      default:
        return '';
    }
  };

  const handleChange = (event, newValue) => {
    setValueLevel1(newValue);
  };
 */

  const handleChange = (event, newValue) => {
    /* console.log(newValue, 'newValue'); */
    setValueLevel1(newValue);
  };
  const handleChangeRischioIdraulico = (e, v) => {
    setValueLevel2(v);
  };

  const renderContentNestedTab = value => {
    switch (value) {
      case 0:
        return cda?.structuralFoundationalCda?.verificaStatica ===
          'Presente' ? (
          <></>
        ) : (
          <ContainerCard>
            <WrapperCardL2
              section={cda?.structuralFoundationalCda?.danger?.class}
            >
              <CardL2
                sectionColorCdA={
                  L2CdAMap[cda?.structuralFoundationalCda?.danger?.class]
                    ?.labelColor
                }
                key={'sef-p'}
                color="transparent"
                values={mappingL2('sef-p', cda)}
              />
            </WrapperCardL2>
            {cda?.structuralFoundationalCda?.vulnerability
              ?.vulnerabilitaStrutturaleBassa === 'Sì' ? (
              <ContainerMessage>
                <Typography
                  variant="h3Bold"
                  sx={{ fontSize: 16, lineHeight: '1.5rem' }}
                >
                  La vulnerabilità strutturale è BASSA come da indicazioni delle
                  Linee Guida per i ponti di recente costruzione
                </Typography>
              </ContainerMessage>
            ) : (
              <>
                <WrapperCardL2
                  section={cda?.structuralFoundationalCda?.vulnerability?.class}
                >
                  <CardL2
                    sectionColorCdA={
                      L2CdAMap[
                        cda?.structuralFoundationalCda?.vulnerability?.class
                      ]?.labelColor
                    }
                    key={'sef-v-section2-box1'}
                    color={
                      mappingColorBorder[
                        cda?.structuralFoundationalCda?.vulnerability?.section2
                          ?.value
                      ]
                    }
                    values={mappingL2('sef-v-section2-box1', cda)}
                    half={true}
                    childrenArray={[
                      {
                        color:
                          mappingColorBorder[
                            cda?.structuralFoundationalCda?.vulnerability
                              ?.section2?.section?.value
                          ],
                        values: mappingL2('sef-v-section2-box2', cda),
                        borderpx: '9px',

                        childrenArray: [
                          {
                            color:
                              mappingColorBorder[
                                cda?.structuralFoundationalCda?.vulnerability
                                  ?.section2?.section?.section?.value
                              ],
                            values: mappingL2('sef-v-section2-box3', cda),
                            borderpx: '7px',
                          },
                        ],
                      },
                    ]}
                  />

                  <CardL2
                    key={'sef-v-section1-box1'}
                    color={
                      mappingColorBorder[
                        cda?.structuralFoundationalCda?.vulnerability?.section1
                          ?.value
                      ]
                    }
                    values={mappingL2('sef-v-section1-box1', cda)}
                    half={true}
                    childrenArray={[
                      {
                        color:
                          mappingColorBorder[
                            cda?.structuralFoundationalCda?.vulnerability
                              ?.section1?.section.value
                          ],
                        values: mappingL2('sef-v-section1-box2', cda),
                        borderpx: '9px',
                      },
                    ]}
                  />
                </WrapperCardL2>
              </>
            )}
            <WrapperCardL2
              section={cda?.structuralFoundationalCda?.exposure?.class}
            >
              <CardL2
                /* sectionColorCdA={
                L2CdAMap[cda?.structuralFoundationalCda?.exposure?.class]
                  ?.labelColor
              } */
                key={'sef-e'}
                color="transparent"
                values={mappingL2('sef-e', cda)}
                half={true}
              />

              <CardL2
                key={'sef-e-section-box1'}
                color={
                  mappingColorBorder[
                    cda?.structuralFoundationalCda?.exposure?.section?.value
                  ]
                }
                values={mappingL2('sef-e-section-box1', cda)}
                half={true}
                childrenArray={[
                  {
                    color:
                      mappingColorBorder[
                        cda?.structuralFoundationalCda?.exposure?.section
                          ?.section.value
                      ],
                    values: mappingL2('sef-e-section-box2', cda),
                    borderpx: '9px',
                    childrenArray: [
                      {
                        color:
                          mappingColorBorder[
                            cda?.structuralFoundationalCda?.exposure?.section
                              ?.section?.section?.value
                          ],
                        values: mappingL2('sef-e-section-box3', cda),
                        borderpx: '7px',
                      },
                    ],
                  },
                ]}
              />
            </WrapperCardL2>
          </ContainerCard>
        );
      case 1:
        return cda?.seismicCda?.verificaSismica === 'Presente' ? (
          <></>
        ) : (
          <ContainerCard>
            <WrapperCardL2 section={cda?.seismicCda?.danger?.class}>
              <CardL2
                sectionColorCdA={
                  L2CdAMap[cda?.seismicCda?.danger?.class]?.labelColor
                }
                key={'s-p-section'}
                color={
                  mappingColorBorder[cda?.seismicCda?.danger?.section?.value]
                }
                values={mappingL2('s-p-section', cda)}
                half={true}
              />
              <CardL2
                key={'s-p'}
                color={'transparent'}
                values={mappingL2('s-p', cda)}
                half={true}
              />
            </WrapperCardL2>
            <WrapperCardL2 section={cda?.seismicCda?.vulnerability?.class}>
              <CardL2
                sectionColorCdA={
                  L2CdAMap[cda?.seismicCda?.vulnerability?.class]?.labelColor
                }
                key={'s-v-section1-box1'}
                color={
                  mappingColorBorder[
                    cda?.seismicCda?.vulnerability?.section1?.value
                  ]
                }
                values={mappingL2('s-v-section1-box1', cda)}
                half={true}
                childrenArray={[
                  {
                    color:
                      mappingColorBorder[
                        cda?.seismicCda?.vulnerability?.section1?.section?.value
                      ],
                    values: mappingL2('s-v-section1-box2', cda),
                    borderpx: '9px',
                    childrenArray: [
                      {
                        color:
                          mappingColorBorder[
                            cda?.seismicCda?.vulnerability?.section1?.section
                              ?.section?.value
                          ],
                        values: mappingL2('s-v-section1-box3', cda),
                        borderpx: '7px',
                      },
                    ],
                  },
                ]}
              />
              <CardL2
                key={'s-v-section2-box1'}
                color={
                  mappingColorBorder[
                    cda?.seismicCda?.vulnerability?.section2?.value
                  ]
                }
                values={mappingL2('s-v-section2-box1', cda)}
                half={true}
              />
            </WrapperCardL2>
            <WrapperCardL2 section={cda?.seismicCda?.exposure?.class}>
              <CardL2
                sectionColorCdA={
                  L2CdAMap[cda?.seismicCda?.exposure?.class]?.labelColor
                }
                key={'s-e-section-box1'}
                color={
                  mappingColorBorder[cda?.seismicCda?.exposure?.section?.value]
                }
                values={mappingL2('s-e-section-box1', cda)}
                half={true}
                childrenArray={[
                  {
                    color:
                      mappingColorBorder[
                        cda?.seismicCda?.exposure?.section?.section?.value
                      ],
                    values: mappingL2('s-e-section-box2', cda),
                    borderpx: '9px',
                    childrenArray: [
                      {
                        color:
                          mappingColorBorder[
                            cda?.seismicCda?.exposure?.section?.section?.section
                              ?.value
                          ],
                        values: mappingL2('s-e-section-box3', cda),
                        borderpx: '7px',
                        childrenArray: [
                          {
                            color:
                              mappingColorBorder[
                                cda?.seismicCda?.exposure?.section?.section
                                  ?.section?.section?.value
                              ],
                            values: mappingL2('s-e-section-box4', cda),
                            borderpx: '5px',
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />

              <CardL2
                key={'s-e'}
                color={'transparent'}
                values={mappingL2('s-e', cda)}
                half={true}
              />
            </WrapperCardL2>
          </ContainerCard>
        );
      case 2:
        return cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
          ?.rischioFrana === 'Assente' ? (
          <></>
        ) : (
          <ContainerCard>
            <WrapperCardL2
              section={
                cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                  ?.susceptibility?.class
              }
            >
              <CardL2
                sectionColorCdA={
                  L2CdAMap[
                    cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                      ?.susceptibility?.class
                  ]?.labelColor
                }
                key={'rf-s-section-box1'}
                color={
                  mappingColorBorder[
                    cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                      ?.susceptibility?.section?.value
                  ]
                }
                values={mappingL2('rf-s-section-box1', cda)}
                half={true}
                childrenArray={[
                  {
                    color:
                      mappingColorBorder[
                        cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                          ?.susceptibility?.section?.section?.value
                      ],
                    values:
                      cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                        ?.susceptibility?.section?.section?.pa ||
                      cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                        ?.susceptibility?.section?.section?.pa === 'required'
                        ? mappingL2('rf-s-section-box2', cda)
                        : cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                            ?.susceptibility?.section?.section?.missingPaPc ||
                          cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                            ?.susceptibility?.section?.section?.missingPaPc ===
                            'required'
                        ? mappingL2('rf-s-section-box4', cda)
                        : mappingL2('rf-s-section-box3', cda),
                    borderpx: '9px',
                  },
                ]}
              />
              <CardL2
                key={'rf-s'}
                color={'transparent'}
                values={mappingL2('rf-s', cda)}
                half={true}
              />
            </WrapperCardL2>
            <WrapperCardL2
              section={
                cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                  ?.vulnerability?.class
              }
            >
              <CardL2
                sectionColorCdA={
                  L2CdAMap[
                    cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                      ?.vulnerability?.class
                  ]?.labelColor
                }
                key={'rf-v-section-box1'}
                color={
                  mappingColorBorder[
                    cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                      ?.vulnerability?.section?.value
                  ]
                }
                values={mappingL2('rf-v-section-box1', cda)}
                half={true}
                childrenArray={[
                  {
                    color:
                      mappingColorBorder[
                        cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                          ?.vulnerability?.section?.section?.value
                      ],
                    values: mappingL2('rf-v-section-box2', cda),
                    borderpx: '9px',
                  },
                ]}
              />
              <CardL2
                key={'rf-v'}
                color={'transparent'}
                values={mappingL2('rf-v', cda)}
                half={true}
              />
            </WrapperCardL2>

            <WrapperCardL2
              section={
                cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.exposure
                  ?.class
              }
            >
              <CardL2
                sectionColorCdA={
                  L2CdAMap[
                    cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                      ?.exposure?.class
                  ]?.labelColor
                }
                key={'rf-e-section-box1'}
                color={
                  mappingColorBorder[
                    cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                      ?.exposure?.section?.value
                  ]
                }
                values={mappingL2('rf-e-section-box1', cda)}
                half={true}
                childrenArray={[
                  {
                    color:
                      mappingColorBorder[
                        cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                          ?.exposure?.section?.section?.value
                      ],
                    values: mappingL2('rf-e-section-box2', cda),
                    borderpx: '9px',
                    childrenArray: [
                      {
                        color:
                          mappingColorBorder[
                            cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
                              ?.exposure?.section?.section?.section?.value
                          ],
                        values: mappingL2('rf-e-section-box3', cda),
                        borderpx: '7px',
                        childrenArray: [
                          {
                            color:
                              mappingColorBorder[
                                cda?.landslideAndHydraulicRiskCda
                                  ?.landslideRiskCda?.exposure?.section?.section
                                  ?.section?.section?.value
                              ],
                            values: mappingL2('rf-e-section-box4', cda),
                            borderpx: '5px',
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />

              <CardL2
                key={'s-e'}
                color={'transparent'}
                values={mappingL2('s-e', cda)}
                half={true}
              />
            </WrapperCardL2>
          </ContainerCard>
        );
      case 3:
        if (
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
            ?.rischioIdraulico === 'Assente'
        ) {
          return <></>;
        } else if (valueLevel2 === 0) {
          return (
            <ContainerCard>
              <WrapperCardL2
                section={
                  cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                    ?.overlapCda?.danger?.class
                }
              >
                <CardL2
                  sectionColorCdA={
                    L2CdAMap[
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.overlapCda?.danger?.class
                    ]?.labelColor
                  }
                  key={'ri-s-p-section1'}
                  color={
                    mappingColorBorder[
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.overlapCda?.danger?.section1?.value
                    ]
                  }
                  values={
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.overlapCda?.danger?.section1?.francoIdraulicoStimato
                      ? mappingL2('ri-s-p-section1', cda)
                      : cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                          ?.overlapCda?.danger?.section1
                          ?.francoIdraulicoSuperiore
                      ? mappingL2('ri-s-p-section1-fs', cda)
                      : mappingL2('ri-s-p-section1-fp2', cda)
                  }
                  half={
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.overlapCda?.danger?.section2?.francoIdraulicoFp3 ||
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.overlapCda?.danger?.section2?.francoIdraulicoFp3 ===
                      'required'
                      ? true
                      : false
                  }
                />
                {(cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                  ?.overlapCda?.danger?.section2?.francoIdraulicoFp3 ||
                  cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                    ?.overlapCda?.danger?.section2?.francoIdraulicoFp3 ===
                    'required') && (
                  <CardL2
                    key={'ri-s-p-section2'}
                    color={
                      mappingColorBorder[
                        cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                          ?.overlapCda?.danger?.section2?.value
                      ]
                    }
                    values={mappingL2('ri-s-p-section2', cda)}
                    half={true}
                  />
                )}
              </WrapperCardL2>
              <WrapperCardL2
                section={
                  cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                    ?.overlapCda?.vulnerability?.class
                }
              >
                <CardL2
                  sectionColorCdA={
                    L2CdAMap[
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.overlapCda?.vulnerability?.class
                    ]?.labelColor
                  }
                  key={'ri-s-v'}
                  color="transparent"
                  values={mappingL2('ri-s-v', cda)}
                />
              </WrapperCardL2>
              <WrapperCardL2
                section={
                  cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                    ?.overlapCda?.exposure?.class
                }
              >
                <CardL2
                  sectionColorCdA={
                    L2CdAMap[
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.overlapCda?.exposure?.class
                    ]?.labelColor
                  }
                  key={'ri-s-e-section-box1'}
                  color={
                    mappingColorBorder[
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.overlapCda?.exposure?.section?.value
                    ]
                  }
                  values={mappingL2('ri-s-e-section-box1', cda)}
                  half={true}
                  childrenArray={[
                    {
                      color:
                        mappingColorBorder[
                          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                            ?.overlapCda?.exposure?.section?.section?.value
                        ],
                      values: mappingL2('ri-s-e-section-box2', cda),
                      borderpx: '9px',
                      childrenArray: [
                        {
                          color:
                            mappingColorBorder[
                              cda?.landslideAndHydraulicRiskCda
                                ?.hydraulicRiskCda?.overlapCda?.exposure
                                ?.section?.section?.section?.value
                            ],
                          values: mappingL2('ri-s-e-section-box3', cda),
                          borderpx: '7px',
                          childrenArray: [
                            {
                              color:
                                mappingColorBorder[
                                  cda?.landslideAndHydraulicRiskCda
                                    ?.hydraulicRiskCda?.overlapCda?.exposure
                                    ?.section?.section?.section?.section?.value
                                ],
                              values: mappingL2('ri-s-e-section-box4', cda),
                              borderpx: '5px',
                            },
                          ],
                        },
                      ],
                    },
                  ]}
                />

                <CardL2
                  key={'ri-s-e-1'}
                  color={'transparent'}
                  values={mappingL2('ri-s-e', cda)}
                  half={true}
                />
              </WrapperCardL2>
            </ContainerCard>
          );
        } else if (valueLevel2 === 1) {
          if (
            cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
              ?.fenomenoErosioneRischioIdraulico === 'Assenti'
          ) {
            return <></>;
          } else {
            return (
              <ContainerCard>
                <WrapperCardL2
                  section={
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.generalizedErosionCda?.danger?.class
                  }
                >
                  <CardL2
                    sectionColorCdA={
                      L2CdAMap[
                        cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                          ?.erosionCda?.generalizedErosionCda?.danger?.class
                      ]?.labelColor
                    }
                    key={'ri-eg-p'}
                    color={'transparent'}
                    values={mappingL2('ri-eg-p', cda)}
                  />
                </WrapperCardL2>
                <WrapperCardL2
                  section={
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.generalizedErosionCda?.vulnerability?.class
                  }
                >
                  <CardL2
                    sectionColorCdA={
                      L2CdAMap[
                        cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                          ?.erosionCda?.generalizedErosionCda?.vulnerability
                          ?.class
                      ]?.labelColor
                    }
                    key={'ri-eg-v'}
                    color="transparent"
                    values={
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.erosionCda?.localizedErosionCda?.vulnerability
                        ?.evidenzeProfonditaDelleFondazioni === 'Profonde' &&
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.erosionCda?.localizedErosionCda?.vulnerability
                        ?.abbassamentoGeneralizzatoAlveo === 'Sì'
                        ? mappingL2('ri-eg-v-abbassamentoAccentuato', cda)
                        : mappingL2('ri-eg-v', cda)
                    }
                  />
                </WrapperCardL2>
                <WrapperCardL2
                  section={
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.generalizedErosionCda?.exposure?.class
                  }
                >
                  <CardL2
                    sectionColorCdA={
                      L2CdAMap[
                        cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                          ?.erosionCda?.generalizedErosionCda?.exposure?.class
                      ]?.labelColor
                    }
                    key={'ri-eg-e-section-box1'}
                    color={
                      mappingColorBorder[
                        cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                          ?.erosionCda?.generalizedErosionCda?.exposure?.section
                          ?.value
                      ]
                    }
                    values={mappingL2('ri-eg-e-section-box1', cda)}
                    half={true}
                    childrenArray={[
                      {
                        color:
                          mappingColorBorder[
                            cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                              ?.erosionCda?.generalizedErosionCda?.exposure
                              ?.section?.section?.value
                          ],
                        values: mappingL2('ri-eg-e-section-box2', cda),
                        borderpx: '9px',
                        childrenArray: [
                          {
                            color:
                              mappingColorBorder[
                                cda?.landslideAndHydraulicRiskCda
                                  ?.hydraulicRiskCda?.erosionCda
                                  ?.generalizedErosionCda?.exposure?.section
                                  ?.section?.section?.value
                              ],
                            values: mappingL2('ri-eg-e-section-box3', cda),
                            borderpx: '7px',
                            childrenArray: [
                              {
                                color:
                                  mappingColorBorder[
                                    cda?.landslideAndHydraulicRiskCda
                                      ?.hydraulicRiskCda?.erosionCda
                                      ?.generalizedErosionCda?.exposure?.section
                                      ?.section?.section?.section?.value
                                  ],
                                values: mappingL2('ri-eg-e-section-box4', cda),
                                borderpx: '5px',
                              },
                            ],
                          },
                        ],
                      },
                    ]}
                  />

                  <CardL2
                    key={'ri-s-e-2'}
                    color={'transparent'}
                    values={mappingL2('ri-s-e', cda)}
                    half={true}
                  />
                </WrapperCardL2>
              </ContainerCard>
            );
          }
        } else if (
          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.fenomenoErosioneRischioIdraulico === 'Assenti'
        ) {
          return <></>;
        } else {
          return (
            <ContainerCard>
              <WrapperCardL2
                section={
                  cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                    ?.erosionCda?.localizedErosionCda?.danger?.class
                }
              >
                <CardL2
                  sectionColorCdA={
                    L2CdAMap[
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.erosionCda?.localizedErosionCda?.danger?.class
                    ]?.labelColor
                  }
                  key={'ri-el-p'}
                  color={'transparent'}
                  values={mappingL2('ri-el-p', cda)}
                />
              </WrapperCardL2>
              <WrapperCardL2
                section={
                  cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                    ?.erosionCda?.localizedErosionCda?.vulnerability?.class
                }
              >
                <CardL2
                  sectionColorCdA={
                    L2CdAMap[
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.erosionCda?.localizedErosionCda?.vulnerability?.class
                    ]?.labelColor
                  }
                  key={'ri-el-v'}
                  color="transparent"
                  values={
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.localizedErosionCda?.vulnerability
                      ?.evidenzeProfonditaDelleFondazioni === 'Profonde' &&
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.localizedErosionCda?.vulnerability
                      ?.abbassamentoGeneralizzatoAlveo === 'Sì'
                      ? mappingL2('ri-el-v-abbassamentoAccentuato', cda)
                      : mappingL2('ri-el-v', cda)
                  }
                />
              </WrapperCardL2>
              <WrapperCardL2
                section={
                  cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                    ?.erosionCda?.localizedErosionCda?.exposure?.class
                }
              >
                <CardL2
                  sectionColorCdA={
                    L2CdAMap[
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.erosionCda?.localizedErosionCda?.exposure?.class
                    ]?.labelColor
                  }
                  key={'ri-el-e-section-box1'}
                  color={
                    mappingColorBorder[
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                        ?.erosionCda?.localizedErosionCda?.exposure?.section
                        ?.value
                    ]
                  }
                  values={mappingL2('ri-el-e-section-box1', cda)}
                  half={true}
                  childrenArray={[
                    {
                      color:
                        mappingColorBorder[
                          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                            ?.erosionCda?.localizedErosionCda?.exposure?.section
                            ?.section?.value
                        ],
                      values: mappingL2('ri-el-e-section-box2', cda),
                      borderpx: '9px',
                      childrenArray: [
                        {
                          color:
                            mappingColorBorder[
                              cda?.landslideAndHydraulicRiskCda
                                ?.hydraulicRiskCda?.erosionCda
                                ?.localizedErosionCda?.exposure?.section
                                ?.section?.section?.value
                            ],
                          values: mappingL2('ri-el-e-section-box3', cda),
                          borderpx: '7px',
                          childrenArray: [
                            {
                              color:
                                mappingColorBorder[
                                  cda?.landslideAndHydraulicRiskCda
                                    ?.hydraulicRiskCda?.erosionCda
                                    ?.localizedErosionCda?.exposure?.section
                                    ?.section?.section?.section?.value
                                ],
                              values: mappingL2('ri-el-e-section-box4', cda),
                              borderpx: '5px',
                            },
                          ],
                        },
                      ],
                    },
                  ]}
                />

                <CardL2
                  key={'ri-s-e-2'}
                  color={'transparent'}
                  values={mappingL2('ri-s-e', cda)}
                  half={true}
                />
              </WrapperCardL2>
            </ContainerCard>
          );
        }

      default:
        return <></>;
    }
  };

  const nestedTab1 = () => {
    return (
      <>
        <Accordion
          cda={cda}
          cdaSectionData={cda?.structuralFoundationalCda?.class}
          onChange={handleChange}
          style={{
            backgroundColor: `${colors.WHITE}`,
            marginBottom: 10,
            boxShadow: 'none',
            borderRadius: 6,
            padding: 0,
            border: cda
              ? `3px solid ${
                  L2CdAMap[cda?.structuralFoundationalCda?.class]?.labelColor
                }`
              : `3px solid  ${colors.GREY}`,
          }}
        >
          <NestedAccordionSummary
            expandIcon={<Clear />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h3" sx={{ fontSize: 16, marginTop: '8px' }}>
              CdA STRUTTURALE E FONDAZIONALE
            </Typography>
            <CdATag
              cda={cda}
              cdaSectionData={cda?.structuralFoundationalCda?.class}
            >
              <Typography variant="h3" sx={{ fontSize: 16 }}>
                {cda
                  ? L2CdAMap[cda?.structuralFoundationalCda?.class]?.labelText
                  : 'N.D.'}
              </Typography>
            </CdATag>
          </NestedAccordionSummary>
          <AccordionDetails>
            {cda?.structuralFoundationalCda?.verificaStatica === 'Presente' ? (
              <Typography
                variant="h3Bold"
                sx={{ fontSize: 16, lineHeight: '3rem' }}
              >
                La CdA strutturale e fondazionale è BASSA come da indicazioni
                delle Linee Guida per i ponti che abbiano a disposizione
                verifiche accurate
              </Typography>
            ) : (
              <>
                {nestedTab2(cda?.structuralFoundationalCda)}
                {renderContentNestedTab(0)}
              </>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          cda={cda}
          /* cdaSectionData={cda?.seismicCda?.class} */
          style={{
            backgroundColor: `${colors.WHITE}`,
            marginBottom: 10,
            boxShadow: 'none',
            borderRadius: 6,
            padding: 0,
            border: cda
              ? `3px solid ${L2CdAMap[cda?.seismicCda?.class]?.labelColor}`
              : `3px solid  ${colors.GREY}`,
          }}
          label={'CLASSE DI ATTENZIONE SISMICA'}
        >
          <NestedAccordionSummary
            expandIcon={<Clear />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h3" sx={{ fontSize: 16, marginTop: '8px' }}>
              CdA SISMICA
            </Typography>
            <CdATag cda={cda} cdaSectionData={cda?.seismicCda?.class}>
              <Typography variant="h3" sx={{ fontSize: 16 }}>
                {cda ? L2CdAMap[cda?.seismicCda?.class]?.labelText : 'N.D.'}
              </Typography>
            </CdATag>
          </NestedAccordionSummary>
          <AccordionDetails>
            {cda?.seismicCda?.verificaSismica === 'Presente' ? (
              <Typography
                variant="h3Bold"
                sx={{ fontSize: 16, lineHeight: '3rem' }}
              >
                La CdA sismica è BASSA come da indicazioni delle Linee Guida per
                i ponti che abbiano a disposizione verifiche accurate
              </Typography>
            ) : (
              <>
                {nestedTab2(cda?.seismicCda)}
                {renderContentNestedTab(1)}
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          cda={cda}
          /* cdaSectionData={
            cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.class
          } */
          style={{
            backgroundColor: `${colors.WHITE}`,
            marginBottom: 10,
            boxShadow: 'none',
            borderRadius: 6,
            padding: 0,
            border: cda
              ? `3px solid ${
                  L2CdAMap[
                    cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.class
                  ]?.labelColor
                }`
              : `3px solid  ${colors.GREY}`,
          }}
        >
          <NestedAccordionSummary expandIcon={<Clear />}>
            <Typography variant="h3" sx={{ fontSize: 16, marginTop: '8px' }}>
              CdA FRANE
            </Typography>
            <CdATag
              cda={cda}
              cdaSectionData={
                cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.class
              }
            >
              <Typography variant="h3" sx={{ fontSize: 16 }}>
                {cda
                  ? L2CdAMap[
                      cda?.landslideAndHydraulicRiskCda?.landslideRiskCda?.class
                    ]?.labelText
                  : 'N.D.'}
              </Typography>
            </CdATag>
          </NestedAccordionSummary>
          <AccordionDetails>
            {cda?.landslideAndHydraulicRiskCda?.landslideRiskCda
              ?.rischioFrana === 'Assente' ? (
              <Typography
                variant="h3Bold"
                sx={{ fontSize: 16, lineHeight: '3rem' }}
              >
                Il rischio frane è assente
              </Typography>
            ) : (
              <>
                {nestedTab2(
                  cda?.landslideAndHydraulicRiskCda?.landslideRiskCda,
                  'frane'
                )}
                {renderContentNestedTab(2)}
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            backgroundColor: `${colors.WHITE}`,
            marginBottom: 10,
            boxShadow: 'none',
            borderRadius: 6,
            padding: 0,
            border: cda
              ? `3px solid ${
                  L2CdAMap[
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.class
                  ]?.labelColor
                }`
              : `3px solid  ${colors.GREY}`,
          }}
          cda={cda}
          cdaSectionData={
            cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.class
          }
        >
          <NestedAccordionSummary expandIcon={<Clear />}>
            <Typography variant="h3" sx={{ fontSize: 16, marginTop: '8px' }}>
              CdA IDRAULICA
            </Typography>
            <CdATag
              cda={cda}
              cdaSectionData={
                cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.class
              }
            >
              <Typography variant="h3" sx={{ fontSize: 16 }}>
                {cda
                  ? L2CdAMap[
                      cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.class
                    ]?.labelText
                  : 'N.D.'}
              </Typography>
            </CdATag>
          </NestedAccordionSummary>
          <AccordionDetails>
            {cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
              ?.rischioIdraulico === 'Assente' ? (
              <Typography
                variant="h3Bold"
                sx={{ fontSize: 16, lineHeight: '3rem' }}
              >
                Il rischio idraulico è assente
              </Typography>
            ) : (
              <>
                {nestedTabRischioIdraulico()}
                {renderContentNestedTab(3)}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const nestedTabRischioIdraulico = () => {
    return (
      <TabContext value={valueLevel2}>
        <Tabs
          TabIndicatorProps={{
            style: { backgroundColor: 'white' },
          }}
          value={valueLevel2}
          variant="fullWidth"
          onChange={handleChangeRischioIdraulico}
        >
          <SecondTabIdraulica
            borderColor={
              cda
                ? mappingColorBorder[
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.overlapCda?.class
                  ]
                : colors.GREY
            }
            color={
              cda
                ? InspectionStatusesMap[
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.overlapCda?.class
                  ]?.labelColor
                : colors.GREY
            }
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <p>{'SORMONTO'}</p>

                <CdATag
                  cda={cda}
                  cdaSectionData={
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.overlapCda?.class
                  }
                >
                  <Typography variant="h3" sx={{ fontSize: 14 }}>
                    {cda
                      ? L2CdAMap[
                          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                            ?.overlapCda?.class
                        ]?.labelText
                      : 'N.D.'}
                  </Typography>
                </CdATag>
              </div>
            }
            value={0}
          />
          <SecondTabIdraulica
            borderColor={
              cda
                ? mappingColorBorder[
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.generalizedErosionCda?.class
                  ]
                : colors.GREY
            }
            color={
              cda
                ? InspectionStatusesMap[
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.generalizedErosionCda?.class
                  ]?.labelColor
                : colors.GREY
            }
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <p>{'EROSIONE GENERALIZZATA'}</p>

                <CdATag
                  cda={cda}
                  cdaSectionData={
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.generalizedErosionCda?.class
                  }
                >
                  <Typography variant="h3" sx={{ fontSize: 14 }}>
                    {cda
                      ? L2CdAMap[
                          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                            ?.erosionCda?.generalizedErosionCda?.class
                        ]?.labelText
                      : 'N.D.'}
                  </Typography>
                </CdATag>
              </div>
            }
            value={1}
          />
          <SecondTabIdraulica
            borderColor={
              cda
                ? mappingColorBorder[
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.localizedErosionCda?.class
                  ]
                : colors.GREY
            }
            color={
              cda
                ? InspectionStatusesMap[
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.localizedErosionCda?.class
                  ]?.labelColor
                : colors.GREY
            }
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <p>{'EROSIONE LOCALIZZATA'}</p>

                <CdATag
                  cda={cda}
                  cdaSectionData={
                    cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                      ?.erosionCda?.localizedErosionCda?.class
                  }
                >
                  <Typography variant="h3" sx={{ fontSize: 14 }}>
                    {cda
                      ? L2CdAMap[
                          cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda
                            ?.erosionCda?.localizedErosionCda?.class
                        ]?.labelText
                      : 'N.D.'}
                  </Typography>
                </CdATag>
              </div>
            }
            value={2}
          />
        </Tabs>
        <TabPanelStyle value={0}>
          {nestedTab2(
            cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.overlapCda
          )}
        </TabPanelStyle>
        <TabPanelStyle value={1}>
          {cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.fenomenoErosioneRischioIdraulico === 'Assenti' ? (
            <Typography
              variant="h3Bold"
              sx={{ fontSize: 16, lineHeight: '3rem' }}
            >
              Il rischio legato ai fenomeni erosivi è assente
            </Typography>
          ) : (
            nestedTab2(
              cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
                ?.generalizedErosionCda
            )
          )}
        </TabPanelStyle>
        <TabPanelStyle value={2}>
          {cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
            ?.fenomenoErosioneRischioIdraulico === 'Assenti' ? (
            <Typography
              variant="h3Bold"
              sx={{ fontSize: 16, lineHeight: '3rem' }}
            >
              Il rischio legato ai fenomeni erosivi è assente
            </Typography>
          ) : (
            nestedTab2(
              cda?.landslideAndHydraulicRiskCda?.hydraulicRiskCda?.erosionCda
                ?.localizedErosionCda
            )
          )}
        </TabPanelStyle>
      </TabContext>
    );
  };
  const nestedTab2 = (cdaInObject, context) => {
    return (
      <TabContext value={5}>
        <Tabs value={5} variant="fullWidth">
          <SecondTab
            borderColor={
              cda
                ? mappingColorBorder[
                    cdaInObject?.danger?.class ||
                      cdaInObject?.susceptibility?.class
                  ]
                : colors.GREY
            }
            color={
              cda
                ? InspectionStatusesMap[
                    cdaInObject?.danger?.class ||
                      cdaInObject?.susceptibility?.class
                  ]?.labelColor
                : colors.GREY
            }
            disabled={true}
            /* label={valueLevel1 === 2 ? 'Suscettibilità' : 'Pericolosità'} */
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <p style={{ marginLeft: 8 }}>
                  {context === 'frane' ? 'Suscettibilità' : 'Pericolosità'}
                </p>

                <CdATag
                  cda={cda}
                  cdaSectionData={
                    cdaInObject?.danger?.class ||
                    cdaInObject?.susceptibility?.class
                  }
                >
                  <Typography variant="h3" sx={{ fontSize: 14 }}>
                    {cda
                      ? L2CdAMap[
                          cdaInObject?.danger?.class ||
                            cdaInObject?.susceptibility?.class
                        ]?.labelText
                      : 'N.D.'}
                  </Typography>
                </CdATag>
              </div>
            }
            value={0}
          />
          <SecondTab
            borderColor={
              /* cda?.structuralFoundationalCda?.vulnerability
                ?.vulnerabilitaStrutturaleBassa === 'Sì'
                ? colors.CHRISTI
                : */ cda
                ? mappingColorBorder[cdaInObject?.vulnerability?.class]
                : colors.GREY
            }
            color={
              /* cda?.structuralFoundationalCda?.vulnerability
                ?.vulnerabilitaStrutturaleBassa === 'Sì'
                ? colors.WILDWILLOW
                : */ cda
                ? InspectionStatusesMap[cdaInObject?.vulnerability?.class]
                    ?.labelColor
                : colors.GREY
            }
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <p style={{ marginLeft: 8 }}>{'Vulnerabilità'}</p>

                <CdATag
                  cda={cda}
                  cdaSectionData={cdaInObject?.vulnerability?.class}
                >
                  <Typography variant="h3" sx={{ fontSize: 14 }}>
                    {cda
                      ? L2CdAMap[cdaInObject?.vulnerability?.class]?.labelText
                      : 'N.D.'}
                  </Typography>
                </CdATag>
              </div>
            }
            disabled={true}
            value={1}
          />
          <SecondTab
            borderColor={
              cda
                ? mappingColorBorder[cdaInObject?.exposure?.class]
                : colors.GREY
            }
            color={
              cda
                ? InspectionStatusesMap[cdaInObject?.exposure?.class]
                    ?.labelColor
                : colors.GREY
            }
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <p style={{ marginLeft: 8 }}>{'Esposizione'}</p>

                <CdATag cda={cda} cdaSectionData={cdaInObject?.exposure?.class}>
                  <Typography variant="h3" sx={{ fontSize: 14 }}>
                    {cda
                      ? L2CdAMap[cdaInObject?.exposure?.class]?.labelText
                      : 'N.D.'}
                  </Typography>
                </CdATag>
              </div>
            }
            disabled={true}
            value={2}
          />
          <SecondTab
            style={{
              minWidth: 0,
              maxWidth: 0,
              padding: 0,
              margin: 0,
            }}
            value={4}
          />
        </Tabs>
      </TabContext>
    );
  };
  return (
    <Box>
      <Accordion
        cda={cda}
        defaultExpanded={true}
        style={{
          backgroundColor: `${colors.WHITE}`,
          marginBottom: 10,
          boxShadow: 'none',
          borderRadius: 6,
          padding: 0,
          border: cda
            ? `3px solid ${L2CdAMap[cda?.class]?.labelColor}`
            : `3px solid  ${colors.SILVER}`,
        }}
      >
        <AccordionSummaryTitle cda={cda}>
          <Typography variant="h3" sx={{ fontSize: 16 }}>
            CLASSE DI ATTENZIONE COMPLESSIVA:
            <Typography variant="h3Bold" sx={{ fontSize: 16 }}>
              {cda ? ` ${L2CdAMap[cda?.class]?.labelText}` : ' N.D.'}
            </Typography>
          </Typography>
        </AccordionSummaryTitle>
        <AccordionDetails>{nestedTab1()}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export { TabsL2 };

TabsL2.propTypes = {
  cda: PropTypes.object,
};
