import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import close from 'assets/icons/close.svg';
import infoDifetti1 from 'assets/icons/infodifetti1.svg';
import infoDifetti2 from 'assets/icons/infodifetti2.svg';
import infoDifetti3 from 'assets/icons/infodifetti3.svg';
import infoDifetti4 from 'assets/icons/infodifetti4.svg';
import { DrawerMUI } from 'components';

import {
  BoxContainer,
  BoxDifetti,
  ButtonContainer,
  Difettosita,
  ImageDifetto,
  ImageIcon,
  InfoBtnContainer,
  InfoContainer,
  List,
  MessageBody,
  MessageInfoContainer,
  ZommedImageDifetto,
  ZoomCloseIconContainer,
  ZoomFlexContainer,
  ZoomImgContainer,
} from './DifettiInfoDrawer.styles';

const DifettiInfoDrawer = ({ drawerTitle, difettosita, infoDifetto }) => {
  //State
  const [open, setOpen] = useState(false);
  const [isZoom0_2, setIsZoom0_2] = useState(false);
  const [isZoom0_5, setIsZoom0_5] = useState(false);
  const [isZoom1, setIsZoom1] = useState(false);

  const handleZoom = imgToZoom => {
    switch (imgToZoom) {
      case 'image_k2_0_2':
        setIsZoom0_2(prev => !prev);
        break;
      case 'image_k2_0_5':
        setIsZoom0_5(prev => !prev);
        break;
      case 'image_k2_1':
        setIsZoom1(prev => !prev);
        break;
    }
  };

  const renderBluText = text => (
    <Typography style={{ color: '#3260CE', margin: '5px 0' }} variant="h3Bold">
      {text}
    </Typography>
  );

  const renderZoomImg = (imgToZoom, srcImg, k, caption) => (
    <ZoomImgContainer>
      <Typography variant="h2" style={{ color: '#3260CE', marginBottom: 8 }}>
        {'Intensità K2'}
      </Typography>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <Typography
          variant="h3Bold"
          style={{
            backgroundColor: '#3260CE',
            color: '#FFFFFF',
            padding: '4px 10px',
            borderRadius: 5,
          }}
        >
          {k}
        </Typography>
        <Typography variant="h3" style={{ fontSize: 15 }}>
          {caption !== 'Sempre = 1' && caption}
        </Typography>
      </div>

      <ZoomFlexContainer>
        <ZoomCloseIconContainer
          onClick={() => {
            handleZoom(imgToZoom);
          }}
        >
          <img src={close} width={15} alt="close icon" />
        </ZoomCloseIconContainer>
        <ZommedImageDifetto src={srcImg} alt="" />
      </ZoomFlexContainer>
    </ZoomImgContainer>
  );

  const renderK1 = () => {
    return (
      (infoDifetto?.k1_0_2 || infoDifetto?.k1_0_5 || infoDifetto?.k1_1) && (
        <MessageInfoContainer>
          <InfoContainer>
            <ImageIcon src={infoDifetti1} alt="" />
            <MessageBody>
              <Typography variant="h3Bold">{'Estensione K1'}</Typography>
              <BoxDifetti>
                {infoDifetto?.k1_0_2 && (
                  <MessageBody>
                    {infoDifetto?.k1_0_2 !== 'Sempre = 0.2' &&
                      renderBluText('0.2')}
                    <Typography variant="h3">{infoDifetto.k1_0_2}</Typography>
                  </MessageBody>
                )}
                {infoDifetto?.k1_0_5 && (
                  <MessageBody>
                    {renderBluText('0.5')}
                    <Typography variant="h3">{infoDifetto.k1_0_5}</Typography>
                  </MessageBody>
                )}
                {infoDifetto?.k1_1 && (
                  <MessageBody>
                    {infoDifetto?.k1_1 !== 'Sempre = 1' && renderBluText('1')}
                    <Typography variant="h3">{infoDifetto.k1_1}</Typography>
                  </MessageBody>
                )}
              </BoxDifetti>
            </MessageBody>
          </InfoContainer>
        </MessageInfoContainer>
      )
    );
  };

  const renderK2 = () => {
    return (
      (infoDifetto?.k2_0_2 || infoDifetto?.k2_0_5 || infoDifetto?.k2_1) && (
        <MessageInfoContainer>
          <InfoContainer>
            <ImageIcon src={infoDifetti2} alt="" />
            <MessageBody>
              <Typography variant="h3Bold">{'Intensità k2'}</Typography>
              <BoxDifetti>
                {infoDifetto?.k2_0_2 && (
                  <MessageBody>
                    {infoDifetto?.k2_0_2 !== 'Sempre = 0.2' &&
                      renderBluText('0.2')}
                    <Typography variant="h3">{infoDifetto.k2_0_2}</Typography>
                    {infoDifetto.image_k2_0_2 && (
                      <ImageDifetto
                        src={infoDifetto.image_k2_0_2}
                        alt=""
                        onClick={() => {
                          handleZoom('image_k2_0_2');
                        }}
                      />
                    )}
                    {isZoom0_2 &&
                      renderZoomImg(
                        'image_k2_0_2',
                        infoDifetto.image_k2_0_2,
                        '0.2',
                        infoDifetto.k2_0_2
                      )}
                  </MessageBody>
                )}
                {infoDifetto?.k2_0_5 && (
                  <MessageBody>
                    {renderBluText('0.5')}
                    <Typography variant="h3">{infoDifetto.k2_0_5}</Typography>
                    {infoDifetto.image_k2_0_5 && (
                      <ImageDifetto
                        src={infoDifetto.image_k2_0_5}
                        alt=""
                        onClick={() => handleZoom('image_k2_0_5')}
                      />
                    )}
                    {isZoom0_5 &&
                      renderZoomImg(
                        'image_k2_0_5',
                        infoDifetto.image_k2_0_5,
                        '0.5',
                        infoDifetto.k2_0_5
                      )}
                  </MessageBody>
                )}
                {infoDifetto?.k2_1 && (
                  <MessageBody>
                    {infoDifetto?.k2_1 !== 'Sempre = 1' && renderBluText('1')}
                    <Typography variant="h3">{infoDifetto.k2_1}</Typography>
                    {infoDifetto.image_k2_1 && (
                      <ImageDifetto
                        src={infoDifetto.image_k2_1}
                        alt=""
                        onClick={() => handleZoom('image_k2_1')}
                      />
                    )}
                    {isZoom1 &&
                      renderZoomImg(
                        'image_k2_1',
                        infoDifetto.image_k2_1,
                        '1',
                        infoDifetto.k2_1
                      )}
                  </MessageBody>
                )}
              </BoxDifetti>
            </MessageBody>
          </InfoContainer>
        </MessageInfoContainer>
      )
    );
  };

  const renderImage = () => {
    return (
      infoDifetto?.image && (
        <MessageBody>
          <ImageDifetto src={infoDifetto.image} alt="" />
        </MessageBody>
      )
    );
  };

  const renderDescription = () => {
    return (
      infoDifetto?.description && (
        <MessageBody style={{ marginTop: '12px' }}>
          <Typography variant="h3Bold">{'Descrizione'}</Typography>

          <Typography variant="h3">{infoDifetto.description}</Typography>
        </MessageBody>
      )
    );
  };

  const renderPhenomena = () => {
    return (
      infoDifetto?.phenomena?.length > 0 && (
        <MessageInfoContainer>
          <InfoContainer>
            <ImageIcon src={infoDifetti3} alt="" />
            <MessageBody>
              <Typography variant="h3Bold">
                {'Fenomeni di degrado correlati'}
              </Typography>
              <List>
                {infoDifetto?.phenomena.map((el, index) => {
                  return (
                    <li key={`${index}-pheno`}>
                      <Typography variant="h3">{el}</Typography>
                    </li>
                  );
                })}
              </List>
            </MessageBody>
          </InfoContainer>
        </MessageInfoContainer>
      )
    );
  };

  const renderSuggestions = () => {
    return (
      infoDifetto?.suggestions && (
        <MessageInfoContainer>
          <InfoContainer>
            <ImageIcon src={infoDifetti4} alt="" />
            <MessageBody>
              <Typography variant="h3Bold">{'Suggerimenti'}</Typography>
              <Typography variant="h3">{infoDifetto.suggestions}</Typography>
            </MessageBody>
          </InfoContainer>
        </MessageInfoContainer>
      )
    );
  };

  return (
    <>
      <InfoBtnContainer
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Typography style={{ fontSize: '8px' }}>info</Typography>
      </InfoBtnContainer>

      <DrawerMUI open={open} setOpen={setOpen} title={drawerTitle}>
        <div>
          <BoxContainer>
            <Difettosita>
              <Typography variant="h3Bold">{`G=${difettosita}`}</Typography>
            </Difettosita>
            {renderK1()}
            {renderK2()}
            {renderImage()}
            {renderDescription()}
            {renderPhenomena()}
            {renderSuggestions()}
          </BoxContainer>

          <ButtonContainer
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setOpen(prev => !prev)}
          >
            Chiudi
          </ButtonContainer>
        </div>
      </DrawerMUI>
    </>
  );
};

export { DifettiInfoDrawer };

DifettiInfoDrawer.propTypes = {
  drawerTitle: PropTypes.string,
  difettosita: PropTypes.number,
  infoDifetto: PropTypes.object,
};
