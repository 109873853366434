export const PermissionType = {
  NoPermission: 1,
  CanViewByInspectionReference: 2,
  CanView: 3,
  CanEdit: 4,
};
export const userTypeEnum = {
  mainUser: 1,
  internal: 2,
  otherInternal: 3,
  unregistered: 4,
};
export const PermissionTypeSelectOptions = [
  {
    label: 'Nessun Accesso',
    value: PermissionType.NoPermission,
  },
  {
    label: 'Può modificare',
    value: PermissionType.CanEdit,
  },
  {
    label: 'Può visualizzare',
    value: PermissionType.CanView,
  },
];

export const CollaboratorInvitedOn = {
  Structure: 1,
  Inspection: 2,
  Nothing: 3,
};

export const UserErrors = {
  FoundAsInvited: 1,
  NotFound: 2,
  CollaboratorNotFound: 3,
  YouCanNotShareTheseStructures: 4,
  YouCanNotUpdateInternalCollaborators: 5,
  InvitedIsNotInstitution: 6,
  NotYourInternalCollaborator: 7,
  NotInvitedByYou: 8,
  IsNotAMainUser: 9,
  YouDoNotOwnThisStructure: 10,
  AdminCanNotInviteUsers: 11,
};

export const CollaborationsErrors = {
  CollaboratorAlreadyExists: 1,
  YouCanNotInviteInternalCollaborators: 2,
  AlreadyInvitedByYou: 3,
  /**
   * Inviter try to share a structure on which he does not have permissions
   */
  YouCanNotShareThisStructure: 4,
  /**
   * Inviter try to share an inspection on which he does not have permissions
   */
  YouCanNotShareThisInspection: 5,
  // An invited user can collaborate only with one main user.
  CollaboratorOfOtherUser: 6,
  /**
   *  If in the collaborations table one user collaborates
   * with more than one user it means that there is a problem
   * with the logic that prevents the multiple collaborations.
   */
  CollaboratesWithMoreThanOne: 7,
  /**
   * User can not be invited multiple times on specific structure.
   * In this update the colaborator
   */
  AlreadyInvitedOnThisStructure: 8,
  /**
   * User can not be invited multiple times on specific inspection.
   * In this update the colaborator
   */
  AlreadyInvitedOnThisInspection: 9,
  /**
   * Collaborators already invited to the platform.
   * In order to share the entity the user must be
   * updated and not reinvited.
   */
  YouMustUpdateNotInvite: 10,
  /**
   * Match not found for the collaborator and inviter
   */
  NotYourCollaborator: 11,

  /**
   * Inspections without structures must not exist in the system
   */
  SharedInspectionOfNotExistingBridge: 12,
  OnlyMainUserCanViewCollaboratorsPanel: 13,
  YouCanNotTryToShareWithInternalCollaboratorOfOthers: 14,
  NotMainUserInvolvedInShareWithRegisteredMainUser: 15,
  /**
   * On the platform can exist main users that collaborates with no one.
   * If an inviter targhet this kind of users the endpoints
   * /collaborators/structures/registeredExternal or
   * /collaborators/inspections/registeredExternal
   */
  YouMustCreateCollaborationWithRegisteredMainUserThatIsNotYourCollaborator: 16,
  CollaboratorNotFoundUseInvite: 17,
  CircularCondivision: 18,
  /**
   * Inspection can be shared with collaborators only with the can share permission.
   */
  TryingToShareOneOrMoreInspectionsWithForbiddenCanViewPermission: 19,
  ImpossibleToCreateCollaborationWithoutSharingEntities: 20,
  /**
   * Can not give to other users permission that does not belongs to you
   */
  CanNotShareEntityWithHigherPermissionsThanPossessedOne: 21,
  /**
   * If user A has lower permission on an entity than user B, A can not
   * downgrade permission of user B.
   */
  CanNotDowngradePermissionsOnUserWithHigherPermissionThanYouOnEntity: 22,
  CanNotModifyPermissionOnEntitiesOfTheOwnerOfTheBridge: 23,
  DoNotInviteToRegisterInternalCanShareEntitiesAmongThemselves: 24,
  CollaboratorAlreadyHaveEntityButWhereNotSharedByYou: 25,
  /**
   * The invited user where already invited on the specified inspeciton
   * and it has "can edit" permission. By design this action must be blocked.
   */
  alreadyinvitedOnInspectionWithCanModifyByOthers: 26,
  /**
   * The invited user where already invited on the specified structure
   * and it has "can edit" permission. By design this action must be blocked.
   */
  alreadyInvitedOnStructureWithCanEditByOther: 27,
  /**
   * Tryng to share again the inspection with the same collaborator.
   * The system will use the put call and will show a proper message
   */
  inspectionAlreadySharedFromThisInviterToThisInvited: 28,

  /**
   * Tryng to share again the bridge with the same collaborator.
   * The system will use the put call and will show a proper message
   */
  bridgeAlreadySharedFromThisInviterToThisInvited: 29,

  internalCantUpdateMainThatHasSharedWithHim: 32,
  internalAlreadySharedWithThisSiblingYouMustUpdateFromTheList: 33,
  selfInvitationNotAllowed: 34,
};

export const CollaborationsErrorsMessageMap = {
  [CollaborationsErrors.CollaboratorAlreadyExists]:
    "L'utente indicato esiste già",
  [CollaborationsErrors.YouCanNotInviteInternalCollaborators]:
    "Non puoi invitare collaboratori interni senza aver prima invitato l'utente principale",
  /* [CollaborationsErrors.AlreadyInvitedByYou]:
    "L'utente indicato risulta già invitato alla collaborazione, modificare di seguito i permessi", */
  [CollaborationsErrors.YouCanNotShareThisStructure]:
    'Non puoi condividere questa struttura',
  [CollaborationsErrors.YouCanNotShareThisInspection]:
    'Non puoi condividere questa ispezione',
  [CollaborationsErrors.CollaboratorOfOtherUser]:
    "ATTENZIONE! L'utente selezionato non è Utente Principale ma un suo Collaboratore. Puoi invitare solo l'Utente Principale.",
  [CollaborationsErrors.CollaboratesWithMoreThanOne]:
    "L'utente selezionato collabora con più di un utente.",
  [CollaborationsErrors.AlreadyInvitedOnThisStructure]:
    "L'utente indicato risulta già invitato alla collaborazione di questa struttura, modificare di seguito i permessi",

  [CollaborationsErrors.AlreadyInvitedOnThisInspection]:
    "L'utente indicato risulta già invitato alla collaborazione di questa ispezione, modificare di seguito i permessi",
  [CollaborationsErrors.YouMustUpdateNotInvite]:
    "L'utente indicato risulta già invitato alla collaborazione , modificare di seguito i permessi",
  [CollaborationsErrors.NotYourCollaborator]:
    "L'utente indicato non è un tuo collaboratore",
  [CollaborationsErrors.SharedInspectionOfNotExistingBridge]:
    "L'ispezione indicata appartiene a un ponte non esistente",
  [CollaborationsErrors.OnlyMainUserCanViewCollaboratorsPanel]:
    "Solo l'utente principale può vedere il pannello di collaborazione",
  [CollaborationsErrors.YouCanNotTryToShareWithInternalCollaboratorOfOthers]:
    'Non puoi condividere con collaboratori interni di altri utenti',
  [CollaborationsErrors.NotMainUserInvolvedInShareWithRegisteredMainUser]:
    "Nessun utente principale collegato all'azione di condivisione del tuo utente principale",
  [CollaborationsErrors.YouMustCreateCollaborationWithRegisteredMainUserThatIsNotYourCollaborator]:
    "L'utente indicato risulta già registrato alla piattaforma, inserire di seguito i permessi",
  [CollaborationsErrors.CollaboratorNotFoundUseInvite]:
    'Collaboratore non trovato, utilizzare invito',
  [CollaborationsErrors.CircularCondivision]: 'Condivisione circolare',
  [CollaborationsErrors.TryingToShareOneOrMoreInspectionsWithForbiddenCanViewPermission]:
    'Tentativo di condivisione ispezione con divieto di accesso in sola visualizzazione',
  [CollaborationsErrors.ImpossibleToCreateCollaborationWithoutSharingEntities]:
    'Impossibile creare una collaborazione senza aggiungere permessi ad almeno un ponte',
  [CollaborationsErrors.DoNotInviteToRegisterInternalCanShareEntitiesAmongThemselves]:
    "L'utente indicato risulta già invitato alla collaborazione, modificare di seguito i permessi",
  [CollaborationsErrors.CollaboratorAlreadyHaveEntityButWhereNotSharedByYou]:
    "L'utente indicato risulta già invitato alla collaborazione, modificare di seguito i permessi",
  [CollaborationsErrors.alreadyinvitedOnInspectionWithCanModifyByOthers]:
    "L'utente indicato risulta già invitato alla collaborazione impossibile modificare i suoi permessi",
  [CollaborationsErrors.alreadyInvitedOnStructureWithCanEditByOther]:
    "L'utente indicato risulta già invitato alla collaborazione impossibile modificare i suoi permessi",
  [CollaborationsErrors.inspectionAlreadySharedFromThisInviterToThisInvited]:
    "L'utente indicato risulta già invitato alla collaborazione dell'ispezione.",
  [CollaborationsErrors.bridgeAlreadySharedFromThisInviterToThisInvited]:
    "L'utente indicato risulta già invitato alla collaborazione del ponte.",
  [CollaborationsErrors.internalCantUpdateMainThatHasSharedWithHim]:
    "L'utente indicato risulta già invitato alla collaborazione.",
  [CollaborationsErrors.internalAlreadySharedWithThisSiblingYouMustUpdateFromTheList]:
    "L'utente indicato risulta già invitato alla collaborazione. Modificare i permessi dalla lista principale",
  [CollaborationsErrors.selfInvitationNotAllowed]:
    'Il tuo account risulta già invitato alla collaborazione.',
};
export const CollaborationsErrorsColorMap = {
  [CollaborationsErrors.CollaboratorAlreadyExists]: 'primary.light',
  [CollaborationsErrors.YouCanNotInviteInternalCollaborators]:
    'error.mauvelous',
  [CollaborationsErrors.AlreadyInvitedByYou]: 'primary.light',
  [CollaborationsErrors.YouCanNotShareThisStructure]: 'error.mauvelous',
  [CollaborationsErrors.YouCanNotShareThisInspection]: 'error.mauvelous',
  [CollaborationsErrors.CollaboratorOfOtherUser]: 'error.mauvelous',
  [CollaborationsErrors.CollaboratesWithMoreThanOne]: 'error.mauvelous',
  [CollaborationsErrors.AlreadyInvitedOnThisStructure]: 'primary.light',
  [CollaborationsErrors.AlreadyInvitedOnThisInspection]: 'primary.light',
  [CollaborationsErrors.YouMustUpdateNotInvite]: 'primary.light',
  [CollaborationsErrors.NotYourCollaborator]: 'error.mauvelous',
  [CollaborationsErrors.SharedInspectionOfNotExistingBridge]: 'error.mauvelous',
  [CollaborationsErrors.OnlyMainUserCanViewCollaboratorsPanel]:
    'error.mauvelous',
  [CollaborationsErrors.YouCanNotTryToShareWithInternalCollaboratorOfOthers]:
    'error.mauvelous',
  [CollaborationsErrors.NotMainUserInvolvedInShareWithRegisteredMainUser]:
    'error.mauvelous',
  [CollaborationsErrors.YouMustCreateCollaborationWithRegisteredMainUserThatIsNotYourCollaborator]:
    'primary.light',
  [CollaborationsErrors.CollaboratorNotFoundUseInvite]: 'error.mauvelous',
  [CollaborationsErrors.CircularCondivision]: 'error.mauvelous',
  [CollaborationsErrors.TryingToShareOneOrMoreInspectionsWithForbiddenCanViewPermission]:
    'error.mauvelous',
  [CollaborationsErrors.ImpossibleToCreateCollaborationWithoutSharingEntities]:
    'error.mauvelous',
  [CollaborationsErrors.DoNotInviteToRegisterInternalCanShareEntitiesAmongThemselves]:
    'primary.light',
  [CollaborationsErrors.CollaboratorAlreadyHaveEntityButWhereNotSharedByYou]:
    'primary.light',
  [CollaborationsErrors.alreadyinvitedOnInspectionWithCanModifyByOthers]:
    'error.mauvelous',
  [CollaborationsErrors.alreadyInvitedOnStructureWithCanEditByOther]:
    'error.mauvelous',
  [CollaborationsErrors.inspectionAlreadySharedFromThisInviterToThisInvited]:
    'error.mauvelous',
  [CollaborationsErrors.bridgeAlreadySharedFromThisInviterToThisInvited]:
    'error.mauvelous',
  [CollaborationsErrors.internalCantUpdateMainThatHasSharedWithHim]:
    'error.mauvelous',
  [CollaborationsErrors.internalAlreadySharedWithThisSiblingYouMustUpdateFromTheList]:
    'error.mauvelous',
  [CollaborationsErrors.selfInvitationNotAllowed]: 'error.mauvelous',
};

export const CollaborationType = {
  Internal: 1,
  External: 2,
};

export const CollaborationTypeMap = {
  [CollaborationType.Internal]: 'Collaboratore Interno',
  [CollaborationType.External]: 'Utente Principale',
};

export const CollaborationTypeOptions = [
  {
    label: CollaborationTypeMap[CollaborationType.Internal],
    value: CollaborationType.Internal,
  },
  {
    label: CollaborationTypeMap[CollaborationType.External],
    value: CollaborationType.External,
  },
];

export const CollaborationResponseStatus = {
  OK: 1,
  ERROR: 2,
};

export const NotEnoughPermissionMap = {
  [PermissionType.NoPermission]: true,
  [PermissionType.CanView]: true,
  [PermissionType.CanViewByInspectionReference]: true,
  [PermissionType.CanEdit]: false,
};
