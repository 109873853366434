import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';

import { Table } from 'components';
import { DrawerMedia } from 'components/DrawerMedia/DrawerMedia';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import {
  paliIlluminazioneOptions,
  tipologiaPaliOptions,
} from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap } from './Accordion1LightingPoles.styles';
import { PaliServiziForm } from './PaliServiziForm/PaliServiziForm';

const Accordion1LightingPoles = () => {
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();
  //State
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const { watch, setValue } = useFormContext();

  const watchPaliTable = watch('paliTable');

  const dataConfig = {
    columns: {
      paliIlluminazione: { label: 'Pali', format: paliIlluminazioneOptions },
      tipologiaPali: { label: 'Tipologia pali', format: tipologiaPaliOptions },
      tipologiaPaliAltro: { label: 'Altro' },
      nPaliTableValue: { label: 'Numero' },
      altezzaPaliTableValue: { label: 'Altezza [cm]' },
    },
  };

  const editRows = item => {
    setValue('paliIlluminazioneTableValue', item.paliIlluminazione);
    setValue('tipologiaPaliTableMultiValue', item.tipologiaPali);
    setValue('tipologiaAltroPaliTableValue', item.tipologiaPaliAltro);
    setValue('nPaliTableValue', item.numero);
    setValue('altezzaPaliTableValue', item.altezza);
  };

  return (
    <>
      <GridWrap container spacing={2} padding="20px" marginTop={-40}>
        {!inspectionDisabled && !isViewingInspection && (
          <PaliServiziForm paliFields={watchPaliTable} />
        )}
        <GridWrap item xs={12}>
          <Table
            data={watchPaliTable || []}
            config={dataConfig}
            rowActions={
              !inspectionDisabled && !isViewingInspection
                ? [
                    {
                      onClick: item => {
                        editRows(item);
                        setSelected([item]);
                      },
                      icon: <EditIcon />,
                    },
                  ]
                : []
            }
            mediaActions={[
              {
                onClick: item => {
                  setDrawerMediaIndex(
                    watchPaliTable.findIndex(el => el.id === item.id)
                  );
                  setOpen(true);
                },
                icon: <MediaIcon />,
              },
            ]}
            selected={selected}
            setSelected={setSelected}
          />
        </GridWrap>

        <DrawerMedia
          open={open}
          setOpen={setOpen}
          title={'Pali illuminati'}
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          dataType="video/*,image/*"
          uploadType={UploadMedia.media}
          type={FieldType.images}
          formName={`paliTable[${drawerMediaIndex}].media`}
          mediaGroup={[MediaGroup.Servizi]}
        />
      </GridWrap>
    </>
  );
};

export { Accordion1LightingPoles };
