import { useCallback } from 'react';
// eslint-disable-next-line import/no-named-as-default
import useFetch from 'use-http';

import { apiBridges } from 'constants/api';

export function useInspectionLockFetch() {
  const { loading, error, put } = useFetch(
    `${apiBridges.ROOT}${apiBridges.INSPECTIONS_ARCHIVE}`
  );

  const onInspectionLock = useCallback(
    inspectionId => put(`/${inspectionId}/lock`),
    [put]
  );

  return {
    loading,
    error,
    onInspectionLock,
  };
}
