import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { BoxStyle } from './LoadingScreen.style';

const LoadingScreen = ({ loading }) => {
  if (!loading) return null;
  return (
    <Backdrop
      open={true}
      sx={{
        zIndex: 1500,
      }}
    >
      <BoxStyle>
        <CircularProgress />
      </BoxStyle>
    </Backdrop>
  );
};

export { LoadingScreen };

LoadingScreen.propTypes = {
  loading: PropTypes.bool,
};
