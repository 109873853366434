import { Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import theme from 'theme';
import { BoxContainer, BoxTitle } from './WhiteBoxInfoDrawer.style';

export const WhiteBoxInfoDrawer = ({ title, description }) => {
  return (
    <BoxContainer>
      <BoxTitle>
        <Typography variant="h3Bold" style={{ color: theme.palette.info.main }}>
          {title}
        </Typography>
      </BoxTitle>
      <Typography sx={{ lineHeight: 1.5 }} variant="h3">
        {description}
      </Typography>
    </BoxContainer>
  );
};

WhiteBoxInfoDrawer.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
