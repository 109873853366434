import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  RHFMultiSelect,
  RHFSelect,
} from 'components';
import { mensole } from 'constants/inspections';
import calculateDifettositaSvrastruttura from 'hooks/inspections/calculateDifettositaSovrastruttura';
import { useInspectionSelector } from 'stores';

const TabellaRiepilogativaForm = ({ fields, selected, setSelected }) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      tabellaRiepilogativaTableValue: '',
      elementiAssociatiTableValue: [],
    },
  });

  const { schedaDifettiTrigger } = useInspectionSelector();
  const [schemaMensoleTamponi, setSchemaMensoleTamponi] = useState(false);

  const {
    calculateSovrastrutturaDifettositaStrutturale,
    calculateSovrastrutturaDifettositaSismica,
  } = calculateDifettositaSvrastruttura();

  useEffect(() => {
    const watchMensole = getValues('mensole');
    setSchemaMensoleTamponi(watchMensole === mensole.Si);
  }, []);

  useEffect(() => {
    const watchMensole = getValues('mensole');
    setSchemaMensoleTamponi(watchMensole === mensole.Si);
  }, [schedaDifettiTrigger]);

  const onSubmit = () => {
    const selected = getValues('tabellaRiepilogativaTableValue');

    const elementiAssociati = getValues('elementiAssociatiTableValue');

    const tabellaRiepilogativa = getValues('riepilogoStrutturaTable');
    const tmp = [...tabellaRiepilogativa];

    tmp.map((x, idx) => {
      if (x.codice === selected) {
        tmp[idx] = {
          ...x,
          elementiAssociati:
            elementiAssociati.length > 0
              ? elementiAssociati
              : ['nessun elemento associato'],
          difettositaStrutturale:
            calculateSovrastrutturaDifettositaStrutturale(elementiAssociati),
          difettositaSismica:
            calculateSovrastrutturaDifettositaSismica(elementiAssociati),
        };
      }
    });

    setValue('riepilogoStrutturaTable', tmp);
  };
  const onReset = () => {
    setValue('tabellaRiepilogativaTableValue', '');
    setValue('elementiAssociatiTableValue', []);
    setSelected([]);
  };

  const [options, setOptions] = useState(
    getValues('mensoleTamponiCampateImpalcatoTable').map(el => {
      return { value: el.codice, label: el.codice };
    })
  );

  return (
    <GridWrap container spacing={2} mt={2}>
      {schemaMensoleTamponi && (
        <>
          <GridWrap item xs={3}>
            <RHFSelect
              name={'tabellaRiepilogativaTableValue'}
              onChange={e => {
                setValue('tabellaRiepilogativaTableValue', e.target.value);
              }}
              defaultValue={''}
              selectOptions={fields}
              label="Sovrastruttura Selezionata"
            />
          </GridWrap>
          <GridWrap item xs={3}>
            <RHFMultiSelect
              name="elementiAssociatiTableValue"
              label="Elementi Associati"
              defaultValue={[]}
              onChange={e => {
                setValue('elementiAssociatiTableValue', e.target.value);
              }}
              selectOptions={options}
            />
          </GridWrap>
          <GridWrap item xs={6}>
            <FormSubmitButtons onSubmit={onSubmit} onReset={onReset} />
          </GridWrap>
        </>
      )}
    </GridWrap>
  );
};

TabellaRiepilogativaForm.propTypes = {
  fields: PropTypes.array,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default TabellaRiepilogativaForm;
