import styled from '@emotion/styled';
import { CdaColor, colors } from 'constants/colors';

export const CardContainer = styled('div')({
  height: '232px',
  width: '375px',
  // cursor: 'pointer',
  position: 'relative',
});
export const DisabledCard = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#f4f5f699',
  zIndex: 9,
  '&:hover': {
    cursor: 'pointer',
  },
});

export const Card = styled('div')({
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  backgroundColor: colors.WHITE,
  position: 'relative',
  padding: '10px 20px',
});

export const StatusContainer = styled('div')({
  height: '40px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& p': {
    fontSize: '12px',
    whiteSpace: 'no-wrap',
    width: '100%',
  },
});

export const Status = styled('div')(({ color }) => ({
  height: '11px',
  width: '11px',
  borderRadius: '100%',
  backgroundColor: color,
  marginRight: '5px',
}));

export const CardContent = styled('div')({
  cursor: 'pointer',
  height: 'calc(100% - 40px)',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  '& span': {
    fontSize: '14px',
    fontWeight: 'medium',
    lineHeight: '16px',
    margin: '0',
  },
});

export const CardImage = styled('div')({
  height: '155px',
  minWidth: '95px',
  borderRadius: '13px',
  backgroundColor: colors.GREY,
  position: 'relative',
  overflow: 'hidden',
  marginRight: '10px',
  '& img': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },
});

export const CardImageLabel = styled('div')(({ cda }) => ({
  height: '26px',
  width: '26px',
  borderRadius: '100%',
  position: 'absolute',
  /* backgroundColor: colors.LIGHTYELLOW, */
  backgroundColor: cda && CdaColor[cda].labelColor,
  bottom: '10px',
  right: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${cda && CdaColor[cda].labelColor}`,
  '& label': {
    fontSize: '8px',
    fontWeight: 'bold',
  },
}));

export const CardText = styled('div')({
  height: '100%',
  width: 'calc(100% - 90px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  '& h2': {
    fontSize: '24px',
    margin: '2px 0 5px 0',
    lineHeight: '27px',
  },
});

export const CardPosisiton = styled('div')({
  height: '20px',
  width: '100%',
  display: 'flex',
  '& span': {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      height: '20px',
      marginRight: '8px',
    },
  },
});

export const TrashIcon = styled('div')({
  height: '20px',
  width: '20px',
  position: 'absolute',
  top: '15px',
  right: '15px',
  cursor: 'pointer',
  '& img': {
    height: '20px',
    margin: '0 auto',
  },
});

export const ModalContainer = styled('div')({
  background: colors.DISABLED_WHITE,
  position: 'absolute',
  width: '100%',
  left: 0,
  top: 0,
  height: '100%',
  borderRadius: 8,

  backdropFilter: 'blur(5px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 20,
});

export const BtnModalWrapper = styled('div')({
  display: 'flex',
  gap: 8,
  marginTop: 16,
});
