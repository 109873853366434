import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormSubmitButtons, GridWrap, RHFMultiSelect } from 'components';
import { tipologiaConvogliamentoAcqueOptions } from 'constants/selectOptions';

const ConvogliamentoAcqueForm = ({
  convogliamentoAcqueFields,
  selected,
  setSelected,
  convogliamentoAcqueTable,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      convogliamentoAcqueTableValue: [],
      tipologiaConvogliamentoAcqueTableValue: '',
    },
  });

  useEffect(() => {
    setValue('convogliamentoAcqueTableValue', selected);
  }, [selected]);

  const onSubmitConvogliamentoAcque = () => {
    const tipologiaConvogliamentoAcqueTableValue = getValues(
      'tipologiaConvogliamentoAcqueTableValue'
    );

    const ids = selected.map(el => el.codice);

    const convogliamentoAcque = [...convogliamentoAcqueFields];

    convogliamentoAcque.map((x, index) => {
      if (ids.includes(x?.codice)) {
        convogliamentoAcque[index] = {
          ...x,
          tipologiaConvogliamentoAcque: tipologiaConvogliamentoAcqueTableValue,
        };
      }
    });

    setValue(convogliamentoAcqueTable, convogliamentoAcque);
  };

  const onResetConvogliamentoAcque = () => {
    setValue('convogliamentoAcqueTableValue', []);
    setValue('tipologiaConvogliamentoAcqueTableValue', '');
    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <GridWrap item xs={3}>
        <RHFMultiSelect
          name="tipologiaConvogliamentoAcqueTableValue"
          label="Tipologia Convogliamento Acque"
          defaultValue={[]}
          onChange={e => {
            setValue('tipologiaConvogliamentoAcqueTableValue', e.target.value);
          }}
          selectOptions={tipologiaConvogliamentoAcqueOptions}
        />
      </GridWrap>
      <GridWrap item xs={9}>
        <FormSubmitButtons
          onSubmit={onSubmitConvogliamentoAcque}
          onReset={onResetConvogliamentoAcque}
        />
      </GridWrap>
    </GridWrap>
  );
};
ConvogliamentoAcqueForm.propTypes = {
  convogliamentoAcqueFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaConvogliamentoAcque: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  convogliamentoAcqueTable: PropTypes.string,
};

export default ConvogliamentoAcqueForm;
