import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaGiuntoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import { GridWrap } from './AppoggiForm.styles';

const GiuntiForm = ({
  giuntiFields,
  selected,
  setSelected,
  giuntiTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      l0t2a4_giuntiTableValue: [],
      l0t2a4_tipologiaGiuntoTableValue: '',
      l0t2a4_larghezzaGiuntoTableValue: '',
      l0t2a4_profonditaGiuntoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a4_giuntiTableValue', selected);
  }, [selected]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  useEffect(() => {
    onResetGiunti();
  }, [selectedIdx]);

  const changeGiunti = () => {
    const l0t2a4_tipologiaGiuntoTableValue = getValues(
      'l0t2a4_tipologiaGiuntoTableValue'
    );
    const l0t2a4_larghezzaGiuntoTableValue = getValues(
      'l0t2a4_larghezzaGiuntoTableValue'
    );
    const l0t2a4_profonditaGiuntoTableValue = getValues(
      'l0t2a4_profonditaGiuntoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const giunti = [...giuntiFields];

    giunti.map((x, index) => {
      if (ids.includes(x?.codice)) {
        giunti[index] = {
          ...x,
          tipologiaGiunto: l0t2a4_tipologiaGiuntoTableValue,
          larghezzaGiunto: l0t2a4_larghezzaGiuntoTableValue,
          profonditaGiunto: l0t2a4_profonditaGiuntoTableValue,
          ...(l0t2a4_tipologiaGiuntoTableValue !== x.tipologiaGiunto
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'giunto',
                  l0t2a4_tipologiaGiuntoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(giuntiTable, giunti);
    onResetGiunti();
  };

  const onConfirmChangeGiunti = () => {
    setOpenConfirmationDialog(false);
    changeGiunti();
  };

  const onSubmitGiunti = () => {
    const l0t2a4_tipologiaGiuntoTableValue = getValues(
      'l0t2a4_tipologiaGiuntoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const giunti = [...giuntiFields];

    const hasChangedTipologia = giunti.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a4_tipologiaGiuntoTableValue !== el.tipologiaGiunto &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaGiunto)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeGiunti();
    }
  };

  const onResetGiunti = () => {
    setValue('l0t2a4_giuntiTableValue', []);
    setValue('l0t2a4_tipologiaGiuntoTableValue', '');
    setValue('l0t2a4_larghezzaGiuntoTableValue', '');
    setValue('l0t2a4_profonditaGiuntoTableValue', '');

    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={6} infoMessage={INFO.giuntiSelezionati.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-giunti"
            name={'l0t2a4_giuntiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={giuntiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Giunti selezionati"
          />
        </InfoWrapper>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          quoteMessage={INFO.tipologiaGiunto.quoteMessage}
          drawerTitle={INFO.tipologiaGiunto.drawerTitle}
          drawerText={INFO.tipologiaGiunto.drawerText}
        >
          <RHFSelect
            name="l0t2a4_tipologiaGiuntoTableValue"
            label="Tipologia Giunto*"
            defaultValue={''}
            onChange={e => {
              setValue('l0t2a4_tipologiaGiuntoTableValue', e.target.value);
            }}
            selectOptions={tipologiaGiuntoOptions}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a4_larghezzaGiuntoTableValue"
            label={'Larghezza (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a4_profonditaGiuntoTableValue"
            label={'Profondità (P) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={9}>
          <FormSubmitButtons
            onSubmit={onSubmitGiunti}
            onReset={onResetGiunti}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeGiunti}
      />
    </>
  );
};
GiuntiForm.propTypes = {
  giuntiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaGiunto: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      larghezzaGiunto: PropTypes.number,
      profonditaGiunto: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  giuntiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default GiuntiForm;
