import styled from '@emotion/styled';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';

export const TabPanelContainer = styled('div')({
  backgroundColor: '#FFFFFF',
});

export const BoxPanel = styled(Box)({
  width: '100%',
  backgroundColor: '#FFFFFF',
  padding: 25,
});

export const StyledTabs = styled(Tabs)(({ theme, typevariant }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: `${theme.palette.info.main}`,
    height: '2.5px',
  },
  borderBottom:
    typevariant === 'secondary'
      ? `3px solid ${theme.palette.info.main}`
      : `1px solid ${theme.palette.error.light}`,
}));
export const StyledTab = styled(Tab)(({ theme, typevariant }) => ({
  backgroundColor:
    typevariant === 'secondary' ? theme.palette.background.grey : 'transparent',
  borderBottom: `1px solid ${theme.palette.error.light}`,
  fontSize: '14px',
  padding: '0px 40px',
  textTransform: 'capitalize',
  opacity: 1,
  // fontWeight: 'bold',
  border: typevariant === 'secondary' ? '0.5px solid #F4F5F6' : 0,
  borderRadius: typevariant === 'secondary' ? '8px 8px 0px 0px' : 0,
  '&.Mui-selected': {
    color:
      typevariant === 'secondary'
        ? theme.palette.secondary.light
        : theme.palette.info.main,
    fontWeight: 'bold',
    backgroundColor:
      typevariant === 'secondary' ? theme.palette.info.main : 'inherit',
  },
  '&.MuiButtonBase-root': {
    textTransform: 'none',
    fontWeight: 'bold',
  },
}));
