import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { difettosita_G, StatoDifetto } from 'constants/difetti';
import { useInspectionSelector } from 'stores';
import useCDA from 'utils/Inspections/L1/calculateCDA';
import SchedaDifettiElementiStrutturaliRow from './SchedaDifettiElementiStrutturaliRow';
import SchedaDifettiElementiSovrastrutturaRow from '../ElementiAccessori/SchedaDifettiElementiAccessoriRow';

const SchedaDifettiElementiStrutturali = ({
  scheda,
  name,
  containerName,
  isScheda20 = false,
  skipForcedRerender = false,
}) => {
  const { getValues, setValue, watch } = useFormContext();

  const { setSchedaDifettiTrigger } = useInspectionSelector();

  const { calculateCDA } = useCDA();
  const scheda6ignore = [1, 2, 3, 5, 6, 8, 9, 10, 13, 14, 15, 22];
  const scheda6ignoreWatch = watch(`${name}.schedaDifetti`).some(
    el => el.id == 95 && el.visto && el.statoDifetto
  );
  const elementoCriticoStrutturaWatch = watch(
    `${name}.elementoCriticoStruttura`
  );

  const condizioneCriticaStrutturaWatch = watch(
    `${name}.condizioneCriticaStruttura`
  );
  const elementoCriticoSismicoWatch = watch(`${name}.elementoCriticoSismica`);

  const condizioneCriticaSismicoWatch = watch(
    `${name}.condizioneCriticaSismica`
  );
  const schedaDifettiTriggerWatch = watch(`${name}.schedaDifettiTrigger`);

  useEffect(() => {
    /*  console.log('RICALCOLO SCHEDA DIFETTI'); */
    let indiceCondizione = 0;
    let completamentoSchedaDifetti = 0;
    let g5totali = 0;
    let pstotali = 0;
    let nrtotali = 0;

    let totalG = 0;
    let totalGK1K2 = 0;

    // we check if is the case of Scheda 6, removing from the percentage the unusable conditions
    if (
      scheda6ignore.some(el =>
        getValues(`${name}.schedaDifetti`).some(o => el == o.id)
      ) &&
      scheda6ignoreWatch
    ) {
      completamentoSchedaDifetti =
        (getValues(`${name}.schedaDifetti`).filter(
          el =>
            el.visto &&
            ((el.intensitaDifetto && el.estensioneDifetto) ||
              el.statoDifetto) &&
            scheda6ignore.every(o => o != el.id)
        ).length /
          (getValues(`${name}.schedaDifetti`).length - scheda6ignore.length)) *
        100;
    } else {
      completamentoSchedaDifetti =
        (getValues(`${name}.schedaDifetti`).filter(
          el =>
            el.visto &&
            ((el.intensitaDifetto && el.estensioneDifetto) || el.statoDifetto)
        ).length /
          getValues(`${name}.schedaDifetti`).length) *
        100;
    }
    setValue(
      `${name}.completamentoSchedaDifetti`,
      `${completamentoSchedaDifetti.toFixed(2)}%`
    );

    // per l'indice di condizione, nel caso della scheda 6, dobbiamo rivedere se i campi sono attivi o
    let list;

    if (
      scheda6ignore.some(el =>
        getValues(`${name}.schedaDifetti`).some(o => el == o.id)
      ) &&
      scheda6ignoreWatch
    ) {
      list = getValues(`${name}.schedaDifetti`).filter(
        el => el.visto && scheda6ignore.every(o => o != el.id)
      );
    } else {
      list = getValues(`${name}.schedaDifetti`);
    }

    if (
      list.some(
        el =>
          !el.visto ||
          !((el.intensitaDifetto && el.estensioneDifetto) || el.statoDifetto)
      )
    ) {
      setValue(`${name}.indiceCondizione`, 'n.a.');
      setValue(`${name}.nrtotali`, 'n.a.');
      setValue(`${name}.CDAstrutturale`, null);
      setValue(`${name}.CDAsismica`, null);
      return;
    }

    // IC
    // questa logica setta l'Indice di Condizione della struttura

    g5totali = list.filter(
      el =>
        el.difettosita == difettosita_G.G5 &&
        (el.intensitaDifetto != null || el.estensioneDifetto != null)
    ).length;
    setValue(`${name}.g5totali`, g5totali);

    nrtotali = list.filter(el => el.statoDifetto == StatoDifetto.NR).length;
    setValue(`${name}.nrtotali`, nrtotali);

    list.forEach(el => {
      if (el.ps) {
        pstotali += 1;
      }
      if (el.statoDifetto != StatoDifetto.NA) {
        totalG += parseInt(el.difettosita);
      }
      if (!el.statoDifetto) {
        totalGK1K2 += parseFloat(
          parseInt(el.difettosita) *
            parseFloat(el.estensioneDifetto) *
            parseFloat(el.intensitaDifetto)
        );
      }
    });
    indiceCondizione = parseFloat((1 - totalGK1K2 / totalG) * 100);
    if (!isNaN(indiceCondizione)) {
      if (indiceCondizione != 100) {
        setValue(`${name}.indiceCondizione`, `${indiceCondizione.toFixed(2)}%`);
      } else {
        setValue(`${name}.indiceCondizione`, `100%`);
      }
    } else {
      setValue(`${name}.indiceCondizione`, `n.a.`);
    }

    setValue(`${name}.summationk2G12345`, totalGK1K2);
    setValue(`${name}.summationG`, totalG);
    setValue(`${name}.pstotali`, pstotali);

    // CDA
    // Questa logica setta la Classe d'Attenzione dell'elemento strutturale
    // I can calculate "Difettosità Str." if defects sheet (scheda difetti) completed (completamentoSchedaDifetti = 100%)
    if (completamentoSchedaDifetti === 100) {
      calculateCDA(
        condizioneCriticaStrutturaWatch,
        elementoCriticoStrutturaWatch,
        'strutturale',
        name
      );
    }
    // I can calculate "Difettosità Sis." if defects sheet (scheda difetti) completed (completamentoSchedaDifetti = 100%)
    if (completamentoSchedaDifetti === 100) {
      calculateCDA(
        condizioneCriticaSismicoWatch,
        elementoCriticoSismicoWatch,
        'sismica',
        name
      );
    }

    // TODO force re-rerender of maincontainer so that the values are printed correctly
    if (!skipForcedRerender) {
      setValue(containerName, getValues(containerName));
      // trigger(containerName);
      // this triggers the store of inspections so that the TabellaRiepilogativa can be recalculated
      setSchedaDifettiTrigger(nanoid());
    }
  }, [
    schedaDifettiTriggerWatch,
    condizioneCriticaStrutturaWatch,
    elementoCriticoStrutturaWatch,
    elementoCriticoSismicoWatch,
    condizioneCriticaSismicoWatch,
  ]);

  if (!scheda) {
    return (
      <Typography color="error" textAlign={'center'}>
        Attenzione! Non sarà possibile compilare una scheda di valutazione
        difetti finchè non sarà rilevata la tipologia dell&apos;elemento
      </Typography>
    );
  }
  if (scheda[0].alert) {
    return (
      <Typography color="error" textAlign={'center'}>
        Attenzione! Non sarà possibile compilare una scheda di valutazione
        difetti finchè non sarà rilevata la tipologia dell&apos;elemento
      </Typography>
    );
  }
  if (scheda[0].alertFondazione) {
    return (
      <Typography color="error" textAlign={'center'}>
        Attenzione! Non sarà possibile compilare una scheda di valutazione
        difetti finchè non sarà rilevata la tipologia di fondazione
        dell&apos;elemento
      </Typography>
    );
  }
  if (scheda[0].id == 'nonPrevisto') {
    return (
      <Typography color="error" textAlign={'center'}>
        Per un elemento &quot;Non Previsto&quot; non si richiede la compilazione
        della scheda
      </Typography>
    );
  }

  return (
    <Box>
      {(process.env.REACT_APP_ENVIRONMENT == 'develop' ||
        process.env.REACT_APP_ENVIRONMENT == 'test') && (
        <Grid container spacing={2} my={3}>
          <Grid item xs={6}>
            <Button
              color="info"
              fullWidth
              variant="contained"
              onClick={() =>
                console.table({ ...getValues(name), schedaDifetti: '' })
              }
            >
              analizza scheda ispezione in console
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="info"
              fullWidth
              variant="contained"
              onClick={() => console.table(scheda)}
            >
              analizza scheda difetti in console
            </Button>
          </Grid>
        </Grid>
      )}
      <TableContainer sx={{ minWidth: 650, maxHeight: 440 }}>
        <Table aria-label="simple table">
          <TableHead
            style={{
              backgroundColor: '#F4F5F6',
              position: 'sticky',
              top: 0,
              zIndex: 3,
            }}
          >
            {!isScheda20 && (
              <>
                <TableRow style={{ outline: '1px solid #b8b8b8' }}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center" colSpan={3}>
                    Estensione K1
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    Intensità K2
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow style={{ outline: '1px solid #b8b8b8' }}>
                  <TableCell>Codice difetto</TableCell>
                  <TableCell>Descrizione difetto</TableCell>
                  <TableCell align="center">Visto</TableCell>
                  <TableCell align="center">NA</TableCell>
                  <TableCell align="center">NR</TableCell>
                  <TableCell align="center">NP</TableCell>
                  <TableCell align="center">G</TableCell>
                  <TableCell align="center">0,2</TableCell>
                  <TableCell align="center">0,5</TableCell>
                  <TableCell align="center">1</TableCell>
                  <TableCell align="center">0,2</TableCell>
                  <TableCell align="center">0,5</TableCell>
                  <TableCell align="center">1</TableCell>
                  <TableCell align="center">PS</TableCell>
                  <TableCell>Foto</TableCell>
                  <TableCell>Note</TableCell>
                </TableRow>
              </>
            )}
            {isScheda20 && (
              <>
                {scheda.some(difetto => difetto.id === 'assente') && (
                  <TableRow style={{ outline: '1px solid #b8b8b8' }}>
                    <TableCell>Codice difetto</TableCell>
                    <TableCell>Descrizione difetto</TableCell>
                    <TableCell align="center">Visto</TableCell>
                    <TableCell align="center">NR</TableCell>
                    <TableCell align="center">G</TableCell>
                    <TableCell align="center">Presente</TableCell>
                    <TableCell>Foto</TableCell>
                    <TableCell>Note</TableCell>
                  </TableRow>
                )}
                {scheda.some(
                  difetto =>
                    difetto.id === '121.a' ||
                    difetto.id === '121.b' ||
                    difetto.id === '121.c' ||
                    difetto.id === '121.d'
                ) && (
                  <TableRow style={{ outline: '1px solid #b8b8b8' }}>
                    <TableCell>Codice difetto</TableCell>
                    <TableCell>Descrizione difetto</TableCell>
                    <TableCell align="center">Visto</TableCell>
                    <TableCell align="center">NR</TableCell>
                    <TableCell align="center">G</TableCell>
                    <TableCell align="center">Presente</TableCell>
                    <TableCell align="center">Lievemente</TableCell>
                    <TableCell align="center">Gravemente</TableCell>
                    <TableCell align="center">Danneggiati</TableCell>
                    <TableCell align="center">Ossidati</TableCell>
                    <TableCell>Foto</TableCell>
                    <TableCell>Note</TableCell>
                  </TableRow>
                )}
                {!scheda.some(
                  difetto =>
                    difetto.id === '121.a' ||
                    difetto.id === '121.b' ||
                    difetto.id === '121.c' ||
                    difetto.id === '121.d' ||
                    difetto.id === 'assente'
                ) && (
                  <TableRow style={{ outline: '1px solid #b8b8b8' }}>
                    <TableCell>Codice difetto</TableCell>
                    <TableCell>Descrizione difetto</TableCell>
                    <TableCell align="center">Visto</TableCell>
                    <TableCell align="center">NR</TableCell>
                    <TableCell align="center">NP</TableCell>
                    <TableCell align="center">G</TableCell>
                    <TableCell align="center">Presente</TableCell>
                    <TableCell>Foto</TableCell>
                    <TableCell>Note</TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableHead>
          {!isScheda20 && (
            <TableBody>
              {scheda.map((difetto, idx) => (
                <SchedaDifettiElementiStrutturaliRow
                  key={difetto.id}
                  difetto={difetto}
                  name={name}
                  containerName={containerName}
                  idx={idx}
                />
              ))}
            </TableBody>
          )}
          {isScheda20 && (
            <TableBody>
              {scheda.map((difetto, idx) => (
                <SchedaDifettiElementiSovrastrutturaRow
                  key={difetto.id}
                  difetto={difetto}
                  name={name}
                  containerName={containerName}
                  idx={idx}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

SchedaDifettiElementiStrutturali.propTypes = {
  scheda: PropTypes.array,
  name: PropTypes.string,
  containerName: PropTypes.string,
  isScheda20: PropTypes.bool,
  skipForcedRerender: PropTypes.bool,
};

export { SchedaDifettiElementiStrutturali };
