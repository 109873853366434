import { Pagination as MuiPagination, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { PaginationContainer } from './Pagination.style';
export const Pagination = ({ count, page, onChange, pageSize, total }) => {
  return (
    <PaginationContainer>
      <Typography variant="body">
        Mostra da{' '}
        {pageSize * page - pageSize === 0 ? 1 : pageSize * page - pageSize} a{' '}
        {pageSize * page > total ? total : pageSize * page} di {total} elementi
      </Typography>
      <MuiPagination
        color="primary"
        count={count}
        page={page}
        onChange={(event, value) => onChange(value)}
      />
    </PaginationContainer>
  );
};
Pagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

/* label={`Mostra da
            ${
              pageSize * page - pageSize === 0 ? 1 : pageSize * page - pageSize
            } a
            ${
              pageSize * page > total ? total : pageSize * page
            } di ${total} elementi`} */
