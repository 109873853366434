import { ExpandMore } from '@mui/icons-material';
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Pdf from 'assets/icons/file-pdf.svg';

import ViewBtn from 'assets/icons/icon-button-view.svg';
import { HeaderPage, ImageCarousel, VideoTutorialBox } from 'components';
import {
  faqContentTypes,
  faqSections,
  videoTutorials,
} from 'constants/infoAssistance';
import { INFO_ASSISTANCE_PATHS } from 'constants/paths';
import { downloadFile } from 'utils/upload';
import {
  Container,
  FileContainer,
  IconShowPdf,
  InfoFlex,
  StyledAccordion,
  WhiteContainer,
} from './FAQ.styles';

const ContentHtml = ({ innerHtml }) => {
  return (
    <Typography variant="p" fontSize={'16px'}>
      {innerHtml}
    </Typography>
  );
};

ContentHtml.propTypes = {
  innerHtml: PropTypes.string,
};
const ContentImages = ({ images }) => {
  return (
    <div style={{ height: '350px', margin: '20px 0px' }}>
      <ImageCarousel
        items={images}
        slidesPerView={3}
        canClick={images?.length > 0}
        maxWidth={images && images?.length <= 3 ? '33%' : 'unset'}
      />
    </div>
  );
};

ContentImages.propTypes = {
  images: PropTypes.array,
};

const renderContent = content => {
  switch (content.type) {
    case faqContentTypes.HTML:
      return <ContentHtml innerHtml={content.innerHtml} />;
    case faqContentTypes.IMAGES:
      return <ContentImages images={content.images} />;
    default:
      return <></>;
  }
};

const FAQ = () => {
  const [searchValue, setSearchValue] = useState('');

  const filterFaq = arr => {
    if (!searchValue) {
      return arr;
    }
    return arr.filter(currentFaq =>
      searchValue
        .split(' ')
        .some(str =>
          currentFaq.metaDescription
            .split(' ')
            .some(mt => mt.toLowerCase() === str)
        )
    );
  };

  return (
    <>
      <HeaderPage
        back={INFO_ASSISTANCE_PATHS.ROOT}
        title={'FAQ'}
        dataBreadcrumbs={[
          { path: INFO_ASSISTANCE_PATHS.ROOT, label: 'Info & Assistenza' },
          { path: INFO_ASSISTANCE_PATHS.FAQ, label: 'FAQ' },
        ]}
      />
      <Container>
        <WhiteContainer>
          <Typography variant="h2" fontSize={'16px'}>
            Ricerca fra le domande più frequenti
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <TextField
                value={searchValue}
                onChange={e => setSearchValue(e.target.value?.toLowerCase())}
                placeholder="cerca"
              />
            </Grid>
          </Grid>
        </WhiteContainer>
        {faqSections.map((faqSection, idx) => {
          if (filterFaq(faqSection.faq).length === 0) {
            return <></>;
          }
          return (
            <WhiteContainer key={idx}>
              <Typography variant="h2" fontSize={'18px'}>
                {faqSection.title}
              </Typography>
              {filterFaq(faqSection.faq).map((currentFaq, idx) => {
                return (
                  <StyledAccordion key={idx}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h3Faq" fontSize={'16px'}>
                        {currentFaq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {currentFaq.content.map(cnt => renderContent(cnt))}
                      <Grid container spacing={2} mt={1} mb={2}>
                        {currentFaq.videoIds?.length > 0 && (
                          /*  process.env.REACT_APP_ENVIRONMENT !== 'main' && */
                          <Grid item xs={12}>
                            <Typography
                              variant="p"
                              fontSize={'18px'}
                              fontWeight={'bolder'}
                            >
                              Video correlati
                            </Typography>
                          </Grid>
                        )}
                        {
                          /*  process.env.REACT_APP_ENVIRONMENT !== 'main' && */
                          videoTutorials
                            .filter(video =>
                              currentFaq.videoIds.includes(video.id)
                            )
                            .map(el => {
                              return (
                                <Grid item key={el.id} xs={4}>
                                  <VideoTutorialBox
                                    bgColor={'#FFFFFF'}
                                    title={el.title}
                                    youtubeId={el.youtubeId}
                                    description={el.description}
                                  />
                                </Grid>
                              );
                            })
                        }
                      </Grid>
                      <Grid container spacing={2} mt={1} mb={2}>
                        {currentFaq.guides?.length > 0 && (
                          <Grid item xs={12}>
                            <Typography
                              variant="p"
                              fontSize={'18px'}
                              fontWeight={'bolder'}
                            >
                              Guide correlate
                            </Typography>
                          </Grid>
                        )}
                        {currentFaq.guides?.map((file, idx) => (
                          <Grid item key={idx} xs={4}>
                            <FileContainer>
                              <InfoFlex>
                                <img src={Pdf} width={18} />
                                <Typography variant="body">
                                  {file.title}
                                </Typography>
                              </InfoFlex>
                              <InfoFlex>
                                <Typography variant="body">
                                  {file.size}
                                </Typography>
                                <IconShowPdf
                                  src={ViewBtn}
                                  onClick={() => downloadFile(file.path)}
                                />
                              </InfoFlex>
                            </FileContainer>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                );
              })}
            </WhiteContainer>
          );
        })}
      </Container>
    </>
  );
};

export default FAQ;
