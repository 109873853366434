import { useEffect } from 'react';

import { useGlobalSelector } from 'stores';

export function useLoader(loading) {
  const { showLoader, hideLoader } = useGlobalSelector();

  useEffect(() => {
    if (loading) {
      showLoader();
    } else {
      hideLoader(false);
    }
  }, [loading]);
}
