import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaCordoloOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
const CordoliForm = ({
  cordoliFields,
  selected,
  setSelected,
  cordoliTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      l0t3a2_cordoliTableValue: [],
      l0t3a2_tipologiaCordoloTableValue: '',
      l0t3a2_altezzaCordoloTableValue: '',
      l0t3a2_profonditaCordoloTableValue: '',
      l0t3a2_lunghezzaCordoloTableValue: '',
    },
  });

  useEffect(() => {
    setValue('l0t3a2_cordoliTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetCordoli();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitCordoli = () => {
    const l0t3a2_tipologiaCordoloTableValue = getValues(
      'l0t3a2_tipologiaCordoloTableValue'
    );
    const l0t3a2_altezzaCordoloTableValue = getValues(
      'l0t3a2_altezzaCordoloTableValue'
    );
    const l0t3a2_profonditaCordoloTableValue = getValues(
      'l0t3a2_profonditaCordoloTableValue'
    );

    const l0t3a2_lunghezzaCordoloTableValue = getValues(
      'l0t3a2_lunghezzaCordoloTableValue'
    );

    const ids = selected.map(el => el.codice);

    const cordoli = [...cordoliFields];

    cordoli.map((x, index) => {
      if (ids.includes(x?.codice)) {
        cordoli[index] = {
          ...x,
          tipologiaCordolo: l0t3a2_tipologiaCordoloTableValue,
          altezzaCordolo: l0t3a2_altezzaCordoloTableValue,
          profonditaCordolo: l0t3a2_profonditaCordoloTableValue,
          lunghezzaCordolo: l0t3a2_lunghezzaCordoloTableValue,
        };
      }
    });

    setValue(cordoliTable, cordoli);
    onResetCordoli();
  };
  const onResetCordoli = () => {
    setValue('l0t3a2_cordoliTableValue', []);
    setValue('l0t3a2_tipologiaCordoloTableValue', '');
    setValue('l0t3a2_altezzaCordoloTableValue', '');
    setValue('l0t3a2_profonditaCordoloTableValue', '');
    setValue('l0t3a2_lunghezzaCordoloTableValue', '');

    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper size={6} infoMessage={INFO.cordoliSelezionati.infoMessage}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-cordoli_l0t3a2"
          name={'l0t3a2_cordoliTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={cordoliFields
            .filter(el => !el.assente)
            ?.filter(el => !selected?.some(s => s?.id === el?.id))}
          label="Cordoli selezionati"
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="l0t3a2_tipologiaCordoloTableValue"
          label="Tipologia cordolo*"
          defaultValue={''}
          onChange={e => {
            setValue('l0t3a2_tipologiaCordoloTableValue', e.target.value);
          }}
          selectOptions={tipologiaCordoloOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="l0t3a2_altezzaCordoloTableValue"
          label={'Altezza (H) [cm]'}
          defaultValue=""
          decimalScale={2}
          withValueLimit={({ value }) => value < 10000000}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="l0t3a2_profonditaCordoloTableValue"
          label={'Larghezza (La) [cm]'}
          defaultValue=""
          decimalScale={2}
          withValueLimit={({ value }) => value < 10000000}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="l0t3a2_lunghezzaCordoloTableValue"
          label={'Lunghezza [m]'}
          defaultValue=""
          decimalScale={2}
          withValueLimit={({ value }) => value < 1000}
        />
      </GridWrap>
      <GridWrap item xs={6}>
        <FormSubmitButtons
          onSubmit={onSubmitCordoli}
          onReset={onResetCordoli}
        />
      </GridWrap>
    </GridWrap>
  );
};
CordoliForm.propTypes = {
  cordoliFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaCordolo: PropTypes.number,
      altezzaCordolo: PropTypes.number,
      profonditaCordolo: PropTypes.number,
      lunghezzaCordolo: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  cordoliTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default CordoliForm;
