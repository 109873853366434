export const markdown_faq_17_1 = `**COMPILARE LE TABELLE**
Per compilare le tabelle in fase di inserimento dati, è necessario utilizzare i box di inserimento dati:
1. Selezionare l'elemento che si vuole descrivere
2. Inserire i dati corrispondenti
3. Per popolare la tabella selezionare la spunta V.

Ricorda che la compilazione può essere velocizzata selezionando più elementi contemporaneamente.`;
export const markdown_faq_17_2 = `**MODIFICARE I DATI DI UN ELEMENTO**
Per modificare i dati di un elemento di una tabella:
1. Selezionare il simbolo di modifica posto alla fine della riga
2. Aggiornare i box con i dati relativi all'elemento
3. Un volta terminate le modifiche selezionare la spunta V.`;
