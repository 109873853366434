import { Divider } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  InfoWrapper,
  MediaUpload,
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
} from 'components';
import {
  CondizioniCriticheStrutturaleFondazionale,
  CondizioniCriticheStrutturaleTipologiaEl,
  FieldType,
  MediaGroup,
  UploadMedia,
} from 'constants/inspections';
import { CondizioniCriticheStrutturaleTipologiaElOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { INFO } from '../../../../../config/info';
import { GridWrap } from '../Accordion5CriticalAspectsL1.styles';

const CondCriticheVulnerabilitaStruttura = () => {
  const { watch, setValue } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchCondizioniCriticheVulnerabilitaStrutturale = watch(
    'condizioniCriticheVulnerabilitaStrutturale'
  );
  const watchCondizioniCriticheVulnerabilitaStrutturalePresenti =
    watchCondizioniCriticheVulnerabilitaStrutturale ===
    CondizioniCriticheStrutturaleFondazionale.Presenti;

  const watchCondizioniCriticheVulnerabilitaStrutturaTipologiaEl = watch(
    'condizioniCriticheVulnerabilitaStrutturaTipologiaEl'
  );

  const condizioniCriticheVStrutturaTipologiaAltro =
    watchCondizioniCriticheVulnerabilitaStrutturaTipologiaEl !== ''
      ? watchCondizioniCriticheVulnerabilitaStrutturaTipologiaEl?.some(
          el => el === CondizioniCriticheStrutturaleTipologiaEl.Altro
        )
      : false;

  useEffect(() => {
    if (!watchCondizioniCriticheVulnerabilitaStrutturalePresenti) {
      setValue('condizioniCriticheVulnerabilitaStrutturaTipologiaEl', '');
      setValue('condizioniCriticheVulnerabilitaStrutturaAltro', '');
    }
  }, [watchCondizioniCriticheVulnerabilitaStrutturale]);

  return (
    <GridWrap container spacing={2} padding="20px" marginTop={-40}>
      <InfoWrapper
        isDivider={true}
        size={12}
        infoDrawer={true}
        drawerTitle={
          INFO.condizioniCriticheVulnerabilitaStrutturale.drawerTitle
        }
        drawerText={INFO.condizioniCriticheVulnerabilitaStrutturale.drawerText}
        legislationMessage={
          INFO.condizioniCriticheVulnerabilitaStrutturale.legislationMessage
        }
      >
        <Divider>
          Condizioni critiche - vulnerabilità strutturale e fondazionale
        </Divider>
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="condizioniCriticheVulnerabilitaStrutturale"
          label={'Condizioni critiche*'}
          defaultValue={''}
          selectOptions={[
            {
              label: 'Presenti',
              value: CondizioniCriticheStrutturaleFondazionale.Presenti,
            },
            {
              label: 'Assenti',
              value: CondizioniCriticheStrutturaleFondazionale.Assenti,
            },
          ]}
          disabled
        />
      </GridWrap>

      <GridWrap item xs={3}>
        <RHFMultiSelect
          name="condizioniCriticheVulnerabilitaStrutturaTipologiaEl"
          label={'Tipologia'}
          defaultValue={[]}
          selectOptions={CondizioniCriticheStrutturaleTipologiaElOptions}
          disabled={
            !watchCondizioniCriticheVulnerabilitaStrutturalePresenti ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
        {/*  <RHFTagAutocomplete
          multiple={true}
          id="tags-elCondCriticiStruttura"
          name={'elCondizioniCriticheVulnerabilitaStrutturaTipologiaElemento'}
          label="Tipologia elemento"
          disabled
        /> */}
      </GridWrap>

      {condizioniCriticheVStrutturaTipologiaAltro && (
        <GridWrap item xs={3}>
          <RHFTextField
            name="condizioniCriticheVulnerabilitaStrutturaAltro"
            label={'Specificare altro'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      )}

      <GridWrap item xs={3}>
        <MediaUpload
          disabled={!watchCondizioniCriticheVulnerabilitaStrutturalePresenti}
          title={`Foto`}
          btnTitle="Allega file"
          formName="condizioniCriticheVulnerabilitaStrutturaFoto"
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          dataType="video/*,image/*"
          preview={true}
          uploadType={UploadMedia.media}
          type={FieldType.images}
          mediaGroup={[MediaGroup.Struttura]}
          tags={[
            'Spalle',
            'Pile',
            'Appoggi',
            'Giunti',
            'Pulvini',
            'Antenne',
            'Altri dispositivi antisismici',
            'Soletta',
            'Travi',
            'Traversi',
            'Controventi',
            'Archi',
            'Piedritti',
            'Tiranti',
            'Elementi extra',
            'quadri fessurativi',
            'perdita di appoggio',
            'cinematismi',
            'giunzioni',
          ]}
        />
      </GridWrap>
    </GridWrap>
  );
};

export default CondCriticheVulnerabilitaStruttura;
