import { Search } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FormSubmitButtons } from 'components';
import { RHFDatePicker, RHFSelect, RHFTextField } from 'components/form';
import { tour_16 } from 'constants/tour';
import { FilterWrapper } from './imagesFilter.styles';

const imageFiltersInputTypes = {
  TextField: 'TextField',
  DatePicker: 'DatePicker',
  Select: 'Select',
};

const Components = {
  [imageFiltersInputTypes.TextField]: RHFTextField,
  [imageFiltersInputTypes.DatePicker]: RHFDatePicker,
  [imageFiltersInputTypes.Select]: RHFSelect,
};

const ImagesFilter = ({ mediaGroupValue, onSubmit, onReset, options }) => {
  return (
    <FilterWrapper container spacing={2} className={`${tour_16.step_02}`}>
      {options.map(({ type, mediaGroupId, ...props }, index) => {
        const Component = Components[type];
        if (!mediaGroupId || mediaGroupId.includes(mediaGroupValue)) {
          return (
            <Grid item xs={2.4} key={`${type}${index}`}>
              <Component {...props} />
            </Grid>
          );
        }
      })}
      <Grid item xs={12}>
        <FormSubmitButtons
          customSubmitIcon={<Search />}
          onSubmit={onSubmit}
          onReset={onReset}
        />
      </Grid>
    </FilterWrapper>
  );
};

ImagesFilter.propTypes = {
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  options: PropTypes.array,
  mediaGroupValue: PropTypes.number,
};

export { ImagesFilter, imageFiltersInputTypes };
