import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const GridWrap = styled(Grid)(({ marginTop = '5px' }) => ({
  marginTop: marginTop,
}));

export const CheckboxContainer = styled('div')({
  marginLeft: '10px',
  height: '100%',
  display: 'flex',
  marginTop: 28,
  gap: 10,
});
