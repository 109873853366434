import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const FilterContainer = styled('div')(({ marginTop }) => ({
  width: '100%',
  backgroundColor: colors.GREY,
  padding: 20,
  borderRadius: 8,
  marginTop: marginTop,
  position: 'relative',
  '& .MuiGrid-item': {
    '&:last-of-type': {
      width: '1',
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        marginLeft: 2,
      },
    },
  },
}));
