import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaTraversoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
const TraversiForm = ({
  traversiFields,
  selected,
  setSelected,
  traversiTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t2a5_traversiTableValue: [],
      l0t2a5_tipologiaTraversoTableValue: '',
      l0t2a5_altezzaTraversoTableValue: '',
      l0t2a5_lunghezzaTraversoTableValue: '',
      l0t2a5_spessoreTraversoTableValue: '',
      l0t2a5_superficieTraversoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a5_traversiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetTraversi();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeTraversi = () => {
    const l0t2a5_tipologiaTraversoTableValue = getValues(
      'l0t2a5_tipologiaTraversoTableValue'
    );
    const l0t2a5_altezzaTraversoTableValue = getValues(
      'l0t2a5_altezzaTraversoTableValue'
    );
    const l0t2a5_lunghezzaTraversoTableValue = getValues(
      'l0t2a5_lunghezzaTraversoTableValue'
    );

    const l0t2a5_spessoreTraversoTableValue = getValues(
      'l0t2a5_spessoreTraversoTableValue'
    );
    const l0t2a5_superficieTraversoTableValue = getValues(
      'l0t2a5_superficieTraversoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const traversi = [...traversiFields];

    traversi.map((x, index) => {
      if (ids.includes(x?.codice)) {
        traversi[index] = {
          ...x,
          tipologiaTraverso: l0t2a5_tipologiaTraversoTableValue,
          altezzaTraverso: l0t2a5_altezzaTraversoTableValue,
          lunghezzaTraverso: l0t2a5_lunghezzaTraversoTableValue,
          spessoreTraverso: l0t2a5_spessoreTraversoTableValue,
          superficieTraverso: l0t2a5_superficieTraversoTableValue,
          ...(l0t2a5_tipologiaTraversoTableValue !== x.tipologiaTraverso
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'traverso',
                  l0t2a5_tipologiaTraversoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(traversiTable, traversi);
    onResetTraversi();
  };

  const onConfirmChangeTraversi = () => {
    setOpenConfirmationDialog(false);
    changeTraversi();
  };

  const onSubmitTraversi = () => {
    const l0t2a5_tipologiaTraversoTableValue = getValues(
      'l0t2a5_tipologiaTraversoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const traversi = [...traversiFields];

    const hasChangedTipologia = traversi.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a5_tipologiaTraversoTableValue !== el.tipologiaTraverso &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaTraverso)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeTraversi();
    }
  };

  const onResetTraversi = () => {
    setValue('l0t2a5_traversiTableValue', []);
    setValue('l0t2a5_tipologiaTraversoTableValue', '');
    setValue('l0t2a5_altezzaTraversoTableValue', '');
    setValue('l0t2a5_lunghezzaTraversoTableValue', '');
    setValue('l0t2a5_spessoreTraversoTableValue', '');
    setValue('l0t2a5_superficieTraversoTableValue', '');

    setSelected([]);
  };

  // [(2*Altezza*Lunghezza)+(Spessore*Lunghezza)]/10000

  const altezzaTraversoWatch = parseFloat(
    watch('l0t2a5_altezzaTraversoTableValue')
  );
  const lunghezzaTraversoWatch = parseFloat(
    watch('l0t2a5_lunghezzaTraversoTableValue')
  );
  const spessoreTraversoWatch = parseFloat(
    watch('l0t2a5_spessoreTraversoTableValue')
  );

  useEffect(() => {
    const result = (
      (2 * altezzaTraversoWatch * lunghezzaTraversoWatch +
        spessoreTraversoWatch * lunghezzaTraversoWatch) /
      10000
    ).toFixed(2);
    setValue('l0t2a5_superficieTraversoTableValue', result);
  }, [altezzaTraversoWatch, lunghezzaTraversoWatch, spessoreTraversoWatch]);

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper
          size={6}
          infoMessage={INFO.traversiSelezionati.infoMessage}
        >
          <RHFTagAutocomplete
            multiple={true}
            id="tags-traversi"
            name={'l0t2a5_traversiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={traversiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Traversi selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_tipologiaTraversoTableValue"
            label="Tipologia Traverso*"
            defaultValue={''}
            onChange={e => {
              setValue('l0t2a5_tipologiaTraversoTableValue', e.target.value);
            }}
            selectOptions={tipologiaTraversoOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_altezzaTraversoTableValue"
            label={'Altezza (H) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_lunghezzaTraversoTableValue"
            label={'Lunghezza (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_spessoreTraversoTableValue"
            label={'Spessore (s) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <InfoWrapper size={3} infoMessage={INFO.superficieTraverso.infoMessage}>
          <RHFNumberTextField
            name="l0t2a5_superficieTraversoTableValue"
            label={'Superficie [m²]'}
            defaultValue=""
            disabled
            InputLabelProps={{ shrink: true }}
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <FormSubmitButtons
            onSubmit={onSubmitTraversi}
            onReset={onResetTraversi}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeTraversi}
      />
    </>
  );
};
TraversiForm.propTypes = {
  traversiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaTraverso: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      altezzaTraverso: PropTypes.number,
      lunghezzaTraverso: PropTypes.number,
      spessoreTraverso: PropTypes.number,
      superficieTraverso: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  traversiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default TraversiForm;
