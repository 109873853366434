export const legislationFiles = [
  {
    title: 'Linee Guida',
    size: '2039KB',
    path: 'downloadables/regulations/ALL DM 204 Allegato_A_-_LL.GG_._Ponti_e_Viadotti.pdf',
  },
  {
    title: 'Appendici e Allegati',
    size: '9566KB',
    path: 'downloadables/regulations/ALL DM 204 Appendici_ed_allegati_alle_LLGG_-_Allegato_A__Decreto_204_-2022.pdf',
  },
  {
    title: 'DM 204 del 1 Luglio 2022',
    size: '134KB',
    path: 'downloadables/regulations/DM_204_del_1_luglio_2022.pdf',
  },
  {
    title: 'ANSFISA - Istruzioni operative',
    size: '1700KB',
    path: 'downloadables/regulations/ANSFISA_IstruzioniOperative.pdf',
  },
];

export const inBeeGuides = [
  {
    title: 'Panoramica',
    size: '371KB',
    path: 'downloadables/guides/Guida INBEE - Panoramica.pdf',
  },
  {
    title: 'Riferimenti Normativi',
    size: '371KB',
    path: 'downloadables/guides/Guida INBEE - Riferimenti normativi.pdf',
  },
  {
    title: 'Suggerimenti Operativi',
    size: '371KB',
    path: 'downloadables/guides/Guida INBEE - Suggerimenti operativi.pdf',
  },
];
