import { Divider } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  InfoWrapper,
  MediaUpload,
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
} from 'components';
import {
  CondizioniCriticheSismica,
  CondizioniCriticheSismicaTipologiaEl,
  FieldType,
  MediaGroup,
  UploadMedia,
} from 'constants/inspections';
import { CondizioniCriticheSismicaTipologiaElOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { INFO } from '../../../../../config/info';
import { GridWrap } from '../Accordion5CriticalAspectsL1.styles';

const CondCriticheVulnerabilitaSismica = () => {
  const { watch, setValue } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchCondizioniCriticheVulnerabilitaSismica = watch(
    'condizioniCriticheVulnerabilitaSismica'
  );
  const watchCondizioniCriticheVulnerabilitaSismicaPresenti =
    watchCondizioniCriticheVulnerabilitaSismica ===
    CondizioniCriticheSismica.Presenti;

  const watchCondizioniCriticheVulnerabilitaSismicaTipologiaEl = watch(
    'condizioniCriticheVulnerabilitaSismicaTipologiaEl'
  );
  const condizioniCriticheSismicaTipologiaAltro =
    watchCondizioniCriticheVulnerabilitaSismicaTipologiaEl !== ''
      ? watchCondizioniCriticheVulnerabilitaSismicaTipologiaEl &&
        watchCondizioniCriticheVulnerabilitaSismicaTipologiaEl?.some(
          el => el === CondizioniCriticheSismicaTipologiaEl.Altro
        )
      : false;

  useEffect(() => {
    if (!watchCondizioniCriticheVulnerabilitaSismicaPresenti) {
      setValue('condizioniCriticheVulnerabilitaSismicaTipologiaEl', '');
      setValue('condizioniCriticheVulnerabilitaSismicaTipologiaAltro', '');
    }
  }, [watchCondizioniCriticheVulnerabilitaSismica]);

  return (
    <GridWrap container spacing={2} padding="20px" marginTop={-40}>
      <InfoWrapper
        isDivider={true}
        size={12}
        infoDrawer={true}
        drawerTitle={INFO.condizioniCriticheSismica.drawerTitle}
        drawerText={INFO.condizioniCriticheSismica.drawerText}
        legislationMessage={INFO.condizioniCriticheSismica.legislationMessage}
      >
        <Divider>Condizioni critiche - vulnerabilità sismica</Divider>
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="condizioniCriticheVulnerabilitaSismica"
          label={'Condizioni critiche*'}
          defaultValue={''}
          selectOptions={[
            {
              label: 'Presenti',
              value: CondizioniCriticheSismica.Presenti,
            },
            {
              label: 'Assenti',
              value: CondizioniCriticheSismica.Assenti,
            },
          ]}
          disabled
        />
      </GridWrap>

      <GridWrap item xs={3}>
        <RHFMultiSelect
          name="condizioniCriticheVulnerabilitaSismicaTipologiaEl"
          label={'Tipologia'}
          defaultValue={[]}
          selectOptions={CondizioniCriticheSismicaTipologiaElOptions}
          disabled={
            !watchCondizioniCriticheVulnerabilitaSismicaPresenti ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
        {/* <RHFTagAutocomplete
          multiple={true}
          id="tags-elCondCriticiSismica"
          name={'elCondizioniCriticheVulnerabilitaSismicaTipologiaElemento'}
          label="Tipologia elemento"
          disabled
        /> */}
      </GridWrap>

      {condizioniCriticheSismicaTipologiaAltro && (
        <GridWrap item xs={3}>
          <RHFTextField
            name="condizioniCriticheVulnerabilitaSismicaTipologiaAltro"
            label={'Specificare altro'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      )}

      <GridWrap item xs={3}>
        <MediaUpload
          disabled={!watchCondizioniCriticheVulnerabilitaSismicaPresenti}
          title={`Foto`}
          btnTitle="Allega file"
          formName="condizioniCriticheVulnerabilitaSismicaFoto"
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          dataType="video/*,image/*"
          preview={true}
          uploadType={UploadMedia.media}
          type={FieldType.images}
          mediaGroup={[MediaGroup.Struttura]}
          tags={[
            'Spalle',
            'Pile',
            'Appoggi',
            'Giunti',
            'Pulvini',
            'Antenne',
            'Altri dispositivi antisismici',
            'Soletta',
            'Travi',
            'Traversi',
            'Controventi',
            'Archi',
            'Piedritti',
            'Tiranti',
            'Elementi extra',
            'quadri fessurativi',
            'perdita di appoggio',
            'cinematismi',
            'giunzioni',
          ]}
        />
      </GridWrap>
    </GridWrap>
  );
};

export default CondCriticheVulnerabilitaSismica;
