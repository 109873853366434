import { Divider } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { InfoWrapper, MediaUpload, RHFSelect, RHFTextField } from 'components';
import {
  ElementiVulnerabilitaSismica,
  FieldType,
  MediaGroup,
  UploadMedia,
} from 'constants/inspections';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { INFO } from '../../../../../config/info';
import { GridWrap } from '../Accordion5CriticalAspectsL1.styles';

const ElVulnerabilitaSismica = () => {
  const { watch, setValue } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchElementiVulnerabilitaSismica = watch(
    'elementiVulnerabilitaSismica'
  );
  const watchElementiVulnerabilitaSismicaPresenti =
    watchElementiVulnerabilitaSismica === ElementiVulnerabilitaSismica.Presenti;

  useEffect(() => {
    !watchElementiVulnerabilitaSismicaPresenti &&
      setValue('elementiVulnerabilitaSismicaDescrizione', '');
  }, [watchElementiVulnerabilitaSismica]);

  return (
    <GridWrap container spacing={2} padding="20px" marginTop={-40}>
      <InfoWrapper
        isDivider={true}
        size={12}
        compassMessage={INFO.elementiVulnerabilitaSismica.compassMessage}
        infoDrawer={true}
        drawerTitle={INFO.elementiVulnerabilitaSismica.drawerTitle}
        drawerText={INFO.elementiVulnerabilitaSismica.drawerText}
      >
        <Divider>Elementi di vulnerabilità sismica</Divider>
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="elementiVulnerabilitaSismica"
          label={'Elementi di vulnerabilità sismica*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={[
            {
              label: 'Presenti',
              value: ElementiVulnerabilitaSismica.Presenti,
            },
            {
              label: 'Assenti',
              value: ElementiVulnerabilitaSismica.Assenti,
            },
          ]}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="elementiVulnerabilitaSismicaDescrizione"
          label={'Descrizione'}
          inputProps={{ maxLength: 250 }}
          defaultValue=""
          disabled={
            !watchElementiVulnerabilitaSismicaPresenti ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <MediaUpload
          disabled={!watchElementiVulnerabilitaSismicaPresenti}
          title={`Foto`}
          btnTitle="Allega file"
          formName="elementiVulnerabilitaSismicaFoto"
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          dataType="video/*,image/*"
          preview={true}
          uploadType={UploadMedia.media}
          type={FieldType.images}
          mediaGroup={[MediaGroup.Struttura]}
          tags={[
            'impalcato sghembo',
            'impalcato in curva',
            'pile a singola colonna',
            'pile disuniformi',
            'appoggi in metallo',
            'appoggi in calcestruzzo',
            'concentrazioni di sforzo',
            'moti rotazionali',
          ]}
        />
      </GridWrap>
    </GridWrap>
  );
};

export default ElVulnerabilitaSismica;
