import { makePath } from 'utils/navigation';
import { InspectionRouteActions } from './inspections';

export const ROOT_PATH = '/';
const AUTH_ROOT = makePath(ROOT_PATH, 'auth');
export const ADMIN_ROOT = makePath(ROOT_PATH, 'admin');
export const USER_ROOT = '/users';
export const ANALYTICS_ROOT = '/analytics';
export const HOME_BO = makePath(ROOT_PATH, 'home');
export const MY_AREA = makePath(ROOT_PATH, 'my-area');
export const PROFILE_AND_SUBSCRIPTION = makePath(ROOT_PATH, 'profile');
export const NOTIFICATION_ROOT = makePath(ROOT_PATH, 'notifications');
export const STRUCTURES_ROOT = '/structures';
export const INSPECTIONS_ROOT = '/inspections';
export const IMAGES_ROOT = '/images';
export const DOCUMENTS_ROOT = '/documents';
export const INFO_ASSISTANCE_ROOT = '/info-assistance';
export const COLLABORATORS_ROOT = '/collaborators';

export const REGISTRATION_PATH = {
  ROOT: '/register',
  REGISTRATION_TYPE: '/registration/:type',
};
export const REGISTRATION_SUCCESS = {
  LINK: 'https://inbee.it/registrazione-avvenuta/',
};

// da eliminare --------------------------------
export const ADMIN_PATHS = {
  ROOT: ADMIN_ROOT,
  CREATE: makePath(ADMIN_ROOT, '/create'),
  EDIT: makePath(ADMIN_ROOT, '/edit/:userId'),
};
// ----------------------------------------------

export const USER_PATHS = {
  ROOT: USER_ROOT,
  CREATE: makePath(USER_ROOT, '/create'),
  EDIT: makePath(USER_ROOT, '/edit/:userId'),
};

export const AUTH_PATHS = {
  ROOT: AUTH_ROOT,
  LOGIN: makePath(AUTH_ROOT, '/login'),
  RESET: makePath(AUTH_ROOT, '/reset'),
  NEWPASSWORD: makePath(AUTH_ROOT, '/new-password'),
  PASSWORDUPDATED: makePath(AUTH_ROOT, '/password-updated'),
  EMAILSENDED: makePath(AUTH_ROOT, '/email-sended'),
};

export const STRUCTURES_PATHS = {
  ROOT: STRUCTURES_ROOT,
  STRUCTURES_LIST: makePath(STRUCTURES_ROOT, '/list'),
  STRUCTURES_NEW: makePath(STRUCTURES_ROOT, '/list/create'),
  INSPECTION_DETAIL: makePath(
    STRUCTURES_ROOT,
    `/list/:structureId/inspection/:inspectionId`
  ),
  INSPECTION_NEW: makePath(
    STRUCTURES_ROOT,
    `/list/:structureId/inspection/:inspectionId/${InspectionRouteActions.CREATE}`
  ),
  INSPECTION_VIEW: makePath(
    STRUCTURES_ROOT,
    `/list/:structureId/inspection/:inspectionId/${InspectionRouteActions.VIEW}`
  ),
  INSPECTION_EDIT: makePath(
    STRUCTURES_ROOT,
    `/list/:structureId/inspection/:inspectionId/${InspectionRouteActions.EDIT}`
  ),
  STRUCTURE_DETAIL: makePath(STRUCTURES_ROOT, '/list/:structureId'),
  DUPLICATE_STRUCTURE: makePath(
    STRUCTURES_ROOT,
    '/list/:structureId/duplicate'
  ),
  STRUCTURE_IMAGES: makePath(STRUCTURES_ROOT, '/list/:structureId/images'),
  INSPECTIONS_ARCHIVE: makePath(
    STRUCTURES_ROOT,
    '/list/:structureId/inspection/archive'
  ),
  STRUCTURE_DOCUMENTS: makePath(
    STRUCTURES_ROOT,
    '/list/:structureId/documents'
  ),
};
export const INSPECTIONS_PATHS = {
  ROOT: INSPECTIONS_ROOT,
  INSPECTIONS_LIST: makePath(INSPECTIONS_ROOT, '/list'),
};
export const IMAGES_PATHS = {
  ROOT: IMAGES_ROOT,
  IMAGES_LIST: makePath(IMAGES_ROOT, '/list'),
};
export const DOCUMENTS_PATHS = {
  ROOT: DOCUMENTS_ROOT,
  DOCUMENTS_LIST: makePath(DOCUMENTS_ROOT, '/list'),
};

export const INFO_ASSISTANCE_PATHS = {
  ROOT: INFO_ASSISTANCE_ROOT,
  GUIDES: makePath(INFO_ASSISTANCE_ROOT, '/guides'),
  FAQ: makePath(INFO_ASSISTANCE_ROOT, '/faq'),
  VIDEOS: makePath(INFO_ASSISTANCE_ROOT, '/videos'),
};
export const COLLABORATORS_PATHS = {
  ROOT: COLLABORATORS_ROOT,
  NEW_INTERNAL_COLLBAORATOR: makePath(COLLABORATORS_ROOT, '/internal/new'),
  NEW_EXTERNAL_COLLBAORATOR: makePath(COLLABORATORS_ROOT, '/external/new'),
  EDIT_INTERNAL_COLLBAORATOR: makePath(
    COLLABORATORS_ROOT,
    '/internal/edit/:userId'
  ),
  EDIT_EXTERNAL_COLLBAORATOR: makePath(
    COLLABORATORS_ROOT,
    '/external/edit/:userId'
  ),
  RESEND_INVITATION_MAIL: makePath(
    COLLABORATORS_ROOT,
    '/resendInvitationEmail'
  ),
};
