import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const FilterWrapper = styled(Grid)(({ theme }) => {
  return {
    backgroundColor: `${theme.palette.background.grey}`,
    margin: '1rem auto 0 auto',
    width: '100%',
    padding: '0 15px 15px 0',
    borderRadius: 5,
  };
});
export const InputWrapper = styled('div')({
  flex: 1,
  padding: '0 10px',
  '& input': {
    height: ' 37px',
    boxSizing: 'border-box',
    padding: '8px 14px',
  },
  '& label': {
    transform: 'translate(14px, 8px) scale(1)',

    '&.MuiFormLabel-filled, &.Mui-focused': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  },
});
