import styled from '@emotion/styled';
import { Button, Paper } from '@mui/material';

export const HeaderWrapper = styled('div')({
  display: 'flex',
});

export const ButtonWrapper = styled(Button)({
  padding: '15px 25px 15px 25px',
  height: '40px',
});

export const NotificationsContainer = styled('div')({
  padding: '0 35px',
});

export const Notification = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${theme.palette.background.grey}`,
  padding: '15px 0 11px 0',
}));

export const StyledPaper = styled(Paper)(({ colorVariant, theme }) => ({
  borderRadius: 8,
  backgroundColor: colorVariant === 'grey' && theme.palette.background.grey,
  padding: theme.spacing(2),
}));
