import PropTypes from 'prop-types';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import * as PATHS from 'constants/paths';
import { useSubscription } from 'hooks';
import { useAuthSelector, useGlobalSelector, useGlobalStore } from 'stores';

const AuthGuard = ({ children, roles }) => {
  const { isAuthenticated } = useAuthSelector();
  const { user } = useSubscription();
  const { requestedLocation } = useGlobalSelector();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handlePermssionDenied = () => {
    navigate(PATHS.HOME_BO);
  };

  if (!isAuthenticated) {
    if (pathname !== requestedLocation)
      useGlobalStore.setState({ requestedLocation: pathname });

    return <Navigate to={PATHS.AUTH_PATHS.LOGIN} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    useGlobalStore.setState({ requestedLocation: null });
    return <Navigate to={requestedLocation} />;
  }
  if (user?.type && roles?.length && !roles.includes(user.type))
    handlePermssionDenied();

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array,
};

export { AuthGuard };
