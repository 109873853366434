import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { mensole } from 'constants/inspections';
import calculateDifettositaSottostruttura from './calculateDifettositaSottostruttura';
import calculateDifettositaSvrastruttura from './calculateDifettositaSovrastruttura';
import { reorderRiepilogoTable } from './orderRiepilogoTable';

const useTabellaRiepilogativa = () => {
  const { getValues, control, setValue } = useFormContext();
  // Riepilogo Struttura Table
  const {
    append: addRiepilogoStrutturaRow,
    remove: removeRiepilogoStrutturaRow,
  } = useFieldArray({
    control,
    name: 'riepilogoStrutturaTable',
  });

  const {
    calculateSottostrutturaDifettositaStrutturale,
    calculateSottostrutturaDifettositaSismica,
  } = calculateDifettositaSottostruttura();
  const {
    calculateSovrastrutturaDifettositaStrutturale,
    calculateSovrastrutturaDifettositaSismica,
  } = calculateDifettositaSvrastruttura();

  const recalculateTable = () => {
    const spallePile = getValues('spallePileCollegamentiTable');
    const campate = getValues('mensoleTamponiCampateImpalcatoTable');

    const numCampate = getValues('numeroCampate');
    const watchMensole = getValues('mensole');
    if (
      !spallePile ||
      spallePile.length < 1 ||
      !campate ||
      campate.length < 1
    ) {
      return;
    }
    if (
      // this logic checks if the length of the riepilogTable equals to the number of elements that should be associated and
      // in the case of schema Mensole Tamponi if the riepilogoTable has the correct data structure, based on the fact
      // that if the Schema Mensole Tamponi is active, the key "elementiAssociati" will always be an array (of elements decided by the user)
      // and in the case of Schema Mensole Tamponi not active, will always be a static string
      spallePile?.length + parseInt(numCampate) ===
        getValues('riepilogoStrutturaTable').length &&
      ((watchMensole === mensole.Si &&
        getValues('riepilogoStrutturaTable')
          .filter(el => el.type === 'sovrastruttura')
          .every(el => Array.isArray(el.elementiAssociati))) ||
        (watchMensole === mensole.No &&
          getValues('riepilogoStrutturaTable')
            .filter(el => el.type === 'sovrastruttura')
            .every(el => typeof el.elementiAssociati == 'string')))
    ) {
      // the number of Spalle/Pile+Campate has not changed
      const tmp = getValues('riepilogoStrutturaTable');
      const refreshRiepilogoTable = tmp.map(el => {
        const found = spallePile.find(
          spallaPila => spallaPila.codice === el.codiceOriginale
        );
        if (el.type === 'sottostruttura') {
          return {
            ...el,
            codeType: el.codiceOriginale.startsWith('S') ? 'S' : 'P',
            order: el.codiceOriginale?.split('-')?.pop()?.replace(/\D/g, ''),
            difettositaStrutturale:
              calculateSottostrutturaDifettositaStrutturale(found),
            difettositaSismica:
              calculateSottostrutturaDifettositaSismica(found),
          };
        } else if (el.type === 'sovrastruttura') {
          if (el.codiceOriginale) {
            return {
              ...el,
              codeType: 'C',
              order: el.codice?.split('-')?.pop()?.replace(/\D/g, ''),
              difettositaStrutturale:
                calculateSovrastrutturaDifettositaStrutturale(
                  el.codiceOriginale
                ),
              difettositaSismica: calculateSovrastrutturaDifettositaSismica(
                el.codiceOriginale
              ),
            };
          } else {
            return {
              ...el,
              codeType: 'C',
              order: el.codice?.split('-')?.pop()?.replace(/\D/g, ''),
              difettositaStrutturale:
                calculateSovrastrutturaDifettositaStrutturale(
                  el.elementiAssociati
                ),
              difettositaSismica: calculateSovrastrutturaDifettositaSismica(
                el.elementiAssociati
              ),
            };
          }
        } else {
          return el;
        }
      });

      // ["M1","T1"]
      const orderedRiepilogo = reorderRiepilogoTable(refreshRiepilogoTable);
      setValue('riepilogoStrutturaTable', orderedRiepilogo);
    } else {
      // the number of Spalle/Pile+Campate has changed
      removeRiepilogoStrutturaRow();
      const tmpArr = [];
      spallePile.forEach(el =>
        tmpArr.push({
          codice: `Sottostruttura-${el.codice}`,
          codiceOriginale: el.codice,
          type: 'sottostruttura',
          elementiAssociati: `${
            el.codice.startsWith('S') ? 'Spalla' : 'Pila'
          } ${el.codice.substring(1, 2)} e collegamenti associati`,
          difettositaStrutturale:
            calculateSottostrutturaDifettositaStrutturale(el),
          difettositaSismica: calculateSottostrutturaDifettositaSismica(el),
          disabled: true,
          codeType: el.codice.startsWith('S') ? 'S' : 'P',
          order: el.codice.substring(1),
        })
      );
      if (watchMensole === mensole.Si) {
        for (let i = 1; i <= numCampate; i++) {
          tmpArr.push({
            codice: `Sovrastruttura-${i}`,
            type: 'sovrastruttura',
            elementiAssociati: ['nessun elemento associato'],
            difettositaStrutturale: null,
            difettositaSismica: null,
            disabled: false,
            codeType: 'C',
            order: i,
          });
        }
      } else {
        campate.forEach((el, idx) =>
          tmpArr.push({
            codice: `Sovrastruttura-${idx + 1}`,
            codiceOriginale: el.codice,
            type: 'sovrastruttura',
            elementiAssociati: `Campata ${idx + 1} e impalcati associati`,
            difettositaStrutturale:
              calculateSovrastrutturaDifettositaStrutturale(el.codice),
            difettositaSismica: calculateSovrastrutturaDifettositaSismica(
              el.codice
            ),
            disabled: true,
            codeType: 'C',
            order: idx + 1,
          })
        );
      }
      const orderedRiepilogo = reorderRiepilogoTable(tmpArr);
      orderedRiepilogo.forEach(el => addRiepilogoStrutturaRow(el));
    }
  };

  return { recalculateTable };
};

export default useTabellaRiepilogativa;
