import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiStrutturaliL1SectionWrapper,
  InspectionInfoForm,
} from 'components';
import { CDAOptions, tipologiaTraversoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import TraversiForm from './TraversiForm';
const TraversiSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const mensoleTamponiCampateImpalcatoTableTraversiTableWatch = watch(
    `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable`
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsTraversi = item => {
    setValue('traversiTableValue', [item]);
    setValue('tipologiaTraversoTableValue', item.tipologiaTraverso);
  };

  const handleSelectAllClickTraversi = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable`
      );
      setSelected(selectedValue);
      setValue('traversiTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('traversiTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t2a4_tecnicoRilevatoreTraversi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t2a4_dataIspezioneTraversi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t2a4_elementoCriticoStrutturaTraversi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].elementoCriticoStruttura`
      ) || ''
    );
    setValue(
      'l1t2a4_elementoCriticoSismicaTraversi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].elementoCriticoSismica`
      ) || ''
    );
    setValue(
      'l1t2a4_condizioneCriticaStrutturaTraversi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`
      ) || ''
    );
    setValue(
      'l1t2a4_condizioneCriticaSismicaTraversi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].condizioneCriticaSismica`
      ) || ''
    );
    setValue(
      'l1t2a4_noteGeneraliTraversi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t2a4_tecnicoRilevatoreTraversi');
    const dataIspezione = getValues('l1t2a4_dataIspezioneTraversi');
    const elementoCriticoStruttura = getValues(
      'l1t2a4_elementoCriticoStrutturaTraversi'
    );
    const elementoCriticoSismica = getValues(
      'l1t2a4_elementoCriticoSismicaTraversi'
    );
    const condizioneCriticaStruttura = getValues(
      'l1t2a4_condizioneCriticaStrutturaTraversi'
    );
    const condizioneCriticaSismica = getValues(
      'l1t2a4_condizioneCriticaSismicaTraversi'
    );
    const noteGenerali = getValues('l1t2a4_noteGeneraliTraversi');
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].elementoCriticoStruttura`,
      elementoCriticoStruttura
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].elementoCriticoSismica`,
      elementoCriticoSismica
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`,
      condizioneCriticaStruttura
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].condizioneCriticaSismica`,
      condizioneCriticaSismica
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
    setValue('l1t2a5_trigger', Math.random() * 1000);
  };

  const handleResetInspection = () => {
    setValue(`l1t2a4_tecnicoRilevatoreTraversi`, '');
    setValue(`l1t2a4_dataIspezioneTraversi`, null);
    setValue(`l1t2a4_elementoCriticoStrutturaTraversi`, '');
    setValue(`l1t2a4_elementoCriticoSismicaTraversi`, '');
    setValue(`l1t2a4_condizioneCriticaStrutturaTraversi`, '');
    setValue(`l1t2a4_condizioneCriticaSismicaTraversi`, '');
    setValue(`l1t2a4_noteGeneraliTraversi`, '');
  };

  const dataTraversiConfig = {
    columns: {
      codice: { label: 'Cod.' },
      tipologiaTraverso: {
        label: 'Tipologia',
        format: tipologiaTraversoOptions,
      },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      indiceCondizione: { label: 'Indice di condizione' },
      CDAstrutturale: { label: 'Difettosità Str.', format: CDAOptions },
      CDAsismica: { label: 'Difettosità Sis.', format: CDAOptions },
      nrtotali: { label: 'NR' },
      g5totali: { label: 'G=5' },
      pstotali: { label: 'PS' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaTraverso: {
        label: 'Tipologia',
        format: tipologiaTraversoOptions,
      },
    },
  };

  const mensoleTamponiCampateImpalcatoSelezione = watch(
    'mensoleTamponiCampateImpalcatoSelezione'
  );

  useEffect(() => {
    if (mensoleTamponiCampateImpalcatoSelezione) {
      setSchedaDifettiIndex(undefined);
      setOpenSchedaDifetti(false);
    }
  }, [mensoleTamponiCampateImpalcatoSelezione]);

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiStrutturaliL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'traversi'}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoTraversi.drawerTitle}
        drawerText={INFO.impalcatoTraversi.drawerText}
        singleElementTitle={'traverso'}
        tableWatch={mensoleTamponiCampateImpalcatoTableTraversiTableWatch}
        tableWatchName={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable`}
        elementForm={
          <TraversiForm
            traversiFields={
              mensoleTamponiCampateImpalcatoTableTraversiTableWatch || []
            }
            selected={selected}
            setSelected={setSelected}
            traversiTable={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traversiTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoForm
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t2a4_tecnicoRilevatoreTraversi'}
            dateName={'l1t2a4_dataIspezioneTraversi'}
            elementoCriticoStrutturaName={
              'l1t2a4_elementoCriticoStrutturaTraversi'
            }
            elementoCriticoSismicaName={'l1t2a4_elementoCriticoSismicaTraversi'}
            condizioneCriticaStrutturaName={
              'l1t2a4_condizioneCriticaStrutturaTraversi'
            }
            condizioneCriticaSismicaName={
              'l1t2a4_condizioneCriticaSismicaTraversi'
            }
            noteGeneraliName={'l1t2a4_noteGeneraliTraversi'}
          />
        }
        tableConfig={dataTraversiConfig}
        editRows={editRowsTraversi}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickTraversi}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaPulvino'}
      />
    </>
  );
};
TraversiSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default TraversiSection;
