import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

function useMensoleTamponi({
  watchMensoleSi,
  removeMensoleTamponi,
  addMensoleTamponi,
  watchNMensole,
  watchNTamponi,
  watch,
}) {
  useEffect(() => {
    if (watchMensoleSi) {
      const nMensoleTamponi = watchNMensole + watchNTamponi;
      const resultMensole = watch('mensoleTamponiTable').filter(el => {
        return el.campata.startsWith('M');
      });
      const resultTampone = watch('mensoleTamponiTable').filter(el => {
        return el.campata.startsWith('T');
      });
      // trigger
      if (nMensoleTamponi !== watch('mensoleTamponiTable')?.length) {
        removeMensoleTamponi();
        if (watchNMensole !== resultMensole.length) {
          if (watchNMensole < resultMensole.length) {
            for (let i = resultMensole.length - 1; i >= watchNMensole; i--) {
              resultMensole.pop();
            }
          } else {
            for (let i = resultMensole.length + 1; i <= watchNMensole; i++) {
              resultMensole.push({
                id: uuidv4(),
                campata: 'M' + i,
                luce: '',
                travi: '',
                traversi: '',
                controventi: '',
                soletta: '',
                archi: '',
                piedritti: '',
                tiranti: '',
                elementiExtra: '',
              });
            }
          }
        }
        if (watchNTamponi !== resultTampone.length) {
          if (watchNTamponi < resultTampone.length) {
            for (let i = resultTampone.length - 1; i >= watchNTamponi; i--) {
              resultTampone.pop();
            }
          } else {
            for (let i = resultTampone.length + 1; i <= watchNTamponi; i++) {
              resultTampone.push({
                id: uuidv4(),
                campata: 'T' + i,
                luce: '',
                travi: '',
                traversi: '',
                controventi: '',
                soletta: '',
                archi: '',
                piedritti: '',
                tiranti: '',
                elementiExtra: '',
              });
            }
          }
        }
        resultMensole.map(el => {
          addMensoleTamponi({
            id: el.id,
            campata: el.campata,
            luce: el.luce,
            travi: el.travi,
            traversi: el.traversi,
            controventi: el.controventi,
            soletta: el.soletta,
            archi: el.archi,
            piedritti: el.piedritti,
            tiranti: el.tiranti,
            elementiExtra: el.elementiExtra,
          });
        });
        resultTampone.map(el => {
          addMensoleTamponi({
            id: el.id,
            campata: el.campata,
            luce: el.luce,
            travi: el.travi,
            traversi: el.traversi,
            controventi: el.controventi,
            soletta: el.soletta,
            archi: el.archi,
            piedritti: el.piedritti,
            tiranti: el.tiranti,
            elementiExtra: el.elementiExtra,
          });
        });
      }
    }
  }, [watchNMensole, watchNTamponi]);
}
export default useMensoleTamponi;
