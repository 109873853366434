export const estensione_k1_val_1 = 0.2;
export const estensione_k1_val_2 = 0.5;
export const estensione_k1_val_3 = 1;

export const intensita_k2_val_1 = 0.2;
export const intensita_k2_val_2 = 0.5;
export const intensita_k2_val_3 = 1;

export const StatoDifetto = {
  NA: 1,
  NR: 2,
  NP: 3,
};

export const difettosita_G = {
  G1: 1,
  G2: 2,
  G3: 3,
  G4: 4,
  G5: 5,
};

export const CDAStrutturale = {
  Bassa: 1,
  MedioBassa: 2,
  Media: 3,
  MedioAlta: 4,
  Alta: 5,
};

export const GravitaValue = {
  Lievemente: 1,
  Gravemente: 2,
};
export const DannoValue = {
  Danneggiato: 1,
  Ossidato: 2,
};

export const difetti = [
  {
    id: 0,
    alert: true,
  },
  {
    id: 1,
    codiceDifetto: 'c.a/c.a.p._1',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Macchie di umidità passiva',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 2,
    codiceDifetto: 'c.a/c.a.p._2',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Macchie di umidità attiva',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 3,
    codiceDifetto: 'c.a./c.a.p._3',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Cls dilavato / ammalorato',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 4,
    codiceDifetto: 'c.a./c.a.p._4',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Vespai',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 5,
    codiceDifetto: 'c.a./c.a.p._5',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Distacco del copriferro',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 6,
    codiceDifetto: 'c.a./c.a.p._6',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Armatura ossidata/corrosa',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 7,
    codiceDifetto: 'c.a./c.a.p._7',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Lesioni a ragnatela modeste',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 8,
    codiceDifetto: 'c.a./c.a.p._8',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Fessure orizzontali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 9,
    codiceDifetto: 'c.a./c.a.p._9',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Fessure verticali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 10,
    codiceDifetto: 'c.a./c.a.p._10',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Fessure diagonali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 11,
    codiceDifetto: 'c.a./c.a.p._11',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Lesioni attacco pilastri (spalle a telaio)',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 12,
    codiceDifetto: 'c.a./c.a.p._12',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Riprese successive deteriorate',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 13,
    codiceDifetto: 'c.a./c.a.p._13',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Lesioni da schiacciamento',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 14,
    codiceDifetto: 'c.a./c.a.p._15',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Lesioni in corrispondenza staffe',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 15,
    codiceDifetto: 'c.a./c.a.p._16',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Staffe scoperte / ossidate',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 16,
    codiceDifetto: 'c.a./c.a.p._17',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Armatura longitudinale deformata',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 17,
    codiceDifetto: 'c.a./c.a.p._18',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Fessure longitudinali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 18,
    codiceDifetto: 'c.a./c.a.p._19',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Fessure trasversali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 19,
    codiceDifetto: 'c.a./c.a.p._20',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Distacco del timpano',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 20,
    codiceDifetto: 'c.a./c.a.p._21',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Cls dilavato / ammalorato Testate',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 21,
    codiceDifetto: 'c.a./c.a.p._22',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Lesioni / distacco travi traversi',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 22,
    codiceDifetto: 'c.a./c.a.p._23',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Rottura staffe',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 23,
    codiceDifetto: 'c.a./c.a.p._24',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Difetti nelle selle Gerber',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 24,
    codiceDifetto: 'c.a./c.a.p._25',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Lesioni attacco trave soletta',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 25,
    codiceDifetto: 'c.a.p_1',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Lesioni capillari agli ancoraggi',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 26,
    codiceDifetto: 'c.a.p_2',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Testate di ancoraggio non sigill.',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 27,
    codiceDifetto: 'c.a.p_3',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Distacco tamponi testate',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 28,
    codiceDifetto: 'c.a.p_4',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Lesioni su anima lungo i cavi',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 29,
    codiceDifetto: 'c.a.p_5',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Lesioni lungo suola del bulbo',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 30,
    codiceDifetto: 'c.a.p_6',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Guaine in vista',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 31,
    codiceDifetto: 'c.a.p_7',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Guaine degradate e fili ossidati',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 32,
    codiceDifetto: 'c.a.p_8',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Fili aderenti in vista ossidati',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 33,
    codiceDifetto: 'c.a.p_9',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Riduzione armatura di precompr.',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 34,
    codiceDifetto: 'c.a.p_10',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: "Umidità dall'interno",
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 35,
    codiceDifetto: 'c.a.p_11',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Arm. Scoperta / ossidata testate',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 36,
    codiceDifetto: 'c.a.p_12',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Fuoriuscita barre ancoraggio',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 37,
    codiceDifetto: 'Mur_1',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Fessure orizzontali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 38,
    codiceDifetto: 'Mur_2',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Fessure verticali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 39,
    codiceDifetto: 'Mur_3',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Fessure diagonali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 40,
    codiceDifetto: 'Mur_4',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Porzione di muratura mancante',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 41,
    codiceDifetto: 'Mur_5',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Fessure trasversali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 42,
    codiceDifetto: 'Mur_6',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Fessure longitudinali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 43,
    codiceDifetto: 'Mur_7',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Distacco del timpano',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 44,
    codiceDifetto: 'Mur_8',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Riprese successive deteriorate',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 45,
    codiceDifetto: 'Mur_9',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Macchie di umidità/risalita',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 46,
    codiceDifetto: 'Mur_10',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Dilavamento',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 47,
    codiceDifetto: 'Mur_11',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Macchie di colore scuro',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 48,
    codiceDifetto: 'Mur_12',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Efflorescenze',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 49,
    codiceDifetto: 'Mur_13',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Patina biologica',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 50,
    codiceDifetto: 'Mur_14',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Polverizzazione',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 51,
    codiceDifetto: 'Mur_15',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Esfoliazione',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 52,
    codiceDifetto: 'Acc_1',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Difetti saldature',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 53,
    codiceDifetto: 'Acc_2',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Rottura saldature',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 54,
    codiceDifetto: 'Acc_3',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Sfogliamento vernice',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 55,
    codiceDifetto: 'Acc_4',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Difetti di chiodatura',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 56,
    codiceDifetto: 'Acc_5',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Bulloni allentati',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 57,
    codiceDifetto: 'Acc_6',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Bulloni tranciati',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 58,
    codiceDifetto: 'Acc_7',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Deformazione anime/piattebande',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 59,
    codiceDifetto: 'Acc_8',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Deformazioni pareti travi scatolari',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 60,
    codiceDifetto: 'Acc_9',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Lesioni ai nodi',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 61,
    codiceDifetto: 'Acc_10',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Corrosione',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 62,
    codiceDifetto: 'Acc_11',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Ossidazione',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 63,
    codiceDifetto: 'Legno_1',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Macchie di umidità',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 64,
    codiceDifetto: 'Legno_2',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Attacco di funghi',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 65,
    codiceDifetto: 'Legno_3',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Attacco di insetti',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 66,
    codiceDifetto: 'Legno_4',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Fessurazioni elicoidali',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 67,
    codiceDifetto: 'Legno_5',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Fessurazioni longitudinali (legno massiccio)',
    estensione_k1: {
      estensione_k1_val_1,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 68,
    codiceDifetto: 'Legno_6',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Fessurazioni trasversali',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 69,
    codiceDifetto: 'Legno_7',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: "Ristagni d'acqua (Trappole d'acqua)",
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 70,
    codiceDifetto: 'Legno_8',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Danni da urto',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 71,
    codiceDifetto: 'Legno_9',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Bulloni allentati',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
    },
  },
  {
    id: 72,
    codiceDifetto: 'Legno_10',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Delaminazione (legno lamellare)',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 73,
    codiceDifetto: 'Legno_11',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Connessioni deteriorate',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 74,
    codiceDifetto: 'Dif.Gen_1',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Tracce di scolo',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 75,
    codiceDifetto: 'Dif.Gen_2',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: "Ristagni d'acqua",
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 76,
    codiceDifetto: 'Dif.Gen_3',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Danni da urto',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 77,
    codiceDifetto: 'Dif.Gen_4',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: "Lesioni caratteristiche zone d'appoggio",
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 78,
    codiceDifetto: 'Dif.Gen_5',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: "Ristagni d'acqua nei cassoni",
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 79,
    codiceDifetto: 'Dif.Gen_6',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Fuori piombo',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 80,
    codiceDifetto: 'Ril/Fond_1a',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Scalzamento',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 81,
    codiceDifetto: 'Ril/Fond_1b',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Scalzamento',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 82,
    codiceDifetto: 'Ril/Fond_2',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: "Dilavamento del rilevato d'approccio",
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 83,
    codiceDifetto: 'Ril/Fond_3',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: "Dissesto del rilevato d'approccio - deformazioni",
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 84,
    codiceDifetto: 'Ril/Fond_4',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: "Dissesto del rilevato d'approccio - stabilità",
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 85,
    codiceDifetto: 'Ril/Fond_5',
    difettosita: difettosita_G.G5,
    descrizioneDifetto: 'Movimenti fondazioni',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 86,
    codiceDifetto: 'App_1',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Piastra di base deformata',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 87,
    codiceDifetto: 'App_2',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Ossidazione',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 88,
    codiceDifetto: 'App_3',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Bloccaggio',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 89,
    codiceDifetto: 'App_4',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Preregolazione sbagliata',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 90,
    codiceDifetto: 'App_5',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Presenza di detriti',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 91,
    codiceDifetto: 'App_6',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Schiacciamento/fuoriuscita lastre di piombo',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 92,
    codiceDifetto: 'App_7',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Invecchiamento neoprene',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
    rowTitle: "Difetti d'appoggio in neoprene",
  },
  {
    id: 93,
    codiceDifetto: 'App_8',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Deformazione orizzontale eccessiva',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 94,
    codiceDifetto: 'App_9',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Schiacciamento/fuoriuscita neoprene',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 95,
    codiceDifetto: 'App_10',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Ammaloramento pendoli in C.A.',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
    rowTitle: 'Difetti pendoli',
  },
  {
    id: 96,
    codiceDifetto: 'App_11',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Fuoripiombo permanente pendoli',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 97,
    codiceDifetto: 'App_12',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Ovalizzazione rulli metallici',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
    rowTitle: 'Difetti carrelli (metallici)',
  },
  {
    id: 98,
    codiceDifetto: 'App_13',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Fuori sede rulli metallici',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 99,
    codiceDifetto: 'App_14',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Deterioramento del teflon',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
    rowTitle: 'Difetti di appoggio generici',
  },
  {
    id: 100,
    codiceDifetto: 'Giunt_1',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Dislivello giunto pavimentazione',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 101,
    codiceDifetto: 'Giunt_2',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Dislivello tra elementi contigui',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_1,
      intensita_k2_val_2,
      intensita_k2_val_3,
    },
  },
  {
    id: 102,
    codiceDifetto: 'Giunt_3',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Riparazione provvisorie giunti',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 103,
    codiceDifetto: 'Giunt_4',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Massetti lesionati',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 104,
    codiceDifetto: 'Giunt_5',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Distacco tampone',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 105,
    codiceDifetto: 'Giunt_6',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Deformazione tampone',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 106,
    codiceDifetto: 'Giunt_7',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Ammaloramento profilati',
    estensione_k1: {
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 107,
    codiceDifetto: 'Giunt_8',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Deformazione/rottura elementi di continuità',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: 108,
    codiceDifetto: 'Giunt_9',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Scossalina permeabile o assente',
    estensione_k1: {
      estensione_k1_val_1,
      estensione_k1_val_2,
      estensione_k1_val_3,
    },
    intensita_k2: {
      intensita_k2_val_3,
    },
  },
  {
    id: '109.a',
    codiceDifetto: 'El.Acc_1',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Dislivello rilevato impalcato',
  },
  {
    id: '109.b',
    codiceDifetto: 'El.Acc_1',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Presenza di dossi',
  },
  {
    id: '109.c',
    codiceDifetto: 'El.Acc_1',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Fessure - anomalie',
  },
  {
    id: 110,
    codiceDifetto: 'El.Acc_2',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Cordoli - Degradati',
  },
  {
    id: 111,
    codiceDifetto: 'El.Acc_3',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Assente',
  },
  {
    id: 112,
    codiceDifetto: 'El.Acc_4',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Pozzetti intasati',
  },
  {
    id: 113,
    codiceDifetto: 'El.Acc_5',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Scarichi corti',
  },
  {
    id: 114,
    codiceDifetto: 'El.Acc_6',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Scarichi ostruiti',
  },
  {
    id: 115,
    codiceDifetto: 'El.Acc_7',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Danneggiati',
  },
  {
    id: 116,
    codiceDifetto: 'El.Acc_8',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Marciapiedi - cattiva pavimentazione',
  },
  {
    id: '117.a',
    codiceDifetto: 'El.Acc_9',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Mal ancorati',
  },
  {
    id: '117.b',
    codiceDifetto: 'El.Acc_9',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Danneggiati',
  },
  {
    id: 118,
    codiceDifetto: 'El.Acc_10',
    difettosita: difettosita_G.G1,
    descrizioneDifetto: 'Arrugginiti',
  },
  {
    id: 119,
    codiceDifetto: 'El.Acc_11',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Mal ancorati',
  },
  {
    id: 120,
    codiceDifetto: 'El.Acc_12',
    difettosita: difettosita_G.G4,
    descrizioneDifetto: 'Assente',
  },
  {
    id: '121.a',
    codiceDifetto: 'El.Acc_13',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Danneggiato',
    hasGravita: true,
  },
  {
    id: '121.b',
    codiceDifetto: 'El.Acc_13',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Ossidati',
    hasGravita: true,
  },
  {
    id: '121.c',
    codiceDifetto: 'El.Acc_13',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Mal Ancorati',
  },
  {
    id: '121.d',
    codiceDifetto: 'El.Acc_13',
    difettosita: difettosita_G.G2,
    descrizioneDifetto: 'Elementi di ancoraggio',
    hasDanneggiato: true,
  },
  {
    id: 122,
    codiceDifetto: 'El.Acc_14',
    difettosita: difettosita_G.G3,
    descrizioneDifetto: 'Spartitraffico danneggiato',
  },
  {
    id: 'assente',
    descrizioneDifetto: 'Assente',
  },
  {
    id: 'nonPrevisto',
    descrizioneDifetto: 'nonPrevisto',
  },
];

export const CHANGE_TYPOLOGY_OR_IMPOSTFOND_WARNING_MSG =
  "Attenzione! Modificando la tipologia dell'elemento o l'imposta di fondazione la scheda di valutazione dei difetti associata a questo elemento verrà sostituita con la nuova.";
