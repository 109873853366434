import styled from '@emotion/styled';

export const CloseContainer = styled('div')({
  position: 'absolute',
  right: '20px',
  top: '20px',
  cursor: 'pointer',
});

export const Container = styled('div')({
  margin: '10px',
  padding: 10,
});
