import PropTypes from 'prop-types';
import {
  FormSubmitButtons,
  GridWrap,
  RHFDatePicker,
  RHFTextField,
} from 'components';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
const InspectionInfoScheda20Form = ({
  tecnicoRilevatoreName,
  dateName,
  noteGeneraliName,
  onSubmit,
  onReset,
}) => {
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();
  return (
    <>
      <GridWrap item xs={6}>
        <RHFTextField
          name={tecnicoRilevatoreName}
          label={'Tecnico rilevatore'}
          inputProps={{ maxLength: 100 }}
          disabled={inspectionDisabled || isViewingInspection}
        />
      </GridWrap>
      <GridWrap item xs={6}>
        <RHFDatePicker
          name={dateName}
          label={'Data ispezione'}
          disabled={inspectionDisabled || isViewingInspection}
        />
      </GridWrap>

      <GridWrap item xs={12}>
        <RHFTextField
          name={noteGeneraliName}
          label={'Note Generali'}
          inputProps={{ maxLength: 1000 }}
          disabled={inspectionDisabled || isViewingInspection}
        />
      </GridWrap>
      {!inspectionDisabled && !isViewingInspection && (
        <GridWrap item xs={12}>
          <FormSubmitButtons onSubmit={onSubmit} onReset={onReset} />
        </GridWrap>
      )}
    </>
  );
};
InspectionInfoScheda20Form.propTypes = {
  tecnicoRilevatoreName: PropTypes.string,
  dateName: PropTypes.string,
  noteGeneraliName: PropTypes.string,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
};
export { InspectionInfoScheda20Form };
