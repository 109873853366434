import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import * as yup from 'yup';
/* import TermsConditions from 'assets/pdf/termini-e-condizioni-inbee.pdf'; */

// API
import {
  RHFApiAutocomplete,
  RHFCheckbox,
  RHFRadioGroup,
  RHFTextField,
} from 'components';
import { apiGeo, apiUsers } from 'constants/api';

import { colors } from 'constants/colors';
import { REGISTRATION_SUCCESS } from 'constants/paths';
import { FormProvider } from 'providers';
import { useGlobalSelector, useGlobalStore } from 'stores/global';
import {
  BtnContainer,
  CheckBoxContainer,
  FormContainer,
  PrivacyPolicyBox,
} from './RegistrationForm.styles';

const RegistrationForm = () => {
  const isProfessional = useGlobalStore(state => state.isProfessional);
  /*   const params = useParams();
  const type = params.type; */
  const { showLoader, hideLoader } = useGlobalSelector();
  const recaptchaRef = useRef();

  const { enqueueSnackbar } = useSnackbar();

  /*   useEffect(() => {
    useGlobalStore.setState(() => ({
      isProfessional: type === 'professional',
    }));
    setValue('isProfessional', type === 'professional');
  }, [type]); */

  //Form const
  const defaultValues = {
    type: '',
    name: '',
    surname: '',
    email: '',
    pec: '',
    office: '',
    businessName: '',
    region: '',
    province: '',
    city: '',
    address: '',
    postalCode: '',
    phone: '',
    vatNumber: '',
    privacyPolicy: false,
    // isVerified: false,
    isProfessional: isProfessional,
  };

  const { post, response } = useFetch();

  /* const schema = yup
    .object({
      name: yup
        .string()
        .required(
          isProfessional ? 'Nome obbligatorio' : 'Nome Gestore obbligatorio'
        ),
      surname: yup
        .string()
        .required(
          isProfessional
            ? 'Cognome obbligatorio'
            : 'Cognome Gestore obbligatorio'
        ),
      email: yup
        .string()
        .email('Inserire una email valida')
        .required(
          isProfessional
            ? 'Email obbligatoria'
            : 'Email Ufficio Tecnico obbligatoria'
        ),
      businessName: yup.string().when('isProfessional', {
        is: false,
        then: yup.string().required('Ragione Sociale obbligatoria'),
      }),
      privacyPolicy: yup.bool().oneOf([true], 'Accettare la Privacy Policy'),
      isVerified: yup.bool().oneOf([true], '!!!'),
    })
    .required(); */

  const schema = yup
    .object({
      type: yup.string().required('Categoria obbligatoria'),
      name: yup.string().required('Nome obbligatorio'),
      surname: yup.string().required('Cognome obbligatorio'),
      email: yup
        .string()
        .email('Inserire una email valida')
        .required('Email obbligatoria'),
      privacyPolicy: yup.bool().oneOf([true], 'Accettare la Privacy Policy'),
      isVerified: yup.bool().oneOf([true], '!!!'),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    formState: { errors },
    handleSubmit,
    watch,
    // getValues,
    reset,
    setValue,
  } = methods;

  const onSubmit = async data => {
    const updatedData = {
      type: data?.type, // radioButton
      name: data?.name,
      surname: data?.surname,
      /* pec: data?.pec || undefined, */
      email: data?.email,
      businessName: data?.businessName || undefined,
      regionId: data.region?.value?.id,
      provinceId: data.province?.value?.id,
      councilId: data.city?.value?.id,
      /* capId: data.postalCode?.value?.id, */
      address: data?.address || undefined,
      phone: data?.phone || undefined,
      fiscalCode: data?.vatNumber || undefined,
      /* type: isProfessional ? UserType.Professionista : UserType.Ente, */
      // privacyPolicy: data?.privacyPolicy,
      // isVerified: data?.isVerified,
    };

    try {
      showLoader();
      await post(`${apiUsers.DEMO}`, updatedData);
      if (response.ok) {
        reset(defaultValues);
        recaptchaRef?.current?.reset();
        window.location.href = REGISTRATION_SUCCESS.LINK;

        return enqueueSnackbar('Utente creato correttamente', {
          variant: 'success',
        });
      }
    } catch (err) {
      console.error('Error in handle cards: ', err);
      if (
        err?.message?.includes('An account with the given email already exists')
      ) {
        return enqueueSnackbar(
          `Si è verificato un errore: utente già esistente`,
          {
            variant: 'error',
          }
        );
      } else
        return enqueueSnackbar(`Si è verificato un errore`, {
          variant: 'error',
        });
    } finally {
      hideLoader();
    }
  };

  /* const navigate = useNavigate(); */

  /*  const toggleProfessional = () => {
    navigate(
      `${REGISTRATION_PATH.ROOT}/${
        !isProfessional ? 'professional' : 'institution'
      }`
    );

    reset(defaultValues);
    recaptchaRef.current.reset();
    setValue('isProfessional', !isProfessional);
  }; */

  //reCAPTCHA
  function onChange() {
    setValue('isVerified', true);
  }

  function onErrored() {
    if (
      process.env.REACT_APP_ENVIRONMENT == 'develop' ||
      process.env.REACT_APP_ENVIRONMENT == 'test'
    ) {
      setValue('isVerified', true);
    } else {
      setValue('isVerified', false);
    }
  }

  function onExpired() {
    setValue('isVerified', false);
  }

  const asyncScriptOnLoad = () => {
    recaptchaRef.current.reset();
  };

  const isVerified = watch('isVerified');
  useEffect(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT == 'develop' ||
      process.env.REACT_APP_ENVIRONMENT == 'test'
    ) {
      setValue('isVerified', true);
    }
  }, []);

  const watchRegion = watch('region');
  const watchProvince = watch('province');

  useEffect(() => {
    if (!watchRegion) {
      setValue('province', null);
    }
  }, [watchRegion]);
  useEffect(() => {
    if (!watchProvince) {
      setValue('city', null);
    }
  }, [watchProvince]);

  return (
    <>
      <div style={{ width: '100%' }}>
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(onSubmit, console.log)}
        >
          <FormContainer>
            <Grid container spacing={2} padding="20px">
              <Grid item xs={12}>
                <RHFRadioGroup name="type" />
                {/* <RHFRadioGroup name="type">
                  <div
                    style={{
                      display: 'flex',
                      color: colors.DARKGREY,
                    }}
                  >
                    <FormControlLabel
                      value="ENTE"
                      control={<Radio />}
                      label="Ente / Gestore"
                    />
                    <FormControlLabel
                      value="PROFESSIONISTA"
                      control={<Radio />}
                      label="Ispettore"
                    />
                  </div>
                </RHFRadioGroup> */}
                {/* <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Categoria
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="type"
                  >
                    <div
                      style={{
                        display: 'flex',
                        color: colors.DARKGREY,
                      }}
                    >
                      <FormControlLabel
                        value="ENTE"
                        control={<Radio />}
                        label="Ente / Gestore"
                      />
                      <FormControlLabel
                        value="PROFESSIONISTA"
                        control={<Radio />}
                        label="Ispettore"
                      />
                    </div>
                  </RadioGroup>
                </FormControl> */}
              </Grid>
              <Grid item sm={6} xs={12}>
                <RHFTextField
                  name="name"
                  label={'Nome*'}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <RHFTextField
                  name="surname"
                  label={'Cognome*'}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <RHFTextField
                  name="email"
                  label={'Email*'}
                  inputProps={{ maxLength: 320 }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <RHFTextField
                  name="businessName"
                  label={'Ente / Società'}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <RHFTextField
                  name="phone"
                  label={'Telefono'}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <RHFApiAutocomplete
                  name={'region'}
                  label={'Regione'}
                  error={!!errors.region}
                  endpoint={`${apiGeo.ROOT}${apiGeo.REGIONS}?`}
                  minSearchLength={0}
                  getOptionLabel={option =>
                    option?.label && option?.value ? `${option?.label}` : option
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <RHFApiAutocomplete
                  name={'province'}
                  label={'Provincia'}
                  error={!!errors.province}
                  endpoint={`${apiGeo.ROOT}${apiGeo.PROVINCES}?parentId=${watchRegion?.value?.id}`}
                  minSearchLength={0}
                  getOptionLabel={option =>
                    option?.label && option?.value ? `${option?.label}` : option
                  }
                  disabled={!watchRegion}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <RHFApiAutocomplete
                  name={'city'}
                  label={'Città'}
                  error={!!errors.city}
                  endpoint={`${apiGeo.ROOT}${apiGeo.COUNCILS}?parentId=${watchProvince?.value?.id}`}
                  minSearchLength={0}
                  getOptionLabel={option =>
                    option?.label && option?.value ? `${option?.label}` : option
                  }
                  disabled={!watchProvince}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <RHFTextField
                  name="address"
                  label={'Indirizzo'}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <RHFTextField
                  name="vatNumber"
                  label={'P.IVA / C.F.'}
                  inputProps={{ maxLength: 150 }}
                />
              </Grid>
            </Grid>

            <CheckBoxContainer>
              <RHFCheckbox name="privacyPolicy" label="" defaultValue={false} />
              <PrivacyPolicyBox>
                <Typography variant="body">Accetto la&nbsp;</Typography>
                <a
                  href="https://inbee.it/privacy-cookie-policy-inbee/"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                  rel="noreferrer"
                >
                  <Typography
                    style={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      color: colors.BLACK,
                      fontFamily: 'Ubuntu',
                    }}
                    variant="body"
                  >
                    Privacy Policy
                  </Typography>
                </a>
                <Typography variant="body">&nbsp;e&nbsp;</Typography>
                <a
                  href="https://inbee.it/termini-e-condizioni-inbee/"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                  rel="noreferrer"
                >
                  <Typography
                    style={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      color: colors.BLACK,
                      fontFamily: 'Ubuntu',
                    }}
                    variant="body"
                  >
                    Termini e Condizioni
                  </Typography>
                </a>
              </PrivacyPolicyBox>
            </CheckBoxContainer>

            <div style={{ marginLeft: 20, marginTop: 10 }}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                onChange={onChange}
                onErrored={onErrored}
                onExpired={onExpired}
                ref={recaptchaRef}
                asyncScriptOnLoad={asyncScriptOnLoad}
              />
            </div>

            <BtnContainer isProfessional={isProfessional}>
              <Button
                disabled={!isVerified}
                type="submit"
                sx={{
                  width: 150,
                  backgroundColor: !isVerified && `${colors.LIGHTGREY}`,
                }}
                variant="containedBlack"
              >
                Invia
              </Button>
              {/*  <Button
                onClick={() => toggleProfessional()}
                variant="textBlack"
                sx={{
                  width: '100%',
                  textDecoration: 'none !important',
                }}
              >
                {isProfessional ? 'Sei un ente?' : 'Sei un professionista?'}
              </Button> */}
            </BtnContainer>
          </FormContainer>
        </FormProvider>
      </div>
    </>
  );
};

export default RegistrationForm;
