import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaPiedrittoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

const PiedrittiForm = ({
  piedrittiFields,
  selected,
  setSelected,
  piedrittiTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      piedrittiTableValue: [],
      tipologiaPiedrittoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('piedrittiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetPiedritti();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changePiedritti = () => {
    const tipologiaPiedrittoTableValue = getValues(
      'tipologiaPiedrittoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const piedritti = [...piedrittiFields];

    piedritti.map((x, index) => {
      if (ids.includes(x?.codice)) {
        piedritti[index] = {
          ...x,
          tipologiaPiedritto: tipologiaPiedrittoTableValue,
          ...(tipologiaPiedrittoTableValue !== x.tipologiaPiedritto
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'piedritto',
                  tipologiaPiedrittoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(piedrittiTable, piedritti);
    onResetPiedritti();
  };

  const onConfirmChangePiedritti = () => {
    setOpenConfirmationDialog(false);
    changePiedritti();
  };

  const onSubmitPiedritti = () => {
    const tipologiaPiedrittoTableValue = getValues(
      'tipologiaPiedrittoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const piedritti = [...piedrittiFields];

    const hasChangedTipologia = piedritti.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          tipologiaPiedrittoTableValue !== el.tipologiaPiedritto &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaPiedritto)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changePiedritti();
    }
  };

  const onResetPiedritti = () => {
    setValue('piedrittiTableValue', []);
    setValue('tipologiaPiedrittoTableValue', '');
    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper
          size={3}
          infoMessage={INFO.piedrittiSelezionati.infoMessage}
        >
          <RHFTagAutocomplete
            multiple={true}
            id="tags-piedritti"
            name={'piedrittiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={piedrittiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Piedritti selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipologiaPiedrittoTableValue"
            label="Tipologia Piedritto"
            defaultValue={''}
            onChange={e => {
              setValue('tipologiaPiedrittoTableValue', e.target.value);
            }}
            selectOptions={tipologiaPiedrittoOptions}
          />
        </GridWrap>
        <GridWrap item xs={6} alignSelf="center">
          <FormSubmitButtons
            onSubmit={onSubmitPiedritti}
            onReset={onResetPiedritti}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangePiedritti}
      />
    </>
  );
};
PiedrittiForm.propTypes = {
  piedrittiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaPiedritto: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  piedrittiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default PiedrittiForm;
