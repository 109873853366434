import styled from '@emotion/styled';

export const PositionContainer = styled('div')({
  height: 18,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginRight: '20px',
  '& img': {
    opacity: '0.5',
    marginRight: '5px',
    height: '98%',
  },
  '& span': {
    fontSize: '14px',
  },
});
