export const markdown_faq_12_1 = `Per ogni ponte possono essere create più attività. 
Un'attività consente di inserire i dati relativi all'opera, di fare il **Censimento** e l'**Ispezione** secondo le Linee Guida Ponti. 
Ad ogni attività è associata una data, un utente proprietario e uno o più utenti che hanno diritto di modifica, e possono così contribuire ad inserire le informazioni relative a quell'attività. Tutti questi utenti sono responsabili delle informazioni scritte.

**PER CREARE UNA NUOVA ATTIVITÀ** 
1. Accedere alla scheda del ponte 
2. Cliccare su *Compila L0,L1* se si tratta delle prima attività, o su *Nuova attività*, se si tratta delle successive.`;

export const markdown_faq_12_2 = `Si aprirà un pannello:
1. Scegliere il tipo di attività che vuoi inserire
2. Inserire la data con cui l'attività verrà censita`;

export const markdown_faq_12_3 = `3. Condividere direttamente l'attività con altri utenti.`;
export const markdown_faq_12_4 = `**COME RICERCARE UN'ATTIVITÀ** 
E' possibile ricercare un'attività creata seguendo più percorsi. 

**A. Archivio attività del ponte**
1. Accedere alla scheda del ponte
2. Cliccare su *Archivio attività*.

Qui troverai lo storico di tutte le attività che sono state effettuate per quel ponte.`;
export const markdown_faq_12_5 = `**B. Sezione Attività**
1. Entrare nella sezione *Attività* (dove sono riportate l'elenco di tutte le attività di tutti i ponti presenti nella piattaforma)
2. Ricercare il ponte o l'attività grazie agli appositi filtri.`;
export const markdown_faq_12_6 = `**C. Sezione Home**
1. Entrare nella sezione *Home* dove hai a disposizione l'elenco delle ultime attività su cui hai lavorato.`;
export const markdown_faq_12_7 = `**AZIONI SULLE ATTIVITÀ**
Una volta aperta un'attività, questa verrà inserita nell'**archivio attività** con una **stringa di sintesi**. A questo punto potrai effettuare diverse operazioni, come accedere all'attività in modalità modifica o di sola visualizzazione, bloccare momentaneamente l'accesso all'attività ad altri utenti, consegnarla e archiviarla o eliminarla.`;
