import { Typography } from '@mui/material';
import { formatDuration, intervalToDuration } from 'date-fns';
import { it } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React from 'react';
import { accessHasExpired } from 'utils';
import { formatData } from 'utils/utilities';

const InspectionAccessExpirationCell = ({ dateString }) => {
  if (dateString) {
    const formattedData = (
      <Typography fontStyle={'italic'} variant={'h6'}>
        {formatData(dateString)}
      </Typography>
    );
    if (accessHasExpired(dateString)) {
      return (
        <>
          <Typography variant={'h6'}>(scaduta)</Typography>
          {formattedData}
        </>
      );
    }
    const timeLeft = intervalToDuration({
      start: new Date(),
      end: new Date(dateString),
    });
    return (
      <>
        <Typography variant={'h6'}>
          {formatDuration(timeLeft, {
            format: ['months', 'years', 'days'],
            delimiter: ', ',
            locale: it,
          })}
        </Typography>
        {formattedData}
      </>
    );
  } else {
    return (
      <>
        <Typography fontStyle={'italic'} variant={'h6'}>
          nessuna scadenza
        </Typography>
      </>
    );
  }
};

InspectionAccessExpirationCell.propTypes = {
  dateString: PropTypes.string,
};

export { InspectionAccessExpirationCell };
