export const inspectionTabMapping = {
  L0: 1,
  L1: 2,
  L2: 3,
  DatiGenerali: 0,
  Struttura: 1,
  ElementiAccessori: 2,
  Servizi: 3,
  L0DatiProgetto: 5,
  L0AttivitaPregresse: 6,
  L0CollegamentoReteStradale: 7,
  L0Elevazione: 3,
  L0Collegamenti: 4,
  L0Impalcato: 4,
  L1InformazioneIspezione: 1,
  L1RischioFrane: 2,
  L1RischioIdraulico: 3,
  L1Elevazione: 2,
  L1Collegamenti: 3,
  L1Impalcato: 4,
  L1AspettiCritici: 5,
};
