export const InspectionStatus = {
  //IN CORSO - BOZZA
  OnGoing: 1,
  //Archiviata
  Archived: 2,
  //Consegna in corso
  OnGoingDelivery: 3,
  //Consegnata
  Delivered: 4,
  Deleted: 5,
  NEW: 20,
  ONGOING: 21,
};
export const InspectionStatusOptions = [
  { label: 'In corso', value: InspectionStatus.OnGoing },
  { label: 'Archiviata', value: InspectionStatus.Archived },
  { label: 'Consegna In Corso', value: InspectionStatus.OnGoingDelivery },
  { label: 'Consegnata', value: InspectionStatus.Delivered },
  { label: 'Nessuna', value: InspectionStatus.NEW },
  /*   { label: 'Eliminata', value: 4 },
   */
];

export const InspectionStatusTooltipMap = {
  [InspectionStatus.OnGoing]: 'Attività ancora modificabile',
  [InspectionStatus.Archived]:
    'Attività non più modificabile, in attesa di essere consegnata all’Ente',
  [InspectionStatus.OnGoingDelivery]:
    'Attività non più modificabile, in attesa che l’Ente si registri in piattaforma',
  [InspectionStatus.Delivered]:
    'Attività non più modificabile e visibile anche all’Ente',
};

export const YesNo = {
  SI: 1,
  NO: 2,
};

export const EffettivoPresunto = {
  Effettivo: 1,
  Presunto: 2,
};
export const RilevabileNonRilevabile = {
  Rilevabile: 1,
  NonRilevabile: 2,
};

export const EffettivoApprossimativo = {
  Effettivo: 1,
  Approssimativo: 2,
};

export const InspectionMainTab = {
  OVERVIEW: 0,
  L0_CENSIMENTO_ANAGRAFICA: 1,
  L1_ISPEZIONI_PRELIMINARI: 2,
  L2_CLASSI_DI_ATTENZIONE: 3,
};

export const InspectionRouteActions = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
};

export const TipologiaCollegamento = {
  Ponte: 1,
  Viadotto: 2,
};

export const TipologiaEnteScavalcato = {
  CorsoAcqua: 1,
  SpecchioAcquaMarina: 2,
  DiscontinuitaOrografica: 3,
  ZonaEdificata: 4,
  ZonaUrbanizzata: 5,
  AltraViaDiComunicazione: 6,
  Ferrovia: 7,
  Altro: 8,
};

export const InspectionStatusMap = {
  [InspectionStatus.OnGoing]: 'In corso',
  [InspectionStatus.Confirmed]: 'Confermata',
  [InspectionStatus.OnGoingDelivery]: 'Consegna in corso',
  [InspectionStatus.Delivered]: 'Consegnata',
  [InspectionStatus.Archived]: 'Archiviata',
  [InspectionStatus.Deleted]: 'Eliminata',
};
export const CaratteristicheClassificazioneEnteScavalcato = {
  PrevedeAffollamentiSignificativi: 1,
  PrevedeNormaliAffollamenti: 2,
  PrevedePresenzaOccasionale: 3,
};

export const ClasseUso = {
  Prima: 1,
  Seconda: 2,
  Terza: 3,
  Quarta: 4,
};
export const ClasseDiConseguenza = {
  CC3: 1,
  CC2: 2,
  CC1: 3,
};

export const StatoOpera = {
  A_Agibile: 1,
  B_ManutenzioneOrdinaria: 2,
  C_ManutenzioneStraordinaria: 3,
  D_ManutenzioneUrgente: 4,
  E_Inagibile: 5,
};

export const LivelloConoscenza = {
  LC0: 1,
  LC1: 2,
  LC2: 3,
  LC3: 4,
};

export const Tracciato = {
  Rettilineo: 1,
  InCurva: 2,
};

export const SchemaIsostatico = {
  Isostatico: 1,
  Iperstatico: 2,
};

export const TipologiaEnteScavalcatoTable = {
  CorsoAcqua: 1,
  SpecchioAcquaMarina: 2,
  DiscontinuitaOrografica: 3,
  ZonaEdificata: 4,
  ZonaUrbanizzata: 5,
  AltraViaDiComunicazione: 6,
  Ferrovia: 7,
  Altro: 8,
};

export const TutelaDLgs = {
  Si: 1,
  No: 2,
};

export const InserimentoNeiPianiPaesaggistici = {
  Si: 1,
  No: 2,
};

export const RischioFrana = {
  DaVerificare: 1,
  Assente: 2,
  Presente: 3,
};

export const RischioPresente = {
  Diretto: 1,
  Indiretto: 2,
};

export const NecessitaIspezioneSpecialeRischioFrane = {
  Si: 1,
  No: 2,
};

export const RisultatiIspezioniSpecialiFrane = {
  NecessitaValutazioneLivello4: 1,
  IspezioneSpecialeNonEsausitva: 2,
  NecessitaIspezioneLivello1eClassificazioneLivello2: 3,
  IspezioneSpecialeAncoraDaEffettuare: 4,
};

export const PericolositaPAIPSAIFrane = {
  AA: 1,
  P1: 2,
  P2: 3,
  P3: 4,
  P4: 5,
  AreaNonMappata: 6,
};

export const RischioIdraulico = {
  DaVerificare: 1,
  Assente: 2,
  Presente: 3,
};

export const CampateSoggetteACollasso = {
  LessEqualThree: 1,
  GreaterThree: 2,
};

export const VulnerabilitaStrutturaleBassa = {
  Si: 1,
  No: 2,
};

export const NecessitaIspezioneSpecialeIdraulico = {
  Si: 1,
  No: 2,
};

export const RisultatiIspezioniSpecialiRischioIdraulico = {
  NecessitaValutazioneLivello4: 1,
  IspezioneSpecialeNonEsausitva: 2,
  NecessitaIspezioneLivello1eClassificazioneLivello2: 3,
  IspezioneSpecialeAncoraDaEffettuare: 4,
};

export const PericolositaPAIPSAIRischioIdraulico = {
  P1: 1,
  P2: 2,
  P3: 3,
  AreaNonMappata: 6,
};

export const PresenzaStudioIdraulicoLivelliDefiniti = {
  Si: 1,
  No: 2,
};

export const AnnoDiProgettazione = {
  Effettivo: 1,
  Approssimativo: 2,
};

export const DataApprossimativaAnnoDiProgettazione = {
  Anno1952: 1,
  Anno19521990: 2,
  Anno19902005: 3,
  Anno20052008: 4,
  AnnoOver2008: 5,
};

export const ClasseProgetto = {
  ClasseA: 1,
  ClasseB: 2,
  ClasseC: 3,
};

export const NormaProgetto = {
  Normale: 1,
  Circ6018: 2,
  Circ772: 3,
  Circ820: 4,
  Circ384: 5,
  DM1980: 6,
  DM1990: 7,
  NTC2005: 8,
  NTC2008: 9,
  NTC2018: 10,
  Antecedente1933: 11,
};

export const AnnoDiCostruzione = {
  Effettivo: 1,
  Approssimativo: 2,
};

export const DataApprossimativaAnnoDiCostruzione = {
  Anno1945: 1,
  Anno19451980: 2,
  AnnoOver1980: 3,
};

export const AnnoDiEntrataInEsercizio = {
  Effettivo: 1,
  Approssimativo: 2,
  NonNoto: 3,
};

export const InterventiSignificativiManutenzione = {
  Si: 1,
  No: 2,
};

export const AnnoInterventiSignificativiRicostruzione = {
  Effettivo: 1,
  Approssimativo: 2,
};

export const DataApprossimativaInterventiSignificativiManutenzione = {
  Anno1945: 1,
  Anno19451980: 2,
  AnnoOver1980: 3,
};

export const CriteriSismiciInOpera = {
  Si: 1,
  No: 2,
};

export const InspezioniPregresse = {
  Presenti: 1,
  Assenti: 2,
  NonNote: 3,
};

export const VerificaStatica = {
  Presente: 1,
  Assente: 2,
};

export const VerificaSismica = {
  Presente: 1,
  Assente: 2,
};

export const Segnalazioni = {
  Presenti: 1,
  Assenti: 2,
  NonNote: 3,
};

export const InterventiEseguiti = {
  Presenti: 1,
  Assenti: 2,
  NonNote: 3,
};

export const AttivitaMonitoraggioPregresseInCorso = {
  Presenti: 1,
  Assenti: 2,
  NonNote: 3,
};

export const InterventiManutenzione = {
  Presenti: 1,
  Assenti: 2,
  NonNote: 3,
};

export const ClassificazioneUsoStradale = {
  AutostradaFerrovia: 1,
  StradaExtraurbanaPrincipale: 2,
  StradaExtraurbanaSecondaria: 3,
  StradaUrbanaDiScorrimento: 4,
  StradaUrbanaDiQuartiere: 5,
  StradaLocale: 6,
  Pedonale: 7,
};

export const CompetanzaInfrastruttura = {
  Autostrada: 'Autostrada',
  StradaStatale: 'Strada statale',
  StradaRegionale: 'Strada regionale',
  StradaProvinciale: 'Strada provinciale',
  StradaComunale: 'Strada comunale',
  StradaVicinale: 'Strada vicinale',
  AltraInfrastrutturaStradale: 'Altra infrastruttura stradale',
};

export const CategoriaPonte = {
  Categoria1: 1,
  Categoria2: 2,
};

export const ItinerarioInternazionale = {
  Si: 1,
  No: 2,
};

export const ReteTEN = {
  Si: 1,
  No: 2,
};

export const ItinerarioEmergenza = {
  Si: 1,
  No: 2,
};

export const TrasportiEccezionali = {
  Si: 1,
  No: 2,
};

export const DisponibilitaStudiTrasportistici = {
  Si: 1,
  No: 2,
};

export const DisponibilitaStudiEffettiCarichiTraffico = {
  Si: 1,
  No: 2,
};

export const ValutazioneEffettiCarichiTraffico = {
  AggravanoVulnerabilitaStruttura: 1,
  NonAggravanoVulnerabilitaStruttra: 2,
  NonNota: 3,
};

export const PresenzaCurve = {
  Si: 1,
  No: 2,
};

export const TGMVeicoliGiorno = {
  ValoreEffettivo: 1,
  ValoreApprossimativo: 2,
};

export const TGMVeicoliGiornoValoreApprossimativo = {
  VeicoliSopra25000: 1,
  VeicoliTra10000e25000: 2,
  VeicoliSotto10000: 3,
};

export const TGMCommercialiVeicoliGiorno = {
  ValoreEffettivo: 1,
  ValoreApprossimativo: 2,
};

export const TGMCommercialiVeicoliGiornoValoreApprossimativo = {
  VeicoliSopra700: 1,
  VeicoliTra300e700: 2,
  VeicoliSotto300: 3,
};

export const TGMCommercialiVeicoliCorsiaSingola = {
  ValoreEffettivo: 1,
  ValoreApprossimativo: 2,
};

export const TGMCommercialiVeicoliCorsiaApprossimativo = {
  VeicoliSopra700: 1,
  VeicoliTra300e700: 2,
  VeicoliSotto300: 3,
};

export const TGMCommercialiVeicoliCorsiaLimiteCarico = {
  ClasseA: 1,
  ClasseB: 2,
  ClasseC: 3,
  ClasseD: 4,
  ClasseE: 5,
};

export const AlternativeStradali = {
  Si: 1,
  No: 2,
};

export const FrequentePassaggioPersone = {
  Si: 1,
  No: 2,
};

export const TrasportoMerciPericolose = {
  Si: 1,
  No: 2,
};

export const UnitaFisiografica = {
  Montuosa: 1,
  Collinare: 2,
  PianuraIntermontana: 3,
  PianuraBassa: 4,
};

export const CategoriaTopografica = {
  Pianura: 1,
  PianuraVersanti: 2,
  PendioDolce: 3,
  PendioModerato: 4,
  PendioRipido: 5,
  Cresta: 6,
};

export const CategoriaSottosuolo = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
};

export const AerosolMarini = {
  Si: 1,
  No: 2,
};

export const MaggioreDegrado = {
  Si: 1,
  No: 2,
};

export const FieldType = {
  images: 1,
  documents: 2,
  graphicElaboration: 3,
};

export const UploadMedia = {
  media: 'media',
  documents: 'documents',
  graphicElaboration: 'graphicElaboration',
};

export const MediaGroup = {
  Contesto: 1,
  Struttura: 2,
  Sovrastruttura: 3,
  Servizi: 4,
  Difetti: 5,
  Idraulica: 6,
  Frane: 7,
  Amministrativi: 8,
  Progetto: 9,
  AttivitaPregresse: 10,
  ElaboratiGrafici: 11,
  Esportazioni: 12,
  IspezioneExtraPiattaforma: 13,
};

export const SismicitaArea = {
  ValoreEffettivo: 1,
  ValoreApprossimativo: 2,
};

export const SismicitiaAreaValApprossimativo = {
  agMaggiore25: 1,
  ag15e25: 2,
  ag10e15: 3,
  ag05e10: 4,
  agMinore005: 5,
};

export const SistemaDiRiferimento = {
  ETRF2000: 1,
  WGS84: 2,
  Altro: 3,
};

export const EllissoideDiRiferimento = {
  WGS84: 1,
  Hayford: 2,
  Altro: 3,
};

export const tipologiaStrutturale = {
  ArcoMassiccio: 1,
  ArcoSottile: 2,
  TravateAppoggiate: 3,
  TravateContinueTelaio: 4,
  TravateGerberPontiStampella: 5,
  SolettaAppoggiata: 6,
  SolettaIncastrata: 7,
  CassonePrecompresso: 8,
  SezioneTubolare: 9,
  TravateCap: 10,
  StrallatoSospeso: 11,
  Altro: 12,
};

export const ispezioniSpecialiRischioStruttura = {
  Si: 1,
  No: 2,
};

export const risultatiIspezioniSpeciali = {
  NecessitaValutazioneLivello4: 1,
  IspezioneNonEsaustiva: 2,
  NecessitaIspezioneLivello1: 3,
  IspezioneAncoraDaEffettuare: 4,
};

export const tipologiaSchema = {
  Isostatico: 1,
  Iperstatico: 2,
};

export const tipologiaImpalcato = {
  Muratura: 1,
  CA: 2,
  CAP: 3,
  Acciaio: 4,
  Metallo: 5,
  Misto: 6,
  Legno: 7,
  Altro: 8,
};

export const mensole = {
  Si: 1,
  No: 2,
};

export const paliIlluminazione = {
  Presenti: 1,
  Assenti: 2,
  NonPrevisti: 3,
};

export const tipologiaPali = {
  Legno: 1,
  Acciaio: 2,
  Ghisa: 3,
  Altro: 4,
};

export const convogliamentoAcque = {
  Presente: 1,
  Assente: 2,
  NonPrevisto: 3,
};

export const tipologiaConvogliamentoAcque = {
  Metallo: 1,
  Plastica: 2,
  Altro: 3,
};

export const TipologiaSpallaTableValue = {
  CalcestruzzoArmato: 1,
  Muratura: 2,
  Nonrilevabile: 3,
};

export const VieAttaccoSpallaTableValue = {
  RilevatoInTerra: 1,
  RilevatoInTerraRinforzataArmata: 2,
  RilevatoInGolena: 3,
  SuRoccia: 4,
  ViadottoInPendenzaCurva: 5,
  Assente: 6,
  Altro: 7,
};

export const ImpostaFondSpallaTableValue = {
  Diretta: 1,
  Indiretta: 2,
  NonNota: 3,
};

export const TipoFondSpallaTableValue = {
  Muratura: 1,
  CalcestruzzoArmato: 2,
  Legno: 3,
  Acciaio: 4,
  NonNota: 5,
};

export const TipologiaPilaTableValue = {
  CalcestruzzoArmato: 1,
  Muratura: 2,
  AcciaioOMetallo: 3,
  NonRilevabile: 4,
};

export const TipologiaSezionePilaTableValue = {
  AParetePiena: 1,
  ADoppiaParete: 2,
  ACassone: 3,
  ACassoniSeparati: 4,
  ATelaio: 5,
  AColonna: 6,
  APiuColonne: 7,
  ADoppiaLama: 8,
  Altro: 9,
};

export const GeometriaSezioneTableValue = {
  Circolare: 1,
  Rettangolare: 2,
  Altro: 3,
};

export const PilaInAlveoTableValue = {
  Si: 1,
  No: 2,
};

export const ImpostaFondPilaTableValue = {
  Diretta: 1,
  Indiretta: 2,
  NonNota: 3,
};

export const TipologiaFondPilaTableValue = {
  Muratura: 1,
  CalcestruzzoArmato: 2,
  Legno: 3,
  Acciaio: 4,
  NonNota: 5,
};

export const sottoservizi = {
  Presente: 1,
  Assente: 2,
  NonPrevisto: 3,
};

export const tipologiaSottoservizi = {
  Metallo: 1,
  Plastica: 2,
  Altro: 3,
};

export const utenzaServizi = {
  Gas: 1,
  Elettricita: 2,
  Acqua: 3,
  Scarichi: 4,
};

export const TipologiaAppoggioTableValue = {
  Gomma: 1,
  GommaArmata: 2,
  GommaTeflon: 3,
  Acciaio: 4,
  AcciaioTeflon: 5,
  Calcestruzzo: 6,
  NonRilevabile: 7,
};

export const AppoggiAntisismiciAppoggioTableValue = {
  Si: 1,
  No: 2,
};

export const TipologiaGiuntoTableValue = {
  ReteNellaPavimentazione: 1,
  ProfilatiElastomerici: 2,
  StrisceInGomma: 3,
  Pettini: 4,
  TamponiOGiuntiSottopavimentazione: 5,
  NonRilevabile: 6,
};

export const TipologiaPulvinoTableValue = {
  CalcestruzzoArmato: 1,
  AcciaioOMetallo: 2,
  NonRilevabile: 3,
};

export const TipologiaAntennaTableValue = {
  CalcestruzzoArmato: 1,
  CalcestruzzoArmatoPrecompresso: 2,
  AcciaioOMetallo: 3,
  NonRilevabile: 4,
};

export const TipologiaSolettaTableValue = {
  CalcestruzzoArmato: 1,
  CalcestruzzoArmatoPrecompresso: 2,
  Legno: 3,
  NonRilevabile: 4,
};
export const TipologiaTraveTableValue = {
  CalcestruzzoArmato: 1,
  CalcestruzzoArmatoPrecompresso: 2,
  AcciaioOMetallo: 3,
  Legno: 4,
  NonRilevabile: 5,
};

export const TipoTraveTableValue = {
  TraveSemplice: 1,
  TraveReticolare: 2,
};

export const TipologiaTraversoTableValue = {
  CalcestruzzoArmato: 1,
  CalcestruzzoArmatoPrecompresso: 2,
  AcciaioOMetallo: 3,
  Legno: 4,
  NonRilevabile: 5,
};
export const TipologiaControventoTableValue = {
  CalcestruzzoArmato: 1,
  CalcestruzzoArmatoPrecompresso: 2,
  AcciaioOMetallo: 3,
  NonRilevabile: 4,
};
export const GeometriaSezioneControventoTableValue = {
  Circolare: 1,
  Rettangolare: 2,
  Altro: 3,
};

export const TipologiaArcoTableValue = {
  CalcestruzzoArmato: 1,
  Muratura: 2,
  Acciaio: 3,
  Legno: 4,
  NonRilevabile: 5,
};
export const TipologiaPiedrittoTableValue = {
  CalcestruzzoArmato: 1,
  AcciaioOMetallo: 2,
  NonRilevabile: 3,
};
export const TipologiaTiranteTableValue = {
  CalcestruzzoArmatoPrecompresso: 1,
  AcciaioOMetallo: 2,
  NonRilevabile: 3,
};

export const GeometriaSezioneTiranteTableValue = {
  Circolare: 1,
  Rettangolare: 2,
  Altro: 3,
};
export const TipologiaElementoExtraTableValue = {
  CalcestruzzoArmato: 1,
  CalcestruzzoArmatoPrecompresso: 2,
  AcciaioOMetallo: 3,
  Legno: 4,
  Muratura: 5,
};

export const SchemaPonteCommonTableValue = {
  Uno: 1,
  Assente: 2,
  NonPrevisto: 3,
};
export const SchemaPonteSpartiTrafficoTableValue = {
  Uno: 1,
  Due: 2,
  Tre: 3,
  Assente: 4,
  NonPrevisto: 5,
};
export const SchemaPontePavimentazioneTableValue = {
  Si: 1,
  No: 2,
};
export const TipologiaCordoloTableValue = {
  CalcestruzzoArmato: 1,
  CalcestruzzoArmatoPrecompresso: 2,
};
export const PossibileAccessoSottoPonte = {
  Si: 1,
  No: 2,
  Parziale: 3,
};

export const VerificaScalzamentoFondazioni = {
  Si: 1,
  No: 2,
};

export const ProspettiIspezionati = {
  Destro: 1,
  Sinistro: 2,
};

export const AffidabilitaComplessivaRischioFrane = {
  Buona: 1,
  Limitata: 2,
};

export const AffidabilitaComplessivaRischioIdraulico = {
  Buona: 1,
  Limitata: 2,
};

export const TipologiaPavimentazioneTableValue = {
  Asfalto: 1,
  Calcestruzzo: 2,
  Pietra: 3,
  Altro: 4,
};
export const TipologiaMarciapiedeTableValue = {
  Asfalto: 1,
  Calcestruzzo: 2,
  Pietra: 3,
  Legno: 4,
  Autobloccanti: 5,
  Altro: 6,
};
export const MarciapiedePresenteTableValue = {
  SoloUnLato: 1,
  SuEntrambiLati: 2,
};
export const SormontabilitaMarciapiedeTableValue = {
  Sormontabile: 1,
  NonSormontabile: 2,
};
export const TipologiaParapettoTableValue = {
  Muratura: 1,
  Ringhiera: 2,
  Metallico: 3,
  Legno: 4,
  Calcestruzzo: 5,
  Altro: 6,
};
export const TipologiaGuardaviaTableValue = {
  Muratura: 1,
  Ringhiera: 2,
  Metallico: 3,
  Legno: 4,
  Calcestruzzo: 5,
  Altro: 6,
};
export const TipologiaSpartitrafficoTableValue = {
  NewJersey: 1,
  Guardrail: 2,
  Aiuola: 3,
  Altro: 4,
};
export const MisureMitigazione = {
  FenomenoRiconosciutoNonStudiato: 1,
  FenomenoRiconosciutoStudiato: 2,
  FenomenoModellato: 3,
  FenomenoOggettoOpereMitigazione: 4,
};

export const EstensioneInterferenzaFrana = {
  Totale: 1,
  Parziale: 2,
  ZonaDiApproccio: 3,
  Altro: 4,
};

export const Fenomeno = {
  Accertato: 1,
  Potenziale: 2,
};

export const TipologiaFenomeno = {
  CrolloRoccia: 1,
  Ribaltamento: 2,
  ColateValangheDetritiche: 3,
  ColateViscose: 4,
  ScorrimtoRotazionale: 5,
  ScorrimentoTraslativo: 6,
  ComplessoComposito: 7,
  FenomeniGravitativiProfondi: 8,
};

export const DistribuzioneAttivita = {
  Costante: 1,
  Retrogressivo: 2,
  InAllargamento: 3,
  Avanzante: 4,
  InDiminuzione: 5,
  Confinato: 6,
  Multidirezionale: 7,
  NonDefinibile: 8,
};

export const UsoSuoloAreaPotezialmenteCoinvolta = {
  AreaUrbanizzata: 1,
  AreaEstrattiva: 2,
  Seminativo: 3,
  Colture: 4,
  VegetazioneRiparia: 5,
  Rimboschimento: 6,
  BoscoCeduo: 7,
  BoscoAltoFusto: 8,
  IncoltoNudo: 9,
  IcoltoMacchiaCespugliato: 10,
  IncoltoPratoPascolo: 11,
  Altro: 12,
};

export const PosizioneInstabilitaVersante = {
  Alta: 1,
  Media: 2,
  Bassa: 4,
  Fondovalle: 5,
};

export const StatoAttivitaFrana = {
  Attiva: 1,
  Inattiva: 2,
  Stabilizzata: 3,
};

export const FraneAttive = {
  Attivo: 1,
  Sospesa: 2,
  Quiescente: 3,
};

export const GradoCriticitaFrana = {
  AltamenteCritica: 1,
  Critica: 2,
  ScarsamenteCritica: 3,
};

export const VelocitaMaxSpostamentoFrana = {
  EstremamenteMoltoRapida: 1,
  Rapida: 2,
  Moderata: 3,
  Lenta: 4,
  EstremamenteMoltoLenta: 5,
};

export const MagnitudoBaseVolumetrica = {
  EstremamenteMoltoGrande: 1,
  Grande: 2,
  Media: 3,
  Piccola: 4,
  MoltoPiccola: 5,
};

export const StrutturaAlveoRischioIdraulico = {
  Pile: 1,
  Spalle: 2,
  Rilevato: 3,
  Nessuna: 4,
};

export const FenomenoSormontoRischioIdraulico = {
  Accertato: 1,
  Ipotizzato: 2,
};

export const FenomenoErosioneRischioIdraulico = {
  Accertati: 1,
  Ipotizzati: 2,
  Assenti: 3,
};

export const ScenariPericolositaRischioIdraulico = {
  P2: 1,
  P3: 2,
  Nessuno: 3,
};

export const AreaPericolosaRischioIdraulico = {
  FenomenoRiconosciutoNonStudiato: 1,
  FenomenoRiconosciutoStudiato: 2,
  FenomenoModellatoMonitorato: 3,
  FenomenoOggettoOpereMitigazione: 4,
};

export const RicadenteAreaAlluvioniRischioIdraulico = {
  Si: 1,
  No: 2,
};

export const PresenzaRilieviRischioIdraulico = {
  Si: 1,
  No: 2,
};

export const BacinoIdrograficoRischioIdraulico = {
  Effettivo: 1,
  Presunto: 2,
};

export const BacinoIdrograficoPresuntoRischioIdraulico = {
  S100: 1,
  S100500: 2,
  S500: 3,
};

export const TipologiaReticolo = {
  ReticoloPrincipale: 1,
  ReticoloSecondario: 2,
  ReticoloArtificiale: 3,
};

export const ConfinamentoAlveo = {
  Confinato: 1,
  Semiconfinato: 2,
  NonConfinato: 3,
};

export const MorfologiaAlveo = {
  CanaleSingolo: 1,
  Intrecciato: 2,
};

export const Sinuosita = {
  Rettilineo: 1,
  Sinuoso: 2,
  Meandriforme: 3,
};

export const TipologiaFondoAlveoRischioIdraulico = {
  FondoFisso: 1,
  FondoMobile: 2,
};

export const MaterialeAlveoRischioIdraulico = {
  NonValutabile: 1,
  Argilla: 2,
  Limo: 3,
  SabbiaFine: 4,
  SabbiaMedia: 5,
  SabbiaGrossolana: 6,
  Ghiaia: 7,
  Ciottoli: 8,
  Massi: 9,
  TerrenoInerbito: 10,
  TerraNuda: 11,
  AlveoImpermeabilizzato: 12,
  Altro: 13,
};

export const AccumuloDetritiRischioIdraulico = {
  Si: 1,
  No: 2,
};

export const EvoluzioneAlveoRischioIdraulico = {
  Equilibrio: 1,
  FaseEvolutiva: 2,
  Stabilizzato: 3,
};

export const CurvaturaAlveoRischioIdraulico = {
  Si: 1,
  No: 2,
};

export const DivagazioneAlveoRischioIdraulico = {
  Si: 1,
  No: 2,
};

export const TipologiaCorsoAcquaRischioIdraulico = {
  CasoI: 1,
  CasoII: 2,
  CasoIII: 3,
  CasoIV: 4,
};

export const DeposizioneSedimenti = {
  Si: 1,
  No: 2,
};

export const MaterialeVegetaleRischioIdraulico = {
  Si: 1,
  No: 2,
};

export const FrancoIdraulicoSuperiore = {
  Si: 1,
  No: 2,
};

export const AreeGolenaliAssenti = {
  Si: 1,
  No: 2,
};

export const EvidenzeProfonditaDelleFondazioni = {
  Profonde: 1,
  Superficiali: 2,
  NessunaEvidenza: 3,
};

export const AbbassamentoGeneralizzatoAlveo = {
  Si: 1,
  No: 2,
};

export const AbbassamentoAccentuatoAlleFondazioni = {
  Si: 1,
  No: 2,
};

export const AccumuliDetritiAMontePila = {
  Si: 1,
  No: 2,
};

export const OpereProtezioneSpondale = {
  Nessuna: 1,
  Scogliera: 2,
  Gabbioni: 3,
  Pennelli: 4,
  MuroDiSponda: 5,
  AlveoCanalizzato: 6,
  Argine: 7,
  Altro: 8,
};

export const StatoConservazioneAdeguatezzaOpera = {
  AdeguataIntegra: 1,
  NonAdeguata: 2,
  Danneggiata: 3,
};

export const ProtezioneAlPiedePileSpalle = {
  Si: 1,
  No: 2,
};

export const StatoConservazioneProtezionePiede = {
  AdeguataIntegra: 1,
  NonAdeguata: 2,
  Danneggiata: 3,
};

export const BrigliaProtezioneValleDelPonte = {
  Si: 1,
  No: 2,
};

export const StatoConservazioneAdeguatezzaOperaBriglia = {
  AdeguataIntegra: 1,
  NonAdeguata: 2,
  Danneggiata: 3,
};

export const OpereArginaturaDiAlterazionePortate = {
  Presenti: 1,
  Assenti: 2,
};

export const ElCriticiVulnerabilitStrutturaleFondazionale = {
  Presenti: 1,
  Assenti: 2,
};

export const StatoConservazioneAdeguatezzaOpereArginatura = {
  Adeguate: 1,
  NonAdeguate: 2,
};

export const MisureLaminazioneMitigazione = {
  Presenti: 1,
  Assenti: 2,
};

export const ElementiCriticiStatoDegrado = {
  NonSegnalareImmediatamente: 1,
  SegnalareImmediatamente: 2,
  NonIspezionabili: 3,
};

export const ElementiCriticiVulnerabilitaSismica = {
  Presenti: 1,
  Assenti: 2,
};

export const StatoConservazioneAdeguatezzaOpereLaminazione = {
  Adeguate: 1,
  NonAdeguate: 2,
};

export const sistemaMonitoraggioRischioIdraulico = {
  Presenti: 1,
  Assenti: 2,
};

export const ElCriticiSismicaStatoDegrado = {
  NonSegnalareImmediatamente: 1,
  SegnalareImmediatamente: 2,
  NonIspezionabili: 3,
};

export const CondizioniCriticheStrutturaleFondazionale = {
  Presenti: 1,
  Assenti: 2,
};

export const statoConservazioneSistemaMonitoraggioRischioIdraulico = {
  Adeguate: 1,
  NonAdeguate: 2,
};

export const CondizioniCriticheStrutturaleTipologiaEl = {
  QuadriFessurativiEstesi: 1,
  Cinematismi: 2,
  IncipientePerditaAppoggio: 3,
  GiunzioneElementiChiave: 4,
  Altro: 5,
};

export const CondizioniCriticheSismica = {
  Presenti: 1,
  Assenti: 2,
};

export const CondizioniCriticheSismicaTipologiaEl = {
  QuadriFessurativiEstesi: 1,
  Cinematismi: 2,
  IncipientePerditaAppoggio: 3,
  GiunzioneElementiChiave: 4,
  Altro: 5,
};

export const ElementiVulnerabilitaSismica = {
  Presenti: 1,
  Assenti: 2,
};

export const DifettositaSovrastruttureEServizi = {
  NessunDifetto: 1,
  NonPresente: 2,
  Difetto: 3,
  NonPrevisto: 4,
  NonDisponibile: 5,
};

export const DifettositaStruttura = {
  NessunDifetto: 1,
  Difetto: 2,
  NonCompilata: 3,
};

export const InspectionRowAccessExpiredType = {
  MAINUSER_OWNER_WITH_NO_SUBSCRIPTION: 1,
  MAINUSER_OWNER_WITH_EXPIRED_SUBSCRIPTION: 2,
  MAINUSER_OWNER_WITH_ACTIVE_SUBSCRIPTION: 12,
  MAINUSER_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION: 3,
  MAINUSER_NOT_OWNER_WITH_NO_SUBSCRIPTION: 4,
  MAINUSER_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION: 5,
  INTERNALCOLLABORATOR_OWNER_WITH_ACTIVE_SUBSCRIPTION: 6,
  INTERNALCOLLABORATOR_OWNER_WITH_NO_SUBSCRIPTION: 7,
  INTERNALCOLLABORATOR_OWNER_WITH_EXPIRED_SUBSCRIPTION: 8,
  INTERNALCOLLABORATOR_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION: 9,
  INTERNALCOLLABORATOR_NOT_OWNER_WITH_NO_SUBSCRIPTION: 10,
  INTERNALCOLLABORATOR_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION: 11,
};

export const InspectionRowAccessExpiredMessageMap = {
  [InspectionRowAccessExpiredType.MAINUSER_OWNER_WITH_NO_SUBSCRIPTION]:
    'Per accedere alle attività in archivio devi avere una sottoscrizione attiva. Contattaci per sottoscrivere. ',
  [InspectionRowAccessExpiredType.MAINUSER_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
    'Per accedere alle attività in archivio devi avere una sottoscrizione attiva. Contattaci per sottoscrivere. ',
  [InspectionRowAccessExpiredType.MAINUSER_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
    "Il tuo accesso all'attività è scaduto. Contattare il proprietario dell'attività se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [InspectionRowAccessExpiredType.MAINUSER_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
    "L'accesso all'attività per il tuo account è scaduto. Contatta il proprietario del ponte per riattivarla.",
  [InspectionRowAccessExpiredType.MAINUSER_NOT_OWNER_WITH_NO_SUBSCRIPTION]:
    "L'accesso all'attività per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [InspectionRowAccessExpiredType.MAINUSER_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
    "L'accesso all'attività per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
    "L'accesso all'attività per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_NO_SUBSCRIPTION]:
    'Per accedere alle attività in archivio devi avere una sottoscrizione attiva. Contattaci per sottoscrivere. ',
  [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
    'Per accedere alle attività in archivio devi avere una sottoscrizione attiva. Contattaci per sottoscrivere. ',
  [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_ACTIVE_SUBSCRIPTION]:
    "L'accesso all'attività per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_NO_SUBSCRIPTION]:
    "L'accesso all'attività per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
  [InspectionRowAccessExpiredType.INTERNALCOLLABORATOR_NOT_OWNER_WITH_EXPIRED_SUBSCRIPTION]:
    "L'accesso all'attività  per il tuo account è scaduto. Contatta il proprietario del ponte se hai necessità di rinnovare la data di scadenza dell'accesso.",
};

export const InspectionListActionsMap = {
  ARCHIVE: 'archive',
  DELETE: 'delete',
};

export const InspectionListActionsWarningMessageMap = {
  [InspectionListActionsMap.ARCHIVE]:
    'Sei sicuro di voler archiviare questa ispezione?',
  [InspectionListActionsMap.DELETE]:
    'Sei sicuro di voler eliminare questa ispezione?',
};
