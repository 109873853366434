import { Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { GridWrap, InfoWrapper, LocalTourWrapper, Table } from 'components';
import {
  schemaPonteCommonOptions,
  //schemaPontePavimentazioneOptions,
  schemaPonteSpartitrafficoOptions,
} from 'constants/selectOptions';
import { tour_12 } from 'constants/tour';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import SchemaPonteForm from './SchemaPonteForm';
import { steps } from '../Accordion1CaratteristicheSovrastruttura.tourSteps';
const SchemaPonteSection = ({ level1 }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const schemaPonteTableWatch = watch('schemaPonteTable');

  const dataSchemaPonteConfig = {
    columns: {
      codice: { label: 'Codice' },
      cordoloSX: { label: 'Cordolo SX', format: schemaPonteCommonOptions },
      cordoloDX: { label: 'Cordolo DX', format: schemaPonteCommonOptions },
      marciapiedeSX: {
        label: 'Marciapiede SX',
        format: schemaPonteCommonOptions,
      },
      marciapiedeDX: {
        label: 'Marciapiede DX',
        format: schemaPonteCommonOptions,
      },
      parapettoSX: { label: 'Parapetto SX', format: schemaPonteCommonOptions },
      parapettoDX: { label: 'Parapetto DX', format: schemaPonteCommonOptions },
      guardaviaSX: { label: 'Guardavia SX', format: schemaPonteCommonOptions },
      guardaviaDX: { label: 'Guardavia DX', format: schemaPonteCommonOptions },
      spartitraffico: {
        label: 'Spartitraffico',
        format: schemaPonteSpartitrafficoOptions,
      },
      pavimentazione: {
        label: 'Pavimentazione',
        format: schemaPonteCommonOptions,
      },
    },
  };

  // solette setup
  const [selectedMensoleTamponiCampate, setSelectedMensoleTamponiCampate] =
    useState([]);

  const editRowsMensoleTamponiCampate = item => {
    setValue('l0t3a1_mensoleTamponiCampateTableValue', [item]);
    setValue('l0t3a1_cordoloSXMensoleTamponiCampateTableValue', item.cordoloSX);
    setValue('l0t3a1_cordoloDXMensoleTamponiCampateTableValue', item.cordoloDX);
    setValue(
      'l0t3a1_marciapiedeSXMensoleTamponiCampateTableValue',
      item.marciapiedeSX
    );
    setValue(
      'l0t3a1_marciapiedeDXMensoleTamponiCampateTableValue',
      item.marciapiedeDX
    );
    setValue(
      'l0t3a1_parapettoSXMensoleTamponiCampateTableValue',
      item.parapettoSX
    );
    setValue(
      'l0t3a1_parapettoDXMensoleTamponiCampateTableValue',
      item.parapettoDX
    );
    setValue(
      'l0t3a1_guardaviaSXMensoleTamponiCampateTableValue',
      item.guardaviaSX
    );
    setValue(
      'l0t3a1_guardaviaDXMensoleTamponiCampateTableValue',
      item.guardaviaDX
    );
    setValue(
      'l0t3a1_spartitrafficoMensoleTamponiCampateTableValue',
      item.spartitraffico
    );
    setValue(
      'l0t3a1_pavimentazioneMensoleTamponiCampateTableValue',
      item.pavimentazione
    );
  };

  const handleSelectAllClickMensoleTamponiCampate = event => {
    if (event.target.checked) {
      const selectedValue = getValues('schemaPonteTable');
      setSelectedMensoleTamponiCampate(selectedValue);
      setValue('l0t3a1_mensoleTamponiCampateTableValue', selectedValue);
    } else {
      setSelectedMensoleTamponiCampate([]);
      setValue('l0t3a1_mensoleTamponiCampateTableValue', []);
    }
  };

  return (
    <>
      <GridWrap item mt={2} xs={12}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.schemaPonteSovrastrutturaAccordion1.drawerTitle}
          drawerImages={INFO.schemaPonteSovrastrutturaAccordion1.drawerImages}
          drawerText={INFO.schemaPonteSovrastrutturaAccordion1.drawerText}
        >
          <Divider>Schema Ponte</Divider>
        </InfoWrapper>
      </GridWrap>
      {!level1 && !inspectionDisabled && !isViewingInspection && (
        <SchemaPonteForm
          schemaPonteFields={schemaPonteTableWatch || []}
          selected={selectedMensoleTamponiCampate}
          setSelected={setSelectedMensoleTamponiCampate}
          schemaPonteTable={'schemaPonteTable'}
        />
      )}

      <GridWrap container spacing={2} mt={2}>
        <GridWrap item xs={12} mt={2} className={`${tour_12.step_01}`}>
          <Table
            data={schemaPonteTableWatch || []}
            config={dataSchemaPonteConfig}
            hasCheckbox={!level1 && !inspectionDisabled && !isViewingInspection}
            rowActions={
              !level1 && !inspectionDisabled && !isViewingInspection
                ? [
                    {
                      onClick: item => {
                        editRowsMensoleTamponiCampate(item);
                        setSelectedMensoleTamponiCampate([item]);
                      },
                      icon: <EditIcon />,
                    },
                  ]
                : []
            }
            selected={selectedMensoleTamponiCampate}
            onSelectAllClick={handleSelectAllClickMensoleTamponiCampate}
            setSelected={setSelectedMensoleTamponiCampate}
          />
        </GridWrap>
      </GridWrap>
      {!isViewingInspection && (
        <GridWrap item xs={12}>
          <LocalTourWrapper steps={steps} />
        </GridWrap>
      )}
    </>
  );
};

SchemaPonteSection.propTypes = {
  level1: PropTypes.bool,
};
export default SchemaPonteSection;
