import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';

const InfoBox = ({ color, text }) => {
  return (
    <>
      {text && (
        <Box sx={{ bgcolor: color, borderRadius: 2 }} p={2} mb={1}>
          <Typography variant="body2">{text}</Typography>
        </Box>
      )}
    </>
  );
};

InfoBox.propTypes = {
  color: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export { InfoBox };
