import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Rating } from 'constants/colors';
import { RateContainer, RateItem } from './Rate.style';
export const Rate = ({ rating }) => {
  return (
    <RateContainer>
      <RateItem customColors={Rating[rating[0]]}>
        <Typography variant="body" sx={{ fontSize: '0.75rem !important' }}>
          0
        </Typography>
      </RateItem>
      <RateItem customColors={Rating[rating[1]]}>
        <Typography variant="body" sx={{ fontSize: '0.75rem !important' }}>
          1
        </Typography>
      </RateItem>
      <RateItem customColors={Rating[rating[2]]}>
        <Typography variant="body" sx={{ fontSize: '0.75rem !important' }}>
          2
        </Typography>
      </RateItem>
      <RateItem>
        <Typography variant="body" sx={{ fontSize: '0.75rem !important' }}>
          3
        </Typography>
      </RateItem>
      <RateItem>
        <Typography variant="body" sx={{ fontSize: '0.75rem !important' }}>
          4
        </Typography>
      </RateItem>
      <RateItem>
        <Typography variant="body" sx={{ fontSize: '0.75rem !important' }}>
          5
        </Typography>
      </RateItem>
    </RateContainer>
  );
};

Rate.propTypes = {
  rating: PropTypes.object.isRequired,
};
