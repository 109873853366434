import styled from '@emotion/styled';

export const TableContainer = styled('div')({
  '& .MuiTableContainer-root': {
    marginTop: 30,
  },
});

export const FormContainer = styled('div')({
  '& .MuiGrid-container': {
    marginBottom: 7,
  },
});

export const Buttons = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  '& .MuiIconButton-root': {
    marginLeft: '5px',
  },
});
