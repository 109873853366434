import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { GeometriaSezioneControventoTableValue } from 'constants/inspections';
import {
  geometriaSezioneControventoOptions,
  tipologiaControventoOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
const ControventiForm = ({
  controventiFields,
  selected,
  setSelected,
  controventiTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t2a5_controventiTableValue: [],
      l0t2a5_tipologiaControventoTableValue: '',
      l0t2a5_geometriaSezioneControventoTableValue: '',
      l0t2a5_lunghezzaControventoTableValue: '',
      l0t2a5_diametroControventoTableValue: '',
      l0t2a5_spessoreControventoTableValue: '',
      l0t2a5_altezzaControventoTableValue: '',
      l0t2a5_superficieControventoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a5_controventiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetControventi();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeControventi = () => {
    const l0t2a5_tipologiaControventoTableValue = getValues(
      'l0t2a5_tipologiaControventoTableValue'
    );
    const l0t2a5_geometriaSezioneControventoTableValue = getValues(
      'l0t2a5_geometriaSezioneControventoTableValue'
    );
    const l0t2a5_lunghezzaControventoTableValue = getValues(
      'l0t2a5_lunghezzaControventoTableValue'
    );

    const l0t2a5_diametroControventoTableValue = getValues(
      'l0t2a5_diametroControventoTableValue'
    );
    const l0t2a5_spessoreControventoTableValue = getValues(
      'l0t2a5_spessoreControventoTableValue'
    );
    const l0t2a5_altezzaControventoTableValue = getValues(
      'l0t2a5_altezzaControventoTableValue'
    );
    const l0t2a5_superficieControventoTableValue = getValues(
      'l0t2a5_superficieControventoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const controventi = [...controventiFields];

    controventi.map((x, index) => {
      if (ids.includes(x?.codice)) {
        controventi[index] = {
          ...x,
          tipologiaControvento: l0t2a5_tipologiaControventoTableValue,
          geometriaSezioneControvento:
            l0t2a5_geometriaSezioneControventoTableValue,
          lunghezzaControvento: l0t2a5_lunghezzaControventoTableValue,
          diametroControvento: l0t2a5_diametroControventoTableValue,
          spessoreControvento: l0t2a5_spessoreControventoTableValue,
          altezzaControvento: l0t2a5_altezzaControventoTableValue,
          superficieControvento: l0t2a5_superficieControventoTableValue,
          ...(l0t2a5_tipologiaControventoTableValue !== x.tipologiaControvento
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'controvento',
                  l0t2a5_tipologiaControventoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(controventiTable, controventi);
    onResetControventi();
  };

  const onConfirmChangeControventi = () => {
    setOpenConfirmationDialog(false);
    changeControventi();
  };

  const onSubmitControventi = () => {
    const l0t2a5_tipologiaControventoTableValue = getValues(
      'l0t2a5_tipologiaControventoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const controventi = [...controventiFields];

    const hasChangedTipologia = controventi.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a5_tipologiaControventoTableValue !== el.tipologiaControvento &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaControvento)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeControventi();
    }
  };

  const onResetControventi = () => {
    setValue('l0t2a5_controventiTableValue', []);
    setValue('l0t2a5_tipologiaControventoTableValue', '');
    setValue('l0t2a5_geometriaSezioneControventoTableValue', '');
    setValue('l0t2a5_lunghezzaControventoTableValue', '');
    setValue('l0t2a5_diametroControventoTableValue', '');
    setValue('l0t2a5_spessoreControventoTableValue', '');
    setValue('l0t2a5_altezzaControventoTableValue', '');
    setValue('l0t2a5_superficieControventoTableValue', '');

    setSelected([]);
  };

  const isCircolare =
    watch('l0t2a5_geometriaSezioneControventoTableValue') ===
    GeometriaSezioneControventoTableValue.Circolare;

  useEffect(() => {
    setValue('l0t2a5_diametroControventoTableValue', '');
    setValue('l0t2a5_spessoreControventoTableValue', '');
    setValue('l0t2a5_altezzaControventoTableValue', '');
  }, [isCircolare]);

  // {[2*(Altezza+Spessore)*Lunghezza]}*2/10000.
  // Oppure, se "Tipologia sezione"="Circolare": (Diametro*π*Lunghezza*2)/10000

  const lunghezzaControventoWatch = parseFloat(
    watch('l0t2a5_lunghezzaControventoTableValue')
  );
  const altezzaControventoWatch = parseFloat(
    watch('l0t2a5_altezzaControventoTableValue')
  );
  const spessoreControventoWatch = parseFloat(
    watch('l0t2a5_spessoreControventoTableValue')
  );
  const diametroControventoWatch = parseFloat(
    watch('l0t2a5_diametroControventoTableValue')
  );

  useEffect(() => {
    let result;
    if (!isCircolare) {
      result = (
        (2 *
          (altezzaControventoWatch + spessoreControventoWatch) *
          lunghezzaControventoWatch *
          2) /
        10000
      ).toFixed(2);
    } else {
      result = (
        (diametroControventoWatch * Math.PI * lunghezzaControventoWatch * 2) /
        10000
      ).toFixed(2);
    }
    setValue('l0t2a5_superficieControventoTableValue', result);
  }, [
    lunghezzaControventoWatch,
    altezzaControventoWatch,
    spessoreControventoWatch,
    diametroControventoWatch,
  ]);

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper
          size={6}
          infoMessage={INFO.controventiSelezionati.infoMessage}
        >
          <RHFTagAutocomplete
            multiple={true}
            id="tags-controventi"
            name={'l0t2a5_controventiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={controventiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Controventi selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_tipologiaControventoTableValue"
            label="Tipologia Controvento*"
            defaultValue={''}
            onChange={e => {
              setValue('l0t2a5_tipologiaControventoTableValue', e.target.value);
            }}
            selectOptions={tipologiaControventoOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_geometriaSezioneControventoTableValue"
            label="Geometria sezione Controvento"
            defaultValue={''}
            onChange={e => {
              setValue(
                'l0t2a5_geometriaSezioneControventoTableValue',
                e.target.value
              );
            }}
            selectOptions={geometriaSezioneControventoOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_lunghezzaControventoTableValue"
            label={'Lunghezza (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_diametroControventoTableValue"
            label={'Diametro (D) [cm]'}
            defaultValue=""
            disabled={!isCircolare}
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_spessoreControventoTableValue"
            label={'Spessore (s) [cm]'}
            defaultValue=""
            disabled={isCircolare}
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_altezzaControventoTableValue"
            label={'Altezza (H) [cm]'}
            defaultValue=""
            disabled={isCircolare}
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoMessage={INFO.superficieControvento.infoMessage}
        >
          <RHFNumberTextField
            name="l0t2a5_superficieControventoTableValue"
            label={'Superficie [m²]'}
            defaultValue=""
            disabled
            InputLabelProps={{ shrink: true }}
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </InfoWrapper>

        <GridWrap item xs={9}>
          <FormSubmitButtons
            onSubmit={onSubmitControventi}
            onReset={onResetControventi}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeControventi}
      />
    </>
  );
};
ControventiForm.propTypes = {
  controventiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaControvento: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      geometriaSezioneControvento: PropTypes.number,
      lunghezzaControvento: PropTypes.number,
      diametroControvento: PropTypes.number,
      spessoreControvento: PropTypes.number,
      altezzaControvento: PropTypes.number,
      superficieControvento: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  controventiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default ControventiForm;
