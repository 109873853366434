import styled from '@emotion/styled';

export const AvatarContainer = styled('div')({
  height: '125px',
  width: '125px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& img': {
    height: '100%',
    width: '100%',
    borderRadius: '100%',
  },
});
