import nessunDifettoImg from 'assets/icons/materials/nessunDifetto.svg';
import difettoImg from 'assets/icons/materials/nonPresente.png';
import nonCompilataImg from 'assets/icons/materials/nonRilevabile.png';
import { DifettositaStrutturaOptions } from 'constants/selectOptions';

export const handleDefectId = defect => {
  return DifettositaStrutturaOptions[defect - 1].label;
};

export const defects = {
  difetto: difettoImg,
  nonCompilata: nonCompilataImg,
  nessunDifetto: nessunDifettoImg,
};
