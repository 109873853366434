import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  convogliamentoAcqueOptions,
  DifettositaSovrastruttureEServiziOptions,
  schemaPonteSpartitrafficoOptionsForL1,
} from 'constants/selectOptions';
import { BoxContainer, Row } from './MaterialBox.styles';
import { handleMaterialId, renderMaterialImg } from './materials';

const MaterialBox = ({
  // eslint-disable-next-line
  dataRecap,
  topic,
  materialsData,
  structureSection,
  enumOptions,
  defect,
}) => {
  let charTex;
  const lastChar = topic.slice(-1);
  switch (lastChar) {
    case 'a':
      charTex = 'Nessuna';
      break;

    default:
      charTex = 'Nessun';
      break;
  }

  if (topic === 'Elemento extra' || topic === 'Guardavia') {
    charTex = 'Nessun';
  }
  if (topic === 'Pavimentazione' || topic === 'Trave') {
    charTex = 'Nessuna';
  }

  let formatTopic = topic;
  if (topic === 'Pali illuminazione') {
    formatTopic = 'Palo illuminazione';
  } else if (topic === 'Sottoservizi') {
    formatTopic = 'Sottoservizio';
  }

  const renderMaterialData = materialsData => {
    if (structureSection) {
      return (
        materialsData?.materials &&
        materialsData?.materials.map((el, idx) => (
          <>
            <Row key={idx} style={{ alignItems: 'center' }}>
              {renderMaterialImg(handleMaterialId(enumOptions, el.id))}
              <Stack>
                <Typography variant="h4">
                  {handleMaterialId(enumOptions, el.id)}
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                  {el?.count}
                </Typography>
              </Stack>
            </Row>
          </>
        ))
      );
    }

    if (materialsData?.presenza === 1) {
      return (
        materialsData?.materials &&
        materialsData?.materials.map((el, idx) => (
          <>
            <Row key={idx} style={{ alignItems: 'center' }}>
              {renderMaterialImg(handleMaterialId(enumOptions, el.id))}
              <Stack>
                <Typography variant="h4">
                  {handleMaterialId(enumOptions, el.id)}
                </Typography>
                {structureSection && (
                  <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                    {el?.count}
                  </Typography>
                )}
              </Stack>
            </Row>
          </>
        ))
      );
    }

    if (materialsData?.presenza > 1) {
      return (
        <Row style={{ alignItems: 'center' }}>
          {renderMaterialImg(
            handleMaterialId(
              convogliamentoAcqueOptions,
              materialsData?.presenza
            )
          )}
          <Stack>
            <Typography variant="h4">
              {handleMaterialId(
                convogliamentoAcqueOptions,
                materialsData?.presenza
              )}
            </Typography>

            {/* <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                  {el?.count}
                </Typography> */}
          </Stack>
        </Row>
      );
    }

    if (materialsData?.presenza >= 1 && topic === 'Spartitraffico') {
      return (
        <Row style={{ alignItems: 'center' }}>
          {renderMaterialImg(
            handleMaterialId(
              schemaPonteSpartitrafficoOptionsForL1,
              materialsData?.presenza
            )
          )}
          <Stack>
            <Typography variant="h4">
              {handleMaterialId(
                schemaPonteSpartitrafficoOptionsForL1,
                materialsData?.presenza
              )}
            </Typography>
          </Stack>
        </Row>
      );
    }

    if (defect >= 1) {
      return (
        <Row style={{ alignItems: 'center' }}>
          {renderMaterialImg(
            handleMaterialId(DifettositaSovrastruttureEServiziOptions, defect)
          )}
          <Stack>
            <Typography variant="h4">
              {handleMaterialId(
                DifettositaSovrastruttureEServiziOptions,
                defect
              )}
            </Typography>
          </Stack>
        </Row>
      );
    }
  };

  /*   const renderAntisismico = materialsData => {
    if (
      materialsData?.presenzaElementiStruttura === 'presenti' &&
      topic === 'Antisismico'
    ) {
      return (
        <Row style={{ alignItems: 'center' }}>
          <Stack>
            <Typography sx={{ fontWeight: 'bold' }} variant="h4">
              ciao
            </Typography>
          </Stack>
        </Row>
      );
    }
  }; */

  return (
    <div>
      <BoxContainer
        status={materialsData}
        defect={defect}
        topic={topic}
        structureSection={structureSection}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ width: '100%' }}
        >
          <div>
            <Typography
              variant="h3Bold"
              sx={{
                fontSize: '0.75rem',
              }}
            >
              {topic}
            </Typography>
          </div>
          {(materialsData?.mq > 0 || materialsData?.lunghezza > 0) && (
            <div>
              <Typography
                variant="h3"
                sx={{
                  fontSize: '0.75rem',
                }}
              >
                {materialsData?.mq
                  ? `${materialsData?.mq}mq`
                  : materialsData?.lunghezza && `${materialsData?.lunghezza}m`}
              </Typography>
            </div>
          )}
        </Stack>

        {materialsData?.presenza >= 1 ||
        materialsData?.materials?.length > 0 ||
        defect >= 1
          ? renderMaterialData(materialsData)
          : !materialsData?.haElementi &&
            topic !== 'Antisismico' && (
              <Typography variant="h4">{`${charTex} ${formatTopic} disponibile`}</Typography>
            )}

        {topic === 'Antisismico' &&
          materialsData?.presenzaElementiStruttura === 'nonSelezionato' && (
            <Typography variant="h4">{`${charTex} ${topic} disponibile`}</Typography>
          )}

        {topic === 'Antisismico' &&
          materialsData?.presenzaElementiStruttura === 'presenti' && (
            <>
              <Row style={{ alignItems: 'center' }}>
                <Stack>
                  <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                    {materialsData?.count || '0'}
                  </Typography>
                </Stack>
              </Row>
            </>
          )}

        {/* {materialsData?.presenza >= 1 || defect >= 1
          ? renderMaterialData(materialsData)
          : !materialsData?.haElementi && (
              <Typography variant="h4">{`${charTex} ${topic} disponibile`}</Typography>
            )} */}

        {/* {(materialsData?.presenza > 1 &&
          materialsData?.materials?.length === 0) ||
        materialsData?.materials?.length > 0 ? (
          renderMaterialData(materialsData)
        ) : materialsData?.presenza === 1 &&
          materialsData?.materials?.length === 0 ? (
          <Typography variant="h4">{`${charTex} ${topic} disponibile`}</Typography>
        ) : (
          <Typography variant="h4">{`${charTex} ${topic} disponibile`}</Typography>
        )} */}
      </BoxContainer>
    </div>
  );
};

export { MaterialBox };

MaterialBox.propTypes = {
  dataRecap: PropTypes.obj,
  topic: PropTypes.string,
  materialsData: PropTypes.obj,
  structureSection: PropTypes.bool,
  enumOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  defect: PropTypes.number,
  /*   materialsData: PropTypes.arrayOf(
    PropTypes.shape({
      nome: PropTypes.string,
      number: PropTypes.number,
    })
  ), */
};
