import styled from '@emotion/styled';

export const FormContainer = styled('div')({
  marginTop: '20px',
});

export const BtnContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 8,
  marginTop: 15,
});
