import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  InfoWrapper,
  MediaUpload,
  RHFCheckbox,
  RHFNumberTextField,
  RHFSelect,
  RHFTextField,
} from 'components';
import {
  AlternativeStradali,
  DisponibilitaStudiEffettiCarichiTraffico,
  DisponibilitaStudiTrasportistici,
  FieldType,
  TGMCommercialiVeicoliCorsiaApprossimativo,
  TGMCommercialiVeicoliCorsiaSingola,
  TGMCommercialiVeicoliGiorno,
  TGMCommercialiVeicoliGiornoValoreApprossimativo,
  TGMVeicoliGiorno,
  TGMVeicoliGiornoValoreApprossimativo,
  TrasportiEccezionali,
  UploadMedia,
  YesNo,
} from 'constants/inspections';

import {
  AlternativeStradaliOptions,
  CategoriaPonteOptions,
  ClassificazioneUsoStradaleOptions,
  CompetenzaInfastrutturaOptions,
  DisponibilitaStudiEffettiCarichiTrafficoOptions,
  DisponibilitaStudiTrasportisticiOptions,
  FrequentePassaggioPersoneOptions,
  ItinerarioEmergenzaOptions,
  ItinerarioInternazionaleOptions,
  PresenzaCurveOptions,
  ReteTENOptions,
  TGMCommercialiVeicoliCorsiaApprossimativoOptions,
  TGMCommercialiVeicoliCorsiaLimiteCaricoOptions,
  TGMCommercialiVeicoliCorsiaSingolaOptions,
  TGMCommercialiVeicoliGiornoOptions,
  TGMCommercialiVeicoliGiornoValoreApprossimativoOptions,
  TGMVeicoliGiornoOptions,
  TGMVeicoliGiornoValoreApprossimativoOptions,
  TrasportiEccezionaliOptions,
  TrasportoMerciPericoloseOptions,
} from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import {
  CheckboxContainer,
  GridWrap,
} from './Accordion8ConnectionAndRoadNetwork.styles';
import { INFO } from '../../../../config/info';

const Accordion8ConnectionAndRoadNetwork = () => {
  const { watch, getValues, setValue } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchTrasportiEccezionali = watch('trasportiEccezionali');
  const watchTrasportiEccezionaliSi =
    watchTrasportiEccezionali === TrasportiEccezionali.Si;

  const watchDisponibilitaStudiTrasportistici = watch(
    'disponibilitaStudiTrasportistici'
  );
  const watchDisponibilitaStudiTrasportisticiSi =
    watchDisponibilitaStudiTrasportistici ===
    DisponibilitaStudiTrasportistici.Si;

  const watchDisponibilitaStudiEffettiCarichiTraffico = watch(
    'disponibilitaStudiEffettiCarichiTraffico'
  );

  const watchPresenzaAlternativeStradali = watch('alternativeStradali');

  const watchDisponibilitaStudiEffettiCarichiTrafficoSi =
    watchDisponibilitaStudiEffettiCarichiTraffico ===
    DisponibilitaStudiEffettiCarichiTraffico.Si;

  const watchPresenzaAlternativeStradaliSi =
    watchPresenzaAlternativeStradali === AlternativeStradali.Si;

  const watchVeicoliGiornoTGM = watch('veicoliGiornoTGM');
  const watchVeicoliGiornoTGMValoreEffettivo =
    watchVeicoliGiornoTGM === TGMVeicoliGiorno.ValoreEffettivo;
  const watchVeicoliGiornoTGMValoreApprossimativo =
    watchVeicoliGiornoTGM === TGMVeicoliGiorno.ValoreApprossimativo;

  const watchVeicoliCommercialiGiornoTGM = watch('veicoliCommercialiGiornoTGM');
  const watchVeicoliCommercialiGiornoTGMValoreEffettivo =
    watchVeicoliCommercialiGiornoTGM ===
    TGMCommercialiVeicoliGiorno.ValoreEffettivo;
  const watchVeicoliCommercialiGiornoTGMValoreApprossimativo =
    watchVeicoliCommercialiGiornoTGM ===
    TGMCommercialiVeicoliGiorno.ValoreApprossimativo;

  const watchVeicoliCommercialITGMSingolaCorsia = watch(
    'veicoliCommercialITGMSingolaCorsia'
  );
  const watchVeicoliCommercialITGMSingolaCorsiaEffettivo =
    watchVeicoliCommercialITGMSingolaCorsia ===
    TGMCommercialiVeicoliCorsiaSingola.ValoreEffettivo;
  const watchVeicoliCommercialITGMSingolaCorsiaApprossimativo =
    watchVeicoliCommercialITGMSingolaCorsia ===
    TGMCommercialiVeicoliCorsiaSingola.ValoreApprossimativo;

  //CHECKBOX EFFETTIVO PRESUNTA
  const watchCategoriaPonteEffettiva = watch('catagoriaPonteEffettiva');
  const watchCategoriaPontePresunta = watch('categoriaPontePresunta');
  const watchCategoriaPonteAltro = watch('categoriaPonteAltro');

  useEffect(() => {
    watchCategoriaPonteEffettiva && setValue('categoriaPonteAltro', YesNo.SI);
    watchCategoriaPontePresunta && setValue('categoriaPonteAltro', YesNo.NO);
  }, [watchCategoriaPonteEffettiva, watchCategoriaPontePresunta]);

  /*  useEffect(() => {
    if (watchCategoriaPonteAltro && watchCategoriaPonteAltro === YesNo.SI) {
      setValue('catagoriaPonteEffettiva', true);
      setValue('categoriaPontePresunta', false);
    }
    if (watchCategoriaPonteAltro && watchCategoriaPonteAltro === YesNo.NO) {
      setValue('categoriaPontePresunta', true);
      setValue('catagoriaPonteEffettiva', false);
    }
  }, [watchCategoriaPonteAltro]); */

  //END - CHECKBOX EFFETTIVO PRESUNTA

  //Functions
  useEffect(() => {
    if (!watchDisponibilitaStudiEffettiCarichiTrafficoSi) {
      setValue('valutazioneEffettiCarichiTraffico', '');
      setValue('documentazioneEffettiCarichiDaTraffico', []);
    }
  }, [watchDisponibilitaStudiEffettiCarichiTraffico]);

  useEffect(() => {
    if (!watchPresenzaAlternativeStradaliSi) {
      setValue('durataDeviazioneKm', '');
      setValue('durataDeviazioneMin', '');
      setValue('categoriaPercorsoAlternativoIndividuato', '');
    }
  }, [watchPresenzaAlternativeStradaliSi]);

  useEffect(() => {
    if (!watchVeicoliGiornoTGMValoreEffettivo) {
      setValue('valoreEffettivoTGM', '');
    } else {
      setValue('valoreApprossimativoTGM', '');
    }

    const valoreEffettivoTGM = getValues('valoreEffettivoTGM');

    if (valoreEffettivoTGM) {
      if (Number(valoreEffettivoTGM) >= 25000) {
        setValue(
          'valoreApprossimativoTGM',
          TGMVeicoliGiornoValoreApprossimativo.VeicoliSopra25000
        );
      }
      if (
        Number(valoreEffettivoTGM) < 25000 &&
        Number(valoreEffettivoTGM) > 10000
      ) {
        setValue(
          'valoreApprossimativoTGM',
          TGMVeicoliGiornoValoreApprossimativo.VeicoliTra10000e25000
        );
      }
      if (
        Number(valoreEffettivoTGM) <= 10000 &&
        Number(valoreEffettivoTGM) !== null
      ) {
        setValue(
          'valoreApprossimativoTGM',
          TGMVeicoliGiornoValoreApprossimativo.VeicoliSotto10000
        );
      }
    }
    if (!watchVeicoliGiornoTGM) {
      setValue('valoreEffettivoTGM', '');
      setValue('valoreApprossimativoTGM', '');
    }
  }, [watchVeicoliGiornoTGM, watch('valoreEffettivoTGM')]);

  useEffect(() => {
    if (!watchVeicoliCommercialiGiornoTGMValoreEffettivo) {
      setValue('valoreEffettivoCommercialiTGM', '');
    } else {
      setValue('valoreApprossimativoCommercialiTGM', '');
    }

    const valoreEffettivoCarreggiata = getValues(
      'valoreEffettivoCommercialiTGM'
    );

    if (valoreEffettivoCarreggiata) {
      if (Number(valoreEffettivoCarreggiata) >= 700) {
        setValue(
          'valoreApprossimativoCommercialiTGM',
          TGMCommercialiVeicoliGiornoValoreApprossimativo.VeicoliSopra700
        );
      }
      if (
        Number(valoreEffettivoCarreggiata) < 700 &&
        Number(valoreEffettivoCarreggiata) > 300
      ) {
        setValue(
          'valoreApprossimativoCommercialiTGM',
          TGMCommercialiVeicoliGiornoValoreApprossimativo.VeicoliTra300e700
        );
      }
      if (
        Number(valoreEffettivoCarreggiata) <= 300 &&
        Number(valoreEffettivoCarreggiata) !== null
      ) {
        setValue(
          'valoreApprossimativoCommercialiTGM',
          TGMCommercialiVeicoliGiornoValoreApprossimativo.VeicoliSotto300
        );
      }
    }
    if (!watchVeicoliCommercialiGiornoTGM) {
      setValue('valoreEffettivoCommercialiTGM', '');
      setValue('valoreApprossimativoCommercialiTGM', '');
    }
  }, [
    watchVeicoliCommercialiGiornoTGM,
    watch('valoreEffettivoCommercialiTGM'),
  ]);

  useEffect(() => {
    !watchVeicoliCommercialITGMSingolaCorsiaEffettivo
      ? setValue('valoreEffettivoCommercialiCorsiaTGM', '')
      : setValue('valoreApprossimativoCommercialiCorsiaTGM', '');

    const valoreEffettivoCorsia = getValues(
      'valoreEffettivoCommercialiCorsiaTGM'
    );

    if (valoreEffettivoCorsia) {
      if (Number(valoreEffettivoCorsia) >= 700) {
        setValue(
          'valoreApprossimativoCommercialiCorsiaTGM',
          TGMCommercialiVeicoliCorsiaApprossimativo.VeicoliSopra700
        );
      }
      if (
        Number(valoreEffettivoCorsia) < 700 &&
        Number(valoreEffettivoCorsia) > 300
      ) {
        setValue(
          'valoreApprossimativoCommercialiCorsiaTGM',
          TGMCommercialiVeicoliCorsiaApprossimativo.VeicoliTra300e700
        );
      }
      if (
        Number(valoreEffettivoCorsia) <= 300 &&
        Number(valoreEffettivoCorsia) !== null
      ) {
        setValue(
          'valoreApprossimativoCommercialiCorsiaTGM',
          TGMCommercialiVeicoliCorsiaApprossimativo.VeicoliSotto300
        );
      }
    }
    if (!watchVeicoliCommercialITGMSingolaCorsia) {
      setValue('valoreEffettivoCommercialiCorsiaTGM', '');
      setValue('valoreApprossimativoCommercialiCorsiaTGM', '');
    }
  }, [
    watchVeicoliCommercialITGMSingolaCorsia,
    watch('valoreEffettivoCommercialiCorsiaTGM'),
  ]);

  return (
    <GridWrap container spacing={2} padding="20px" marginTop={-40}>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.classificazioneUsoStradale.drawerTitle}
        drawerText={INFO.classificazioneUsoStradale.drawerText}
        legislationMessage={INFO.classificazioneUsoStradale.legislationMessage}
      >
        <RHFSelect
          name="classificazioneUsoStradale"
          label={"Classificazione d'uso stradale*"}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={ClassificazioneUsoStradaleOptions}
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="competenzaInfrastruttura"
          label={'Competenza infrastruttura*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={CompetenzaInfastrutturaOptions}
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoMessage={INFO.categoriaPonte.infoMessage}
        legislationMessage={INFO.categoriaPonte.legislationMessage}
      >
        <RHFSelect
          name="categoriaPonte"
          label={'Categoria ponte*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={CategoriaPonteOptions}
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <CheckboxContainer>
          <RHFCheckbox
            // yes this is mispelled, but do not change
            // since it's used in every inspections now
            name="catagoriaPonteEffettiva"
            label="Effettiva"
            disabled={
              watchCategoriaPontePresunta ||
              inspectionDisabled ||
              isViewingInspection
            }
            defaultValue={watchCategoriaPonteAltro === YesNo.SI}
          />
          <RHFCheckbox
            name="categoriaPontePresunta"
            label="Presunta"
            disabled={
              watchCategoriaPonteEffettiva ||
              inspectionDisabled ||
              isViewingInspection
            }
            defaultValue={watchCategoriaPonteAltro === YesNo.NO}
          />
        </CheckboxContainer>
      </GridWrap>

      <GridWrap item xs={3}>
        <RHFSelect
          name="itinerarioInternazionale"
          label={'Itinerario internazionale'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={ItinerarioInternazionaleOptions}
        />
      </GridWrap>

      <InfoWrapper size={3} infoMessage={INFO.reteTEN.infoMessage}>
        <RHFSelect
          name="reteTEN"
          label={'Rete TEN'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={ReteTENOptions}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.itinerarioEmergenza.drawerTitle}
        drawerText={INFO.itinerarioEmergenza.drawerText}
        legislationMessage={INFO.itinerarioEmergenza.legislationMessage}
      >
        <RHFSelect
          name="itinerarioEmergenza"
          label={'Opera strategica*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={ItinerarioEmergenzaOptions}
        />
      </InfoWrapper>
      <GridWrap item xs={3} />
      <InfoWrapper
        size={3}
        infoMessage={INFO.trasportiEccezionali.infoMessage}
        legislationMessage={INFO.trasportiEccezionali.legislationMessage}
      >
        <RHFSelect
          name="trasportiEccezionali"
          label={'Trasporti eccezionali*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={TrasportiEccezionaliOptions}
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <MediaUpload
          disabled={!watchTrasportiEccezionaliSi}
          title={`Richieste permessi/trasporti eccezionali`}
          btnTitle="Allega file"
          dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
          formName="richiestePermessiTrasportiEccezionali"
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFSelect
          name="disponibilitaStudiTrasportistici"
          label={'Disponibilità di studi trasportistici specifici'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={DisponibilitaStudiTrasportisticiOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <MediaUpload
          disabled={!watchDisponibilitaStudiTrasportisticiSi}
          title={`Documentazione studi trasportistici`}
          btnTitle="Allega file"
          dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
          formName="documentazioneStudiTrasportistici"
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoMessage={INFO.disponibilitaStudiEffettiCarichiTraffico.infoMessage}
      >
        <RHFSelect
          name="disponibilitaStudiEffettiCarichiTraffico"
          label={'Disp.tà studi effetti carichi da traffico'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={DisponibilitaStudiEffettiCarichiTrafficoOptions}
        />
      </InfoWrapper>
      {/*   <GridWrap item xs={3}>
        <RHFSelect
          name="valutazioneEffettiCarichiTraffico"
          label={'Valutazione degli effetti carichi da traffico*'}
          disabled={!watchDisponibilitaStudiEffettiCarichiTrafficoSi}
          defaultValue={''}
          selectOptions={ValutazioneEffettiCarichiTrafficoOptions}
        />
      </GridWrap> */}
      <GridWrap item xs={3}>
        <MediaUpload
          disabled={!watchDisponibilitaStudiEffettiCarichiTrafficoSi}
          title={`Documentazione sugli effetti carichi da traffico`}
          btnTitle="Allega file"
          dataType=".pdf,.doc,.docx,.jpeg,.png"
          formName="documentazioneEffettiCarichiDaTraffico"
          uploadType={UploadMedia.documents}
          type={FieldType.documents}
        />
      </GridWrap>
      <GridWrap item xs={6} />
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="nCarreggiate"
          label={'N° di carreggiate*'}
          decimalScale={0}
          disabled={inspectionDisabled || isViewingInspection}
          withValueLimit={({ value }) => value <= 99999}
          defaultValue={''}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="nCorsieCarreggiata"
          label={'N° corsie/carreggiata*'}
          decimalScale={0}
          disabled={inspectionDisabled || isViewingInspection}
          withValueLimit={({ value }) => value <= 99999}
          defaultValue={''}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="larghezzaCarreggiata"
          label={'Larghezza carreggiata(m)*'}
          decimalScale={2}
          disabled={inspectionDisabled || isViewingInspection}
          withValueLimit={({ value }) => value <= 9999999}
          defaultValue={''}
        />
      </GridWrap>

      <GridWrap item xs={3}>
        <RHFSelect
          name="presenzaCurve"
          label={'Presenza di curve'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={PresenzaCurveOptions}
        />
      </GridWrap>
      <InfoWrapper size={3} infoMessage={INFO.TGMVeicoliGiorno.infoMessage}>
        <RHFSelect
          name="veicoliGiornoTGM"
          label={'TGM (veicoli/giorno) (carreggiata)*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={TGMVeicoliGiornoOptions}
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="valoreEffettivoTGM"
          label={'Valore effettivo*'}
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999999}
          defaultValue={''}
          disabled={
            !watchVeicoliGiornoTGMValoreEffettivo ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </GridWrap>

      <GridWrap item xs={3}>
        <RHFSelect
          name="valoreApprossimativoTGM"
          label={'Valore approssimativo*'}
          disabled={
            !watchVeicoliGiornoTGMValoreApprossimativo ||
            inspectionDisabled ||
            isViewingInspection
          }
          defaultValue={''}
          selectOptions={TGMVeicoliGiornoValoreApprossimativoOptions}
        />
      </GridWrap>
      <GridWrap item xs={3} />
      <InfoWrapper
        size={3}
        infoMessage={INFO.TGMCommercialiVeicoliGiorno.infoMessage}
      >
        <RHFSelect
          name="veicoliCommercialiGiornoTGM"
          label={'TGM - Commerciali (veicoli/giorno) (carreggiata)'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={TGMCommercialiVeicoliGiornoOptions}
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="valoreEffettivoCommercialiTGM"
          label={'Valore effettivo'}
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999999}
          defaultValue={''}
          disabled={
            !watchVeicoliCommercialiGiornoTGMValoreEffettivo ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </GridWrap>

      <GridWrap item xs={3}>
        <RHFSelect
          name="valoreApprossimativoCommercialiTGM"
          label={'Valore approssimativo'}
          disabled={
            !watchVeicoliCommercialiGiornoTGMValoreApprossimativo ||
            inspectionDisabled ||
            isViewingInspection
          }
          defaultValue={''}
          selectOptions={TGMCommercialiVeicoliGiornoValoreApprossimativoOptions}
        />
      </GridWrap>

      <GridWrap item xs={3} />

      <InfoWrapper
        size={3}
        infoDrawer={true}
        drawerTitle={INFO.TGMCommercialiVeicoliGiornoCorsiaSingola.drawerTitle}
        drawerText={INFO.TGMCommercialiVeicoliGiornoCorsiaSingola.drawerText}
      >
        <RHFSelect
          name="veicoliCommercialITGMSingolaCorsia"
          label={'TGM - Commerciali (veicoli/giorno) (sing.corsia)*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={TGMCommercialiVeicoliCorsiaSingolaOptions}
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="valoreEffettivoCommercialiCorsiaTGM"
          label={'Valore effettivo*'}
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999999}
          defaultValue={''}
          disabled={
            !watchVeicoliCommercialITGMSingolaCorsiaEffettivo ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </GridWrap>

      <GridWrap item xs={3}>
        <RHFSelect
          name="valoreApprossimativoCommercialiCorsiaTGM"
          label={'Valore approssimativo*'}
          disabled={
            !watchVeicoliCommercialITGMSingolaCorsiaApprossimativo ||
            inspectionDisabled ||
            isViewingInspection
          }
          defaultValue={''}
          selectOptions={TGMCommercialiVeicoliCorsiaApprossimativoOptions}
        />
      </GridWrap>
      <GridWrap item xs={3} />
      <GridWrap item xs={3}>
        <RHFSelect
          name="limiteCaricoCarreggiataSingolaCorsia"
          label={'Limite di carico*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={TGMCommercialiVeicoliCorsiaLimiteCaricoOptions}
        />
      </GridWrap>

      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="valoreLimitazione"
          label={'Valore limitazione (t)'}
          decimalScale={1}
          withValueLimit={({ value }) => value <= 999}
          disabled={inspectionDisabled || isViewingInspection}
          defaultValue={''}
        />
      </GridWrap>

      <InfoWrapper
        size={3}
        infoMessage={INFO.frequentePassaggioPersone.infoMessage}
      >
        <RHFSelect
          name="frequentePassaggioPersone"
          label={'Frequente passaggio persone*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={FrequentePassaggioPersoneOptions}
        />
      </InfoWrapper>

      <InfoWrapper
        size={3}
        infoMessage={INFO.trasportoMerciPericolose.infoMessage}
      >
        <RHFSelect
          name="trasportoMerciPericolose"
          label={'Trasporto merci pericolose*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={TrasportoMerciPericoloseOptions}
        />
      </InfoWrapper>
      <InfoWrapper
        size={3}
        infoMessage={INFO.alternativeStradali.infoMessage}
        legislationMessage={INFO.alternativeStradali.legislationMessage}
      >
        <RHFSelect
          name="alternativeStradali"
          label={'Alternative stradali*'}
          defaultValue={''}
          disabled={inspectionDisabled || isViewingInspection}
          selectOptions={AlternativeStradaliOptions}
        />
      </InfoWrapper>
      <InfoWrapper size={3} infoMessage={INFO.durataDeviazioneMin.infoMessage}>
        <RHFNumberTextField
          name="durataDeviazioneKm"
          label={'Durata deviazione (km)'}
          decimalScale={2}
          withValueLimit={({ value }) => value <= 99999999}
          defaultValue={''}
          disabled={
            !watchPresenzaAlternativeStradaliSi ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </InfoWrapper>
      <InfoWrapper size={3} infoMessage={INFO.durataDeviazioneKm.infoMessage}>
        <RHFNumberTextField
          name="durataDeviazioneMin"
          label={'Durata deviazione (min)'}
          decimalScale={0}
          withValueLimit={({ value }) => value <= 9999}
          defaultValue={''}
          disabled={
            !watchPresenzaAlternativeStradaliSi ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </InfoWrapper>

      <GridWrap item xs={3}>
        <RHFTextField
          name="categoriaPercorsoAlternativoIndividuato"
          label={'Categoria del percorso alternativo individuato'}
          inputProps={{ maxLength: 150 }}
          defaultValue=""
          disabled={
            !watchPresenzaAlternativeStradaliSi ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </GridWrap>
    </GridWrap>
  );
};

export { Accordion8ConnectionAndRoadNetwork };
