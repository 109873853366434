import { UserStatus, UserType, UserTypology } from 'constants/users';

export const usersFilter = [
  {
    id: 1,
    filterType: 'Select',
    name: 'type',
    label: 'Tipo utente',
    options: [
      { value: UserType.Admin, label: 'Admin' },
      { value: UserType.Ente, label: 'Ente' },
      { value: UserType.Professionista, label: 'Ispettore' },
    ],
  },
  {
    id: 2,
    filterType: 'Select',
    name: 'userTypology',
    label: 'Tipologia utente',
    options: [
      { value: UserTypology.UtentePrincipale, label: 'Utente Principale' },
      {
        value: UserTypology.CollaboratoreInterno,
        label: 'Collaboratore Interno',
      },
    ],
  },
  {
    id: 3,
    filterType: 'TextField',
    name: 'displayName',
    label: 'Nome cognome',
  },
  {
    id: 4,
    filterType: 'TextField',
    name: 'email',
    label: 'Email',
  },
  {
    id: 5,
    filterType: 'Select',
    name: 'status',
    label: 'Stato',
    options: [
      { value: UserStatus.Invitato, label: 'Invitato' },
      { value: UserStatus.Registrato, label: 'Registrato' },
      /* { value: UserStatus.Attivo, label: 'Attivo' }, */
    ],
  },
];
