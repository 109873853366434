import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Tour from 'reactour';
import lightbulb from 'assets/icons/lightbulb.svg';
import { colors } from 'constants/colors';
import { useTourSelector } from 'stores';

import './tour.css';

const TourWrapper = ({ steps }) => {
  const { tourIsOpen, setTourIsOpen } = useTourSelector();
  const hasOnlyOneStep = steps.length === 1;
  return (
    <Tour
      className={`${hasOnlyOneStep && 'hide-arrow'} tour-main-container`}
      steps={steps}
      isOpen={tourIsOpen}
      showNavigation={!hasOnlyOneStep}
      showNumber={false}
      rounded={10}
      accentColor={colors.ORANGE}
      onAfterOpen={(/* target */) => (document.body.style.overflowY = 'hidden')}
      onRequestClose={() => {
        setTourIsOpen(false);
        document.body.style.overflowY = 'unset';
      }}
    />
  );
};

TourWrapper.propTypes = {
  steps: PropTypes.object,
};

const LocalTourWrapper = ({ steps, rounded, style }) => {
  const [openLocalTour, setOpenLocalTour] = useState(false);

  const localStyle = rounded
    ? {
        border: '1px solid',
        marginRight: 'auto',
        padding: '12px',
        height: '20px',
        borderRadius: '50%',
        width: '20px',
        minWidth: 'unset',
      }
    : { border: '1px solid', marginRight: 'auto' };
  return (
    <>
      <Button
        onClick={() => setOpenLocalTour(true)}
        variant="secondary"
        style={{ ...localStyle, ...style }}
      >
        <img style={{ width: '14px' }} src={lightbulb} />
      </Button>
      <Tour
        steps={steps}
        showNumber={false}
        isOpen={openLocalTour}
        rounded={10}
        accentColor={colors.ORANGE}
        onAfterOpen={
          (/* target */) => (document.body.style.overflowY = 'hidden')
        }
        onRequestClose={() => {
          setOpenLocalTour(false);
          document.body.style.overflowY = 'unset';
        }}
      />
    </>
  );
};

LocalTourWrapper.propTypes = {
  steps: PropTypes.object,
  rounded: PropTypes.bool,
  style: PropTypes.object,
};
export { TourWrapper, LocalTourWrapper };
