import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// eslint-disable-next-line
import useFetch from 'use-http';
import { apiNotifications } from 'constants/api';
import { useGlobalSelector } from 'stores';

const useNotifications = ({ startPage, startPageSize }) => {
  const { showLoader, hideLoader } = useGlobalSelector();

  const [notifications, setNotifications] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(startPage || 1);
  const [pageSize, setPageSize] = useState(startPageSize || 3);
  const { get, put } = useFetch();

  const handleNotifications = async () => {
    try {
      const res = await get(
        `${apiNotifications.ROOT}?page=${page}&pageSize=${pageSize}`
      );
      if (res) {
        setNotifications(res?.items);
        setTotalCount(res?.count);
      }
    } catch (err) {
      console.log('ERROR NOTIFICATIONS', err);
    }
  };
  const handleReadNotification = async (id, readState) => {
    try {
      showLoader();
      await put(`${apiNotifications.ROOT}/${id}${apiNotifications.READ}`, {
        read: readState,
      });
      handleNotifications();
    } catch (err) {
      console.log('ERROR READ NOTIFICATIONS', err);
    } finally {
      hideLoader();
    }
  };
  const handleReadAllNotification = async () => {
    try {
      showLoader();
      await put(`${apiNotifications.ROOT}${apiNotifications.READALL}`, {
        read: true,
      });
      handleNotifications();
      window.location.reload();
    } catch (err) {
      console.log('ERROR READ ALL NOTIFICATIONS', err);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    handleNotifications();
  }, [page, pageSize]);

  return {
    notifications,
    totalCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    handleNotifications,
    handleReadNotification,
    handleReadAllNotification,
  };
};
useNotifications.defaultProps = {
  startPage: 1,
  startPageSize: 3,
};
useNotifications.propTypes = {
  startPage: PropTypes.number,
  startPageSize: PropTypes.number,
};

export { useNotifications };
