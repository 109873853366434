import {
  Avatar,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// eslint-disable-next-line
import useFetch from 'use-http';
import close from 'assets/icons/close.svg';
import { apiCollaborators } from 'constants/api';
import { randomColor } from 'constants/colors';
import { PermissionType } from 'constants/permissions';
import { SubscriptionStatus } from 'constants/subscription';
import { UserType } from 'constants/users';
import { useSubscription } from 'hooks';
import { useGlobalSelector } from 'stores';
import {
  compareObjectsPropertiesAlphabetically,
  formatData,
} from 'utils/utilities';
import { Box, Box1, Container } from './PermissionsPopover.style';
import MainForm from './SharingForm/MainForm';

const PermissionContainerSearchEmailTitleMap = {
  [apiCollaborators.INSPECTIONS_COLLBAORATORS]:
    "Digita l'indirizzo email associato all'utente a cui vuoi rendere modificabile l'attività",
  [apiCollaborators.STRUCTURE_COLLBAORATORS]:
    "Digita l'indirizzo email associato all'utente con cui vuoi condividere l'opera",
};

const sharedKeyMap = {
  [apiCollaborators.STRUCTURE_COLLBAORATORS]: 'sharedStructures',
  [apiCollaborators.INSPECTIONS_COLLBAORATORS]: 'sharedInspections',
};
const sharedSubKeyMap = {
  [apiCollaborators.STRUCTURE_COLLBAORATORS]: 'structureId',
  [apiCollaborators.INSPECTIONS_COLLBAORATORS]: 'inspectionId',
};
const PermissionTypeMap = {
  [PermissionType.CanView]: 'Può visualizzare',
  [PermissionType.CanEdit]: 'Può modificare',
};

const PermissionRouteMap = {
  1: 'internal',
  2: 'external',
};

//eslint-disable-next-line
const RenderSelfOrParentRow = ({ user, item, label }) => {
  return (
    <Container>
      <Box1 style={{ flex: 2, overflow: 'hidden' }}>
        <Avatar
          variant="circular"
          style={{
            backgroundColor: item?.bg,
            border: `1px solid ${item?.border}`,
            color: item?.border,
          }}
        >
          {label}
        </Avatar>
        <Box>
          <Typography variant="h3Bold" sx={{ marginLeft: 2 }}>
            {`${user?.name} ${user?.surname}`} {' (tu)'}
          </Typography>
          <Tooltip title={user?.email}>
            <Typography variant="h3" sx={{ marginLeft: 2 }}>
              {user?.email}
            </Typography>
          </Tooltip>
        </Box>
      </Box1>
      <Box1 style={{ flex: 1.5 }}>
        <Box>
          <Typography variant="h3Bold" sx={{ marginLeft: 2 }}>
            Scadenza Accesso
          </Typography>
          <Typography variant="h3" sx={{ marginLeft: 2 }}>
            {user?.collaborationInfo?.accessExpiration
              ? formatData(user?.collaborationInfo?.accessExpiration)
              : 'Nessuna'}
          </Typography>
        </Box>
      </Box1>
      <Box1 style={{ flex: 1 }}>
        <Box>
          <Typography variant="h3Bold" sx={{ marginLeft: 2 }}>
            {PermissionTypeMap[user?.collaborationInfo?.permission]}
          </Typography>
        </Box>
      </Box1>
    </Container>
  );
};

RenderSelfOrParentRow.propTypes = {
  label: PropTypes.string,
  user: PropTypes.object,
  item: PropTypes.object,
};

const PermissionContainer = ({
  sharedWith,
  handleShare,
  canShare = false,
  isOwner = false,
  shareRoute,
  entityId,
  shareOptions,
  accessExpiration,
}) => {
  const payloadSchema = {
    collaboratorId: '',
    message: '',
    [sharedKeyMap[shareRoute]]: [
      {
        accessExpiration: null,
        [sharedSubKeyMap[shareRoute]]: parseInt(entityId),
        permission: '',
      },
    ],
  };

  const { subscriptionStatus, subscription, user } = useSubscription();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { enqueueSnackbar } = useSnackbar();
  const [display, setDisplay] = useState(true);

  const { put } = useFetch();

  const handleChangePermission = async (
    collaboratorId,
    permission,
    accessExpiration,
    type
  ) => {
    const parsedAccessExpiration = accessExpiration
      ? format(new Date(accessExpiration), 'yyyy-MM-dd')
      : null;
    const payload = { ...payloadSchema };
    payload.collaboratorId = collaboratorId;
    payload[sharedKeyMap[shareRoute]][0].permission = permission;
    payload[sharedKeyMap[shareRoute]][0].accessExpiration =
      parsedAccessExpiration;

    try {
      showLoader();
      await put(`${shareRoute}/${PermissionRouteMap[type]}`, payload);
      enqueueSnackbar('Utente modificato con successo', {
        variant: 'success',
      });
    } catch (err) {
      console.log('ERROR');
      enqueueSnackbar('Errore durante la richiesta', {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }
    handleShare();
  };
  const onKeyDown = e => {
    e.preventDefault();
  };

  const renderPermissions = () => {
    return sharedWith
      ?.sort(compareObjectsPropertiesAlphabetically)
      ?.reduce((acc, element) => {
        if (element?.id === user?.id || element?.id === user?.parent?.id) {
          return [element, ...acc];
        }
        return [...acc, element];
      }, [])
      .filter(
        el =>
          el?.collaborationInfo?.permission !==
          PermissionType.CanViewByInspectionReference
      )
      .map((el, index) => {
        const label = `${el?.name?.charAt(0)?.toUpperCase() || ''}${
          el?.surname?.charAt(0)?.toUpperCase() || ''
        }`;

        var item = randomColor[index];

        let remappedShareOptions = shareOptions;

        if (
          user.type === UserType.Professionista &&
          el?.type === UserType.Ente
        ) {
          remappedShareOptions = remappedShareOptions.filter(y => {
            return y.value !== PermissionType.CanView;
          });
        }

        if (el.id === user?.id || el.id === user?.parent?.id) {
          return (
            <RenderSelfOrParentRow
              key={index}
              user={el}
              item={item}
              label={label}
            />
          );
        }
        return (
          <Container key={index}>
            <Box1 style={{ flex: 2, overflow: 'hidden' }}>
              <Avatar
                variant="circular"
                style={{
                  backgroundColor: item?.bg,
                  border: `1px solid ${item?.border}`,
                  color: item?.border,
                }}
              >
                {label}
              </Avatar>
              <Box>
                <Typography variant="h3Bold" sx={{ marginLeft: 2 }}>
                  {`${el?.name} ${el?.surname}`}
                </Typography>
                <Tooltip title={el?.email}>
                  <Typography variant="h3" sx={{ marginLeft: 2 }}>
                    {el?.email}
                  </Typography>
                </Tooltip>
              </Box>
            </Box1>
            <Box1 style={{ flex: 1.5 }}>
              {(!canShare || el?.isInviter) && (
                <Box>
                  <Typography variant="h3Bold" sx={{ marginLeft: 2 }}>
                    Scadenza Accesso
                  </Typography>
                  <Typography variant="h3" sx={{ marginLeft: 2 }}>
                    {el?.collaborationInfo?.accessExpiration
                      ? formatData(el?.collaborationInfo?.accessExpiration)
                      : 'Nessuna'}
                  </Typography>
                </Box>
              )}
              {canShare && !el?.isInviter && (
                <Box>
                  {(!isOwner ||
                    !subscriptionStatus ||
                    subscriptionStatus === SubscriptionStatus.ND ||
                    subscriptionStatus === SubscriptionStatus.SCADUTA ||
                    (user.type === UserType.Professionista &&
                      el?.type === UserType.Ente)) && (
                    <>
                      <Typography variant="h3Bold" sx={{ marginLeft: 2 }}>
                        Scadenza Accesso
                      </Typography>
                      <Typography variant="h3" sx={{ marginLeft: 2 }}>
                        {el?.collaborationInfo?.accessExpiration
                          ? formatData(el?.collaborationInfo?.accessExpiration)
                          : 'Nessuna'}
                      </Typography>
                    </>
                  )}
                  {
                    /*  isOwner && */
                    subscriptionStatus &&
                      subscriptionStatus === SubscriptionStatus.ATTIVA &&
                      (user?.type === UserType.Ente ||
                        (user?.type === UserType.Professionista &&
                          el?.type !== UserType.Ente)) && (
                        <MuiDatePicker
                          /* inputFormat="dd/MM/yyyy" */
                          disableHighlightToday
                          label={'Scadenza Accesso'}
                          onKeyDown={onKeyDown}
                          minDate={new Date()}
                          maxDate={new Date(subscription?.expirationDate)}
                          onChange={
                            // newValue => field.onChange(newValue)
                            newValue => {
                              handleChangePermission(
                                el.id,
                                el?.collaborationInfo?.permission,
                                newValue,
                                el?.collaborationInfo?.type
                              );
                            }
                          }
                          value={
                            el?.collaborationInfo?.accessExpiration
                              ? new Date(
                                  el?.collaborationInfo?.accessExpiration
                                )
                              : null
                          }
                          renderInput={params => {
                            return (
                              <TextField
                                {...params}
                                onKeyDown={onKeyDown}
                                variant="standard"
                                size={'small'}
                                fullWidth
                                error={false}
                                InputProps={
                                  el?.collaborationInfo?.accessExpiration
                                    ? {
                                        endAdornment: (
                                          <InputAdornment
                                            sx={{
                                              '&:hover': { cursor: 'pointer' },
                                            }}
                                            position="end"
                                          >
                                            <img
                                              onClick={() => {
                                                handleChangePermission(
                                                  el.id,
                                                  el?.collaborationInfo
                                                    ?.permission,
                                                  null,
                                                  el?.collaborationInfo?.type
                                                );
                                              }}
                                              src={close}
                                              width={15}
                                              alt="close icon"
                                            />
                                          </InputAdornment>
                                        ),
                                      }
                                    : {
                                        endAdornment:
                                          params.InputProps.endAdornment,
                                      }
                                }
                              />
                            );
                          }}
                        />
                      )
                  }
                </Box>
              )}
            </Box1>
            <Box1 style={{ flex: 1 }}>
              {(!canShare || el?.isInviter) && (
                <Box>
                  <Typography variant="h3Bold" sx={{ marginLeft: 2 }}>
                    {PermissionTypeMap[el?.collaborationInfo?.permission]}
                  </Typography>
                </Box>
              )}
              {canShare && !el?.isInviter && (
                <Box>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Permesso
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      label="Permesso"
                      onChange={(e, v) => {
                        handleChangePermission(
                          el.id,
                          v.props.value,
                          el?.collaborationInfo?.accessExpiration,
                          el?.collaborationInfo?.type
                        );
                      }}
                      value={el?.collaborationInfo?.permission}
                    >
                      {remappedShareOptions.map(x => {
                        return (
                          <MenuItem key={x.value} value={x.value}>
                            {x.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              )}
              {/* <SettingsPermissions
              handleClose={handleClosePerm}
              open={openPerm}
              id={idPerm}
              anchorEl={anchorElPerm}
              collaboratorId={el.id}
              handleChangePermission={handleChangePermission}
            /> */}
            </Box1>
          </Container>
        );
      });
  };

  return (
    <>
      {display && (
        <>
          {canShare && (
            <TextField
              sx={{ marginTop: 2, marginBottom: 2 }}
              label={PermissionContainerSearchEmailTitleMap[shareRoute]}
              onFocus={() => setDisplay(!display)}
            />
          )}
          {renderPermissions()}
        </>
      )}
      {!display && (
        <MainForm
          handleShare={handleShare}
          setDisplay={setDisplay}
          shareRoute={shareRoute}
          entityId={entityId}
          isOwner={isOwner}
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
          shareOptions={shareOptions}
          accessExpiration={accessExpiration}
        />
      )}
    </>
  );
};

PermissionContainer.propTypes = {
  id: PropTypes.string,
  sharedWith: PropTypes.array,
  shareOptions: PropTypes.array,
  handleShare: PropTypes.func,
  canShare: PropTypes.bool,
  isOwner: PropTypes.bool,
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shareRoute: PropTypes.oneOf([
    apiCollaborators.INSPECTIONS_COLLBAORATORS,
    apiCollaborators.STRUCTURE_COLLBAORATORS,
  ]),
  accessExpiration: PropTypes.string,
};
export default PermissionContainer;
