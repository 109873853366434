import { Divider } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  InfoWrapper,
  MediaUpload,
  RHFMultiSelect,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import {
  ElementiCriticiVulnerabilitaSismica,
  FieldType,
  MediaGroup,
  UploadMedia,
} from 'constants/inspections';
import { ElCriticiSismicaStatoDegradoOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { INFO } from '../../../../../config/info';
import { GridWrap } from '../Accordion5CriticalAspectsL1.styles';

const ElCriticiVulnerabilitaSismica = () => {
  const { watch, control, setValue } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchelEmentiCriticiVulnerabilitaSismica = watch(
    'elementiCriticiVulnerabilitaSismica'
  );

  const watchelEmentiCriticiVulnerabilitaSismicaPresenti =
    watchelEmentiCriticiVulnerabilitaSismica ===
    ElementiCriticiVulnerabilitaSismica.Presenti;

  const watchElCriticiVulnerabilitaSismicaStatoDegrado = watch(
    'elCriticiVulnerabilitaSismicaStatoDegrado'
  );

  const { remove: removeSismicaSelected } = useFieldArray({
    control,
    name: 'elCriticiVulnerabilitaSismicaStatoDegrado',
  });

  useEffect(() => {
    if (watchelEmentiCriticiVulnerabilitaSismicaPresenti) {
      if (
        watchElCriticiVulnerabilitaSismicaStatoDegrado?.length &&
        watchElCriticiVulnerabilitaSismicaStatoDegrado?.includes(3)
      ) {
        if (watchElCriticiVulnerabilitaSismicaStatoDegrado?.length > 1) {
          watchElCriticiVulnerabilitaSismicaStatoDegrado.sort();
          removeSismicaSelected(0);
        }
      }
    }

    if (
      !watchelEmentiCriticiVulnerabilitaSismicaPresenti &&
      watchElCriticiVulnerabilitaSismicaStatoDegrado?.length
    ) {
      removeSismicaSelected();
    }
  }, [
    watchElCriticiVulnerabilitaSismicaStatoDegrado,
    watchelEmentiCriticiVulnerabilitaSismica,
  ]);

  useEffect(() => {
    if (!watchelEmentiCriticiVulnerabilitaSismicaPresenti) {
      setValue('elCriticiVulnerabilitaSismicaTipologiaElemento', '');
      setValue('elCriticiVulnerabilitaSismicaDescrizioneSintetica', '');
    }
  }, [watchelEmentiCriticiVulnerabilitaSismica]);

  return (
    <GridWrap container spacing={2} padding="20px" marginTop={-40}>
      <InfoWrapper
        isDivider={true}
        size={12}
        compassMessage={INFO.elementiCriticiVulnerabilitaSismica.compassMessage}
        infoDrawer={true}
        drawerTitle={INFO.elementiCriticiVulnerabilitaSismica.drawerTitle}
        drawerText={INFO.elementiCriticiVulnerabilitaSismica.drawerText}
      >
        <Divider>Elementi critici - vulnerabilità sismica</Divider>
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="elementiCriticiVulnerabilitaSismica"
          label={'Elementi critici*'}
          defaultValue={''}
          selectOptions={[
            {
              label: 'Presenti',
              value: ElementiCriticiVulnerabilitaSismica.Presenti,
            },
            {
              label: 'Assenti',
              value: ElementiCriticiVulnerabilitaSismica.Assenti,
            },
          ]}
          disabled
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFMultiSelect
          name="elCriticiVulnerabilitaSismicaStatoDegrado"
          label={'Stato di degrado'}
          defaultValue={[]}
          selectOptions={ElCriticiSismicaStatoDegradoOptions}
          disabled={
            !watchelEmentiCriticiVulnerabilitaSismicaPresenti ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-elCriticiSismica"
          name={'elCriticiVulnerabilitaSismicaTipologiaElemento'}
          label="Tipologia elemento"
          disabled
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="elCriticiVulnerabilitaSismicaDescrizioneSintetica"
          label={'Descrizione sintetica'}
          inputProps={{ maxLength: 50 }}
          defaultValue=""
          disabled={
            !watchelEmentiCriticiVulnerabilitaSismicaPresenti ||
            inspectionDisabled ||
            isViewingInspection
          }
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <MediaUpload
          disabled={!watchelEmentiCriticiVulnerabilitaSismicaPresenti}
          title={`Foto`}
          btnTitle="Allega file"
          formName="elCriticiVulnerabilitaSismicaFoto"
          infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
          dataType="video/*,image/*"
          preview={true}
          uploadType={UploadMedia.media}
          type={FieldType.images}
          mediaGroup={[MediaGroup.Struttura]}
          tags={[
            'Spalle',
            'Pile',
            'Appoggi',
            'Giunti',
            'Pulvini',
            'Antenne',
            'Altri dispositivi antisismici',
            'Soletta',
            'Travi',
            'Traversi',
            'Controventi',
            'Archi',
            'Piedritti',
            'Tiranti',
            'Elementi extra',
            'appoggi',
            'estremità delle pile',
          ]}
        />
      </GridWrap>
    </GridWrap>
  );
};

export default ElCriticiVulnerabilitaSismica;
