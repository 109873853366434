import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import { DrawerNewInspection } from 'components';
import { TabsL2 } from 'components/TabsL2/TabsL2';
import { apiBridges } from 'constants/api';
import { InspectionStatus } from 'constants/inspections';
import { PermissionType } from 'constants/permissions';
import { useSubscription } from 'hooks';
import usePromises from 'hooks/usePromises';
import { useGlobalSelector } from 'stores';
import { exampleEmptyCda } from './config/emptyCda';

// import { exampleCda } from './config/cda';
const L2Cda = ({
  structure,
  userPermissionOnStructure,
  openDrawerNewInspection,
  setOpenDrawerNewInspection,
}) => {
  const { user } = useSubscription();
  const { setPromises } = usePromises();
  const { enqueueSnackbar } = useSnackbar();
  const { get } = useFetch();
  const params = useParams();
  const id = params.structureId;
  const inspectionId = params.inspectionId;
  const { showLoader, hideLoader } = useGlobalSelector();
  const [cda, setCda] = useState({});
  const [open, setOpen] = useState(false);

  const isOwner =
    structure?.ownerId === user?.id || structure?.ownerId === user?.parent?.id;

  useEffect(() => {
    const getCda = async () => {
      showLoader();
      try {
        const resultL2 = await get(
          inspectionId
            ? `${apiBridges.INSPECTIONS}/${inspectionId}/cda`
            : `${apiBridges.ROOT}/${id}/overview/cda`
        );
        if (resultL2) {
          setCda(resultL2);
        } else {
          setCda(exampleEmptyCda);
        }
      } catch (err) {
        console.error('Error in read cda: ', err);
        setCda({});
        // useCdaStore.setState(() => ({
        //   cda: {},
        // }));
        return enqueueSnackbar(
          `Si è verificato un errore nella lettura della cda`,
          {
            variant: 'error',
          }
        );
      } finally {
        hideLoader();
      }
    };
    Object.keys(cda).length === 0 && setPromises([getCda()]);
  }, []);

  // const { cda } = useCdaSelector();
  /* return Object.keys(cda).length ? <TabsL2 cda={cda} /> : <TabsL2 cda={null} />; */
  return structure?.status === InspectionStatus.NEW && !cda?.hasInspection ? (
    <>
      <Stack alignItems={'center'}>
        <Typography variant="h3">{`Non sono presenti attività all'interno di questa struttura`}</Typography>
        {userPermissionOnStructure === PermissionType.CanEdit && (
          <Button
            onClick={() => setOpenDrawerNewInspection(!openDrawerNewInspection)}
            variant="contained"
            color="primary"
            size="small"
            style={{ marginTop: 8 }}
          >
            Compila L0,L1
          </Button>
        )}
      </Stack>
      <DrawerNewInspection
        open={openDrawerNewInspection}
        setOpen={setOpenDrawerNewInspection}
        isOwner={isOwner}
      />
    </>
  ) : (
    Object.keys(cda).length > 0 && (
      <>
        <TabsL2 cda={cda} />
      </>
    )
  );
};

export default L2Cda;

L2Cda.propTypes = {
  callCda: PropTypes.bool,
  structure: PropTypes.object,
  userPermissionOnStructure: PropTypes.oneOf([
    PermissionType.CanView,
    PermissionType.CanEdit,
  ]),
  openDrawerNewInspection: PropTypes.bool,
  setOpenDrawerNewInspection: PropTypes.func,
};

L2Cda.defaultProps = {
  callCda: false,
};
