import { useLoadScript } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { LoadingScreen } from 'components';
import { Map } from './';

const MapWrapper = ({
  lat,
  lng,
  center,
  setCenter,
  markersDefault,
  hasMarkers,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
    language: 'it',
  });

  if (!isLoaded)
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  return (
    <Map
      lat={lat}
      lng={lng}
      center={center}
      setCenter={setCenter}
      markersDefault={markersDefault}
      hasMarkers={hasMarkers}
    />
  );
};

MapWrapper.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  center: PropTypes.object,
  setCenter: PropTypes.func,
  markersDefault: PropTypes.array,
  hasMarkers: PropTypes.bool,
};

export { MapWrapper };
