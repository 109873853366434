import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const BoxContainer = styled('div')(({ defect }) => ({
  width: '300px',
  borderRadius: 8,
  border: `1px solid ${colors.PALAGREY}`,
  backgroundColor: defect === 4 ? colors.GREY : colors.WHITE,
  padding: 16,
}));

export const Row = styled('div')({
  display: 'flex',
  gap: 6,
  alignItems: 'center',
});

export const VerticalBar = styled('div')({
  width: 2,
  height: 40,
  backgroundColor: colors.PALAGREY,
  margin: '0 6px 0 16px',
});

export const HorizontalBar = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: colors.PALAGREY,
  margin: '8px 0',
});

export const RateItem = styled('div')(({ customColors, theme }) => ({
  height: '35px',
  minWidth: '35px',
  borderRadius: '100%',
  backgroundColor: customColors?.bgColor || theme.palette.secondary.light,
  color: customColors?.color || theme.palette.error.light,
  border: `1px solid ${customColors?.border || theme.palette.error.light}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  marginRight: '2px',
}));

export const CriticTag = styled('div')({
  backgroundColor: colors.LIGHTRED,
  padding: '3px 8px',
  borderRadius: 10,
});
