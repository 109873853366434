import styled from '@emotion/styled';
import { colors } from 'constants/colors';
import { statusInspections } from './constants';

export const BoxContainer = styled('div')(
  ({ status, isSpecialInspection, isRischioFrane }) => ({
    width: '100%',
    borderRadius: 8,
    //   cursor: 'pointer',
    border:
      status >= 1 && isRischioFrane
        ? `1px solid ${colors.BLUE}`
        : status === statusInspections.PresenteNecessario
        ? `1px solid ${colors.BLUE}`
        : status === statusInspections.NecessitaIspezioniSpeciali &&
          isSpecialInspection
        ? `1px solid ${colors.RED}`
        : status === 3 && !isSpecialInspection
        ? `1px solid ${colors.PALAGREY}`
        : `1px solid ${colors.PALAGREY}`,
    backgroundColor:
      status >= 1 && isRischioFrane
        ? colors.BLUE
        : status === statusInspections.PresenteNecessario
        ? colors.BLUE
        : status === statusInspections.NecessitaIspezioniSpeciali &&
          isSpecialInspection
        ? colors.RED
        : status === statusInspections.AssenteNonNecessario
        ? colors.GREY
        : status === 3 && !isSpecialInspection
        ? colors.WHITE
        : colors.WHITE,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: 16,
  })
);

export const IconContainer = styled('div')(
  ({ status, isSpecialInspection }) => ({
    width: 40,
    height: 40,
    borderRadius: '100%',
    border:
      (!status || (status === 3 && !isSpecialInspection)) &&
      `1px solid ${colors.PALAGREY}`,
    backgroundColor: colors.WHITE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

export const ArrowIcon = styled('img')({
  cursor: 'pointer',
  marginLeft: 8,
  width: 12,
  height: 12,
});
