import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from 'constants/colors';
import { STRUCTURES_PATHS } from 'constants/paths';
import { refreshS3Path } from 'utils/upload';

const CustomCard = styled(Card)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.default}`,
  minHeight: 600,
  borderRadius: 10,
  boxShadow: 'none',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));

const ModalContainer = styled('div')({
  background: colors.DISABLED_WHITE,
  position: 'absolute',
  width: '100%',
  left: 0,
  top: 0,
  height: '100%',
  borderRadius: 8,

  backdropFilter: 'blur(5px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 20,
});
const ModalWrapper = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 10,
  transform: 'translate(-50%,-50%)',
});

const BtnModalWrapper = styled('div')({
  display: 'flex',
  gap: 8,
  marginTop: 16,
});

const UnifiedMaterialMap = {
  1: 'Muratura',
  2: 'Calcestruzzo armato',
  3: 'Calcestruzzo armato precompresso',
  4: 'Calcestruzzo',
  5: 'Acciaio o metallo',
  6: 'Legno',
  7: 'Gomma',
  8: 'Gomma armata',
  9: 'Gomma teflon',
  10: 'Acciaio teflon',
  11: 'Rete nella pavimentazione',
  12: 'Profilati elastomerici',
  13: 'Strisce in gomma',
  14: 'Pettini',
  15: 'Tamponi o giunti sottopavimentazione',
  16: 'Asfalto',
  17: 'Pietra',
  18: 'Autobloccanti',
  19: 'Ringhiera',
  20: 'NewJersey',
  21: 'Guardrail',
  22: 'Aiuola',
  23: 'Ghisa',
  24: 'Plastica',
  25: 'Altro',
  26: 'Non rilevabile',
};

const MediaSubjectElementMap = {
  1: 'Spalle',
  2: 'Pile',
  3: 'Appoggi',
  4: 'Giunti',
  5: 'Pulvini',
  6: 'Antenne',
  7: 'Altri dispositivi antisismici',
  8: 'Soletta',
  9: 'Travi',
  10: 'Traversi',
  11: 'Controventi',
  12: 'Archi',
  13: 'Piedritti',
  14: 'Tiranti',
  15: 'Elementi extra',
  16: 'Pavimentazione',
  17: 'Cordoli',
  18: 'Marciapiede',
  19: 'Parapetti',
  20: 'Guardavia',
  21: 'Spartitraffico',
  22: 'Pali illuminazione',
  23: 'Convogliamento acque',
  24: 'Sottoservizi',
};

const MediaCard = ({
  filename,
  fileDimension,
  author,
  date,
  imgUrl,
  imgAlt,
  code,
  structureName,
  material,
  element,
  isVideo,
  tags,
  options,
  handleMediaClick,
  onDelete,
  goToStructureImages,
  structureId,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomCard>
        <div
          style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}
        >
          <CardHeader
            sx={{
              overflow: 'hidden',
            }}
            title={
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                fontWeight={'bolder'}
              >
                {filename}
              </Typography>
            }
            subheader={
              <>
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {author}{' '}
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {date} {fileDimension}{' '}
                </Typography>
              </>
            }
          />
          <IconButton
            sx={{ position: 'absolute', right: 10, top: 10 }}
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '20ch',
              },
            }}
          >
            {options.map(({ name, onClick, id }) => {
              if (id === 'delete') {
                return (
                  <MenuItem
                    key={name}
                    onClick={() => {
                      setAnchorEl(null);
                      setOpenModal(true);
                    }}
                  >
                    {name}
                  </MenuItem>
                );
              }
              return (
                <MenuItem
                  key={name}
                  onClick={() => {
                    onClick();
                    handleClose();
                  }}
                >
                  {name}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
        <div style={{ flex: 2 }}>
          <CardMedia
            sx={{ borderRadius: 2 }}
            component={isVideo ? 'video' : 'img'}
            height="250"
            controls
            image={refreshS3Path(imgUrl)}
            alt={imgAlt}
            onClick={() => {
              if (!isVideo && handleMediaClick) handleMediaClick();
            }}
          />
        </div>
        <CardContent
          sx={{
            flex: 2,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography>
            <Typography variant="span" fontWeight={'bolder'}>
              Codice:
            </Typography>{' '}
            {code}
          </Typography>
          <Typography>
            <Typography variant="span" fontWeight={'bolder'}>
              Nome Opera:
            </Typography>{' '}
          </Typography>
          <Typography
            sx={{
              flex: 2,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '&:hover': {
                cursor: goToStructureImages ? 'pointer' : 'unset',
              },
            }}
            onClick={() => {
              if (goToStructureImages) {
                navigate(
                  STRUCTURES_PATHS.STRUCTURE_IMAGES.replace(
                    ':structureId',
                    structureId
                  )
                );
              }
            }}
            variant="span"
          >
            {structureName}
          </Typography>
          <Typography>
            <Typography variant="span" fontWeight={'bolder'}>
              Materiale:
            </Typography>{' '}
            {UnifiedMaterialMap[material]}
          </Typography>
          <Typography>
            <Typography variant="span" fontWeight={'bolder'}>
              Elemento:
            </Typography>{' '}
            {MediaSubjectElementMap[element]}
          </Typography>
        </CardContent>
        <div style={{ flex: 1 }}>
          {tags.map(el => (
            <Chip
              color="primary"
              sx={{ marginRight: 1, marginTop: 1 }}
              key={el}
              label={el}
            />
          ))}
        </div>
        {openModal && (
          <ModalContainer>
            <ModalWrapper>
              <Typography variant="h3Bold" sx={{ fontSize: 16 }}>
                Sei sicuro di eliminare questo file?
              </Typography>
              <BtnModalWrapper>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => onDelete()}
                >
                  Sì
                </Button>
                <Button
                  variant="containedBlack"
                  size="small"
                  sx={{ padding: 0 }}
                  onClick={() => setOpenModal(false)}
                >
                  No
                </Button>
              </BtnModalWrapper>
            </ModalWrapper>
          </ModalContainer>
        )}
      </CustomCard>
    </>
  );
};

MediaCard.propTypes = {
  filename: PropTypes.string,
  fileDimension: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  imgUrl: PropTypes.string,
  imgAlt: PropTypes.string,
  code: PropTypes.string,
  structureName: PropTypes.string,
  material: PropTypes.number,
  isVideo: PropTypes.bool,
  element: PropTypes.number,
  tags: PropTypes.array,
  options: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, onClick: PropTypes.func })
  ),
  handleMediaClick: PropTypes.func,
  onDelete: PropTypes.func,
  goToStructureImages: PropTypes.bool,
  structureId: PropTypes.number,
};

export { MediaCard };
