import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useUsers } from 'hooks';
import { useGlobalStore } from 'stores';
import { Header } from './Header/Header';
import { Sidebar } from './Sidebar/Sidebar';

import { ChildrenContainer } from './StandardLayout.style';

const StandardLayout = ({ isInfoAssistance }) => {
  const sidebarClose = useGlobalStore(state => state.sidebarClose);
  const { getUserInfo } = useUsers();
  const [currentUser, setCurrentUser] = useState();

  const getUserDetail = async () => {
    const res = await getUserInfo();
    setCurrentUser(res);
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  const type = currentUser?.type;

  //Function

  return (
    <>
      <Header user={currentUser} userType={type} />
      <Sidebar isInfoAssistance={isInfoAssistance} userType={type} />
      <ChildrenContainer close={sidebarClose}>
        <Outlet />
      </ChildrenContainer>
    </>
  );
};

export { StandardLayout };

StandardLayout.propTypes = {
  isInfoAssistance: PropTypes.bool,
  type: PropTypes.string,
};
