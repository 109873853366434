import { convertTSEnumToSelectOptions } from 'utils/utilities';
import { CDAStrutturale } from './difetti';
import {
  AbbassamentoAccentuatoAlleFondazioni,
  AbbassamentoGeneralizzatoAlveo,
  AccumuliDetritiAMontePila,
  AccumuloDetritiRischioIdraulico,
  AffidabilitaComplessivaRischioFrane,
  AffidabilitaComplessivaRischioIdraulico,
  AlternativeStradali,
  AppoggiAntisismiciAppoggioTableValue,
  AreaPericolosaRischioIdraulico,
  AreeGolenaliAssenti,
  BacinoIdrograficoPresuntoRischioIdraulico,
  BacinoIdrograficoRischioIdraulico,
  BrigliaProtezioneValleDelPonte,
  CategoriaPonte,
  CategoriaSottosuolo,
  CategoriaTopografica,
  ClasseUso,
  ClassificazioneUsoStradale,
  CompetanzaInfrastruttura,
  CondizioniCriticheSismicaTipologiaEl,
  CondizioniCriticheStrutturaleTipologiaEl,
  ConfinamentoAlveo,
  convogliamentoAcque,
  CurvaturaAlveoRischioIdraulico,
  DataApprossimativaAnnoDiCostruzione,
  DeposizioneSedimenti,
  DifettositaSovrastruttureEServizi,
  DifettositaStruttura,
  DisponibilitaStudiEffettiCarichiTraffico,
  DisponibilitaStudiTrasportistici,
  DistribuzioneAttivita,
  DivagazioneAlveoRischioIdraulico,
  ElCriticiSismicaStatoDegrado,
  ElementiCriticiStatoDegrado,
  EstensioneInterferenzaFrana,
  EvidenzeProfonditaDelleFondazioni,
  EvoluzioneAlveoRischioIdraulico,
  Fenomeno,
  FenomenoErosioneRischioIdraulico,
  FenomenoSormontoRischioIdraulico,
  FrancoIdraulicoSuperiore,
  FraneAttive,
  FrequentePassaggioPersone,
  GeometriaSezioneControventoTableValue,
  GeometriaSezioneTableValue,
  GeometriaSezioneTiranteTableValue,
  GradoCriticitaFrana,
  ImpostaFondPilaTableValue,
  ImpostaFondSpallaTableValue,
  ispezioniSpecialiRischioStruttura,
  ItinerarioEmergenza,
  ItinerarioInternazionale,
  MagnitudoBaseVolumetrica,
  MarciapiedePresenteTableValue,
  MaterialeAlveoRischioIdraulico,
  MaterialeVegetaleRischioIdraulico,
  mensole,
  MisureLaminazioneMitigazione,
  MisureMitigazione,
  MorfologiaAlveo,
  OpereArginaturaDiAlterazionePortate,
  OpereProtezioneSpondale,
  paliIlluminazione,
  PericolositaPAIPSAIFrane,
  PilaInAlveoTableValue,
  PosizioneInstabilitaVersante,
  PossibileAccessoSottoPonte,
  PresenzaCurve,
  PresenzaRilieviRischioIdraulico,
  PresenzaStudioIdraulicoLivelliDefiniti,
  ProspettiIspezionati,
  ProtezioneAlPiedePileSpalle,
  ReteTEN,
  RicadenteAreaAlluvioniRischioIdraulico,
  RischioFrana,
  RischioIdraulico,
  RischioPresente,
  risultatiIspezioniSpeciali,
  ScenariPericolositaRischioIdraulico,
  SchemaIsostatico,
  SchemaPonteCommonTableValue,
  SchemaPontePavimentazioneTableValue,
  SchemaPonteSpartiTrafficoTableValue,
  Sinuosita,
  SismicitiaAreaValApprossimativo,
  sistemaMonitoraggioRischioIdraulico,
  SormontabilitaMarciapiedeTableValue,
  sottoservizi,
  StatoAttivitaFrana,
  StatoConservazioneAdeguatezzaOpera,
  StatoConservazioneAdeguatezzaOperaBriglia,
  StatoConservazioneAdeguatezzaOpereArginatura,
  StatoConservazioneAdeguatezzaOpereLaminazione,
  StatoConservazioneProtezionePiede,
  statoConservazioneSistemaMonitoraggioRischioIdraulico,
  StatoOpera,
  StrutturaAlveoRischioIdraulico,
  TGMCommercialiVeicoliCorsiaApprossimativo,
  TGMCommercialiVeicoliCorsiaLimiteCarico,
  TGMCommercialiVeicoliCorsiaSingola,
  TGMCommercialiVeicoliGiorno,
  TGMCommercialiVeicoliGiornoValoreApprossimativo,
  TGMVeicoliGiorno,
  TGMVeicoliGiornoValoreApprossimativo,
  TipoFondSpallaTableValue,
  TipologiaAntennaTableValue,
  TipologiaAppoggioTableValue,
  TipologiaArcoTableValue,
  TipologiaControventoTableValue,
  tipologiaConvogliamentoAcque,
  TipologiaCordoloTableValue,
  TipologiaCorsoAcquaRischioIdraulico,
  TipologiaElementoExtraTableValue,
  TipologiaEnteScavalcatoTable,
  TipologiaFenomeno,
  TipologiaFondoAlveoRischioIdraulico,
  TipologiaFondPilaTableValue,
  TipologiaGiuntoTableValue,
  TipologiaGuardaviaTableValue,
  tipologiaImpalcato,
  TipologiaMarciapiedeTableValue,
  tipologiaPali,
  TipologiaParapettoTableValue,
  TipologiaPavimentazioneTableValue,
  TipologiaPiedrittoTableValue,
  TipologiaPilaTableValue,
  TipologiaPulvinoTableValue,
  TipologiaReticolo,
  tipologiaSchema,
  TipologiaSezionePilaTableValue,
  TipologiaSolettaTableValue,
  tipologiaSottoservizi,
  TipologiaSpallaTableValue,
  TipologiaSpartitrafficoTableValue,
  tipologiaStrutturale,
  TipologiaTiranteTableValue,
  TipologiaTraversoTableValue,
  TipologiaTraveTableValue,
  TipoTraveTableValue,
  TrasportiEccezionali,
  TrasportoMerciPericolose,
  UnitaFisiografica,
  UsoSuoloAreaPotezialmenteCoinvolta,
  utenzaServizi,
  ValutazioneEffettiCarichiTraffico,
  VelocitaMaxSpostamentoFrana,
  VerificaScalzamentoFondazioni,
  VieAttaccoSpallaTableValue,
} from './inspections';

export const YesNoOptions = [
  {
    label: 'Sì',
    value: 1,
  },
  {
    label: 'No',
    value: 2,
  },
];

export const PresentiAssentiOptions = [
  {
    label: 'Presenti',
    value: 1,
  },
  {
    label: 'Assenti',
    value: 2,
  },
];

// L0 - Contesto - Accordion 4 Rischio frane
export const RischioFranaOptions = [
  {
    label: 'Da verificare',
    value: RischioFrana.DaVerificare,
  },
  {
    label: 'Assente',
    value: RischioFrana.Assente,
  },
  {
    label: 'Presente',
    value: RischioFrana.Presente,
  },
];

export const RischioPresenteOptions = [
  {
    label: 'Diretto',
    value: RischioPresente.Diretto,
  },
  {
    label: 'Indiretto',
    value: RischioPresente.Indiretto,
  },
];

//L0 - Contesto - Accordion 5 Rischio idraulico
export const RischioIdraulicoOptions = [
  {
    label: 'Da verificare',
    value: RischioIdraulico.DaVerificare,
  },
  {
    label: 'Assente',
    value: RischioIdraulico.Assente,
  },
  {
    label: 'Presente',
    value: RischioIdraulico.Presente,
  },
];

// L0 - Contesto - Accordion 8 Collegamento e rete stradale
export const ClassificazioneUsoStradaleOptions = [
  {
    label: 'Autostrada o ferrovia',
    value: ClassificazioneUsoStradale.AutostradaFerrovia,
  },
  {
    label: 'Strada extraurbana principale',
    value: ClassificazioneUsoStradale.StradaExtraurbanaPrincipale,
  },
  {
    label: 'Strada extraurbana secondaria',
    value: ClassificazioneUsoStradale.StradaExtraurbanaSecondaria,
  },
  {
    label: 'Strada urbana di scorrimento',
    value: ClassificazioneUsoStradale.StradaUrbanaDiScorrimento,
  },
  {
    label: 'Strada urbana di quartiere',
    value: ClassificazioneUsoStradale.StradaUrbanaDiQuartiere,
  },
  {
    label: 'Strada locale',
    value: ClassificazioneUsoStradale.StradaLocale,
  },
  {
    label: 'Pedonale',
    value: ClassificazioneUsoStradale.Pedonale,
  },
];

export const CompetenzaInfastrutturaOptions = [
  {
    label: 'Autostrada',
    value: CompetanzaInfrastruttura.Autostrada,
  },
  {
    label: 'Strada statale',
    value: CompetanzaInfrastruttura.StradaStatale,
  },
  {
    label: 'Strada regionale',
    value: CompetanzaInfrastruttura.StradaRegionale,
  },
  {
    label: 'Strada provinciale',
    value: CompetanzaInfrastruttura.StradaProvinciale,
  },
  {
    label: 'Strada comunale',
    value: CompetanzaInfrastruttura.StradaComunale,
  },
  {
    label: 'Strada vicinale',
    value: CompetanzaInfrastruttura.StradaVicinale,
  },
  {
    label: 'Altra infrastruttura stradale',
    value: CompetanzaInfrastruttura.AltraInfrastrutturaStradale,
  },
];

export const CategoriaTopograficaShortOptions = [
  {
    label: 'T1',
    value: CategoriaTopografica.Pianura,
  },
  {
    label: 'T1',
    value: CategoriaTopografica.PianuraVersanti,
  },
  {
    label: 'T1',
    value: CategoriaTopografica.PendioDolce,
  },
  {
    label: 'T2',
    value: CategoriaTopografica.PendioModerato,
  },
  {
    label: 'T3',
    value: CategoriaTopografica.PendioRipido,
  },
  {
    label: 'T4',
    value: CategoriaTopografica.Cresta,
  },
];

export const CategoriaSottosuoloOptions = [
  {
    label: 'A',
    value: CategoriaSottosuolo.A,
  },
  {
    label: 'B',
    value: CategoriaSottosuolo.B,
  },
  {
    label: 'C',
    value: CategoriaSottosuolo.C,
  },
  {
    label: 'D',
    value: CategoriaSottosuolo.D,
  },
  {
    label: 'E',
    value: CategoriaSottosuolo.E,
  },
];

export const EffettivaPresuntaOptions = [
  { label: 'Effettiva', value: 1 },
  { label: 'Presunta', value: 2 },
];

export const CategoriaPonteOptions = [
  {
    label:
      'Categoria I: ponti destinati al transito di carichi civili e militari',
    value: CategoriaPonte.Categoria1,
  },
  {
    label: 'Categoria II: ponti destinati al transito dei soli carichi civili',
    value: CategoriaPonte.Categoria2,
  },
];

export const AnnoCostruzioneOptions = [
  {
    label: '≤1945',
    value: DataApprossimativaAnnoDiCostruzione.Anno1945,
  },
  {
    label: '1945-1980',
    value: DataApprossimativaAnnoDiCostruzione.Anno19451980,
  },
  {
    label: '≥1980',
    value: DataApprossimativaAnnoDiCostruzione.AnnoOver1980,
  },
];

export const AnnoCostruzioneExportOptions = [
  {
    label: '<=1945',
    value: DataApprossimativaAnnoDiCostruzione.Anno1945,
  },
  {
    label: '1945-1980',
    value: DataApprossimativaAnnoDiCostruzione.Anno19451980,
  },
  {
    label: '>=1980',
    value: DataApprossimativaAnnoDiCostruzione.AnnoOver1980,
  },
];

export const ItinerarioInternazionaleOptions = [
  {
    label: 'Sì',
    value: ItinerarioInternazionale.Si,
  },
  {
    label: 'No',
    value: ItinerarioInternazionale.No,
  },
];

export const ReteTENOptions = [
  {
    label: 'Sì',
    value: ReteTEN.Si,
  },
  {
    label: 'No',
    value: ReteTEN.No,
  },
];

export const ItinerarioEmergenzaOptions = [
  {
    label: 'Sì',
    value: ItinerarioEmergenza.Si,
  },
  {
    label: 'No',
    value: ItinerarioEmergenza.No,
  },
];

export const TrasportiEccezionaliOptions = [
  {
    label: 'Sì',
    value: TrasportiEccezionali.Si,
  },
  {
    label: 'No',
    value: TrasportiEccezionali.No,
  },
];

export const DisponibilitaStudiTrasportisticiOptions = [
  {
    label: 'Sì',
    value: DisponibilitaStudiTrasportistici.Si,
  },
  {
    label: 'No',
    value: DisponibilitaStudiTrasportistici.No,
  },
];

export const DisponibilitaStudiEffettiCarichiTrafficoOptions = [
  {
    label: 'Sì',
    value: DisponibilitaStudiEffettiCarichiTraffico.Si,
  },
  {
    label: 'No',
    value: DisponibilitaStudiEffettiCarichiTraffico.No,
  },
];

export const ValutazioneEffettiCarichiTrafficoOptions = [
  {
    label: 'Aggravano la vulnerabilità della struttura',
    value: ValutazioneEffettiCarichiTraffico.AggravanoVulnerabilitaStruttura,
  },
  {
    label: 'Non aggravano la vulnerabilità della struttura',
    value: ValutazioneEffettiCarichiTraffico.NonAggravanoVulnerabilitaStruttra,
  },
  {
    label: 'Non nota',
    value: ValutazioneEffettiCarichiTraffico.NonNota,
  },
];

export const PresenzaCurveOptions = [
  {
    label: 'Sì',
    value: PresenzaCurve.Si,
  },
  {
    label: 'No',
    value: PresenzaCurve.No,
  },
];

export const TGMVeicoliGiornoOptions = [
  {
    label: 'Valore effettivo',
    value: TGMVeicoliGiorno.ValoreEffettivo,
  },
  {
    label: 'Valore approssimativo',
    value: TGMVeicoliGiorno.ValoreApprossimativo,
  },
];

export const TGMVeicoliGiornoValoreApprossimativoOptions = [
  {
    label: '≥ 25000 veicoli/giorno',
    value: TGMVeicoliGiornoValoreApprossimativo.VeicoliSopra25000,
  },
  {
    label: '10000 < veicoli/giorno < 25000',
    value: TGMVeicoliGiornoValoreApprossimativo.VeicoliTra10000e25000,
  },
  {
    label: '≤ 10000 veicoli/giorno',
    value: TGMVeicoliGiornoValoreApprossimativo.VeicoliSotto10000,
  },
];

export const TGMVeicoliGiornoValoreApprossimativoPDFOptions = [
  {
    label: '>= 25000 veicoli/giorno',
    value: TGMVeicoliGiornoValoreApprossimativo.VeicoliSopra25000,
  },
  {
    label: '10000 < veicoli/giorno < 25000',
    value: TGMVeicoliGiornoValoreApprossimativo.VeicoliTra10000e25000,
  },
  {
    label: '<= 10000 veicoli/giorno',
    value: TGMVeicoliGiornoValoreApprossimativo.VeicoliSotto10000,
  },
];

export const TGMCommercialiVeicoliGiornoOptions = [
  {
    label: 'Valore effettivo',
    value: TGMCommercialiVeicoliGiorno.ValoreEffettivo,
  },
  {
    label: 'Valore approssimativo',
    value: TGMCommercialiVeicoliGiorno.ValoreApprossimativo,
  },
];

export const TGMCommercialiVeicoliGiornoValoreApprossimativoOptions = [
  {
    label: '≥ 700 veicoli/giorno',
    value: TGMCommercialiVeicoliGiornoValoreApprossimativo.VeicoliSopra700,
  },
  {
    label: '300 < veicoli/giorno < 700',
    value: TGMCommercialiVeicoliGiornoValoreApprossimativo.VeicoliTra300e700,
  },
  {
    label: ' ≤ 300 veicoli/giorno',
    value: TGMCommercialiVeicoliGiornoValoreApprossimativo.VeicoliSotto300,
  },
];

export const TGMCommercialiVeicoliGiornoValoreApprossimativoPDFOptions = [
  {
    label: '>= 700 veicoli/giorno',
    value: TGMCommercialiVeicoliGiornoValoreApprossimativo.VeicoliSopra700,
  },
  {
    label: '300 < veicoli/giorno < 700',
    value: TGMCommercialiVeicoliGiornoValoreApprossimativo.VeicoliTra300e700,
  },
  {
    label: ' <= 300 veicoli/giorno',
    value: TGMCommercialiVeicoliGiornoValoreApprossimativo.VeicoliSotto300,
  },
];

export const TGMCommercialiVeicoliCorsiaSingolaOptions = [
  {
    label: 'Valore effettivo',
    value: TGMCommercialiVeicoliCorsiaSingola.ValoreEffettivo,
  },
  {
    label: 'Valore approssimativo',
    value: TGMCommercialiVeicoliCorsiaSingola.ValoreApprossimativo,
  },
];

export const TGMCommercialiVeicoliCorsiaApprossimativoOptions = [
  {
    label: '≥ 700 veicoli/giorno',
    value: TGMCommercialiVeicoliCorsiaApprossimativo.VeicoliSopra700,
  },
  {
    label: '300 < veicoli/giorno < 700',
    value: TGMCommercialiVeicoliCorsiaApprossimativo.VeicoliTra300e700,
  },
  {
    label: ' ≤ 300 veicoli/giorno',
    value: TGMCommercialiVeicoliCorsiaApprossimativo.VeicoliSotto300,
  },
];

export const TGMCommercialiVeicoliCorsiaLimiteCaricoOptions = [
  {
    label: 'Classe A: nessuna limitazione presente',
    value: TGMCommercialiVeicoliCorsiaLimiteCarico.ClasseA,
  },
  {
    label: 'Classe B : limitazione di carico a 44 t',
    value: TGMCommercialiVeicoliCorsiaLimiteCarico.ClasseB,
  },
  {
    label: 'Classe C: limitazione di carico a 26 t',
    value: TGMCommercialiVeicoliCorsiaLimiteCarico.ClasseC,
  },
  {
    label: 'Classe D: limitazione di carico a 8 t',
    value: TGMCommercialiVeicoliCorsiaLimiteCarico.ClasseD,
  },
  {
    label: 'Classe E: limitazione di carico a 3,5 t',
    value: TGMCommercialiVeicoliCorsiaLimiteCarico.ClasseE,
  },
];

export const TGMCommercialiVeicoliCorsiaLimiteCaricoShortOptions = [
  {
    label: 'Classe A',
    value: TGMCommercialiVeicoliCorsiaLimiteCarico.ClasseA,
  },
  {
    label: 'Classe B',
    value: TGMCommercialiVeicoliCorsiaLimiteCarico.ClasseB,
  },
  {
    label: 'Classe C',
    value: TGMCommercialiVeicoliCorsiaLimiteCarico.ClasseC,
  },
  {
    label: 'Classe D',
    value: TGMCommercialiVeicoliCorsiaLimiteCarico.ClasseD,
  },
  {
    label: 'Classe E',
    value: TGMCommercialiVeicoliCorsiaLimiteCarico.ClasseE,
  },
];

export const AlternativeStradaliOptions = [
  {
    label: 'Sì',
    value: AlternativeStradali.Si,
  },
  {
    label: 'No',
    value: AlternativeStradali.No,
  },
];

export const FrequentePassaggioPersoneOptions = [
  {
    label: 'Sì',
    value: FrequentePassaggioPersone.Si,
  },
  {
    label: 'No',
    value: FrequentePassaggioPersone.No,
  },
];

export const TrasportoMerciPericoloseOptions = [
  {
    label: 'Sì',
    value: TrasportoMerciPericolose.Si,
  },
  {
    label: 'No',
    value: TrasportoMerciPericolose.No,
  },
];

// L0 - Contesto - Accordion 10 Caratteristiche Geometriche
export const schemaCampataOptions = [
  {
    label: 'Isostatico',
    value: SchemaIsostatico.Isostatico,
  },
  {
    label: 'Iperstatico',
    value: SchemaIsostatico.Iperstatico,
  },
];

export const tipoEnteScavalcatoOptions = [
  {
    label: "Corso d'acqua",
    value: TipologiaEnteScavalcatoTable.CorsoAcqua,
  },
  {
    label: "Specchio d'acqua marina",
    value: TipologiaEnteScavalcatoTable.SpecchioAcquaMarina,
  },
  {
    label: 'Discontinuità orografica (vallata, piccoli canali, ecc.)',
    value: TipologiaEnteScavalcatoTable.DiscontinuitaOrografica,
  },
  {
    label: 'Zona edificata',
    value: TipologiaEnteScavalcatoTable.ZonaEdificata,
  },
  {
    label: 'Zona urbanizzata',
    value: TipologiaEnteScavalcatoTable.ZonaUrbanizzata,
  },
  {
    label: 'Altra via di comunicazione',
    value: TipologiaEnteScavalcatoTable.AltraViaDiComunicazione,
  },
  {
    label: 'Ferrovia',
    value: TipologiaEnteScavalcatoTable.Ferrovia,
  },
  {
    label: 'Altro',
    value: TipologiaEnteScavalcatoTable.Altro,
  },
];

// L0 - Struttura - Accordion 1 Caratteristiche strutturali
export const tipologiaStrutturaleOptions = [
  {
    label: 'Arco massiccio',
    value: tipologiaStrutturale.ArcoMassiccio,
  },
  {
    label: 'Arco sottile',
    value: tipologiaStrutturale.ArcoSottile,
  },
  {
    label: 'Travate appoggiate',
    value: tipologiaStrutturale.TravateAppoggiate,
  },
  {
    label: 'Travate continue/telaio',
    value: tipologiaStrutturale.TravateContinueTelaio,
  },
  {
    label: 'Travate Gerber/Ponti a stampella con tamponi',
    value: tipologiaStrutturale.TravateGerberPontiStampella,
  },
  {
    label: 'Soletta appoggiata',
    value: tipologiaStrutturale.SolettaAppoggiata,
  },
  {
    label: 'Soletta incastrata',
    value: tipologiaStrutturale.SolettaIncastrata,
  },
  {
    label: 'Cassone in precompresso',
    value: tipologiaStrutturale.CassonePrecompresso,
  },
  {
    label: 'Sezione tubolare',
    value: tipologiaStrutturale.SezioneTubolare,
  },
  {
    label: 'Travate in c.a.p. a cavi post tesi',
    value: tipologiaStrutturale.TravateCap,
  },
  {
    label: 'Strallato o sospeso',
    value: tipologiaStrutturale.StrallatoSospeso,
  },
  {
    label: 'Altro',
    value: tipologiaStrutturale.Altro,
  },
];

export const ispezioniSpecialiRischioStrutturaOptions = [
  {
    label: 'Sì',
    value: ispezioniSpecialiRischioStruttura.Si,
  },
  {
    label: 'No',
    value: ispezioniSpecialiRischioStruttura.No,
  },
];

export const risultatiIspezioniSpecialiOptions = [
  {
    label: 'Necessità di valutazioni di Livello 4',
    value: risultatiIspezioniSpeciali.NecessitaValutazioneLivello4,
  },
  {
    label:
      ' Ispezione speciale non esaustiva. Necessità di valutazioni di Livello 4',
    value: risultatiIspezioniSpeciali.IspezioneNonEsaustiva,
  },
  {
    label: 'Necessità di ispezioni di Livello 1 e classificazione Livello 2',
    value: risultatiIspezioniSpeciali.NecessitaIspezioneLivello1,
  },
  {
    label: 'Ispezione speciale ancora da effettuare',
    value: risultatiIspezioniSpeciali.IspezioneAncoraDaEffettuare,
  },
];

export const tipologiaSchemaOptions = [
  {
    label: 'Isostatico',
    value: tipologiaSchema.Isostatico,
  },
  {
    label: 'Iperstatico',
    value: tipologiaSchema.Iperstatico,
  },
];

export const tipologiaImpalcatoOptions = [
  {
    label: 'Muratura',
    value: tipologiaImpalcato.Muratura,
  },
  {
    label: 'C.A.',
    value: tipologiaImpalcato.CA,
  },
  {
    label: 'C.A.P.',
    value: tipologiaImpalcato.CAP,
  },
  {
    label: 'Acciaio',
    value: tipologiaImpalcato.Acciaio,
  },
  {
    label: 'Metallo (ponti storici)',
    value: tipologiaImpalcato.Metallo,
  },
  {
    label: 'Misto (C.A./acciaio)',
    value: tipologiaImpalcato.Misto,
  },
  {
    label: 'Legno',
    value: tipologiaImpalcato.Legno,
  },
  {
    label: 'Altro',
    value: tipologiaImpalcato.Altro,
  },
];

export const mensoleOptions = [
  {
    label: 'Sì',
    value: mensole.Si,
  },
  {
    label: 'No',
    value: mensole.No,
  },
];

// L0 - Struttura - Accordion 3 Elevazione

export const tipologiaSpallaOptions = [
  {
    label: 'Calcestruzzo',
    value: TipologiaSpallaTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Muratura',
    value: TipologiaSpallaTableValue.Muratura,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaSpallaTableValue.Nonrilevabile,
  },
];

export const vieAttaccoSpallaOptions = [
  {
    label: 'Rilevato in terra',
    value: VieAttaccoSpallaTableValue.RilevatoInTerra,
  },
  {
    label: 'Rilevato in terra rinforzata/armata',
    value: VieAttaccoSpallaTableValue.RilevatoInTerraRinforzataArmata,
  },
  {
    label: 'Rilevato in golena',
    value: VieAttaccoSpallaTableValue.RilevatoInGolena,
  },
  {
    label: 'Su roccia',
    value: VieAttaccoSpallaTableValue.SuRoccia,
  },
  {
    label: 'Viadotto in pendenza/curva',
    value: VieAttaccoSpallaTableValue.ViadottoInPendenzaCurva,
  },
  {
    label: 'Assente',
    value: VieAttaccoSpallaTableValue.Assente,
  },
  {
    label: 'Altro',
    value: VieAttaccoSpallaTableValue.Altro,
  },
];

export const impostaFondSpallaOptions = [
  {
    label: 'Diretta',
    value: ImpostaFondSpallaTableValue.Diretta,
  },
  {
    label: 'Indiretta',
    value: ImpostaFondSpallaTableValue.Indiretta,
  },
  {
    label: 'Non nota',
    value: ImpostaFondSpallaTableValue.NonNota,
  },
];

export const tipoFondSpallaOptions = [
  {
    label: 'Muratura',
    value: TipoFondSpallaTableValue.Muratura,
  },
  {
    label: 'Calcestruzzo armato',
    value: TipoFondSpallaTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Legno',
    value: TipoFondSpallaTableValue.Legno,
  },
  {
    label: 'Acciaio',
    value: TipoFondSpallaTableValue.Acciaio,
  },
  {
    label: 'Non nota',
    value: TipoFondSpallaTableValue.NonNota,
  },
];

export const tipoPilaSelectOptions = [
  {
    label: 'Calcestruzzo',
    value: TipologiaPilaTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Muratura',
    value: TipologiaPilaTableValue.Muratura,
  },
  {
    label: 'Acciaio o metallo',
    value: TipologiaPilaTableValue.AcciaioOMetallo,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaPilaTableValue.NonRilevabile,
  },
];

export const tipoSezionePilaOptions = [
  {
    label: 'A parete piena',
    value: TipologiaSezionePilaTableValue.AParetePiena,
  },
  {
    label: 'A doppia parete',
    value: TipologiaSezionePilaTableValue.ADoppiaParete,
  },
  {
    label: 'A cassone',
    value: TipologiaSezionePilaTableValue.ACassone,
  },
  {
    label: 'A cassoni separati',
    value: TipologiaSezionePilaTableValue.ACassoniSeparati,
  },
  {
    label: 'A telaio',
    value: TipologiaSezionePilaTableValue.ATelaio,
  },
  {
    label: 'A colonna',
    value: TipologiaSezionePilaTableValue.AColonna,
  },
  {
    label: 'A più colonne',
    value: TipologiaSezionePilaTableValue.APiuColonne,
  },
  {
    label: 'A doppia lama',
    value: TipologiaSezionePilaTableValue.ADoppiaLama,
  },
  {
    label: 'Altro',
    value: TipologiaSezionePilaTableValue.Altro,
  },
];

export const pilaInAlveoOptions = [
  {
    label: 'Si',
    value: PilaInAlveoTableValue.Si,
  },
  {
    label: 'No',
    value: PilaInAlveoTableValue.No,
  },
];

export const geometriaSezionePilaOptions = [
  {
    label: 'Circolare',
    value: GeometriaSezioneTableValue.Circolare,
  },
  {
    label: 'Rettangolare',
    value: GeometriaSezioneTableValue.Rettangolare,
  },
  {
    label: 'Altro',
    value: GeometriaSezioneTableValue.Altro,
  },
];

export const impostaFondPilaOptions = [
  {
    label: 'Diretta',
    value: ImpostaFondPilaTableValue.Diretta,
  },
  {
    label: 'Indiretta',
    value: ImpostaFondPilaTableValue.Indiretta,
  },
  {
    label: 'Non nota',
    value: ImpostaFondPilaTableValue.NonNota,
  },
];

export const tipoFondPilaOptions = [
  {
    label: 'Muratura',
    value: TipologiaFondPilaTableValue.Muratura,
  },
  {
    label: 'Calcestruzzo armato',
    value: TipologiaFondPilaTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Legno',
    value: TipologiaFondPilaTableValue.Legno,
  },
  {
    label: 'Acciaio',
    value: TipologiaFondPilaTableValue.Acciaio,
  },
  {
    label: 'Non nota',
    value: TipologiaFondPilaTableValue.NonNota,
  },
];

// Appoggi

export const tipologiaAppoggioOptions = [
  {
    label: 'Gomma',
    value: TipologiaAppoggioTableValue.Gomma,
  },

  {
    label: 'Gomma armata',
    value: TipologiaAppoggioTableValue.GommaArmata,
  },
  {
    label: 'Gomma e teflon',
    value: TipologiaAppoggioTableValue.GommaTeflon,
  },
  {
    label: 'Acciaio',
    value: TipologiaAppoggioTableValue.Acciaio,
  },
  {
    label: 'Acciaio e teflon',
    value: TipologiaAppoggioTableValue.AcciaioTeflon,
  },
  {
    label: 'Calcestruzzo',
    value: TipologiaAppoggioTableValue.Calcestruzzo,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaAppoggioTableValue.NonRilevabile,
  },
];

export const appoggiAntisismiciAppoggiOption = [
  {
    label: 'Si',
    value: AppoggiAntisismiciAppoggioTableValue.Si,
  },
  {
    label: 'No',
    value: AppoggiAntisismiciAppoggioTableValue.No,
  },
];

export const tipologiaGiuntoOptions = [
  {
    label: 'Rete nella pavimentazione',
    value: TipologiaGiuntoTableValue.ReteNellaPavimentazione,
  },
  {
    label: 'Profilati elastomerici',
    value: TipologiaGiuntoTableValue.ProfilatiElastomerici,
  },
  {
    label: 'Strisce in gomma',
    value: TipologiaGiuntoTableValue.StrisceInGomma,
  },
  {
    label: 'Pettini',
    value: TipologiaGiuntoTableValue.Pettini,
  },
  {
    label: 'Tamponi o giunti sottopavimentazione',
    value: TipologiaGiuntoTableValue.TamponiOGiuntiSottopavimentazione,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaGiuntoTableValue.NonRilevabile,
  },
];

export const tipologiaPulvinoOptions = [
  {
    label: 'Calcestruzzo armato',
    value: TipologiaPulvinoTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Acciaio o metallo',
    value: TipologiaPulvinoTableValue.AcciaioOMetallo,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaPulvinoTableValue.NonRilevabile,
  },
];

export const tipologiaAntennaOptions = [
  {
    label: 'Calcestruzzo armato',
    value: TipologiaAntennaTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Calcestruzzo armato precompresso ',
    value: TipologiaAntennaTableValue.CalcestruzzoArmatoPrecompresso,
  },
  {
    label: 'Acciaio o metallo',
    value: TipologiaAntennaTableValue.AcciaioOMetallo,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaAntennaTableValue.NonRilevabile,
  },
];
export const paliIlluminazioneOptions = [
  {
    label: 'Presenti',
    value: paliIlluminazione.Presenti,
  },
  {
    label: 'Assenti',
    value: paliIlluminazione.Assenti,
  },
  {
    label: 'Non previsti',
    value: paliIlluminazione.NonPrevisti,
  },
];

export const tipologiaPaliOptions = [
  {
    label: 'Legno',
    value: tipologiaPali.Legno,
  },
  {
    label: 'Acciaio',
    value: tipologiaPali.Acciaio,
  },
  {
    label: 'Ghisa',
    value: tipologiaPali.Ghisa,
  },
  {
    label: 'Altro',
    value: tipologiaPali.Altro,
  },
];

// L0 - Servizi - Accordion 2 Convogliamento acque
export const convogliamentoAcqueOptions = [
  {
    label: 'Presente',
    value: convogliamentoAcque.Presente,
  },
  {
    label: 'Assente',
    value: convogliamentoAcque.Assente,
  },
  {
    label: 'Non previsto',
    value: convogliamentoAcque.NonPrevisto,
  },
];

export const tipologiaConvogliamentoAcqueOptions = [
  {
    label: 'Metallo',
    value: tipologiaConvogliamentoAcque.Metallo,
  },
  {
    label: 'Plastica',
    value: tipologiaConvogliamentoAcque.Plastica,
  },
  {
    label: 'Altro',
    value: tipologiaConvogliamentoAcque.Altro,
  },
];

// L0 - Servizi - Accordion 3 Sottoservizi
export const sottoserviziOptions = [
  {
    label: 'Presente',
    value: sottoservizi.Presente,
  },
  {
    label: 'Assente',
    value: sottoservizi.Assente,
  },
  {
    label: 'Non previsto',
    value: sottoservizi.NonPrevisto,
  },
];

export const tipologiaSottoserviziOptions = [
  {
    label: 'Metallo',
    value: tipologiaSottoservizi.Metallo,
  },
  {
    label: 'Plastica',
    value: tipologiaSottoservizi.Plastica,
  },
  {
    label: 'Altro',
    value: tipologiaSottoservizi.Altro,
  },
];

export const utenzaServiziOptions = [
  {
    label: 'Gas',
    value: utenzaServizi.Gas,
  },
  {
    label: 'Elettricità',
    value: utenzaServizi.Elettricita,
  },
  {
    label: 'Acqua',
    value: utenzaServizi.Acqua,
  },
  {
    label: 'Scarichi',
    value: utenzaServizi.Scarichi,
  },
];

export const tipologiaSolettaOptions = [
  {
    label: 'Calcestruzzo armato',
    value: TipologiaSolettaTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Calcestruzzo armato precompresso',
    value: TipologiaSolettaTableValue.CalcestruzzoArmatoPrecompresso,
  },
  {
    label: 'Legno',
    value: TipologiaSolettaTableValue.Legno,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaSolettaTableValue.NonRilevabile,
  },
];

export const tipologiaTraveOptions = [
  {
    label: 'Calcestruzzo armato',
    value: TipologiaTraveTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Calcestruzzo armato precompresso',
    value: TipologiaTraveTableValue.CalcestruzzoArmatoPrecompresso,
  },
  {
    label: 'Acciaio o metallo',
    value: TipologiaTraveTableValue.AcciaioOMetallo,
  },
  {
    label: 'Legno',
    value: TipologiaTraveTableValue.Legno,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaTraveTableValue.NonRilevabile,
  },
];

export const tipoTraveOptions = [
  {
    label: 'Trave semplice',
    value: TipoTraveTableValue.TraveSemplice,
  },
  {
    label: 'Trave reticolare',
    value: TipoTraveTableValue.TraveReticolare,
  },
];

export const tipologiaTraversoOptions = [
  {
    label: 'Calcestruzzo armato',
    value: TipologiaTraversoTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Calcestruzzo armato precompresso',
    value: TipologiaTraversoTableValue.CalcestruzzoArmatoPrecompresso,
  },
  {
    label: 'Acciaio o metallo',
    value: TipologiaTraversoTableValue.AcciaioOMetallo,
  },
  {
    label: 'Legno',
    value: TipologiaTraversoTableValue.Legno,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaTraversoTableValue.NonRilevabile,
  },
];
export const tipologiaControventoOptions = [
  {
    label: 'Calcestruzzo armato',
    value: TipologiaControventoTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Calcestruzzo armato precompresso',
    value: TipologiaControventoTableValue.CalcestruzzoArmatoPrecompresso,
  },
  {
    label: 'Acciaio o metallo',
    value: TipologiaControventoTableValue.AcciaioOMetallo,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaControventoTableValue.NonRilevabile,
  },
];
export const geometriaSezioneControventoOptions = [
  {
    label: 'Circolare',
    value: GeometriaSezioneControventoTableValue.Circolare,
  },
  {
    label: 'Rettangolare',
    value: GeometriaSezioneControventoTableValue.Rettangolare,
  },
  {
    label: 'Altro',
    value: GeometriaSezioneControventoTableValue.Altro,
  },
];

export const tipologiaArcoOptions = [
  {
    label: 'Calcestruzzo armato',
    value: TipologiaArcoTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Muratura',
    value: TipologiaArcoTableValue.Muratura,
  },
  {
    label: 'Acciaio',
    value: TipologiaArcoTableValue.Acciaio,
  },
  {
    label: 'Legno',
    value: TipologiaArcoTableValue.Legno,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaArcoTableValue.NonRilevabile,
  },
];

export const tipologiaPiedrittoOptions = [
  {
    label: 'Calcestruzzo armato',
    value: TipologiaPiedrittoTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Acciaio o metallo',
    value: TipologiaPiedrittoTableValue.AcciaioOMetallo,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaPiedrittoTableValue.NonRilevabile,
  },
];

export const tipologiaTiranteOptions = [
  {
    label: 'Calcestruzzo armato precompresso',
    value: TipologiaTiranteTableValue.CalcestruzzoArmatoPrecompresso,
  },

  {
    label: 'Acciaio o metallo',
    value: TipologiaTiranteTableValue.AcciaioOMetallo,
  },
  {
    label: 'Non rilevabile',
    value: TipologiaTiranteTableValue.NonRilevabile,
  },
];
export const geometriaSezioneTiranteOptions = [
  {
    label: 'Circolare',
    value: GeometriaSezioneTiranteTableValue.Circolare,
  },
  {
    label: 'Rettangolare',
    value: GeometriaSezioneTiranteTableValue.Rettangolare,
  },
  {
    label: 'Altro',
    value: GeometriaSezioneTiranteTableValue.Altro,
  },
];
export const tipologiaElementoExtraOptions = [
  {
    label: 'Calcestruzzo armato',
    value: TipologiaElementoExtraTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Calcestruzzo armato precompresso',
    value: TipologiaElementoExtraTableValue.CalcestruzzoArmatoPrecompresso,
  },
  {
    label: 'Acciaio o metallo',
    value: TipologiaElementoExtraTableValue.AcciaioOMetallo,
  },
  {
    label: 'Legno',
    value: TipologiaElementoExtraTableValue.Legno,
  },
  {
    label: 'Muratura',
    value: TipologiaElementoExtraTableValue.Muratura,
  },
];

export const schemaPonteCommonOptions = [
  {
    label: '1',
    value: SchemaPonteCommonTableValue.Uno,
  },
  {
    label: 'Assente',
    value: SchemaPonteCommonTableValue.Assente,
  },
  {
    label: 'Non previsto',
    value: SchemaPonteCommonTableValue.NonPrevisto,
  },
];
export const schemaPontePavimentazioneOptions = [
  {
    label: '1',
    value: SchemaPontePavimentazioneTableValue.Si,
  },
  {
    label: 'Assente',
    value: SchemaPontePavimentazioneTableValue.No,
  },
];
export const schemaPonteSpartitrafficoOptions = [
  {
    label: '1',
    value: SchemaPonteSpartiTrafficoTableValue.Uno,
  },
  {
    label: '2',
    value: SchemaPonteSpartiTrafficoTableValue.Due,
  },
  {
    label: '3',
    value: SchemaPonteSpartiTrafficoTableValue.Tre,
  },
  {
    label: 'Assente',
    value: SchemaPonteSpartiTrafficoTableValue.Assente,
  },
  {
    label: 'Non previsto',
    value: SchemaPonteSpartiTrafficoTableValue.NonPrevisto,
  },
];
export const schemaPonteSpartitrafficoOptionsForL1 = [
  {
    label: 'Presente',
    value: SchemaPonteSpartiTrafficoTableValue.Uno,
  },
  {
    label: 'Presente',
    value: SchemaPonteSpartiTrafficoTableValue.Due,
  },
  {
    label: 'Presente',
    value: SchemaPonteSpartiTrafficoTableValue.Tre,
  },
  {
    label: 'Assente',
    value: SchemaPonteSpartiTrafficoTableValue.Assente,
  },
  {
    label: 'Non previsto',
    value: SchemaPonteSpartiTrafficoTableValue.NonPrevisto,
  },
];

export const tipologiaCordoloOptions = [
  {
    label: 'Calcestruzzo armato',
    value: TipologiaCordoloTableValue.CalcestruzzoArmato,
  },
  {
    label: 'Calcestruzzo armato precompresso',
    value: TipologiaCordoloTableValue.CalcestruzzoArmatoPrecompresso,
  },
];
// L1 - Contesto - Accordion 1 Informazioni Generali
export const ClasseUsoOptions = [
  {
    label: 'I',
    value: ClasseUso.Prima,
  },
  {
    label: 'II',
    value: ClasseUso.Seconda,
  },
  {
    label: 'III',
    value: ClasseUso.Terza,
  },
  {
    label: 'IV',
    value: ClasseUso.Quarta,
  },
];

export const StatoOperaOptions = [
  {
    label: 'A: Pienamente agibile',
    value: StatoOpera.A_Agibile,
  },
  {
    label: 'B: Agibile ma con scadenze di lavori di manutenzione ordinaria',
    value: StatoOpera.B_ManutenzioneOrdinaria,
  },
  {
    label: 'C: Agibile ma con scadenze di lavori di manutenzione straordinaria',
    value: StatoOpera.C_ManutenzioneStraordinaria,
  },
  {
    label:
      'D: Condizioni critiche e agibile parzialmente/lavori di manutenzione urgenti',
    value: StatoOpera.D_ManutenzioneUrgente,
  },
  {
    label: 'E: Inagibile',
    value: StatoOpera.E_Inagibile,
  },
];

// L1 - Contesto - Accordion 2 Informazioni di ispezione
export const PossibileAccessoSottoPonteOptions = [
  {
    label: 'Sì',
    value: PossibileAccessoSottoPonte.Si,
  },
  {
    label: 'No',
    value: PossibileAccessoSottoPonte.No,
  },
  {
    label: 'Parziale',
    value: PossibileAccessoSottoPonte.Parziale,
  },
];

export const VerificaScalzamentoFondazioniOptions = [
  {
    label: 'Sì',
    value: VerificaScalzamentoFondazioni.Si,
  },
  {
    label: 'No',
    value: VerificaScalzamentoFondazioni.No,
  },
];

export const ProspettiIspezionatiOptions = [
  {
    label: 'Destro',
    value: ProspettiIspezionati.Destro,
  },
  {
    label: 'Sinistro',
    value: ProspettiIspezionati.Sinistro,
  },
];

export const AffidabilitaComplessivaRischioFraneOptions = [
  {
    label: 'Buona',
    value: AffidabilitaComplessivaRischioFrane.Buona,
  },
  {
    label: 'Limitata',
    value: AffidabilitaComplessivaRischioFrane.Limitata,
  },
];

export const AffidabilitaComplessivaRischioIdraulicoOptions = [
  {
    label: 'Buona',
    value: AffidabilitaComplessivaRischioIdraulico.Buona,
  },
  {
    label: 'Limitata',
    value: AffidabilitaComplessivaRischioIdraulico.Limitata,
  },
];

export const tipologiaPavimentazioneOptions = [
  {
    label: 'Asfalto',
    value: TipologiaPavimentazioneTableValue.Asfalto,
  },
  {
    label: 'Calcestruzzo',
    value: TipologiaPavimentazioneTableValue.Calcestruzzo,
  },
  {
    label: 'Pietra',
    value: TipologiaPavimentazioneTableValue.Pietra,
  },
  {
    label: 'Altro',
    value: TipologiaPavimentazioneTableValue.Altro,
  },
];

export const tipologiaMarciapiedeOptions = [
  {
    label: 'Asfalto',
    value: TipologiaMarciapiedeTableValue.Asfalto,
  },
  {
    label: 'Calcestruzzo',
    value: TipologiaMarciapiedeTableValue.Calcestruzzo,
  },
  {
    label: 'Pietra',
    value: TipologiaMarciapiedeTableValue.Pietra,
  },
  {
    label: 'Legno',
    value: TipologiaMarciapiedeTableValue.Legno,
  },
  {
    label: 'Autobloccanti',
    value: TipologiaMarciapiedeTableValue.Autobloccanti,
  },
  {
    label: 'Altro',
    value: TipologiaMarciapiedeTableValue.Altro,
  },
];
export const marciapiedePresenteOptions = [
  {
    label: 'Solo su un lato',
    value: MarciapiedePresenteTableValue.SoloUnLato,
  },
  {
    label: 'Su entrambi i lati',
    value: MarciapiedePresenteTableValue.SuEntrambiLati,
  },
];

export const sormontabilitaMarciapiedeOptions = [
  {
    label: 'Sormontabile',
    value: SormontabilitaMarciapiedeTableValue.Sormontabile,
  },
  {
    label: 'Non sormontabile',
    value: SormontabilitaMarciapiedeTableValue.NonSormontabile,
  },
];

export const tipologiaParapettoOptions = [
  {
    label: 'Muratura',
    value: TipologiaParapettoTableValue.Muratura,
  },
  {
    label: 'Ringhiera',
    value: TipologiaParapettoTableValue.Ringhiera,
  },
  {
    label: 'Metallico',
    value: TipologiaParapettoTableValue.Metallico,
  },
  {
    label: 'Legno',
    value: TipologiaParapettoTableValue.Legno,
  },
  {
    label: 'Calcestruzzo',
    value: TipologiaParapettoTableValue.Calcestruzzo,
  },
  {
    label: 'Altro',
    value: TipologiaParapettoTableValue.Altro,
  },
];
export const tipologiaGuardaviaOptions = [
  {
    label: 'Muratura',
    value: TipologiaGuardaviaTableValue.Muratura,
  },
  {
    label: 'Ringhiera',
    value: TipologiaGuardaviaTableValue.Ringhiera,
  },
  {
    label: 'Metallico',
    value: TipologiaGuardaviaTableValue.Metallico,
  },
  {
    label: 'Legno',
    value: TipologiaGuardaviaTableValue.Legno,
  },
  {
    label: 'Calcestruzzo',
    value: TipologiaGuardaviaTableValue.Calcestruzzo,
  },
  {
    label: 'Altro',
    value: TipologiaGuardaviaTableValue.Altro,
  },
];

export const tipologiaSpartitrafficoOptions = [
  {
    label: 'New Jersey',
    value: TipologiaSpartitrafficoTableValue.NewJersey,
  },
  {
    label: 'Guard rail',
    value: TipologiaSpartitrafficoTableValue.Guardrail,
  },
  {
    label: 'Aiuola',
    value: TipologiaSpartitrafficoTableValue.Aiuola,
  },
  {
    label: 'Altro',
    value: TipologiaSpartitrafficoTableValue.Altro,
  },
];
// L1 - Contesto - Accordion 3 Rischio frane
export const MisureMitigazioneOptions = [
  {
    label: 'Fenomeno riconosciuto ma non ancora studiato',
    value: MisureMitigazione.FenomenoRiconosciutoNonStudiato,
  },
  {
    label: ' Fenomeno riconosciuto e studiato',
    value: MisureMitigazione.FenomenoRiconosciutoStudiato,
  },
  {
    label: 'Fenomeno modellato e oggetto di monitoraggio',
    value: MisureMitigazione.FenomenoModellato,
  },
  {
    label: 'Fenomeno oggetto di opere di mitigazione',
    value: MisureMitigazione.FenomenoOggettoOpereMitigazione,
  },
];

export const EstensioneInterferenzaFranaOptions = [
  {
    label: 'Totale',
    value: EstensioneInterferenzaFrana.Totale,
  },
  {
    label: 'Parziale (spalle o pile)',
    value: EstensioneInterferenzaFrana.Parziale,
  },
  {
    label: 'Zona di approccio',
    value: EstensioneInterferenzaFrana.ZonaDiApproccio,
  },
  {
    label: 'Altro',
    value: EstensioneInterferenzaFrana.Altro,
  },
];

export const UnitaFisiograficaOptions = [
  {
    label: 'Montuosa',
    value: UnitaFisiografica.Montuosa,
  },
  {
    label: 'Collinare',
    value: UnitaFisiografica.Collinare,
  },
  {
    label: 'Pianura intermontana',
    value: UnitaFisiografica.PianuraIntermontana,
  },
  {
    label: 'Pianura bassa',
    value: UnitaFisiografica.PianuraBassa,
  },
];

export const FenomenoOptions = [
  {
    label: 'Accertato',
    value: Fenomeno.Accertato,
  },
  {
    label: 'Potenziale',
    value: Fenomeno.Potenziale,
  },
];

export const TipologiaFenomenoOptions = [
  {
    label: 'Crollo in roccia',
    value: TipologiaFenomeno.CrolloRoccia,
  },
  {
    label: 'Ribaltamento',
    value: TipologiaFenomeno.Ribaltamento,
  },
  {
    label: 'Colate e valanghe detritiche',
    value: TipologiaFenomeno.ColateValangheDetritiche,
  },
  {
    label: 'Colate viscose e traslative',
    value: TipologiaFenomeno.ColateViscose,
  },
  {
    label: 'Scorrimento rotazionale',
    value: TipologiaFenomeno.ScorrimtoRotazionale,
  },
  {
    label: 'Scorrimento traslativo',
    value: TipologiaFenomeno.ScorrimentoTraslativo,
  },
  {
    label: 'Complesso e composito',
    value: TipologiaFenomeno.ComplessoComposito,
  },
  {
    label: 'Fenomeni gravitativi profondi',
    value: TipologiaFenomeno.FenomeniGravitativiProfondi,
  },
];

export const DistribuzioneAttivitaOptions = [
  {
    label: 'Costante',
    value: DistribuzioneAttivita.Costante,
  },
  {
    label: 'Retrogressivo',
    value: DistribuzioneAttivita.Retrogressivo,
  },
  {
    label: 'In allargamento',
    value: DistribuzioneAttivita.InAllargamento,
  },
  {
    label: 'Avanzante',
    value: DistribuzioneAttivita.Avanzante,
  },
  {
    label: 'In diminuzione',
    value: DistribuzioneAttivita.InDiminuzione,
  },
  {
    label: 'Confinato',
    value: DistribuzioneAttivita.Confinato,
  },
  {
    label: 'Multidirezionale',
    value: DistribuzioneAttivita.Multidirezionale,
  },
  {
    label: 'Non definibile',
    value: DistribuzioneAttivita.NonDefinibile,
  },
];

export const UsoSuoloAreaPotezialmenteCoinvoltaOptions = [
  {
    label: 'Area urbanizzata',
    value: UsoSuoloAreaPotezialmenteCoinvolta.AreaUrbanizzata,
  },
  {
    label: 'Area estrattiva',
    value: UsoSuoloAreaPotezialmenteCoinvolta.AreaEstrattiva,
  },
  {
    label: 'Seminativo',
    value: UsoSuoloAreaPotezialmenteCoinvolta.Seminativo,
  },
  {
    label: 'Colture',
    value: UsoSuoloAreaPotezialmenteCoinvolta.Colture,
  },
  {
    label: 'Vegetazione riparia',
    value: UsoSuoloAreaPotezialmenteCoinvolta.VegetazioneRiparia,
  },
  {
    label: 'Rimboschimento',
    value: UsoSuoloAreaPotezialmenteCoinvolta.Rimboschimento,
  },
  {
    label: 'Bosco ceduo',
    value: UsoSuoloAreaPotezialmenteCoinvolta.BoscoCeduo,
  },
  {
    label: `Bosco d'alto fusto`,
    value: UsoSuoloAreaPotezialmenteCoinvolta.BoscoAltoFusto,
  },
  {
    label: 'Incolto nudo',
    value: UsoSuoloAreaPotezialmenteCoinvolta.IncoltoNudo,
  },
  {
    label: 'Incolto macchia cespugliato',
    value: UsoSuoloAreaPotezialmenteCoinvolta.IcoltoMacchiaCespugliato,
  },
  {
    label: 'Incolto prato pascolo',
    value: UsoSuoloAreaPotezialmenteCoinvolta.IncoltoPratoPascolo,
  },
  {
    label: 'Altro',
    value: UsoSuoloAreaPotezialmenteCoinvolta.Altro,
  },
];

export const PosizioneInstabilitaVersanteOptions = [
  {
    label: 'Alta',
    value: PosizioneInstabilitaVersante.Alta,
  },
  {
    label: 'Media',
    value: PosizioneInstabilitaVersante.Media,
  },
  {
    label: 'Bassa',
    value: PosizioneInstabilitaVersante.Bassa,
  },
  {
    label: 'Fondovalle',
    value: PosizioneInstabilitaVersante.Fondovalle,
  },
];

export const StatoAttivitaFranaOptions = [
  {
    label: 'Attiva',
    value: StatoAttivitaFrana.Attiva,
  },
  {
    label: 'Inattiva (non attiva da diversi cicli stagionali)',
    value: StatoAttivitaFrana.Inattiva,
  },
  {
    label: 'Stabilizzata',
    value: StatoAttivitaFrana.Stabilizzata,
  },
];

export const FraneAttiveOptions = [
  {
    label: 'Attivo al momento del rilievo o con segni di movimento in atto',
    value: FraneAttive.Attivo,
  },
  {
    label: `Sospesa: attivo nell'ultimo ciclo stagionale, ma che attualmente non si muove`,
    value: FraneAttive.Sospesa,
  },
  {
    label:
      'Quiescente: non attivo da più di un ciclo stagionale ma riattivabile',
    value: FraneAttive.Quiescente,
  },
];

export const GradoCriticitaFranaOptions = [
  {
    label: 'Altamente critica',
    value: GradoCriticitaFrana.AltamenteCritica,
  },
  {
    label: 'Critica',
    value: GradoCriticitaFrana.Critica,
  },
  {
    label: 'Scarsamente critica',
    value: GradoCriticitaFrana.ScarsamenteCritica,
  },
];

export const VelocitaMaxSpostamentoFranaOptions = [
  {
    label: 'Estremamente/molto rapida (> 3m/min)',
    value: VelocitaMaxSpostamentoFrana.EstremamenteMoltoRapida,
  },
  {
    label: 'Rapida (3 m/min - 1,8 m/h)',
    value: VelocitaMaxSpostamentoFrana.Rapida,
  },
  {
    label: 'Moderata (1,8 m/h - 13 m/mese)',
    value: VelocitaMaxSpostamentoFrana.Moderata,
  },
  {
    label: 'Lenta (13 m/mese - 1,6 m/anno)',
    value: VelocitaMaxSpostamentoFrana.Lenta,
  },
  {
    label: 'Estremamente/molto lenta (< 1,6 m/anno)',
    value: VelocitaMaxSpostamentoFrana.EstremamenteMoltoLenta,
  },
];

export const MagnitudoBaseVolumetricaOptions = [
  {
    label: 'Estremamente/molto grande (>10⁶)',
    value: MagnitudoBaseVolumetrica.EstremamenteMoltoGrande,
  },
  {
    label: 'Grande (2,5*10⁵ - 10⁶)',
    value: MagnitudoBaseVolumetrica.Grande,
  },
  {
    label: 'Media (10⁴ - 2,5*10⁵)',
    value: MagnitudoBaseVolumetrica.Media,
  },
  {
    label: 'Piccola (5*10² - 10⁴)',
    value: MagnitudoBaseVolumetrica.Piccola,
  },
  {
    label: 'Molto piccola (<5*10²)',
    value: MagnitudoBaseVolumetrica.MoltoPiccola,
  },
];

// L1 - Contesto - Accordion 4 Rischio idraulico
export const PresenzaStudioIdraulicoLivelliDefinitiOptions = [
  {
    label: 'Sì',
    value: PresenzaStudioIdraulicoLivelliDefiniti.Si,
  },
  {
    label: 'No',
    value: PresenzaStudioIdraulicoLivelliDefiniti.No,
  },
];

export const StrutturaAlveoRischioIdraulicoOptions = [
  {
    label: 'Pile',
    value: StrutturaAlveoRischioIdraulico.Pile,
  },
  {
    label: 'Spalle',
    value: StrutturaAlveoRischioIdraulico.Spalle,
  },
  {
    label: 'Rilevato',
    value: StrutturaAlveoRischioIdraulico.Rilevato,
  },
  {
    label: 'Nessuna',
    value: StrutturaAlveoRischioIdraulico.Nessuna,
  },
];

export const FenomenoSormontoRischioIdraulicoOptions = [
  {
    label: 'Accertato',
    value: FenomenoSormontoRischioIdraulico.Accertato,
  },
  {
    label: 'Ipotizzato',
    value: FenomenoSormontoRischioIdraulico.Ipotizzato,
  },
];

export const FenomenoErosioneRischioIdraulicoOptions = [
  {
    label: 'Accertati',
    value: FenomenoErosioneRischioIdraulico.Accertati,
  },
  {
    label: 'Ipotizzati',
    value: FenomenoErosioneRischioIdraulico.Ipotizzati,
  },
  {
    label: 'Assenti',
    value: FenomenoErosioneRischioIdraulico.Assenti,
  },
];

export const ScenariPericolositaRischioIdraulicoOptions = [
  {
    label: 'P2',
    value: ScenariPericolositaRischioIdraulico.P2,
  },
  {
    label: 'P3',
    value: ScenariPericolositaRischioIdraulico.P3,
  },
  {
    label: 'Nessuno',
    value: ScenariPericolositaRischioIdraulico.Nessuno,
  },
];

export const AreaPericolosaRischioIdraulicoOptions = [
  {
    label: 'Fenomeno riconosciuto ma non ancora studiato',
    value: AreaPericolosaRischioIdraulico.FenomenoRiconosciutoNonStudiato,
  },
  {
    label: 'Fenomeno riconosciuto e studiato',
    value: AreaPericolosaRischioIdraulico.FenomenoRiconosciutoStudiato,
  },
  {
    label: 'Fenomeno modellato e oggetto di monitoraggio',
    value: AreaPericolosaRischioIdraulico.FenomenoModellatoMonitorato,
  },
  {
    label: 'Fenomeno oggetto di opere di mitigazione',
    value: AreaPericolosaRischioIdraulico.FenomenoOggettoOpereMitigazione,
  },
];

export const RicadenteAreaAlluvioniRischioIdraulicoOptions = [
  {
    label: 'Sì',
    value: RicadenteAreaAlluvioniRischioIdraulico.Si,
  },
  {
    label: 'No',
    value: RicadenteAreaAlluvioniRischioIdraulico.No,
  },
];

export const PresenzaRilieviRischioIdraulicoOptions = [
  {
    label: 'Sì',
    value: PresenzaRilieviRischioIdraulico.Si,
  },
  {
    label: 'No',
    value: PresenzaRilieviRischioIdraulico.No,
  },
];

export const BacinoIdrograficoRischioIdraulicoOptions = [
  {
    label: 'Effettivo',
    value: BacinoIdrograficoRischioIdraulico.Effettivo,
  },
  {
    label: 'Presunto',
    value: BacinoIdrograficoRischioIdraulico.Presunto,
  },
];

export const BacinoIdrograficoPresuntoRischioIdraulicoOptions = [
  {
    label: 'S < 100 km²',
    value: BacinoIdrograficoPresuntoRischioIdraulico.S100,
  },
  {
    label: '100 km² ≤ S ≤ 500 km²',
    value: BacinoIdrograficoPresuntoRischioIdraulico.S100500,
  },
  {
    label: 'S > 500 km²',
    value: BacinoIdrograficoPresuntoRischioIdraulico.S500,
  },
];

export const TipologiaReticoloOptions = [
  {
    label: 'Reticolo principale',
    value: TipologiaReticolo.ReticoloPrincipale,
  },
  {
    label: 'Reticolo secondario',
    value: TipologiaReticolo.ReticoloSecondario,
  },
  {
    label: 'Reticolo artificiale di scolo',
    value: TipologiaReticolo.ReticoloArtificiale,
  },
];

export const ConfinamentoAlveoOptions = [
  {
    label:
      'Confinato: per più del 90% del tratto le sponde sono a contatto con versanti',
    value: ConfinamentoAlveo.Confinato,
  },
  {
    label: 'Semiconfinato: situazione intermedia',
    value: ConfinamentoAlveo.Semiconfinato,
  },
  {
    label:
      'Non confinato: per meno del 10% del tratto le sponde sono a contatto con versanti',
    value: ConfinamentoAlveo.NonConfinato,
  },
];

export const MorfologiaAlveoOptions = [
  {
    label: 'A canale singolo',
    value: MorfologiaAlveo.CanaleSingolo,
  },
  {
    label: 'Intrecciato',
    value: MorfologiaAlveo.Intrecciato,
  },
];

export const SinuositaOptions = [
  {
    label: 'Rettilineo',
    value: Sinuosita.Rettilineo,
  },
  {
    label: 'Sinuoso',
    value: Sinuosita.Sinuoso,
  },
  {
    label: 'Meandriforme',
    value: Sinuosita.Meandriforme,
  },
];

export const TipologiaFondoAlveoRischioIdraulicoOptions = [
  {
    label: 'Fondo fisso',
    value: TipologiaFondoAlveoRischioIdraulico.FondoFisso,
  },
  {
    label: 'Fondo mobile',
    value: TipologiaFondoAlveoRischioIdraulico.FondoMobile,
  },
];

export const MaterialeAlveoRischioIdraulicoOptions = [
  {
    label: 'Non valutabile',
    value: MaterialeAlveoRischioIdraulico.NonValutabile,
  },
  {
    label: 'Argilla',
    value: MaterialeAlveoRischioIdraulico.Argilla,
  },
  {
    label: 'Limo',
    value: MaterialeAlveoRischioIdraulico.Limo,
  },
  {
    label: 'Sabbia fine',
    value: MaterialeAlveoRischioIdraulico.SabbiaFine,
  },
  {
    label: 'Sabbia media',
    value: MaterialeAlveoRischioIdraulico.SabbiaMedia,
  },
  {
    label: 'Sabbia grossolana',
    value: MaterialeAlveoRischioIdraulico.SabbiaGrossolana,
  },
  {
    label: 'Ghiaia',
    value: MaterialeAlveoRischioIdraulico.Ghiaia,
  },
  {
    label: 'Ciottoli',
    value: MaterialeAlveoRischioIdraulico.Ciottoli,
  },
  {
    label: 'Massi',
    value: MaterialeAlveoRischioIdraulico.Massi,
  },
  {
    label: 'Terreno inerbito',
    value: MaterialeAlveoRischioIdraulico.TerrenoInerbito,
  },
  {
    label: 'Terra nuda',
    value: MaterialeAlveoRischioIdraulico.TerraNuda,
  },
  {
    label: 'Alveo impermeabilizzato (calcestruzzo, etc.)',
    value: MaterialeAlveoRischioIdraulico.AlveoImpermeabilizzato,
  },
  {
    label: 'Altro',
    value: MaterialeAlveoRischioIdraulico.Altro,
  },
];

export const AccumuloDetritiRischioIdraulicoOptions = [
  {
    label: 'Sì',
    value: AccumuloDetritiRischioIdraulico.Si,
  },
  {
    label: 'No',
    value: AccumuloDetritiRischioIdraulico.No,
  },
];

export const EvoluzioneAlveoRischioIdraulicoOptions = [
  {
    label: 'In equilibrio',
    value: EvoluzioneAlveoRischioIdraulico.Equilibrio,
  },
  {
    label: 'In fase evolutiva',
    value: EvoluzioneAlveoRischioIdraulico.FaseEvolutiva,
  },
  {
    label: 'Stabilizzato: in equilibrio grazie a opere antropiche',
    value: EvoluzioneAlveoRischioIdraulico.Stabilizzato,
  },
];

export const CurvaturaAlveoRischioIdraulicoOptions = [
  {
    label: 'Sì',
    value: CurvaturaAlveoRischioIdraulico.Si,
  },
  {
    label: 'No',
    value: CurvaturaAlveoRischioIdraulico.No,
  },
];

export const DivagazioneAlveoRischioIdraulicoOptions = [
  {
    label: 'Sì',
    value: DivagazioneAlveoRischioIdraulico.Si,
  },
  {
    label: 'No',
    value: DivagazioneAlveoRischioIdraulico.No,
  },
];

export const TipologiaCorsoAcquaRischioIdraulicoOptions = [
  {
    label: "Caso I: Corso d'acqua arginato",
    value: TipologiaCorsoAcquaRischioIdraulico.CasoI,
  },
  {
    label:
      "Caso II: Corso d'acqua non arginato con possibilità di espansione laterale",
    value: TipologiaCorsoAcquaRischioIdraulico.CasoII,
  },
  {
    label:
      "Caso III: Corso d'acqua non arginato senza possibilità di espansione laterale e pile molto alte",
    value: TipologiaCorsoAcquaRischioIdraulico.CasoIII,
  },
  {
    label:
      "Caso IV:  Corso d'acqua non arginato senza possibilità di espansione laterale",
    value: TipologiaCorsoAcquaRischioIdraulico.CasoIV,
  },
];

export const TipologiaCorsoAcquaRischioIdraulicoShortOptions = [
  {
    label: 'Caso I',
    value: TipologiaCorsoAcquaRischioIdraulico.CasoI,
  },
  {
    label: 'Caso II',
    value: TipologiaCorsoAcquaRischioIdraulico.CasoII,
  },
  {
    label: 'Caso III',
    value: TipologiaCorsoAcquaRischioIdraulico.CasoIII,
  },
  {
    label: 'Caso IV',
    value: TipologiaCorsoAcquaRischioIdraulico.CasoIV,
  },
];

export const DeposizioneSedimentiOptions = [
  {
    label: 'Sì',
    value: DeposizioneSedimenti.Si,
  },
  {
    label: 'No',
    value: DeposizioneSedimenti.No,
  },
];

export const MaterialeVegetaleRischioIdraulicoOptions = [
  {
    label: 'Sì',
    value: MaterialeVegetaleRischioIdraulico.Si,
  },
  {
    label: 'No',
    value: MaterialeVegetaleRischioIdraulico.No,
  },
];

export const FrancoIdraulicoSuperioreOptions = [
  {
    label: 'Sì',
    value: FrancoIdraulicoSuperiore.Si,
  },
  {
    label: 'No',
    value: FrancoIdraulicoSuperiore.No,
  },
];

export const AreeGolenaliAssentiOptions = [
  {
    label: 'Sì',
    value: AreeGolenaliAssenti.Si,
  },
  {
    label: 'No',
    value: AreeGolenaliAssenti.No,
  },
];

export const EvidenzeProfonditaDelleFondazioniOptions = [
  {
    label: 'Profonde',
    value: EvidenzeProfonditaDelleFondazioni.Profonde,
  },
  {
    label: 'Superficiali',
    value: EvidenzeProfonditaDelleFondazioni.Superficiali,
  },
  {
    label: 'Nessuna evidenza',
    value: EvidenzeProfonditaDelleFondazioni.NessunaEvidenza,
  },
];

export const AbbassamentoGeneralizzatoAlveoOptions = [
  {
    label: 'Sì',
    value: AbbassamentoGeneralizzatoAlveo.Si,
  },
  {
    label: 'No',
    value: AbbassamentoGeneralizzatoAlveo.No,
  },
];

export const AbbassamentoAccentuatoAlleFondazioniOptions = [
  {
    label: 'Sì',
    value: AbbassamentoAccentuatoAlleFondazioni.Si,
  },
  {
    label: 'No',
    value: AbbassamentoAccentuatoAlleFondazioni.No,
  },
];

export const AccumuliDetritiAMontePilaOptions = [
  {
    label: 'Sì',
    value: AccumuliDetritiAMontePila.Si,
  },
  {
    label: 'No',
    value: AccumuliDetritiAMontePila.No,
  },
];

export const PericolositaPAIPSAIFraneOptions = [
  {
    label: 'AA: Aree di attenzione',
    value: PericolositaPAIPSAIFrane.AA,
  },
  {
    label: 'P1: Aree a pericolosità da frana moderata',
    value: PericolositaPAIPSAIFrane.P1,
  },
  {
    label: 'P2: Aree a pericolosità da frana media',
    value: PericolositaPAIPSAIFrane.P2,
  },
  {
    label: 'P3: Aree a pericolosità da frana elevata',
    value: PericolositaPAIPSAIFrane.P3,
  },
  {
    label: 'P4: Aree a pericolosità da frana molto elevata',
    value: PericolositaPAIPSAIFrane.P4,
  },
  {
    label: 'Area non mappata',
    value: PericolositaPAIPSAIFrane.AreaNonMappata,
  },
];

export const PericolositaPAIPSAIFraneShortOptions = [
  {
    label: 'AA',
    value: PericolositaPAIPSAIFrane.AA,
  },
  {
    label: 'P1',
    value: PericolositaPAIPSAIFrane.P1,
  },
  {
    label: 'P2',
    value: PericolositaPAIPSAIFrane.P2,
  },
  {
    label: 'P3',
    value: PericolositaPAIPSAIFrane.P3,
  },
  {
    label: 'P4',
    value: PericolositaPAIPSAIFrane.P4,
  },
  {
    label: 'Area non mappata',
    value: PericolositaPAIPSAIFrane.AreaNonMappata,
  },
];

export const OpereProtezioneSpondaleOptions = [
  {
    label: 'Scogliera',
    value: OpereProtezioneSpondale.Scogliera,
  },
  {
    label: 'Gabbioni',
    value: OpereProtezioneSpondale.Gabbioni,
  },
  {
    label: 'Pennelli',
    value: OpereProtezioneSpondale.Pennelli,
  },
  {
    label: 'Muro di sponda',
    value: OpereProtezioneSpondale.MuroDiSponda,
  },
  {
    label: 'Alveo canalizzato',
    value: OpereProtezioneSpondale.AlveoCanalizzato,
  },
  {
    label: 'Argine',
    value: OpereProtezioneSpondale.Argine,
  },
  {
    label: 'Altro',
    value: OpereProtezioneSpondale.Altro,
  },
  {
    label: 'Nessuna',
    value: OpereProtezioneSpondale.Nessuna,
  },
];

export const StatoConservazioneAdeguatezzaOperaOptions = [
  {
    label: 'Adeguata e integra',
    value: StatoConservazioneAdeguatezzaOpera.AdeguataIntegra,
  },
  {
    label: 'Non adeguata',
    value: StatoConservazioneAdeguatezzaOpera.NonAdeguata,
  },
  {
    label: 'Danneggiata',
    value: StatoConservazioneAdeguatezzaOpera.Danneggiata,
  },
];

export const ProtezioneAlPiedePileSpalleOptions = [
  {
    label: 'Sì',
    value: ProtezioneAlPiedePileSpalle.Si,
  },
  {
    label: 'No',
    value: ProtezioneAlPiedePileSpalle.No,
  },
];

export const StatoConservazioneProtezionePiedeOptions = [
  {
    label: 'Adeguata e integra',
    value: StatoConservazioneProtezionePiede.AdeguataIntegra,
  },
  {
    label: 'Non adeguata',
    value: StatoConservazioneProtezionePiede.NonAdeguata,
  },
  {
    label: 'Danneggiata',
    value: StatoConservazioneProtezionePiede.Danneggiata,
  },
];

export const BrigliaProtezioneValleDelPonteOptions = [
  {
    label: 'Sì',
    value: BrigliaProtezioneValleDelPonte.Si,
  },
  {
    label: 'No',
    value: BrigliaProtezioneValleDelPonte.No,
  },
];

export const StatoConservazioneAdeguatezzaOperaBrigliaOptions = [
  {
    label: 'Adeguata e integra',
    value: StatoConservazioneAdeguatezzaOperaBriglia.AdeguataIntegra,
  },
  {
    label: 'Non adeguata',
    value: StatoConservazioneAdeguatezzaOperaBriglia.NonAdeguata,
  },
  {
    label: 'Danneggiata',
    value: StatoConservazioneAdeguatezzaOperaBriglia.Danneggiata,
  },
];

export const OpereArginaturaDiAlterazionePortateOptions = [
  {
    label: 'Presenti',
    value: OpereArginaturaDiAlterazionePortate.Presenti,
  },
  {
    label: 'Assenti',
    value: OpereArginaturaDiAlterazionePortate.Assenti,
  },
];

export const StatoConservazioneAdeguatezzaOpereArginaturaOptions = [
  {
    label: 'Adeguate',
    value: StatoConservazioneAdeguatezzaOpereArginatura.Adeguate,
  },
  {
    label: 'Non adeguate',
    value: StatoConservazioneAdeguatezzaOpereArginatura.NonAdeguate,
  },
];

export const MisureLaminazioneMitigazioneOptions = [
  {
    label: 'Presenti',
    value: MisureLaminazioneMitigazione.Presenti,
  },
  {
    label: 'Assenti',
    value: MisureLaminazioneMitigazione.Assenti,
  },
];

export const StatoConservazioneAdeguatezzaOpereLaminazioneOptions = [
  {
    label: 'Adeguate',
    value: StatoConservazioneAdeguatezzaOpereLaminazione.Adeguate,
  },
  {
    label: 'Non adeguate',
    value: StatoConservazioneAdeguatezzaOpereLaminazione.NonAdeguate,
  },
];

export const sistemaMonitoraggioRischioIdraulicoOptions = [
  {
    label: 'Presenti',
    value: sistemaMonitoraggioRischioIdraulico.Presenti,
  },
  {
    label: 'Assenti',
    value: sistemaMonitoraggioRischioIdraulico.Assenti,
  },
];

export const statoConservazioneSistemaMonitoraggioRischioIdraulicoOptions = [
  {
    label: 'Adeguate',
    value: statoConservazioneSistemaMonitoraggioRischioIdraulico.Adeguate,
  },
  {
    label: 'Non adeguate',
    value: statoConservazioneSistemaMonitoraggioRischioIdraulico.NonAdeguate,
  },
];

// L1 - Struttura - Accordion 5 Aspetti critici
export const ElementiCriticiStatoDegradoOptions = [
  {
    label:
      'Condizioni tali da non doverli segnalare immediatamente (assenza di uno stato di degrado avanzato)',
    value: ElementiCriticiStatoDegrado.NonSegnalareImmediatamente,
  },
  {
    label:
      ' Condizioni tali da doverli segnalare immediatamente (presenza di uno stato di degrado avanzato)',
    value: ElementiCriticiStatoDegrado.SegnalareImmediatamente,
  },
  {
    label: 'Non ispezionabili',
    value: ElementiCriticiStatoDegrado.NonIspezionabili,
  },
];
export const SismicitiaAreaValApprossimativoOptions = [
  {
    label: 'ag >= 0,25 g',
    value: SismicitiaAreaValApprossimativo.agMaggiore25,
  },
  {
    label: '0,15 g <= ag < 0,25 g',
    value: SismicitiaAreaValApprossimativo.ag15e25,
  },
  {
    label: '0,10 g >= ag < 0,15 g',
    value: SismicitiaAreaValApprossimativo.ag10e15,
  },
  {
    label: '0,05 g <= ag < 0,10 g',
    value: SismicitiaAreaValApprossimativo.ag05e10,
  },
  {
    label: 'ag < 0,05 g',
    value: SismicitiaAreaValApprossimativo.agMinore005,
  },
];
export const ElCriticiSismicaStatoDegradoOptions = [
  {
    label:
      'Condizioni tali da non doverli segnalare immediatamente (assenza di uno stato di degrado avanzato)',
    value: ElCriticiSismicaStatoDegrado.NonSegnalareImmediatamente,
  },
  {
    label:
      ' Condizioni tali da doverli segnalare immediatamente (presenza di uno stato di degrado avanzato)',
    value: ElCriticiSismicaStatoDegrado.SegnalareImmediatamente,
  },
  {
    label: 'Non ispezionabili',
    value: ElCriticiSismicaStatoDegrado.NonIspezionabili,
  },
];

export const CondizioniCriticheStrutturaleTipologiaElOptions = [
  {
    label: 'Quadri fessurativi molto estesi ed intensi',
    value: CondizioniCriticheStrutturaleTipologiaEl.QuadriFessurativiEstesi,
  },
  {
    label: 'Cinematismi in atto',
    value: CondizioniCriticheStrutturaleTipologiaEl.Cinematismi,
  },
  {
    label: 'Meccanismi di incipiente perdita di appoggio',
    value: CondizioniCriticheStrutturaleTipologiaEl.IncipientePerditaAppoggio,
  },
  {
    label: 'Giunzioni di elementi chiave per la staticità del ponte',
    value: CondizioniCriticheStrutturaleTipologiaEl.GiunzioneElementiChiave,
  },
  {
    label: 'Altro',
    value: CondizioniCriticheStrutturaleTipologiaEl.Altro,
  },
];

export const CondizioniCriticheSismicaTipologiaElOptions = [
  {
    label: 'Quadri fessurativi molto estesi ed intensi',
    value: CondizioniCriticheSismicaTipologiaEl.QuadriFessurativiEstesi,
  },
  {
    label: 'Cinematismi in atto',
    value: CondizioniCriticheSismicaTipologiaEl.Cinematismi,
  },
  {
    label: 'Meccanismi di incipiente perdita di appoggio',
    value: CondizioniCriticheSismicaTipologiaEl.IncipientePerditaAppoggio,
  },
  {
    label: 'Giunzioni di elementi chiave per la staticità del ponte',
    value: CondizioniCriticheSismicaTipologiaEl.GiunzioneElementiChiave,
  },
  {
    label: 'Altro',
    value: CondizioniCriticheSismicaTipologiaEl.Altro,
  },
];

export const CDAOptions = [
  {
    label: 'Alta',
    value: CDAStrutturale.Alta,
  },
  {
    label: 'Medio Alta',
    value: CDAStrutturale.MedioAlta,
  },
  {
    label: 'Media',
    value: CDAStrutturale.Media,
  },
  {
    label: 'Medio Bassa',
    value: CDAStrutturale.MedioBassa,
  },
  {
    label: 'Bassa',
    value: CDAStrutturale.Bassa,
  },
  {
    label: 'n.d.',
    value: null,
  },
];

export const DifettositaSovrastruttureEServiziOptions = [
  {
    label: 'Nessun difetto',
    value: DifettositaSovrastruttureEServizi.NessunDifetto,
  },
  {
    label: 'Non presente',
    value: DifettositaSovrastruttureEServizi.NonPresente,
  },
  {
    label: 'Difetto',
    value: DifettositaSovrastruttureEServizi.Difetto,
  },
  {
    label: 'Non previsto',
    value: DifettositaSovrastruttureEServizi.NonPrevisto,
  },
  {
    label: 'Non disponibile',
    value: DifettositaSovrastruttureEServizi.NonDisponibile,
  },
];

export const DifettositaStrutturaOptions = [
  {
    label: 'Nessun difetto',
    value: DifettositaStruttura.NessunDifetto,
  },
  {
    label: 'Difetto',
    value: DifettositaStruttura.Difetto,
  },
  {
    label: 'Non compilata',
    value: DifettositaStruttura.NonCompilata,
  },
];

export const projectTypologyOptions = convertTSEnumToSelectOptions([
  'Preliminare',
  'Definitivo',
  'Esecutivo',
  'Varianti',
  'Direzione Lavori',
  'Localizzazione',
  'Collaudo',
]);

export const activityTypologyOptions = convertTSEnumToSelectOptions([
  'Ispezioni',
  'Segnalazioni',
  'Interventi strutturali',
  'Attività di monitoraggio',
  'Interventi di manutenzione',
]);

export const mediaMaterialSelectOptions = convertTSEnumToSelectOptions([
  'Muratura',
  'CA',
  'CAP',
  'Calcestruzzo',
  'Acciaio o metallo',
  'Legno',
  'Gomma',
  'Gomma armata',
  'Gomma e teflon',
  'Acciaio e teflon',
  'Rete nella pavimentazione',
  'Profilati elastomerici',
  'Strisce in gomma',
  'Pettini',
  'Tamponi o giunti Sottopavimentazione',
  'Asfalto',
  'Pietra',
  'Autobloccanti',
  'Ringhiera',
  'New Jersey',
  'GuardRail',
  'Aiuola',
  'Ghisa',
  'Plastica',
  'Altro',
  'Non rilevabile',
]);

export const mediaElementSelectOptions = convertTSEnumToSelectOptions([
  'Spalle',
  'Pile',
  'Appoggi',
  'Giunti',
  'Pulvini',
  'Antenne',
  'Altri dispositivi Antisismici',
  'Soletta',
  'Travi',
  'Traversi',
  'Controventi',
  'Archi',
  'Piedritti',
  'Tiranti',
  'Elementi extra',
  'Pavimentazione',
  'Cordoli',
  'Marciapiede',
  'Parapetti',
  'Guardavia',
  'Spartitraffico',
  'Pali illuminazione',
  'Convogliamento acque',
  'Sottoservizi',
]);

export const criticalAspectSelectOptions = convertTSEnumToSelectOptions([
  'Elementi critici stuttura',
  'Elementi critici sismica',
  'Condizioni critiche struttura',
  'Condizioni critiche sismica',
  'Elementi vulnerabilità sismica',
]);
export const fondazioniSelectOptions = convertTSEnumToSelectOptions([
  'Dirette',
  'Indirette',
  'Non rilevabili',
]);
export const pileAlveoSelectOptions = convertTSEnumToSelectOptions([
  'Si',
  'No',
]);
export const mediaTypologySelectOptions = convertTSEnumToSelectOptions([
  'Acciaio',
  'Muratura',
  'C.A./C.A.P.',
  'C.A.P.',
  'Legno',
  'Appoggi',
  'Generici',
  'Giunti',
  'Elementi accessori',
]);
export const difettositaSelectOptions = convertTSEnumToSelectOptions([
  '1',
  '2',
  '3',
  '4',
  '5',
]);

export const psSelectOptions = [
  {
    label: 'Sì',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export const materialeAlveoSelectOptions = convertTSEnumToSelectOptions([
  'Non valutabile',
  'Argilla',
  'Limo',
  'Sabbia fine',
  'Sabbia media',
  'Sabbia gossolana',
  'Ghiaia',
  'Ciottoli',
  'Massi',
  'Terreno inerbito',
  'Terra nuda',
  'Alveo impermeabilizzato',
  'Altro',
]);
export const protezioniSelectOptions = convertTSEnumToSelectOptions([
  'Spondale',
  'Pile e spalle',
  'Briglia',
]);
export const accumuliSelectOptions = convertTSEnumToSelectOptions([
  'Deposito sedimenti',
  'Trasporto materiale',
  'Accumuli al piede della pila',
]);
export const exportTypeOptions = convertTSEnumToSelectOptions([
  'Livello 0',
  'Livello 1',
  'Livello 2',
  'Relazione',
  'csv',
  'AINOP',
]);
