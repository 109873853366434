import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import {
  FormSubmitButtons,
  RHFNumberTextField,
  RHFTagAutocomplete,
} from 'components';

import { useInspectionPath } from 'hooks';
import useTabellaRiepilogativa from 'hooks/inspections/useTabellaRiepilogativa';
import { useInspectionSelector } from 'stores';

const SpallePileForm = ({ spallePileFields, selected, setSelected }) => {
  const { getValues, setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  /**
   * this hook is used to refresh the riepilogoStrutturaTable
   * since that the number of elements/or subelement that can be changed here in L0'
   * will unvalidate any other previous calculation on the CDA
   */

  const { recalculateTable } = useTabellaRiepilogativa();

  const methods = useFormContext({
    defaultValues: {
      codiceTableValue: [],
      appoggiTableValue: '',
      giuntiTableValue: '',
      pulviniTableValue: '',
      antenneTableValue: '',
      dispositiviSismiciTableValue: '',
    },
  });

  useEffect(() => {
    setValue('codiceTableValue', selected);
  }, [selected]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitData = () => {
    const appoggiTableValue = getValues('appoggiTableValue');
    const giuntiTableValue = getValues('giuntiTableValue');
    const pulviniTableValue = getValues('pulviniTableValue');
    const antenneTableValue = getValues('antenneTableValue');
    const dispositiviSismiciTableValue = getValues(
      'dispositiviSismiciTableValue'
    );

    const ids = selected.map(el => el.codice);

    const codici = [...spallePileFields];

    codici.map((x, index) => {
      if (ids.includes(x?.codice)) {
        codici[index] = {
          id: x.id,
          codice: x.codice,
          appoggi: appoggiTableValue,
          giunti: giuntiTableValue,
          pulvini: pulviniTableValue,
          antenne: antenneTableValue,
          dispositiviSismici: dispositiviSismiciTableValue,
        };
      }
    });

    setValue('spallePileTable', codici);
    onResetSpallePile();
  };

  const onResetSpallePile = () => {
    setValue('codiceTableValue', []);
    setValue('appoggiTableValue', '');
    setValue('giuntiTableValue', '');
    setValue('pulviniTableValue', '');
    setValue('antenneTableValue', '');
    setValue('dispositiviSismiciTableValue', '');
    setSelected([]);
  };

  const watchSpallePileCollegamenti = watch('spallePileCollegamentiTable');
  useEffect(() => {
    if (watchSpallePileCollegamenti?.length > 0) {
      recalculateTable();
    }
  }, [watchSpallePileCollegamenti]);

  return (
    <Grid container spacing={2} padding="20px">
      <Grid item xs={6}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-spalla-pila"
          name={'codiceTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={spallePileFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Elementi selezionati"
          disabled={inspectionDisabled || isViewingInspection}
        />
      </Grid>
      <Grid item xs={3}>
        <RHFNumberTextField
          name="appoggiTableValue"
          label={'Appoggi (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          onChange={e => {
            methods.setValue('appoggiTableValue', e.target.value);
          }}
          disabled={inspectionDisabled || isViewingInspection}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="giuntiTableValue"
          label={'Giunti (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          onChange={e => {
            methods.setValue('giuntiTableValue', e.target.value);
          }}
          disabled={inspectionDisabled || isViewingInspection}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="pulviniTableValue"
          label={'Pulvini (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          onChange={e => {
            methods.setValue('pulviniTableValue', e.target.value);
          }}
          disabled={inspectionDisabled || isViewingInspection}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="antenneTableValue"
          label={'Antenne (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          onChange={e => {
            methods.setValue('antenneTableValue', e.target.value);
          }}
          disabled={inspectionDisabled || isViewingInspection}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFNumberTextField
          name="dispositiviSismiciTableValue"
          label={'Altri dispositivi antisismici (n°)*'}
          defaultValue=""
          decimalScale={0}
          withValueLimit={({ value }) => value <= 999}
          onChange={e => {
            methods.setValue('dispositiviSismiciTableValue', e.target.value);
          }}
          disabled={inspectionDisabled || isViewingInspection}
        />
      </Grid>

      <Grid item xs={3} alignSelf="center">
        <FormSubmitButtons
          disabled={inspectionDisabled || isViewingInspection}
          onSubmit={onSubmitData}
          onReset={onResetSpallePile}
        />
      </Grid>
    </Grid>
  );
};
export { SpallePileForm };

SpallePileForm.propTypes = {
  spallePileFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codiceTableValue: PropTypes.string,
      AppoggiTableValue: PropTypes.number,
      giuntiTableValue: PropTypes.number,
      pulviniTableValue: PropTypes.number,
      antenneTableValue: PropTypes.number,
      dispositiviSismiciTableValue: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};
