import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { CardOverview, MaterialBox } from 'components';
import { STRUCTURES_PATHS } from 'constants/paths';
import { DifettositaSovrastruttureEServiziOptions } from 'constants/selectOptions';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import { handleDynamicInspectionsTabChange } from 'utils/utilities';
import { Row } from '../TabL1.styles';

const Servizi = ({ overviewL1 }) => {
  const params = useParams();
  const id = params.structureId;
  const navigate = useNavigate();

  const lastInspectionId = overviewL1?.requestUserLastValidInspectionId;

  const paliIlluminazione = overviewL1?.servizi?.paliIlluminazione?.difettosita;
  const convogliamentoAcque =
    overviewL1?.servizi?.convogliamentoAcque?.difettosita;
  const sottoservizio = overviewL1?.servizi?.sottoservizi?.difettosita;
  return (
    <CardOverview
      title={'Servizi'}
      subtitle={`Rilievo dei degradi`}
      buttonText={'Approfondisci'}
      buttonDisabled={lastInspectionId ? false : true}
      height="auto"
      onClick={() =>
        handleDynamicInspectionsTabChange(
          inspectionTabMapping.L1,
          inspectionTabMapping.Servizi,
          null,
          navigate,
          STRUCTURES_PATHS.STRUCTURES_LIST,
          id,
          lastInspectionId
        )
      }
    >
      <Row style={{ marginTop: 12 }}>
        <MaterialBox
          topic={'Pali illuminazione'}
          defect={paliIlluminazione}
          enumOptions={DifettositaSovrastruttureEServiziOptions}
        />
        <MaterialBox
          topic={'Convogliamento acque'}
          defect={convogliamentoAcque}
          enumOptions={DifettositaSovrastruttureEServiziOptions}
        />
        <MaterialBox
          topic={'Sottoservizi'}
          defect={sottoservizio}
          enumOptions={DifettositaSovrastruttureEServiziOptions}
        />
      </Row>
    </CardOverview>
  );
};

export { Servizi };

Servizi.propTypes = {
  overviewL1: PropTypes.obj,
};
