import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const MediaBoxContainer = styled('div')({
  width: '130px',
  padding: '5px',
  '& h5': {
    lineHeight: '0',
    marginBottom: 15,
    fontSize: 14,
    whiteSpace: 'no-wrap',
  },
});

export const MediaBoxSection = styled('div')({
  height: '125px',
  width: '100%',
  backgroundColor: colors.GREY,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    height: '28px',
    marginTop: '30px',
    marginBottom: '25px',
  },
  '& span': {
    fontSize: '14px',
    fontWeight: 'bold',
  },
});
