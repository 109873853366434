import { MediaGroup } from './inspections';

export const MAX_FILE_SIZE_LIMIT = 100000000;
export const DOCUMENT_FILE_SIZE_LIMIT = 100000000;
export const VIDEO_FILE_SIZE_LIMIT = 100000000;
export const IMAGE_FILE_SIZE_LIMIT = 10000000;

export const DataTypeFileLimitMap = {
  file: MAX_FILE_SIZE_LIMIT,
  image: IMAGE_FILE_SIZE_LIMIT,
  video: VIDEO_FILE_SIZE_LIMIT,
  document: VIDEO_FILE_SIZE_LIMIT,
};

export const structureDocumentTabs = [
  { label: 'Amministrativi', value: MediaGroup.Amministrativi }, // amministrativi
  { label: 'Progetto', value: MediaGroup.Progetto }, // amministrativi
  { label: 'Attività Pregresse', value: MediaGroup.AttivitaPregresse }, // amministrativi
  { label: 'Idraulica', value: MediaGroup.Idraulica }, // amministrativi
  { label: 'Frane', value: MediaGroup.Frane }, // amministrativi
  { label: 'Elaborati Grafici', value: MediaGroup.ElaboratiGrafici }, // amministrativi
  { label: 'Report', value: MediaGroup.Esportazioni }, // amministrativi
];

export const IMAGE_CAROUSEL_MAX_IMAGES = 6;
