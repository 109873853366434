import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { TipologiaTraveTableValue } from 'constants/inspections';
import {
  tipologiaTraveOptions,
  tipoTraveOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
const TraviForm = ({
  traviFields,
  selected,
  setSelected,
  traviTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t2a5_traviTableValue: [],
      l0t2a5_tipologiaTraveTableValue: '',
      l0t2a5_tipoTraveTableValue: '',
      l0t2a5_altezzaSviluppoLateraleTraveTableValue: '',
      l0t2a5_lunghezzaTraveTableValue: '',
      l0t2a5_spessoreTraveTableValue: '',
      l0t2a5_superficieTraveTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a5_traviTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetTravi();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeTravi = () => {
    const l0t2a5_tipologiaTraveTableValue = getValues(
      'l0t2a5_tipologiaTraveTableValue'
    );
    const l0t2a5_tipoTraveTableValue = getValues('l0t2a5_tipoTraveTableValue');
    const l0t2a5_altezzaSviluppoLateraleTraveTableValue = getValues(
      'l0t2a5_altezzaSviluppoLateraleTraveTableValue'
    );
    const l0t2a5_lunghezzaTraveTableValue = getValues(
      'l0t2a5_lunghezzaTraveTableValue'
    );
    const l0t2a5_spessoreTraveTableValue = getValues(
      'l0t2a5_spessoreTraveTableValue'
    );
    const l0t2a5_superficieTraveTableValue = getValues(
      'l0t2a5_superficieTraveTableValue'
    );

    const ids = selected.map(el => el.codice);

    const travi = [...traviFields];

    travi.map((x, index) => {
      if (ids.includes(x?.codice)) {
        travi[index] = {
          ...x,
          id: x.id,
          codice: x.codice,
          tipologiaTrave: l0t2a5_tipologiaTraveTableValue,
          tipoTrave: l0t2a5_tipoTraveTableValue,
          altezzaSviluppoLateraleTrave:
            l0t2a5_altezzaSviluppoLateraleTraveTableValue,
          lunghezzaTrave: l0t2a5_lunghezzaTraveTableValue,
          spessoreTrave: l0t2a5_spessoreTraveTableValue,
          superficieTrave: l0t2a5_superficieTraveTableValue,
          ...(l0t2a5_tipologiaTraveTableValue !== x.tipologiaTrave
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'trave',
                  l0t2a5_tipologiaTraveTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(traviTable, travi);
    onResetTravi();
  };

  const onConfirmChangeTravi = () => {
    setOpenConfirmationDialog(false);
    changeTravi();
  };

  const onSubmitTravi = () => {
    const l0t2a5_tipologiaTraveTableValue = getValues(
      'l0t2a5_tipologiaTraveTableValue'
    );
    const ids = selected.map(el => el.codice);

    const travi = [...traviFields];

    const hasChangedTipologia = travi.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a5_tipologiaTraveTableValue !== el.tipologiaTrave &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaTrave)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeTravi();
    }
  };

  const onResetTravi = () => {
    setValue('l0t2a5_traviTableValue', []);
    setValue('l0t2a5_tipologiaTraveTableValue', '');
    setValue('l0t2a5_tipoTraveTableValue', '');
    setValue('l0t2a5_altezzaSviluppoLateraleTraveTableValue', '');
    setValue('l0t2a5_lunghezzaTraveTableValue', '');
    setValue('l0t2a5_spessoreTraveTableValue', '');
    setValue('l0t2a5_superficieTraveTableValue', '');

    setSelected([]);
  };

  const tipologiaTraveWatch = watch('l0t2a5_tipologiaTraveTableValue');
  const [disabledTipoTrave, setDisabledTipoTrave] = useState(true);

  useEffect(() => {
    if (tipologiaTraveWatch !== TipologiaTraveTableValue.AcciaioOMetallo) {
      setValue('l0t2a5_tipoTraveTableValue', '');
      setDisabledTipoTrave(true);
    } else {
      setDisabledTipoTrave(false);
    }
  }, [tipologiaTraveWatch]);

  // [(2*Altezza*Lunghezza)+(Spessore*Lunghezza)]/10000
  const altezzaTraveWatch = parseFloat(
    watch('l0t2a5_altezzaSviluppoLateraleTraveTableValue')
  );
  const lunghezzaTraveWatch = parseFloat(
    watch('l0t2a5_lunghezzaTraveTableValue')
  );
  const spessoreTraveWatch = parseFloat(
    watch('l0t2a5_spessoreTraveTableValue')
  );

  useEffect(() => {
    const result = (
      (2 * altezzaTraveWatch * lunghezzaTraveWatch +
        spessoreTraveWatch * lunghezzaTraveWatch) /
      10000
    ).toFixed(2);
    setValue('l0t2a5_superficieTraveTableValue', result);
  }, [altezzaTraveWatch, lunghezzaTraveWatch, spessoreTraveWatch]);

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={6} infoMessage={INFO.traviSelezionate.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-travi"
            name={'l0t2a5_traviTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={traviFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Travi selezionate"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_tipologiaTraveTableValue"
            label="Tipologia Trave*"
            defaultValue={''}
            onChange={e => {
              setValue('l0t2a5_tipologiaTraveTableValue', e.target.value);
            }}
            selectOptions={tipologiaTraveOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_tipoTraveTableValue"
            label="Tipo trave"
            defaultValue={''}
            disabled={disabledTipoTrave}
            onChange={e => {
              setValue('l0t2a5_tipoTraveTableValue', e.target.value);
            }}
            selectOptions={tipoTraveOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_altezzaSviluppoLateraleTraveTableValue"
            label={'Altezza/Sviluppo laterale (H) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_lunghezzaTraveTableValue"
            label={'Lunghezza (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_spessoreTraveTableValue"
            label={'Spessore (s) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <InfoWrapper size={2} infoMessage={INFO.superficieTrave.infoMessage}>
          <RHFNumberTextField
            name="l0t2a5_superficieTraveTableValue"
            label={'Superficie [m²]'}
            defaultValue=""
            disabled
            InputLabelProps={{ shrink: true }}
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </InfoWrapper>
        <GridWrap item xs={1}>
          <FormSubmitButtons onSubmit={onSubmitTravi} onReset={onResetTravi} />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeTravi}
      />
    </>
  );
};

TraviForm.propTypes = {
  traviFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaTrave: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      tipoTrave: PropTypes.number,
      altezzaSviluppoLateraleTrave: PropTypes.number,
      lunghezzaTrave: PropTypes.number,
      spessoreTrave: PropTypes.number,
      superficieTrave: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  traviTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default TraviForm;
