export const markdown_faq_23_1 = `Per definire gli elementi del ponte:

Nella sezione *L0>Struttura>Caratteristiche geometriche*:
1. Definire il numero di campate presenti
2. Per ogni campata definire alcune caratteristiche come la luce e la tipologia di ente scavalcato.`;
export const markdown_faq_23_2 = `Nella sezione *L0>Caratteristiche strutturali*:
1. Scegliere tra due tipologie di schema, con o senza mensole
2. Eventualmente definire il numero di mensole e travi tampone.`;
export const markdown_faq_23_3 = `Per ogni spalla/pila è possibile definire il numero di Appoggi, Giunti, Pulvini… 
Per ogni Campata (o alternativamente Mensola e Trave tampone) il numero di Travi, Traversi, Solette…

Il sistema è così in grado di assegnare ad ogni elemento un **codice univoco** definito secondo il sistema di riferimento adottato.`;
export const markdown_faq_23_4 = `Per ogni elemento, nelle sezioni di Elevazione, Collegamenti e Impalcato, è possibile:
- nel Livello 0 definire geometria e materiale;
- nel Livello 1 compilare le schede di valutazione dei difetti.`;
