import { Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import calendarBlue from 'assets/icons/calendar_blue.png';
import calendarGrey from 'assets/icons/calendar_grey.png';
import userBlue from 'assets/icons/user_blue.png';
import userGrey from 'assets/icons/user_grey.png';
import { CardOverview } from 'components';
import { STRUCTURES_PATHS } from 'constants/paths';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import { formatData, handleDynamicInspectionsTabChange } from 'utils/utilities';
import { Row } from '../TabL1.styles';

const Ispezioni = ({ overviewL1 }) => {
  const params = useParams();
  const id = params.structureId;
  const navigate = useNavigate();

  const lastInspectionId = overviewL1?.requestUserLastValidInspectionId;

  const ispezioneFrana = overviewL1?.ispezioni?.frana;
  const dataIspezioneFrana = formatData(ispezioneFrana?.data);
  const ispezioneIdraulica = overviewL1?.ispezioni?.idraulica;
  const dataIspezioneIdraulica = formatData(ispezioneIdraulica?.data);
  const ispezioneStruttura = overviewL1?.ispezioni?.struttura;
  const dataIspezioneStruttura = formatData(ispezioneStruttura?.data);

  return (
    <CardOverview
      title={'Ispezioni'}
      buttonText={'Approfondisci'}
      /* onClick={() => setValue(3)} */
      buttonDisabled={lastInspectionId ? false : true}
      height="auto"
      onClick={() =>
        handleDynamicInspectionsTabChange(
          inspectionTabMapping.L1,
          inspectionTabMapping.DatiGenerali,
          inspectionTabMapping.L1InformazioneIspezione,
          navigate,
          STRUCTURES_PATHS.STRUCTURES_LIST,
          id,
          lastInspectionId
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CardOverview
            title={'Ispezione Struttura'}
            height="auto"
            subCard={true}
          >
            <Row gap="100px">
              <Row alignItems="center">
                <img
                  src={ispezioneStruttura?.data ? calendarBlue : calendarGrey}
                />
                <Stack>
                  <Typography variant="h3Bold">Data</Typography>
                  <Typography variant="h3">
                    {dataIspezioneStruttura || 'n.d.'}
                  </Typography>
                </Stack>
              </Row>
              <Row alignItems="center">
                <img src={ispezioneStruttura?.autore ? userBlue : userGrey} />
                <Stack>
                  <Typography variant="h3Bold">Ispettore</Typography>
                  <Typography variant="h3">
                    {ispezioneStruttura?.autore || 'n.d.'}
                  </Typography>
                </Stack>
              </Row>
            </Row>
          </CardOverview>
        </Grid>
        <Grid item xs={4}>
          <CardOverview title={'Ispezione Frana'} height="auto" subCard={true}>
            <Row gap="100px">
              <Row alignItems="center">
                <img src={ispezioneFrana?.data ? calendarBlue : calendarGrey} />
                <Stack>
                  <Typography variant="h3Bold">Data</Typography>
                  <Typography variant="h3">
                    {dataIspezioneFrana || 'n.d.'}
                  </Typography>
                </Stack>
              </Row>
              <Row alignItems="center">
                <img src={ispezioneFrana?.autore ? userBlue : userGrey} />
                <Stack>
                  <Typography variant="h3Bold">Ispettore</Typography>
                  <Typography variant="h3">
                    {ispezioneFrana?.autore || 'n.d.'}
                  </Typography>
                </Stack>
              </Row>
            </Row>
          </CardOverview>
        </Grid>
        <Grid item xs={4}>
          <CardOverview
            title={'Ispezione Idraulica'}
            height="auto"
            subCard={true}
          >
            <Row gap="100px">
              <Row alignItems="center">
                <img
                  src={ispezioneIdraulica?.data ? calendarBlue : calendarGrey}
                />
                <Stack>
                  <Typography variant="h3Bold">Data</Typography>
                  <Typography variant="h3">
                    {dataIspezioneIdraulica || 'n.d.'}
                  </Typography>
                </Stack>
              </Row>
              <Row alignItems="center">
                <img src={ispezioneIdraulica?.autore ? userBlue : userGrey} />
                <Stack>
                  <Typography variant="h3Bold">Ispettore</Typography>
                  <Typography variant="h3">
                    {ispezioneIdraulica?.autore || 'n.d.'}
                  </Typography>
                </Stack>
              </Row>
            </Row>
          </CardOverview>
        </Grid>
      </Grid>
    </CardOverview>
  );
};

export { Ispezioni };

Ispezioni.propTypes = {
  overviewL1: PropTypes.obj,
};
