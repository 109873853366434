import { Typography } from '@mui/material';
import { tour_06, tour_06a } from 'constants/tour';

/* import { Check } from '@mui/icons-material';*/
export const steps = [
  {
    selector: `.${tour_06.step_01}`,
    content: () => {
      return (
        <Typography>
          Entra nelle schede di censimento e ispezione secondo il livello
          stabilito dalle Linee Guida.
        </Typography>
      );
    },
  },
  {
    selector: `.${tour_06.step_04}`,
    content: () => {
      return (
        <Typography>
          I dati sono organizzati in quattro macro categorie alla fine delle
          quali è presente un ripepilogo
        </Typography>
      );
    },
    /* style: {
      backgroundColor: '#bada55',
    }, */
  },
  {
    selector: `.${tour_06.step_02}`,
    content: () => {
      return (
        <Typography>
          Gestisci la condivisione dell&apos;attività con i tuoi collaboratori
          interni od esterni
        </Typography>
      );
    },
  },
  /* {
    selector: `.${tour_06.step_03}`,
    content: () => {
      return (
        <Typography>
          Clicca sulla scheda di un livello di attività (L0 o L1) per inserire i
          dati del ponte
        </Typography>
      );
    },
  }, */
  {
    selector: `.${tour_06.step_05}`,
    content: () => {
      return (
        <Typography>
          Salva fintanto che stai inserendo i dati. Consegna e Archivia per
          confermare l&apos;attività e consegnarla all&apos;Ente gestore
          proprietario.
        </Typography>
      );
    },
    /* style: {
      backgroundColor: '#bada55',
    }, */
  },
];

export const viewInspectionSteps = [
  {
    selector: `.${tour_06a.step_01}`,
    content: () => {
      return (
        <Typography>
          I dati sono organizzati in quattro macro categorie alla fine delle
          quali è presente un ripepilogo
        </Typography>
      );
    },
    /* style: {
      backgroundColor: '#bada55',
    }, */
  },
];
