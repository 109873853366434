import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { StatoDifetto } from 'constants/difetti';
import SchedaDifettiElementiAccessoriRow from './SchedaDifettiElementiAccessoriRow';

const SchedaDifettiElementiAccessori = ({ scheda, name, containerName }) => {
  const { getValues, setValue, watch } = useFormContext();

  const cordoliIgnore = [1, 2, 74, 3, 4, 5, 6, 10, 18, 19, 76];
  const cordoliIgnoreWatch = watch(`${name}.schedaDifetti`).some(
    el => el.id == 110 && el.visto && !el.presente
  );

  const schedaDifettiTriggerWatch = watch(`${name}.schedaDifettiTrigger`);

  useEffect(() => {
    // related to the https://github.com/nautes-tech/tecnoindagini-issues/issues/699
    if (cordoliIgnoreWatch) {
      getValues(`${name}.schedaDifetti`).map((el, idx) => {
        if (cordoliIgnore.includes(el.id)) {
          setValue(`${name}.schedaDifetti[${idx}].media`, []);
          setValue(`${name}.schedaDifetti[${idx}].statoDifetto`, null);
          setValue(`${name}.schedaDifetti[${idx}].visto`, false);
          setValue(`${name}.schedaDifetti[${idx}].presente`, null);
        }
      });
    }
  }, [cordoliIgnoreWatch]);

  useEffect(() => {
    let completamentoSchedaDifetti = 0;
    let nrtotali = 0;
    const list = cordoliIgnoreWatch
      ? getValues(`${name}.schedaDifetti`).filter(
          el => !cordoliIgnore.some(x => x == el.id)
        )
      : getValues(`${name}.schedaDifetti`);

    if (list.some(el => el.id === 'nonPrevisto')) {
      setValue(`${name}.completamentoSchedaDifetti`, 'n.a.');
      setValue(`${name}.nrtotali`, 'n.a.');
      return;
    }

    completamentoSchedaDifetti =
      (list.filter(el => el.visto && (el.statoDifetto || el.presente)).length /
        list.length) *
      100;

    setValue(
      `${name}.completamentoSchedaDifetti`,
      `${completamentoSchedaDifetti.toFixed(2)}%`
    );

    nrtotali = list.filter(el => el.statoDifetto == StatoDifetto.NR).length;
    setValue(`${name}.nrtotali`, nrtotali);
    // TODO force re-rerender of maincontainer so that the values are printed correctly
    setValue(containerName, getValues(containerName));
  }, [schedaDifettiTriggerWatch]);

  useEffect(() => {
    const list = cordoliIgnoreWatch
      ? getValues(`${name}.schedaDifetti`).filter(el => el.id === 110)
      : getValues(`${name}.schedaDifetti`);

    if (list.some(el => !el.visto)) {
      setValue(`${name}.hasDefects`, null);
      return;
    }
    if (list.some(el => !el.presente && !el.statoDifetto)) {
      setValue(`${name}.hasDefects`, null);
      return;
    }
    if (
      list.some(
        el => el.presente || el.gravita || el.danneggiato || el.ossidato
      )
    ) {
      setValue(`${name}.hasDefects`, true);
    }
    if (list.every(el => el.statoDifetto)) {
      setValue(`${name}.hasDefects`, false);
    }
  }, [schedaDifettiTriggerWatch]);

  if (!scheda) {
    return (
      <Typography color="error" textAlign={'center'}>
        Attenzione! Non sarà possibile compilare una scheda di valutazione
        difetti finchè non sarà rilevata la tipologia dell&apos;elemento
      </Typography>
    );
  }
  if (scheda[0].alert) {
    return (
      <Typography color="error" textAlign={'center'}>
        Attenzione! Non sarà possibile compilare una scheda di valutazione
        difetti finchè non sarà rilevata la tipologia dell&apos;elemento
      </Typography>
    );
  }
  if (scheda[0].alertFondazione) {
    return (
      <Typography color="error" textAlign={'center'}>
        Attenzione! Non sarà possibile compilare una scheda di valutazione
        difetti finchè non sarà rilevata la tipologia di fondazione
        dell&apos;elemento
      </Typography>
    );
  }
  if (scheda[0].id == 'nonPrevisto') {
    return (
      <Typography color="error" textAlign={'center'}>
        Per un elemento &quot;Non Previsto&quot; non si richiede la compilazione
        della scheda
      </Typography>
    );
  }

  return (
    <Box>
      {(process.env.REACT_APP_ENVIRONMENT == 'develop' ||
        process.env.REACT_APP_ENVIRONMENT == 'test') && (
        <Grid container spacing={2} my={3}>
          <Grid item xs={6}>
            <Button
              color="info"
              fullWidth
              variant="contained"
              onClick={() =>
                console.table({ ...getValues(name), schedaDifetti: '' })
              }
            >
              analizza scheda ispezione in console
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="info"
              fullWidth
              variant="contained"
              onClick={() => console.table(scheda)}
            >
              analizza scheda difetti in console
            </Button>
          </Grid>
        </Grid>
      )}
      <TableContainer sx={{ minWidth: 650, maxHeight: 440 }}>
        <Table aria-label="simple table">
          <TableHead
            style={{
              backgroundColor: '#F4F5F6',
              position: 'sticky',
              top: 0,
              zIndex: 3,
            }}
          >
            <>
              {scheda.some(
                difetto => difetto.id === 'assente' || difetto.id === 111
              ) && (
                <TableRow style={{ outline: '1px solid #b8b8b8' }}>
                  <TableCell>Codice difetto</TableCell>
                  <TableCell>Descrizione difetto</TableCell>
                  <TableCell align="center">Visto</TableCell>
                  <TableCell align="center">NR</TableCell>
                  <TableCell align="center">G</TableCell>
                  <TableCell align="center">Presente</TableCell>
                  <TableCell>Foto</TableCell>
                  <TableCell>Note</TableCell>
                </TableRow>
              )}
              {scheda.some(
                difetto =>
                  difetto.id === '121.a' ||
                  difetto.id === '121.b' ||
                  difetto.id === '121.c' ||
                  difetto.id === '121.d'
              ) && (
                <TableRow style={{ outline: '1px solid #b8b8b8' }}>
                  <TableCell>Codice difetto</TableCell>
                  <TableCell>Descrizione difetto</TableCell>
                  <TableCell align="center">Visto</TableCell>
                  <TableCell align="center">NR</TableCell>
                  <TableCell align="center">NP</TableCell>
                  <TableCell align="center">G</TableCell>
                  <TableCell align="center">Presente</TableCell>
                  <TableCell align="center">Lievemente</TableCell>
                  <TableCell align="center">Gravemente</TableCell>
                  <TableCell align="center">Danneggiati</TableCell>
                  <TableCell align="center">Ossidati</TableCell>
                  <TableCell>Foto</TableCell>
                  <TableCell>Note</TableCell>
                </TableRow>
              )}
              {!scheda.some(
                difetto =>
                  difetto.id === '121.a' ||
                  difetto.id === '121.b' ||
                  difetto.id === '121.c' ||
                  difetto.id === '121.d' ||
                  difetto.id === 'assente' ||
                  difetto.id === 111
              ) && (
                <TableRow style={{ outline: '1px solid #b8b8b8' }}>
                  <TableCell>Codice difetto</TableCell>
                  <TableCell>Descrizione difetto</TableCell>
                  <TableCell align="center">Visto</TableCell>
                  <TableCell align="center">NR</TableCell>
                  <TableCell align="center">NP</TableCell>
                  <TableCell align="center">G</TableCell>
                  <TableCell align="center">Presente</TableCell>
                  <TableCell>Foto</TableCell>
                  <TableCell>Note</TableCell>
                </TableRow>
              )}
            </>
          </TableHead>

          <TableBody>
            {scheda.map((difetto, idx) => (
              <SchedaDifettiElementiAccessoriRow
                key={difetto.id}
                difetto={difetto}
                name={name}
                containerName={containerName}
                idx={idx}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

SchedaDifettiElementiAccessori.propTypes = {
  scheda: PropTypes.array,
  name: PropTypes.string,
  containerName: PropTypes.string,
  isScheda20: PropTypes.bool,
};

export { SchedaDifettiElementiAccessori };
