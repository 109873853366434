import arrowGreen from 'assets/icons/arrow-green.svg';
import arrowOrange from 'assets/icons/arrow-orange.svg';
import arrowRed from 'assets/icons/arrow-red.svg';
import blackBridge from 'assets/icons/bridges/black-bridge.png';
import greenBridge from 'assets/icons/bridges/green-bridge.png';
import greyBridge from 'assets/icons/bridges/grey-bridge.png';
import lightGreenBridge from 'assets/icons/bridges/lightgreen-bridge.png';
import orangeBridge from 'assets/icons/bridges/orange-bridge.png';
import redBridge from 'assets/icons/bridges/red-bridge.png';
import silverBridge from 'assets/icons/bridges/silver-bridge.png';
import yellowBridge from 'assets/icons/bridges/yellow-bridge.png';

export const colors = {
  ORANGE: '#FFAD4C',
  BLUE: '#3260CE',
  BLACK: '#000000',
  GREY: '#E5E5E5',
  WHITE: '#FFFFFF',
  LIGHTGREY: '#F4F5F6',
  PALAGREY: '#B8B8B8',
  DARKGREY: '#707070',
  GREEN: '#5DCDA0',
  RED: '#EB3C5C',
  LIGHTBLUE: '#22D7DD',
  VIOLET: '#5C65E1',
  LIGHTYELLOW: '#FFE699',
  SPINDLE: '#DBE6FF ',
  LIGHTGREEN: '#C6E0B4',
  MARINER: '#3261CF',
  LIGHTRED: '#FACCD5',
  WILDWILLOW: '#99C567',
  SPROUT: '#C7DEB5',
  CREAMBRULEE: '#FFE695',
  SUNGLOW: '#FDCB37',
  MAUVELOUS: '#F0878A',
  SILVER: '#C3C3C3',
  CHRISTI: '#54A919',
  FEIJOA: '#ADD492',
  CASABLANCA: '#F4C246',
  WEBORANGE: '#EBA300',
  AMARANTH: '#EB3C5C',
  DOVEGRAY: '#707070',
  DISABLED_WHITE: '#ffffffa1',
};

export const L2CdAMap = {
  1: {
    labelColor: colors.WILDWILLOW,
    labelText: 'BASSA',
  },
  2: {
    labelColor: colors.SPROUT,
    labelText: 'MEDIO-BASSA',
  },
  3: {
    labelColor: colors.CREAMBRULEE,
    labelText: 'MEDIA',
  },
  4: {
    labelColor: colors.SUNGLOW,
    labelText: 'MEDIO-ALTA',
  },
  5: {
    labelColor: colors.MAUVELOUS,
    labelText: 'ALTA',
  },
  6: {
    labelColor: colors.SILVER,
    labelText: 'N.D.',
  },
};

export const InspectionStatuses = {
  0: {
    labelColor: colors.BLUE,
    labelCardText: 'ND',
  },
  1: {
    labelColor: colors.BLUE,
    labelCardText: 'A-Pienamente Agibile',
    labelMapText: 'A-Pienamente Agibile',
  },
  2: {
    labelColor: colors.BLUE,
    labelCardText: 'B-Agibile',
    labelMapText: 'B-Agibile, scadenza manutenzione ordinaria',
  },
  3: {
    labelColor: colors.BLUE,
    labelCardText: 'C-Agibile',
    labelMapText: 'C-Agibile, scadenza manutezione straordinaria',
  },
  4: {
    labelColor: colors.BLUE,
    labelCardText: 'D-Agibile Parzialmente',
    labelMapText:
      'D-Agibile Parzialmente, condizione critiche/manutenzione urgente',
  },
  5: {
    labelColor: colors.BLUE,
    labelCardText: 'E-Inagibile',
  },

  20: {
    labelColor: colors.BLACK,
    labelCardText: 'Nuova',
  },
  21: {
    labelColor: colors.SPINDLE,
    labelCardText: 'In Corso',
  },
};

export const CdaColor = {
  1: {
    labelColor: colors.MAUVELOUS,
  },
  2: {
    labelColor: colors.SUNGLOW,
  },
  3: {
    labelColor: colors.CREAMBRULEE,
  },
  4: {
    labelColor: colors.SPROUT,
  },
  5: {
    labelColor: colors.WILDWILLOW,
  },
  6: {
    labelColor: colors.SILVER,
  },
};

export const InspectionStatusesMap = {
  1: {
    labelColor: colors.WILDWILLOW,
    labelMapText: 'Cda Bassa',
    labelCdaGraph: 'Bassa',
    icon: greenBridge,
  },
  2: {
    labelColor: colors.SPROUT,
    labelMapText: 'CdA Medio-Bassa',
    labelCdaGraph: 'Medio-Bassa',
    icon: lightGreenBridge,
  },
  3: {
    labelColor: colors.CREAMBRULEE,
    labelMapText: 'CdA Media',
    labelCdaGraph: 'Media',
    icon: yellowBridge,
  },
  4: {
    labelColor: colors.SUNGLOW,
    labelMapText: 'CdA Medio-Alta',
    labelCdaGraph: 'Medio-Alta',
    icon: orangeBridge,
  },
  5: {
    labelColor: colors.MAUVELOUS,
    labelMapText: 'CdA Alta',
    labelCdaGraph: 'Alta',
    icon: redBridge,
  },
  6: {
    labelColor: colors.SILVER,
    labelMapText: 'Dati non sufficienti',
    icon: greyBridge,
  },
  7: {
    labelColor: colors.SPINDLE,
    labelMapText: 'In corso',
    icon: silverBridge,
  },
  8: {
    labelColor: colors.BLACK,
    labelMapText: 'Nuova',
    icon: blackBridge,
  },
};

export const Rating = {
  new: {
    bgColor: colors.WHITE,
    color: colors.PALAGREY,
    border: colors.PALAGREY,
  },
  draft: {
    bgColor: colors.SPINDLE,
    color: colors.BLUE,
    border: colors.SPINDLE,
  },
  checked: {
    bgColor: colors.BLUE,
    color: colors.WHITE,
    border: colors.BLUE,
  },
  undefined: {
    bgColor: colors.GREY,
    color: colors.BLACK,
    border: colors.GREY,
  },
};

export const cardKpiValue = {
  negative: {
    labelColor: colors.RED,
    labelIcon: arrowRed,
    labelText: 'negative value',
  },
  neutro: {
    labelColor: colors.ORANGE,
    labelIcon: arrowOrange,
    labelText: 'negative value',
  },
  positive: {
    labelColor: colors.GREEN,
    labelIcon: arrowGreen,
    labelText: 'positive value',
  },
};

export const randomColor = [
  {
    bg: '#DBE6FF',
    border: '#3261CF',
  },
  {
    bg: '#FACCD5',
    border: '#EB3C5C',
  },
  {
    bg: '#C6E0B4',
    border: '#75A758',
  },
  {
    bg: '#FFE699',
    border: '#FFAD4C',
  },
  {
    bg: '#DBE6FF',
    border: '#3261CF',
  },
  {
    bg: '#FACCD5',
    border: '#EB3C5C',
  },
  {
    bg: '#C6E0B4',
    border: '#75A758',
  },
  {
    bg: '#FFE699',
    border: '#FFAD4C',
  },
  {
    bg: '#DBE6FF',
    border: '#3261CF',
  },
  {
    bg: '#FACCD5',
    border: '#EB3C5C',
  },
  {
    bg: '#C6E0B4',
    border: '#75A758',
  },
  {
    bg: '#FFE699',
    border: '#FFAD4C',
  },
];
