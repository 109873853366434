import { Drawer } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import close from 'assets/icons/close.svg';
import { CloseContainer, Container } from './Drawer.style';

export const DrawerMUI = ({
  children,
  open,
  setOpen,
  onClose,
  title,
  subtitle,
}) => {
  const toggleDrawer = () => {
    setOpen(!open);
    onClose && onClose();
  };

  return (
    <Drawer anchor={'right'} open={open} onClose={toggleDrawer}>
      <Container>
        {title && <Typography variant="h1">{title}</Typography>}
        {subtitle && (
          <Typography variant="h3" style={{ marginTop: 10 }}>
            {subtitle}
          </Typography>
        )}
        <CloseContainer onClick={toggleDrawer}>
          <img src={close} alt="" />
        </CloseContainer>
        {children}
      </Container>
    </Drawer>
  );
};

DrawerMUI.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
