import { Button, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import {
  DrawerCopy,
  DrawerMedia,
  GridWrap,
  InfoWrapper,
  LocalTourWrapper,
  SchedaDifettiElementiStrutturali,
  Table,
} from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import { CDAOptions, tipoPilaSelectOptions } from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import { generateCopySchedaDifettiSteps } from 'utils';
import { populateSchedeDifettiTableActions } from 'utils/Inspections/L1/schedeDifettiTableActions';
import InspectionInfoForm from './InspectionInfoForm';
import PileForm from './PileForm';
import useCopy from '../hooks/useCopyPile';

const tourId = 'l1-struttura-elevazione-pile';

const PileSection = () => {
  const { setValue, getValues, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const pileTable = watch('pileTable');
  const watchNPile = watch('nPileStruttura');

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsPile = item => {
    setValue('pileTableValue', [item]);
    setValue('tipologiaPilaTableValue', item.tipoPila);
    setValue('impostaFondPilaTableValue', item.impostaFondPila);
  };
  const handleSelectAllClickPile = event => {
    if (event.target.checked) {
      const selectedValue = getValues('pileTable');
      setSelected(selectedValue);
      setValue('pileTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('pileTableValue', []);
    }
  };

  const dataPileConfig = {
    columns: {
      codicePila: { label: 'Cod.' },
      tipoPila: { label: 'Tipologia', format: tipoPilaSelectOptions },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      indiceCondizione: { label: 'Indice di condizione' },
      CDAstrutturale: { label: 'Difettosità Str.', format: CDAOptions },
      CDAsismica: { label: 'Difettosità Sis.', format: CDAOptions },
      nrtotali: { label: 'NR' },
      g5totali: { label: 'G=5' },
      pstotali: { label: 'PS' },
    },
  };

  useEffect(() => {
    setValue(
      'l1t2a2_tecnicoRilevatorePile',
      getValues(`pileTable[${schedaDifettiIndex}].tecnicoRilevatore`) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t2a2_dataIspezionePile',
      getValues(`pileTable[${schedaDifettiIndex}].dataIspezione`) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t2a2_elementoCriticoStrutturaPile',
      getValues(`pileTable[${schedaDifettiIndex}].elementoCriticoStruttura`) ||
        ''
    );
    setValue(
      'l1t2a2_elementoCriticoSismicaPile',
      getValues(`pileTable[${schedaDifettiIndex}].elementoCriticoSismica`) || ''
    );
    setValue(
      'l1t2a2_condizioneCriticaStrutturaPile',
      getValues(
        `pileTable[${schedaDifettiIndex}].condizioneCriticaStruttura`
      ) || ''
    );
    setValue(
      'l1t2a2_condizioneCriticaSismicaPile',
      getValues(`pileTable[${schedaDifettiIndex}].condizioneCriticaSismica`) ||
        ''
    );
    setValue(
      'l1t2a2_noteGeneraliPile',
      getValues(`pileTable[${schedaDifettiIndex}].noteGenerali`) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t2a2_tecnicoRilevatorePile');
    const dataIspezione = getValues('l1t2a2_dataIspezionePile');
    const elementoCriticoStruttura = getValues(
      'l1t2a2_elementoCriticoStrutturaPile'
    );
    const elementoCriticoSismica = getValues(
      'l1t2a2_elementoCriticoSismicaPile'
    );
    const condizioneCriticaStruttura = getValues(
      'l1t2a2_condizioneCriticaStrutturaPile'
    );
    const condizioneCriticaSismica = getValues(
      'l1t2a2_condizioneCriticaSismicaPile'
    );
    const noteGenerali = getValues('l1t2a2_noteGeneraliPile');
    setValue(
      `pileTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(`pileTable[${schedaDifettiIndex}].dataIspezione`, dataIspezione);
    setValue(
      `pileTable[${schedaDifettiIndex}].elementoCriticoStruttura`,
      elementoCriticoStruttura
    );
    setValue(
      `pileTable[${schedaDifettiIndex}].elementoCriticoSismica`,
      elementoCriticoSismica
    );
    setValue(
      `pileTable[${schedaDifettiIndex}].condizioneCriticaStruttura`,
      condizioneCriticaStruttura
    );
    setValue(
      `pileTable[${schedaDifettiIndex}].condizioneCriticaSismica`,
      condizioneCriticaSismica
    );
    setValue(`pileTable[${schedaDifettiIndex}].noteGenerali`, noteGenerali);
    setValue('l1t2a5_trigger', Math.random() * 1000);
  };
  const handleResetInspection = () => {
    setValue(`l1t2a2_tecnicoRilevatorePile`, '');
    setValue(`l1t2a2_dataIspezionePile`, null);
    setValue(`l1t2a2_elementoCriticoStrutturaPile`, '');
    setValue(`l1t2a2_elementoCriticoSismicaPile`, '');
    setValue(`l1t2a2_condizioneCriticaStrutturaPile`, '');
    setValue(`l1t2a2_condizioneCriticaSismicaPile`, '');
    setValue(`l1t2a2_noteGeneraliPile`, '');
  };

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  const {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedCopy,
    setSelectedCopy,
    dataSpallePileConfig,
    selectedPaste,
    handleSelectAllSpallePilePaste,
    setSelectedPaste,
    checkCompatibility,
    handleCopy,
  } = useCopy();

  return (
    watchNPile > 0 && (
      <>
        <GridWrap container spacing={2} style={{ marginTop: 16 }}>
          <InfoWrapper
            isDivider={true}
            size={12}
            infoDrawer={true}
            drawerTitle={INFO.elevazionePile.drawerTitle}
            drawerText={INFO.elevazionePile.drawerText}
          >
            <Divider>PILE</Divider>
          </InfoWrapper>
          <GridWrap item xs={12}>
            {!inspectionDisabled && !isViewingInspection && (
              <PileForm
                pileFields={pileTable || []}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          </GridWrap>
          <GridWrap item xs={12}>
            <Table
              data={pileTable || []}
              config={dataPileConfig}
              hasCheckbox={!inspectionDisabled && !isViewingInspection}
              rowActions={
                !inspectionDisabled && !isViewingInspection
                  ? [
                      {
                        onClick: item => {
                          editRowsPile(item);
                          setSelected([item]);
                        },
                        icon: <EditIcon />,
                      },
                    ]
                  : []
              }
              mediaActions={[
                {
                  onClick: item => {
                    setDrawerMediaIndex(
                      pileTable.findIndex(el => el.id === item.id)
                    );
                    setOpen(true);
                  },
                  icon: <MediaIcon />,
                },
              ]}
              schedaDifettiActions={populateSchedeDifettiTableActions(
                schedaDifettiIndex,
                pileTable,
                setOpenSchedaDifetti,
                setSchedaDifettiIndex
              )}
              selected={selected}
              onSelectAllClick={handleSelectAllClickPile}
              setSelected={setSelected}
            />
          </GridWrap>

          {schedaDifettiIndex !== undefined && openSchedaDifetti && (
            <>
              <InfoWrapper
                isDivider={true}
                size={12}
                infoDrawer={true}
                drawerTitle={INFO.schedaIspezioniPonteDifettiInfo.drawerTitle}
                drawerText={INFO.schedaIspezioniPonteDifettiInfo.drawerText}
                button={
                  <>
                    {!inspectionDisabled && !isViewingInspection && (
                      <>
                        <LocalTourWrapper
                          style={{
                            padding: 5,
                            fontSize: 12,
                            height: 25,
                            marginLeft: 5,
                          }}
                          steps={generateCopySchedaDifettiSteps(tourId)}
                        />
                        <Button
                          className={`${tourId}`}
                          style={{
                            padding: 2,
                            fontSize: 12,
                            height: 25,
                            marginLeft: 5,
                          }}
                          onClick={() => {
                            setCopyDrawerOpen(true);
                            setSelectedPaste([]);
                            setSelectedCopy(
                              getValues(`pileTable[${schedaDifettiIndex}]`)
                            );
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Copia scheda ispezione
                        </Button>
                      </>
                    )}
                  </>
                }
              >
                <Divider>
                  {pileTable[schedaDifettiIndex].codicePila} - Scheda Ispezione
                  Ponti - Pila
                </Divider>
              </InfoWrapper>
              {pileTable[schedaDifettiIndex].schedaDifetti &&
                !inspectionDisabled &&
                !isViewingInspection && (
                  <InspectionInfoForm
                    onSubmit={handleSaveInspection}
                    onReset={handleResetInspection}
                    tecnicoRilevatoreName={'l1t2a2_tecnicoRilevatorePile'}
                    dateName={'l1t2a2_dataIspezionePile'}
                    elementoCriticoStrutturaName={
                      'l1t2a2_elementoCriticoStrutturaPile'
                    }
                    elementoCriticoSismicaName={
                      'l1t2a2_elementoCriticoSismicaPile'
                    }
                    condizioneCriticaStrutturaName={
                      'l1t2a2_condizioneCriticaStrutturaPile'
                    }
                    condizioneCriticaSismicaName={
                      'l1t2a2_condizioneCriticaSismicaPile'
                    }
                    noteGeneraliName={'l1t2a2_noteGeneraliPile'}
                  />
                )}
              <GridWrap item xs={12}>
                <SchedaDifettiElementiStrutturali
                  key={pileTable[schedaDifettiIndex].codice}
                  scheda={pileTable[schedaDifettiIndex].schedaDifetti}
                  name={`pileTable[${schedaDifettiIndex}]`}
                  containerName={`pileTable`}
                />
              </GridWrap>
            </>
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={`Pile`}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`pileTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Struttura]}
          />
          <DrawerCopy
            title={`Copia ${getValues(
              `pileTable[${schedaDifettiIndex}].codicePila`
            )} - Scheda ispezioni ponti - Spalla in ${
              tipoPilaSelectOptions.find(
                el =>
                  el.value ===
                  getValues(`pileTable[${schedaDifettiIndex}].tipoPila`)
              )?.label
            }`}
            subtitle={
              'Tutti gli elementi su cui le informazioni saranno copiate saranno sovrascritti. Eventuali media o schede di ispezione a loro associati saranno resettati.'
            }
            open={copyDrawerOpen}
            setOpen={setCopyDrawerOpen}
            tableConfig={dataSpallePileConfig}
            copyTableData={
              watch('pileTable').filter(
                el => el.codicePila == selectedCopy.codicePila
              ) || []
            }
            pasteTableData={
              watch('pileTable').filter(
                el => el.codicePila != selectedCopy.codicePila
              ) || []
            }
            selectedCopy={selectedCopy}
            selectedPaste={selectedPaste}
            handleSelectAll={handleSelectAllSpallePilePaste}
            setSelectedPaste={setSelectedPaste}
            checkCompatibility={checkCompatibility}
            handleCopy={handleCopy}
          />
        </GridWrap>
      </>
    )
  );
};
export default PileSection;
