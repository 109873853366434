import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const BoxStyle = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
});
