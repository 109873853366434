import styled from '@emotion/styled';
import { colors } from 'constants/colors';

export const CardContainer = styled('div')(({ height, subCard }) => ({
  backgroundColor: subCard ? colors.WHITE : colors.LIGHTGREY,
  height: height || '475px',
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  margin: '10px 0',
  padding: height === 'auto' ? '0 24px 20px' : '0 24px',
  borderRadius: '8px',
}));

export const FirstRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
