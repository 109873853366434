import styled from '@emotion/styled';

export const BoxContainer = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  height: 'auto',
  background: theme.palette.secondary.light,
  display: 'flex',
  flexDirection: 'column',
  padding: 5,
  margin: '10px 0',
}));

export const BoxTitle = styled('div')({
  marginBottom: '5px',
});
