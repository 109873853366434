import { Typography } from '@mui/material';
import { tour_11 } from 'constants/tour';

/* import { Check } from '@mui/icons-material';*/
export const steps = [
  {
    selector: `.${tour_11.step_01}`,
    content: () => {
      return (
        <Typography>
          Seleziona l&apos;elemento di cui vuoi duplicare i dati e clicca sul
          tasto `&quot;Copia`&quot;
        </Typography>
      );
    },
    /* style: {
      backgroundColor: '#bada55',
    }, */
  },
];
