import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import { mensole, TipologiaMarciapiedeTableValue } from 'constants/inspections';
import {
  marciapiedePresenteOptions,
  sormontabilitaMarciapiedeOptions,
  tipologiaMarciapiedeOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
const MarciapiediForm = ({
  marciapiediFields,
  selected,
  setSelected,
  marciapiediTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t3a2_marciapiedeTableValue: [],
      l0t3a2_tipologiaMarciapiedeTableValue: '',
      l0t3a2_tipologiaAltroMarciapiedeTableValue: '',
      l0t3a2_marciapiedePresenteTableValue: '',
      l0t3a2_sormontabilitaMarciapiedeTableValue: '',
      l0t3a2_larghezzaMarciapiedeTableValue: '',
      l0t3a2_altezzaMarciapiedeTableValue: '',
      l0t3a2_superficieMarciapiedeTableValue: '',
    },
  });

  useEffect(() => {
    setValue('l0t3a2_marciapiedeTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetMarciapiedi();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitMarciapiedi = () => {
    const l0t3a2_tipologiaMarciapiedeTableValue = getValues(
      'l0t3a2_tipologiaMarciapiedeTableValue'
    );
    const l0t3a2_tipologiaAltroMarciapiedeTableValue = getValues(
      'l0t3a2_tipologiaAltroMarciapiedeTableValue'
    );
    const l0t3a2_marciapiedePresenteTableValue = getValues(
      'l0t3a2_marciapiedePresenteTableValue'
    );
    const l0t3a2_sormontabilitaMarciapiedeTableValue = getValues(
      'l0t3a2_sormontabilitaMarciapiedeTableValue'
    );

    const l0t3a2_larghezzaMarciapiedeTableValue = getValues(
      'l0t3a2_larghezzaMarciapiedeTableValue'
    );

    const l0t3a2_altezzaMarciapiedeTableValue = getValues(
      'l0t3a2_altezzaMarciapiedeTableValue'
    );
    const l0t3a2_superficieMarciapiedeTableValue = getValues(
      'l0t3a2_superficieMarciapiedeTableValue'
    );

    const ids = selected.map(el => el.codice);

    const marciapiedi = [...marciapiediFields];
    marciapiedi.map((x, index) => {
      if (ids.includes(x?.codice)) {
        marciapiedi[index] = {
          ...x,
          tipologiaMarciapiede: l0t3a2_tipologiaMarciapiedeTableValue,
          tipologiaAltroMarciapiede: l0t3a2_tipologiaAltroMarciapiedeTableValue,
          marciapiedePresente: l0t3a2_marciapiedePresenteTableValue,
          sormontabilitaMarciapiede: l0t3a2_sormontabilitaMarciapiedeTableValue,
          larghezzaMarciapiede: l0t3a2_larghezzaMarciapiedeTableValue,
          altezzaMarciapiede: l0t3a2_altezzaMarciapiedeTableValue,
          superficieMarciapiede: l0t3a2_superficieMarciapiedeTableValue,
        };
      }
    });

    setValue(marciapiediTable, marciapiedi);
    onResetMarciapiedi();
  };

  const onResetMarciapiedi = () => {
    setValue('l0t3a2_marciapiedeTableValue', []);
    setValue('l0t3a2_tipologiaMarciapiedeTableValue', '');
    setValue('l0t3a2_tipologiaAltroMarciapiedeTableValue', '');
    setValue('l0t3a2_marciapiedePresenteTableValue', '');
    setValue('l0t3a2_sormontabilitaMarciapiedeTableValue', '');
    setValue('l0t3a2_larghezzaMarciapiedeTableValue', '');
    setValue('l0t3a2_altezzaMarciapiedeTableValue', '');
    setValue('l0t3a2_superficieMarciapiedeTableValue', '');

    setSelected([]);
  };

  // Luce impalcato*(Larghezza/100)
  const l0t3a2_sovrastrutturaImpalcatoSelezioneWatch = watch(
    'l0t3a2_sovrastrutturaImpalcatoSelezione'
  );
  const larghezzaMarciapiedeWatch = parseFloat(
    watch('l0t3a2_larghezzaMarciapiedeTableValue')
  );
  const marciapiedeTableWatch = watch('l0t3a2_marciapiedeTableValue');
  useEffect(() => {
    const watchMensole = watch('mensole');
    const watchMensoleSi = watchMensole === mensole.Si;
    let result;

    if (watchMensoleSi) {
      const mensoleTamponiTableWatch = getValues(
        `mensoleTamponiTable[${l0t3a2_sovrastrutturaImpalcatoSelezioneWatch}]`
      );
      result =
        parseFloat(mensoleTamponiTableWatch.luce) *
        (larghezzaMarciapiedeWatch / 100);
    } else {
      const campataTableWatch = getValues(
        `campataTable[${l0t3a2_sovrastrutturaImpalcatoSelezioneWatch}]`
      );
      result =
        parseFloat(campataTableWatch.luceCampata) *
        (larghezzaMarciapiedeWatch / 100);
    }
    setValue('l0t3a2_superficieMarciapiedeTableValue', result);
  }, [
    l0t3a2_sovrastrutturaImpalcatoSelezioneWatch,
    marciapiedeTableWatch,
    larghezzaMarciapiedeWatch,
  ]);

  const tipologiaMarciapiedeWatch = watch(
    'l0t3a2_tipologiaMarciapiedeTableValue'
  );
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (tipologiaMarciapiedeWatch === TipologiaMarciapiedeTableValue.Altro) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setValue('l0t3a2_tipologiaAltroMarciapiedeTableValue', '');
    }
  }, [tipologiaMarciapiedeWatch]);

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper
        size={6}
        infoMessage={INFO.marciapiediSelezionati.infoMessage}
      >
        <RHFTagAutocomplete
          multiple={true}
          id="tags-marciapiedi_l0t3a2"
          name={'l0t3a2_marciapiedeTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={marciapiediFields
            .filter(el => !el.assente)
            ?.filter(el => !selected?.some(s => s?.id === el?.id))}
          label="Marciapiedi selezionati"
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="l0t3a2_tipologiaMarciapiedeTableValue"
          label="Tipologia Marciapiede*"
          defaultValue={''}
          onChange={e => {
            setValue('l0t3a2_tipologiaMarciapiedeTableValue', e.target.value);
          }}
          selectOptions={tipologiaMarciapiedeOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="l0t3a2_tipologiaAltroMarciapiedeTableValue"
          label={'Tipologia Marciapiede (Altro)'}
          disabled={isDisabled}
          inputProps={{ maxLength: 100 }}
          defaultValue=""
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFSelect
          name="l0t3a2_marciapiedePresenteTableValue"
          label="Marciapiede presente"
          defaultValue={''}
          onChange={e => {
            setValue('l0t3a2_marciapiedePresenteTableValue', e.target.value);
          }}
          selectOptions={marciapiedePresenteOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFSelect
          name="l0t3a2_sormontabilitaMarciapiedeTableValue"
          label="Sormontabilità Marciapiede"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a2_sormontabilitaMarciapiedeTableValue',
              e.target.value
            );
          }}
          selectOptions={sormontabilitaMarciapiedeOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="l0t3a2_larghezzaMarciapiedeTableValue"
          label={'Larghezza (L) [cm]'}
          defaultValue=""
          decimalScale={2}
          withValueLimit={({ value }) => value < 1000}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="l0t3a2_altezzaMarciapiedeTableValue"
          label={'Altezza (H) [cm]'}
          defaultValue=""
          decimalScale={2}
          withValueLimit={({ value }) => value < 1000}
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoMessage={INFO.superficieMarciapiede.infoMessage}
      >
        <RHFNumberTextField
          name="l0t3a2_superficieMarciapiedeTableValue"
          label={'Superficie [m²]'}
          defaultValue=""
          decimalScale={2}
          disabled
          InputLabelProps={{ shrink: true }}
          withValueLimit={({ value }) => value < 1000}
        />
      </InfoWrapper>
      <GridWrap item xs={9}>
        <FormSubmitButtons
          onSubmit={onSubmitMarciapiedi}
          onReset={onResetMarciapiedi}
        />
      </GridWrap>
    </GridWrap>
  );
};
MarciapiediForm.propTypes = {
  marciapiediFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaMarciapiede: PropTypes.number,
      tipologiaAltroMarciapiede: PropTypes.string,
      sormontabilitaMarciapiede: PropTypes.number,
      larghezzaMarciapiede: PropTypes.number,
      altezzaMarciapiede: PropTypes.number,
      superficieMarciapiede: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  marciapiediTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default MarciapiediForm;
