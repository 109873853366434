import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  InfoWrapper,
  MediaUpload,
  RHFCheckbox,
  RHFNumberTextField,
  RHFSelect,
  RHFTextField,
} from 'components';
import {
  EffettivoPresunto,
  EstensioneInterferenzaFrana,
  Fenomeno,
  FieldType,
  MediaGroup,
  RischioFrana,
  StatoAttivitaFrana,
  UploadMedia,
  UsoSuoloAreaPotezialmenteCoinvolta,
} from 'constants/inspections';
import {
  DistribuzioneAttivitaOptions,
  EstensioneInterferenzaFranaOptions,
  FenomenoOptions,
  FraneAttiveOptions,
  GradoCriticitaFranaOptions,
  MagnitudoBaseVolumetricaOptions,
  MisureMitigazioneOptions,
  PosizioneInstabilitaVersanteOptions,
  RischioFranaOptions,
  RischioPresenteOptions,
  StatoAttivitaFranaOptions,
  TipologiaFenomenoOptions,
  UsoSuoloAreaPotezialmenteCoinvoltaOptions,
  VelocitaMaxSpostamentoFranaOptions,
} from 'constants/selectOptions';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import {
  CheckboxContainer,
  GridWrap,
  TitleSection,
} from './Accordion3LandslideRiskL1.styles';
import { INFO } from '../../../../config/info';

const Accordion3LandslideRiskL1 = () => {
  const { setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchRischioFrana = watch('rischioFrana');
  const watchRischioFranaPresente = watchRischioFrana === RischioFrana.Presente;
  const watchRischioFranaAssente = watchRischioFrana === RischioFrana.Assente;
  const watchRischioFranaDaVerificare =
    watchRischioFrana === RischioFrana.DaVerificare;
  const rischioPresenteDaVerificare =
    watchRischioFranaPresente || watchRischioFranaDaVerificare;

  const watchEstensioneInterferenza = watch('estensioneInterferenzaFrana');
  const watchEstensioneInterferenzaAltro =
    watchEstensioneInterferenza === EstensioneInterferenzaFrana.Altro;

  const watchUsoSuoloAreaCoinvolta = watch(
    'usoSuoloAreaPotezialmenteCoinvolta'
  );
  const watchUsoSuoloAreaCoinvoltaAltro =
    watchUsoSuoloAreaCoinvolta === UsoSuoloAreaPotezialmenteCoinvolta.Altro;

  const watchFenomeno = watch('fenomeno');
  const watchFenomenoAccertato = watchFenomeno === Fenomeno.Accertato;
  const watchFenomenoPotenziale = watchFenomeno === Fenomeno.Potenziale;

  const watchStatoAttivita = watch('statoAttivitaFrana');
  const watchStatoAttivo = watchStatoAttivita === StatoAttivitaFrana.Attiva;

  //CHECKBOX
  const watchVelocitaMaxSpostamentoFranaEffettivo = watch(
    'velocitaMaxSpostamentoFranaEffettivo'
  );
  const watchVelocitaMaxSpostamentoFranaPresunto = watch(
    'velocitaMaxSpostamentoFranaPresunto'
  );

  /**
   * The velocitaMaxSpostamentoFranaFlag is derived
   * from the App, where the chcekboxes are not exclusive
   * so in order to render them correctly we have to set the correct values
   * in both our fields and in the App field
   */
  useEffect(() => {
    !watchVelocitaMaxSpostamentoFranaEffettivo &&
      !watchVelocitaMaxSpostamentoFranaPresunto &&
      setValue('velocitaMaxSpostamentoFranaFlag', null);

    watchVelocitaMaxSpostamentoFranaEffettivo &&
      setValue('velocitaMaxSpostamentoFranaFlag', EffettivoPresunto.Effettivo);
    watchVelocitaMaxSpostamentoFranaPresunto &&
      setValue('velocitaMaxSpostamentoFranaFlag', EffettivoPresunto.Presunto);
  }, [
    watchVelocitaMaxSpostamentoFranaEffettivo,
    watchVelocitaMaxSpostamentoFranaPresunto,
  ]);

  const watchMagnitudoBaseVolumetricaEffettivo = watch(
    'magnitudoBaseVolumetricaEffettivo'
  );
  const watchMagnitudoBaseVolumetricaPresunto = watch(
    'magnitudoBaseVolumetricaPresunto'
  );

  /**
   * The magnitudoBaseVolumetricaFlag is derived
   * from the App, where the chcekboxes are not exclusive
   * so in order to render them correctly we have to set the correct values
   * in both our fields and in the App field
   */

  useEffect(() => {
    !watchMagnitudoBaseVolumetricaEffettivo &&
      !watchMagnitudoBaseVolumetricaPresunto &&
      setValue('magnitudoBaseVolumetricaFlag', null);

    watchMagnitudoBaseVolumetricaEffettivo &&
      setValue('magnitudoBaseVolumetricaFlag', EffettivoPresunto.Effettivo);
    watchMagnitudoBaseVolumetricaPresunto &&
      setValue('magnitudoBaseVolumetricaFlag', EffettivoPresunto.Presunto);
  }, [
    watchMagnitudoBaseVolumetricaEffettivo,
    watchMagnitudoBaseVolumetricaPresunto,
  ]);

  //Functions
  useEffect(() => {
    if (watchRischioFranaAssente || watchRischioFranaDaVerificare) {
      setValue('rischioPresente', '');
      setValue('misureMitigazione', '');
      setValue('estensioneInterferenzaFrana', '');
      setValue('estensioneInterferenzaFranaAltro', '');
      setValue('fenomeno', '');
      setValue('tipologiaFenomeno', '');
      setValue('distribuzioneAttivita', '');
      setValue('usoSuoloAreaPotezialmenteCoinvolta', '');
      setValue('usoSuoloAreaPotezialmenteCoinvoltaAltro');
      setValue('posizioneInstabilitaVersante', '');
      setValue('formazioniFrana', '');
      setValue('elementoRiferimentoFrana', '');
      setValue('quotaOrloSuperiore', '');
      setValue('quotaPonteViadotto', '');
      setValue('profonditaSuperficieDistacco', '');
      setValue('areaTotaleFrana', '');
      setValue('volumeMassaFrana', '');
      setValue('statoAttivitaFrana', '');
      setValue('franeAttive', '');
      setValue('gradoCriticitaFrana', '');
      setValue('velocitaMaxSpostamentoFrana', '');
      setValue('velocitaMaxSpostamentoFranaEffettivo', false);
      setValue('velocitaMaxSpostamentoFranaPresunto', false);
      setValue('magnitudoBaseVolumetrica');
      setValue('magnitudoBaseVolumetricaEffettivo', false);
      setValue('magnitudoBaseVolumetricaPresunto', false);
      setValue('annotazioniRischioFrane', '');
    }
  }, [watchRischioFrana]);

  useEffect(() => {
    !watchEstensioneInterferenzaAltro &&
      setValue('estensioneInterferenzaFranaAltro', '');
  }, [watchEstensioneInterferenza]);

  useEffect(() => {
    !watchUsoSuoloAreaCoinvoltaAltro &&
      setValue('usoSuoloAreaPotezialmenteCoinvoltaAltro', '');
  }, [watchUsoSuoloAreaCoinvolta]);

  useEffect(() => {
    !watchFenomenoAccertato && setValue('statoAttivitaFrana', '');
    !watchFenomenoPotenziale && setValue('gradoCriticitaFrana', '');
  }, [watchFenomeno]);

  useEffect(() => {
    !watchStatoAttivo && setValue('franeAttive', '');
  }, [watchStatoAttivita]);

  return (
    <>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">DATI GENERALI</Typography>
          </TitleSection>
        </GridWrap>
        <InfoWrapper
          size={3}
          infoMessage={INFO.rischioFrana.infoMessage}
          linkMessage={INFO.rischioFrana.linkMessage}
        >
          <RHFSelect
            name="rischioFrana"
            label={'Rischio frana'}
            defaultValue={''}
            selectOptions={RischioFranaOptions}
            disabled
          />
        </InfoWrapper>
        {rischioPresenteDaVerificare && (
          <>
            <GridWrap item xs={3}>
              <RHFSelect
                name="rischioPresente"
                label={'Tipologia di interferenza'}
                defaultValue={''}
                selectOptions={RischioPresenteOptions}
                disabled
              />
            </GridWrap>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              legislationMessage={INFO.misureMitigazione.legislationMessage}
              drawerTitle={INFO.misureMitigazione.drawerTitle}
              drawerText={INFO.misureMitigazione.drawerText}
            >
              <RHFSelect
                name="misureMitigazione"
                label={'Misure di mitigazione*'}
                defaultValue={''}
                selectOptions={MisureMitigazioneOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoMessage={INFO.docMisureMitigazione.infoMessage}
            >
              <MediaUpload
                title={`Documentazione misure di mitigazione`}
                btnTitle="Seleziona"
                formName="documentazioneMisureMitigazione"
                dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
                uploadType={UploadMedia.documents}
                type={FieldType.documents}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Frane]}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoMessage={INFO.estensioneInterferenzaFrana.infoMessage}
              legislationMessage={
                INFO.estensioneInterferenzaFrana.legislationMessage
              }
            >
              <RHFSelect
                name="estensioneInterferenzaFrana"
                label={'Estensione interferenza*'}
                defaultValue={''}
                selectOptions={EstensioneInterferenzaFranaOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <GridWrap item xs={3}>
              <RHFTextField
                name="estensioneInterferenzaFranaAltro"
                label={'Specificare altro'}
                inputProps={{ maxLength: 100 }}
                defaultValue=""
                disabled={
                  !rischioPresenteDaVerificare ||
                  !watchEstensioneInterferenzaAltro ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
          </>
        )}
      </GridWrap>

      {rischioPresenteDaVerificare && (
        <>
          <GridWrap container spacing={2} padding="20px">
            <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
              <TitleSection>
                <Typography variant="h3BoldBlue">
                  TIPOLOGIA DI FENOMENO
                </Typography>
              </TitleSection>
            </GridWrap>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              compassMessage={INFO.fenomeno.compassMessage}
              drawerTitle={INFO.fenomeno.drawerTitle}
              drawerText={INFO.fenomeno.drawerText}
            >
              <RHFSelect
                name="fenomeno"
                label={'Fenomeno*'}
                defaultValue={''}
                selectOptions={FenomenoOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              compassMessage={INFO.tipologiaFenomeno.compassMessage}
              quoteMessage={INFO.tipologiaFenomeno.quoteMessage}
            >
              <RHFSelect
                name="tipologiaFenomeno"
                label={'Tipologia di fenomeno'}
                defaultValue={''}
                selectOptions={TipologiaFenomenoOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.distribuzioneAttivita.drawerTitle}
              drawerText={INFO.distribuzioneAttivita.drawerText}
              quoteMessage={INFO.distribuzioneAttivita.quoteMessage}
            >
              <RHFSelect
                name="distribuzioneAttivita"
                label={'Distribuzione di attività'}
                defaultValue={''}
                selectOptions={DistribuzioneAttivitaOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoMessage={INFO.posizioneInstabilitaVersante.infoMessage}
            >
              <RHFSelect
                name="posizioneInstabilitaVersante"
                label={`Posizione instabilità sul versante`}
                defaultValue={''}
                selectOptions={PosizioneInstabilitaVersanteOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.usoSuoloAreaPotezialmenteCoinvolta.drawerTitle}
              drawerText={INFO.usoSuoloAreaPotezialmenteCoinvolta.drawerText}
              quoteMessage={
                INFO.usoSuoloAreaPotezialmenteCoinvolta.quoteMessage
              }
            >
              <RHFSelect
                name="usoSuoloAreaPotezialmenteCoinvolta"
                label={`Uso del suolo dell'area poten.te coinvolta`}
                defaultValue={''}
                selectOptions={UsoSuoloAreaPotezialmenteCoinvoltaOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <GridWrap item xs={3}>
              <RHFTextField
                name="usoSuoloAreaPotezialmenteCoinvoltaAltro"
                label={'Specificare altro'}
                inputProps={{ maxLength: 100 }}
                defaultValue=""
                disabled={
                  !rischioPresenteDaVerificare ||
                  !watchUsoSuoloAreaCoinvoltaAltro ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
          </GridWrap>

          <GridWrap container spacing={2} padding="20px">
            <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
              <TitleSection>
                <Typography variant="h3BoldBlue">CONTESTO GEOLOGICO</Typography>
              </TitleSection>
            </GridWrap>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              drawerTitle={INFO.formazioniFrana.drawerTitle}
              drawerText={INFO.formazioniFrana.drawerText}
              quoteMessage={INFO.formazioniFrana.quoteMessage}
            >
              <RHFTextField
                name="formazioniFrana"
                label={'Formazioni'}
                inputProps={{ maxLength: 250 }}
                defaultValue=""
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoMessage={INFO.elementoRiferimentoFrana.infoMessage}
            >
              <RHFTextField
                name="elementoRiferimentoFrana"
                label={'Elemento livello 0m di riferimento*'}
                inputProps={{ maxLength: 50 }}
                defaultValue=""
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoMessage={INFO.quotaOrloSuperiore.infoMessage}
            >
              <RHFNumberTextField
                name="quotaOrloSuperiore"
                label={'Quota orlo superiore zona distacco [m]'}
                defaultValue=""
                decimalScale={2}
                withValueLimit={({ value }) => value < 10000}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoMessage={INFO.quotaPonteViadotto.infoMessage}
            >
              <RHFNumberTextField
                name="quotaPonteViadotto"
                label={'Quota ponte o viadotto [m]'}
                defaultValue=""
                decimalScale={2}
                withValueLimit={({ value }) => value < 10000}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoMessage={INFO.profonditaSuperficieDistacco.infoMessage}
              quoteMessage={INFO.profonditaSuperficieDistacco.quoteMessage}
            >
              <RHFNumberTextField
                name="profonditaSuperficieDistacco"
                label={'Profondità superficie di distacco [m]'}
                defaultValue=""
                decimalScale={2}
                withValueLimit={({ value }) => value < 10000}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoMessage={INFO.areaTotaleFrana.infoMessage}
              quoteMessage={INFO.areaTotaleFrana.quoteMessage}
            >
              <RHFNumberTextField
                name="areaTotaleFrana"
                label={'Area totale [m²]'}
                defaultValue=""
                decimalScale={2}
                withValueLimit={({ value }) => value < 1000000000}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoMessage={INFO.volumeMassaFrana.infoMessage}
              quoteMessage={INFO.volumeMassaFrana.quoteMessage}
            >
              <RHFNumberTextField
                name="volumeMassaFrana"
                label={'Volume massa [m³]'}
                defaultValue=""
                decimalScale={2}
                withValueLimit={({ value }) => value < 1000000000}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <GridWrap item xs={3}>
              <MediaUpload
                title={`Schema rilievo frana`}
                btnTitle="Allega file"
                dataType=".jpeg,.png"
                formName="schemaRilievoFrana"
                uploadType={UploadMedia.documents}
                type={FieldType.documents}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Frane]}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
            <GridWrap item xs={3}>
              <MediaUpload
                title={`Elaborato grafico frana`}
                btnTitle="Allega file"
                dataType=".dwg"
                formName="elaboratoGraficoFrana"
                uploadType={UploadMedia.graphicElaboration}
                type={FieldType.documents}
                mediaGroup={[
                  MediaGroup.Contesto,
                  MediaGroup.Frane,
                  MediaGroup.ElaboratiGrafici,
                ]}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
          </GridWrap>

          <GridWrap container spacing={2} padding="20px">
            <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
              <TitleSection>
                <Typography variant="h3BoldBlue">PARAMETRI FRANA</Typography>
              </TitleSection>
            </GridWrap>
            <GridWrap item xs={3}>
              <RHFSelect
                name="statoAttivitaFrana"
                label={'Parametro dello stato di attività*'}
                defaultValue={''}
                selectOptions={StatoAttivitaFranaOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  !watchFenomenoAccertato ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
            <GridWrap item xs={3}>
              <RHFSelect
                name="franeAttive"
                label={'Frane attive'}
                defaultValue={''}
                selectOptions={FraneAttiveOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  !watchStatoAttivo ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
            <GridWrap item xs={3}>
              <RHFSelect
                name="gradoCriticitaFrana"
                label={'Parametro del grado di criticità*'}
                defaultValue={''}
                selectOptions={GradoCriticitaFranaOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  !watchFenomenoPotenziale ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </GridWrap>
            <GridWrap item xs={3}></GridWrap>
            <InfoWrapper
              size={3}
              compassMessage={INFO.velocitaMaxSpostamentoFrana.compassMessage}
              quoteMessage={INFO.velocitaMaxSpostamentoFrana.quoteMessage}
            >
              <RHFSelect
                name="velocitaMaxSpostamentoFrana"
                label={'Par. della max velocità potenziale spostamento*'}
                defaultValue={''}
                selectOptions={VelocitaMaxSpostamentoFranaOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <GridWrap item xs={3}>
              <CheckboxContainer>
                <RHFCheckbox
                  name="velocitaMaxSpostamentoFranaEffettivo"
                  label="Effettivo"
                  disabled={
                    !rischioPresenteDaVerificare ||
                    watchVelocitaMaxSpostamentoFranaPresunto ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                  defaultValue={false}
                />
                <RHFCheckbox
                  name="velocitaMaxSpostamentoFranaPresunto"
                  label="Presunto"
                  disabled={
                    !rischioPresenteDaVerificare ||
                    watchVelocitaMaxSpostamentoFranaEffettivo ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                  defaultValue={false}
                />
              </CheckboxContainer>
            </GridWrap>
            <InfoWrapper
              size={3}
              infoMessage={INFO.magnitudoBaseVolumetrica.infoMessage}
            >
              <RHFSelect
                name="magnitudoBaseVolumetrica"
                label={'Par. della magnitudo su base volumetrica [m³]*'}
                defaultValue={''}
                selectOptions={MagnitudoBaseVolumetricaOptions}
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <GridWrap item xs={3}>
              <CheckboxContainer>
                <RHFCheckbox
                  name="magnitudoBaseVolumetricaEffettivo"
                  label="Effettivo"
                  disabled={
                    !rischioPresenteDaVerificare ||
                    watchMagnitudoBaseVolumetricaPresunto ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                  defaultValue={false}
                />
                <RHFCheckbox
                  name="magnitudoBaseVolumetricaPresunto"
                  label="Presunto"
                  disabled={
                    !rischioPresenteDaVerificare ||
                    watchMagnitudoBaseVolumetricaEffettivo ||
                    inspectionDisabled ||
                    isViewingInspection
                  }
                  defaultValue={false}
                />
              </CheckboxContainer>
            </GridWrap>
          </GridWrap>

          <GridWrap container spacing={2} padding="20px">
            <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
              <TitleSection>
                <Typography variant="h3BoldBlue">OSSERVAZIONI</Typography>
              </TitleSection>
            </GridWrap>
            <InfoWrapper
              size={12}
              infoMessage={INFO.annotazioniRischioFrane.infoMessage}
            >
              <RHFTextField
                name="annotazioniRischioFrane"
                label={'Annotazioni rischio frane'}
                inputProps={{ maxLength: 2000 }}
                defaultValue=""
                disabled={
                  !rischioPresenteDaVerificare ||
                  inspectionDisabled ||
                  isViewingInspection
                }
              />
            </InfoWrapper>
            <GridWrap item md={3}>
              <MediaUpload
                title={`Foto di annotazione rischio frane`}
                btnTitle="Allega file"
                formName="fotoAnnotazioniRischioFrane"
                infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
                dataType="video/*,image/*"
                preview={true}
                uploadType={UploadMedia.media}
                type={FieldType.images}
                mediaGroup={[MediaGroup.Contesto, MediaGroup.Frane]}
                disabled={!rischioPresenteDaVerificare}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};

export { Accordion3LandslideRiskL1 };
