import * as am5 from '@amcharts/amcharts5';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import '../../globals.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { it } from 'date-fns/locale';
import { BrowserRouter } from 'react-router-dom';
import {
  AmplifyProvider,
  HttpProvider,
  LoadingProvider,
  SnackbarProvider,
} from 'providers';
import Router from 'routes';

import theme from 'theme';

const App = () => {
  // TODO: Replace <TestForm /> with Routing components
  // (if we choose to use decleartive routing instead of useRoutes / Outlet)
  am5.addLicense(process.env.REACT_APP_AMCHART);
  am5.addLicense(process.env.REACT_APP_AMCHARTSMAP);
  return (
    <HttpProvider>
      <AmplifyProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
            <SnackbarProvider>
              <BrowserRouter>
                <LoadingProvider />
                <Router />
              </BrowserRouter>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AmplifyProvider>
    </HttpProvider>
  );
};

export default App;
