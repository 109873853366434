import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import {
  mensole,
  TipologiaPavimentazioneTableValue,
} from 'constants/inspections';
import { tipologiaPavimentazioneOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
const PavimentazioneForm = ({
  pavimentazioneFields,
  selected,
  setSelected,
  pavimentazioneTable,
  selectedIdx,
}) => {
  // codice: `PV-${el.codice}`,
  //           tipologiaPavimentazione: '',
  //           spessorePavimentazione: '',
  //           superficiePavimentazione: '',
  // setValue('l0t3a2_pavimentazioneTableValue', [item]);
  // setValue(
  //   'l0t3a2_tipologiaPavimentazioneTableValue',
  //   item.tipologiaPavimentazione
  // );
  // setValue(
  //   'l0t3a2_superficiePavimentazioneTableValue',
  //   item.superficiePavimentazione
  // );

  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t3a2_pavimentazioneTableValue: [],
      l0t3a2_tipologiaPavimentazioneTableValue: '',
      l0t3a2_tipologiaAltroPavimentazioneTableValue: '',
      l0t3a2_spessorePavimentazioneTableValue: '',
      l0t3a2_superficiePavimentazioneTableValue: '',
    },
  });

  useEffect(() => {
    setValue('l0t3a2_pavimentazioneTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetPavimentazione();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitPavimentazione = () => {
    const l0t3a2_tipologiaPavimentazioneTableValue = getValues(
      'l0t3a2_tipologiaPavimentazioneTableValue'
    );
    const l0t3a2_tipologiaAltroPavimentazioneTableValue = getValues(
      'l0t3a2_tipologiaAltroPavimentazioneTableValue'
    );
    const l0t3a2_spessorePavimentazioneTableValue = getValues(
      'l0t3a2_spessorePavimentazioneTableValue'
    );
    const l0t3a2_superficiePavimentazioneTableValue = getValues(
      'l0t3a2_superficiePavimentazioneTableValue'
    );

    const ids = selected.map(el => el.codice);

    const pavimentazione = [...pavimentazioneFields];

    pavimentazione.map((x, index) => {
      if (ids.includes(x?.codice)) {
        pavimentazione[index] = {
          ...x,
          tipologiaPavimentazione: l0t3a2_tipologiaPavimentazioneTableValue,
          tipologiaAltroPavimentazione:
            l0t3a2_tipologiaAltroPavimentazioneTableValue,
          spessorePavimentazione: l0t3a2_spessorePavimentazioneTableValue,
          superficiePavimentazione: l0t3a2_superficiePavimentazioneTableValue,
        };
      }
    });

    setValue(pavimentazioneTable, pavimentazione);
    onResetPavimentazione();
  };
  const onResetPavimentazione = () => {
    setValue('l0t3a2_pavimentazioneTableValue', []);
    setValue('l0t3a2_tipologiaPavimentazioneTableValue', '');
    setValue('l0t3a2_tipologiaAltroPavimentazioneTableValue', '');
    setValue('l0t3a2_spessorePavimentazioneTableValue', '');
    setValue('l0t3a2_superficiePavimentazioneTableValue', '');

    setSelected([]);
  };
  const l0t3a2_sovrastrutturaImpalcatoSelezioneWatch = watch(
    'l0t3a2_sovrastrutturaImpalcatoSelezione'
  );
  const pavimentazioneTableWatch = watch('l0t3a2_pavimentazioneTableValue');
  const larghezzaCarreggiata = getValues('larghezzaCarreggiata');
  useEffect(() => {
    const watchMensole = watch('mensole');
    const watchMensoleSi = watchMensole === mensole.Si;
    let result;
    if (
      !isNullOrUndefinedOrEmptyString(
        l0t3a2_sovrastrutturaImpalcatoSelezioneWatch
      )
    ) {
      if (watchMensoleSi) {
        const mensoleTamponiTableWatch = getValues(
          `mensoleTamponiTable[${l0t3a2_sovrastrutturaImpalcatoSelezioneWatch}]`
        );
        result =
          parseFloat(mensoleTamponiTableWatch.luce) * larghezzaCarreggiata;
      } else {
        const campataTableWatch = getValues(
          `campataTable[${l0t3a2_sovrastrutturaImpalcatoSelezioneWatch}]`
        );
        if (campataTableWatch?.luceCampata && larghezzaCarreggiata) {
          result =
            parseFloat(campataTableWatch?.luceCampata) * larghezzaCarreggiata;
        }
      }
      result &&
        setValue(
          'l0t3a2_superficiePavimentazioneTableValue',
          result?.toFixed(2)
        );
    }
  }, [
    l0t3a2_sovrastrutturaImpalcatoSelezioneWatch,
    pavimentazioneTableWatch,
    larghezzaCarreggiata,
  ]);

  const tipologiaPavimentazioneWatch = watch(
    'l0t3a2_tipologiaPavimentazioneTableValue'
  );
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (
      tipologiaPavimentazioneWatch === TipologiaPavimentazioneTableValue.Altro
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setValue('l0t3a2_tipologiaAltroPavimentazioneTableValue', '');
    }
  }, [tipologiaPavimentazioneWatch]);

  return (
    <GridWrap container spacing={2} mt={2}>
      <GridWrap item xs={6}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-pavimentazione_l0t3a2"
          name={'l0t3a2_pavimentazioneTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={pavimentazioneFields
            .filter(el => !el.assente)
            ?.filter(el => !selected?.some(s => s?.id === el?.id))}
          label="Pavimentazione selezionata"
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFSelect
          name="l0t3a2_tipologiaPavimentazioneTableValue"
          label="Tipologia pavimentazione*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a2_tipologiaPavimentazioneTableValue',
              e.target.value
            );
          }}
          selectOptions={tipologiaPavimentazioneOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="l0t3a2_tipologiaAltroPavimentazioneTableValue"
          label={'Tipologia Pavimentazione (Altro)'}
          disabled={isDisabled}
          inputProps={{ maxLength: 100 }}
          defaultValue=""
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="l0t3a2_spessorePavimentazioneTableValue"
          label={'Spessore (s) [cm]'}
          defaultValue=""
          decimalScale={2}
          withValueLimit={({ value }) => value < 1000}
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoMessage={INFO.superficiePavimentazione.infoMessage}
      >
        <RHFNumberTextField
          name="l0t3a2_superficiePavimentazioneTableValue"
          label={'Superficie [m²]'}
          defaultValue=""
          decimalScale={2}
          disabled
          /* InputLabelProps={{ shrink: true }} */
          withValueLimit={({ value }) => value < 1000}
        />
      </InfoWrapper>
      <GridWrap item xs={6}>
        <FormSubmitButtons
          onSubmit={onSubmitPavimentazione}
          onReset={onResetPavimentazione}
        />
      </GridWrap>
    </GridWrap>
  );
};
PavimentazioneForm.propTypes = {
  pavimentazioneFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaPavimentazione: PropTypes.number,
      spessorePavimentazione: PropTypes.number,
      superficiePavimentazione: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  pavimentazioneTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default PavimentazioneForm;
