export const markdown_faq_21_1 = `E' possibile allegare file, sia immagini che documenti, all'interno dell'attività, sia nel Livello 0 che nel Livello 1.

Per allegare i file nelle schede di Livello 0 e Livello 1, nei campi previsti:
1. Selezionare *Allega file* o l'icona dell'immagine.

Nel pannello appena aperto:

2. Selezionare *Carica file* e scegliere l'immagine tra le cartelle del proprio computer
3. Selezionare *Conferma* per confermare i file caricati.`;
export const markdown_faq_21_2 = `Per ogni file è possibile aggiungere autore, data, descrizione e tag, che consentono una ricerca più veloce nelle sezioni di Immagini e Documenti del ponte o del database.

Prima di confermare:
1.  Selezionare la penna
2. Inserire i dati
3. Salvare i dati
4. Confermare il caricamento.`;
