import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaArcoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import {
  degrees_to_radians,
  isNullOrUndefinedOrEmptyString,
  radians_to_degrees,
} from 'utils/utilities';
const ArchiForm = ({
  archiFields,
  selected,
  setSelected,
  archiTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t2a5_archiTableValue: [],
      l0t2a5_tipologiaArcoTableValue: '',
      l0t2a5_luceArcoTableValue: '',
      l0t2a5_larghezzaSuperioreArcoTableValue: '',
      l0t2a5_larghezzaInferioreArcoTableValue: '',
      l0t2a5_spessoreArcoTableValue: '',
      l0t2a5_frecciaArcoTableValue: '',
      l0t2a5_superficieArcoTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('l0t2a5_archiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetArchi();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeArchi = () => {
    const l0t2a5_tipologiaArcoTableValue = getValues(
      'l0t2a5_tipologiaArcoTableValue'
    );

    const l0t2a5_luceArcoTableValue = getValues('l0t2a5_luceArcoTableValue');
    const l0t2a5_larghezzaSuperioreArcoTableValue = getValues(
      'l0t2a5_larghezzaSuperioreArcoTableValue'
    );

    const l0t2a5_larghezzaInferioreArcoTableValue = getValues(
      'l0t2a5_larghezzaInferioreArcoTableValue'
    );

    const l0t2a5_spessoreArcoTableValue = getValues(
      'l0t2a5_spessoreArcoTableValue'
    );
    const l0t2a5_frecciaArcoTableValue = getValues(
      'l0t2a5_frecciaArcoTableValue'
    );
    const l0t2a5_superficieArcoTableValue = getValues(
      'l0t2a5_superficieArcoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const archi = [...archiFields];

    archi.map((x, index) => {
      if (ids.includes(x?.codice)) {
        archi[index] = {
          ...x,

          tipologiaArco: l0t2a5_tipologiaArcoTableValue,
          luceArco: l0t2a5_luceArcoTableValue,
          larghezzaSuperioreArco: l0t2a5_larghezzaSuperioreArcoTableValue,
          larghezzaInferioreArco: l0t2a5_larghezzaInferioreArcoTableValue,
          spessoreArco: l0t2a5_spessoreArcoTableValue,
          frecciaArco: l0t2a5_frecciaArcoTableValue,
          superficieArco: l0t2a5_superficieArcoTableValue,
          ...(l0t2a5_tipologiaArcoTableValue !== x.tipologiaArco
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'arco',
                  l0t2a5_tipologiaArcoTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(archiTable, archi);
    onResetArchi();
  };

  const onConfirmChangeArchi = () => {
    setOpenConfirmationDialog(false);
    changeArchi();
  };

  const onSubmitArchi = () => {
    const l0t2a5_tipologiaArcoTableValue = getValues(
      'l0t2a5_tipologiaArcoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const archi = [...archiFields];

    const hasChangedTipologia = archi.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          l0t2a5_tipologiaArcoTableValue !== el.tipologiaArco &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaArco)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeArchi();
    }
  };

  const luceArcoWatch = parseFloat(watch('l0t2a5_luceArcoTableValue'));
  const frecciaArcoWatch = parseFloat(watch('l0t2a5_frecciaArcoTableValue'));
  const larghezzaInfArcoWatch = parseFloat(
    watch('l0t2a5_larghezzaInferioreArcoTableValue')
  );
  const larghezzaSupArcoWatch = parseFloat(
    watch('l0t2a5_larghezzaSuperioreArcoTableValue')
  );
  // [Lunghezza arco*(Larghezza inferiore+Larghezza superiore)]/10000.
  useEffect(() => {
    if (
      selected?.length > 0 &&
      typeof luceArcoWatch === 'number' &&
      typeof frecciaArcoWatch === 'number' &&
      typeof larghezzaInfArcoWatch === 'number' &&
      typeof larghezzaSupArcoWatch === 'number'
    ) {
      const radius =
        (luceArcoWatch * luceArcoWatch) / 8 / frecciaArcoWatch +
        frecciaArcoWatch / 2;

      const angle =
        radians_to_degrees(Math.asin(luceArcoWatch / 2 / radius)) * 2;

      const arcLength = radius * degrees_to_radians(angle);
      const superficie = (
        (arcLength * (larghezzaInfArcoWatch + larghezzaSupArcoWatch)) /
        10000
      ).toFixed(2);
      if (!isNaN(superficie)) {
        setValue('l0t2a5_superficieArcoTableValue', superficie);
      }
    } else {
      setValue('l0t2a5_superficieArcoTableValue', '');
    }
  }, [
    luceArcoWatch,
    frecciaArcoWatch,
    larghezzaInfArcoWatch,
    larghezzaSupArcoWatch,
    selected,
  ]);

  const onResetArchi = () => {
    setValue('l0t2a5_archiTableValue', []);
    setValue('l0t2a5_tipologiaArcoTableValue', '');
    setValue('l0t2a5_luceArcoTableValue', '');
    setValue('l0t2a5_larghezzaSuperioreArcoTableValue', '');
    setValue('l0t2a5_larghezzaInferioreArcoTableValue', '');
    setValue('l0t2a5_spessoreArcoTableValue', '');
    setValue('l0t2a5_frecciaArcoTableValue', '');
    setValue('l0t2a5_superficieArcoTableValue', '');

    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={6} infoMessage={INFO.archiSelezionati.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-archi"
            name={'l0t2a5_archiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={archiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Archi selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="l0t2a5_tipologiaArcoTableValue"
            label="Tipologia Arco*"
            defaultValue={''}
            onChange={e => {
              setValue('l0t2a5_tipologiaArcoTableValue', e.target.value);
            }}
            selectOptions={tipologiaArcoOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_luceArcoTableValue"
            label={'Luce (L) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_larghezzaSuperioreArcoTableValue"
            label={'Larghezza superiore (Ls) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_larghezzaInferioreArcoTableValue"
            label={'Larghezza inferiore (Li) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_spessoreArcoTableValue"
            label={'Spessore (s) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="l0t2a5_frecciaArcoTableValue"
            label={'Freccia (f) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 1000}
          />
        </GridWrap>
        <InfoWrapper size={3} infoMessage={INFO.superficieArco.infoMessage}>
          <RHFNumberTextField
            name="l0t2a5_superficieArcoTableValue"
            label={'Superficie [m²]'}
            defaultValue=""
            decimalScale={2}
            disabled
            InputLabelProps={{ shrink: true }}
            withValueLimit={({ value }) => value < 1000}
          />
        </InfoWrapper>
        <GridWrap item xs={9}>
          <FormSubmitButtons onSubmit={onSubmitArchi} onReset={onResetArchi} />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeArchi}
      />
    </>
  );
};
ArchiForm.propTypes = {
  archiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaArco: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      luceArco: PropTypes.number,
      larghezzaSuperioreArco: PropTypes.number,
      larghezzaInferioreArco: PropTypes.number,
      spessoreArco: PropTypes.number,
      frecciaArco: PropTypes.number,
      superficieArco: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  archiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default ArchiForm;
