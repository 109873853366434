import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import {
  mensole,
  TipologiaSpartitrafficoTableValue,
} from 'constants/inspections';
import { tipologiaSpartitrafficoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
const SpartitrafficoForm = ({
  spartitrafficoFields,
  selected,
  setSelected,
  spartitrafficoTable,
  selectedIdx,
}) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      l0t3a2_spartitrafficoTableValue: [],
      l0t3a2_tipologiaSpartitrafficoTableValue: '',
      l0t3a2_tipologiaAltroSpartitrafficoTableValue: '',
      l0t3a2_larghezzaSpartitrafficoTableValue: '',
      l0t3a2_altezzaSpartitrafficoTableValue: '',
      l0t3a2_superficieSpartitrafficoTableValue: '',
    },
  });

  useEffect(() => {
    setValue('l0t3a2_spartitrafficoTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetSpartitraffico();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitSpartitraffico = () => {
    const l0t3a2_tipologiaSpartitrafficoTableValue = getValues(
      'l0t3a2_tipologiaSpartitrafficoTableValue'
    );
    const l0t3a2_tipologiaAltroSpartitrafficoTableValue = getValues(
      'l0t3a2_tipologiaAltroSpartitrafficoTableValue'
    );
    const l0t3a2_larghezzaSpartitrafficoTableValue = getValues(
      'l0t3a2_larghezzaSpartitrafficoTableValue'
    );
    const l0t3a2_altezzaSpartitrafficoTableValue = getValues(
      'l0t3a2_altezzaSpartitrafficoTableValue'
    );
    const l0t3a2_superficieSpartitrafficoTableValue = getValues(
      'l0t3a2_superficieSpartitrafficoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const spartitraffico = [...spartitrafficoFields];
    spartitraffico.map((x, index) => {
      if (ids.includes(x?.codice)) {
        spartitraffico[index] = {
          ...x,
          tipologiaSpartitraffico: l0t3a2_tipologiaSpartitrafficoTableValue,
          tipologiaAltroSpartitraffico:
            l0t3a2_tipologiaAltroSpartitrafficoTableValue,
          larghezzaSpartitraffico: l0t3a2_larghezzaSpartitrafficoTableValue,
          altezzaSpartitraffico: l0t3a2_altezzaSpartitrafficoTableValue,
          superficieSpartitraffico: l0t3a2_superficieSpartitrafficoTableValue,
        };
      }
    });

    setValue(spartitrafficoTable, spartitraffico);
    onResetSpartitraffico();
  };
  const onResetSpartitraffico = () => {
    setValue('l0t3a2_spartitrafficoTableValue', []);
    setValue('l0t3a2_tipologiaSpartitrafficoTableValue', '');
    setValue('l0t3a2_tipologiaAltroSpartitrafficoTableValue', '');
    setValue('l0t3a2_larghezzaSpartitrafficoTableValue', '');
    setValue('l0t3a2_altezzaSpartitrafficoTableValue', '');
    setValue('l0t3a2_superficieSpartitrafficoTableValue', '');

    setSelected([]);
  };

  // 2*Luce impalcato *(Altezza/100) + Luce impalcato*(Larghezza/100)
  const l0t3a2_sovrastrutturaImpalcatoSelezioneWatch = watch(
    'l0t3a2_sovrastrutturaImpalcatoSelezione'
  );
  const altezzaSpartitrafficoWatch = parseFloat(
    watch('l0t3a2_altezzaSpartitrafficoTableValue')
  );
  const larghezzaSpartitrafficoWatch = parseFloat(
    watch('l0t3a2_larghezzaSpartitrafficoTableValue')
  );
  const spartitrafficoTableWatch = watch('l0t3a2_spartitrafficoTableValue');
  useEffect(() => {
    const watchMensole = watch('mensole');
    const watchMensoleSi = watchMensole === mensole.Si;
    let result;

    if (watchMensoleSi) {
      const mensoleTamponiTableWatch = getValues(
        `mensoleTamponiTable[${l0t3a2_sovrastrutturaImpalcatoSelezioneWatch}]`
      );
      result =
        2 *
          parseFloat(mensoleTamponiTableWatch.luce) *
          (altezzaSpartitrafficoWatch / 100) +
        parseFloat(mensoleTamponiTableWatch.luce) *
          (larghezzaSpartitrafficoWatch / 100);
    } else {
      const campataTableWatch = getValues(
        `campataTable[${l0t3a2_sovrastrutturaImpalcatoSelezioneWatch}]`
      );
      result =
        2 *
          parseFloat(campataTableWatch.luceCampata) *
          (altezzaSpartitrafficoWatch / 100) +
        parseFloat(campataTableWatch.luceCampata) *
          (larghezzaSpartitrafficoWatch / 100);
    }
    setValue('l0t3a2_superficieSpartitrafficoTableValue', result);
  }, [
    l0t3a2_sovrastrutturaImpalcatoSelezioneWatch,
    spartitrafficoTableWatch,
    altezzaSpartitrafficoWatch,
  ]);

  const tipologiaSpartitrafficoWatch = watch(
    'l0t3a2_tipologiaSpartitrafficoTableValue'
  );
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (
      tipologiaSpartitrafficoWatch === TipologiaSpartitrafficoTableValue.Altro
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setValue('l0t3a2_tipologiaAltroSpartitrafficoTableValue', '');
    }
  }, [tipologiaSpartitrafficoWatch]);

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper
        size={6}
        infoMessage={INFO.spartitrafficoSelezionati.infoMessage}
      >
        <RHFTagAutocomplete
          multiple={true}
          id="tags-spartitraffico_l0t3a2"
          name={'l0t3a2_spartitrafficoTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={spartitrafficoFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Spartitraffico selezionati"
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="l0t3a2_tipologiaSpartitrafficoTableValue"
          label="Tipologia Spartitraffico*"
          defaultValue={''}
          onChange={e => {
            setValue(
              'l0t3a2_tipologiaSpartitrafficoTableValue',
              e.target.value
            );
          }}
          selectOptions={tipologiaSpartitrafficoOptions}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFTextField
          name="l0t3a2_tipologiaAltroSpartitrafficoTableValue"
          label={'Tipologia Spartitraffico (Altro)'}
          disabled={isDisabled}
          inputProps={{ maxLength: 100 }}
          defaultValue=""
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="l0t3a2_larghezzaSpartitrafficoTableValue"
          label={'Larghezza (L) [cm]'}
          defaultValue=""
          decimalScale={2}
          withValueLimit={({ value }) => value < 1000}
        />
      </GridWrap>
      <GridWrap item xs={3}>
        <RHFNumberTextField
          name="l0t3a2_altezzaSpartitrafficoTableValue"
          label={'Altezza (H) [cm]'}
          defaultValue=""
          decimalScale={2}
          withValueLimit={({ value }) => value < 1000}
        />
      </GridWrap>
      <InfoWrapper
        size={3}
        infoMessage={INFO.superficieSpartitraffico.infoMessage}
      >
        <RHFNumberTextField
          name="l0t3a2_superficieSpartitrafficoTableValue"
          label={'Superficie [m²]'}
          defaultValue=""
          decimalScale={2}
          disabled
          InputLabelProps={{ shrink: true }}
          withValueLimit={({ value }) => value < 1000}
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <FormSubmitButtons
          onSubmit={onSubmitSpartitraffico}
          onReset={onResetSpartitraffico}
        />
      </GridWrap>
    </GridWrap>
  );
};
SpartitrafficoForm.propTypes = {
  spartitrafficoFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tipologiaSpartitraffico: PropTypes.number,
      tipologiaAltroSpartitraffico: PropTypes.string,
      larghezzaSpartitraffico: PropTypes.number,
      altezzaSpartitraffico: PropTypes.number,
      superficieSpartitraffico: PropTypes.number,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  spartitrafficoTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};
export default SpartitrafficoForm;
