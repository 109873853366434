import PropTypes from 'prop-types';
import { useState } from 'react';
import { CollaboratorsListActionsMap } from 'constants/users';

export const useCollaboratorsListWarningDialog = ({
  handleCollaboratorDelete,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [actionSelected, setActionSelected] = useState();
  const [collaboratorSelected, setCollaboratorSelected] = useState();

  const handleSelectRow = (action, collaboratorId) => {
    setOpenDialog(true);
    setCollaboratorSelected(collaboratorId);
    setActionSelected(action);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setActionSelected();
    setCollaboratorSelected();
  };

  const handleDialogAction = () => {
    switch (actionSelected) {
      case CollaboratorsListActionsMap.DELETE:
        handleCollaboratorDelete(collaboratorSelected);
        break;
    }
    handleCloseDialog();
  };

  return {
    openDialog,
    handleCloseDialog,
    handleDialogAction,
    actionSelected,
    handleSelectRow,
  };
};

useCollaboratorsListWarningDialog.propTypes = {
  handleCollaboratorDelete: PropTypes.func,
};
