import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const GridWrap = styled(Grid)(
  ({ marginBottom, marginTop = '5px', paddingTop }) => ({
    marginBottom: marginBottom,
    marginTop: marginTop,
    paddingTop: paddingTop,
  })
);

export const BtnContainer = styled('div')({
  display: 'flex',
  gap: 3,
  justifyContent: 'center',
});
