import { useFormContext } from 'react-hook-form';
import { ElementiCriticiVulnerabilitaSismica } from 'constants/inspections';
/* eslint-disable */
const useCDARecap = () => {
  const { getValues, setValue } = useFormContext();

  const handleCriticoValue = table => {
    const noElementsTable = table[0]?.length === 0;
    let elCriticoVal = 2;
    if (!noElementsTable) {
      table?.map(el => {
        if (el?.some(el => el === 1)) {
          return (elCriticoVal = 1);
        }
      });
    } else {
      elCriticoVal = undefined;
    }
    return elCriticoVal;
  };

  const handleCriticalArray = (
    elCriticoPileTableVal,
    elCriticoSpalleTableVal,
    antenneT,
    appoggiT,
    giuntiT,
    pulviniT,
    controventiT,
    soletteT,
    traversiT,
    traviT,
    archiT,
    piedrittiT,
    tirantiT,
    elementiExtraT
  ) => {
    const elCriticoAntenneTableVal = handleCriticoValue(antenneT);
    const elCriticoAppoggiTableVal = handleCriticoValue(appoggiT);
    const elCriticoGiuntiTableVal = handleCriticoValue(giuntiT);
    const elCriticoPulviniTableVal = handleCriticoValue(pulviniT);
    const elCriticoControventiTableVal = handleCriticoValue(controventiT);
    const elCriticoSoletteTableVal = handleCriticoValue(soletteT);
    const elCriticoTraversiTableVal = handleCriticoValue(traversiT);
    const elCriticoTraviTableVal = handleCriticoValue(traviT);
    const elCriticoArchiTableVal = handleCriticoValue(archiT);
    const elCriticoPiedrittiTableVal = handleCriticoValue(piedrittiT);
    const elCriticoTirantiTableVal = handleCriticoValue(tirantiT);
    const elCriticoElementiExtraTableVal = handleCriticoValue(elementiExtraT);

    const elValArray = [
      elCriticoPileTableVal,
      elCriticoSpalleTableVal,
      elCriticoAntenneTableVal,
      elCriticoAppoggiTableVal,
      elCriticoGiuntiTableVal,
      elCriticoPulviniTableVal,
      elCriticoControventiTableVal,
      elCriticoSoletteTableVal,
      elCriticoTraversiTableVal,
      elCriticoTraviTableVal,
      elCriticoArchiTableVal,
      elCriticoPiedrittiTableVal,
      elCriticoTirantiTableVal,
      elCriticoElementiExtraTableVal,
    ];

    const _elValArray = elValArray.filter(el => el !== undefined);

    return _elValArray;
  };

  const handleCDAPresence = (cdaProperty, codesMultiselectName, selectName) => {
    const checkCDAElementSpallePile = table => {
      if (table?.length > 0) {
        const elCriticoTableVal = table?.map(el => el?.[cdaProperty]) || [];
        let elCriticoVal = 2;

        if (elCriticoTableVal?.some(el => el === 1)) {
          elCriticoVal = 1;
        }
        return elCriticoVal;
      }
    };

    const watchPileTable = getValues('pileTable');
    const watchSpalleTable = getValues('spalleTable');
    const watchSpallePileCollegamentiTable = getValues(
      'spallePileCollegamentiTable'
    );
    const watchMensoleTamponiCampateImpalcatoTable = getValues(
      'mensoleTamponiCampateImpalcatoTable'
    );
    if (
      !watchPileTable ||
      !watchSpalleTable ||
      !watchSpallePileCollegamentiTable ||
      !watchMensoleTamponiCampateImpalcatoTable
    ) {
      return;
    }

    //Antenne
    const antenneT = watchSpallePileCollegamentiTable
      .map(el => el?.antenneTable)
      ?.map(ant => ant.map(a => a?.[cdaProperty]));

    //Appoggi
    const appoggiT = watchSpallePileCollegamentiTable
      .map(el => el?.appoggiTable)
      ?.map(ant => ant.map(a => a?.[cdaProperty]));

    //Giunti
    const giuntiT = watchSpallePileCollegamentiTable
      .map(el => el?.giuntiTable)
      ?.map(giun => giun.map(a => a?.[cdaProperty]));

    //Pulvini
    const pulviniT = watchSpallePileCollegamentiTable
      .map(el => el?.pulviniTable)
      ?.map(pul => pul.map(a => a?.[cdaProperty]));

    //Controventi
    const controventiT = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.controventiTable)
      ?.map(con => con.map(a => a?.[cdaProperty]));

    //Solette
    const soletteT = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.soletteTable)
      ?.map(sol => sol.map(a => a?.[cdaProperty]));

    //Traversi
    const traversiT = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.traversiTable)
      ?.map(tra => tra.map(a => a?.[cdaProperty]));

    //Travi
    const traviT = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.traviTable)
      ?.map(t => t.map(a => a?.[cdaProperty]));

    //Archi
    const archiT = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.archiTable)
      ?.map(t => t.map(a => a?.[cdaProperty]));

    //Piedritti
    const piedrittiT = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.piedrittiTable)
      ?.map(t => t.map(a => a?.[cdaProperty]));

    //Tiranti
    const tirantiT = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.tirantiTable)
      ?.map(t => t.map(a => a?.[cdaProperty]));

    //ElementiExtra
    const elementiExtraT = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.elementiExtraTable)
      ?.map(t => t.map(a => a?.[cdaProperty]));

    const elCriticoPileTableVal = checkCDAElementSpallePile(watchPileTable);

    const elCriticoSpalleTableVal = checkCDAElementSpallePile(watchSpalleTable);

    const criticalCDAArr = handleCriticalArray(
      elCriticoPileTableVal,
      elCriticoSpalleTableVal,
      antenneT,
      appoggiT,
      giuntiT,
      pulviniT,
      controventiT,
      soletteT,
      traversiT,
      traviT,
      archiT,
      piedrittiT,
      tirantiT,
      elementiExtraT
    );

    //FILTER CODICI
    //Spalle
    const spallePresenti =
      watchSpalleTable?.length &&
      watchSpalleTable.map(el => el?.[cdaProperty]).find(f => f === 1);

    const spalleCodici = [];
    if (spallePresenti) {
      watchSpalleTable.map(el => {
        el[cdaProperty] === 1 && spalleCodici.push(el.codiceSpalla);
      });
    }

    //Pile
    const pilePresenti =
      watchPileTable?.length &&
      watchPileTable.map(el => el?.[cdaProperty]).find(f => f === 1);

    const pileCodici = [];
    if (pilePresenti) {
      watchPileTable.map(el => {
        el[cdaProperty] === 1 && pileCodici.push(el.codicePila);
      });
    }

    //Antenne
    const antennePresenti = watchSpallePileCollegamentiTable
      .map(el => el?.antenneTable)
      .map(t => t)
      .flat()
      .map(ant => ant[cdaProperty])
      .find(f => f === 1);

    const antenneCodici = [];
    if (antennePresenti) {
      watchSpallePileCollegamentiTable
        .map(el => el?.antenneTable)
        .map(t => t)
        .flat()
        .map(ant => ant?.[cdaProperty] === 1 && antenneCodici.push(ant.codice));
    }

    //Appoggi
    const appoggiPresenti = watchSpallePileCollegamentiTable
      .map(el => el?.appoggiTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const appoggiCodici = [];
    if (appoggiPresenti) {
      watchSpallePileCollegamentiTable
        .map(el => el?.appoggiTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && appoggiCodici.push(a.codice));
    }

    //Giunti
    const giuntiPresenti = watchSpallePileCollegamentiTable
      .map(el => el?.giuntiTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const giuntiCodici = [];
    if (giuntiPresenti) {
      watchSpallePileCollegamentiTable
        .map(el => el?.giuntiTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && giuntiCodici.push(a.codice));
    }

    //Pulvini
    const pulviniPresenti = watchSpallePileCollegamentiTable
      .map(el => el?.pulviniTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const pulviniCodici = [];
    if (pulviniPresenti) {
      watchSpallePileCollegamentiTable
        .map(el => el?.pulviniTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && pulviniCodici.push(a.codice));
    }

    //Controventi
    const controventiPresenti = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.controventiTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const controventiCodici = [];
    if (controventiPresenti) {
      watchMensoleTamponiCampateImpalcatoTable
        .map(el => el?.controventiTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && controventiCodici.push(a.codice));
    }

    //Solette
    const solettePresenti = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.soletteTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const soletteCodici = [];
    if (solettePresenti) {
      watchMensoleTamponiCampateImpalcatoTable
        .map(el => el?.soletteTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && soletteCodici.push(a.codice));
    }

    //Traversi
    const traversiPresenti = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.traversiTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const traversiCodici = [];
    if (traversiPresenti) {
      watchMensoleTamponiCampateImpalcatoTable
        .map(el => el?.traversiTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && traversiCodici.push(a.codice));
    }

    //Travi
    const traviPresenti = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.traviTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const traviCodici = [];
    if (traviPresenti) {
      watchMensoleTamponiCampateImpalcatoTable
        .map(el => el?.traviTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && traviCodici.push(a.codice));
    }

    //Archi
    const archiPresenti = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.archiTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const archiCodici = [];
    if (archiPresenti) {
      watchMensoleTamponiCampateImpalcatoTable
        .map(el => el?.archiTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && archiCodici.push(a.codice));
    }

    //Piedritti
    const piedrittiPresenti = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.piedrittiTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const piedrittiCodici = [];
    if (piedrittiPresenti) {
      watchMensoleTamponiCampateImpalcatoTable
        .map(el => el?.piedrittiTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && piedrittiCodici.push(a.codice));
    }

    //Tiranti
    const tirantiPresenti = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.tirantiTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const tirantiCodici = [];
    if (tirantiPresenti) {
      watchMensoleTamponiCampateImpalcatoTable
        .map(el => el?.tirantiTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && tirantiCodici.push(a.codice));
    }

    //ElementiExtra
    const elementiExtraPresenti = watchMensoleTamponiCampateImpalcatoTable
      .map(el => el?.elementiExtraTable)
      .map(t => t)
      .flat()
      .map(a => a[cdaProperty])
      .find(f => f === 1);

    const elementiExtraCodici = [];
    if (elementiExtraPresenti) {
      watchMensoleTamponiCampateImpalcatoTable
        .map(el => el?.elementiExtraTable)
        .map(t => t)
        .flat()
        .map(a => a?.[cdaProperty] === 1 && elementiExtraCodici.push(a.codice));
    }

    const criticalElementsCodes = [
      ...spalleCodici,
      ...pileCodici,
      ...appoggiCodici,
      ...giuntiCodici,
      ...pulviniCodici,
      ...antenneCodici,
      ...soletteCodici,
      ...traviCodici,
      ...traversiCodici,
      ...controventiCodici,
      ...archiCodici,
      ...piedrittiCodici,
      ...tirantiCodici,
      ...elementiExtraCodici,
    ];

    /* if (criticalCDAArr.every(val => val === 2)) {
      setValue(
        selectName,
        ElementiCriticiVulnerabilitaSismica.Assenti
      );
    } else if (criticalCDAArr.some(val => val === 1)) {
      setValue(
       selectName,
        ElementiCriticiVulnerabilitaSismica.Presenti
      );
    } else {
      setValue(selectName,, '');
    } */

    if (criticalElementsCodes.length >= 1) {
      setValue(codesMultiselectName, criticalElementsCodes);
      setValue(selectName, ElementiCriticiVulnerabilitaSismica.Presenti);
    } else {
      setValue(selectName, ElementiCriticiVulnerabilitaSismica.Assenti);
    }
  };

  return { handleCDAPresence };
};

export default useCDARecap;
