import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CardBody, CardHeader, CardWhiteBox } from './CardHomeKpi.styles';

const CardHomeKpi = ({ title, icon, number, text, isEnte, number2, text2 }) => {
  return (
    <CardWhiteBox>
      <CardHeader>
        <img src={icon} width={26} height={26} />
        <Typography variant="h3Bold" style={{ fontSize: 14 }}>
          {title}
        </Typography>
      </CardHeader>
      <CardBody>
        <div>
          <Typography variant="h2">{number}</Typography>
          <Typography variant="h5" style={{ textTransform: 'uppercase' }}>
            {text}
          </Typography>
        </div>
        {isEnte && (
          <div>
            <Typography variant="h2">{number2}</Typography>
            <Typography variant="h5" style={{ textTransform: 'uppercase' }}>
              {text2}
            </Typography>
          </div>
        )}
      </CardBody>
    </CardWhiteBox>
  );
};

export { CardHomeKpi };

CardHomeKpi.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  number: PropTypes.number,
  text: PropTypes.string,
  isEnte: PropTypes.bool,
  number2: PropTypes.number,
  text2: PropTypes.string,
};
