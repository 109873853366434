import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import notificationClose from 'assets/icons/notifications/notification-close.svg';
import notificationOpen from 'assets/icons/notifications/notification-open.svg';
import { colors } from 'constants/colors';
import { NotificationType } from 'constants/notification';
import {
  DotAlert,
  NotificationContainer,
  TimeContainer,
} from './Notification.styles';

export const Notification = ({
  id,
  read,
  time,
  text,
  hideIcon,
  link,
  handleReadNotification,
  type,
}) => {
  const checkWorkNotificationTypeMap = [
    NotificationType.InspectionClosure,
    NotificationType.DeliverInspectionToRegisteredInstitution,
  ];
  return (
    <NotificationContainer>
      <div
        onClick={async () => {
          await handleReadNotification(id, true);
          window.open(link, '_self');
        }}
      >
        <TimeContainer>
          {!read && <DotAlert />}
          <Typography
            variant={'h3'}
            sx={{ fontWeight: read ? 'normal' : 'bold' }}
            style={{ color: !read && colors.MARINER }}
          >
            {time}
          </Typography>
        </TimeContainer>
        <Typography
          variant={'h3'}
          sx={{ fontWeight: read ? 'normal' : 'bold' }}
        >
          {text}
          {checkWorkNotificationTypeMap.includes(type) && (
            <>
              <br />
              <span
                onClick={async () => {
                  await handleReadNotification(id, true);
                  window.open(link, '_self');
                }}
              >
                Guarda il lavoro svolto.
              </span>
            </>
          )}
        </Typography>
      </div>
      {!hideIcon && (
        <img
          style={{ '&:hover': { cursor: 'pointer' } }}
          onClick={() => handleReadNotification(id, !read)}
          src={read ? notificationOpen : notificationClose}
        />
      )}
    </NotificationContainer>
  );
};

Notification.propTypes = {
  read: PropTypes.bool,
  hideIcon: PropTypes.bool,
  time: PropTypes.string,
  id: PropTypes.number,
  handleReadNotification: PropTypes.func,
  text: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.number,
};
