import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Lightbox } from 'components';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// import required modules
import './styles.css';
import { refreshS3Path } from 'utils/upload';

const ImageCarousel = ({ items, slidesPerView = 3, canClick, maxWidth }) => {
  const [open, setOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState();

  const handleOpenLightbox = el => {
    setCurrentSlide(refreshS3Path(el.original));
  };

  const handleCloseLightbox = () => {
    setOpen(false);
    setCurrentSlide();
  };

  useEffect(() => {
    currentSlide && setOpen(true);
  }, [currentSlide]);

  return (
    <>
      <Swiper
        navigation
        slidesPerView={slidesPerView}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation]}
        className="mySwiper"
      >
        {items.map(el => (
          <SwiperSlide
            style={{ maxWidth: maxWidth ? maxWidth : 'unset' }}
            key={el.orignal}
            onClick={() => canClick && handleOpenLightbox(el)}
          >
            <img loading="lazy" src={refreshS3Path(el.original)} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Lightbox
        open={open}
        onClose={handleCloseLightbox}
        imageUrl={currentSlide}
      />
    </>
  );
};
ImageCarousel.propTypes = {
  items: PropTypes.array,
  slidesPerView: PropTypes.number,
  canClick: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export { ImageCarousel };
