import { Button, Grid, TextField, Typography } from '@mui/material';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import bigBridge from 'assets/icons/bigBridge.png';
import documents from 'assets/icons/documents.png';
import elaborati from 'assets/icons/elaborati.png';
import gallery from 'assets/icons/gallery.png';
import maps from 'assets/icons/maps.png';
import {
  InfoWrapper,
  Rate,
  RHFTagAutocomplete,
  TabCreateInspection,
} from 'components';
import { getOverviewTab } from 'components/TabCreateInspection/costants';

import { apiAnnotations, apiBridges } from 'constants/api';
import { InspectionStatuses } from 'constants/colors';
import { LivelloConoscenza } from 'constants/inspections';
import { STRUCTURES_PATHS } from 'constants/paths';
import { PermissionType } from 'constants/permissions';
import {
  tipologiaImpalcatoOptions,
  tipologiaStrutturaleOptions,
} from 'constants/selectOptions';

import { tour_03 } from 'constants/tour';
import { INFO } from 'pages/Inspections/config/info';
import { FormProvider } from 'providers';
import { useGlobalSelector } from 'stores';
import { refreshS3Path } from 'utils/upload';
import { demoStructures } from 'utils/utilities';
import {
  AvatarBridge,
  AvatarSpan,
  Box,
  BridgesIcon,
  Container,
  ContainerInfo,
  ContainerMedia,
  ContainerText,
  ImageCircle,
  MediaInfoBox,
  Row,
  Status,
  Typo,
  Upper,
  WhiteBoxInfo,
} from './Summary.styles';

const Summary = ({
  structure,
  userPermissionOnStructure,
  graphRef,
  graphRef2,
  openDrawerNewInspection,
  setOpenDrawerNewInspection,
}) => {
  const navigate = useNavigate();
  const { put } = useFetch();
  const {
    get: getAnnotations,
    post: postAnnotation,
    put: putAnnotation,
  } = useFetch();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { enqueueSnackbar } = useSnackbar();

  // Annotations
  const [notes, setNotes] = useState();
  const [existingNoteId, setExistingNoteId] = useState();

  const params = useParams();
  const id = params.structureId;

  const isDemoStructure = demoStructures.includes(structure?.name);

  const structureType = structure?.tipologiaStrutturale
    ? tipologiaStrutturaleOptions?.find(obj => {
        return obj.value === structure.tipologiaStrutturale;
      })
    : '';

  const impalcatoType = structure?.tipologiaImpalcato
    ? tipologiaImpalcatoOptions?.find(obj => {
        return obj.value === structure.tipologiaImpalcato;
      })
    : '';

  const getObjKey = (obj, value) => {
    return Object.keys(obj).find(key => obj[key] === value);
  };

  const livelloConoscenza = structure?.livelloConoscenza
    ? getObjKey(LivelloConoscenza, structure.livelloConoscenza)
    : '';

  const basicRating = {
    0: '',
    1: '',
    2: '',
  };

  useEffect(() => {
    const handleAnnotations = async () => {
      try {
        const res = await getAnnotations(
          `${apiAnnotations.GET_ANNOTATIONS_STRUCTURE}/${id}`
        );
        if (res.id) {
          setExistingNoteId(res.id);
          setNotes(res.text);
        }
      } catch (err) {
        console.log('err');
      }
    };
    id && handleAnnotations();
  }, [id]);

  const handleSaveAnnotations = async () => {
    try {
      showLoader();
      if (existingNoteId) {
        await putAnnotation(`${apiAnnotations.ROOT}`, {
          id: parseInt(existingNoteId),
          text: notes,
        });
      } else {
        const structureId = parseInt(id);
        const res = await postAnnotation(`${apiAnnotations.ROOT}`, {
          structureId,
          text: notes,
        });
        setExistingNoteId(res?.id);
      }
      return enqueueSnackbar(`Nota salvata con successo`, {
        variant: 'success',
      });
    } catch (err) {
      return enqueueSnackbar(`Impossibile eseguire l'esportazione`, {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }
  };

  const renderPhotoBrigdge = () => {
    return (
      <ContainerInfo>
        <BridgesIcon>
          <AvatarBridge
            sx={{ width: '125px', height: '125px' }}
            variant="circular"
          >
            <ImageCircle src={refreshS3Path(structure?.image) || bigBridge} />
          </AvatarBridge>
          {livelloConoscenza && <AvatarSpan>{livelloConoscenza}</AvatarSpan>}
        </BridgesIcon>
        {renderTypo(structure?.status || 0)}
      </ContainerInfo>
    );
  };

  const renderBoxMedia = () => {
    return (
      <ContainerMedia className={tour_03.step_02}>
        <Box>
          <Typography sx={{ marginBottom: '12px' }} variant="h3Bold">
            {'Mappa'}
          </Typography>
          <img
            src={maps}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              structure?.latitude
                ? window.open(
                    'https://maps.google.com?q=' +
                      structure?.latitude +
                      ',' +
                      structure?.longitude
                  )
                : {}
            }
          />
        </Box>
        <Box>
          <Typography sx={{ marginBottom: '12px' }} variant="h3Bold">
            {`Gallery(${structure?.imagesCount || 0})`}
          </Typography>
          <img
            src={gallery}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                STRUCTURES_PATHS.STRUCTURE_IMAGES.replace(
                  ':structureId',
                  structure.id
                )
              )
            }
          />
        </Box>
        <Box>
          <Typography sx={{ marginBottom: '12px' }} variant="h3Bold">
            {`Documenti(${structure?.documentsCount || 0}) `}
          </Typography>
          <img
            src={documents}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                STRUCTURES_PATHS.STRUCTURE_DOCUMENTS.replace(
                  ':structureId',
                  structure.id
                )
              )
            }
          />
        </Box>
        <Box>
          <Typography sx={{ marginBottom: '12px' }} variant="h3Bold">
            {`Elaborati grafici(${structure?.graphicalElaborationsCount || 0})`}
          </Typography>
          <img
            src={elaborati}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                STRUCTURES_PATHS.STRUCTURE_DOCUMENTS.replace(
                  ':structureId',
                  structure.id
                ),
                {
                  state: {
                    documentsLevelTab: 11,
                  },
                }
              )
            }
          />
        </Box>
      </ContainerMedia>
    );
  };

  const renderTextAdministration = () => {
    return (
      <ContainerText>
        <Typography sx={{ marginBottom: '12px' }} variant="h3Bold">
          {'Amministrazione'}
        </Typography>
        <Row>
          <Typography variant="h3">{'Ente scavalcato'}</Typography>
          <Typography variant="h3Bold">
            {' '}
            {structure?.tipologiaEnteScavalcato?.length > 0
              ? structure?.tipologiaEnteScavalcato?.map(t => `${t}; `)
              : 'ND'}
          </Typography>
        </Row>
        <Row>
          <Typography variant="h3">{'Proprietario'}</Typography>
          <Typography variant="h3Bold">
            {structure?.proprietario ? `${structure?.proprietario}` : 'ND'}
          </Typography>
        </Row>
        <Row>
          <Typography variant="h3">{'Ente vigilante'}</Typography>
          <Typography variant="h3Bold">
            {structure?.enteVigilante || 'ND'}
          </Typography>
        </Row>
        <Row style={{ marginTop: '60px' }}>
          <Rate rating={structure?.involvedLevelsColors || basicRating} />
        </Row>
      </ContainerText>
    );
  };

  const renderTextStructure = () => {
    return (
      <ContainerText>
        <Typography sx={{ marginBottom: '12px' }} variant="h3Bold">
          {'Struttura'}
        </Typography>
        <Row>
          <Typography variant="h3">{'Tipologia strutturale'}</Typography>
          <Typography variant="h3Bold">
            {structureType?.label || 'ND'}
          </Typography>
        </Row>
        <Row>
          <Typography variant="h3">{'Materiale prevalente'}</Typography>
          <Typography variant="h3Bold">
            {impalcatoType?.label || 'ND'}
          </Typography>
        </Row>
        <Row>
          <Typography variant="h3">{'Luce complessiva'}</Typography>
          <Typography variant="h3Bold">
            {structure?.luceComplessivaEstesa || 'ND'}
          </Typography>
        </Row>
        <Row>
          <Typography variant="h3">{'Numero campate'}</Typography>
          <Typography variant="h3Bold">
            {structure?.numeroCampate || 'ND'}
          </Typography>
        </Row>
      </ContainerText>
    );
  };

  // Form to update structure data (tags and private note)
  // TODO: add call to update structure data
  const defaultValues = {
    tags: structure?.tags?.length ? structure?.tags?.split(',') : [],
    /* privateNotes: '', */
  };
  const methods = useForm({
    defaultValues,
  });

  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  const onSubmit = async data => {
    const updatedData = {
      tags: data.tags?.length > 0 ? data.tags?.join(',') : '',
      /* privateNotes: data?.notes, */
    };
    try {
      showLoader();
      await put(`${apiBridges.ROOT}/${id}`, updatedData);
      enqueueSnackbar('Salvataggio riuscito', {
        variant: 'success',
      });
    } catch (err) {
      console.error('Error in update structure: ', err);
      enqueueSnackbar('Errore! Salvataggio non riuscito', {
        variant: 'error',
      });
    } finally {
      hideLoader();
    }
  };

  const renderTypo = index => {
    return (
      <Typo>
        <Status color={InspectionStatuses[index].labelColor} />
        <Typography variant="h3">
          {InspectionStatuses[index].labelCardText}
        </Typography>
      </Typo>
    );
  };
  return (
    <Container>
      <WhiteBoxInfo>
        <Upper>
          <MediaInfoBox>
            {renderPhotoBrigdge()}
            {renderTextAdministration()}
            {renderTextStructure()}
          </MediaInfoBox>
          <MediaInfoBox style={{ justifyContent: 'end' }}>
            {renderBoxMedia()}
          </MediaInfoBox>
        </Upper>
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(onSubmit, console.log)}
        >
          <Grid
            container
            spacing={1}
            sx={{ marginTop: '10px' }}
            alignItems="center"
          >
            <InfoWrapper infoMessage={INFO.structureTag.infoMessage} size={10}>
              <RHFTagAutocomplete
                size="small"
                multiple={true}
                id="tags-outlined"
                name={'tags'}
                errors={errors}
                freeSolo={true}
                getOptionLabel={option => option.name || ''}
                label="Tag"
                placeholder="Scrivi, poi premi invio per inserire il tag"
              />
            </InfoWrapper>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                fullWidth
                type="submit"
                disabled={
                  userPermissionOnStructure !== PermissionType.CanEdit ||
                  isDemoStructure
                }
              >
                Salva tag
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            sx={{ marginTop: '10px' }}
            alignItems="center"
          >
            <Grid item xs={10}>
              <TextField
                fullWidth
                size="small"
                label="Nota privata"
                value={notes}
                InputLabelProps={{ shrink: true }}
                onChange={e => setNotes(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={handleSaveAnnotations}
              >
                Salva nota
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
      </WhiteBoxInfo>
      <TabCreateInspection
        typevariant="secondary"
        variant="fullWidth"
        tabs={getOverviewTab({
          graphRef,
          graphRef2,
          structure,
          userPermissionOnStructure,
          openDrawerNewInspection,
          setOpenDrawerNewInspection,
        })}
        initialTab={0}
        firstLevel={true}
      />
    </Container>
  );
};

export default Summary;

Summary.propTypes = {
  structure: PropTypes.object.isRequired,
  userPermissionOnStructure: PropTypes.number,
  graphRef: PropTypes.node,
  graphRef2: PropTypes.node,
  openDrawerNewInspection: PropTypes.bool,
  setOpenDrawerNewInspection: PropTypes.func,
};
