import { Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import Check from 'assets/icons/check-white.svg';
import Cancel from 'assets/icons/close-black.svg';
import { RHFMultiSelect, RHFSelect, RHFTextField } from 'components';
import { sottoservizi, tipologiaSottoservizi } from 'constants/inspections';
import {
  sottoserviziOptions,
  tipologiaSottoserviziOptions,
  utenzaServiziOptions,
} from 'constants/selectOptions';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import { Buttons } from './SottoserviziForm.styles';

const SottoserviziForm = ({ sottoserviziFields }) => {
  const { getValues, setValue, watch } = useFormContext({
    defaultValues: {
      sottoservizi: '',
      tipologiaSottoservizi: '',
      tipologiaSottoserviziAltro: '',
      utenzaServizi: '',
      concessionario: '',
    },
  });
  const { populateScheda20Difetti } = useSchedaDifetti();

  const watchSottoservizi = watch('sottoserviziTableValue');
  const watchSottoserviziPresenti = watchSottoservizi === sottoservizi.Presente;

  const watchTipologiaSottoservizi = watch('tipologiaSottoserviziTableValue');

  const watchTipologiaAltro =
    watchTipologiaSottoservizi !== ''
      ? watchTipologiaSottoservizi?.some(
          el => el === tipologiaSottoservizi.Altro
        )
      : false;

  useEffect(() => {
    !watchTipologiaAltro &&
      setValue('tipologiaSottoserviziAltroTableValue', '');
  }, [watchTipologiaSottoservizi]);

  const onSubmitSottoservizi = () => {
    const sottoserviziTableValue = getValues('sottoserviziTableValue');
    if (isNullOrUndefinedOrEmptyString(sottoserviziTableValue)) {
      return setValue('sottoserviziTable', []);
    }
    const tipologiaSottoserviziTableValue = getValues(
      'tipologiaSottoserviziTableValue'
    );

    const tipologiaAltroTableValue = getValues(
      'tipologiaSottoserviziAltroTableValue'
    );

    const utenzaServiziTableValue = getValues('utenzaServiziTableValue');

    const concessionarioTableValue = getValues('concessionarioTableValue');

    const schedaDifettiSottoServizi = populateScheda20Difetti(
      'sottoservizio',
      sottoserviziTableValue
    ).map(el => {
      return {
        ...el,
        visto: sottoserviziTableValue === sottoservizi.Assente ? true : false,
        presente:
          sottoserviziTableValue === sottoservizi.Assente ? true : false,
        statoDifetto: null,
        note: '',
        media: [],
      };
    });

    if (sottoserviziFields.length > 0) {
      const sottoserviziArray = [...sottoserviziFields];
      sottoserviziArray.map((x, index) => {
        sottoserviziArray[index] = {
          ...x,
          sottoservizi: sottoserviziTableValue,
          tipologiaSottoservizi: tipologiaSottoserviziTableValue,
          tipologiaSottoserviziAltro: tipologiaAltroTableValue,
          utenzaServizi: utenzaServiziTableValue,
          concessionario: concessionarioTableValue,
          assente:
            sottoserviziTableValue === sottoservizi.Assente ? true : undefined,
          presenza: sottoserviziTableValue,
          ...(sottoserviziTableValue !== x.sottoservizi
            ? {
                schedaDifetti: schedaDifettiSottoServizi,
              }
            : null),
        };
      });
      setValue('sottoserviziTable', sottoserviziArray);
    } else {
      setValue('sottoserviziTable', [
        {
          id: uuidv4(),
          sottoservizi: sottoserviziTableValue,
          tipologiaSottoservizi: tipologiaSottoserviziTableValue,
          tipologiaSottoserviziAltro: tipologiaAltroTableValue,
          utenzaServizi: utenzaServiziTableValue,
          concessionario: concessionarioTableValue,
          assente:
            sottoserviziTableValue === sottoservizi.Assente ? true : undefined,
          presenza: sottoserviziTableValue,
          schedaDifetti: schedaDifettiSottoServizi,
        },
      ]);
    }
    onResetSottoServizi();
  };

  const onResetSottoServizi = () => {
    setValue('sottoserviziTableValue', '');
    setValue('tipologiaSottoserviziTableValue', []);
    setValue('tipologiaSottoserviziAltroTableValue', '');
    setValue('utenzaServiziTableValue', []);
    setValue('concessionarioTableValue', '');
  };

  useEffect(() => {
    if (!watchSottoserviziPresenti) {
      setValue('tipologiaSottoserviziTableValue', []);
      setValue('tipologiaSottoserviziAltroTableValue', '');
      setValue('utenzaServiziTableValue', []);
      setValue('concessionarioTableValue', '');
    }
  }, [watchSottoserviziPresenti]);

  return (
    <Grid container spacing={2} padding="20px">
      <Grid item xs={3}>
        <RHFSelect
          name="sottoserviziTableValue"
          label="Sottoservizi*"
          defaultValue={''}
          onChange={e => {
            setValue('sottoserviziTableValue', e.target.value);
          }}
          selectOptions={sottoserviziOptions}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFMultiSelect
          name="tipologiaSottoserviziTableValue"
          label="Tipologia sottoservizi"
          defaultValue={[]}
          disabled={!watchSottoserviziPresenti}
          onChange={e => {
            setValue('tipologiaSottoserviziTableValue', e.target.value);
          }}
          selectOptions={tipologiaSottoserviziOptions}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFTextField
          name="tipologiaSottoserviziAltroTableValue"
          label={'Specificare altro'}
          inputProps={{ maxLength: 100 }}
          defaultValue=""
          disabled={!watchTipologiaAltro}
          onChange={e => {
            setValue('tipologiaSottoserviziAltroTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFMultiSelect
          name="utenzaServiziTableValue"
          label="Utenza servizi"
          defaultValue={[]}
          disabled={!watchSottoserviziPresenti}
          onChange={e => {
            setValue('utenzaServiziTableValue', e.target.value);
          }}
          selectOptions={utenzaServiziOptions}
        />
      </Grid>

      <Grid item xs={3}>
        <RHFTextField
          name="concessionarioTableValue"
          label={'Concessionario'}
          inputProps={{ maxLength: 150 }}
          defaultValue=""
          disabled={!watchSottoserviziPresenti}
          onChange={e => {
            setValue('concessionarioTableValue', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={9} alignSelf="center">
        <Buttons>
          <IconButton color="primary" onClick={onSubmitSottoservizi}>
            <img src={Check} alt="" />
          </IconButton>
          <IconButton color="primary" onClick={onResetSottoServizi}>
            <img src={Cancel} alt="" />
          </IconButton>
        </Buttons>
      </Grid>
    </Grid>
  );
};
export { SottoserviziForm };

SottoserviziForm.propTypes = {
  sottoserviziFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      sottoserviziTableValue: PropTypes.string,
      tipologiaSottoserviziTableValue: PropTypes.string,
      tipologiaSottoserviziAltro: PropTypes.string,
      utenzaServiziTableValue: PropTypes.string,
      concessionarioTableValue: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};
