import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';

import PropTypes from 'prop-types';

//chart type
import React, { useLayoutEffect } from 'react';

export const RadiusRadar = ({ chartID, cda, overviewDetail }) => {
  useLayoutEffect(() => {
    /**
     * ---------------------------------------
     * This demo was created using amCharts 5.
     *
     * For more information visit:
     * https://www.amcharts.com/
     *
     * Documentation is available at:
     * https://www.amcharts.com/docs/v5/
     * ---------------------------------------
     */

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    const root = am5.Root.new(chartID);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    var chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        innerRadius: am5.p50,
        layout: root.verticalLayout,
      })
    );

    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
    var xRenderer = am5radar.AxisRendererCircular.new(root, {});
    xRenderer.labels.template.setAll({
      textType: 'adjusted',
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'category',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    var yRenderer = am5radar.AxisRendererRadial.new(root, {});
    yRenderer.labels.template.set('visible', false);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    for (var i = 0; i <= 5; i++) {
      var series = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          stacked: true,
          name: 'Series ' + i,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          categoryXField: 'category',
        })
      );

      series.columns.template.setAll({
        tooltipText: '{categoryTooltip}',
      });

      //  todo: tooltip ?
      // series.columns.template.setAll({
      //   tooltipText: '{name}: {valueY}',
      // });
      // const cda = {
      //   0: {
      //     tgm: 1,
      //     difettositaStruttura: 1,
      //     fondazioni: 1,
      //     resilienzaIdraulica: 1,
      //     instabilitàVersante: 1,
      //   },
      //   1: {
      //     tgm: 1,
      //     difettositaStruttura: 1,
      //     // fondazioni: 1,
      //     resilienzaIdraulica: 1,
      //     instabilitàVersante: 1,
      //   },
      //   2: {
      //     tgm: 1,
      //     // difettositaStruttura: 0,
      //     // fondazioni: 1,
      //     resilienzaIdraulica: 1,
      //     instabilitàVersante: 1,
      //   },
      //   3: {
      //     tgm: 1,
      //     // difettositaStruttura: 0,
      //     // fondazioni: 0,
      //     resilienzaIdraulica: 1,
      //     instabilitàVersante: 1,
      //   },
      //   4: {
      //     tgm: 1,
      //     // difettositaStruttura: 0,
      //     // fondazioni: 0,
      //     // resilienzaIdraulica: 0,
      //     instabilitàVersante: 1,
      //   },
      //   5: {
      //     tgm: 0,
      //     // difettositaStruttura: 0,
      //     // fondazioni: 0,
      //     // resilienzaIdraulica: 0,
      //     instabilitàVersante: 1,
      //   },
      // };

      const renderValues = i => {
        const valuesToCda = [];
        if (cda[i]?.tgm) {
          valuesToCda.push({
            category: 'TGM',
            value: cda[i]?.tgm,
            categoryTooltip:
              'Traffico Giornaliero Medio [veicoli/giorno] (carreggiata)',
          });
        }
        if (cda[i]?.difettositaStruttura) {
          valuesToCda.push({
            category: 'Difettosità Struttura',
            value: cda[i]?.difettositaStruttura,
            categoryTooltip: `Parametro determinato dalla compilazione delle schede`,
          });
        }
        if (cda[i]?.fondazioni) {
          valuesToCda.push({
            category: 'Fondazioni',
            value: cda[i]?.fondazioni,
            categoryTooltip: `Parametro che indica se le fondazioni sono ignote, dirette o indirette (su pali)`,
          });
        }
        if (cda[i]?.resilienzaIdraulica) {
          valuesToCda.push({
            category: 'Vulnerabilità idraulica',
            value: cda[i]?.resilienzaIdraulica,
            categoryTooltip: `Parametro che riassume la vulnerabilità idraulica dell'opera`,
          });
        }
        if (cda[i]?.instabilitàVersante) {
          valuesToCda.push({
            category: 'Instabilità Versante',
            value: cda[i]?.instabilitàVersante,
            categoryTooltip: `Parametro che considera lo stato di attività, la velocità e il volume mobilizzabile della frana`,
          });
        }
        return valuesToCda;
      };

      const dataSeries = renderValues(i);

      series.data.setAll(dataSeries);

      var color = am5.color(0xff0000);
      switch (i) {
        case 0:
          color = am5.color(0x99c567);
          break;
        case 1:
          color = am5.color(0xc7deb5);
          break;
        case 2:
          color = am5.color(0xffe695);
          break;
        case 3:
          color = am5.color(0xfdcb37);
          break;
        case 4:
        case 5:
          color = am5.color(0xf0878a);
          break;
      }
      series.set('fill', color); // set Series color to red

      series.appear(1000);
    }

    // slider
    var slider = chart.children.push(
      am5.Slider.new(root, {
        orientation: 'horizontal',
        start: 0.5,
        width: am5.percent(60),
        centerY: am5.p50,
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    slider.events.on('rangechanged', function () {
      var start = slider.get('start');
      var startAngle = 270 - start * 179 - 1;
      var endAngle = 270 + start * 179 + 1;

      chart.setAll({ startAngle: startAngle, endAngle: endAngle });
      yAxis.get('renderer').set('axisAngle', startAngle);
    });

    var data = [
      {
        category: 'TGM',
        value: 1,
      },
      {
        category: 'Difettosità Struttura',
        value: 1,
      },
      {
        category: 'Fondazioni',
        value: 1,
      },
      {
        category: 'Vulnerabilità idraulica',
        value: 1,
      },
      {
        category: 'Instabilità Versante',
        value: 1,
      },
    ];

    if (overviewDetail?.rischioIdraulico || overviewDetail?.rischioFrana) {
      if (
        overviewDetail?.rischioIdraulico === 2 &&
        overviewDetail?.rischioFrana === 2
      ) {
        data = data.filter(
          el =>
            el.category !== 'Vulnerabilità idraulica' &&
            el.category !== 'Instabilità Versante'
        );
      }

      if (overviewDetail?.rischioIdraulico === 2) {
        data = data.filter(el => el.category !== 'Vulnerabilità idraulica');
      }

      if (overviewDetail?.rischioFrana === 2) {
        data = data.filter(el => el.category !== 'Instabilità Versante');
      }
    }

    const xAxisRender = xAxis.get('renderer');

    xAxisRender.labels.template.setAll({
      fontSize: '0.7em',
    });
    xAxis.data.setAll(data);

    // Animate chart
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    chart.appear(100, 100);
    return () => {
      root.dispose();
    };
  }, [chartID]);

  return <div style={{ width: '100%', height: '100%' }} id={chartID}></div>;
};

RadiusRadar.propTypes = {
  chartID: PropTypes.string,
  cda: PropTypes.object,
  overviewDetail: PropTypes.object,
};
