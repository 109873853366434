import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Provider as FetchProvider } from 'use-http';
import { useAuthSelector, useAuthStore } from 'stores';
import { CHILDREN_TYPE } from 'utils/types';
import { CustomOptionsShape } from './HttpProvider.types';

// TODO: Handle Authorization and append relative req header
/**
 * Dummy variable, used to emulate token extraction from global/auth store
 */
// const user = { token: 'Oxk62cvqHg' };

const HttpProvider = ({ children, baseUrl, customOptions }) => {
  const { getRefreshedToken, handleLogout } = useAuthSelector();

  const globalOptions = {
    cachePolicy: 'no-cache',
    onError: ({ error }) => {
      throw error;
    },
    interceptors: {
      request: async ({ options }) => {
        const sessionToken = useAuthStore.getState().sessionToken;
        let currentToken = null;
        if (sessionToken) currentToken = sessionToken;
        else currentToken = await getRefreshedToken();

        options.headers = [['Content-Type', 'application/json']];

        if (currentToken)
          options.headers = [
            ...options.headers,
            ['Authorization', `Bearer ${currentToken}`],
          ];
        return options;
      },
      response: async ({ response }) => {
        if (!response.ok) {
          const statusCode = response.status;

          if (statusCode === 401) handleLogout();

          let errorMessage = statusCode.toString();
          if (response.statusText) errorMessage += ` - ${response.statusText}`;
          if (response?.data?.message)
            errorMessage += ` - ${response.data.message}`;
          throw new Error(errorMessage);
        }
        return response;
      },
    },
  };

  const options = useMemo(
    () => ({
      ...globalOptions,
      ...customOptions,
    }),
    [customOptions]
  );

  return (
    <FetchProvider
      url={baseUrl || process.env.REACT_APP_PROJECT_API_PREFIX}
      options={options}
    >
      {children}
    </FetchProvider>
  );
};

HttpProvider.propTypes = {
  children: CHILDREN_TYPE,
  baseUrl: PropTypes.string,
  customOptions: CustomOptionsShape,
};

export { HttpProvider };
