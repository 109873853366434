import { Check } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { tour_17 } from 'constants/tour';

export const steps = [
  {
    selector: `.${tour_17.step_01}`,
    content: () => {
      return (
        <Typography>
          Cerca le opere che vuoi assegnare al tuo collaboratore
        </Typography>
      );
    },
  },
  {
    selector: `.${tour_17.step_02}`,
    content: () => {
      return (
        <Typography>
          Assegna i diritti alle opere selezionate e clicca <Check /> per
          popolare la tabella
        </Typography>
      );
    },
  },
];
