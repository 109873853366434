import { InputAdornment, TextField } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';

import * as datefns from 'date-fns';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import close from 'assets/icons/close.svg';

const RHFDatePicker = ({
  name,
  rules,
  label,
  disableHighlightToday,
  defaultValue,
  size,
  disabled,
  ...other
}) => {
  const { control } = useFormContext();
  const onKeyDown = e => {
    e.preventDefault();
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <MuiDatePicker
              inputFormat="dd/MM/yyyy"
              disableHighlightToday={disableHighlightToday}
              label={label}
              disabled={disabled}
              {...other}
              onChange={
                // newValue => field.onChange(newValue)
                newValue =>
                  field.onChange(datefns.format(newValue, 'yyyy-MM-dd'))
              }
              value={field.value}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    {...field}
                    size={size}
                    onKeyDown={onKeyDown}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    InputProps={
                      field.value
                        ? {
                            endAdornment: (
                              <InputAdornment
                                sx={{ '&:hover': { cursor: 'pointer' } }}
                                position="end"
                              >
                                {!disabled && (
                                  <img
                                    onClick={() => field.onChange(null)}
                                    src={close}
                                    width={15}
                                    alt="close icon"
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }
                        : {
                            endAdornment: !disabled
                              ? params.InputProps.endAdornment
                              : null,
                          }
                    }
                  />
                );
              }}
            />
          </>
        );
      }}
    />
  );
};

RHFDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  defaultValue: PropTypes.string,
  disableHighlightToday: PropTypes.bool,
};

export { RHFDatePicker };
