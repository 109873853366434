import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiAccessoriL1SectionWrapper,
  InspectionInfoScheda20Form,
} from 'components';
import {
  convogliamentoAcqueOptions,
  tipologiaConvogliamentoAcqueOptions,
} from 'constants/selectOptions';
import ConvogliamentoAcqueForm from './ConvogliamentoAcqueForm';

const Accordion2ConvogliamentoAcque = () => {
  const { watch, setValue, getValues } = useFormContext();
  const convogliamentoAcqueTableWatch = watch('convogliamentoAcqueTable');

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsConvogliamentoAcque = item => {
    setValue('convogliamentoAcqueTableValue', [item]);
    setValue(
      'tipologiaConvogliamentoAcqueTableValue',
      item.tipologiaConvogliamentoAcque
    );
  };

  const handleSelectAllClickConvogliamentoAcque = event => {
    if (event.target.checked) {
      const selectedValue = getValues(`convogliamentoAcqueTable`);
      setSelected(selectedValue);
      setValue('convogliamentoAcqueTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('convogliamentoAcqueTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t4a2_tecnicoRilevatoreConvogliamentoAcque',
      getValues(`convogliamentoAcqueTable[0].tecnicoRilevatore`) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t4a2_dataIspezioneConvogliamentoAcque',
      getValues(`convogliamentoAcqueTable[0].dataIspezione`) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t4a2_noteGeneraliConvogliamentoAcque',
      getValues(`convogliamentoAcqueTable[0].noteGenerali`) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues(
      'l1t4a2_tecnicoRilevatoreConvogliamentoAcque'
    );
    const dataIspezione = getValues('l1t4a2_dataIspezioneConvogliamentoAcque');

    const noteGenerali = getValues('l1t4a2_noteGeneraliConvogliamentoAcque');
    setValue(
      `convogliamentoAcqueTable[0].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(`convogliamentoAcqueTable[0].dataIspezione`, dataIspezione);
    setValue(`convogliamentoAcqueTable[0].noteGenerali`, noteGenerali);
  };

  const handleResetInspection = () => {
    setValue(`l1t4a2_tecnicoRilevatoreConvogliamentoAcque`, '');
    setValue(`l1t4a2_dataIspezioneConvogliamentoAcque`, null);
    setValue(`l1t4a2_noteGeneraliConvogliamentoAcque`, '');
  };

  const dataConvogliamentoAcqueConfig = {
    columns: {
      presenza: {
        label: 'Convogliamento Acque',
        format: convogliamentoAcqueOptions,
      },
      tipologiaConvogliamentoAcque: {
        label: 'Tipologia',
        format: tipologiaConvogliamentoAcqueOptions,
      },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      nrtotali: { label: 'NR' },
    },
  };

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiAccessoriL1SectionWrapper
        elementTitle={'convogliamento acque'}
        singleElementTitle={'convogliamento acque'}
        tableWatch={convogliamentoAcqueTableWatch}
        tableWatchName={`convogliamentoAcqueTable`}
        elementForm={
          <ConvogliamentoAcqueForm
            convogliamentoAcqueFields={convogliamentoAcqueTableWatch || []}
            selected={selected}
            setSelected={setSelected}
            convogliamentoAcqueTable={`convogliamentoAcqueTable`}
          />
        }
        inspectionInfoForm={
          <InspectionInfoScheda20Form
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={
              'l1t4a2_tecnicoRilevatoreConvogliamentoAcque'
            }
            dateName={'l1t4a2_dataIspezioneConvogliamentoAcque'}
            noteGeneraliName={'l1t4a2_noteGeneraliConvogliamentoAcque'}
          />
        }
        tableConfig={dataConvogliamentoAcqueConfig}
        editRows={editRowsConvogliamentoAcque}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickConvogliamentoAcque}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
      />
    </>
  );
};
export default Accordion2ConvogliamentoAcque;
