import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { CardOverview } from 'components';
import { PlanningBox } from 'components/PlanningBox/PlanningBox';
import { STRUCTURES_PATHS } from 'constants/paths';
import {
  EvidenzeProfonditaDelleFondazioniOptions,
  GradoCriticitaFranaOptions,
  StatoAttivitaFranaOptions,
  TipologiaFenomenoOptions,
} from 'constants/selectOptions';
import { inspectionTabMapping } from 'utils/inspectionTabMapping';
import {
  handleDynamicInspectionsTabChange,
  isNullOrUndefinedOrEmptyString,
} from 'utils/utilities';
import { Row } from '../TabL1.styles';

const Contesto = ({ overviewL1 }) => {
  const params = useParams();
  const id = params.structureId;
  const navigate = useNavigate();

  const lastInspectionId = overviewL1?.requestUserLastValidInspectionId;

  const handleEnum = (enumOptions, val) => {
    return overviewL1 && enumOptions[val - 1]?.label;
  };

  // Rischio idrogeologico
  const rischioIdraulico = overviewL1?.ispezioni?.idraulica?.rischio;
  const presenzaStudioIdraulico =
    overviewL1?.contestoAmbientale?.rischioIdraulico_presenzaStudio;
  const struttureInAlveo =
    overviewL1?.contestoAmbientale?.rischioIdraulico_struttureInAlveo;
  const francoIdraulicoStimato =
    overviewL1?.contestoAmbientale?.rischioIdraulico_francoIdraulico;
  const fenomenoErosioneAssente =
    overviewL1?.contestoAmbientale
      ?.fenomeniDiErosioneLocalizzataEGeneralizzataAssenti;
  const profonditaFondazioni =
    overviewL1?.contestoAmbientale?.rischioIdraulico_profonditaFondazioni;

  const tendenzaDivagazionePlanimetrica =
    overviewL1?.contestoAmbientale?.rischioIdraulico_tendenzaAlveo;

  // Rischio frane
  const rischioFrana = overviewL1?.ispezioni?.frana?.rischio;
  const fenomeno = overviewL1?.contestoAmbientale?.rischioFrane_fenomeno;
  const tipologia = overviewL1?.contestoAmbientale?.rischioFrane_tipologia;
  const statoAttivitaData =
    overviewL1?.contestoAmbientale?.rischioFrane_statoAttivita;
  let statoAttivita;
  let statusAttivitaVal;

  if (statoAttivitaData?.seAccertato > 0) {
    statoAttivita = handleEnum(
      StatoAttivitaFranaOptions,
      statoAttivitaData?.seAccertato
    );
    statusAttivitaVal = statoAttivitaData?.seAccertato;
  } else if (statoAttivitaData?.sePotenziale > 0) {
    statoAttivita = handleEnum(
      GradoCriticitaFranaOptions,
      statoAttivitaData?.sePotenziale
    );
    statusAttivitaVal = statoAttivitaData?.sePotenziale;
  }

  return (
    <CardOverview
      title={'Contesto'}
      subtitle={`Rischio idrogeologico`}
      buttonText={'Approfondisci'}
      /* onClick={() => setValue(3)} */
      buttonDisabled={lastInspectionId ? false : true}
      height="auto"
      onClick={() =>
        handleDynamicInspectionsTabChange(
          inspectionTabMapping.L1,
          inspectionTabMapping.DatiGenerali,
          null,
          navigate,
          STRUCTURES_PATHS.STRUCTURES_LIST,
          id,
          lastInspectionId
        )
      }
    >
      <Row>
        <CardOverview
          title={'Rischio idraulico'}
          buttonText={'Approfondisci'}
          buttonDisabled={lastInspectionId ? false : true}
          height="auto"
          subCard={true}
          onClick={() =>
            handleDynamicInspectionsTabChange(
              inspectionTabMapping.L1,
              inspectionTabMapping.DatiGenerali,
              inspectionTabMapping.L1RischioIdraulico,
              navigate,
              STRUCTURES_PATHS.STRUCTURES_LIST,
              id,
              lastInspectionId
            )
          }
        >
          {rischioIdraulico !== 2 ? (
            <>
              <Row style={{ marginTop: 16 }}>
                <PlanningBox
                  topic="Studio idraulico"
                  status={presenzaStudioIdraulico}
                  statusMessage={
                    presenzaStudioIdraulico === 1 ? 'Presente' : 'Assente'
                  }
                  noIcon={true}
                  hideArrow={true}
                />
                <PlanningBox
                  topic="Strutture in alveo"
                  status={struttureInAlveo}
                  statusMessage={
                    struttureInAlveo === 1 ? 'Presente' : 'Assente'
                  }
                  noIcon={true}
                  hideArrow={true}
                />
                <PlanningBox
                  topic="Tendenza dell'alveo a divagazione planimetrica"
                  status={tendenzaDivagazionePlanimetrica}
                  statusMessage={
                    tendenzaDivagazionePlanimetrica === 1
                      ? 'Presente'
                      : 'Assente'
                  }
                  noIcon={true}
                  hideArrow={true}
                />
              </Row>
              <Stack
                sx={{ height: 40, marginTop: '8px' }}
                justifyContent={'space-between'}
              >
                <Typography variant="h3">
                  Franco idraulico stimato{' '}
                  <Typography variant="h3Bold">{`${
                    // 0
                    !isNullOrUndefinedOrEmptyString(
                      francoIdraulicoStimato?.metri
                    )
                      ? `${francoIdraulicoStimato?.metri?.toFixed(2)}m`
                      : francoIdraulicoStimato?.superiore
                      ? `${francoIdraulicoStimato?.superiore?.toFixed(2)}m`
                      : 'n.d.'
                  }`}</Typography>
                </Typography>

                {!fenomenoErosioneAssente && (
                  <Typography variant="h3">
                    Profondità delle fondazioni{' '}
                    <Typography variant="h3Bold">{`${
                      profonditaFondazioni
                        ? handleEnum(
                            EvidenzeProfonditaDelleFondazioniOptions,
                            profonditaFondazioni
                          )
                        : 'n.d.'
                    }`}</Typography>
                  </Typography>
                )}
              </Stack>
            </>
          ) : (
            <>
              <Row style={{ marginTop: 16, width: 250 }}>
                <PlanningBox
                  topic="Rischio idraulico"
                  status={rischioIdraulico}
                  statusMessage={'Assente'}
                  hideArrow={true}
                />
              </Row>
            </>
          )}
        </CardOverview>

        <CardOverview
          title={'Rischio frane'}
          buttonText={'Approfondisci'}
          buttonDisabled={lastInspectionId ? false : true}
          height="auto"
          subCard={true}
          onClick={() =>
            handleDynamicInspectionsTabChange(
              inspectionTabMapping.L1,
              inspectionTabMapping.DatiGenerali,
              inspectionTabMapping.L1RischioFrane,
              navigate,
              STRUCTURES_PATHS.STRUCTURES_LIST,
              id,
              lastInspectionId
            )
          }
        >
          {rischioFrana !== 2 ? (
            <>
              <Row style={{ marginTop: 16 }}>
                <PlanningBox
                  topic="Fenomeno"
                  status={fenomeno}
                  statusMessage={fenomeno ? 'Accertato' : 'Potenziale'}
                  noIcon={true}
                  isRischioFrane={true}
                  hideArrow={true}
                />
                <PlanningBox
                  topic="Tipologia"
                  status={tipologia}
                  statusMessage={
                    tipologia && handleEnum(TipologiaFenomenoOptions, tipologia)
                  }
                  noIcon={true}
                  isRischioFrane={true}
                  hideArrow={true}
                />
                <PlanningBox
                  topic={
                    statoAttivitaData?.seAccertato
                      ? 'Grado di criticità'
                      : 'Stato di attività'
                  }
                  status={statusAttivitaVal}
                  statusMessage={statoAttivita && statoAttivita}
                  noIcon={true}
                  isRischioFrane={true}
                  hideArrow={true}
                />
              </Row>
            </>
          ) : (
            <>
              <Row style={{ marginTop: 16, width: 250 }}>
                <PlanningBox
                  topic="Rischio frana"
                  status={rischioFrana}
                  statusMessage={'Assente'}
                  hideArrow={true}
                  style={{ marginTop: 16 }}
                />
              </Row>
            </>
          )}
        </CardOverview>
      </Row>
    </CardOverview>
  );
};

export { Contesto };

Contesto.propTypes = {
  overviewL1: PropTypes.obj,
};
