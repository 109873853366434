import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaParapettoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';

const ParapettiForm = ({
  parapettiFields,
  selected,
  setSelected,
  parapettiTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      parapettiTableValue: [],
      tipologiaParapettoTableValue: '',
    },
  });

  useEffect(() => {
    setValue('parapettiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetParapetti();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitParapetti = () => {
    const tipologiaParapettoTableValue = getValues(
      'tipologiaParapettoTableValue'
    );

    const ids = selected.map(el => el.codice);

    const parapetti = [...parapettiFields];

    parapetti.map((x, index) => {
      if (ids.includes(x?.codice)) {
        parapetti[index] = {
          ...x,
          tipologiaParapetto: tipologiaParapettoTableValue,
        };
      }
    });

    setValue(parapettiTable, parapetti);
    onResetParapetti();
  };

  const onResetParapetti = () => {
    setValue('parapettiTableValue', []);
    setValue('tipologiaParapettoTableValue', '');
    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper size={3} infoMessage={INFO.parapettiSelezionati.infoMessage}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-parapetti"
          name={'parapettiTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={parapettiFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Parapetti selezionati"
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="tipologiaParapettoTableValue"
          label="Tipologia Parapetto*"
          defaultValue={''}
          onChange={e => {
            setValue('tipologiaParapettoTableValue', e.target.value);
          }}
          selectOptions={tipologiaParapettoOptions}
        />
      </GridWrap>
      <GridWrap item xs={6} alignSelf="center">
        <FormSubmitButtons
          onSubmit={onSubmitParapetti}
          onReset={onResetParapetti}
        />
      </GridWrap>
    </GridWrap>
  );
};
ParapettiForm.propTypes = {
  parapettiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaParapetto: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  parapettiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default ParapettiForm;
