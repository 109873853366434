import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';

import {
  InfoWrapper,
  LocalTourWrapper,
  MediaUpload,
  RHFNumberTextField,
  RHFSelect,
  RHFTextField,
  Table,
} from 'components';
import { colors } from 'constants/colors';
import {
  FieldType,
  ispezioniSpecialiRischioStruttura,
  mensole,
  risultatiIspezioniSpeciali,
  tipologiaImpalcato,
  tipologiaSchema,
  tipologiaStrutturale,
  UploadMedia,
} from 'constants/inspections';

import {
  ispezioniSpecialiRischioStrutturaOptions,
  mensoleOptions,
  risultatiIspezioniSpecialiOptions,
  tipologiaImpalcatoOptions,
  tipologiaSchemaOptions,
  tipologiaStrutturaleOptions,
} from 'constants/selectOptions';
import { tour_10 } from 'constants/tour';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import { GridWrap } from './Accordion1StructuralFeatures.styles';
import { steps } from './Accordion1StructuralFeatures.tourSteps';
import { CampateStrutturaForm } from './CampateStrutturaForm/CampateStrutturaForm';
import useMensoleTamponi from './hooks/useMensoleTamponi';
import useSpallePileCampate from './hooks/useSpallePileCampate';
import { SpallePileForm } from './SpallePileForm/SpallePileForm';
import { INFO } from '../../../../config/info';

const Accordion1StructuralFeatures = ({ levelL1 }) => {
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();
  //State
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [hasMensole, setHasMensole] = useState(false);

  const [selected, setSelected] = useState([]);
  const [selectedCampata, setSelectedCampata] = useState([]);

  const { watch, getValues, setValue, control } = useFormContext();
  // SPALLE/PILE TABLE
  const { append: addSpallaPila, remove: removeSpallaPila } = useFieldArray({
    control,
    name: 'spallePileTable',
  });

  const watchSpallePileTable = watch('spallePileTable');

  const dataSpallePileConfig = {
    columns: {
      codice: { label: 'Codice' },
      appoggi: { label: 'Appoggi (n°)' },
      giunti: { label: 'Giunti (n°)' },
      pulvini: { label: 'Pulvini (n°)' },
      antenne: { label: 'Antenne (n°)' },
      dispositiviSismici: { label: 'Altri dispositivi antisismici (n°)' },
    },
  };

  const editRows = item => {
    setValue('codiceTableValue', [item]);
    setValue('appoggiTableValue', item.appoggi);
    setValue('giuntiTableValue', item.giunti);
    setValue('pulviniTableValue', item.pulvini);
    setValue('antenneTableValue', item.antenne);
    setValue('dispositiviSismiciTableValue', item.dispositiviSismici);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const selectedValue = getValues('spallePileTable');
      setSelected(selectedValue);
      setValue('spallePileTable', selectedValue);
    } else {
      setSelected([]);
    }
  };

  // CAMPATE STRUTTURA TABLE
  const { append: addCampate, remove: removeCampate } = useFieldArray({
    control,
    name: 'campataStrutturaTable',
  });

  const watchCampataStrutturaTable = watch('campataStrutturaTable');

  // MENSOLE/TAMPONI TABLE
  const { append: addMensoleTamponi, remove: removeMensoleTamponi } =
    useFieldArray({
      control,
      name: 'mensoleTamponiTable',
    });

  const watchMensoleTamponiTable = watch('mensoleTamponiTable');

  const dataCampateConfig = {
    columns: {
      campata: { label: 'Codice' },
      travi: { label: 'Travi (n°)' },
      traversi: { label: 'Traversi (n°)' },
      controventi: { label: 'Controventi (n°)' },
      soletta: { label: 'Solette (n°)' },
      archi: { label: 'Archi (n°)' },
      piedritti: { label: 'Piedritti (n°)' },
      tiranti: { label: 'Tiranti (n°)' },
      elementiExtra: { label: 'Elementi extra (n°)' },
    },
  };

  const editCampateRows = item => {
    setValue('codiceCampataTableValue', [item]);
    setValue('traviTableValue', item.travi);
    setValue('traversiTableValue', item.traversi);
    setValue('controventiTableValue', item.controventi);
    setValue('solettaTableValue', item.soletta);
    setValue('archiTableValue', item.archi);
    setValue('piedrittiTableValue', item.piedritti);
    setValue('tirantiTableValue', item.tiranti);
    setValue('elementiExtraTableValue', item.elementiExtra);
  };

  const handleSelectAllCampateClick = event => {
    if (event.target.checked) {
      const selectedValue = getValues('campataStrutturaTable');
      setSelectedCampata(selectedValue);
      setValue('campataStrutturaTable', selectedValue);
    } else {
      setSelectedCampata([]);
    }
  };

  const dataMensoleTamponiConfig = {
    columns: {
      campata: { label: 'Codice' },
      luce: { label: 'Luce M/T (m)' },
      travi: { label: 'Travi (n°)' },
      traversi: { label: 'Traversi (n°)' },
      controventi: { label: 'Controventi (n°)' },
      soletta: { label: 'Solette (n°)' },
      archi: { label: 'Archi (n°)' },
      piedritti: { label: 'Piedritti (n°)' },
      tiranti: { label: 'Tiranti (n°)' },
      elementiExtra: { label: 'Elementi extra (n°)' },
    },
  };

  const editMensoleTamponiRows = item => {
    setValue('codiceCampataTableValue', [item]);
    setValue('luceTableValue', item.luce);
    setValue('traviTableValue', item.travi);
    setValue('traversiTableValue', item.traversi);
    setValue('controventiTableValue', item.controventi);
    setValue('solettaTableValue', item.soletta);
    setValue('archiTableValue', item.archi);
    setValue('piedrittiTableValue', item.piedritti);
    setValue('tirantiTableValue', item.tiranti);
    setValue('elementiExtraTableValue', item.elementiExtra);
  };

  const handleSelectAllMensoleTamponiClick = event => {
    if (event.target.checked) {
      const selectedValue = getValues('mensoleTamponiTable');
      setSelectedCampata(selectedValue);
      setValue('mensoleTamponiTable', selectedValue);
    } else {
      setSelectedCampata([]);
    }
  };

  const watchTipologiaStrutturale = watch('tipologiaStrutturale');
  const watcthTravateCap =
    watchTipologiaStrutturale === tipologiaStrutturale.TravateCap;
  const watchTipologiaStrutturaleAltro =
    watchTipologiaStrutturale === tipologiaStrutturale.Altro;

  const watchTipologiaImpalcato = watch('tipologiaImpalcato');
  const watchTipologiaImpalcatoAltro =
    watchTipologiaImpalcato === tipologiaImpalcato.Altro;

  const watchIspezioniSpeciali = watch(
    'necessitaIspezioniSpecialiRischioStruttura'
  );
  const watchIspezioniSpecialiSi =
    watchIspezioniSpeciali === ispezioniSpecialiRischioStruttura.Si;

  const watchRisultatiIspezioniSpeciali = watch('risultatiIspezioniSpeciali');
  const watchCampiCarimentoFile =
    watchRisultatiIspezioniSpeciali ===
      risultatiIspezioniSpeciali.NecessitaValutazioneLivello4 ||
    watchRisultatiIspezioniSpeciali ===
      risultatiIspezioniSpeciali.IspezioneNonEsaustiva ||
    watchRisultatiIspezioniSpeciali ===
      risultatiIspezioniSpeciali.NecessitaIspezioneLivello1;

  const watchNCampate = watch('numeroCampate');

  const watchMensole = watch('mensole');
  const watchMensoleSi = watchMensole === mensole.Si;
  const watchNMensole = watch('nMensoleStruttura');
  const watchNTamponi = watch('nTamponiStruttura');

  //Functions
  useEffect(() => {
    if (!watcthTravateCap) {
      setValue('necessitaIspezioniSpecialiRischioStruttura', '');
      setValue('risultatiIspezioniSpeciali', '');
    }

    !watchTipologiaStrutturaleAltro &&
      setValue('tipologiaStrutturaleSpecificareAltro', '');
  }, [watchTipologiaStrutturale]);

  useEffect(() => {
    !watchTipologiaImpalcatoAltro &&
      setValue('tipologiaImpalcatoSpecificareAltro', '');
  }, [watchTipologiaImpalcato]);

  useEffect(() => {
    !watchIspezioniSpecialiSi && setValue('risultatiIspezioniSpeciali', '');
  }, [watchIspezioniSpeciali]);

  // hook to handle the change of number spalle/pile and campate
  useSpallePileCampate({
    watchNCampate,
    setIsFormVisible,
    setValue,
    getValues,
    watch,
    removeSpallaPila,
    addSpallaPila,
    removeCampate,
    addCampate,
  });

  useEffect(() => {
    watchMensoleSi ? setHasMensole(true) : setHasMensole(false);

    if (!watchMensoleSi) {
      setValue('nMensoleStruttura', '');
      setValue('nTamponiStruttura', '');
      setValue('mensoleTamponiTable', []);
    } else {
      const resultCampate = [];
      for (let i = 1; i <= watchNCampate; i++) {
        resultCampate.push({
          id: uuidv4(),
          campata: 'C' + i,
          travi: '',
          traversi: '',
          controventi: '',
          soletta: '',
          archi: '',
          piedritti: '',
          tiranti: '',
          elementiExtra: '',
        });
      }
      setValue('campataStrutturaTable', resultCampate);
    }
  }, [watchMensole]);

  // hook to handle the change of number mensole/tamponi
  useMensoleTamponi({
    watchMensoleSi,
    removeMensoleTamponi,
    addMensoleTamponi,
    watchNMensole,
    watchNTamponi,
    watch,
  });

  const watchNpile = watch('nPileStruttura');

  useEffect(() => {
    watchNpile === 0 && setValue('nPileStruttura', '0');
  }, [watchNpile]);

  // CONTROLLO PER DISABILITARE OPZIONI DELLA SELECT
  const [impalcatoOptionsDisabled, setImpalcatoOptionsDisabled] = useState([
    ...tipologiaImpalcatoOptions,
  ]);

  const nCampate = Number(watchNCampate);

  // Campi Tipologia Strutturale
  const travataAppoggiata =
    watchTipologiaStrutturale === tipologiaStrutturale.TravateAppoggiate;

  const travataContinuaTelaio =
    watchTipologiaStrutturale === tipologiaStrutturale.TravateContinueTelaio;

  const arcoMassiccio =
    watchTipologiaStrutturale === tipologiaStrutturale.ArcoMassiccio;

  const arcoSottile =
    watchTipologiaStrutturale === tipologiaStrutturale.ArcoSottile;

  const travataGerber =
    watchTipologiaStrutturale ===
    tipologiaStrutturale.TravateGerberPontiStampella;

  const solettaAppoggiata =
    watchTipologiaStrutturale === tipologiaStrutturale.SolettaAppoggiata;

  const solettaIncastrata =
    watchTipologiaStrutturale === tipologiaStrutturale.SolettaIncastrata;

  const ponteStrallato =
    watchTipologiaStrutturale === tipologiaStrutturale.StrallatoSospeso;

  const cassonePrecompresso =
    watchTipologiaStrutturale === tipologiaStrutturale.CassonePrecompresso;

  const travateCapCaviPostTesi =
    watchTipologiaStrutturale === tipologiaStrutturale.TravateCap;

  const sezioneTubolare =
    watchTipologiaStrutturale === tipologiaStrutturale.SezioneTubolare;

  // Campi Tipologia Schema
  const watchTipologiaSchema = watch('tipologiaSchema');
  const watchSchemaIsostatico =
    watchTipologiaSchema === tipologiaSchema.Isostatico;

  // Campi Tipologia Impalcato
  const tipologiaImpalcatoOptionsNoMuratura = tipologiaImpalcatoOptions.filter(
    o => o.value !== tipologiaImpalcato.Muratura
  );

  const tipologiaImpalcatoNoCapNoMuratura = tipologiaImpalcatoOptions.filter(
    o =>
      o.value !== tipologiaImpalcato.CAP &&
      o.value !== tipologiaImpalcato.Muratura
  );

  const impalcatoNoAcciaioNoMetalloNoLegnoNoMisto =
    tipologiaImpalcatoOptions.filter(
      o =>
        o.value !== tipologiaImpalcato.Acciaio &&
        o.value !== tipologiaImpalcato.Metallo &&
        o.value !== tipologiaImpalcato.Legno &&
        o.value !== tipologiaImpalcato.Misto
    );

  const impalcatoNoAcciaioNoMetalloNoLegnoNoMistoNoCap =
    tipologiaImpalcatoOptions.filter(
      o =>
        o.value !== tipologiaImpalcato.Acciaio &&
        o.value !== tipologiaImpalcato.Metallo &&
        o.value !== tipologiaImpalcato.Legno &&
        o.value !== tipologiaImpalcato.Misto &&
        o.value !== tipologiaImpalcato.CAP
    );

  const impalcatoNoAcciaioNoMetalloNoLegnoNoMuraturaNoMisto =
    tipologiaImpalcatoOptions.filter(
      o =>
        o.value !== tipologiaImpalcato.Acciaio &&
        o.value !== tipologiaImpalcato.Metallo &&
        o.value !== tipologiaImpalcato.Legno &&
        o.value !== tipologiaImpalcato.Muratura &&
        o.value !== tipologiaImpalcato.Misto
    );

  const impalcatoNoMuraturaNoMisto = tipologiaImpalcatoOptions.filter(
    o =>
      o.value !== tipologiaImpalcato.Muratura &&
      o.value !== tipologiaImpalcato.Misto
  );

  const impalcatoNoMuraturaNoMistoNoCap = tipologiaImpalcatoOptions.filter(
    o =>
      o.value !== tipologiaImpalcato.Muratura &&
      o.value !== tipologiaImpalcato.Misto &&
      o.value !== tipologiaImpalcato.CAP
  );

  const impalcatoNoMuraturaNoLegno = tipologiaImpalcatoOptions.filter(
    o =>
      o.value !== tipologiaImpalcato.Muratura &&
      o.value !== tipologiaImpalcato.Legno
  );

  const impalcatoNoMuraturaNoLegnoNoCap = tipologiaImpalcatoOptions.filter(
    o =>
      o.value !== tipologiaImpalcato.Muratura &&
      o.value !== tipologiaImpalcato.Legno &&
      o.value !== tipologiaImpalcato.CAP
  );

  const impalcatoSoloCap = tipologiaImpalcatoOptions.filter(
    o => o.value === tipologiaImpalcato.CAP
  );

  const muratura = watchTipologiaImpalcato === tipologiaImpalcato.Muratura;
  const ca = watchTipologiaImpalcato === tipologiaImpalcato.CA;
  const cap = watchTipologiaImpalcato === tipologiaImpalcato.CAP;
  const acciaio = watchTipologiaImpalcato === tipologiaImpalcato.Acciaio;
  const metallo = watchTipologiaImpalcato === tipologiaImpalcato.Metallo;
  const misto = watchTipologiaImpalcato === tipologiaImpalcato.Misto;
  const legno = watchTipologiaImpalcato === tipologiaImpalcato.Legno;

  useEffect(() => {
    // Controllo Travata appoggiata - Ponte strallato - Travata continua/telaio
    if (travataAppoggiata || ponteStrallato || travataContinuaTelaio) {
      setImpalcatoOptionsDisabled([...tipologiaImpalcatoOptionsNoMuratura]);
      watchTipologiaSchema === 2 && nCampate === 1
        ? setImpalcatoOptionsDisabled([...tipologiaImpalcatoNoCapNoMuratura])
        : setImpalcatoOptionsDisabled([...tipologiaImpalcatoOptionsNoMuratura]);
    }

    // Controllo Arco massiccio
    if (arcoMassiccio) {
      setImpalcatoOptionsDisabled([
        ...impalcatoNoAcciaioNoMetalloNoLegnoNoMisto,
      ]);
      watchTipologiaSchema === 2 && nCampate === 1
        ? setImpalcatoOptionsDisabled([
            ...impalcatoNoAcciaioNoMetalloNoLegnoNoMistoNoCap,
          ])
        : setImpalcatoOptionsDisabled([
            ...impalcatoNoAcciaioNoMetalloNoLegnoNoMisto,
          ]);

      watchTipologiaSchema === 1 &&
        setImpalcatoOptionsDisabled([
          ...impalcatoNoAcciaioNoMetalloNoLegnoNoMuraturaNoMisto,
        ]);
    }

    // Controllo Arco sottile - Soletta appoggiata - Soletta incastrata - Sezione tubolare
    if (
      arcoSottile ||
      solettaAppoggiata ||
      solettaIncastrata ||
      sezioneTubolare
    ) {
      setImpalcatoOptionsDisabled([...impalcatoNoMuraturaNoMisto]);
      watchTipologiaSchema === 2 && nCampate === 1
        ? setImpalcatoOptionsDisabled([...impalcatoNoMuraturaNoMistoNoCap])
        : setImpalcatoOptionsDisabled([...impalcatoNoMuraturaNoMisto]);
    }

    // Controllo Travate gerber/Ponti a stampella con tamponi
    if (travataGerber) {
      setImpalcatoOptionsDisabled([...impalcatoNoMuraturaNoLegno]);
      watchTipologiaSchema === 2 && nCampate === 1
        ? setImpalcatoOptionsDisabled([...impalcatoNoMuraturaNoLegnoNoCap])
        : setImpalcatoOptionsDisabled([...impalcatoNoMuraturaNoLegno]);
    }

    // Controllo Cassone in precompresso - Travate in c.a.p. a cavi post tesi
    if (cassonePrecompresso || travateCapCaviPostTesi) {
      setImpalcatoOptionsDisabled([...impalcatoSoloCap]);
      watchTipologiaSchema === 2 && nCampate === 1
        ? setImpalcatoOptionsDisabled([])
        : setImpalcatoOptionsDisabled([...impalcatoSoloCap]);
    }

    if (!watchTipologiaStrutturale) {
      setValue('tipologiaImpalcato', '');
      setImpalcatoOptionsDisabled([...tipologiaImpalcatoOptions]);
    }
  }, [watchTipologiaSchema, nCampate, watchTipologiaStrutturale]);

  // Sbiancamento campi tipologia impalcato
  useEffect(() => {
    !watchSchemaIsostatico &&
      nCampate === 1 &&
      cap &&
      setValue('tipologiaImpalcato', '');

    watchSchemaIsostatico && muratura && setValue('tipologiaImpalcato', '');

    (travataAppoggiata || travataContinuaTelaio || ponteStrallato) &&
      muratura &&
      setValue('tipologiaImpalcato', '');

    arcoMassiccio &&
      (acciaio || metallo || legno || misto) &&
      setValue('tipologiaImpalcato', '');

    (arcoSottile ||
      solettaAppoggiata ||
      solettaIncastrata ||
      sezioneTubolare) &&
      (muratura || misto) &&
      setValue('tipologiaImpalcato', '');

    travataGerber && (muratura || legno) && setValue('tipologiaImpalcato', '');

    cassonePrecompresso &&
      (ca || acciaio || metallo || legno || misto || muratura) &&
      setValue('tipologiaImpalcato', '');
  }, [
    watchTipologiaSchema,
    watchTipologiaImpalcato,
    watchTipologiaStrutturale,
  ]);

  return (
    <>
      <GridWrap container spacing={2} padding="20px" marginTop={-40}>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          compassMessage={INFO.tipologiaStrutturale.compassMessage}
          drawerTitle={INFO.tipologiaStrutturale.drawerTitle}
          drawerText={INFO.tipologiaStrutturale.drawerText}
          drawerImages={INFO.tipologiaStrutturale.drawerImages}
        >
          <RHFSelect
            name="tipologiaStrutturale"
            label={'Tipologia strutturale*'}
            defaultValue={''}
            selectOptions={tipologiaStrutturaleOptions}
            disabled={levelL1 || inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFTextField
            name="tipologiaStrutturaleSpecificareAltro"
            label={'Specificare altro'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={
              !watchTipologiaStrutturaleAltro ||
              levelL1 ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.tipologiaSchema.drawerTitle}
          drawerText={INFO.tipologiaSchema.drawerText}
        >
          <RHFSelect
            name="tipologiaSchema"
            label={'Tipologia schema*'}
            defaultValue={''}
            selectOptions={tipologiaSchemaOptions}
            disabled={levelL1 || inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>
        <GridWrap item xs={3}></GridWrap>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.tipologiaImpalcato.drawerTitle}
          drawerText={INFO.tipologiaImpalcato.drawerText}
          drawerImages={INFO.tipologiaImpalcato.drawerImages}
        >
          <RHFSelect
            name="tipologiaImpalcato"
            label={'Tipologia impalcato*'}
            defaultValue={''}
            selectOptions={impalcatoOptionsDisabled}
            disabled={levelL1 || inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFTextField
            name="tipologiaImpalcatoSpecificareAltro"
            label={'Specificare altro'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={
              !watchTipologiaImpalcatoAltro ||
              levelL1 ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
        </GridWrap>
        {!levelL1 && (
          <>
            <GridWrap item xs={6}></GridWrap>
            <InfoWrapper
              size={3}
              infoMessage={
                INFO.necessitaIspezioniSpecialiRischioStruttura.infoMessage
              }
              compassMessage={
                INFO.necessitaIspezioniSpecialiRischioStruttura.compassMessage
              }
            >
              <RHFSelect
                name="necessitaIspezioniSpecialiRischioStruttura"
                label={'Necessità di ispezioni speciali (rischio struttura)*'}
                defaultValue={''}
                disabled={
                  !watcthTravateCap || inspectionDisabled || isViewingInspection
                }
                selectOptions={ispezioniSpecialiRischioStrutturaOptions}
              />
            </InfoWrapper>
            <InfoWrapper
              size={3}
              infoDrawer={true}
              compassMessage={INFO.risultatiIspezioniSpeciali.compassMessage}
              drawerTitle={INFO.risultatiIspezioniSpeciali.drawerTitle}
              drawerText={INFO.risultatiIspezioniSpeciali.drawerText}
            >
              <RHFSelect
                name="risultatiIspezioniSpeciali"
                label={'Risultati ispezioni speciali*'}
                defaultValue={''}
                disabled={
                  !watchIspezioniSpecialiSi ||
                  inspectionDisabled ||
                  isViewingInspection
                }
                selectOptions={risultatiIspezioniSpecialiOptions}
              />
            </InfoWrapper>
            <GridWrap item md={3}>
              <MediaUpload
                disabled={
                  !watchCampiCarimentoFile ||
                  !watchIspezioniSpecialiSi ||
                  isViewingInspection
                }
                title={`Doc. ispezioni speciali`}
                btnTitle="Allega file"
                dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
                formName="documentiIspezioniSpecialiRischioStruttura"
                uploadType={UploadMedia.documents}
                type={FieldType.documents}
                tags={['indagini', 'laboratorio', 'ispettore', 'prove']}
              />
            </GridWrap>
            <GridWrap item xs={3}></GridWrap>
          </>
        )}
        <GridWrap item md={3}>
          <RHFNumberTextField
            name="numeroCampate"
            label={'N° campate'}
            defaultValue=""
            decimalScale={0}
            disabled
            withValueLimit={({ value }) => value <= 50}
          />
        </GridWrap>
        {!levelL1 && (
          <>
            <GridWrap item md={3}>
              <RHFNumberTextField
                name="nSpalleStruttura"
                label={'N° spalle*'}
                defaultValue=""
                disabled
                decimalScale={0}
                withValueLimit={({ value }) => value <= 999}
              />
            </GridWrap>
            <GridWrap item md={3}>
              <RHFNumberTextField
                name="nPileStruttura"
                label={'N° pile*'}
                defaultValue=""
                disabled
                decimalScale={0}
                withValueLimit={({ value }) => value <= 999}
              />
            </GridWrap>
            <GridWrap item xs={3} />
          </>
        )}
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.mensole.drawerTitle}
          drawerText={INFO.mensole.drawerText}
        >
          <RHFSelect
            name="mensole"
            label={'Mensole*'}
            defaultValue={''}
            selectOptions={mensoleOptions}
            onChange={e => {
              setValue('mensole', e.target.value);
            }}
            disabled={levelL1 || inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>

        {!levelL1 && (
          <GridWrap item md={3}>
            <RHFNumberTextField
              name="nMensoleStruttura"
              label={'N° mensole*'}
              defaultValue=""
              decimalScale={0}
              withValueLimit={({ value }) => value <= 999 && value >= 0}
              disabled={
                !watchMensoleSi ||
                levelL1 ||
                inspectionDisabled ||
                isViewingInspection
              }
            />
          </GridWrap>
        )}

        {!levelL1 && (
          <GridWrap item md={3}>
            <RHFNumberTextField
              name="nTamponiStruttura"
              label={'N° tamponi*'}
              defaultValue=""
              decimalScale={0}
              withValueLimit={({ value }) => value <= 999 && value >= 0}
              disabled={
                !watchMensoleSi || inspectionDisabled || isViewingInspection
              }
            />
          </GridWrap>
        )}
      </GridWrap>

      <GridWrap container spacing={2}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.creazioneSchemaPonte.drawerTitle}
          drawerText={INFO.creazioneSchemaPonte.drawerText}
          drawerImages={INFO.creazioneSchemaPonte.drawerImages}
        >
          <Divider>Schema ponte</Divider>
        </InfoWrapper>
        {!isFormVisible ? (
          <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
            <Typography variant="h3Bold" sx={{ color: colors.RED }}>
              Attenzione! Per proseguire è necessario inserire il numero di
              campate
            </Typography>
          </div>
        ) : (
          <>
            <GridWrap item xs={12}>
              <Typography variant="h3BoldBlue">SPALLE/PILE</Typography>
            </GridWrap>

            {!levelL1 && !inspectionDisabled && !isViewingInspection && (
              <SpallePileForm
                selected={selected}
                setSelected={setSelected}
                spallePileFields={watchSpallePileTable}
              />
            )}
            <GridWrap item xs={12} className={`${tour_10.step_01}`}>
              <Table
                data={watchSpallePileTable || []}
                config={dataSpallePileConfig}
                hasCheckbox={
                  !levelL1 && !inspectionDisabled && !isViewingInspection
                }
                rowActions={
                  levelL1 || inspectionDisabled || isViewingInspection
                    ? []
                    : [
                        {
                          onClick: item => {
                            editRows(item);
                            setSelected([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                }
                selected={selected}
                onSelectAllClick={handleSelectAllClick}
                setSelected={setSelected}
              />
            </GridWrap>

            {hasMensole ? (
              <>
                <GridWrap item xs={12}>
                  {' '}
                  <Typography variant="h3BoldBlue">MENSOLE/TAMPONI</Typography>
                </GridWrap>

                {!levelL1 && !inspectionDisabled && !isViewingInspection && (
                  <CampateStrutturaForm
                    tableName="mensoleTamponiTable"
                    selected={selectedCampata}
                    setSelected={setSelectedCampata}
                    campateFields={watchMensoleTamponiTable}
                  />
                )}
                <GridWrap item xs={12} className={`${tour_10.step_02}`}>
                  <Table
                    data={watchMensoleTamponiTable || []}
                    config={dataMensoleTamponiConfig}
                    hasCheckbox={
                      !levelL1 && !inspectionDisabled && !isViewingInspection
                    }
                    rowActions={
                      levelL1 || inspectionDisabled || isViewingInspection
                        ? []
                        : [
                            {
                              onClick: item => {
                                editMensoleTamponiRows(item);
                                setSelectedCampata([item]);
                              },
                              icon: <EditIcon />,
                            },
                          ]
                    }
                    selected={selectedCampata}
                    onSelectAllClick={handleSelectAllMensoleTamponiClick}
                    setSelected={setSelectedCampata}
                  />
                </GridWrap>
              </>
            ) : (
              <>
                {' '}
                <GridWrap item xs={12}>
                  <Typography variant="h3BoldBlue">CAMPATE</Typography>{' '}
                </GridWrap>
                {!levelL1 && !inspectionDisabled && !isViewingInspection && (
                  <CampateStrutturaForm
                    tableName="campataStrutturaTable"
                    selected={selectedCampata}
                    setSelected={setSelectedCampata}
                    campateFields={watchCampataStrutturaTable}
                  />
                )}
                <GridWrap item xs={12} className={`${tour_10.step_02}`}>
                  <Table
                    data={watchCampataStrutturaTable || []}
                    config={dataCampateConfig}
                    hasCheckbox={
                      !levelL1 && !inspectionDisabled && !isViewingInspection
                    }
                    rowActions={
                      levelL1 || inspectionDisabled || isViewingInspection
                        ? []
                        : [
                            {
                              onClick: item => {
                                editCampateRows(item);
                                setSelectedCampata([item]);
                              },
                              icon: <EditIcon />,
                            },
                          ]
                    }
                    selected={selectedCampata}
                    onSelectAllClick={handleSelectAllCampateClick}
                    setSelected={setSelectedCampata}
                  />
                </GridWrap>
              </>
            )}
          </>
        )}
        {!isViewingInspection && (
          <GridWrap item xs={12}>
            {parseInt(watchNCampate) !== 0 &&
              !isNullOrUndefinedOrEmptyString(watchNCampate) && (
                <LocalTourWrapper steps={steps} />
              )}
          </GridWrap>
        )}
      </GridWrap>
    </>
  );
};

Accordion1StructuralFeatures.propTypes = {
  levelL1: PropTypes.bool,
};

export { Accordion1StructuralFeatures };
