import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Check from 'assets/icons/check-white.svg';
import Cancel from 'assets/icons/close-black.svg';
import {
  ChangeElementTypologyDialog,
  InfoWrapper,
  RHFNumberTextField,
  RHFSelect,
  RHFTagAutocomplete,
  RHFTextField,
} from 'components';
import {
  GeometriaSezioneTableValue,
  TipologiaEnteScavalcato,
  TipologiaSezionePilaTableValue,
} from 'constants/inspections';
import {
  geometriaSezionePilaOptions,
  impostaFondPilaOptions,
  pilaInAlveoOptions,
  tipoFondPilaOptions,
  tipoPilaSelectOptions,
  tipoSezionePilaOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';
import { Buttons, GridWrap } from './SpalleForm.styles';

const PileForm = ({ pileFields, selected, setSelected }) => {
  const { getValues, setValue, watch } = useFormContext();

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const methods = useFormContext({
    defaultValues: {
      pileTableValue: [],
      tipoPilaTableValue: '',
      tipoSezionePilaTableValue: '',
      tipoSezioneAltroPilaTableValue: '',
      geometriaSezioneTableValue: '',
      pilaInAlveoTableValue: '',
      pilastriPerPilaTableValue: '',
      altezzaMediaPilaTableValue: '',
      altezzaMassimaPilaTableValue: '',
      diametroPilaTableValue: '',
      larghezzaPilastroPilaTableValue: '',
      profonditaPilastroPilaTableValue: '',
      superficiePilaTableValue: '',
      numFondPilaTableValue: '',
      impostaFondPilaTableValue: '',
      tipoFondPilaTableValue: '',
    },
  });
  useEffect(() => {
    setValue('pileTableValue', selected);
  }, [selected]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changePile = () => {
    const tipoPilaTableValue = getValues('tipoPilaTableValue');
    const tipoSezionePilaTableValue = getValues('tipoSezionePilaTableValue');
    const tipoSezioneAltroPilaTableValue = getValues(
      'tipoSezioneAltroPilaTableValue'
    );
    const geometriaSezioneTableValue = getValues('geometriaSezioneTableValue');
    const pilaInAlveoTableValue = getValues('pilaInAlveoTableValue');
    const pilastriPerPilaTableValue = getValues('pilastriPerPilaTableValue');
    const altezzaMediaPilaTableValue = getValues('altezzaMediaPilaTableValue');
    const altezzaMassimaPilaTableValue = getValues(
      'altezzaMassimaPilaTableValue'
    );
    const diametroPilaTableValue = getValues('diametroPilaTableValue');
    const larghezzaPilastroPilaTableValue = getValues(
      'larghezzaPilastroPilaTableValue'
    );
    const profonditaPilastroPilaTableValue = getValues(
      'profonditaPilastroPilaTableValue'
    );
    const superficiePilaTableValue = getValues('superficiePilaTableValue');
    const numFondPilaTableValue = getValues('numFondPilaTableValue');
    const impostaFondPilaTableValue = getValues('impostaFondPilaTableValue');
    const tipoFondPilaTableValue = getValues('tipoFondPilaTableValue');

    const ids = selected.map(el => el.codicePila);

    const pile = [...pileFields];

    pile.map((x, index) => {
      if (ids.includes(x?.codicePila)) {
        pile[index] = {
          // id: x.id,
          // codicePila: x.codicePila,
          ...x,
          tipoPila: tipoPilaTableValue,
          sezionePila: tipoSezionePilaTableValue,
          sezionePilaAltro: tipoSezioneAltroPilaTableValue,
          geometriaSezionePila: geometriaSezioneTableValue,
          pilaInAlveo: pilaInAlveoTableValue,
          pilastriPerPila: pilastriPerPilaTableValue,
          altezzaMediaPila: altezzaMediaPilaTableValue,
          altezzaMassimaPila: altezzaMassimaPilaTableValue,
          diametroPila: diametroPilaTableValue,
          larghezzaPilastroPila: larghezzaPilastroPilaTableValue,
          profonditaPilastroPila: profonditaPilastroPilaTableValue,
          superficiePila: superficiePilaTableValue,
          numFondPila: numFondPilaTableValue,
          impostaFondPila: impostaFondPilaTableValue,
          tipoFondPila: tipoFondPilaTableValue,
          ...(tipoPilaTableValue !== x.tipoPila ||
          impostaFondPilaTableValue !== x.impostaFondPila
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'pila',
                  tipoPilaTableValue,
                  impostaFondPilaTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue('pileTable', pile);
    onResetPile();
  };

  const onConfirmChangePile = () => {
    setOpenConfirmationDialog(false);
    changePile();
  };

  const onSubmitPile = () => {
    const tipoPilaTableValue = getValues('tipoPilaTableValue');

    const impostaFondPilaTableValue = getValues('impostaFondPilaTableValue');
    const ids = selected.map(el => el.codicePila);

    const pile = [...pileFields];

    const hasChangedTipologiaOrImpostaFond = pile.some(el => {
      if (ids.includes(el?.codicePila)) {
        return (
          (tipoPilaTableValue !== el.tipoPila ||
            impostaFondPilaTableValue !== el.impostaFondPila) &&
          !isNullOrUndefinedOrEmptyString(el.tipoPila) &&
          !isNullOrUndefinedOrEmptyString(el.impostaFondPila)
        );
      }
    });

    if (hasChangedTipologiaOrImpostaFond) {
      setOpenConfirmationDialog(true);
    } else {
      changePile();
    }
  };

  const onResetPile = () => {
    setValue('pileTableValue', []);
    setValue('tipoPilaTableValue', '');
    setValue('tipoSezionePilaTableValue', '');
    setValue('tipoSezioneAltroPilaTableValue', '');
    setValue('geometriaSezioneTableValue', '');
    setValue('pilaInAlveoTableValue', '');
    setValue('pilastriPerPilaTableValue', '');
    setValue('altezzaMediaPilaTableValue', '');
    setValue('altezzaMassimaPilaTableValue', '');
    setValue('diametroPilaTableValue', '');
    setValue('larghezzaPilastroPilaTableValue', '');
    setValue('profonditaPilastroPilaTableValue', '');
    setValue('superficiePilaTableValue', '');
    setValue('numFondPilaTableValue', '');
    setValue('impostaFondPilaTableValue', '');
    setValue('tipoFondPilaTableValue', '');

    setSelected([]);
  };

  const tipologiaEnteIsCorsodacqua = watch('tipologiaEnteScavalcato')?.some(
    el => el === TipologiaEnteScavalcato.CorsoAcqua
  );
  const watchGeometriaSezione = watch('geometriaSezioneTableValue');
  // const geometriaSezioneIsCircolare =
  //   watch('geometriaSezioneTableValue') ===
  //   GeometriaSezioneTableValue.Circolare;
  const geometriaSezioneIsCircolare =
    watchGeometriaSezione === GeometriaSezioneTableValue.Circolare;

  const profondita = watch('profonditaPilastroPilaTableValue');
  const larghezza = watch('larghezzaPilastroPilaTableValue');
  const altezzaMedia = watch('altezzaMediaPilaTableValue');
  const pilastriPerPila = watch('pilastriPerPilaTableValue');
  const diametro = watch('diametroPilaTableValue');
  const pi = 3.1415929;

  useEffect(() => {
    if (geometriaSezioneIsCircolare) {
      setValue('larghezzaPilastroPilaTableValue', '');
      setValue('profonditaPilastroPilaTableValue', '');
    } else {
      setValue('diametroPilaTableValue', '');
    }
  }, [geometriaSezioneIsCircolare]);

  // {[2*(Profondità+Larghezza)*Altezza media]*(Numero di pilastri per pila)}/10000
  // Oppure, se "Tipologia sezione" = "Circolare": (Diametro * π * Altezza media)* (Numero di pilastri per pila) /10000
  useEffect(() => {
    if (geometriaSezioneIsCircolare) {
      setValue(
        'superficiePilaTableValue',
        (diametro * pi * altezzaMedia * pilastriPerPila) / 10000
      );
    } else {
      profondita && larghezza && altezzaMedia && pilastriPerPila
        ? setValue(
            'superficiePilaTableValue',
            (2 *
              (Number(profondita) + Number(larghezza)) *
              Number(altezzaMedia) *
              Number(pilastriPerPila)) /
              10000
          )
        : setValue('superficiePilaTableValue', '');
    }
  }, [
    profondita,
    larghezza,
    altezzaMedia,
    pilastriPerPila,
    diametro,
    watchGeometriaSezione,
  ]);

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={3} infoMessage={INFO.pileSelezionate.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-pile"
            name={'pileTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codicePila'}
            getOptionLabel={option => option.codicePila}
            opt={pileFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Pile selezionate"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipoPilaTableValue"
            label="Tipologia Pila*"
            defaultValue={''}
            onChange={e => {
              methods.setValue('tipoPilaTableValue', e.target.value);
            }}
            selectOptions={tipoPilaSelectOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipoSezionePilaTableValue"
            label="Tipologia Sezione"
            defaultValue={''}
            onChange={e => {
              methods.setValue('tipoSezionePilaTableValue', e.target.value);
            }}
            selectOptions={tipoSezionePilaOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="tipoSezioneAltroPilaTableValue"
            label="Tipologia Sezione (Altro)"
            defaultValue={''}
            disabled={
              !(
                watch('tipoSezionePilaTableValue') ===
                TipologiaSezionePilaTableValue.Altro
              )
            }
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="geometriaSezioneTableValue"
            label="Geometria Sezione"
            defaultValue={''}
            onChange={e => {
              methods.setValue('geometriaSezioneTableValue', e.target.value);
            }}
            selectOptions={geometriaSezionePilaOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="pilaInAlveoTableValue"
            label="Pila in Alveo"
            defaultValue={''}
            disabled={!tipologiaEnteIsCorsodacqua}
            onChange={e => {
              methods.setValue('pilaInAlveoTableValue', e.target.value);
            }}
            selectOptions={pilaInAlveoOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="pilastriPerPilaTableValue"
            label={'Numero di pilastri per pila'}
            defaultValue=""
            decimalScale={0}
            withValueLimit={({ value }) => value < 100000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="altezzaMediaPilaTableValue"
            label={'Altezza media (Hm) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="altezzaMassimaPilaTableValue"
            label={'Altezza massima (HM) [cm]'}
            defaultValue=""
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="diametroPilaTableValue"
            label={'Diametro (D) [cm]'}
            defaultValue=""
            disabled={!geometriaSezioneIsCircolare}
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="larghezzaPilastroPilaTableValue"
            label={'Larghezza pilastro (L) [cm]'}
            defaultValue=""
            disabled={geometriaSezioneIsCircolare}
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFNumberTextField
            name="profonditaPilastroPilaTableValue"
            label={'Profondità pilastro (P) [cm]'}
            defaultValue=""
            disabled={geometriaSezioneIsCircolare}
            decimalScale={2}
            withValueLimit={({ value }) => value < 100000000}
          />
        </GridWrap>
        <InfoWrapper size={3} infoMessage={INFO.superficiePile.infoMessage}>
          <RHFNumberTextField
            name="superficiePilaTableValue"
            label={'Superficie [m²]'}
            // InputLabelProps={{ shrink: true }}
            defaultValue={''}
            decimalScale={2}
            disabled
            withValueLimit={({ value }) => value < 1000}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFTextField
            name="numFondPilaTableValue"
            label={'N° fondazioni pila'}
            defaultValue=""
            inputProps={{ maxLength: 50 }}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="impostaFondPilaTableValue"
            label="Imposta fondazioni pila*"
            defaultValue={''}
            onChange={e => {
              methods.setValue('impostaFondPilaTableValue', e.target.value);
            }}
            selectOptions={impostaFondPilaOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipoFondPilaTableValue"
            label="Tipologia fondazioni pila"
            defaultValue={''}
            onChange={e => {
              methods.setValue('tipoFondPilaTableValue', e.target.value);
            }}
            selectOptions={tipoFondPilaOptions}
          />
        </GridWrap>
        <GridWrap item xs={12}>
          <Buttons>
            <IconButton color="primary" onClick={onSubmitPile}>
              <img src={Check} alt="" />
            </IconButton>
            <IconButton color="primary" onClick={onResetPile}>
              <img src={Cancel} alt="" />
            </IconButton>
          </Buttons>
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangePile}
      />
    </>
  );
};

PileForm.propTypes = {
  pileFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codicePila: PropTypes.string,
      tipoPila: PropTypes.string,
      sezionePila: PropTypes.string,
      geometriaSezionePila: PropTypes.string,
      pilaInAlveo: PropTypes.string,
      pilastriPerPila: PropTypes.string,
      altezzaMediaPila: PropTypes.string,
      altezzaMassimaPila: PropTypes.string,
      diametroPila: PropTypes.string,
      larghezzaPilastroPila: PropTypes.string,
      profonditaPilastroPila: PropTypes.string,
      superficiePila: PropTypes.string,
      impostaFondPila: PropTypes.string,
      tipoFondPila: PropTypes.string,
      media: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default PileForm;
