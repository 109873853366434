import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import { DrawerMedia, GridWrap, InfoWrapper, Table } from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import {
  tipologiaTraveOptions,
  tipoTraveOptions,
} from 'constants/selectOptions';
import { useInspectionPath } from 'hooks';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import TraviForm from './TraviForm';
const TraviSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const mensoleTamponiCampateImpalcatoTableTraviTableWatch = watch(
    `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traviTable`
  );

  // table setup
  const dataConfigTravi = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaTrave: {
        label: 'Tipologia',
        format: tipologiaTraveOptions,
      },
      tipoTrave: {
        label: 'Tipo trave',
        format: tipoTraveOptions,
      },
      altezzaSviluppoLateraleTrave: {
        label: 'H [cm]',
      },
      lunghezzaTrave: {
        label: 'L [cm]',
      },
      spessoreTrave: {
        label: 's [cm]',
      },
      superficieTrave: {
        label: 'Sup. [m²]',
        formatValue: val => `${(+val)?.toFixed(2)}`,
      },
    },
  };

  // travi setup
  const [selectedTravi, setSelectedTravi] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();

  const editRowsTravi = item => {
    setValue('l0t2a5_traviTableValue', [item]);
    setValue('l0t2a5_tipologiaTraveTableValue', item.tipologiaTrave);
    setValue('l0t2a5_tipoTraveTableValue', item.tipoTrave);
    setValue(
      'l0t2a5_altezzaSviluppoLateraleTraveTableValue',
      item.altezzaSviluppoLateraleTrave
    );
    setValue('l0t2a5_lunghezzaTraveTableValue', item.lunghezzaTrave);
    setValue('l0t2a5_spessoreTraveTableValue', item.spessoreTrave);
    setValue('l0t2a5_superficieTraveTableValue', item.superficieTrave);
  };

  const handleSelectAllClickTravi = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traviTable`
      );
      setSelectedTravi(selectedValue);
      setValue('l0t2a5_traviTableValue', selectedValue);
    } else {
      setSelectedTravi([]);
      setValue('l0t2a5_traviTableValue', []);
    }
  };

  return (
    <>
      <GridWrap container spacing={2} style={{ marginTop: 16 }}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={INFO.impalcatoTravi.drawerTitle}
          drawerText={INFO.impalcatoTravi.drawerText}
        >
          <Divider>TRAVI</Divider>
        </InfoWrapper>
        {mensoleTamponiCampateImpalcatoTableTraviTableWatch?.length === 0 && (
          <GridWrap item xs={12} paddingTop={'0px !important'}>
            <Typography>Nessuna trave presente in questo elemento</Typography>
          </GridWrap>
        )}
      </GridWrap>
      {mensoleTamponiCampateImpalcatoTableTraviTableWatch?.length > 0 && (
        <>
          {!inspectionDisabled && !isViewingInspection && (
            <TraviForm
              traviFields={
                mensoleTamponiCampateImpalcatoTableTraviTableWatch || []
              }
              selected={selectedTravi}
              setSelected={setSelectedTravi}
              traviTable={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traviTable`}
              selectedIdx={selectedIdx}
            />
          )}
          <DrawerMedia
            open={open}
            setOpen={setOpen}
            title={'Travi'}
            infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
            dataType="video/*,image/*"
            uploadType={UploadMedia.media}
            type={FieldType.images}
            formName={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].traviTable[${drawerMediaIndex}].media`}
            mediaGroup={[MediaGroup.Struttura]}
          />
          <GridWrap container spacing={2} mt={2}>
            <GridWrap item xs={12}>
              <Table
                data={mensoleTamponiCampateImpalcatoTableTraviTableWatch || []}
                config={dataConfigTravi}
                hasCheckbox={!inspectionDisabled && !isViewingInspection}
                rowActions={
                  !inspectionDisabled && !isViewingInspection
                    ? [
                        {
                          onClick: item => {
                            editRowsTravi(item);
                            setSelectedTravi([item]);
                          },
                          icon: <EditIcon />,
                        },
                      ]
                    : []
                }
                mediaActions={[
                  {
                    onClick: item => {
                      setDrawerMediaIndex(
                        mensoleTamponiCampateImpalcatoTableTraviTableWatch.findIndex(
                          el => el.id === item.id
                        )
                      );
                      setOpen(true);
                    },
                    icon: <MediaIcon />,
                  },
                ]}
                selected={selectedTravi}
                onSelectAllClick={handleSelectAllClickTravi}
                setSelected={setSelectedTravi}
              />
            </GridWrap>
          </GridWrap>
        </>
      )}
    </>
  );
};
TraviSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default TraviSection;
