import { Typography } from '@mui/material';
import { tour_07 } from 'constants/tour';

/* import { Check } from '@mui/icons-material';*/
export const steps = [
  {
    selector: `.${tour_07.step_01}`,
    content: () => {
      return (
        <Typography>
          Associa i tag per una più rapida ricerca nelle sezioni Immagini e
          Documenti
        </Typography>
      );
    },
    /* style: {
      backgroundColor: '#bada55',
    }, */
  },
];
