import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaCordoloOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';

const CordoliForm = ({
  cordoliFields,
  selected,
  setSelected,
  cordoliTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      cordoliTableValue: [],
      tipologiaCordoloTableValue: '',
    },
  });

  useEffect(() => {
    setValue('cordoliTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetCordoli();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const onSubmitCordoli = () => {
    const tipologiaCordoloTableValue = getValues('tipologiaCordoloTableValue');

    const ids = selected.map(el => el.codice);

    const cordoli = [...cordoliFields];

    cordoli.map((x, index) => {
      if (ids.includes(x?.codice)) {
        cordoli[index] = {
          ...x,
          tipologiaCordolo: tipologiaCordoloTableValue,
        };
      }
    });

    setValue(cordoliTable, cordoli);
    onResetCordoli();
  };

  const onResetCordoli = () => {
    setValue('cordoliTableValue', []);
    setValue('tipologiaCordoloTableValue', '');
    setSelected([]);
  };

  return (
    <GridWrap container spacing={2} mt={2}>
      <InfoWrapper size={3} infoMessage={INFO.cordoliSelezionati.infoMessage}>
        <RHFTagAutocomplete
          multiple={true}
          id="tags-cordoli"
          name={'cordoliTableValue'}
          changeAutocomplete={value => {
            changeAutocomplete(value);
          }}
          labelName={'option.codice'}
          getOptionLabel={option => option.codice}
          opt={cordoliFields?.filter(
            el => !selected?.some(s => s?.id === el?.id)
          )}
          label="Cordoli selezionati"
        />
      </InfoWrapper>
      <GridWrap item xs={3}>
        <RHFSelect
          name="tipologiaCordoloTableValue"
          label="Tipologia Cordolo*"
          defaultValue={''}
          onChange={e => {
            setValue('tipologiaCordoloTableValue', e.target.value);
          }}
          selectOptions={tipologiaCordoloOptions}
        />
      </GridWrap>
      <GridWrap item xs={6} alignSelf="center">
        <FormSubmitButtons
          onSubmit={onSubmitCordoli}
          onReset={onResetCordoli}
        />
      </GridWrap>
    </GridWrap>
  );
};
CordoliForm.propTypes = {
  cordoliFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaCordolo: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  cordoliTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default CordoliForm;
