export const populateSchedeDifettiTableActions = (
  schedaDifettiIndex,
  tableWatch,
  setOpenSchedaDifetti,
  setSchedaDifettiIndex
) => {
  return [
    {
      onClick: item => {
        // TODO migliorare il reset della scheda difetti
        // al momento rimettere lo stato di schedaDifettiIndex a undefined
        // sembra triggerare correttamente la renderizzazione del componente
        // da migliorare
        if (
          schedaDifettiIndex !== tableWatch.findIndex(el => el.id === item.id)
        ) {
          setOpenSchedaDifetti(false);
          setSchedaDifettiIndex(tableWatch.findIndex(el => el.id === item.id));
        } else {
          setOpenSchedaDifetti(false);
          setSchedaDifettiIndex(undefined);
        }
      },
      setButtonText: item =>
        schedaDifettiIndex !== tableWatch.findIndex(el => el.id === item.id)
          ? `Apri scheda ispezione`
          : 'Chiudi Scheda Ispezione',
    },
  ];
};
