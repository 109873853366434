import styled from '@emotion/styled';
import { Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DataGrid, HeaderPage, PaginationBase } from 'components';
import { colors } from 'constants/colors';
import { USER_PATHS } from 'constants/paths';
import { SubscriptionStatusMap } from 'constants/subscription';
import {
  UserStatusMap,
  UserTypeMap,
  UserTypology,
  UserTypologyMap,
} from 'constants/users';
import { formatData, getGBfromBytes } from 'utils/utilities';
import { useUsersList } from './useUsersList';
import { usersFilter } from '../AdminFilter/Admin.filter';
import { AdminFilter } from '../AdminFilter/AdminFIlter';

const StyledButton = styled(Button)({
  width: 36,
  height: 36,
  minWidth: 0,
});

const Admin = () => {
  const navigate = useNavigate();

  const columns = [
    {
      name: 'id',
      label: 'ID',
      sortable: true,
    },
    {
      name: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      name: 'displayName',
      label: 'Nome cognome',
      sortable: true,
    },
    {
      name: 'type',
      label: 'Tipo utente',
      sortable: true,
      valueFormatter: type => UserTypeMap[type],
    },
    {
      name: 'tipologiaUtente',
      label: 'Tipologia utente',
      sortable: true,
      valueFormatter: type => UserTypologyMap[type],
    },
    {
      label: 'Stato',
      name: 'updatedRegistrationStatus',
      valueFormatter: status => UserStatusMap[status],
      sortable: true,
    },
    {
      name: 'businessName',
      label: 'Azienda/Ente',
      sortable: true,
    },
    {
      name: 'lastSubscriptionStatus',
      label: 'Sottoscrizione',
      sortable: true,
      renderCell: row => {
        return SubscriptionStatusMap[parseInt(row.lastSubscriptionStatus)];
      },
    },
    {
      name: 'subscriptionExpiration',
      label: 'Scad. Sottoscrizione',
      sortable: true,
      renderCell: row => {
        if (row.lastSubscription) {
          return `${formatData(row.lastSubscription.expirationDate) || ''}`;
        } else {
          return '';
        }
      },
    },
    {
      name: 'relatedStructuresNumber',
      label: 'Nr. Strutture',
      sortable: true,
      renderCell: row => {
        if (row.lastSubscription) {
          return row.lastSubscription.maxStructureNumber
            ? `${row.relatedStructuresNumber || '0'}/${
                row.lastSubscription.maxStructureNumber
              }`
            : '';
        } else {
          if (row.tipologiaUtente === UserTypology.UtentePrincipale) {
            return row.relatedStructuresNumber || '0';
          }
        }
      },
    },
    {
      name: 'usedSpace',
      label: 'Spazio utilizzato',
      sortable: true,
      renderCell: row => {
        if (row.lastSubscription) {
          return row.lastSubscription.maxAvailableSpace
            ? `${getGBfromBytes(row?.usedSpace) || '0'}/${
                row.lastSubscription.maxAvailableSpace || '0'
              }GB`
            : '';
        } else {
          if (row.tipologiaUtente === UserTypology.UtentePrincipale) {
            return row?.lastSubscription?.maxAvailableSpace
              ? `${row?.lastSubscription?.maxAvailableSpace}GB`
              : `${getGBfromBytes(row?.usedSpace)}GB`;
          }
        }
      },
    },
    {
      name: 'actions',
      renderCell: row => {
        return (
          <Stack>
            <StyledButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                /* navigate(ADMIN_PATHS.EDIT.replace(':userId', row.id)); */
                navigate(USER_PATHS.EDIT.replace(':userId', row.id));
              }}
            >
              <Edit />
            </StyledButton>
          </Stack>
        );
      },
    },
  ];

  const defaultValues = {
    type: '',
    userTypology: '',
    email: '',
    displayName: '',
    status: '',
  };
  const [filters, setFilters] = useState({});
  const methods = useForm({ defaultValues });

  const { reset } = methods;

  const onReset = () => {
    reset();
  };

  const onSubmit = data => {
    setFilters(data);
  };

  const {
    error,
    loading,
    data,
    orderBy,
    direction,
    handleOrderChange,
    rowsPerPageOptions,
    rowsPerPage,
    page,
    handlePageChange,
    handleRowsPerPageChange,
  } = useUsersList(filters);

  const { users } = data;

  return (
    <>
      <HeaderPage
        title={'Utenti'}
        buttons={
          <>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              onClick={() => navigate(USER_PATHS.CREATE)}
            >
              Nuovo utente
            </Button>
          </>
        }
      />

      <AdminFilter
        inputs={usersFilter}
        itemInLine={5}
        handleSearch={onSubmit}
        resetFilter={onReset}
        marginTop={'-50px'}
      />

      {!error && !loading && (
        <DataGrid
          rowColor={colors.WHITE}
          columns={columns}
          rows={users?.items}
          orderBy={orderBy}
          direction={direction}
          onSort={handleOrderChange}
        />
      )}
      {!error && !loading && (
        <PaginationBase
          div
          count={users?.count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </>
  );
};

export default Admin;
