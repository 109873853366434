import { useEffect, useState } from 'react';
import { useAuthSelector } from 'stores';

const useSubscription = () => {
  const { user: loggedUser } = useAuthSelector();
  /*   const { get } = useFetch();
   */ /*   const { showLoader, hideLoader } = useGlobalSelector();
   */ const [subscription, setSubscription] = useState(
    loggedUser?.lastSubscription
  );
  const [subscriptionStatus, setSubscriptionStatus] = useState(
    parseInt(loggedUser?.lastSubscriptionStatus)
  );
  const [user, setUser] = useState(loggedUser);
  const [relatedStructuresNumber, setRelatedStructuresNumber] = useState(
    parseInt(loggedUser?.relatedStructuresNumber)
  );
  /*   const getUserInfo = async () => {
    let data;
    try {
      showLoader();
      data = await get(apiUsers.USERS_ME);
      setUser(data);
      setSubscriptionStatus(parseInt(data?.lastSubscriptionStatus));
      if (data?.lastSubscription) {
        setSubscription(data.lastSubscription);
        setRelatedStructuresNumber(parseInt(data.relatedStructuresNumber));
      }
      return data;
    } catch (err) {
      console.log('Error in fetch user: ', err);
      throw err;
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []); */

  useEffect(() => {
    if (loggedUser) {
      setUser(loggedUser);
      setSubscriptionStatus(parseInt(loggedUser?.lastSubscriptionStatus));
      if (loggedUser?.lastSubscription) {
        setSubscription(loggedUser.lastSubscription);
        setRelatedStructuresNumber(
          parseInt(loggedUser.relatedStructuresNumber)
        );
      }
    }
  }, [loggedUser]);

  return {
    subscription,
    relatedStructuresNumber,
    subscriptionStatus,
    user,
  };
};

export { useSubscription };
