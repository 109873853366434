import { WebL1Fields } from 'constants/inspectionFields';
import { Accordion1CaratteristicheGeometriche } from 'pages/Inspections/CreateInspection/L0/Structures/Accordion1CaratteristicheGeometriche/Accordion1CaratteristicheGeometriche';
import { Accordion1StructuralFeatures } from 'pages/Inspections/CreateInspection/L0/Structures/Accordion1StructuralFeatures/Accordion1StructuralFeatures';
import Accordion2Elevazione from '../Accordion2Elevazione/Accordion2Elevazione';
import Accordion3Collegamenti from '../Accordion3Collegamenti/Accordion3Collegamenti';
import Accordion4Impalcato from '../Accordion4Impalcato/Accordion4Impalcato';
import { Accordion5CriticalAspectsL1 } from '../Accordion5CriticalAspects/Accordion5CriticalAspectsL1';
import { Accordion6CommentiPerReportL1 } from '../Accordion6CommentiPerReport/Accordion6CommentiPerReportL1';

export const AccordionDataStructureL1 = [
  {
    id: '1',
    title: '1. Caratteristiche geometriche',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion1CaratteristicheGeometriche levelL1={true} />,
    indicatorFields: WebL1Fields.Struttura.carattteristicheGeometriche,
  },
  {
    id: '2',
    title: '2. Caratteristiche strutturali',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion1StructuralFeatures levelL1={true} />,
    indicatorFields: WebL1Fields.Struttura.caratteristicheStrutturali,
  },
  {
    id: '3',
    title: '3. Elevazione',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion2Elevazione />,
    indicatorFields: WebL1Fields.Struttura.elevazione,
  },
  {
    id: '4',
    title: '4. Collegamenti',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion3Collegamenti />,
    indicatorFields: WebL1Fields.Struttura.collegamenti,
  },
  {
    id: '5',
    title: '5. Impalcato',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion4Impalcato />,
    indicatorFields: WebL1Fields.Struttura.impalcato,
  },
  {
    id: '6',
    title: '6. Categorie strutturali e Aspetti critici',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion5CriticalAspectsL1 />,
    indicatorFields: WebL1Fields.Struttura.aspettiCritici,
  },
  {
    id: '7',
    title: '7. Annotazioni ispezione per report',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion6CommentiPerReportL1 />,
    /* indicatorFields: WebL1Fields.Struttura.annotazioniReport, */
  },
];
