export const reorderRiepilogoTable = arr => {
  const tmpArr = arr
    .filter(el => el.codiceOriginale !== 'S1' && el.codiceOriginale !== 'S2')
    .sort((a, b) => a.codeType.localeCompare(b.codeType))
    .sort((a, b) => +a.order - +b.order);
  tmpArr.unshift(arr.find(el => el.codiceOriginale === 'S1'));
  tmpArr.push(arr.find(el => el.codiceOriginale === 'S2'));

  return tmpArr;
};
