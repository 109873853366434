import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import { tipologiaTiranteOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

const TirantiForm = ({
  tirantiFields,
  selected,
  setSelected,
  tirantiTable,
  selectedIdx,
}) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      tirantiTableValue: [],
      tipologiaTiranteTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('tirantiTableValue', selected);
  }, [selected]);

  useEffect(() => {
    onResetTiranti();
  }, [selectedIdx]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeTiranti = () => {
    const tipologiaTiranteTableValue = getValues('tipologiaTiranteTableValue');

    const ids = selected.map(el => el.codice);

    const tiranti = [...tirantiFields];

    tiranti.map((x, index) => {
      if (ids.includes(x?.codice)) {
        tiranti[index] = {
          ...x,
          tipologiaTirante: tipologiaTiranteTableValue,
          ...(tipologiaTiranteTableValue !== x.tipologiaTirante
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'tirante',
                  tipologiaTiranteTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue(tirantiTable, tiranti);
    onResetTiranti();
  };

  const onConfirmChangeTiranti = () => {
    setOpenConfirmationDialog(false);
    changeTiranti();
  };

  const onSubmitTiranti = () => {
    const tipologiaTiranteTableValue = getValues('tipologiaTiranteTableValue');

    const ids = selected.map(el => el.codice);

    const tiranti = [...tirantiFields];

    const hasChangedTipologia = tiranti.some(el => {
      if (ids.includes(el?.codice)) {
        return (
          tipologiaTiranteTableValue !== el.tipologiaTirante &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaTirante)
        );
      }
    });

    if (hasChangedTipologia) {
      setOpenConfirmationDialog(true);
    } else {
      changeTiranti();
    }
  };

  const onResetTiranti = () => {
    setValue('tirantiTableValue', []);
    setValue('tipologiaTiranteTableValue', '');
    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={3} infoMessage={INFO.tirantiSelezionati.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-tiranti"
            name={'tirantiTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codice'}
            getOptionLabel={option => option.codice}
            opt={tirantiFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Tiranti selezionati"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipologiaTiranteTableValue"
            label="Tipologia Tirante"
            defaultValue={''}
            onChange={e => {
              setValue('tipologiaTiranteTableValue', e.target.value);
            }}
            selectOptions={tipologiaTiranteOptions}
          />
        </GridWrap>
        <GridWrap item xs={6} alignSelf="center">
          <FormSubmitButtons
            onSubmit={onSubmitTiranti}
            onReset={onResetTiranti}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeTiranti}
      />
    </>
  );
};
TirantiForm.propTypes = {
  tirantiFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaTirante: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  tirantiTable: PropTypes.string,
  selectedIdx: PropTypes.number,
};

export default TirantiForm;
