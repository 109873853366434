import styled from '@emotion/styled';

export const NotificationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.background.grey}`,
  padding: '15px 0 11px 0',

  '& > div:hover': {
    cursor: 'pointer',
  },

  '& > img': {
    height: '25px',
  },

  '& > img:hover': {
    cursor: 'pointer',
  },
}));

export const DotAlert = styled('div')(({ theme }) => ({
  borderRadius: '100%',
  backgroundColor: theme.palette.background.mariner,
  height: '9px',
  width: '9px',
  marginRight: '5px',
}));

export const TimeContainer = styled('div')({
  display: 'flex',
  marginBottom: '5px',
  alignItems: 'center',
});
