import { Divider } from '@mui/material';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { InfoWrapper, RHFSelect } from 'components';
import { CDAStrutturale } from 'constants/difetti';
import {
  CampateSoggetteACollasso,
  ClasseProgetto,
  VulnerabilitaStrutturaleBassa,
} from 'constants/inspections';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import { GridWrap } from './Accordion5CriticalAspectsL1.styles';
import CondCriticheVulnerabilitaSismica from './sections/CondCriticheVulnerabilitaSismica';
import CondCriticheVulnerabilitaStruttura from './sections/CondCriticheVulnerabilitaStruttura';
import ElCriticiVulnerabilitaSismica from './sections/ElCriticiVulnerabilitaSismica';
import ElCriticiVulnerabilitaStrutturale from './sections/ElCriticiVulnerabilitaStrutturale';
import ElVulnerabilitaSismica from './sections/ElVulnerabilitaSismica';
import TabellaRiepilogativa from './sections/TabellaRiepilogativa';
import useCDARecap from './useCDARecap';
import { INFO } from '../../../../config/info';

const Accordion5CriticalAspectsL1 = () => {
  const { watch, setValue } = useFormContext();
  const { schedaDifettiTrigger, inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();
  const triggerWatch = watch('l1t2a5_trigger');
  const watchNormaProgetto = watch('normaProgetto');

  const { handleCDAPresence } = useCDARecap();
  // ELEMENTI CRITICI - VULNERABILITA STRUTTURALE E FONDAZIONALE
  useEffect(() => {
    handleCDAPresence(
      'elementoCriticoStruttura',
      'elCriticiVulnerabilitaStrutturaleTipologiaElemento',
      'elementiCriticiVulnerabilitaStrutturaleFondazionale'
    );
  }, [triggerWatch, schedaDifettiTrigger]);

  // ELEMENTI CRITICI - VULNERABILITA SISMICA

  useEffect(() => {
    handleCDAPresence(
      'elementoCriticoSismica',
      'elCriticiVulnerabilitaSismicaTipologiaElemento',
      'elementiCriticiVulnerabilitaSismica'
    );
  }, [triggerWatch, schedaDifettiTrigger]);

  // CONDIZIONI CRITICHE - VULNERABILITA STRUTTURALE E FONDAZIONALE

  useEffect(() => {
    handleCDAPresence(
      'condizioneCriticaStruttura',
      'elCondizioniCriticheVulnerabilitaStrutturaTipologiaElemento',
      'condizioniCriticheVulnerabilitaStrutturale'
    );
  }, [triggerWatch, schedaDifettiTrigger]);

  // CONDIZIONI CRITICHE - VULNERABILITA SISMICA

  useEffect(() => {
    handleCDAPresence(
      'condizioneCriticaSismica',
      'elCondizioniCriticheVulnerabilitaSismicaTipologiaElemento',
      'condizioniCriticheVulnerabilitaSismica'
    );
  }, [triggerWatch, schedaDifettiTrigger]);

  useEffect(() => {
    setValue('l1t2a5_trigger', nanoid());
  }, []);

  useEffect(() => {
    if (watchNormaProgetto !== ClasseProgetto.ClasseC) {
      setValue('vulnerabilitaStrutturaleBassa', '');
    }
  }, [watchNormaProgetto]);

  return (
    <>
      <GridWrap container spacing={2} padding="20px" marginTop={-40}>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.campateSoggetteACollasso.drawerTitle}
          drawerText={INFO.campateSoggetteACollasso.drawerText}
          legislationMessage={INFO.campateSoggetteACollasso.legislationMessage}
        >
          <RHFSelect
            name="campateSoggetteACollasso"
            label={'Campate soggette a collasso*'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: '≤3',
                value: CampateSoggetteACollasso.LessEqualThree,
              },
              {
                label: '>3',
                value: CampateSoggetteACollasso.GreaterThree,
              },
            ]}
          />
        </InfoWrapper>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px" marginTop={-40}>
        <InfoWrapper
          isDivider={true}
          size={12}
          infoDrawer={true}
          drawerTitle={
            INFO.definizioneElementiSottostrutturaSovrastruttura.drawerTitle
          }
          drawerText={
            INFO.definizioneElementiSottostrutturaSovrastruttura.drawerText
          }
          legislationMessage={
            INFO.definizioneElementiSottostrutturaSovrastruttura
              .legislationMessage
          }
        >
          <Divider>
            Definizione elementi di sottostruttura e sovrastruttura
          </Divider>
        </InfoWrapper>

        <GridWrap item xs={12}>
          <TabellaRiepilogativa />
        </GridWrap>

        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.vulnerabilitaStrutturaleBassa.drawerTitle}
          drawerText={INFO.vulnerabilitaStrutturaleBassa.drawerText}
          legislationMessage={
            INFO.vulnerabilitaStrutturaleBassa.legislationMessage
          }
        >
          <RHFSelect
            name="vulnerabilitaStrutturaleBassa"
            label={'Vulnerabilità strutturale Bassa'}
            defaultValue={''}
            disabled={
              watch('riepilogoStrutturaTable')?.some(
                el =>
                  !el?.difettositaStrutturale ||
                  el?.difettositaStrutturale == CDAStrutturale.Alta ||
                  el?.difettositaStrutturale == CDAStrutturale.MedioAlta ||
                  el?.difettositaStrutturale == CDAStrutturale.Media
              ) ||
              watchNormaProgetto !== ClasseProgetto.ClasseC ||
              !watchNormaProgetto ||
              inspectionDisabled ||
              isViewingInspection
            }
            selectOptions={[
              {
                label: 'Sì',
                value: VulnerabilitaStrutturaleBassa.Si,
              },
              {
                label: 'No',
                value: VulnerabilitaStrutturaleBassa.No,
              },
            ]}
          />
        </InfoWrapper>
      </GridWrap>

      <ElVulnerabilitaSismica />

      <ElCriticiVulnerabilitaStrutturale />

      <ElCriticiVulnerabilitaSismica />

      <CondCriticheVulnerabilitaStruttura />

      <CondCriticheVulnerabilitaSismica />
    </>
  );
};

export { Accordion5CriticalAspectsL1 };
