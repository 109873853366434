import { Avatar, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const AvatarWithTooltip = ({ label, tooltipTitle }) => {
  return (
    <Tooltip title={tooltipTitle}>
      <Avatar>{label}</Avatar>
    </Tooltip>
  );
};

AvatarWithTooltip.propTypes = {
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

export { AvatarWithTooltip };
