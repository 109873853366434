import { downloadExportFile } from 'utils/upload';
import { ExportOptions } from './constants';

export const handleEnum = (enumOptions, val) => {
  if (val) {
    return enumOptions[val - 1]?.label;
  }
};

export const handleArrayData = (data, enumOption) => {
  if (data) {
    if (data.length === 1) {
      const result = handleEnum(enumOption, data[0]);
      return result;
    } else if (data.length > 1) {
      const result = data?.map(el => handleEnum(enumOption, el))?.join(', ');
      return result;
    }
  }
};

export const handleDownload = (
  fileToDownload,
  setExportSuccess,
  setBtnExportClicked,
  exportOverview,
  schedaDifettiFile,
  schedaDifettiMedia,
  csvL0,
  schedaIspezioniComplete,
  csvL1,
  csvL2,
  schedaCensimentoL0,
  csvAnagraficaBase,
  csvPontiViadottiStradali,
  docFotoRischioIdraulico,
  docFotoRischioFrane,
  strutturaSismica,
  rischioFrane,
  rischioIdraulico,
  relazioneTecnicaCdA,
  csvGenerico,
  tabelleSintesiL1,
  tabelleSintesiL0,
  relazioneTecnicaL0
) => {
  switch (fileToDownload[0].value) {
    case ExportOptions.SchedeCensimento:
      schedaCensimentoL0 && downloadExportFile(schedaCensimentoL0);
      break;

    case ExportOptions.CsvL0:
      csvL0 && downloadExportFile(csvL0);
      break;

    /* case ExportOptions.RelazioneTecnicaL0:
      relazioneTecnicaL0 && downloadExportFile(relazioneTecnicaL0);
      break;

      case ExportOptions.RelazioneTecnicaCdAStrutturaleSismica:
        relazioneTecnicaL0 && downloadExportFile(relazioneTecnicaL0);
        break; */

    case ExportOptions.SchedeIspezioniComplete:
      schedaIspezioniComplete && downloadExportFile(schedaIspezioniComplete);
      break;

    case ExportOptions.CsvL1:
      csvL1 && downloadExportFile(csvL1);
      break;

    case ExportOptions.StrutturaSismica:
      strutturaSismica && downloadExportFile(strutturaSismica);
      break;

    case ExportOptions.SchedeValutazioneDifetti:
      schedaDifettiFile && downloadExportFile(schedaDifettiFile);
      break;

    case ExportOptions.DocFotograficaDifetti:
      schedaDifettiMedia && downloadExportFile(schedaDifettiMedia);
      break;

    case ExportOptions.RischioFrane:
      rischioFrane && downloadExportFile(rischioFrane);
      break;

    case ExportOptions.DocFotograficaFrane:
      docFotoRischioFrane && downloadExportFile(docFotoRischioFrane);
      break;

    case ExportOptions.RischioIdraulico:
      rischioIdraulico && downloadExportFile(rischioIdraulico);
      break;

    case ExportOptions.DocFotograficaIdraulica:
      docFotoRischioIdraulico && downloadExportFile(docFotoRischioIdraulico);
      break;

    case ExportOptions.RelazioneTecnicaL0:
      relazioneTecnicaCdA && downloadExportFile(relazioneTecnicaCdA);
      break;
    case ExportOptions.RelazioneTecnicaCdA:
      relazioneTecnicaCdA && downloadExportFile(relazioneTecnicaCdA);
      break;
    case ExportOptions.RelazioneTecnicaCdAStrutturaleSismica:
      relazioneTecnicaCdA && downloadExportFile(relazioneTecnicaCdA);
      break;
    case ExportOptions.RelazioneTecnicaCdAFrane:
      relazioneTecnicaCdA && downloadExportFile(relazioneTecnicaCdA);
      break;
    case ExportOptions.RelazioneTecnicaCdAIdraulica:
      relazioneTecnicaCdA && downloadExportFile(relazioneTecnicaCdA);
      break;

    case ExportOptions.CsvL2:
      csvL2 && downloadExportFile(csvL2);
      break;

    case ExportOptions.CsvAnagraficaBase:
      csvAnagraficaBase && downloadExportFile(csvAnagraficaBase);
      break;

    case ExportOptions.CsvPontiViadotti:
      csvPontiViadottiStradali && downloadExportFile(csvPontiViadottiStradali);
      break;
    case ExportOptions.CsvPersonalizzati:
      csvGenerico && downloadExportFile(csvGenerico);
      break;
    case ExportOptions.TabelleSintesiL1:
      tabelleSintesiL1 && downloadExportFile(tabelleSintesiL1);
      break;
    case ExportOptions.TabelleSintesiL0:
      tabelleSintesiL0 && downloadExportFile(tabelleSintesiL0);
      break;
  }
};
