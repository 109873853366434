import { WebL1Fields } from 'constants/inspectionFields';
import Accordion1PaliIlluminazione from '../Accordion1PaliIlluminazione/Accordion1PaliIlluminazione';
import Accordion2ConvogliamentoAcque from '../Accordion2ConvogliamentoAcque/Accordion2ConvogliamentoAcque';
import Accordion3Sottoservizi from '../Accordion3Sottoservizi/Accordion3Sottoservizi';
import { Accordion4CommentiPerReportL1 } from '../Accordion4CommentiPerReport/Accordion4CommentiPerReportL1';

export const AccordionDataServicesL1 = [
  {
    id: '1',
    title: '1. Pali illuminazione',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion1PaliIlluminazione />,
    indicatorFields: WebL1Fields.Servizi.paliIlluminazione,
  },
  {
    id: '2',
    title: '2. Convogliamento acque',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion2ConvogliamentoAcque />,
    indicatorFields: WebL1Fields.Servizi.convogliamentoAcque,
  },
  {
    id: '3',
    title: '3. Sottoservizi',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion3Sottoservizi />,
    indicatorFields: WebL1Fields.Servizi.sottoservizi,
  },
  {
    id: '4',
    title: '4. Annotazioni ispezioni per report',
    fieldsDone: '0',
    fieldsTotal: 'Y',
    content: <Accordion4CommentiPerReportL1 />,
    /*  indicatorFields: WebL1Fields.Servizi.annotazioniReport, */
  },
];
