import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  InfoWrapper,
  RHFDatePicker,
  RHFMultiSelect,
  RHFNumberTextField,
  RHFSelect,
  RHFTextField,
} from 'components';
import { PossibileAccessoSottoPonte } from 'constants/inspections';
import {
  AffidabilitaComplessivaRischioFraneOptions,
  AffidabilitaComplessivaRischioIdraulicoOptions,
  PossibileAccessoSottoPonteOptions,
  ProspettiIspezionatiOptions,
  RischioFranaOptions,
  RischioIdraulicoOptions,
  VerificaScalzamentoFondazioniOptions,
} from 'constants/selectOptions';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import {
  GridWrap,
  TitleSection,
} from './Accordion2InspectionInformation.styles';
import { INFO } from '../../../../config/info';

const Accordion2InspectionInformationL1 = () => {
  const { setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchDataIspezione = watch('dataIspezioneRischioStrutturale');
  const watchPossibileAccessoSottoPonte = watch('accessoSottoPonte');
  const watchPossibileAccessoSottoPonteParziale =
    watchPossibileAccessoSottoPonte === PossibileAccessoSottoPonte.Parziale;

  const watchDataIspezioneRischioFrane = watch('dataIspezioneRischioFrane');
  const watchDataIspezioneRischioIdraulico = watch(
    'dataIspezioneRischioIdraulico'
  );

  //Functions
  useEffect(() => {
    !watchDataIspezione && setValue('dataIspezioneRischioStrutturale', null);
  }, [watchDataIspezione]);

  useEffect(() => {
    !watchDataIspezioneRischioFrane &&
      setValue('dataIspezioneRischioFrane', null);
  }, [watchDataIspezioneRischioFrane]);

  useEffect(() => {
    !watchDataIspezioneRischioIdraulico &&
      setValue('dataIspezioneRischioIdraulico', null);
  }, [watchDataIspezioneRischioIdraulico]);

  useEffect(() => {
    !watchPossibileAccessoSottoPonteParziale &&
      setValue('accessoSottoPonteParziale', '');
  }, [watchPossibileAccessoSottoPonte]);

  return (
    <>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              RISCHIO STRUTTURALE E SISMICO
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="rilevatoreRischioStrutturale"
            label={'Rilevatore*'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFDatePicker
            label="Data ispezione*"
            name="dataIspezioneRischioStrutturale"
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="accessoSottoPonte"
            label={'Possibile accedere al di sotto del ponte*'}
            defaultValue={''}
            selectOptions={PossibileAccessoSottoPonteOptions}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoMessage={INFO.accessoSottoPonteParziale.infoMessage}
        >
          <RHFNumberTextField
            name="accessoSottoPonteParziale"
            label={'Parziale'}
            defaultValue=""
            decimalScale={0}
            withValueLimit={({ value }) => value < 10000}
            disabled={
              !watchPossibileAccessoSottoPonteParziale ||
              inspectionDisabled ||
              isViewingInspection
            }
          />
        </InfoWrapper>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.prospettiIspezionati.drawerTitle}
          drawerImages={INFO.prospettiIspezionati.drawerImages}
          drawerText={INFO.prospettiIspezionati.drawerText}
        >
          <RHFMultiSelect
            name="prospettiIspezionati"
            label={'Prospetti ispezionati*'}
            defaultValue={[]}
            selectOptions={ProspettiIspezionatiOptions}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">RISCHIO FRANE</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="rischioFrana"
            label={'Rischio frana'}
            defaultValue={''}
            selectOptions={RischioFranaOptions}
            disabled
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoMessage={INFO.affidabilitaComplessivaRischioFrane.infoMessage}
        >
          <RHFSelect
            name="affidabilitaComplessivaRischioFrane"
            label={'Affidabilità complessiva*'}
            defaultValue={''}
            selectOptions={AffidabilitaComplessivaRischioFraneOptions}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFTextField
            name="rilevatoreRischioFrane"
            label={'Rilevatore rischio frane*'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFDatePicker
            label="Data ispezione rischio frane*"
            name="dataIspezioneRischioFrane"
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">RISCHIO IDRAULICO</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="rischioIdraulico"
            label={'Rischio idraulico'}
            defaultValue={''}
            selectOptions={RischioIdraulicoOptions}
            disabled
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="affidabilitaComplessivaValutazioneRischioIdraulico"
            label={'Affidabilità complessiva*'}
            defaultValue={''}
            selectOptions={AffidabilitaComplessivaRischioIdraulicoOptions}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="rilevatoreRischioIdraulico"
            label={'Rilevatore rischio idraulico*'}
            inputProps={{ maxLength: 50 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFDatePicker
            label="Data ispezione rischio idraulico*"
            name="dataIspezioneRischioIdraulico"
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="possibilitaVerificaScalzamentoFondazioni"
            label={'Possibilità verifica scalzamento fondazioni*'}
            defaultValue={''}
            selectOptions={VerificaScalzamentoFondazioniOptions}
            disabled={inspectionDisabled || isViewingInspection}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">OSSERVAZIONI</Typography>
          </TitleSection>
        </GridWrap>
        <InfoWrapper
          size={12}
          infoMessage={INFO.descrizioneAttivitaIspezione.infoMessage}
        >
          <RHFTextField
            name="descrizioneAttivitaIspezione"
            label={'Descrizione delle attività'}
            inputProps={{ maxLength: 2000 }}
            defaultValue=""
            disabled={inspectionDisabled || isViewingInspection}
          />
        </InfoWrapper>
      </GridWrap>
    </>
  );
};

export { Accordion2InspectionInformationL1 };
