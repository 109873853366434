import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { Rating } from 'constants/colors';
import {
  BoxContainer,
  CriticTag,
  HorizontalBar,
  RateItem,
  Row,
  VerticalBar,
} from './CriticalBox.styles';
import { defects, handleDefectId } from './defects';

const CriticalBox = ({ topic, element }) => {
  const defectData = element?.difettosita;
  const defect = defectData && handleDefectId(defectData);
  const totalG5 = element?.g_5;
  const totalNR = element?.nr;
  const totalPS = element?.ps;
  const isElementoCritico = element?.isElementoCritico;

  let charTex;
  const lastChar = topic.slice(-1);
  switch (lastChar) {
    case 'a':
      charTex = 'Nessuna';
      break;

    default:
      charTex = 'Nessun';
      break;
  }

  if (topic === 'Elemento extra') {
    charTex = 'Nessun';
  }
  if (topic === 'Trave') {
    charTex = 'Nessuna';
  }

  let defectIcon;
  switch (defect) {
    case 'Difetto':
      defectIcon = defects.difetto;
      break;
    case 'Nessun difetto':
      defectIcon = defects.nessunDifetto;
      break;
    case 'Non compilata':
      defectIcon = defects.nonCompilata;
      break;
    case 'Non previsto':
      defectIcon = defects.nonCompilata;
      break;
  }

  return element?.presenzaElementiStruttura === 'presenti' ? (
    <BoxContainer defect={defect}>
      <Typography
        variant="h3Bold"
        sx={{
          fontSize: '0.75rem',
        }}
      >
        {topic}
      </Typography>

      <Row style={{ marginTop: 8 }}>
        <img src={defectIcon} />
        <Typography variant="h4">{defect}</Typography>
        {defect === 'Difetto' && (
          <>
            <VerticalBar />
            <Typography variant="h3Bold">{'G=5'}</Typography>
            <RateItem
              customColors={Rating[totalG5 === '0' ? 'undefined' : 'checked']}
            >
              <Typography variant="h4">{totalG5}</Typography>
            </RateItem>
            <Typography variant="h3Bold">{'PS'}</Typography>
            <RateItem
              customColors={Rating[totalPS === '0' ? 'undefined' : 'checked']}
            >
              <Typography variant="h4">{totalPS}</Typography>
            </RateItem>
          </>
        )}
      </Row>
      {defect !== 'Non previsto' && (
        <>
          <HorizontalBar />
          <Stack
            flexDirection={'row'}
            justifyContent={isElementoCritico ? 'space-between' : 'flex-end'}
            alignItems={'center'}
          >
            {isElementoCritico && (
              <CriticTag>
                <Typography variant="h4">{'Elemento critico'}</Typography>
              </CriticTag>
            )}

            <Row>
              <Typography variant="h3Bold">{'NR'}</Typography>
              <RateItem
                customColors={Rating[totalNR === '0' ? 'undefined' : 'checked']}
              >
                <Typography variant="h4">{totalNR}</Typography>
              </RateItem>
            </Row>
          </Stack>
        </>
      )}
    </BoxContainer>
  ) : (
    element?.presenzaElementiStruttura === 'nonSelezionato' && (
      <BoxContainer defect={null}>
        <Typography
          variant="h3Bold"
          sx={{
            fontSize: '0.75rem',
          }}
        >
          {topic}
        </Typography>
        <Typography variant="h4">{`${
          topic === 'Elementi extra' ? 'Nessun' : charTex
        } ${topic} disponibile`}</Typography>
      </BoxContainer>
    )
  );
};

export { CriticalBox };

CriticalBox.propTypes = {
  /* dataRecap: PropTypes.obj, */
  topic: PropTypes.string,
  element: PropTypes.obj,
};
