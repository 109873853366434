import { Button, Grid, IconButton, InputAdornment } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useFetch from 'use-http';
import EyeDisable from 'assets/icons/eye-disable.svg';
import Eye from 'assets/icons/eye.svg';
import { BasicDialog, HeaderPage } from 'components';
import { apiUsers } from 'constants/api';
import {
  UserActionsWarningMessageMap,
  UsersListActionsMap,
} from 'constants/users';
import { useUsers } from 'hooks';
import { FormProvider } from 'providers';

import { useAuthSelector, useGlobalSelector } from 'stores';
import {
  ButtonContainer,
  ButtonWrapper,
  CustomDivider,
  CustomTextField,
  HeaderWrapper,
} from './MyArea.styles';
import { useMyAreaWarningDialog } from './useMyAreaWarningDialog';

/* {
  "pec": null,
  "fiscalCode": "12345678912",
  "address": null,
  "phone": "123456789",
  "relatedStructuresNumber": null,
  "displayName": "John Doe",
  "id": 3,
  "name": "John",
  "surname": "Doe",
  "businessName": "John Doe Industries",
  "type": "ADMIN",
  "status": 1,
  "email": "tecnomario@mailinator.com"
} */

const MyArea = () => {
  const methods = useForm({
    defaultValues: {
      email: '',
      name: '',
      surname: '',
      pec: '',
      address: '',
      fiscalCode: '',
      phone: '',
      businessName: '',
      password: '',
      confirmPassword: '',
      type: '',
      id: '',
    },
  });

  const { post, del } = useFetch();

  const [showCurrPassword, setShowCurrPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { getUserInfo } = useUsers();
  const { handleSubmit, getValues, reset, clearErrors } = methods;
  const { handleChangePassword, handleLogout } = useAuthSelector();
  const { enqueueSnackbar } = useSnackbar();
  const { showLoader, hideLoader } = useGlobalSelector();

  const passwordsEquality = () => {
    const isValid = getValues('newPassword') === getValues('confirmPassword');
    if (!isValid) return 'Attenzione, la password non coincide.';
    else {
      clearErrors();
      return isValid;
    }
  };

  const passwordValidationPattern = {
    value:
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
    message:
      'La password deve essere lunga almeno 8 caratteri e contenere un numero, una lettera minuscola, una lettera maiuscola e un carattere speciale',
  };
  // eslint-disable-next-line
  const onSubmit = async data => {
    const newPassword = getValues('newPassword');
    const currPassword = getValues('password');
    handleChangePassword(currPassword, newPassword).then(res => {
      if (res) {
        enqueueSnackbar('Password cambiata con successo', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Errore durante il cambio password', {
          variant: 'error',
        });
      }
    });
  };

  const changeInfo = async () => {
    const name = getValues('name');
    const surname = getValues('surname');
    const id = getValues('id');
    const type = getValues('type');
    const email = getValues('email');
    const payload = {
      name,
      surname,
      type,
      email,
    };
    try {
      showLoader();
      await post(`${apiUsers.ROOT}/${id}${apiUsers.EDIT}`, payload);
      enqueueSnackbar('Informazioni cambiate con successo', {
        variant: 'success',
      });
    } catch (err) {
      console.log('C.LOG ~ file: MyArea.jsx:114 ~ err', err);
      enqueueSnackbar('Errore durante la richiesta', {
        variant: 'error',
      });
    } finally {
      hideLoader();
      getDetail();
    }
  };
  const getDetail = async () => {
    const res = await getUserInfo();
    reset(res);
  };
  useEffect(() => {
    getDetail();
  }, []);

  const handleDeleteMyAccount = async () => {
    try {
      showLoader();
      await del(`${apiUsers.USERS_ME}`);
    } catch (err) {
      console.log('ERR', err);
    } finally {
      handleLogout();
      hideLoader();
    }
  };

  const {
    openDialog,
    handleCloseDialog,
    handleDialogAction,
    actionSelected,
    handleSelectRow,
  } = useMyAreaWarningDialog({
    handleDeleteMyAccount,
  });

  return (
    <>
      <HeaderWrapper>
        <HeaderPage title="Area Personale" />
        <ButtonContainer>
          <ButtonWrapper
            variant="outlined"
            color="secondary"
            size="small"
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => handleSelectRow(UsersListActionsMap.DELETE)}
          >
            Elimina Account
          </ButtonWrapper>
        </ButtonContainer>
      </HeaderWrapper>
      <Grid container xs={12} direction="column">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <CustomTextField disabled label="Email d'accesso" name="email" />
          </Grid>
          <Grid container xs={12} direction="row">
            <Grid item xs={6}>
              <CustomTextField label="Nome" name="name" />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField label="Cognome" name="surname" />
            </Grid>
            <Grid item xs={12} textAlign="right">
              <Button variant="containedBlack" onClick={changeInfo}>
                Modifica
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <CustomDivider>Modifica Password</CustomDivider>
          </Grid>
          <Grid container xs={12} direction="row">
            <Grid item xs={4}>
              <CustomTextField
                rules={{
                  required: 'Password obbligatoria',
                  pattern: passwordValidationPattern,
                }}
                type={!showCurrPassword ? 'password' : 'text'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowCurrPassword(!showCurrPassword)}
                      >
                        <img src={showCurrPassword ? Eye : EyeDisable} alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Password Attuale"
                name="password"
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                rules={{
                  required: 'Password obbligatoria',
                  validate: passwordsEquality,
                  pattern: passwordValidationPattern,
                }}
                type={!showPassword ? 'password' : 'text'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <img src={showPassword ? Eye : EyeDisable} alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Nuova Password"
                name="newPassword"
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                rules={{
                  required: 'Password obbligatoria',
                  validate: passwordsEquality,
                  pattern: passwordValidationPattern,
                }}
                type={!showConfirmPassword ? 'password' : 'text'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        <img
                          src={showConfirmPassword ? Eye : EyeDisable}
                          alt=""
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Conferma Nuova Password"
                name="confirmPassword"
              />
            </Grid>
            <Grid item xs={12} textAlign="right">
              <Button variant="containedBlack" type="submit">
                Modifica
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
      <BasicDialog
        title="Attenzione"
        open={openDialog}
        onClose={handleCloseDialog}
        actions={
          <>
            <Button onClick={handleCloseDialog}>Annulla</Button>
            <Button variant="contained" onClick={handleDialogAction}>
              Conferma
            </Button>
          </>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {UserActionsWarningMessageMap[actionSelected]}
          </Grid>
        </Grid>
      </BasicDialog>
    </>
  );
};

export default MyArea;
