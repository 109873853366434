import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ChangeElementTypologyDialog,
  FormSubmitButtons,
  GridWrap,
  InfoWrapper,
  RHFSelect,
  RHFTagAutocomplete,
} from 'components';
import {
  impostaFondSpallaOptions,
  tipologiaSpallaOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

const SpalleForm = ({ spalleFields, selected, setSelected }) => {
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      spalleTableValue: [],
      tipologiaSpallaTableValue: '',
      impostaFondSpallaTableValue: '',
    },
  });

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setValue('spalleTableValue', selected);
  }, [selected]);

  const changeAutocomplete = value => {
    setSelected(value);
  };

  const changeSpalle = () => {
    const tipologiaSpallaTableValue = getValues('tipologiaSpallaTableValue');
    const impostaFondSpallaTableValue = getValues(
      'impostaFondSpallaTableValue'
    );
    const ids = selected.map(el => el.codiceSpalla);

    const spalle = [...spalleFields];

    spalle.map((x, index) => {
      if (ids.includes(x?.codiceSpalla)) {
        spalle[index] = {
          ...x,
          tipologiaSpalla: tipologiaSpallaTableValue,
          impostaFondSpalla: impostaFondSpallaTableValue,
          ...(tipologiaSpallaTableValue !== x.tipologiaSpalla ||
          impostaFondSpallaTableValue !== x.impostaFondSpalla
            ? {
                ...resetObjectSchedaIspezione,
                schedaDifetti: populateSchedaDifetti(
                  'spalla',
                  tipologiaSpallaTableValue,
                  impostaFondSpallaTableValue
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              }
            : null),
        };
      }
    });

    setValue('spalleTable', spalle);
    onResetSpalle();
  };

  const onConfirmChangeSpalle = () => {
    setOpenConfirmationDialog(false);
    changeSpalle();
  };

  const onSubmitSpalle = () => {
    const tipologiaSpallaTableValue = getValues('tipologiaSpallaTableValue');
    const impostaFondSpallaTableValue = getValues(
      'impostaFondSpallaTableValue'
    );

    const ids = selected.map(el => el.codiceSpalla);

    const spalle = [...spalleFields];

    const hasChangedTipologiaOrImpostaFond = spalle.some(el => {
      if (ids.includes(el?.codiceSpalla)) {
        return (
          (tipologiaSpallaTableValue !== el.tipologiaSpalla ||
            impostaFondSpallaTableValue !== el.impostaFondSpalla) &&
          !isNullOrUndefinedOrEmptyString(el.tipologiaSpalla) &&
          !isNullOrUndefinedOrEmptyString(el.impostaFondSpalla)
        );
      }
    });

    if (hasChangedTipologiaOrImpostaFond) {
      setOpenConfirmationDialog(true);
    } else {
      changeSpalle();
    }
  };

  const onResetSpalle = () => {
    setValue('spalleTableValue', []);
    setValue('tipologiaSpallaTableValue', '');
    setValue('impostaFondSpallaTableValue', '');
    setSelected([]);
  };

  return (
    <>
      <GridWrap container spacing={2} mt={2}>
        <InfoWrapper size={3} infoMessage={INFO.spalleSelezionate.infoMessage}>
          <RHFTagAutocomplete
            multiple={true}
            id="tags-spalle"
            name={'spalleTableValue'}
            changeAutocomplete={value => {
              changeAutocomplete(value);
            }}
            labelName={'option.codiceSpalla'}
            getOptionLabel={option => option.codiceSpalla}
            opt={spalleFields?.filter(
              el => !selected?.some(s => s?.id === el?.id)
            )}
            label="Spalle selezionate"
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="tipologiaSpallaTableValue"
            label="Tipologia Spalla"
            defaultValue={''}
            onChange={e => {
              setValue('tipologiaSpallaTableValue', e.target.value);
            }}
            selectOptions={tipologiaSpallaOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="impostaFondSpallaTableValue"
            label="Imposta fondazioni spalla*"
            defaultValue={''}
            onChange={e => {
              setValue('impostaFondSpallaTableValue', e.target.value);
            }}
            selectOptions={impostaFondSpallaOptions}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <FormSubmitButtons
            onSubmit={onSubmitSpalle}
            onReset={onResetSpalle}
          />
        </GridWrap>
      </GridWrap>
      <ChangeElementTypologyDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={onConfirmChangeSpalle}
      />
    </>
  );
};
SpalleForm.propTypes = {
  spalleFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codice: PropTypes.string,
      tipologiaSpalla: PropTypes.string,
    })
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default SpalleForm;
