import { Grid, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Compass from 'assets/icons/compass.svg';
import Info from 'assets/icons/info.svg';
import Legislation from 'assets/icons/legislation.svg';
import Link from 'assets/icons/link.svg';
import Quote from 'assets/icons/quote.svg';
import { DrawerMUI } from 'components';
import { WhiteBoxInfoDrawer } from 'components/Drawer';
import {
  ButtonContainer,
  Container,
  ImgStyle,
  InfoBtnContainer,
  InfoContainer,
  MessageInfoContainer,
  MessagePopUpContainer,
  RightButtonContainer,
} from './InfoWrapper.style';

const InfoWrapper = ({
  size,
  infoMessage,
  compassMessage,
  linkMessage,
  quoteMessage,
  legislationMessage,
  drawerTitle,
  children,
  infoDrawer,
  drawerText,
  drawerImages,
  isDivider,
  button,
}) => {
  //State
  const [openMessage, setOpenMessage] = useState(false);
  const [open, setOpen] = useState(false);

  //Function
  const handleInfoMessage = () => {
    if (infoDrawer) {
      setOpen(!open);
    }
    setOpenMessage(prev => !prev);
  };

  const hasInfo = !!(
    infoMessage?.length > 0 ||
    compassMessage?.length > 0 ||
    linkMessage?.length > 0 ||
    quoteMessage?.length > 0 ||
    legislationMessage?.length > 0
  );
  const renderSpecificInfo = (message, imgSrc) => {
    if (message?.length > 0) {
      return (
        <InfoContainer>
          <img src={imgSrc} alt="" />
          <MessagePopUpContainer>
            {message?.map((x, i) => (
              <Typography
                key={`infomessage-${i}-${nanoid(8)}`}
                style={{ fontSize: '12px' }}
              >
                {x}
              </Typography>
            ))}
          </MessagePopUpContainer>
        </InfoContainer>
      );
    }
  };

  return (
    <Grid item xs={size}>
      <Container>
        {
          /**
           * three ways of displaying info
           * 1) simple message under the info button, can have   infoMessage or compassMessage or linkMessage or quoteMessage or legislationMessage
           * 2) is Drawer and can have drawerTitle or drawerText or infoMessage or compassMessage or linkMessage or quoteMessage or legislationMessage
           */
          ((hasInfo && !infoDrawer) || infoDrawer) && (
            <InfoBtnContainer
              isDivider={isDivider}
              onClick={() => handleInfoMessage()}
            >
              <Typography style={{ fontSize: '8px' }}>info</Typography>
            </InfoBtnContainer>
          )
        }
        <div style={{ marginTop: 5 }}>
          {children}

          {!infoDrawer && openMessage && (
            <MessageInfoContainer>
              {renderSpecificInfo(infoMessage, Info)}
              {renderSpecificInfo(legislationMessage, Legislation)}
              {renderSpecificInfo(compassMessage, Compass)}
              {renderSpecificInfo(quoteMessage, Quote)}
              {renderSpecificInfo(linkMessage, Link)}
            </MessageInfoContainer>
          )}
        </div>
        <RightButtonContainer>{button}</RightButtonContainer>
        <DrawerMUI
          open={open}
          setOpen={setOpen}
          title={drawerTitle}

          // onClose={() => setFiles([])}
        >
          <div>
            {hasInfo && (
              <MessageInfoContainer>
                {renderSpecificInfo(infoMessage, Info)}
                {renderSpecificInfo(legislationMessage, Legislation)}
                {renderSpecificInfo(compassMessage, Compass)}
                {renderSpecificInfo(quoteMessage, Quote)}
                {renderSpecificInfo(linkMessage, Link)}
              </MessageInfoContainer>
            )}
            {drawerText?.map((x, index) => {
              return (
                <>
                  <WhiteBoxInfoDrawer
                    key={index}
                    title={x.title}
                    description={x.description}
                  />
                  {x.image && (
                    <ImgStyle
                      key={'images' + index}
                      src={x.image}
                      alt="structure info image"
                    />
                  )}
                </>
              );
            })}
            {drawerImages?.map((x, index) => {
              return (
                <ImgStyle
                  key={'images' + index}
                  src={x.image}
                  alt="structure info image"
                />
              );
            })}

            <ButtonContainer
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setOpen(prev => !prev)}
            >
              Chiudi
            </ButtonContainer>
          </div>
        </DrawerMUI>
      </Container>
    </Grid>
  );
};

export { InfoWrapper };

InfoWrapper.defaultProps = {
  infoDrawer: false,
};

InfoWrapper.propTypes = {
  size: PropTypes.number.isRequired,
  infoMessage: PropTypes.array,
  children: PropTypes.node,
  infoDrawer: PropTypes.bool,
  drawerTitle: PropTypes.string,
  compassMessage: PropTypes.array,
  linkMessage: PropTypes.array,
  quoteMessage: PropTypes.array,
  legislationMessage: PropTypes.array,
  drawerText: PropTypes.array,
  drawerImages: PropTypes.array,
  isDivider: PropTypes.bool,
  button: PropTypes.node,
};
